import { Button, ModalBody, Col, Row } from 'reactstrap';
import ModalLoader from '../../../../../../Common/Loader/ModalLoader';

const FileTypesConfigModal = ({ dataList, selectedData, onSubmit, onClose, onBack, isLoading }) => {
  return (
    <>
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title d-flex align-items-center">
          <span className="fs-4 cursor-pointer d-inline-block me-2" onClick={onBack}>
            <i className=" ri-arrow-left-s-line"></i>
          </span>
          Bulk Upload
        </h5>
        <Button
          type="button"
          onClick={() => {
            onClose();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        />
      </div>

      <ModalBody>
        {isLoading && <ModalLoader />}

        {!isLoading && (
          <>
            <Row>
              <Col md={12}>
                <p htmlFor="name" className="fs-5 mb-2">
                  Select golf Category
                </p>
              </Col>
            </Row>

            <Row>
              {dataList?.map((fileType, index) => (
                <Col md={6} key={index}>
                  <button
                    className={`btn rounded-pill w-100 mb-3 ${selectedData?.objectId === fileType?.objectId ? 'btn-primary' : 'btn-outline-primary'}`}
                    onClick={() => onSubmit(fileType)}
                  >
                    <span className="d-flex align-items-center">
                      <span className="flex-grow-1 ms-2">{fileType?.name}</span>
                    </span>
                  </button>
                </Col>
              ))}
            </Row>

            <Row>
              <p className="text-center mt-3 text-muted">*You can choose only one type</p>
            </Row>
          </>
        )}
      </ModalBody>
    </>
  );
};

export default FileTypesConfigModal;
