import React from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import RedeemedTable from './RedeemedTable';
import moment from 'moment';

const UserHistoryModal = ({ userPoints, userBookings, onCloseHandler, pageNumber, pageHandler, filterDateHandler }) => {
  return (
    <Modal
      isOpen={true}
      toggle={() => {
        onCloseHandler();
      }}
      id="userHistoryModal"
      size="xl"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          User History
        </h5>
        <Button
          type="button"
          onClick={() => {
            onCloseHandler();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        <form>
          {/* <Row className=" mb-3">
                        <Col md={4}>
                            <div className="input-group">
                                <div className="input-group-text bg-success border-success text-white">
                                    <i className="ri-calendar-2-line"></i>
                                </div>
                                <input
                                    className="form-control border border-groove bg-light border-start-0 shadow"
                                    type="text"
                                    readOnly="readonly"
                                    value={moment(`${new Date()}`).format("DD-MMM-YYYY")}
                                />
                            </div>
                        </Col>
                    </Row> */}

          <Row>
            <Col md={4}>
              <div className="p-3 card card-body  rounded bg-white">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm me-2">
                    <div className="avatar-title bg-soft-success text-success fs-17 rounded">
                      <i className="bx bx-wallet"></i>
                    </div>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <h6 className="">Total Coins Issued</h6>
                    <h5 className="mb-0">{userPoints?.issuedCoins ?? ''}</h5>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="p-3 card card-body  rounded bg-white">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm me-2">
                    <div className="avatar-title bg-soft-success text-success fs-17 rounded">
                      <i className="bx bx-wallet"></i>
                    </div>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <h6 className="">Current Coins Balance</h6>
                    <h5 className="mb-0">{userPoints?.currentCoins ?? ''}</h5>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="p-3 card card-body  rounded bg-white">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm me-2">
                    <div className="avatar-title bg-soft-success text-success fs-17 rounded">
                      <i className="bx bx-wallet"></i>
                    </div>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <h6 className="">Total Coins Redeemed</h6>
                    <h5 className="mb-0">{userPoints?.coinsReedemed ?? ''}</h5>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <RedeemedTable
                title={`Redeemed History`}
                data={userBookings?.results}
                totalData={userBookings?.count}
                pageHandler={pageHandler}
                pageNumber={pageNumber}
                actionHandler={0}
                filterDateHandler={filterDateHandler}
              />
            </Col>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default UserHistoryModal;
