import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from './store';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './i18n';
import { GoogleOAuthProvider } from '@react-oauth/google';

const environment = process.env.REACT_APP_NODE_ENV;
const clientDSN = process.env.REACT_APP_SENTRY_DSN;

// if (environment !== `dev`) {
//   Sentry.init({
//     dsn: clientDSN,
//     integrations: [
//       new Sentry.BrowserTracing(),
//       new Sentry.Replay({
//         maskAllText: false,
//         blockAllMedia: false,
//       }),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0,
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0,
//     environment: environment
//   });
// }

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={configureStore({})}>
    <React.Fragment>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <ToastContainer />
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH}>
          <App />
        </GoogleOAuthProvider>
      </BrowserRouter>
    </React.Fragment>
  </Provider>,
);

reportWebVitals();
