import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from '../../../../Common';
import { Container } from 'reactstrap';
import { GET_PARENT_PROJECTS, CREATE_PARENT_PROJECT_EMPTY, CREATE_CLIENTS_EMPTY, GET_CLIENTS } from '../../../../../store/application/actionType';
import PlatformConfiguration from './Components/Forms/PlatformConfiguration';
import CreateClientModal from './Components/Modals/CreateClient';
import PlatformsList from './Components/Tables/PlatformsList';

const Platform = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);

  const [isPlatformsTableShow, setIsPlatformsTableShow] = useState(true);
  const [isCreatePlatformShow, setIsCreatePlatformShow] = useState(false);

  const [isCreateClient, setIsCreateClient] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Platforms List
  const { platformsList, platformsListLoading } = useSelector(state => ({
    platformsList: state?.ParentProjects?.data,
    platformsListLoading: state?.ParentProjects?.loading,
  }));

  // Create Program
  const { createProgram, createProgramLoading } = useSelector(state => ({
    createProgram: state?.CreateParentProject?.data,
    createProgramLoading: state?.CreateProject?.loading,
  }));

  // Countries List
  const { countries, countriesLoading } = useSelector(state => ({
    countries: state?.Countries?.data,
    countriesLoading: state?.Countries?.loading,
  }));

  // Create Client
  const { clientsRes, createClientsLoading } = useSelector(state => ({
    clientsRes: state?.CreateClient?.data,
    createClientsLoading: state?.CreateClient?.loading,
  }));

  // Clients List
  const { clientsLoading } = useSelector(state => ({
    clientsLoading: state?.Clients?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_PARENT_PROJECTS,
      payload: {
        urlParam: {
          pageIndex: 0,
          pageSize: 100,
        },
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (createProgram !== null) {
      dispatch({
        type: CREATE_PARENT_PROJECT_EMPTY,
      });

      dispatch({
        type: GET_PARENT_PROJECTS,
        payload: {
          urlParam: {
            pageIndex: 0,
            pageSize: 100,
          },
        },
      });
      setIsPlatformsTableShow(true);
      setIsCreatePlatformShow(false);
    }
  }, [createProgram]);

  useEffect(() => {
    if (clientsRes !== null) {
      dispatch({
        type: CREATE_CLIENTS_EMPTY,
      });

      dispatch({
        type: GET_CLIENTS,
      });
    }
  }, [clientsRes]);

  /* ---------------------------- BUTTON HANDLER ---------------------------- */
  const createPlatformBtnHandler = () => {
    setIsPlatformsTableShow(false);
    setIsCreatePlatformShow(true);
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (platformsListLoading || countriesLoading || clientsLoading || createProgramLoading || createClientsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [platformsListLoading, countriesLoading, clientsLoading, createProgramLoading, createClientsLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Platform Management`) {
      setIsPlatformsTableShow(true);
      setIsCreatePlatformShow(false);

      dispatch({
        type: GET_PARENT_PROJECTS,
        payload: {
          urlParam: {
            pageIndex: 0,
            pageSize: 100,
          },
        },
      });
    } else if (title === `Information`) {
      // do nothing
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {isCreateClient && <CreateClientModal countries={countries} onCloseHandler={() => setIsCreateClient(false)} />}

          {isPlatformsTableShow && (
            <>
              <BreadCrumb title="Platform" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Platform Management`]} />
              <PlatformsList programs={platformsList?.results} createNew={createPlatformBtnHandler} />
            </>
          )}

          {isCreatePlatformShow && (
            <>
              <BreadCrumb title="Platform" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Platform Management`, `Create`]} />
              <PlatformConfiguration createClientAction={() => setIsCreateClient(true)} />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default Platform;
