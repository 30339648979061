import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Table } from 'reactstrap';

const PartnerPoints = ({ data }) => {
  const [benefitClaims, setBenefitClaims] = useState([]);

  useEffect(() => {
    setBenefitClaims(
      data?.bookingReport?.benefitClaimCount?.map(d => ({
        name: d?.entity?.name,
        count: d?.count ?? 0,
      })) || [],
    );
  }, [data?.bookingReport?.benefitClaimCount]);

  const grandTotal = benefitClaims.reduce((total, claim) => total + claim.count, 0);

  return (
    <Col xl={6} md={6} className="mb-4">
      <Card>
        <CardHeader>
          <h4 className="mb-0">Partner Points Redemption</h4>
        </CardHeader>
        <CardBody style={{ maxHeight: '300px', overflowY: 'scroll', position: 'relative', paddingBottom: '60px' }}>
          <Table striped bordered hover responsive style={{ marginBottom: '0' }}>
            <thead className="table-light">
              <tr>
                <th className="text-start">Partner</th>
                <th className="text-start">Counts</th>
              </tr>
            </thead>
            <tbody>
              {benefitClaims?.length > 0 ? (
                benefitClaims?.map((claim, index) => (
                  <tr key={index}>
                    <td>{claim.name}</td>
                    <th scope="row">{claim.count}</th>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2" className="text-center">
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
        {benefitClaims?.length > 0 && (
          <div style={{ position: 'absolute', bottom: '0', width: '100%', background: 'white' }}>
            <Table striped bordered hover responsive style={{ marginBottom: '0' }}>
              <tfoot className="table-light">
                <tr>
                  <th>Grand Total</th>
                  <th>{grandTotal}</th>
                </tr>
              </tfoot>
            </Table>
          </div>
        )}
      </Card>
    </Col>
  );
};

export default PartnerPoints;
