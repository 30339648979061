import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'reactstrap';

import Table from './Components/Tables/Table';
import { Constant } from '../../../../../Helpers/constant';
import { BreadCrumb, Loader } from './../../../../../Common';
import CreateAnnouncement from './Components/Modals/CreateAnnouncement';
import { GET_ANNOUNCEMENTS, POST_ANNOUNCEMENTS_EMPTY } from '../../../../../../store/application/actionType';

const Announcements = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  const [currentSelectedDetails, setCurrentSelectedDetails] = useState(null);
  const [isShowCreateAnnouncementModal, setIsShowCreateAnnouncementModal] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // GET ANNOUNCEMENT
  const { announcementsList, getAnnouncementsListLoading } = useSelector(state => ({
    announcementsList: state?.GetAnnouncements?.data,
    getAnnouncementsListLoading: state?.GetAnnouncements?.loading,
  }));

  // CREATE ANNOUNCEMENT
  const { createAnnouncement, createAnnouncementLoading } = useSelector(state => ({
    createAnnouncement: state?.CreateAnnoucement?.data,
    createAnnouncementLoading: state?.CreateAnnoucement?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_ANNOUNCEMENTS,
      payload: {
        urlParam: {
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (createAnnouncement != null) {
      setCurrentPageNumber(0);

      dispatch({
        type: POST_ANNOUNCEMENTS_EMPTY,
      });

      dispatch({
        type: GET_ANNOUNCEMENTS,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          },
        },
      });
    }
  }, [createAnnouncement]);

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);

    dispatch({
      type: GET_ANNOUNCEMENTS,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push('/client/dashboard');
    } else if (title === `Announcements`) {
      // nothing to do here
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (createAnnouncementLoading || getAnnouncementsListLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [createAnnouncementLoading, getAnnouncementsListLoading]);

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        {/*  ----------------------------  MODAL'S ---------------------------- */}
        {isShowCreateAnnouncementModal && (
          <CreateAnnouncement
            data={currentSelectedDetails}
            onCloseHandler={() => setIsShowCreateAnnouncementModal(false)}
            setData={setCurrentSelectedDetails}
          />
        )}

        {/*  ----------------------------  UI'S ---------------------------- */}
        <Container fluid className="position-relative">
          <>
            <BreadCrumb title="Announcements" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Announcements']} />
            <Table
              title={`Announcements`}
              data={announcementsList?.data?.data ?? []}
              totalData={announcementsList?.data?.count}
              pageHandler={paginationHandler}
              pageNumber={currentPageNumber}
              setIsShowCreateAnnouncementModal={() => {
                setIsShowCreateAnnouncementModal(true);
              }}
            />
          </>
        </Container>
      </div>
    </>
  );
};

export default Announcements;
