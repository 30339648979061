import { GET_FILE_SUMMARY, GET_FILE_SUMMARY_SUCCESS, GET_FILE_SUMMARY_ERROR, GET_FILE_SUMMARY_EMPTY } from './../../actionType';

const initialState = {
  data: null,
  message: null,
  loading: false,
  success: false,
  error: false,
};

const GetFileSummary = (state = initialState, action) => {
  switch (action.type) {
    case GET_FILE_SUMMARY:
      state = {
        ...state,
        // data: null,
        loading: true,
        success: false,
        error: false,
        message: null,
      };
      break;

    case GET_FILE_SUMMARY_SUCCESS:
      state = {
        ...state,
        data: action?.payload,
        loading: false,
        success: true,
        error: false,
        message: null,
      };
      break;

    case GET_FILE_SUMMARY_ERROR:
      state = {
        ...state,
        data: null,
        loading: false,
        success: false,
        error: true,
        message: action?.payload,
      };
      break;

    case GET_FILE_SUMMARY_EMPTY:
      state = {
        ...initialState,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default GetFileSummary;
