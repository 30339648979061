import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert } from 'reactstrap';
import ParticlesAuth from '../../../../../pages/AuthenticationInner/ParticlesAuth';

//redux
import { useSelector, useDispatch } from 'react-redux';

import { withRouter, Link } from 'react-router-dom';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useLocation, useHistory } from 'react-router-dom';

import ThriweWhite from '../../../../../assets/custom/thriwe-white-logo.png';
import PasswordCreatedSuccessfulImg from '../../../../../assets/custom/password-successful.png';

const PasswordCreatedSuccessful = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const { user } = useSelector(state => ({
  //   user: state.Account.user,
  // }));

  const [userLogin, setUserLogin] = useState([]);

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <p className="mt-3 fs-15 fw-medium">&nbsp;</p>
                    <Link to="/client/login" className="d-inline-block auth-logo">
                      <img src={ThriweWhite} alt="" height="50" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4" style={{ borderRadius: '40px' }}>
                  <CardBody className="p-md-5 p-sm-3">
                    <div className="text-center mt-2">
                      <h2 className="text-dark">Password Changed</h2>
                    </div>
                    <div className="text-center">
                      <img src={PasswordCreatedSuccessfulImg} className="img-fluid" style={{ width: '50%' }} />
                    </div>
                    <div className="p-2 mb-3">
                      <div className="text-center fs-5">Your password has been changed Successfully.</div>
                    </div>
                    <div className="mt-4">
                      <Button
                        color="success"
                        className="btn btn-success w-100"
                        // type="submit"
                        onClick={() => history.push(`/client/login`)}
                      >
                        <i className="ri-arrow-left-line label-icon align-middle fs-16 ms-2"></i>
                        &nbsp; Back to Login
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(PasswordCreatedSuccessful);
