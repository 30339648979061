import React, { useState } from 'react';
import Select from 'react-select';
import Navigator from './Components/Navigator';
import ThriweNameLogo from './../../../../../assets/custom/Thriwe-name-logo.svg';
import QRPage from '../QR';
import i18n from '../../../../../i18n';
import { useTranslation } from 'react-i18next';
const options = [
  { value: 'Chocolate', label: 'Chocolate' },
  { value: 'Strawberry', label: 'Strawberry' },
  { value: 'Banana', label: 'banana' },
  { value: 'Orange', label: 'Orange' },
];
const VendorValetParkingDashboard = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page-content">
        <div className="text-center">
          <div className="logo-md">
            <img src={ThriweNameLogo} className="img-fluid" alt="" />
          </div>
          <h3 className="mt-2">{t('thriwe Valet Parking')}</h3>
        </div>
        <div className="my-5">
          <Navigator />
        </div>
      </div>
    </>
  );
};

export default VendorValetParkingDashboard;
