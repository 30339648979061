import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Modal, ModalBody, ModalHeader, Col, Label, Input, Row } from 'reactstrap';
import { UPLOAD_FILE } from './../../../../../store/actions';
import Select from 'react-select';
import { Error } from '../../../../Common';
import { Constant } from '../../../../Helpers/constant';

const fileTypes = [
  { label: `Transaction`, value: `1` },
  { label: `Customer`, value: `2` },
];

const UploadFile = ({ onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    file: '',
    type: '',
  };
  const [state, setState] = useState(initialObj);
  const [isValidationShow, setIsValidationShow] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'file':
        updatedState = { ...state, file: value, fileData: e.target.files[0] };
        break;

      default:
      // do nothing
    }
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...state };

    if (label === `type`) {
      updatedState = {
        ...state,
        type: data,
      };
    }
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data?.file !== `` && data?.type !== `` && [...(Constant?.EXCEL_FILE_FORMATS ?? [])]?.includes(state?.file.split('.')?.pop())) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      const formData = new FormData();
      formData.append('file', state?.fileData);
      formData.append('type', state?.type?.value);

      dispatch({
        type: UPLOAD_FILE,
        payload: {
          data: formData,
        },
      });

      onCloseHandler();
    }
  };

  return (
    <Modal
      isOpen={true}
      toggle={() => {
        onCloseHandler();
      }}
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Upload File
        </h5>
        <Button
          type="button"
          onClick={() => {
            onCloseHandler();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log(state)}>Get state</button> */}
        <form>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="type" className="form-label" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>
                    File Type<span className="lbl_mandatory">*</span>
                  </span>
                </Label>
                <Select id="type" name="type" options={fileTypes ?? []} value={state?.type} onChange={data => inputSelectHandler(`type`, data)} />
                {isValidationShow && state?.type === `` && <Error text="Please select file type" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="formatExcel" className="form-label" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>
                    Upload File<span className="lbl_mandatory">*</span>
                  </span>
                </Label>
                <Input type="file" name="file" id="file" value={state?.file} onChange={inputChangeHandler} className="form-control" />
                {isValidationShow && state?.file === `` && <Error text="Please upload file" />}
                {isValidationShow && state?.file !== `` && ![...(Constant?.EXCEL_FILE_FORMATS ?? [])]?.includes(state?.file.split('.')?.pop()) && (
                  <Error text="Please upload proper file format" />
                )}
              </div>
            </Col>
          </Row>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button
          className="overlay-disable"
          color="primary"
          onClick={submitHandler}
          // disabled={isSubmitBtnDisabled}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default React.memo(UploadFile);
