import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Flatpickr from 'react-flatpickr';
import * as moment from 'moment';

import { Card, CardBody, Col, Form, Label, Row, CardFooter } from 'reactstrap';
import { PreviewCardHeader } from '../../../../Common';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Constant } from '../../../../Helpers/constant';
import { baseUrl, customHeaders } from '../../../../Config';
import axios from 'axios';

const CustomersFilter = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    benefitGroups: null,
    dateRange: {
      from: undefined,
      to: undefined,
    },
  };
  const [filter, setFilter] = useState(initialState);
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);
  const [isResetDisabled, setIsResetDisabled] = useState(true);

  useEffect(() => {
    if (props?.currentlyAppliedFilters !== null) {
      setFilter({
        benefitGroups: props?.appliedFilters?.benefitGroups,
        dateRange: {
          from: props?.appliedFilters?.dateRange?.from,
          to: props?.appliedFilters?.dateRange?.to,
        },
      });
    }
  }, [props]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'dateRange':
        updatedState = {
          ...filter,
          dateRange: {
            from: moment(new Date(date[0])).format('Y-MM-DD'),
            to: moment(new Date(date[1])).format('Y-MM-DD'),
          },
        };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- TYPED SERVICE HANDLER ---------------------------- */
  async function loadBenefitGroup(search, loadedOptions) {
    return await axios({
      method: `GET`,
      url: `${baseUrl}/admin/benefitGroups`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        name: search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data.objectId,
              label: `${data.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  /* ---------------------------- ON SELECT SERVICE ---------------------------- */
  const onSelectBenefitGroup = event => {
    const updatedState = {
      ...filter,
      benefitGroups: event,
    };
    setFilter(updatedState);
    validationCheck(updatedState);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data?.bookingDateRange?.from !== `` || data?.bookingDateRange?.to !== ``) {
      setIsResetDisabled(false);
    } else {
      setIsResetDisabled(true);
    }
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    let filterValue = { ...filter };

    props.filterHandler({ ...filterValue, filter: true });
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter(initialState);
    setIsResetDisabled(true);
    props.filterHandler({ ...initialState, filter: false });
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Filter" />
            {/* <button onClick={() => console.log(filter)}>get state</button> */}
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="created-at-range" className="form-label">
                          Benefit Group
                        </Label>
                        <AsyncPaginate
                          debounceTimeout={500}
                          value={filter?.benefitGroups}
                          loadOptions={loadBenefitGroup}
                          onChange={e => onSelectBenefitGroup(e)}
                          isMulti={false}
                          closeMenuOnSelect={true}
                          noOptionsMessage={() => 'No results found'}
                          cacheUniqs={[['code']]}
                          placeholder="Select..."
                          //   isDisabled={isServiceDisabled}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="created-at-range" className="form-label">
                          Uploaded At - Range
                          <span className="icon_calendar">
                            <i className="las la-calendar"></i>
                          </span>
                        </Label>
                        <div className="area_expandable">
                          <Flatpickr
                            className="form-control"
                            onChange={date => datePickHandler(`dateRange`, date)}
                            value={[filter?.dateRange?.from, filter?.dateRange?.to]}
                            options={{
                              mode: 'range',
                              dateFormat: 'Y-m-d',
                              maxDate: 'today',
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>

            <CardFooter>
              <Row>
                <Col md={12}>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary mx-3 overlay-disable" onClick={filterSubmitHandler} disabled={isFilterDisabled}>
                      Search
                    </button>

                    <button type="submit" className="btn btn-primary overlay-disable" onClick={resetFilterHandler} disabled={isResetDisabled}>
                      Reset Filter
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CustomersFilter;
