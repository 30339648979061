import { useEffect, useState } from 'react';

import moment from 'moment';
import DataTable from 'react-data-table-component';

import { Constant } from '../../../../../../../../Helpers/constant';
import { hasPermission } from '../../../../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../../../../Helpers/Constants/permissions';

const TrackerTable = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No',
          width: '70px',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
        },
        {
          name: 'Batch No.',
          width: '240px',
          wrap: true,
          cell: row => (
            <span
              className={
                hasPermission(PermissionConstants?.ALL_PERMISSIONS?.VIEW_RECORD_BOOKING_RECON_GM_REPORT) &&
                'text-decoration-underline text-primary fw-semibold cursor-pointer'
              }
              onClick={() => {
                if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.VIEW_RECORD_BOOKING_RECON_GM_REPORT)) {
                  actionBtnHandler(`VIEW`, row);
                }
              }}
            >
              {row.batchNumber}
            </span>
          ),
        },
        {
          name: 'Type',
          width: '150px',
          wrap: true,
          selector: row => row?.fileConfigName,
        },
        {
          name: 'File name',
          width: '200px',
          wrap: true,
          selector: row => row?.fileName,
        },
        {
          name: 'Total records',
          width: '120px',
          wrap: true,
          selector: row => row?.totalUploadedRecords,
        },
        {
          name: 'Uploaded by',
          width: '150px',
          wrap: true,
          selector: row => row?.uploadBy,
        },
        {
          name: 'Date/Time',
          width: '180px',
          wrap: true,
          selector: row => row?.createdAt,
        },
        {
          name: 'Type',
          width: '140px',
          wrap: true,
          selector: row => row?.fileType,
        },
        {
          name: 'Status',
          width: '180px',
          wrap: true,
          selector: row => row?.status,
        },
        {
          name: 'Pending action',
          width: '250px',
          wrap: true,
          selector: row => row?.pendingAction,
        },
        {
          name: 'Action',
          width: '150px',
          cell: row => (
            <div className="d-flex justify-content-center flex-column w-100">
              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.VIEW_RECORD_BOOKING_RECON_GM_REPORT) && (
                <span
                  className="btn btn-sm btn-primary mb-1 w-100"
                  onClick={() => {
                    actionBtnHandler(`VIEW`, row);
                  }}
                >
                  View
                </span>
              )}
            </div>
          ),
        },
      ];
      setColumns(fetchColumns);

      const data = props?.data?.map((trackerData, index) => ({
        serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
        batchNumber: trackerData?.batchNumber,
        fileConfigName: trackerData.fileConfigName,
        fileName: trackerData.fileName,
        totalUploadedRecords: trackerData?.uploadBy?.totalUploadedRecords,
        uploadBy: trackerData?.uploadBy?.name,
        createdAt: moment(new Date(`${trackerData?.createdAt}`)).format('DD-MM-YYYY, h:mm A'),
        fileType: trackerData?.fileType,
        status: trackerData?.status?.message,
        pendingAction: trackerData?.pendingAction,
        originalData: trackerData,
      }));
      setData(data);
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `VIEW`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    }
  };

  return (
    <div>
      <DataTable
        columns={columns ?? []}
        data={data ?? []}
        highlightOnHover
        customStyles={{
          headRow: {
            style: {
              borderBottom: '1px solid #ddd',
              backgroundColor: '#f9fafb',
              fontWeight: '600',
            },
          },
          cells: {
            style: {
              border: '0.5px solid #ddd',
              borderBottom: '0px solid #ddd',
            },
          },
        }}
      />
    </div>
  );
};

export default TrackerTable;
