import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Table from './components/Tables/Table';
import { BreadCrumb, Loader } from '../../../../Common';
import { Constant } from '../../../../Helpers/constant';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { GET_THRIWE_PRIME_BOOKING_LIST } from '../../../../../store/application/actionType';

const ThriwePrimeBooking = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Thriwe Prime Booking
  const { thriwePrimeBooking, thriwePrimeBookingLoading } = useSelector(state => ({
    thriwePrimeBooking: state?.ThriwePrimeBookingList?.data,
    thriwePrimeBookingLoading: state?.ThriwePrimeBookingList?.loading,
  }));

  useEffect(() => {
    dispatch({
      type: GET_THRIWE_PRIME_BOOKING_LIST,
      payload: {
        urlParam: {
          pageIndex: currentPageIndex,
          pageSize: Constant?.TABLE_PAGE_SIZE,
        },
      },
    });
  }, []);

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {};

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);
    dispatch({
      type: GET_THRIWE_PRIME_BOOKING_LIST,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  return (
    <>
      {thriwePrimeBookingLoading && <Loader />}
      <div className="page-content">
        <BreadCrumb title="Thriwe Prime Booking" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Thriwe Prime Booking`]} />
        <Table
          data={thriwePrimeBooking?.results}
          totalData={thriwePrimeBooking?.count}
          pageNumber={currentPageNumber}
          pageHandler={paginationHandler}
          actionHandler={tableActionHandler}
          appliedFilters={currentlyAppliedFilters}
        />
      </div>
    </>
  );
};

export default ThriwePrimeBooking;
