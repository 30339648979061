import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardBody, Col, Container, Input, Label, Row, Table } from 'reactstrap';
import { PreviewCardHeader } from '../../../../../../Common';
import { Link } from 'react-router-dom';
import { GET_GOLF_COURSE_FORMATS_BY_ID } from '../../../../../../../store/application/actionType';

const ViewCourseFormat = props => {
  const dispatch = useDispatch();

  const [sortedData, setSortedData] = useState(null);

  useEffect(() => {
    if (props?.data?.golfCourseFormat?.slotsData !== null) {
      let timings = [];

      props?.data?.golfCourseFormat?.slotsData?.forEach(slots => {
        slots?.slots?.forEach(slot => {
          timings.push(slot.time);
        });
      });

      timings = [...new Set(timings)].sort();

      let days = [];
      timings?.forEach(time => {
        let row = [{ time, day: 'timing' }];
        props?.data?.golfCourseFormat?.slotsData?.map(slots => {
          if (slots.day === 'Monday') {
            const tempSlot = slots?.slots?.filter(slot => slot.time === time);
            if (tempSlot.length > 0) {
              row.push({ ...tempSlot[0], day: slots.day });
            } else {
              row.push({ time, slotCount: `NA`, day: slots.day });
            }
          } else if (slots.day === 'Tuesday') {
            const tempSlot = slots?.slots?.filter(slot => slot.time === time);
            if (tempSlot.length > 0) {
              row.push({ ...tempSlot[0], day: slots.day });
            } else {
              row.push({ time, slotCount: `NA`, day: slots.day });
            }
          } else if (slots.day === 'Wednesday') {
            const tempSlot = slots?.slots?.filter(slot => slot.time === time);
            if (tempSlot.length > 0) {
              row.push({ ...tempSlot[0], day: slots.day });
            } else {
              row.push({ time, slotCount: `NA`, day: slots.day });
            }
          } else if (slots.day === 'Thursday') {
            const tempSlot = slots?.slots?.filter(slot => slot.time === time);
            if (tempSlot.length > 0) {
              row.push({ ...tempSlot[0], day: slots.day });
            } else {
              row.push({ time, slotCount: `NA`, day: slots.day });
            }
          } else if (slots.day === 'Friday') {
            const tempSlot = slots?.slots?.filter(slot => slot.time === time);
            if (tempSlot.length > 0) {
              row.push({ ...tempSlot[0], day: slots.day });
            } else {
              row.push({ time, slotCount: `NA`, day: slots.day });
            }
          } else if (slots.day === 'Saturday') {
            const tempSlot = slots?.slots?.filter(slot => slot.time === time);
            if (tempSlot.length > 0) {
              row.push({ ...tempSlot[0], day: slots.day });
            } else {
              row.push({ time, slotCount: `NA`, day: slots.day });
            }
          } else if (slots.day === 'Sunday') {
            const tempSlot = slots?.slots?.filter(slot => slot.time === time);
            if (tempSlot.length > 0) {
              row.push({ ...tempSlot[0], day: slots.day });
            } else {
              row.push({ time, slotCount: `NA`, day: slots.day });
            }
          }
        });
        days.push(row);
      });

      const d = days.map(dayData => {
        return [
          dayData.filter(dayData => dayData.day === 'timing')[0],
          dayData.filter(dayData => dayData.day === 'Monday')[0],
          dayData.filter(dayData => dayData.day === 'Tuesday')[0],
          dayData.filter(dayData => dayData.day === 'Wednesday')[0],
          dayData.filter(dayData => dayData.day === 'Thursday')[0],
          dayData.filter(dayData => dayData.day === 'Friday')[0],
          dayData.filter(dayData => dayData.day === 'Saturday')[0],
          dayData.filter(dayData => dayData.day === 'Sunday')[0],
        ];
      });

      setSortedData(d);
    }
  }, [props]);

  return (
    <Col md={12}>
      <Card>
        <PreviewCardHeader title={props?.data?.golfCourseFormat?.name} />
        <CardBody>
          <div className="live-preview">
            <div className="table-responsive">
              <Table className="table-bordered  table-striped table-nowrap align-middle table-nowrap mb-0 text-center">
                <thead>
                  <tr>
                    <th scope="col">Time</th>
                    <th scope="col">Monday</th>
                    <th scope="col">Tuesday</th>
                    <th scope="col">Wednesday</th>
                    <th scope="col">Thrusday</th>
                    <th scope="col">Friday</th>
                    <th scope="col">Saturday</th>
                    <th scope="col">Sunday</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData?.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{data[0]?.time}</td>
                        <td>{data[1]?.slotCount}</td>
                        <td>{data[2]?.slotCount}</td>
                        <td>{data[3]?.slotCount}</td>
                        <td>{data[4]?.slotCount}</td>
                        <td>{data[5]?.slotCount}</td>
                        <td>{data[6]?.slotCount}</td>
                        <td>{data[7]?.slotCount}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ViewCourseFormat;
