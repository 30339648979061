import { useState, useEffect } from 'react';

import moment from 'moment';
import { Button, Modal, ModalBody } from 'reactstrap';
import DataTable from 'react-data-table-component';

const LogsModal = ({ data, onCloseHandler }) => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [modal_varying1, setmodal_varying1] = useState(true);
  const [logsData, setLogsData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (data?.logs?.length > 0) {
      const fetchColums = [
        {
          name: 'S.No',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
          width: '70px',
        },
        {
          name: 'Name',
          selector: row => row?.displayName,
          wrap: true,
          minWidth: '300px',
        },
        {
          name: 'Logged At',
          width: '250px',
          selector: row => row?.loggedAt,
        },
      ];
      setColumns(fetchColums);
      setLogsData(
        data?.logs?.map((log, index) => {
          return {
            serialNumber: `${index + 1}.`,
            displayName: log?.name || `NA`,
            loggedAt: log?.loggedAt ? moment(`${log?.loggedAt}`).format('DD-MM-YYYY, h:mm A') : `NA`,
          };
        }),
      );
    } else {
      setLogsData([]);
    }
  }, [data?.logs]);

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <>
      <Modal
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1();
        }}
        id="logsModal"
        size="lg"
        centered
      >
        <div className="modal-header p-3 bg-soft-secondary">
          <h5 className="modal-title" id="createboardModalLabel">
            Logs
          </h5>
          <Button
            type="button"
            onClick={() => {
              tog_varying1();
            }}
            id="addBoardBtn-close"
            className="btn-close btn-light"
            aria-label="Close"
          ></Button>
        </div>

        <ModalBody>
          <DataTable
            columns={columns ?? []}
            data={logsData ?? []}
            highlightOnHover
            customStyles={{
              headRow: {
                style: {
                  borderBottom: '1px solid #ddd',
                  backgroundColor: '#f9fafb',
                  fontWeight: '600',
                },
              },
              cells: {
                style: {
                  border: '0.5px solid #ddd',
                  borderBottom: '0px solid #ddd',
                },
              },
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default LogsModal;
