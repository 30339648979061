import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Flatpickr from 'react-flatpickr';

import { Button, Modal, ModalBody, ModalHeader, Col, Label, Input, Row } from 'reactstrap';
import { UPDATE_COUPON } from '../../../../../../store/actions';
import { Error } from '../../../../../Common';
import moment from 'moment';

const ChangeCouponExpiry = ({ data, couponLot, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    expiryDate: ``,
  };
  const [state, setState] = useState(initialObj);
  const [modal_varying1, setmodal_varying1] = useState(true);
  const [isValidationShow, setIsValidationShow] = useState(false);

  useEffect(() => {
    const stateData = {
      expiryDate: data?.expiryDate.replace('00Z', '00.000Z'),
    };
    setState(stateData);
  }, [data]);

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'expiryDate':
        updatedState = {
          ...state,
          expiryDate: new Date(date).toISOString(),
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data?.expiryDate !== `` && moment() <= moment(data?.expiryDate)) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      dispatch({
        type: UPDATE_COUPON,
        payload: {
          data: {
            ...state,
            couponIds: [data?.id],
            updateAll: false,
            couponInfoId: couponLot?.id,
          },
        },
      });

      onCloseHandler(false);
      setmodal_varying1(!modal_varying1);
    }
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Change Expiry
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log(state)}>Get state</button> */}
        <form>
          <Row>
            <div className="mb-3">
              <Col md={12}>
                <div className="mb-3">
                  <Label htmlFor="expiryDate" className="form-label">
                    Expiry Date
                  </Label>
                  <div className="area_expandable">
                    <Flatpickr
                      className="form-control"
                      name="expiryDate"
                      id="expiryDate"
                      value={state?.expiryDate}
                      onChange={date => datePickHandler(`expiryDate`, date)}
                      options={{
                        // minDate: 'today',
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',
                      }}
                    />
                    {isValidationShow && state?.expiryDate === `` && <Error text="Please enter expiry date" />}
                    {isValidationShow && state?.expiryDate !== `` && moment() > moment(state?.expiryDate) && <Error text="Invalid Date" />}
                  </div>
                </div>
              </Col>
            </div>
          </Row>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default ChangeCouponExpiry;
