import { useEffect } from 'react';

import moment from 'moment';
import { Card, CardBody, Col, Row, Container, Label, CardHeader } from 'reactstrap';

const BookingDetails = ({ details }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <Card className="mt-n4 mx-n4 mb-n5">
            <div className="bg-soft-warning">
              <CardBody className="pb-4 mb-5">
                <Row>
                  <div className="col-md">
                    <Row className="align-items-center">
                      <div className="col-md-auto mb-1 mt-4"></div>
                      <div className="col-md">
                        <h4 className="fw-semibold" id="ticket-title">
                          {details?.bookingId || ''}
                        </h4>
                      </div>
                    </Row>
                  </div>
                </Row>
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Personal Details</h6>
              <div className="border mt-2 mb-3 border"></div>
              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Full Name'}</Label>
                    <Label className="text-muted">{details?.user?.name || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">User Contact Details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Mobile No.'}</Label>
                    <Label className="text-muted">{details?.user?.mobile || `NA`}</Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Email'}</Label>
                    <Label className="text-muted">{details?.user?.email || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Booking</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Booking Date'}</Label>
                    <Label className="text-muted">{moment(`${new Date(`${details?.bookingDateTime}`)}`).format('DD-MM-YYYY, h:mm A') || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row> */}

      {details?.dynamicFields?.length > 0 && (
        <Row>
          <Col xxl={12}>
            <Card>
              <CardBody className="p-4">
                <h6 className="fw-semibold text-uppercase mb-3">Dynamic Booking Data</h6>
                <div className="border mt-2 mb-3 border"></div>

                <Row>
                  {details?.dynamicFields?.map((dynamicField, index) => (
                    <Col md={4} key={index}>
                      <div className="mb-3">
                        <Label className="d-flex">{`${dynamicField?.headerName}`}</Label>
                        <Label className="text-muted">{dynamicField?.value || `NA`}</Label>
                      </div>
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

      {/* ------------------------- PAYMENT SUMMARY ------------------------- */}
      {details?.paymentSummary?.totalPayable?.value !== 0 && (
        <Row>
          <Col xxl={12}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0 flex-grow-1">PAYMENT SUMMARY</h4>
              </CardHeader>

              <CardBody className="m-4 mb-0 bg-light">
                <h6 className="fw-semibold text-uppercase mb-3">Total Payable</h6>
                <div className="border mt-2 mb-3 border"></div>

                <Row>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="d-flex">{details?.paymentSummary?.totalPayable?.name || ``}</Label>
                      <Label className="text-muted">
                        {`${details?.paymentSummary?.totalPayable?.value}` || `NA`}({details?.paymentSummary?.totalPayable?.currency || `NA`})
                      </Label>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="d-flex">{'Payment Status'}</Label>
                      <Label className="text-muted">{details?.payment?.status || `NA`}</Label>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="d-flex">{'Payment Id'}</Label>
                      <Label className="text-muted">{details?.payment?.paymentId || `NA`}</Label>
                    </div>
                  </Col>
                </Row>
              </CardBody>

              <CardBody className="m-4 mb-0 bg-light">
                <h6 className="fw-semibold text-uppercase mb-3">Booking Tax</h6>
                <div className="border mt-2 mb-3 border"></div>

                <Row>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="d-flex">{details?.paymentSummary?.bookingTax?.name || ``}</Label>
                      <Label className="text-muted">
                        {`${details?.paymentSummary?.bookingTax?.value}` || `NA`}({details?.paymentSummary?.bookingTax?.currency || `NA`})
                      </Label>
                    </div>
                  </Col>
                </Row>
              </CardBody>

              <CardBody className="m-4 mb-0 bg-light">
                <h6 className="fw-semibold text-uppercase mb-3">Booking Discount</h6>
                <div className="border mt-2 mb-3 border"></div>

                <Row>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="d-flex">{details?.paymentSummary?.bookingDiscount?.name || ``}</Label>
                      <Label className="text-muted">
                        {`${details?.paymentSummary?.bookingDiscount?.value}` || `NA`}({details?.paymentSummary?.bookingDiscount?.currency || `NA`})
                      </Label>
                    </div>
                  </Col>
                </Row>
              </CardBody>

              <CardBody className="m-4 mb-0 bg-light">
                <h6 className="fw-semibold text-uppercase mb-3">Booking Fee</h6>
                <div className="border mt-2 mb-3 border"></div>

                <Row>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="d-flex">{details?.paymentSummary?.bookingFee?.name || ``}</Label>
                      <Label className="text-muted">
                        {`${details?.paymentSummary?.bookingFee?.value}` || `NA`}({details?.paymentSummary?.bookingFee?.currency || `NA`})
                      </Label>
                    </div>
                  </Col>
                </Row>
              </CardBody>

              <CardBody className="m-4 mb-0 bg-light">
                <h6 className="fw-semibold text-uppercase mb-3">Discounts</h6>
                <div className="border mt-2 mb-3 border"></div>

                {details?.paymentSummary?.discounts?.map((discount, index) => {
                  return (
                    <Row key={index}>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label className="d-flex">{'Amount'}</Label>
                          <Label className="text-muted">
                            {`${discount?.amount}` || `NA`}({discount?.currency || `NA`})
                          </Label>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-3">
                          <Label className="d-flex">{discount?.name || ``}</Label>
                          <Label className="text-muted">{`${discount?.value}%` || `NA`}</Label>
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              </CardBody>

              <CardBody className="m-4 mb-0 bg-light">
                <h6 className="fw-semibold text-uppercase mb-3"> Fees</h6>
                <div className="border mt-2 mb-3 border"></div>

                <Row>
                  {details?.paymentSummary?.fees?.map((fee, index) => {
                    return (
                      <Col key={index} md={4}>
                        <div className="mb-3">
                          <Label className="d-flex">{fee?.name || ``}</Label>
                          <Label className="text-muted">
                            {`${fee?.value}` || `NA`}({fee?.currency || `NA`})
                          </Label>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </CardBody>

              <CardBody className="m-4 bg-light">
                <h6 className="fw-semibold text-uppercase mb-3"> Taxes</h6>
                <div className="border mt-2 mb-3 border"></div>

                {details?.paymentSummary?.taxes?.map((tax, index) => {
                  return (
                    <Row key={index}>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label className="d-flex">{tax?.name || ``}</Label>
                          <Label className="text-muted">{`${tax?.value}%` || `NA`}</Label>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-3">
                          <Label className="d-flex">{`Amount`}</Label>
                          <Label className="text-muted">
                            {`${tax?.amount}` || `NA`}({tax?.currency || `NA`})
                          </Label>
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default BookingDetails;
