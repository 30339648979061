import { useEffect, useState } from 'react';

import moment from 'moment';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';

import { TablePagination } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import { formattedWithComma } from '../../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';

const BenefitGroupsTable = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColums = [
        {
          name: 'S.No.',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
          width: '90px',
        },
        {
          name: 'System ID',
          selector: row => <span className="fw-medium">{row?.systemId}</span>,
          width: '150px',
        },
        {
          name: 'Benefit Group Name',
          selector: row => row?.name,
          wrap: true,
          width: '250px',
        },
        {
          name: 'Total Packs Released',
          selector: row => row?.totalPacksReleased,
          wrap: true,
          width: '180px',
        },
        {
          name: 'Total Membership Released',
          selector: row => row?.totalMembershipReleased,
          wrap: true,
          width: '220px',
        },
        {
          name: 'Default Lot',
          selector: row => row?.defaultLot,
          wrap: true,
          width: '120px',
        },
        {
          name: 'Is Active',
          selector: row => row?.isActive,
          minWidth: '120px',
          wrap: true,
        },
        {
          name: 'Created By',
          selector: row => row?.createdAt,
          minWidth: '200px',
          wrap: true,
        },
        {
          name: 'Last Updated',
          selector: row => row?.updatedAt,
          minWidth: '180px',
          wrap: true,
        },
        {
          name: 'Actions',
          width: '150px',
          cell: row => (
            <div className="d-flex justify-content-center p-2 flex-column w-100">
              {true && (
                <span
                  className="btn btn-sm btn-danger mb-1"
                  onClick={() => {
                    actionBtnHandler(`UPDATE`, row?.data);
                  }}
                >
                  <div className="">Update</div>
                </span>
              )}
            </div>
          ),
        },
      ];
      setColumns(fetchColums);
      setData(
        props?.data?.map((data, index) => {
          return {
            serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            systemId: data?.objectId || `NA`,
            name: data?.name || `NA`,
            totalPacksReleased: `${data?.totalPacksReleased}` || `NA`,
            totalMembershipReleased: `${data?.totalMembershipReleased}` || `NA`,
            defaultLot: `${data?.defaultLot}` || `NA`,
            isActive: data?.isActive ? 'YES' : 'No' || `NA`,

            createdAt: data?.createdAt ? moment(new Date(`${data?.createdAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            updatedAt: data?.updatedAt ? moment(new Date(`${data?.updatedAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            data,
          };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `UPDATE`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{`Benefit Groups`}</h4>

              {PermissionConstants?.ALL_PERMISSIONS?.BENEFIT_GROUP && (
                <span className="btn btn-success mb-1 mx-2" onClick={props?.addNewBenefitGroupBtnHandler}>
                  <i className="ri-add-line text-white fs-14 align-middle"></i> Create Benefit Group
                </span>
              )}

              <span className="btn btn-info mb-1" onClick={props?.filterBtnHandler}>
                <i className="ri-filter-3-line align-bottom me-1"></i>Filter
              </span>
            </CardHeader>

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of&nbsp;
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default BenefitGroupsTable;
