import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { GET_PROJECT_UTILITY, GET_PROJECT_UTILITY_SUCCESS, GET_PROJECT_UTILITY_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';

function* getProjectUtility({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/project-utilities`,
      method: 'GET',
      headers: {
        // ...customHeaders,...payload.headers
        'project-code': 'ADMIN_LANDMARK',
      },
      params: {
        limit: 1000,
      },
    });

    if (response.status === 200) {
      localStorage.setItem('projectUtility', JSON.stringify(response?.data));
      yield put({
        type: GET_PROJECT_UTILITY_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_PROJECT_UTILITY_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_PROJECT_UTILITY_ERROR, payload: error });
  }
}

export function* watchGetProjectUtility() {
  yield takeEvery(GET_PROJECT_UTILITY, getProjectUtility);
}

function* getProjectUtilitySaga() {
  yield all([fork(watchGetProjectUtility)]);
}

export default getProjectUtilitySaga;
