import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert } from 'reactstrap';
import ParticlesAuth from '../../../../../pages/AuthenticationInner/ParticlesAuth';

import { withRouter, Link } from 'react-router-dom';

import ThriweWhite from '../../../../../assets/custom/thriwe-white-logo.png';
import { Error } from '../../../../Common';
import { isValidEmail } from '../../../../Helpers/Helpers';

import { useTranslation } from 'react-i18next';

const ForgotPassword = ({ onSubmit }) => {
  const { t } = useTranslation();
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    email: '',
  };
  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'email':
        updatedState = {
          ...state,
          email: value,
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data?.email !== `` && isValidEmail(data?.email)) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      const payload = {
        ...state,
      };

      onSubmit({
        ...payload,
      });
    }
  };

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <p className="mt-3 fs-15 fw-medium">&nbsp;</p>
                    <Link to="/client/login" className="d-inline-block auth-logo">
                      <img src={ThriweWhite} alt="" height="50" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4" style={{ borderRadius: '40px' }}>
                  <CardBody className="p-md-5 p-sm-3">
                    {/* <button onClick={() => console.log(state)}>get state</button> */}
                    <div className="text-center mt-2">
                      <h2 className="text-primary">{t(`Forgot password`)}</h2>
                    </div>
                    <div className="p-2 mt-4 mb-3">
                      <div className="mb-3">
                        <Label htmlFor="email" className="form-label">
                          {t(`Email ID`)}
                        </Label>
                        <Input
                          id="email"
                          name="email"
                          type="text"
                          placeholder={t(`Enter Email ID`)}
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state?.email}
                        />
                        {isValidationShow && state?.email?.trim() === `` && <Error text="Email can't be empty" />}
                        {isValidationShow && state?.email?.trim() !== `` && !isValidEmail(state?.email) && (
                          <Error text={t(`Please enter valid email`)} />
                        )}
                      </div>

                      <div className="mt-4">
                        <Button color="success" className="btn btn-success w-100" type="submit" onClick={submitHandler}>
                          {t(`Continue`)}
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(ForgotPassword);
