import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row, CardBody, Input, Label, CardHeader, ButtonGroup } from 'reactstrap';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import * as moment from 'moment';
import { Error, PreviewCardHeader } from '../../../../../Common';
import { GET_ATTRIBUTES, GET_SURGE_EVENTS } from '../../../../../../store/application/actionType';

const ruleStatus = [
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' },
];

const ruleTriggers = [
  { value: `1`, label: `Transaction` },
  { value: `2`, label: `Event` },
  // { value: `3`, label: `Agg` },
];

const ruleOperators = [
  { value: '=', label: 'Equal to' },
  { value: '>', label: 'Greater than' },
  { value: '<', label: 'Less than' },
  { value: '<=', label: 'Less than or equal' },
  // { value: "", label: "Is Between" },
  // { value: "!=", label: "Not equal" },
  { value: '>=', label: 'Greater than or equal' },
];

const ruleOperatorForString = [
  { value: '=', label: 'Equal to' },
  { value: '!=', label: 'Not equal' },
];

const addEffect = [{ value: '0', label: 'Add Points' }];

const effectValues = [{ value: 0, label: 'Exact value' }];

const ruleRelatedPoints = [{ value: '0', label: 'Instant' }];

const addConditionStruct = {
  fieldValue: '',
  aggregatorType: '',
  operator: '',
  value: {
    type: '',
    fromDate: '',
    toDate: '',
    val: '',
  },
};

const CreateRuleForm = ({ campaigns, onSubmit, ruleData }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    campaignId: '',
    campaignName: '',
    name: '',
    displayName: '',
    description: '',
    ruleStartDate: '',
    ruleEndDate: '',
    isActive: { value: true, label: 'Active' },
    trigger: '',
    eventRuleName: '',
    isIndefiniteDate: false,
    effect: {
      action: '', // add effect
      rate: '', // value
      conversionRate: '',
      instantCoinAmount: '', // enter value
      coinExpiry: '', // Rule Related Points Expiry
      coinActivationType: '', //Rule Related Points Release Schedule
      coinActivationDate: '', // today's date
      delayedCoinAmount: 0,
      ceilingCoinAmount: 0,
      isIndefiniteDate: false,
    },
    conditions: [{ ...addConditionStruct }],
    conditionsBetween: [],
  };
  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Events List
  const { events, eventsLoading } = useSelector(state => ({
    events: state?.GetEvents?.data,
    eventsLoading: state?.GetEvents?.loading,
  }));

  // Attributes List
  const { attributes, attributesLoading } = useSelector(state => ({
    attributes: state?.GetAttributes?.data,
    attributesLoading: state?.GetAttributes?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (ruleData !== null) {
      dispatch({
        type: GET_SURGE_EVENTS,
        payload: {
          urlParam: {
            pageIndex: 0,
            getAll: 'true',
          },
        },
      });

      dispatch({
        type: GET_ATTRIBUTES,
        payload: {
          urlParam: {
            pageIndex: 0,
            getAll: 'true',
            filterQuery: {
              type: ruleData?.trigger === '1' ? '2' : '1',
            },
          },
        },
      });

      let tempCampaign = campaigns?.filter(campaign => campaign?.objectId === ruleData?.campaignId);
      tempCampaign = tempCampaign?.map(campaign => ({
        value: `${campaign?.objectId}`,
        label: `${campaign?.campaignName}`,
      }))?.[0];

      const tempConditions = [];
      const tempConditionsBetween = [];
      ruleData?.conditions?.forEach((condition, index) => {
        if (index % 2 === 0) {
          tempConditions.push({
            ...condition,
            operator: ruleOperators?.filter(operator => operator?.value === condition?.operator)?.[0] ?? '',
            fieldValue: {
              value: condition?.fieldObjectId,
              label: condition?.fieldValueLabel,
              inputType: condition?.fieldInputType,
            },
            value: {
              ...condition?.value,
            },

            // value: att?.inputKeyword,
            // label: att?.name,
            // inputType: att?.inputType,

            // fieldValue: condition?.fieldValue?.value,
            // fieldObjectId: condition?.fieldValue?.value,
            // fieldInputType: condition?.value?.type,
            // operator: condition?.operator?.value,
          });
        } else {
          tempConditionsBetween.push(condition);
        }
      });

      setState({
        objectId: ruleData?.objectId,
        name: ruleData?.name,
        displayName: ruleData?.displayName,
        campaignId: tempCampaign,
        campaignName: tempCampaign?.label,
        description: ruleData?.description,
        ruleStartDate: ruleData?.ruleStartDate,
        ruleEndDate: ruleData?.ruleEndDate,
        eventRuleName: {
          value: ruleData?.eventRuleName?.id,
          label: ruleData?.eventRuleName?.name,
        },
        isIndefiniteDate: ruleData?.isIndefiniteDate,
        isActive: ruleStatus?.filter(status => status?.value === ruleData?.isActive)?.[0],
        trigger: ruleTriggers?.filter(rTigger => rTigger?.value === ruleData?.trigger)?.[0],

        effect: {
          rate: ruleData?.effect?.rate,
          coinExpiry: ruleData?.effect?.coinExpiry,
          isIndefiniteDate: ruleData?.effect?.isIndefiniteDate,
          coinActivationDate: ruleData?.effect?.coinActivationDate,
          coinActivationType: ruleRelatedPoints?.filter(rule => rule?.value === ruleData?.effect?.coinActivationType)?.[0],
          instantCoinAmount: effectValues?.filter(data => data?.value === ruleData?.effect?.instantCoinAmount)?.[0],
          action: addEffect?.filter(data => data?.value === ruleData?.effect?.action)[0],
        },

        conditions: tempConditions,
        conditionsBetween: tempConditionsBetween,
      });
    }
  }, [ruleData]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'name':
        updatedState = {
          ...state,
          name: value,
        };
        break;

      case 'displayName':
        updatedState = {
          ...state,
          displayName: value,
        };
        break;

      case 'description':
        updatedState = {
          ...state,
          description: value,
        };
        break;

      case 'rate':
        updatedState = {
          ...state,
          effect: {
            ...state?.effect,
            rate: value,
          },
        };
        break;

      case 'effect-isIndefiniteDate':
        var tempDate = new Date(state?.ruleStartDate || new Date().toISOString());
        tempDate.setDate(tempDate.getDate() + parseInt(366 * 3));

        updatedState = {
          ...state,
          effect: {
            ...state?.effect,
            isIndefiniteDate: e.target.checked,
            coinExpiry: new Date(tempDate).toISOString(),
          },
        };
        break;

      case 'isIndefiniteDate':
        var myDate = new Date(state?.ruleStartDate || new Date().toISOString());
        myDate.setDate(myDate.getDate() + parseInt(366 * 3));

        updatedState = {
          ...state,
          isIndefiniteDate: e.target.checked,
          ruleEndDate: new Date(myDate).toISOString(),
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- DATE PICKR HANDLER ---------------------------- */
  const datePickHandler = (flag, date) => {
    let updatedState = { ...state };

    switch (flag) {
      case 'ruleStartDate':
        updatedState = {
          ...state,
          ruleStartDate: new Date(date).toISOString(),
        };
        break;

      case 'ruleEndDate':
        updatedState = {
          ...state,
          ruleEndDate: new Date(date).toISOString(),
        };
        break;

      case 'coinExpiry':
        updatedState = {
          ...state,
          effect: {
            ...state?.effect,
            coinExpiry: new Date(date).toISOString(),
          },
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...state };

    switch (label) {
      case 'isActive':
        updatedState = {
          ...state,
          isActive: data,
        };
        break;

      case 'campaignId':
        updatedState = {
          ...state,
          campaignId: data,
        };
        break;

      case 'trigger':
        updatedState = {
          ...state,
          trigger: data,
        };

        if (data?.value === '2') {
          dispatch({
            type: GET_SURGE_EVENTS,
            payload: {
              urlParam: {
                pageIndex: 0,
              },
            },
          });

          dispatch({
            type: GET_ATTRIBUTES,
            payload: {
              urlParam: {
                pageIndex: 0,
                filterQuery: {
                  type: data?.value === '1' ? '2' : '1',
                },
              },
            },
          });
        } else if (data?.value === '1') {
          dispatch({
            type: GET_ATTRIBUTES,
            payload: {
              urlParam: {
                pageIndex: 0,
                filterQuery: {
                  type: data?.value === '1' ? '2' : '1',
                },
              },
            },
          });
        }
        break;

      case 'eventRuleName':
        updatedState = {
          ...state,
          eventRuleName: data,
        };
        break;

      case 'action':
        updatedState = {
          ...state,
          effect: {
            ...state?.effect,
            action: data,
          },
        };
        break;

      case 'instantCoinAmount':
        updatedState = {
          ...state,
          effect: {
            ...state?.effect,
            instantCoinAmount: data,
          },
        };

        break;

      case 'coinActivationType':
        updatedState = {
          ...state,
          effect: {
            ...state?.effect,
            coinActivationType: data,
            coinActivationDate: new Date().toISOString(),
          },
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- CONDITIONS HANDLER ---------------------------- */
  const conditionsHandler = (flag, data, index) => {
    let updatedState = { ...state };
    let tempConditions = updatedState?.conditions;

    let selectedCondition = tempConditions[index];

    if (flag === 'fieldValue') {
      selectedCondition = {
        ...selectedCondition,
        fieldValue: data,
        operator: '',
        value: {
          ...selectedCondition?.value,
          type: data?.inputType,
        },
      };
    } else if (flag === 'operator') {
      selectedCondition = {
        ...selectedCondition,
        operator: data,
      };
    } else if (flag === 'value') {
      selectedCondition = {
        ...selectedCondition,
        value: {
          ...selectedCondition?.value,
          val: data,
        },
      };
    } else if (flag === `date`) {
      selectedCondition = {
        ...selectedCondition,
        value: {
          ...selectedCondition?.value,
          fromDate: moment(new Date(data[0])).format('Y-MM-DD'),
          toDate: moment(new Date(data[1])).format('Y-MM-DD'),
        },
      };
    }

    tempConditions.splice(index, 1, selectedCondition);
    updatedState = {
      ...updatedState,
      conditions: tempConditions,
    };

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- CONDITIONS BUTTON HANDLER ---------------------------- */
  const conditionBtnHandler = (flag, index) => {
    let updatedState = { ...state };
    let tempConditions = [...updatedState.conditions];

    let tempConditionsBtw = [...updatedState.conditionsBetween];
    tempConditionsBtw?.splice(index, 1, flag);

    if (index === tempConditions?.length - 1) {
      tempConditions = [...tempConditions, { ...addConditionStruct }];
    }

    updatedState = {
      ...updatedState,
      conditions: tempConditions,
      conditionsBetween: tempConditionsBtw,
    };
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- CONDITIONS DELETE HANDLER ---------------------------- */
  const conditionDeleteHandler = index => {
    let updatedState = { ...state };
    let tempConditions = [...updatedState.conditions];
    let tempConditionsBtw = [...updatedState.conditionsBetween];

    if (index === 1 && tempConditionsBtw?.length === 2) {
      tempConditionsBtw?.splice(index - 1, 2);
    } else {
      tempConditionsBtw?.splice(index - 1, 1);
    }

    tempConditions?.splice(index, 1);

    updatedState = {
      ...updatedState,
      conditions: tempConditions,
      conditionsBetween: tempConditionsBtw,
    };
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (
      data?.campaignId !== `` &&
      data?.name?.trim() !== `` &&
      data?.trigger !== `` &&
      data?.ruleStartDate?.trim() !== `` &&
      data?.ruleEndDate?.trim() !== `` &&
      new Date(data?.ruleStartDate?.trim()).getTime() < new Date(data?.ruleEndDate?.trim()).getTime() &&
      data?.effect?.action !== `` &&
      data?.effect?.rate !== `` &&
      data?.effect?.coinExpiry !== `` &&
      data?.effect?.coinActivationType !== ``
    ) {
      const validateConditions = state?.conditions
        ?.map(condition => {
          if (
            condition?.operator === `` ||
            condition?.fieldValue === `` ||
            (condition?.fieldValue?.inputType === `string` && condition?.value?.val?.trim() === ``) ||
            (condition?.fieldValue?.inputType === `int` && condition?.value?.val?.trim() === ``) |
              (condition?.fieldValue?.inputType === `date` && (condition?.value?.fromDate === `` || condition?.value?.toDate === ``))
          ) {
            return condition;
          } else {
            return null;
          }
        })
        ?.filter(Boolean);

      if (validateConditions?.length > 0) {
        setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
        return false;
      } else {
        setIsValidationShow(false);
        return true;
      }

      // setIsValidationShow(false);
      // return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      const conditionMaker = state?.conditions
        ?.map((condition, index) => {
          const tempCondition = {
            ...condition,
            fieldValue: condition?.fieldValue?.value,
            fieldValueLabel: condition?.fieldValue?.label,
            fieldObjectId: condition?.fieldValue?.value,
            fieldInputType: condition?.value?.type,
            operator: condition?.operator?.value,
            value: {
              ...condition?.value,
            },
          };
          return [tempCondition, state?.conditionsBetween[index]];
        })
        ?.flat(1)
        ?.filter(Boolean);

      const payload = {
        campaignId: state?.campaignId?.value ?? '',
        campaignName: state?.campaignId?.label ?? '',
        isActive: state?.isActive?.value,
        trigger: state?.trigger?.value,
        name: state?.name,
        displayName: state?.displayName,
        description: state?.description,
        ruleStartDate: state?.ruleStartDate,
        ruleEndDate: state?.ruleEndDate,

        eventRuleName: {
          id: state?.eventRuleName?.value ?? '',
          name: state?.eventRuleName?.label ?? '',
        },
        isIndefiniteDate: state?.isIndefiniteDate,
        objectId: state?.objectId ?? '',
        conditions: conditionMaker?.length > 0 ? [...conditionMaker] : [],

        effect: {
          action: state?.effect?.action?.value,
          instantCoinAmount: state?.effect?.instantCoinAmount?.value,
          coinExpiry: state?.effect?.coinExpiry,
          isIndefiniteDate: state?.effect?.isIndefiniteDate,
          rate: state?.effect?.rate,
          coinActivationType: state?.effect?.coinActivationType?.value,
          coinActivationDate: state?.effect?.coinActivationDate,
        },
      };

      // return;

      if (ruleData === null) {
        onSubmit(
          {
            ...payload,
          },
          'New',
        );
      } else {
        onSubmit(
          {
            ...payload,
          },
          `Update`,
        );
      }
    }
  };

  return (
    <>
      <Card className="card-inner">
        {/* <button onClick={() => console.log(state)}>get state</button> */}
        <CardHeader className="d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Campaign details</h4>
          {/* <button className="btn btn-outline-primary btn-ghost-primary">
                        + Create New Campaign
                    </button> */}
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm={6}>
              <div className="mb-3">
                <Label htmlFor="campaignId" className="form-label">
                  Campaign Name
                </Label>
                <Select
                  id="campaignId"
                  name="campaignId"
                  value={state?.campaignId}
                  options={campaigns?.map(campaign => ({
                    value: campaign?.objectId,
                    label: campaign?.campaignName,
                  }))}
                  onChange={data => inputSelectHandler(`campaignId`, data)}
                />
                {isValidationShow && state?.campaignId === `` && <Error text="Please select campaign" />}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="card-inner">
        <PreviewCardHeader title="Set Rule Configuration" />
        <CardBody>
          <Row>
            <Col sm={6}>
              <div className="mb-3">
                <Label htmlFor="name" className="form-label">
                  Rule Name
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Enter Rule name"
                  value={state?.name}
                  onChange={inputChangeHandler}
                />
                {isValidationShow && state?.name === `` && <Error text="Please enter rule name" />}
              </div>
            </Col>

            <Col sm={6}>
              <div className="mb-3">
                <Label htmlFor="displayName" className="form-label">
                  Display Rule Name
                  <span className="text-muted">(Optional)</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="displayName"
                  name="displayName"
                  placeholder="This rule will be displayed in the report"
                  value={state?.displayName}
                  onChange={inputChangeHandler}
                />
              </div>
            </Col>

            <Col sm={12}>
              <div className="mb-3">
                <Label htmlFor="description" className="form-label">
                  Rule Description <span className="text-muted">(Optional)</span>
                </Label>
                <Input type="textarea" name="description" id="description" onChange={inputChangeHandler} value={state?.description} />
              </div>
            </Col>

            <Col md={6}>
              <div className="mb-3">
                <Label htmlFor="ruleStartDate" className="form-label">
                  Rule Start Date
                </Label>
                <Flatpickr
                  className="form-control"
                  name="ruleStartDate"
                  id="ruleStartDate"
                  placeholder="Select date"
                  value={state?.ruleStartDate}
                  onChange={date => datePickHandler(`ruleStartDate`, date)}
                  options={{
                    minDate: 'today',
                    enableTime: false,
                    dateFormat: 'Y-m-d',
                  }}
                />
                {isValidationShow && state?.ruleStartDate?.trim() === `` && <Error text="Please enter start date" />}

                {isValidationShow &&
                  state?.ruleStartDate !== `` &&
                  state?.ruleEndDate !== `` &&
                  new Date(state?.ruleStartDate).getTime() > new Date(state?.ruleEndDate).getTime() && <Error text="Start date is invalid" />}
              </div>
            </Col>

            <Col md={6}>
              <div className="mb-3">
                <Label htmlFor="redemeed-date" className="form-label d-flex justify-content-between">
                  <span> Rule End Date</span>
                  <div className="form-check">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      id="isIndefiniteDate"
                      name="isIndefiniteDate"
                      checked={state?.isIndefiniteDate}
                      onChange={inputChangeHandler}
                    />
                    <Label className="form-check-label" for="formCheck2">
                      Set as Indefinite
                    </Label>
                  </div>
                </Label>
                <Flatpickr
                  className="form-control"
                  name="ruleEndDate"
                  id="ruleEndDate"
                  placeholder="Select date"
                  value={state?.ruleEndDate}
                  onChange={date => datePickHandler(`ruleEndDate`, date)}
                  options={{
                    minDate: 'today',
                    enableTime: false,
                    dateFormat: 'Y-m-d',
                  }}
                />
                {isValidationShow && state?.ruleEndDate?.trim() === `` && <Error text="Please enter end date" />}
              </div>
            </Col>

            <Col sm={6}>
              <div className="mb-3">
                <Label htmlFor="trigger" className="form-label">
                  Rule Trigger
                </Label>
                <Select
                  id="trigger"
                  name="trigger"
                  value={state?.trigger}
                  options={ruleTriggers}
                  onChange={data => inputSelectHandler(`trigger`, data)}
                />
                {isValidationShow && state?.trigger === `` && <Error text="Please select trigger" />}
              </div>
            </Col>

            {state?.trigger?.value === '2' && (
              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="eventRuleName" className="form-label">
                    Select an Event
                  </Label>
                  <Select
                    id="eventRuleName"
                    name="eventRuleName"
                    value={state?.eventRuleName}
                    options={events?.results?.map(event => ({
                      value: event?.objectId,
                      label: event?.eventName,
                    }))}
                    onChange={data => inputSelectHandler(`eventRuleName`, data)}
                  />
                  {/* {isValidationShow && state?.trigger === `` && (
                                    <Error text="Please select trigger" />
                                )} */}
                </div>
              </Col>
            )}

            <Col md={12}>
              <Card className="card-inner">
                <PreviewCardHeader title="Condition(s)" />
                <CardBody>
                  {state?.conditions?.map((condition, index) => (
                    <Row key={index}>
                      <>
                        <Col md={4}>
                          <div className="mb-3">
                            <Label htmlFor="where" className="form-label">
                              Where
                            </Label>
                            <Select
                              id="where"
                              name="where"
                              value={condition?.fieldValue}
                              options={attributes?.results?.map(att => ({
                                value: att?.inputKeyword,
                                label: att?.name,
                                inputType: att?.inputType,
                              }))}
                              onChange={data => conditionsHandler(`fieldValue`, data, index)}
                            />
                            {isValidationShow && condition?.fieldValue === `` && <Error text="Please select" />}
                          </div>
                        </Col>

                        <Col md={3}>
                          <div className="mb-3">
                            <Label htmlFor="operator" className="form-label">
                              Condition Selector
                            </Label>
                            <Select
                              id="operator"
                              name="operator"
                              value={condition?.operator}
                              options={condition?.fieldValue?.inputType === 'string' ? ruleOperatorForString : ruleOperators}
                              onChange={data => conditionsHandler(`operator`, data, index)}
                            />
                            {isValidationShow && condition?.operator === `` && <Error text="Please select operator" />}
                          </div>
                        </Col>

                        {condition?.fieldValue?.inputType === 'string' && (
                          <Col md={3}>
                            <div className="mb-3">
                              <Label htmlFor="validityTatValue" className="form-label">
                                Value
                              </Label>
                              <Input
                                type="number"
                                onWheel={e => e.target.blur()}
                                className="form-control"
                                id="value"
                                name="value"
                                placeholder="Enter value"
                                value={condition?.value?.val}
                                onChange={e => conditionsHandler(`value`, e.target.value, index)}
                              />
                              {isValidationShow && condition?.value?.val === `` && <Error text="Please enter" />}
                            </div>
                          </Col>
                        )}

                        {condition?.fieldValue?.inputType === 'int' && (
                          <Col md={3}>
                            <div className="mb-3">
                              <Label htmlFor="validityTatValue" className="form-label">
                                Value
                              </Label>
                              <Input
                                type="number"
                                onWheel={e => e.target.blur()}
                                className="form-control"
                                id="value"
                                name="value"
                                placeholder="Enter value"
                                value={condition?.value?.val}
                                onChange={e => conditionsHandler(`value`, e.target.value, index)}
                              />
                              {isValidationShow && condition?.value?.val === `` && <Error text="Please enter" />}
                            </div>
                          </Col>
                        )}

                        {condition?.fieldValue?.inputType === 'date' && (
                          <Col md={3}>
                            <div className="mb-3">
                              <Label htmlFor="date" className="form-label">
                                Date
                              </Label>
                              <Flatpickr
                                className="form-control"
                                value={[condition?.value?.fromDate, condition?.value?.toDate]}
                                onChange={date => conditionsHandler(`date`, date, index)}
                                options={{
                                  mode: 'range',
                                  dateFormat: 'Y-m-d',
                                  minDate: 'today',
                                }}
                              />
                              {isValidationShow && (condition?.value?.fromDate === `` || condition?.value?.toDate === ``) && (
                                <Error text="Please select" />
                              )}
                            </div>
                          </Col>
                        )}

                        <Col md={2}>
                          <div className="mb-3">
                            <Label htmlFor="validityTatValue" className="form-label">
                              &nbsp;
                            </Label>
                            <div className="d-flex justify-content-between">
                              <ButtonGroup size="md">
                                <button
                                  type="submit"
                                  className={`btn ${state?.conditionsBetween[index] === '$and' ? 'btn-success' : 'btn-outline-success'}`}
                                  onClick={() => conditionBtnHandler(`$and`, index)}
                                >
                                  AND
                                </button>
                                <button
                                  type="submit"
                                  className={`btn ${state?.conditionsBetween[index] === '$OR' ? 'btn-info' : 'btn-outline-info'}`}
                                  onClick={() => conditionBtnHandler(`$OR`, index)}
                                >
                                  OR
                                </button>
                              </ButtonGroup>

                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={() => conditionDeleteHandler(index)}
                                disabled={state?.conditions?.length === 1}
                              >
                                <i className="ri-delete-bin-5-line"></i>
                              </button>
                            </div>
                          </div>
                        </Col>
                      </>
                    </Row>
                  ))}
                </CardBody>
              </Card>
            </Col>

            <Col md={12}>
              <Card className="card-inner">
                <PreviewCardHeader title="Effect(s)" />
                <CardBody>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="action" className="form-label">
                          Add Effect
                        </Label>
                        <Select
                          id="action"
                          name="action"
                          value={state?.effect?.action}
                          options={addEffect}
                          onChange={data => inputSelectHandler(`action`, data)}
                        />
                        {isValidationShow && state?.effect?.action === `` && <Error text="Please select effect" />}
                      </div>
                    </Col>

                    {/* <Col md={4}>
                                            <div className="mb-3">
                                                <Label
                                                    htmlFor="validityTatValue"
                                                    className="form-label"
                                                >
                                                    Select Wallet
                                                </Label>
                                                <Select
                                                    id="validityTatUnit"
                                                    name="validityTatUnit"
                                                    // value={state?.validityTat?.unit}
                                                    options={[{ value: "India", label: "India" }]}
                                                // onChange={(data) =>
                                                //     inputSelectHandler(`validityTatUnit`, data)
                                                // }
                                                />
                                                {isValidationShow && state?.effect?.action === `` && (
                                                    <Error text="Please select wallet" />
                                                )}
                                            </div>
                                        </Col> */}

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="instantCoinAmount" className="form-label">
                          Value
                        </Label>
                        <Select
                          id="instantCoinAmount"
                          name="instantCoinAmount"
                          value={state?.effect?.instantCoinAmount}
                          options={effectValues}
                          onChange={data => inputSelectHandler(`instantCoinAmount`, data)}
                        />
                        {isValidationShow && state?.effect?.instantCoinAmount === `` && <Error text="Please select value" />}
                      </div>
                    </Col>

                    {state?.effect?.instantCoinAmount?.value === 0 && (
                      <Col sm={4}>
                        <div className="mb-3">
                          <Label htmlFor="rate" className="form-label">
                            Enter Value
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="rate"
                            name="rate"
                            value={state?.effect?.rate}
                            onChange={inputChangeHandler}
                          />
                          {isValidationShow && state?.effect?.rate === `` && <Error text="Please enter value" />}
                        </div>
                      </Col>
                    )}

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="coinActivationType" className="form-label">
                          Rule Related Points Release Schedule
                        </Label>
                        <Select
                          id="coinActivationType"
                          name="coinActivationType"
                          value={state?.effect?.coinActivationType}
                          options={ruleRelatedPoints}
                          onChange={data => inputSelectHandler(`coinActivationType`, data)}
                        />
                        {isValidationShow && state?.effect?.coinActivationType === `` && <Error text="Please select" />}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="redemeed-date" className="form-label d-flex justify-content-between">
                          <span> Rule Related Points Expiry</span>
                          <div className="form-check">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id="effect-isIndefiniteDate"
                              name="effect-isIndefiniteDate"
                              checked={state?.effect?.isIndefiniteDate}
                              onChange={inputChangeHandler}
                            />
                            <Label className="form-check-label" for="formCheck2">
                              Set as Indefinite
                            </Label>
                          </div>
                        </Label>
                        <Flatpickr
                          className="form-control"
                          name="coinExpiry"
                          id="coinExpiry"
                          value={state?.effect?.coinExpiry}
                          onChange={date => datePickHandler(`coinExpiry`, date)}
                          options={{
                            // maxDate: 'today',
                            enableTime: false,
                            dateFormat: 'Y-m-d',
                          }}
                        />
                        {isValidationShow && state?.effect?.coinExpiry === `` && <Error text="Please select expiry" />}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="card-inner">
        <CardHeader className="d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Set Rule Status</h4>
          <Col md={4}>
            <Select
              id="isActive"
              name="isActive"
              value={state?.isActive}
              options={ruleStatus}
              onChange={data => inputSelectHandler(`isActive`, data)}
            />
          </Col>
        </CardHeader>
      </Card>

      <Col md={12} className="mb-5">
        <div className="text-end">
          <button type="submit" className="btn btn-primary" onClick={submitHandler}>
            {ruleData !== null ? `Update Rule` : `Create Rule`}
          </button>
        </div>
      </Col>
    </>
  );
};

export default CreateRuleForm;
