import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, commsHeader, indianProjectsBaseURL } from '../../../../Containers/Config/index.js';
import {
  UPDATE_COMS_CHANNEL_CONFIG_PROJECT,
  UPDATE_COMS_CHANNEL_CONFIG_PROJECT_SUCCESS,
  UPDATE_COMS_CHANNEL_CONFIG_PROJECT_ERROR,
  UPDATE_COMS_CHANNEL_CONFIG_PROJECT_EMPTY,
} from './../../actionType.js';
import apiCall from '../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* updateComsChannelConfig({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/comms/projects/channel?project_id=${payload?.params?.project_id}&channel=${payload?.params?.channel}`,
      method: 'PUT',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        ...commsHeader,
      },
      data: {
        ...payload?.urlParam,
      },
    });

    if (response.status === 200) {
      yield put({
        type: UPDATE_COMS_CHANNEL_CONFIG_PROJECT_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.success(response?.response?.data?.message);
      yield put({
        type: UPDATE_COMS_CHANNEL_CONFIG_PROJECT_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_COMS_CHANNEL_CONFIG_PROJECT_ERROR,
      payload: error,
    });
  }
}

export function* watchUpdateComsChannelConfig() {
  yield takeEvery(UPDATE_COMS_CHANNEL_CONFIG_PROJECT, updateComsChannelConfig);
}

function* updateComsChannelConfigSaga() {
  yield all([fork(watchUpdateComsChannelConfig)]);
}

export default updateComsChannelConfigSaga;
