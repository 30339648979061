import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useLayoutEffect, useState } from 'react';

import axios from 'axios';
import moment from 'moment/moment';
import jwt_decode from 'jwt-decode';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import { AdibDashboard } from '../Client/Adib';
import { Constant } from './../../Helpers/constant';
import ENBDDashboard from './Components/ENBDDashboard';
import BSESDashboard from './Components/ProjectDashboard';
import CommonDashboard from './Components/CommonDashboard';
import noPermission from '../../../assets/custom/no-permission.jpg';
import { GET_COUPON_PROJECTS } from '../../../store/application/actionType';
import ProjectPlaceholder from './../../../assets/custom/project-placeholder-img.png';

const Dashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  /* ---------------------------- lOCAL STATES ---------------------------- */
  const [isClientType, setIsClientType] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // PROJECTS
  const { projects, projectsLoading } = useSelector(state => ({
    projects: state?.GetCouponProject?.data,
    projectsLoading: state?.GetCouponProject?.loading,
  }));

  useLayoutEffect(() => {
    if (projects !== null) {
      setSelectedProject(JSON.parse(localStorage.getItem('data'))?.program?.id || '');
    } else {
      dispatch({
        type: GET_COUPON_PROJECTS,
      });
    }
  }, [projects]);

  /* ---------------------------- PROGRAM CHANGE HANDLER ---------------------------- */
  const changeProgramHandler = async dataProjectId => {
    const projectId = dataProjectId;
    setSelectedProject(dataProjectId);

    if ([null, undefined, [], ''].includes(dataProjectId)) {
      localStorage.setItem(
        'data',
        JSON.stringify({
          program: '',
        }),
      );

      const tempPermissions = {
        ...JSON.parse(window.localStorage.getItem('authUser')),
        permissions: [],
      };
      localStorage.setItem('authUser', JSON.stringify(tempPermissions));

      history.push(`/dashboard`);
      window.location.reload();
    } else {
      const selectedProgram = projects?.filter(project => project?.id === projectId)?.[0];

      localStorage.setItem(
        'data',
        JSON.stringify({
          program: selectedProgram,
        }),
      );

      const projectCode = selectedProgram?.code;
      const userId = JSON.parse(window.localStorage?.getItem('authUser'))?.data?.userId;
      await getUserPermissions(userId, projectCode);
    }
  };

  /* ---------------------------- TYPED USER HANDLER ---------------------------- */
  async function getUserPermissions(userId, projectCode) {
    let projectBaseURL = ``;
    const contractingCountry = JSON.parse(localStorage?.getItem('data'))?.program?.contractingCountry?.name.toLowerCase();

    if (contractingCountry === `india`) {
      projectBaseURL = process.env.REACT_APP_INDIA_PROJECTS_URL;
    } else if (contractingCountry === `united arab emirates`) {
      projectBaseURL = process.env.REACT_APP_UAE_PROJECTS_URL;
    } else {
      projectBaseURL = process.env.REACT_APP_UAE_PROJECTS_URL;
    }

    axios({
      method: 'get',
      url: `${[`SAB_LANDMARK`, `SAB`].includes(projectCode) ? process.env.REACT_APP_KSA_URL : projectBaseURL}/admin/permissions`,
      headers: {
        Authorization: JSON.parse(window.localStorage.getItem('authUser'))?.token,
        'Content-Type': 'application/json',
        'project-code': projectCode,
      },
      params: {
        userId,
      },
    }).then(function (response) {
      const tempPermissions = {
        ...JSON.parse(window.localStorage.getItem('authUser')),
        token: response?.headers?.authorization,
        permissions: jwt_decode(response?.headers?.authorization)?.permissions,
      };
      localStorage.setItem('authUser', JSON.stringify(tempPermissions));
      localStorage.setItem('sessionPersistTill', jwt_decode(response?.headers?.authorization)?.exp);

      history.push(`/dashboard`);
      window.location.reload();
    });
  }

  /* ---------------------------- GREETINGS ---------------------------- */
  const getGreeting = () => {
    const currentTime = moment();
    const hour = currentTime.hours();

    if (hour >= 5 && hour < 12) {
      return 'morning';
    } else if (hour >= 12 && hour < 17) {
      return 'afternoon';
    } else if (hour >= 17 && hour < 21) {
      return 'evening';
    } else {
      return 'night';
    }
  };

  useEffect(() => {
    const isClientType = location?.pathname?.includes('/client/') ?? false;
    setIsClientType(isClientType);
  }, []);

  const selectedProjectDashboard = () => {
    if (Constant?.PROJECT_CODE !== `ADMIN_ACCESS` && selectedProject) {
      if (['BSES_V1', 'BYPL_LOYALTY_PLATFORM']?.includes(Constant?.PROJECT_CODE)) {
        return <BSESDashboard />;
      } else if (['ENBD_V3']?.includes(Constant?.PROJECT_CODE)) {
        return (
          <>
            {/* <CommonDashboard /> */}
            <ENBDDashboard />
          </>
        );
      } else {
        return <CommonDashboard />;
      }
    } else if (['ADIB_V2']?.includes(Constant?.PROJECT_CODE)) {
      return (
        <>
          <AdibDashboard />
        </>
      );
    }
  };

  return (
    <div className="page-content">
      <Container fluid className="position-relative">
        <Row>
          <Col md={12}>
            <div className="d-flex align-items-lg-center flex-lg-row flex-column mb-3 pb-1">
              <div className="flex-grow-1">
                <h4 className="fs-16 mb-1">
                  Good {getGreeting()},{' '}
                  {isClientType
                    ? JSON.parse(localStorage.getItem('authUser'))?.data?.firstName
                    : JSON.parse(localStorage.getItem('authUser'))?.data?.name?.split(' ')?.[0]}
                  {/* Good {getGreeting()}, {isClientType ? 'Rahul' : JSON.parse(localStorage.getItem('authUser'))?.data?.name?.split(' ')?.[0] + `! 😀`} */}
                </h4>
                {/* <p className="text-muted mb-0">Here's what's happening with your store today.</p> */}
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          {!selectedProject &&
            projects?.length > 0 &&
            projects?.map((project, index) => (
              <Col xl={4} key={index}>
                <Card className="card-animate" style={{ borderRadius: '12px' }}>
                  <CardBody>
                    <div className="d-flex align-items-center">
                      <div className="avatar-sm flex-shrink-0">
                        <img
                          className="flex-shink-0"
                          src={`${project?.logo === '' ? ProjectPlaceholder : project?.logo}`}
                          alt={project?.name}
                          style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                        />
                      </div>
                      <div className="flex-grow-1 overflow-hidden ms-3">
                        <p className="text-uppercase fw-medium text-muted text-truncate mb-3">{project?.contractingCountry?.name || '-'}</p>

                        <div className="d-flex align-items-center mb-3">
                          <h4 className="fs-4 flex-grow-1 mb-0">{project?.name}</h4>
                        </div>
                        <p className="text-secondary text-decoration-underline cursor-pointer	" onClick={() => changeProgramHandler(project?.id)}>
                          Go to the project
                          <span className="d-inline-block text-decoration-none">
                            <i className="fs-13 align-middle ri-arrow-right-up-line"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}

          {projects?.length === 0 && (
            <div>
              <img src={noPermission} alt="no-permission" className="img-fluid" />
            </div>
          )}
        </Row>

        {selectedProjectDashboard()}
      </Container>
    </div>
  );
};

export default Dashboard;
