import React from 'react';
import { CardHeader, Input, Label, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { t } from 'i18next';

const PreviewCardHeader = props => {
  return (
    <React.Fragment>
      <CardHeader className="d-flex">
        <h4 className="card-title mb-0 flex-grow-1">
          {props?.title}

          {props?.tooltip?.isShow && (
            <>
              &nbsp;
              <Link to="#" className="fw-medium" id={props?.tooltip?.id}>
                <i className="ri-information-line"></i>
              </Link>
              <UncontrolledTooltip placement="top" target={props?.tooltip?.id}>
                {props?.tooltip?.content}
              </UncontrolledTooltip>
            </>
          )}
        </h4>

        {props?.secondaryButton && (
          <button className="btn btn-primary" onClick={props?.secondaryButtonHandler}>
            {props.secondaryButtonText}
          </button>
        )}

        {props?.primaryButton && (
          <button className="btn btn-primary mx-2" onClick={props?.primaryClickHandler}>
            {props?.primaryButtonText}
          </button>
        )}

        {props?.refreshBtn && (
          <button className="refresh-btn btn btn-success mx-2" onClick={props?.refreshHandler}>
            {t('Refresh')}&nbsp;
            <span>
              <i className="ri-refresh-line"></i>
            </span>
          </button>
        )}

        {props?.isShowFilterBtn && (
          <button className="btn btn-info" onClick={() => props?.filterBtnHandler()}>
            <i className="ri-filter-3-line align-bottom me-1"></i>
            Fliters
          </button>
        )}
      </CardHeader>
    </React.Fragment>
  );
};

export default PreviewCardHeader;
