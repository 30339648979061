import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { GET_GOLF_COURSE, GET_GOLF_COURSE_SLOTS } from '../../../../../../../store/application/actionType';

import { Card, CardBody, Col, Container, Form, Label, Row, Input, CardFooter, UncontrolledTooltip } from 'reactstrap';
import { PreviewCardHeader } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import ReactTooltip from 'react-tooltip';

const FilterOptions = [
  { label: `All (00:00 - 23-59)`, value: 1 },
  { label: `Morning (50:00 - 10-59)`, value: 2 },
  { label: `AfterNoon (11:00 - 16-59)`, value: 3 },
  { label: `Evening (17:00 - 23-59)`, value: 4 },
];

const EditCourseFromatsForm = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    gId: null,
    slotIntervals: null,
    admin: true,
  };
  const [state, setState] = useState(initialState);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  const [golfCourses, setGolfCourses] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Golf Courses
  const { allGolfCoursesData } = useSelector(state => ({
    allGolfCoursesData: state?.GetGolfCourses?.data,
  }));

  useEffect(() => {
    if (allGolfCoursesData?.results?.length > 0) {
      setGolfCourses(
        allGolfCoursesData?.results?.map(facility => {
          return { value: facility?.id, label: facility?.name };
        }),
      );
    }
  }, [allGolfCoursesData]);

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_GOLF_COURSE,
    });
  }, []);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const onSelectInputChange = (data, label) => {
    let updatedState = null;

    switch (label) {
      case 'golfCourse':
        updatedState = {
          ...state,
          gId: data,
          slotIntervals: {
            label: 'All (00:00 - 23-59)',
            value: 1,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'filter':
        updatedState = {
          ...state,
          slotIntervals: data,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'startDate':
        updatedState = {
          ...state,
          startDate: new Date(date).toISOString(),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'endDate':
        updatedState = {
          ...state,
          endDate: new Date(date).toISOString(),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const payload = {
      startDate: moment(state?.startDate).format('Y-MM-DD'),
      endDate: moment(state?.endDate).format('Y-MM-DD'),
      gId: state.gId.value,
      slotIntervals: state.slotIntervals.value,
      admin: state.admin,
    };

    dispatch({
      type: GET_GOLF_COURSE_SLOTS,
      payload: {
        urlParam: {
          ...payload,
        },
      },
    });

    props.onSubmit(payload);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data.gId !== null) {
      setIsSubmitBtnDisabled(false);
    } else {
      setIsSubmitBtnDisabled(true);
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Select slots to edit" />
            {/* <button onClick={() => console.log(state)}>get state</button> */}
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="golf-course" className="form-label">
                          Golf Course
                          <span className="lbl_mandatory">*</span>
                        </Label>

                        <Select options={golfCourses} value={state.gId} isMulti={false} onChange={e => onSelectInputChange(e, `golfCourse`)} />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="filter" className="form-label">
                          Filter
                        </Label>
                        <Select
                          id="filter"
                          name="filter"
                          options={FilterOptions}
                          isMulti={false}
                          value={state?.slotIntervals}
                          onChange={data => onSelectInputChange(data, `filter`)}
                          style={{
                            border: '4px solid #ced4da',
                            bordeRadius: '0.25rem',
                          }}
                        />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="from" className="form-label">
                          From
                        </Label>
                        <div className="area_expandable">
                          <Flatpickr
                            className="form-control"
                            name="from"
                            id="from"
                            value={state?.startDate}
                            onChange={date => datePickHandler(`startDate`, date)}
                            options={{
                              enableTime: false,
                              dateFormat: 'd M, Y',
                              //   minDate: 'today',
                              //   dateFormat: 'Y-m-d',
                            }}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="to" className="form-label">
                          To
                        </Label>
                        <div className="area_expandable">
                          <Flatpickr
                            className="form-control"
                            name="to"
                            id="to"
                            value={state?.endDate}
                            onChange={date => datePickHandler(`endDate`, date)}
                            options={{
                              enableTime: false,
                              dateFormat: 'd M, Y',
                              //   minDate: 'today',
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md={6} className="p-0">
                  <div className="start"></div>
                </Col>

                <Col md={6}>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary mx-3 overlay-disable" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
                      Get Slots
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>

            <div
              style={{
                width: '100px',
                position: 'relative',
                zIndex: '999999999999',
              }}
            >
              <ReactTooltip />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EditCourseFromatsForm;
