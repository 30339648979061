/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';

const Error = forwardRef((props, ref) => {
  return (
    <span style={{ color: 'red' }} ref={ref}>
      {props?.text}
    </span>
  );
});

export default Error;
