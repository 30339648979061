import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, ModalBody } from 'reactstrap';
import { VENDOR_UPDATE_BOOKING, VENDOR_UPDATE_BOOKING_EMPTY } from '../../../../../../store/actions';

const CancelBooking = ({ data, onCloseHandler, isAirportPickUp }) => {
  const dispatch = useDispatch();

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Update Booking
  const { updateBookings } = useSelector(state => ({
    updateBookings: state?.VendorUpdateBooking?.data,
    updateBookingsLoading: state?.VendorUpdateBooking?.loading,
  }));

  useEffect(() => {
    if (updateBookings !== null) {
      onCloseHandler();

      return () => {
        dispatch({
          type: VENDOR_UPDATE_BOOKING_EMPTY,
        });
      };
    }
  }, [updateBookings, dispatch, onCloseHandler]);

  const handleYesClick = () => {
    dispatch({
      type: VENDOR_UPDATE_BOOKING,
      payload: {
        data: {
          bookingTypeId: isAirportPickUp ? 5 : 6,
          bookingsData: [
            {
              objectId: data?.bookingId,
              process: 'cancel-booking',
            },
          ],
        },
      },
    });
  };

  return (
    <Modal isOpen={true} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <div className="mt-4 pt-2 fs-15 mx-2 mx-sm-2">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-1 mb-0">Are you sure you want to Cancel this booking?</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button type="button" className="btn w-sm btn-light" data-bs-dismiss="modal" onClick={onCloseHandler}>
            Close
          </button>
          <button type="button" className="btn w-sm btn-primary" onClick={handleYesClick}>
            Sure
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CancelBooking;
