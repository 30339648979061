import React from 'react';

import classnames from 'classnames';
import { Nav, NavItem, NavLink } from 'reactstrap';

function Navigation(props) {
  return (
    <Nav className="nav-pills nav-justified custom-nav" role="tablist">
      <NavItem role="presentation">
        <NavLink
          href="#"
          className={classnames(
            {
              active: props.activeTab === 1,
              done: props.activeTab <= 4 && props.activeTab >= 0,
            },
            'p-3 ',
          )}
          style={{ fontSize: '15px' }}
          // onClick={() => {
          //   props.toggleTab(1)
          // }}
        >
          <i className="ri-user-2-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
          Benefit Group(s)
        </NavLink>
      </NavItem>
      <NavItem role="presentation">
        <NavLink
          href="#"
          className={classnames(
            {
              active: props.activeTab === 2,
              done: props.activeTab <= 4 && props.activeTab > 1,
            },
            'p-3',
          )}
          style={{ fontSize: '15px' }}
          // onClick={() => {
          //   props.toggleTab(2)
          // }}
        >
          <i className="ri-truck-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
          Benefit Group Item(s)
        </NavLink>
      </NavItem>

      {/* <NavItem role="presentation">
        <NavLink
          href="#"
          className={classnames(
            {
              active: props.activeTab === 3,
              done: props.activeTab <= 4 && props.activeTab > 2,
            },
            'p-3',
          )}
          style={{ fontSize: '15px' }}
          // onClick={() => {
          //   props.toggleTab(3)
          // }}
        >
          <i className="ri-bank-card-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
          Preview
        </NavLink>
      </NavItem>
      <NavItem role="presentation">
        <NavLink
          href="#"
          className={classnames(
            {
              active: props.activeTab === 4,
              done: props.activeTab <= 4 && props.activeTab > 3,
            },
            'p-3',
          )}
          style={{ fontSize: '15px' }}
          // onClick={() => {
          //   props.toggleTab(4)
          // }}
        >
          <i className="ri-checkbox-circle-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
          Finish
        </NavLink>
      </NavItem> */}
    </Nav>
  );
}

export default Navigation;
