import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import cogoToast from 'cogo-toast';
import { Container } from 'reactstrap';

import Table from './components/Tables/Table';
import Filter from './components/Filters/Filter';
import QuestionModal from './components/Modals/Question';
import UpdateOffer from './components/Modals/UpdateOffer';
import { Constant } from '../../../../../Helpers/constant';
import { BreadCrumb, Loader } from '../../../../../Common';
import QuestionAnswerModal from './components/Modals/QuestionAnswer';
import { CLICK_DOWNLOAD_DUMP_EMPTY, GET_OFFER, GET_OFFER_EMPTY, GET_QUES_ANS, GET_QUES_ANS_EMPTY } from '../../../../../../store/actions';

// Define the shape of the filter object
interface FilterState {
  createdAtRange: {
    from: string;
    to: string;
  };
  sortOn: string;
  bookingId?: string;
  membershipId?: string;
}

// Define the shape of your Redux state
interface RootStateForGetPost {
  GetOffer: {
    data: any;
    loading: boolean;
    error: any;
  };
}

interface RootStateForUpdatePost {
  UpdateOffer: {
    data: any;
    loading: boolean;
    error: any;
  };
}

const Offers = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState<boolean>(false);
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
  const [currentSelectedOfferDetails, setCurrentSelectedOfferDetails] = useState([]);
  const [isUpdateModalShow, setIsUpdateModalShow] = useState(false);
  const [isQuestionModalShow, setIsQuestionModalShow] = useState(false);
  const [isQuestionWithAnsModalShow, setIsQuestionWithAnsModalShow] = useState(false);
  const [updateType, setUpdateType] = useState('');
  const [actionType, setActionType] = useState('');

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Offers
  const { getOffer, getOfferLoading } = useSelector((state: RootStateForGetPost) => ({
    getOffer: state?.GetOffer?.data?.data,
    getOfferLoading: state?.GetOffer?.loading,
  }));

  // Update Offers
  const { updateOffer, updateOfferLoading } = useSelector((state: RootStateForUpdatePost) => ({
    updateOffer: state?.UpdateOffer?.data,
    updateOfferLoading: state?.UpdateOffer?.loading,
  }));

  // Ask Question
  const { askQuestionLoading } = useSelector((state: any) => ({
    askQuestionLoading: state?.AskQuestion?.loading,
  }));

  // Get Questions With Ans
  const { getQuesAns, getQuesAnsLoading } = useSelector((state: any) => ({
    getQuesAns: state?.GetQuestionAnswer?.data,
    getQuesAnsLoading: state?.GetQuestionAnswer?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    return () => {
      dispatch({
        type: GET_OFFER_EMPTY,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (updateOffer !== null) {
      dispatch({
        type: GET_OFFER,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          },
          data: null,
        },
      });
    }
  }, [updateOffer]);

  useEffect(() => {
    dispatch({
      type: GET_OFFER,
      payload: {
        urlParam: {
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
        },
        data: null,
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (getQuesAns && actionType === 'QUESTION_ASKED') {
      getQuesAns?.data?.length < 2 ? setIsQuestionModalShow(true) : cogoToast.warn('You can not ask more than two questions');
    }
    dispatch({
      type: GET_QUES_ANS_EMPTY,
    });
  }, [getQuesAns]);

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedOfferDetails(params?.offerDetails);
    setActionType(params?.actionType);
    if (params?.actionType === `ACCEPT`) {
      setIsUpdateModalShow(true);
      setUpdateType('Accept');
    } else if (params?.actionType === `REJECT`) {
      setIsUpdateModalShow(true);
      setUpdateType('Reject');
    } else if (params?.actionType === `BLOCK`) {
      setIsUpdateModalShow(true);
      setUpdateType('Block');
    } else if (params?.actionType === `UNBLOCK`) {
      setIsUpdateModalShow(true);
      setUpdateType('Unblock');
    } else if (params?.actionType === `DELETE`) {
      setIsUpdateModalShow(true);
      setUpdateType('Delete');
    } else if (params?.actionType === `QUESTION_ASKED`) {
      dispatch({
        type: GET_QUES_ANS,
        payload: {
          data: {
            externalId: params?.offerDetails?.id,
            type: 2,
          },
        },
      });
    } else if (params?.actionType === `QUES_ANS`) {
      setIsQuestionWithAnsModalShow(true);
    }
  };

  /* ---------------------------- DOWNLOAD DUMP HANDLER ---------------------------- */
  const downloadDumpHandler = () => {
    dispatch({
      type: CLICK_DOWNLOAD_DUMP_EMPTY,
      payload: {
        urlParam: {
          downloadType: 1,
        },
        data: currentlyAppliedFilters,
      },
    });
  };

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = (filters: FilterState) => {
    if (filters) {
      setCurrentPageNumber(0);
      setCurrentlyAppliedFilters(filters);
      dispatch({
        type: GET_OFFER,
        payload: {
          urlParam: {
            pageIndex: 0,
          },
          data: {
            ...filters,
          },
        },
      });
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = (pageNumber: number) => {
    setCurrentPageNumber(pageNumber);
    dispatch({
      type: GET_OFFER,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          sortOn: currentlyAppliedFilters?.sortOn ?? 'updatedAt',
        },
        data: currentlyAppliedFilters,
      },
    });
  };

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/client/dashboard`);
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (getOfferLoading || updateOfferLoading || askQuestionLoading || getQuesAnsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [getOfferLoading, updateOfferLoading, askQuestionLoading, getQuesAnsLoading]);

  return (
    <>
      {loading && <Loader />}

      {isUpdateModalShow && (
        <UpdateOffer data={currentSelectedOfferDetails} onCloseHandler={() => setIsUpdateModalShow(false)} updateType={updateType} />
      )}

      {isQuestionModalShow && <QuestionModal data={currentSelectedOfferDetails} onCloseHandler={() => setIsQuestionModalShow(false)} />}

      {isQuestionWithAnsModalShow && (
        <QuestionAnswerModal data={currentSelectedOfferDetails} onCloseHandler={() => setIsQuestionWithAnsModalShow(false)} />
      )}

      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ----------------------------  UI'S ---------------------------- */}
          <>
            <BreadCrumb title="Offer Approval" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Offer Approval`]} />
            <Filter filterHandler={filterSubmitHandler} appliedFilters={currentlyAppliedFilters} />
            <Table
              data={getOffer?.data}
              totalData={getOffer?.count}
              pageNumber={currentPageNumber}
              pageHandler={paginationHandler}
              actionHandler={tableActionHandler}
              dumpDownload={downloadDumpHandler}
              appliedFilters={currentlyAppliedFilters}
            />
          </>
        </Container>
      </div>
    </>
  );
};

export default Offers;
