import React from 'react';
import './Loader.scss';

const Loader = props => {
  return (
    <React.Fragment>
      <div className="loader" style={{ zIndex: props?.zIndex ? '999999999999' : 1, display: 'block' }}>
        <div className="lds-facebook">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Loader;
