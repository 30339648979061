import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { Grid, _ } from 'gridjs-react';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import { PreviewCardHeader, TablePagination } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';
import Countdown from 'react-countdown';
import { hasPermission, isDatePassed } from '../../../../../../Helpers/Helpers';

const Table = props => {
  const [data, setData] = useState([]);

  const [allData, setAllData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [selectedBookings, setSelectedBookings] = useState([]);
  const [isBulkActionShow, setIsBulkActionShow] = useState(false);
  const [currentFilterBookingStatus, setCurrentFilterBookingStatus] = useState(0);
  const [currentFilteredBookingTitle, setCurrentFilterBookingTitle] = useState('Bookings');

  useEffect(() => {
    getBookingStatusTitleHandler(parseInt(props?.appliedFilters?.status));

    if (props?.data?.length > 0) {
      setAllData(props?.data);

      setBookingData(
        props?.data?.map(booking => {
          return { ...booking, checkboxValue: false };
        }),
      );

      setSelectedBookings([]);
      setIsBulkActionShow(false);
      getBookingStatusTitleHandler(parseInt(props?.appliedFilters?.status));
      setCurrentFilterBookingStatus(parseInt(props?.appliedFilters?.status));
    } else {
      setBookingData([]);
      setSelectedBookings([]);
      setCurrentFilterBookingStatus(0);
      setIsBulkActionShow(false);
    }
  }, [props?.data]);

  useEffect(() => {
    if (bookingData?.length > 0) {
      setAllData();
      setData(
        bookingData?.map((data, index) => {
          const status = Constant?.BOOKING_STATUSES?.filter(statusObj => statusObj.objectId === data?.bookingStatusCode)[0]?.name;

          let confirmedAt = `NA`;
          data?.bookingModifyPersons?.forEach(element => {
            if (element?.bookingStatus === Constant?.BOOKING_STATUS?.CONFIRM) {
              confirmedAt = moment(`${element?.modifiedAt}`).format(`DD-MM-YYYY, h:mm A`);
            }
          });

          return [
            // false,
            `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            data || `NA`,
            data?.user?.name || `NA`,
            data?.payment?.paymentId || `NA`,
            data?.paymentSummary?.fees?.[0]?.value
              ? `${data?.paymentSummary?.fees?.[0]?.value} (${data?.paymentSummary?.fees?.[0]?.currency})`
              : `NA`,

            data?.paymentSummary?.totalPayable?.value
              ? `${data?.paymentSummary?.totalPayable?.value} (${data?.paymentSummary?.totalPayable?.currency})`
              : `NA`,

            data?.paymentSummary?.discounts?.[0]?.amount
              ? `${data?.paymentSummary?.discounts?.[0]?.amount} (${data?.paymentSummary?.discounts?.[0]?.currency})`
              : `NA`,
            data?.createdAt ? moment(new Date(`${data?.createdAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            data?.bookingStatusCode ? status : 'NA',
            data,
          ];
        }),
      );
    } else {
      setData([]);
    }
  }, [bookingData]);

  /* ---------------------------- BOOKING TABLE TITLE HANDLER ---------------------------- */
  const getBookingStatusTitleHandler = bookingsStatusCode => {
    let bookingsTitle = 'Bookings';

    if (bookingsStatusCode !== 0) {
      Constant?.BOOKING_STATUSES?.forEach(bookingStatus => {
        if (bookingsStatusCode === bookingStatus?.objectId) {
          bookingsTitle = `${bookingStatus?.name} Bookings`;
        }
      });
    }
    setCurrentFilterBookingTitle(bookingsTitle);
  };

  const actionBtnHandler = (flag, data) => {
    if (flag === 'DOWNLOAD_PDF') {
      props?.actionHandler({
        actionType: flag,
        bookingDetails: data,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            {/* <button
              onClick={() => console.log({ currentFilteredBookingTitle })}
            >
              get state
            </button> */}
            <PreviewCardHeader
              title={currentFilteredBookingTitle}
              primaryButton={hasPermission(PermissionConstants?.ALL_PERMISSIONS?.VENDOR_DOWNLOAD_DUMP) && props?.data?.length > 0}
              primaryButtonText="Download Dump"
              primaryClickHandler={props?.dumpDownload}
              refreshBtn={true}
              refreshHandler={props.refreshHandler}
            />

            {/* ------------------------ TABLE DETAILS ------------------------ */}
            {props?.data?.length > 0 && (
              <CardBody>
                <div id="table-gridjs">
                  <Grid
                    data={data}
                    columns={[
                      {
                        name: 'S.No.',
                        width: '60px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Booking ID',
                        width: '130px',
                        formatter: (cell, row) => _(<span className="">{cell?.bookingId}</span>),
                      },

                      {
                        name: 'Name',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Payment ID',
                        width: '220px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Total Amount',
                        width: '220px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Paid Amount',
                        width: '220px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Discount Amount',
                        width: '220px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Created At',
                        width: '180px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Payment Status',
                        width: '140px',
                        formatter: cell =>
                          _(
                            <span
                              className={`booking_status badge break-word bg-${
                                Constant.BOOKING_STATUSES.filter(status => status?.name === cell)[0]?.bgColor
                              }`}
                            >
                              {cell}
                            </span>,
                          ),
                      },

                      {
                        name: 'Action',
                        width: '140px',
                        formatter: cell =>
                          _(
                            <div className="d-flex justify-content-center flex-column">
                              <button
                                className="btn btn-sm btn-primary mb-1"
                                onClick={() => {
                                  actionBtnHandler(`DOWNLOAD_PDF`, cell);
                                }}
                                // disabled={true}
                              >
                                Download
                              </button>
                            </div>,
                          ),
                      },
                    ]}
                    search={false}
                    sort={false}
                    fixedHeader={true}
                    // height="400px"
                    pagination={{ enabled: false, limit: 5 }}
                  />
                </div>
                {props.children}
              </CardBody>
            )}

            {props?.data?.length === 0 && (
              <CardBody>
                <h5 className="text-center">Record not found!</h5>
              </CardBody>
            )}

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Table);
