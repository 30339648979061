import React from 'react';
import { Card, CardBody, CardHeader, Container, Row } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index';
import CategoryPoints from '../Tables/CategoryPoints';
import MonthlyRegistration from '../Tables/MonthlyRegistration';
import PartnerPoints from '../Tables/PartnerPoints';

const MisReports = ({ data }) => {
  return (
    <Card className="card-inner">
      <CardHeader>
        <h5>MIS Reports</h5>
      </CardHeader>
      <CardBody>
        <Container className="mt-4">
          <Row>
            <MonthlyRegistration data={data} />
          </Row>
        </Container>
      </CardBody>
    </Card>
  );
};

export default MisReports;
