import { useEffect, useState } from 'react';

import Select from 'react-select';
import { Button, Card, CardBody, Col, Container, Input, Label, Modal, ModalBody, Row } from 'reactstrap';

import CarType from '../Card/CarType';

const unitOptions = [
  { label: 'Km', value: 'km' },
  { label: 'Miles', value: 'miles' },
];

const VendorPricingModal = ({
  modal,
  setModal,
  vendorPricing,
  setVendorPricing,
  updateVendorPricing,
  setUpdateVendorPricing,
  maxDistance,
  setMaxDistance,
  minimumDistance,
}) => {
  const toggle = () => {
    setUpdateVendorPricing(null);
    setModal(!modal);
  };

  let carType = ['Comfort', 'Luxury', 'MVP'];
  let dropType = ['pickup', 'drop'];

  const initialState = {
    minimumDistance: '',
    maximumDistance: '',
    unit: null,
  };
  const [state, setState] = useState(initialState);

  const [errors, setErrors] = useState<any>({
    minimumDistance: '',
    maximumDistance: '',
    unit: '',
  });

  useEffect(() => {
    let validateObj = {};
    carType.forEach(car => {
      if (dropType.includes('pickup') && dropType.includes('drop')) {
        initialState[car] = {
          arrival: '',
          departure: '',
          parking: '',
        };
        validateObj[car] = {
          arrival: '',
          departure: '',
          parking: '',
        };
      } else if (dropType.includes('drop')) {
        initialState[car] = {
          departure: '',
        };
        validateObj[car] = {
          departure: '',
        };
      } else if (dropType.includes('pickup')) {
        initialState[car] = {
          arrival: '',
          parking: '',
        };
        validateObj[car] = {
          arrival: '',
          parking: '',
        };
      }
    });
    setErrors({ ...errors, validateObj });
  }, []);

  const validateForm = () => {
    let isValid = true;
    const errorsCopy = { ...errors };

    // Validate minimum distance
    if (!state.minimumDistance.trim()) {
      errorsCopy.minimumDistance = 'Minimum distance is required';
      isValid = false;
    } else if (parseInt(state.minimumDistance) < parseInt(maxDistance)) {
      errorsCopy.minimumDistance = 'Minimum Distance is always greater than your last zone maximum distance';
      isValid = false;
    } else {
      errorsCopy.minimumDistance = '';
    }

    // Validate maximum distance
    if (!state.maximumDistance.trim()) {
      errorsCopy.maximumDistance = 'Maximum distance is required';
      isValid = false;
    } else if (parseInt(state.maximumDistance) <= parseInt(state.minimumDistance)) {
      errorsCopy.maximumDistance = 'Maximum distance must be greater than minimum distance';
      isValid = false;
    } else if (
      updateVendorPricing &&
      vendorPricing.length > 1 &&
      minimumDistance < state.maximumDistance &&
      updateVendorPricing?.index !== vendorPricing.length - 1
    ) {
      errorsCopy.maximumDistance = 'Maximum distance must be smaller than your next minimum distance';
      isValid = false;
    } else {
      errorsCopy.maximumDistance = '';
    }

    // Validate unit
    if (!state.unit) {
      errorsCopy.unit = 'Unit is required';
      isValid = false;
    } else {
      errorsCopy.unit = '';
    }

    // Validate car type fields
    carType.forEach(car => {
      const carTypeErrors = { ...errors[car] };
      const carTypeValues = { ...state[car] };
      // Validate arrival
      if (!carTypeValues.arrival.trim()) {
        carTypeErrors.arrival = `${car} Arrival is required`;
        isValid = false;
      } else {
        carTypeErrors.arrival = '';
      }

      // Validate departure
      if (!carTypeValues.departure.trim()) {
        carTypeErrors.departure = `${car} Departure is required`;
        isValid = false;
      } else {
        carTypeErrors.departure = '';
      }

      // Validate parking
      if (!carTypeValues.parking.trim()) {
        carTypeErrors.parking = `${car} Parking is required`;
        isValid = false;
      } else {
        carTypeErrors.parking = '';
      }

      errorsCopy[car] = carTypeErrors;
    });

    setErrors(errorsCopy);
    return isValid;
  };

  useEffect(() => {
    if (updateVendorPricing) {
      setState(updateVendorPricing);
    }
  }, [updateVendorPricing]);

  const handleChangeMinimumDistance = e => {
    const { value } = e.target;

    setState(prevState => ({
      ...prevState,
      minimumDistance: value,
    }));

    if (parseInt(value) < parseInt(maxDistance)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        minimumDistance: 'Minimum Distance is always greater than your last zone maximum distance',
      }));
    } else if (state.maximumDistance && parseInt(value) >= parseInt(state.maximumDistance)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        maximumDistance: 'Maximum distance must be greater than minimum distance',
      }));
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        minimumDistance: '',
      }));
    }
  };

  const handleChangeMaximumDistance = e => {
    const { value } = e.target;

    setState(prevState => ({
      ...prevState,
      maximumDistance: value,
    }));
    if (state.minimumDistance && parseInt(value) <= parseInt(state.minimumDistance)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        maximumDistance: 'Maximum distance must be greater than minimum distance',
      }));
    } else if (
      updateVendorPricing &&
      vendorPricing.length > 1 &&
      minimumDistance < value &&
      updateVendorPricing?.index !== vendorPricing.length - 1
    ) {
      setErrors(prevErrors => ({
        ...prevErrors,
        maximumDistance: 'Maximum distance must be smaller than your next minimum distance',
      }));
    } else {
      setErrors(prevErrors => ({
        ...prevErrors,
        maximumDistance: '',
      }));
    }
  };

  const handleSubmit = () => {
    const isValid = validateForm();
    if (isValid) {
      let updatePricing = [...vendorPricing];
      if (updateVendorPricing) {
        updatePricing[updateVendorPricing.index] = state;
        setVendorPricing(updatePricing);
        setUpdateVendorPricing(null);
      } else {
        updatePricing.push(state);
        setVendorPricing(updatePricing);
      }
      setModal(false);
      setMaxDistance(state?.maximumDistance);
    } else {
      //
    }
  };
  return (
    <Modal isOpen={modal} toggle={toggle} size="xl">
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Vendor Pricing
        </h5>
        <Button type="button" onClick={toggle} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close"></Button>
      </div>
      <ModalBody>
        <Container fluid className="position-relative">
          <Row>
            <Col xxl={12}>
              <Card>
                <CardBody>
                  <h4>Zones</h4>
                  <div className="live-preview">
                    <Row>
                      <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                        <div className="mb-3">
                          <Label htmlFor="minimumDistance" className="form-label">
                            Minimum Distance
                            <span className="lbl_mandatory"> *</span>
                          </Label>
                          <Input
                            id="minimumDistance"
                            name="minimumDistance"
                            type="text"
                            className="form-control"
                            onChange={handleChangeMinimumDistance}
                            value={state.minimumDistance}
                            autoComplete="off"
                          />
                          {errors.minimumDistance && <span style={{ color: 'red' }}>{errors.minimumDistance}</span>}
                        </div>
                      </Col>
                      <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                        <div className="mb-3">
                          <Label htmlFor="maximumDistance" className="form-label">
                            Maximum Distance
                            <span className="lbl_mandatory"> *</span>
                          </Label>
                          <Input
                            id="maximumDistance"
                            name="maximumDistance"
                            type="text"
                            className="form-control"
                            onChange={handleChangeMaximumDistance}
                            value={state.maximumDistance}
                            autoComplete="off"
                          />
                          {errors.maximumDistance && <span style={{ color: 'red' }}>{errors.maximumDistance}</span>}
                        </div>
                      </Col>
                      <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                        <div className="mb-3">
                          <Label htmlFor="unit" className="form-label">
                            Unit
                            <span className="lbl_mandatory"> *</span>
                          </Label>
                          <Select
                            id="unit"
                            name="unit"
                            value={state.unit}
                            options={unitOptions}
                            onChange={data => {
                              setState({ ...state, unit: data });
                              setErrors((prevErrors: any) => ({
                                ...prevErrors,
                                unit: '',
                              }));
                            }}
                          />
                          {errors.unit && <span style={{ color: 'red' }}>{errors.unit}</span>}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {carType?.map((carTypeValues, index) => {
            return (
              <CarType
                carType={carTypeValues}
                dropType={dropType}
                key={index}
                state={state}
                setState={setState}
                errors={errors}
                setErrors={setErrors}
              />
            );
          })}
        </div>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-right">
        <Button className="overlay-disable" color="primary" onClick={handleSubmit} disabled={false}>
          {updateVendorPricing ? 'Update' : 'Submit'}
        </Button>
      </div>
    </Modal>
  );
};

export default VendorPricingModal;
