import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import apiCall from '../../../apiCall';
import { baseUrl, customHeaders } from './../../../../../Containers/Config/index';

import { DELETE_SURGE_EVENT, DELETE_SURGE_EVENT_SUCCESS, DELETE_SURGE_EVENT_ERROR } from './../../../actionType';
import { Constant } from '../../../../../Containers/Helpers/constant';
import cogoToast from 'cogo-toast';

function* deleteEvent({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/event-rules`,
      method: 'DELETE',
      headers: { Authorization: Constant?.CLIENT_TOKEN, ...customHeaders },
      data: payload.data,
    });

    if (response.status === 200) {
      cogoToast.success(`Attribute disabled successfully!`);
      // cogoToast.success(response?.data?.message)
      yield put({ type: DELETE_SURGE_EVENT_SUCCESS, payload: response });
    } else {
      cogoToast.error(response?.response?.data?.message);
      // yield put({ type: DELETE_SURGE_EVENT_ERROR, payload: response.error })
    }
  } catch (error) {
    yield put({ type: DELETE_SURGE_EVENT_ERROR, payload: error });
  }
}

export function* watchDeleteEvent() {
  yield takeEvery(DELETE_SURGE_EVENT, deleteEvent);
}

function* deleteEventSaga() {
  yield all([fork(watchDeleteEvent)]);
}

export default deleteEventSaga;
