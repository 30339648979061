import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const MobileField = props => {
  return (
    <PhoneInput
      value={props?.value}
      onChange={props?.onChange}
      containerStyle={{
        color: 'red',
      }}
      style={{}}
      inputStyle={{
        width: '100%',
        backgroundColor: `${props?.disabled ? '#f0f2f6' : '#fff'}`,
      }}
      dropdownStyle={{
        background: 'white',
      }}
      searchStyle={{}}
      defaultCountry="it"
      preferredCountries={props?.preferredCountries ? props?.preferredCountries : ['it', 'se']}
      type="search"
      placeholder={'Phone Number'}
      autoFocus={true}
      excludeCountries={['us']}
      disabled={props?.disabled}
      inputClass="form-control"
      isValid={props?.isValid}
      country={props?.defaultCountry}
      onEnterKeyPress={props?.inputHandler}
      // autoComplete={autocompleteSearch ? 'on' : 'off'}
    />
  );
};

export default MobileField;
