import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncPaginate } from 'react-select-async-paginate';

import { Col, Label, Row, Input, OffcanvasBody, OffcanvasHeader, Offcanvas } from 'reactstrap';
import { GET_COMMS_REPORT_EMPTY } from '../../../../../../../store/application/actionType';
import Select from 'react-select';
import { indianProjectsBaseURL, customHeaders, commsHeader } from '../../../../../../Config';
import { Constant } from '../../../../../../Helpers/constant';
import axios from 'axios';
import { isANumber, isValidTextWithUnderscore } from '../../../../../../Helpers/Helpers';

const channelTypes = [
  { label: 'Sms', value: 'sms' },
  { label: 'Whatsapp', value: 'whatsapp' },
  { label: 'Email', value: 'email' },
];

const statusTypes = [
  { label: 'Initiated', value: 'INITIATED' },
  { label: 'In-process', value: 'IN_PROCESS' },
  { label: 'Success', value: 'SUCCESS' },
  { label: 'Failed', value: 'FAILED' },
];

const CustomFilter = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    status: ``,
    project_id: ``,
    event_type: ``,
    channel: ``,
    tracker_id: ``,
    template_name: ``,
  };
  const [filter, setFilter] = useState(initialState);

  /* ---------------------------- REDUX STATES ---------------------------- */

  useEffect(() => {
    if (props?.appliedFilters !== null) {
      setFilter(props?.appliedFilters);
    }
  }, [props?.appliedFilters]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...filter };
    let tempProjectId = '';

    switch (name) {
      case 'project_id':
        if (value?.length === 0) {
          tempProjectId = ``;
        } else {
          tempProjectId = isValidTextWithUnderscore(value) ? value : filter?.project_id;
        }
        updatedState = {
          ...filter,
          project_id: tempProjectId.toLocaleUpperCase(),
        };
        break;

      case 'tracker_id':
        updatedState = {
          ...filter,
          tracker_id: value,
        };
        break;

      case 'template_name':
        updatedState = {
          ...filter,
          template_name: value,
        };
        break;

      default:
      // do nothing
    }

    setFilter(updatedState);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...filter };

    if (label === `channel`) {
      updatedState = {
        ...filter,
        channel: data,
      };
    } else if (label === `report-status`) {
      updatedState = {
        ...filter,
        status: data,
      };
    } else if (label === `event_type`) {
      updatedState = {
        ...filter,
        event_type: data,
      };
    } else if (label === `template_name`) {
      updatedState = {
        ...filter,
        template_name: data,
      };
    } else if (label === `project_id`) {
      updatedState = {
        ...filter,
        project_id: data,
      };
    }

    setFilter(updatedState);
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    props.filterHandler({ ...filter });
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter({ ...initialState });

    dispatch({
      type: GET_COMMS_REPORT_EMPTY,
    });
  };

  /* ---------------------------- TYPED USER HANDLER ---------------------------- */
  async function loadProjects(search) {
    const response = await fetch(`${indianProjectsBaseURL}/admin/projects?getAll=true&name=${search}`, {
      method: `GET`,
      headers: new Headers({
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      }),
    });
    const usersResponse = await response.json();

    return {
      options: usersResponse?.results?.map((data, index) => {
        return {
          value: data?.code,
          label: `${data?.name}`,
          completeData: data,
        };
      }),
      hasMore: false,
    };
  }

  async function loadEvents(search) {
    let urlParam = null;
    return await axios({
      method: `GET`,
      url: `${indianProjectsBaseURL}/comms/events`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        ...commsHeader,
      },
      params: {
        ...urlParam,
        event_type: search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.map(data => {
            return {
              value: data?.id,
              label: `${data?.event_type}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  async function loadTemplates(search) {
    let urlParam = null;
    return await axios({
      method: `GET`,
      url: `${indianProjectsBaseURL}/comms/templates`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        ...commsHeader,
      },
      params: {
        ...urlParam,
        template_name: search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data?.id,
              label: `${data?.template_name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  return (
    <>
      <Offcanvas direction="end" isOpen={props.show} id="offcanvasExample" toggle={() => props.onCloseClick()}>
        <OffcanvasHeader className="bg-light" toggle={() => props.onCloseClick()}>
          Report filters
        </OffcanvasHeader>
        <OffcanvasBody>
          {/* <button onClick={() => console.log(filter)}>get state</button> */}
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="project_id" className="form-label">
                  Program
                </Label>
                <Input
                  id="project_id"
                  name="project_id"
                  type="text"
                  className="form-control"
                  value={filter?.project_id}
                  onChange={inputChangeHandler}
                  autoComplete="off"
                />
              </div>
            </Col>

            {/* <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="project" className="form-label">
                  Program
                </Label>
                <AsyncPaginate
                  debounceTimeout={500}
                  value={filter?.project_id}
                  loadOptions={loadProjects}
                  onChange={selectedOption => inputSelectHandler('project_id', selectedOption)}
                  isMulti={false}
                  closeMenuOnSelect={true}
                  noOptionsMessage={() => 'No results found'}
                  cacheUniqs={[['code']]}
                  placeholder="Select..."
                  isClearable={true}
                />
              </div>
            </Col> */}

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="report-status" className="form-label">
                  Status
                </Label>
                <Select
                  id="report-status"
                  name="report-status"
                  options={statusTypes ?? []}
                  value={filter?.status}
                  onChange={data => inputSelectHandler(`report-status`, data)}
                  isClearable={true}
                />
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="channel" className="form-label">
                  Channel
                </Label>
                <Select
                  id="channel"
                  name="channel"
                  options={channelTypes ?? []}
                  value={filter?.channel}
                  onChange={data => inputSelectHandler(`channel`, data)}
                  isClearable={true}
                />
              </div>
            </Col>

            <Col md={12} className="mb-3">
              <div className="d-flex justify-content-between">
                <Label htmlFor="event_type" className="form-label">
                  Template
                </Label>
              </div>
              <AsyncPaginate
                debounceTimeout={500}
                value={filter?.template_name}
                loadOptions={loadTemplates}
                onChange={selectedOption => inputSelectHandler('template_name', selectedOption)}
                isMulti={false}
                closeMenuOnSelect={true}
                noOptionsMessage={() => 'No results found'}
                cacheUniqs={[['code']]}
                placeholder="Select..."
                isClearable={true}
              />
            </Col>

            <Col md={12} className="mb-3">
              <div className="d-flex justify-content-between">
                <Label htmlFor="event_type" className="form-label">
                  Events
                </Label>
              </div>
              <AsyncPaginate
                debounceTimeout={500}
                value={filter?.event_type}
                loadOptions={loadEvents}
                onChange={selectedOption => inputSelectHandler('event_type', selectedOption)}
                isMulti={false}
                closeMenuOnSelect={true}
                noOptionsMessage={() => 'No results found'}
                cacheUniqs={[['code']]}
                placeholder="Select..."
                isClearable={true}
              />
            </Col>
          </Row>
        </OffcanvasBody>

        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
          <button
            className="btn btn-light w-100"
            onClick={resetFilterHandler}
            // disabled={isResetDisabled}
          >
            Reset Filter
          </button>

          <button className="btn btn-primary w-100" onClick={filterSubmitHandler}>
            Search
          </button>
        </div>
      </Offcanvas>
    </>
  );
};

export default CustomFilter;
