import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CreateEventForm from './Components/Forms/Form';
import EventsTable from './Components/Tables/Table';
import { Constant } from '../../../../../Helpers/constant';
import { Loader } from '../../../../../Common';
import {
  CREATE_SURGE_EVENT,
  CREATE_SURGE_EVENT_EMPTY,
  GET_SURGE_EVENTS,
  GET_PROJECTS_LIST,
  UPDATE_SURGE_EVENT,
  UPDATE_SURGE_EVENT_EMPTY,
} from '../../../../../../store/application/actionType';

const Events = ({ tabId, activeTab }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentSelectedDetails, setCurrentSelectedDetails] = useState(null);

  const [isShowEventsTable, setIsShowEventsTable] = useState(true);
  const [isShowCreateEventForm, setIsShowCreateEventForm] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Projects List
  const { projectsList, projectsListLoading } = useSelector(state => ({
    projectsList: state?.ProjectsList?.data,
    projectsListLoading: state?.ProjectsList?.loading,
  }));

  // Events List
  const { events, eventsLoading } = useSelector(state => ({
    events: state?.GetEvents?.data,
    eventsLoading: state?.GetEvents?.loading,
  }));

  // Create Events
  const { createEvent, createEventLoading } = useSelector(state => ({
    createEvent: state?.CreateEvent?.data,
    createEventLoading: state?.CreateEvent?.loading,
  }));

  // Update Events
  const { updateEvent, updateEventLoading } = useSelector(state => ({
    updateEvent: state?.UpdateEvent?.data,
    updateEventLoading: state?.UpdateEvent?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (activeTab !== tabId) {
      setCurrentPageNumber(0);
      setCurrentSelectedDetails(null);
      setIsShowEventsTable(true);
      setIsShowCreateEventForm(false);

      dispatch({
        type: GET_SURGE_EVENTS,
        payload: {
          urlParam: {
            pageIndex: 0,
          },
        },
      });
    }
  }, [activeTab]);

  useEffect(() => {
    dispatch({
      type: GET_SURGE_EVENTS,
      payload: {
        urlParam: {
          pageIndex: 0,
        },
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (createEvent !== null) {
      dispatch({
        type: CREATE_SURGE_EVENT_EMPTY,
      });
      setIsShowEventsTable(true);
      setIsShowCreateEventForm(false);
      setCurrentSelectedDetails(null);

      dispatch({
        type: GET_SURGE_EVENTS,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          },
        },
      });
    }
  }, [createEvent]);

  useEffect(() => {
    if (updateEvent !== null) {
      dispatch({
        type: UPDATE_SURGE_EVENT_EMPTY,
      });
      setIsShowEventsTable(true);
      setIsShowCreateEventForm(false);
      setCurrentSelectedDetails(null);

      dispatch({
        type: GET_SURGE_EVENTS,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          },
        },
      });
    }
  }, [updateEvent]);

  useEffect(() => {
    if (projectsList === null) {
      dispatch({
        type: GET_PROJECTS_LIST,
        payload: {
          urlParam: {
            pageIndex: 0,
            pageSize: 100,
          },
        },
      });
    }
  }, [dispatch]);

  /* ---------------------------- ON SUBMIT CAMPAIGN HANDLER ---------------------------- */
  const onSubmitEventForm = (data, flag) => {
    if (flag === `New`) {
      dispatch({
        type: CREATE_SURGE_EVENT,
        payload: {
          data: {
            ...data,
          },
        },
      });
    } else if (flag === `Update`) {
      dispatch({
        type: UPDATE_SURGE_EVENT,
        payload: {
          data: {
            ...data,
          },
        },
      });
    } else if (flag === `DISABLE`) {
      dispatch({
        type: UPDATE_SURGE_EVENT,
        payload: {
          data: {
            ...data,
            isActive: false,
          },
        },
      });
    } else if (flag === `ENABLE`) {
      dispatch({
        type: UPDATE_SURGE_EVENT,
        payload: {
          data: {
            ...data,
            isActive: true,
          },
        },
      });
    }
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedDetails(params?.details);

    if (params?.actionType === `UPDATE`) {
      setIsShowEventsTable(false);
      setIsShowCreateEventForm(true);
    } else if (params?.actionType === `DISABLE`) {
      onSubmitEventForm(params?.details, `DISABLE`);
    } else if (params?.actionType === `ENABLE`) {
      onSubmitEventForm(params?.details, `ENABLE`);
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentSelectedDetails(null);

    dispatch({
      type: GET_SURGE_EVENTS,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (projectsListLoading || eventsLoading || createEventLoading || updateEventLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [projectsListLoading, eventsLoading, createEventLoading, updateEventLoading]);

  /* ---------------------------- BACK BUTTON HANDLER ---------------------------- */
  const backBtnHandler = () => {
    setIsShowEventsTable(true);
    setIsShowCreateEventForm(false);
    setCurrentSelectedDetails(null);
  };

  /* ---------------------------- CREATE EVENT FORM HANDLER ---------------------------- */
  const createNewEventHandler = () => {
    setIsShowEventsTable(false);
    setIsShowCreateEventForm(true);
  };

  return (
    <>
      {loading && <Loader />}

      {isShowCreateEventForm && (
        <CreateEventForm projects={projectsList} onSubmit={onSubmitEventForm} eventData={currentSelectedDetails} onBack={backBtnHandler} />
      )}

      {isShowEventsTable && (
        <EventsTable
          events={events?.results}
          createNewEvent={createNewEventHandler}
          totalData={events?.count}
          pageHandler={paginationHandler}
          pageNumber={currentPageNumber}
          actionHandler={tableActionHandler}
        />
      )}
    </>
  );
};

export default Events;
