import { useState } from 'react';
import { useDispatch } from 'react-redux';

import moment from 'moment';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import { Col, Label, Row } from 'reactstrap';

import AdvancedFilter from './AdvancedFilter';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Constant } from '../../../../../../../../Helpers/constant';
import { GET_TRACKER_DATA } from '../../../../../../../../../store/actions';
import { customHeaders, indianProjectsBaseURL } from '../../../../../../../../Config';

const customSelectStyle = {
  singleValue: (provided, state) => ({
    ...provided,
    color: 'black',
    fontSize: '13px',
  }),
  placeholder: styles => ({
    ...styles,
    color: 'black',
  }),
  menu: styles => ({
    ...styles,
    borderRadius: 0,
    width: '100%',
    border: 'none',
  }),

  dropdownIndicator: styles => ({ ...styles, color: 'black' }),
  indicatorSeparator: styles => ({ ...styles, display: 'none' }),
};

const statusFilterValue = [
  {
    label: `Draft`,
    value: `1`,
  },
  {
    label: `Awaiting approval`,
    value: `2`,
  },
  {
    label: `Discarded`,
    value: `3`,
  },
  {
    label: `Live`,
    value: `4`,
  },
];

const TrackerFilter = ({ filters, setFilters, currentPageNumber, setCurrentPageNumber }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [isShowAdvancedFilter, setIsShowAdvancedFilter] = useState(false);

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedFilters = { ...filters };

    switch (label) {
      case 'tracker-status':
        updatedFilters = {
          ...filters,
          status: data,
        };
        break;

      case 'uploadBy':
        updatedFilters = {
          ...filters,
          uploadBy: data,
        };
        break;

      case 'dateRange':
        updatedFilters = {
          ...filters,
          startDate: data === '' ? '' : moment(new Date(data[0])).format('Y-MM-DD'),
          endDate: data === '' ? '' : moment(new Date(data[1])).format('Y-MM-DD'),
        };
        break;

      default:
      // nothing
    }
    setFilters(updatedFilters);
    setCurrentPageNumber(0);

    let urlParams = {
      status: updatedFilters?.status === `` ? null : updatedFilters?.status?.value,
      startDate: updatedFilters?.startDate === '' || updatedFilters?.startDate === 'Invalid date' ? null : updatedFilters?.startDate,
      endDate: updatedFilters?.endDate === '' || updatedFilters?.endDate === 'Invalid date' ? null : updatedFilters?.endDate,
      uploadBy: updatedFilters?.uploadBy === '' ? null : updatedFilters?.uploadBy?.value,
      facilityName: '',
      limit: Constant?.TABLE_PAGE_SIZE,
      skip: 0,
      orderBy: `_created_at`,
      fileType: `BOOKING-RECON`,
    };

    dispatch({
      type: GET_TRACKER_DATA,
      payload: {
        urlParam: {
          ...urlParams,
        },
      },
    });
  };

  /* ---------------------------- TYPED USER HANDLER ---------------------------- */
  async function loadUsers(search) {
    const response = await fetch(`${indianProjectsBaseURL}/admin/users?name=${search}`, {
      method: `GET`,
      headers: new Headers({
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      }),
    });
    const responseData = await response.json();

    return {
      options: responseData?.results?.map((data, index) => {
        return {
          value: data.objectId,
          label: `${data?.name}`,
        };
      }),
      hasMore: false,
    };
  }

  return (
    <>
      {isShowAdvancedFilter && <AdvancedFilter filters={filters} setFilters={setFilters} onClose={() => setIsShowAdvancedFilter(false)} />}
      {/* <button onClick={() => console.log({ filters })}>get state</button> */}

      <Row className="my-4">
        <Col md={12}>
          <h5>Filters -</h5>
        </Col>

        <Col xl={3} lg={3} md={6} sm={6} xs={12} className="mb-3">
          <Label htmlFor="tracker-status" className="form-label">
            Status
          </Label>
          <Select
            styles={{
              ...customSelectStyle,
            }}
            id="tracker-status"
            name="tracker-status"
            options={statusFilterValue}
            value={filters?.status}
            onChange={data => inputSelectHandler(`tracker-status`, data)}
            isClearable={true}
          />
        </Col>

        <Col xl={3} lg={3} md={6} sm={6} xs={12} className="mb-3">
          <Label htmlFor="status" className="form-label">
            Date Range
          </Label>

          <div className="position-relative">
            <Flatpickr
              name="date"
              className="form-control cust-flatpickr"
              options={{
                mode: 'range',
                dateFormat: 'Y-m-d',
                maxDate: 'today',
              }}
              placeholder="Select..."
              id="date-select"
              value={[filters?.startDate, filters?.endDate]}
              onChange={date => inputSelectHandler(`dateRange`, date)}
            />

            <div className="date-icons">
              {filters?.startDate !== '' && (
                <span className="cursor-pointer" onClick={date => inputSelectHandler(`dateRange`, '')}>
                  <CloseIcon />
                </span>
              )}
              <label className="date-picker-icon" htmlFor="date-select">
                <CalenderIcon />
              </label>
            </div>
          </div>
        </Col>

        <Col xl={3} lg={3} md={6} sm={6} xs={12}>
          <Label htmlFor="status" className="form-label">
            Uploaded by
          </Label>
          <AsyncPaginate
            debounceTimeout={500}
            value={filters?.uploadBy}
            loadOptions={loadUsers}
            onChange={data => inputSelectHandler(`uploadBy`, data)}
            isMulti={false}
            closeMenuOnSelect={true}
            noOptionsMessage={() => 'No results found'}
            cacheUniqs={[['code']]}
            placeholder="Select..."
            isClearable={true}
          />
        </Col>

        <Col xl={3} lg={3} md={6} sm={6} xs={12}>
          <Label htmlFor="status" className="form-label">
            &nbsp;
          </Label>
          <div
            className="mx-1 text-muted border-dotted rounded p-1 cursor-pointer"
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '100%',
            }}
            onClick={() => setIsShowAdvancedFilter(true)}
          >
            <i className="ri-add-line align-middle fs-18 me-2"></i>
            Advanced Filters
          </div>
        </Col>
      </Row>
    </>
  );
};

export default TrackerFilter;

function CloseIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="15px" height="15px">
      <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z" />
    </svg>
  );
}

function CalenderIcon() {
  return (
    <svg width={15} height={16} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 0.5V2.5H13V0.5H15V2.5H19C19.5523 2.5 20 2.94772 20 3.5V19.5C20 20.0523 19.5523 20.5 19 20.5H1C0.44772 20.5 0 20.0523 0 19.5V3.5C0 2.94772 0.44772 2.5 1 2.5H5V0.5H7ZM18 10.5H2V18.5H18V10.5ZM9 12.5V16.5H4V12.5H9ZM5 4.5H2V8.5H18V4.5H15V6.5H13V4.5H7V6.5H5V4.5Z"
        fill="black"
      />
    </svg>
  );
}
