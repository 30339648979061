import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Label, Modal, ModalBody, ModalFooter, Row, Spinner } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import Select from 'react-select';
import SimpleBar from 'simplebar-react';

const AttachmentModal = ({ onClose }) => {
  const [selectedFiles, setselectedFiles] = useState([]);

  const handleAcceptedFiles = files => {
    const file = files[0];
    const allowedExtensions = ['xls', 'xlsx', 'csv'];
    const fileExtension = file?.name?.split('.')?.pop()?.toLowerCase();

    if (allowedExtensions?.includes(fileExtension)) {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        }),
      );
      setselectedFiles(files);
    } else {
      cogoToast.error('Kindly upload proper file!');
    }
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  return (
    <Modal isOpen={true} id="restart-confirmation" size="sm" className="modal-dialog-bottom-right">
      <div className="modal-header p-3">
        <h5 className="modal-title">Upload</h5>
        <Button
          type="button"
          onClick={() => {
            onClose();
          }}
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        <Row>
          <Dropzone
            onDrop={acceptedFiles => {
              handleAcceptedFiles(acceptedFiles);
            }}
            className="cursor-pointer"
            accept="image/*,audio/*,video/*"
            canRemove={true}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone dz-clickable pt-3">
                <Col>
                  <div className="mb-3">
                    <Label htmlFor="type" className="form-label">
                      Choose file type-
                    </Label>
                    <Select
                      id="type"
                      name="type"
                      // options={passTypes ?? []} value={state?.type} onChange={data => inputSelectHandler(`type`, data)}
                    />
                  </div>
                </Col>
                <input {...getInputProps()} />
                <div className="dz-message needsclick" {...getRootProps()}>
                  <div className="d-flex align-items-center px-3">
                    <i className="display-4 text-muted ri-upload-cloud-2-fill fs-3" />
                    <p className="mb-0 fs-12 mx-2">Click to Upload or drag and drop</p>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>

          <div className="list-unstyled mb-0" id="file-previews">
            {selectedFiles.map((f, i) => {
              return (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={i + '-file'}>
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col>
                        <Link to="#" className="text-muted font-weight-bold">
                          {f.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{f.formattedSize}</strong>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Card>
              );
            })}
          </div>
        </Row>

        <Row className="mt-4">
          <Col>
            <div className="mx-n3">
              <SimpleBar autoHide={false} style={{ maxHeight: '33vh' }} className="px-3">
                <Link to="#" className="list-group-item py-3">
                  <div className="d-flex align-items-start">
                    <div className="flex-shrink-0 align-self-center me-1">
                      <div className="avatar-xs">
                        <i className="display-4 text-black bx bxs-file-pdf fs-3" />
                      </div>
                    </div>

                    <div className="flex-grow-1 overflow-hidden">
                      <p className="text-truncate fs-12 mb-1">HannahBusing_Resume.pdf</p>
                      <p className="text-truncate fs-10 mb-0">200 KB</p>
                    </div>
                    <div className="avatar-xs">
                      <i className="display-4 bx bxs-trash fs-4 text-danger" />
                    </div>
                  </div>
                </Link>

                <Link to="#" className="list-group-item py-3">
                  <div className="d-flex align-items-start">
                    <div className="flex-shrink-0 align-self-center me-1">
                      <div className="avatar-xs">
                        <i className="display-4 text-black bx bxs-file-pdf fs-3" />
                      </div>
                    </div>

                    <div className="flex-grow-1 overflow-hidden">
                      <p className="text-truncate fs-12 mb-1">HannahBusing_Resume.pdf</p>
                      <p className="text-truncate fs-10 mb-0">200 KB</p>
                    </div>
                    <div className="avatar-xs">
                      <i className="display-4 bx bxs-trash fs-4" />
                    </div>
                  </div>
                </Link>

                <Link to="#" className="list-group-item py-3">
                  <div className="d-flex align-items-start">
                    <div className="flex-shrink-0 align-self-center me-1">
                      <div className="avatar-xs">
                        <i className="display-4 text-black bx bxs-file-pdf fs-3" />
                      </div>
                    </div>

                    <div className="flex-grow-1 overflow-hidden">
                      <p className="text-truncate fs-12 mb-1">HannahBusing_Resume.pdf</p>
                      <p className="text-truncate fs-10 mb-0">200 KB</p>
                    </div>
                    <div className="avatar-xs">
                      <i className="display-4 bx bxs-trash fs-4" />
                    </div>
                  </div>
                </Link>

                <Link to="#" className="list-group-item py-3">
                  <div className="d-flex align-items-start">
                    <div className="flex-shrink-0 align-self-center me-1">
                      <div className="avatar-xs">
                        <i className="display-4 text-black bx bxs-file-pdf fs-3" />
                      </div>
                    </div>

                    <div className="flex-grow-1 overflow-hidden">
                      <p className="text-truncate fs-12 mb-1">HannahBusing_Resume.pdf</p>
                      <p className="text-truncate fs-10 mb-0">200 KB</p>
                    </div>
                    <div className="avatar-xs">
                      <i className="display-4 bx bxs-trash fs-4" />
                    </div>
                  </div>
                </Link>

                <Link to="#" className="list-group-item py-3">
                  <div className="d-flex align-items-start">
                    <div className="flex-shrink-0 align-self-center me-1">
                      <div className="avatar-xs">
                        <i className="display-4 text-black bx bxs-file-pdf fs-3" />
                      </div>
                    </div>

                    <div className="flex-grow-1 overflow-hidden">
                      <p className="text-truncate fs-12 mb-1">HannahBusing_Resume.pdf</p>
                      <p className="text-truncate fs-10 mb-0">200 KB</p>
                    </div>
                    <div className="avatar-xs">
                      <i className="display-4 bx bxs-trash fs-4" />
                    </div>
                  </div>
                </Link>

                <Link to="#" className="list-group-item py-3">
                  <div className="d-flex align-items-start">
                    <div className="flex-shrink-0 align-self-center me-1">
                      <div className="avatar-xs">
                        <i className="display-4 text-black bx bxs-file-pdf fs-3" />
                      </div>
                    </div>

                    <div className="flex-grow-1 overflow-hidden">
                      <p className="text-truncate fs-12 mb-1">HannahBusing_Resume.pdf</p>
                      <p className="text-truncate fs-10 mb-0">200 KB</p>
                    </div>
                    <div className="avatar-xs">
                      <i className="display-4 bx bxs-trash fs-4" />
                    </div>
                  </div>
                </Link>

                <Link to="#" className="list-group-item py-3">
                  <div className="d-flex align-items-start">
                    <div className="flex-shrink-0 align-self-center me-1">
                      <div className="avatar-xs">
                        <i className="display-4 text-black bx bxs-file-pdf fs-3" />
                      </div>
                    </div>

                    <div className="flex-grow-1 overflow-hidden">
                      <p className="text-truncate fs-12 mb-1">HannahBusing_Resume.pdf</p>
                      <p className="text-truncate fs-10 mb-0">200 KB</p>
                    </div>
                    <div className="avatar-xs">
                      <i className="display-4 bx bxs-trash fs-4" />
                    </div>
                  </div>
                </Link>

                <Link to="#" className="list-group-item py-3">
                  <div className="d-flex align-items-start">
                    <div className="flex-shrink-0 align-self-center me-1">
                      <div className="avatar-xs">
                        <i className="display-4 text-black bx bxs-file-pdf fs-3" />
                      </div>
                    </div>

                    <div className="flex-grow-1 overflow-hidden">
                      <p className="text-truncate fs-12 mb-1">HannahBusing_Resume.pdf</p>
                      <p className="text-truncate fs-10 mb-0">200 KB</p>
                    </div>
                    <div className="avatar-xs">
                      <i className="display-4 bx bxs-trash fs-4" />
                    </div>
                  </div>
                </Link>
              </SimpleBar>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default AttachmentModal;
