import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Flatpickr from 'react-flatpickr';

import { Card, CardBody, Col, Form, Label, Row, CardFooter, Input, Container, OffcanvasBody, OffcanvasHeader, Offcanvas } from 'reactstrap';
import { PreviewCardHeader } from '../../../../../../Common';
import { GET_BOOKING_TYPES_LIST, GET_CATEGORY_EMPTY } from '../../../../../../../store/application/actionType';
import Select from 'react-select';
import { isValidAplha, isValidAplhaWithSpecialCharacter } from '../../../../../../Helpers/Helpers';

const channelType = [
  { label: 'Sms', value: 'sms' },
  { label: 'Whatsapp', value: 'whatsapp' },
  { label: 'Email', value: 'email' },
];
const priority_type = [
  { label: 'Transactional', value: 'transactional' },
  { label: 'Promotional', value: 'promotional' },
  { label: 'Delayed', value: 'delayed' },
];
const CustomFilter = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    event_type: ``,
    typeId: ``,
    template_name: ``,
    channel: ``,
    priority_type: ``,
  };
  const [filter, setFilter] = useState(initialState);
  const [isResetDisabled, setIsResetDisabled] = useState(true);

  const [bookingTypesData, setBookingTypesData] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Booking Types
  const { bookingTypes } = useSelector(state => ({
    bookingTypes: state?.BookingTypes?.data,
  }));

  useEffect(() => {
    if (bookingTypes === null) {
      dispatch({
        type: GET_BOOKING_TYPES_LIST,
      });
    }
  }, []);

  useEffect(() => {
    if (props?.appliedFilters !== null) {
      setFilter(props?.appliedFilters);
      validationCheck(props?.appliedFilters);
    }
  }, [props?.appliedFilters]);

  useEffect(() => {
    if (bookingTypes !== null) {
      setBookingTypesData(
        bookingTypes?.results?.map(bookingType => {
          return {
            value: bookingType?.objectId,
            label: bookingType?.name,
            data: bookingType,
          };
        }),
      );
    }
  }, [bookingTypes]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...filter };

    switch (name) {
      case 'event_type':
        updatedState = {
          ...filter,
          // event_type: isValidAplhaWithSpecialCharacter(value) ? value : filter?.event_type,
          event_type: value,
        };
        break;
      case 'template_name':
        updatedState = {
          ...filter,
          // template_name: isValidAplhaWithSpecialCharacter(value) ? value : filter?.template_name,
          template_name: value,
        };
        break;

      default:
      // do nothing
    }

    setFilter(updatedState);
    validationCheck({ ...updatedState });
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...filter };

    if (label === `typeId`) {
      updatedState = {
        ...filter,
        typeId: data,
      };
    } else if (label === `channel`) {
      updatedState = {
        ...filter,
        channel: data,
      };
    } else if (label === `priority_type`) {
      updatedState = {
        ...filter,
        priority_type: data,
      };
    }
    setFilter(updatedState);
    validationCheck({ ...updatedState });
  };

  /* ---------------------------- DATE SELECT HANDLER ---------------------------- */
  const datePickHandler = (flag, date) => {
    let updatedState = { ...filter };

    switch (flag) {
      case 'updateDate':
        updatedState = {
          ...filter,
          updateDate: new Date(date).toISOString(),
        };
        break;

      default:
      // do nothing
    }

    setFilter(updatedState);
    validationCheck(updatedState);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data?.event_type !== `` || data?.updateDate !== `` || data?.typeId !== `` || data?.template_name !== ``) {
      setIsResetDisabled(false);
    } else {
      setIsResetDisabled(true);
    }
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    props.filterHandler({ ...filter });
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter({ ...initialState });
    setIsResetDisabled(true);

    dispatch({
      type: GET_CATEGORY_EMPTY,
    });
  };

  return (
    <>
      <Offcanvas direction="end" isOpen={props.show} id="offcanvasExample" toggle={() => props.onCloseClick()}>
        <OffcanvasHeader className="bg-light" toggle={() => props.onCloseClick()}>
          Template Filters
        </OffcanvasHeader>
        <OffcanvasBody>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="name" className="form-label">
                  Event Name
                </Label>
                <Input
                  id="event_type"
                  name="event_type"
                  type="text"
                  className="form-control"
                  value={filter?.event_type}
                  onChange={inputChangeHandler}
                  autoComplete="off"
                />
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="name" className="form-label">
                  Template Name
                </Label>
                <Input
                  id="template_name"
                  name="template_name"
                  type="text"
                  className="form-control"
                  value={filter?.template_name}
                  onChange={inputChangeHandler}
                  autoComplete="off"
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="bookingType" className="form-label">
                  Channel
                </Label>
                <Select
                  id="channel"
                  name="channel"
                  options={channelType ?? []}
                  value={filter?.channel}
                  onChange={data => inputSelectHandler(`channel`, data)}
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="bookingType" className="form-label">
                  Priority Type
                </Label>
                <Select
                  id="priority_type"
                  name="priority_type"
                  options={priority_type ?? []}
                  value={filter?.priority_type}
                  onChange={data => inputSelectHandler(`priority_type`, data)}
                />
              </div>
            </Col>
          </Row>
        </OffcanvasBody>

        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
          <button
            className="btn btn-light w-100"
            onClick={resetFilterHandler}
            // disabled={isResetDisabled}
          >
            Reset Filter
          </button>

          <button className="btn btn-primary w-100" onClick={filterSubmitHandler}>
            Search
          </button>
        </div>
      </Offcanvas>
    </>
  );
};

export default CustomFilter;
