import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import axios from 'axios';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Row, Col, Button, Modal, ModalBody, Table, ModalFooter } from 'reactstrap';

import { Constant } from '../../../../../../../Helpers/constant';
import { indianProjectsBaseURL } from '../../../../../../../Config';
import { UPDATE_BOOKING } from '../../../../../../../../store/application/actionType';

const VendorAssignModal = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [bookings, setBookings] = useState([]);
  const [modal_varying1, setmodal_varying1] = useState(true);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  useEffect(() => {
    if (props?.data !== null) {
      if (props?.isBulkChangeVendor) {
        setBookings(
          props?.data?.map(booking => {
            return {
              ...booking,
              vendorDetails: { value: booking?.vendor?.vendorId, label: booking?.vendor?.name, completeData: { firstName: booking?.vendor?.name } },
            };
          }),
        );
      } else {
        setBookings(
          props?.data?.map(booking => {
            return {
              ...booking,
              vendorDetails: null,
            };
          }),
        );
      }
    }
  }, [props]);

  useEffect(() => {
    validationCheck(bookings);
  }, [bookings]);

  const getWorkflow = currentStatus => {
    if (Constant?.BOOKING_STATUS?.PENDING === currentStatus) {
      return 'assign-vendor';
    } else if (Constant?.BOOKING_STATUS?.PROCESSED === currentStatus) {
      return 'update-booking';
    } else if (Constant?.BOOKING_STATUS?.REJECTED === currentStatus) {
      return 'change-vendor';
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const requestBookings = bookings?.map(booking => {
      return {
        objectId: booking?.bookingId,
        detailedBookingId: booking?.detailedBookingId,
        vendor: {
          ...booking?.vendor,
          bookingId: booking?.bookingId,
          vendorId: booking?.vendorDetails?.value,
          name: `${booking?.vendorDetails?.completeData?.firstName}`,
        },
        process: getWorkflow(booking?.bookingStatusCode),
      };
    });

    dispatch({
      type: UPDATE_BOOKING,
      payload: {
        data: {
          projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
          bookingTypeId: Constant?.BOOKING_TYPES?.meetAndGreetBookings?.objectId,
          bookingsData: requestBookings,
        },
      },
    });
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const cancelHandler = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  /* ---------------------------- GET VENDORS ---------------------------- */
  async function loadVendors(search) {
    return await axios({
      method: `GET`,
      // url: `${uaeProjectsBaseURL}/admin/services`,
      url: `${indianProjectsBaseURL}/admin/v1/vendors`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        'Content-Type': 'application/json',
        'Project-Code': `ADMIN_ACCESS`,
      },
      params: {
        firstName: search,
        pageIndex: 0,
        pageSize: 15,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.vendors?.map(vendor => {
            return {
              value: vendor?.userId,
              label: `${vendor?.firstName ?? ''}`,
              completeData: vendor,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  /* ---------------------------- ON SELECT USER ---------------------------- */
  const onSelectVendor = (event, index) => {
    let tempBooking = [];
    let selectedChangingBooking = null;
    tempBooking = [...bookings];
    selectedChangingBooking = {
      ...tempBooking[index],
      vendorDetails: event,
    };
    tempBooking?.splice(index, 1, selectedChangingBooking);
    validationCheck(tempBooking);
    setBookings(tempBooking);
  };

  /* ---------------------------- VALIDATION HANDLER ---------------------------- */
  const validationCheck = data => {
    let flag = false;
    for (let i = 0; i < data?.length; i++) {
      if (data[i]?.vendorDetails === null) {
        flag = true;
        break;
      }
    }
    flag ? setIsSubmitBtnDisabled(true) : setIsSubmitBtnDisabled(false);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      scrollable={true}
      id="exampleModal"
      size="lg"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Are you sure you want to go ahead?
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log({ bookings })}>get state</button> */}
        <form>
          <Row>
            <Col md={12} style={{ mixHeight: '500px' }}>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Booking ID</th>
                    <th> Name</th>
                    <th> Vendors</th>
                  </tr>
                </thead>
                <tbody>
                  {bookings &&
                    bookings?.map((booking, index) => (
                      <tr key={index}>
                        <th>{index + 1}.</th>
                        <td>{booking?.bookingId}</td>
                        <td>{booking?.user?.name}</td>
                        <td>
                          <AsyncPaginate
                            debounceTimeout={500}
                            value={booking?.vendorDetails}
                            loadOptions={loadVendors}
                            onChange={e => onSelectVendor(e, index)}
                            isMulti={false}
                            closeMenuOnSelect={true}
                            noOptionsMessage={() => 'No results found'}
                            cacheUniqs={[['code']]}
                            placeholder=""
                            menuPosition="fixed"
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <p className="text-muted m-0">Once you will click on proceed, you will not be able to change previous state.</p>
          </Row>
        </form>
      </ModalBody>

      <ModalFooter className="pb-0">
        <div className="modal-footer mx-auto">
          <Button className="btn btn-light overlay-disable" color="primary" onClick={cancelHandler}>
            Cancel
          </Button>
          <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
            Sure
          </Button>
        </div>
      </ModalFooter>
      {/* <div className="modal-footer d-flex justify-content-center">
      <Button
        className="overlay-disable"
        color="primary"
        onClick={submitHandler}
        disabled={isSubmitBtnDisabled}
      >
        Submit
      </Button>
    </div> */}
    </Modal>
  );
};

export default VendorAssignModal;
