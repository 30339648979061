import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import fetch from './../../fetch';
import { baseUrl, customHeaders } from '../../../../Containers/Config/index.js';

import { GET_COUPON_ASSIGNED_STATUS, GET_COUPON_ASSIGNED_STATUS_SUCCESS, GET_COUPON_ASSIGNED_STATUS_ERROR } from './../../actionType.js';
import fetchBooking from './../../fetch';
import { Constant } from '../../../../Containers/Helpers/constant';

function* getCouponAssignedStatus({ payload }) {
  try {
    const response = yield call(fetchBooking, {
      url: `${baseUrl}/admin/assign-coupons`,
      method: 'PUT',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
    });

    if (response?.statusCode === undefined) {
      yield put({ type: GET_COUPON_ASSIGNED_STATUS_SUCCESS, payload: response });
    } else {
      yield put({
        type: GET_COUPON_ASSIGNED_STATUS_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_COUPON_ASSIGNED_STATUS_ERROR, payload: error });
  }
}

export function* watchCouponAssignedStatus() {
  yield takeEvery(GET_COUPON_ASSIGNED_STATUS, getCouponAssignedStatus);
}

function* getCouponAssignedStatusSaga() {
  yield all([fork(watchCouponAssignedStatus)]);
}

export default getCouponAssignedStatusSaga;
