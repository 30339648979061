export function CloseIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="15px"
            height="15px"
        >
            {" "}
            <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z" />
        </svg>
    );
}
export function CalenderIcon() {
    return (
        <svg
            width={15}
            height={16}
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7 0.5V2.5H13V0.5H15V2.5H19C19.5523 2.5 20 2.94772 20 3.5V19.5C20 20.0523 19.5523 20.5 19 20.5H1C0.44772 20.5 0 20.0523 0 19.5V3.5C0 2.94772 0.44772 2.5 1 2.5H5V0.5H7ZM18 10.5H2V18.5H18V10.5ZM9 12.5V16.5H4V12.5H9ZM5 4.5H2V8.5H18V4.5H15V6.5H13V4.5H7V6.5H5V4.5Z"
                fill="black"
            />
        </svg>
    );
}