import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';

import { GET_USER_RIGHTS, GET_USER_RIGHTS_SUCCESS, GET_USER_RIGHTS_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getAllRights({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/admin/facilities`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_USER_RIGHTS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_USER_RIGHTS_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_USER_RIGHTS_ERROR, payload: error });
  }
}

export function* watchGetAllRights() {
  yield takeEvery(GET_USER_RIGHTS, getAllRights);
}

function* getAllRightsSaga() {
  yield all([fork(watchGetAllRights)]);
}

export default getAllRightsSaga;
