import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';

import { BreadCrumb, Loader } from '../../../../../Common';
import RecordedDataTable from './Components/RecordedDataTable';
import UpdateRecordModal from './Components/UpdateRecordModal';
import { Constant } from '../../../../../Helpers/constant';
import RecordsFilter from './Components/RecordsFilter';
import FileSummaryMetrices from './Components/FileSummaryMetrices';
import PaginationSection from './Components/PaginationSection';
import ApprovalStatus from './Components/ApprovalStatus';
import {
  APPROVE_REJECT_RECORDS,
  DELETE_TRACKER_RECORD,
  GET_RECORD_DATA,
  GET_RECORD_DATA_EMPTY,
  PUBLISH_DATA_WITH_AUTH,
  PUBLISH_DATA_WITH_AUTH_EMPTY,
  PUBLISH_RECORD_DATA,
  PUBLISH_RECORD_DATA_EMPTY,
  SEND_APPROVAL_MAIL,
  SEND_APPROVAL_MAIL_EMPTY,
  UPDATE_RECORD_DATA_EMPTY,
} from '../../../../../../store/actions';
import RestartConfirmation from './Components/Modals/RestartConfirmation';
import OtpInputModal from './Components/Modals/OtpInputModal';
import { PermissionConstants } from '../../../../../Helpers/Constants/permissions';
import { findGMUserLevel, hasPermission, isAllowedGmUserToAction } from '../../../../../Helpers/Helpers';
import AttachmentModal from './Components/Modals/AttachmentModal';

const RecordData = ({ selectedTracker, onViewTrackerDetails }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- REDUX STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [isShowUpdateModal, setIsShowUpdateModal] = useState(false);

  const [restartConfirmationModal, setIsRestartConfirmation] = useState(false);
  const [otpInputModal, setOtpInputModal] = useState(false);

  const [currentSelectedDetails, setCurrentSelectedDetails] = useState(null);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  const [appliedFilters, setAppliedFilters] = useState({ id: 1, name: 'All records', status: ``, recordType: ``, errorType: `` });
  const [appliedFiltering, setAppliedFiltering] = useState({
    value: 2,
    label: `All records`,
  });

  const [isShowAttachmentModal, setIsShowAttachmentModal] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  //  RECORD DATA
  const { recordData, recordDataLoading } = useSelector(state => ({
    recordData: state?.GetRecordData?.data,
    recordDataLoading: state?.GetRecordData?.loading,
  }));

  //  UPDATE RECORD
  const { updateRecordStatus, updateRecordStatusLoading } = useSelector(state => ({
    updateRecordStatus: state?.UpdateRecordData?.data,
    updateRecordStatusLoading: state?.UpdateRecordData?.loading,
  }));

  //  PUBLISH RECORD
  const { publishRecord, publishRecordLoading } = useSelector(state => ({
    publishRecord: state?.PublishRecord?.data,
    publishRecordLoading: state?.PublishRecord?.loading,
  }));

  //  PUBLISH RECORD WITH AUTH
  const { publishRecordWithAuth, publishRecordWithAuthLoading } = useSelector(state => ({
    publishRecordWithAuth: state?.PublishDataWithAuth?.data,
    publishRecordWithAuthLoading: state?.PublishDataWithAuth?.loading,
  }));

  // APPROVAL MAIL
  const { sendApproval, sendApprovalLoading } = useSelector(state => ({
    sendApproval: state?.SendApprovalMail?.data,
    sendApprovalLoading: state?.SendApprovalMail?.loading,
  }));

  // APPROVE REJECT
  const { approveReject, approveRejectLoading } = useSelector(state => ({
    approveReject: state?.ApproveRejectRecords?.data,
    approveRejectLoading: state?.ApproveRejectRecords?.loading,
  }));

  //  DELETE RECORD
  const { deleteRecordLoading } = useSelector(state => ({
    deleteRecordLoading: state?.DeleteTrackerRecord?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_RECORD_DATA,
      payload: {
        urlParam: {
          fileTrackerId: `${selectedTracker?.objectId}`,
          configId: `${selectedTracker?.fileConfigId}`,
          skip: 0,
          limit: Constant?.TABLE_PAGE_SIZE,
        },
      },
    });

    return () => {
      dispatch({
        type: SEND_APPROVAL_MAIL_EMPTY,
      });

      dispatch({
        type: PUBLISH_RECORD_DATA_EMPTY,
      });

      dispatch({
        type: PUBLISH_DATA_WITH_AUTH_EMPTY,
      });

      dispatch({
        type: GET_RECORD_DATA_EMPTY,
      });

      dispatch({
        type: UPDATE_RECORD_DATA_EMPTY,
      });
    };
  }, []);

  useEffect(() => {
    if (updateRecordStatus !== null) {
      dispatch({
        type: UPDATE_RECORD_DATA_EMPTY,
      });
      setIsShowUpdateModal(false);
      setCurrentSelectedDetails(null);

      dispatch({
        type: GET_RECORD_DATA,
        payload: {
          urlParam: {
            fileTrackerId: `${selectedTracker?.objectId}`,
            configId: `${selectedTracker?.fileConfigId}`,
            skip: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            limit: Constant?.TABLE_PAGE_SIZE,
          },
        },
      });
    }
  }, [updateRecordStatus]);

  useEffect(() => {
    if (publishRecord !== null) {
      if (publishRecord?.isAuthRequired) {
        setOtpInputModal(true);
      } else {
        onViewTrackerDetails();

        dispatch({
          type: GET_RECORD_DATA_EMPTY,
        });
      }
    }
  }, [publishRecord]);

  useEffect(() => {
    if (sendApproval !== null) {
      dispatch({
        type: SEND_APPROVAL_MAIL_EMPTY,
      });

      dispatch({
        type: PUBLISH_RECORD_DATA_EMPTY,
      });

      dispatch({
        type: PUBLISH_DATA_WITH_AUTH_EMPTY,
      });

      onViewTrackerDetails();
      dispatch({
        type: GET_RECORD_DATA_EMPTY,
      });
    }
  }, [sendApproval]);

  useEffect(() => {
    if (publishRecordWithAuth !== null) {
      dispatch({
        type: PUBLISH_RECORD_DATA_EMPTY,
      });

      dispatch({
        type: PUBLISH_DATA_WITH_AUTH_EMPTY,
      });

      onViewTrackerDetails();
      dispatch({
        type: GET_RECORD_DATA_EMPTY,
      });
    }
  }, [publishRecordWithAuth]);

  useEffect(() => {
    if (approveReject !== null) {
      dispatch({
        type: GET_RECORD_DATA,
        payload: {
          urlParam: {
            fileTrackerId: `${selectedTracker?.objectId}`,
            configId: `${selectedTracker?.fileConfigId}`,
            skip: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            limit: Constant?.TABLE_PAGE_SIZE,
          },
        },
      });
    }
  }, [approveReject]);

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);

    dispatch({
      type: GET_RECORD_DATA,
      payload: {
        urlParam: {
          fileTrackerId: `${selectedTracker?.objectId}`,
          configId: `${selectedTracker?.fileConfigId}`,
          skip: pageNumber * Constant.TABLE_PAGE_SIZE,
          limit: Constant?.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedDetails(params?.details);

    if (params?.actionType === `EDIT`) {
      setIsShowUpdateModal(true);
    }
  };

  /* ---------------------------- UPDATE BULK HANDLER ---------------------------- */
  const updateBulkHandler = (label, recordsData, dataRemarks) => {
    let tempIdsForApproval = [];
    let tempIdsForReject = [];
    let tempIsApproveEntireBatch = false;
    let remarks = '';

    if (label === `APPROVE`) {
      tempIdsForApproval = recordsData?.map(rData => rData?.objectId);
      remarks = recordsData?.[0]?.['remarks'];
    } else if (label === `REJECT`) {
      tempIdsForReject = recordsData?.map(rData => rData?.objectId);
      remarks = recordsData?.[0]?.['remarks'];
    } else if (label === `APPROVE_ENTIRE_BATCH`) {
      tempIsApproveEntireBatch = true;
      remarks = dataRemarks;
    } else if (label === `SINGLE_RECORD_APPROVE`) {
      tempIdsForApproval = recordsData?.map(rData => rData?.objectId);
      remarks = recordsData?.[0]?.['remarks'];
    } else if (label === `SINGLE_RECORD_REJECT`) {
      tempIdsForReject = recordsData?.map(rData => rData?.objectId);
      remarks = recordsData?.[0]?.['remarks'];
    }

    const payload = {
      fileTrackerId: `${selectedTracker?.objectId}`,
      configId: `${selectedTracker?.fileConfigId}`,
      idsForApproval: [...tempIdsForApproval],
      idsForReject: [...tempIdsForReject],
      isApproveEntireBatch: tempIsApproveEntireBatch,
      remarks: remarks,
    };

    dispatch({
      type: APPROVE_REJECT_RECORDS,
      payload: {
        data: {
          ...payload,
        },
      },
    });
  };

  /* ---------------------------- PUBLISH HANDLER ---------------------------- */
  const publishHandler = () => {
    dispatch({
      type: PUBLISH_RECORD_DATA,
      payload: {
        data: {
          fileTrackerId: `${selectedTracker?.objectId}`,
          configId: `${selectedTracker?.fileConfigId}`,
        },
      },
    });
  };

  /* ---------------------------- APPROVAL HANDLER ---------------------------- */
  const sendForApprovalHandler = () => {
    dispatch({
      type: SEND_APPROVAL_MAIL,
      payload: {
        data: {
          fileTrackerId: `${selectedTracker?.objectId}`,
          configId: `${selectedTracker?.fileConfigId}`,
        },
      },
    });
  };

  /* ---------------------------- ONCLOSE UPDATE MODAL ---------------------------- */
  const onCloseUpdateData = () => {
    setIsShowUpdateModal(false);
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push('/dashboard');
    } else if (title === `Master upload`) {
      onViewTrackerDetails();
      dispatch({
        type: GET_RECORD_DATA_EMPTY,
      });
    }
  };

  /* ---------------------------- RESTART BUTTON HANDLER ---------------------------- */
  const restartHandler = () => {
    setIsRestartConfirmation(true);
  };

  /* ---------------------------- ON CONFIRMATION RESTART HANDLER ---------------------------- */
  const confirmRestartHandler = () => {
    setIsRestartConfirmation(false);

    dispatch({
      type: DELETE_TRACKER_RECORD,
      payload: {
        urlParam: {
          fileTrackerId: `${selectedTracker?.objectId}`,
          configId: `${selectedTracker?.fileConfigId}`,
        },
      },
    });
  };

  /* ---------------------------- OTP SUBMIT HANDLER ---------------------------- */
  const onConfirmOTPInput = otp => {
    dispatch({
      type: PUBLISH_DATA_WITH_AUTH,
      payload: {
        data: {
          fileTrackerId: `${selectedTracker?.objectId}`,
          configId: `${selectedTracker?.fileConfigId}`,
          otp: otp,
          token: `${publishRecord?.token}`,
        },
      },
    });
  };

  /* ---------------------------- ON FILTERING HANDLER ---------------------------- */
  const onApplyFilter = filters => {
    setAppliedFilters(filters);

    dispatch({
      type: GET_RECORD_DATA,
      payload: {
        urlParam: {
          fileTrackerId: `${selectedTracker?.objectId}`,
          configId: `${selectedTracker?.fileConfigId}`,
          status: filters?.status,
          recordType: filters?.recordType,
          errorType: filters?.errorType,
          skip: 0,
          limit: Constant?.TABLE_PAGE_SIZE,
        },
      },
    });

    setCurrentPageNumber(0);
  };

  const onApplyFiltering = filters => {
    setAppliedFiltering(filters);

    dispatch({
      type: GET_RECORD_DATA,
      payload: {
        urlParam: {
          fileTrackerId: `${selectedTracker?.objectId}`,
          configId: `${selectedTracker?.fileConfigId}`,
          state: filters?.value,
          skip: 0,
          limit: Constant?.TABLE_PAGE_SIZE,
        },
      },
    });

    setCurrentPageNumber(0);
  };

  /* ---------------------------- LOADER HANDLER ---------------------------- */
  useEffect(() => {
    if (
      recordDataLoading ||
      updateRecordStatusLoading ||
      publishRecordLoading ||
      sendApprovalLoading ||
      approveRejectLoading ||
      deleteRecordLoading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [recordDataLoading, updateRecordStatusLoading, publishRecordLoading, sendApprovalLoading, approveRejectLoading, deleteRecordLoading]);

  return (
    <Container fluid className="position-relative">
      {loading && <Loader />}
      {isShowUpdateModal && <UpdateRecordModal data={currentSelectedDetails} onClose={onCloseUpdateData} />}

      {restartConfirmationModal && <RestartConfirmation onClose={() => setIsRestartConfirmation(false)} onConfirm={confirmRestartHandler} />}
      {otpInputModal && (
        <OtpInputModal
          data={publishRecord}
          loading={publishRecordWithAuthLoading}
          onClose={() => setOtpInputModal(false)}
          onConfirm={onConfirmOTPInput}
        />
      )}

      {isShowAttachmentModal && <AttachmentModal onClose={() => setIsShowAttachmentModal(false)} />}

      <BreadCrumb title="Master upload" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Master upload', 'Preview']} />
      <Row>
        <Col lg={12}>
          <Card>
            <CardHeader className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <h4 className="card-title mb-0 d-inline-block">Preview</h4>
                <div
                  className="text-muted"
                  style={{
                    borderLeft: '2px solid',
                    margin: '0 10px',
                  }}
                >
                  &nbsp;
                </div>
                <h4 className="card-title mb-0 d-flex align-items-center">
                  <span className="fs-22">
                    <i className="ri-file-excel-2-line"></i>
                  </span>
                  &nbsp;
                  {selectedTracker?.fileName ?? ''}
                </h4>
              </div>

              {/* {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.ATTACHMENT_SUPPLY_DATA_GM_REPORT) && (
                <div>
                  <button type="button" className="btn btn-md btn-outline-primary border-dotted" onClick={() => setIsShowAttachmentModal(true)}>
                    <i className=" ri-attachment-line align-middle"></i>&nbsp; Attachment
                  </button>
                </div>
              )} */}
            </CardHeader>

            <CardBody>
              {/* <ApprovalStatus /> */}
              <FileSummaryMetrices data={recordData} />
              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.EDIT_RECORD_SUPPLY_DATA_GM_REPORT) && (
                <RecordsFilter appliedFilter={appliedFilters} onApply={onApplyFilter} />
              )}

              <RecordedDataTable
                data={recordData?.results}
                totalData={recordData?.count}
                pageHandler={paginationHandler}
                pageNumber={currentPageNumber}
                actionHandler={tableActionHandler}
                updateHandler={updateBulkHandler}
                appliedFilter={appliedFiltering}
                onApplyFilter={onApplyFiltering}
                selectedTracker={selectedTracker}
                userLevelData={isAllowedGmUserToAction(selectedTracker, findGMUserLevel(`SUPPLY-DATA`))}
              />
            </CardBody>

            <PaginationSection
              recordData={recordData}
              currentPageNumber={currentPageNumber}
              paginationHandler={paginationHandler}
              onRestart={restartHandler}
              publishHandler={publishHandler}
              approvalHandler={sendForApprovalHandler}
              selectedTracker={selectedTracker}
              userLevelData={isAllowedGmUserToAction(selectedTracker, findGMUserLevel(`SUPPLY-DATA`))}
            />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default RecordData;
