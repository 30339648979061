import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { Constant } from '../../../../../../Helpers/constant';

import { Button, Modal, ModalBody, ModalHeader, Col, Label, Input, Row } from 'reactstrap';

import { UPDATE_BOOKING } from '../../../../../../../store/application/actionType';

const ConfirmModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  //   const initialObj = {
  //     bookingDateTime: '',
  //     facility: {
  //       id: '',
  //       name: '',
  //     },
  //   }
  const [state, setState] = useState([]);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    setState({
      ...data,
      //   bookingDateTime: data?.timeOfPlay,
    });
  }, [data]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'remarks':
        updatedState = {
          ...state,
          remarks: value,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'confirmation-number':
        updatedState = {
          ...state,
          confirmationCode: value,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    // const date = data?.bookingDateTime?.split('T')[0]
    // let time = state.bookingDateTime
    // time = `${time}:00.000Z`

    dispatch({
      type: UPDATE_BOOKING,
      payload: {
        data: {
          projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
          bookingTypeId: Constant?.BOOKING_TYPES?.hireADriverBookings?.objectId,
          bookingsData: [
            {
              objectId: data?.bookingId,
              detailedBookingId: data?.detailedBookingId,
              // bookingDateTime: `${date}T${time}`,
              dateTimeOfService: {
                date: state?.dateTimeOfService?.date,
                time: state?.dateTimeOfService?.time,
              },
              remarks: state.remarks,
              confirmationCode: state?.confirmationCode,
              status: Constant?.BOOKING_STATUS?.CONFIRM,
              process: `confirm-booking`,
            },
          ],
        },
      },
    });

    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'dateAndTimeOfPlay':
        updatedState = {
          ...state,
          bookingDateTime: new Date(date).toISOString(),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data?.dateTimeOfService?.time.trim() === `` || data?.confirmationCode.trim() === `` || data?.remarks.trim() === ``) {
      setIsSubmitBtnDisabled(true);
    } else {
      setIsSubmitBtnDisabled(false);
    }
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Confirm Booking
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>
      <ModalBody>
        {/* <button onClick={() => console.log({ data, state })}>Get state</button> */}
        <form>
          <Row>
            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="name" className="form-label">
                    Booking ID
                  </Label>
                  <Input className="form-control" value={data?.bookingId} disabled />
                </div>
              </Col>
            </div>
            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="name" className="form-label">
                    User Name
                  </Label>
                  <Input className="form-control" value={data?.user?.name} disabled />
                </div>
              </Col>
            </div>
            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="name" className="form-label">
                    Confirmation No. <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    type="number"
                    onWheel={e => e.target.blur()}
                    className="form-control"
                    name="confirmation-number"
                    value={state?.confirmationCode}
                    onChange={inputChangeHandler}
                  />
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="date-of-play" className="form-label">
                    Confirmed Time <span className="lbl_mandatory">*</span>
                  </Label>
                  <div className="area_expandable">
                    <Input
                      value={state?.dateTimeOfService?.time}
                      className="form-control"
                      type="time"
                      onChange={e => {
                        const data = {
                          ...state,
                          dateTimeOfService: {
                            ...state?.dateTimeOfService,
                            time: e.target.value,
                          },
                          // bookingDateTime: e.target.value,
                        };
                        setState(data);
                        validationCheck(data);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </div>

            {/* <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="date-of-play" className="form-label">
                    Confirmed Time <span className="lbl_mandatory">*</span>
                  </Label>
                  <div className="area_expandable">
                    <Input
                      value={state?.bookingDateTime}
                      className="form-control"
                      type="time"
                      onChange={(e) => {
                        setState({
                          ...state,
                          bookingDateTime: e.target.value,
                        })
                      }}
                    />
                  </div>
                </div>
              </Col>
            </div> */}

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="name" className="form-label">
                    Remarks <span className="lbl_mandatory">*</span>
                  </Label>
                  <textarea className="form-control" name="remarks" value={state?.remarks} onChange={inputChangeHandler} />
                </div>
              </Col>
            </div>
          </Row>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
