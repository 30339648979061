import React, { useEffect, useState } from 'react';

import { Card, Col, Row, CardBody, Input, Label, CardHeader } from 'reactstrap';
import Select from 'react-select';
import { Error, PreviewCardHeader } from '../../../../../../../Common';
import { Constant } from '../../../../../../../Helpers/constant';

const CreateEventForm = ({ projects, onSubmit, eventData, onBack }) => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    objectId: '',
    programName: Constant?.CURRENT_SELECTED_PROGRAM?.name,
    programId: {
      value: Constant?.CURRENT_SELECTED_PROGRAM?.id,
      label: Constant?.CURRENT_SELECTED_PROGRAM?.name,
    },
    eventName: '',
    eventId: '',
    displayName: '',
    description: '',
    isActive: true,
  };
  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (eventData !== null) {
      setState({
        objectId: eventData?.objectId,
        programName: eventData?.programName,
        programId: {
          value: eventData?.programId,
          label: eventData?.programName,
        },
        eventName: eventData?.eventName,
        eventId: eventData?.eventId,
        displayName: eventData?.displayName,
        description: eventData?.description,
        isActive: eventData?.isActive,
      });
    }
  }, [eventData]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'eventName':
        updatedState = {
          ...state,
          eventName: value,
        };
        break;

      case 'displayName':
        updatedState = {
          ...state,
          displayName: value,
        };
        break;

      case 'description':
        updatedState = {
          ...state,
          description: value,
        };
        break;

      case 'isActive':
        updatedState = {
          ...state,
          isActive: e.target.checked,
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...state };

    switch (label) {
      case 'programId':
        updatedState = {
          ...state,
          programId: data,
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data?.eventName?.trim() !== `` && data?.description?.trim() !== ``) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      const payload = {
        ...state,
        programId: state?.programId?.value,
      };

      if (eventData === null) {
        onSubmit(
          {
            ...payload,
          },
          'New',
        );
      } else {
        onSubmit(
          {
            ...payload,
          },
          `Update`,
        );
      }
    }
  };

  return (
    <>
      <Card className="card-inner">
        {/* <button onClick={() => console.log(state)}>get state</button> */}
        <CardHeader className="d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Event details</h4>
        </CardHeader>
        <CardBody className="text-black">
          <Row>
            <Col sm={4}>
              <div className="mb-3">
                <Label htmlFor="eventName" className="form-label">
                  Event Name
                </Label>
                <Input type="text" className="form-control" id="eventName" name="eventName" value={state?.eventName} onChange={inputChangeHandler} />
                {isValidationShow && state?.eventName?.trim() === `` && <Error text="Please enter Event name" />}
              </div>
            </Col>

            {eventData !== null && (
              <Col sm={4}>
                <div className="mb-3">
                  <Label htmlFor="eventId" className="form-label">
                    Event ID
                  </Label>
                  <Input type="text" className="form-control" id="eventId" name="eventId" value={state?.eventId} disabled />
                </div>
              </Col>
            )}

            <Col sm={4}>
              <div className="mb-3">
                <Label htmlFor="programId" className="form-label">
                  Project Name
                </Label>
                <Select
                  id="programId"
                  name="programId"
                  isDisabled={true}
                  value={state?.programId}
                  options={projects?.results?.map(project => ({
                    value: project?.id,
                    label: project?.name,
                  }))}
                  onChange={data => inputSelectHandler(`programId`, data)}
                />
              </div>
            </Col>

            <Col sm={12}>
              <div className="mb-3">
                <Label htmlFor="validityTatValue" className="form-label">
                  Event Display Name
                  <span className="text-muted">(Optional)</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="displayName"
                  name="displayName"
                  placeholder="This name will be displayed in the report"
                  value={state?.displayName}
                  onChange={inputChangeHandler}
                />
              </div>
            </Col>

            <Col sm={12}>
              <div className="mb-3">
                <Label htmlFor="validityTatValue" className="form-label">
                  Event Description
                </Label>
                <Input type="textarea" name="description" id="description" rows="5" value={state?.description} onChange={inputChangeHandler} />
                {isValidationShow && state?.description?.trim() === `` && <Error text="Please enter description" />}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="card-inner">
        <PreviewCardHeader title="Set Event Status" />
        <CardBody className="text-black">
          <Row>
            <Col md={3}>
              <div className="mb-3">
                <div className="d-flex align-items-center ms-4">
                  <span className="text-muted me-3">Inactive</span>
                  <div className="form-switch d-inline-block ">
                    <Input
                      className="form-check-input code-switcher form-switch-md cursor-pointer"
                      type="checkbox"
                      id="isActive"
                      name="isActive"
                      checked={state?.isActive}
                      onChange={inputChangeHandler}
                    />
                  </div>
                  <span className="ms-3">Active</span>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Row>
        <Col md={6} className="mb-5">
          <div className="text-start">
            <button type="submit" className="btn btn-soft-dark" onClick={onBack}>
              <span className="fs-10">
                <i className="ri-arrow-left-line"></i>
              </span>
              &nbsp;Back
            </button>
          </div>
        </Col>
        <Col md={6} className="mb-5">
          <div className="text-end">
            <button type="submit" className="btn btn-primary" onClick={submitHandler}>
              {eventData !== null ? `Update Event` : `Create Event`}
            </button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CreateEventForm;
