import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { AsyncPaginate } from 'react-select-async-paginate';

import { GET_SERVICES_IN_BENEFIT_ITEM_EMPTY } from './../../../../../../../../store/application/actionType';
import { customHeaders, uaeProjectsBaseURL } from '../../../../../../../Config';
import { Constant } from '../../../../../../../Helpers/constant';
import axios from 'axios';

const BenefitForSubCategoryModal = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */

  const [state, setState] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [modal_varying1, setmodal_varying1] = useState(true);

  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [selectServices, setSelectServices] = useState([]);
  const [selectExceptServices, setSelectExceptServices] = useState([]);

  useEffect(() => {
    setState(props?.data?.data);
    setCurrentIndex(props?.data?.currentIndex);
    setIsSelectedAll(props?.data?.data?.benefitGroupItem?.completeData?.allServices);
    setSelectServices(
      props?.data?.data?.benefitGroupItem?.completeData?.services?.length === 0
        ? []
        : props?.data?.data?.benefitGroupItem?.completeData?.services?.map(service => ({
            value: service?.objectId,
            label: service?.name,
            completeData: { ...service },
          })),
    );
    setSelectExceptServices(
      props?.data?.data?.benefitGroupItem?.completeData?.exceptServices?.length === 0
        ? []
        : props?.data?.data?.benefitGroupItem?.completeData?.exceptServices?.map(service => ({
            value: service?.objectId,
            label: service?.name,
            completeData: { ...service },
          })),
    );
  }, []);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    setIsSelectedAll(e.target.checked);

    if (e.target.checked) {
      setSelectServices([]);
    } else {
      setSelectExceptServices([]);
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    let data = null;

    if (props?.data?.data?.benefitGroupItemType === `Sub-category`) {
      data = {
        currentIndex: currentIndex,
        data: {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              category: {
                id: state?.benefitGroupItem?.completeData?.objectId,
                name: state?.benefitGroupItem?.completeData?.name,
              },
              allServices: isSelectedAll === undefined ? false : isSelectedAll,
              services:
                selectServices?.length === 0 || selectServices === undefined
                  ? []
                  : selectServices?.map(service => ({
                      ...service?.completeData,
                    })),
              exceptServices:
                selectExceptServices?.length === 0 || selectExceptServices === undefined
                  ? []
                  : selectExceptServices?.map(service => ({
                      ...service?.completeData,
                    })),
            },
          },
        },
      };
    } else {
      data = {
        currentIndex: currentIndex,
        data: {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              allServices: isSelectedAll === undefined ? false : isSelectedAll,
              services:
                selectServices?.length === 0 || selectServices === undefined
                  ? []
                  : selectServices?.map(service => ({
                      ...service?.completeData,
                    })),
              exceptServices:
                selectExceptServices?.length === 0 || selectExceptServices === undefined
                  ? []
                  : selectExceptServices?.map(service => ({
                      ...service?.completeData,
                    })),
            },
          },
        },
      };
    }

    props.onSubmit(data);

    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);

    dispatch({
      type: GET_SERVICES_IN_BENEFIT_ITEM_EMPTY,
    });
  };

  /* ---------------------------- CLOSE HANDLER ---------------------------- */
  const onCloseHandler = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);

    dispatch({
      type: GET_SERVICES_IN_BENEFIT_ITEM_EMPTY,
    });
  };

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);

    dispatch({
      type: GET_SERVICES_IN_BENEFIT_ITEM_EMPTY,
    });
  };

  /* ---------------------------- TYPED SERVICE HANDLER ---------------------------- */
  async function loadServices(search) {
    let urlParam = null;
    if (props?.data?.data?.[`benefitGroupItemType`] === `Benefit`) {
      urlParam = {
        serviceId: props?.data?.data?.[`benefitGroupItem`]?.[`completeData`]?.[`objectId`],
      };
    } else if (props?.data?.data?.[`benefitGroupItemType`] === `Brand`) {
      urlParam = {
        partnerId: props?.data?.data?.[`benefitGroupItem`]?.[`completeData`]?.[`objectId`],
      };
    } else if (props?.data?.data?.[`benefitGroupItemType`] === `Sub-category`) {
      urlParam = {
        categoryId: props?.data?.data?.[`benefitGroupItem`]?.[`completeData`]?.[`objectId`],
      };
    }

    return await axios({
      method: `GET`,
      url: `${uaeProjectsBaseURL}/admin/services`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...urlParam,
        name: search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data.objectId,
              label: `${data.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  /* ---------------------------- ON SELECT SERVICE ---------------------------- */
  const onSelectService = event => {
    setSelectServices(event);
  };

  /* ---------------------------- TYPED EXCEPT SERVICE HANDLER ---------------------------- */
  async function loadExceptServices(search, loadedOptions) {
    let urlParam = null;
    if (props?.data?.data?.[`benefitGroupItemType`] === `Benefit`) {
      urlParam = {
        serviceId: props?.data?.data?.[`benefitGroupItem`]?.[`completeData`]?.[`objectId`],
      };
    } else if (props?.data?.data?.[`benefitGroupItemType`] === `Brand`) {
      urlParam = {
        partnerId: props?.data?.data?.[`benefitGroupItem`]?.[`completeData`]?.[`objectId`],
      };
    } else if (props?.data?.data?.[`benefitGroupItemType`] === `Sub-category`) {
      urlParam = {
        categoryId: props?.data?.data?.[`benefitGroupItem`]?.[`completeData`]?.[`objectId`],
      };
    }

    return await axios({
      method: `GET`,
      url: `${uaeProjectsBaseURL}/admin/services`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        name: search,
        ...urlParam,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data.objectId,
              label: `${data.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  /* ---------------------------- ON EXCEPT SELECT SERVICE ---------------------------- */
  const onSelectExceptService = event => {
    setSelectExceptServices(event);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Add Benefit for Sub-Category
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      {/* <button
        onClick={() => console.log(state, selectExceptServices, selectServices)}
      >
        Get State
      </button> */}

      <ModalBody>
        <Row>
          <div className="mb-3">
            <Col md={12}>
              <div className="d-flex justify-content-between">
                <Label htmlFor="choices-multiple-default" className="form-label">
                  Services
                  <span className="lbl_mandatory">*</span>
                </Label>

                <div>
                  <Label htmlFor="allServices" className="form-label mx-2">
                    All
                  </Label>
                  <div className="form-switch d-inline-block ">
                    <Input
                      className="form-check-input code-switcher"
                      type="checkbox"
                      id="allServices"
                      name="allServices"
                      checked={isSelectedAll}
                      onChange={inputChangeHandler}
                    />
                  </div>
                </div>
              </div>

              <AsyncPaginate
                debounceTimeout={500}
                value={selectServices}
                loadOptions={loadServices}
                onChange={e => onSelectService(e)}
                isMulti={true}
                closeMenuOnSelect={true}
                noOptionsMessage={() => 'No results found'}
                cacheUniqs={[['code']]}
                placeholder="Select..."
                isDisabled={isSelectedAll}
              />
            </Col>
          </div>

          {true && (
            <div className="mb-3">
              <Col md={12}>
                <div className="d-flex justify-content-between">
                  <Label htmlFor="choices-multiple-default" className="form-label">
                    Except Services
                  </Label>
                </div>

                <AsyncPaginate
                  debounceTimeout={500}
                  value={selectExceptServices}
                  loadOptions={loadExceptServices}
                  onChange={e => onSelectExceptService(e)}
                  isMulti={true}
                  closeMenuOnSelect={true}
                  noOptionsMessage={() => 'No results found'}
                  cacheUniqs={[['code']]}
                  placeholder="Select..."
                  isDisabled={!isSelectedAll}
                />
              </Col>
            </div>
          )}
        </Row>
      </ModalBody>

      <ModalFooter className="d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler}>
          Add to list
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default BenefitForSubCategoryModal;
