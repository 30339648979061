import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';

import { Card, CardBody, Col, Form, Label, Row, Input, CardFooter } from 'reactstrap';
import { Error, PreviewCardHeader } from '../../../../../../Common';
import ReactTooltip from 'react-tooltip';
import { CREATE_CLIENTS, CREATE_PROJECT, GET_CLIENTS, GET_COUNTRY } from '../../../../../../../store/application/actionType';

const ClientCreationForm = ({ countries }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    name: '',
    description: '',
    contractingCountry: null,
    clientType: ``,
    industry: ``,
  };
  const [state, setState] = useState(initialObj);
  const [isValidationShow, setIsValidationShow] = useState(false);

  const [allCountries, setAllCountries] = useState([]);
  const [allClients, setAllClients] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Client List
  const { clients } = useSelector(state => ({
    clients: state?.Clients?.data,
    clientsLoading: state?.Clients?.loading,
    clientsError: state?.Clients?.error,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (countries !== null) {
      setAllCountries(
        countries?.map(country => ({
          value: country?.countryId,
          label: country?.name,
        })),
      );
    }
  }, [countries]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'name':
        updatedState = {
          ...state,
          name: value,
        };
        break;

      case 'description':
        updatedState = {
          ...state,
          description: value,
        };
        break;

      case 'clientType':
        updatedState = {
          ...state,
          clientType: value,
        };
        break;

      case 'industry':
        updatedState = {
          ...state,
          industry: value,
        };
        break;

      default:
      // do nothing
    }
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = null;

    if (label === `contractingCountry`) {
      updatedState = {
        ...state,
        contractingCountry: data,
      };
      setState(updatedState);
      validateErrors(updatedState, `validationCheck`);
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (
      data?.name?.trim() !== `` &&
      data?.description?.time?.trim() !== `` &&
      data?.contractingCountry !== null &&
      data?.clientType?.trim() !== `` &&
      data?.industry?.trim() !== ``
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      const payload = {
        ...state,
        contractingCountry: {
          id: state?.contractingCountry?.value,
          name: state?.contractingCountry?.label,
        },
      };

      dispatch({
        type: CREATE_CLIENTS,
        payload: {
          data: {
            ...payload,
          },
        },
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Client Creation" />
            {/* <button onClick={() => console.log(state)}>get state</button> */}
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="name" className="form-label">
                          Name
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state?.name}
                          autoComplete="off"
                        />
                        {isValidationShow && state?.name?.trim() === `` && <Error text="Please enter name" />}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <div>
                          <Label htmlFor="name" className="form-label">
                            Description
                            <span className="lbl_mandatory"> *</span>
                          </Label>
                          <Input
                            id="description"
                            name="description"
                            type="text"
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={state?.description}
                            autoComplete="off"
                          />
                          {isValidationShow && state?.description?.trim() === `` && <Error text="Please enter description" />}
                        </div>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <div>
                          <Label htmlFor="clientType" className="form-label">
                            Client Type
                            <span className="lbl_mandatory"> *</span>
                          </Label>
                          <Input
                            id="clientType"
                            name="clientType"
                            type="text"
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={state?.clientType}
                            autoComplete="off"
                          />
                          {isValidationShow && state?.clientType?.trim() === `` && <Error text="Please enter client type" />}
                        </div>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <div>
                          <Label htmlFor="industry" className="form-label">
                            Industry
                            <span className="lbl_mandatory"> *</span>
                          </Label>
                          <Input
                            id="industry"
                            name="industry"
                            type="text"
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={state?.industry}
                            autoComplete="off"
                          />
                          {isValidationShow && state?.industry?.trim() === `` && <Error text="Please enter industry" />}
                        </div>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <div>
                          <Label htmlFor="" className="form-label">
                            Contracting Country
                            <span className="lbl_mandatory"> *</span>
                          </Label>
                          <Select
                            id="contracting-country"
                            name="contracting-country"
                            value={state?.contractingCountry}
                            options={allCountries}
                            onChange={data => inputSelectHandler(`contractingCountry`, data)}
                          />
                          {isValidationShow && state?.contractingCountry === null && <Error text="Please select country" />}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>

            <CardFooter>
              <Row>
                <Col md={12}>
                  <div className="text-center">
                    <button type="submit" className="btn btn-primary mx-3 overlay-disable" onClick={submitHandler}>
                      Create
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>

            <div
              style={{
                width: '100px',
                position: 'relative',
                zIndex: '999999999999',
              }}
            >
              <ReactTooltip />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ClientCreationForm;
