import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { CardBody, CardFooter, CardHeader, Col, Input, Label, Row } from 'reactstrap';

import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';

import { Error, Loader } from '../../../../../../../../Common';
import { Constant } from '../../../../../../../../Helpers/constant';
import { uaeProjectsBaseURL } from '../../../../../../../../Config';
import { GET_BINS_LIST } from '../../../../../../../../../store/actions';
import MobileField from '../../../../../../../../Common/FormControls/MobileField';
import { isValidAplha, isValidEmail, validateApplicationReferenceNumber } from '../../../../../../../../Helpers/Helpers';

const initialState = {
  countryCode: '966',
  phoneNumber: '',
  email: '',
  name: '',
  customerId: '',
  binNumber: null,
  bank: null,
};

const NewCardVoucherForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const [appRefError, setAppRefError] = useState('');
  const [isValidationShow, setIsValidationShow] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { binsList, binsListLoading } = useSelector(state => ({
    binsList: state?.GetBinsList?.data,
    binsListLoading: state?.GetBinsList?.loading,
  }));

  const { createUserLoading } = useSelector(state => ({
    createUserLoading: state?.CreateClientUser?.loading,
  }));

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputChangeHandler = (label, data) => {
    let updatedState = { ...state };

    switch (label) {
      case `bank`:
        localStorage.setItem('bankCode', data?.value);
        updatedState = {
          ...state,
          bank: data,
        };

        dispatch({
          type: GET_BINS_LIST,
        });
        break;

      case `binNumber`:
        updatedState = {
          ...state,
          binNumber: data,
        };
        break;

      case `customerId`:
        if (data?.length <= 20) {
          updatedState = {
            ...state,
            customerId: data?.toLocaleUpperCase()?.trim(),
          };

          if (validateApplicationReferenceNumber(data?.toLocaleUpperCase()?.trim())) {
            setAppRefError('');
          } else {
            setAppRefError('Invalid Customer Id');
          }
        }
        break;

      case `name`:
        updatedState = {
          ...state,
          name: isValidAplha(data) ? data : state?.name,
        };
        break;

      case `email`:
        updatedState = {
          ...state,
          email: data,
        };
        break;

      default:
      //
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- MOBILE CHANGE HANDLER ---------------------------- */
  const mobileChangeHandler = async (value, data) => {
    let updatedState = {
      ...state,
      countryCode: data?.dialCode,
      phoneNumber: value.slice(data?.dialCode?.length),
    };

    if (state?.email || state.name || state?.binNumber) {
      setState({
        ...state,
        countryCode: data?.dialCode,
        phoneNumber: value.slice(data?.dialCode?.length),
      });
    } else {
      setState(updatedState);
    }

    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (
      data?.bank !== null &&
      data?.binNumber !== null &&
      data?.name?.trim() !== `` &&
      data?.email?.trim() !== `` &&
      isValidEmail(data?.email?.trim()) &&
      data?.phoneNumber?.trim() !== `` &&
      appRefError?.trim() === `` &&
      data?.customerId?.trim() !== ``
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      const payload = {
        firstName: state?.name,
        countryCode: state?.countryCode,
        phoneNumber: state?.phoneNumber,
        email: state?.email,
        customerId: state?.customerId,
        card: {
          binId: state?.binNumber?.data?.binId,
          first: state?.binNumber?.data?.number,
        },
      };

      onSubmit(payload);
    }
  };

  /* ---------------------------- LOAD BANKS ---------------------------- */
  async function loadBanks(search) {
    let projectCodes = [];
    JSON.parse(localStorage?.getItem('data'))?.program?.client?.clients.map(item => projectCodes.push(item));

    return await axios({
      method: `POST`,
      url: `${uaeProjectsBaseURL}/admin/v1/projects`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        'Content-Type': 'application/json',
      },
      params: {
        name: search === '' ? null : search,
      },
      data: {
        projectCodes: projectCodes,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data?.code,
              label: `${data?.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  /* ---------------------------- LOADER HANDLER ---------------------------- */
  useEffect(() => {
    if (binsListLoading || createUserLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [binsListLoading, createUserLoading]);

  return (
    <div>
      {loading && <Loader />}

      <CardHeader className="d-flex">
        <h4 className="card-title mb-0 flex-grow-1">{t(`Customer Details`)}</h4>
      </CardHeader>

      <CardBody>
        <Row>
          <Col xl={3} lg={3} md={6} sm={6} xs={12}>
            <div className="mb-3">
              <Label htmlFor="name" className="form-label text-black">
                {t(`Select Bank`)}
                <span className="lbl_mandatory"> *</span>
              </Label>
              <AsyncPaginate
                debounceTimeout={500}
                value={state?.bank}
                loadOptions={loadBanks}
                onChange={data => inputChangeHandler(`bank`, data)}
                isMulti={false}
                closeMenuOnSelect={true}
                noOptionsMessage={() => 'No results found'}
                cacheUniqs={[['code']]}
                placeholder={t(`Bank Name`)}
                className="text-black cursor-pointer"
              />
              {isValidationShow && state?.bank === null && <Error text={`${t('Please select bank')}`} />}
            </div>
          </Col>

          <Col xl={3} lg={3} md={6} sm={6} xs={12}>
            <div className="mb-3">
              <Label htmlFor="name" className="form-label text-black">
                {t(`Select Card`)}
                <span className="lbl_mandatory"> *</span>
              </Label>
              <Select
                placeholder={t(`Card Name`)}
                id="card"
                name="card"
                value={state?.binNumber}
                onChange={data => inputChangeHandler(`binNumber`, data)}
                options={binsList?.results?.map(bin => ({
                  value: `${bin?.binId}`,
                  label: `${bin?.name}`,
                  data: bin,
                }))}
                isDisabled={state?.bank === null}
                className="text-black cursor-pointer"
              />
              {isValidationShow && state?.bank !== null && state?.binNumber === null && <Error text={`${t('Please select card')}`} />}
            </div>
          </Col>
        </Row>

        <Row>
          <Col xl={3} lg={3} md={6} sm={6} xs={12}>
            <div className="mb-3">
              <Label htmlFor="customerId" className="form-label text-black">
                {t(`Customer Id`)}
                <span className="lbl_mandatory"> *</span>
              </Label>
              <Input
                placeholder={t(`Customer Id`)}
                id="customerId"
                name="customerId"
                type="text"
                className="form-control"
                value={state?.customerId}
                onChange={e => inputChangeHandler(`customerId`, e?.target?.value)}
                disabled={state?.binNumber === null}
                autoComplete="off"
              />
              {isValidationShow && state?.customerId?.trim() !== '' && appRefError !== '' && <Error text={appRefError}></Error>}
              {isValidationShow && state?.binNumber !== null && state?.customerId?.trim() === `` && (
                <Error text={`${t('Please enter customer ID')}`} />
              )}
            </div>
          </Col>

          <Col xl={3} lg={3} md={6} sm={6} xs={12}>
            <div className="mb-3">
              <Label htmlFor="name" className="form-label text-black">
                {t(`Customer Name`)}
                <span className="lbl_mandatory"> *</span>
              </Label>
              <Input
                placeholder={t(`Name`)}
                id="name"
                name="name"
                type="text"
                className="form-control"
                value={state?.name}
                onChange={e => inputChangeHandler(`name`, e?.target?.value)}
                disabled={state?.binNumber === null}
                autoComplete="off"
              />
              {isValidationShow && state?.binNumber !== null && state?.name?.trim() === `` && <Error text={`${t('Please enter customer name')}`} />}
            </div>
          </Col>

          <Col xl={3} lg={3} md={6} sm={6} xs={12}>
            <div className="mb-3">
              <Label htmlFor="name" className="form-label text-black">
                {t(`Mobile Number`)}
                <span className="lbl_mandatory"> *</span>
              </Label>
              <div className="input-group align-items-center">
                <MobileField
                  placeholder={t(`Mobile Number`)}
                  onChange={mobileChangeHandler}
                  value={`${state?.countryCode}${state?.phoneNumber}`}
                  preferredCountries={['sa', 'uae', 'in']}
                  defaultCountry={'sa'}
                  // inputHandler={searchHandler}
                  disabled={state?.binNumber === null}
                />
              </div>
              {isValidationShow && state?.binNumber !== null && state?.phoneNumber?.trim() === `` && <Error text="Please enter mobile number" />}
            </div>
          </Col>

          <Col xl={3} lg={3} md={6} sm={6} xs={12}>
            <div className="mb-3">
              <Label htmlFor="name" className="form-label text-black">
                {t(`Email ID`)}
                <span className="lbl_mandatory"> *</span>
              </Label>
              <Input
                placeholder={t(`Email ID`)}
                id="email"
                name="email"
                type="text"
                className="form-control"
                value={state?.email}
                onChange={e => inputChangeHandler(`email`, e?.target?.value)}
                disabled={state?.binNumber === null}
                autoComplete="off"
              />
              {isValidationShow && state?.binNumber !== null && state?.email?.trim() === `` && <Error text="Please enter email ID" />}
              {isValidationShow && state?.email?.trim() !== `` && !isValidEmail(state?.email) && <Error text="Please enter correct email" />}
            </div>
          </Col>
        </Row>
      </CardBody>

      <CardFooter>
        <button type="submit" className="btn btn-success px-4" onClick={submitHandler}>
          {t(`Submit`)}
        </button>
      </CardFooter>
    </div>
  );
};

export default NewCardVoucherForm;
