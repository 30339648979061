import { useEffect, useState } from 'react';

import ProjectPlaceholder from './../../../../../../../assets/custom/project-placeholder-img.png';

import ReactApexChart from 'react-apexcharts';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Table, CardHeader, Col, Row, Container } from 'reactstrap';

let options = {
  chart: {
    type: 'radialBar',
    offsetY: -40,
    sparkline: {
      enabled: true,
    },
  },
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      track: {
        background: '#e7e7e7',
        strokeWidth: '90%',
        margin: 5,
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          color: '#999',
          opacity: 1,
          blur: 2,
        },
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          offsetY: -20,
          fontSize: '22px',
        },
      },
    },
  },
  grid: {
    padding: {
      top: -10,
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'light',
      shadeIntensity: 0.4,
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 50, 53, 91],
    },
  },
  labels: ['Average Results'],
};

const DetailDescription = ({ selectedReportData, domainSpecifData }) => {
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [completionPercentage, setCompletionPercentage] = useState(selectedReportData?.overallData?.completionPercentage ?? 0);
  const [profitPercentage, setProfitPercentage] = useState(domainSpecifData?.finalReport?.marginPercentage ?? 0);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: `smooth` });
  }, []);

  return (
    <>
      <Card>
        <CardHeader>
          <Row className="w-100">
            <Col sm={12} md={8} lg={6} className="d-flex align-items-center my-1">
              <div className="">
                <img
                  src={`${selectedReportData?.project?.logo === '' ? ProjectPlaceholder : selectedReportData?.project?.logo}`}
                  alt=""
                  style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    display: 'inline-block',
                    marginRight: '10px',
                  }}
                  className="img-fluid"
                />
                <span>{selectedReportData?.project?.name}</span>
              </div>

              <div
                className="text-muted"
                style={{
                  borderLeft: '2px solid',
                  margin: '0 10px',
                }}
              >
                &nbsp;
              </div>

              <div>
                <span className="me-3">{domainSpecifData?.domain}</span>
                {/* <button type="button" className="btn btn-outline-primary btn-sm mx-1">
                  March - January 2024/Overall
                </button> */}
              </div>
            </Col>

            <Col sm={12} md={6} lg={6} className="my-1">
              <div className="text-sm-start text-lg-end">
                <button type="button" className="btn btn-sm btn-outline-primary mx-1" onClick={() => history.push(`/booking-recon`)}>
                  <i className="ri-upload-2-line align-middle"></i>&nbsp; Upload
                </button>

                <button type="button" className="btn btn-sm btn-outline-primary mx-1" onClick={() => history.push(`/booking-recon`)}>
                  <i className="ri-download-cloud-line align-middle"></i>&nbsp; Download
                </button>
              </div>
            </Col>
          </Row>
        </CardHeader>

        <CardBody>
          <Row>
            <Col md={6}>
              <CardBody className="border">
                <Row className="row-cols-md-3 row-cols-1">
                  <>
                    {[
                      {
                        counter: `${domainSpecifData?.totalBookingCount}`,
                        label: 'All Bookings',
                      },
                      {
                        counter: `${domainSpecifData?.pendingCount}`,
                        label: 'Pending',
                      },
                    ]?.map((item, key) => (
                      <Col className={key === 1 ? 'col-lg' : 'col-lg border-end'} key={key}>
                        <div className="mt-3 mt-md-0 py-2 px-3 text-center">
                          <div className="flex-grow-1 ms-3">
                            <h2 className="mb-0">{item?.counter ?? 0}</h2>
                            <h5 className="text-muted">{item?.label}</h5>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </>
                </Row>
              </CardBody>
            </Col>

            <Col md={6}>
              <CardBody className="border">
                <Row className="row-cols-md-3 row-cols-1">
                  <>
                    {[
                      {
                        counter: `${domainSpecifData?.finalReport?.marginPercentage}`,
                        label: 'Margin Percentage',
                      },
                    ]?.map((item, key) => (
                      <Col className={key === 0 && 'col-lg'} key={key}>
                        <div className="mt-3 mt-md-0 py-2 px-3 text-center">
                          <div className="flex-grow-1 ms-3">
                            <h2 className="mb-0">{item?.counter ?? 0}</h2>
                            <h5 className="text-muted">{item?.label}</h5>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </>
                </Row>
              </CardBody>
            </Col>
          </Row>

          <Row className="my-3">
            <Col md={6}>
              <CardBody className="border">
                <ReactApexChart options={options} series={[completionPercentage]} type="radialBar" />
              </CardBody>
            </Col>

            <Col md={6}>
              <CardBody className="border">
                <ReactApexChart options={options} series={[profitPercentage]} type="radialBar" />
              </CardBody>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={6}>
              <Card>
                <CardBody className="border border-1">
                  <h4 className="card-title mb-2 d-inline-block">All Incomes</h4>

                  <Table>
                    <tbody>
                      <tr>
                        <td className="p-2">Amount Received (Tax Exl.)</td>
                        <td className="p-2 fw-semibold">{domainSpecifData?.revenue?.amountReceived ?? '-'}</td>
                      </tr>
                      <tr>
                        <td className="p-2">Processing Fee (Tax Exl.)</td>
                        <td className="p-2 fw-semibold">{domainSpecifData?.revenue?.processingFeeReceived ?? '-'}</td>
                      </tr>
                      <tr>
                        <td className="p-2">No Show Charges/ others</td>
                        <td className="p-2 fw-semibold">{domainSpecifData?.revenue?.otherCharges ?? '-'}</td>
                      </tr>
                      <tr>
                        <td className="p-2">Concierge fee Received</td>
                        <td className="p-2 fw-semibold">{domainSpecifData?.revenue?.conciergeFeeReceived ?? '-'}</td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>

            <Col md={6}>
              <Card>
                <CardBody className="border border-1">
                  <h4 className="card-title mb-2 d-inline-block">All Costs</h4>

                  <Table>
                    <tbody>
                      <tr>
                        <td className="p-2">Cost of Slot to game (Tax Exl.)</td>
                        <td className="p-2 fw-semibold">{domainSpecifData?.expense?.costToSlot ?? '-'}</td>
                      </tr>
                      <tr>
                        <td className="p-2">No Show Charges </td>
                        <td className="p-2 fw-semibold">{domainSpecifData?.expense?.chargesPaidToGC ?? '-'}</td>
                      </tr>
                      <tr>
                        <td className="p-2">Amount of Reimbursement</td>
                        <td className="p-2 fw-semibold">{domainSpecifData?.expense?.amountReimbursementToCustomer ?? '-'}</td>
                      </tr>
                      <tr>
                        <td className="p-2">Concierge Fee Paif</td>
                        <td className="p-2 fw-semibold">{domainSpecifData?.expense?.conciergeFeePaid ?? '-'}</td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default DetailDescription;
