import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Modal, ModalBody, Input, Label, Col, Row } from 'reactstrap';

import Flatpickr from 'react-flatpickr';
import { Error } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import { UPDATE_BOOKING } from '../../../../../../../store/application/actionType';
import {
  isANumber,
  isANumberWithDecimal,
  isValidAlphaNumberic,
  isValidAlphaNumbericWithoutSpace,
  isValidAplha,
} from '../../../../../../Helpers/Helpers';

const UpdateModalAirport = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    detailedBookingId: data?.detailedBookingId,
    dateTimeOfService: {
      ...data?.dateTimeOfService,
    },
    chauffer: {
      name: data?.chauffer?.name ?? '',
      mobile: data?.chauffer?.mobile ?? '',
      carNumber: data?.chauffer?.carNumber ?? '',
      carType: data?.chauffer?.carType ?? '',
    },
    numberOfPassengers: data?.numberOfPassengers ?? '',
    numberOfBabySeats: data?.numberOfBabySeats ?? '',
    numberOfLuggages: data?.numberOfLuggages ?? '',
    pickupLocation: data?.pickupLocation,
    dropLocation: data?.dropLocation,
    pickupDate: data?.pickupDate ?? '',
    pickupTime: data?.pickupTime ?? '',
    remarks: data?.remarks ?? '',
    referenceNumber: data?.referenceNumber ?? '',
    vendor: {
      price: data?.vendor?.price,
      carType: data?.vendor?.carType ?? '',
      bookingId: data?.bookingId ?? '',
      vendorId: data?.vendorId ?? '',
    },
  };
  const [state, setState] = useState(initialObj);
  const [isValidationShow, setIsValidationShow] = useState(false);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    let vendorPrice = '';
    let chaufferMobile = '';
    let tempCarNumber = '';
    let tempReferenceNo = '';
    let tempRemarks = '';
    let tempPickupLocation = '';

    switch (name) {
      case 'chauffer-name':
        updatedState = {
          ...state,
          chauffer: {
            ...state?.chauffer,
            name: isValidAplha(value) ? value : state?.chauffer?.name,
          },
        };
        break;

      case 'chauffer-mobile':
        if (value?.length === 0) {
          chaufferMobile = ``;
        } else {
          chaufferMobile = isANumber(value) ? value : state?.chauffer?.mobile;
        }
        updatedState = {
          ...state,
          chauffer: {
            ...state?.chauffer,
            mobile: chaufferMobile,
          },
        };
        break;

      case 'confirm-pickup-time':
        updatedState = {
          ...state,
          pickupTime: value,
        };
        break;

      case 'confirm-pickup-location':
        if (value?.length === 0) {
          tempPickupLocation = ``;
        } else {
          tempPickupLocation = isValidAlphaNumberic(value) ? value : state?.pickupLocation?.address;
        }
        updatedState = {
          ...state,
          pickupLocation: {
            ...state?.pickupLocation,
            address: tempPickupLocation,
          },
        };
        break;

      case 'car-number':
        if (value?.length === 0) {
          tempCarNumber = ``;
        } else {
          tempCarNumber = isValidAlphaNumbericWithoutSpace(value) ? value : state?.chauffer?.carNumber;
        }
        updatedState = {
          ...state,
          chauffer: {
            ...state?.chauffer,
            carNumber: tempCarNumber,
          },
        };
        break;

      case 'vendor-car-type':
        updatedState = {
          ...state,
          chauffer: {
            ...state?.chauffer,
            carType: value,
          },
          vendor: {
            ...state?.vendor,
            carType: value,
          },
        };
        break;

      case 'reference-no':
        if (value?.length === 0) {
          tempReferenceNo = ``;
        } else {
          tempReferenceNo = isANumber(value) ? value : state?.referenceNumber;
        }
        updatedState = {
          ...state,
          referenceNumber: tempReferenceNo,
        };
        break;

      case 'vendor-price':
        if (value?.length === 0) {
          vendorPrice = ``;
        } else {
          vendorPrice = isANumberWithDecimal(value) ? value : state?.vendor?.price;
        }
        updatedState = {
          ...state,
          vendor: {
            ...state?.vendor,
            price: vendorPrice,
          },
        };
        break;

      case 'vendor-remarks':
        if (value?.length === 0) {
          tempRemarks = ``;
        } else {
          tempRemarks = isValidAlphaNumberic(value) ? value : state?.remarks;
        }
        updatedState = {
          ...state,
          remarks: tempRemarks,
        };
        break;

      default:
      // do nothing
    }
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data?.chauffer?.name?.trim() !== `` && data?.chauffer?.mobile?.trim() !== ``) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- DATE PICKR HANDLER ---------------------------- */
  const datePickHandler = (flag, date) => {
    let updatedState = { ...state };

    switch (flag) {
      case 'pickupTime':
        updatedState = {
          ...state,
          pickupTime: new Date(date)?.toISOString(),
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      dispatch({
        type: UPDATE_BOOKING,
        payload: {
          data: {
            projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
            bookingTypeId: Constant?.BOOKING_TYPES?.pickupAndDropBookings?.objectId,
            bookingsData: [
              {
                objectId: data?.bookingId,
                process: `update-booking`,
                ...state,
                numberOfPassengers: data?.numberOfPassengers ?? '0',
                numberOfBabySeats: data?.numberOfBabySeats ?? '0',
                numberOfLuggages: data?.numberOfLuggages ?? '0',
                vendor: {
                  ...data?.vendor,
                  ...state?.vendor,
                  price: Number(state?.vendor?.price ?? 0),
                },
              },
            ],
          },
        },
      });
      onCloseHandler(false);
    }
  };

  return (
    <Modal
      isOpen={true}
      toggle={() => {
        onCloseHandler();
      }}
      id="completed-bookings-modal"
      size="xl"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Update Booking
        </h5>
        <Button
          type="button"
          onClick={() => {
            onCloseHandler();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log('statee', state)}>get state</button> */}
        <form>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="chauffer-name" className="form-label">
                    Chauffeur Name <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="chauffer-name"
                    name="chauffer-name"
                    type="text"
                    className="form-control"
                    value={state?.chauffer?.name}
                    onChange={inputChangeHandler}
                    placeholder="Chauffeur Name"
                  />
                </div>
                {isValidationShow && state?.chauffer?.name?.trim() === `` && <Error text="Please enter chauffer name" />}
              </div>
            </Col>

            <Col md={6}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="chauffer-mobile" className="form-label">
                    Chauffeur Mobile No. <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="chauffer-mobile"
                    name="chauffer-mobile"
                    type="text"
                    className="form-control"
                    value={state?.chauffer?.mobile}
                    onChange={inputChangeHandler}
                    placeholder="Chauffeur Mobile"
                  />
                </div>
                {isValidationShow && state?.chauffer?.mobile?.trim() === `` && <Error text="Please enter mobile" />}
              </div>
            </Col>

            <Col md={4}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="confirm-pickup-date" className="form-label">
                    Confirm Pickup Date
                  </Label>
                  <Flatpickr
                    className={`form-control overlay-disable`}
                    name="confirm-pickup-date"
                    id="confirm-pickup-date"
                    value={new Date(state?.pickupDate)}
                    disabled={true}
                    onChange={date => datePickHandler(`pickupTime`, date)}
                    options={{
                      defaultMinute: '00',
                      defaultHour: '00',
                      enableTime: false,
                      dateFormat: 'Y-m-d',
                    }}
                  />
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="confirm-pickup-time1" className="form-label">
                    Confirm Pickup Time
                  </Label>
                  <Input
                    id="confirm-pickup-time"
                    name="confirm-pickup-time"
                    type="time"
                    className="form-control"
                    value={state?.pickupTime}
                    onChange={inputChangeHandler}
                  />
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="confirm-pickup-location" className="form-label">
                    Confirm Pickup Location
                  </Label>
                  <Input
                    id="confirm-pickup-location"
                    name="confirm-pickup-location"
                    type="text"
                    className="form-control"
                    value={state?.pickupLocation?.address}
                    onChange={inputChangeHandler}
                    placeholder="Confirm Pickup Location"
                  />
                </div>
              </div>
            </Col>

            <Col md={6}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="car-number" className="form-label">
                    Car Number
                  </Label>
                  <Input
                    id="car-number"
                    name="car-number"
                    type="text"
                    className="form-control"
                    value={state?.chauffer?.carNumber}
                    onChange={inputChangeHandler}
                    placeholder="Car Number"
                  />
                </div>
              </div>
            </Col>

            <Col md={6}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="vendor-car-type" className="form-label">
                    Vendor Car Type
                  </Label>
                  <select
                    id="vendor-car-type"
                    name="vendor-car-type"
                    className="form-select"
                    value={state?.vendor?.carType}
                    onChange={inputChangeHandler}
                  >
                    <option value="">Choose...</option>
                    <option value="Sedan">Sedan</option>
                    <option value="MVP">MVP</option>
                    <option value="Luxury">Luxury</option>
                  </select>
                </div>
              </div>
            </Col>

            <Col md={3}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="confirmed-of-passengers" className="form-label">
                    Confirmed No. Of Passengers
                  </Label>
                  <Input
                    id="confirmed-of-passengers"
                    name="confirmed-of-passengers"
                    type="text"
                    className="form-control"
                    value={state?.numberOfPassengers}
                    // onChange={inputChangeHandler}
                    disabled={true}
                  />
                </div>
              </div>
            </Col>

            <Col md={3}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="confirmed-baby-seats" className="form-label">
                    Confirmed No. Of Baby Seats
                  </Label>
                  <Input
                    id="confirmed-baby-seats"
                    name="confirmed-baby-seats"
                    type="text"
                    className="form-control"
                    value={state?.numberOfBabySeats}
                    // onChange={inputChangeHandler}
                    disabled={true}
                  />
                </div>
              </div>
            </Col>

            <Col md={3}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="confirmed-no-luggage" className="form-label">
                    Confirmed No. Of Luggages
                  </Label>
                  <Input
                    id="confirmed-no-luggage"
                    name="confirmed-no-luggage"
                    type="text"
                    className="form-control"
                    value={state?.numberOfLuggages}
                    // onChange={inputChangeHandler}
                    disabled={true}
                  />
                </div>
              </div>
            </Col>

            <Col md={3}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="reference-no" className="form-label">
                    Reference No.
                  </Label>
                  <Input
                    id="reference-no"
                    name="reference-no"
                    type="text"
                    className="form-control"
                    value={state?.referenceNumber}
                    onChange={inputChangeHandler}
                    placeholder="Reference No"
                  />
                </div>
              </div>
            </Col>

            <Col md={8}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="vendor-remarks" className="form-label">
                    Admin Remarks For Confirmed
                  </Label>
                  <Input
                    id="vendor-remarks"
                    name="vendor-remarks"
                    type="text"
                    className="form-control"
                    value={state?.remarks}
                    onChange={inputChangeHandler}
                    placeholder="Admin Remarks For Confirmed"
                  />
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="vendor-price" className="form-label">
                    Vendor Price (AED)
                  </Label>
                  <Input
                    id="vendor-price"
                    name="vendor-price"
                    type="text"
                    className="form-control"
                    value={state?.vendor?.price}
                    onChange={inputChangeHandler}
                    disabled={true}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </ModalBody>

      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="danger" onClick={() => onCloseHandler()}>
          Close
        </Button>
        <Button className="overlay-disable" color="primary" onClick={submitHandler}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateModalAirport;
