import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';

import { UPDATE_MEMBERSHIP, UPDATE_MEMBERSHIP_EMPTY } from '../../../../../../store/actions';

const initialValues = {
  expiryDate: null,
  isActive: Boolean,
};

const UpdateMembership = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [state, setState] = useState(initialValues);
  const [loading, setLoading] = useState(true);

  const { UpdateMemberShip, UpdateMemberShipLoading } = useSelector(state => ({
    UpdateMemberShip: state?.UpdateMembership?.data,
    UpdateMemberShipLoading: state?.UpdateMembership?.loading,
  }));

  useEffect(() => {
    if (UpdateMemberShip != null) {
      props?.setModal(false);
      dispatch({
        type: UPDATE_MEMBERSHIP_EMPTY,
      });
    }
  }, [UpdateMemberShip]);

  useEffect(() => {
    if (props?.data) {
      setState({
        expiryDate: props?.data?.expiryDate,
        isActive: props?.data?.isActive,
      });
    }
  }, [props?.data]);

  /* ---------------------------- DATE PICKR HANDLER ---------------------------- */
  const datePickHandler = (flag, date) => {
    let updatedState = null;
    switch (flag) {
      case 'expiryDate':
        updatedState = {
          ...state,
          expiryDate: moment(new Date(date[0])).format('Y-MM-DD'),
        };
        setState(updatedState);
        break;
      default:
      // do nothing
    }
  };

  const updateMembershipHandler = () => {
    const formattedExpiryDate = moment(state.expiryDate).toISOString();
    dispatch({
      type: UPDATE_MEMBERSHIP,
      payload: {
        data: {
          objectId: props?.data?.objectId,
          expiryDate: formattedExpiryDate,
          isActive: state.isActive,
        },
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (UpdateMemberShipLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [UpdateMemberShipLoading]);

  return (
    <>
      <Modal centered isOpen={true} scrollable={true} size="md">
        <ModalHeader
          className="bg-soft-info p-3"
          toggle={() => {
            props.setModal(false);
          }}
        >
          Update Membership - {props?.data?.membershipId}
        </ModalHeader>

        <ModalBody>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="voucherDetailsExpiry" className="form-label">
                  Expiry Date
                  <span className="icon_calendar">
                    <i className="las la-calendar"></i>
                  </span>
                </Label>
                <Flatpickr
                  className="form-control"
                  onChange={date => datePickHandler('expiryDate', date)}
                  value={state?.expiryDate}
                  options={{
                    dateFormat: 'Y-m-d',
                  }}
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="mb-3">
                <div className="form-check form-switch">
                  <Input
                    role="switch"
                    type="checkbox"
                    id="has-parent-vendor"
                    name="has-parent-vendor"
                    className="form-check-input cursor-pointer"
                    checked={state?.isActive}
                    onChange={() => setState({ ...state, isActive: !state?.isActive })}
                  />
                  <Label className="form-check-label" htmlFor="has-parent-vendor">
                    Status
                  </Label>
                </div>
              </div>
            </Col>
          </Row>

          {/* <div className="modal-footer d-flex justify-content-center pt-3">
            <Button className="btn btn-primary" color="primary" onClick={updateMembershipHandler}>
              {loading ? (
                <Spinner animation="border" role="status" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                'Update'
              )}
            </Button>
          </div> */}
        </ModalBody>

        <ModalFooter className="border border-top-1 d-flex justify-content-center">
          <div className="hstack gap-2">
            <Button className="btn btn-success" color="primary" onClick={updateMembershipHandler}>
              {loading ? (
                <Spinner animation="border" role="status" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                'Update'
              )}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default UpdateMembership;
