import cogoToast from 'cogo-toast';
import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../Containers/Config/index.js';
import { Constant } from '../../../../Containers/Helpers/constant.js';
import apiCall from '../../apiCall.js';

import { UPDATE_COUPON, UPDATE_COUPON_SUCCESS, UPDATE_COUPON_ERROR } from './../../actionType.js';

function* updateCoupon({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/couponsInfo`,
      method: 'PUT',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      cogoToast.success(response.data.message);
      yield put({
        type: UPDATE_COUPON_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({ type: UPDATE_COUPON_ERROR, payload: response.data.message });
    }
  } catch (error) {
    yield put({ type: UPDATE_COUPON_ERROR, payload: error });
  }
}

export function* watchUpdateCoupon() {
  yield takeEvery(UPDATE_COUPON, updateCoupon);
}

function* updateCouponSaga() {
  yield all([fork(watchUpdateCoupon)]);
}

export default updateCouponSaga;
