import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Label, Row } from 'reactstrap';

import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { CLICK_DOWNLOAD_DUMP } from '../../../../../../store/application/actionType';
import { Loader } from '../../../../../Common';

const Filter = () => {
  const dispatch = useDispatch();

  const initialState = {
    bookingDateRange: {
      from: null,
      to: null,
    },
    typeId: null,
  };
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [filter, setFilter] = useState(initialState);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Click Download Dump
  const { downloadDump, downloadDumpLoading } = useSelector(state => ({
    downloadDump: state?.DownloadDump?.data,
    downloadDumpLoading: state?.DownloadDump?.loading,
    downloadDumpError: state?.DownloadDump?.error,
  }));

  /* ---------------------------- DATE PICKR HANDLER ---------------------------- */
  const datePickHandler = (flag, date) => {
    let updatedState = { ...filter };

    switch (flag) {
      case 'dateOfPlayRange':
        updatedState = {
          ...filter,
          bookingDateRange: {
            from: moment(new Date(date[0])).format('Y-MM-DD'),
            to: moment(new Date(date[1])).format('Y-MM-DD'),
          },
        };
        setFilter(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...filter };

    if (label === `typeId`) {
      updatedState = {
        ...filter,
        typeId: data,
      };
    }
    setFilter(updatedState);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    dispatch({
      type: CLICK_DOWNLOAD_DUMP,
      payload: {
        urlParam: {
          // projectId: `IaQlNnHgJr`,
          bookingTypeId: 3,
          downloadType: filter?.typeId !== null ? filter?.typeId?.value : null,
        },
        data: {
          bookingDateRange: {
            from: filter?.bookingDateRange?.from,
            to: filter?.bookingDateRange?.to,
          },
        },
      },
    });
  };

  return (
    <Row className="justify-content-center">
      {downloadDumpLoading && <Loader />}

      <Col md={8} lg={6} xl={5}>
        <Card className="mt-4">
          <CardBody className="p-4">
            <div className="text-center mt-2">
              <h4 className="text-primary">DOWNLOAD REPORTS</h4>
              {/* <p className="text-muted">
                                Enter your benefit type to unlock the screen!
                            </p> */}
            </div>

            <div className="p-2 mt-4">
              <form>
                <div className="mb-3">
                  <Label htmlFor="to" className="form-label">
                    Date Range
                    <i className="las la-calendar mx-1 fs-3 align-middle me-1"></i>
                  </Label>
                  <Flatpickr
                    className="form-control"
                    value={[filter?.bookingDateRange?.from, filter?.bookingDateRange?.to]}
                    onChange={date => datePickHandler(`dateOfPlayRange`, date)}
                    options={{
                      mode: 'range',
                      dateFormat: 'Y-m-d',
                      maxDate: 'today',
                    }}
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="userpassword">
                    Download Type
                    <span className="lbl_mandatory"> *</span>
                  </label>
                  <Select
                    id="bookingType"
                    name="bookingType"
                    options={
                      [
                        {
                          label: 'Balance Extraction',
                          value: '6',
                        },
                        {
                          label: 'Usage Report',
                          value: '7',
                        },
                        {
                          label: 'Finance Report',
                          value: '8',
                        },
                        {
                          label: 'Registration Report',
                          value: '9',
                        },
                        {
                          label: 'Outlet Data Report',
                          value: '10',
                        },
                      ] ?? []
                    }
                    value={filter?.typeId}
                    onChange={data => inputSelectHandler(`typeId`, data)}
                  />
                </div>

                <div className="mb-2 mt-4">
                  <Button color="success" className="w-100" onClick={submitHandler} disabled={filter?.typeId === null}>
                    Download
                    <i className="ri-download-line mx-1 fs-5 align-middle me-1"></i>
                  </Button>
                </div>
              </form>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Filter;
