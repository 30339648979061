import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { Col, Row, Table, Input, Container, Card, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { PreviewCardHeader } from '../../../../../Common';
import { GET_USER_CARDS, UPDATE_USER_CARD } from '../../../../../../store/application/actionType';
import ActionConfirmation from '../Modals/ActionConfirmation';
import { PermissionConstants } from '../../../../../Helpers/Constants/permissions';
import { hasPermission } from '../../../../../Helpers/Helpers';

const UserCards = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [cardsData, setCardsData] = useState([]);
  const [currentCardIndexToUpdate, setCurrentCardIndexToUpdate] = useState(0);
  const [currentCardDataToUpdate, setCurrentCardDataToUpdate] = useState(null);
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  useEffect(() => {
    if (props?.data?.length > 0) {
      setCardsData(
        props?.data?.map(card => ({
          ...card,
        })),
      );
    }
  }, [props?.data]);

  const inputChangeHandler = (e, index) => {
    const allCardsData = [...cardsData];
    let currentlySelectedCard = allCardsData[index];

    currentlySelectedCard = {
      ...currentlySelectedCard,
      status: e?.target?.checked,
    };

    setIsShowConfirmationModal(true);
    setCurrentCardIndexToUpdate(index);
    setCurrentCardDataToUpdate({ ...currentlySelectedCard });
  };

  const onChangeCardStatus = flag => {
    if (flag === `NO`) {
      // not sure
      setIsShowConfirmationModal(false);
    } else if (flag === `YES`) {
      setIsShowConfirmationModal(false);

      dispatch({
        type: UPDATE_USER_CARD,
        payload: {
          data: {
            status: currentCardDataToUpdate?.status,
            cardId: currentCardDataToUpdate?.cardId,
          },
        },
      });

      // dispatch({
      //   type: GET_USER_CARDS,
      //   payload: {
      //     urlParam: {
      //       userId: `${props?.currentUser?.userId}`,
      //     },
      //   },
      // });
    }
  };

  return (
    <>
      {isShowConfirmationModal && <ActionConfirmation onClose={onChangeCardStatus} />}

      <Card>
        <PreviewCardHeader title="User Cards" />
        <CardBody>
          <Row>
            <Col md={12}>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th> First Digit Numbers</th>
                    <th> Last Digit Numbers</th>
                    <th> Status</th>
                    <th> Action</th>
                  </tr>
                </thead>
                <tbody>
                  {cardsData?.map((cardDetail, index) => {
                    return (
                      <tr key={index}>
                        <th>{index + 1}.</th>
                        <td>{cardDetail?.first}</td>
                        <td>{cardDetail?.last}</td>
                        <td>{cardDetail?.status ? 'Active' : 'Inactive'}</td>
                        <td>
                          {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.USER_AGENT_CARD_INACTIVE) && (
                            <div className="form-switch d-inline-block ">
                              <Input
                                className="form-check-input code-switcher form-switch-md cursor-pointer"
                                type="checkbox"
                                id="isActive"
                                name="isActive"
                                checked={cardDetail?.status}
                                onChange={e => inputChangeHandler(e, index)}
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default UserCards;
