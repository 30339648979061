import React, { useEffect, useState } from 'react';

import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { Grid, _ } from 'gridjs-react';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { Constant } from '../../../../../Helpers/constant';
import { PreviewCardHeader } from '../../../../../Common';
import { PermissionConstants } from '../../../../../Helpers/Constants/permissions';
import { hasPermission } from '../../../../../Helpers/Helpers';

const ServiceWorthTable = props => {
  const [data, setData] = useState([]);
  const [serialColumnNumber, setSerialColumnNumber] = useState(0);

  useEffect(() => {
    if (props?.data?.length > 0) {
      setData(
        props?.data?.map((data, index) => {
          return [`${props?.dataStartedFrom + (index + 1)}.`, data?.service?.name || `NA`, data?.service?.earnValue, data?.service?.burnValue];
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, index) => {
    const currentSelectedIndex = Number(index.split('.')[0]) - 1 - props.pageNumber * Constant.TABLE_PAGE_SIZE;

    const selectedDetails = {
      ...props?.data?.[currentSelectedIndex],
    };

    if (flag === `DELETE`) {
      props.actionHandler({
        details: selectedDetails,
        actionType: flag,
      });
    } else if (flag === `UPDATE`) {
      props.actionHandler({
        details: selectedDetails,
        actionType: flag,
      });
    }
  };
  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader
              title="Service Worth's"
              // primaryButton={hasPermission(
              //   PermissionConstants?.ALL_PERMISSIONS?.ASSIGN_COINS_CREATE,
              // )}
              primaryButton={true}
              primaryButtonText={`Create Service Worth`}
              primaryClickHandler={props.onClickPrimaryBtn}
            />
            <CardBody>
              <div id="table-gridjs">
                <Grid
                  data={data}
                  columns={[
                    {
                      name: 'S.No.',
                      width: '70px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    // {
                    //   name: 'Project Id',
                    //   width: '120px',
                    //   formatter: (cell) => _(<span className="">{cell}</span>),
                    // },

                    {
                      name: 'Service Name',
                      width: '120px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Earn Value',
                      width: '120px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Burn Value',
                      width: '120px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Actions',
                      width: '120px',
                      formatter: (cell, row) =>
                        _(
                          <div className="d-flex justify-content-center flex-column">
                            {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.GOLF_BOOKING_UPDATE) && (
                              <span
                                className="btn btn-sm btn-primary mb-1"
                                onClick={() => {
                                  actionBtnHandler(`UPDATE`, row.cells[serialColumnNumber]?.data);
                                }}
                              >
                                Update
                              </span>
                            )}

                            {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.GOLF_BOOKING_UPDATE) && (
                              <>
                                {/* <span
                                className="btn btn-sm btn-danger mb-1"
                                onClick={() => {
                                  actionBtnHandler(
                                    `DELETE`,
                                    row.cells[serialColumnNumber]?.data,
                                  )
                                }}
                              >
                                Delete
                              </span> */}
                              </>
                            )}
                          </div>,
                        ),
                    },
                  ]}
                  search={false}
                  sort={false}
                  fixedHeader={true}
                  height="400px"
                  pagination={{ enabled: false, limit: 5 }}
                />
              </div>
              {props.children}
            </CardBody>

            {/* <CardFooter>
              <Row className="custom_pagination">
                <Col className="text-end">
                  <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    forcePage={props?.pageNumber}
                    pageCount={Math.ceil(
                      props?.totalData / Constant.TABLE_PAGE_SIZE,
                    )}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={(event) => {
                      props.pageHandler(event.selected)
                    }}
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </Col>
              </Row>
            </CardFooter> */}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ServiceWorthTable;
