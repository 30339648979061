import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { customHeaders, indianProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { DELETE_TRACKER_RECORD, DELETE_TRACKER_RECORD_SUCCESS, DELETE_TRACKER_RECORD_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* deleteTrackerRecord({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/admin/delete-record`,
      method: 'DELETE',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        'Project-Code': 'ADMIN_ACCESS',
      },
      params: {
        ...payload?.urlParam,
      },
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({
        type: DELETE_TRACKER_RECORD_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: DELETE_TRACKER_RECORD_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: DELETE_TRACKER_RECORD_ERROR, payload: error });
  }
}

export function* watchDeleteTrackerRecord() {
  yield takeEvery(DELETE_TRACKER_RECORD, deleteTrackerRecord);
}

function* deleteTrackerRecordSaga() {
  yield all([fork(watchDeleteTrackerRecord)]);
}

export default deleteTrackerRecordSaga;
