import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { Container } from 'reactstrap';

import { Constant } from '../../Helpers/constant';
import { BreadCrumb, Loader } from '../../Common';
import CustomersTable from './Components/Tables/Customers';
import CustomersDataTable from './Components/Tables/CustomersData';
import UploadCustomerData from './Components/Modals/UploadCustomers';
import {
  CLIENTS_CUSTOMER,
  CUSTOMER_UPLOADS_INFO,
  CUSTOMER_UPLOADS_INFO_EMPTY,
  GET_EMPTY_POST_COUPON,
  UPDATE_CLIENTS_CUSTOMER_EMPTY,
} from '../../../store/actions';
import CustomersFilter from './Components/Filters/CutsomersFilter';
import CustomersDataFilter from './Components/Filters/CustomersDataFilter';
import UpdateClientsCustomerModal from './Components/Modals/ChangeExpiry';

const CustomerDataManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);

  const [isCustomersShow, setIsCustomersShow] = useState(true);
  const [isUpdateClientsCustomer, setIsUpdateClientsCustomer] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [selectedCustomersData, setSelectedCustomersData] = useState(null);
  const [isCustomerDataModal, setIsCustomerDataModal] = useState(false);
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);

  const [selectedCustomersDataSec, setSelectedCustomersDataSec] = useState(null);
  const [currentlyAppliedFiltersSec, setCurrentlyAppliedFiltersSec] = useState(null);
  const [currentPageNumberSec, setCurrentPageNumberSec] = useState(0);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Upload Customer Data
  const { customerUploadsInfoData, customerUplaodsInfoLoading } = useSelector(state => ({
    customerUploadsInfoData: state?.CustomerUploadsInfo?.data,
    customerUplaodsInfoLoading: state?.CustomerUploadsInfo?.loading,
  }));

  // Customer Upload Info Data
  const { customerData, customerDataLoading } = useSelector(state => ({
    customerData: state?.PostCoupons?.data,
    customerDataLoading: state?.PostCoupons?.loading,
  }));

  // Customers Data
  const { clientCustomerData, clientCustomerLoading } = useSelector(state => ({
    clientCustomerData: state?.ClientsCustomer?.data,
    clientCustomerLoading: state?.ClientsCustomer?.loading,
  }));

  // Update Clients Customer
  const { updateClientsCustomerData, updateClientsCustomerLoading } = useSelector(state => ({
    updateClientsCustomerData: state?.UpdateClientsCustomer?.data,
    updateClientsCustomerLoading: state?.UpdateClientsCustomer?.loading,
  }));

  /* ---------------------------- SIDE EFFECTS ---------------------------- */
  useEffect(() => {
    if (customerData !== null) {
      dispatch({
        type: GET_EMPTY_POST_COUPON,
      });

      const urlParams = {
        dateRangeFrom:
          currentlyAppliedFilters?.dateRange?.from === undefined ? null : moment(`${currentlyAppliedFilters?.dateRange?.from}`).format('YYYY-MM-DD'),
        dateRangeTo:
          currentlyAppliedFilters?.dateRange?.to === undefined ? null : moment(`${currentlyAppliedFilters?.dateRange?.to}`).format('YYYY-MM-DD'),
        benefitGroup: currentlyAppliedFilters?.benefitGroups === undefined ? null : currentlyAppliedFilters?.benefitGroups?.value,
        pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
      };

      dispatch({
        type: CUSTOMER_UPLOADS_INFO,
        payload: {
          urlParam: {
            ...urlParams,
          },
        },
      });
    }
  }, [customerData]);

  useEffect(() => {
    dispatch({
      type: CUSTOMER_UPLOADS_INFO,
      payload: {
        urlParam: {
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (updateClientsCustomerData !== null) {
      dispatch({
        type: UPDATE_CLIENTS_CUSTOMER_EMPTY,
      });

      const urlParams = {
        name: currentlyAppliedFiltersSec?.name === `` ? null : currentlyAppliedFiltersSec?.name,
        email: currentlyAppliedFiltersSec?.email === `` ? null : currentlyAppliedFiltersSec?.email,
        mobileNumber: currentlyAppliedFiltersSec?.mobileNumber === `` ? null : currentlyAppliedFiltersSec?.mobileNumber,

        pageIndex: currentPageNumberSec * Constant.TABLE_PAGE_SIZE,
        uploadInfoId: selectedCustomersData?.objectId,
      };

      dispatch({
        type: CLIENTS_CUSTOMER,
        payload: {
          urlParam: {
            ...urlParams,
          },
        },
      });
    }
  }, [updateClientsCustomerData]);

  /* ---------------------------- UPLOAD COUPON BUTTON HANDLER ---------------------------- */
  const uploadCustomerButtonHandler = () => {
    setIsCustomerDataModal(true);
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);

    const urlParams = {
      dateRangeFrom:
        currentlyAppliedFilters?.dateRange?.from === undefined ? null : moment(`${currentlyAppliedFilters?.dateRange?.from}`).format('YYYY-MM-DD'),

      dateRangeTo:
        currentlyAppliedFilters?.dateRange?.to === undefined ? null : moment(`${currentlyAppliedFilters?.dateRange?.to}`).format('YYYY-MM-DD'),

      benefitGroup: currentlyAppliedFilters?.benefitGroups === undefined ? null : currentlyAppliedFilters?.benefitGroups?.value,
      pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
    };

    dispatch({
      type: CUSTOMER_UPLOADS_INFO,
      payload: {
        urlParam: {
          ...urlParams,
        },
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER  ---------------------------- */
  const tableActionHandler = params => {
    if (params?.actionType === `VIEW_DETAILS`) {
      setSelectedCustomersData(params?.data);
      setIsCustomersShow(false);
      setCurrentPageNumberSec(0);

      dispatch({
        type: CLIENTS_CUSTOMER,
        payload: {
          urlParam: {
            pageIndex: 0,
            uploadInfoId: params?.data?.objectId,
          },
        },
      });
    }
  };

  /* ---------------------------- CUSTOMER UPLAOD INFO FILTERS  ---------------------------- */
  const customerUploadInfoFilter = data => {
    setCurrentlyAppliedFilters(data);

    const urlParams = {
      dateRangeFrom: data?.dateRange?.from === undefined ? null : moment(`${data?.dateRange?.from}`).format('YYYY-MM-DD'),

      dateRangeTo: data?.dateRange?.to === undefined ? null : moment(`${data?.dateRange?.to}`).format('YYYY-MM-DD'),

      benefitGroup: data?.benefitGroups === undefined ? null : data?.benefitGroups?.value,
      pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
    };

    if (data?.filter) {
      dispatch({
        type: CUSTOMER_UPLOADS_INFO,
        payload: {
          urlParam: {
            ...urlParams,
          },
        },
      });
    } else {
      dispatch({
        type: CUSTOMER_UPLOADS_INFO_EMPTY,
      });
    }
  };

  /* ---------------------------- PAGINATION HANDLER SEC ---------------------------- */
  const paginationHandlerSec = pageNumber => {
    setCurrentPageNumberSec(pageNumber);

    const urlParams = {
      name: currentlyAppliedFiltersSec?.name === `` ? null : currentlyAppliedFiltersSec?.name,
      email: currentlyAppliedFiltersSec?.email === `` ? null : currentlyAppliedFiltersSec?.email,
      mobileNumber: currentlyAppliedFiltersSec?.mobileNumber === `` ? null : currentlyAppliedFiltersSec?.mobileNumber,

      pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
      uploadInfoId: selectedCustomersData?.objectId,
    };

    dispatch({
      type: CLIENTS_CUSTOMER,
      payload: {
        urlParam: {
          ...urlParams,
        },
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER SEC ---------------------------- */
  const tableActionHandlerSec = params => {
    if (params?.actionType === `CHANGE_EXPIRY`) {
      setSelectedCustomersDataSec(params?.data);
      setIsUpdateClientsCustomer(true);
    }
  };

  /* ---------------------------- CUSTOMERs FILTERS SEC ---------------------------- */
  const customerUploadInfoFilterSec = data => {
    setCurrentlyAppliedFiltersSec(data);
    setCurrentPageNumberSec(0);

    const urlParams = {
      name: data?.name === `` ? null : data?.name,
      email: data?.email === `` ? null : data?.email,
      mobileNumber: data?.mobileNumber === `` ? null : data?.mobileNumber,
      pageIndex: 0,
      uploadInfoId: selectedCustomersData?.objectId,
    };

    dispatch({
      type: CLIENTS_CUSTOMER,
      payload: {
        urlParam: {
          ...urlParams,
        },
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (customerDataLoading || customerUplaodsInfoLoading || clientCustomerLoading || updateClientsCustomerLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [customerDataLoading, customerUplaodsInfoLoading, clientCustomerLoading, updateClientsCustomerLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Customers Upload Info`) {
      setIsCustomersShow(true);
    } else if (title === `Clients Customer`) {
      // do nothing
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {isCustomerDataModal && <UploadCustomerData onCloseHandler={() => setIsCustomerDataModal(false)} />}

          {isUpdateClientsCustomer && (
            <UpdateClientsCustomerModal data={selectedCustomersDataSec} onCloseHandler={() => setIsUpdateClientsCustomer(false)} />
          )}

          {isCustomersShow && (
            <>
              <BreadCrumb title="Customers Data" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Customers Upload Info`]} />
              <CustomersFilter appliedFilters={currentlyAppliedFilters} filterHandler={customerUploadInfoFilter} />
              <CustomersTable
                data={customerUploadsInfoData?.results}
                totalData={customerUploadsInfoData?.count}
                pageNumber={currentPageNumber}
                pageHandler={paginationHandler}
                actionHandler={tableActionHandler}
                uploadCustomers={uploadCustomerButtonHandler}
              />
            </>
          )}

          {!isCustomersShow && (
            <>
              <BreadCrumb
                title="Clients Customer"
                navigationby={breadcrumNavigationHandler}
                navigation={[`Dashboard`, `Customers Upload Info`, `Clients Customer`]}
              />
              <CustomersDataFilter appliedFilters={currentlyAppliedFiltersSec} filterHandler={customerUploadInfoFilterSec} />
              <CustomersDataTable
                data={clientCustomerData?.results}
                totalData={clientCustomerData?.count}
                pageNumber={currentPageNumberSec}
                pageHandler={paginationHandlerSec}
                actionHandler={tableActionHandlerSec}
                uploadCustomers={uploadCustomerButtonHandler}
              />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default CustomerDataManagement;
