import { useEffect, useState } from 'react';

import moment from 'moment';
import Countdown from 'react-countdown';
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';

import { TablePagination } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';
import { hasPermission, isDateIsGreaterThanLast7Days, formattedWithComma } from '../../../../../../Helpers/Helpers';

const Table = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [selectedBookings, setSelectedBookings] = useState([]);
  const [currentFilteredBookingTitle, setCurrentFilterBookingTitle] = useState('Bookings');
  const [currentFilterBookingStatus, setCurrentFilterBookingStatus] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [columns, setColumns] = useState([]);
  const [clearRow, setClearRow] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Update Booking
  const { updateBookings } = useSelector(state => ({
    updateBookings: state?.UpdateBooking?.data,
  }));

  // Resend Mail
  const { resendMail } = useSelector(state => ({
    resendMail: state?.ResendMail?.data,
  }));

  useEffect(() => {
    getBookingStatusTitleHandler(parseInt(props?.appliedFilters?.status));
    if (props?.data?.length > 0) {
      setBookingData(
        props?.data?.map(booking => {
          return { ...booking };
        }),
      );
      setSelectedBookings([]);
      setClearRow(!clearRow);
    } else {
      setData([]);
      setSelectedBookings([]);
    }
    // if (!props?.appliedFilters?.statusRange) {
    //   setSelectedBookings([]);
    // }
    setCurrentFilterBookingStatus(props?.appliedFilters?.statusRange || []);
  }, [props?.data]);

  useEffect(() => {
    if (bookingData?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No',
          width: '80px',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
        },
        {
          name: 'Booking Id',
          width: '140px',
          cell: row => (
            <span
              className="cursor-pointer text-decoration-underline"
              style={{ color: '#3577f1' }}
              onClick={() => actionBtnHandler(`VIEW`, row?.data)}
            >
              {row?.data?.bookingId}
            </span>
          ),
        },
        {
          name: 'Countdown',
          selector: row => countdownFunc(row.index),
          width: '150px',
        },
        {
          name: 'Name',
          selector: row => row?.userName,
          wrap: true,
          width: '180px',
        },
        {
          name: 'Email',
          selector: row => row?.userEmail,
          wrap: true,
          width: '250px',
        },
        {
          name: 'Mobile No',
          selector: row => row?.userMobile,
          wrap: true,
          width: '150px',
        },
        {
          name: 'Customer Name',
          selector: row => row?.customerName,
          wrap: true,
          width: '200px',
        },
        {
          name: 'Customer Email',
          selector: row => row?.customerEmail,
          wrap: true,
          width: '250px',
        },
        {
          name: 'Customer Mobile',
          selector: row => row?.customerMobile,
          wrap: true,
          width: '150px',
        },
        {
          name: 'Pickup Date',
          selector: row => row?.pickupDate,
          wrap: true,
          width: '130px',
        },
        {
          name: 'Pickup Time',
          selector: row => row?.pickupTime,
          wrap: true,
          width: '120px',
        },
        {
          name: 'User Type',
          selector: row => row?.bookingForSomeoneElse,
          wrap: true,
          width: '120px',
        },
        {
          name: 'Benefit Type',
          selector: row => row?.benefitType,
          wrap: true,
          width: '150px',
        },
        {
          name: 'Booking Type',
          selector: row => row?.bookingType,
          wrap: true,
          width: '130px',
        },
        {
          name: 'Payment ID',
          selector: row => row?.paymentId,
          wrap: true,
          width: '250px',
        },
        {
          name: 'Membership ID',
          selector: row => row?.membershipId,
          wrap: true,
          width: '200px',
        },
        {
          name: 'Confirmed At',
          selector: row => row?.confirmedAt,
          wrap: true,
          width: '180px',
        },
        {
          name: 'Created At',
          selector: row => row?.createdAt,
          wrap: true,
          width: '180px',
        },
        {
          name: 'Updated At',
          selector: row => row?.updatedAt,
          wrap: true,
          width: '200px',
        },
        {
          name: 'Booking Status',
          width: '150px',
          wrap: true,
          cell: row => (
            <span
              className={`booking_status badge break-word bg-${
                Constant.BOOKING_STATUSES.filter(status => status?.name === row.bookingStatus)[0]?.bgColor
              }`}
            >
              {row.bookingStatus}
            </span>
          ),
        },
        {
          name: 'Actions',
          width: '250px',
          cell: row => (
            <div className="d-flex justify-content-center flex-column m-4">
              {row?.originalData?.workflow === Constant.WORKFLOWS[0] && (
                <>
                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CAR_SERVICING_BOOKING_UPDATE) &&
                    row?.originalData?.bookingStatusCode === Constant.BOOKING_STATUS.PENDING && (
                      <>
                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`PROCESS`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          // disabled={isDatePassed(row?.originalData?.bookingDateTime)}
                          // disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Process
                        </button>

                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`UPDATE`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          // disabled={isDatePassed(row?.originalData?.bookingDateTime)}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Update
                        </button>

                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`RESEND_EMAIL`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          // disabled={isDatePassed(row?.originalData?.bookingDateTime)}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Resend Email
                        </button>

                        <button
                          className="btn btn-sm btn-danger mb-1"
                          onClick={() => {
                            actionBtnHandler(`CANCEL`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          // disabled={isDatePassed(row?.originalData?.bookingDateTime)}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Cancel
                        </button>
                      </>
                    )}

                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CAR_SERVICING_BOOKING_UPDATE) &&
                    row?.originalData?.bookingStatusCode === Constant.BOOKING_STATUS.PROCESSED && (
                      <>
                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`CONFIRM`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          // disabled={isDatePassed(row?.originalData?.bookingDateTime)}
                          // disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Confirm
                        </button>

                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`UPDATE`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          // disabled={isDatePassed(row?.originalData?.bookingDateTime)}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Update
                        </button>

                        <button
                          className="btn btn-sm btn-danger mb-1"
                          onClick={() => {
                            actionBtnHandler(`CANCEL`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          // disabled={isDatePassed(row?.originalData?.bookingDateTime)}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Cancel
                        </button>
                      </>
                    )}

                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CAR_SERVICING_BOOKING_UPDATE) &&
                    row?.originalData?.bookingStatusCode === Constant.BOOKING_STATUS.CONFIRM && (
                      <>
                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`UPDATE`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          // disabled={isDatePassed(row?.originalData?.bookingDateTime)}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Update
                        </button>

                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`RESEND_EMAIL`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          // disabled={isDatePassed(row?.originalData?.bookingDateTime)}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Resend Email
                        </button>

                        <button
                          className="btn btn-sm btn-danger mb-1"
                          onClick={() => {
                            actionBtnHandler(`CANCEL`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          // disabled={isDatePassed(row?.originalData?.bookingDateTime)}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Cancel
                        </button>
                      </>
                    )}

                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CAR_SERVICING_BOOKING_UPDATE) &&
                    row?.originalData?.bookingStatusCode === Constant.BOOKING_STATUS.CANCEL_BY_THRIWE && (
                      <>
                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`RESEND_EMAIL`, row?.originalData);
                          }}
                          style={{ width: '150px' }}
                          // disabled={isDatePassed(row?.originalData?.bookingDateTime)}
                          disabled={isDateIsGreaterThanLast7Days(row?.originalData?.bookingDateTime)}
                        >
                          Resend Email
                        </button>
                      </>
                    )}
                </>
              )}
            </div>
          ),
        },
      ];
      setColumns(fetchColumns);
      setData(
        bookingData?.map((data, index) => {
          const status = Constant?.BOOKING_STATUSES?.filter(statusObj => statusObj.objectId === data?.bookingStatusCode)[0]?.name;
          let confirmedAt = `NA`;
          data?.bookingModifyPersons?.forEach(element => {
            if (element?.bookingStatus === Constant?.BOOKING_STATUS?.CONFIRM) {
              confirmedAt = moment(`${element?.modifiedAt}`).format(`DD-MM-YYYY, h:mm A`);
            }
          });
          return {
            serialNumber: `${props?.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            data: data || `NA`,
            index: index,
            userName: data?.user?.name || `NA`,
            userEmail: data?.user?.email || `NA`,
            userMobile: data?.user?.mobile || `NA`,
            customerName: data?.bookingForSomeoneElse === 0 ? data?.user?.name || `NA` : data?.playerDetails?.name || `NA`,
            customerEmail: data?.bookingForSomeoneElse === 0 ? data?.user?.email || `NA` : data?.playerDetails?.email || `NA`,
            customerMobile: data?.bookingForSomeoneElse === 0 ? data?.user?.mobile || `NA` : data?.playerDetails?.mobileNumber || `NA`,
            pickupDate: data?.pickupDate ? moment(`${data?.pickupDate}`).format('DD-MM-YYYY') : `NA`,
            pickupTime: data?.pickupTime ? moment(`${data?.pickupTime}`, 'HH:mm').format('h:mm A') : `NA`,
            bookingForSomeoneElse: data?.bookingForSomeoneElse === 0 ? 'Self' : 'Someone',
            benefitType: Constant?.benefitType[data?.benefitType ?? 0] || `NA`,
            bookingType: data?.bookingType || `NA`,
            paymentId: data?.payment?.paymentId || `NA`,
            membershipId: data?.membershipId || `NA`,
            confirmedAt: confirmedAt,
            createdAt: data?.createdAt ? moment(new Date(`${data?.createdAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            updatedAt: data?.updatedAt ? moment(new Date(`${data?.updatedAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            bookingStatus: status,
            originalData: data,
          };
        }),
      );
      setTimeout(() => {
        colorizeSlaBreached();
      }, [100]);
    } else {
      setData([]);
      setColumns([]);
    }
  }, [bookingData]);

  useEffect(() => {
    setSelectedBookings([]);
  }, [updateBookings, resendMail]);

  const handleRowSelected = state => {
    const selectedBookingDetails = state?.selectedRows;
    if (selectedBookingDetails.length > 0) {
      setSelectedBookings(
        selectedBookingDetails.map(selectedBooking => {
          return selectedBooking.data;
        }),
      );
    } else {
      setSelectedBookings([]);
    }
  };

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `EDIT`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `VIEW`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `PROCESS`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CONFIRM`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CANCEL`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `UPDATE`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `RESEND_EMAIL`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CONFIRM_WITH_DATA`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    }
  };

  /* ---------------------------- BULK UPDATE STATUSES HANDLER ---------------------------- */
  const bulkUpdateStatusHandler = flag => {
    if (flag === 'BULK_PROCESS') {
      props.actionHandler({
        bookingDetails: selectedBookings,
        actionType: flag,
      });
    } else if (flag === 'BULK_CONFIRM') {
      props.actionHandler({
        bookingDetails: selectedBookings,
        actionType: flag,
      });
    } else if (flag === 'BULK_CANCEL') {
      props.actionHandler({
        bookingDetails: selectedBookings,
        actionType: flag,
      });
    } else if (flag === 'BULK_UPDATE') {
      props.actionHandler({
        bookingDetails: selectedBookings,
        actionType: flag,
      });
    } else if (flag === 'BULK_RESEND_MAIL') {
      props.actionHandler({
        bookingDetails: selectedBookings,
        actionType: flag,
      });
    }
  };

  /* ---------------------------- BOOKING TABLE TITLE HANDLER ---------------------------- */
  const getBookingStatusTitleHandler = bookingsStatusCode => {
    let bookingsTitle = 'Bookings';

    if (bookingsStatusCode !== 0) {
      Constant?.BOOKING_STATUSES?.forEach(bookingStatus => {
        if (bookingsStatusCode === bookingStatus?.objectId) {
          bookingsTitle = `${bookingStatus?.name} Bookings`;
        }
      });
    }
    setCurrentFilterBookingTitle(bookingsTitle);
  };

  setTimeout(() => {
    colorizeSlaBreached();
  }, [100]);

  /* ---------------------------- COLORIZE SLA BREACH ---------------------------- */
  function colorizeSlaBreached() {
    if (props?.data?.length > 0) {
      const slaBreachedTableIndexes = props?.data
        ?.map((bookingData, index) => {
          if (bookingData?.slaDetails?.slaBreached) {
            return index;
          }
        })
        .filter(data => data !== undefined);
      Array.from(document.querySelectorAll('.rdt_TableRow')).map((row, index) => {
        if (slaBreachedTableIndexes.includes(index)) {
          row.style.backgroundColor = `#ec7b65`;
          row.style.color = `#fff`;
        } else {
          row.style.backgroundColor = `#fff`;
          row.style.color = `black`;
        }
      });
    } else {
      Array.from(document.querySelectorAll('.rdt_TableRow')).map((row, index) => {
        row.style.backgroundColor = `#fff`;
        row.style.color = `black`;
      });
    }
  }

  /* ---------------------------- COUNTDOWN HANDLER ---------------------------- */
  const countdownFunc = index => {
    if (props?.data?.[index]?.slaDetails?.slaBreached === false && props?.data?.[index]?.slaDetails?.slaLimit === 0) {
      return;
    } else {
      const diffTime = new Date() - new Date(props?.data?.[index]?.slaDetails?.lastProcessedTime);

      const remainingMilisecond = props?.data?.[index]?.slaDetails?.slaLimit - diffTime;
      return <Countdown date={new Date().valueOf() + remainingMilisecond} renderer={data => renderer(data, index)}></Countdown>;
    }
  };

  const renderer = (data, index) => {
    if (data.completed) {
      const selectedRow = Array.from(document.querySelectorAll('.rdt_TableRow'))[index + 1];
      if (selectedRow) {
        selectedRow.style.backgroundColor = `#ec7b65`;
        selectedRow.style.color = `#fff`;
      }
    } else {
      return (
        <span>
          {data.hours}:{data.minutes}:{data.seconds}
        </span>
      );
    }
  };

  useEffect(() => {
    setIsButtonDisabled(isTimePassed());
  }, [selectedBookings]);

  const isTimePassed = () => {
    for (let i = 0; i < selectedBookings.length; i++) {
      if (isDateIsGreaterThanLast7Days(selectedBookings[i]?.bookingDateTime)) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{currentFilteredBookingTitle}</h4>

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_ACCESS) && props?.data?.length > 0 && (
                <span className="btn btn-success mb-1" onClick={props?.dumpDownload}>
                  <i className="ri-download-line me-1 text-white fs-14 align-middle"></i>Download dump
                </span>
              )}
            </CardHeader>

            {selectedBookings.length > 0 && hasPermission(PermissionConstants?.ALL_PERMISSIONS?.GOLF_BOOKING_UPDATE) && (
              <CardHeader>
                {currentFilterBookingStatus.some(status => status === Constant.BOOKING_STATUS.PENDING) && (
                  <button
                    className="rounded-pill btn btn-primary btn-sm mx-1"
                    onClick={() => bulkUpdateStatusHandler(`BULK_PROCESS`)}
                    disabled={isButtonDisabled}
                  >
                    Process
                  </button>
                )}

                {currentFilterBookingStatus.some(status => status === Constant.BOOKING_STATUS.PROCESSED) && (
                  <button
                    className="rounded-pill btn btn-primary btn-sm mx-1"
                    onClick={() => bulkUpdateStatusHandler(`BULK_CONFIRM`)}
                    disabled={isButtonDisabled}
                  >
                    Confirm
                  </button>
                )}

                {currentFilterBookingStatus.some(status =>
                  [Constant.BOOKING_STATUS.PENDING, Constant.BOOKING_STATUS.PROCESSED, Constant.BOOKING_STATUS.CONFIRM].includes(status),
                ) && (
                  <button
                    className="rounded-pill btn btn-primary btn-sm mx-1"
                    onClick={() => bulkUpdateStatusHandler(`BULK_UPDATE`)}
                    disabled={isButtonDisabled}
                  >
                    Update
                  </button>
                )}

                {currentFilterBookingStatus.some(status =>
                  [
                    Constant.BOOKING_STATUS.PENDING,
                    Constant.BOOKING_STATUS.CONFIRM,
                    Constant.BOOKING_STATUS.CANCEL_BY_THRIWE,
                    Constant.BOOKING_STATUS.CANCELLED,
                  ].includes(status),
                ) && (
                  <button
                    className="rounded-pill btn btn-primary btn-sm mx-1"
                    onClick={() => bulkUpdateStatusHandler(`BULK_RESEND_MAIL`)}
                    disabled={isButtonDisabled}
                  >
                    Resend Email
                  </button>
                )}

                {currentFilterBookingStatus.some(status =>
                  [Constant.BOOKING_STATUS.PENDING, Constant.BOOKING_STATUS.PROCESSED, Constant.BOOKING_STATUS.CONFIRM].includes(status),
                ) && (
                  <button
                    className="rounded-pill btn btn-primary btn-sm mx-1"
                    onClick={() => bulkUpdateStatusHandler(`BULK_CANCEL`)}
                    disabled={isButtonDisabled}
                  >
                    Cancel
                  </button>
                )}
              </CardHeader>
            )}

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                selectableRows={currentFilterBookingStatus.length === 1 ? true : false}
                onSelectedRowsChange={handleRowSelected}
                clearSelectedRows={(updateBookings !== null ? true : false) || (resendMail !== null ? true : false) || clearRow}
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
