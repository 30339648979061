import React from 'react';

import { Card, CardBody, Col, Label, Row, TabPane } from 'reactstrap';
import { PreviewCardHeader } from '../../../../../../../Common';

const CataloguePreview = () => {
  return (
    <TabPane tabId={3} id="pills-bill-info">
      <div>
        <Card className="card-inner">
          <PreviewCardHeader title="General Properties" />
          <CardBody>
            <Row className="text-muted">
              <Col md={4}>
                <Label className="form-label">
                  Client Name :<Label className="form-label text-black">HSBC</Label>
                </Label>
              </Col>

              <Col md={4}>
                <Label className="form-label">
                  Program Name :<Label className="form-label text-black">XYZ</Label>
                </Label>
              </Col>

              <Col md={4}>
                <Label className="form-label">
                  Customer Segement Name :<Label className="form-label text-black">XYZ</Label>
                </Label>
              </Col>

              <Col md={4}>
                <Label className="form-label">
                  Wallet Name :<Label className="form-label text-black">5</Label>
                </Label>
              </Col>

              <Col md={4}>
                <Label className="form-label">
                  Catalog Name :<Label className="form-label text-black">XYZ</Label>
                </Label>
              </Col>

              <Col md={4}>
                <Label className="form-label">
                  Total Benefits Added :<Label className="form-label text-black">5</Label>
                </Label>
              </Col>

              <Col md={4}>
                <Label className="form-label">
                  Released : <Label className="form-label text-black">10</Label>
                </Label>
              </Col>

              <Col md={4}>
                <Label className="form-label">
                  Total Packs Limit :<Label className="form-label text-black">6</Label>
                </Label>
              </Col>

              <Col md={4}>
                <Label className="form-label">
                  Benefit Worth Value :<Label className="form-label text-black">1234</Label>
                </Label>
              </Col>

              <Col md={4}>
                <Label className="form-label">
                  Benefit Group Cost :<Label className="form-label text-black">1234</Label>
                </Label>
              </Col>

              <Col md={4}>
                <Label className="form-label">
                  Pack Release Type :<Label className="form-label text-black">Manual</Label>
                </Label>
              </Col>

              <Col md={4}>
                <Label className="form-label">
                  Membership Generation Restriction :<Label className="form-label text-black">True</Label>
                </Label>
              </Col>

              <Col md={4}>
                <Label className="form-label">
                  Eligible Data Upload Restriction :<Label className="form-label text-black">True</Label>
                </Label>
              </Col>

              <Col md={4}>
                <Label className="form-label">
                  Benefit Pack Validity Duration Unit :<Label className="form-label text-black">Month</Label>
                </Label>
              </Col>

              <Col md={4}>
                <Label className="form-label">
                  Benefit Pack Validity Duration value :<Label className="form-label text-black">Lifetime</Label>
                </Label>
              </Col>

              <Col md={4}>
                <Label className="form-label">
                  Expiry Logic :<Label className="form-label text-black">On registration</Label>
                </Label>
              </Col>

              <Col md={4}>
                <Label className="form-label">
                  Default Lot :<Label className="form-label text-black">XYZ</Label>
                </Label>
              </Col>

              <Col md={4}>
                <Label className="form-label">
                  Is Active? :<Label className="form-label text-black">Yes</Label>
                </Label>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="card-inner">
          <PreviewCardHeader title="Catalog Item(s)" />
          <CardBody>
            <Row>
              <Col md={4}>
                <Label className="form-label">
                  Benefit Group Item Type :<Label className="form-label text-black">Brand +</Label>
                </Label>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <h5>Items List</h5>
              </Col>
            </Row>

            <Row>
              <Col md={6} className="mb-2">
                <Card className="card-inner bg-white">
                  <CardBody>
                    <div className="preview-item">
                      <div className="d-flex mb-2">
                        <div className="flex-shrink-0 avatar-sm me-2">
                          <div className="avatar-title bg-light rounded">
                            <img src="https://design.thriwe.com/thriwe-one-admin/assets/images/brands/unnamed.png" className="img-fluid" alt="" />
                          </div>
                        </div>

                        <div className="flex-grow-1 ms-3 ">
                          <h5 className="fs-15 mb-1">Zomato</h5>
                          <p className="text-muted d-flex align-items-center flex-wrap m-0">3 Months Subscription</p>
                        </div>
                      </div>

                      <div>
                        <span className="badge badge-soft-success fs-13">Points Worth - 1000 Pts</span>
                      </div>

                      <div className="action-btn">
                        <button type="button" className="btn btn-outline-primary btn-sm mx-1">
                          <i className="ri-edit-line"></i>
                        </button>

                        <button type="button" className="btn btn-outline-danger btn-sm mx-1">
                          <i className="ri-delete-bin-6-line"></i>
                        </button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col md={6} className="mb-2">
                <Card className="card-inner bg-white">
                  <CardBody>
                    <div className=" preview-item">
                      <div className="d-flex mb-2">
                        <div className="flex-shrink-0 avatar-sm me-2">
                          <div className="avatar-title bg-light rounded">
                            <img src="https://design.thriwe.com/thriwe-one-admin-surge/assets/images/brands/lic.png" className="img-fluid" alt="" />
                          </div>
                        </div>

                        <div className="flex-grow-1 ms-3 ">
                          <h5 className="fs-15 mb-1">Zomato</h5>
                          <p className="text-muted d-flex align-items-center flex-wrap m-0">3 Months Subscription</p>
                        </div>
                      </div>

                      <div>
                        <span className="badge badge-soft-success fs-13">Points Worth - 1000 Pts</span>
                      </div>

                      <div className="action-btn">
                        <button type="button" className="btn btn-outline-primary btn-sm mx-1">
                          <i className="ri-edit-line"></i>
                        </button>

                        <button type="button" className="btn btn-outline-danger btn-sm mx-1">
                          <i className="ri-delete-bin-6-line"></i>
                        </button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col md={6} className="mb-2">
                <Card className="card-inner bg-white">
                  <CardBody>
                    <div className=" preview-item">
                      <div className="d-flex mb-2">
                        <div className="flex-shrink-0 avatar-sm me-2">
                          <div className="avatar-title bg-light rounded">
                            <img src="https://design.thriwe.com/thriwe-one-admin-surge/assets/images/brands/golf.jpeg" className="img-fluid" alt="" />
                          </div>
                        </div>

                        <div className="flex-grow-1 ms-3 ">
                          <h5 className="fs-15 mb-1">Zomato</h5>
                          <p className="text-muted d-flex align-items-center flex-wrap m-0">3 Months Subscription</p>
                        </div>
                      </div>

                      <div>
                        <span className="badge badge-soft-success fs-13">Points Worth - 1000 Pts</span>
                      </div>

                      <div className="action-btn">
                        <button type="button" className="btn btn-outline-primary btn-sm mx-1">
                          <i className="ri-edit-line"></i>
                        </button>

                        <button type="button" className="btn btn-outline-danger btn-sm mx-1">
                          <i className="ri-delete-bin-6-line"></i>
                        </button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col md={6} className="mb-2">
                <Card className="card-inner bg-white">
                  <CardBody>
                    <div className=" preview-item">
                      <div className="d-flex mb-2">
                        <div className="flex-shrink-0 avatar-sm me-2">
                          <div className="avatar-title bg-light rounded">
                            <img
                              src="https://design.thriwe.com/thriwe-one-admin-surge/assets/images/brands/mediclaim.jpeg"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                        </div>

                        <div className="flex-grow-1 ms-3">
                          <h5 className="fs-15 mb-1">Zomato</h5>
                          <p className="text-muted d-flex align-items-center flex-wrap m-0">
                            <span>
                              {' '}
                              <strong className="text-primary">5 </strong> Brands
                            </span>
                            <span>
                              {' '}
                              <strong className="text-primary">10 </strong> Outlets
                            </span>
                            <span>
                              {' '}
                              <strong className="text-primary">20 </strong> Benefits
                            </span>
                          </p>
                          <p>
                            {' '}
                            <i className="ri-check-line text-success fw-bold"></i>Tax Applicable{' '}
                          </p>
                        </div>
                      </div>

                      <div>
                        <span className="badge badge-soft-success fs-13">Points Worth - 1000 Pts</span>
                      </div>

                      <div className="action-btn">
                        <button type="button" className="btn btn-outline-primary btn-sm mx-1">
                          <i className="ri-edit-line"></i>
                        </button>

                        <button type="button" className="btn btn-outline-danger btn-sm mx-1">
                          <i className="ri-delete-bin-6-line"></i>
                        </button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <div className="d-flex align-items-start gap-3 mt-4">
          <button
            type="button"
            className="btn btn-light btn-label previestab"
            // onClick={() => {
            //   props.toggleTab(props.activeTab - 1)
            // }}
          >
            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
            Back to Catalog Item(s)
          </button>
          <button
            type="button"
            className="btn btn-primary btn-label right ms-auto nexttab"
            onClick={() => {
              //   props.onPressNext(groupItemList)
              // props.toggleTab(props.activeTab + 1)
            }}
          >
            <i className=" ri-check-double-line label-icon align-middle fs-16 ms-2"></i>
            Submit
          </button>
        </div>
      </div>
    </TabPane>
  );
};

export default CataloguePreview;
