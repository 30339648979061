import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Constant } from '../../../../../../Helpers/constant';
import { Grid, _ } from 'gridjs-react';

const TemplateDetailsModal = ({ templateDetailsModal, setTemplateDetailsMOdal, data }) => {
  const toggle = () => setTemplateDetailsMOdal(!templateDetailsModal);
  const [modalData, setModalData] = useState([]);

  useEffect(() => {
    if (data?.edges?.template_commsconfig?.length > 0) {
      const linkedProject = data?.edges?.template_commsconfig?.map((linkProject, index) => {
        return [
          `${index + 1}.`,
          linkProject?.channel || `NA`,
          linkProject?.project_id || `NA`,
          linkProject?.region || `NA`,
          linkProject?.event_id || `NA`,
        ];
      });
      setModalData(linkedProject);
    } else {
      setModalData([]);
    }
  }, [data]);

  return (
    <Modal isOpen={templateDetailsModal} toggle={toggle} size="lg">
      <div className="modal-header p-3 bg-soft-warning">
        <h5 className="modal-title" id="createboardModalLabel">
          Template Details
        </h5>
        <Button type="button" onClick={toggle} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close"></Button>
      </div>
      <>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <div id="table-gridjs">
                  <Grid
                    data={modalData}
                    columns={[
                      {
                        name: 'S.No.',
                        width: '60px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },
                      {
                        name: 'Channel',
                        width: '200px',
                        formatter: (cell, row) => _(<span className="fw-semibold cursor-pointer">{cell}</span>),
                      },
                      {
                        name: 'Linked Project',
                        width: '200px',
                        formatter: (cell, row) => _(<span className="fw-semibold cursor-pointer">{cell}</span>),
                      },

                      {
                        name: 'Region',
                        width: '170px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },
                      {
                        name: 'Event',
                        width: '170px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },
                    ]}
                    search={false}
                    sort={false}
                    fixedHeader={true}
                    height="400px"
                    pagination={{ enabled: false, limit: 5 }}
                    style={{
                      th: {
                        zIndex: '999',
                      },
                      td: {
                        position: 'relative',
                      },
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    </Modal>
  );
};

export default TemplateDetailsModal;
