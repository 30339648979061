import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import fetch from './../../fetch';
import { baseUrl, customHeaders } from '../../../../Containers/Config/index.js';

import { ASSIGN_COUPON, ASSIGN_COUPON_SUCCESS, ASSIGN_COUPON_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant';
import cogoToast from 'cogo-toast';

function* assignCoupon({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/assign-coupons`,
      method: 'PUT',
      data: payload.data,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({
        type: ASSIGN_COUPON_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(`${response.response.data.message}`);
      yield put({
        type: ASSIGN_COUPON_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: ASSIGN_COUPON_ERROR, payload: error });
  }
}

export function* watchAssignCoupon() {
  yield takeEvery(ASSIGN_COUPON, assignCoupon);
}

function* assignCouponSaga() {
  yield all([fork(watchAssignCoupon)]);
}

export default assignCouponSaga;
