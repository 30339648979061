import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import * as moment from 'moment';
import { isANumber } from '../../../../../../../Helpers/Helpers';
import { Error } from '../../../../../../../Common';

const CouponSettings = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */

  const [state, setState] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modal_varying1, setmodal_varying1] = useState(true);
  const [isDynamicCouponCode, setIsDynamicCouponCode] = useState(false);
  const [isValidationShow, setIsValidationShow] = useState(false);

  useEffect(() => {
    setState(props?.data?.data);
    setCurrentIndex(props?.data?.currentIndex);
    if (props?.data.data?.dynamicCode) {
      setIsDynamicCouponCode(true);
    }
  }, [props]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = state;
    const lengthValue = parseInt(value, 10);

    switch (name) {
      case 'thirdPartyWebsite':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              thirdPartyWebsite: value,
            },
          },
        };
        break;

      case 'prefix':
        if (value.length <= 3) {
          updatedState = {
            ...state,
            dynamicCode: {
              ...state?.dynamicCode,
              prefix: value,
            },
          };
        }
        break;

      case 'length':
        updatedState = {
          ...state,
          dynamicCode: {
            ...state?.dynamicCode,
            length: value === '' ? '' : isANumber(value) ? Number(value) : state?.dynamicCode?.length,
          },
        };
        break;

      case 'expiryValue':
        updatedState = {
          ...state,
          dynamicCode: {
            ...state?.dynamicCode,
            expiryValue: value === '' ? '' : isANumber(value) ? Number(value) : state?.dynamicCode?.expiryValue,
          },
        };
        break;

      case 'expiryUnit':
        updatedState = {
          ...state,
          dynamicCode: {
            ...state?.dynamicCode,
            expiryUnit: Number(value),
          },
        };
        break;

      default:
      // do nothing
    }
    setState(updatedState);
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'voucherDetailsExpiry':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,

              voucherDetails: {
                ...state?.benefitGroupItem?.completeData?.voucherDetails,
                expiry: moment(new Date(date[0])).format('Y-MM-DD'),
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validationCheck(state)) {
      props.onSubmit({
        currentIndex: currentIndex,
        data: {
          ...props.data,
          ...state,
        },
      });

      props.onCloseHandler(false);
      setmodal_varying1(!modal_varying1);
    }
  };

  /* ---------------------------- VALIDATION HANDLER ---------------------------- */
  const validationCheck = data => {
    if (isDynamicCouponCode) {
      const { prefix, length, expiryValue, expiryUnit } = data?.dynamicCode || {};
      const isValid = prefix?.length <= 3 && length !== '' && length >= 0 && length <= 10 && expiryValue !== '' && expiryValue >= 0 && expiryUnit;
      setIsValidationShow(!isValid);
      return isValid;
    } else {
      setIsValidationShow(false);
      return true;
    }
  };

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
      // centered
      size="md"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Coupon Settings
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log(state)}>get state</button> */}
        <Row>
          <Col md={12}>
            <div className="mb-3">
              <Label htmlFor="thirdPartyWebsite" className="form-label">
                Third Party Website URL
              </Label>
              <Input
                id="thirdPartyWebsite"
                name="thirdPartyWebsite"
                type="text"
                className="form-control"
                value={state?.benefitGroupItem?.completeData?.thirdPartyWebsite}
                onChange={inputChangeHandler}
              />
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <Label htmlFor="voucherDetailsExpiry" className="form-label">
                Voucher Expiry
                <span className="icon_calendar">
                  <i className="las la-calendar"></i>
                </span>
              </Label>
              <Flatpickr
                className="form-control"
                onChange={date => datePickHandler(`voucherDetailsExpiry`, date)}
                value={state?.benefitGroupItem?.completeData?.voucherDetails?.expiry}
                options={{
                  dateFormat: 'Y-m-d',
                  minDate: 'today',
                }}
              />
            </div>
          </Col>

          <Col md={12} className="mb-3">
            <div className="form-switch d-flex align-items-center">
              <Input
                className="form-check-input code-switcher form-switch-md cursor-pointer"
                type="checkbox"
                id="isDynamicCouponCode"
                name="isDynamicCouponCode"
                checked={isDynamicCouponCode}
                onChange={() => setIsDynamicCouponCode(!isDynamicCouponCode)}
              />
              <span>&nbsp; Dynamic Code Activation</span>
            </div>
          </Col>

          {isDynamicCouponCode && (
            <>
              <Col md={12}>
                <div className="mb-3">
                  <Label htmlFor="length" className="form-label">
                    Length
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="length"
                    name="length"
                    placeholder="Enter length (1-10)"
                    value={String(state?.dynamicCode?.length || 0)}
                    onChange={inputChangeHandler}
                  />
                  {isValidationShow && !state?.dynamicCode?.length && <Error text="Length is required" />}
                  {isValidationShow && (state?.dynamicCode?.length < 0 || state?.dynamicCode?.length > 10) && (
                    <Error text="Length must be between 0 and 10." />
                  )}
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Label htmlFor="expiryValue" className="form-label">
                    Expiry Value
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="expiryValue"
                    name="expiryValue"
                    placeholder="Enter expiry value"
                    value={String(state?.dynamicCode?.expiryValue || 0)}
                    onChange={inputChangeHandler}
                  />
                  {isValidationShow && !state?.dynamicCode?.expiryValue && <Error text="Expiry value is required" />}
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Label htmlFor="expiryValue" className="form-label">
                    Expiry Value
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="expiryValue"
                    name="expiryValue"
                    placeholder="Enter expiry value"
                    value={String(state?.dynamicCode?.expiryValue || 0)}
                    onChange={inputChangeHandler}
                  />
                  {isValidationShow && !state?.dynamicCode?.expiryValue && <Error text="Expiry value is required" />}
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Label htmlFor="expiryUnit" className="form-label">
                    Expiry Unit
                  </Label>
                  <select
                    id="expiryUnit"
                    name="expiryUnit"
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.dynamicCode?.expiryUnit}
                  >
                    <option value="">Choose</option>
                    <option value="4">Hours</option>
                    <option value="5">Minutes</option>
                    <option value="2">Months</option>
                    <option value="3">Days</option>
                  </select>
                  {isValidationShow && !state?.dynamicCode?.expiryUnit && <Error text="Expiry unit is required" />}
                </div>
              </Col>
            </>
          )}
        </Row>
      </ModalBody>

      <ModalFooter className="d-flex justify-content-center">
        <Button
          className="overlay-disable"
          color="primary"
          onClick={submitHandler}
          //   disabled={isSubmitBtnDisabled}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CouponSettings;
