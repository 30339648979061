import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';

import { Button, Modal, ModalBody, ModalHeader, Col, Label, Input, Row } from 'reactstrap';

import { UPDATE_SERVICE_WORTH } from '../../../../../../store/application/actionType';

const UpdateServiceWorthModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    burnValue: 0,
    earnValue: 0,
    objectId: '',
    projectId: '',
    serviceId: '',
    serviceName: '',
  };
  const [state, setState] = useState(initialObj);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [modalData, setModalData] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */

  useEffect(() => {
    setState({
      burnValue: data?.service?.burnValue,
      earnValue: data?.service?.earnValue,
      objectId: data.objectId,
      projectId: '',
      serviceId: data?.service?.id,
      serviceName: data?.service?.name,
      // ...data,
    });

    validationCheck(state);
  }, [data]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'earnValue':
        updatedState = {
          ...state,
          earnValue: isNaN(parseInt(value)) ? value : parseInt(value),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'burnValue':
        updatedState = {
          ...state,
          burnValue: isNaN(parseInt(value)) ? value : parseInt(value),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data?.earnValue !== `` && data?.burnValue !== ``) {
      setIsSubmitBtnDisabled(false);
    } else {
      setIsSubmitBtnDisabled(true);
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const payload = {
      objectId: state?.objectId,
      projectId: state?.projectId,
      delete: false,
      services: [
        {
          serviceId: state?.serviceId,
          serviceName: state?.serviceName,
          earnValue: state?.earnValue,
          burnValue: state?.burnValue,
        },
      ],
    };

    dispatch({
      type: UPDATE_SERVICE_WORTH,
      payload: {
        data: payload,
      },
    });

    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <>
      <Modal
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1();
        }}
        id="exampleModal"
      >
        <div className="modal-header p-3 bg-soft-secondary">
          <h5 className="modal-title" id="createboardModalLabel">
            Update Service Worth
          </h5>
          <Button type="button" onClick={() => tog_varying1()} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close"></Button>
        </div>

        <ModalBody>
          {/* <button onClick={() => console.log({ state })}>Get state</button> */}
          <form>
            <Row>
              <div className="mb-3">
                <Col md={12}>
                  <div>
                    <Label htmlFor="name" className="form-label">
                      Service Name
                      <span className="lbl_mandatory">*</span>
                    </Label>
                    <Input className="form-control" value={state?.serviceName} disabled={true} />
                  </div>
                </Col>
              </div>

              <div className="mb-3">
                <Col md={12}>
                  <div>
                    <Label htmlFor="name" className="form-label">
                      Earn Value
                      <span className="lbl_mandatory">*</span>
                    </Label>
                    <Input
                      id="earnValue"
                      name="earnValue"
                      type="number"
                      onWheel={e => e.target.blur()}
                      className="form-control"
                      onChange={inputChangeHandler}
                      value={state?.earnValue}
                    />
                  </div>
                </Col>
              </div>

              <div className="mb-3">
                <Col md={12}>
                  <div>
                    <Label htmlFor="name" className="form-label">
                      Burn Value
                      <span className="lbl_mandatory">*</span>
                    </Label>
                    <Input
                      id="burnValue"
                      name="burnValue"
                      type="number"
                      onWheel={e => e.target.blur()}
                      className="form-control"
                      onChange={inputChangeHandler}
                      value={state?.burnValue}
                    />
                  </div>
                </Col>
              </div>
            </Row>
          </form>
        </ModalBody>
        <div className="modal-footer d-flex justify-content-center">
          <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default UpdateServiceWorthModal;
