import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { Constant } from '../../../../../../Helpers/constant';

import { Button, Modal, ModalBody, ModalHeader, Col, Label, Input, Row } from 'reactstrap';

import { GET_SERVICE, GET_SERVICE_EMPTY, UPDATE_BOOKING } from '../../../../../../../store/application/actionType';
import { Loader } from '../../../../../../Common';

const UpdateBookingModal = ({ locations, data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    // bookingDateTime: '',
    facility: null,
    service: null,
    dateTimeOfService: {
      date: '',
      time: '',
    },
  };
  const [state, setState] = useState(initialObj);
  const [golfCourses, setGolfCourses] = useState([]);
  const [allServices, setAllServices] = useState([]);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [modalData, setModalData] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Services
  const { services, servicesLoading } = useSelector(state => ({
    services: state?.GetService?.data,
    servicesLoading: state?.GetService?.loading,
  }));

  useEffect(() => {
    const updatedState = {
      ...state,
      facility: {
        label: data?.facility?.name,
        value: data?.facility?.id,
      },
      service: {
        label: data?.service?.name,
        value: data?.service?.id,
      },
      dateTimeOfService: {
        date: data?.dateTimeOfService?.date,
        time: data?.dateTimeOfService?.time,
      },
      // bookingDateTime: data?.timeOfPlay,
    };
    setState(updatedState);
    validationCheck(updatedState);

    dispatch({
      type: GET_SERVICE,
      payload: {
        urlParam: {
          facilityId: data?.facility?.id,
        },
      },
    });
  }, [data]);

  useEffect(() => {
    if (locations?.results?.length > 0) {
      setGolfCourses(
        locations?.results?.map(location => {
          return {
            label: location.name,
            value: location.objectId,
          };
        }),
      );
    } else {
      setGolfCourses([]);
    }
  }, [locations]);

  useEffect(() => {
    if (services?.results?.length > 0) {
      setAllServices(
        services?.results?.map(service => {
          return {
            label: service.name,
            value: service.objectId,
          };
        }),
      );
    } else {
      dispatch({ type: GET_SERVICE_EMPTY });
      setAllServices([]);
    }
  }, [services]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const selectInputChangeHandler = (label, data) => {
    let updatedState = null;

    switch (label) {
      case 'golfCourse':
        updatedState = {
          ...state,
          facility: data,
          service: null,
        };
        setState(updatedState);
        validationCheck(updatedState);

        dispatch({
          type: GET_SERVICE,
          payload: {
            urlParam: {
              facilityId: data.value,
            },
          },
        });
        break;

      case 'service':
        updatedState = {
          ...state,
          service: data,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    let confirmedData = null;
    if (data?.bookingStatusCode === Constant?.BOOKING_STATUS?.CONFIRM) {
      confirmedData = {
        confirmedDate: state?.dateTimeOfService?.date,
        confirmedTime: state?.dateTimeOfService?.time,
      };
    } else {
      confirmedData = {
        dateTimeOfService: {
          date: state?.dateTimeOfService?.date,
          time: state?.dateTimeOfService?.time,
        },
      };
    }

    const payload = {
      objectId: data?.bookingId,
      detailedBookingId: data?.detailedBookingId,
      ...confirmedData,
      service: {
        id: state.service.value,
        name: state.service.label,
      },
      process: `update-booking`,
    };

    dispatch({
      type: UPDATE_BOOKING,
      payload: {
        data: {
          projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
          bookingTypeId: Constant?.BOOKING_TYPES?.hireADriverBookings?.objectId,
          bookingsData: [{ ...payload }],
        },
      },
    });

    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'dateAndTimeOfPlay':
        updatedState = {
          ...state,
          bookingDateTime: new Date(date).toISOString(),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (
      data?.dateOfPlay !== `` &&
      data?.dateTimeOfService?.time !== `` &&
      // data?.bookingDateTime !== `` &&
      data?.facility !== null &&
      data?.service !== null
    ) {
      setIsSubmitBtnDisabled(false);
    } else {
      setIsSubmitBtnDisabled(true);
    }
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <>
      <Modal
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1();
        }}
        id="exampleModal"
        // scrollable={true}
      >
        <div className="modal-header p-3 bg-soft-secondary">
          <h5 className="modal-title" id="createboardModalLabel">
            Update Booking
          </h5>
          <Button
            type="button"
            onClick={() => {
              tog_varying1();
            }}
            id="addBoardBtn-close"
            className="btn-close btn-light"
            aria-label="Close"
          ></Button>
        </div>

        <ModalBody>
          {/* <button onClick={() => console.log({ state })}>Get state</button> */}
          <form>
            <Row>
              <div className="mb-3">
                <Col md={12}>
                  <div>
                    <Label htmlFor="name" className="form-label">
                      Booking ID
                    </Label>
                    <Input className="form-control" value={data?.bookingId} disabled />
                  </div>
                </Col>
              </div>
              <div className="mb-3">
                <Col md={12}>
                  <div>
                    <Label htmlFor="name" className="form-label">
                      User Name
                    </Label>
                    <Input className="form-control" value={data?.user?.name} disabled />
                  </div>
                </Col>
              </div>

              <div className="mb-3">
                <Col md={12}>
                  <div>
                    <Label htmlFor="service" className="form-label">
                      Service <span className="lbl_mandatory">*</span>
                    </Label>

                    <Select
                      id="service"
                      name="service"
                      options={allServices}
                      value={state.service}
                      onChange={data => selectInputChangeHandler(`service`, data)}
                    />
                    {/* <select
                      id="service"
                      name="service"
                      className="form-select"
                      value={state?.service?.id}
                      onChange={inputChangeHandler}
                    >
                      <option value="">Choose...</option>
                      {services?.results?.map((service, index) => (
                        <option key={index} value={service?.objectId}>
                          {service?.name}
                        </option>
                      ))}
                    </select> */}
                  </div>
                </Col>
              </div>

              <div className="mb-3">
                <Col md={12}>
                  <div>
                    <Label htmlFor="date-of-play" className="form-label">
                      Time of Play <span className="lbl_mandatory">*</span>
                    </Label>
                    <div className="area_expandable">
                      <Input
                        value={state?.dateTimeOfService?.time}
                        className="form-control"
                        type="time"
                        onChange={e => {
                          const data = {
                            ...state,
                            dateTimeOfService: {
                              date: state?.dateTimeOfService?.date,
                              time: e.target.value,
                            },
                            // bookingDateTime: e.target.value,
                          };
                          setState(data);
                          validationCheck(data);
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </div>
            </Row>
          </form>
        </ModalBody>
        <div className="modal-footer d-flex justify-content-center">
          <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default UpdateBookingModal;
