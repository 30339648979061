import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import apiCall from '../../apiCall';
import { customHeaders, indianProjectsBaseURL } from './../../../../Containers/Config/index';

import { PUBLISH_DATA_WITH_AUTH, PUBLISH_DATA_WITH_AUTH_SUCCESS, PUBLISH_DATA_WITH_AUTH_ERROR } from './../../actionType';
import { Constant } from '../../../../Containers/Helpers/constant';
import cogoToast from 'cogo-toast';

function* publishDataWithAuth({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/admin/publish-data-with-auth`,
      method: 'POST',
      headers: { Authorization: Constant?.CLIENT_TOKEN, ...customHeaders, 'Project-Code': 'ADMIN_ACCESS' },
      data: payload?.data,
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({ type: PUBLISH_DATA_WITH_AUTH_SUCCESS, payload: response });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({ type: PUBLISH_DATA_WITH_AUTH_ERROR, payload: response.error });
    }
  } catch (error) {
    yield put({ type: PUBLISH_DATA_WITH_AUTH_ERROR, payload: error });
  }
}

export function* watchPublishDataWithAuth() {
  yield takeEvery(PUBLISH_DATA_WITH_AUTH, publishDataWithAuth);
}

function* publishDataWithAuthSaga() {
  yield all([fork(watchPublishDataWithAuth)]);
}

export default publishDataWithAuthSaga;
