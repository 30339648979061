import { useDispatch } from 'react-redux';

import axios from 'axios';
import Select from 'react-select';
import { Col, Input, Label, Row } from 'reactstrap';
import { AsyncPaginate } from 'react-select-async-paginate';
import { debounce } from 'lodash';

import { Constant } from '../../../../../../../../Helpers/constant';
import { GET_CLIENT_PRICE, GET_RECON_DETAILS } from '../../../../../../../../../store/actions';
import { customHeaders, indianProjectsBaseURL } from '../../../../../../../../Config';
import { useCallback } from 'react';

const ReconStatusList = [
  {
    value: ``,
    label: `All`,
  },
  {
    value: `1`,
    label: `Open`,
  },
  {
    value: `2`,
    label: `Close`,
  },
];

const ReconForList = [
  {
    value: ``,
    label: `All`,
  },
  {
    value: `1`,
    label: `Client`,
  },
  {
    value: `2`,
    label: `Vendor`,
  },
];

const Filters = ({ filters, setFilters, setCurrentPageNumber }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const onChangeHandler = (name, event) => {
    let updatedFilters = { ...filters };

    switch (name) {
      case 'reconType':
        updatedFilters = {
          ...filters,
          reconType: event,
        };
        break;

      case 'benfitDomain':
        updatedFilters = {
          ...filters,
          benfitDomain: event,
        };
        break;

      case 'clientOrVendorName':
        updatedFilters = {
          ...filters,
          clientOrVendorName: event?.target?.value,
        };
        break;

      case 'recon-status':
        updatedFilters = {
          ...filters,
          status: event,
        };
        break;

      default:
      // do nothing
    }

    setFilters(updatedFilters);
    setCurrentPageNumber(0);

    if (name !== `clientOrVendorName`) {
      let urlParams = {
        reconType: updatedFilters?.reconType === `` ? null : updatedFilters?.reconType?.value,
        benfitDomain: updatedFilters?.benfitDomain === `` ? null : updatedFilters?.benfitDomain?.value,
        clientOrVendorName: updatedFilters?.clientOrVendorName === `` ? null : updatedFilters?.clientOrVendorName,
        status: updatedFilters?.status === `` ? null : updatedFilters?.status?.value,
        limit: Constant?.TABLE_PAGE_SIZE,
        skip: 0,
      };

      dispatch({
        type: GET_RECON_DETAILS,
        payload: {
          urlParam: {
            ...urlParams,
          },
        },
      });
    }
  };

  /* ---------------------------- GET BENEFIT TYPE HANDLER ---------------------------- */
  async function loadBenefitType(search, loadedOptions) {
    const results = [
      {
        objectId: `${process.env.REACT_APP_GOLF_BENEFIT_DOMAIN_ID}`,
        name: 'Golf Play',
      },
    ];

    return {
      options: results?.map(data => {
        return {
          value: data?.objectId,
          label: `${data?.name}`,
          completeData: data,
        };
      }),
      hasMore: false,
    };
  }

  const handleEnterPress = useCallback(
    debounce(() => {
      let urlParams = {
        reconType: filters?.reconType === `` ? null : filters?.reconType?.value,
        benfitDomain: filters?.benfitDomain === `` ? null : filters?.benfitDomain?.value,
        clientOrVendorName: filters?.clientOrVendorName === `` ? null : filters?.clientOrVendorName,
        status: filters?.status === `` ? null : filters?.status?.value,
        limit: Constant?.TABLE_PAGE_SIZE,
        skip: 0,
      };

      dispatch({
        type: GET_RECON_DETAILS,
        payload: {
          urlParam: {
            ...urlParams,
          },
        },
      });
    }, 100),
    [filters?.clientOrVendorName],
  );

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleEnterPress();
    }
  };

  return (
    <>
      <Row className="my-4">
        {/* <button
          onClick={e => {
            e.preventDefault();
            console.log(filters);
          }}
        >
          get state
        </button> */}

        <Col md={12}>
          <h5>Filters -</h5>
        </Col>

        <Col xl={3} lg={3} md={4} sm={6} xs={12} className="mb-3">
          <Label htmlFor="status" className="form-label">
            Recon for
          </Label>
          <Select
            id="reconType"
            name="reconType"
            options={ReconForList}
            value={filters?.reconType}
            onChange={data => onChangeHandler(`reconType`, data)}
            // isClearable={true}
          />
        </Col>

        <Col xl={3} lg={3} md={4} sm={6} xs={12} className="mb-3">
          <Label htmlFor="status" className="form-label">
            Benefit Type
          </Label>
          <AsyncPaginate
            debounceTimeout={500}
            value={filters?.benfitDomain}
            loadOptions={loadBenefitType}
            onChange={e => onChangeHandler(`benfitDomain`, e)}
            isMulti={false}
            closeMenuOnSelect={true}
            noOptionsMessage={() => 'No results found'}
            cacheUniqs={[['code']]}
            placeholder="Select..."
            isClearable={true}
          />
        </Col>

        <Col xl={3} lg={3} md={4} sm={6} xs={12} className="mb-3">
          <Label htmlFor="status" className="form-label">
            Client/Vendor Name
          </Label>
          <Input
            id="clientOrVendorName"
            name="clientOrVendorName"
            type="text"
            placeholder="Enter name"
            className="form-control"
            onChange={e => onChangeHandler(`clientOrVendorName`, e)}
            value={filters?.clientOrVendorName}
            onKeyDown={handleKeyDown}
          />

          {/* <AsyncPaginate
            debounceTimeout={500}
            value={filters?.benfitDomain}
            loadOptions={loadBenefitType}
            onChange={e => onChangeHandler(`benfitDomain`, e)}
            isMulti={false}
            closeMenuOnSelect={true}
            noOptionsMessage={() => 'No results found'}
            cacheUniqs={[['code']]}
            placeholder="Select..."
            isClearable={true}
            menuIsOpen={false}
          /> */}
        </Col>

        <Col xl={3} lg={3} md={4} sm={6} xs={12} className="mb-3">
          <Label htmlFor="status" className="form-label">
            Status
          </Label>
          <Select
            id="recon-status"
            name="recon-status"
            options={ReconStatusList}
            value={filters?.status}
            onChange={data => onChangeHandler(`recon-status`, data)}
            // isClearable={true}
          />
        </Col>
      </Row>
    </>
  );
};

export default Filters;
