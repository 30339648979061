import ReactPaginate from 'react-paginate';

import { Constant } from '../../Helpers/constant';

const TablePagination = ({ totalData, currentPage, onPageChangeHandler }) => {
  return (
    <ReactPaginate
      previousLabel={'<<'}
      nextLabel={'>>'}
      breakLabel={'...'}
      forcePage={currentPage}
      pageCount={Math.ceil(totalData / Constant.TABLE_PAGE_SIZE)}
      marginPagesDisplayed={1}
      pageRangeDisplayed={2}
      onPageChange={event => {
        onPageChangeHandler(event.selected);
      }}
      containerClassName={'pagination justify-content-center'}
      pageClassName={'page-item'}
      pageLinkClassName={'page-link'}
      previousClassName={'page-item'}
      previousLinkClassName={'page-link'}
      nextClassName={'page-item'}
      nextLinkClassName={'page-link'}
      breakClassName={'page-item'}
      breakLinkClassName={'page-link'}
      activeClassName={'active'}
    />
  );
};

export default TablePagination;
