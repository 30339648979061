import React, { useEffect, useState } from 'react';
import './App.css';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
//import Scss
import './assets/scss/themes.scss';

//imoprt Route
import Route from './Routes';
import { GET_PROJECT_UTILITY } from './store/actions';

const App = () => {
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    console.log = function () {};
  }
  const { clientId } = useParams();
  const dispatch = useDispatch();
  const [projectUtility, setProjectUtility] = useState();

  const { getProjectUtility } = useSelector(state => ({
    getProjectUtility: state.GetProjectUtility.data?.results,
  }));

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('projectUtility')) || clientId) {
      console.log("localStorage.getItem('projectUtility')", localStorage.getItem('projectUtility'));
      dispatch({ type: GET_PROJECT_UTILITY });
    }
  }, []);
  useEffect(() => {
    if (getProjectUtility != null) {
      setProjectUtility(localStorage.setItem('projectUtility', JSON.stringify({ results: getProjectUtility })));
    }
  }, [getProjectUtility]);

  // const [isOnline, setIsOnline] = useState(navigator.onLine);
  // useEffect(() => {
  //   const updateOnlineStatus = () => {
  //     setIsOnline(navigator.onLine);
  //   };

  //   window.addEventListener('online', updateOnlineStatus);
  //   window.addEventListener('offline', updateOnlineStatus);

  //   return () => {
  //     window.removeEventListener('online', updateOnlineStatus);
  //     window.removeEventListener('offline', updateOnlineStatus);
  //   };
  // }, []);

  useEffect(() => {
    console.log('lang inapp', localStorage.getItem('i18nextLng'));
  }, [localStorage.getItem('i18nextLng')]);

  function FallbackComponent() {
    return <div>An error has occured</div>;
  }

  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
};

export default App;
