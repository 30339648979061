import { GET_COMS_BENEFIT, GET_COMS_BENEFIT_SUCCESS, GET_COMS_BENEFIT_ERROR, GET_COMS_BENEFIT_EMPTY } from './../../actionType.js';

const initialState = {
  data: null,
  message: null,
  loading: false,
  success: false,
  error: false,
};

const GetComsBenefit = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMS_BENEFIT:
      state = {
        ...state,
        // data: null,
        loading: true,
        success: false,
        error: false,
        message: null,
      };
      break;

    case GET_COMS_BENEFIT_SUCCESS:
      state = {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
        error: false,
        message: null,
      };

      break;

    case GET_COMS_BENEFIT_ERROR:
      state = {
        ...state,
        data: null,
        loading: false,
        success: false,
        error: true,
        message: action.payload,
      };
      break;

    case GET_COMS_BENEFIT_EMPTY:
      state = {
        ...initialState,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default GetComsBenefit;
