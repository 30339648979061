import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, uaeProjectsBaseURL } from './../../../../../Containers/Config/index';
import { CREATE_CATEGORY, CREATE_CATEGORY_SUCCESS, CREATE_CATEGORY_ERROR } from './../../../actionType';
import apiCall from '../../../apiCall';
import { Constant } from '../../../../../Containers/Helpers/constant';
import cogoToast from 'cogo-toast';

function* createCategory({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/admin/categories`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      data: payload.data,
    });

    if (response.status === 200) {
      cogoToast.success(`${response?.data?.message}`);
      yield put({
        type: CREATE_CATEGORY_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: CREATE_CATEGORY_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: CREATE_CATEGORY_ERROR, payload: error });
  }
}

export function* watchCreateCategorySaga() {
  yield takeEvery(CREATE_CATEGORY, createCategory);
}

function* createCategorySaga() {
  yield all([fork(watchCreateCategorySaga)]);
}

export default createCategorySaga;
