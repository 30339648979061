export class Constant {
  static INDIA_PROJECTS = [`EPIFI_V1`, `MOBILEPE`];

  // static isClient = useLocation()?.pathname?.includes('/client/') ?? false;

  static TABLE_PAGE_SIZE = 10;

  static CURRENTLY_LOGGED_IN_USER = JSON.parse(localStorage?.getItem('authUser'))?.data;

  static CURRENTLY_ASSIGNED_PERMISSIONS = JSON.parse(localStorage?.getItem('authUser'))?.permissions;

  static CURRENT_SELECTED_PROGRAM = JSON.parse(window?.localStorage?.getItem('data'))?.program;

  static CURRENT_SELECTED_CLIENT = JSON.parse(window?.localStorage?.getItem('data'))?.program?.client;

  static CLIENT_TOKEN = JSON.parse(window.localStorage.getItem('authUser'))?.token;
  static CLIENT_OTP_VALIDATE_TOKEN = JSON.parse(window.localStorage.getItem('authUser'))?.validateOtpToken;

  static PROJECT_CODE = JSON.parse(window.localStorage.getItem('data'))?.program?.code;

  static CURRENT_LOGGED_USER_ID = JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId;

  static GOLF_COURSE_CATEGORY_ID = `QFQtHAsdcs`;

  static BOOKING_STATUSES = [
    { name: `Init`, objectId: 0, bgColor: `pending` },
    { name: `Pending`, objectId: 1, bgColor: `pending` },
    { name: `In Process`, objectId: 2, bgColor: `in-process` },
    { name: `Processed`, objectId: 3, bgColor: `processed` },
    { name: `Confirmed`, objectId: 4, bgColor: `confirmed` },
    {
      name: `Short Notice Cancelled By User`,
      objectId: 5,
      bgColor: `cancelled`,
    },
    { name: `Cancel By User`, objectId: 6, bgColor: `cancelled` },
    { name: `Cancel By Thriwe`, objectId: 7, bgColor: `cancelled` },
    { name: `Cancelled`, objectId: 567, bgColor: `cancelled` },
    { name: `Expired`, objectId: 8, bgColor: `expired` },
    { name: `Redeemed`, objectId: 9, bgColor: `redeemed` },
    {
      name: `Short Notice Cancelled By Thriwe`,
      objectId: 10,
      bgColor: `cancelled`,
    },
    {
      name: `Completed`,
      objectId: 11,
      bgColor: `completed`,
    },
    {
      name: `Recommended`,
      objectId: 12,
      bgColor: `processed`,
    },
    {
      name: `Rescheduled`,
      objectId: 13,
      bgColor: `processed`,
    },
    {
      name: `Rescheduled Acknowledged`,
      objectId: 14,
      bgColor: `completed`,
    },
    {
      name: `In Transit`,
      objectId: 15,
      bgColor: `processed`,
    },
    {
      name: `Payment Failed`,
      objectId: 16,
      bgColor: `cancelled`,
    },
    {
      name: `Payment Pending`,
      objectId: 17,
      bgColor: `completed`,
    },
    {
      name: `Payment Received`,
      objectId: 18,
      bgColor: `confirmed`,
    },
    {
      name: `Requested`,
      objectId: 19,
      bgColor: `pending`,
    },
    {
      name: `Rejected`,
      objectId: 20,
      bgColor: `cancelled`,
    },
  ];

  static BOOKING_STATUSES_V2 = [
    { name: `Init`, bookingStatus: 'INIT', bgColor: `pending` },
    { name: `Pending`, bookingStatus: 'PENDING', bgColor: `pending` },
    { name: `In Process`, bookingStatus: 'IN_PROCESS', bgColor: `in-process` },
    { name: `Processed`, bookingStatus: 'PROCESSED', bgColor: `processed` },
    { name: `Confirmed`, bookingStatus: 'CONFIRM', bgColor: `confirmed` },
    {
      name: `Short Notice Cancelled By User`,
      bookingStatus: 'SHORT_NOTICE_CANCELLED_BY_USER',
      bgColor: `cancelled`,
    },
    { name: `Cancel By User`, bookingStatus: 'CANCELLED_BY_USER', bgColor: `cancelled` },
    { name: `Cancel By Thriwe`, bookingStatus: 'CANCELLED_BY_THRIWE', bgColor: `cancelled` },
    { name: `Cancelled`, bookingStatus: 'ALL_CANCELLED_BOOKINGS', bgColor: `cancelled` },
    { name: `Expired`, bookingStatus: 'EXPIRED', bgColor: `expired` },
    { name: `Redeemed`, bookingStatus: 'REDEEMED', bgColor: `redeemed` },
    {
      name: `Short Notice Cancelled By Thriwe`,
      bookingStatus: 'SHORT_NOTICE_CANCELLED_BY_THRIWE',
      bgColor: `cancelled`,
    },
    { name: `Completed`, bookingStatus: 'COMPLETED', bgColor: `completed` },
    { name: `Recommended`, bookingStatus: 'RECOMMENDED', bgColor: `processed` },
    { name: `Rescheduled`, bookingStatus: 'RESCHEDULED', bgColor: `processed` },
    {
      name: `Rescheduled Acknowledged`,
      bookingStatus: 'RESCHEDULED_ACKNOWLEDGED',
      bgColor: `completed`,
    },
    { name: `In Transit`, bookingStatus: 'IN_TRANSIT', bgColor: `processed` },
    { name: `Payment Failed`, bookingStatus: 'PAYMENT_FAILED', bgColor: `cancelled` },
    { name: `Payment Pending`, bookingStatus: 'PAYMENT_PENDING', bgColor: `completed` },
    { name: `Payment Received`, bookingStatus: 'PAYMENT_RECEIVED', bgColor: `confirmed` },
    { name: `Requested`, bookingStatus: 'REQUESTED', bgColor: `pending` },
    { name: `Rejected`, bookingStatus: 'REJECTED', bgColor: `cancelled` },
  ];

  static BOOKING_STATUS = {
    INIT: 0,
    PENDING: 1,
    IN_PROCESS: 2,
    PROCESSED: 3,
    CONFIRM: 4,
    SHORT_NOTICE_CANCELLED_BY_USER: 5,
    CANCEL_BY_USER: 6,
    CANCEL_BY_THRIWE: 7,
    CANCELLED: 567,
    EXPIRED: 8,
    REDEEMED: 9,
    SHORT_NOTICE_CANCELLED_BY_THRIWE: 10,
    COMPLETED: 11,
    RECOMMENDED: 12,
    RESCHEDULED: 13,
    RESCHEDULED_ACKNOWLEDGE: 14,
    IN_TRANSIT: 15,
    PAYMENT_FAILED: 16,
    PAYMENT_PENDING: 17,
    PAYMENT_RECEIVED: 18,
    REQUESTED: 19,
    REJECTED: 20,
  };

  static BOOKING_STATUS_V2 = {
    INIT: 'INIT',
    PENDING: 'PENDING',
    IN_PROCESS: 'IN_PROCESS',
    PROCESSED: 'PROCESSED',
    CONFIRM: 'CONFIRM',
    SHORT_NOTICE_CANCELLED_BY_USER: 'SHORT_NOTICE_CANCELLED_BY_USER',
    CANCELLED_BY_USER: 'CANCELLED_BY_USER',
    CANCELLED_BY_THRIWE: 'CANCELLED_BY_THRIWE',
    ALL_CANCELLED_BOOKINGS: 'ALL_CANCELLED_BOOKINGS',
    EXPIRED: 'EXPIRED',
    REDEEMED: 'REDEEMED',
    SHORT_NOTICE_CANCELLED_BY_THRIWE: 'SHORT_NOTICE_CANCELLED_BY_THRIWE',
    COMPLETED: 'COMPLETED',
    RECOMMENDED: 'RECOMMENDED',
    RESCHEDULED: 'RESCHEDULED',
    RESCHEDULED_ACKNOWLEDGED: 'RESCHEDULED_ACKNOWLEDGED',
    IN_TRANSIT: 'IN_TRANSIT',
    PAYMENT_FAILED: 'PAYMENT_FAILED',
    PAYMENT_PENDING: 'PAYMENT_PENDING',
    PAYMENT_RECEIVED: 'PAYMENT_RECEIVED',
    REQUESTED: 'REQUESTED',
    REJECTED: 'REJECTED',
  };

  static SIDEBAR_TYPES = {
    BOOKING: 'BOOKING',
    SUPPLY_STORE: 'SUPPLY_STORE',
  };

  static BOOKING_TYPES = {
    staticCouponBookings: {
      objectId: 1,
      name: 'Static Coupon Bookings',
    },
    uniqueCouponBookings: {
      objectId: 2,
      name: 'Unique Coupon Bookings',
    },
    qrBookings: {
      objectId: 3,
      name: 'QR Bookings',
    },
    golfBookings: {
      objectId: 4,
      name: 'Golf Bookings',
    },
    pickupAndDropBookings: {
      objectId: 5,
      name: 'Pickup & Drop Bookings',
    },
    meetAndGreetBookings: {
      objectId: 6,
      name: 'Meet And Greet Bookings',
    },
    localCourierBookings: {
      objectId: 7,
      name: 'Local Courier Bookings',
    },
    insuranceBookings: {
      objectId: 8,
      name: 'Insurance Bookings',
    },
    dynamicCouponBookings: {
      objectId: 9,
      name: 'Dynamic Coupon Bookings',
    },
    carServicingBookings: {
      objectId: 14,
      name: 'Car Servicing Bookings',
    },
    carRegistrationBookings: {
      objectId: 15,
      name: 'Car Registration Bookings',
    },
    hireADriverBookings: {
      objectId: 12,
      name: 'Hire a Driver Bookings',
    },
    clubBookings: {
      objectId: 13,
      name: 'Club Bookings',
    },
    valetParking: {
      objectId: 16,
      name: 'Valet Parking Bookings',
    },
    thriweDining: {
      objectId: 18,
      name: 'Thriwe Dining Bookings',
    },
    delayedCouponBookings: {
      objectId: 17,
      name: 'Delayed Coupon Bookings',
    },
    dynamicQrBookings: {
      objectId: 9,
      name: 'Dynamic Qr Bookings',
    },
    carterXBookings: {
      objectId: 10,
      name: 'CarterX Bookings',
    },
    dynamicBookings: {
      objectId: 21,
      name: 'Dynamic Bookings',
    },
  };

  static GENDER_TYPES = ['Male', 'Female', 'Others'];

  static WORKFLOWS = [`DEFAULT`, `VIA_VENDOR`];

  static PAYMENT_STATUSES = [
    {
      objectId: 1,
      name: 'Complete',
    },
    {
      objectId: 2,
      name: 'Pending',
    },
    {
      objectId: 3,
      name: 'Failed',
    },
  ];

  static CALENDER_DATA = [`Year`, `Month`, `Day`, `Hour`, `Minute`, `Second`, `Clear day`];
  static EXCEL_FILE_FORMATS = ['xlxs', 'xlsx', 'xls'];

  static CkEditorToolbar = [
    'heading',
    '|',
    'fontSize',
    'fontFamily',
    '|',
    'fontColor',
    'fontBackgroundColor',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    '|',
    'alignment',
    '|',
    'numberedList',
    'bulletedList',
    '|',
    'outdent',
    'indent',
    '|',
    'todoList',
    'link',
    'blockQuote',
    'insertTable',
    'mediaEmbed',
    '|',
    'superscript',
    'subscript',
    'removeFormat',
    'specialCharacters',
    'undo',
    'redo',
  ];

  static campaignStatuses = [
    { value: `1`, label: `Draft` },
    { value: `2`, label: `Active` },
    { value: `3`, label: `OnHold` },
    { value: `4`, label: `Closed` },
    { value: `5`, label: `Cancelled` },
  ];

  static bookingTypes = {
    STATIC_COUPON_CODE: 1,
    UNIQUE_COUPON_CODE: 2,
    QR_BOOKINGS: 3,
    GOLF_BOOKINGS: 4,
    PICK_AND_DROP_BOOKINGS: 5,
    MEET_AND_GREET_BOOKINGS: 6,
    LOCAL_COURIER_BOOKINGS: 7,
    INSURANCE_SERVICE_BOOKINGS: 8,
    DYNAMIC_COUPON_BOOKING: 9,
    BAGGAGE_BOOKING: 10,
    DELAYED_BOOKING: 11,
    HIRE_A_DRIVER: 12,
    CLUB_BOOKING: 13,
    CAR_SERVICING: 14,
    CAR_REGISTRATION: 15,
    VALET_PARKING: 16,
    DELAYED_COUPON: 17,
  };

  static userTypes = {
    ADMIN_USER: 1,
    CLIENT: 2,
    VENDOR: 3,
  };

  static benefitType = [`Complementary`, `Discounted`];
}
