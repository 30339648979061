import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { Container } from 'reactstrap';
import Filter from './Components/Filters/Filter';
import Table from './Components/Tables/Table';
import { ActionConfirmation, BreadCrumb, Loader } from '../../../../Common';
import { Constant } from '../../../../Helpers/constant';

import BookingDetails from './Components/Details/Details';
import CancelModal from './Components/Modals/CancelModal';
import ConfirmModal from './Components/Modals/ConfirmModal';
import UpdateBookingModal from './Components/Modals/Update';
import BulkCancelModal from './Components/Modals/BulkUpdateModals/CancelModal';
import BulkProcessModal from './Components/Modals/BulkUpdateModals/ProcessModal';
import BulkConfirmModal from './Components/Modals/BulkUpdateModals/ConfirmModal';
import BulkResendMailModal from './Components/Modals/BulkUpdateModals/ResendMailModal';
import BulkUpdateModal from './Components/Modals/BulkUpdateModals/UpdateModal';
import ConfirmationWithData from './Components/Modals/ConfirmationWithData';
import {
  CLICK_DOWNLOAD_DUMP,
  CLICK_DOWNLOAD_DUMP_EMPTY,
  EMPTY_UPDATE_BOOKING,
  GET_BOOKING_LIST,
  GET_BOOKING_LIST_EMPTY,
  GET_CATEGORY,
  GET_COUNTRY,
  GET_LOCATION,
  GET_PARTNER,
  MAIN_AREA_LOADER_SHOW,
  RESEND_MAIL,
  SERVICES_ON_FACILITY,
  UPDATE_BOOKING,
} from '../../../../../store/application/actionType';

const ClubBookingManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [projectId, setProjectId] = useState(Constant?.CURRENT_SELECTED_PROGRAM?.id);
  const [bookingTypeId, setBookingTypeId] = useState(Constant?.BOOKING_TYPES?.clubBookings?.objectId);

  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);
  const [isDetailsPage, setIsDetailsPage] = useState(false);
  const [currentSelectedBookingDetails, setCurrentSelectedBookingDetails] = useState([]);

  const [loading, setLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [currentConfirmationModalActionFor, setCurrentConfirmationModalActionFor] = useState(null);

  const [isCancelModalShow, setIsCancelModalShow] = useState(false);
  const [isConfirmModalShow, setIsConfirmModalShow] = useState(false);
  const [isUpdateBookingModal, setIsUpdateBookingModal] = useState(false);

  const [isProcessBulkModalShow, setIsProcessBulkModalShow] = useState(false);
  const [isConfirmBulkModalShow, setIsConfirmBulkModalShow] = useState(false);
  const [isCancelBulkModalShow, setIsCancelBulkModalShow] = useState(false);
  const [isUpdateBulkModalShow, setIsUpdateBulkModalShow] = useState(false);
  const [isResendMailBulkModalShow, setIsResendMailBulkModalShow] = useState(false);
  const [isConfirmationWithData, setIsConfirmationWithData] = useState(false);
  /* ---------------------------- REDUX STATES ---------------------------- */
  // Bookings
  const { bookings, bookingsLoading } = useSelector(state => ({
    bookings: state?.Bookings?.data,
    bookingsLoading: state?.Bookings?.loading,
  }));

  // Update Booking
  const { updateBookings, updateBookingsLoading } = useSelector(state => ({
    updateBookings: state?.UpdateBooking?.data,
    updateBookingsLoading: state?.UpdateBooking?.loading,
  }));

  // Countries
  const { countries, countriesLoading } = useSelector(state => ({
    countries: state?.Countries?.data,
    countriesLoading: state?.Countries?.loading,
  }));

  // Regions
  const { regions, regionsLoading } = useSelector(state => ({
    regions: state?.Regions?.data,
    regionsLoading: state?.Regions?.loading,
  }));

  // Sub-Regions
  const { subRegions, subRegionsLoading } = useSelector(state => ({
    subRegions: state?.SubRegions?.data,
    subRegionsLoading: state?.SubRegions?.loading,
  }));

  // Category
  const { categories, categoryLoading } = useSelector(state => ({
    categories: state?.GetCategory?.data,
    categoryLoading: state?.GetCategory?.loading,
  }));

  // Partners
  const { partners, partnersLoading } = useSelector(state => ({
    partners: state?.GetPartners?.data,
    partnersLoading: state?.GetPartners?.loading,
  }));

  // Locations
  const { locations, locationsLoading } = useSelector(state => ({
    locations: state?.GetLocation?.data,
    locationsLoading: state?.GetLocation?.loading,
  }));

  // Service
  const { services, serviceLoading } = useSelector(state => ({
    services: state?.GetService?.data,
    serviceLoading: state?.GetService?.loading,
  }));

  // Click Download Dump
  const { downloadDump, downloadDumpLoading } = useSelector(state => ({
    downloadDump: state?.DownloadDump?.data,
    downloadDumpLoading: state?.DownloadDump?.loading,
  }));

  // Resend Mail
  const { resendMail, resendMailLoading } = useSelector(state => ({
    resendMail: state?.ResendMail?.data,
    resendMailLoading: state?.ResendMail?.loading,
  }));

  // Services
  const { servicesOnFacility, servicesOnFacilityLoading } = useSelector(state => ({
    servicesOnFacility: state?.GetServiceOnFacility?.data,
    servicesOnFacilityLoading: state?.GetServiceOnFacility?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_BOOKING_LIST,
      payload: {
        urlParam: {
          projectId,
          bookingTypeId,
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          sortOn: 'updatedAt',
        },
        data: null,
      },
    });

    return () => {
      dispatch({
        type: GET_BOOKING_LIST_EMPTY,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: GET_BOOKING_LIST,
      payload: {
        urlParam: {
          projectId,
          bookingTypeId,
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          sortOn: 'updatedAt',
        },
        data: null,
      },
    });

    dispatch({
      type: SERVICES_ON_FACILITY,
      payload: {
        urlParam: {
          pagination: true,
          // facilityId: value,
        },
      },
    });

    if (countries === null) {
      dispatch({
        type: GET_COUNTRY,
      });
    }

    if (categories === null) {
      dispatch({
        type: GET_CATEGORY,
      });
    }

    if (partners === null) {
      dispatch({
        type: GET_PARTNER,
      });
    }

    if (locations === null) {
      dispatch({
        type: GET_LOCATION,
        payload: {
          urlParam: {
            categoryId: Constant?.GOLF_COURSE_CATEGORY_ID,
          },
        },
      });
    }
    if (partners === null) {
      dispatch({
        type: GET_PARTNER,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (updateBookings !== null) {
      dispatch({
        type: EMPTY_UPDATE_BOOKING,
      });

      dispatch({
        type: GET_BOOKING_LIST,
        payload: {
          urlParam: {
            projectId,
            bookingTypeId,
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            sortOn: currentlyAppliedFilters?.sortOn ?? 'updatedAt',
          },
          data: currentlyAppliedFilters,
        },
      });
    }
  }, [updateBookings]);

  useEffect(() => {
    if (downloadDump !== null) {
      dispatch({
        type: CLICK_DOWNLOAD_DUMP_EMPTY,
      });
    }
  }, [downloadDump]);

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = filters => {
    if (filters?.filter) {
      setCurrentPageNumber(0);
      setCurrentPageIndex(0);
      setCurrentlyAppliedFilters(filters);

      dispatch({
        type: GET_BOOKING_LIST,
        payload: {
          urlParam: {
            projectId,
            bookingTypeId,
            pageIndex: 0,
            sortOn: filters?.sortOn ?? 'updatedAt',
          },
          data: filters,
        },
      });
    } else {
      setCurrentlyAppliedFilters(filters);
    }
  };

  /* ---------------------------- FILTER RESET HANDLER ---------------------------- */
  const onResetHandler = () => {
    setCurrentPageNumber(0);
    setCurrentPageIndex(0);

    dispatch({
      type: GET_BOOKING_LIST,
      payload: {
        urlParam: {
          projectId,
          bookingTypeId,
          pageIndex: 0,
          sortOn: 'updatedAt',
        },
        data: null,
      },
    });
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);

    dispatch({
      type: GET_BOOKING_LIST,
      payload: {
        urlParam: {
          projectId,
          bookingTypeId,
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          sortOn: currentlyAppliedFilters?.sortOn ?? 'updatedAt',
        },
        data: currentlyAppliedFilters,
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedBookingDetails(params?.bookingDetails);
    setCurrentConfirmationModalActionFor(params?.actionType);

    if (params?.actionType === `VIEW`) {
      setIsDetailsPage(!isDetailsPage);
    } else if (params?.actionType === `PROCESS`) {
      setIsShowConfirmationModal(true);
    } else if (params?.actionType === `CONFIRM`) {
      setIsConfirmModalShow(true);
    } else if (params?.actionType === `CANCEL`) {
      setIsCancelModalShow(true);
    } else if (params?.actionType === `UPDATE`) {
      setIsUpdateBookingModal(true);
    } else if (params?.actionType === `RESEND_EMAIL`) {
      setIsShowConfirmationModal(true);
    } else if (params?.actionType === `BULK_PROCESS`) {
      setIsProcessBulkModalShow(true);
    } else if (params?.actionType === `BULK_CONFIRM`) {
      setIsConfirmBulkModalShow(true);
    } else if (params?.actionType === `BULK_CANCEL`) {
      setIsCancelBulkModalShow(true);
    } else if (params?.actionType === `BULK_UPDATE`) {
      setIsUpdateBulkModalShow(true);
    } else if (params?.actionType === `BULK_RESEND_MAIL`) {
      setIsResendMailBulkModalShow(true);
    } else if (params?.actionType === `CONFIRM_WITH_DATA`) {
      setIsConfirmationWithData(true);
    }
  };

  /* ---------------------------- CLOSE MODAL HANDLER ---------------------------- */
  const closeModalHandler = (flag, data) => {
    if (flag === `ConfirmationModal`) {
      setIsShowConfirmationModal(false);

      if (data === `YES`) {
        if (currentConfirmationModalActionFor === `PROCESS`) {
          dispatch({
            type: UPDATE_BOOKING,
            payload: {
              data: {
                projectId,
                bookingTypeId,
                bookingsData: [
                  {
                    objectId: currentSelectedBookingDetails?.bookingId,
                    detailedBookingId: currentSelectedBookingDetails?.detailedBookingId,
                    status: Constant?.BOOKING_STATUS?.PROCESSED,
                    process: `process-booking`,
                  },
                ],
              },
            },
          });
        } else if (currentConfirmationModalActionFor === `CONFIRM`) {
          dispatch({
            type: UPDATE_BOOKING,
            payload: {
              data: {
                projectId,
                bookingTypeId,
                bookingsData: [
                  {
                    objectId: currentSelectedBookingDetails?.bookingId,
                    detailedBookingId: currentSelectedBookingDetails?.detailedBookingId,
                    status: Constant?.BOOKING_STATUS?.CONFIRM,
                    process: `confirm-booking`,
                  },
                ],
              },
            },
          });
        } else if (currentConfirmationModalActionFor === `CANCEL`) {
          dispatch({
            type: UPDATE_BOOKING,
            payload: {
              data: {
                projectId,
                bookingTypeId,
                bookingsData: [
                  {
                    objectId: currentSelectedBookingDetails?.bookingId,
                    detailedBookingId: currentSelectedBookingDetails?.detailedBookingId,
                    status: Constant?.BOOKING_STATUS?.CANCEL_BY_THRIWE,
                    process: `cancel-booking`,
                  },
                ],
              },
            },
          });
        } else if (currentConfirmationModalActionFor === `RESEND_EMAIL`) {
          dispatch({
            type: RESEND_MAIL,
            payload: {
              data: {
                projectId,
                bookingTypeId,
                bookingIds: [currentSelectedBookingDetails?.bookingId],
              },
            },
          });
        }
      }
    }
  };

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Club Booking`) {
      setIsDetailsPage(false);
    } else if (title === `Club Booking Details`) {
      // do nothing
    }
  };

  /* ---------------------------- DOWNLOAD DUMP HANDLER ---------------------------- */
  const downloadDumpHandler = () => {
    dispatch({
      type: CLICK_DOWNLOAD_DUMP,
      payload: {
        urlParam: {
          projectId,
          bookingTypeId,
          downloadType: 1,
        },
        data: currentlyAppliedFilters,
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (
      bookingsLoading ||
      countriesLoading ||
      regionsLoading ||
      subRegionsLoading ||
      partnersLoading ||
      locationsLoading ||
      updateBookingsLoading ||
      downloadDumpLoading ||
      resendMailLoading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    bookingsLoading,
    countriesLoading,
    regionsLoading,
    subRegionsLoading,
    partnersLoading,
    locationsLoading,
    updateBookingsLoading,
    downloadDumpLoading,
    resendMailLoading,
  ]);

  const object1 = {
    name: 'ABC',
    address: 'India',
  };

  const object2 = {
    address: 'India',
    name: 'ABC',
  };

  // JSON.stringify(object1) === JSON.stringify(object2)
  // false

  const test = _.isEqual(object1, object2);

  return (
    <>
      {loading && <Loader />}

      {isShowConfirmationModal && (
        <ActionConfirmation onClose={closeModalHandler} data={currentSelectedBookingDetails} action={currentConfirmationModalActionFor} />
      )}

      <div className="page-content">
        <Container fluid className="position-relative">
          {/* <button
            onClick={() =>
              dispatch({
                type: MAIN_AREA_LOADER_SHOW,
              })
            }
          >
            Get state
          </button> */}

          {/*  ---------------------------- BULK MODAL'S ---------------------------- */}
          {isCancelBulkModalShow && <BulkCancelModal data={currentSelectedBookingDetails} onCloseHandler={() => setIsCancelBulkModalShow(false)} />}

          {isProcessBulkModalShow && (
            <BulkProcessModal data={currentSelectedBookingDetails} onCloseHandler={() => setIsProcessBulkModalShow(false)} />
          )}

          {isConfirmBulkModalShow && (
            <BulkConfirmModal data={currentSelectedBookingDetails} onCloseHandler={() => setIsConfirmBulkModalShow(false)} />
          )}

          {isResendMailBulkModalShow && (
            <BulkResendMailModal data={currentSelectedBookingDetails} onCloseHandler={() => setIsResendMailBulkModalShow(false)} />
          )}

          {isUpdateBulkModalShow && (
            <BulkUpdateModal
              data={currentSelectedBookingDetails}
              onCloseHandler={() => setIsUpdateBulkModalShow(false)}
              locations={locations}
              allServices={servicesOnFacility?.results}
            />
          )}

          {/*  ----------------------------  MODAL ---------------------------- */}
          {isConfirmModalShow && <ConfirmModal data={currentSelectedBookingDetails} onCloseHandler={() => setIsConfirmModalShow(false)} />}

          {isCancelModalShow && <CancelModal data={currentSelectedBookingDetails} onCloseHandler={() => setIsCancelModalShow(false)} />}

          {/* {isUpdateBookingModal && (
            <UpdateBookingModal
              locations={locations}
              data={currentSelectedBookingDetails}
              onCloseHandler={() => setIsUpdateBookingModal(false)}
            />
          )} */}

          {isConfirmationWithData && (
            <ConfirmationWithData data={currentSelectedBookingDetails} onCloseHandler={() => setIsConfirmationWithData(false)} />
          )}

          {/*  ----------------------------  UI'S ---------------------------- */}
          {!isDetailsPage && (
            <>
              <BreadCrumb title="Club Booking" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Club Booking`]} />
              <Filter
                data={{
                  countries,
                  regions,
                  subRegions,
                  categories: categories?.results,
                  partners: partners?.results,
                  locations: locations?.results,
                  services: services?.results,
                }}
                filterHandler={filterSubmitHandler}
                appliedFilters={currentlyAppliedFilters}
                onReset={onResetHandler}
              />

              <Table
                data={bookings?.results}
                totalData={bookings?.count}
                pageNumber={currentPageNumber}
                pageHandler={paginationHandler}
                actionHandler={tableActionHandler}
                dumpDownload={downloadDumpHandler}
                appliedFilters={currentlyAppliedFilters}
              />
            </>
          )}

          {isDetailsPage && (
            <>
              <BreadCrumb
                title="Club Booking"
                navigationby={breadcrumNavigationHandler}
                navigation={[`Dashboard`, `Club Booking`, `Club Booking Details`]}
              />
              <BookingDetails details={currentSelectedBookingDetails} />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default ClubBookingManagement;
