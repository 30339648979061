import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import apiCall from '../../apiCall';
import { customHeaders, indianProjectsBaseURL } from './../../../../Containers/Config/index';

import { UPDATE_RECORD_DATA, UPDATE_RECORD_DATA_SUCCESS, UPDATE_RECORD_DATA_ERROR } from './../../actionType';
import { Constant } from '../../../../Containers/Helpers/constant';
import cogoToast from 'cogo-toast';

function* updateRecordData({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/admin/update-record`,
      method: 'PUT',
      headers: { Authorization: Constant?.CLIENT_TOKEN, ...customHeaders, 'Project-Code': 'ADMIN_ACCESS' },
      params: {
        ...payload?.urlParam,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({ type: UPDATE_RECORD_DATA_SUCCESS, payload: response });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({ type: UPDATE_RECORD_DATA_ERROR, payload: response.error });
    }
  } catch (error) {
    yield put({ type: UPDATE_RECORD_DATA_ERROR, payload: error });
  }
}

export function* watchUpdateRecordData() {
  yield takeEvery(UPDATE_RECORD_DATA, updateRecordData);
}

function* updateRecordDataSaga() {
  yield all([fork(watchUpdateRecordData)]);
}

export default updateRecordDataSaga;
