import { useDispatch } from 'react-redux';

import moment from 'moment';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';

import { Constant } from '../../../../../../Helpers/constant';
import { GET_GM_REPORT, GET_TRACKER_DATA } from '../../../../../../../store/actions';
import { customHeaders, indianProjectsBaseURL } from '../../../../../../Config';
import { AsyncPaginate } from 'react-select-async-paginate';
import axios from 'axios';

function getFinancialYearDates(year) {
  let startOfFinancialYear = moment([year, 3, 1]);
  let endOfFinancialYear = moment([year + 1, 2, 31]);
  return {
    start: startOfFinancialYear.format('YYYY-MM-DD'),
    end: endOfFinancialYear.format('YYYY-MM-DD'),
  };
}

function getLast12Months() {
  let months = [];
  for (let i = 0; i < 12; i++) {
    let startOfMonth = moment().subtract(i, 'months').startOf('month').format('YYYY-MM-DD');
    let endOfMonth = moment().subtract(i, 'months').endOf('month').format('YYYY-MM-DD');
    let monthName = moment().subtract(i, 'months').format('MMMM YYYY');
    months.push({ month: monthName, start: startOfMonth, end: endOfMonth });
  }
  return months.reverse();
}

const customSelectStyle = {
  singleValue: (provided, state) => ({
    ...provided,
    color: 'black',
    fontSize: '13px',
  }),
  placeholder: styles => ({
    ...styles,
    color: 'black',
  }),
  menu: styles => ({
    ...styles,
    borderRadius: 0,
    width: '100%',
    border: 'none',
  }),

  dropdownIndicator: styles => ({ ...styles, color: 'black' }),
  indicatorSeparator: styles => ({ ...styles, display: 'none' }),
};

const statusFilterValue = [
  {
    label: `Draft`,
    value: `1`,
  },
  {
    label: `Awaiting approval`,
    value: `2`,
  },
  {
    label: `Discarded`,
    value: `3`,
  },
  {
    label: `Live`,
    value: `4`,
  },
];

const Filter = ({ filters, setFilters, currentPageNumber, setCurrentPageNumber }) => {
  const dispatch = useDispatch();

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedFilters = { ...filters };

    switch (label) {
      case 'report-type':
        updatedFilters = {
          ...filters,
          reportType: data,
        };
        break;

      case 'projectCode':
        updatedFilters = {
          ...filters,
          projectCode: data,
        };
        break;

      case 'uploadBy':
        updatedFilters = {
          ...filters,
          uploadBy: data,
        };
        break;

      case 'dateRange':
        updatedFilters = {
          ...filters,
          startDate: data === '' ? '' : moment(new Date(data[0])).format('Y-MM-DD'),
          endDate: data === '' ? '' : moment(new Date(data[1])).format('Y-MM-DD'),
        };
        break;

      default:
      // nothing
    }
    setFilters(updatedFilters);
    setCurrentPageNumber(0);

    let urlParams = {
      status: updatedFilters?.status === `` ? null : updatedFilters?.status?.value,
      startDate: updatedFilters?.startDate === '' || updatedFilters?.startDate === 'Invalid date' ? null : updatedFilters?.startDate,
      endDate: updatedFilters?.endDate === '' || updatedFilters?.endDate === 'Invalid date' ? null : updatedFilters?.endDate,
      uploadBy: updatedFilters?.uploadBy === '' ? null : updatedFilters?.uploadBy?.value,
      facilityName: '',
      limit: Constant?.TABLE_PAGE_SIZE,
      skip: 0,
      orderBy: `_created_at`,
    };

    // dispatch({
    //   type: GET_GM_REPORT,
    //   payload: {
    //     urlParam: {
    //       ...urlParams,
    //     },
    //   },
    // });
  };

  const monthCalculationsHandler = () => {
    let financialYears = [];

    let currentDate = moment();
    let currentFinancialYearStart, currentFinancialYearEnd;

    if (currentDate.month() < 3) {
      currentFinancialYearStart = moment([currentDate.year() - 1, 3, 1]);
      currentFinancialYearEnd = moment([currentDate.year(), 2, 31]);
    } else {
      currentFinancialYearStart = moment([currentDate.year(), 3, 1]);
      currentFinancialYearEnd = moment([currentDate.year() + 1, 2, 31]);
    }

    for (let i = 0; i < 5; i++) {
      let year = currentFinancialYearStart.year() - i - 1;
      let financialYear = getFinancialYearDates(year);
      financialYears.push({
        label: `${year}-${year + 1}`,
        value: `${financialYear.start} ${financialYear.end}`,
      });
    }

    let lastMonths = [];
    let last12Months = getLast12Months();
    last12Months.forEach(month => {
      lastMonths?.push({
        label: `${month.month}`,
        value: `${month.start} ${month.end}`,
      });
    });

    lastMonths?.reverse();
    return [...lastMonths, ...financialYears];
  };

  /* ---------------------------- PROJECTS LIST HANDLER ---------------------------- */
  async function getProjects(search) {
    let urlParam = null;
    return await axios({
      method: `GET`,
      url: `${indianProjectsBaseURL}/admin/projects`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...urlParam,
        getAll: true,
        name: search?.trim() === '' ? null : search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data?.code,
              label: `${data?.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  return (
    <>
      {/* <button onClick={() => console.log({ filters })}>get state</button> */}

      <Row className="my-4">
        <Col md={12}>
          <h5>Filters -</h5>
        </Col>

        <Col xl={3} lg={3} md={6} sm={6} xs={12} className="mb-3">
          <Label htmlFor="report-type" className="form-label">
            Report Type
          </Label>
          <Select
            styles={{
              ...customSelectStyle,
            }}
            id="report-type"
            name="report-type"
            options={monthCalculationsHandler()}
            value={filters?.reportType}
            onChange={data => inputSelectHandler(`report-type`, data)}
            isClearable={true}
          />
        </Col>

        <Col xl={3} lg={3} md={6} sm={6} xs={12} className="mb-3">
          <Label htmlFor="project-code" className="form-label">
            Program Name
          </Label>
          <AsyncPaginate
            debounceTimeout={500}
            value={filters?.projectCode}
            loadOptions={getProjects}
            onChange={data => inputSelectHandler(`projectCode`, data)}
            isMulti={false}
            closeMenuOnSelect={true}
            noOptionsMessage={() => 'No results found'}
            cacheUniqs={[['code']]}
            placeholder="Enter Name..."
            isClearable={true}
          />
        </Col>

        {/* <Col xl={3} lg={3} md={6} sm={6} xs={12} className="mb-3">
          <Label htmlFor="tracker-status" className="form-label">
            Client
          </Label>
          <Select
            styles={{
              ...customSelectStyle,
            }}
            id="tracker-status"
            name="tracker-status"
            options={[
              {
                value: 'All',
                label: 'All',
              },
            ]}
            value={filters?.status}
            onChange={data => inputSelectHandler(`tracker-status`, data)}
            isClearable={true}
          />
        </Col> */}

        {/* <Col xl={3} lg={3} md={6} sm={6} xs={12} className="mb-3">
          <Label htmlFor="tracker-status" className="form-label">
            Country
          </Label>
          <Select
            styles={{
              ...customSelectStyle,
            }}
            id="tracker-status"
            name="tracker-status"
            options={[
              {
                value: 'All',
                label: 'All',
              },
            ]}
            value={filters?.status}
            onChange={data => inputSelectHandler(`tracker-status`, data)}
            isClearable={true}
          />
        </Col> */}

        {/* <Col xl={3} lg={3} md={6} sm={6} xs={12} className="mb-3">
          <Label htmlFor="tracker-status" className="form-label">
            Program name
          </Label>
          <Select
            styles={{
              ...customSelectStyle,
            }}
            id="tracker-status"
            name="tracker-status"
            options={[
              {
                value: 'All',
                label: 'All',
              },
            ]}
            value={filters?.status}
            onChange={data => inputSelectHandler(`tracker-status`, data)}
            isClearable={true}
          />
        </Col> */}

        {/* <Col xl={3} lg={3} md={6} sm={6} xs={12} className="mb-3">
          <Label htmlFor="tracker-status" className="form-label">
            Category name
          </Label>
          <Select
            styles={{
              ...customSelectStyle,
            }}
            id="tracker-status"
            name="tracker-status"
            options={[
              {
                value: 'All',
                label: 'All',
              },
            ]}
            value={filters?.status}
            onChange={data => inputSelectHandler(`tracker-status`, data)}
            isClearable={true}
          />
        </Col> */}

        {/* <Col xl={3} lg={3} md={6} sm={6} xs={12} className="mb-3">
          <Label htmlFor="tracker-status" className="form-label">
            Sort by
          </Label>
          <Select
            styles={{
              ...customSelectStyle,
            }}
            id="tracker-status"
            name="tracker-status"
            options={[
              {
                value: 'All',
                label: 'All',
              },
            ]}
            value={filters?.status}
            onChange={data => inputSelectHandler(`tracker-status`, data)}
            isClearable={true}
          />
        </Col> */}
      </Row>
    </>
  );
};

export default Filter;
