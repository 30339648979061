import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../Containers/Config/index.js';
import { GET_BENEFIT_GROUP_ITEMS, GET_BENEFIT_GROUP_ITEMS_SUCCESS, GET_BENEFIT_GROUP_ITEMS_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getBenefitGroupItemsList({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/benefit-configs`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload?.urlParam,
        pageIndex: 0,
        pageSize: 100,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_BENEFIT_GROUP_ITEMS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_BENEFIT_GROUP_ITEMS_ERROR,
        payload: response.response.data,
      });
    }
  } catch (error) {
    yield put({ type: GET_BENEFIT_GROUP_ITEMS_ERROR, payload: error });
  }
}

export function* watchGetBenefitGroupItemsList() {
  yield takeEvery(GET_BENEFIT_GROUP_ITEMS, getBenefitGroupItemsList);
}

function* getBenefitGroupItemsListSaga() {
  yield all([fork(watchGetBenefitGroupItemsList)]);
}

export default getBenefitGroupItemsListSaga;
