import React, { useState } from 'react';

import classnames from 'classnames';
import { Accordion, AccordionItem, Badge, Card, Col, Collapse, Row, Tooltip } from 'reactstrap';

const WalletAccordion = () => {
  const [iconCol4, seticonCol4] = useState(true);
  const [iconCol5, seticonCol5] = useState(false);
  const [iconCol6, seticonCol6] = useState(false);
  const [ttop, setttop] = useState(false);

  const t_iconCol4 = () => {
    seticonCol4(!iconCol4);
    seticonCol5(false);
    seticonCol6(false);
  };

  const t_iconCol5 = () => {
    seticonCol5(!iconCol5);
    seticonCol4(false);
    seticonCol6(false);
  };

  const t_iconCol6 = () => {
    seticonCol6(!iconCol6);
    seticonCol4(false);
    seticonCol5(false);
  };

  return (
    <Accordion className="lefticon-accordion custom-accordionwithicon accordion-border-box" id="accordionlefticon">
      <AccordionItem className="position-relative">
        <Card className="ribbon-box border position-absolute bg-transparent" style={{ zIndex: '99', right: '10%' }}>
          <div className="d-inline-block">
            <span className="ribbon-three ribbon-three-success">
              <span>Active</span>
            </span>
          </div>
        </Card>

        <h2 className="accordion-header" id="accordionwithouticonExample1">
          <button
            className={classnames('accordion-button', { collapsed: !iconCol4 })}
            type="button"
            onClick={t_iconCol4}
            style={{ cursor: 'pointer' }}
            id="testtt"
          >
            <div
              className=""
              style={{
                borderRadius: '50%',
                border: '1px solid black',
                padding: '2px',
              }}
            >
              <img
                src={`https://design.thriwe.com/thriwe-one-admin-surge/assets/images/products/3d-illustration-of-wallet-icon-png.webp`}
                alt=""
                style={{
                  width: '40px',
                  borderRadius: '5px',
                  display: 'inline-block',
                }}
                className="img-fluid"
              />
            </div>

            <div className="bg-primary px-3 py-1 mx-2" style={{ borderRadius: '10px' }}>
              <h5 className="text-white mb-0">Fit India Campaign(UCare)</h5>
            </div>
            {/* <span className="badge badge-gradient-light badge-outline-primary">Gold</span> */}

            <span className="badge badge-outline-primary fs-12"> Gold </span>
          </button>
        </h2>

        <Collapse isOpen={iconCol4} className="accordion-collapse" id="accor_withouticoncollapse1">
          <div className="accordion-body">
            <Row className="my-4">
              <Col md={8}>
                <div className="text-muted">
                  <h5 className="fs-14 text-muted">Wallet Description :</h5>
                  <p className="mb-0">
                    Our digital wallet empowered by our loyalty engine, provides your customers with a seamless and secure payment experience, while
                    offering incentives and personalised rewards for repetitive usage.
                  </p>
                </div>
              </Col>
              <Col md={4} className="d-flex align-items-end justify-content-end ">
                <div>
                  <Tooltip
                    placement="top"
                    isOpen={ttop}
                    target="TooltipTop"
                    toggle={() => {
                      setttop(!ttop);
                    }}
                  >
                    Edit
                  </Tooltip>
                  <span id="TooltipTop" className="btn btn-light">
                    <i className="ri-pencil-fill align-bottom"></i>
                  </span>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl={12}>
                <div className="mt-xl-0 mt-5">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <div className="hstack gap-3 flex-wrap">
                        <div className="text-muted">
                          Wallet ID :&nbsp;
                          <span className="text-body fw-medium">
                            #THRWSRG123
                            <span
                              className="cursor-pointer"
                              style={{
                                color: '#3a497b',
                              }}
                            >
                              {' '}
                              <i className="ri-file-copy-line"></i>
                            </span>
                          </span>
                        </div>
                        <div className="vr"></div>
                        <div className="text-muted">
                          Client Name :&nbsp;
                          <span className="text-body fw-medium">EmiratedNBD</span>
                        </div>
                        <div className="vr"></div>
                        <div className="text-muted">
                          Project Name :&nbsp;
                          <span className="text-body fw-medium">THRWSONYLIV</span>
                        </div>
                        <div className="vr"></div>
                        <div className="text-muted">
                          Program ID :&nbsp;
                          <span className="text-body fw-medium">THRWSRG12345</span>
                        </div>
                        <div className="vr"></div>
                        <div className="text-muted">
                          Customer Segment :&nbsp;
                          <span className="text-body fw-medium">Silver</span>
                        </div>
                        <div className="vr"></div>
                        <div className="text-muted">
                          Default Point Expiry :&nbsp;
                          <span className="text-body fw-medium">30 Days</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="my-4">
              <Col md={12}>
                <h5 className="fs-14 text-muted">
                  Coversion Ratio :<span className="text-dark"></span>
                </h5>
              </Col>
              <Col lg={3} sm={6}>
                <div className="border border-dashed rounded p-2 px-3">
                  <Row>
                    <Col md={4} className="my-auto">
                      <h5 className="mb-0">1 Point</h5>
                    </Col>
                    <Col md={4} className="text-center">
                      <i className="ri-arrow-left-right-line fs-20 text-primary"></i>
                    </Col>
                    <Col md={4} className="my-auto">
                      <h5 className="mb-0">INR 100</h5>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={3} sm={6}>
                <div className="border border-dashed rounded p-2 px-3">
                  <Row>
                    <Col md={4} className="my-auto">
                      <h5 className="mb-0">1 Point</h5>
                    </Col>
                    <Col md={4} className="text-center">
                      <i className="ri-arrow-left-right-line fs-20 text-primary"></i>
                    </Col>
                    <Col md={4} className="my-auto">
                      <h5 className="mb-0">AED 10</h5>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={3} sm={6}>
                <div className="border border-dashed rounded p-2 px-3">
                  <Row>
                    <Col md={4} className="my-auto">
                      <h5 className="mb-0">1 Point</h5>
                    </Col>
                    <Col md={4} className="text-center">
                      <i className="ri-arrow-left-right-line fs-20 text-primary"></i>
                    </Col>
                    <Col md={4} className="my-auto">
                      <h5 className="mb-0">USD 1</h5>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row className="my-4">
              <Col md={12}>
                <h5 className="fs-14 text-muted">
                  Wallet Stats as of :<span className="text-dark"> 20 Sep 2023</span>
                </h5>
              </Col>
              <Col lg={3} sm={6}>
                <div className="p-2 border border-dashed rounded">
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm me-2">
                      <div className="avatar-title rounded bg-transparent text-primary fs-24">
                        <i className="ri-wallet-3-line"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <p className="text-muted mb-1">Current Total Balance:</p>
                      <h5 className="mb-0">
                        <span className="text-warning fs-20">
                          <i className="lab las la-coins"></i>
                        </span>
                        45,000
                      </h5>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} sm={6}>
                <div className="p-2 border border-dashed rounded">
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm me-2">
                      <div className="avatar-title rounded bg-transparent text-success fs-24">
                        <i className="mdi mdi-arrow-bottom-right"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <p className="text-muted mb-1">Total Incomimg:</p>
                      <h5 className="mb-0">
                        <span className="text-warning fs-20">
                          <i className="lab las la-coins"></i>
                        </span>
                        50,000
                      </h5>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} sm={6}>
                <div className="p-2 border border-dashed rounded">
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm me-2">
                      <div className="avatar-title rounded bg-transparent text-danger fs-24">
                        <i className="mdi mdi-arrow-top-right"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <p className="text-muted mb-1">Total Outgoing:</p>
                      <h5 className="mb-0">
                        <span className="text-warning fs-20">
                          <i className="lab las la-coins"></i>
                        </span>
                        5,000
                      </h5>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} sm={6}>
                <div className="p-2 border border-dashed rounded">
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm me-2">
                      <div className="avatar-title rounded bg-transparent text-warning fs-24">
                        <i className="mdi mdi-trash-can-outline"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <p className="text-muted mb-1">Total Expired:</p>
                      <h5 className="mb-0">
                        <span className="text-warning fs-20">
                          <i className="lab las la-coins"></i>
                        </span>
                        500
                      </h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="my-4">
              <Col md={12}>
                <h5 className="fs-14 text-muted">
                  Wallet Balance this Month : <span className="text-dark">September</span>
                </h5>
              </Col>
              <Col lg={3} sm={6}>
                <div className="p-2 border border-dashed rounded">
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm me-2">
                      <div className="avatar-title rounded bg-transparent text-success fs-24">
                        <i className="mdi mdi-arrow-bottom-right"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <p className="text-muted mb-1">Total Income :</p>
                      <h5 className="mb-0">
                        <span className="text-warning fs-20">
                          <i className="lab las la-coins"></i>
                        </span>
                        50,000
                      </h5>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} sm={6}>
                <div className="p-2 border border-dashed rounded">
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm me-2">
                      <div className="avatar-title rounded bg-transparent text-danger fs-24">
                        <i className="mdi mdi-arrow-top-right"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <p className="text-muted mb-1">Total Outgoing :</p>
                      <h5 className="mb-0">
                        <span className="text-warning fs-20">
                          <i className="lab las la-coins"></i>
                        </span>
                        5,000
                      </h5>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} sm={6}>
                <div className="p-2 border border-dashed rounded">
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm me-2">
                      <div className="avatar-title rounded bg-transparent text-warning fs-24">
                        <i className="mdi mdi-trash-can-outline"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <p className="text-muted mb-1">Total Expired :</p>
                      <h5 className="mb-0">
                        <span className="text-warning fs-20">
                          <i className="lab las la-coins"></i>
                        </span>
                        500
                      </h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="my-4">
              <Col md={12}>
                <h5 className="fs-14 text-muted">Wallet Balance Expiring</h5>
              </Col>
              <Col lg={3} sm={6}>
                <div className="p-2 border border-dashed rounded">
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm me-2">
                      <div className="avatar-title rounded bg-transparent text-warning" style={{ fontSize: '33px' }}>
                        <i className="lab las la-history"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <p className="text-muted mb-1">Expiring in next 30 Days :</p>
                      <h5 className="mb-0">
                        <span className="text-warning fs-20">
                          <i className="lab las la-coins"></i>
                        </span>
                        15,000
                      </h5>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} sm={6}>
                <div className="p-2 border border-dashed rounded">
                  <div className="d-flex align-items-center">
                    <div className="avatar-sm me-2">
                      <div className="avatar-title rounded bg-transparent text-warning" style={{ fontSize: '33px' }}>
                        <i className="lab las la-history"></i>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <p className="text-muted mb-1">Expiring in next 60 Days :</p>
                      <h5 className="mb-0">
                        <span className="text-warning fs-20">
                          <i className="lab las la-coins"></i>
                        </span>
                        20,000
                      </h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Collapse>
      </AccordionItem>

      {/* <AccordionItem>
                <Card
                    className="ribbon-box border  position-absolute bg-transparent"
                    style={{ zIndex: "99", right: "10%" }}
                >
                    <div className="d-inline-block">
                        <span className="ribbon-three ribbon-three-warning">
                            <span>Inactive</span>
                        </span>
                    </div>
                </Card>
                <h2 className="accordion-header" id="accordionwithouticonExample1">
                    <button
                        className={classnames("accordion-button", { collapsed: !iconCol5 })}
                        type="button"
                        onClick={t_iconCol5}
                        style={{ cursor: "pointer" }}
                        id="testtt"
                    >
                        <div
                            className=""
                            style={{
                                borderRadius: "50%",
                                border: "1px solid black",
                                padding: "2px",
                            }}
                        >
                            
                        </div>

                        <div
                            className="bg-primary px-3 py-1 mx-2"
                            style={{ borderRadius: "10px" }}
                        >
                            <h5 className="text-white mb-0">10 Years Anniversary(ENBD)</h5>
                        </div>
                    </button>
                </h2>

                <Collapse
                    isOpen={iconCol5}
                    className="accordion-collapse"
                    id="accor_withouticoncollapse2"
                >
                    <div className="accordion-body">
                        When, while the lovely valley teems with vapour around me, and the
                        meridian sun strikes the upper surface of the impenetrable foliage
                        of my trees, and but a few stray gleams steal into the inner
                        sanctuary, I throw myself down among the tall grass by the trickling
                        stream; and, as I lie close to the earth, a thousand unknown.
                    </div>
                </Collapse>
            </AccordionItem> */}
    </Accordion>
  );
};

export default WalletAccordion;
