import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Table } from 'reactstrap';
import { GET_MIS_REPORT } from '../../../../../../../store/actions';

const MonthlyRegistration = () => {
  const dispatch = useDispatch();
  const [misData, setMisData] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // MIS REPORT
  const { misReportData } = useSelector(state => ({
    misReportData: state?.GetMisReport?.data,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_MIS_REPORT,
    });
  }, [dispatch]);

  useEffect(() => {
    if (misReportData !== null) {
      setMisData(
        misReportData?.results?.map(data => ({
          month: data?.month,
          registrations: data?.registrations || '0',
          counts: data?.noOfBookings || '0',
        })) || [],
      );
    }
  }, [misReportData]);

  const grandTotalRegistrations = misData?.reduce((total, item) => total + (parseInt(item.registrations) || 0), 0);
  const grandTotalCounts = misData?.reduce((total, item) => total + (parseInt(item.counts) || 0), 0);

  return (
    <Col xl={6} md={6} className="mb-4">
      <Card>
        <CardHeader>
          <h4 className="mb-0">Monthly Registration</h4>
        </CardHeader>
        <CardBody style={{ maxHeight: '300px', overflowY: 'scroll', position: 'relative', paddingBottom: '60px' }}>
          <Table striped bordered hover responsive style={{ marginBottom: '0' }}>
            <thead className="table-light">
              <tr>
                <th className="text-start">Month</th>
                <th className="text-start">Registrations</th>
                <th className="text-start">Counts</th>
              </tr>
            </thead>
            <tbody>
              {misData?.length > 0 ? (
                misData.map((item, index) => (
                  <tr key={index}>
                    <th scope="row">{item.month}</th>
                    <td>{item.registrations}</td>
                    <td>{item.counts}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
        {misData?.length > 0 && (
          <div style={{ position: 'absolute', bottom: '0', width: '100%', background: 'white' }}>
            <Table striped bordered hover responsive style={{ marginBottom: '0' }}>
              <tfoot className="table-light">
                <tr>
                  <th>Grand Total</th>
                  <th>{grandTotalRegistrations}</th>
                  <th>{grandTotalCounts}</th>
                </tr>
              </tfoot>
            </Table>
          </div>
        )}
      </Card>
    </Col>
  );
};

export default MonthlyRegistration;
