import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const DisplayPropertiesSettings = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [state, setState] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [modalData, setModalData] = useState(null);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  useEffect(() => {
    setState(props?.data?.data);
    setCurrentIndex(props?.data?.currentIndex);
  }, [props]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'displayName':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              displayName: value,
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'logo':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              images: {
                ...state?.benefitGroupItem?.completeData?.images,
                logo: value,
              },
            },
          },
        };

        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'bannerImageMobile':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              images: {
                ...state?.benefitGroupItem?.completeData?.images,
                bannersMobile: value ? [value] : null,
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'bannerImageWeb':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              images: {
                ...state?.benefitGroupItem?.completeData?.images,
                bannersWeb: value ? [value] : null,
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'coverImageMobile':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              images: {
                ...state?.benefitGroupItem?.completeData?.images,
                coverImageMobile: value,
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'coverImageWeb':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              images: {
                ...state?.benefitGroupItem?.completeData?.images,
                coverImageWeb: value,
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'displayImageMobile':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              images: {
                ...state?.benefitGroupItem?.completeData?.images,
                displayImageMobile: value,
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'displayImageWeb':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              images: {
                ...state?.benefitGroupItem?.completeData?.images,
                displayImageWeb: value,
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'shortDescription':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              shortDescription: value,
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'longDescription':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              longDescription: value,
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  const ckEditorDataInput = (name, data) => {
    let updatedState = null;

    switch (name) {
      case 'redemptionProcess':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              redemptionProcess: data,
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'termsAndCondition':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              termsAndCondition: data,
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'benefitOffered':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              benefitOffered: data,
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    props.onSubmit({
      currentIndex: currentIndex,
      data: state,
    });

    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  /* ---------------------------- VALIDATION HANDLER ---------------------------- */
  const validationCheck = data => {};

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
      // centered
      size="lg"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Display Properties
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log(state)}>get state</button> */}
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <Label htmlFor="displayName" className="form-label">
                Display Name
              </Label>
              <Input
                id="displayName"
                name="displayName"
                type="text"
                placeholder="Enter display name"
                className="form-control"
                value={state?.benefitGroupItem?.completeData?.displayName}
                onChange={inputChangeHandler}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label htmlFor="logo" className="form-label">
                Display Logo
              </Label>
              <Input
                id="logo"
                name="logo"
                type="text"
                placeholder="Enter display logo URL"
                className="form-control"
                value={state?.benefitGroupItem?.completeData?.images?.logo}
                onChange={inputChangeHandler}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label htmlFor="coverImageMobile" className="form-label">
                Cover Image (Mobile)
              </Label>
              <Input
                id="coverImageMobile"
                name="coverImageMobile"
                type="text"
                placeholder="Enter display logo URL"
                className="form-control"
                value={state?.benefitGroupItem?.completeData?.images?.coverImageMobile}
                onChange={inputChangeHandler}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label htmlFor="coverImageWeb" className="form-label">
                Cover Image (Desktop)
              </Label>
              <Input
                id="coverImageWeb"
                name="coverImageWeb"
                type="text"
                placeholder="Enter display logo URL"
                className="form-control"
                value={state?.benefitGroupItem?.completeData?.images?.coverImageWeb}
                onChange={inputChangeHandler}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label htmlFor="displayImageMobile" className="form-label">
                Display Image (Mobile)
              </Label>
              <Input
                id="displayImageMobile"
                name="displayImageMobile"
                type="text"
                placeholder="Enter display logo URL"
                className="form-control"
                value={state?.benefitGroupItem?.completeData?.images?.displayImageMobile}
                onChange={inputChangeHandler}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label htmlFor="displayImageWeb" className="form-label">
                Display Image (Desktop)
              </Label>
              <Input
                id="displayImageWeb"
                name="displayImageWeb"
                type="text"
                placeholder="Enter display logo URL"
                className="form-control"
                value={state?.benefitGroupItem?.completeData?.images?.displayImageWeb}
                onChange={inputChangeHandler}
              />
            </div>
          </Col>

          <Col md={6}>
            <div className="mb-3">
              <Label htmlFor="bannerImageMobile" className="form-label">
                Banner Image Mobile
              </Label>
              <Input
                id="bannerImageMobile"
                name="bannerImageMobile"
                type="text"
                placeholder="Enter banner Image Mobile URL"
                className="form-control"
                // value={state?.benefitGroupItem?.completeData?.images?.bannerImageMobile}
                value={state?.benefitGroupItem?.completeData?.images?.bannersMobile?.[0]}
                onChange={inputChangeHandler}
              />
            </div>
          </Col>

          <Col md={6}>
            <div className="mb-3">
              <Label htmlFor="bannerImageWeb" className="form-label">
                Banner Image Web
              </Label>
              <Input
                id="bannerImageWeb"
                name="bannerImageWeb"
                type="text"
                placeholder="Enter banner Image Web URL"
                className="form-control"
                // value={state?.benefitGroupItem?.completeData?.images?.bannerImageWeb}
                value={state?.benefitGroupItem?.completeData?.images?.bannersWeb?.[0]}
                onChange={inputChangeHandler}
              />
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <Label htmlFor="" className="form-label">
                Custom Redemption Process
              </Label>

              <CKEditor
                editor={ClassicEditor}
                data={state?.benefitGroupItem?.completeData?.redemptionProcess}
                onBlur={(event, editor) => {
                  const data = editor?.getData();
                  ckEditorDataInput(`redemptionProcess`, data);
                }}
              />
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <Label htmlFor="longDescription" className="form-label">
                Long Description
              </Label>
              <Input
                id="longDescription"
                name="longDescription"
                type="textarea"
                className="form-control"
                value={state?.benefitGroupItem?.completeData?.longDescription}
                onChange={inputChangeHandler}
              />
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <Label htmlFor="" className="form-label">
                Short Description
              </Label>
              <Input
                id="shortDescription"
                name="shortDescription"
                type="textarea"
                className="form-control"
                value={state?.benefitGroupItem?.completeData?.shortDescription}
                onChange={inputChangeHandler}
              />
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <Label htmlFor="" className="form-label">
                Custom TnC
              </Label>

              <CKEditor
                editor={ClassicEditor}
                data={state?.benefitGroupItem?.completeData?.termsAndCondition}
                onBlur={(event, editor) => {
                  const data = editor.getData();
                  ckEditorDataInput(`termsAndCondition`, data);
                }}
              />
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <Label htmlFor="" className="form-label">
                Custom Benefit Offered
              </Label>

              <CKEditor
                editor={ClassicEditor}
                data={state?.benefitGroupItem?.completeData?.benefitOffered}
                onBlur={(event, editor) => {
                  const data = editor?.getData();
                  ckEditorDataInput(`benefitOffered`, data);
                }}
              />
            </div>
          </Col>

          {/* <Col md={12}>
            <div className="mb-3">
              <Label htmlFor="" className="form-label">
                FAQ
              </Label>
              <CKEditor
                editor={ClassicEditor}
                data={state?.redemptionProcess}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  // ckEditorDataInput(`redemptionProcess`, data)
                }}
              />
            </div>
          </Col> */}
        </Row>
      </ModalBody>

      <ModalFooter className="d-flex justify-content-center">
        <Button
          className="overlay-disable"
          color="primary"
          onClick={submitHandler}
          //   disabled={isSubmitBtnDisabled}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DisplayPropertiesSettings;
