import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Col, Label, Row, Input, OffcanvasHeader, OffcanvasBody, Offcanvas } from 'reactstrap';

import { Constant } from '../../../../../../Helpers/constant';
import { isValidAplha } from '../../../../../../Helpers/Helpers';
import { customHeaders, uaeProjectsBaseURL } from '../../../../../../Config';
import {
  GET_BOOKING_TYPES_LIST,
  GET_CATEGORY_EMPTY,
  GET_COUNTRY,
  GET_LOCATION_EMPTY,
  GET_PARTNER_EMPTY,
  GET_REGION,
  GET_SERVICE_EMPTY,
  GET_SUB_REGION_EMPTY,
} from '../../../../../../../store/application/actionType';

const Filter = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    name: ``,
    updateDate: ``,
    categoryId: null,
    partnerId: null,
    countryId: null,
    typeId: ``,
  };
  const [filter, setFilter] = useState(initialState);

  const [allCountries, setAllCountries] = useState([]);
  const [bookingTypesData, setBookingTypesData] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Countries
  const { countries } = useSelector(state => ({
    countries: state?.Countries?.data,
  }));

  // Booking Types
  const { bookingTypes } = useSelector(state => ({
    bookingTypes: state?.BookingTypes?.data,
  }));

  useEffect(() => {
    if (countries === null) {
      dispatch({
        type: GET_COUNTRY,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (bookingTypes === null) {
      dispatch({
        type: GET_BOOKING_TYPES_LIST,
      });
    }
  }, []);

  useEffect(() => {
    if (countries !== null) {
      setAllCountries(
        countries?.map(country => {
          return {
            value: country?.countryId,
            label: country?.name,
            data: country,
          };
        }),
      );
    } else {
      setAllCountries(null);
    }
  }, [countries]);

  useEffect(() => {
    if (bookingTypes !== null) {
      setBookingTypesData(
        bookingTypes?.results?.map(bookingType => {
          return {
            value: bookingType?.objectId,
            label: bookingType?.name,
            data: bookingType,
          };
        }),
      );
    }
  }, [bookingTypes]);

  useEffect(() => {
    if (props?.appliedFilters) {
      setFilter(props?.appliedFilters);
    }
  }, [props?.appliedFilters]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'name':
        updatedState = {
          ...filter,
          name: value,
          // name: isValidAplha(value) ? value : filter?.name,
        };
        break;

      default:
      // do nothing
    }

    setFilter(updatedState);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...filter };

    switch (label) {
      case 'typeId':
        updatedState = {
          ...filter,
          typeId: data,
        };
        break;

      case 'country':
        updatedState = {
          ...filter,
          countryId: data,
          regionId: null,
          subRegionId: null,
        };
        break;

      default:
      // do nothing
    }

    setFilter(updatedState);
  };

  /* ---------------------------- DATE SELECT HANDLER ---------------------------- */
  const datePickHandler = (flag, date) => {
    let updatedState = { ...filter };

    switch (flag) {
      case 'updateDate':
        updatedState = {
          ...filter,
          updateDate: new Date(date).toISOString(),
        };
        break;

      default:
      // do nothing
    }

    setFilter(updatedState);
  };

  /* ---------------------------- TYPED CATEGORIES HANDLER ---------------------------- */
  async function loadCategoriesOptions(search, loadedOptions) {
    const response = await fetch(`${uaeProjectsBaseURL}/admin/categories?name=${search}`, {
      method: `GET`,
      headers: new Headers({
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      }),
    });
    const categoriesResponse = await response.json();

    return {
      options: categoriesResponse?.results?.map((data, index) => {
        return {
          value: data?.objectId,
          label: data.name,
          completeData: data,
        };
      }),
      hasMore: false,
    };
  }

  const onSelectCategory = event => {
    let updatedState = { ...filter, categoryId: event, partnerId: null, facilityId: null };
    setFilter(updatedState);
  };

  /* ---------------------------- TYPED PARTNERS HANDLER ---------------------------- */
  async function loadPartnersOptions(search, loadedOptions) {
    const dynamicParams = {
      name: search,
      categoryId: filter?.categoryId === null ? null : filter?.categoryId?.value,
    };

    const queryString = Object.keys(dynamicParams)
      .filter(key => dynamicParams[key] !== null && dynamicParams[key] !== undefined)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(dynamicParams[key]))
      .join('&');

    const baseURL = `${uaeProjectsBaseURL}/admin/partners`;
    const completeURL = queryString ? `${baseURL}?${queryString}` : baseURL;

    const response = await fetch(`${completeURL}`, {
      method: `GET`,
      headers: new Headers({
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      }),
    });
    const categoriesResponse = await response.json();

    return {
      options: categoriesResponse?.results?.map((data, index) => {
        return {
          value: data?.objectId,
          label: data?.name,
          completeData: data,
        };
      }),
      hasMore: false,
    };
  }

  const onSelectPartner = event => {
    let updatedState = { ...filter, partnerId: event, facilityId: null };
    setFilter(updatedState);
  };

  /* ---------------------------- TYPED FACILITY HANDLER ---------------------------- */
  async function loadFacilitiesOptions(search, loadedOptions) {
    const dynamicParams = {
      name: search,
      categoryId: filter?.categoryId === null ? null : filter?.categoryId?.value,
      partnerId: filter?.partnerId === null ? null : filter?.partnerId?.value,
    };

    const queryString = Object.keys(dynamicParams)
      .filter(key => dynamicParams[key] !== null && dynamicParams[key] !== undefined)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(dynamicParams[key]))
      .join('&');

    const baseURL = `${uaeProjectsBaseURL}/admin/facilities`;
    const completeURL = queryString ? `${baseURL}?${queryString}` : baseURL;

    const response = await fetch(`${completeURL}`, {
      method: `GET`,
      headers: new Headers({
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      }),
    });
    const facilitiesResponse = await response.json();

    return {
      options: facilitiesResponse?.results?.map((data, index) => {
        return {
          value: data?.objectId,
          label: data?.name,
          completeData: data,
        };
      }),
      hasMore: false,
    };
  }

  const onSelectFacility = event => {
    let updatedState = { ...filter, facilityId: event };
    setFilter(updatedState);
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    props.filterHandler({ ...filter });
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter({ ...initialState });

    dispatch({
      type: GET_CATEGORY_EMPTY,
    });

    dispatch({
      type: GET_PARTNER_EMPTY,
    });

    dispatch({
      type: GET_LOCATION_EMPTY,
    });

    dispatch({
      type: GET_SERVICE_EMPTY,
    });
  };

  return (
    <>
      <Offcanvas direction="end" isOpen={props.show} id="offcanvasExample" toggle={() => props.onCloseClick()}>
        <OffcanvasHeader className="bg-light" toggle={() => props.onCloseClick()}>
          Benefit Filters
        </OffcanvasHeader>

        <OffcanvasBody>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="name" className="form-label">
                  Display Name
                </Label>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  className="form-control"
                  value={filter?.name}
                  onChange={inputChangeHandler}
                  autoComplete="off"
                />
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="name" className="form-label">
                  Sub-Category
                </Label>
                <AsyncPaginate
                  debounceTimeout={500}
                  value={filter?.categoryId}
                  loadOptions={loadCategoriesOptions}
                  onChange={e => onSelectCategory(e)}
                  isMulti={false}
                  closeMenuOnSelect={true}
                  noOptionsMessage={() => 'No results found'}
                  cacheUniqs={[['code']]}
                  placeholder="Enter sub-category..."
                />
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="name" className="form-label">
                  Brand
                </Label>
                <AsyncPaginate
                  debounceTimeout={500}
                  value={filter?.partnerId}
                  loadOptions={loadPartnersOptions}
                  onChange={e => onSelectPartner(e)}
                  isMulti={false}
                  closeMenuOnSelect={true}
                  noOptionsMessage={() => 'No results found'}
                  cacheUniqs={[['code']]}
                  placeholder="Enter brand..."
                />
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="name" className="form-label">
                  Outlet
                </Label>
                <AsyncPaginate
                  debounceTimeout={500}
                  value={filter?.facilityId}
                  loadOptions={loadFacilitiesOptions}
                  onChange={e => onSelectFacility(e)}
                  isMulti={false}
                  closeMenuOnSelect={true}
                  noOptionsMessage={() => 'No results found'}
                  cacheUniqs={[['code']]}
                  placeholder="Enter outlet..."
                />
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="name" className="form-label">
                  Country
                </Label>
                <Select
                  id="country"
                  name="country"
                  options={allCountries ?? []}
                  value={filter?.countryId}
                  onChange={data => inputSelectHandler(`country`, data)}
                />
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="bookingType" className="form-label">
                  Booking Type
                </Label>
                <Select
                  id="bookingType"
                  name="bookingType"
                  options={bookingTypesData ?? []}
                  value={filter?.typeId}
                  onChange={data => inputSelectHandler(`typeId`, data)}
                />
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="to" className="form-label">
                  Last Updated At
                  <span className="icon_calendar">
                    <i className="las la-calendar"></i>
                  </span>
                </Label>
                <div className="area_expandable">
                  <Flatpickr
                    className="form-control"
                    name="to"
                    id="to"
                    value={filter?.updateDate}
                    onChange={date => datePickHandler(`updateDate`, date)}
                    options={{
                      enableTime: false,
                      dateFormat: 'd M, Y',
                      maxDate: 'today',
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </OffcanvasBody>

        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
          <button
            className="btn btn-light w-100"
            onClick={resetFilterHandler}
            // disabled={isResetDisabled}
          >
            Reset Filter
          </button>

          <button className="btn btn-primary w-100" onClick={filterSubmitHandler}>
            Search
          </button>
        </div>
      </Offcanvas>
    </>
  );
};

export default Filter;
