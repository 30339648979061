import { useEffect, useState } from 'react';

import moment from 'moment';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';

import { TablePagination } from '../../../../../Common';
import { Constant } from '../../../../../Helpers/constant';
import { formattedWithComma, hasPermission } from '../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../Helpers/Constants/permissions';

const Table = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColums = [
        {
          name: 'S.No.',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
          width: '90px',
        },

        {
          name: 'System ID',
          selector: row => <span className="fw-medium">{row?.systemId}</span>,
          width: '150px',
        },
        {
          name: 'Name',
          selector: row => row?.name,
          wrap: true,
          width: '220px',
        },

        {
          name: 'Email',
          selector: row => (
            <span
              className="text-decoration-underline text-primary cursor-pointer"
              onClick={() => {
                actionBtnHandler(`VIEW`, row?.data);
              }}
            >
              {row?.email}
            </span>
          ),
          wrap: true,
          minWidth: '270px',
        },

        {
          name: 'Username',
          selector: row => row?.userName,
          wrap: true,
          width: '270px',
        },

        {
          name: 'Mobile',
          selector: row => row?.mobileNumber,
          wrap: true,
          width: '200px',
        },

        {
          name: 'Created At',
          selector: row => row?.createdAt,
          wrap: true,
          width: '180px',
        },

        {
          name: 'Status',
          width: '180px',
          cell: row => (
            <>
              {hasPermission(PermissionConstants.ALL_PERMISSIONS.UPDATE_USER) ? (
                <div className="d-flex justify-content-center">
                  {row?.data?.isActive ? (
                    <button
                      className="btn btn-success btn-sm btn-label right rounded rounded-2"
                      onClick={() => {
                        actionBtnHandler('UPDATE_STATUS', row?.data);
                      }}
                    >
                      Active
                      <i className="ri ri-pencil-line label-icon align-middle fs-14 ms-1"></i>
                    </button>
                  ) : (
                    <button
                      className="btn btn-danger btn-sm btn-label right rounded rounded-2"
                      onClick={() => {
                        actionBtnHandler('UPDATE_STATUS', row?.data);
                      }}
                    >
                      In-active
                      <i className="ri ri-pencil-line label-icon align-middle fs-14 ms-1"></i>
                    </button>
                  )}
                </div>
              ) : (
                <div className="d-flex justify-content-center gap-2">
                  {row?.data?.isActive ? (
                    <div
                      style={{
                        color: '#28a745',
                        padding: '5px 10px',
                        backgroundColor: '#eaffea',
                        borderRadius: '5px',
                      }}
                    >
                      Active
                    </div>
                  ) : (
                    <div
                      style={{
                        color: '#dc3545',
                        padding: '5px 10px',
                        backgroundColor: '#ffeaea',
                        borderRadius: '5px',
                      }}
                    >
                      In-active
                    </div>
                  )}
                </div>
              )}
            </>
          ),
        },
        {
          name: 'Status',
          width: '180px',
          cell: row => (
            <>
              {hasPermission(PermissionConstants.ALL_PERMISSIONS.UPDATE_USER) ? (
                <div className="d-flex justify-content-center">
                  {row?.data?.isActive ? (
                    <button
                      className="btn btn-success btn-sm btn-label right rounded rounded-2"
                      onClick={() => {
                        actionBtnHandler('UPDATE_STATUS', row?.data);
                      }}
                    >
                      Active
                      <i className="ri ri-pencil-line label-icon align-middle fs-14 ms-1"></i>
                    </button>
                  ) : (
                    <button
                      className="btn btn-danger btn-sm btn-label right rounded rounded-2"
                      onClick={() => {
                        actionBtnHandler('UPDATE_STATUS', row?.data);
                      }}
                    >
                      In-active
                      <i className="ri ri-pencil-line label-icon align-middle fs-14 ms-1"></i>
                    </button>
                  )}
                </div>
              ) : (
                <div className="d-flex justify-content-center gap-2">
                  {row?.data?.isActive ? (
                    <div
                      style={{
                        color: '#28a745',
                        padding: '5px 10px',
                        backgroundColor: '#eaffea',
                        borderRadius: '5px',
                      }}
                    >
                      Active
                    </div>
                  ) : (
                    <div
                      style={{
                        color: '#dc3545',
                        padding: '5px 10px',
                        backgroundColor: '#ffeaea',
                        borderRadius: '5px',
                      }}
                    >
                      In-active
                    </div>
                  )}
                </div>
              )}
            </>
          ),
        },
        {
          name: 'Actions',
          width: '180px',
          cell: row => (
            <div className="d-flex justify-content-center flex-column w-100 my-2">
              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.VIEW_CARD_LIST) && (
                <span
                  className="btn btn-sm btn-primary mb-1"
                  onClick={() => {
                    actionBtnHandler(`CARD_LIST`, row?.data);
                  }}
                >
                  Card List
                </span>
              )}
            </div>
          ),
        },
      ];
      setColumns(fetchColums);

      setData(
        props?.data?.map((data, index) => {
          return {
            serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            systemId: data?.objectId || ``,
            name: `${data?.firstName} ${data?.lastName}` || ``,
            email: data?.email || ``,
            userName: data?.userName || ``,
            mobileNumber: `${data?.countryCode ? `+${data?.countryCode}-` : ''}${data?.mobileNumber ?? ''}` || ``,
            createdAt: data?.createdAt ? moment(`${data?.createdAt}`).format('DD-MM-YYYY, h:mm A') : `NA`,
            data,
            data,
          };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `VIEW`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `CARD_LIST`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === 'UPDATE_STATUS') {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{`Customers Data`}</h4>

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_ACCESS) && props?.data?.length > 0 && (
                <span className="btn btn-success mb-1 mx-2" onClick={props?.dumpDownload}>
                  <i className="ri-download-line me-1 text-white fs-14 align-middle"></i> Download Dump
                </span>
              )}

              <span className="btn btn-info mb-1" onClick={props?.filterBtnHandler}>
                <i className="ri-filter-3-line align-bottom me-1"></i>Filter
              </span>
            </CardHeader>

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
