import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, indianProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { GET_PARENT_PROJECTS, GET_PARENT_PROJECTS_SUCCESS, GET_PARENT_PROJECTS_ERROR } from '../../actionType.js';
import apiCall from '../../apiCall.js';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getParentProjects({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/admin/parent-projects`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload.urlParam,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_PARENT_PROJECTS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_PARENT_PROJECTS_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_PARENT_PROJECTS_ERROR, payload: error });
  }
}

export function* watchGetParentProjects() {
  yield takeEvery(GET_PARENT_PROJECTS, getParentProjects);
}

function* getParentProjectsSaga() {
  yield all([fork(watchGetParentProjects)]);
}

export default getParentProjectsSaga;
