import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { BreadCrumb } from '../../../Common';

import { useHistory } from 'react-router-dom';
import PhoneImage from './../../../../assets/custom/phone.png';
import EmailImage from './../../../../assets/custom/email.png';

const ContactUs = () => {
  const history = useHistory();

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/client/dashboard`);
    }
  };

  return (
    <div className="page-content">
      <BreadCrumb title="Contact US" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Contact US`]} />
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0 flex-grow-1">Contact US</h4>
            </CardHeader>
            <CardBody>
              <Row className="d-flex justify-content-center">
                <Col xl={3} lg={4} md={6} sm={12} xs={12}>
                  <Card className="card-animate cursor-pointer">
                    <CardBody className="text-center">
                      <div>
                        <img
                          src={EmailImage}
                          alt=""
                          style={{
                            width: '40px',
                            borderRadius: '5px',
                            display: 'inline-block',
                          }}
                          className="img-fluid mb-2"
                        />
                      </div>
                      <h5 className="fs-15 fw-semibold mb-2">Email</h5>
                      <span>customer.care@thriwe.com</span>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl={3} lg={4} md={6} sm={12} xs={12}>
                  <Card className="card-animate cursor-pointer">
                    <CardBody className="text-center">
                      <div className="m-2">
                        <img
                          src={PhoneImage}
                          alt=""
                          style={{
                            width: '25px',
                            borderRadius: '5px',
                            display: 'inline-block',
                          }}
                          className="img-fluid mb-2"
                        />
                      </div>
                      <h5 className="fs-25 fw-semibold mb-2">Customer Support No</h5>
                      <span>+971 45177071</span>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ContactUs;
