import { UPDATE_BOOKING, UPDATE_BOOKING_SUCCESS, UPDATE_BOOKING_ERROR, EMPTY_UPDATE_BOOKING } from './../../actionType.js';

const initialState = {
  data: null,
  message: null,
  loading: false,
  success: false,
  error: false,
};

const UpdateBooking = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BOOKING:
      state = {
        ...state,
        data: null,
        loading: true,
        success: false,
        error: false,
        message: null,
      };
      break;

    case UPDATE_BOOKING_SUCCESS:
      state = {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
        error: false,
        message: null,
      };
      break;

    case UPDATE_BOOKING_ERROR:
      state = {
        ...state,
        data: null,
        loading: false,
        success: false,
        error: true,
        message: null,
      };
      break;

    case EMPTY_UPDATE_BOOKING:
      state = {
        ...initialState,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default UpdateBooking;
