import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import { Row, Col, Button, Modal, ModalBody, Table, Input } from 'reactstrap';

import { Constant } from '../../../../../../../Helpers/constant';
import { isANumber } from '../../../../../../../Helpers/Helpers';
import { UPDATE_BOOKING } from '../../../../../../../../store/application/actionType';

const UpdateModal = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [bookings, setBookings] = useState([]);
  const [modal_varying1, setmodal_varying1] = useState(true);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  useEffect(() => {
    if (props?.data !== null) {
      setBookings(props?.data);
      validationCheck(props?.data);
    }
  }, [props]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = (e, index) => {
    const { name, value } = e.target;

    let tempBooking = [...bookings];
    let selectedChangingBooking = null;

    let courierPickupLandline = '';
    let courierPickupMobile = '';
    let courierDropLandline = '';
    let courierDropMobile = '';

    switch (name) {
      case 'golf-course':
        break;
      case 'courier-pickup-emirates':
        selectedChangingBooking = {
          ...tempBooking[index],
          courierPickupAddress: {
            ...tempBooking[index]['courierPickupAddress'],
            emirates: {
              ...tempBooking[index]['courierPickupAddress']['emirates'],
              location: value,
            },
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'courier-pickup-address':
        selectedChangingBooking = {
          ...tempBooking[index],
          courierPickupAddress: {
            ...tempBooking[index]['courierPickupAddress'],
            fullAddress: {
              ...tempBooking[index]['courierPickupAddress']['fullAddress'],
              address: value,
            },
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'courier-pickup-fullname':
        selectedChangingBooking = {
          ...tempBooking[index],
          courierPickupAddress: {
            ...tempBooking[index]['courierPickupAddress'],
            fullName: value,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'courier-pickup-landline':
        if (value?.length === 0) {
          courierPickupLandline = ``;
        } else {
          courierPickupLandline = isANumber(value) ? value : tempBooking[index]?.courierPickupAddress?.landline;
        }
        selectedChangingBooking = {
          ...tempBooking[index],
          courierPickupAddress: {
            ...tempBooking[index]['courierPickupAddress'],
            landline: courierPickupLandline,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'courier-pickup-landmark':
        selectedChangingBooking = {
          ...tempBooking[index],
          courierPickupAddress: {
            ...tempBooking[index]['courierPickupAddress'],
            landmark: value,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'courier-pickup-mobile':
        if (value?.length === 0) {
          courierPickupMobile = ``;
        } else {
          courierPickupMobile = isANumber(value) ? value : tempBooking[index]?.courierPickupAddress?.mobile;
        }
        selectedChangingBooking = {
          ...tempBooking[index],
          courierPickupAddress: {
            ...tempBooking[index]['courierPickupAddress'],
            mobile: courierPickupMobile,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'courier-pickup-region':
        selectedChangingBooking = {
          ...tempBooking[index],
          courierPickupAddress: {
            ...tempBooking[index]['courierPickupAddress'],
            region: {
              ...tempBooking[index]['courierPickupAddress']['region'],
              location: value,
            },
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'courier-dropup-emirates':
        selectedChangingBooking = {
          ...tempBooking[index],
          courierDropAddress: {
            ...tempBooking[index]['courierDropAddress'],
            emirates: {
              ...tempBooking[index]['courierDropAddress']['emirates'],
              location: value,
            },
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'courier-dropup-address':
        selectedChangingBooking = {
          ...tempBooking[index],
          courierDropAddress: {
            ...tempBooking[index]['courierDropAddress'],
            fullAddress: {
              ...tempBooking[index]['courierDropAddress']['fullAddress'],
              address: value,
            },
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'courier-dropup-fullname':
        selectedChangingBooking = {
          ...tempBooking[index],
          courierDropAddress: {
            ...tempBooking[index]['courierDropAddress'],
            fullName: value,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'courier-dropup-landline':
        if (value?.length === 0) {
          courierDropLandline = ``;
        } else {
          courierDropLandline = isANumber(value) ? value : tempBooking[index]?.courierDropAddress?.landline;
        }
        selectedChangingBooking = {
          ...tempBooking[index],
          courierDropAddress: {
            ...tempBooking[index]['courierDropAddress'],
            landline: courierDropLandline,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'courier-dropup-landmark':
        selectedChangingBooking = {
          ...tempBooking[index],
          courierDropAddress: {
            ...tempBooking[index]['courierDropAddress'],
            landmark: value,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'courier-dropup-mobile':
        if (value?.length === 0) {
          courierDropMobile = ``;
        } else {
          courierDropMobile = isANumber(value) ? value : tempBooking[index]?.courierDropAddress?.mobile;
        }
        selectedChangingBooking = {
          ...tempBooking[index],
          courierDropAddress: {
            ...tempBooking[index]['courierDropAddress'],
            mobile: courierDropMobile,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'courier-dropup-region':
        selectedChangingBooking = {
          ...tempBooking[index],
          courierDropAddress: {
            ...tempBooking[index]['courierDropAddress'],
            region: {
              ...tempBooking[index]['courierDropAddress']['region'],
              location: value,
            },
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      default:
      // do nothing
    }
  };

  const datePickHandler = (flag, date, index) => {
    let tempBooking = [...bookings];
    let selectedChangingBooking = null;

    const selectedTime = date[0]; // Extract the first (and only) date object from the array
    const value = selectedTime
      ? `${selectedTime.getHours().toString().padStart(2, '0')}:${selectedTime.getMinutes().toString().padStart(2, '0')}`
      : ''; // Extract hours and minutes as a string with leading zeros

    switch (flag) {
      case 'serviceDate':
        if (moment() > moment(tempBooking[index]?.dateTimeOfService?.date)) {
          selectedChangingBooking = {
            ...tempBooking[index],
            dateTimeOfService: {
              ...tempBooking[index]?.['dateTimeOfService'],
              date: tempBooking[index]?.['dateTimeOfService']['date'],
            },
          };
        } else {
          selectedChangingBooking = {
            ...tempBooking[index],
            dateTimeOfService: {
              ...tempBooking[index]['dateTimeOfService'],
              date: moment(new Date(date)).format('Y-MM-DD'),
            },
          };
        }

        break;

      case 'serviceTime':
        selectedChangingBooking = {
          ...tempBooking[index],
          dateTimeOfService: {
            ...tempBooking[index]['dateTimeOfService'],
            time: value,
          },
        };
        break;

      default:
      // do nothing
    }
    tempBooking?.splice(index, 1, selectedChangingBooking);
    validationCheck(tempBooking);
    setBookings(tempBooking);
  };

  /* ---------------------------- VALIDATION HANDLER ---------------------------- */
  const validationCheck = data => {
    let flag = false;

    for (let i = 0; i < data?.length; i++) {
      if (
        data[i]?.courierPickupAddress?.emirates?.location?.trim() === '' ||
        data[i]?.courierPickupAddress?.fullAddress?.address?.trim() === '' ||
        data[i]?.courierPickupAddress?.fullName?.trim() === '' ||
        data[i]?.courierPickupAddress?.landline?.trim() === '' ||
        data[i]?.courierPickupAddress?.landmark?.trim() === '' ||
        data[i]?.courierPickupAddress?.mobile?.trim() === '' ||
        data[i]?.courierPickupAddress?.region?.location?.trim() === '' ||
        data[i]?.courierDropAddress?.emirates?.location?.trim() === '' ||
        data[i]?.courierDropAddress?.emirates?.location?.trim() === '' ||
        data[i]?.courierDropAddress?.fullAddress?.address?.trim() === '' ||
        data[i]?.courierDropAddress?.fullName?.trim() === '' ||
        data[i]?.courierDropAddress?.landline?.trim() === '' ||
        data[i]?.courierDropAddress?.landmark?.trim() === '' ||
        data[i]?.courierDropAddress?.mobile?.trim() === '' ||
        data[i]?.courierDropAddress?.region?.location?.trim() === '' ||
        data[i]?.dateTimeOfService?.date === '' ||
        data[i]?.dateTimeOfService?.time === '' ||
        moment() <= moment(data[i]?.dateTimeOfService?.date)
      ) {
        flag = true;
        break;
      }
    }

    flag ? setIsSubmitBtnDisabled(true) : setIsSubmitBtnDisabled(false);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const requestBookings = bookings?.map(booking => {
      let confirmedData = null;
      if (booking?.bookingStatusCode === Constant?.BOOKING_STATUS?.CONFIRM) {
        confirmedData = {
          confirmedDate: booking?.dateTimeOfService?.date,
          confirmedTime: booking?.dateTimeOfService?.time,
        };
      } else {
        confirmedData = {
          dateTimeOfService: {
            date: booking?.dateTimeOfService?.date,
            time: booking?.dateTimeOfService?.time,
          },
        };
      }

      return {
        objectId: booking?.bookingId,
        detailedBookingId: booking?.detailedBookingId,
        courierDropAddress: booking.courierDropAddress,
        courierPickupAddress: booking.courierPickupAddress,
        ...confirmedData,
        process: `update-booking`,
      };
    });

    dispatch({
      type: UPDATE_BOOKING,
      payload: {
        data: {
          projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
          bookingTypeId: Constant?.BOOKING_TYPES?.golfBookings?.objectId,
          bookingsData: requestBookings,
        },
      },
    });
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      scrollable={true}
      id="exampleModal"
      size="lg"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Update
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log({ bookings })}>get state</button> */}
        <form>
          <Row>
            <Col md={12} style={{ mixHeight: '500px' }}>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Booking ID</th>
                    <th> Name</th>
                    <th> Courier Pickup emirates </th>
                    <th> Courier Pickup Address </th>
                    <th> Courier Pickup Name </th>
                    <th> Courier Pickup Landline </th>
                    <th> Courier Pickup LandMark </th>
                    <th> Courier Pickup Mobile </th>
                    <th> Courier Pickup Region </th>
                    <th> Courier Drop emirates </th>
                    <th> Courier Drop Address </th>
                    <th> Courier Drop Name </th>
                    <th> Courier Drop Landline </th>
                    <th> Courier Drop LandMark </th>
                    <th> Courier Drop Mobile </th>
                    <th> Courier Drop Region </th>
                    <th> Date of Service </th>
                    <th> Time of Service </th>
                  </tr>
                </thead>
                <tbody>
                  {bookings?.map((booking, index) => (
                    <tr key={index}>
                      <th>{index + 1}.</th>
                      {/* <td>{booking?.bookingId}</td> */}
                      <td>
                        <span style={{ display: 'inline-block', width: '140px' }}>{booking?.bookingId}</span>
                      </td>
                      <td>
                        <span style={{ display: 'inline-block', width: '140px' }}>{booking?.user?.name}</span>
                      </td>

                      <td>
                        {
                          <Input
                            id="courier-pickup-emirates"
                            name="courier-pickup-emirates"
                            type="text"
                            placeholder=""
                            className="form-control w-200"
                            style={{ width: '200px' }}
                            onChange={e => inputChangeHandler(e, index)}
                            value={booking?.courierPickupAddress?.emirates?.location}
                          />
                        }
                      </td>

                      <td>
                        {
                          <Input
                            id="courier-pickup-address"
                            name="courier-pickup-address"
                            type="text"
                            placeholder=""
                            className="form-control"
                            style={{ width: '200px' }}
                            onChange={e => inputChangeHandler(e, index)}
                            value={booking?.courierPickupAddress?.fullAddress?.address}
                          />
                        }
                      </td>

                      <td>
                        <Input
                          id="courier-pickup-fullname"
                          name="courier-pickup-fullname"
                          type="text"
                          placeholder=""
                          className="form-control"
                          style={{ width: '200px' }}
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.courierPickupAddress?.fullName}
                        />
                      </td>

                      <td>
                        <Input
                          id="courier-pickup-landline"
                          name="courier-pickup-landline"
                          type="number"
                          onWheel={e => e.target.blur()}
                          placeholder=""
                          className="form-control"
                          style={{ width: '200px' }}
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.courierPickupAddress?.landline}
                        />
                      </td>

                      <td>
                        <Input
                          id="courier-pickup-landmark"
                          name="courier-pickup-landmark"
                          type="text"
                          placeholder=""
                          className="form-control"
                          style={{ width: '200px' }}
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.courierPickupAddress?.landmark}
                        />
                      </td>
                      <td>
                        <Input
                          id="courier-pickup-mobile"
                          name="courier-pickup-mobile"
                          type="number"
                          onWheel={e => e.target.blur()}
                          placeholder=""
                          className="form-control"
                          style={{ width: '200px' }}
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.courierPickupAddress?.mobile}
                        />
                      </td>
                      <td>
                        <Input
                          id="courier-pickup-region"
                          name="courier-pickup-region"
                          type="text"
                          placeholder=""
                          className="form-control"
                          style={{ width: '200px' }}
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.courierPickupAddress?.region?.location}
                        />
                      </td>
                      <td>
                        <Input
                          id="courier-dropup-emirates"
                          name="courier-dropup-emirates"
                          type="text"
                          placeholder=""
                          className="form-control"
                          style={{ width: '200px' }}
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.courierDropAddress?.emirates?.location}
                        />
                      </td>
                      <td>
                        <Input
                          id="courier-dropup-address"
                          name="courier-dropup-address"
                          type="text"
                          placeholder=""
                          className="form-control"
                          style={{ width: '200px' }}
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.courierDropAddress?.fullAddress?.address}
                        />
                      </td>
                      <td>
                        <Input
                          id="courier-dropup-fullname"
                          name="courier-dropup-fullname"
                          type="text"
                          placeholder=""
                          className="form-control"
                          style={{ width: '200px' }}
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.courierDropAddress?.fullName}
                        />
                      </td>
                      <td>
                        <Input
                          id="courier-dropup-landline"
                          name="courier-dropup-landline"
                          type="number"
                          onWheel={e => e.target.blur()}
                          style={{ width: '200px' }}
                          placeholder=""
                          className="form-control"
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.courierDropAddress?.landline}
                        />
                      </td>
                      <td>
                        <Input
                          id="courier-dropup-landmark"
                          name="courier-dropup-landmark"
                          type="text"
                          placeholder=""
                          style={{ width: '200px' }}
                          className="form-control"
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.courierDropAddress?.landmark}
                        />
                      </td>
                      <td>
                        <Input
                          id="courier-dropup-mobile"
                          name="courier-dropup-mobile"
                          type="number"
                          onWheel={e => e.target.blur()}
                          placeholder=""
                          style={{ width: '200px' }}
                          className="form-control"
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.courierDropAddress?.mobile}
                        />
                      </td>
                      <td>
                        <Input
                          id="courier-dropup-region"
                          name="courier-dropup-region"
                          type="text"
                          placeholder=""
                          style={{ width: '200px' }}
                          className="form-control"
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.courierDropAddress?.region?.location}
                        />
                      </td>
                      <td>
                        <Flatpickr
                          className="form-control"
                          name="serviceDate"
                          id="serviceDate"
                          style={{ width: '200px' }}
                          value={booking?.dateTimeOfService?.date}
                          onChange={date => datePickHandler(`serviceDate`, date, index)}
                          options={{
                            enableTime: false,
                            dateFormat: 'Y-m-d',
                          }}
                        />
                      </td>
                      <td>
                        <Flatpickr
                          className={`form-control`}
                          name={`serviceTime`}
                          id="serviceTime"
                          style={{ width: '200px' }}
                          value={booking?.dateTimeOfService?.time}
                          onChange={date => datePickHandler(`serviceTime`, date, index)}
                          options={{
                            noCalendar: true,
                            enableTime: true,
                            dateFormat: 'H:i',
                            time_24hr: false,
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateModal;
