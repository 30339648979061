import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Flatpickr from 'react-flatpickr';

import { Card, CardBody, Col, Form, Label, Row, CardFooter, Input, Container, OffcanvasBody, OffcanvasHeader, Offcanvas } from 'reactstrap';
import { PreviewCardHeader } from '../../../../../../Common';

import { AsyncPaginate } from 'react-select-async-paginate';
import { PAYMENT_LOGS_EMPTY } from '../../../../../../../store/application/actionType';
import Select from 'react-select';
import { isValidAplha } from '../../../../../../Helpers/Helpers';
import { indianProjectsBaseURL, customHeaders } from '../../../../../../Config';
import { Constant } from '../../../../../../Helpers/constant';

const CustomFilter = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    paymentDateRange: {
      from: ``,
      to: ``,
    },
    paymentId: ``,
    projectCode: ``,
  };
  const [filter, setFilter] = useState(initialState);

  /* ---------------------------- REDUX STATES ---------------------------- */
  useEffect(() => {
    if (props?.appliedFilters !== null) {
      setFilter(props?.appliedFilters);
    }
  }, [props?.appliedFilters]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'paymentId':
        updatedState = {
          ...filter,
          paymentId: value,
        };
        setFilter(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    props.filterHandler({
      ...filter,
    });
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter({ ...initialState });

    dispatch({
      type: PAYMENT_LOGS_EMPTY,
    });
  };

  /* ---------------------------- TYPED USER HANDLER ---------------------------- */
  async function loadProjects(search) {
    const response = await fetch(`${indianProjectsBaseURL}/admin/projects?getAll=true&name=${search}`, {
      method: `GET`,
      headers: new Headers({
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      }),
    });
    const usersResponse = await response.json();

    return {
      options: usersResponse?.results?.map((data, index) => {
        return {
          value: data?.id,
          label: `${data?.name}`,
          completeData: data,
        };
      }),
      hasMore: false,
    };
  }

  /* ---------------------------- ON SELECT USER ---------------------------- */
  const onSelectProject = event => {
    setFilter({
      ...filter,
      projectCode: event,
    });
  };

  return (
    <>
      <Offcanvas direction="end" isOpen={props.show} id="offcanvasExample" toggle={() => props.onCloseClick()}>
        <OffcanvasHeader className="bg-light" toggle={() => props.onCloseClick()}>
          Report filters
        </OffcanvasHeader>
        <OffcanvasBody>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="program" className="form-label">
                  Program
                </Label>
                <AsyncPaginate
                  debounceTimeout={500}
                  value={filter?.projectCode}
                  loadOptions={loadProjects}
                  onChange={e => onSelectProject(e)}
                  isMulti={false}
                  closeMenuOnSelect={true}
                  noOptionsMessage={() => 'No results found'}
                  cacheUniqs={[['code']]}
                  placeholder="Enter Name..."
                  isClearable={true}
                />
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="facility" className="form-label">
                  Payment ID
                </Label>
                <Input id="paymentId" name="paymentId" type="text" className="form-control" value={filter?.paymentId} onChange={inputChangeHandler} />
              </div>
            </Col>

            {/* <Col md={4}>
                      <div className="mb-3">
                        <Label
                          htmlFor="paymentDateRange"
                          className="form-label"
                        >
                          Payment - Range
                          <span className="icon_calendar">
                            <i className="las la-calendar"></i>
                          </span>
                        </Label>
                        <div className="area_expandable">
                          <Flatpickr
                            className="form-control"
                            value={[
                              filter?.paymentDateRange?.from,
                              filter?.paymentDateRange?.to,
                            ]}
                            onChange={(date) =>
                              datePickHandler(`paymentDateRange`, date)
                            }
                            options={{
                              mode: 'range',
                              dateFormat: 'Y-m-d',
                              maxDate: 'today',
                            }}
                          />
                        </div>
                      </div>
                    </Col> */}
          </Row>
        </OffcanvasBody>

        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
          <button className="btn btn-light w-100" onClick={resetFilterHandler}>
            Reset Filter
          </button>

          <button className="btn btn-primary w-100" onClick={filterSubmitHandler} disabled={filter?.projectCode === ``}>
            Search
          </button>
        </div>
      </Offcanvas>
    </>
  );
};

export default CustomFilter;
