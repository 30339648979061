import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';

import ProjectPlaceholder from './../../../../../../../assets/custom/project-placeholder-img.png';

const ProgramsList = ({ selecteProgram, programs, allProjectsList, setAllProgramsList }) => {
  // /* ---------------------------- LOCAL STATES ---------------------------- */
  // const [searchedName, setSearchedName] = useState("");
  // const [allProjectsList, setAllProgramsList] = useState([]);

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    setAllProgramsList(programs);
  }, [programs]);

  // /* ---------------------------- SEARCH PROJECT BY NAME HANDLER ---------------------------- */
  // const searchProjectHandler = (e) => {
  //   setSearchedName(e.target.value);

  //   let filteredProjectsName = programs;
  //   if (e.target.value.trim() !== "") {
  //     filteredProjectsName = allProjectsList?.filter((project) =>
  //       project?.name
  //         ?.toLocaleLowerCase()
  //         ?.includes(e.target.value?.toLocaleLowerCase())
  //     );
  //   }
  //   setAllProgramsList([...filteredProjectsName]);
  // };

  return (
    <>
      <Row>
        {allProjectsList?.map((project, key) => (
          <Col key={key} xl={3} lg={4} md={6}>
            <Card>
              <CardBody className="cursor-pointer" onClick={() => selecteProgram(project)}>
                <div className="d-flex">
                  <div className="flex-shink-0">
                    <img
                      src={`${project?.configuration?.images?.logo === '' ? ProjectPlaceholder : project?.configuration?.images?.logo}`}
                      alt={project?.name}
                      className="avatar-sm object-cover rounded"
                    />
                  </div>
                  <div className="ms-3 flex-grow-1">
                    <h5 className="mb-1">{project?.name}</h5>
                    <p className="text-muted mb-0">
                      <i className="las la-handshake text-primary fs-16"></i> {project?.client?.name}
                    </p>
                  </div>
                  {/* <div>
                                        <h5 className="text-center">{`${project?.contractingCountry?.flag ?? ""}`}</h5>
                                        <UncontrolledDropdown
                                            direction="start"
                                            className="float-end"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        >
                                            <DropdownToggle
                                                tag="button"
                                                className="btn btn-ghost-primary btn-icon"
                                            >
                                                <i className="ri-more-2-fill align-middle fs-16"></i>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-end">
                                                <DropdownItem className="view-item-btn">
                                                    Update Program
                                                </DropdownItem>
                                                <DropdownItem className="view-item-btn">
                                                    Comm Settings
                                                </DropdownItem>
                                                <DropdownItem className="view-item-btn">
                                                    Payment Settings
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div> */}
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default ProgramsList;
