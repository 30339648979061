import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'reactstrap';
import FilesSummaryTable from './Components/Tables/FilesSummaryTable';
import { BreadCrumb, Loader } from '../../Common';
import Conclusion from './Components/UI/Conclusion/Conclusion';
import { Constant } from './../../Helpers/constant';

import {
  UPLOAD_FILE_EMPTY,
  GET_FILES_SUMMARY,
  GET_FILES_SUMMARY_EMPTY,
  GET_FILE_SUMMARY,
  GET_FILE_SUMMARY_EMPTY,
} from './../../../store/application/actionType.js';
import UploadFile from './Components/Modals/UploadFile.js';

const DataPool = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [isMainPage, setIsMainPage] = useState(true);
  const [isSummaryPage, setIsSummaryPage] = useState(false);

  const [isUploadModalShow, setIsUploadModalShow] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentSelectedFileSummary, setCurrentSelectedFileSummary] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  //    Files Summary
  const { filesSummary, filesSummaryLoading } = useSelector(state => ({
    filesSummary: state?.FilesSummary?.data,
    filesSummaryLoading: state?.FilesSummary?.loading,
  }));

  //    Files Summary
  const { fileSummary, fileSummaryLoading } = useSelector(state => ({
    fileSummary: state?.FileSummary?.data,
    fileSummaryLoading: state?.FileSummary?.loading,
  }));

  // Upload file
  const { uploadFile, uploadFileLoading } = useSelector(state => ({
    uploadFile: state?.UploadFile?.data,
    uploadFileLoading: state?.UploadFile?.loading,
  }));

  useEffect(() => {
    dispatch({
      type: GET_FILES_SUMMARY,
      payload: {
        urlParam: {
          pageIndex: 0,
          pageSize: Constant?.TABLE_PAGE_SIZE,
        },
      },
    });

    return () => {
      dispatch({
        type: GET_FILE_SUMMARY_EMPTY,
      });

      dispatch({
        type: GET_FILES_SUMMARY_EMPTY,
      });
    };
  }, []);

  useEffect(() => {
    if (uploadFile) {
      dispatch({
        type: UPLOAD_FILE_EMPTY,
      });
    }
  }, [uploadFile]);

  useEffect(() => {
    if (fileSummary !== null) {
      setIsMainPage(false);
      setIsSummaryPage(true);
    }
  }, [fileSummary]);

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);

    dispatch({
      type: GET_FILES_SUMMARY,
      payload: {
        urlParam: {
          pageSize: Constant?.TABLE_PAGE_SIZE,
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedFileSummary(params?.details);

    if (params?.actionType === `VIEW`) {
      dispatch({
        type: GET_FILE_SUMMARY,
        payload: {
          params: {
            pageIndex: 0,
            pageSize: Constant?.TABLE_PAGE_SIZE,
            filterQuery: {
              projectCode: params?.details?.projectCode,
              fileId: params?.details?.fileId,
            },
          },
        },
      });
    }
  };

  /* ---------------------------- UPLOAD FILE HANDLER ---------------------------- */
  const uploadFileHandler = () => {
    setIsUploadModalShow(!isUploadModalShow);
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (filesSummaryLoading || fileSummaryLoading || uploadFileLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [filesSummaryLoading, fileSummaryLoading, uploadFileLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `File Summary`) {
      setIsMainPage(true);
      setIsSummaryPage(false);

      dispatch({
        type: GET_FILE_SUMMARY_EMPTY,
      });
    } else if (title === `Row Summary`) {
      // nothing
    }
  };

  return (
    <div className="page-content">
      <Container fluid className="position-relative">
        {loading && <Loader />}

        {isUploadModalShow && <UploadFile onCloseHandler={() => setIsUploadModalShow(false)} />}

        {/* ---------------------------- SCREEN'S ----------------------------  */}
        {isMainPage && (
          <>
            <BreadCrumb title="Ledger" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `File Summary`]} />
            <FilesSummaryTable
              data={filesSummary?.results}
              totalData={filesSummary?.count}
              pageNumber={currentPageNumber}
              dataStartedFrom={currentPageIndex}
              pageHandler={paginationHandler}
              actionHandler={tableActionHandler}
              onFileUpload={uploadFileHandler}
            />
          </>
        )}

        {isSummaryPage && (
          <>
            <BreadCrumb title="Ledger" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `File Summary`, `Row Summary`]} />
            <Conclusion selectedFileSummary={currentSelectedFileSummary} />
          </>
        )}
      </Container>
    </div>
  );
};

export default DataPool;
