import React, { useEffect, useState } from 'react';

import moment from 'moment';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';

import { Constant } from '../../../../../../../Helpers/constant';
import { PreviewCardHeader, TablePagination } from '../../../../../../../Common';
import { PermissionConstants } from '../../../../../../../Helpers/Constants/permissions';
import { formattedWithComma, hasPermission } from '../../../../../../../Helpers/Helpers';

const Table = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  const [currentFilteredBookingTitle, setCurrentFilterBookingTitle] = useState('Requested Bookings');

  useEffect(() => {
    if (props?.data?.length > 0) {
      getBookingStatusTitleHandler(parseInt(props?.appliedFilters?.vendorStatus));

      const fetchColums = [
        {
          name: 'S.No',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
          width: '90px',
        },
        {
          name: 'Booking ID',
          selector: row => row?.bookingId,
          wrap: true,
          width: '130px',
        },
        {
          name: 'Full Name',
          selector: row => row?.name,
          wrap: true,
          width: '140px',
        },
        {
          name: 'Email',
          width: '250px',
          selector: row => row?.email,
          wrap: true,
        },
        {
          name: 'Mobile No.',
          width: '140px',
          selector: row => row?.mobile,
          wrap: true,
        },
        {
          name: 'Date Time of Request',
          width: '180px',
          selector: row => row?.createdAt,
          wrap: true,
        },
        {
          name: 'No. Of Passengers',
          width: '170px',
          selector: row => row?.numberOfPassengers,
          wrap: true,
        },
        {
          name: 'No. Of Baby Seats',
          width: '140px',
          selector: row => row?.numberOfBabySeats,
          wrap: true,
        },
        {
          name: 'No. Of Luggages',
          width: '140px',
          selector: row => row?.numberOfLuggages,
          wrap: true,
        },
        {
          name: 'Pickup Location',
          width: '220px',
          selector: row => row?.pickupLocationAddress,
          wrap: true,
        },
        {
          name: 'Pickup SubRegion',
          width: '180px',
          selector: row => row?.pickupLocationEmirates,
          wrap: true,
        },
        {
          name: 'Pickup Region',
          width: '140px',
          selector: row => row?.pickupLocationRegion,
          wrap: true,
        },
        {
          name: 'Drop Location',
          width: '140px',
          selector: row => row?.dropLocationAddress,
          wrap: true,
        },
        {
          name: 'Drop SubRegion',
          width: '180px',
          selector: row => row?.dropLocationEmirates,
          wrap: true,
        },
        {
          name: 'Drop Region',
          width: '140px',
          selector: row => row?.dropLocationRegion,
          wrap: true,
        },
        {
          name: 'Pickup Date',
          width: '140px',
          selector: row => row?.pickupDate,
          wrap: true,
        },
        {
          name: 'Pickup Time',
          width: '140px',
          selector: row => row?.pickupTime,
          wrap: true,
        },
        {
          name: 'Flight Number',
          width: '140px',
          selector: row => row?.flightNumber,
          wrap: true,
        },
        {
          name: 'Terminal Number',
          width: '140px',
          selector: row => row?.terminalNumber,
          wrap: true,
        },
        {
          name: 'Action',
          width: '150px',
          cell: row => (
            <div className="d-flex justify-content-center flex-column w-100 p-2">
              {row?.data?.vendorStatus === Constant?.BOOKING_STATUS?.REQUESTED && (
                <>
                  <span
                    className="btn btn-sm btn-primary mb-1"
                    onClick={() => {
                      actionBtnHandler(`ACCEPT`, row?.data);
                    }}
                  >
                    Accept
                  </span>

                  <span
                    className="btn btn-sm btn-danger mb-1"
                    onClick={() => {
                      actionBtnHandler(`REJECT`, row?.data);
                    }}
                  >
                    Reject
                  </span>
                </>
              )}

              {row?.data?.vendorStatus === Constant?.BOOKING_STATUS?.CONFIRM && (
                <>
                  <span
                    className="btn btn-sm btn-primary mb-1"
                    onClick={() => {
                      actionBtnHandler(`COMPLETE`, row?.data);
                    }}
                  >
                    Complete
                  </span>
                </>
              )}
            </div>
          ),
        },
      ];
      setColumns(fetchColums);

      setData(
        props?.data?.map((data, index) => {
          return {
            serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            bookingId: data?.bookingId || `NA`,
            name: data?.user?.name || `NA`,
            email: data?.user?.email || `NA`,
            mobile: data?.user?.mobile || `NA`,
            createdAt: data?.createdAt ? moment(new Date(`${data?.createdAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            numberOfPassengers: data?.numberOfPassengers || `NA`,
            numberOfBabySeats: data?.numberOfBabySeats || `NA`,
            numberOfLuggages: data?.numberOfLuggages || `NA`,

            pickupLocationAddress: data?.pickupLocation?.address || `NA`,
            pickupLocationEmirates: data?.pickupLocation?.emirates || `NA`,
            pickupLocationRegion: data?.pickupLocation?.region || `NA`,

            dropLocationAddress: data?.dropLocation?.address || `NA`,
            dropLocationEmirates: data?.dropLocation?.emirates || `NA`,
            dropLocationRegion: data?.dropLocation?.region || `NA`,

            pickupDate: data?.pickupDate ? moment(new Date(`${data?.pickupDate}`)).format('DD-MM-YYYY') : 'NA',
            pickupTime: data?.pickupTime ? moment(`${data?.pickupTime}`, 'HH:mm').format('h:mm A') : 'NA',

            flightNumber: data?.flightNumber || `NA`,
            terminalNumber: data?.terminalNumber || `NA`,

            data,
          };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- BOOKING TABLE TITLE HANDLER ---------------------------- */
  const getBookingStatusTitleHandler = bookingsStatusCode => {
    let bookingsTitle = 'Requested Bookings';

    if (bookingsStatusCode !== 0) {
      Constant?.BOOKING_STATUSES?.forEach(bookingStatus => {
        if (bookingsStatusCode === bookingStatus?.objectId) {
          bookingsTitle = `${bookingStatus?.name} Bookings`;
        }
      });
    }
    setCurrentFilterBookingTitle(bookingsTitle);
  };

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `ACCEPT`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `REJECT`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `COMPLETE`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      {/* <button onClick={() => console.log(bookingData)}>get state</button> */}
      <Row>
        <Col xxl={12}>
          <PreviewCardHeader
            title={currentFilteredBookingTitle}
            primaryButton={
              hasPermission(PermissionConstants?.ALL_PERMISSIONS?.VENDOR_DOWNLOAD_DUMP) &&
              props?.data?.length > 0 &&
              !['', null, undefined]?.includes(props?.appliedFilters?.createdAtRange?.from)
            }
            primaryButtonText="Download Dump"
            primaryClickHandler={props?.dumpDownload}
          />

          <Card>
            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Table);
