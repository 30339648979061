import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import apiCall from '../../../apiCall';
import { baseUrl, customHeaders } from './../../../../../Containers/Config/index';

import { UPDATE_RULE, UPDATE_RULE_SUCCESS, UPDATE_RULE_ERROR } from './../../../actionType';
import { Constant } from '../../../../../Containers/Helpers/constant';
import cogoToast from 'cogo-toast';

function* updateRule({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/rules`,
      method: 'POST',
      headers: { Authorization: Constant?.CLIENT_TOKEN, ...customHeaders },
      data: payload.data,
    });

    if (response.status === 200) {
      cogoToast.success(`Rule updated successfully.`);
      // cogoToast.success(response?.data?.message)
      yield put({ type: UPDATE_RULE_SUCCESS, payload: response });
    } else {
      cogoToast.error(response?.response?.data?.message);
      // yield put({ type: UPDATE_RULE_ERROR, payload: response.error })
    }
  } catch (error) {
    yield put({ type: UPDATE_RULE_ERROR, payload: error });
  }
}

export function* watchUpdateRule() {
  yield takeEvery(UPDATE_RULE, updateRule);
}

function* updateRuleSaga() {
  yield all([fork(watchUpdateRule)]);
}

export default updateRuleSaga;
