import { useEffect, useState } from 'react';

import moment from 'moment';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';

import { TablePagination } from '../../../../../../../Common';
import { Constant } from '../../../../../../../Helpers/constant';
import { formattedWithComma } from '../../../../../../../Helpers/Helpers';

const Table = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColums = [
        {
          name: 'S.No',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
          width: '90px',
        },
        {
          name: 'Type',
          selector: row => row?.type,
          wrap: true,
          width: '150px',
        },
        {
          name: 'Category Name',
          selector: row => row?.categoryName,
          wrap: true,
          minWidth: '200px',
        },
        {
          name: 'City Name',
          selector: row => row?.cityName,
          wrap: true,
          width: '220px',
        },
        {
          name: 'Post Type',
          selector: row => row?.postTypeName,
          wrap: true,
          width: '180px',
        },
        {
          name: 'Created At',
          selector: row => row?.createdAt,
          wrap: true,
          width: '200px',
        },
      ];
      setColumns(fetchColums);

      setData(
        props?.data?.map((data, index) => {
          let tempType = `NA`;
          if (data?.type === 1) {
            tempType = `Feed`;
          } else if (data?.type === 2) {
            tempType = `Advertisement`;
          }

          return {
            serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            type: tempType,
            cityName: data?.cityName || `NA`,
            postTypeName: data?.postTypeName || `NA`,
            categoryName: data?.categoryName || `NA`,
            createdAt: data?.createdAt ? moment(new Date(`${data?.createdAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            data,
          };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{`${props?.title}`}</h4>

              <span className="btn btn-success mb-1" onClick={props?.setIsShowCreateAnnouncementModal}>
                <div className="me-2">
                  <i className="ri-add-line me-1 text-white text-opacity-75 fs-14 align-middle"></i>Create Announcement
                </div>
              </span>
            </CardHeader>

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b>
                      of&nbsp;<b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
