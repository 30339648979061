import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { customBaseURL, customHeaders, indianProjectsBaseURL, uaeProjectsBaseURL,clientCustomHeaders } from '../../../../Containers/Config/index.js';
import { GET_CLIENT_BENEFIT_CONFIG, GET_CLIENT_BENEFIT_CONFIG_SUCCESS, GET_CLIENT_BENEFIT_CONFIG_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getBenefitConfig({ payload,endpoint=`/vendor/benefit-configs` }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}${endpoint}`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...clientCustomHeaders(),
        ...payload?.headers
      },
      params: {
        ...payload?.urlParam,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_CLIENT_BENEFIT_CONFIG_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_CLIENT_BENEFIT_CONFIG_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_CLIENT_BENEFIT_CONFIG_ERROR, payload: error });
  }
}

export function* watchGetBenefitConfig() {
  yield takeEvery(GET_CLIENT_BENEFIT_CONFIG, getBenefitConfig);
}

function* getClientBenefitConfigSaga() {
  yield all([fork(watchGetBenefitConfig)]);
}

export default getClientBenefitConfigSaga;
