import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';

import { Card, CardBody, Col, Container, Form, Label, Row, Input, CardFooter, UncontrolledTooltip } from 'reactstrap';
import { PreviewCardHeader } from '../../../../../Common';
import { Constant } from '../../../../../Helpers/constant';
import ReactTooltip from 'react-tooltip';

import { AsyncPaginate } from 'react-select-async-paginate';
import { isANumber, isValidAplha } from '../../../../../Helpers/Helpers';

const expenditureRuleTypes = [
  { value: 1, label: `Conversion` },
  { value: 2, label: `Flat` },
];

const ExpenditureForm = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */

  const initialState = {
    globalRuleDetails: {
      id: 0,
      ruleName: '',
    },
    name: ``,
    isRepeat: false,
    repeatDetail: {
      value: 1,
      unit: ``,
    },
    coinExpiry: ``,
    isActive: true,
    ruleExpiryDate: ``,
    maxCoin: 0,
    conversionRate: 0,
    expenditureAmount: 0,
  };
  const [state, setState] = useState(initialState);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);
  const [isRepeatDetailsDisabled, setIsRepeatDetailsDisabled] = useState(false);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    let rate = null;

    switch (name) {
      case 'isRepeat':
        updatedState = {
          ...state,
          repeatDetail: {
            ...state.repeatDetail,
            value: !state.isRepeat === false ? `` : 1,
          },
          isRepeat: !state.isRepeat,
        };
        setIsRepeatDetailsDisabled(!isRepeatDetailsDisabled);
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'repeatDetailUnit':
        updatedState = {
          ...state,
          repeatDetail: {
            value: parseInt(state?.repeatDetail?.value),
            unit: value,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'repeatDetailValue':
        updatedState = {
          ...state,
          repeatDetail: {
            value: parseInt(value),
            unit: state?.repeatDetail?.unit,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'maxCoin':
        updatedState = {
          ...state,
          maxCoin: isNaN(value) ? state?.maxCoin : parseInt(value || 0),
          // maxCoin: parseInt(value),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'conversionRate':
        if (value?.length === 0) {
          rate = ``;
        } else {
          rate = isANumber(value) ? value : state.conversionRate;
        }
        updatedState = {
          ...state,
          conversionRate: rate,
          // conversionRate: parseInt(value),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'expenditureAmount':
        updatedState = {
          ...state,
          // expenditureAmount: parseInt(value),
          expenditureAmount: isNaN(value) ? state?.expenditureAmount : parseInt(value || 0),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'isActive':
        updatedState = {
          ...state,
          isActive: !state.isActive,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'name':
        updatedState = {
          ...state,
          name: isValidAplha(value) ? value : state?.name,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'coinExpiry':
        updatedState = {
          ...state,
          coinExpiry: isNaN(value) ? state?.coinExpiry : parseInt(value || 0),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'ruleExpiry':
        updatedState = {
          ...state,
          ruleExpiryDate: new Date(date).toISOString(),
          // ruleExpiryDate: moment(new Date(date)).format('Y-MM-DDTh:mm:ss.000Z'),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  const expenditureInputHandler = data => {
    let updatedState = null;

    updatedState = {
      ...initialState,
      globalRuleDetails: {
        id: data?.value,
        ruleName: data?.label,
      },
      // maxCoin: 0,
      // conversionRate: 0,
    };
    setState(updatedState);
    validationCheck(updatedState);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    props.onSubmit({
      ...state,
      conversionRate: parseFloat(state.conversionRate),
    });
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data?.globalRuleDetails?.id === 1) {
      if (
        data.maxCoin !== 0 &&
        data.conversionRate !== 0 &&
        data.conversionRate.includes('.') &&
        data.coinExpiry !== `` &&
        data.ruleExpiryDate !== `` &&
        data.name !== `` &&
        data.expenditureAmount !== 0
      ) {
        if (data.isRepeat === true && data.repeatDetail.unit !== ``) {
          setIsSubmitBtnDisabled(false);
        } else if (data.isRepeat === false && data.repeatDetail.unit === ``) {
          setIsSubmitBtnDisabled(false);
        } else {
          setIsSubmitBtnDisabled(true);
        }
      } else {
        setIsSubmitBtnDisabled(true);
      }
    } else if (data?.globalRuleDetails?.id === 2) {
      if (data.conversionRate != 0 && data.conversionRate.includes('.') && data.coinExpiry !== 0 && data.ruleExpiryDate !== `` && data.name !== ``) {
        if (data.isRepeat === true && data.repeatDetail.unit !== ``) {
          setIsSubmitBtnDisabled(false);
        } else if (data.isRepeat === false && data.repeatDetail.unit === ``) {
          setIsSubmitBtnDisabled(false);
        } else {
          setIsSubmitBtnDisabled(true);
        }
      } else {
        setIsSubmitBtnDisabled(true);
      }
    }
  };

  const [value, setValue] = useState(null);

  async function loadOptions(search, loadedOptions) {
    const response = await fetch(`https://demo.dataverse.org/api/search?q=${search}`);
    const responseJSON = await response.json();
    return {
      options: responseJSON?.data?.items?.map((data, index) => {
        return {
          value: index,
          label: data.name,
        };
      }),
      hasMore: false,
    };
  }

  const onPagiChange = event => {
    setValue(event);
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Expenditure Rule Creation" />
            {/* <button onClick={() => console.log(state)}>get state</button> */}
            <CardBody>
              <div className="live-preview">
                <Form>
                  {/* <Row>
                    <AsyncPaginate
                      debounceTimeout={500}
                      value={value}
                      loadOptions={loadOptions}
                      onChange={(e) => onPagiChange(e)}
                      isMulti
                      closeMenuOnSelect={false}
                    />
                  </Row> */}

                  <Row>
                    <Row>
                      {/* <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="client" className="form-label">
                            Client
                          </Label>
                          <select
                            id="client"
                            name="client"
                            className="form-select"
                            value={state?.clientId}
                            disabled={true}
                          >
                            <option value={state?.clientId?.id}>
                              {state?.clientId?.name}
                            </option>
                          </select>
                        </div>
                      </Col> */}

                      {/* <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="program" className="form-label">
                            Program
                          </Label>
                          <select
                            id="program"
                            name="program"
                            className="form-select"
                            disabled={true}
                          >
                            <option value={state?.projectId?.id}>
                              {state?.projectId?.name}
                            </option>

                            {props?.data?.projects?.map((project, index) => (
                              <option key={index} value={project?.objectId}>
                                {project?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col> */}

                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="program" className="form-label">
                            Expenditure Type &nbsp;
                            <span className="lbl_mandatory">*</span>
                          </Label>
                          <Select id="expenditureType" name="expenditureType" options={expenditureRuleTypes} onChange={expenditureInputHandler} />
                        </div>
                      </Col>

                      {[1, 2].includes(state.globalRuleDetails.id) && (
                        <Col md={4}>
                          <div className="mb-3">
                            <Label className="form-label" htmlFor="name">
                              Name <span className="lbl_mandatory">*</span>
                            </Label>
                            <Input id="name" name="name" type="text" className="form-control" onChange={inputChangeHandler} value={state?.name} />
                          </div>
                        </Col>
                      )}
                    </Row>

                    {[1, 2].includes(state.globalRuleDetails.id) && (
                      <>
                        <hr style={{ backgroundColor: '#9ea0a1' }} />
                        <Row>
                          <Col md={12}>
                            <div
                              style={{
                                // position: 'relative',
                                // top: '20px',
                                marginBottom: '10px',
                              }}
                            >
                              <Label className="form-label">Is Repeat &nbsp;</Label>
                              <div className="form-switch d-inline-block ">
                                <Input
                                  className="form-check-input code-switcher"
                                  type="checkbox"
                                  id="isRepeat"
                                  name="isRepeat"
                                  checked={state?.isRepeat}
                                  onChange={inputChangeHandler}
                                />
                              </div>
                            </div>
                          </Col>

                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="repeatDetailUnit" className="form-label">
                                Repeat Details in
                                <span className="lbl_mandatory">*</span>
                              </Label>
                              <select
                                id="repeatDetailUnit"
                                name="repeatDetailUnit"
                                className="form-select"
                                onChange={inputChangeHandler}
                                value={state?.repeatDetail?.unit}
                                disabled={!isRepeatDetailsDisabled}
                              >
                                <option value="">Choose...</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Yearly">Yearly</option>
                              </select>
                            </div>
                          </Col>

                          <Col md={4}>
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="repeatDetailValue">
                                Repeat Details Value
                                <span className="lbl_mandatory">*</span>
                              </Label>
                              <Input
                                id="repeatDetailValue"
                                name="repeatDetailValue"
                                type="number"
                                onWheel={e => e.target.blur()}
                                className="form-control"
                                onChange={inputChangeHandler}
                                value={state?.repeatDetail?.value}
                                disabled={true}
                                // disabled={!isRepeatDetailsDisabled}
                                // min={6}
                                // max={20}
                              />
                            </div>
                          </Col>
                        </Row>
                        <hr style={{ backgroundColor: '#9ea0a1' }} />
                      </>
                    )}

                    {[1].includes(state.globalRuleDetails.id) && (
                      <Col md={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="maxCoin">
                            Max Coin <span className="lbl_mandatory">*</span>
                          </Label>
                          <Input
                            id="maxCoin"
                            name="maxCoin"
                            type="text"
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={state?.maxCoin}
                            disabled={state?.globalRuleDetails?.id !== 1}
                            // min={6}
                            // max={20}
                          />
                        </div>
                      </Col>
                    )}

                    {[1, 2].includes(state.globalRuleDetails.id) && (
                      <Col md={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="conversionRate">
                            Conversion Rate
                            <span className="lbl_mandatory">*</span>
                          </Label>
                          <Input
                            id="conversionRate"
                            name="conversionRate"
                            type="text"
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={state?.conversionRate}
                          />
                        </div>
                      </Col>
                    )}

                    {[1].includes(state.globalRuleDetails.id) && (
                      <Col md={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="expenditureAmount">
                            Expenditure Amount
                            <span className="lbl_mandatory">*</span>
                          </Label>
                          <Input
                            id="expenditureAmount"
                            name="expenditureAmount"
                            type="text"
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={state?.expenditureAmount}
                            disabled={state?.globalRuleDetails?.id !== 1}
                          />
                        </div>
                      </Col>
                    )}

                    {[1, 2].includes(state.globalRuleDetails.id) && (
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="coinExpiry" className="form-label">
                            Coin Expiry <span className="lbl_mandatory">*</span>
                            <span to="#" className="fw-medium" id="length_tooltip">
                              <i className="ri-information-line"></i>
                            </span>
                            <UncontrolledTooltip placement="top" target="length_tooltip">
                              Coin expiry must be in months
                            </UncontrolledTooltip>
                          </Label>
                          <Input
                            id="coinExpiry"
                            name="coinExpiry"
                            type="text"
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={state?.coinExpiry}
                          />
                          {/* <div className="area_expandable">
                          <Flatpickr
                            className="form-control"
                            name="coinExpiry"
                            id="coinExpiry"
                            value={state?.coinExpiry}
                            onChange={(date) =>
                              datePickHandler(`coinExpiry`, date)
                            }
                            options={{
                              minDate: 'today',
                              defaultMinute: '59',
                              defaultHour: '23',
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i',
                            }}
                          />
                        </div> */}
                        </div>
                      </Col>
                    )}

                    {[1, 2].includes(state.globalRuleDetails.id) && (
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="ruleExpiry" className="form-label">
                            Rule Expiry <span className="lbl_mandatory">*</span>
                          </Label>
                          <div className="area_expandable">
                            <Flatpickr
                              className="form-control"
                              name="ruleExpiry"
                              id="ruleExpiry"
                              value={state?.ruleExpiryDate}
                              onChange={date => datePickHandler(`ruleExpiry`, date)}
                              options={{
                                minDate: 'today',
                                defaultMinute: '59',
                                defaultHour: '23',
                                enableTime: true,
                                dateFormat: 'Y-m-d H:i',
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    )}

                    {[1, 2].includes(state.globalRuleDetails.id) && (
                      <Col md={12}>
                        <div className="mb-3">
                          <Label className="form-label">Is Active &nbsp;</Label>
                          <div className="form-switch d-inline-block ">
                            <Input
                              className="form-check-input code-switcher"
                              type="checkbox"
                              id="isActive"
                              name="isActive"
                              checked={state?.isActive}
                              onChange={inputChangeHandler}
                            />
                          </div>
                        </div>
                      </Col>
                    )}
                  </Row>
                </Form>
              </div>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md={6} className="p-0">
                  <div className="start"></div>
                </Col>

                <Col md={6}>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary mx-3 overlay-disable" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
                      Create
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>

            <div
              style={{
                width: '100px',
                position: 'relative',
                zIndex: '999999999999',
              }}
            >
              <ReactTooltip />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ExpenditureForm;
