import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Flatpickr from 'react-flatpickr';
import Select from 'react-select';

import { Button, Modal, ModalBody, ModalHeader, Col, Row, Label, Input } from 'reactstrap';
import { POST_COUPONS, UPDATE_COUPON } from '../../../../../../store/application/actionType';
import { isANumber } from '../../../../../Helpers/Helpers';
import { Error } from '../../../../../Common';

const invoicesStatus = [
  { label: 'Paid', value: 'Paid' },
  { label: 'Pending', value: 'Pending' },
];

const couponTypes = [
  { label: 'Code', value: '1' },
  { label: 'Pin', value: '2' },
  { label: 'Url', value: '3' },
];

const CouponUpdateModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    lotPrice: '',
    perCouponPrice: '',
    status: null,
    expiryDate: '',
    invoice: {
      number: '',
      date: '',
      status: null,
    },
  };
  const [state, setState] = useState(initialObj);
  const [isValidationShow, setIsValidationShow] = useState(false);

  useEffect(() => {
    if (data) {
      setState({
        lotPrice: `${data?.lotPrice}`,
        perCouponPrice: `${data?.perCouponPrice}`,
        expiryDate: new Date(data?.expiryDate).toISOString(),
        invoice: {
          number: data?.invoice?.number,
          date: new Date(data?.invoice?.date).toISOString(),
          status: invoicesStatus?.filter(status => status?.value === data?.invoice?.status),
        },
      });
    }
  }, [data]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    let lotPrice = '';
    let couponPrice = '';

    switch (name) {
      case 'lotPrice':
        if (value?.length === 0) {
          lotPrice = ``;
        } else {
          lotPrice = isANumber(value) ? value : state.lotPrice;
        }
        updatedState = {
          ...state,
          lotPrice: lotPrice,
        };
        break;

      case 'perCouponPrice':
        if (value?.length === 0) {
          couponPrice = ``;
        } else {
          couponPrice = isANumber(value) ? value : state.perCouponPrice;
        }
        updatedState = {
          ...state,
          perCouponPrice: couponPrice,
        };
        break;

      case 'number':
        updatedState = {
          ...state,
          invoice: {
            ...state?.invoice,
            number: value,
          },
        };
        break;

      default:
      // do nothing
    }
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...state };

    if (label === `invoice-status`) {
      updatedState = {
        ...state,
        invoice: {
          ...state?.invoice,
          status: data,
        },
      };
    } else if (label === `couponType`) {
      updatedState = {
        ...state,
        couponType: data,
      };
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (
      data?.lotPrice?.trim() !== `` &&
      data?.perCouponPrice?.trim() !== `` &&
      data?.expiryDate.trim() !== `` &&
      data?.invoice?.status !== null &&
      data?.invoice?.date !== `` &&
      data?.invoice?.number?.trim() !== ``
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'expiryDate':
        updatedState = {
          ...state,
          expiryDate: new Date(date).toISOString(),
        };
        break;

      case 'date':
        updatedState = {
          ...state,
          invoice: {
            ...state?.invoice,
            date: new Date(date).toISOString(),
          },
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      dispatch({
        type: UPDATE_COUPON,
        payload: {
          data: {
            ...state,
            couponInfoId: data?.id,
            lotPrice: parseFloat(state?.lotPrice),
            perCouponPrice: parseFloat(state?.perCouponPrice),
            invoice: {
              ...state?.invoice,
              status: state?.invoice?.status?.value,
            },
          },
        },
      });
      onCloseHandler(false);
    }
  };

  return (
    <Modal
      isOpen={true}
      toggle={() => {
        onCloseHandler(false);
      }}
      id="update-coupon"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Update Coupon
        </h5>
        <Button
          type="button"
          onClick={() => {
            onCloseHandler(false);
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log(state)}>get state</button> */}
        <form>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <Label className="form-label">
                  Lot Price
                  <span className="lbl_mandatory"> *</span>
                </Label>
                <Input id="lotPrice" name="lotPrice" type="text" className="form-control" onChange={inputChangeHandler} value={state?.lotPrice} />
                {isValidationShow && state?.lotPrice?.trim() === `` && <Error text="Kindly fill lot price" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label className="form-label">
                  Per Coupon Price
                  <span className="lbl_mandatory"> *</span>
                </Label>
                <Input
                  id="perCouponPrice"
                  name="perCouponPrice"
                  type="number"
                  onWheel={e => e.target.blur()}
                  className="form-control"
                  onChange={inputChangeHandler}
                  value={state?.perCouponPrice}
                />
                {isValidationShow && state?.perCouponPrice?.trim() === `` && <Error text="Kindly fill coupon price" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label className="form-label">
                  Invoice Number
                  <span className="lbl_mandatory"> *</span>
                </Label>
                <Input id="number" name="number" type="text" className="form-control" onChange={inputChangeHandler} value={state?.invoice?.number} />
                {isValidationShow && state?.invoice?.number?.trim() === '' && <Error text="Kindly enter invoice number" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="date" className="form-label">
                    Invoice Date <span className="lbl_mandatory">* </span>
                    <span className="icon_calendar">
                      <i className="las la-calendar"></i>
                    </span>
                  </Label>
                  <div className="area_expandable">
                    <Flatpickr
                      className="form-control"
                      name="date"
                      id="date"
                      value={state?.invoice?.date}
                      onChange={date => datePickHandler(`date`, date)}
                      options={{
                        // minDate: 'today',
                        defaultMinute: '59',
                        defaultHour: '23',
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',
                      }}
                    />
                    {isValidationShow && state?.invoice?.date === '' && <Error text="Kindly enter date" />}
                  </div>
                </div>
              </div>
            </Col>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="status" className="form-label">
                    Invoice Status <span className="lbl_mandatory">*</span>
                  </Label>
                  <Select
                    id="invoice-status"
                    name="invoice-status"
                    isMulti={false}
                    options={invoicesStatus}
                    value={state?.invoice?.status}
                    onChange={data => inputSelectHandler(`invoice-status`, data)}
                    style={{
                      border: '4px solid #ced4da',
                      bordeRadius: '0.25rem',
                    }}
                  />
                  {isValidationShow && state?.invoice?.status === null && <Error text="Kindly select invoice status" />}
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div className="mb-3">
                  <Label htmlFor="expiryDate" className="form-label">
                    Expiry Date <span className="lbl_mandatory">*</span>
                  </Label>
                  <div className="area_expandable">
                    <Flatpickr
                      className="form-control"
                      name="expiryDate"
                      id="expiryDate"
                      value={state?.expiryDate}
                      onChange={date => datePickHandler(`expiryDate`, date)}
                      options={{
                        minDate: 'today',
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',
                      }}
                    />
                    {isValidationShow && state?.expiryDate === '' && <Error text="Kindly enter expiry date" />}
                  </div>
                </div>
              </Col>
            </div>
          </Row>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default CouponUpdateModal;
