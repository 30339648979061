import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';

import { Button, Modal, ModalBody, ModalHeader, Col, Label, Input, Row } from 'reactstrap';

import { UPDATE_EVENT_RULE } from '../../../../../../store/application/actionType';
import { Constant } from '../../../../../Helpers/constant';

const UpdateEventRule = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */

  const [state, setState] = useState(data);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [modalData, setModalData] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */

  useEffect(() => {
    setState({
      ...state,
    });

    validationCheck(data);
  }, [data]);

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const payload = {
      request: state,
    };

    dispatch({
      type: UPDATE_EVENT_RULE,
      payload: {
        urlParam: {
          objectId: state.objectId,
        },
        data: state,
      },
    });

    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'coinAmount':
        updatedState = {
          ...state,
          coinAmount: parseInt(value),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'isActive':
        updatedState = {
          ...state,
          isActive: !state.isActive,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'ruleExpiry':
        updatedState = {
          ...state,
          ruleExpiryDate: new Date(date).toISOString(),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'coinExpiry':
        updatedState = {
          ...state,
          coinExpiry: new Date(date).toISOString(),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data.maxCoin !== `` && data.isActive !== ``) {
      setIsSubmitBtnDisabled(false);
    } else {
      setIsSubmitBtnDisabled(true);
    }
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <>
      <Modal
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1();
        }}
        id="exampleModal"
      >
        <div className="modal-header p-3 bg-soft-secondary">
          <h5 className="modal-title" id="createboardModalLabel">
            Update Expenditure Rule
          </h5>
          <Button type="button" onClick={() => tog_varying1()} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close"></Button>
        </div>

        <ModalBody>
          {/* <button onClick={() => console.log({ state })}>Get state</button> */}
          <form>
            <Row>
              <div className="mb-3">
                <Col md={12}>
                  <Label className="form-label">Is Active &nbsp;</Label>
                  <div className="form-switch d-inline-block ">
                    <Input
                      className="form-check-input code-switcher"
                      type="checkbox"
                      id="isActive"
                      name="isActive"
                      checked={state?.isActive}
                      onChange={inputChangeHandler}
                    />
                  </div>
                </Col>
              </div>

              <div className="mb-3">
                <Col md={12}>
                  <div>
                    <Label htmlFor="name" className="form-label">
                      Coin Amount <span className="lbl_mandatory">*</span>
                    </Label>
                    <Input
                      id="coinAmount"
                      name="coinAmount"
                      type="number"
                      onWheel={e => e.target.blur()}
                      className="form-control"
                      onChange={inputChangeHandler}
                      value={state?.coinAmount}
                      min={6}
                      max={20}
                    />
                  </div>
                </Col>
              </div>

              {/* <div className="mb-3">
                <Col md={12}>
                  <div>
                    <Label htmlFor="name" className="form-label">
                      Coin Expiry <span className="lbl_mandatory">*</span>
                    </Label>
                    <Flatpickr
                      className="form-control"
                      name="coinExpiry"
                      id="coinExpiry"
                      value={state?.coinExpiry}
                      onChange={(date) => datePickHandler(`coinExpiry`, date)}
                      options={{
                        minDate: 'today',
                        defaultMinute: '59',
                        defaultHour: '23',
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',
                      }}
                    />
                  </div>
                </Col>
              </div>

              <div className="mb-3">
                <Col md={12}>
                  <div>
                    <Label htmlFor="name" className="form-label">
                      Rule Expiry <span className="lbl_mandatory">*</span>
                    </Label>
                    <Flatpickr
                      className="form-control"
                      name="ruleExpiry"
                      id="ruleExpiry"
                      value={state?.ruleExpiryDate}
                      onChange={(date) => datePickHandler(`ruleExpiry`, date)}
                      options={{
                        minDate: 'today',
                        defaultMinute: '59',
                        defaultHour: '23',
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',
                      }}
                    />
                  </div>
                </Col>
              </div> */}
            </Row>
          </form>
        </ModalBody>
        <div className="modal-footer d-flex justify-content-center">
          <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
            Update
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default UpdateEventRule;
