import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../Containers/Config/index.js';
import { CREATE_BINS, CREATE_BINS_SUCCESS, CREATE_BINS_ERROR } from '../../actionType.js';
import apiCall from '../../apiCall.js';
import { Constant } from '../../../../Containers/Helpers/constant.js';
import cogoToast from 'cogo-toast';

function* createBins({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/global-bins`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload?.urlParams,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      cogoToast.success(`Bins created successfully`);
      yield put({
        type: CREATE_BINS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: CREATE_BINS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: CREATE_BINS_ERROR, payload: error });
  }
}

export function* watchCreateBins() {
  yield takeEvery(CREATE_BINS, createBins);
}

function* createBinsSaga() {
  yield all([fork(watchCreateBins)]);
}

export default createBinsSaga;
