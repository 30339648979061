import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { Container } from 'reactstrap';
import Filter from './Components/Filters/Filter';
import Table from './Components/Tables/Table';
import { BreadCrumb, Loader } from '../../../../Common';
import { Constant } from '../../../../Helpers/constant';

import {
  VENDOR_DOWNLOAD_DUMP_EMPTY,
  VENDOR_BOOKINGS_DATA,
  VENDOR_BOOKINGS_DATA_EMPTY,
  VENDOR_DOWNLOAD_DUMP,
} from '../../../../../store/application/actionType';
import html2pdf from 'html2pdf.js';
import Template from './Components/Templates';
import cogoToast from 'cogo-toast';

const VendorDiningBooking = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [projectId, setProjectId] = useState(Constant?.CURRENT_SELECTED_PROGRAM?.id);
  const [bookingTypeId, setBookingTypeId] = useState(Constant?.BOOKING_TYPES?.thriweDining?.objectId);

  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);
  const [isDetailsPage, setIsDetailsPage] = useState(false);
  const [currentSelectedBookingDetails, setCurrentSelectedBookingDetails] = useState([]);

  const [loading, setLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [isDownloadPdf, setIsDownloadPdf] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Bookings
  const { bookings, bookingsLoading } = useSelector((state: any) => ({
    bookings: state?.GetVendorBookings?.data,
    bookingsLoading: state?.GetVendorBookings?.loading,
  }));

  // Click Download Dump
  const { downloadDump, downloadDumpLoading } = useSelector((state: any) => ({
    downloadDump: state?.VendorDownloadDump?.data,
    downloadDumpLoading: state?.VendorDownloadDump?.loading,
  }));

  const authUserJson = localStorage.getItem('authUser');
  const authUser = authUserJson ? JSON.parse(authUserJson) : null;

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    return () => {
      dispatch({
        type: VENDOR_BOOKINGS_DATA_EMPTY,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (authUser && authUser?.data) {
      const { facilityId } = authUser.data;
      if (facilityId) {
        dispatch({
          type: VENDOR_BOOKINGS_DATA,
          payload: {
            urlParam: {
              bookingTypeId,
              pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
              sortOn: 'updatedAt',
              facilityId: facilityId,
            },
            data: null,
          },
        });
      } else {
        cogoToast.error('Facility Id not found');
        history.push('/vendor/dashboard');
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (downloadDump !== null) {
      dispatch({
        type: VENDOR_DOWNLOAD_DUMP_EMPTY,
      });
    }
  }, [downloadDump]);

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = filters => {
    if (filters?.filter && authUser && authUser.data) {
      const { facilityId } = authUser.data;
      setCurrentPageNumber(0);
      setCurrentPageIndex(0);
      setCurrentlyAppliedFilters(filters);
      dispatch({
        type: VENDOR_BOOKINGS_DATA,
        payload: {
          urlParam: {
            bookingTypeId,
            pageIndex: 0,
            sortOn: filters?.sortOn ?? 'updatedAt',
            facilityId: facilityId,
          },
          data: filters,
        },
      });
    } else {
      setCurrentlyAppliedFilters(filters);
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    if (authUser && authUser.data) {
      const { facilityId } = authUser.data;
      setCurrentPageNumber(pageNumber);
      setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);
      dispatch({
        type: VENDOR_BOOKINGS_DATA,
        payload: {
          urlParam: {
            bookingTypeId,
            pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
            sortOn: currentlyAppliedFilters?.sortOn ?? 'updatedAt',
            facilityId: facilityId,
          },
          data: currentlyAppliedFilters,
        },
      });
    }
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = async params => {
    setCurrentSelectedBookingDetails(params?.bookingDetails);
    if (params?.actionType === `DOWNLOAD_PDF`) {
      setIsDownloadPdf(true);
      const options = {
        margin: 10,
        filename: `${params?.bookingDetails?.bookingId || 'dummy'}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };
      const input = document.createElement('div');
      const templates = Template(params?.bookingDetails);
      input.innerHTML = templates;
      const pdf = await html2pdf().set(options).from(input).toPdf().save();
      setIsDownloadPdf(false);
    }
  };

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/vendor/dashboard`);
    } else if (title === `Dining Bookings`) {
      setIsDetailsPage(false);
    }
  };

  /* ---------------------------- DOWNLOAD DUMP HANDLER ---------------------------- */
  const downloadDumpHandler = () => {
    if (authUser && authUser.data) {
      const { facilityId } = authUser.data;
      dispatch({
        type: VENDOR_DOWNLOAD_DUMP,
        payload: {
          urlParam: {
            bookingTypeId,
            downloadType: 1,
            facilityId: facilityId,
          },
          data: currentlyAppliedFilters,
        },
      });
    }
  };

  const refreshHandler = () => {
    if (authUser && authUser.data) {
      const { facilityId } = authUser.data;
      dispatch({
        type: VENDOR_BOOKINGS_DATA,
        payload: {
          urlParam: {
            bookingTypeId,
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            sortOn: 'updatedAt',
            facilityId: facilityId,
          },
          data: currentlyAppliedFilters,
        },
      });
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (bookingsLoading || downloadDumpLoading || isDownloadPdf) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [bookingsLoading, downloadDumpLoading, isDownloadPdf]);

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ----------------------------  UI'S ---------------------------- */}
          {!isDetailsPage && (
            <>
              <BreadCrumb title="Dining Bookings" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Dining Bookings`]} />
              <Filter filterHandler={filterSubmitHandler} appliedFilters={currentlyAppliedFilters} />
              <Table
                data={bookings?.results}
                totalData={bookings?.count}
                pageNumber={currentPageNumber}
                pageHandler={paginationHandler}
                actionHandler={tableActionHandler}
                dumpDownload={downloadDumpHandler}
                appliedFilters={currentlyAppliedFilters}
                refreshHandler={refreshHandler}
              />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default VendorDiningBooking;
