import React, { useEffect, useRef, useState } from 'react';
import { BreadCrumb } from '../../../../../../Common';
import { Button, ButtonGroup, Card, CardBody, CardFooter, Col, Container } from 'reactstrap';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import L, { Icon, point } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';
import 'leaflet-geosearch/dist/geosearch.css';
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import ServicableNonServicableCard from '../Card/ServicableNonServicableCard';

const ServicableArea = ({ polygons, setPolygons, setPoint, setUserDrawItems, submitHandler, point, userDrawItems }) => {
  const mapRef = useRef(null);
  const drawnItemsRef: any = useRef();
  const [selectedButton, setSelectedButton] = useState(null);

  useEffect(() => {
    if (!mapRef.current) return;
    const map = L.map(mapRef.current).setView(point.length > 0 ? point : [20.5937, 78.9629], point.length > 0 ? 10 : 5);
    L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);
    drawnItemsRef.current = new L.FeatureGroup().addTo(map);
    const drawControl = new L.Control.Draw({
      draw: {
        polygon: selectedButton ? true : false,
        polyline: false,
        circle: false,
        rectangle: false,
        marker: false,
        circlemarker: false,
      },
      edit: {
        featureGroup: drawnItemsRef.current,
      },
    });

    map.addControl(drawControl);

    map.on(L.Draw.Event.CREATED, async e => {
      const { layerType, layer } = e;
      if (layerType === 'polygon') {
        const leafletId = drawnItemsRef.current.getLayerId(layer);
        const polygonCoordinates = layer.getLatLngs()[0].map(point => [point.lat, point.lng]);
        const polygonCoordinate = layer.getLatLngs()[0];
        const { lat, lng } = calculateCentroid(polygonCoordinate);
        const locationName = await getLocationName(lat, lng);
        setUserDrawItems(prevItems => [
          ...prevItems,
          {
            type: 'polygon',
            coordinates: polygonCoordinates,
            id: leafletId,
            area: selectedButton,
            locationName: locationName,
          },
        ]);
        layer.setStyle({
          color: `${selectedButton === 'Servicable' ? 'blue' : 'red'}`,
          fillColor: `${selectedButton === 'Servicable' ? 'blue' : 'transparent'}`,
        });
      }
      drawnItemsRef.current.addLayer(layer);
    });

    map.on(L.Draw.Event.EDITED, e => {
      const editedLayers = e.layers;
      const editedItems = [];
      editedLayers.eachLayer(async layer => {
        if (layer instanceof L.Polygon) {
          const leafletId = drawnItemsRef.current.getLayerId(layer);
          const polygonCoordinates = layer.getLatLngs()[0].map(point => [point.lat, point.lng]);
          const polygonCoordinate = layer.getLatLngs()[0];
          const { lat, lng } = calculateCentroid(polygonCoordinate);
          const locationName = await getLocationName(lat, lng);
          editedItems.push({
            type: 'polygon',
            coordinates: polygonCoordinates,
            id: leafletId,
            area: selectedButton,
            locationName: locationName,
          });
        }
        setUserDrawItems(prevItems => {
          return prevItems.map(item => {
            if (item.type === 'polygon') {
              const matchingEditedItem = editedItems.find(editedItem => editedItem.id === item.id);
              return matchingEditedItem || item;
            }
            return item;
          });
        });
      });
    });

    map.on(L.Draw.Event.DELETED, e => {
      const deleteLayers = e.layers;
      const deleteItems = [];
      deleteLayers.eachLayer(layer => {
        if (layer instanceof L.Polygon) {
          const leafletId = drawnItemsRef.current.getLayerId(layer);
          deleteItems.push(leafletId);
        }
      });
      setUserDrawItems(prevItems => {
        return prevItems.filter(item => {
          return !(item.type === 'polygon' && deleteItems.includes(item.id));
        });
      });
    });

    const provider = new OpenStreetMapProvider();
    const searchControl = new (GeoSearchControl as any)({
      provider: provider,
      showMarker: false,
      showPopup: false,
      retainZoomLevel: false,
      animateZoom: true,
      autoClose: true,
      searchLabel: 'Enter address, city, or coordinates',
      keepResult: true,
      style: 'bar',
    });

    map.addControl(searchControl);

    map.on('geosearch/showlocation', async e => {
      if (!selectedButton) {
        cogoToast.error('Kindly add area type before add a polygon');
        return;
      }
      setPoint([e.location.y, e.location.x]);
      await getPolygonData(e.location);
    });
    return () => {
      map.off();
      map.remove();
    };
  }, [selectedButton]);

  useEffect(() => {
    drawPolygons();
  }, [polygons, selectedButton]);

  const drawPolygons = async () => {
    if (drawnItemsRef.current && polygons?.length > 0) {
      drawnItemsRef.current.clearLayers();
      const updatedDrawItems = await Promise.all(
        polygons.map(async polygon => {
          const layer = L.polygon(polygon?.coordinates, {
            fillColor: polygon?.area === 'Servicable' ? 'blue' : 'transparent',
            color: polygon?.area === 'Servicable' ? 'blue' : 'red',
          }).addTo(drawnItemsRef.current);

          const leafletId = layer._leaflet_id;
          const polygonCoordinate = layer.getLatLngs()[0];
          const { lat, lng } = calculateCentroid(polygonCoordinate);
          const locationName = await getLocationName(lat, lng);
          return {
            type: 'polygon',
            coordinates: polygon?.coordinates,
            id: leafletId,
            area: polygon?.area,
            locationName: locationName,
          };
        }),
      );
      setUserDrawItems(updatedDrawItems);
    }
  };

  const calculateCentroid = polygonCoordinates => {
    let sumX = 0;
    let sumY = 0;
    for (let i = 0; i < polygonCoordinates.length; i++) {
      sumX += polygonCoordinates[i].lat;
      sumY += polygonCoordinates[i].lng;
    }
    const centroidLat = sumX / polygonCoordinates.length;
    const centroidLng = sumY / polygonCoordinates.length;
    return { lat: centroidLat, lng: centroidLng };
  };

  const swapElements = (array, index1, index2) => {
    if (index1 < 0 || index1 >= array?.length || index2 < 0 || index2 >= array?.length) {
      console.error('Invalid indices for swapping elements');
      return array;
    }

    for (let i = 0; i < array?.length; i++) {
      let temp = array[i][index1];
      array[i][index1] = array[i][index2];
      array[i][index2] = temp;
    }

    return array;
  };

  const getLocationName = async (latitude, longitude) => {
    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
      if (response.data && response.data.display_name) {
        return response.data.display_name;
      } else {
        return 'Unknown Location';
      }
    } catch (error) {
      console.error('Error fetching location name:', error);
      return 'Unknown Location';
    }
  };

  const getPolygonData = async location => {
    const data = await axios({
      url: `https://nominatim.openstreetmap.org/search.php?q=${location.label.replace(/\s+/g, '+').toLowerCase()}&polygon_geojson=1&format=json`,
      method: 'get',
    });

    if (data?.data?.length > 0) {
      const polygonData = [];
      data?.data?.forEach(value => {
        if (value?.geojson?.type === 'Polygon') {
          const swapedData = swapElements(value?.geojson?.coordinates[0], 0, 1);
          polygonData.push({
            type: 'Polygon',
            coordinates: swapedData,
            area: selectedButton,
          });
        } else if (value?.geojson?.type === 'MultiPolygon') {
          for (let j = 0; j < value?.geojson?.coordinates?.length; j++) {
            const swapedData = swapElements(value?.geojson?.coordinates[j][0], 0, 1);
            polygonData.push({
              type: 'Polygon',
              coordinates: swapedData,
              area: selectedButton,
            });
          }
        }
      });
      if (polygonData.length === 0) {
        cogoToast.error('No Polygons Found');
      }
      let updatedPolygon = [...polygons];
      updatedPolygon = updatedPolygon.concat(polygonData);
      setPolygons(updatedPolygon);
    }
  };

  const handleTypeArea = button => {
    setPolygons(userDrawItems);
    setSelectedButton(button);
  };

  return (
    <>
      <BreadCrumb title="Airport Transfer" navigationby={''} navigation={[`Dashboard`, `Airport Transfer`]} />
      <Container fluid className="position-relative">
        <Card>
          <CardBody>
            <div className="d-flex gap-3">
              <>
                <div id="map" style={{ height: '75vh', width: '70%' }} ref={mapRef}></div>
              </>
              <div
                style={{
                  height: '80vh',
                  width: '30%',
                  border: '1px solid black',
                  overflowY: 'auto',
                  padding: '10px',
                }}
              >
                <ServicableNonServicableCard handleClick={handleTypeArea} selectedButton={selectedButton} />
              </div>
            </div>
          </CardBody>
          <CardFooter>
            <Col md={12}>
              <div className="text-center">
                <button type="submit" className="btn btn-primary text-center" onClick={submitHandler}>
                  Next
                </button>
              </div>
            </Col>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
};

export default ServicableArea;
