import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, commsHeader, indianProjectsBaseURL, uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { GET_USER_PROFILE, GET_USER_PROFILE_SUCCESS, GET_USER_PROFILE_ERROR } from '../../actionType.js';
import apiCall from '../../apiCall.js';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getUserProfile({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/user/v1/me`,
      method: 'GET',
      headers: {
        'Project-Code': 'ADMIN_ACCESS',
        Authorization: payload?.headers?.token,
      },
      params: {
        ...payload?.urlParam,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_USER_PROFILE_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_USER_PROFILE_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_USER_PROFILE_ERROR, payload: error });
  }
}

export function* watchGetUserProfile() {
  yield takeEvery(GET_USER_PROFILE, getUserProfile);
}

function* getUserProfileSaga() {
  yield all([fork(watchGetUserProfile)]);
}

export default getUserProfileSaga;
