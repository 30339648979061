import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { customBaseURL, customHeaders, indianProjectsBaseURL, uaeProjectsBaseURL , clientCustomHeaders} from '../../../../Containers/Config/index.js';
import { GET_BENEFIT_ITEMS, GET_BENEFIT_ITEMS_SUCCESS, GET_BENEFIT_ITEMS_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getBenefitItems({ payload, endpoint=`/vendor/benefit-items`}) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}${endpoint}`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...clientCustomHeaders(),
        ...payload?.headers
      
      },
      params: {
        ...payload?.urlParam,
        pageSize: Constant?.TABLE_PAGE_SIZE,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_BENEFIT_ITEMS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_BENEFIT_ITEMS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_BENEFIT_ITEMS_ERROR, payload: error });
  }
}

export function* watchGetBenefitItems() {
  yield takeEvery(GET_BENEFIT_ITEMS, getBenefitItems);
}

function* getBenefitItemsSaga() {
  yield all([fork(watchGetBenefitItems)]);
}

export default getBenefitItemsSaga;
