import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from './../../../../Common';
import { Container } from 'reactstrap';
import GolfCourseSlots from './Components/Tables/GolfCourseSlots';
import DisabledModal from './Components/Modals/Disabled';
import {
  CREATE_GOLF_COURSE_SLOT_EMPTY,
  CREATE_GOLF_COURSE_UNAVAILABILITY_EMPTY,
  DELETE_GOLF_COURSE_FORMATS_BY_ID_EMPTY,
  DISABLE_GOLF_COURSE_UNAVAILABILITY_EMPTY,
  GET_COUPON_FACILITY,
  GET_GOLF_COURSE,
  GET_GOLF_COURSE_FORMATS,
  GET_GOLF_COURSE_FORMATS_BY_ID,
  GET_GOLF_COURSE_SUCCESS,
  GET_GOLF_COURSE_UNAVAILABLE,
} from '../../../../../store/application/actionType';
import { Constant } from '../../../../Helpers/constant';
import AddSlotsUnavailbility from './Components/Forms/AddSlotsUnavailability';

const SlotUnavailability = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [currentPageNumberSecondary, setCurrentPageNumberSecondary] = useState(0);
  const [currentPageIndexSecondary, setCurrentPageIndexSecondary] = useState(0);

  const [currentSelectedDetails, setCurrentSelectedDetails] = useState([]);

  const [currentConfirmationModalActionFor, setCurrentConfirmationModalActionFor] = useState(null);

  const [isAssignGolfCourseModalShow, setIsAssignGolfCourseModalShow] = useState(false);
  const [isDisabledModalShow, setIsDisabledModalShow] = useState(false);

  const [isViewCourseFormatShow, setIsViewCourseFormatShow] = useState(false);
  const [isMainPage, setIsMainPage] = useState(true);

  const [isAddNewSlotFormShow, setIsAddNewSlotFormShow] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Golf Course Unavailablility
  const { golfCourseUnavailability, golfCourseUnavailabilityLoading } = useSelector(state => ({
    golfCourseUnavailability: state?.GetGolfCourseUnavailable?.data,
    golfCourseUnavailabilityLoading: state?.GetGolfCourseUnavailable?.loading,
  }));

  // Create Golf Course Unavailablility
  const { createGolfCourseUnavailabilitySuccess, createGolfCourseUnavailabilityError, createGolfCourseUnavailabilityLoading } = useSelector(
    state => ({
      createGolfCourseUnavailabilitySuccess: state?.createGolfCourseUnavailability?.success,
      createGolfCourseUnavailabilityError: state?.createGolfCourseUnavailability?.error,
      createGolfCourseUnavailabilityLoading: state?.createGolfCourseUnavailability?.loading,
    }),
  );

  // Disabled Slot Unavailability
  const { disabledGolfCourseUnavailablility, disabledGolfCourseUnavailablilityLoading } = useSelector(state => ({
    disabledGolfCourseUnavailablility: state?.DisableGolfCourseUnavailability?.data,
    disabledGolfCourseUnavailablilityLoading: state?.DisableGolfCourseUnavailability?.loading,
  }));

  // Get Golf Courses
  const { golfCourses, golfCoursesLoading } = useSelector(state => ({
    golfCourses: state?.GetGolfCourses?.data,
    golfCoursesLoading: state?.GetGolfCourses?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (golfCourseUnavailability === null) {
      dispatch({
        type: GET_GOLF_COURSE_UNAVAILABLE,
        payload: {
          urlParam: {
            pageIndex: 0,
          },
        },
      });
    }

    if (golfCourses === null) {
      dispatch({
        type: GET_GOLF_COURSE,
      });
    }
  }, [golfCourseUnavailability, golfCourses]);

  useEffect(() => {
    if (createGolfCourseUnavailabilitySuccess) {
      dispatch({
        type: CREATE_GOLF_COURSE_UNAVAILABILITY_EMPTY,
      });

      setIsMainPage(true);
      setIsAddNewSlotFormShow(false);

      dispatch({
        type: GET_GOLF_COURSE_UNAVAILABLE,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          },
        },
      });
    }
    if (createGolfCourseUnavailabilityError) {
      dispatch({
        type: CREATE_GOLF_COURSE_UNAVAILABILITY_EMPTY,
      });
    }
  }, [createGolfCourseUnavailabilitySuccess, createGolfCourseUnavailabilityError]);

  useEffect(() => {
    if (disabledGolfCourseUnavailablility !== null) {
      dispatch({
        type: DISABLE_GOLF_COURSE_UNAVAILABILITY_EMPTY,
      });

      dispatch({
        type: GET_GOLF_COURSE_UNAVAILABLE,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          },
        },
      });
    }
  }, [disabledGolfCourseUnavailablility]);

  useEffect(() => {
    if (golfCourseUnavailability === null) {
      dispatch({
        type: GET_GOLF_COURSE_UNAVAILABLE,
        payload: {
          urlParam: {
            pageIndex: 0,
          },
        },
      });
    }
  }, []);

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedDetails(params?.details);
    setCurrentConfirmationModalActionFor(params?.actionType);

    if (params?.actionType === `DISABLE`) {
      setIsDisabledModalShow(true);
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);

    dispatch({
      type: GET_GOLF_COURSE_UNAVAILABLE,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (golfCourseUnavailabilityLoading || disabledGolfCourseUnavailablilityLoading || golfCoursesLoading || createGolfCourseUnavailabilityLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [golfCourseUnavailabilityLoading, disabledGolfCourseUnavailablilityLoading, golfCoursesLoading, createGolfCourseUnavailabilityLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Golf Admin`) {
      // do nothing
    } else if (title === `Slot Unavailability`) {
      setIsMainPage(true);
      setIsAddNewSlotFormShow(false);
    } else if (title === `Add Slot Unavailability`) {
      // do nothing
    }
  };

  const addNewUnavailableSlotsHandler = () => {
    setIsAddNewSlotFormShow(true);
    setIsMainPage(false);
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ----------------------------  MODAL'S ---------------------------- */}

          {isDisabledModalShow && <DisabledModal data={currentSelectedDetails} onCloseHandler={() => setIsDisabledModalShow(false)} />}

          {/*  ----------------------------  UI'S ---------------------------- */}
          {isMainPage && (
            <>
              <BreadCrumb title="Benefits" navigationby={breadcrumNavigationHandler} navigation={[`Golf Admin`, `Slot Unavailability`]} />
              <GolfCourseSlots
                data={golfCourseUnavailability?.results}
                totalData={golfCourseUnavailability?.count}
                pageNumber={currentPageNumber}
                pageHandler={paginationHandler}
                actionHandler={tableActionHandler}
                primaryBtnHandler={addNewUnavailableSlotsHandler}
              />
            </>
          )}

          {isAddNewSlotFormShow && (
            <>
              <BreadCrumb
                title="Add Slots Unavailabality"
                navigationby={breadcrumNavigationHandler}
                navigation={[`Golf Admin`, `Slot Unavailability`, `Add Slot Unavailability`]}
              />
              <AddSlotsUnavailbility />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default SlotUnavailability;
