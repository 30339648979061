import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Card, Col, Row } from 'reactstrap';
import { PreviewCardHeader } from '../../../../Common';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { GET_BOOKING_SUMMARY } from '../../../../../store/actions';
import { Constant } from '../../../../Helpers/constant';

let options = {
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '65%',
    },
  },

  xaxis: {
    categories: [''],
    axisTicks: {
      show: false,
      borderType: 'solid',
      color: '#78909C',
      height: 6,
      offsetX: 0,
      offsetY: 0,
    },
    title: {
      text: '',
      offsetX: 0,
      offsetY: -30,
      style: {
        color: '#78909C',
        fontSize: '12px',
        fontWeight: 400,
      },
    },
  },

  yaxis: {
    labels: {
      formatter: function (value) {
        return value;
      },
    },
    tickAmount: 4,
    min: 0,
  },

  stroke: {
    show: true,
    width: 5,
    colors: ['transparent'],
  },

  fill: {
    opacity: 1,
  },

  chart: {
    type: 'bar',
    height: 341,
    toolbar: {
      show: false,
    },
  },

  legend: {
    show: true,
    position: 'bottom',
    horizontalAlign: 'center',
    fontWeight: 500,
    offsetX: 0,
    offsetY: -14,
    itemMargin: {
      horizontal: 8,
      vertical: 0,
    },
    markers: {
      width: 10,
      height: 10,
    },
  },
  colors: ['#405189', '#0ab39c', '#f7b84b', '#f06548'],
};

const ENBDDashboard = () => {
  const dispatch = useDispatch();

  const [benefitWiseBookingsData, setBenefitWiseBookingsData] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { benefitWiseBookings } = useSelector(state => ({
    benefitWiseBookings: state?.GetBookingSummary?.data?.benefitWiseBookings,
  }));

  useEffect(() => {
    if (benefitWiseBookingsData === null) {
      dispatch({
        type: GET_BOOKING_SUMMARY,
        payload: {
          urlParam: {
            toDate: moment(new Date()).format('YYYY-MM-DD'),
            fromDate: moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (benefitWiseBookings) {
      setBenefitWiseBookingsData(
        benefitWiseBookings?.map((benefitDomain, index) => ({
          name: `${benefitDomain?.name}`,
          data: [benefitDomain?.bookingStatusWiseCount?.total ?? 0],
        })),
      );
    }
  }, [benefitWiseBookings]);

  return (
    <Row>
      <Col md={12}>
        <Col md={12}>
          <Card className="card-inner">
            <PreviewCardHeader title="Benefit domains wise total bookings data" />
            <ReactApexChart options={options} series={benefitWiseBookingsData} type="bar" height="341" className="apex-charts" />
          </Card>
        </Col>
      </Col>
    </Row>
  );
};

export default ENBDDashboard;
