import React, { useEffect, useRef, useState } from 'react';
import { Table } from 'reactstrap';
import { FaRegCheckCircle } from 'react-icons/fa';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Constant } from '../../../../../Helpers/constant';
import Loader from '../../../../../../Components/Common/Loader';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const BookingTableMobileScreen = ({ actionHandler, hasMoreBookingsData, data, totalData, pageNumber, pageHandler, bankName }) => {
  const [cancellationAllowance, setCancellationAllowance] = useState({});
  const { t } = useTranslation();
  const handleAction = (flag: string, data) => {
    actionHandler(flag, data);
  };

  useEffect(() => {
    if (data?.length > 0) {
      const updatedCancellationAllowance = {};
      data.forEach(booking => {
        // Check if the booking's creation time is valid
        if (booking?.createdAt) {
          const creationTime = new Date(booking.createdAt);
          const currentTime = new Date();
          const timeDifference = currentTime.getTime() - creationTime.getTime(); // Time difference in milliseconds

          // Allow cancellation if less than 10 minutes have passed since creation
          updatedCancellationAllowance[booking.bookingId] = timeDifference < 10 * 60 * 1000;
        }
      });
      setCancellationAllowance(updatedCancellationAllowance);
    }
  }, [data]);

  const fetchData = () => {
    pageHandler(pageNumber + 1);
  };

  return (
    <>
      <InfiniteScroll
        dataLength={data?.length ?? 0} // This is important to prevent infinite loop when reaching the last page
        next={fetchData}
        hasMore={hasMoreBookingsData} // Assuming you have totalData to check if there is more data to load
      >
        {data?.length > 0 &&
          data?.map((booking: any, index: number) => {
            return (
              <div
                className="table-responsive mt-3"
                key={index}
                style={{ border: '1px solid black', borderRadius: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}
              >
                <Table className="table-bordered align-middle table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">{t('S.No.')}</th>
                      <td>{index + 1}</td>
                    </tr>
                    <tr>
                      <th scope="row">{t('Booking ID')}</th>
                      <td>{booking?.bookingId}</td>
                    </tr>
                    <tr>
                      <th scope="row">{t('Name')}</th>
                      <td>{booking?.user?.name || 'NA'}</td>
                    </tr>
                    <tr>
                      <th scope="row">{t('Bank Name')}</th>
                      <td>{bankName}</td>
                    </tr>

                    <tr>
                      <th scope="row">{t('Car Number')}</th>
                      <td>{booking?.car?.number}</td>
                    </tr>

                    <tr>
                      <th scope="row">Date & Time</th>
                      <td>{booking?.createdAt ? moment(`${booking?.createdAt}`).format('DD-MM-YYYY, h:mm A') : `NA`}</td>
                    </tr>
                    <tr>
                      <th scope="row">Status</th>
                      <td>
                        {' '}
                        {booking?.vendor?.status === 'Completed' && <span className="booking_status break-word badge bg-completed">Accepted</span>}
                        {booking?.vendor?.status === 'Confirmed' && !cancellationAllowance[booking.bookingId] && (
                          <span className="booking_status break-word badge bg-completed">Accepted</span>
                        )}
                        {booking?.vendor?.status === 'Confirmed' && cancellationAllowance[booking.bookingId] && (
                          <span className="booking_status break-word badge bg-confirmed">Confirmed</span>
                        )}
                        {booking?.vendor?.status === 'Rejected' && <span className="booking_status break-word badge bg-cancelled">Cancelled</span>}
                      </td>
                    </tr>
                    {(booking?.vendor?.status === 'Completed' ||
                      (booking?.vendor?.status === 'Confirmed' && !cancellationAllowance[booking.bookingId])) && (
                      <tr>
                        <td colSpan={2}>
                          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                            <FaRegCheckCircle size={25} color="#3F9B77" />
                            <h4 className="text-center" style={{ color: '#3F9B77' }}>
                              Accepted
                            </h4>
                          </div>
                          {cancellationAllowance[booking.bookingId] && (
                            <p className="text-center fw-light fs-11">
                              Cancellation open for 10 minutes only{' '}
                              <span
                                className="fw-normal"
                                style={{ color: '#DB0011', textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => handleAction('Reject', booking)}
                              >
                                Cancel Now
                              </span>{' '}
                            </p>
                          )}
                        </td>
                      </tr>
                    )}

                    {booking?.vendor?.status === 'Confirmed' && cancellationAllowance[booking.bookingId] && (
                      <tr>
                        <td colSpan={2}>
                          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <h5
                              className="text-center"
                              style={{ color: '#3F9B77', textDecoration: 'underline', cursor: 'pointer' }}
                              onClick={() => handleAction('Accept', booking)}
                            >
                              Accept
                            </h5>
                            <h5
                              className="text-center"
                              style={{ color: '#3F9B77', textDecoration: 'underline', cursor: 'pointer' }}
                              onClick={() => handleAction('Reject', booking)}
                            >
                              Cancel
                            </h5>
                          </div>
                        </td>
                      </tr>
                    )}
                    {booking?.vendor?.status === 'Rejected' && (
                      <tr>
                        <td colSpan={2}>
                          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                            <IoMdCloseCircleOutline size={25} color="red" />
                            <h4 className="text-center" style={{ color: '#DB0011' }}>
                              Cancelled
                            </h4>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            );
          })}
      </InfiniteScroll>
    </>
  );
};

export default BookingTableMobileScreen;
