import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Button, Col, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import { indianProjectsBaseURL, customHeaders } from '../../../../../Config';
import { Constant } from '../../../../../Helpers/constant';
import { useDispatch, useSelector } from 'react-redux';
import { GET_CLIENT_REGION, GET_COUNTRY } from '../../../../../../store/actions';
import { Loader } from '../../../../../Common';

const initialState = {
  nickname: '',
  streetAddress: '',
  region: null,
  subregion: null,
  postalCode: '',
  countryId: 0,
};

const Address = ({ isOpen, toggle, onConfirm }) => {
  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);
  const [regionOptions, setRegionOptions] = useState([]);
  const [regionLoading, setRegionLoading] = useState(false);
  const [subRegionOptions, setSubRegionOptions] = useState([]);
  const [subRegionLoading, setSubRegionLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getRegions();
  }, [isOpen]);

  const getRegions = async () => {
    setRegionLoading(true);
    const regions = await axios({
      method: `GET`,
      url: `${indianProjectsBaseURL}/vendor/regions?pageSize=100`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        countryId: 231,
      },
    });
    if (regions?.data?.results?.length === 0) {
      cogoToast.error('sub regions not found');
      return;
    }
    setRegionOptions(
      regions?.data?.results?.map(subregion => {
        return {
          value: subregion?.name,
          label: subregion?.name,
          data: subregion,
        };
      }),
    );
    const dubaiRegion = regions?.data?.results?.find(region => region?.name === 'Dubai');
    inputSelectHandler(`region`, {
      value: dubaiRegion?.name,
      label: dubaiRegion?.name,
      data: dubaiRegion,
    });
    regionSelectHandler({
      value: dubaiRegion?.name,
      label: dubaiRegion?.name,
      data: dubaiRegion,
    });
    setRegionLoading(false);
  };

  const validateErrors = (flag, data) => {
    if (data?.nickname?.trim() !== '' && data?.streetAddress?.trim() !== '' && data?.region !== null && data?.subregion !== null) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  const submitHandler = () => {
    if (validateErrors(`submissionCheck`, state)) {
      onConfirm(state);
    }
  };

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };
    switch (name) {
      case 'nickname':
        updatedState = {
          ...state,
          nickname: value,
        };
        break;

      case 'streetAddress':
        updatedState = {
          ...state,
          streetAddress: value,
        };
        break;

      case 'postalCode':
        updatedState = {
          ...state,
          postalCode: value,
        };
        break;

      case 'country':
        updatedState = {
          ...state,
          countryId: parseInt(value),
        };
        break;
      default:
      // do nothing
    }
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = null;
    if (label === `region`) {
      updatedState = {
        ...state,
        region: data,
      };
      setState(updatedState);
      //   validateErrors(updatedState, `validationCheck`);
    } else if (label === `subregion`) {
      updatedState = {
        ...state,
        subregion: data,
      };
      setState(updatedState);
    }
    validateErrors(updatedState, `validationCheck`);
  };

  const regionSelectHandler = async data => {
    setSubRegionLoading(true);
    const subRegions = await axios({
      method: `GET`,
      url: `${indianProjectsBaseURL}/vendor/subRegions`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        regionId: String(data?.data?.stateId),
        pageSize: 100,
      },
    });
    if (subRegions?.data?.results?.length === 0) {
      cogoToast.error('sub regions not found');
      return;
    }
    setSubRegionOptions(
      subRegions?.data?.results?.map(subregion => {
        return {
          value: subregion?.name,
          label: subregion?.name,
          data: subregion,
        };
      }),
    );
    setSubRegionLoading(false);
  };

  useEffect(() => {
    if (subRegionLoading || regionLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [subRegionLoading, regionLoading]);

  return (
    <>
      {loading && <Loader />}
      <Modal isOpen={isOpen} toggle={toggle} id="adressModal">
        <div className="modal-header p-3 bg-soft-secondary">
          <h5 className="modal-title" id="createboardModalLabel">
            Address
          </h5>
          <Button type="button" onClick={toggle} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close"></Button>
        </div>
        <ModalBody>
          <form>
            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <div>
                    <Label htmlFor="nickname" className="form-label">
                      Nick Name
                      <span className="lbl_mandatory"> *</span>
                    </Label>
                    <Input className="form-control" name="nickname" value={state?.nickname} onChange={inputChangeHandler} />
                    {isValidationShow && state?.nickname?.trim() === '' && <div className="text-danger">Please enter name </div>}{' '}
                  </div>
                </div>
              </Col>

              <Col md={12}>
                <div className="mb-3">
                  <div>
                    <Label htmlFor="name" className="form-label">
                      Address
                      <span className="lbl_mandatory"> *</span>
                    </Label>
                    <Input className="form-control" name="streetAddress" value={state?.streetAddress} onChange={inputChangeHandler} />
                    {isValidationShow && state?.streetAddress?.trim() === '' && <div className="text-danger">Please enter address </div>}
                  </div>
                </div>
              </Col>

              <Col md={12}>
                <div className="mb-3">
                  <Label htmlFor="region" className="form-label">
                    Emirates
                    <span className="lbl_mandatory"> *</span>
                  </Label>
                  <Select
                    id="region"
                    name="region"
                    value={state.region}
                    options={regionOptions}
                    onChange={data => regionSelectHandler(data)}
                    isDisabled={true}
                  />
                  {isValidationShow && state?.region === null && <div className="text-danger">Please select region </div>}
                </div>
              </Col>

              <Col md={12}>
                <div className="mb-3">
                  <Label htmlFor="region" className="form-label">
                    City
                    <span className="lbl_mandatory"> *</span>
                  </Label>
                  <Select
                    id="subregion"
                    name="subregion"
                    value={state.subregion}
                    options={subRegionOptions}
                    onChange={data => inputSelectHandler(`subregion`, data)}
                    isDisabled={state.region === null}
                  />
                  {isValidationShow && state?.subregion === null && <div className="text-danger">Please select subregion </div>}
                </div>
              </Col>

              <Col md={12}>
                <div className="mb-3">
                  <div>
                    <Label htmlFor="nickname" className="form-label">
                      Pincode
                    </Label>
                    <Input className="form-control" name="postalCode" value={state?.postalCode} onChange={inputChangeHandler} />
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </ModalBody>
        <div className="modal-footer d-flex justify-content-center">
          <Button className="overlay-disable" color="primary" onClick={submitHandler}>
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Address;
