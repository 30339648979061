import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import { AsyncPaginate } from 'react-select-async-paginate';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';
import moment from 'moment';
import dummyExcel from './../../../../../../../assets/static-files/golfCourseFormat.xlsx';

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,
  Row,
  Input,
  CardFooter,
  UncontrolledTooltip,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { Constant } from '../../../../../../Helpers/constant';

import { CREATE_GOLF_COURSE_SLOT, SEND_MAIL, UPDATE_BOOKING, UPDATE_GOLF_BOOKING } from './../../../../../../../store/application/actionType';

const AssignGolfCourseModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    file: ``,
    gId: ``,
    formatName: ``,
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
  };
  const [state, setState] = useState(initialObj);

  const [golfCourses, setGolfCourses] = useState([]);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [modalData, setModalData] = useState(null);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  useEffect(() => {
    if (data.golfCourses?.results?.length > 0) {
      setGolfCourses(
        data?.golfCourses?.results?.map(facility => {
          return { value: facility?.id, label: facility?.name };
        }),
      );
    }
  }, []);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'formatName':
        updatedState = {
          ...state,
          formatName: value,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'formatExcel':
        updatedState = {
          ...state,
          file: value,
          fileData: e.target.files[0],
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'startDate':
        updatedState = {
          ...state,
          startDate: new Date(date).toISOString(),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'endDate':
        updatedState = {
          ...state,
          endDate: new Date(date).toISOString(),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  const onSelectInputChange = (data, label) => {
    let updatedState = null;

    switch (label) {
      case 'golfCourse':
        updatedState = {
          ...state,
          gId: data,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data?.file !== `` && data?.gId !== null && data?.startDate !== `` && data?.endDate !== ``) {
      setIsSubmitBtnDisabled(false);
    } else {
      setIsSubmitBtnDisabled(true);
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const formData = new FormData();
    formData.append('file', state.fileData);
    formData.append('gId', state?.gId?.value);
    formData.append('formatName', state.formatName);
    formData.append('startDate', moment(state?.startDate).format('Y-MM-DD'));
    formData.append('endDate', moment(state?.endDate).format('Y-MM-DD'));

    dispatch({
      type: CREATE_GOLF_COURSE_SLOT,
      payload: {
        data: formData,
      },
    });

    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const downloadSampleHandler = () => {
    window.open(dummyExcel, '_self');
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
      //   scrollable={true}
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Assign Format to Golf Course
        </h5>
        <Button type="button" onClick={tog_varying1} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close"></Button>
      </div>
      {/* <button onClick={() => console.log(state)}>get state</button> */}
      <ModalBody>
        <Row>
          <div className="mb-3">
            <Col md={12}>
              <Label htmlFor="golf-course" className="form-label">
                Golf Course
                <span className="lbl_mandatory"> *</span>
              </Label>
              <Select options={golfCourses} value={state?.gId} isMulti={false} onChange={e => onSelectInputChange(e, `golfCourse`)} />
            </Col>
          </div>

          <div className="mb-3">
            <Col md={12}>
              <Label htmlFor="formatName" className="form-label">
                Format Name
              </Label>
              <Input type="text" name="formatName" className="form-control" value={state.formatName} onChange={inputChangeHandler} />
            </Col>
          </div>

          <div className="mb-3">
            <Col md={12}>
              <Label htmlFor="formatExcel" className="form-label" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>
                  Format Excel <span className="lbl_mandatory"> *</span>
                </span>
                <span className="download-sample-excel" onClick={downloadSampleHandler}>
                  Download Sample Excel
                </span>
              </Label>
              <Input type="file" name="formatExcel" className="form-control" onChange={inputChangeHandler} />
            </Col>
          </div>

          <div className="mb-3">
            <Col md={12}>
              <Label htmlFor="start-date" className="form-label">
                From
                <span className="lbl_mandatory"> *</span>
              </Label>
              <div className="area_expandable">
                <Flatpickr
                  className="form-control"
                  name="startDate"
                  id="startDate"
                  value={state?.startDate}
                  onChange={date => datePickHandler(`startDate`, date)}
                  options={{
                    enableTime: false,
                    dateFormat: 'd M, Y',
                  }}
                />
              </div>
            </Col>
          </div>

          <div className="mb-3">
            <Col md={12}>
              <Label htmlFor="end-date" className="form-label">
                To
                <span className="lbl_mandatory"> *</span>
              </Label>
              <div className="area_expandable">
                <Flatpickr
                  className="form-control"
                  name="endDate"
                  id="endDate"
                  value={state?.endDate}
                  onChange={date => datePickHandler(`endDate`, date)}
                  options={{
                    enableTime: false,
                    dateFormat: 'd M, Y',
                  }}
                />
              </div>
            </Col>
          </div>
        </Row>
      </ModalBody>

      <div className="modal-footer d-flex justify-content-center">
        <Button
          className="overlay-disable"
          color="primary"
          onClick={submitHandler}
          // disabled={isSubmitBtnDisabled}
        >
          Assign
        </Button>
      </div>
    </Modal>
  );
};

export default AssignGolfCourseModal;
