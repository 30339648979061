import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import PricingViewerComponent from './PricingViewer';
import SearchFormComponent from './SearchForm';
import PDFReportDocument from './ReportPDFDocument';
import { GET_CATEGORY } from '../../../../../store/actions';
import { Constant } from '../../../../Helpers/constant';
import { useHistory } from 'react-router-dom';
import { BreadCrumb, Loader } from '../../../../Common';
import moment from 'moment';
import { hasPermission } from '../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../Helpers/Constants/permissions';
const PriceViewer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState();
  const [filteredInvoiceData, setFilteredInvoiceData] = useState();
  const [searchFormData, setSearchFormData] = useState();
  const [isPricingView, setPricingView] = useState();
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [costPriceUploadedDate, setCostPriceUploadedDate] = useState();
  const [sellPriceUploadedDate, setSellPriceUploadedDate] = useState();
  const sf = ['Dashboard', 'Pricing Viewer'];
  const [navigation, setNavigation] = useState([...sf]);
  const CURRENCY_NAME = 'INR';
  const [page, setPage] = useState(0);

  // const { categories, categoriesLoading } = useSelector(state => ({
  //   categories: state?.GetCategory?.data,
  //   categoriesLoading: state?.GetCategory?.loading,
  // }));

  const categories = {
    results: [{ name: 'Golf Play' }, { name: 'Golf Learn' }],
  };

  useEffect(() => {
    dispatch({
      type: GET_CATEGORY,
      payload: {
        urlParam: {
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  }, [dispatch]);

  const SearchFormRoute = category => {
    setSelectedCategory(category);
    setNavigation([...sf, category.name]);
    // setPricingView(false);
    setPage(1);
  };

  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push('/dashboard');
    } else if (title === `Pricing Viewer`) {
      // setSelectedCategory('');
      setNavigation([...sf]);
      // setPricingView(false);
      setPage(0);
      // do nothing
    } else if (title === `Golf Play`) {
      setNavigation([...sf, selectedCategory?.name]);
      // setSelectedCategory('');
      setPage(1);
      // do nothing
    }
  };

  return (
    <div className="page-content">
      <Container fluid className="position-relative">
        <BreadCrumb title="Pricing Viewer" navigationby={breadcrumNavigationHandler} navigation={navigation} />
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <div className="d-flex gap-3 align-items-center">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Price viewer {[1, 2].includes(page) && <>- {selectedCategory.name}</>} {page == 2 && <>- Output</>}
                  </h4>
                  {page == 2 && (
                    <h4 className="m-0">
                      <span className="badge bg-light text-dark">{filteredInvoiceData?.isPeakDay ? 'Peak Day' : 'Non Peak Day'}</span>
                    </h4>
                  )}
                </div>
                {page == 2 && hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_PRICING_GOLF_PLAY_GM_REPORT) && (
                  <div>
                    <PDFDownloadLink
                      document={
                        <PDFReportDocument
                          filteredInvoiceData={filteredInvoiceData}
                          CURRENCY_NAME={CURRENCY_NAME}
                          searchFormData={searchFormData}
                          costPriceUploadedDate={costPriceUploadedDate}
                          sellPriceUploadedDate={sellPriceUploadedDate}
                        />
                      }
                      fileName={`Price-Viewer.pdf`}
                      style={{ color: 'white' }}
                    >
                      <button type="button" className="btn btn-primary d-flex gap-2 align-items-center">
                        {' '}
                        <svg width={16} height={14} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M0.181641 9.29795C0.181641 7.72019 1.01922 6.33616 2.278 5.55833C2.62036 2.89042 4.92052 0.827759 7.70708 0.827759C10.4936 0.827759 12.7938 2.89042 13.1361 5.55833C14.3949 6.33616 15.2325 7.72019 15.2325 9.29795C15.2325 11.6165 13.4238 13.5166 11.1277 13.6896L4.28643 13.7025C1.99033 13.5166 0.181641 11.6165 0.181641 9.29795ZM11.024 12.3383C12.6203 12.218 13.8643 10.8945 13.8643 9.29795C13.8643 8.23206 13.309 7.26253 12.4119 6.70817L11.8607 6.36754L11.7788 5.72921C11.5201 3.71307 9.77918 2.18299 7.70708 2.18299C5.63496 2.18299 3.89406 3.71307 3.63534 5.72921L3.55342 6.36754L3.00223 6.70817C2.10512 7.26253 1.5499 8.23206 1.5499 9.29795C1.5499 10.8945 2.79388 12.218 4.39021 12.3383L4.50877 12.3472H10.9054L11.024 12.3383ZM8.39121 7.60391H10.4436L7.70708 10.992L4.97056 7.60391H7.02295V4.89345H8.39121V7.60391Z"
                            fill="white"
                          />
                        </svg>
                        <span>Download</span>
                      </button>
                    </PDFDownloadLink>
                  </div>
                )}
              </CardHeader>

              {page == 0 && (
                <CardBody>
                  <Row>
                    <h4 className="card-title mb-0 flex-grow-1 mb-2">Choose from the below category -</h4>
                  </Row>
                  <Row className="mt-3">
                    {categories?.results?.length > 0 &&
                      categories?.results?.map((category, index) => (
                        <Col xl={4} key={index}>
                          <Card
                            className="card explore-box card-animate rounded cursor-pointer user-select-none border border-light"
                            onClick={() => SearchFormRoute(category)}
                          >
                            <CardBody>
                              <div className="my-5">
                                <div className="text-center">
                                  <h4 className="fs-4 flex-grow-1 fw-medium text-muted text-truncate">{category?.name}</h4>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                  </Row>
                </CardBody>
              )}

              {page == 1 && (
                <SearchFormComponent
                  setPricingView={setPricingView}
                  selectedCategory={selectedCategory}
                  setFilteredInvoiceData={setFilteredInvoiceData}
                  setSearchFormData={setSearchFormData}
                  navigation={navigation}
                  setNavigation={setNavigation}
                  setPage={setPage}
                />
              )}
              {page == 2 && (
                <div>
                  <PricingViewerComponent
                    setFilteredInvoiceData={setFilteredInvoiceData}
                    filteredInvoiceData={filteredInvoiceData}
                    CURRENCY_NAME={CURRENCY_NAME}
                    searchFormData={searchFormData}
                    setCostPriceUploadedDate={setCostPriceUploadedDate}
                    setSellPriceUploadedDate={setSellPriceUploadedDate}
                  />
                </div>
              )}

              {/* {(isPricingView) && <PDFViewer>
                <PDFReportDocument filteredInvoiceData={filteredInvoiceData} CURRENCY_NAME={CURRENCY_NAME} costPriceUploadedDate={costPriceUploadedDate} sellPriceUploadedDate={sellPriceUploadedDate} />
              </PDFViewer>} */}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PriceViewer;
