import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Row, Col, Button, Modal, ModalBody, ModalHeader, Table, ModalFooter } from 'reactstrap';
import { RESEND_MAIL, UPDATE_BOOKING } from '../../../../../../store/application/actionType';
import { Constant } from '../../../../../Helpers/constant';
import moment from 'moment';

const MembershipModal = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [details, setModalDetails] = useState([]);

  const [modal_varying1, setmodal_varying1] = useState(true);

  useEffect(() => {
    if (props?.data !== null) {
      setModalDetails(props?.data[0]);
    }
  }, [props]);

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  /* ---------------------------- CANCEL HANDLER ---------------------------- */
  const cancelHandler = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal isOpen={modal_varying1} scrollable={true} size="md" centered modalClassName="zoomIn">
      <ModalHeader
        toggle={() => {
          tog_varying1();
        }}
        className="modal_header_color"
      >
        <span style={{ fontWeight: '500' }}>Membership ID : </span>
        <span style={{ fontWeight: 'normal' }}>{details?.membershipId}</span>
      </ModalHeader>
      <ModalBody>
        <form>
          <Row>
            <Col md={12} style={{ mixHeight: '500px' }}>
              <Table responsive borderless>
                <tbody>
                  <tr>
                    <th>Benefit Group:</th>
                    <td>{`${details?.benefitGroup?.name}`}</td>
                  </tr>
                  <tr>
                    <th>Created Date:</th>
                    <td>{moment(`${details?.createdAt}`).format(`DD-MM-YYYY, h:mm A`)}</td>
                  </tr>
                  <tr>
                    <th>Expiry Date:</th>
                    <td>{moment(`${details?.expiryDate}`).format(`DD-MM-YYYY, h:mm A`)}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </form>
        <div className="modal-footer d-flex justify-content-center">
          <Button className="btn btn-danger" color="danger" onClick={cancelHandler}>
            Close
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MembershipModal;
