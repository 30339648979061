import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { GET_SERVICES_IN_BENEFIT_ITEM, GET_SERVICES_IN_BENEFIT_ITEM_SUCCESS, GET_SERVICES_IN_BENEFIT_ITEM_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getServices({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/admin/services`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload?.urlParam,
        pageSize: `100`,
        // pageSize: Constant?.TABLE_PAGE_SIZE,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_SERVICES_IN_BENEFIT_ITEM_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_SERVICES_IN_BENEFIT_ITEM_ERROR,
        payload: response.response.data,
      });
    }
  } catch (error) {
    yield put({ type: GET_SERVICES_IN_BENEFIT_ITEM_ERROR, payload: error });
  }
}

export function* watchGetServicesInBenefitItem() {
  yield takeEvery(GET_SERVICES_IN_BENEFIT_ITEM, getServices);
}

function* getServicesInBenefitItemSaga() {
  yield all([fork(watchGetServicesInBenefitItem)]);
}

export default getServicesInBenefitItemSaga;
