import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import apiCall from '../../../apiCall';
import { baseUrl, customHeaders, uaeProjectsBaseURL } from './../../../../../Containers/Config/index';

import { CREATE_PARTNER, CREATE_PARTNER_SUCCESS, CREATE_PARTNER_ERROR } from './../../../actionType';
import { Constant } from '../../../../../Containers/Helpers/constant';
import cogoToast from 'cogo-toast';

function* createPartner({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/admin/partners`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      data: payload.data,
    });

    if (response.status === 200) {
      cogoToast.success(`${response?.data?.message}`);
      yield put({
        type: CREATE_PARTNER_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: CREATE_PARTNER_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: CREATE_PARTNER_ERROR, payload: error });
  }
}

export function* watchCreatePartner() {
  yield takeEvery(CREATE_PARTNER, createPartner);
}

function* createPartnerSaga() {
  yield all([fork(watchCreatePartner)]);
}

export default createPartnerSaga;
