import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, ModalBody } from 'reactstrap';

import { CONFIRM_BOOKING_V2, CONFIRM_BOOKING_V2_EMPTY, CANCEL_BOOKING_V2, CANCEL_BOOKING_V2_EMPTY } from '../../../../../../../../store/actions';

const UpdateBooking = ({ data, onCloseHandler, updateType }) => {
  const dispatch = useDispatch();

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { confirmBooking } = useSelector((state: any) => ({
    confirmBooking: state?.ConfirmBookingV2?.data,
  }));

  const { cancelBooking } = useSelector((state: any) => ({
    cancelBooking: state?.CancelBookingV2?.data,
  }));

  console.log(confirmBooking, cancelBooking);

  useEffect(() => {
    if (confirmBooking !== null || cancelBooking !== null) {
      onCloseHandler();

      if (updateType === 'Confirm') {
        return () => {
          dispatch({
            type: CONFIRM_BOOKING_V2_EMPTY,
          });
        };
      } else {
        return () => {
          dispatch({
            type: CANCEL_BOOKING_V2_EMPTY,
          });
        };
      }
    }
  }, [confirmBooking, cancelBooking, dispatch, onCloseHandler]);

  const handleYesClick = () => {
    if (updateType === 'Confirm') {
      dispatch({
        type: CONFIRM_BOOKING_V2,
        payload: {
          data: {
            user_id: data?.user_id,
            booking_id: data?.booking_id,
            service_id: data?.service_id,
            membership_id: data?.membership_id,
            benefit_group_config_id: String(data?.benefit_group_config_id),
            facility_id: data?.facility_id,
            date_of_service: data?.delayed?.DateOfService,
            time_of_service: data?.delayed?.TimeOfService,
          },
        },
      });
    } else {
      dispatch({
        type: CANCEL_BOOKING_V2,
        payload: {
          urlParams: {
            booking_id: data?.booking_id,
          },
        },
      });
    }
  };

  return (
    <Modal isOpen={true} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <div className="mt-4 pt-2 fs-15 mx-2 mx-sm-2">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-1 mb-0">Are you sure you want to {updateType} this booking?</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button type="button" className="btn w-sm btn-light" data-bs-dismiss="modal" onClick={onCloseHandler}>
            Close
          </button>
          <button type="button" className="btn w-sm btn-primary" onClick={handleYesClick}>
            Sure
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UpdateBooking;
