import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import { Row, Col, TabPane, Label, Input, CardBody, Card } from 'reactstrap';
import { Error, Loader, PreviewCardHeader } from '../../../../../../../Common';
import { CREATE_BENEFIT_GROUP_CATEGORY_EMPTY, GET_BENEFIT_GROUP_CATEORIES, GET_COUNTRY } from '../../../../../../../../store/application/actionType';
import CreateCategoryModal from '../Modals/CreateCategory';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Constant } from '../../../../../../../Helpers/constant';
import { isANumber } from '../../../../../../../Helpers/Helpers';

const BenefitGroups = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    name: '',
    displayName: '',
    benefitWorth: '',
    benefitWorthCurrency: '',
    benefitGroupCost: '',
    totalPacksLimit: '',
    totalPacksReleased: 0,
    shortDescription: '',
    longDescription: '',
    displayPicture: '',
    coverPicture: '',
    packReleaseType: '',
    priority: 0,
    membershipGenerationRestriction: false,
    dataUploadRestriction: false,
    isActive: true,
    groupCategory: '',
    validityTat: null,
    membership: {
      prefix: '',
      length: '',
      expiry: '',
    },
  };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [isValidationShow, setIsValidationShow] = useState(false);

  const [currencies, setCurrencies] = useState([]);
  const [allCategories, setAllCategories] = useState([]);

  const [isShowCreateCategory, setIsShowCreateCategory] = useState(false);

  // Countries
  const { countriesData, countriesDataLoading } = useSelector(state => ({
    countriesData: state?.Countries?.data,
    countriesDataLoading: state?.Countries?.loading,
  }));

  // Benefit Group Categories
  const { benefitGroupCategories, benefitGroupCategoriesLoading } = useSelector(state => ({
    benefitGroupCategories: state?.BenefitGroupCategories?.data,
    benefitGroupCategoriesLoading: state?.BenefitGroupCategories?.loading,
  }));

  // Create Benefit Group Category
  const { benefitGroupCategory, benefitGroupCategoryLoading } = useSelector(state => ({
    benefitGroupCategory: state?.CreateBenefitGroupCategory?.data,
    benefitGroupCategoryLoading: state?.CreateBenefitGroupCategory?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (benefitGroupCategory !== null) {
      dispatch({
        type: CREATE_BENEFIT_GROUP_CATEGORY_EMPTY,
      });

      dispatch({
        type: GET_BENEFIT_GROUP_CATEORIES,
      });
    } else {
      dispatch({
        type: GET_BENEFIT_GROUP_CATEORIES,
      });
    }
  }, [benefitGroupCategory]);

  useEffect(() => {
    if (benefitGroupCategories !== null) {
      setAllCategories(
        benefitGroupCategories?.results?.map(category => {
          return {
            value: `${category?.name}`,
            label: `${category?.name}`,
            data: category,
          };
        }),
      );
    }
  }, [benefitGroupCategories]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  useEffect(() => {
    if (countriesData === null) {
      dispatch({
        type: GET_COUNTRY,
      });
    } else {
      setCurrencies(
        countriesData?.map((country, index) => {
          return {
            value: country.currency,
            label: country.currency,
            data: country,
          };
        }),
      );
    }
  }, [countriesData]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    let tempBenefitWorth = '';
    let tempTotalPacksLimit = '';
    let tempBenefitGroupCost = '';
    let tempMembershipLength = '';

    // membershipLength

    switch (name) {
      case 'name':
        updatedState = {
          ...state,
          name: value,
          displayName: value,
        };
        break;

      case 'benefitWorth':
        if (value?.length === 0) {
          tempBenefitWorth = ``;
        } else {
          tempBenefitWorth = isANumber(value) ? value : state?.benefitWorth;
        }
        updatedState = {
          ...state,
          benefitWorth: tempBenefitWorth,
        };
        break;

      case 'benefitGroupCost':
        if (value?.length === 0) {
          tempBenefitGroupCost = ``;
        } else {
          tempBenefitGroupCost = isANumber(value) ? value : state?.benefitGroupCost;
        }
        updatedState = {
          ...state,
          benefitGroupCost: tempBenefitGroupCost,
        };
        break;

      case 'totalPacksLimit':
        if (value?.length === 0) {
          tempTotalPacksLimit = ``;
        } else {
          tempTotalPacksLimit = isANumber(value) ? value : state?.totalPacksLimit;
        }
        updatedState = {
          ...state,
          totalPacksLimit: tempTotalPacksLimit,
        };
        break;

      case 'membershipPrefix':
        updatedState = {
          ...state,
          membership: {
            ...state.membership,
            prefix: value,
          },
        };
        break;

      case 'membershipLength':
        if (value?.length === 0) {
          tempMembershipLength = ``;
        } else {
          tempMembershipLength = isANumber(value) ? value : state?.membership?.length;
        }
        updatedState = {
          ...state,
          membership: {
            ...state?.membership,
            length: tempMembershipLength,
          },
        };
        break;

      case 'membershipGenerationRestriction':
        updatedState = {
          ...state,
          membershipGenerationRestriction: !state.membershipGenerationRestriction,
        };
        break;

      case 'dataUploadRestriction':
        updatedState = {
          ...state,
          dataUploadRestriction: !state.dataUploadRestriction,
        };
        break;

      case 'isActive':
        updatedState = {
          ...state,
          isActive: !state.isActive,
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors('validationCheck', updatedState);
  };

  const inputSelectHandler = (label, data) => {
    let updatedState = null;

    if (label === `benefitWorthCurrency`) {
      updatedState = {
        ...state,
        benefitWorthCurrency: data,
      };
    } else if (label === `packReleaseType`) {
      updatedState = {
        ...state,
        packReleaseType: data,
      };
    } else if (label === `groupCategory`) {
      updatedState = {
        ...state,
        groupCategory: data,
      };
    } else if (label === `membershipExpiry`) {
      updatedState = {
        ...state,
        membership: {
          ...state.membership,
          expiry: data,
        },
      };
    } else if (label === `validityTatUnit`) {
      updatedState = {
        ...state,
        validityTat: {
          ...state.validityTat,
          unit: data,
        },
      };
    } else if (label === `validityTatValue`) {
      updatedState = {
        ...state,
        validityTat: {
          ...state.validityTat,
          value: data,
        },
      };
    }

    setState(updatedState);
    validateErrors('validationCheck', updatedState);
  };

  const ckEditorDataInput = (name, data) => {
    let updatedState = { ...state };

    switch (name) {
      case 'shortDescription':
        updatedState = {
          ...state,
          shortDescription: data,
        };
        break;

      case 'longDescription':
        updatedState = {
          ...state,
          longDescription: data,
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- VALIDATE ERROR ---------------------------- */
  const validateErrors = (flag, data = state) => {
    if (
      data?.name?.trim() !== '' &&
      data?.benefitWorthCurrency !== '' &&
      data?.packReleaseType !== '' &&
      data?.membership?.expiry !== '' &&
      data?.membership?.prefix !== '' &&
      data?.membership?.length !== ''
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(`submissionCheck`)) {
      const tempState = {
        ...state,
        benefitWorth: state?.benefitWorth === '' ? 0 : Number(state?.benefitWorth),
        totalPacksLimit: state?.totalPacksLimit === '' ? 0 : Number(state?.totalPacksLimit),
        benefitGroupCost: state?.benefitGroupCost === '' ? 0 : Number(state?.benefitGroupCost),
        // validityTat: {
        //   unit: state?.validityTat?.unit ? state?.validityTat?.unit?.value : '',
        //   value: state?.validityTat?.value ? state?.validityTat?.value?.value : '',
        // },

        membership: {
          ...state?.membership,
          length: state?.membership?.length === '' ? 0 : Number(state?.membership?.length),
        },
      };

      props.submitHandler(tempState);
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (countriesDataLoading || benefitGroupCategoryLoading || benefitGroupCategoriesLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [countriesDataLoading, benefitGroupCategoryLoading, benefitGroupCategoriesLoading]);

  function removeLeadingZeros(inputNumber) {
    // Convert the number to a string
    const numberAsString = inputNumber.toString();

    // Remove leading zeros using regular expression
    const numberWithoutLeadingZeros = numberAsString.replace(/^0+/, '');

    // Parse the string back to a number
    const result = parseInt(numberWithoutLeadingZeros, 10);

    return result;
  }

  return (
    <>
      {/* <a className="btn btn-primary" onClick={() => console.log(state)}>
        Get state
      </a> */}

      {loading && <Loader />}

      {isShowCreateCategory && <CreateCategoryModal onCloseHandler={() => setIsShowCreateCategory(false)} />}

      <TabPane tabId={1} id="pills-bill-info">
        <div>
          <Card>
            <PreviewCardHeader title="General Properties" />
            <CardBody>
              <Row>
                <Col sm={6}>
                  <div className="mb-3">
                    <Label htmlFor="name" className="form-label">
                      Benefit Group Name
                      <span className="lbl_mandatory"> *</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Enter benefit group name"
                      value={state?.name}
                      onChange={inputChangeHandler}
                      autoComplete="off"
                    />
                    {isValidationShow && state?.name?.trim() === '' && <Error text="Please enter benefit group name" />}
                  </div>
                </Col>

                <Col sm={6}>
                  <div className="mb-3">
                    <Label htmlFor="currency" className="form-label">
                      Currency <span className="lbl_mandatory">*</span>
                    </Label>
                    <Select
                      id="currency"
                      name="currency"
                      value={state?.benefitWorthCurrency}
                      options={currencies}
                      onChange={data => inputSelectHandler(`benefitWorthCurrency`, data)}
                    />
                    {isValidationShow && state?.benefitWorthCurrency === '' && <Error text="Please enter currency" />}
                  </div>
                </Col>

                <Col sm={6}>
                  <div className="mb-3">
                    <Label htmlFor="totalPacksLimit" className="form-label">
                      Total Packs Limit
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="totalPacksLimit"
                      name="totalPacksLimit"
                      placeholder="Enter total packs limit"
                      value={state?.totalPacksLimit}
                      onChange={inputChangeHandler}
                    />
                  </div>
                </Col>

                <Col sm={6}>
                  <div className="mb-3">
                    <Label htmlFor="benefitWorth" className="form-label">
                      Benefit Worth Value
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="benefitWorth"
                      name="benefitWorth"
                      placeholder="Enter benefit worth value"
                      value={state?.benefitWorth}
                      onChange={inputChangeHandler}
                    />
                  </div>
                </Col>

                <Col sm={6}>
                  <div className="mb-3">
                    <Label htmlFor="benefitGroupCost" className="form-label">
                      Benefit Group Cost
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="benefitGroupCost"
                      name="benefitGroupCost"
                      placeholder="Enter benefit group cost"
                      value={state?.benefitGroupCost}
                      onChange={inputChangeHandler}
                    />
                  </div>
                </Col>

                <Col sm={6}>
                  <div className="mb-3">
                    <Label htmlFor="packReleaseType" className="form-label">
                      Pack Release Type
                      <span className="lbl_mandatory"> *</span>
                    </Label>
                    <Select
                      id="packReleaseType"
                      name="packReleaseType"
                      value={state?.packReleaseType}
                      options={[
                        { value: 'Manual', label: 'Manual' },
                        { value: 'Automatic', label: 'Automatic' },
                      ]}
                      onChange={data => inputSelectHandler(`packReleaseType`, data)}
                    />
                    {isValidationShow && state?.packReleaseType === '' && <Error text="Please enter benefit pack release type" />}
                  </div>
                </Col>

                <Col sm={6}>
                  <div className="mb-3">
                    <Label htmlFor="membershipGenerationRestriction" className="form-label">
                      Membership Generation Restriction
                    </Label>
                    <div className="d-flex align-items-center">
                      <span className="text-muted">False &nbsp;</span>
                      <div className="form-switch d-inline-block ">
                        <Input
                          className="form-check-input code-switcher form-switch-md"
                          type="checkbox"
                          id="membershipGenerationRestriction"
                          name="membershipGenerationRestriction"
                          checked={state?.membershipGenerationRestriction}
                          onChange={inputChangeHandler}
                        />
                      </div>
                      <span> &nbsp;True</span>
                    </div>
                  </div>
                </Col>

                <Col sm={6}>
                  <div className="mb-3">
                    <Label htmlFor="validityTatUnit" className="form-label">
                      Benefit Pack Validity Duration Unit
                    </Label>
                    <Select
                      id="validityTatUnit"
                      name="validityTatUnit"
                      value={state?.validityTat?.unit}
                      options={[
                        { value: 'Days', label: 'Days' },
                        { value: 'Months', label: 'Months' },
                        { value: 'Year', label: 'Year' },
                      ]}
                      onChange={data => inputSelectHandler(`validityTatUnit`, data)}
                    />
                  </div>
                </Col>

                <Col sm={6}>
                  <div className="mb-3">
                    <Label htmlFor="validityTatValue" className="form-label">
                      Benefit Pack Validity Duration Value
                    </Label>
                    <Select
                      id="validityTatValue"
                      name="validityTatValue"
                      value={state?.validityTat?.value}
                      options={[
                        { value: 'Custom', label: 'Custom' },
                        { value: 'Lifetime', label: 'Lifetime' },
                      ]}
                      onChange={data => inputSelectHandler(`validityTatValue`, data)}
                    />
                  </div>
                </Col>

                <Col sm={6}>
                  <div className="mb-3">
                    <Label htmlFor="groupCategory" className="form-label d-flex justify-content-between">
                      <span>Benefit Group Category</span>
                      <span
                        style={{
                          fontSize: '10px',
                          color: '#405189',
                          cursor: 'pointer',
                        }}
                        onClick={() => setIsShowCreateCategory(true)}
                      >
                        Create New Category
                      </span>
                    </Label>

                    <Select
                      id="groupCategory"
                      name="groupCategory"
                      value={state?.groupCategory}
                      options={allCategories}
                      // options={[
                      //   { value: 'On Registration', label: 'On Registration' },
                      //   { value: 'Predefined', label: 'Predefined' },
                      // ]}
                      onChange={data => inputSelectHandler(`groupCategory`, data)}
                    />
                  </div>
                </Col>

                <Col sm={6}>
                  <div className="mb-3">
                    <Label htmlFor="membershipExpiry" className="form-label">
                      Expiry Logic
                      <span className="lbl_mandatory"> *</span>
                    </Label>
                    <Select
                      id="membershipExpiry"
                      name="membershipExpiry"
                      value={state?.membership?.expiry}
                      options={[
                        { value: 'On Registration', label: 'On Registration' },
                        { value: 'Predefined', label: 'Predefined' },
                      ]}
                      onChange={data => inputSelectHandler(`membershipExpiry`, data)}
                    />
                    {isValidationShow && state?.membership?.expiry === '' && <Error text="Please enter expiry logic" />}
                  </div>
                </Col>

                <Col sm={6}>
                  <div className="mb-3">
                    <Label htmlFor="isActive" className="form-label">
                      Is Active?
                    </Label>
                    <div className="d-flex align-items-center">
                      <span className="text-muted">False &nbsp;</span>
                      <div className="form-switch d-inline-block ">
                        <Input
                          className="form-check-input code-switcher form-switch-md"
                          type="checkbox"
                          id="isActive"
                          name="isActive"
                          checked={state?.isActive}
                          onChange={inputChangeHandler}
                        />
                      </div>
                      <span> &nbsp;True</span>
                    </div>
                  </div>
                </Col>

                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="defaultRedemptionProcess" className="form-label">
                      Short Description
                    </Label>
                    <div className="snow-editor" style={{ minHeight: 300 }}>
                      <CKEditor
                        editor={ClassicEditor}
                        data={state?.shortDescription || ''}
                        config={{
                          toolbar: {
                            items: Constant.CkEditorToolbar,
                          },
                        }}
                        onBlur={(event, editor) => {
                          const data = editor.getData();
                          ckEditorDataInput(`shortDescription`, data);
                        }}
                      />
                    </div>
                  </div>
                </Col>

                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="defaultRedemptionProcess" className="form-label">
                      Long Description
                    </Label>
                    <div className="snow-editor" style={{ minHeight: 300 }}>
                      <CKEditor
                        editor={ClassicEditor}
                        data={state?.longDescription || ''}
                        config={{
                          toolbar: {
                            items: Constant.CkEditorToolbar,
                          },
                        }}
                        onBlur={(event, editor) => {
                          const data = editor.getData();
                          ckEditorDataInput(`longDescription`, data);
                        }}
                      />
                    </div>
                  </div>
                </Col>

                <Col md={12}>
                  <Card>
                    <PreviewCardHeader title="Membership Properties" />
                    <CardBody>
                      <Row>
                        <Col sm={12}>
                          <div className="mb-3">
                            <Label htmlFor="membershipPrefix" className="form-label">
                              Membership Pre-fix
                              <span className="lbl_mandatory"> *</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="membershipPrefix"
                              name="membershipPrefix"
                              placeholder="Enter membership prefix"
                              value={state?.membership?.prefix}
                              onChange={inputChangeHandler}
                              autoComplete="off"
                            />
                            {isValidationShow && state?.membership?.prefix?.trim() === '' && <Error text="Please enter membership prefix" />}
                          </div>
                        </Col>

                        <Col sm={12}>
                          <div className="mb-3">
                            <Label htmlFor="membershipLength" className="form-label">
                              Dynamic Unique Code Length
                              <span className="lbl_mandatory"> *</span>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="membershipLength"
                              name="membershipLength"
                              placeholder="Enter dynamic unique code length"
                              value={state?.membership?.length}
                              onChange={inputChangeHandler}
                            />
                            {isValidationShow && state?.membership?.length === '' && <Error text="Please enter code length" />}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <div className="d-flex align-items-start gap-3 mt-3">
            <button
              className="btn btn-primary btn-label right ms-auto nexttab"
              onClick={e => {
                e.preventDefault();
                submitHandler();
                // props.toggleTab(props.activeTab + 1)
              }}
            >
              <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
              Continue to Next step
            </button>
          </div>
        </div>
      </TabPane>
    </>
  );
};

export default BenefitGroups;
