import React, { useEffect, useState } from 'react';

import { PreviewCardHeader } from '../../../../../../../Common';
import BenefitItem from '../CatalogueItems/BenefitItem';
import { Card, CardBody, Col, Input, Label, Row, TabPane } from 'reactstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { GET_CLIENTS, GET_COUNTRY, GET_PROJECTS_LIST, GET_WALLETS } from '../../../../../../../../store/application/actionType';

const CatalogueProperties = () => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    name: '',
    displayName: '',
    benefitWorth: 0,
    benefitWorthCurrency: '',
    benefitGroupCost: 0,
    totalPacksLimit: 0,
    totalPacksReleased: 0,
    shortDescription: '',
    longDescription: '',
    displayPicture: '',
    coverPicture: '',
    packReleaseType: '',
    priority: 0,
    membershipGenerationRestriction: true,
    dataUploadRestriction: false,
    isActive: true,
    groupCategory: '',
    validityTat: {
      value: '',
      unit: '',
    },
    membership: {
      prefix: '',
      length: 0,
      expiry: '',
    },
    isCatalogue: false,
    customerSegmentIds: '',
    cost: {
      toThriwe: '',
      toClient: '',
    },
    walletId: '',

    clientId: '',
    projectId: '',
  };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // CLIENTS
  const { clients, clientsLoading } = useSelector(state => ({
    clients: state?.Clients?.data,
    clientsLoading: state?.Clients?.loading,
  }));

  // PROJECTS
  const { projects, projectsLoading } = useSelector(state => ({
    projects: state?.ProjectsList?.data,
    projectsLoading: state?.ProjectsList?.loading,
  }));

  // COUNTRIES
  const { countries, countriesLoading } = useSelector(state => ({
    countries: state?.Countries?.data,
    countriesLoading: state?.Countries?.loading,
  }));

  // WALLET
  const { wallets, walletsLoading } = useSelector(state => ({
    wallets: state?.GetWallets?.data,
    walletsLoading: state?.GetWallets?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (countries === null) {
      dispatch({
        type: GET_COUNTRY,
      });
    }
    if (clients === null) {
      dispatch({
        type: GET_CLIENTS,
      });
    }

    if (projects === null) {
      dispatch({
        type: GET_PROJECTS_LIST,
        payload: {
          urlParam: {
            pageIndex: 0,
            pageSize: 100,
          },
        },
      });
    }

    if (wallets === null) {
      dispatch({
        type: GET_WALLETS,
        payload: {
          urlParam: {
            pageIndex: 0,
            pageSize: 100,
          },
        },
      });
    }
  }, [countries, clients, projects]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'name':
        updatedState = {
          ...state,
          name: value,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'displayName':
        updatedState = {
          ...state,
          displayName: value,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'costToThriwe':
        updatedState = {
          ...state,
          cost: {
            ...state?.cost,
            toThriwe: value,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'costToClient':
        updatedState = {
          ...state,
          cost: {
            ...state?.cost,
            toClient: value,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'isActive':
        updatedState = {
          ...state,
          isActive: e?.target?.checked,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = null;

    switch (label) {
      case 'benefitWorthCurrency':
        updatedState = {
          ...state,
          benefitWorthCurrency: data,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'validityTatUnit':
        updatedState = {
          ...state,
          validityTat: {
            ...state.validityTat,
            unit: data,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'membershipExpiry':
        updatedState = {
          ...state,
          membership: {
            ...state.membership,
            expiry: data,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    // if (data?.searchCode !== '0' && data?.searchValue !== ``) {
    //   setIsResetDisabled(false)
    // } else {
    //   setIsResetDisabled(true)
    // }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (countriesLoading || clientsLoading || projectsLoading || walletsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [countriesLoading, clientsLoading, projectsLoading, walletsLoading]);

  return (
    <TabPane tabId={1} id="pills-bill-info">
      {/* <a className="btn btn-primary" onClick={() => console.log(state)}>
                Get state
            </a> */}
      <div>
        <Card className="card-inner">
          <PreviewCardHeader title="Catalog Properties" />
          <CardBody>
            <Row>
              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="validityTatValue" className="form-label">
                    Client Name
                  </Label>
                  <Select
                    id="validityTatUnit"
                    name="validityTatUnit"
                    // value={state?.validityTat?.unit}
                    // options={[{ value: "India", label: "India" }]}
                    options={clients?.results?.map(client => ({
                      label: `${client?.name}`,
                      value: `${client?.id}`,
                    }))}
                    onChange={data => inputSelectHandler(`clientId`, data)}
                  />
                </div>
              </Col>

              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="validityTatValue" className="form-label">
                    Program Name
                  </Label>
                  <Select
                    id="validityTatUnit"
                    name="validityTatUnit"
                    value={state?.projectId}
                    options={projects?.results?.map(project => ({
                      label: `${project?.name}`,
                      value: `${project?.id}`,
                    }))}
                    // onChange={(data) =>
                    //     inputSelectHandler(`validityTatUnit`, data)
                    // }
                  />
                </div>
              </Col>

              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="validityTatValue" className="form-label">
                    Customer Segement
                  </Label>
                  <Select
                    id="validityTatUnit"
                    name="validityTatUnit"
                    // value={state?.validityTat?.unit}
                    options={[{ value: 'India', label: 'India' }]}
                    isDisabled={true}
                    // onChange={(data) =>
                    //     inputSelectHandler(`validityTatUnit`, data)
                    // }
                  />
                </div>
              </Col>

              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="validityTatValue" className="form-label">
                    Wallet Name
                  </Label>
                  <Select
                    id="validityTatUnit"
                    name="validityTatUnit"
                    // value={state?.validityTat?.unit}
                    options={[{ value: 'India', label: 'India' }]}
                    // onChange={(data) =>
                    //     inputSelectHandler(`validityTatUnit`, data)
                    // }
                  />
                </div>
              </Col>

              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="name" className="form-label">
                    Catalog Name
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Enter benefit group name"
                    value={state?.name}
                    onChange={inputChangeHandler}
                  />
                </div>
              </Col>

              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="displayName" className="form-label">
                    Catalog Display Name
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="displayName"
                    name="displayName"
                    placeholder="This name will appear in reports"
                    value={state?.displayName}
                    onChange={inputChangeHandler}
                  />
                </div>
              </Col>

              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="benefitWorthCurrency" className="form-label">
                    Currency
                  </Label>
                  <Select
                    id="benefitWorthCurrency"
                    name="benefitWorthCurrency"
                    value={state?.benefitWorthCurrency}
                    options={countries?.map(country => ({
                      label: `${country?.name}`,
                      value: `${country?.currency}`,
                    }))}
                    onChange={data => inputSelectHandler(`benefitWorthCurrency`, data)}
                  />
                </div>
              </Col>

              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="costToThriwe" className="form-label">
                    Catalog Cost - Thriwe
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="costToThriwe"
                    name="costToThriwe"
                    placeholder="Enter benefit group cost"
                    value={state?.cost?.toThriwe}
                    onChange={inputChangeHandler}
                  />
                </div>
              </Col>

              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="costToClient" className="form-label">
                    Catalog Cost - Client
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="costToClient"
                    name="costToClient"
                    placeholder="Enter benefit worth value"
                    value={state?.cost?.toClient}
                    onChange={inputChangeHandler}
                  />
                </div>
              </Col>

              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="validityTatValue" className="form-label">
                    Benefit Pack Validity Duration Unit
                  </Label>
                  <Select
                    id="validityTatUnit"
                    name="validityTatUnit"
                    value={state?.validityTat?.unit}
                    options={[
                      { value: 'Days', label: 'Days' },
                      { value: 'Months', label: 'Months' },
                      { value: 'Year', label: 'Year' },
                    ]}
                    onChange={data => inputSelectHandler(`validityTatUnit`, data)}
                  />
                </div>
              </Col>

              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="validityTatValue" className="form-label">
                    Benefit Pack Validity Duration value
                  </Label>
                  <div className="d-flex align-items-center mb-2">
                    <span className="text-muted">Custom &nbsp;</span>
                    <div className="form-switch d-inline-block ">
                      <Input
                        className="form-check-input code-switcher form-switch-md"
                        type="checkbox"
                        id="isActive"
                        name="isActive"
                        placeholder="Enter benefit pack validity duration value"
                        // checked={state?.isActive}
                        // onChange={inputChangeHandler}
                      />
                    </div>
                    <span> &nbsp;Lifetime</span>
                  </div>

                  <Input
                    type="number"
                    onWheel={e => e.target.blur()}
                    className="form-control"
                    id="benefitGroupCost"
                    name="benefitGroupCost"
                    placeholder="Enter benefit group cost"
                    //   value={state?.benefitGroupCost}
                    //   onChange={inputChangeHandler}
                  />
                </div>
              </Col>

              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="membershipExpiry" className="form-label">
                    Expiry Logic
                  </Label>
                  <Select
                    id="membershipExpiry"
                    name="validitymembershipExpiryTatUnit"
                    value={state?.membership?.expiry}
                    options={[
                      { value: 'On Registration', label: 'On Registration' },
                      { value: 'Predefined', label: 'Predefined' },
                    ]}
                    onChange={data => inputSelectHandler(`membershipExpiry`, data)}
                  />
                </div>
              </Col>

              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="validityTatValue" className="form-label">
                    Is Active?
                  </Label>
                  <div className="d-flex align-items-center">
                    <span className="text-muted">Inactive &nbsp;</span>
                    <div className="form-switch d-inline-block ">
                      <Input
                        className="form-check-input code-switcher form-switch-md"
                        type="checkbox"
                        id="isActive"
                        name="isActive"
                        checked={state?.isActive}
                        onChange={inputChangeHandler}
                      />
                    </div>
                    <span> &nbsp;Active</span>
                  </div>
                </div>
              </Col>

              <Col sm={12}>
                <div className="d-flex align-items-start gap-3 mt-3">
                  <button
                    className="btn btn-primary btn-label right ms-auto nexttab"
                    onClick={e => {
                      e.preventDefault();
                      // props.submitHandler(state)
                      // props.toggleTab(props.activeTab + 1)
                    }}
                  >
                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                    Continue to Next step
                  </button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </TabPane>
  );
};

export default CatalogueProperties;
