import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button, CardFooter, Col, Input, Label, Modal, ModalBody } from 'reactstrap';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Constant } from '../../../../../../../../Helpers/constant';
import { baseUrl, customHeaders, uaeProjectsBaseURL } from '../../../../../../../../Config';
import { GET_TRACKER_DATA } from '../../../../../../../../../store/actions';
import axios from 'axios';

const AdvancedFilter = ({ filters, setFilters, onClose }) => {
  const dispatch = useDispatch();

  /* ---------------------------- TYPED GOLF COURSES HANDLER ---------------------------- */
  async function getGolfCourses(search) {
    return await axios({
      method: `GET`,
      url: `${uaeProjectsBaseURL}/admin/facilities`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        categoryId: process.env.REACT_APP_GOLF_CATEGORY_ID,
        name: search === '' ? null : search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data.objectId,
              label: `${data.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  /* ---------------------------- ON SELECT USER ---------------------------- */
  const onSelectGolfCourse = event => {
    setFilters({ ...filters, facilityName: event });
  };

  const onSubmitHandler = () => {
    let urlParams = {
      status: filters?.status === `` ? null : filters?.status?.value,
      startDate: filters?.startDate === '' || filters?.startDate === 'Invalid date' ? null : filters?.startDate,
      endDate: filters?.endDate === '' || filters?.endDate === 'Invalid date' ? null : filters?.endDate,
      uploadBy: filters?.uploadBy === '' ? null : filters?.uploadBy?.value,
      facilityName: filters?.facilityName === '' ? null : filters?.facilityName?.label,
      limit: Constant?.TABLE_PAGE_SIZE,
      skip: 0,
      orderBy: `_created_at`,
      fileType: `BOOKING-RECON`,
    };

    dispatch({
      type: GET_TRACKER_DATA,
      payload: {
        urlParam: {
          ...urlParams,
        },
      },
    });

    onClose();
  };

  return (
    <Modal
      isOpen={true}
      // toggle={() => {
      //   onClose();
      // }}
      id="additional-filter"
      centered={true}
    >
      <div className="modal-header p-3 bg-soft-light">
        <h5 className="modal-title d-flex align-items-center">Additional Filters</h5>
        <Button
          type="button"
          onClick={() => {
            onClose();
          }}
          className="btn-close btn-light"
          aria-label="Close"
        />
      </div>

      <ModalBody>
        <Col md={12}>
          <Label className="form-label">Golf Course</Label>
          <AsyncPaginate
            debounceTimeout={500}
            value={filters?.facilityName}
            loadOptions={getGolfCourses}
            onChange={e => onSelectGolfCourse(e)}
            isMulti={false}
            closeMenuOnSelect={true}
            noOptionsMessage={() => 'No results found'}
            cacheUniqs={[['code']]}
            placeholder="Enter Name..."
            isClearable
          />
        </Col>
      </ModalBody>

      <CardFooter>
        <div className="text-center">
          <Button className="btn btn-md" color="primary" onClick={onSubmitHandler}>
            Submit
          </Button>
        </div>
      </CardFooter>
    </Modal>
  );
};

export default AdvancedFilter;
