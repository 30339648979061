import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap';

import TrackerTable from './Components/TrackerTable';
import TrackerFilter from './Components/TrackerFilter';
import { Constant } from '../../../../../../../Helpers/constant';
import { BreadCrumb, Loader, TablePagination } from '../../../../../../../Common';
import {
  GET_CLIENT_PRICE_EMPTY,
  GET_FILE_TYPES,
  GET_RECORD_DATA_EMPTY,
  GET_TRACKER_DATA,
  GET_TRACKER_DATA_EMPTY,
  PUBLISH_DATA_WITH_AUTH_EMPTY,
  PUBLISH_RECORD_DATA_EMPTY,
  SEND_APPROVAL_MAIL_EMPTY,
  UPDATE_RECORD_DATA_EMPTY,
  UPLOAD_CONFIG_EXCEL_EMPTY,
} from '../../../../../../../../store/actions';

const Tracker = ({ onView, currentPageNumber, setCurrentPageNumber, filters, setFilters, headerToggler }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [trackerData, setTrackerData] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  //  TRACKER DATA
  const { trackerList, trackerListLoading } = useSelector(state => ({
    trackerList: state?.GetTrackerData?.data,
    trackerListLoading: state?.GetTrackerData?.loading,
  }));

  //  UPLOAD CONFIG FILE
  const { uploadConfigFileData } = useSelector(state => ({
    uploadConfigFileData: state?.UploadConfigExcel?.data,
  }));

  //  FILE TYPES
  const { fileTypes } = useSelector(state => ({
    fileTypes: state?.GetFileTypes?.data,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_TRACKER_DATA,
      payload: {
        urlParam: {
          skip: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          limit: Constant?.TABLE_PAGE_SIZE,
          orderBy: `_created_at`,
          fileType: `CLIENT-DATA`,
        },
      },
    });

    if (fileTypes === null) {
      dispatch({
        type: GET_FILE_TYPES,
      });
    }

    return () => {
      dispatch({
        type: SEND_APPROVAL_MAIL_EMPTY,
      });

      dispatch({
        type: GET_CLIENT_PRICE_EMPTY,
      });

      dispatch({
        type: PUBLISH_RECORD_DATA_EMPTY,
      });

      dispatch({
        type: PUBLISH_DATA_WITH_AUTH_EMPTY,
      });

      dispatch({
        type: GET_RECORD_DATA_EMPTY,
      });

      dispatch({
        type: UPDATE_RECORD_DATA_EMPTY,
      });

      dispatch({
        type: GET_TRACKER_DATA_EMPTY,
      });

      dispatch({
        type: UPLOAD_CONFIG_EXCEL_EMPTY,
      });
    };
  }, []);

  useEffect(() => {
    if (trackerList !== null) {
      setTrackerData(trackerList);
    }
  }, [trackerList]);

  useEffect(() => {
    if (uploadConfigFileData !== null) {
      dispatch({
        type: UPLOAD_CONFIG_EXCEL_EMPTY,
      });

      let urlParams = {};
      dispatch({
        type: GET_TRACKER_DATA,
        payload: {
          urlParam: {
            ...urlParams,
            skip: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            limit: Constant?.TABLE_PAGE_SIZE,
            orderBy: `_created_at`,
            fileType: `CLIENT-DATA`,
          },
        },
      });
    }
  }, [uploadConfigFileData]);

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);

    let urlParams = {};
    dispatch({
      type: GET_TRACKER_DATA,
      payload: {
        urlParam: {
          ...urlParams,
          skip: pageNumber * Constant.TABLE_PAGE_SIZE,
          limit: Constant?.TABLE_PAGE_SIZE,
          orderBy: `_created_at`,
          fileType: `CLIENT-DATA`,
        },
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    if (params?.actionType === `VIEW`) {
      onView(params?.details?.originalData);
    }
  };

  /* ---------------------------- LOADER HANDLER ---------------------------- */
  useEffect(() => {
    if (trackerListLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [trackerListLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push('/dashboard');
    }
  };

  return (
    <>
      {loading && <Loader />}

      <Container fluid className="position-relative">
        <BreadCrumb title="Pricing Uploads" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Pricing Uploads']} />
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Pricing Uploads</h4>

                <div className="btn-group custom-btn-group" role="group" aria-label="Basic radio toggle button group">
                  <input
                    type="radio"
                    className="btn-check"
                    name="peakType"
                    id="btnradio1"
                    autoComplete="off"
                    value={1}
                    onChange={() => headerToggler(`PROGRAM_PRICING`)}
                  />
                  <label className="btn btn-outline-primary" htmlFor="btnradio1">
                    Program Pricing
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name="peakType"
                    id="btnradio2"
                    autoComplete="off"
                    value={2}
                    defaultChecked
                    onChange={() => headerToggler(`PRICING_UPLOADS`)}
                  />
                  <label className="btn btn-outline-primary" htmlFor="btnradio2">
                    Pricing Uploads
                  </label>
                </div>
              </CardHeader>

              <CardBody>
                <TrackerFilter
                  filters={filters}
                  setFilters={setFilters}
                  currentPageNumber={currentPageNumber}
                  setCurrentPageNumber={setCurrentPageNumber}
                />
                <TrackerTable
                  data={trackerData?.results}
                  totalData={trackerData?.count}
                  pageHandler={paginationHandler}
                  pageNumber={currentPageNumber}
                  actionHandler={tableActionHandler}
                />
              </CardBody>

              {trackerData?.count > 0 && (
                <CardFooter>
                  <Row className="custom_pagination">
                    <Col md={12} className="d-flex justify-content-between align-items-center">
                      <div>
                        Showing <b>{currentPageNumber * Constant?.TABLE_PAGE_SIZE + 1}</b> to&nbsp;
                        <b>{currentPageNumber * Constant?.TABLE_PAGE_SIZE + trackerData?.results?.length}</b> of <b>{trackerData?.count}</b> records
                      </div>
                      <div className="d-flex justify-content-end">
                        <TablePagination totalData={trackerData?.count} currentPage={currentPageNumber} onPageChangeHandler={paginationHandler} />
                      </div>
                    </Col>
                  </Row>
                </CardFooter>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Tracker;
