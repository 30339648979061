import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ReactPaginate from 'react-paginate';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';

import Filter from './Components/Filter';
import { Constant } from '../../../../../Helpers/constant';
import AccordionSection from './Components/AccordionSection';
import { Loader } from '../../../../../Common';
import { GET_GM_REPORT, GET_GM_REPORT_EMPTY } from '../../../../../../store/actions';
import cogoToast from 'cogo-toast';
import { indianProjectsBaseURL } from '../../../../../Config';

const ReportSection = ({
  accordionRow,
  setAccordionRow,
  type,
  onViewDetailDescription,
  currentPageNumber,
  setCurrentPageNumber,
  filters,
  setFilters,
  headerToggler,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const pageSize = 5;

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  // const [currentPageNumber, setCurrentPageNumber] = useState(0);

  /* ---------------------------- REDUX STATES ---------------------------- */
  //  GM REPORT DATA
  const { gmReport, gmReportLoading } = useSelector(state => ({
    gmReport: state?.GetGmReport?.data,
    gmReportLoading: state?.GetGmReport?.loading,
  }));

  useEffect(() => {
    if (gmReport === null) {
      setCurrentPageNumber(0);
      dispatch({
        type: GET_GM_REPORT,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber + 1,
            pageSize: pageSize,
            requestedFrom: filters?.reportType === null ? null : filters?.reportType?.value?.split(' ')?.[0],
            requestedTo: filters?.reportType === null ? null : filters?.reportType?.value?.split(' ')?.[1],
            projectCode: filters?.projectCode === null ? null : filters?.projectCode?.value,
            type: type,
          },
        },
      });
    }
  }, []);

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);

    dispatch({
      type: GET_GM_REPORT,
      payload: {
        urlParam: {
          pageIndex: pageNumber + 1,
          pageSize: pageSize,
          requestedFrom: filters?.reportType === null ? null : filters?.reportType?.value?.split(' ')?.[0],
          requestedTo: filters?.reportType === null ? null : filters?.reportType?.value?.split(' ')?.[1],
          projectCode: filters?.projectCode === null ? null : filters?.projectCode?.value,
          type: type,
        },
      },
    });
  };

  /* ---------------------------- DOWNLOAD FILE HANDLER ---------------------------- */
  const downloadReportHandler = () => {
    setLoading(true);

    fetch(`${indianProjectsBaseURL}/admin/download-excel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Constant?.CLIENT_TOKEN,
        'Project-Code': 'ADMIN_ACCESS',
      },
      body: JSON.stringify({
        fileConfigId: `${gmReport?.fileConfigId ?? ''}`,
        requestedFrom: filters?.reportType === null ? null : filters?.reportType?.value?.split(' ')?.[0],
        requestedTo: filters?.reportType === null ? null : filters?.reportType?.value?.split(' ')?.[1],
        projectCode: filters?.projectCode === null ? null : filters?.projectCode?.value,
        type: type,
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        setLoading(false);
        const link = document.createElement('a');
        const fileName = 'file.xlsx';
        link.setAttribute('download', fileName);
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(error => {
        setLoading(false);
        cogoToast.error('Something went wrong');
      });
  };

  /* ---------------------------- LOADER HANDLER ---------------------------- */
  useEffect(() => {
    if (gmReportLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [gmReportLoading]);

  return (
    <>
      {loading && <Loader />}

      <Row>
        <Col lg={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">GM Report</h4>
            </CardHeader>

            <CardBody>
              <Filter filters={filters} setFilters={setFilters} currentPageNumber={currentPageNumber} setCurrentPageNumber={setCurrentPageNumber} />
              <div>
                <div className="d-flex justify-content-between mb-4">
                  <div className="btn-group custom-btn-group" role="group" aria-label="Basic radio toggle button group">
                    <input
                      type="radio"
                      className="btn-check"
                      name="peakType"
                      id="btnradio1"
                      autoComplete="off"
                      value={`actual`}
                      checked={type === `actual`}
                      onChange={() => headerToggler(`actual`)}
                    />
                    <label className="btn btn-outline-primary" htmlFor="btnradio1">
                      Actual
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="peakType"
                      id="btnradio2"
                      autoComplete="off"
                      value={`receivable`}
                      checked={type === `receivable`}
                      onChange={() => headerToggler(`receivable`)}
                    />
                    <label className="btn btn-outline-primary" htmlFor="btnradio2">
                      Receivable
                    </label>
                  </div>

                  {gmReport?.results?.length > 0 && (
                    <button type="button" className="btn btn-md btn-primary mx-1" onClick={downloadReportHandler}>
                      <i className="ri-download-cloud-line align-middle"></i>&nbsp; Download Report
                    </button>
                  )}
                </div>

                <AccordionSection
                  accordionRow={accordionRow}
                  setAccordionRow={setAccordionRow}
                  reportsData={gmReport?.results}
                  onViewDetailDescription={onViewDetailDescription}
                />
              </div>
            </CardBody>

            {gmReport?.count === 0 && (
              <CardBody>
                <h5 className="text-center">No Report found!</h5>
              </CardBody>
            )}

            {gmReport?.count > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{currentPageNumber * pageSize + 1}</b> to&nbsp;
                      <b>{currentPageNumber * pageSize + gmReport?.results?.length}</b> of <b>{gmReport?.count}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      {/* <TablePagination totalData={gmReport?.count} currentPage={currentPageNumber} onPageChangeHandler={paginationHandler} /> */}
                      <ReactPaginate
                        previousLabel={'<<'}
                        nextLabel={'>>'}
                        breakLabel={'...'}
                        forcePage={currentPageNumber}
                        pageCount={Math.ceil(gmReport?.count / pageSize)}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={event => {
                          paginationHandler(event.selected);
                        }}
                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                      />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ReportSection;
