import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toastify = props => {
  const showToastMessage = type => {
    toast.success('Success Notification !', {
      position: toast.POSITION.TOP_CENTER,
    });
    toast.error('Error Notification !', {
      position: toast.POSITION.TOP_CENTER,
    });
    toast.warning('Warning Notification !', {
      position: toast.POSITION.TOP_CENTER,
    });
    toast.info('Information Notification !', {
      position: toast.POSITION.TOP_CENTER,
    });
    toast('Default Notification !', {
      position: toast.POSITION.TOP_CENTER,
    });
    toast('Custom Style Notification with css class!', {
      position: toast.POSITION.TOP_CENTER,
      className: 'foo-bar',
    });
  };

  // success, error, warning, info

  return (
    <React.Fragment>
      {props.type === 'success' &&
        toast.warning(`${props.msg}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true,
        })}

      {props.type === 'error' &&
        toast.error(`${props.msg}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true,
        })}

      {props.type === 'warning' &&
        toast.warning(`${props.msg}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true,
        })}

      {props.type === 'info' &&
        toast.info(`${props.msg}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          hideProgressBar: true,
        })}
    </React.Fragment>
  );
};
export default Toastify;
