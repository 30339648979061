import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Row, Col, Button, Modal, ModalBody, ModalHeader, Table, Input } from 'reactstrap';
import { GET_SERVICE, GET_SERVICE_EMPTY, UPDATE_BOOKING } from '../../../../../../../../store/application/actionType';
import { Constant } from '../../../../../../../Helpers/constant';

const UpdateModal = props => {
  const dispatch = useDispatch();

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Services
  const { services, servicesLoading } = useSelector(state => ({
    services: state?.GetService?.data,
    servicesLoading: state?.GetService?.loading,
  }));

  useEffect(() => {
    if (services?.results?.length > 0) {
      const tempAllServices = [...allServicesArray];
      tempAllServices.splice(currentSelectedServiceIndex, 1, services?.results);
      setAllServicesArray(tempAllServices);
    }
    dispatch({ type: GET_SERVICE_EMPTY });
  }, [services]);

  /* ---------------------------- LOCAL STATES ---------------------------- */

  const [bookings, setBookings] = useState([]);

  const [modal_varying1, setmodal_varying1] = useState(true);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  const [allServicesArray, setAllServicesArray] = useState([]);

  const [currentSelectedServiceIndex, setCurrentSelectedServiceIndex] = useState(-1);

  useEffect(() => {
    if (props?.data !== null) {
      setBookings(
        props?.data.map(booking => {
          return {
            ...booking,
            tempTimeOfPlay: ``,
            service: {
              id: ``,
              name: ``,
            },
          };
        }),
      );

      setAllServicesArray(
        props?.data.map(() => {
          return [];
        }),
      );
    }
  }, []);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = (e, index) => {
    const { name, value } = e.target;

    let tempBooking = [];
    let selectedChangingBooking = null;
    let selectedRowServices = [];

    switch (name) {
      case 'golf-course':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],
          facility: {
            id: value,
            name: ``,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        setCurrentSelectedServiceIndex(index);

        dispatch({
          type: GET_SERVICE,
          payload: {
            urlParam: {
              facilityId: value,
            },
          },
        });
        break;

      case 'service':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],
          service: {
            id: value,
            name: ``,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'time-of-play':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],
          tempTimeOfPlay: value,
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- VALIDATION HANDLER ---------------------------- */
  const validationCheck = data => {
    let flag = false;

    for (let i = 0; i < data?.length; i++) {
      if (data[i]?.facility?.id === '') {
        flag = true;
        break;
      } else if (data[i]?.tempTimeOfPlay === '') {
        flag = true;
        break;
      } else if (data[i]?.service?.id === '') {
        flag = true;
        break;
      }
    }

    flag ? setIsSubmitBtnDisabled(true) : setIsSubmitBtnDisabled(false);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const requestBookings = bookings?.map(booking => {
      const date = booking?.bookingDateTime?.split('T')[0];
      const tempBookingDateTime = `${date}T${booking?.tempTimeOfPlay}:00.000Z`;

      return {
        objectId: booking?.bookingId,
        detailedBookingId: booking?.detailedBookingId,
        bookingDateTime: tempBookingDateTime,
        service: {
          id: booking?.service?.id,
          name: ``,
        },
        process: `update-booking`,
      };
    });

    dispatch({
      type: UPDATE_BOOKING,
      payload: {
        data: {
          projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
          bookingTypeId: Constant?.BOOKING_TYPES?.golfBookings?.objectId,
          bookingsData: requestBookings,
        },
      },
    });
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      scrollable={true}
      id="exampleModal"
      size="lg"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Update
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>
      <ModalBody>
        {/* <button onClick={() => console.log(bookings)}>get state</button> */}
        <form>
          <Row>
            <Col md={12} style={{ mixHeight: '500px' }}>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Booking ID</th>
                    <th> Name</th>
                    <th> Golf Course</th>
                    <th> Service</th>
                    <th> Time of Play </th>
                  </tr>
                </thead>
                <tbody>
                  {bookings?.map((booking, index) => (
                    <tr key={index}>
                      <th>{index + 1}.</th>
                      <td>{booking?.bookingId}</td>
                      <td>{booking?.user?.name}</td>

                      <td>
                        {
                          <select
                            id="golf-course"
                            name="golf-course"
                            className="form-select"
                            value={booking?.facility?.id}
                            onChange={e => inputChangeHandler(e, index)}
                          >
                            <option value="">Choose...</option>
                            {props.locations?.results?.map((location, index) => (
                              <option key={index} value={location?.objectId}>
                                {location?.displayName}
                              </option>
                            ))}
                          </select>
                        }
                      </td>

                      <td>
                        {
                          <select
                            id="service"
                            name="service"
                            className="form-select"
                            value={booking?.service?.id}
                            onChange={e => inputChangeHandler(e, index)}
                          >
                            <option value="">Choose...</option>
                            {allServicesArray?.[index]?.map((service, i) => (
                              <option key={i} value={service?.objectId}>
                                {service?.name}
                              </option>
                            ))}
                          </select>
                        }
                      </td>

                      <td>
                        {
                          <Input
                            className="form-control"
                            type="time"
                            name="time-of-play"
                            value={booking?.tempTimeOfPlay}
                            onChange={e => inputChangeHandler(e, index)}
                          />
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateModal;
