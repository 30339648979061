import React, { useEffect, useState } from 'react';
import { GoPlus } from 'react-icons/go';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import DynamicForm from './DynamicForm';
import DataTable from 'react-data-table-component';

const inputTypeOptions = [
  { value: 1, label: 'Text' },
  { value: 2, label: 'Select' },
  { value: 3, label: 'Date' },
  { value: 4, label: 'Time' },
  { value: 5, label: 'Address' },
  { value: 6, label: 'Number' },
];

const DynamicSetting = props => {
  const [state, setState] = useState(null);
  const [modal_varying1, setmodal_varying1] = useState(true);
  const [dynamicField, setDynamicField] = useState([]);
  const [isShowDynamicForm, setIsShowDynamicForm] = useState(false);
  const [selectedDynamicField, setSelectedDynamicField] = useState(null);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (dynamicField.length > 0) {
      const fetchCoumns = [
        {
          name: 'S.No',
          width: '90px',
          wrap: true,
          selector: row => row?.serialNumber,
        },
        {
          name: 'Input Type',
          width: '150px',
          wrap: true,
          selector: row => row?.inputType,
        },
        {
          name: 'Header Name',
          width: '150px',
          wrap: true,
          selector: row => row?.headerName,
        },
        {
          name: 'Placeholder',
          width: '150px',
          wrap: true,
          selector: row => row?.placeholder,
        },
        {
          name: 'Action',
          width: '250px',
          wrap: true,
          cell: row => (
            <div className="d-flex justify-content-center flex-column m-4">
              <button
                className="btn btn-sm btn-primary mb-1"
                onClick={() => {
                  setIsShowDynamicForm(true);
                  setSelectedDynamicField(row?.orignalData);
                }}
                style={{ width: '150px' }}
              >
                Update
              </button>
            </div>
          ),
        },
      ];
      setColumns(fetchCoumns);
      setData(
        dynamicField?.map((dynamicData, index) => {
          return {
            serialNumber: index + 1,
            inputType: inputTypeOptions.find(option => option?.value === dynamicData?.inputType)?.label || 'NA',
            headerName: dynamicData?.headerName || 'NA',
            placeholder: dynamicData?.placeholder || 'NA',
            orignalData: { ...dynamicData, index },
          };
        }),
      );
    }
  }, [dynamicField]);

  useEffect(() => {
    setState(props?.data?.data);
    setCurrentIndex(props?.data?.currentIndex);
    if (props?.data?.data?.dynamicField?.length > 0) {
      setDynamicField(props?.data?.data?.dynamicField);
    }
  }, [props]);

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const submitHandler = () => {
    if (props?.label === 'CREATE') {
      const newData = {
        ...props.data,
        data: {
          ...props.data.data,
          dynamicField: dynamicField,
        },
      };
      console.log({
        currentIndex: currentIndex,
        ...newData,
      });

      props.onSubmit({
        currentIndex: currentIndex,
        ...newData,
      });
    } else {
      props.onSubmit({
        currentIndex: currentIndex,
        data: {
          ...props.data,
          ...state,
          dynamicField: dynamicField,
        },
      });
    }

    tog_varying1();
  };

  const saveDynamicField = (flag, index = 0, data) => {
    if (flag === 'CREATE') {
      setDynamicField([...dynamicField, data]);
    } else if (flag === 'UPDATE') {
      setDynamicField(dynamicField.map((item, idx) => (idx === index ? data : item)));
    }
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
      size="lg"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Dynamic Settings
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      {isShowDynamicForm && (
        <DynamicForm
          selectedDynamicField={selectedDynamicField}
          saveDynamicField={saveDynamicField}
          onCloseHandler={() => setIsShowDynamicForm(false)}
        />
      )}

      <ModalBody>
        <div className="text-center mb-3">
          <button
            type="button"
            className={`btn btn-success`}
            onClick={() => {
              setSelectedDynamicField(null);
              setIsShowDynamicForm(true);
            }}
          >
            Add New Field <GoPlus />
          </button>
        </div>

        <DataTable
          columns={columns ?? []}
          data={data ?? []}
          customStyles={{
            headRow: {
              style: {
                borderBottom: '1px solid #ddd',
                backgroundColor: '#f9fafb',
                fontWeight: '600',
              },
            },
            cells: {
              style: {
                border: '0.5px solid #ddd',
                borderBottom: '0px solid #ddd',
              },
            },
          }}
        />
      </ModalBody>

      <ModalFooter className="d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={dynamicField.length === 0}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DynamicSetting;
