import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from '../../../../Common';
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import {
  GET_COMS_BENEFIT,
  GET_PROJECTS_LIST,
  GET_PROJECTS_LIST_EMPTY,
  UPDATE_PAYMENT_SETTING_EMPTY,
} from '../../../../../store/application/actionType';
import ProjectsTable from './Components/Table/ProjectsTable';
import { Constant } from '../../../../Helpers/constant';
import ComsSetting from './Components/Forms/ComsSetting';
import classnames from 'classnames';
import Sms from './Components/Sms';
import Email from './Components/Email';
import Whatsapp from './Components/Whatsapp';

const ProjectComs = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [projectsTableShow, setProjectsTableShow] = useState(true);
  const [isCreateProjectShow, setIsCreateProjectShow] = useState(false);

  const [currentSelectedDetails, setCurrentSelectedDetails] = useState([]);
  const [currentConfirmationModalActionFor, setCurrentConfirmationModalActionFor] = useState(null);

  const [checkMarks, setcheckMarks] = useState({
    sms: false,
    whatsapp: false,
    email: false,
  });
  const [showTable, setShowTable] = useState(false);

  const [updateConfirmationData, setUpdateConfirmationData] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Projects List
  const { projectsList, projectsListLoading } = useSelector(state => ({
    projectsList: state?.ProjectsList?.data,
    projectsListLoading: state?.ProjectsList?.loading,
  }));

  const { updateConfig, updateConfigLoading } = useSelector(state => ({
    updateConfig: state?.UpdateComsConfig?.data,
    updateConfigLoading: state?.UpdateComsConfig?.loading,
  }));

  useEffect(() => {
    if (projectsList === null) {
      dispatch({
        type: GET_PROJECTS_LIST,
        payload: {
          urlParam: {
            pageIndex: currentPageIndex,
            pageSize: Constant?.TABLE_PAGE_SIZE,
          },
        },
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (updateConfig !== null) {
      dispatch({
        type: GET_PROJECTS_LIST_EMPTY,
      });
      dispatch({
        type: GET_PROJECTS_LIST,
        payload: {
          urlParam: {
            pageIndex: currentPageIndex,
            pageSize: Constant?.TABLE_PAGE_SIZE,
          },
        },
      });
    }
  }, [dispatch]);

  /* ---------------------------- BUTTON HANDLER ---------------------------- */
  const createProjectBtnHandler = () => {
    setProjectsTableShow(false);
    setIsCreateProjectShow(true);
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedDetails(params?.details);
    setCurrentConfirmationModalActionFor(params?.actionType);
    if (params?.actionType === `Show_Table`) {
      setProjectsTableShow(false);
      setIsCreateProjectShow(true);
      setShowTable(true);
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);
    dispatch({
      type: GET_PROJECTS_LIST,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          pageSize: Constant?.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (projectsListLoading || updateConfigLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [projectsListLoading, updateConfigLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Comms Management`) {
      setProjectsTableShow(true);
      setIsCreateProjectShow(false);
      setShowTable(false);
      dispatch({
        type: GET_PROJECTS_LIST,
        payload: {
          urlParam: {
            pageIndex: currentPageIndex,
            pageSize: Constant?.TABLE_PAGE_SIZE,
          },
        },
      });
    } else if (title === `Information`) {
      // do nothing
    }
  };

  const [topBorderjustifyTab, settopBorderjustifyTab] = useState('1');
  const topBorderJustifytoggle = tab => {
    if (topBorderjustifyTab !== tab) {
      settopBorderjustifyTab(tab);
    }
  };

  return (
    <>
      {/* {loading && <Loader />} */}
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {projectsTableShow && (
            <>
              <BreadCrumb title="Communication" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Comms Management`]} />

              <ProjectsTable
                data={projectsList?.results}
                totalData={projectsList?.count}
                pageNumber={currentPageNumber}
                dataStartedFrom={currentPageIndex}
                pageHandler={paginationHandler}
                actionHandler={tableActionHandler}
                onClickPrimaryBtn={createProjectBtnHandler}
              />
            </>
          )}
          {showTable && (
            <>
              <BreadCrumb title="Coms" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Comms Management`, `Comms Update`]} />
              <Row>
                <Col xxl={12}>
                  <Card>
                    <CardBody>
                      <Nav tabs className="nav nav-tabs nav-border-top nav-border-top-primary mb-3">
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: topBorderjustifyTab === '1',
                            })}
                            onClick={() => {
                              topBorderJustifytoggle('1');
                            }}
                          >
                            Sms
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: topBorderjustifyTab === '2',
                            })}
                            onClick={() => {
                              topBorderJustifytoggle('2');
                            }}
                          >
                            Email
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                              active: topBorderjustifyTab === '3',
                            })}
                            onClick={() => {
                              topBorderJustifytoggle('3');
                            }}
                          >
                            Whatsapp
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={topBorderjustifyTab} className="text-muted">
                        {topBorderjustifyTab === '1' && (
                          <TabPane tabId="1" id="nav-border-top-home">
                            <Sms tabId="1" activeTab={topBorderjustifyTab} projectId={currentSelectedDetails?.code} setcheckMarks={setcheckMarks} />
                          </TabPane>
                        )}
                        {topBorderjustifyTab === '2' && (
                          <TabPane tabId="2" id="nav-border-top-profile">
                            <Email tabId="2" activeTab={topBorderjustifyTab} projectId={currentSelectedDetails?.code} setcheckMarks={setcheckMarks} />
                          </TabPane>
                        )}
                        {topBorderjustifyTab === '3' && (
                          <TabPane tabId="3" id="nav-border-top-profile">
                            <Whatsapp
                              tabId="3"
                              activeTab={topBorderjustifyTab}
                              projectId={currentSelectedDetails?.code}
                              setcheckMarks={setcheckMarks}
                            />
                          </TabPane>
                        )}
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default ProjectComs;
