import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, realTimeBaseURL } from '../../../../Containers/Config/index.js';
import {
  DISABLE_GOLF_COURSE_UNAVAILABILITY,
  DISABLE_GOLF_COURSE_UNAVAILABILITY_SUCCESS,
  DISABLE_GOLF_COURSE_UNAVAILABILITY_ERROR,
} from './../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';
import cogoToast from 'cogo-toast';

function* disabledgolfCourseUnavailability({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${realTimeBaseURL}/admin/golfcourse-unavailable/${payload.id}`,
      method: 'DELETE',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
    });

    if (response.status === 200) {
      cogoToast.success(`${response?.data?.message}`);
      yield put({
        type: DISABLE_GOLF_COURSE_UNAVAILABILITY_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: DISABLE_GOLF_COURSE_UNAVAILABILITY_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({
      type: DISABLE_GOLF_COURSE_UNAVAILABILITY_ERROR,
      payload: error,
    });
  }
}

export function* watchDisableGolfCourseUnavailability() {
  yield takeEvery(DISABLE_GOLF_COURSE_UNAVAILABILITY, disabledgolfCourseUnavailability);
}

function* disableGolfCourseUnavailablilitySaga() {
  yield all([fork(watchDisableGolfCourseUnavailability)]);
}

export default disableGolfCourseUnavailablilitySaga;
