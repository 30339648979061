import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert } from 'reactstrap';
import ParticlesAuth from '../../../../../../pages/AuthenticationInner/ParticlesAuth';

import { withRouter, Link } from 'react-router-dom';

import ThriweWhite from '../../../../../../assets/custom/thriwe-white-logo.png';
import { Error } from '../../../../../Common';
import { isValidEmail, isValidPassword } from '../../../../../Helpers/Helpers';

const LoginForm = ({ onSubmit }) => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    username: '',
    password: '',
  };
  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);

  const [isShowPassword, setIsShowPassword] = useState(false);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'username':
        updatedState = {
          ...state,
          username: value,
        };
        break;

      case 'password':
        updatedState = {
          ...state,
          password: value,
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data?.username?.trim() !== `` && data?.password?.trim() !== `` && isValidPassword(data?.password?.trim())) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      const payload = {
        ...state,
      };

      onSubmit({
        ...payload,
      });
    }
  };

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <p className="mt-3 fs-15 fw-medium">&nbsp;</p>
                    <Link to="/vendor/login" className="d-inline-block auth-logo">
                      <img src={ThriweWhite} alt="" height="50" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4" style={{ borderRadius: '40px' }}>
                  <CardBody className="p-md-5 p-sm-3">
                    {/* <button onClick={() => console.log(state)}>get state</button> */}
                    <div className="text-center mt-2">
                      <h1 className="text-primary">Login</h1>
                    </div>
                    <div className="p-2 mt-4 mb-3">
                      <div className="mb-3">
                        <Label htmlFor="username" className="form-label">
                          Login ID
                        </Label>
                        <Input
                          id="username"
                          name="username"
                          type="text"
                          placeholder="Enter username"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state?.username}
                        />
                        {isValidationShow && state?.username?.trim() === `` && <Error text="Login id can't be empty" />}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="password-input">
                          Password
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            id="password"
                            name="password"
                            type={isShowPassword ? 'text' : 'password'}
                            placeholder="Enter password"
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={state?.password}
                          />
                          {isValidationShow && state?.password?.trim() === `` && <Error text="Password can't be empty" />}
                          {isValidationShow && state?.password?.trim() !== `` && !isValidPassword(state?.password?.trim()) && (
                            <Error text="Please enter correct password" />
                          )}
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                            type="button"
                            id="password-addon"
                            onClick={() => {
                              setIsShowPassword(!isShowPassword);
                            }}
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </div>

                      <div className="form-check">
                        <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                        <Label className="form-check-label" htmlFor="auth-remember-check">
                          Remember me
                        </Label>
                        {/* <div className="float-end">
                          <Link to="/client/forgot-password" className="text-muted">
                            Forgot password?
                          </Link>
                        </div> */}
                      </div>

                      <div className="mt-4">
                        <Button color="success" className="btn btn-success w-100" type="submit" onClick={submitHandler}>
                          Log in
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(LoginForm);
