import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert } from 'reactstrap';
import ParticlesAuth from '../../../../../pages/AuthenticationInner/ParticlesAuth';

//redux
import { useSelector, useDispatch } from 'react-redux';

import { withRouter, Link } from 'react-router-dom';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useLocation, useHistory } from 'react-router-dom';

import ThriweWhite from '../../../../../assets/custom/thriwe-white-logo.png';
import LinkExpiredImage from '../../../../../assets/custom/Password_link_expired.jpg';

const LinkBroken = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const { user } = useSelector(state => ({
  //   user: state.Account.user,
  // }));

  const [userLogin, setUserLogin] = useState([]);

  // useEffect(() => {
  //   if (user && user) {
  //     setUserLogin({
  //       email: user.user.email,
  //       password: user.user.confirm_password,
  //     });
  //   }
  // }, [user]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your Email'),
      password: Yup.string().required('Please Enter Your Password'),
    }),
    onSubmit: values => {
      // dispatch(loginUser(values, props.history));
    },
  });

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <p className="mt-3 fs-15 fw-medium">&nbsp;</p>
                    <Link to="/client/login" className="d-inline-block auth-logo">
                      <img src={ThriweWhite} alt="" height="50" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4" style={{ borderRadius: '40px' }}>
                  <CardBody className="p-md-5 p-sm-3">
                    <div className="text-center mt-2">
                      <h2 className="text-dark">Reset Password</h2>
                    </div>

                    <div className="text-center">
                      <img src={LinkExpiredImage} className="img-fluid" />
                    </div>
                    <div className="p-2 mt-4 mb-3">
                      <div className="text-center fs-5 text-danger">Your reset password link has expired</div>
                    </div>
                    <div className="mt-4">
                      <Button
                        color="success"
                        className="btn btn-success w-100"
                        // type="submit"
                        onClick={() => history.push(`/client/login`)}
                      >
                        <i className="ri-arrow-left-line label-icon align-middle fs-16 ms-2"></i>
                        &nbsp; Back to Login
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(LinkBroken);
