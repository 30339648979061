import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from './../../../../Common';
import { Container } from 'reactstrap';
import GolfCourseSlots from './Components/Tables/GolfCourseSlots';
import AssignGolfCourseModal from './Components/Modals/AssignGolfCourse';
import UnassignedModal from './Components/Modals/Unassigned';
import ViewCourseFormat from './Components/Tables/ViewCourseFormat';
import {
  CREATE_GOLF_COURSE_SLOT_EMPTY,
  DELETE_GOLF_COURSE_FORMATS_BY_ID_EMPTY,
  GET_COUPON_FACILITY,
  GET_GOLF_COURSE,
  GET_GOLF_COURSE_FORMATS,
  GET_GOLF_COURSE_FORMATS_BY_ID,
  GET_GOLF_COURSE_FORMATS_BY_ID_EMPTY,
  GET_GOLF_COURSE_SUCCESS,
} from '../../../../../store/application/actionType';
import { Constant } from '../../../../Helpers/constant';

const CourseWiseFormats = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [currentPageNumberSecondary, setCurrentPageNumberSecondary] = useState(0);
  const [currentPageIndexSecondary, setCurrentPageIndexSecondary] = useState(0);

  const [currentSelectedDetails, setCurrentSelectedDetails] = useState([]);

  const [currentConfirmationModalActionFor, setCurrentConfirmationModalActionFor] = useState(null);

  const [isAssignGolfCourseModalShow, setIsAssignGolfCourseModalShow] = useState(false);
  const [isUnassignedModalShow, setIsUnassignedModalShow] = useState(false);

  const [isViewCourseFormatShow, setIsViewCourseFormatShow] = useState(false);
  const [isMainPage, setIsMainPage] = useState(true);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Golf Course Formats
  const { golfCourseFormats, golfCourseFormatsLoading } = useSelector(state => ({
    golfCourseFormats: state?.GetGolfCourseFormats?.data,
    golfCourseFormatsLoading: state?.GetGolfCourseFormats?.loading,
  }));

  // Delete Golf Course Format By Id
  const { deleteGolfCourseFormatsById, deleteGolfCourseFormatsByIdLoading } = useSelector(state => ({
    deleteGolfCourseFormatsById: state?.deleteGolfCourseFormatById?.data,
    deleteGolfCourseFormatsByIdLoading: state?.deleteGolfCourseFormatById?.loading,
  }));

  // Get Golf Courses
  const { golfCourses, golfCoursesLoading } = useSelector(state => ({
    golfCourses: state?.GetGolfCourses?.data,
    golfCoursesLoading: state?.GetGolfCourses?.loading,
  }));

  // create golf Course slots
  const { createGolfCourse, createGolfCourseLoading } = useSelector(state => ({
    createGolfCourse: state?.createGolfCourseSlot?.data,
    createGolfCourseLoading: state?.createGolfCourseSlot?.loading,
  }));

  // Get golf course format by Id
  const { golfCourseFormat, golfCourseFormatLoading } = useSelector(state => ({
    golfCourseFormat: state?.GetGolfCourseFormatById?.data,
    golfCourseFormatLoading: state?.GetGolfCourseFormatById?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (golfCourseFormats === null) {
      dispatch({
        type: GET_GOLF_COURSE_FORMATS,
      });
    }

    if (golfCourses === null) {
      dispatch({
        type: GET_GOLF_COURSE,
      });
    }
  }, [golfCourseFormats, golfCourses]);

  useEffect(() => {
    if (deleteGolfCourseFormatsById !== null) {
      dispatch({
        type: DELETE_GOLF_COURSE_FORMATS_BY_ID_EMPTY,
      });

      dispatch({
        type: GET_GOLF_COURSE_FORMATS,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          },
        },
      });
    }

    if (createGolfCourse !== null) {
      dispatch({
        type: CREATE_GOLF_COURSE_SLOT_EMPTY,
      });

      dispatch({
        type: GET_GOLF_COURSE_FORMATS,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          },
        },
      });
    }
  }, [deleteGolfCourseFormatsById]);

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedDetails(params?.details);
    setCurrentConfirmationModalActionFor(params?.actionType);

    if (params?.actionType === `VIEW_FORMAT`) {
      setIsViewCourseFormatShow(true);
      setIsMainPage(false);

      dispatch({
        type: GET_GOLF_COURSE_FORMATS_BY_ID,
        payload: {
          urlParam: {
            id: params?.details?.id,
          },
        },
      });
    } else if (params?.actionType === `UNASSIGNED`) {
      setIsUnassignedModalShow(true);
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);

    dispatch({
      type: GET_GOLF_COURSE_FORMATS,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  const assignGolfCourseHandler = () => {
    setIsAssignGolfCourseModalShow(true);
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (golfCourseFormatsLoading || deleteGolfCourseFormatsByIdLoading || golfCoursesLoading || createGolfCourseLoading || golfCourseFormatLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [golfCourseFormatsLoading, deleteGolfCourseFormatsByIdLoading, golfCoursesLoading, createGolfCourseLoading, golfCourseFormatLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Golf Admin`) {
      // do nothing
    } else if (title === `Course Wise Formats`) {
      setIsMainPage(true);
      setIsViewCourseFormatShow(false);
      dispatch({
        type: GET_GOLF_COURSE_FORMATS_BY_ID_EMPTY,
      });
    } else if (title === `Format`) {
      // do nothing
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {/* ---------------------------- MODAL'S ---------------------------- */}
          {isAssignGolfCourseModalShow && (
            <AssignGolfCourseModal data={{ golfCourses: golfCourses }} onCloseHandler={() => setIsAssignGolfCourseModalShow(false)} />
          )}
          {isUnassignedModalShow && <UnassignedModal data={currentSelectedDetails} onCloseHandler={() => setIsUnassignedModalShow(false)} />}
          {/*  ----------------------------  UI'S ---------------------------- */}
          {isMainPage && (
            <>
              <BreadCrumb title="Benefits" navigationby={breadcrumNavigationHandler} navigation={[`Golf Admin`, `Course Wise Formats`]} />
              <GolfCourseSlots
                data={golfCourseFormats?.results}
                totalData={golfCourseFormats?.count}
                pageNumber={currentPageNumber}
                pageHandler={paginationHandler}
                actionHandler={tableActionHandler}
                primaryBtnHandler={assignGolfCourseHandler}
              />
            </>
          )}
          {isViewCourseFormatShow && (
            <>
              <BreadCrumb title="Benefits" navigationby={breadcrumNavigationHandler} navigation={[`Golf Admin`, `Course Wise Formats`, `Format`]} />

              {golfCourseFormat?.results !== null && (
                <ViewCourseFormat
                  data={{
                    golfCourseFormat: golfCourseFormat?.results,
                  }}
                />
              )}
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default CourseWiseFormats;
