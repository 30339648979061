import React, { useEffect, useState } from 'react';

import { Grid, _ } from 'gridjs-react';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { PreviewCardHeader } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import ReactPaginate from 'react-paginate';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';
import { hasPermission } from '../../../../../../Helpers/Helpers';
import moment from 'moment';

const logsInfoStyle = {
  cursor: 'pointer',
  position: 'relative',
  top: ' 3px',
  fontSize: '17px',
  left: '3px',
  zIndex: '0',
};

const Table = props => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      setData(
        props?.data?.map((data, index) => {
          return [
            `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            data || `NA`,
            data?.category?.name || `NA`,
            data?.partner?.name || `NA`,
            data?.facility?.name || `NA`,
            data?.bookingType?.name || `NA`,
            data?.mql?.isActive ? 'Yes' : 'No' || `NA`,
            data || `NA`,
            data,
          ];
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `BLOCK`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title={props.title} isShowFilterBtn={props?.isShowFilterBtn} filterBtnHandler={props?.filterBtnHandler} />

            <CardBody>
              <div id="table-gridjs">
                <Grid
                  data={data}
                  columns={[
                    {
                      name: 'S.No.',
                      width: '60px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Name',
                      width: '200px',
                      formatter: (cell, row) => _(<span className="fw-semibold">{cell?.displayName}</span>),
                    },

                    {
                      name: 'Category',
                      width: '170px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Brand',
                      width: '170px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Outlet',
                      width: '170px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Booking Type',
                      width: '170px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Is MQL Active?',
                      width: '170px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Last updated',
                      width: '190px',
                      formatter: cell =>
                        _(
                          <span>
                            {cell?.updatedAt ? moment(new Date(`${cell?.updatedAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA'}

                            <span
                              style={logsInfoStyle}
                              onClick={() => {
                                actionBtnHandler(`LOGS`, cell);
                              }}
                            >
                              <i className="ri-information-fill"></i>
                            </span>
                          </span>,
                        ),
                    },

                    {
                      name: 'Actions',
                      width: '120px',
                      formatter: (cell, row) =>
                        _(
                          <div className="d-flex justify-content-center flex-column">
                            {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.SERVICE_BLOCKED_DATES) && (
                              <span
                                className="btn btn-sm btn-danger mb-1"
                                onClick={() => {
                                  actionBtnHandler(`BLOCK`, cell);
                                }}
                              >
                                Block
                              </span>
                            )}
                          </div>,
                        ),
                    },
                  ]}
                  search={false}
                  sort={false}
                  fixedHeader={true}
                  height="400px"
                  pagination={{ enabled: false, limit: 5 }}
                  style={{
                    th: {
                      zIndex: '999',
                    },
                    td: {
                      position: 'relative',
                    },
                  }}
                />
              </div>
              {props.children}
            </CardBody>

            <CardFooter>
              <Row className="custom_pagination">
                <Col className="text-end">
                  <ReactPaginate
                    previousLabel={'previous'}
                    forcePage={props?.pageNumber}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    pageCount={Math.ceil(props?.totalData / Constant.TABLE_PAGE_SIZE)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={event => {
                      props.pageHandler(event.selected);
                    }}
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Table, (prevProps, nextProps) => prevProps.data === nextProps.data);
