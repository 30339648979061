import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import { PreviewCardHeader, TablePagination } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';
import Countdown from 'react-countdown';
import { formattedWithComma, hasPermission, isDatePassed } from '../../../../../../Helpers/Helpers';
import DataTable from 'react-data-table-component';

const Table = props => {
  const [data, setData] = useState([]);

  const [allData, setAllData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [selectedBookings, setSelectedBookings] = useState([]);
  const [serialColumnNumber, setSerialColumnNumber] = useState(1);
  const [isBulkActionShow, setIsBulkActionShow] = useState(false);
  const [currentFilterBookingStatus, setCurrentFilterBookingStatus] = useState(0);
  const [currentFilteredBookingTitle, setCurrentFilterBookingTitle] = useState('Bookings');
  const [columns, setColumns] = useState([]);

  const { binList, binListLoading } = useSelector(state => ({
    binList: state.BinsList.data,
    binListLoading: state.BinsList.loading,
  }));

  useEffect(() => {
    getBookingStatusTitleHandler(parseInt(props?.appliedFilters?.status));

    if (props?.data?.length > 0) {
      setAllData(props?.data);
      setBookingData(
        props?.data?.map(booking => {
          return { ...booking, checkboxValue: false };
        }),
      );
      setSelectedBookings([]);
      setIsBulkActionShow(false);
      getBookingStatusTitleHandler(parseInt(props?.appliedFilters?.status));
      setCurrentFilterBookingStatus(parseInt(props?.appliedFilters?.status));
    } else {
      setBookingData([]);
      setSelectedBookings([]);
      setCurrentFilterBookingStatus(0);
      setIsBulkActionShow(false);
    }
  }, [props?.data]);

  useEffect(() => {
    if (bookingData?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No.',
          width: '60px',
          wrap: true,
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
        },
        {
          name: 'Booking ID',
          width: '150px',
          selector: row => (
            <span
              className="fw-semibold"
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                color: '#3577f1',
              }}
              onClick={() => actionBtnHandler(`VIEW`, row?.data)}
            >
              {row?.data?.bookingId}
            </span>
          ),
        },
        {
          name: 'Full Name',
          width: '160px',
          wrap: true,
          selector: row => row?.name,
        },
        {
          name: 'Mobile No.',
          width: '140px',
          wrap: true,
          selector: row => row?.mobileNumber,
        },
        {
          name: 'Email',
          width: '250px',
          wrap: true,
          selector: row => row?.email,
        },
        {
          name: 'Date Of Visit',
          width: '140px',
          wrap: true,
          selector: row => row?.dateOfVisit,
        },
        {
          name: 'Time of Visit',
          width: '140px',
          wrap: true,
          selector: row => row?.timeOfVisit,
        },
        {
          name: 'Outlet',
          width: '200px',
          wrap: true,
          selector: row => row?.outletName,
        },
        {
          name: 'Category',
          width: '200px',
          wrap: true,
          selector: row => row?.category,
        },
        {
          name: 'Bin number',
          width: '200px',
          wrap: true,
          selector: row => row?.binNumber,
        },
        {
          name: 'Last four digits',
          width: '200px',
          wrap: true,
          selector: row => row?.lastFourDigits,
        },
        {
          name: 'Updated At',
          width: '180px',
          wrap: true,
          selector: row => row?.updatedAt,
        },

        {
          name: 'Status',
          width: '140px',
          selector: row => (
            <span
              className={`booking_status break-word badge bg-${Constant.BOOKING_STATUSES.filter(status => status?.name === row?.status)[0]?.bgColor}`}
            >
              {row?.status}
            </span>
          ),
        },

        {
          name: 'Actions',
          width: '200px',
          selector: row => (
            <div className="d-flex justify-content-center flex-column">
              {
                // DEFAULT
              }
              {row?.orignalData?.workflow === Constant.WORKFLOWS[0] && (
                <>
                  {console.log('check', row?.orignalData)}
                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CLUB_BOOKING_UPDATE) &&
                    row?.orignalData?.bookingStatusCode === Constant.BOOKING_STATUS.PENDING && (
                      <>
                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`PROCESS`, row?.orignalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDatePassed(row?.orignalData?.bookingDateTime)}
                        >
                          Process
                        </button>

                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`RESEND_EMAIL`, row?.orignalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDatePassed(row?.orignalData?.bookingDateTime)}
                        >
                          Resend Email
                        </button>

                        <button
                          className="btn btn-sm btn-danger mb-1"
                          onClick={() => {
                            actionBtnHandler(`CANCEL`, row?.orignalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDatePassed(row?.orignalData?.bookingDateTime)}
                        >
                          Cancel
                        </button>
                      </>
                    )}

                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CLUB_BOOKING_UPDATE) &&
                    row?.orignalData?.bookingStatusCode === Constant.BOOKING_STATUS.PROCESSED && (
                      <>
                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`CONFIRM`, row?.orignalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDatePassed(row?.orignalData?.bookingDateTime)}
                        >
                          Confirm
                        </button>

                        <button
                          className="btn btn-sm btn-danger mb-1"
                          onClick={() => {
                            actionBtnHandler(`CANCEL`, row?.orignalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDatePassed(row?.orignalData?.bookingDateTime)}
                        >
                          Cancel
                        </button>
                      </>
                    )}

                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CLUB_BOOKING_UPDATE) &&
                    row?.orignalData?.bookingStatusCode === Constant.BOOKING_STATUS.CONFIRM && (
                      <>
                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`RESEND_EMAIL`, row?.orignalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDatePassed(row?.orignalData?.bookingDateTime)}
                        >
                          Resend Email
                        </button>

                        <button
                          className="btn btn-sm btn-danger mb-1"
                          onClick={() => {
                            actionBtnHandler(`CANCEL`, row?.orignalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDatePassed(row?.orignalData?.bookingDateTime)}
                        >
                          Cancel
                        </button>
                      </>
                    )}

                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CLUB_BOOKING_UPDATE) &&
                    row?.orignalData?.bookingStatusCode === Constant.BOOKING_STATUS.CANCEL_BY_THRIWE && (
                      <>
                        <button
                          className="btn btn-sm btn-primary mb-1"
                          onClick={() => {
                            actionBtnHandler(`RESEND_EMAIL`, row?.orignalData);
                          }}
                          style={{ width: '150px' }}
                          disabled={isDatePassed(row?.orignalData?.bookingDateTime)}
                        >
                          Resend Email
                        </button>
                      </>
                    )}
                </>
              )}
            </div>
          ),
        },
      ];
      setAllData();
      setData(
        bookingData?.map((data, index) => {
          const status = Constant?.BOOKING_STATUSES?.filter(statusObj => statusObj.objectId === data?.bookingStatusCode)[0]?.name;
          return {
            // false,
            serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            data: data || `NA`,
            name: data?.user?.name || `NA`,
            mobileNumber: data?.user?.mobile || `NA`,
            email: data?.user?.email || `NA`,

            dateOfVisit: data?.dateOfPlay ? moment(new Date(`${data?.dateOfPlay}`)).format('DD-MM-YYYY') : 'NA',
            timeOfVisit: data?.timeOfPlay ? moment(`${data?.timeOfPlay}`, 'HH:mm').format('h:mm A') : `NA`,

            outletName: data?.facility?.name || `NA`,
            category: data?.category?.name || `NA`,

            binNumber: data?.cardDetails?.firstSix || `NA`,
            lastFourDigits: data?.cardDetails?.lastFour || `NA`,

            updatedAt: data?.updatedAt ? moment(new Date(`${data?.updatedAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            status: data?.bookingStatusCode ? status : 'NA',
            orignalData: data || 'NA',
          };
        }),
      );
      setColumns(fetchColumns);
      setTimeout(() => {
        colorizeSlaBreached();
      }, [100]);
    } else {
      setData([]);
    }
  }, [bookingData, binList]);

  setTimeout(() => {
    colorizeSlaBreached();
  }, [100]);

  /* ---------------------------- COLORIZE SLA BREACH ---------------------------- */
  function colorizeSlaBreached() {
    if (props?.data?.length > 0) {
      const slaBreachedTableIndexes = props?.data
        ?.map((bookingData, index) => {
          if (bookingData?.slaDetails?.slaBreached) {
            return index;
          }
        })
        .filter(data => data !== undefined);

      Array.from(document.querySelectorAll('.gridjs-tr')).map((row, index) => {
        if (slaBreachedTableIndexes?.includes(index - 1)) {
          row.style.backgroundColor = `#ec7b65`;
          row.style.color = `#fff`;
        }
      });
    } else {
      Array.from(document.querySelectorAll('.gridjs-tr')).map((row, index) => {
        row.style.backgroundColor = `#fff`;
        row.style.color = `black`;
      });
    }
  }

  /* ---------------------------- BOOKING TABLE TITLE HANDLER ---------------------------- */
  const getBookingStatusTitleHandler = bookingsStatusCode => {
    let bookingsTitle = 'Bookings';

    if (bookingsStatusCode !== 0) {
      Constant?.BOOKING_STATUSES?.forEach(bookingStatus => {
        if (bookingsStatusCode === bookingStatus?.objectId) {
          bookingsTitle = `${bookingStatus?.name === 'Short Notice Cancelled By Thriwe' ? 'No Show' : `${bookingStatus?.name} Bookings`}`;
        }
      });
    }
    setCurrentFilterBookingTitle(bookingsTitle);
  };

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `EDIT`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `VIEW`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `PROCESS`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CONFIRM`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CANCEL`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `UPDATE`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `RESEND_EMAIL`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CONFIRM_WITH_DATA`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{currentFilteredBookingTitle}</h4>

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_ACCESS) && props?.data?.length > 0 && (
                <span className="btn btn-success mb-1" onClick={props?.dumpDownload}>
                  <i className="ri-download-line me-1 text-white fs-14 align-middle"></i>Download dump
                </span>
              )}
            </CardHeader>

            {/* ------------------------ TABLE DETAILS ------------------------ */}
            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Table);
