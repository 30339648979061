import { useEffect, useState } from 'react';

import * as moment from 'moment';
import Flatpickr from 'react-flatpickr';
import { Card, CardBody, Col, Form, Label, Row, CardFooter, Input } from 'reactstrap';

import { PreviewCardHeader } from '../../../../../../../Common';
import { Constant } from '../../../../../../../Helpers/constant';
import { isValidAlphaNumbericWithoutSpace } from '../../../../../../../Helpers/Helpers';

const statuses = [
  {
    name: `Requested`,
    objectId: Constant?.BOOKING_STATUS?.REQUESTED,
  },
  {
    name: `Confirmed`,
    objectId: Constant?.BOOKING_STATUS?.CONFIRM,
  },
  {
    name: `Completed`,
    objectId: Constant?.BOOKING_STATUS?.COMPLETED,
  },
  {
    name: `Cancelled`,
    objectId: Constant?.BOOKING_STATUS?.CANCELLED,
  },
  {
    name: `Rejected`,
    objectId: Constant?.BOOKING_STATUS?.REJECTED,
  },
];

const Filter = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    vendorStatus: Constant?.BOOKING_STATUS?.REQUESTED,
    createdAt: ``,
    createdAtRange: {
      from: ``,
      to: ``,
    },
    bookingDate: ``,
    bookingDateRange: {
      from: ``,
      to: ``,
    },
    updatedAtRange: {
      from: ``,
      to: ``,
    },
    bookingId: ``,
    slaBreached: false,
    sortOn: 'updatedAt',
    flightNumber: ``,
    journeyType: ``,
  };
  const [filter, setFilter] = useState(initialState);
  const [isResetDisabled, setIsResetDisabled] = useState(true);

  useEffect(() => {
    if (props?.appliedFilters) {
      setFilter(props?.appliedFilters);
    }
  }, [props?.appliedFilters]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...filter };

    let tempFlightNumber = ``;
    let tempBookingId = ``;

    switch (name) {
      case 'status-type':
        updatedState = {
          ...filter,
          vendorStatus: parseInt(value),
        };
        break;

      case 'sla':
        updatedState = { ...filter, slaBreached: value === 'true' };
        break;

      case 'sortOn':
        updatedState = { ...filter, sortOn: value };
        break;

      case 'bookingId':
        if (value?.length === 0) {
          tempBookingId = ``;
        } else {
          tempBookingId = isValidAlphaNumbericWithoutSpace(value) ? value : filter?.bookingId;
        }
        updatedState = { ...filter, bookingId: tempBookingId };
        break;

      case 'flightNumber':
        if (value?.length === 0) {
          tempFlightNumber = ``;
        } else {
          tempFlightNumber = isValidAlphaNumbericWithoutSpace(value) ? value : filter?.flightNumber;
        }
        updatedState = { ...filter, flightNumber: tempFlightNumber };
        break;

      case 'journeyType':
        updatedState = { ...filter, journeyType: value };
        break;

      default:
      // do nothing
    }

    setFilter(updatedState);
    validationCheck(updatedState);
  };

  /* ---------------------------- DATE PICKR HANDLER ---------------------------- */
  const datePickHandler = (flag, date) => {
    let updatedState = { ...filter };

    switch (flag) {
      case 'createdAtRange':
        updatedState = {
          ...filter,
          createdAtRange: {
            from: moment(new Date(date[0])).format('Y-MM-DD'),
            to: moment(new Date(date[1])).format('Y-MM-DD'),
          },
        };
        break;

      case 'updatedAtRange':
        updatedState = {
          ...filter,
          updatedAtRange: {
            from: moment(new Date(date[0])).format('Y-MM-DD'),
            to: moment(new Date(date[1])).format('Y-MM-DD'),
          },
        };
        break;

      case 'bookingDateRange':
        updatedState = {
          ...filter,
          bookingDateRange: {
            from: moment(new Date(date[0])).format('Y-MM-DD'),
            to: moment(new Date(date[1])).format('Y-MM-DD'),
          },
        };
        break;

      default:
      // do nothing
    }

    setFilter(updatedState);
    validationCheck(updatedState);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (
      data?.status !== 0 ||
      data?.createdAtRange?.from !== `` ||
      data?.createdAtRange?.to !== `` ||
      data?.updatedAtRange?.from !== `` ||
      data?.updatedAtRange?.to !== `` ||
      data?.bookingDateRange?.from !== `` ||
      data?.bookingDateRange?.to !== `` ||
      data?.bookingId !== `` ||
      data?.slaBreached !== `` ||
      data?.sortOn !== `` ||
      data?.flightNumber !== `` ||
      data?.journeyType !== ``
    ) {
      setIsResetDisabled(false);
    } else {
      setIsResetDisabled(true);
    }
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    let filterValue = { ...filter };
    props.filterHandler({ ...filterValue });
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter(initialState);
    setIsResetDisabled(true);
    props.filterHandler({ ...initialState, filter: false });
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Filter" />
            {/* <button onClick={() => console.log({ filter })}>get state</button> */}
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="status-type" className="form-label">
                          Booking Status
                        </Label>
                        <select
                          id="status-type"
                          name="status-type"
                          className="form-select"
                          value={filter?.vendorStatus}
                          onChange={inputChangeHandler}
                        >
                          {statuses?.map((status, index) => (
                            <option key={index} value={status.objectId}>
                              {status.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="created-at-range" className="form-label">
                          Created At - Range
                          <span className="icon_calendar">
                            <i className="las la-calendar"></i>
                          </span>
                        </Label>
                        <div className="area_expandable">
                          <Flatpickr
                            className="form-control"
                            onChange={date => datePickHandler(`createdAtRange`, date)}
                            value={[filter?.createdAtRange?.from, filter?.createdAtRange?.to]}
                            options={{
                              mode: 'range',
                              dateFormat: 'Y-m-d',
                              maxDate: 'today',
                            }}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="date-of-play-range" className="form-label">
                          Updated At - Range
                          <span className="icon_calendar">
                            <i className="las la-calendar"></i>
                          </span>
                        </Label>
                        <div className="area_expandable">
                          <Flatpickr
                            className="form-control"
                            value={[filter?.updatedAtRange?.from, filter?.updatedAtRange?.to]}
                            onChange={date => datePickHandler(`updatedAtRange`, date)}
                            options={{
                              mode: 'range',
                              dateFormat: 'Y-m-d',
                              maxDate: 'today',
                            }}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="date-of-play-range" className="form-label">
                          Booking Date - Range
                          <span className="icon_calendar">
                            <i className="las la-calendar"></i>
                          </span>
                        </Label>
                        <div className="area_expandable">
                          <Flatpickr
                            className="form-control"
                            value={[filter?.bookingDateRange?.from, filter?.bookingDateRange?.to]}
                            onChange={date => datePickHandler(`bookingDateRange`, date)}
                            options={{
                              mode: 'range',
                              dateFormat: 'Y-m-d',
                              // maxDate: 'today',
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="sla" className="form-label">
                          SLA
                        </Label>
                        <select id="sla" name="sla" className="form-select" value={filter?.slaBreached?.toString()} onChange={inputChangeHandler}>
                          <option value="false">None</option>
                          <option value="true">SLA Breach</option>
                        </select>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="sortOn" className="form-label">
                          Sort By
                        </Label>
                        <select id="sortOn" name="sortOn" className="form-select" value={filter?.sortOn} onChange={inputChangeHandler}>
                          <option value="updatedAt">Updated At</option>
                          <option value="createdAt">Created At</option>
                        </select>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="flightNumber" className="form-label">
                          Flight No.
                        </Label>
                        <Input
                          id="flightNumber"
                          name="flightNumber"
                          type="text"
                          className="form-control"
                          value={filter?.flightNumber}
                          onChange={inputChangeHandler}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="bookingId" className="form-label">
                          Booking ID
                        </Label>
                        <Input
                          id="bookingId"
                          name="bookingId"
                          type="text"
                          className="form-control"
                          value={filter?.bookingId}
                          onChange={inputChangeHandler}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="journeyType" className="form-label">
                          Journey Type
                        </Label>
                        <select id="journeyType" name="journeyType" className="form-select" value={filter?.journeyType} onChange={inputChangeHandler}>
                          <option value="">All</option>
                          <option value="pickup">Airport Pickup</option>
                          <option value="drop">Airport Drop</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>

            <CardFooter>
              <Row>
                <Col md={12}>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary mx-3 overlay-disable" onClick={filterSubmitHandler}>
                      Search
                    </button>

                    <button type="submit" className="btn btn-primary overlay-disable" onClick={resetFilterHandler} disabled={isResetDisabled}>
                      Reset Filter
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Filter;
