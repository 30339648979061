import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Card, CardBody, Col, Form, Label, Row, Input, CardFooter } from 'reactstrap';
import { Error, PreviewCardHeader } from '../../../../../../Common';
import ReactTooltip from 'react-tooltip';
import { CREATE_INTEGRATION_DATA, GET_INTEGRATION_DATA_EMPTY, UPDATE_INTEGRATION_DATA } from '../../../../../../../store/application/actionType';
import { isANumber } from '../../../../../../Helpers/Helpers';

const DataIntegrationForm = ({ data, formStatus, currentProject }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    projectCode: ``,
    hostName: '',
    port: '',
    userName: '',
    fileFormat: '',
    inFilePath: '',
    outFilePath: '',
    minutes: '',
    hours: '',
    dayOfMonth: '',
    month: '',
    dayOfWeek: '',
    years: '',
    headers: [''],
    uniqueKeyCombination: [''],
    connectionKey: false,
  };
  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);

  useEffect(() => {
    if (formStatus === `UPDATE` && data?.length === 1) {
      setState({ ...data[0], port: data[0]?.port?.toString() });
    }
  }, [formStatus]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    let tempPort = '';
    let tempMinute = '';
    let tempHour = '';

    switch (name) {
      case 'hostName':
        updatedState = {
          ...state,
          hostName: value,
          projectCode: `${currentProject?.code}`,
        };
        break;

      case 'port':
        if (value?.length === 0) {
          tempPort = ``;
        } else {
          tempPort = isANumber(value) ? value : state.port;
        }
        updatedState = {
          ...state,
          port: tempPort,
        };
        break;

      case 'userName':
        updatedState = {
          ...state,
          userName: value,
        };
        break;

      case 'fileFormat':
        updatedState = {
          ...state,
          fileFormat: value,
        };
        break;

      case 'inFilePath':
        updatedState = {
          ...state,
          inFilePath: value,
        };
        break;

      case 'outFilePath':
        updatedState = {
          ...state,
          outFilePath: value,
        };
        break;

      case 'minutes':
        if (value?.length === 0) {
          tempMinute = ``;
        } else {
          tempMinute = isANumber(value) ? value : state?.minutes;
        }
        updatedState = {
          ...state,
          minutes: tempMinute,
        };
        break;

      case 'hours':
        if (value?.length === 0) {
          tempHour = ``;
        } else {
          tempHour = isANumber(value) ? value : state?.hours;
        }
        updatedState = {
          ...state,
          hours: tempHour,
        };
        break;

      case 'dayOfMonth':
        updatedState = {
          ...state,
          dayOfMonth: value,
        };
        break;

      case 'month':
        updatedState = {
          ...state,
          month: value,
        };
        break;

      case 'dayOfWeek':
        updatedState = {
          ...state,
          dayOfWeek: value,
        };
        break;

      case 'years':
        updatedState = {
          ...state,
          years: value,
        };
        break;

      case 'connectionKey':
        updatedState = {
          ...state,
          connectionKey: e?.target?.checked,
        };
        break;

      default:
      // do nothing
    }
    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (flag, data = state) => {
    if (
      data?.hostName?.trim() !== `` &&
      data?.port?.trim() !== `` &&
      data?.userName?.trim() !== `` &&
      data?.fileFormat?.trim() !== `` &&
      data?.inFilePath?.trim() !== `` &&
      data?.outFilePath?.trim() !== `` &&
      data?.minutes?.trim() !== `` &&
      data?.hours?.trim() !== ``
    ) {
      const headerData = data?.headers?.filter(header => header?.trim() === ``);
      const uniqueKeyCombinationData = data?.uniqueKeyCombination?.filter(key => key?.trim() === ``);

      if (headerData?.length > 0 || uniqueKeyCombinationData?.length > 0) {
        setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
        return true;
      } else {
        setIsValidationShow(false);
        return false;
      }
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return true;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (!validateErrors(`submissionCheck`, state)) {
      const payload = {
        ...state,
        port: parseInt(state?.port),
      };

      dispatch({
        type: GET_INTEGRATION_DATA_EMPTY,
      });

      if (formStatus === `UPDATE`) {
        dispatch({
          type: UPDATE_INTEGRATION_DATA,
          payload: {
            data: payload,
          },
        });
      } else {
        dispatch({
          type: CREATE_INTEGRATION_DATA,
          payload: {
            data: payload,
          },
        });
      }
    }
  };

  const changeHeaderHandler = (flag, value, index) => {
    const tempHeaders = state?.headers;
    const tempUniqueKeyCombination = state?.uniqueKeyCombination;

    if (flag === 'Header') {
      tempHeaders?.splice(index, 1, value);
    } else if (flag === 'Key') {
      tempUniqueKeyCombination?.splice(index, 1, value);
    }

    const updatedState = {
      ...state,
      headers: [...tempHeaders],
      uniqueKeyCombination: [...tempUniqueKeyCombination],
    };
    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  const addHandler = (flag, index) => {
    const tempHeaders = state?.headers;
    const tempUniqueKeyCombination = state?.uniqueKeyCombination;

    if (flag === 'Header') {
      tempHeaders?.splice(index + 1, 0, '');
    } else if (flag === 'Key') {
      tempUniqueKeyCombination?.splice(index + 1, 0, '');
    }

    const updatedState = {
      ...state,
      headers: [...tempHeaders],
      uniqueKeyCombination: [...tempUniqueKeyCombination],
    };
    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  const removeHandler = (flag, index) => {
    const tempHeaders = state?.headers;
    const tempUniqueKeyCombination = state?.uniqueKeyCombination;

    if (flag === 'Header') {
      tempHeaders?.splice(index, 1);
    } else if (flag === 'Key') {
      tempUniqueKeyCombination?.splice(index, 1);
    }
    const updatedState = {
      ...state,
      headers: [...tempHeaders],
      uniqueKeyCombination: [...tempUniqueKeyCombination],
    };
    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Data Integration" />
            {/* <button onClick={() => console.log(state)}>get state</button> */}
            <CardBody>
              <div className="live-preview">
                <Row>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Host Name
                        <span className="lbl_mandatory"> *</span>
                      </Label>
                      <Input
                        id="hostName"
                        name="hostName"
                        type="text"
                        className="form-control"
                        onChange={inputChangeHandler}
                        value={state?.hostName}
                      />
                      {isValidationShow && state?.hostName?.trim() === `` && <Error text="Kindly enter host name" />}
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Port
                        <span className="lbl_mandatory"> *</span>
                      </Label>
                      <Input id="port" name="port" type="text" className="form-control" onChange={inputChangeHandler} value={state?.port} />
                      {isValidationShow && state?.port?.trim() === `` && <Error text="Kindly enter port" />}
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="form-label">
                        User Name
                        <span className="lbl_mandatory"> *</span>
                      </Label>
                      <Input
                        id="userName"
                        name="userName"
                        type="text"
                        className="form-control"
                        onChange={inputChangeHandler}
                        value={state?.userName}
                      />
                      {isValidationShow && state?.userName?.trim() === `` && <Error text="Kindly enter username" />}
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="form-label">
                        File Format
                        <span className="lbl_mandatory"> *</span>
                      </Label>
                      <Input
                        id="fileFormat"
                        name="fileFormat"
                        type="text"
                        className="form-control"
                        onChange={inputChangeHandler}
                        value={state?.fileFormat}
                      />
                      {isValidationShow && state?.fileFormat?.trim() === `` && <Error text="Kindly enter file format" />}
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="form-label">
                        In file path
                        <span className="lbl_mandatory"> *</span>
                      </Label>
                      <Input
                        id="inFilePath"
                        name="inFilePath"
                        type="text"
                        className="form-control"
                        onChange={inputChangeHandler}
                        value={state?.inFilePath}
                      />
                      {isValidationShow && state?.inFilePath?.trim() === `` && <Error text="Kindly enter file path" />}
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Out file path
                        <span className="lbl_mandatory"> *</span>
                      </Label>
                      <Input
                        id="outFilePath"
                        name="outFilePath"
                        type="text"
                        className="form-control"
                        onChange={inputChangeHandler}
                        value={state?.outFilePath}
                      />
                      {isValidationShow && state?.outFilePath?.trim() === `` && <Error text="Kindly enter file path" />}
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Minutes
                        <span className="lbl_mandatory"> *</span>
                      </Label>
                      <Input id="minutes" name="minutes" type="text" className="form-control" onChange={inputChangeHandler} value={state?.minutes} />
                      {isValidationShow && state?.minutes?.trim() === `` && <Error text="Kindly enter minutes" />}
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Hours
                        <span className="lbl_mandatory"> *</span>
                      </Label>
                      <Input id="hours" name="hours" type="text" className="form-control" onChange={inputChangeHandler} value={state?.hours} />
                      {isValidationShow && state?.hours?.trim() === `` && <Error text="Kindly enter hours" />}
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="form-label">Day of Month</Label>
                      <Input
                        id="dayOfMonth"
                        name="dayOfMonth"
                        type="text"
                        className="form-control"
                        onChange={inputChangeHandler}
                        value={state?.dayOfMonth}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="form-label">Month</Label>
                      <Input id="month" name="month" type="text" className="form-control" onChange={inputChangeHandler} value={state?.month} />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="form-label">Day of Week</Label>
                      <Input
                        id="dayOfWeek"
                        name="dayOfWeek"
                        type="text"
                        className="form-control"
                        onChange={inputChangeHandler}
                        value={state?.dayOfWeek}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="form-label">Years</Label>
                      <Input id="years" name="years" type="text" className="form-control" onChange={inputChangeHandler} value={state?.years} />
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="mb-3">
                      <Label className="form-label">Connection Key</Label>
                      <div className="d-flex align-items-center">
                        <span className="text-muted">False &nbsp;</span>
                        <div className="form-switch d-inline-block ">
                          <Input
                            className="form-check-input code-switcher form-switch-md"
                            type="checkbox"
                            id="connectionKey"
                            name="connectionKey"
                            checked={state?.connectionKey}
                            onChange={inputChangeHandler}
                          />
                        </div>
                        <span> &nbsp;True</span>
                      </div>
                    </div>
                  </Col>

                  <Col md={6}>
                    <Card className="card-inner">
                      <PreviewCardHeader title="Headers" />
                      <CardBody>
                        {state?.headers?.map((header, index) => (
                          <Row key={index}>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">Header-{`${index + 1}`}</Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="value"
                                  name="value"
                                  placeholder="Enter header"
                                  value={header}
                                  onChange={e => changeHeaderHandler(`Header`, e?.target?.value, index)}
                                />
                                {isValidationShow && header?.trim() === `` && <Error text="Please enter" />}
                              </div>
                            </Col>

                            <Col md={2} className="d-flex align-items-end gap-2">
                              <div className="mb-3">
                                <button type="submit" className="btn btn-primary" onClick={() => addHandler('Header', index)}>
                                  <i className=" ri-add-line"></i>
                                </button>
                              </div>
                              <div className="mb-3 ">
                                <button
                                  type="submit"
                                  className="btn btn-danger"
                                  onClick={() => removeHandler('Header', index)}
                                  disabled={state?.headers?.length === 1}
                                >
                                  <i className="ri-delete-bin-5-line"></i>
                                </button>
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md={6}>
                    <Card className="card-inner">
                      <PreviewCardHeader title="Unique key combination" />
                      <CardBody>
                        {state?.uniqueKeyCombination?.map((header, index) => (
                          <Row key={index}>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">Key-{`${index + 1}`}</Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="value"
                                  name="value"
                                  placeholder="Enter key"
                                  value={header}
                                  onChange={e => changeHeaderHandler(`Key`, e.target.value, index)}
                                />
                                {isValidationShow && header?.trim() === `` && <Error text="Please enter" />}
                              </div>
                            </Col>

                            <Col md={2} className="d-flex align-items-end gap-2">
                              <div className="mb-3">
                                <button type="submit" className="btn btn-primary" onClick={() => addHandler('Key', index)}>
                                  <i className="ri-add-line"></i>
                                </button>
                              </div>
                              <div className="mb-3 ">
                                <button
                                  type="submit"
                                  className="btn btn-danger"
                                  onClick={() => removeHandler('Key', index)}
                                  disabled={state?.uniqueKeyCombination?.length === 1}
                                >
                                  <i className="ri-delete-bin-5-line"></i>
                                </button>
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </CardBody>

            <CardFooter>
              <Row>
                <Col md={12}>
                  <div className="text-center">
                    <button type="submit" className="btn btn-primary mx-3 overlay-disable" onClick={submitHandler}>
                      {formStatus === `UPDATE` ? 'Update' : 'Create'}
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DataIntegrationForm;
