import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';

import { Button, Modal, ModalBody, ModalHeader, Col, Label, Input, Row } from 'reactstrap';

import { UPDATE_BENEFIT_PACK } from '../../../../../../../store/application/actionType';

const ChangeExpiryModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    id: '',
    updatedExpiry: '',
  };
  const [state, setState] = useState(initialObj);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [modalData, setModalData] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */

  useEffect(() => {
    setState({
      ...state,
      id: data?.objectId,
    });
  }, [data]);

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const payload = {
      request: [state],
    };

    dispatch({
      type: UPDATE_BENEFIT_PACK,
      payload: {
        data: payload,
      },
    });

    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'expiryDate':
        updatedState = {
          ...state,
          updatedExpiry: new Date(date).toISOString(),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data?.updatedExpiry !== ``) {
      setIsSubmitBtnDisabled(false);
    } else {
      setIsSubmitBtnDisabled(true);
    }
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <>
      <Modal
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1();
        }}
        id="exampleModal"
      >
        <div className="modal-header p-3 bg-soft-secondary">
          <h5 className="modal-title" id="createboardModalLabel">
            Update Pack Expiry
          </h5>
          <Button
            type="button"
            onClick={() => {
              tog_varying1();
            }}
            id="addBoardBtn-close"
            className="btn-close btn-light"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          {/* <button onClick={() => console.log({ state })}>Get state</button> */}
          <form>
            <Row>
              <div className="mb-3">
                <Col md={12}>
                  <div>
                    <Label htmlFor="name" className="form-label">
                      Benefit Group Name
                    </Label>
                    <Input className="form-control" value={data?.benefitGroup?.name} disabled />
                  </div>
                </Col>
              </div>

              <div className="mb-3">
                <Col md={12}>
                  <div>
                    <Label htmlFor="name" className="form-label">
                      Expiry Date
                      <span className="icon_calendar">
                        <i className="las la-calendar"></i>
                      </span>
                    </Label>
                    <Flatpickr
                      className="form-control"
                      name="expiry-date"
                      id="expiry-date"
                      value={state?.membershipData?.expiryDate}
                      onChange={date => datePickHandler(`expiryDate`, date)}
                      options={{
                        minDate: 'today',
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',
                      }}
                    />
                  </div>
                </Col>
              </div>
            </Row>
          </form>
        </ModalBody>
        <div className="modal-footer d-flex justify-content-center">
          <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ChangeExpiryModal;
