import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Row, Col, Input, Button, Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import { UPDATE_BOOKING } from '../../../../../../../../store/application/actionType';
import { Constant } from '../../../../../../../Helpers/constant';

const ChaufferAssign = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */

  const [bookings, setBookings] = useState([]);

  const [modal_varying1, setmodal_varying1] = useState(true);

  const [isOthers, setIsOthers] = useState(false);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  useEffect(() => {
    if (props?.data !== null) {
      setBookings(props?.data);
      validationCheck(props?.data);
    }
  }, [props]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = (e, index) => {
    const { name, value } = e.target;

    let tempBooking = [];
    let selectedChangingBooking = null;

    switch (name) {
      case 'name':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],

          chauffer: {
            ...tempBooking[index]['chauffer'],
            name: value,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'mobile':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],

          chauffer: {
            ...tempBooking[index]['chauffer'],
            mobile: value,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'car-number':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],

          chauffer: {
            ...tempBooking[index]['chauffer'],
            carNumber: value,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'car-type':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],

          chauffer: {
            ...tempBooking[index]['chauffer'],
            carType: value,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- VALIDATION HANDLER ---------------------------- */
  const validationCheck = data => {
    let flag = false;
    for (let i = 0; i < data?.length; i++) {
      if (data[i]?.chauffer?.name === `` || data[i]?.chauffer?.mobile === `` || data[i]?.chauffer?.carType === ``) {
        flag = true;
        break;
      }
    }

    flag ? setIsSubmitBtnDisabled(true) : setIsSubmitBtnDisabled(false);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const requestBookings = bookings?.map(booking => {
      return {
        objectId: booking?.bookingId,
        detailedBookingId: booking?.detailedBookingId,
        chauffer: {
          ...booking.chauffer,
        },
        status: 4,
        process: `update-booking`,
      };
    });

    dispatch({
      type: UPDATE_BOOKING,
      payload: {
        data: {
          projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
          bookingTypeId: Constant?.BOOKING_TYPES?.pickupAndDropBookings?.objectId,
          bookingsData: requestBookings,
        },
      },
    });
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      scrollable={true}
      id="exampleModal"
      size="lg"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Chauffeur Assign
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log({ bookings })}>get state</button> */}
        <form>
          <Row>
            <Col md={12} style={{ mixHeight: '500px' }}>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Booking ID</th>
                    <th> Name</th>
                    <th> Chauffeur Name</th>
                    <th> Chauffeur Mobile No.</th>
                    {/* <th>Car Number</th> */}
                    <th>Car Type</th>
                  </tr>
                </thead>
                <tbody>
                  {bookings &&
                    bookings?.map((booking, index) => (
                      <tr key={index}>
                        <th>{index + 1}.</th>
                        <td>
                          <span style={{ display: 'inline-block', width: '140px' }}>{booking?.bookingId}</span>
                        </td>
                        <td>
                          <span style={{ display: 'inline-block', width: '140px' }}>{booking?.user?.name}</span>
                        </td>

                        <td>
                          <Input
                            id="name"
                            name="name"
                            type="text"
                            placeholder="Name"
                            className="form-control"
                            style={{ width: '140px' }}
                            value={booking?.chauffer?.name}
                            onChange={e => inputChangeHandler(e, index)}
                          />
                        </td>
                        <td>
                          {' '}
                          <Input
                            id="mobile"
                            name="mobile"
                            type="number"
                            onWheel={e => e.target.blur()}
                            placeholder="Mobile"
                            className="form-control"
                            style={{ width: '140px' }}
                            value={booking?.chauffer?.mobile}
                            onChange={e => inputChangeHandler(e, index)}
                          />
                        </td>
                        {/* <td>
                          {' '}
                          <Input
                            id="car-number"
                            name="car-number"
                            type="number"
                            
                            placeholder="Car Number"
                            className="form-control"
                            style={{ width: '140px' }}
                            value={booking?.chauffer?.carNumber}
                            onChange={(e) => inputChangeHandler(e, index)}
                          />
                        </td> */}
                        <td>
                          <select
                            id="car-type"
                            name="car-type"
                            className="form-select"
                            style={{ width: '140px' }}
                            value={booking?.chauffer?.carType}
                            onChange={e => inputChangeHandler(e, index)}
                          >
                            <option value="">Choose...</option>
                            <option value="NA">NA</option>
                            <option value="Sedan">Sedan</option>
                            <option value="MVP">MVP</option>
                            <option value="Luxury">Luxury</option>
                          </select>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default ChaufferAssign;
