import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { Card, CardBody, Col, Row, Container, Label, CardFooter, Button, Input } from 'reactstrap';
import { Constant } from '../../../../../../Helpers/constant';
import cogoToast from 'cogo-toast';

const Details = ({ details }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: `smooth` });
  }, []);

  return (
    <>
      {/* <button onClick={() => console.log({ details })}>Get state</button> */}
      <Row>
        <Col lg={12}>
          <Card className="mt-n4 mx-n4 mb-n5">
            <div className="bg-soft-warning">
              <CardBody className="pb-4 mb-5">
                <Row>
                  <div className="col-md">
                    <Row className="align-items-center">
                      <div className="col-md-auto mb-1 mt-4"></div>
                      <div className="col-md">
                        <h4 className="fw-semibold" id="ticket-title">
                          {details?.id || ''}
                        </h4>
                      </div>
                    </Row>
                  </div>
                </Row>
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Core Details</h6>
              <div className="border mt-2 mb-3 border"></div>
              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Project Code'}</Label>
                    <Label className="text-muted">{details?.project_id || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Channel'}</Label>
                    <Label className="text-muted">{details?.channel}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Event Type'}</Label>
                    <Label className="text-muted">{details?.event_type}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Status'}</Label>
                    <Label className="text-muted">{details?.status}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Sent At'}</Label>
                    <Label className="text-muted">{details?.created_at ? moment(`${details?.created_at}`).format('DD-MM-YYYY, h:mm A') : `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Vendor Details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Request ID'}</Label>
                    <Label className="text-muted">{details?.vendor_request_id || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Vendor Status'}</Label>
                    <Label className="text-muted">{details?.vendor_status || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Template Details</h6>
              <div className="border mt-2 mb-3 border"></div>
              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Template ID'}</Label>
                    <Label className="text-muted">{details?.template_id || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Template Name'}</Label>
                    <Label className="text-muted">{details?.template_name}</Label>
                  </div>
                </Col>

                <Col md={12}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Template Variables'}</Label>
                    <Label className="text-muted">{details?.template_variable_value?.map(v => `${v}`)?.join(', ')}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Meta Info</h6>
              <div className="border mt-2 mb-3 border"></div>
              <Row>
                <Col md={11} xs={12}>
                  <div className="mb-3">
                    <div>
                      <Input type="textarea" className="form-control" rows="5" value={details?.meta_info} />
                    </div>
                  </div>
                </Col>

                <Col md={1}>
                  <button
                    className="btn btn-primary waves-effect waves-light material-shadow-none"
                    onClick={() => {
                      navigator.clipboard.writeText(details?.meta_info ?? '');
                      cogoToast.info('Text copied!');
                    }}
                  >
                    <i className="ri ri-file-copy-2-line"></i>
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Details;
