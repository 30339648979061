import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Col, Label, Row, Input, OffcanvasBody, OffcanvasHeader, Offcanvas } from 'reactstrap';
import { Constant } from '../../../../../Helpers/constant';

import { GET_BULK_USERS, GET_BULK_USERS_EMPTY } from '../../../../../../store/application/actionType';
import { isValidNumber } from '../../../../../Helpers/Helpers';

const CustomFilter = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    email: ``,
    joinedAtFrom: ``,
    firstName: ``,
    joinedAtTo: ``,
    phoneNumber: ``,
    membershipId: ``,
    firstSix: ``,
    lastFour: ``,
    cardName: ``,
  };
  const [filter, setFilter] = useState(initialState);
  const [isResetDisabled, setIsResetDisabled] = useState(true);

  useEffect(() => {
    if (props?.appliedFilters) {
      setFilter(props?.appliedFilters);
    }
  }, []);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...filter };

    switch (name) {
      case 'firstName':
        updatedState = {
          ...filter,
          firstName: value,
        };
        break;

      case 'email':
        updatedState = {
          ...filter,
          email: value,
        };
        break;

      case 'phoneNumber':
        updatedState = {
          ...filter,
          phoneNumber: isValidNumber(value) ? value : filter?.phoneNumber,
        };
        break;

      default:
      // do nothing
    }

    setFilter(updatedState);
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    props.filterHandler({ ...filter });
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter({ ...initialState });
    setIsResetDisabled(true);
    props?.setCurrentPageNumber(0);
    props.setCurrentlyAppliedFilters(null);

    props?.onReset();

    dispatch({
      type: GET_BULK_USERS_EMPTY,
    });

    dispatch({
      type: GET_BULK_USERS,
      payload: {
        urlParam: {
          pageIndex: 0,
          pageSize: Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  return (
    <>
      <Offcanvas direction="end" isOpen={props.show} toggle={() => props.onCloseClick()}>
        <OffcanvasHeader className="bg-light" toggle={() => props.onCloseClick()}>
          Customer Filters
        </OffcanvasHeader>
        <OffcanvasBody>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="email" className="form-label">
                  First Name
                </Label>
                <Input
                  id="name"
                  name="firstName"
                  type="text"
                  className="form-control"
                  value={filter?.firstName}
                  onChange={inputChangeHandler}
                  autoComplete="off"
                />
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="email" className="form-label">
                  Email
                </Label>
                <Input
                  id="email"
                  name="email"
                  type="text"
                  className="form-control"
                  value={filter?.email}
                  onChange={inputChangeHandler}
                  autoComplete="off"
                />
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="phoneNumber" className="form-label">
                  Mobile Number
                </Label>
                <Input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  className="form-control"
                  value={filter?.phoneNumber}
                  onChange={inputChangeHandler}
                  autoComplete="off"
                />
              </div>
            </Col>
          </Row>
        </OffcanvasBody>

        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
          <button className="btn btn-light w-100" onClick={resetFilterHandler}>
            Reset Filter
          </button>

          <button className="btn btn-primary w-100" onClick={filterSubmitHandler}>
            Search
          </button>
        </div>
      </Offcanvas>
    </>
  );
};

export default CustomFilter;
