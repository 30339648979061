import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { Constant } from '../../../../../../Helpers/constant';

import { Button, Modal, ModalBody, ModalHeader, Col, Label, Input, Row } from 'reactstrap';

import { GET_VENDORS_LIST_EMPTY, UPDATE_BOOKING } from '../../../../../../../store/application/actionType';
import axios from 'axios';
import { indianProjectsBaseURL } from '../../../../../../Config';
import { AsyncPaginate } from 'react-select-async-paginate';

const VendorAssignModalAirport = ({ modalFor, data, onCloseHandler, vendorsList }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    vendor: null,
  };
  const [state, setState] = useState(initialObj);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [modalData, setModalData] = useState(null);

  const [vendorsLists, setVendorsLists] = useState([]);

  useEffect(() => {
    if (vendorsList !== null) {
      setVendorsLists(
        vendorsList?.results?.map((vendor, vendorIndex) => {
          return {
            value: `${vendor.objectId}`,
            label: `${vendor.vendorName}`,
          };
        }),
      );
    }

    if (data?.vendor?.vendorId !== '') {
      const tempState = {
        vendor: {
          value: `${data?.vendor?.vendorId}`,
          label: `${data?.vendor?.name}`,
          completeData: {
            firstName: `${data?.vendor?.name?.split(' ')?.[0] ?? ''}`,
          },
        },
      };
      setState({ ...tempState });
      validationCheck({ ...tempState });
    }
  }, [data]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = null;

    if (label === `vendors`) {
      updatedState = {
        ...state,
        vendor: data,
      };
      setState(updatedState);
      validationCheck(updatedState);
    }
  };

  const getWorkflow = currentStatus => {
    if (Constant?.BOOKING_STATUS?.PENDING === currentStatus) {
      return 'assign-vendor';
    } else if (Constant?.BOOKING_STATUS?.PROCESSED === currentStatus) {
      return 'update-booking';
    } else if (Constant?.BOOKING_STATUS?.REJECTED === currentStatus) {
      return 'change-vendor';
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    dispatch({
      type: UPDATE_BOOKING,
      payload: {
        data: {
          projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
          bookingTypeId: Constant?.BOOKING_TYPES?.pickupAndDropBookings?.objectId,
          bookingsData: [
            {
              objectId: data?.bookingId,
              detailedBookingId: data?.detailedBookingId,
              vendor: {
                ...data?.vendor,
                bookingId: data?.bookingId,
                vendorId: state?.vendor?.value,
                name: `${state?.vendor?.completeData?.firstName}`,
              },
              process: getWorkflow(data?.bookingStatusCode),
            },
          ],
        },
      },
    });

    dispatch({
      type: GET_VENDORS_LIST_EMPTY,
    });

    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data?.vendor !== null) {
      setIsSubmitBtnDisabled(false);
    } else {
      setIsSubmitBtnDisabled(true);
    }
  };

  const tog_varying1 = () => {
    dispatch({
      type: GET_VENDORS_LIST_EMPTY,
    });

    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  /* ---------------------------- GET VENDORS ---------------------------- */
  async function loadVendors(search) {
    return await axios({
      method: `GET`,
      // url: `${uaeProjectsBaseURL}/admin/services`,
      url: `${indianProjectsBaseURL}/admin/v1/vendors`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        'Content-Type': 'application/json',
        'Project-Code': `ADMIN_ACCESS`,
      },
      params: {
        firstName: search,
        pageIndex: 0,
        pageSize: 15,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.vendors?.map(vendor => {
            return {
              value: vendor?.userId,
              label: `${vendor?.firstName ?? ''}`,
              completeData: vendor,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  /* ---------------------------- ON SELECT USER ---------------------------- */
  const onSelectVendor = event => {
    let updatedState = null;

    updatedState = {
      ...state,
      vendor: event,
    };
    validationCheck(updatedState);
    setState(updatedState);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
      //   scrollable={true}
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Assign Vendor
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log({ state })}>Get state</button> */}
        <form>
          <Row>
            <div className="mb-3">
              <div className="mb-3">
                <Col md={12}>
                  <div>
                    <Label htmlFor="name" className="form-label">
                      Booking ID
                    </Label>
                    <Input className="form-control" value={data?.bookingId} disabled />
                  </div>
                </Col>
              </div>

              <div className="mb-3">
                <Col md={12}>
                  <div>
                    <Label htmlFor="name" className="form-label">
                      User Name
                    </Label>
                    <Input className="form-control" value={data?.user?.name} disabled />
                  </div>
                </Col>
              </div>

              <Col md={12}>
                <div>
                  <Label htmlFor="pickup-address" className="form-label">
                    Vendors
                    <span className="lbl_mandatory"> *</span>
                  </Label>
                  <AsyncPaginate
                    debounceTimeout={500}
                    value={state?.vendor}
                    loadOptions={loadVendors}
                    onChange={e => onSelectVendor(e)}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    noOptionsMessage={() => 'No results found'}
                    cacheUniqs={[['code']]}
                    placeholder=""
                  />
                </div>
              </Col>
            </div>
          </Row>
        </form>
      </ModalBody>

      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
          Assign Vendor
        </Button>
      </div>
    </Modal>
  );
};

export default VendorAssignModalAirport;
