import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import { Button, Card, CardBody, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';

import LoginForm from './components/LoginForm';
import FullLoader from '../../../Common/Loader/CompleteLoader';
import { GET_PROJECT_UTILITY, GET_USER_PROFILE, VENDOR_LOGIN, VENDOR_OTP_VALIDATE, VENDOR_RESEND_OTP } from '../../../../store/actions';

import LoginType from './components/LoginType';
import { Constant } from '../../../Helpers/constant';
import ThriweWhite from '../../../../assets/custom/thriwe-white-logo.png';
import ParticlesAuth from '../../../../pages/AuthenticationInner/ParticlesAuth';
import LanguageDropdown from '../../../../Components/Common/LanguageDropdown';
import i18n from '../../../../i18n';
import { get, map } from 'lodash';
import languages from '../../../../Components/Common/languages';

const VendorLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [client, setClient] = useState('MASTERCARD_KSA');
  const { clientId } = useParams();

  // Declare a new state variable, which we'll call "menu"
  const [selectedLang, setSelectedLang] = useState(localStorage.getItem('I18N_LANGUAGE'));

  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const langParam = urlParams.get('lang');
    const validLang = langParam === 'en' || langParam === 'ar' ? langParam : 'en';
    setSelectedLang(validLang);
  }, []);

  const changeLanguageAction = lang => {
    //set language as i18n
    i18n.changeLanguage(lang);
    localStorage.setItem('I18N_LANGUAGE', lang);
    setSelectedLang(lang);
  };

  const [isLanguageDropdown, setIsLanguageDropdown] = useState(false);
  const toggleLanguageDropdown = () => {
    setIsLanguageDropdown(!isLanguageDropdown);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    //set language as i18n
    i18n.changeLanguage(urlParams.get('lang') ?? 'en');
    localStorage.setItem('I18N_LANGUAGE', urlParams.get('lang') ?? 'en');
  }, []);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Login
  const { vendorLogin, vendorLoginLoading } = useSelector(state => ({
    vendorLogin: state?.VendorLogin?.data,
    vendorLoginLoading: state?.VendorLogin?.loading,
  }));
  useEffect(() => {
    if (clientId && clientId == 'landmark') {
      setClient(clientId);
      dispatch({ type: GET_PROJECT_UTILITY });
    } else {
      localStorage.removeItem('projectUtility');
    }
  }, [clientId]);
  // Validate Otp
  const { vendorOtpValidate, vendorOtpValidateLoading } = useSelector(state => ({
    vendorOtpValidate: state?.VendorOtpValidate?.data,
    vendorOtpValidateLoading: state?.VendorOtpValidate?.loading,
  }));

  // Resend Otp
  const { vendorResendOtp, VendorResendOtpLoading } = useSelector(state => ({
    vendorResendOtp: state?.VendorResendOtp?.data,
    VendorResendOtpLoading: state?.VendorResendOtp?.loading,
  }));

  // Get User Profile
  const { getUserProfile, getUserProfileLoading } = useSelector(state => ({
    getUserProfile: state?.GetUserProfile?.data,
    getUserProfileLoading: state?.GetUserProfile?.loading,
  }));

  const [loginType, setLoginType] = useState(0);

  useEffect(() => {
    if (loginType === 1 || loginType === 2) {
      if (vendorLogin !== null) {
        dispatch({
          type: GET_USER_PROFILE,
          payload: {
            headers: {
              token: vendorLogin?.headers?.authorization,
            },
          },
        });
      }
    } else {
      if (vendorOtpValidate !== null) {
        dispatch({
          type: GET_USER_PROFILE,
          payload: {
            headers: {
              token: vendorOtpValidate?.headers?.authorization,
            },
          },
        });
      }
    }
  }, [vendorLogin, vendorOtpValidate]);

  useEffect(() => {
    if (vendorLogin !== null && loginType !== 3 && getUserProfile !== null) {
      if (getUserProfile?.user?.organization?.serviceOffered?.services?.includes('valet_parking')) {
        localStorage.setItem('userType', JSON.stringify(Constant?.userTypes?.VENDOR ?? -1));
        localStorage.setItem(
          'authUser',
          JSON.stringify({
            status: 'success',
            token: vendorLogin?.headers?.authorization,
            data: {
              ...vendorLogin?.data?.user,
              address: getUserProfile?.user?.addresses,
              facilityId: getUserProfile?.user?.facilityId,
            },
            clients: getUserProfile?.user?.clients,
            permissions: ['VENDOR_DASHBOARD', 'VENDOR_DOWNLOAD_DUMP', 'VENDOR_QR', 'VENDOR_VALET_PARKING', 'VENDOR_REPORT'],
          }),
        );
        window.localStorage.setItem(
          'data',
          JSON.stringify({
            program: {
              id: 'IaQlNnHgJr',
              name: 'Valet Parking',
              internalName: '',
              code: 'SAB',
              logo: '',
              url: '',
              configId: 'JjOfOscNo0',
              client: {
                id: 'qeOwLFd3O9',
                name: 'Valet Parking',
                contractingCountry: { id: 231, name: 'India' },
                isActive: true,
              },
              bookingTypes: [],
              contractingCountry: { id: '', name: '' },
            },
          }),
        );
        history.push(`/vendor/dashboard`);
        window.location.reload();
      } else if (getUserProfile?.user?.organization?.serviceOffered?.services?.includes('dining')) {
        localStorage.setItem('userType', JSON.stringify(Constant?.userTypes?.VENDOR ?? -1));
        localStorage.setItem(
          'authUser',
          JSON.stringify({
            status: 'success',
            token: vendorLogin?.headers?.authorization,
            data: {
              ...vendorLogin?.data?.user,
              address: getUserProfile?.user?.addresses,
              facilityId: getUserProfile?.user?.facilityId,
            },
            permissions: ['VENDOR_DASHBOARD', 'VENDOR_DOWNLOAD_DUMP', 'DINING_BOOKING_MANAGEMENT'],
          }),
        );
        window.localStorage.setItem(
          'data',
          JSON.stringify({
            program: {
              id: 'IaQlNnHgJr',
              name: 'Thriwe Dining',
              internalName: '',
              code: 'THRIWE_DINING_V1',
              logo: '',
              url: '',
              configId: 'JjOfOscNo0',
              client: {
                id: 'qeOwLFd3O9',
                name: 'Thriwe Dining',
                contractingCountry: { id: 231, name: 'India' },
                isActive: true,
              },
              bookingTypes: [],
              contractingCountry: { id: '', name: '' },
            },
          }),
        );
        history.push(`/vendor/dashboard`);
        window.location.reload();
      } else {
        localStorage.setItem('userType', JSON.stringify(Constant?.userTypes?.VENDOR ?? -1));
        localStorage.setItem(
          'authUser',
          JSON.stringify({
            status: 'success',
            token: vendorLogin?.headers?.authorization,
            data: {
              ...vendorLogin?.data?.user,
            },
            permissions: ['VENDOR_DASHBOARD', 'VENDOR_DOWNLOAD_DUMP', 'VENDOR_BOOKING_MANAGEMENT', 'VENDOR_AIRPORT_TRANSFER'],
          }),
        );
        window.localStorage.setItem(
          'data',
          JSON.stringify({
            program: {
              id: 'IaQlNnHgJr',
              name: 'ENBD',
              internalName: '',
              code: 'ENBD_V3',
              logo: '',
              url: '',
              configId: 'JjOfOscNo0',
              client: {
                id: 'qeOwLFd3O9',
                name: 'Enbd',
                contractingCountry: { id: 231, name: 'United Arab Emirates' },
                isActive: true,
              },
              bookingTypes: [],
              contractingCountry: { id: '', name: '' },
            },
          }),
        );
        history.push(`/vendor/dashboard`);
        window.location.reload();
      }
    }
  }, [getUserProfile]);

  useEffect(() => {
    if (vendorOtpValidate !== null && getUserProfile !== null) {
      if (getUserProfile?.user?.organization?.serviceOffered?.services?.includes('valet_parking')) {
        localStorage.setItem('userType', JSON.stringify(Constant?.userTypes?.VENDOR ?? -1));
        localStorage.setItem(
          'authUser',
          JSON.stringify({
            status: 'success',
            token: vendorOtpValidate?.headers?.authorization,
            data: {
              ...vendorOtpValidate?.data?.user,
              address: getUserProfile?.user?.addresses,
              facilityId: getUserProfile?.user?.facilityId,
            },
            clients: getUserProfile?.user?.clients,
            permissions: ['VENDOR_DASHBOARD', 'VENDOR_DOWNLOAD_DUMP', 'VENDOR_QR', 'VENDOR_VALET_PARKING', 'VENDOR_REPORT'],
          }),
        );
        window.localStorage.setItem(
          'data',
          JSON.stringify({
            program: {
              id: 'IaQlNnHgJr',
              name: 'Valet Parking',
              internalName: '',
              code: 'SAB',
              logo: '',
              url: '',
              configId: 'JjOfOscNo0',
              client: {
                id: 'qeOwLFd3O9',
                name: 'Valet Parking',
                contractingCountry: { id: 231, name: 'India' },
                isActive: true,
              },
              bookingTypes: [],
              contractingCountry: { id: '', name: '' },
            },
          }),
        );
        history.push(`/vendor/dashboard`);
        window.location.reload();
      } else if (getUserProfile?.user?.organization?.serviceOffered?.services?.includes('dining')) {
        localStorage.setItem('userType', JSON.stringify(Constant?.userTypes?.VENDOR ?? -1));
        localStorage.setItem(
          'authUser',
          JSON.stringify({
            status: 'success',
            token: vendorLogin?.headers?.authorization,
            data: {
              ...vendorLogin?.data?.user,
              address: getUserProfile?.user?.addresses,
              facilityId: getUserProfile?.user?.facilityId,
            },
            permissions: ['VENDOR_DASHBOARD', 'VENDOR_DOWNLOAD_DUMP', 'DINING_BOOKING_MANAGEMENT'],
          }),
        );
        window.localStorage.setItem(
          'data',
          JSON.stringify({
            program: {
              id: 'IaQlNnHgJr',
              name: 'Thriwe Dining',
              internalName: '',
              code: 'THRIWE_DINING_V1',
              logo: '',
              url: '',
              configId: 'JjOfOscNo0',
              client: {
                id: 'qeOwLFd3O9',
                name: 'Thriwe Dining',
                contractingCountry: { id: 231, name: 'India' },
                isActive: true,
              },
              bookingTypes: [],
              contractingCountry: { id: '', name: '' },
            },
          }),
        );
        history.push(`/vendor/dashboard`);
        window.location.reload();
      } else {
        localStorage.setItem('userType', JSON.stringify(Constant?.userTypes?.VENDOR ?? -1));
        localStorage.setItem(
          'authUser',
          JSON.stringify({
            status: 'success',
            token: vendorOtpValidate?.headers?.authorization,
            data: {
              ...vendorOtpValidate?.data?.user,
            },
            permissions: ['VENDOR_DASHBOARD', 'VENDOR_DOWNLOAD_DUMP', 'VENDOR_BOOKING_MANAGEMENT', 'VENDOR_AIRPORT_TRANSFER'],
          }),
        );
        window.localStorage.setItem(
          'data',
          JSON.stringify({
            program: {
              id: 'IaQlNnHgJr',
              name: 'ENBD',
              internalName: '',
              code: 'ENBD_V3',
              logo: '',
              url: '',
              configId: 'JjOfOscNo0',
              client: {
                id: 'qeOwLFd3O9',
                name: 'Enbd',
                contractingCountry: { id: 231, name: 'United Arab Emirates' },
                isActive: true,
              },
              bookingTypes: [],
              contractingCountry: { id: '', name: '' },
            },
          }),
        );
        history.push(`/vendor/dashboard`);
        window.location.reload();
      }
    }
  }, [getUserProfile]);

  const onLogin = data => {
    dispatch({
      type: VENDOR_LOGIN,
      payload: {
        data: {
          ...data,
          loginMethod: loginType === 1 || loginType === 2 ? 2 : 1,
        },
      },
    });
  };

  const onValidate = data => {
    dispatch({
      type: VENDOR_OTP_VALIDATE,
      payload: {
        data: {
          ...data,
        },
        headers: {
          token: vendorLogin?.headers?.authorization,
        },
      },
    });
  };

  const onResendOtp = () => {
    dispatch({
      type: VENDOR_RESEND_OTP,
      payload: {
        headers: {
          token: vendorLogin?.headers?.authorization,
        },
      },
    });
  };

  useEffect(() => {
    if (![null, undefined, -1].includes(window.localStorage.getItem('authUser'))) {
      let redirectionRoute = `/`;
      const userType = parseInt(JSON.parse(window.localStorage.getItem('userType') ?? -1));
      if (userType === Constant?.userTypes?.ADMIN_USER) {
        redirectionRoute = `/dashboard`;
      } else if (userType === Constant?.userTypes?.CLIENT) {
        redirectionRoute = `/client/dashboard`;
      } else if (userType === Constant?.userTypes?.VENDOR) {
        redirectionRoute = `/vendor/dashboard`;
      } else {
        redirectionRoute = `/dashboard`;
      }
      history.push(`${redirectionRoute}`);
    }
  }, []);

  return (
    <>
      <React.Fragment>
        <ParticlesAuth>
          <div className="auth-page-content">
            <div style={{ display: 'flex', justifyContent: 'right', position: 'absolute', top: '0px', right: '48px', color: '#fff', zIndex: 1000 }}>
              <Dropdown isOpen={isLanguageDropdown} toggle={toggleLanguageDropdown} className="ms-1 topbar-head-dropdown header-item">
                <DropdownToggle className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" tag="button">
                  <div>
                    <button
                      className="btn btn-icon btn-topbar btn-ghost-secondary"
                      style={{
                        color: '#333',
                        border: '1px solid #fff',
                        width: '108px',
                        background: '#fff',
                      }}
                    >
                      <img
                        src={get(languages, `${selectedLang}.flag`)}
                        alt="Header Language"
                        height="20"
                        className="rounded"
                        style={{ marginRight: '10px' }}
                      />
                      {selectedLang === 'en' ? 'English' : 'Arabic'}
                    </button>
                  </div>
                </DropdownToggle>
                <DropdownMenu className="notify-item language py-2">
                  {Object.keys(languages).map(key => (
                    <DropdownItem
                      key={key}
                      onClick={() => changeLanguageAction(key)}
                      className={`notify-item ${selectedLang === key ? 'active' : 'none'}`}
                    >
                      <img src={get(languages, `${key}.flag`)} alt="Skote" className="me-2 rounded" height="18" />
                      <span className="align-middle">{get(languages, `${key}.label`)}</span>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="text-center mt-sm-5 mb-4 text-white-50">
                    <div>
                      <p className="mt-3 fs-15 fw-medium">&nbsp;</p>
                      <Link to="/vendor/login" className="d-inline-block auth-logo">
                        <img src={ThriweWhite} alt="" height="50" />
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="mt-4" style={{ borderRadius: '40px' }}>
                    <CardBody className="p-md-5 p-sm-3">
                      <div style={{ display: 'flex', justifyContent: 'right' }}></div>
                      {!loginType ? (
                        <LoginType setLoginType={setLoginType} />
                      ) : (
                        <>
                          {(vendorLoginLoading || vendorOtpValidateLoading || VendorResendOtpLoading || getUserProfileLoading) && <FullLoader />}
                          <LoginForm
                            onSubmit={onLogin}
                            loginType={loginType}
                            setLoginType={setLoginType}
                            onValidate={onValidate}
                            onResendOtp={onResendOtp}
                          />
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </ParticlesAuth>
      </React.Fragment>
    </>
  );
};

export default VendorLogin;
