import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Row, Col, Button, Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import { UPDATE_BOOKING } from '../../../../../../../../store/application/actionType';
import { Constant } from '../../../../../../../Helpers/constant';

const CancelModal = props => {
  const dispatch = useDispatch();

  const cancellationResons = [
    'Requested Slot Not Available',
    'Tournament at the golf course',
    'Golf Course Fully Booked',
    'No response on recommendation',
    'Others',
  ];

  /* ---------------------------- LOCAL STATES ---------------------------- */

  const [bookings, setBookings] = useState([]);

  const [modal_varying1, setmodal_varying1] = useState(true);

  const [isOthers, setIsOthers] = useState(false);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  useEffect(() => {
    if (props?.data !== null) {
      setBookings(
        props?.data?.map(booking => {
          return {
            ...booking,
            remarksValue: ``,
            othersValue: ``,
            isRemarksOther: false,
          };
        }),
      );
    }
  }, [props]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = (e, index) => {
    const { name, value } = e.target;

    let tempBooking = [];
    let selectedChangingBooking = null;

    switch (name) {
      case 'cancellation remarks':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],
          remarksValue: value,
          othersValue: ``,
          isRemarksOther: value === 'Others' ? true : false,
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'others_value':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],
          othersValue: value,
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- VALIDATION HANDLER ---------------------------- */
  const validationCheck = data => {
    let flag = false;
    for (let i = 0; i < data?.length; i++) {
      if (data[i]?.remarksValue === ``) {
        flag = true;
        break;
      } else if (data[i]?.remarksValue === `Others`) {
        if (data[i]?.othersValue === ``) {
          flag = true;
          break;
        }
      }
    }

    flag ? setIsSubmitBtnDisabled(true) : setIsSubmitBtnDisabled(false);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const requestBookings = bookings?.map(booking => {
      return {
        objectId: booking?.bookingId,
        detailedBookingId: booking?.detailedBookingId,
        status: Constant?.BOOKING_STATUS?.CANCEL_BY_THRIWE,
        bookingRemarks: booking?.isRemarksOther ? booking?.othersValue : booking?.remarksValue,
        process: `cancel-booking`,
      };
    });

    dispatch({
      type: UPDATE_BOOKING,
      payload: {
        data: {
          projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
          bookingTypeId: Constant?.BOOKING_TYPES?.meetAndGreetBookings?.objectId,
          bookingsData: requestBookings,
        },
      },
    });
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      scrollable={true}
      size="lg"
      id="exampleModal"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Cancel Remarks
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log({ bookings })}>get state</button> */}
        <form>
          <Row>
            <Col md={12} style={{ mixHeight: '500px' }}>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Booking ID</th>
                    <th> Name</th>
                    <th> Cancellation Remarks</th>
                    <th> Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {bookings &&
                    bookings?.map((booking, index) => (
                      <tr key={index}>
                        <th>{index + 1}.</th>
                        <td>{booking?.bookingId}</td>
                        <td>{booking?.user?.name}</td>

                        <td>
                          <select
                            className="form-select"
                            name="cancellation remarks"
                            value={booking?.remarksValue}
                            onChange={e => inputChangeHandler(e, index)}
                          >
                            <option value="">Please select a reason for cancellation</option>
                            {cancellationResons?.map((cancellationReason, index) => (
                              <option value={cancellationReason} key={index}>
                                {cancellationReason}
                              </option>
                            ))}
                          </select>
                        </td>

                        <td>
                          <input
                            className="form-control"
                            type="text"
                            disabled={[undefined, false].includes(booking?.isRemarksOther)}
                            name="others_value"
                            value={booking?.othersValue}
                            onChange={e => inputChangeHandler(e, index)}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </form>
      </ModalBody>

      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default CancelModal;
