import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import apiCall from '../../apiCall';
import { baseUrl, customHeaders, uaeProjectsBaseURL } from './../../../../Containers/Config/index';

import { UPDATE_MEMBERSHIP, UPDATE_MEMBERSHIP_SUCCESS, UPDATE_MEMBERSHIP_ERROR } from './../../actionType';
import { Constant } from '../../../../Containers/Helpers/constant';
import cogoToast from 'cogo-toast';

function* updateMembership({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/memberships`,
      method: 'PUT',
      headers: { Authorization: Constant?.CLIENT_TOKEN, ...customHeaders },
      data: payload?.data,
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({ type: UPDATE_MEMBERSHIP_SUCCESS, payload: response });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({ type: UPDATE_MEMBERSHIP_ERROR, payload: response.error });
    }
  } catch (error) {
    yield put({ type: UPDATE_MEMBERSHIP_ERROR, payload: error });
  }
}

export function* watchUpdateMembership() {
  yield takeEvery(UPDATE_MEMBERSHIP, updateMembership);
}

function* updateMembershipSaga() {
  yield all([fork(watchUpdateMembership)]);
}

export default updateMembershipSaga;
