import { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Col, Label, Row, Input } from 'reactstrap';

const RemarksModal = ({ currentSelectedData, onCancel, onConfirm }) => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [currentPage, setCurrentPage] = useState(1);
  const [remarks, setRemarks] = useState('');

  return (
    <Modal isOpen={true} id="master-upload-rejection-remarks">
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title d-flex align-items-center">
          {currentPage === 2 && (
            <span
              className="fs-4 cursor-pointer d-inline-block me-2"
              onClick={() => {
                setRemarks('');
                setCurrentPage(1);
              }}
            >
              <i className=" ri-arrow-left-s-line"></i>
            </span>
          )}
          {currentSelectedData?.label?.includes('APPROVE') ? 'Acceptance' : 'Rejection'} Remarks
        </h5>
        <Button
          type="button"
          onClick={() => {
            onCancel();
          }}
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {currentPage === 1 && (
          <RemarksOptions currentSelectedData={currentSelectedData} remarks={remarks} setRemarks={setRemarks} setCurrentPage={setCurrentPage} />
        )}
        {currentPage === 2 && <Remarks remarks={remarks} setRemarks={setRemarks} />}
      </ModalBody>

      <ModalFooter className="pb-0">
        <div className="modal-footer mx-auto">
          <button
            className="btn rounded-3 mb-2 me-2 btn-primary"
            onClick={() => onConfirm(currentSelectedData, remarks)}
            disabled={['', null]?.includes(remarks?.trim())}
          >
            Submit
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default RemarksModal;

function RemarksOptions({ currentSelectedData, remarks, setRemarks, setCurrentPage }) {
  return (
    <Row>
      <Col>
        <div className="mb-3">
          <Label className="form-label">Select {currentSelectedData?.label?.includes('APPROVE') ? 'Acceptance' : 'Rejection'} remarks-</Label>
          <select
            id="remarks"
            name="remarks"
            className="form-control"
            value={remarks}
            onChange={e => {
              if (e.target.value == 2) {
                setCurrentPage(parseInt(e.target.value));
                setRemarks('');
              } else {
                setRemarks(e.target.value);
              }
            }}
          >
            <option value={``}>Select option...</option>
            <option value={`Value1`}>Value1</option>
            <option value={`Value2`}>Value2</option>
            <option value={2}>Others</option>
          </select>
        </div>
      </Col>
    </Row>
  );
}

function Remarks({ remarks, setRemarks }) {
  return (
    <Row>
      <Col>
        <Input
          rows={5}
          id="remarks"
          name="remarks"
          type="textarea"
          placeholder="Enter your remarks"
          className="form-control"
          value={remarks}
          onChange={e => setRemarks(e?.target?.value)}
        />
      </Col>
    </Row>
  );
}
