import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Select from 'react-select';

import { Button, Modal, ModalBody, ModalHeader, Col, Label, Input, Row } from 'reactstrap';

import { CREATE_CLIENTS } from '../../../../../../../store/application/actionType';
import { Error } from '../../../../../../Common';

const CreateClientModal = ({ countries, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    name: '',
    description: '',
    contractingCountry: null,
    clientType: ``,
    industry: ``,
  };
  const [state, setState] = useState(initialObj);
  const [isValidationShow, setIsValidationShow] = useState(false);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [allCountries, setAllCountries] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */

  useEffect(() => {
    if (countries !== null) {
      setAllCountries(
        countries?.map(country => ({
          value: country?.countryId,
          label: country?.name,
        })),
      );
    }
  }, [countries]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'name':
        updatedState = {
          ...state,
          name: value,
        };
        break;

      case 'description':
        updatedState = {
          ...state,
          description: value,
        };
        break;

      case 'clientType':
        updatedState = {
          ...state,
          clientType: value,
        };
        break;

      case 'industry':
        updatedState = {
          ...state,
          industry: value,
        };
        break;

      default:
      // do nothing
    }
    setState(updatedState);
    validateErrors(updatedState);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = null;

    if (label === `contractingCountry`) {
      updatedState = {
        ...state,
        contractingCountry: data,
      };
      setState(updatedState);
      validateErrors(updatedState);
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = data => {
    if (
      data?.name?.trim() !== `` &&
      data?.description?.time?.trim() !== `` &&
      data?.contractingCountry !== null &&
      data?.clientType?.trim() !== `` &&
      data?.industry?.trim() !== ``
    ) {
      setIsValidationShow(false);
      return false;
    } else {
      setIsValidationShow(true);
      return true;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (!validateErrors(state)) {
      const payload = {
        ...state,
        contractingCountry: {
          id: state?.contractingCountry?.value,
          name: state?.contractingCountry?.label,
        },
      };

      dispatch({
        type: CREATE_CLIENTS,
        payload: {
          data: {
            ...payload,
          },
        },
      });

      onCloseHandler(false);
      setmodal_varying1(!modal_varying1);
    }
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <>
      <Modal
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1();
        }}
        id="create-client"
      >
        <div className="modal-header p-3 bg-soft-secondary">
          <h5 className="modal-title" id="createboardModalLabel">
            Add Client
          </h5>
          <Button type="button" onClick={tog_varying1} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close"></Button>
        </div>

        <ModalBody>
          {/* <button onClick={() => console.log({ state })}>Get state</button> */}
          <form>
            <Row>
              <div className="mb-3">
                <Col md={12}>
                  <div>
                    <Label htmlFor="name" className="form-label">
                      Name
                      <span className="lbl_mandatory"> *</span>
                    </Label>
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      className="form-control"
                      onChange={inputChangeHandler}
                      value={state?.name}
                      autoComplete="off"
                    />
                    {isValidationShow && state?.name?.trim() === `` && <Error text="Please enter name" />}
                  </div>
                </Col>
              </div>

              <div className="mb-3">
                <Col md={12}>
                  <div>
                    <Label htmlFor="name" className="form-label">
                      Description
                      <span className="lbl_mandatory"> *</span>
                    </Label>
                    <Input
                      id="description"
                      name="description"
                      type="text"
                      className="form-control"
                      onChange={inputChangeHandler}
                      value={state?.description}
                      autoComplete="off"
                    />
                    {isValidationShow && state?.description?.trim() === `` && <Error text="Please enter description" />}
                  </div>
                </Col>
              </div>

              <div className="mb-3">
                <Col md={12}>
                  <div>
                    <Label htmlFor="clientType" className="form-label">
                      Client Type
                      <span className="lbl_mandatory"> *</span>
                    </Label>
                    <Input
                      id="clientType"
                      name="clientType"
                      type="text"
                      className="form-control"
                      onChange={inputChangeHandler}
                      value={state?.clientType}
                      autoComplete="off"
                    />
                    {isValidationShow && state?.clientType?.trim() === `` && <Error text="Please enter client type" />}
                  </div>
                </Col>
              </div>

              <div className="mb-3">
                <Col md={12}>
                  <div>
                    <Label htmlFor="industry" className="form-label">
                      Industry
                      <span className="lbl_mandatory"> *</span>
                    </Label>
                    <Input
                      id="industry"
                      name="industry"
                      type="text"
                      className="form-control"
                      onChange={inputChangeHandler}
                      value={state?.industry}
                      autoComplete="off"
                    />
                    {isValidationShow && state?.industry?.trim() === `` && <Error text="Please enter industry" />}
                  </div>
                </Col>
              </div>

              <div className="mb-3">
                <Col md={12}>
                  <div>
                    <Label htmlFor="" className="form-label">
                      Contracting Country
                      <span className="lbl_mandatory"> *</span>
                    </Label>
                    <Select
                      id="contracting-country"
                      name="contracting-country"
                      value={state?.contractingCountry}
                      options={allCountries}
                      onChange={data => inputSelectHandler(`contractingCountry`, data)}
                    />
                    {isValidationShow && state?.contractingCountry === null && <Error text="Please select country" />}
                  </div>
                </Col>
              </div>
            </Row>
          </form>
        </ModalBody>

        <div className="modal-footer d-flex justify-content-center">
          <Button className="overlay-disable" color="primary" onClick={submitHandler}>
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default CreateClientModal;
