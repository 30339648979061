import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from '../../../Common';
import { Container } from 'reactstrap';
import {
  CREATE_EXPENDITURE_RULE,
  CREATE_EXPENDITURE_RULE_EMPTY,
  DELETE_EXPENDITURE_RULE,
  DELETE_EXPENDITURE_RULE_EMPTY,
  LIST_EXPENDITURE_RULE,
  LIST_EXPENDITURE_RULE_EMPTY,
  UPDATE_EXPENDITURE_RULE_EMPTY,
} from '../../../../store/application/actionType';
import ExpenditureForm from './Components/Forms/ExpenditureForm';
import { Constant } from '../../../Helpers/constant';
import ExpenditureTable from './Components/Tables/ExpenditureTable';
import ActionConfirmation from './Components/Modals/ConfirmationModal';
import UpdateExpenditureRule from './Components/Modals/UpdateExpenditureModal';

const ExpenditureManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [isCreateForm, setIsCreateForm] = useState(false);

  const [currentSelectedDetails, setCurrentSelectedDetails] = useState([]);
  const [currentConfirmationModalActionFor, setCurrentConfirmationModalActionFor] = useState(null);

  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [isShowUpdateModal, setIsShowUpdateModal] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Expenditure Rules List
  const { expenditureRules, expenditureRulesLoading, expenditureRulesError } = useSelector(state => ({
    expenditureRules: state?.ExpenditureRulesList?.data,
    expenditureRulesLoading: state?.ExpenditureRulesList?.loading,
    expenditureRulesError: state?.ExpenditureRulesList?.error,
  }));

  // Create Expenditure Rule
  const { createExpenditureRule, createExpenditureRuleLoading, createExpenditureRuleError } = useSelector(state => ({
    createExpenditureRule: state?.CreateExpenditureRule?.data,
    createExpenditureRuleLoading: state?.CreateExpenditureRule?.loading,
    createExpenditureRuleError: state?.CreateExpenditureRule?.error,
  }));

  // Delete Expenditure Rule
  const { deleteExpenditureRule, deleteExpenditureRuleLoading, deleteExpenditureRuleError } = useSelector(state => ({
    deleteExpenditureRule: state?.DeleteExpenditureRule?.data,
    deleteExpenditureRuleLoading: state?.DeleteExpenditureRule?.loading,
    deleteExpenditureRuleError: state?.DeleteExpenditureRule?.error,
  }));

  // Update Expenditure Rule
  const { updateExpenditureRule, updateExpenditureRuleLoading, updateExpenditureRuleError } = useSelector(state => ({
    updateExpenditureRule: state?.UpdateExpenditureRule?.data,
    updateExpenditureRuleLoading: state?.UpdateExpenditureRule?.loading,
    updateExpenditureRuleError: state?.UpdateExpenditureRule?.error,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */

  useEffect(() => {
    if (expenditureRules === null) {
      dispatch({
        type: LIST_EXPENDITURE_RULE,
      });
    }

    return () => {
      dispatch({
        type: LIST_EXPENDITURE_RULE_EMPTY,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (createExpenditureRule !== null) {
      dispatch({
        type: CREATE_EXPENDITURE_RULE_EMPTY,
      });

      setIsCreateForm(false);
      dispatch({
        type: LIST_EXPENDITURE_RULE,
        // payload: {
        //   urlParam: {
        //     pageIndex: currentPageIndex,
        //   },
        // },
      });
    }
    if (deleteExpenditureRule !== null) {
      dispatch({
        type: DELETE_EXPENDITURE_RULE_EMPTY,
      });

      dispatch({
        type: LIST_EXPENDITURE_RULE,
        // payload: {
        //   urlParam: {
        //     pageIndex: currentPageIndex,
        //   },
        // },
      });
    }

    if (updateExpenditureRule !== null) {
      dispatch({
        type: UPDATE_EXPENDITURE_RULE_EMPTY,
      });

      dispatch({
        type: LIST_EXPENDITURE_RULE,
        // payload: {
        //   urlParam: {
        //     pageIndex: currentPageIndex,
        //   },
        // },
      });
    }
  }, [createExpenditureRule, deleteExpenditureRule, updateExpenditureRule]);

  /* ---------------------------- BUTTON HANDLER ---------------------------- */
  const createCreateExpenditureRuleBtnHandler = () => {
    setIsCreateForm(true);
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedDetails(params?.details);
    setCurrentConfirmationModalActionFor(params?.actionType);

    if (params?.actionType === `DELETE`) {
      setIsShowConfirmationModal(true);
    } else if (params?.actionType === `UPDATE`) {
      setIsShowUpdateModal(true);
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);

    dispatch({
      type: LIST_EXPENDITURE_RULE,
      // payload: {
      //   urlParam: {
      //     pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
      //   },
      // },
    });
  };

  /* ---------------------------- CLOSE MODAL HANDLER ---------------------------- */
  const closeModalHandler = (flag, data) => {
    setIsShowConfirmationModal(false);

    if (flag === `YES`) {
      if (currentConfirmationModalActionFor === `DELETE`) {
        dispatch({
          type: DELETE_EXPENDITURE_RULE,
          payload: {
            data: {
              objectId: currentSelectedDetails?.objectId,
            },
          },
        });
      }
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const createExpenditureHandler = data => {
    dispatch({
      type: CREATE_EXPENDITURE_RULE,
      payload: {
        data: data,
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (expenditureRulesLoading || createExpenditureRuleLoading || deleteExpenditureRuleLoading || updateExpenditureRuleLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [expenditureRulesLoading, createExpenditureRuleLoading, deleteExpenditureRuleLoading, updateExpenditureRuleLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Expenditure Rule`) {
      setIsCreateForm(false);

      dispatch({
        type: LIST_EXPENDITURE_RULE,
        // payload: {
        //   urlParam: {
        //     pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
        //   },
        // },
      });
    } else if (title === `Information`) {
      // do nothing
    }
  };

  return (
    <>
      {loading && <Loader />}

      {isShowConfirmationModal && (
        <ActionConfirmation onClose={closeModalHandler} data={currentSelectedDetails} action={currentConfirmationModalActionFor} />
      )}

      {isShowUpdateModal && <UpdateExpenditureRule data={currentSelectedDetails} onCloseHandler={() => setIsShowUpdateModal(false)} />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {!isCreateForm && (
            <>
              <BreadCrumb title="Rules" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Expenditure Rule`]} />

              <ExpenditureTable
                data={expenditureRules?.results}
                totalData={expenditureRules?.results?.length}
                pageNumber={currentPageNumber}
                dataStartedFrom={currentPageIndex}
                pageHandler={paginationHandler}
                actionHandler={tableActionHandler}
                onClickPrimaryBtn={createCreateExpenditureRuleBtnHandler}
              />
            </>
          )}

          {isCreateForm && (
            <>
              <BreadCrumb title="Rules" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Expenditure Rule`, `Create`]} />

              <ExpenditureForm onSubmit={createExpenditureHandler} />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default ExpenditureManagement;
