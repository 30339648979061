import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import cogoToast from 'cogo-toast';
import { Container } from 'reactstrap';

import Table from './components/Tables/Table';
import Filter from './components/Filters/Filter';
import QuestionModal from './components/Modals/Question';
import { Constant } from '../../../../../Helpers/constant';
import { BreadCrumb, Loader } from '../../../../../Common';
import UpdateBusiness from './components/Modals/UpdateBusiness';
import QuestionAnswerModal from './components/Modals/QuestionAnswer';
import { CLICK_DOWNLOAD_DUMP_EMPTY, GET_BUSINESS, GET_BUSINESS_EMPTY, GET_QUES_ANS, GET_QUES_ANS_EMPTY } from '../../../../../../store/actions';

// Define the shape of the filter object
interface FilterState {
  createdAtRange: {
    from: string;
    to: string;
  };
}

// Define the shape of your Redux state
interface RootState {
  GetBusiness: {
    data: any;
    loading: boolean;
    error: any;
  };
}

const BusinessRegistration: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState<boolean>(false);
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
  const [currentSelectedCompanyDetails, setCurrentSelectedCompanyDetails] = useState(null);
  const [isUpdateModalShow, setIsUpdateModalShow] = useState(false);
  const [isQuestionModalShow, setIsQuestionModalShow] = useState(false);
  const [isQuestionWithAnsModalShow, setIsQuestionWithAnsModalShow] = useState(false);
  const [updateType, setUpdateType] = useState('');
  const [actionType, setActionType] = useState('');

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Business
  const { getBusiness, getBusinessLoading } = useSelector((state: RootState) => ({
    getBusiness: state?.GetBusiness?.data?.data,
    getBusinessLoading: state?.GetBusiness?.loading,
  }));

  // Update Business
  const { updateBusiness, updateBusinessLoading } = useSelector((state: any) => ({
    updateBusiness: state?.UpdateBusiness?.data,
    updateBusinessLoading: state?.UpdateBusiness?.loading,
  }));

  // Ask Question
  const { askQuestionLoading } = useSelector((state: any) => ({
    askQuestionLoading: state?.AskQuestion?.loading,
  }));

  // Get Questions With Ans
  const { getQuesAns, getQuesAnsLoading } = useSelector((state: any) => ({
    getQuesAns: state?.GetQuestionAnswer?.data,
    getQuesAnsLoading: state?.GetQuestionAnswer?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    return () => {
      dispatch({
        type: GET_BUSINESS_EMPTY,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (updateBusiness !== null) {
      dispatch({
        type: GET_BUSINESS,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          },
          data: null,
        },
      });
    }
  }, [updateBusiness, dispatch, currentPageNumber]);

  useEffect(() => {
    dispatch({
      type: GET_BUSINESS,
      payload: {
        urlParam: {
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
        },
        data: null,
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (getQuesAns && actionType === 'QUESTION_ASKED') {
      getQuesAns?.data?.length < 2 ? setIsQuestionModalShow(true) : cogoToast.warn('You can not ask more than two questions');
    }
    dispatch({
      type: GET_QUES_ANS_EMPTY,
    });
  }, [getQuesAns]);

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedCompanyDetails(params?.companyDetails);
    setActionType(params?.actionType);

    switch (params?.actionType) {
      case 'ACCEPT':
        setIsUpdateModalShow(true);
        setUpdateType('Accept');
        break;

      case 'REJECT':
        setIsUpdateModalShow(true);
        setUpdateType('Reject');
        break;

      case 'BLOCK':
        setIsUpdateModalShow(true);
        setUpdateType('Block');
        break;

      case 'UNBLOCK':
        setIsUpdateModalShow(true);
        setUpdateType('Unblock');
        break;

      case 'QUESTION_ASKED':
        dispatch({
          type: GET_QUES_ANS,
          payload: {
            data: {
              businessId: params?.companyDetails?.id,
              type: 1,
            },
          },
        });
        break;

      case 'QUES_ANS':
        setIsQuestionWithAnsModalShow(true);
        break;

      default:
        break;
    }
  };

  /* ---------------------------- DOWNLOAD DUMP HANDLER ---------------------------- */
  const downloadDumpHandler = () => {
    dispatch({
      type: CLICK_DOWNLOAD_DUMP_EMPTY,
      payload: {
        urlParam: {
          downloadType: 1,
        },
        data: currentlyAppliedFilters,
      },
    });
  };

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = (filters: FilterState) => {
    if (filters) {
      setCurrentPageNumber(0);
      setCurrentlyAppliedFilters(filters);
      dispatch({
        type: GET_BUSINESS,
        payload: {
          urlParam: {
            pageIndex: 0,
          },
          data: {
            ...filters,
          },
        },
      });
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = (pageNumber: number) => {
    setCurrentPageNumber(pageNumber);
    dispatch({
      type: GET_BUSINESS,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          sortOn: currentlyAppliedFilters?.sortOn ?? 'updatedAt',
        },
        data: currentlyAppliedFilters,
      },
    });
  };

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/client/dashboard`);
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    setLoading(getBusinessLoading || updateBusinessLoading || askQuestionLoading || getQuesAnsLoading);
  }, [getBusinessLoading, updateBusinessLoading, askQuestionLoading, getQuesAnsLoading]);

  return (
    <>
      {loading && <Loader />}

      {isUpdateModalShow && (
        <UpdateBusiness data={currentSelectedCompanyDetails} onCloseHandler={() => setIsUpdateModalShow(false)} updateType={updateType} />
      )}

      {isQuestionModalShow && <QuestionModal data={currentSelectedCompanyDetails} onCloseHandler={() => setIsQuestionModalShow(false)} />}

      {isQuestionWithAnsModalShow && (
        <QuestionAnswerModal data={currentSelectedCompanyDetails} onCloseHandler={() => setIsQuestionWithAnsModalShow(false)} />
      )}

      <div className="page-content">
        <Container fluid className="position-relative">
          <>
            <BreadCrumb title="Business Registration" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Business Registration`]} />
            <Filter filterHandler={filterSubmitHandler} appliedFilters={currentlyAppliedFilters} />
            <Table
              data={getBusiness?.data}
              totalData={getBusiness?.count}
              pageNumber={currentPageNumber}
              pageHandler={paginationHandler}
              actionHandler={tableActionHandler}
              dumpDownload={downloadDumpHandler}
              appliedFilters={currentlyAppliedFilters}
            />
          </>
        </Container>
      </div>
    </>
  );
};

export default BusinessRegistration;
