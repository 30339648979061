import { FULL_SCREEN_LOADER_SHOW, FULL_SCREEN_LOADER_HIDE } from './../../actionType.js';

const initialState = {
  loading: false,
};

const FullScreenLoader = (state = initialState, action) => {
  switch (action.type) {
    case FULL_SCREEN_LOADER_SHOW:
      state = {
        loading: true,
      };
      break;

    case FULL_SCREEN_LOADER_HIDE:
      state = {
        loading: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default FullScreenLoader;
