import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import * as Yup from 'yup';
import Select from 'react-select';
import { Formik, Form } from 'formik';
import HTMLReactParser from 'html-react-parser';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Card, CardBody, Col, Row, Label, Modal, ModalBody, Button } from 'reactstrap';

import { Constant } from '../../../../../../Helpers/constant';
import { FormikInput, PreviewCardHeader } from '../../../../../../Common';
import {
  CREATE_COMS_CONFIG,
  CREATE_COMS_CONFIG_EMPTY,
  GET_COMS_BENEFIT,
  UPDATE_COMS_CONFIG,
  UPDATE_COMS_CONFIG_EMPTY,
} from '../../../../../../../store/actions';
import { baseUrl, indianProjectsBaseURL, customHeaders, commsHeader } from '../../../../../../Config';

const validationSchema = Yup.object().shape({
  region: Yup.string().required('Kindly select a region'),
  channel: Yup.string().required('Please select a category'),
});

const channelType = [
  { label: 'sms', value: 'sms' },
  { label: 'whatsapp', value: 'whatsapp' },
  { label: 'email', value: 'email' },
  { label: 'push', value: 'push' },
];
const region = [
  { label: 'India', value: 'India' },
  { label: 'USA', value: 'USA' },
];

const ComsConfigModal = ({ projectID, channel, setIsShowCreateEventForm, isShowCreateEventForm, formType, updateData }) => {
  const dispatch = useDispatch();

  const [validationErrors, setValidationErrors] = useState([]);
  const [initialValues, setInitialValues] = useState({
    project_id: '',
    region: '',
    channel: '',
  });
  const [eventTemplate, setEventTemplate] = useState({
    event: null,
    template: null,
  });
  const [benefitGroup, setBenefitGroup] = useState();
  const [showTemplates, setShowTemplates] = useState(false);
  const [isBenefitActive, setIsBenefitActive] = useState(false);

  const getBenefitGroup = async () => {
    const benefit = await axios({
      method: `GET`,
      url: `${baseUrl}/admin/benefitGroups`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        id: updateData?.benefit_group_id,
      },
    });
    setBenefitGroup({
      label: benefit?.data?.results[0]?.name,
      id: benefit?.data?.results[0]?.objectId,
      completeData: benefit?.data?.results[0],
    });
  };

  useEffect(() => {
    if (formType === 'UpdateForm') {
      setEventTemplate({
        event: {
          value: updateData?.event_id,
          label: updateData?.event_type,
          completeData: 'data',
        },
        template: {
          value: updateData?.template_id,
          label: updateData?.template_name,
          completeData: 'data',
        },
      });
      setInitialValues({
        ...initialValues,
        channel: channel,
        project_id: projectID,
        region: updateData?.region,
      });
      if (updateData?.benefit_group_id && updateData?.benefit_group_id !== 'NA') getBenefitGroup();
    } else {
      setInitialValues({
        ...initialValues,
        channel: channel,
        project_id: projectID,
      });
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: GET_COMS_BENEFIT,
      payload: { urlParam: { projectId: projectID } },
    });
  }, []);

  async function loadEvents(search) {
    let urlParam = null;
    return await axios({
      method: `GET`,
      url: `${indianProjectsBaseURL}/comms/events`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        ...commsHeader,
      },
      params: {
        ...urlParam,
        event_type: search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.map(data => {
            return {
              value: data?.id,
              label: `${data?.event_type}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  async function loadTemplates(search) {
    // Replace this URL with your actual API endpoint for templates
    const url = `${indianProjectsBaseURL}/comms/templates`;

    return await axios({
      method: 'GET',
      url,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        ...commsHeader,
      },
      params: {
        template_name: search,
        event_type: eventTemplate?.event?.label,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data.id,
              label: `${data.template_name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  async function loadBenefitGroup(search, loadedOptions) {
    return await axios({
      method: `GET`,
      url: `${baseUrl}/admin/benefitGroups`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        name: search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data.objectId,
              label: `${data.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  // const onSelectEvent = (selectedOption, index) => {
  //   const updatedEventTemplate = [...eventTemplate];

  //   // Ensure that updatedVendors[index] is not null or undefined before attempting to update
  //   if (updatedEventTemplate[index] != null) {
  //     updatedEventTemplate[index].event = selectedOption;
  //   }
  //   setEventTemplate(updatedEventTemplate);
  //   const updatedValidationErrors = [...validationErrors];
  //   updatedValidationErrors[index] = {
  //     event: false,
  //     template: updatedValidationErrors[index]?.template,
  //   };
  //   setValidationErrors(updatedValidationErrors);
  // };

  // const onSelectTemplate = (selectedOption, index) => {
  //   const updatedEventTemplate = [...eventTemplate];

  //   // Ensure that updatedVendors[index] is not null or undefined before attempting to update
  //   if (updatedEventTemplate[index] != null) {
  //     updatedEventTemplate[index].template = selectedOption;
  //   }
  //   setEventTemplate(updatedEventTemplate);
  //   const updatedValidationErrors = [...validationErrors];
  //   updatedValidationErrors[index] = {
  //     event: updatedValidationErrors[index]?.event,
  //     template: false,
  //   };
  //   setValidationErrors(updatedValidationErrors);
  // };

  const onSelectEvent = selectedOption => {
    if (JSON.stringify(selectedOption) === JSON.stringify(eventTemplate.event)) {
      setEventTemplate(prevEventTemplate => ({
        ...prevEventTemplate,
        event: selectedOption,
      }));
    } else {
      setEventTemplate(prevEventTemplate => ({
        ...prevEventTemplate,
        event: selectedOption,
        template: null,
      }));
    }
    setValidationErrors(prevValidationErrors => ({
      ...prevValidationErrors,
      event: false,
      template: false,
    }));
  };

  const onSelectBenefitGroup = selectedOption => {
    setBenefitGroup(selectedOption);
    setValidationErrors(prevValidationErrors => ({
      ...prevValidationErrors,
      benefitGroup: false,
    }));
  };

  const onSelectTemplate = selectedOption => {
    setEventTemplate(prevEventTemplate => ({
      ...prevEventTemplate,
      template: selectedOption,
    }));
    setValidationErrors(prevValidationErrors => ({
      ...prevValidationErrors,
      template: false,
    }));
  };

  // Get Coms Benefit
  const { comsBenefit } = useSelector(state => ({
    comsBenefit: state?.GetComsBenefit?.data,
    comsBenefitLoading: state?.GetComsBenefit?.loading,
  }));

  const { createConfig } = useSelector(state => ({
    createConfig: state?.CreateComsConfig?.data,
  }));

  const { updateConfig } = useSelector(state => ({
    updateConfig: state?.UpdateComsConfig?.data,
  }));

  useEffect(() => {
    if (createConfig !== null) {
      setIsShowCreateEventForm(false);
      dispatch({
        type: CREATE_COMS_CONFIG_EMPTY,
      });
    }
    if (updateConfig !== null) {
      setIsShowCreateEventForm(false);
      dispatch({
        type: UPDATE_COMS_CONFIG_EMPTY,
      });
    }
  }, [createConfig, updateConfig]);

  // const addEventTemplateField = () => {
  //   setEventTemplate([...eventTemplate, { event: null, template: "" }]);
  //   setValidationErrors([
  //     ...validationErrors,
  //     { event: false, template: false },
  //   ]);
  // };

  // const removeEventTemplateField = (index) => {
  //   if (eventTemplate.length > 1) {
  //     const updatedEventTemplate = eventTemplate.filter((_, i) => i !== index);
  //     setEventTemplate(updatedEventTemplate);
  //     const updatedValidationErrors = [...validationErrors];
  //     updatedValidationErrors.splice(index, 1);
  //     setValidationErrors(updatedValidationErrors);
  //   }
  // };

  const validationEventTemplates = () => {
    let updatedValidationErrors;
    if (!isBenefitActive) {
      updatedValidationErrors = {
        event: !eventTemplate.event,
        template: !eventTemplate.template,
      };
    } else {
      updatedValidationErrors = {
        event: !eventTemplate.event,
        template: !eventTemplate.template,
        benefitGroup: !benefitGroup,
      };
    }
    setValidationErrors(updatedValidationErrors);
    if (!isBenefitActive) return updatedValidationErrors.event || updatedValidationErrors.template;
    else return updatedValidationErrors.event || updatedValidationErrors.template || updatedValidationErrors?.benefitGroup;
  };

  const toggle = () => setIsShowCreateEventForm(!isShowCreateEventForm);

  const createComsConfig = values => {
    dispatch({
      type: CREATE_COMS_CONFIG,
      payload: {
        data: {
          ...values,
        },
      },
    });
  };

  const updateComsConfig = values => {
    dispatch({
      type: UPDATE_COMS_CONFIG,
      payload: {
        data: {
          ...values,
          is_active: updateData?.is_active ? true : false,
        },
        params: updateData?.id,
      },
    });
  };

  const openNewTabWithHtmlContent = htmlContent => {
    const newWindow = window.open('', '_blank');
    if (newWindow) {
      newWindow.document.write(htmlContent);
      newWindow.document.close();
    }
  };

  const handleOpenNewTab = () => {
    openNewTabWithHtmlContent(eventTemplate?.template?.completeData?.content?.body);
  };

  return (
    <Modal isOpen={isShowCreateEventForm} toggle={toggle} size="lg">
      {/* <ModalHeader toggle={toggle}>Create Communication Config</ModalHeader> */}
      <div className="modal-header p-3 bg-soft-warning">
        <h5 className="modal-title" id="createboardModalLabel">
          {formType === 'UpdateForm' ? 'Update Communication Config' : 'Create Communication Config'}
        </h5>
        <Button type="button" onClick={toggle} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close"></Button>
      </div>
      <ModalBody>
        <Row>
          <Col xxl={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting }) => {
                const isValidation = validationEventTemplates();
                if (isValidation) return;
                values.event_id = eventTemplate?.event?.value;
                values.event_type = eventTemplate?.event?.label;
                values.template_id = eventTemplate?.template?.value;
                values.template_name = eventTemplate?.template?.label;
                values.project_id = projectID;
                values.benefit_group_id = benefitGroup?.value;
                const { data } = JSON.parse(localStorage.getItem('authUser'));
                if (Object.keys(updateData).length > 0) {
                  values.updated_by = data?.name;
                  updateComsConfig(values);
                } else {
                  values.created_by = data?.name;
                  values.updated_by = data?.name;
                  createComsConfig(values);
                }
              }}
            >
              {({ values, errors, handleSubmit, setFieldValue, isSubmitting, handleChange, touched }) => (
                <Form onSubmit={handleSubmit}>
                  <>
                    {/* <button onClick={() => console.log(state)}>get state</button> */}
                    {/* <CardBody>
                          <PreviewCardHeader title="Create Coms Config" /> */}
                    <div className="live-preview">
                      <Row>
                        <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                          <div className="mb-3">
                            <FormikInput
                              label="Project Name"
                              type="text"
                              name="projectID"
                              placeholder=""
                              value={values?.project_id}
                              disabled={true}
                            />
                          </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                          <div className="mb-3">
                            <Label htmlFor="channel" className="form-label">
                              Channel
                              <span className="lbl_mandatory"> *</span>
                            </Label>
                            <Select
                              id="channel"
                              name="channel"
                              options={channelType}
                              onChange={option => {
                                handleChange('channel')(option?.value);
                              }}
                              value={channelType.length > 0 ? channelType?.find(option => option?.value === values?.channel) : ''}
                              isDisabled={true}
                            />
                            {touched?.channel && !values?.channel && <div style={{ color: 'red' }}>Please select a channel type</div>}
                          </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                          <div className="mb-3">
                            <Label htmlFor="channel" className="form-label">
                              Region
                              <span className="lbl_mandatory"> *</span>
                            </Label>
                            <Select
                              id="region"
                              name="region"
                              options={region}
                              onChange={option => {
                                handleChange('region')(option?.value);
                              }}
                              value={region.length > 0 ? region?.find(option => option?.value === values?.region) : ''}
                            />
                            {touched.region && !values.region && <div style={{ color: 'red' }}>Please select a region type</div>}
                          </div>
                        </Col>
                        <Col md={5} className="mb-4">
                          <div className="d-flex justify-content-between">
                            <Label htmlFor="choices-multiple-default" className="form-label">
                              Benefit Group
                              <span className="lbl_mandatory">{isBenefitActive && '*'}</span>
                            </Label>
                          </div>
                          <AsyncPaginate
                            debounceTimeout={500}
                            value={benefitGroup}
                            loadOptions={loadBenefitGroup}
                            onChange={selectedOption => onSelectBenefitGroup(selectedOption)}
                            isMulti={false}
                            closeMenuOnSelect={true}
                            noOptionsMessage={() => 'No results found'}
                            cacheUniqs={[['code']]}
                            placeholder="Select..."
                            isDisabled={comsBenefit ? !comsBenefit?.is_benefit_group_active : true}
                          />
                          {isBenefitActive && validationErrors?.benefitGroup && (
                            <div
                              style={{
                                color: 'red',
                              }}
                            >
                              Benefit Group is required
                            </div>
                          )}
                        </Col>
                        {/* <Col md={12}>
                          <Card className="card-inner">
                            <PreviewCardHeader title="" />
                            <CardBody>
                              {eventTemplate?.map((event, index) => (
                                <Row key={index}>
                                  <Col md={5}>
                                    <div className="d-flex justify-content-between">
                                      <Label
                                        htmlFor="choices-multiple-default"
                                        className="form-label"
                                      >
                                        Choose Events
                                        <span className="lbl_mandatory">*</span>
                                      </Label>
                                    </div>
                                    <AsyncPaginate
                                      debounceTimeout={500}
                                      value={event?.event}
                                      loadOptions={loadEvents}
                                      onChange={(selectedOption) =>
                                        onSelectEvent(selectedOption, index)
                                      }
                                      isMulti={false}
                                      closeMenuOnSelect={true}
                                      noOptionsMessage={() =>
                                        "No results found"
                                      }
                                      cacheUniqs={[["code"]]}
                                      placeholder="Select..."
                                    />
                                    {validationErrors[index]?.event && (
                                      <div
                                        style={{
                                          color: "red",
                                        }}
                                      >
                                        event is required
                                      </div>
                                    )}
                                  </Col>
                                  <Col md={5}>
                                    <div className="d-flex justify-content-between">
                                      <Label
                                        htmlFor="choices-multiple-default"
                                        className="form-label"
                                      >
                                        Choose Template
                                        <span className="lbl_mandatory">*</span>
                                      </Label>
                                    </div>
                                    <AsyncPaginate
                                      debounceTimeout={500}
                                      value={event?.template}
                                      loadOptions={loadTemplates}
                                      onChange={(selectedOption) =>
                                        onSelectTemplate(selectedOption, index)
                                      }
                                      isMulti={false}
                                      closeMenuOnSelect={true}
                                      noOptionsMessage={() =>
                                        "No results found"
                                      }
                                      cacheUniqs={[["code"]]}
                                      placeholder="Select..."
                                    />

                                    {validationErrors[index]?.template && (
                                      <div
                                        style={{
                                          color: "red",
                                        }}
                                      >
                                        Template is required
                                      </div>
                                    )}
                                  </Col>
                                  <Col md={2}>
                                    <div className="mb-3">
                                      <Label
                                        htmlFor="validityTatValue"
                                        className="form-label"
                                      >
                                        &nbsp;
                                      </Label>
                                      <div className="d-flex gap-2">
                                        <button
                                          type="button"
                                          className={`btn btn-success`}
                                          onClick={addEventTemplateField}
                                        >
                                          <GoPlus />
                                        </button>

                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          onClick={() =>
                                            removeEventTemplateField(index)
                                          }
                                          disabled={eventTemplate.length === 1}
                                        >
                                          <i className="ri-delete-bin-5-line"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              ))}
                            </CardBody>
                          </Card>
                        </Col> */}
                        <Col md={12}>
                          <Card className="card-inner">
                            <PreviewCardHeader title="" />
                            <CardBody>
                              <Row>
                                <Col md={5} className="mb-4">
                                  <div className="d-flex justify-content-between">
                                    <Label htmlFor="choices-multiple-default" className="form-label">
                                      Choose Events
                                      <span className="lbl_mandatory">*</span>
                                    </Label>
                                  </div>
                                  <AsyncPaginate
                                    debounceTimeout={500}
                                    value={eventTemplate?.event}
                                    loadOptions={loadEvents}
                                    onChange={selectedOption => onSelectEvent(selectedOption)}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    noOptionsMessage={() => 'No results found'}
                                    cacheUniqs={[['code']]}
                                    placeholder="Select..."
                                  />
                                  {validationErrors?.event && (
                                    <div
                                      style={{
                                        color: 'red',
                                      }}
                                    >
                                      Event is required
                                    </div>
                                  )}
                                </Col>

                                <Col md={5} className="mb-4">
                                  <div className="d-flex justify-content-between ">
                                    <Label htmlFor="choices-multiple-default" className="form-label">
                                      Choose Template
                                      <span className="lbl_mandatory">*</span>
                                    </Label>
                                  </div>
                                  <AsyncPaginate
                                    debounceTimeout={500}
                                    value={eventTemplate?.template}
                                    loadOptions={loadTemplates}
                                    onChange={selectedOption => onSelectTemplate(selectedOption)}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    noOptionsMessage={() => 'No results found'}
                                    cacheUniqs={[['code']]}
                                    placeholder="Select..."
                                    isDisabled={!eventTemplate.event && true}
                                  />

                                  {validationErrors?.template && (
                                    <div
                                      style={{
                                        color: 'red',
                                      }}
                                    >
                                      Template is required
                                    </div>
                                  )}
                                </Col>
                                {eventTemplate?.template && formType !== 'UpdateForm' && Object.keys(eventTemplate?.template).length > 0 && (
                                  <Col md={2} className="d-flex align-items-center">
                                    <div className="mb-3 text-center ">
                                      <p className="text-decoration-underline cursor-pointer" onClick={handleOpenNewTab}>
                                        View Templates
                                      </p>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Row>
                          <Col md={12}>
                            <div className="text-center mt-4">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                // disabled={isSubmitting}
                                onClick={validationEventTemplates}
                              >
                                {formType === 'UpdateForm' ? 'Update Config' : 'Create Config'}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Row>
                    </div>
                  </>
                </Form>
              )}
            </Formik>
            {showTemplates && (
              <Modal isOpen={showTemplates} toggle={() => setShowTemplates(false)} size="lg">
                <div className="modal-header p-3 bg-soft-warning">
                  <h5 className="modal-title" id="createboardModalLabel">
                    Template
                  </h5>

                  <Button
                    type="button"
                    onClick={() => setShowTemplates(false)}
                    id="addBoardBtn-close"
                    className="btn-close btn-light"
                    aria-label="Close"
                  ></Button>
                </div>
                <ModalBody>{HTMLReactParser()}</ModalBody>
              </Modal>
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ComsConfigModal;
