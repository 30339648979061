import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, uaeProjectsBaseURL } from './../../../../../Containers/Config/index';
import { GET_PARTNER, GET_PARTNER_SUCCESS, GET_PARTNER_ERROR } from './../../../actionType';
import apiCall from '../../../apiCall';
import { Constant } from '../../../../../Containers/Helpers/constant';

function* getPartner({ payload }) {
  // let URL = `${uaeProjectsBaseURL}/admin/partners`

  // let urlParams = ''
  // if (payload?.pagination) {
  //   urlParams = `${`pageIndex=${payload?.urlParam?.pageIndex}`}&${`pageSize=${Constant?.TABLE_PAGE_SIZE}`}`
  // } else {
  //   if (payload?.urlParam?.categoryId) {
  //     urlParams = `categoryId=${payload?.urlParam?.categoryId}&pageIndex=0&pageSize=100`
  //   }
  // }

  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/admin/partners`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        pageSize: Constant?.TABLE_PAGE_SIZE,
        ...payload?.urlParam,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_PARTNER_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_PARTNER_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_PARTNER_ERROR, payload: error });
  }
}

export function* watchGetPartner() {
  yield takeEvery(GET_PARTNER, getPartner);
}

function* getPartnerSaga() {
  yield all([fork(watchGetPartner)]);
}

export default getPartnerSaga;
