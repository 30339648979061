import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../../Containers/Config/index.js';
import { GET_UPLOADS_INFO, GET_UPLOADS_INFO_SUCCESS, GET_UPLOADS_INFO_ERROR } from './../../../actionType.js';
import apiCall from '../../../apiCall';
import { Constant } from '../../../../../Containers/Helpers/constant.js';

function* getUploadsInfo({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/uploadsInfo`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload?.urlParam,
        pageSize: Constant?.TABLE_PAGE_SIZE,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_UPLOADS_INFO_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_UPLOADS_INFO_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_UPLOADS_INFO_ERROR, payload: error });
  }
}

export function* watchUploadsInfo() {
  yield takeEvery(GET_UPLOADS_INFO, getUploadsInfo);
}

function* uploadsInfoSaga() {
  yield all([fork(watchUploadsInfo)]);
}

export default uploadsInfoSaga;
