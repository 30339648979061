import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  baseUrl,
  customHeaders,
  commsHeader,
  indianProjectsBaseURL,
  uaeProjectsBaseURL,
  ksaProjectsBaseURL,
} from '../../../../Containers/Config/index.js';
import { VENDOR_GENERATE_QR, VENDOR_GENERATE_QR_SUCCESS, VENDOR_GENERATE_QR_ERROR } from '../../actionType.js';
import apiCall from '../../apiCall.js';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* vendorGenerateQr({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${Constant.PROJECT_CODE === 'SAB' ? ksaProjectsBaseURL : uaeProjectsBaseURL}/vendor/generate-qr`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        'Project-Code': payload?.headers?.code,
        // ...customHeaders,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      yield put({
        type: VENDOR_GENERATE_QR_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: VENDOR_GENERATE_QR_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: VENDOR_GENERATE_QR_ERROR, payload: error });
  }
}

export function* watchVendorGenerateQr() {
  yield takeEvery(VENDOR_GENERATE_QR, vendorGenerateQr);
}

function* vendorGenerateQrSaga() {
  yield all([fork(watchVendorGenerateQr)]);
}

export default vendorGenerateQrSaga;
