import { VendorEnbdDashboard } from '../../Enbd';
import { VendorValetParkingDashboard } from '../../ValetParking';
import { ThriweDiningDashboard } from '../../ThriweDining';

const VendorPanelDashboard = () => {
  const user = JSON.parse(localStorage.getItem('data'));

  return (
    <div>
      {user?.program?.code === 'ENBD_V3' && <VendorEnbdDashboard />}
      {user?.program?.code === 'SAB' && <VendorValetParkingDashboard />}
      {user?.program?.code === 'THRIWE_DINING_V1' && <ThriweDiningDashboard />}
    </div>
  );
};

export default VendorPanelDashboard;
