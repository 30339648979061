import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from '../../../Common';
import { Container } from 'reactstrap';
import {
  CREATE_EVENT_RULE,
  CREATE_EVENT_RULE_EMPTY,
  CREATE_EVENT_RULE_WITH_BENEFIT_GROUP,
  CREATE_EVENT_RULE_WITH_BENEFIT_GROUP_EMPTY,
  CREATE_EVENT_RULE_WITH_USER_IDS,
  CREATE_EVENT_RULE_WITH_USER_IDS_EMPTY,
  DELETE_EVENT_RULE,
  DELETE_EVENT_RULE_EMPTY,
  LIST_EVENT_RULE,
  LIST_EVENT_RULE_EMPTY,
  UPDATE_EVENT_RULE_EMPTY,
} from '../../../../store/application/actionType';
import EventForm from './Components/Forms/EventForm';
import { Constant } from '../../../Helpers/constant';
import EventTable from './Components/Tables/EventTable';
import ActionConfirmation from './Components/Modals/ConfirmationModal';
import UpdateEventRule from './Components/Modals/UpdateEventRule';

const EventManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [isCreateForm, setIsCreateForm] = useState(false);

  const [currentSelectedDetails, setCurrentSelectedDetails] = useState([]);
  const [currentConfirmationModalActionFor, setCurrentConfirmationModalActionFor] = useState(null);

  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [isShowUpdateModal, setIsShowUpdateModal] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Event Rules List
  const { eventRules, eventRulesLoading, eventRulesError } = useSelector(state => ({
    eventRules: state?.EventRulesList?.data,
    eventRulesLoading: state?.EventRulesList?.loading,
    eventRulesError: state?.EventRulesList?.error,
  }));

  // Create Event Rule
  const { createEventRule, createEventRuleLoading, createEventRuleError } = useSelector(state => ({
    createEventRule: state?.CreateEventRules?.data,
    createEventRuleLoading: state?.CreateEventRules?.loading,
    createEventRuleError: state?.CreateEventRules?.error,
  }));

  // Create Event Rule With user ids
  const { createEventRuleWithUserIds, createEventRuleWithUserIdsLoading } = useSelector(state => ({
    createEventRuleWithUserIds: state?.CreateEventRulesWithUserIds?.data,
    createEventRuleWithUserIdsLoading: state?.CreateEventRulesWithUserIds?.loading,
  }));

  // Create Event Rule with benefit group id
  const { createEventRuleWithBenefitGroup, createEventRuleWithBenefitGroupLoading } = useSelector(state => ({
    createEventRuleWithBenefitGroup: state?.CreateEventRulesBenefitGroupId?.data,
    createEventRuleWithBenefitGroupLoading: state?.CreateEventRulesBenefitGroupId?.loading,
  }));

  // Delete Event Rule
  const { deleteEventRule, deleteEventRuleLoading, deleteEventRuleError } = useSelector(state => ({
    deleteEventRule: state?.DeleteEventRule?.data,
    deleteEventRuleLoading: state?.DeleteEventRule?.loading,
    deleteEventRuleError: state?.DeleteEventRule?.error,
  }));

  // Update Event Rule
  const { updateEventRule, updateEventRuleLoading, updateEventRuleError } = useSelector(state => ({
    updateEventRule: state?.UpdateEventRule?.data,
    updateEventRuleLoading: state?.UpdateEventRule?.loading,
    updateEventRuleError: state?.UpdateEventRule?.error,
  }));

  // Program Users
  const { programUsersLoading } = useSelector(state => ({
    programUsersLoading: state?.ProgramUsers?.loading,
  }));

  // Benefit Groups
  const { benefitGroupsLoading } = useSelector(state => ({
    benefitGroupsLoading: state?.BenefitGroups?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */

  useEffect(() => {
    if (eventRules === null) {
      dispatch({
        type: LIST_EVENT_RULE,
      });
    }

    return () => {
      dispatch({
        type: LIST_EVENT_RULE_EMPTY,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (createEventRule !== null) {
      dispatch({
        type: CREATE_EVENT_RULE_EMPTY,
      });

      setIsCreateForm(false);
      dispatch({
        type: LIST_EVENT_RULE,
        // payload: {
        //   urlParam: {
        //     pageIndex: currentPageIndex,
        //   },
        // },
      });
    }

    if (createEventRuleWithUserIds !== null) {
      dispatch({
        type: CREATE_EVENT_RULE_WITH_USER_IDS_EMPTY,
      });

      setIsCreateForm(false);
      dispatch({
        type: LIST_EVENT_RULE,
        // payload: {
        //   urlParam: {
        //     pageIndex: currentPageIndex,
        //   },
        // },
      });
    }

    if (createEventRuleWithBenefitGroup !== null) {
      dispatch({
        type: CREATE_EVENT_RULE_WITH_BENEFIT_GROUP_EMPTY,
      });

      setIsCreateForm(false);
      dispatch({
        type: LIST_EVENT_RULE,
        // payload: {
        //   urlParam: {
        //     pageIndex: currentPageIndex,
        //   },
        // },
      });
    }

    if (deleteEventRule !== null) {
      dispatch({
        type: DELETE_EVENT_RULE_EMPTY,
      });

      dispatch({
        type: LIST_EVENT_RULE,
        // payload: {
        //   urlParam: {
        //     pageIndex: currentPageIndex,
        //   },
        // },
      });
    }

    if (updateEventRule !== null) {
      dispatch({
        type: UPDATE_EVENT_RULE_EMPTY,
      });

      dispatch({
        type: LIST_EVENT_RULE,
        // payload: {
        //   urlParam: {
        //     pageIndex: currentPageIndex,
        //   },
        // },
      });
    }
  }, [createEventRule, createEventRuleWithUserIds, createEventRuleWithBenefitGroup, deleteEventRule, updateEventRule]);

  /* ---------------------------- BUTTON HANDLER ---------------------------- */
  const createEventRuleBtnHandler = () => {
    setIsCreateForm(true);
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedDetails(params?.details);
    setCurrentConfirmationModalActionFor(params?.actionType);

    if (params?.actionType === `DELETE`) {
      setIsShowConfirmationModal(true);
    } else if (params?.actionType === `UPDATE`) {
      setIsShowUpdateModal(true);
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);

    dispatch({
      type: LIST_EVENT_RULE,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  /* ---------------------------- CLOSE MODAL HANDLER ---------------------------- */
  const closeModalHandler = (flag, data) => {
    setIsShowConfirmationModal(false);

    if (flag === `YES`) {
      if (currentConfirmationModalActionFor === `DELETE`) {
        dispatch({
          type: DELETE_EVENT_RULE,
          payload: {
            data: {
              objectId: currentSelectedDetails?.objectId,
            },
          },
        });
      }
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const createEventRuleHandler = data => {
    if (data?.globalEventDetails?.id === 1 || data?.globalEventDetails?.id === 2) {
      dispatch({
        type: CREATE_EVENT_RULE,
        payload: {
          data: data,
        },
      });
    } else if (data?.globalEventDetails?.id === 3) {
      dispatch({
        type: CREATE_EVENT_RULE_WITH_USER_IDS,
        payload: {
          data: {
            userId: data?.userId,
            coinAmount: data?.coinAmount,
            coinExpiry: data?.coinExpiry,
            ruleName: data?.globalEventDetails?.ruleName,
          },
        },
      });
    } else if (data?.globalEventDetails?.id === 4) {
      dispatch({
        type: CREATE_EVENT_RULE_WITH_BENEFIT_GROUP,
        payload: {
          data: {
            benefitGroupId: data?.benefitGroupId,
            coinAmount: data?.coinAmount,
            coinExpiry: data?.coinExpiry,
            name: data?.name,
            globalEventDetails: data?.globalEventDetails,
          },
        },
      });
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (
      eventRulesLoading ||
      createEventRuleLoading ||
      deleteEventRuleLoading ||
      updateEventRuleLoading ||
      programUsersLoading ||
      benefitGroupsLoading ||
      createEventRuleWithUserIdsLoading ||
      createEventRuleWithBenefitGroupLoading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    eventRulesLoading,
    createEventRuleLoading,
    deleteEventRuleLoading,
    updateEventRuleLoading,
    programUsersLoading,
    benefitGroupsLoading,
    createEventRuleWithUserIdsLoading,
    createEventRuleWithBenefitGroupLoading,
  ]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Event Rule`) {
      setIsCreateForm(false);

      dispatch({
        type: LIST_EVENT_RULE,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          },
        },
      });
    } else if (title === `Create`) {
      // do nothing
    }
  };

  return (
    <>
      {loading && <Loader />}

      {/*  ----------------------------  MODAL'S ---------------------------- */}
      {isShowConfirmationModal && (
        <ActionConfirmation onClose={closeModalHandler} data={currentSelectedDetails} action={currentConfirmationModalActionFor} />
      )}

      {isShowUpdateModal && <UpdateEventRule data={currentSelectedDetails} onCloseHandler={() => setIsShowUpdateModal(false)} />}

      {/*  ----------------------------  UI'S ---------------------------- */}
      <div className="page-content">
        <Container fluid className="position-relative">
          {!isCreateForm && (
            <>
              <BreadCrumb title="Rules" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Event Rule`]} />

              <EventTable
                data={eventRules?.results}
                totalData={eventRules?.results.length}
                pageNumber={currentPageNumber}
                dataStartedFrom={currentPageIndex}
                pageHandler={paginationHandler}
                actionHandler={tableActionHandler}
                onClickPrimaryBtn={createEventRuleBtnHandler}
              />
            </>
          )}

          {isCreateForm && (
            <>
              <BreadCrumb title="Rules" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Event Rule`, `Create`]} />

              <EventForm onSubmit={createEventRuleHandler} />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default EventManagement;
