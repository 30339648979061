// Actions
export const API_RESPONSE_SUCCESS = 'API_RESPONSE_SUCCESS';
export const API_RESPONSE_ERROR = 'API_RESPONSE_ERROR';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_ORDERS = 'GET_ORDERS';
export const GET_SELLERS = 'GET_SELLERS';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';

/**
 * Delete ORDER
 */
export const DELETE_ORDER = 'DELETE_ORDER';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAIL = 'DELETE_ORDER_FAIL';

/**
 * Update ORDER
 */
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL';

/**
 * Add ORDER
 */
export const ADD_NEW_ORDER = 'ADD_NEW_ORDER';
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS';
export const ADD_ORDER_FAIL = 'ADD_ORDER_FAIL';

/**
 * Add CUSTOMER
 */
export const ADD_NEW_CUSTOMER = 'ADD_NEW_CUSTOMER';
export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS';
export const ADD_CUSTOMER_FAIL = 'ADD_CUSTOMER_FAIL';

/**
 * Edit CUSTOMER
 */
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAIL = 'UPDATE_CUSTOMER_FAIL';

/**
 * Delete CUSTOMER
 */
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAIL = 'DELETE_CUSTOMER_FAIL';

/**
 * Delete PRODUCT
 */
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL';

export const RESET_ECOM_FLAG = 'RESET_ECOM_FLAG';
