import { useState } from 'react';

import cogoToast from 'cogo-toast';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalBody, Col, Row, Card, CardBody, ModalFooter, Spinner } from 'reactstrap';

const UploadFileModal = ({ data, isLoading, onUpload, onClose }) => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [selectedFiles, setselectedFiles] = useState([]);

  const handleAcceptedFiles = files => {
    const file = files[0];
    const allowedExtensions = ['xls', 'xlsx', 'csv'];
    const fileExtension = file?.name?.split('.')?.pop()?.toLowerCase();

    if (allowedExtensions?.includes(fileExtension)) {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        }),
      );
      setselectedFiles(files);
    } else {
      cogoToast.error('Kindly upload proper file!');
    }
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const onSubmitHandler = () => {
    if (selectedFiles?.length > 0) {
      const formData = new FormData();
      formData.append('file', selectedFiles[0]);
      formData.append('fileConfigId', data?.configId);
      formData.append('configEnum', 10);

      onUpload(formData);
    } else {
      cogoToast.error('Please select a file!');
    }
  };

  return (
    <>
      <Modal isOpen={true} centered size="md">
        <div className="modal-header p-3 bg-soft-secondary">
          <h5 className="modal-title">Recon Upload</h5>
          <Button
            type="button"
            onClick={() => {
              onClose();
            }}
            className="btn-close btn-light"
            aria-label="Close"
          ></Button>
        </div>

        <ModalBody>
          <Row>
            <CardBody>
              <Dropzone
                onDrop={acceptedFiles => {
                  handleAcceptedFiles(acceptedFiles);
                }}
                className="cursor-pointer"
                accept="image/*,audio/*,video/*"
                canRemove={true}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone dz-clickable">
                    <input {...getInputProps()} />
                    <div className="dz-message needsclick" {...getRootProps()}>
                      <div className="mb-3">
                        <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                      </div>
                      <h6>Select a file of drag and drop here</h6>
                    </div>
                  </div>
                )}
              </Dropzone>

              <div className="list-unstyled mb-0" id="file-previews">
                {selectedFiles.map((f, i) => {
                  return (
                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={i + '-file'}>
                      <div className="p-2">
                        <Row className="align-items-center">
                          {/* <Col className="col-auto">
                            <img data-dz-thumbnail="" height="80" className="avatar-sm rounded bg-light" alt={f.name} src={f.preview} />
                          </Col> */}
                          <Col>
                            <Link to="#" className="text-muted font-weight-bold">
                              {f.name}
                            </Link>
                            <p className="mb-0">
                              <strong>{f.formattedSize}</strong>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  );
                })}
              </div>
            </CardBody>
          </Row>
        </ModalBody>

        <ModalFooter className="modal-footer bg-light pt-3 justify-content-center">
          {isLoading ? (
            <Button color="primary" className="btn-load" outline>
              <span className="d-flex align-items-center">
                <Spinner size="sm" className="flex-shrink-0">
                  Loading...
                </Spinner>
                <span className="flex-grow-1 ms-2">Loading...</span>
              </span>
            </Button>
          ) : (
            <Button className="btn btn-md" color="primary" onClick={onSubmitHandler}>
              <i className="ri-upload-2-line align-middle"></i>&nbsp; Upload
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default UploadFileModal;
