import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { baseUrl, customHeaders, indianProjectsBaseURL } from '../../../../Containers/Config';
import { GET_BOOKING_SUMMARY, GET_BOOKING_SUMMARY_SUCCESS, GET_BOOKING_SUMMARY_ERROR } from './../../actionType';
import cogoToast from 'cogo-toast';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant';

function* getBookingSummary({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/booking-dashboard`,
      method: 'GET',
      headers: { Authorization: Constant?.CLIENT_TOKEN, ...customHeaders },
      params: {
        ...payload?.urlParam,
      },
    });

    if (response.status === 200) {
      yield put({ type: GET_BOOKING_SUMMARY_SUCCESS, payload: response?.data });
    } else {
      yield put({ type: GET_BOOKING_SUMMARY_ERROR, payload: response.error });
    }
  } catch (error) {
    yield put({ type: GET_BOOKING_SUMMARY_ERROR, payload: error });
  }
}

export function* watchGetBookingSummary() {
  yield takeEvery(GET_BOOKING_SUMMARY, getBookingSummary);
}

function* getBookingSummarySaga() {
  yield all([fork(watchGetBookingSummary)]);
}

export default getBookingSummarySaga;
