import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'reactstrap';

import Table from './Components/Tables/Tables';
import Details from './Components/Details/Details';
import { BreadCrumb, Loader } from '../../../../Common';
import { Constant } from '../../../../Helpers/constant';
import CustomFilter from './Components/Filters/customFilter';
import { GET_COMMS_REPORT_LIST } from '../../../../../store/application/actionType';

const CommsReport = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [isShowFilters, setIsShowFilters] = useState(false);
  const [isDetailsPage, setIsDetailsPage] = useState(false);
  const [currentSelectedReport, setCurrentSelectedReport] = useState(null);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Comms Report List
  const { commsReport, commsReportLoading } = useSelector(state => ({
    commsReport: state?.GetCommsReport?.data,
    commsReportLoading: state?.GetCommsReport?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_COMMS_REPORT_LIST,
      payload: {
        urlParam: {
          offset: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          limit: Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  }, [dispatch]);

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = filters => {
    setIsShowFilters(false);
    setCurrentlyAppliedFilters(filters);
    setCurrentPageNumber(0);

    const urlParams = {
      status: filters?.status === `` ? null : filters?.status?.value,
      event_type: filters?.event_type === `` ? null : filters?.event_type?.label,
      channel: filters?.channel === `` ? null : filters?.channel?.value,
      tracker_id: filters?.tracker_id === `` ? null : filters?.tracker_id,
      template_name: filters?.template_name === `` ? null : filters?.template_name?.label,
      project_id: filters?.project_id === `` ? null : filters?.project_id,
    };

    dispatch({
      type: GET_COMMS_REPORT_LIST,
      payload: {
        urlParam: {
          offset: 0,
          limit: Constant.TABLE_PAGE_SIZE,
          ...urlParams,
        },
      },
    });
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);

    const urlParams = {
      status: currentlyAppliedFilters?.status === `` ? null : currentlyAppliedFilters?.status?.value,
      event_type: currentlyAppliedFilters?.event_type === `` ? null : currentlyAppliedFilters?.event_type?.label,
      channel: currentlyAppliedFilters?.channel === `` ? null : currentlyAppliedFilters?.channel?.value,
      tracker_id: currentlyAppliedFilters?.tracker_id === `` ? null : currentlyAppliedFilters?.tracker_id,
      template_name: currentlyAppliedFilters?.template_name === `` ? null : currentlyAppliedFilters?.template_name?.label,
      project_id: currentlyAppliedFilters?.project_id === `` ? null : currentlyAppliedFilters?.project_id,
    };

    dispatch({
      type: GET_COMMS_REPORT_LIST,
      payload: {
        urlParam: {
          offset: pageNumber * Constant.TABLE_PAGE_SIZE,
          limit: Constant.TABLE_PAGE_SIZE,
          ...urlParams,
        },
        pagination: true,
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedReport(params?.reportData);

    if (params?.actionType === `VIEW`) {
      setIsDetailsPage(true);
    }
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push('/dashboard');
    } else if (title === `Comms Reports`) {
      setIsDetailsPage(false);

      const urlParams = {
        status: currentlyAppliedFilters?.status === `` ? null : currentlyAppliedFilters?.status?.value,
        event_type: currentlyAppliedFilters?.event_type === `` ? null : currentlyAppliedFilters?.event_type?.label,
        channel: currentlyAppliedFilters?.channel === `` ? null : currentlyAppliedFilters?.channel?.value,
        tracker_id: currentlyAppliedFilters?.tracker_id === `` ? null : currentlyAppliedFilters?.tracker_id,
        template_name: currentlyAppliedFilters?.template_name === `` ? null : currentlyAppliedFilters?.template_name?.label,
        project_id: currentlyAppliedFilters?.project_id === `` ? null : currentlyAppliedFilters?.project_id,
      };

      dispatch({
        type: GET_COMMS_REPORT_LIST,
        payload: {
          urlParam: {
            offset: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            limit: Constant.TABLE_PAGE_SIZE,
            ...urlParams,
          },
          pagination: true,
        },
      });
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (commsReportLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [commsReportLoading]);

  return (
    <>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ----------------------------  UI'S ---------------------------- */}
          {!isDetailsPage && (
            <>
              <BreadCrumb title="Comms Report" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Comms Reports']} />
              <CustomFilter
                filterHandler={filterSubmitHandler}
                appliedFilters={currentlyAppliedFilters}
                show={isShowFilters}
                onCloseClick={() => setIsShowFilters(false)}
              />
              <Table
                title={`Comms Report`}
                data={commsReport?.results}
                totalData={commsReport?.count}
                pageHandler={paginationHandler}
                pageNumber={currentPageNumber}
                actionHandler={tableActionHandler}
                isShowFilterBtn={true}
                filterBtnHandler={() => {
                  setIsShowFilters(true);
                }}
              />
            </>
          )}

          {isDetailsPage && (
            <>
              <BreadCrumb title="Comms Report" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Comms Reports', 'Details']} />
              <Details details={currentSelectedReport} />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default CommsReport;
