import { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Tooltip, Row } from 'reactstrap';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import HTMLReactParser from 'html-react-parser';
import moment from 'moment';

function DetailsInfo({ details, editActionHandler }) {
  const [ttop, setttop] = useState(false);

  const [customActiveTab, setcustomActiveTab] = useState('1');
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row className="gx-lg-5">
                <Col xl={4} md={8} className="mx-auto">
                  <div className="product-img-slider sticky-side-div">
                    <div className="text-muted">
                      <h5 className="fs-14"> Display Images</h5>
                    </div>

                    <Swiper navigation={true} modules={[Navigation]} className="mySwiper mb-5">
                      {[details?.images?.displayImageWeb ?? '', details?.images?.displayImageWeb ?? '']?.map((mobileImage, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <div className="swiper-wrapper">
                              <img
                                src={`${mobileImage !== '' ? mobileImage : 'http://via.placeholder.com/640x360'}`}
                                alt=""
                                className="img-fluid d-block"
                                style={{ width: '100%', height: '200px' }}
                              />
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>

                    <div className="text-muted">
                      <h5 className="fs-14"> Cover Images</h5>
                    </div>
                    <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
                      {[details?.images?.coverImageMobile ?? '', details?.images?.coverImageWeb ?? '']?.map((coverImage, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <div className="swiper-wrapper">
                              <img
                                src={`${coverImage !== '' ? coverImage : 'http://via.placeholder.com/640x360'}`}
                                alt=""
                                className="img-fluid d-block"
                                style={{ width: '100%', height: '200px' }}
                              />
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </Col>

                <Col xl={8}>
                  <div className="mt-xl-0 mt-5">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <Row>
                          <Col md={2} sm={12}>
                            <img
                              src={details?.images?.logo ?? `http://via.placeholder.com/640x360`}
                              alt=""
                              // className="img-fluid d-block"
                              style={{ width: '80px', height: '80px' }}
                            />
                          </Col>

                          <Col md={10} sm={12}>
                            {' '}
                            <h4>{details?.displayName}</h4>
                            <div className="hstack gap-3 flex-wrap">
                              {details?.partner?.name && (
                                <>
                                  <div className="text-muted">
                                    Brand : <span className="text-body fw-medium">{details?.partner?.name}</span>
                                  </div>
                                  <div className="vr"></div>
                                </>
                              )}

                              {details?.facility?.name && (
                                <>
                                  <div className="text-muted">
                                    Outlet : <span className="text-body fw-medium">{details?.facility?.name}</span>
                                  </div>
                                  <div className="vr"></div>
                                </>
                              )}

                              {details?.category?.name && (
                                <>
                                  <div className="text-muted">
                                    Sub-Category : <span className="text-body fw-medium">{details?.category?.name}</span>
                                  </div>
                                  <div className="vr"></div>
                                </>
                              )}

                              <div className="text-muted">
                                Published :{' '}
                                <span className="text-body fw-medium">{moment(new Date(`${details?.createdAt}`)).format('DD-MM-YYYY, h:mm A')}</span>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="flex-shrink-0">
                        <div
                          onClick={() =>
                            editActionHandler({
                              details: details,
                              actionType: `UPDATE`,
                            })
                          }
                        >
                          <Tooltip
                            placement="top"
                            isOpen={ttop}
                            target="TooltipTop"
                            toggle={() => {
                              setttop(!ttop);
                            }}
                          >
                            Edit
                          </Tooltip>
                          <span href="apps-ecommerce-add-product" id="TooltipTop" className="btn btn-light">
                            <i className="ri-pencil-fill align-bottom"></i>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 text-muted">
                      <h5 className="fs-14"> Short Description :</h5>
                      <p> {HTMLReactParser(details?.shortDescription)}</p>
                    </div>

                    <div className="mt-4 text-muted">
                      <h5 className="fs-14"> Long Description :</h5>
                      <p> {HTMLReactParser(details?.longDescription)}</p>
                    </div>

                    {details?.amenities?.length > 0 && (
                      <div className="mt-3">
                        <h5 className="fs-14">Amenities :</h5>
                        <ul className="list-unstyled">
                          {details?.amenities?.map((amenity, index) => (
                            <li className="py-1" key={index}>
                              <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i> {amenity?.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}

                    {/* <div className="mt-4 text-muted">
                                            <h5 className="fs-14"> Redemption Process :</h5>
                                            <p> {HTMLReactParser(details?.redemptionProcess)}</p>
                                        </div> */}

                    {/* <div className="mt-4 text-muted">
                                            <h5 className="fs-14"> Term & Conditions :</h5>
                                            <p>{HTMLReactParser(details?.termsAndCondition)}</p>
                                        </div> */}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default DetailsInfo;
