import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../Containers/Config/index.js';
import { GET_USER_CARDS, GET_USER_CARDS_SUCCESS, GET_USER_CARDS_ERROR } from '../../actionType.js';
import apiCall from '../../apiCall.js';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getUserCards({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/payment/v1/cards`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload.urlParam,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_USER_CARDS_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(`${response?.response?.data?.message}`);
      yield put({
        type: GET_USER_CARDS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_USER_CARDS_ERROR, payload: error });
  }
}

export function* watchGetUserCards() {
  yield takeEvery(GET_USER_CARDS, getUserCards);
}

function* getUserCardsSaga() {
  yield all([fork(watchGetUserCards)]);
}

export default getUserCardsSaga;
