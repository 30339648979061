import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../Containers/Config/index.js';
import { GET_BOOKING_TYPES_LIST, GET_BOOKING_TYPES_LIST_SUCCESS, GET_BOOKING_TYPES_LIST_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getBookingTypesList({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/bookingTypes`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        projectId: payload?.urlParam?.projectId,
        bookingTypeId: payload?.urlParam?.bookingTypeId,
        pageIndex: payload?.urlParam?.pageIndex,
        pageSize: Constant?.TABLE_PAGE_SIZE,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      yield put({
        type: GET_BOOKING_TYPES_LIST_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_BOOKING_TYPES_LIST_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_BOOKING_TYPES_LIST_ERROR, payload: error });
  }
}

export function* watchGetBookingTypesList() {
  yield takeEvery(GET_BOOKING_TYPES_LIST, getBookingTypesList);
}

function* bookingTypesListSaga() {
  yield all([fork(watchGetBookingTypesList)]);
}

export default bookingTypesListSaga;
