import { useState } from 'react';

import moment from 'moment';
import { Row, Col, Button, Modal, ModalBody, Card, CardBody, ModalHeader, ModalFooter } from 'reactstrap';

import UpdateMembership from './UpdateMembership';

const Memberships = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [membershipData, setMembershipData] = useState(null);
  const [modal, setModal] = useState(false);

  return (
    <Modal centered isOpen={true} scrollable={true} size="lg">
      <ModalHeader
        className="bg-soft-info p-3"
        toggle={() => {
          props.onCloseHandler();
        }}
      >
        Memberships
      </ModalHeader>

      <ModalBody>
        <Row>
          {props?.data?.length > 0 ? (
            props?.data?.map((membership, index) => (
              <Col md={4} key={index}>
                <Card className="card-animate bg-light">
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <div>
                        {/* <p className="fw-medium mb-0 mt-2">Membership</p> */}
                        <h5 className="mt-1 ff-secondary fw-semibold">
                          <span className="counter-value">Membership:</span>
                        </h5>
                        <h6 className="mt-1 ff-secondary fw-semibold text-muted">
                          <span className="counter-value">{`${membership?.membershipId}`}</span>
                        </h6>
                        <h6 className="mt-4 ff-secondary fw-semibold">
                          <span className="counter-value">ID-{`${membership?.benefitGroup?.name}`}</span>
                        </h6>
                        <p className="mb-0">
                          Uploaded At: <span className="text-muted">{`${moment(membership?.createdAt)?.format('DD-MM-YY')}`}</span>
                        </p>
                        <p className="mb-0">
                          Expiry: <span className="text-muted">{`${moment(membership?.expiryDate)?.format('DD-MM-YY')}`}</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span
                          href="apps-ecommerce-add-product"
                          id="TooltipTop"
                          className="btn btn-light"
                          onClick={() => {
                            setMembershipData(membership);
                            setModal(true);
                          }}
                        >
                          <i className="ri-pencil-fill align-bottom"></i>
                        </span>
                      </div>
                      <div></div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))
          ) : (
            <h5 className="text-center">Membership is not available.</h5>
          )}
        </Row>
      </ModalBody>

      {/* <ModalFooter className="border border-top-1 d-flex justify-content-center">
        <div className="hstack gap-2">
          <Button
            color="danger"
            onClick={() => {
              props.onCloseHandler();
            }}
          >
            Close
          </Button>
        </div>
      </ModalFooter> */}

      {modal && <UpdateMembership data={membershipData} setModal={setModal} />}
    </Modal>
  );
};

export default Memberships;
