import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Flatpickr from 'react-flatpickr';

import { Button, Modal, ModalBody, ModalHeader, Col, Label, Input, Row } from 'reactstrap';
import { UPDATE_CLIENTS_CUSTOMER } from '../../../../../store/actions';

const UpdateClientsCustomerModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    expiryDate: '',
  };
  const [state, setState] = useState(initialObj);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  const [modal_varying1, setmodal_varying1] = useState(true);

  useEffect(() => {
    if (data !== null) {
      setState({
        expiryDate: data?.expiryDate,
      });
      validationCheck({
        expiryDate: data?.expiryDate,
      });
    }
  }, [data]);

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'expiryDate':
        updatedState = {
          ...state,
          expiryDate: new Date(date).toISOString(),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const payload = {
      expiryDate: new Date(state.expiryDate).toJSON(),
      objectId: data?.objectId,
    };

    dispatch({
      type: UPDATE_CLIENTS_CUSTOMER,
      payload: {
        data: payload,
      },
    });

    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data?.expiryDate !== ``) {
      setIsSubmitBtnDisabled(false);
    } else {
      setIsSubmitBtnDisabled(true);
    }
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Change Expiry
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log(state)}>Get state</button> */}
        <form>
          <Row>
            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="name" className="form-label">
                    Expiry Date
                    <span className="icon_calendar">
                      <i className="las la-calendar"></i>
                    </span>
                  </Label>
                  <Flatpickr
                    className="form-control"
                    name="expiry-date"
                    id="expiry-date"
                    value={state?.expiryDate}
                    onChange={date => datePickHandler(`expiryDate`, date)}
                    options={{
                      defaultMinute: '59',
                      defaultHour: '23',
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                    }}
                  />
                </div>
              </Col>
            </div>
          </Row>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default React.memo(UpdateClientsCustomerModal);
