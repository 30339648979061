import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'reactstrap';

import AddPartnerForm from './Components/Forms/Form';
import { BreadCrumb, Loader } from '../../../../Common';
import BulkUploadModal from './Components/Modals/BulkUpload';
import { CREATE_PARTNER_EMPTY, GET_EMPTY_POST_COUPON } from '../../../../../store/application/actionType';

const AddPartner = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);

  const [isBulkUploadShow, setIsBulkUploadShow] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Create Partner
  const { createPartner, createPartnerLoading } = useSelector(state => ({
    createPartner: state?.CreatePartner?.data,
    createPartnerLoading: state?.CreatePartner?.loading,
  }));

  // Categories
  const { CategoriesLoading } = useSelector(state => ({
    CategoriesLoading: state?.GetCouponCategory?.loading,
  }));

  // Redemption Types
  const { redemptionTypesLoading } = useSelector(state => ({
    redemptionTypesLoading: state?.GetRedemptionType?.loading,
  }));

  // Booking Types
  const { bookingTypesLoading } = useSelector(state => ({
    bookingTypesLoading: state?.BookingTypes?.loading,
  }));

  // Bulk Upload
  const { bulkUpload, bulkUploadLoading } = useSelector(state => ({
    bulkUpload: state?.PostCoupons?.data,
    bulkUploadLoading: state?.PostCoupons?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (createPartner !== null) {
      history.push('/partner/manage-partner');
      dispatch({
        type: CREATE_PARTNER_EMPTY,
      });
    }
  }, [createPartner]);

  useEffect(() => {
    if (bulkUpload !== null) {
      dispatch({
        type: GET_EMPTY_POST_COUPON,
      });
    }
  }, [bulkUpload]);

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (createPartnerLoading || CategoriesLoading || redemptionTypesLoading || bookingTypesLoading || bulkUploadLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [createPartnerLoading, CategoriesLoading, redemptionTypesLoading, bookingTypesLoading, bulkUploadLoading]);

  /* ---------------------------- BULK UPLOAD HANDLER ---------------------------- */
  const bulkUploadActionHandler = () => {
    setIsBulkUploadShow(true);
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push('/dashboard');
    } else if (title === `Brand`) {
      // do nothing
      history.push(`/partner/manage-partner`);
    } else if (title === `Add Brand`) {
      // do nothing
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ---------------------------- MODAL'S ----------------------------  */}
          {isBulkUploadShow && <BulkUploadModal onCloseHandler={() => setIsBulkUploadShow(false)} />}

          {/*  ---------------------------- PAGES ----------------------------  */}
          <>
            <BreadCrumb title="Brand" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Brand', 'Add Brand']} />
            <AddPartnerForm bulkUploadAction={bulkUploadActionHandler} title="Add Brand" />
          </>
        </Container>
      </div>
    </>
  );
};

export default AddPartner;
