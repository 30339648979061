import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button, CardFooter, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Constant } from '../../../../../../Helpers/constant';
import { baseUrl, customHeaders, uaeProjectsBaseURL } from '../../../../../../Config';
import { GET_TRACKER_DATA } from '../../../../../../../store/actions';
import axios from 'axios';

const AdvancedFilter = ({ filters, setFilters, onClose }) => {
  const dispatch = useDispatch();

  /* ---------------------------- TYPED GOLF COURSES HANDLER ---------------------------- */
  async function getGolfCourses(search) {
    return await axios({
      method: `GET`,
      url: `${uaeProjectsBaseURL}/admin/facilities`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        categoryId: process.env.REACT_APP_GOLF_CATEGORY_ID,
        name: search === '' ? null : search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data.objectId,
              label: `${data.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  /* ---------------------------- ON SELECT USER ---------------------------- */
  const onSelectGolfCourse = event => {
    setFilters({ ...filters, facilityName: event });
  };

  const onSubmitHandler = () => {
    let urlParams = {
      status: filters?.status === `` ? null : filters?.status?.value,
      startDate: filters?.startDate === '' || filters?.startDate === 'Invalid date' ? null : filters?.startDate,
      endDate: filters?.endDate === '' || filters?.endDate === 'Invalid date' ? null : filters?.endDate,
      uploadBy: filters?.uploadBy === '' ? null : filters?.uploadBy?.value,
      facilityName: filters?.facilityName === '' ? null : filters?.facilityName?.label,
      limit: Constant?.TABLE_PAGE_SIZE,
      skip: 0,
      orderBy: `_created_at`,
      fileType: `SUPPLY-DATA`,
    };

    dispatch({
      type: GET_TRACKER_DATA,
      payload: {
        urlParam: {
          ...urlParams,
        },
      },
    });

    onClose();
  };

  return (
    <Modal isOpen={true} centered={true}>
      <ModalHeader
        className="bg-soft-info p-3"
        toggle={() => {
          onClose();
        }}
      >
        Additional Filters
      </ModalHeader>

      <ModalBody>
        <Col md={12}>
          <Label className="form-label">Golf Course</Label>
          <AsyncPaginate
            debounceTimeout={500}
            value={filters?.facilityName}
            loadOptions={getGolfCourses}
            onChange={e => onSelectGolfCourse(e)}
            isMulti={false}
            closeMenuOnSelect={true}
            noOptionsMessage={() => 'No results found'}
            cacheUniqs={[['code']]}
            placeholder="Enter Name..."
            isClearable
          />
        </Col>
      </ModalBody>

      <ModalFooter className="border border-top-1 pt-2 d-flex justify-content-center">
        <Button type="submit" color="success" onClick={onSubmitHandler}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AdvancedFilter;
