import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Col, Input, Label, Modal, ModalBody, Row, Spinner } from 'reactstrap';

import { Error } from '../../../../../Common';
import { UPDATE_CUSTOMER_DATA } from '../../../../../../store/actions';
import MobileField from '../../../../../Common/FormControls/MobileField';
import { isValidEmail, isValidNumber } from '../../../../../Helpers/Helpers';
import cogoToast from 'cogo-toast';

const initialValues = {
  firstName: '',
  email: '',
  phoneNumber: '',
  countryCode: '',
  id: '',
};

const UpdateUser = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [state, setState] = useState(initialValues);
  const [initialState, setInitialState] = useState(initialValues);
  const [isValidationShow, setIsValidationShow] = useState(false);
  const [loading, setLoading] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { updateUserLoading } = useSelector(state => ({
    updateUserLoading: state.UpdateCustomer.loading,
  }));

  useEffect(() => {
    if (props?.data !== null) {
      const initialData = {
        id: props?.data?.userId || '',
        firstName: props?.data?.firstName || '',
        email: props?.data?.email || '',
        phoneNumber: props?.data?.phoneNumber || '',
        countryCode: props?.data?.countryCode || '',
      };
      setState(initialData);
      setInitialState(initialData); // Set initial state to compare later
    }
  }, [props?.data]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;

    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));

    validateErrors({ ...state, [name]: value }, 'validationCheck');
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data?.email?.trim() === '' && data?.phoneNumber?.trim() === '') {
      setIsValidationShow(true);
      return false;
    } else if (data?.email?.trim() !== '' && !isValidEmail(data?.email?.trim())) {
      setIsValidationShow(true);
      return false;
    } else if (data?.phoneNumber?.trim() !== '' && !isValidNumber(data?.phoneNumber?.trim())) {
      setIsValidationShow(true);
      return false;
    } else {
      setIsValidationShow(false);
      return true;
    }
  };

  const getChangedFields = (state, initialState) => {
    const changedFields = {};
    for (const key in state) {
      if (state[key]?.trim() !== initialState[key]?.trim()) {
        changedFields[key] = state[key];
      }
    }
    return changedFields;
  };

  const updateUserHandler = () => {
    if (validateErrors(state, 'submissionCheck')) {
      const changedFields = getChangedFields(state, initialState);
      if (Object.keys(changedFields).length > 0) {
        dispatch({
          type: UPDATE_CUSTOMER_DATA,
          payload: {
            data: {
              ...changedFields,
              id: state.id,
              firstName: state.firstName,
            },
          },
        });
      } else {
        cogoToast.error('You need to change a field to update the user');
      }
    }
  };

  useEffect(() => {
    if (updateUserLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [updateUserLoading]);

  const mobileChangeHandler = async (value, data) => {
    const updatedState = {
      ...state,
      countryCode: data?.dialCode,
      phoneNumber: value.slice(data?.dialCode?.length),
    };
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  return (
    <Modal centered isOpen={true} scrollable={true} id="user-cards-list" size="md">
      <div className="modal-header p-3 bg-soft-warning">
        <h5 className="modal-title" id="createboardModalLabel">
          Update User
        </h5>
        <Button
          type="button"
          onClick={() => props.setModal(false)}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>
      <ModalBody>
        <form>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="firstName" className="form-label">
                    Name
                  </Label>
                  <Input
                    id="firstName"
                    name="firstName"
                    type="text"
                    className="form-control"
                    value={state?.firstName}
                    onChange={inputChangeHandler}
                    placeholder=""
                    disabled={true}
                  />
                </div>
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="email" className="form-label">
                    Email
                  </Label>
                  <Input
                    id="email"
                    name="email"
                    type="text"
                    className="form-control"
                    value={state?.email}
                    onChange={inputChangeHandler}
                    placeholder=""
                  />
                </div>
                {isValidationShow && state?.email?.trim() !== `` && !isValidEmail(state?.email) && <Error text="Please enter correct email" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="phoneNumber" className="form-label">
                    Mobile Number
                  </Label>
                  <MobileField
                    onChange={mobileChangeHandler}
                    value={`${state?.countryCode}${state?.phoneNumber}`}
                    preferredCountries={['sa', 'uae', 'in']}
                    defaultCountry={'in'}
                  />
                </div>
                {isValidationShow && state?.phoneNumber?.trim() !== `` && !isValidNumber(state?.phoneNumber) && (
                  <Error text="Please enter correct number" />
                )}
              </div>
            </Col>
          </Row>
          {isValidationShow && state?.email?.trim() === '' && state?.phoneNumber?.trim() === '' && (
            <Error text="Please enter either an email or a mobile number" />
          )}
        </form>
      </ModalBody>

      <div className="modal-footer d-flex justify-content-center pt-3">
        <Button className="btn btn-primary" color="primary" onClick={updateUserHandler}>
          {loading ? (
            <Spinner animation="border" role="status" size="sm">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            'Update'
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateUser;
