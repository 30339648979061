import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { Grid, _ } from 'gridjs-react';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { hasPermission } from '../../../../Helpers/Helpers';
import { PreviewCardHeader, TablePagination } from '../../../../Common';
import { Constant } from '../../../../Helpers/constant';
import { PermissionConstants } from '../../../../Helpers/Constants/permissions';

const CustomersTable = props => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      setData(
        props?.data?.map((data, index) => {
          return [
            `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            `${data?.user?.name}` || `NA`,
            data?.benefitGroup?.name || `NA`,
            data?.successCount || `NA`,
            data?.failedCount || `NA`,
            data?.error?.join(`, `) || `NA`,
            data?.createdAt ? moment(new Date(`${data?.createdAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            data,
          ];
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `VIEW_DETAILS`) {
      props.actionHandler({
        data: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader
              title="Customer Data"
              primaryButton={hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CUSTOMER_DATA_MANAGEMENT)}
              primaryButtonText={`Upload Customer`}
              primaryClickHandler={() => props?.uploadCustomers()}
            />
            <CardBody>
              <div id="table-gridjs">
                <Grid
                  data={data}
                  columns={[
                    {
                      name: 'S.No.',
                      width: '70px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Name',
                      width: '140',
                      formatter: (cell, row) => _(<span>{cell}</span>),
                    },

                    {
                      name: 'Benefit Group',
                      width: '140',
                      formatter: (cell, row) => _(<span>{cell}</span>),
                    },

                    {
                      name: 'Success Count',
                      width: '140',
                      formatter: (cell, row) => _(<span>{cell}</span>),
                    },

                    {
                      name: 'Error Count',
                      width: '140',
                      formatter: (cell, row) => _(<span>{cell}</span>),
                    },

                    {
                      name: 'Error',
                      width: '140',
                      formatter: (cell, row) => _(<span>{cell}</span>),
                    },

                    {
                      name: 'Uploaded At',
                      width: '170',
                      formatter: (cell, row) => _(<span>{cell}</span>),
                    },

                    {
                      name: 'Actions',
                      width: '120px',
                      formatter: (cell, row) =>
                        _(
                          <div className="d-flex justify-content-center flex-column">
                            <>
                              <span
                                className="btn btn-sm btn-primary mb-1"
                                onClick={() => {
                                  actionBtnHandler(`VIEW_DETAILS`, cell);
                                }}
                              >
                                View Details
                              </span>
                            </>
                          </div>,
                        ),
                    },
                  ]}
                  search={false}
                  sort={false}
                  fixedHeader={true}
                  height="400px"
                  pagination={{ enabled: false, limit: 5 }}
                />
              </div>
              {props.children}
            </CardBody>

            <CardFooter>
              <Row className="custom_pagination">
                <Col>
                  <div className="d-flex justify-content-end">
                    <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CustomersTable;
