import React, { useEffect, useState } from 'react';
import { isValidEmail, isValidNumber } from '../../../../../Helpers/Helpers';
import moment from 'moment';
import { PreviewCardHeader } from '../../../../../Common';
import { Button, Col, Input, Label, Row, TabPane } from 'reactstrap';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import PickupModal from '../../Components/Modal/Pickup';
import DateUtils from '../../../../../Helpers/DateUtils';

const initialState = {
  email: '',
  name: '',
  phoneNumber: '',
  journeyType: null,
  dateOfService: null,
  timeOfService: '',
  porter: null,
  noOfBags: null,
  airportName: null,
  flightNumber: '',
  terminalNumber: '',
  classOfTravel: null,
  type: 'self',
  remarks: '',
};
const porterOptions = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'NO',
  },
];

const classOfTravelOptions = [
  {
    label: 'Economy Class',
    value: 'economy',
  },
  {
    label: 'Business Class',
    value: 'business',
  },
];

const journeyTypeOptions = [
  {
    label: 'Arrival',
    value: 'arrival',
  },
  {
    label: 'Departure',
    value: 'departure',
  },
];

const FlightDetails = ({ activeTab, toggleTab, bookingDetails, submitFlightAndAddress }) => {
  const [bookingType, setBookingType] = useState('self');
  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);
  const [airportOptions, setAirportOptions] = useState([]);
  const [isPickupModalOpen, setIsPickupModalOpen] = useState(false);
  const [labelPickup, setLabelPickup] = useState('');
  const [addressOrAirportList, setAddressOrAirportList] = useState(null);
  const [noOfBags, setNoOfBags] = useState([]);

  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      submitFlightAndAddress(state);
    }
  };

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };
    switch (name) {
      case 'name':
        updatedState = {
          ...state,
          name: value,
        };
        break;

      case 'email':
        updatedState = {
          ...state,
          email: value,
        };
        break;

      case 'phoneNumber':
        updatedState = {
          ...state,
          phoneNumber: value,
        };
        break;

      case 'terminalNumber':
        updatedState = {
          ...state,
          terminalNumber: value,
        };
        break;

      case 'flightNumber':
        updatedState = {
          ...state,
          flightNumber: value,
        };
        break;

      case 'timeOfService':
        updatedState = {
          ...state,
          timeOfService: value,
        };
        break;

      case 'remarks':
        updatedState = {
          ...state,
          remarks: value,
        };
        break;

      default:
      // do nothing
    }
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = null;

    if (label === `journeyType`) {
      updatedState = {
        ...state,
        journeyType: data,
      };
      setState(updatedState);
    } else if (label === `porter`) {
      updatedState = {
        ...state,
        porter: data,
        noOfBags: null,
      };
      setState(updatedState);
    } else if (label === `noOfBags`) {
      updatedState = {
        ...state,
        noOfBags: data,
      };
      setState(updatedState);
    } else if (label === `airportName`) {
      updatedState = {
        ...state,
        airportName: data,
      };
      setState(updatedState);
    } else if (label === `classOfTravel`) {
      updatedState = {
        ...state,
        classOfTravel: data,
      };
      setState(updatedState);
    }
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (
      (state.type !== 'guest' || // Check if bookingType is not guest or
        (state.type === 'guest' && // If type is guest, then check required fields
          data?.name?.trim() !== '' &&
          isValidEmail(data?.email?.trim()) &&
          isValidNumber(data?.phoneNumber?.trim()))) &&
      (state.porter?.label === 'No' || // Check if porter is not 'Yes' or
        (state.porter?.label === 'Yes' && // If porter is 'Yes', then check if noOfBags is provided
          data?.noOfBags !== null)) && // Check for noOfBags
      data?.journeyType !== null &&
      data?.dateOfService !== null &&
      data?.timeOfService !== null &&
      data?.airportName !== null &&
      data?.flightNumber.trim() !== '' &&
      data?.terminalNumber.trim() !== '' &&
      data?.classOfTravel !== null
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;
    switch (flag) {
      case 'dateOfService':
        updatedState = {
          ...state,
          dateOfService: moment(new Date(date[0])).format('Y-MM-DD'),
        };
        setState(updatedState);
        break;
      default:
    }
    validateErrors(updatedState, `validationCheck`);
  };

  const togglePickupModal = () => {
    setIsPickupModalOpen(!isPickupModalOpen);
    setAddressOrAirportList(null);
  };

  const handlePickup = (flag: string, data) => {
    setState({ ...state, [flag]: data });
    setIsPickupModalOpen(false);
  };

  const handleOpenAddress = () => {
    // setIsAddressModalOpen(true);
  };
  useEffect(() => {
    setNoOfBags(
      Array.from({ length: bookingDetails?.carTypes?.[0]?.luggages }, (_, index) => ({
        value: (index + 1).toString(),
        label: (index + 1).toString(),
      })),
    );
    setAirportOptions(
      bookingDetails?.airports?.map(airport => {
        return {
          label: airport?.nickName,
          value: airport?.nickName,
          data: airport,
        };
      }),
    );
  }, [bookingDetails]);

  return (
    <TabPane tabId={2}>
      <PickupModal
        isOpen={isPickupModalOpen}
        toggle={togglePickupModal}
        airportAndAddress={addressOrAirportList}
        handlePickup={handlePickup}
        handleOpenAddress={handleOpenAddress}
        label={labelPickup}
      />
      <div className="mb-3 d-flex flex-column flex-md-row align-items-center justify-content-between">
        <div className="d-flex gap-2 align-items-center mb-2 mb-md-0">
          <PreviewCardHeader title="Passenger & Luggage Details" />
        </div>{' '}
        <div className="d-flex align-items-center gap-3">
          <div className="mr-2 mr-md-3">Booking for</div>
          <div className="form-check mr-2 mr-md-3">
            <Input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              checked={state.type === 'self'}
              onClick={() => setState({ ...state, type: 'self' })}
            />
            <Label className="form-check-label" for="flexRadioDefault1" style={{ marginLeft: '5px' }}>
              Self
            </Label>
          </div>
          {/* <div className="form-check">
            <Input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              checked={state.type === 'guest'}
              onClick={() => setState({ ...state, type: 'guest' })}
            />
            <Label className="form-check-label" for="flexRadioDefault2" style={{ marginLeft: '5px' }}>
              Guest
            </Label>
          </div> */}
        </div>
      </div>
      <Row>
        <Col xxl={12}>
          <Row>
            {state?.type === 'guest' && (
              <>
                <h5 style={{ textDecoration: 'underline', marginBottom: '10px' }}>Booking for Guest</h5>
                <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                  <div className="mb-3">
                    <Label htmlFor="name" className="form-label">
                      Guest Name
                      <span className="lbl_mandatory"> *</span>
                    </Label>
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      className="form-control"
                      onChange={inputChangeHandler}
                      value={state.name}
                      autoComplete="off"
                    />
                    {isValidationShow && state?.name.trim() === '' && <div className="text-danger">Please enter name </div>}
                  </div>
                </Col>

                <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                  <div className="mb-3">
                    <Label htmlFor="name" className="form-label">
                      Guest Email
                      <span className="lbl_mandatory"> *</span>
                    </Label>
                    <div className="input-group">
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        className="form-control"
                        onChange={inputChangeHandler}
                        value={state.email}
                        autoComplete="off"
                        disabled={false}
                      />
                    </div>
                    {isValidationShow && state?.email.trim() === '' && <div className="text-danger">Please enter email </div>}
                    {isValidationShow && state?.email?.trim() !== '' && !isValidEmail(state?.email?.trim()) && (
                      <div className="text-danger">Please enter correct email</div>
                    )}
                  </div>
                </Col>

                <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                  <div className="mb-3">
                    <Label htmlFor="name" className="form-label">
                      Guest Mobile
                      <span className="lbl_mandatory"> *</span>
                    </Label>
                    <Input
                      id="phoneNumber"
                      name="phoneNumber"
                      type="number"
                      onWheel={(e: any) => e.target.blur()}
                      className="form-control"
                      onChange={inputChangeHandler}
                      value={state.phoneNumber}
                      autoComplete="off"
                      disabled={false}
                    />
                    {isValidationShow && state?.phoneNumber.trim() === '' && <div className="text-danger">Please enter mobile no </div>}
                  </div>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
              <div className="mb-3">
                <Label htmlFor="noOfPassenger" className="form-label">
                  Journey Type
                  <span className="lbl_mandatory"> *</span>
                </Label>
                <Select
                  id="journeyType"
                  name="journeyType"
                  value={state.journeyType}
                  options={journeyTypeOptions}
                  onChange={data => inputSelectHandler('journeyType', data)}
                  isDisabled={false}
                />
                {isValidationShow && state?.journeyType === null && <div className="text-danger">Please select journey</div>}
              </div>
            </Col>

            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
              <div className="mb-3">
                <Label htmlFor="name" className="form-label">
                  Date Of Service
                  <span className="lbl_mandatory"> *</span>
                </Label>
                <Flatpickr
                  className="form-control"
                  onChange={date => datePickHandler(`dateOfService`, date)}
                  value={state?.dateOfService}
                  options={{
                    dateFormat: 'Y-m-d',
                    minDate: new Date().fp_incr(
                      DateUtils.getMinDateInDays(bookingDetails?.tat?.delayedMinTat, bookingDetails?.tat?.delayedMinTatUnit),
                    ),
                    maxDate: new Date().fp_incr(
                      DateUtils.getMaxDateInDays(bookingDetails?.tat?.delayedMaxTat, bookingDetails?.tat?.delayedMaxTatUnit),
                    ),
                  }}
                />
                {isValidationShow && state?.dateOfService === null && <div className="text-danger">Please select date</div>}
              </div>
            </Col>

            <Col xl={2} lg={4} md={6} sm={6} xs={12}>
              <div className="mb-3">
                <Label htmlFor="name" className="form-label">
                  Time Of Service
                  <span className="lbl_mandatory"> *</span>
                </Label>
                <Input
                  type="time"
                  name="timeOfService"
                  className="form-control"
                  style={{ width: '100% important' }}
                  value={state.timeOfService}
                  onChange={inputChangeHandler}
                />
                {isValidationShow && state?.timeOfService === '' && <div className="text-danger">Please select time</div>}
              </div>
            </Col>

            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
              <div className="mb-3">
                <Label htmlFor="noOfBabySeat" className="form-label">
                  Porter
                  <span className="lbl_mandatory"> *</span>
                </Label>
                <Select
                  id="porter"
                  name="porter"
                  value={state.porter}
                  options={porterOptions}
                  onChange={data => inputSelectHandler('porter', data)}
                  isDisabled={false}
                />
                {isValidationShow && state?.porter === null && <div className="text-danger">Please select porter </div>}
              </div>
            </Col>

            {state?.porter?.value === 'Yes' && (
              <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className="mb-3">
                  <Label htmlFor="noOfLuggage" className="form-label">
                    No Of Bags
                    <span className="lbl_mandatory"> *</span>
                  </Label>
                  <Select
                    id="noOfBags"
                    name="noOfBags"
                    value={state.noOfBags}
                    options={noOfBags}
                    onChange={data => inputSelectHandler('noOfBags', data)}
                    isDisabled={false}
                  />
                  {isValidationShow && state?.noOfBags === null && <div className="text-danger">Please select bags</div>}
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <div className="mb-3">
        <PreviewCardHeader title="Flight Details" />
      </div>
      <Row>
        <Col xxl={12}>
          <Row>
            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
              <div className="mb-3">
                <Label htmlFor="terminalNo" className="form-label">
                  Airport
                  <span className="lbl_mandatory"> *</span>
                </Label>
                <Select
                  id="airportName"
                  name="airportName"
                  value={state.airportName}
                  options={airportOptions}
                  onMenuOpen={() => {
                    if (!isPickupModalOpen) {
                      setIsPickupModalOpen(true);
                      setLabelPickup('airportName');
                      setAddressOrAirportList({ isAddress: false, list: airportOptions, selectedValue: state?.airportName });
                    }
                  }}
                  isDisabled={false}
                />
                {isValidationShow && state?.airportName === null && <div className="text-danger">Please select airport</div>}
              </div>
            </Col>
            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
              <div className="mb-3">
                <Label htmlFor="name" className="form-label">
                  Flight Number
                  <span className="lbl_mandatory"> *</span>
                </Label>
                <Input
                  id="flightNumber"
                  name="flightNumber"
                  type="text"
                  className="form-control"
                  onChange={inputChangeHandler}
                  value={state.flightNumber}
                  autoComplete="off"
                  disabled={false}
                />
                {isValidationShow && state?.flightNumber.trim() === '' && <div className="text-danger">Please enter flight no </div>}
              </div>
            </Col>

            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
              <div className="mb-3">
                <Label htmlFor="terminalNo" className="form-label">
                  Terminal Number
                  <span className="lbl_mandatory"> *</span>
                </Label>
                <Input
                  id="terminalNumber"
                  name="terminalNumber"
                  type="text"
                  className="form-control"
                  onChange={inputChangeHandler}
                  value={state.terminalNumber}
                  autoComplete="off"
                  disabled={false}
                />
                {isValidationShow && state?.terminalNumber.trim() === '' && <div className="text-danger">Please enter a terminal no</div>}
              </div>
            </Col>
            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
              <div className="mb-3">
                <Label htmlFor="terminalNo" className="form-label">
                  Class of Travel
                  <span className="lbl_mandatory"> *</span>
                </Label>
                <Select
                  id="classOfTravel"
                  name="classOfTravel"
                  value={state.classOfTravel}
                  options={classOfTravelOptions}
                  onChange={data => {
                    inputSelectHandler('classOfTravel', data);
                  }}
                  isDisabled={false}
                />
                {isValidationShow && state?.classOfTravel === null && <div className="text-danger">Please select class</div>}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="remarks" className="form-label">
                  Remarks
                </Label>
                <Input type="textarea" name="remarks" id="remarks" rows="3" value={state?.remarks} onChange={inputChangeHandler} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="d-flex align-items-start gap-3 mt-4">
        <button
          type="button"
          className="btn btn-outline-success btn-label previestab"
          onClick={() => {
            toggleTab(activeTab - 1, 0);
          }}
        >
          <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Previous
        </button>
        <button type="button" className="btn btn-success btn-label right ms-auto nexttab nexttab" onClick={submitHandler}>
          <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
          Continue
        </button>
      </div>
    </TabPane>
  );
};

export default FlightDetails;
