import React, { useEffect, useState } from 'react';
import Navigation from './components/Navigation/Navigation';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_CLIENTS_EMPTY, GET_CLIENTS, GET_COUNTRY, GET_PROJECTS_LIST } from '../../../../../store/actions';
import { BreadCrumb, Loader } from '../../../../Common';
import { CardBody, Col, Container, Form, Row } from 'reactstrap';
import ClientCreationForm from './components/Forms/ClientCreation';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PlatformConfiguration from './components/Forms/PlatformConfiguration';
import ProgramConfiguration from './components/Forms/ProjectConfiguration';
import Client from './components/Modal/Client';
import ProgramsList from './components/Tables/ProjectsList';
import Platform from './components/Modal/Platform';

const ProgramOnboarding = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [activeTab, setactiveTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [clientModal, setClientModal] = useState(false);
  const [platformModal, setPlatformModal] = useState(false);
  const [currentlySelectedProgram, setCurrentlySelectedProgram] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [programOnboarding, setProgramOnboarding] = useState(false);
  const [searchedName, setSearchedName] = useState('');
  const [allProjectsList, setAllProgramsList] = useState([]);

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (countries === null) {
      dispatch({
        type: GET_COUNTRY,
      });
    }

    // dispatch({
    //   type: GET_CLIENTS,
    //   payload: {
    //     urlParam: {
    //       pageIndex: currentPageIndex,
    //       pageSize: 100,
    //     },
    //   },
    // });

    return () => {
      dispatch({
        type: CREATE_CLIENTS_EMPTY,
      });
    };
  }, [dispatch]);

  // Create Client
  const { createClient, createClientLoading } = useSelector(state => ({
    createClient: state?.CreateClient?.data,
    createClientLoading: state?.CreateClient?.loading,
  }));

  const { updateClient, updateClientLoading } = useSelector(state => ({
    updateClient: state?.UpdateClient?.data,
    updateClientLoading: state?.UpdateClient?.loading,
  }));

  // Countries List
  const { countries, countriesLoading } = useSelector(state => ({
    countries: state?.Countries?.data,
    countriesLoading: state?.Countries?.loading,
  }));

  // Projects List
  const { projectsList, projectsListLoading } = useSelector(state => ({
    projectsList: state?.ProjectsList?.data,
    projectsListLoading: state?.ProjectsList?.loading,
  }));

  const submitParentHandler = (data, name) => {
    if (name === 'client') {
      setSelectedClient(data);
    }
  };

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_PROJECTS_LIST,
      payload: {
        urlParam: {
          pageIndex: 0,
          pageSize: 100,
        },
      },
    });
    return () => {};
  }, [dispatch]);

  useEffect(() => {
    if (createClientLoading || countriesLoading || updateClientLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [createClientLoading, countriesLoading, updateClientLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Client Management`) {
      //   setIsClientsShow(true);
      //   setIsCreateClientFormShow(false);
      //   dispatch({
      //     type: GET_CLIENTS,
      //     payload: {
      //       urlParam: {
      //         pageIndex: currentPageIndex,
      //         pageSize: 100,
      //       },
      //     },
      //   });
      history.push(`/project`);
    }
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        setactiveTab(tab);
      }
    }
  }
  const selectedProgramHandler = program => {
    program = {
      id: 'IERPyIvLEL',
      name: 'ADIB',
      internalName: 'ADIB',
      code: 'ADIB',
      logo: '',
      url: 'adib.thriwe.com',
      configId: 'k5Y24ThaHq',
      client: {
        id: 'jMLJXIW9Dc',
        name: 'EPIFI',
        description: 'Fi Fintech',
        contractingCountry: {
          id: 101,
          name: 'India',
        },
        clientType: 'Banking',
        industry: 'Banking',
        isActive: true,
      },
      parentProject: {
        objectId: 'N48Zr5BGZe',
        name: 'CBD',
        description: '',
        code: 'CBD',
        client: {
          __type: '',
          className: '',
          objectId: 'qrdX9BthTI',
        },
        internalName: '',
        brandingType: {
          id: '',
          name: '',
        },
        contractingCountry: {
          id: '231',
          name: 'United Arab Emirates',
        },
        isActive: false,
        url: 'https://cbdgolf.thriwe.com',
        logo: '',
        bookingTypes: null,
      },
      bookingTypes: [],
      contractingCountry: {
        id: '231',
        name: 'United Arab Emirates',
      },
      configuration: {
        objectId: 'k5Y24ThaHq',
        payment: {
          brandingType: 'standard',
          paymentViaCard: true,
          paymentViaNonCard: false,
          paymentLinkFunctionality: false,
          cardDuplicationCheck: false,
          cardDuplicationLevel: '',
          secure3dsRequired: false,
          gateways: [
            {
              priority: 0,
              status: 'active',
              pgData: {
                name: 'PG2C2P',
                code: 'PG03',
                logo: 'https://2c2p.com/assets/images/2C2P_Logo_RGB_Dark_Green-01.png',
                objectId: 'ZlgMsklZR3',
              },
            },
          ],
          gatewaySelection: 'server_side',
          binDirectory: {
            isActive: false,
            allowInRequest: 'not_required',
          },
          cardDirectory: {
            isActive: false,
            allowInRequest: 'non_mandatory',
            cardsLimit: '',
            cardInfoLevel: '',
            registerViaBinLocking: false,
            duplicacyAllowed: false,
            duplicacyLevel: '',
            tokenization: false,
            tokenizationVendor: '',
          },
        },
        images: {
          logo: '',
        },
        ums: {
          isEnabled: false,
          version: '',
        },
      },
      startDate: '0001-01-01T00:00:00Z',
      endDate: '0001-01-01T00:00:00Z',
      invoices: null,
      cost: 2000,
      capping: '2323',
    };
    setCurrentlySelectedProgram(program);
    setProgramOnboarding(true);
  };

  const createProgramBtnHandler = () => {
    setCurrentlySelectedProgram(null);
    setProgramOnboarding(true);
  };

  /* ---------------------------- SEARCH PROJECT BY NAME HANDLER ---------------------------- */
  const searchProjectHandler = e => {
    setSearchedName(e.target.value);
    let filteredProjectsName = projectsList?.results;
    if (e.target.value.trim() !== '') {
      filteredProjectsName = allProjectsList?.filter(project => project?.name?.toLocaleLowerCase()?.includes(e.target.value?.toLocaleLowerCase()));
    }
    setAllProgramsList([...filteredProjectsName]);
  };
  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {clientModal && <Client countries={countries} onCloseHandler={() => setClientModal(false)} />}

          {platformModal && <Platform selectedClient={selectedClient} onCloseHandler={() => setPlatformModal(false)} />}

          {programOnboarding && (
            <>
              <BreadCrumb title="Project" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Client Management`, `Create`]} />
              <CardBody className="checkout-tab">
                <Form action="#">
                  <div className="step-arrow-nav">
                    <Navigation activeTab={activeTab} toggleTab={toggleTab} />
                  </div>
                  {activeTab === 1 && (
                    <ClientCreationForm
                      countries={countries}
                      toggleTab={toggleTab}
                      submitParentHandler={submitParentHandler}
                      updatedData={currentlySelectedProgram}
                    />
                  )}
                  {activeTab === 2 && (
                    <PlatformConfiguration toggleTab={toggleTab} selectedClient={selectedClient} updatedData={currentlySelectedProgram} />
                  )}
                  {activeTab === 3 && <ProgramConfiguration updatedData={currentlySelectedProgram} toggleTab={toggleTab} />}
                </Form>
              </CardBody>
            </>
          )}
          {!programOnboarding && (
            <>
              {' '}
              <BreadCrumb title="Program" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Program Management`]} />
              <Row className="g-4 mb-3">
                <Col className="col-sm-auto">
                  <div className="btn btn-success" onClick={createProgramBtnHandler}>
                    <i className="ri-add-line align-bottom me-1"></i> New Program Onboarding
                  </div>
                </Col>
                <Col className="col-sm-auto">
                  <div className="btn btn-success" onClick={() => setClientModal(true)}>
                    Clients
                  </div>
                </Col>
                <Col className="col-sm-auto">
                  <div className="btn btn-success" onClick={() => setPlatformModal(true)}>
                    Platform
                  </div>
                </Col>
                <Col className="col-sm">
                  <div className="d-flex justify-content-sm-end gap-2">
                    <div className="search-box ms-2">
                      <input type="text" className="form-control" placeholder="Search..." onChange={searchProjectHandler} value={searchedName} />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </Col>
              </Row>
              <ProgramsList
                selecteProgram={selectedProgramHandler}
                programs={projectsList?.results}
                allProjectsList={allProjectsList}
                setAllProgramsList={setAllProgramsList}
              />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default ProgramOnboarding;
