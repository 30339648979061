import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'reactstrap';

import Table from './Components/Tables/Table';
import { Constant } from '../../Helpers/constant';
import { BreadCrumb, Loader } from '../../Common';
import { DOWNLOADS_LIST, DOWNLOADS_LIST_EMPTY } from '../../../store/application/actionType';

const Downloads = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Downloads
  const { downloads, downloadsLoading } = useSelector(state => ({
    downloads: state?.DownloadsList?.data,
    downloadsLoading: state?.DownloadsList?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (downloads === null) {
      dispatch({
        type: DOWNLOADS_LIST,
        payload: {
          urlParam: {
            pageIndex: currentPageIndex,
          },
        },
      });
    }
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch({
        type: DOWNLOADS_LIST_EMPTY,
      });
    };
  }, [dispatch]);

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    if (params?.actionType === `DOWNLOAD`) {
      window.open(params?.downloadDetails?.file, `_blank`);
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);

    dispatch({
      type: DOWNLOADS_LIST,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  /* ---------------------------- REFRESH HANDLER ---------------------------- */
  const refreshHandler = () => {
    dispatch({
      type: DOWNLOADS_LIST,
      payload: {
        urlParam: {
          pageIndex: currentPageIndex,
        },
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (downloadsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [downloadsLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Downloads`) {
      // do nothing
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          <BreadCrumb title="Dump Download" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Downloads`]} />
          <Table
            data={downloads?.results}
            totalData={downloads?.count}
            pageNumber={currentPageNumber}
            dataStartedFrom={currentPageIndex}
            pageHandler={paginationHandler}
            actionHandler={tableActionHandler}
            refreshHandler={refreshHandler}
          />
        </Container>
      </div>
    </>
  );
};

export default Downloads;
