import React, { useState } from 'react';

import TabNavigation from './TabNavigation';
import { Card, CardBody, Col, Form, Row, TabContent } from 'reactstrap';
import CatalogueItems from './TabContent/CatalogueItems';
import CatalogueProperties from './TabContent/CatalogueProperties';
import CataloguePreview from './TabContent/CataloguePreview';
import CreateBenefitGroupItemModal from './Modals/CreateBenefitGroupItem';

const CatalogueForm = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [isBenefitGroupModal, setIsBenefitGroupModal] = useState(false);

  const toggleArrowTab = tab => {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        setActiveTab(tab);
      }
    }
  };

  const toggleBenefitGroupModal = () => {
    setIsBenefitGroupModal(!isBenefitGroupModal);
  };

  return (
    <>
      <CreateBenefitGroupItemModal status={isBenefitGroupModal} onClose={toggleBenefitGroupModal} />
      <Row>
        <Col xl="12">
          <Card className="card_custom">
            <CardBody>
              <Form action="#">
                <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                  <TabNavigation curActiveTab={activeTab} toggleHandler={toggleArrowTab} />
                </div>

                <TabContent activeTab={activeTab}>
                  <CatalogueProperties curActiveTab={activeTab} toggleHandler={toggleArrowTab} />

                  <CatalogueItems curActiveTab={activeTab} toggleHandler={toggleArrowTab} />

                  <CataloguePreview curActiveTab={activeTab} toggleHandler={toggleArrowTab} />
                </TabContent>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CatalogueForm;
