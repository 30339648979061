import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'reactstrap';

import Table from './Components/Tables/Table';
import { Constant } from '../../../../../Helpers/constant';
import { BreadCrumb, Loader } from './../../../../../Common';
import {
  GET_CLIENT_CUSTOMER,
  GET_CLIENT_CUSTOMER_EMPTY,
  GET_VENDOR_DOWNLOAD_BOOKINGS,
  GET_VENDOR_DOWNLOAD_BOOKINGS_EMPTY,
} from '../../../../../../store/application/actionType';
import Filter from './Components/Filters/Filter';

const ClientCustomers = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // GET CUSTOMERS
  const { ClientCustomers, ClientCustomersLoading } = useSelector(state => ({
    ClientCustomers: state?.GetClientCustomers?.data,
    ClientCustomersLoading: state?.GetClientCustomers?.loading,
  }));

  // DOWNLOAD DUMP
  const { downloadDump, downloadDumpLoading } = useSelector(state => ({
    downloadDump: state?.DownloadBookingsDump?.data,
    downloadDumpLoading: state?.DownloadBookingsDump?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_CLIENT_CUSTOMER,
      payload: {
        urlParam: {
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
        },
        headers: {
          code: 'ADIB',
        },
      },
    });

    return () => {
      dispatch({
        type: GET_CLIENT_CUSTOMER_EMPTY,
      });
    };
  }, []);

  useEffect(() => {
    if (downloadDump !== null) {
      dispatch({
        type: GET_VENDOR_DOWNLOAD_BOOKINGS_EMPTY,
      });
    }
  }, [downloadDump]);

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);

    dispatch({
      type: GET_CLIENT_CUSTOMER,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
        },
        headers: {
          code: 'ADIB',
        },
      },
    });
  };

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = filters => {
    setCurrentlyAppliedFilters(filters);

    setCurrentPageNumber(0);

    dispatch({
      type: GET_CLIENT_CUSTOMER,
      payload: {
        urlParam: {
          pageIndex: 0,
          ...filters,
        },
        headers: {
          code: 'ADIB',
        },
      },
    });
  };

  /* ---------------------------- DOWNLOAD DUMP HANDLER ---------------------------- */
  const downloadDumpHandler = () => {
    dispatch({
      type: GET_VENDOR_DOWNLOAD_BOOKINGS,
      payload: {
        urlParam: {
          // bookingTypeId,
          downloadType: 3,
          projectId: JSON.parse(window.localStorage.getItem('data'))?.program?.id,
        },
        data: {
          // vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
          ...currentlyAppliedFilters,
        },
        headers: {
          'Project-Code': 'ADIB_V2',
        },
      },
    });
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/client/dashboard`);
    } else if (title === `Customers Report`) {
      // nothing to do here
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (ClientCustomersLoading || downloadDumpLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [ClientCustomersLoading, downloadDumpLoading]);

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        {/*  ----------------------------  UI'S ---------------------------- */}
        <Container fluid className="position-relative">
          <>
            <BreadCrumb title="Customers Report" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Customers Report']} />
            <Filter filterHandler={filterSubmitHandler} appliedFilters={currentlyAppliedFilters} setCurrentPageNumber={setCurrentPageNumber} />
            <Table
              title={`Customers Report`}
              data={ClientCustomers?.results ?? []}
              totalData={ClientCustomers?.count}
              pageHandler={paginationHandler}
              pageNumber={currentPageNumber}
              dumpDownload={downloadDumpHandler}
            />
          </>
        </Container>
      </div>
    </>
  );
};

export default ClientCustomers;
