import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as moment from 'moment';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import { Card, CardBody, Col, Form, Label, Row, CardFooter, Input, UncontrolledDropdown } from 'reactstrap';

import { PreviewCardHeader } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import {
  GET_BOOKING_LIST_EMPTY,
  GET_REGION,
  GET_REGION_EMPTY,
  GET_SUB_REGION,
  GET_SUB_REGION_EMPTY,
  GET_PARTNER,
  GET_LOCATION,
  GET_SERVICE,
  GET_LOCATION_EMPTY,
  GET_SERVICE_EMPTY,
} from '../../../../../../../store/application/actionType';

const statuses = [
  {
    value: Constant?.BOOKING_STATUS?.PENDING,
    label: `Pending`,
  },
  {
    label: `Processed`,
    value: Constant?.BOOKING_STATUS?.PROCESSED,
  },
  {
    label: `Confirmed`,
    value: Constant?.BOOKING_STATUS?.CONFIRM,
  },
  {
    label: `Cancelled (ALL)`,
    value: Constant?.BOOKING_STATUS?.CANCELLED,
  },
  {
    label: `Cancelled by User`,
    value: Constant?.BOOKING_STATUS?.CANCEL_BY_USER,
  },
  {
    label: `Cancelled by Thriwe`,
    value: Constant?.BOOKING_STATUS?.CANCEL_BY_THRIWE,
  },
  {
    label: `Short Notice Cancelled by User`,
    value: Constant?.BOOKING_STATUS?.SHORT_NOTICE_CANCELLED_BY_USER,
  },
  {
    label: `Short Notice Cancelled by Thriwe`,
    value: Constant?.BOOKING_STATUS?.SHORT_NOTICE_CANCELLED_BY_THRIWE,
  },
];

const initialState = {
  status: 0,
  statusRange: null,
  createdAt: '',
  createdAtRange: {
    from: '',
    to: '',
  },
  bookingDate: '',
  bookingDateRange: {
    from: '',
    to: '',
  },
  updatedAtRange: {
    from: '',
    to: '',
  },
  category: '',
  partner: '',
  facility: '',
  service: '',
  countryId: 0,
  regionId: 0,
  subRegionId: 0,

  bookingId: '',
  membershipId: '',
  expiryDate: '',
  paymentStatus: 0,
  redeemedDate: '',
  couponCode: '',
  familyMemberName: '',
  customerDetails: {
    name: '',
    email: '',
    mobile: '',
  },
  slaBreached: false,
  sortOn: 'updatedAt',
};

const Filter = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [filter, setFilter] = useState(initialState);
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);
  const [isResetDisabled, setIsResetDisabled] = useState(true);

  const [categories, setCategories] = useState(null);
  const [partners, setPartners] = useState(null);
  const [facilities, setFacilities] = useState(null);
  const [services, setServices] = useState(null);

  const [searchCode, setSearchCode] = useState('0');
  const [searchValue, setSearchValue] = useState('');
  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (props?.appliedFilters) {
      setFilter(props?.appliedFilters);
    }
  }, [props?.appliedFilters]);

  useEffect(() => {
    setCategories(props?.data?.categories);
    setPartners(props?.data?.partners);
    setFacilities(props?.data?.locations);
    setServices(props?.data?.services);
  }, [props]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...filter };

    switch (name) {
      case 'search':
        setSearchCode(value);
        setSearchValue('');
        break;

      case 'search-value':
        if (searchCode !== '0') {
          setSearchValue(value);
        }
        break;

      case 'category':
        updatedState = {
          ...filter,
          category: value,
        };

        dispatch({
          type: GET_PARTNER,
          payload: {
            urlParam: {
              categoryId: value,
            },
            pagination: false,
          },
        });
        break;

      case 'partner':
        updatedState = {
          ...filter,
          partner: value,
        };

        dispatch({
          type: GET_LOCATION,
          payload: {
            urlParam: {
              partnerId: value,
            },
            pagination: false,
          },
        });

        dispatch({
          type: GET_SERVICE,
          payload: {
            urlParam: {
              partnerId: value,
            },
            pagination: false,
          },
        });
        break;

      case 'facility':
        updatedState = {
          ...filter,
          facility: value,
        };

        dispatch({
          type: GET_SERVICE,
          payload: {
            urlParam: {
              facilityId: value,
            },
            pagination: false,
          },
        });
        break;

      case 'service':
        updatedState = {
          ...filter,
          service: value,
        };
        break;

      case 'booking-id':
        updatedState = {
          ...filter,
          bookingId: value,
        };
        break;

      case 'membership-id':
        updatedState = {
          ...filter,
          membershipId: value,
        };
        break;

      case 'customer-name':
        updatedState = {
          ...filter,
          customerDetails: {
            ...filter.customerDetails,
            name: value,
          },
        };
        break;

      case 'customer-mobile':
        updatedState = {
          ...filter,
          customerDetails: {
            ...filter.customerDetails,
            mobile: value,
          },
        };
        break;

      case 'customer-email':
        updatedState = {
          ...filter,
          customerDetails: {
            ...filter.customerDetails,
            email: value,
          },
        };
        break;

      case 'coupon-code':
        updatedState = {
          ...filter,
          couponCode: value,
        };
        break;

      case 'payment-status':
        updatedState = {
          ...filter,
          paymentStatus: parseInt(value),
        };
        break;

      case 'status-type':
        updatedState = {
          ...filter,
          status: parseInt(value),
        };
        break;

      case 'country':
        updatedState = { ...filter, countryId: parseInt(value) };

        dispatch({
          type: GET_REGION,
          payload: {
            urlParam: {
              countryId: parseInt(value),
            },
          },
        });
        break;

      case 'region':
        updatedState = { ...filter, regionId: parseInt(value) };

        dispatch({
          type: GET_SUB_REGION,
          payload: {
            urlParam: {
              regionId: parseInt(value),
            },
          },
        });
        break;

      case 'sub-region':
        updatedState = { ...filter, subRegions: parseInt(value) };
        break;

      case 'family-member-name':
        updatedState = {
          ...filter,
          familyMemberName: value,
        };
        break;

      case 'sla':
        updatedState = { ...filter, slaBreached: value === 'true' };
        break;

      case 'sortOn':
        updatedState = { ...filter, sortOn: value };
        break;

      default:
      // do nothing
    }

    setFilter(updatedState);
  };

  /* ---------------------------- DATE PICKR HANDLER ---------------------------- */
  const datePickHandler = (flag, date) => {
    let updatedState = { ...filter };

    switch (flag) {
      case 'createdAtRange':
        updatedState = {
          ...filter,
          createdAtRange: {
            from: moment(new Date(date[0])).format('Y-MM-DD'),
            to: moment(new Date(date[1])).format('Y-MM-DD'),
          },
        };
        break;

      case 'updatedAtRange':
        updatedState = {
          ...filter,
          updatedAtRange: {
            from: moment(new Date(date[0])).format('Y-MM-DD'),
            to: moment(new Date(date[1])).format('Y-MM-DD'),
          },
        };
        break;

      case 'dateOfPlayRange':
        updatedState = {
          ...filter,
          bookingDateRange: {
            from: moment(new Date(date[0])).format('Y-MM-DD'),
            to: moment(new Date(date[1])).format('Y-MM-DD'),
          },
        };
        break;

      case 'expiryDate':
        updatedState = {
          ...filter,
          expiryDate: moment(new Date(date)).format('Y-MM-DD'),
        };
        break;

      case 'redemeedDate':
        updatedState = {
          ...filter,
          redeemedDate: moment(new Date(date)).format('Y-MM-DD'),
        };
        break;

      default:
      // do nothing
    }

    setFilter(updatedState);
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    let { statusRange } = filter;
    statusRange = status?.map(data => data.value);

    let filterValue = { ...filter, customerDetails: { ...initialState.customerDetails }, bookingId: '', membershipId: '', statusRange };

    if (searchCode !== '0' && searchValue !== ``) {
      if (searchCode === '1') {
        filterValue = {
          ...filterValue,
          customerDetails: {
            ...filterValue.customerDetails,
            name: searchValue,
          },
        };
        setFilter(filterValue);
      } else if (searchCode === '2') {
        filterValue = {
          ...filterValue,
          customerDetails: {
            ...filterValue.customerDetails,
            mobile: searchValue,
          },
        };
        setFilter(filterValue);
      } else if (searchCode === '3') {
        filterValue = {
          ...filterValue,
          customerDetails: {
            ...filterValue.customerDetails,
            email: searchValue,
          },
        };
        setFilter(filterValue);
      } else if (searchCode === '4') {
        filterValue = {
          ...filterValue,
          bookingId: searchValue,
        };
        setFilter(filterValue);
      } else if (searchCode === '5') {
        filterValue = {
          ...filterValue,
          membershipId: searchValue,
        };
        setFilter(filterValue);
      }
    }
    props.filterHandler({ ...filterValue, filter: true });
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter(initialState);
    setSearchCode('0');
    setSearchValue('');
    setStatus(null);
    props.filterHandler({ ...initialState, filter: false });

    dispatch({
      type: GET_BOOKING_LIST_EMPTY,
    });

    dispatch({
      type: GET_REGION_EMPTY,
    });
    dispatch({
      type: GET_SUB_REGION_EMPTY,
    });
    dispatch({
      type: GET_LOCATION_EMPTY,
    });
    dispatch({
      type: GET_SERVICE_EMPTY,
    });
    dispatch({
      type: GET_PARTNER,
    });

    props?.onReset();
  };

  const inputSelectHandler = (label, data) => {
    if (label === `status-type`) {
      setStatus(data);
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Filter" />
            {/* <button onClick={() => console.log({ filter })}>get state</button> */}
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="search" className="form-label">
                          Search
                        </Label>
                        <UncontrolledDropdown className="input-group">
                          <select
                            id="search"
                            name="search"
                            className="form-select truncate cursor-pointer"
                            style={{ maxWidth: '35%' }}
                            value={searchCode}
                            onChange={inputChangeHandler}
                          >
                            <option value="0">Select</option>
                            <option value="1">First name</option>
                            <option value="2">Mobile</option>
                            <option value="3">Email</option>
                            <option value="4">Booking Id</option>
                            {/* <option value="5">Membership Id</option> */}
                          </select>
                          <Input
                            id="search-value"
                            name="search-value"
                            type="text"
                            className="form-control"
                            aria-label="Text input with dropdown button"
                            value={searchValue}
                            onChange={inputChangeHandler}
                          />
                        </UncontrolledDropdown>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="priority_type" className="form-label">
                          Booking Status
                        </Label>
                        <Select
                          name="status-type"
                          options={statuses}
                          isMulti={true}
                          value={status}
                          onChange={data => inputSelectHandler('status-type', data)}
                        />
                      </div>
                    </Col>

                    {/* <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="category" className="form-label">
                          Category
                        </Label>
                        <select
                          id="category"
                          name="category"
                          className="form-select"
                          value={filter?.category}
                          onChange={inputChangeHandler}
                        >
                          <option value="0">Choose...</option>
                          {categories?.map((category, index) => (
                            <option key={index} value={category?.objectId}>
                              {category?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col> */}

                    {/* <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="partner" className="form-label">
                          Partner
                        </Label>
                        <select
                          id="partner"
                          name="partner"
                          className="form-select"
                          value={filter?.partner}
                          onChange={inputChangeHandler}
                        >
                          <option value="0">Choose...</option>
                          {partners?.map((partner, index) => (
                            <option key={index} value={partner?.objectId}>
                              {partner?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col> */}

                    {/* <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="facility" className="form-label">
                          Facility
                        </Label>
                        <select
                          id="facility"
                          name="facility"
                          className="form-select"
                          value={filter?.facility}
                          onChange={inputChangeHandler}
                        >
                          <option value="0">Choose...</option>
                          {facilities?.map((facility, index) => (
                            <option key={index} value={facility?.objectId}>
                              {facility?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col> */}

                    {/* <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="service" className="form-label">
                          Service
                        </Label>
                        <select
                          id="service"
                          name="service"
                          className="form-select"
                          value={filter?.service}
                          onChange={inputChangeHandler}
                        >
                          <option value="0">Choose...</option>
                          {services?.map((service, index) => (
                            <option key={index} value={service?.objectId}>
                              {service?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col> */}

                    {/* <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="booking-id" className="form-label">
                          Booking Id
                        </Label>
                        <Input
                          id="booking-id"
                          name="booking-id"
                          type="text"
                          placeholder=""
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={filter?.bookingId}
                        />
                      </div>
                    </Col> */}

                    {/* <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="membership-id" className="form-label">
                          Membership Id
                        </Label>
                        <Input
                          id="membership-id"
                          name="membership-id"
                          type="text"
                          placeholder=""
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={filter?.membershipId}
                        />
                      </div>
                    </Col> */}

                    {/* <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="customer-name" className="form-label">
                          Customer Name
                        </Label>
                        <Input
                          id="customer-name"
                          name="customer-name"
                          type="text"
                          placeholder=""
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={filter?.customerDetails?.name}
                        />
                      </div>
                    </Col> */}

                    {/* <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="customer-mobile" className="form-label">
                          Customer Mobile
                        </Label>
                        <Input
                          id="customer-mobile"
                          name="customer-mobile"
                          type="number"
                          placeholder=""
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={filter?.customerDetails?.mobile}
                        />
                      </div>
                    </Col> */}

                    {/* <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="customer-email" className="form-label">
                          Customer Email
                        </Label>
                        <Input
                          id="customer-email"
                          name="customer-email"
                          type="text"
                          placeholder=""
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={filter?.customerDetails?.email}
                        />
                      </div>
                    </Col> */}

                    {/* <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="payment-status" className="form-label">
                          Payment Status
                        </Label>
                        <select
                          id="payment-status"
                          name="payment-status"
                          className="form-select"
                          value={filter?.status}
                          onChange={inputChangeHandler}
                        >
                          <option value="0">Choose...</option>
                          {Constant?.PAYMENT_STATUSES?.map((paymentStatus) => (
                            <option
                              key={paymentStatus.objectId}
                              value={paymentStatus.objectId}
                            >
                              {paymentStatus.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col> */}

                    {/* <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="country" className="form-label">
                          Country
                        </Label>
                        <select
                          id="country"
                          name="country"
                          className="form-select"
                          value={filter?.countryId}
                          onChange={inputChangeHandler}
                        >
                          <option value="0">Choose...</option>
                          {props?.data?.countries?.map((country, index) => (
                            <option key={index} value={country?.countryId}>
                              {country?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col> */}

                    {/* <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="region" className="form-label">
                          Region
                        </Label>
                        <select
                          id="region"
                          name="region"
                          className="form-select"
                          value={filter?.regionId}
                          onChange={inputChangeHandler}
                        >
                          <option value="0">Choose...</option>
                          {props?.data?.regions?.map((region, index) => (
                            <option key={index} value={region?.stateId}>
                              {region?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col> */}

                    {/* <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="sub-region" className="form-label">
                          Sub Region
                        </Label>
                        <select
                          id="sub-region"
                          name="sub-region"
                          className="form-select"
                          value={filter?.subRegionId}
                          onChange={inputChangeHandler}
                        >
                          <option value="0">Choose...</option>
                          {props?.data?.subRegions?.map((subRegion, index) => (
                            <option key={index} value={subRegion?.cityId}>
                              {subRegion?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col> */}

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="created-at-range" className="form-label">
                          Created At - Range
                          <span className="icon_calendar">
                            <i className="las la-calendar"></i>
                          </span>
                        </Label>
                        <div className="area_expandable">
                          <Flatpickr
                            className="form-control"
                            onChange={date => datePickHandler(`createdAtRange`, date)}
                            value={[filter?.createdAtRange?.from, filter?.createdAtRange?.to]}
                            options={{
                              mode: 'range',
                              dateFormat: 'Y-m-d',
                              maxDate: 'today',
                            }}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="date-of-play-range" className="form-label">
                          Updated At - Range
                          <span className="icon_calendar">
                            <i className="las la-calendar"></i>
                          </span>
                        </Label>
                        <div className="area_expandable">
                          <Flatpickr
                            className="form-control"
                            value={[filter?.updatedAtRange?.from, filter?.updatedAtRange?.to]}
                            onChange={date => datePickHandler(`updatedAtRange`, date)}
                            options={{
                              mode: 'range',
                              dateFormat: 'Y-m-d',
                              maxDate: 'today',
                            }}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="date-of-play-range" className="form-label">
                          Booking Date - Range
                          <span className="icon_calendar">
                            <i className="las la-calendar"></i>
                          </span>
                        </Label>
                        <div className="area_expandable">
                          <Flatpickr
                            className="form-control"
                            value={[filter?.bookingDateRange?.from, filter?.bookingDateRange?.to]}
                            onChange={date => datePickHandler(`dateOfPlayRange`, date)}
                            options={{
                              mode: 'range',
                              dateFormat: 'Y-m-d',
                              // maxDate: 'today',
                            }}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="sla" className="form-label">
                          SLA
                        </Label>
                        <select id="sla" name="sla" className="form-select" value={filter?.slaBreached?.toString()} onChange={inputChangeHandler}>
                          <option value="false">None</option>
                          <option value="true">SLA Breach</option>
                        </select>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="sortOn" className="form-label">
                          Sort By
                        </Label>
                        <select id="sortOn" name="sortOn" className="form-select" value={filter?.sortOn} onChange={inputChangeHandler}>
                          <option value="updatedAt">Updated At</option>
                          <option value="createdAt">Created At</option>
                          {/* <option value="displayName">Display Name</option> */}
                          {/* <option value="membershipId">Membership Id</option> */}
                          {/* <option value="bookingStatusCode">Booking Status</option> */}
                          {/* <option value="bookingDate">Booking Date</option> */}
                        </select>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>

            <CardFooter>
              <Row>
                <Col md={12}>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary mx-3" onClick={filterSubmitHandler}>
                      <i className="ri-search-line me-1 text-white fs-14 align-middle"></i>Search
                    </button>

                    <button type="submit" className="btn btn-light" onClick={resetFilterHandler}>
                      Reset Filter
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Filter;
