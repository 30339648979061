import { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { VENDOR_DASHBOARD_DATA } from '../../../../../store/actions';

const Dashboard = () => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [summaryData, setSummaryData] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { BookingSummary } = useSelector((state: any) => ({
    BookingSummary: state?.GetVendorsDashboardData?.data,
  }));

  useEffect(() => {
    dispatch({
      type: VENDOR_DASHBOARD_DATA,
      payload: {
        urlParam: {
          toDate: moment(new Date()).format('YYYY-MM-DD'),
          fromDate: moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
        },
      },
    });
  }, []);

  useEffect(() => {
    if (BookingSummary !== null) {
      setSummaryData([
        {
          id: 1,
          label: 'Requested booking',
          badge: 'ri-arrow-up-circle-line text-success',
          icon: 'ri-space-ship-line',
          counter: BookingSummary?.data?.bookings?.requested,
          decimals: 0,
          suffix: '',
          prefix: '',
        },
        {
          id: 2,
          label: 'Confirmed booking',
          badge: 'ri-arrow-up-circle-line text-success',
          icon: 'ri-exchange-dollar-line',
          counter: BookingSummary?.data?.bookings?.confirmed,
          decimals: 0,
          suffix: '',
          prefix: '',
        },
        {
          id: 3,
          label: 'Cancelled booking',
          badge: 'ri-arrow-down-circle-line text-danger',
          icon: 'ri-pulse-line',
          counter: `${
            BookingSummary?.data?.bookings?.cancelledByThriwe +
            BookingSummary?.data?.bookings?.cancelledByUser +
            BookingSummary?.data?.bookings?.shortNoticeCancelledByThriwe +
            BookingSummary?.data?.bookings?.shortNoticeCancelledByUser
          }`,
          decimals: 0,
          suffix: '',
          prefix: '',
        },
        {
          id: 4,
          label: 'Complete booking',
          badge: 'ri-arrow-up-circle-line text-success',
          icon: 'ri-trophy-line',
          counter: BookingSummary?.data?.bookings?.completed,
          decimals: 0,
          prefix: '',
          separator: ',',
          suffix: '',
        },
        {
          id: 5,
          label: 'Reject booking',
          badge: 'ri-arrow-down-circle-line text-danger',
          icon: 'ri-service-line',
          counter: BookingSummary?.data?.bookings?.rejected,
          decimals: 0,
          separator: ',',
          suffix: '',
          prefix: '',
        },
      ]);
    }
  }, [BookingSummary]);

  return (
    <div className="page-content">
      <Container>
        <Row>
          <div className="col-xl-12">
            <div className="card crm-widget">
              <div className="card-body p-0">
                <div className="row row-cols-xxl-5 row-cols-md-3 row-cols-1 g-0">
                  {summaryData?.map((widget, index) => (
                    <div className="col" key={index}>
                      <div className="py-4 px-3">
                        <h5 className="text-muted text-uppercase fs-13">
                          {widget.label}
                          {/* <i className={widget.badge + ' fs-18 float-end align-middle'}></i> */}
                        </h5>
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0">
                            <i className={widget.icon + ' display-6 text-muted'}></i>
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h2 className="mb-0">
                              <span className="counter-value" data-target="197">
                                <CountUp
                                  start={0}
                                  prefix={widget?.prefix}
                                  suffix={widget?.suffix}
                                  separator={widget?.separator}
                                  end={widget?.counter}
                                  decimals={widget?.decimals}
                                  duration={4}
                                />
                              </span>
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Dashboard;
