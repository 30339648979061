import { useEffect, useState } from 'react';

import cogoToast from 'cogo-toast';
import DataTable from 'react-data-table-component';
import { Row, Col, Button, Modal, ModalBody, CardFooter, Spinner } from 'reactstrap';

import { baseUrl } from '../../../../../../../../../Config';
import { TablePagination } from '../../../../../../../../../Common';
import { Constant } from '../../../../../../../../../Helpers/constant';
import { hasPermission } from '../../../../../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../../../../../Helpers/Constants/permissions';

const ProgramPricingDetails = ({
  downloadType,
  appliedFilters,
  pricingData,
  pricePreviewData,
  onClose,
  onUpload,
  onFilter,
  currentPageNumber,
  onChangePage,
}) => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [previewData, setPreviewData] = useState([]);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  /* ---------------------------- DYNAMIC DATA HANDLER ---------------------------- */
  useEffect(() => {
    if (pricePreviewData?.data?.results?.length > 0) {
      const rowData = pricePreviewData?.data?.results?.map(rowData => {
        let excelRowData = {};
        Object.entries(rowData)?.forEach(([key, value], index) => {
          excelRowData = {
            ...excelRowData,
            [key]: rowData?.[key]?.['value'],
          };
        });

        return { ...excelRowData, originalData: rowData };
      });

      setPreviewData(rowData);
    } else {
      setPreviewData([]);
    }
  }, [pricePreviewData]);

  /* ---------------------------- FIND COLUMN HANDLER ---------------------------- */
  const findColumnsHandler = () => {
    if (pricePreviewData?.data?.results?.length > 0) {
      const colData = Object.entries(pricePreviewData?.data?.results?.[0])?.map(([key, value], index) => {
        return {
          name: `${value?.['headerName']}`,
          width: '200px',
          wrap: true,
          selector: row => row?.[key],
        };
      });

      return colData;
    } else {
      return [];
    }
  };

  /* ---------------------------- DOWNLOAD FILE HANDLER ---------------------------- */
  const downloadFile = () => {
    setIsDownloadLoading(true);
    fetch(`${baseUrl}/admin/download-excel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Constant?.CLIENT_TOKEN,
        'Project-Code': 'ADMIN_ACCESS',
      },
      body: downloadType
        ? JSON.stringify({
            projectId: `${pricingData?.projectId ?? ''}`,
            categoryId: `${pricingData?.category?.id ?? ''}`,
            fileConfigId: `${pricingData?.configId ?? ''}`,
            configEnum: 10,
          })
        : JSON.stringify({
            projectId: `${pricingData?.projectId ?? ''}`,
            benefitGroupId: `${pricingData?.benefitGroups?.id ?? ''}`,
            categoryId: `${pricingData?.category?.id ?? ''}`,
            fileConfigId: `${pricingData?.configId ?? ''}`,
            configEnum: 10,
          }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        setIsDownloadLoading(false);
        const link = document.createElement('a');
        const fileName = 'file.xlsx';
        link.setAttribute('download', fileName);
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(error => {
        setIsDownloadLoading(false);
        cogoToast.error('Something went wrong');
      });
  };

  return (
    <Modal isOpen={true} id="restart-confirmation" centered size="lg">
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title">Program Pricing Details</h5>
        <Button
          type="button"
          onClick={() => {
            onClose();
          }}
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        <Row>
          <Col md={12} className="mb-2">
            <h5>
              {`${pricingData?.category?.name}`} - {`${pricingData?.benefitGroups?.name}`}
            </h5>
          </Col>

          <>
            <Col md={12}>
              <p className="fw-semibold">Filter by Price Change Status -</p>
            </Col>

            <Col md={12} className="mb-2">
              {[
                { id: `1`, name: `All records`, value: `` },
                { id: `2`, name: `Ok(All)`, value: `1` },
                { id: `3`, name: `Pending(All)`, value: `2` },
              ]?.map((filter, index) => (
                <button
                  key={index}
                  className={`btn btn-sm rounded-pill mb-2 me-2 ${appliedFilters?.id === filter?.id ? 'btn-primary' : 'btn-outline-primary'}`}
                  onClick={() => onFilter(filter)}
                >
                  <span className="d-flex align-items-center">
                    <span className="flex-grow-1">{filter?.name}</span>
                  </span>
                </button>
              ))}
            </Col>
          </>
          {/* )} */}

          <Col md={12}>
            <DataTable
              columns={findColumnsHandler()}
              data={previewData}
              customStyles={{
                table: {
                  style: {
                    border: '1px solid #afafaf',
                    maxHeight: '350px',
                    overflowY: 'auto',
                  },
                },
                headRow: {
                  style: {
                    borderBottom: '1px solid #ddd', // Header bottom border
                    backgroundColor: '#f9fafb',
                    fontWeight: '600',
                  },
                },
                cells: {
                  style: {
                    border: '0.5px solid #ddd', // Cell borders
                    borderBottom: '0px solid #ddd',
                  },
                },
              }}
            />
          </Col>

          {pricePreviewData?.data?.results?.length > 0 && (
            <Col md={12} className="mt-3 d-flex justify-content-between align-items-center">
              <span>
                Showing <b>{currentPageNumber * Constant?.TABLE_PAGE_SIZE + 1}</b> to&nbsp;
                <b>{currentPageNumber * Constant?.TABLE_PAGE_SIZE + pricePreviewData?.data?.results?.length}</b> of{' '}
                <b>{pricePreviewData?.data?.count}</b> records
              </span>
              <div className="d-flex justify-content-end">
                <TablePagination totalData={pricePreviewData?.data?.count} currentPage={currentPageNumber} onPageChangeHandler={onChangePage} />
              </div>
            </Col>
          )}
        </Row>
      </ModalBody>

      <CardFooter className="pb-0">
        <div className="modal-footer mx-auto d-flex gap-2">
          {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.UPLOAD_FILE_WITH_AUTH_CLIENT_DATA_GM_REPORT) && (
            <button
              type="button"
              className="btn btn-outline-primary btn-md"
              onClick={() => {
                onUpload();
              }}
            >
              <i className="ri-upload-2-line align-middle"></i>&nbsp; Upload
            </button>
          )}

          {pricePreviewData?.data?.results?.length > 0 && (
            <>
              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_FILE_CLIENT_DATA_GM_REPORT) && isDownloadLoading ? (
                <Button color="primary" className="btn-load mx-1" outline>
                  <span className="d-flex align-items-center">
                    <Spinner size="sm" className="flex-shrink-0">
                      Loading...
                    </Spinner>
                    <span className="flex-grow-1 ms-2">Loading...</span>
                  </span>
                </Button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary btn-md mx-1"
                  onClick={() => {
                    downloadFile();
                  }}
                >
                  <i className="ri-download-cloud-line align-middle"></i>&nbsp; Download
                </button>
              )}
            </>
          )}
        </div>
      </CardFooter>
    </Modal>
  );
};

export default ProgramPricingDetails;
