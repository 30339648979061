import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../../Containers/Config/index.js';
import { LIST_EVENT_RULE, LIST_EVENT_RULE_SUCCESS, LIST_EVENT_RULE_ERROR } from './../../../actionType.js';
import apiCall from '../../../apiCall';
import { Constant } from '../../../../../Containers/Helpers/constant.js';

function* getEventRulesList({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/event-rules`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
    });

    if (response.status === 200) {
      yield put({
        type: LIST_EVENT_RULE_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: LIST_EVENT_RULE_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: LIST_EVENT_RULE_ERROR, payload: error });
  }
}

export function* watchEventRulesList() {
  yield takeEvery(LIST_EVENT_RULE, getEventRulesList);
}

function* eventRulesListSaga() {
  yield all([fork(watchEventRulesList)]);
}

export default eventRulesListSaga;
