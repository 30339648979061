import React, { useEffect, useState } from 'react';

import moment from 'moment';
import DataTable from 'react-data-table-component';
import { Badge, Card, CardBody, CardFooter, Col, Row } from 'reactstrap';

import { Constant } from '../../../../../../Helpers/constant';
import { PreviewCardHeader, TablePagination } from '../../../../../../Common';
import { formattedWithComma } from '../../../../../../Helpers/Helpers';

const Table = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No.',
          width: '70px',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
        },
        {
          name: 'Payment ID',
          width: '220px',
          wrap: true,
          selector: row => row?.paymentId,
        },
        {
          name: 'Gateway ID',
          width: '220px',
          wrap: true,
          selector: row => row?.paymentGatewayRefId,
        },
        {
          name: 'Payment Status',
          width: '180px',
          selector: row => checkPaymentStatusHandler(row.paymentStatus),
        },
        {
          name: 'Customer ID',
          width: '200px',
          wrap: true,
          selector: row => row?.customerId,
        },
        {
          name: 'Reference ID',
          width: '250px',
          wrap: true,
          selector: row => row?.clientReferenceId,
        },
        {
          name: 'Currency',
          width: '120px',
          wrap: true,
          selector: row => row?.currency,
        },
        {
          name: 'Amount',
          width: '120px',
          selector: row => row?.amount,
        },
        {
          name: 'Description',
          width: '200px',
          wrap: true,
          selector: row => row?.description,
        },
        {
          name: 'Gateway',
          width: '120px',
          selector: row => row?.paymentGateway,
        },
        {
          name: 'Created At',
          width: '180px',
          selector: row => row?.initiatedAt,
        },
        {
          name: 'Processed At',
          width: '180px',
          selector: row => row?.processedAt,
        },
        {
          name: 'Remarks',
          width: '280px',
          wrap: true,
          selector: row => row?.remarks,
        },
      ];
      setColumns(fetchColumns);

      const data = props?.data?.map((payment, index) => ({
        serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
        paymentId: payment?.paymentId,
        paymentGatewayRefId: payment.paymentGatewayRefId,
        paymentStatus: payment.paymentStatus,
        customerId: payment?.customerId,
        clientReferenceId: payment?.clientReferenceId,
        currency: payment?.currency,
        amount: payment?.amount,
        description: payment?.description,
        paymentGateway: payment?.paymentGateway,
        remarks: payment?.remarks,
        initiatedAt: moment(new Date(`${payment?.timeLogs?.initiatedAt}`)).format('DD-MM-YYYY, h:mm A'),
        processedAt: moment(new Date(`${payment?.timeLogs?.processedAt}`)).format('DD-MM-YYYY, h:mm A'),
      }));
      setData(data);
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- CHECK PAYMENT STATUS HANDLER ---------------------------- */
  const checkPaymentStatusHandler = status => {
    switch (status) {
      case 'created':
        return <Badge color="info"> {`Created`} </Badge>;

      case 'initiated':
        return <Badge color="warning"> {`Initiated`} </Badge>;

      case 'authentication_required':
        return <Badge color="warning"> {`Authentication required`} </Badge>;

      case 'successful':
        return <Badge color="success"> {`Successful`} </Badge>;

      case 'refunded':
        return <Badge color="success"> {`Refunded`} </Badge>;

      case 'refund_failed':
        return <Badge color="danger"> {`Refund failed`} </Badge>;

      case 'failed':
        return <Badge color="danger"> {`Failed`} </Badge>;

      case 'gateway_switched':
        return <Badge color="secondary"> {`Gateway switched`} </Badge>;

      default:
      // Need not to do anything
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader
              title="Payments Report"
              refreshBtn={props?.data?.length > 0 ? true : false}
              refreshHandler={props?.refreshHandler}
              isShowFilterBtn={props?.isShowFilterBtn}
              filterBtnHandler={props?.filterBtnHandler}
            />

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                      paddingTop: '8px',
                      paddingBottom: '8px',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Table, (prevProps, nextProps) => prevProps.data === nextProps.data);
