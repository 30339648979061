import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Flatpickr from 'react-flatpickr';
import * as moment from 'moment';

import { Card, CardBody, Col, Form, Label, Row, CardFooter, Input } from 'reactstrap';
import { PreviewCardHeader } from '../../../../Common';
import { CLIENTS_CUSTOMER_EMPTY } from '../../../../../store/actions';

const CustomersDataFilter = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    name: '',
    email: '',
    mobileNumber: '',
  };
  const [filter, setFilter] = useState(initialState);
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);
  const [isResetDisabled, setIsResetDisabled] = useState(true);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'name':
        updatedState = {
          ...filter,
          name: value,
        };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;

      case 'email':
        updatedState = {
          ...filter,
          email: value,
        };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;

      case 'mobileNumber':
        updatedState = {
          ...filter,
          mobileNumber: value,
        };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    let filterValue = { ...filter };

    props.filterHandler({ ...filterValue });
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter({ ...initialState });
    setIsResetDisabled(true);
    dispatch({
      type: CLIENTS_CUSTOMER_EMPTY,
    });
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (filter?.name !== `` || filter?.email !== `` || filter?.mobileNumber !== ``) {
      setIsResetDisabled(false);
    } else {
      setIsResetDisabled(true);
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Filter" />
            {/* <button onClick={() => console.log(filter)}>get state</button> */}
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="name" className="form-label">
                          Name
                        </Label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          placeholder=""
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={filter?.name}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="email" className="form-label">
                          Email
                        </Label>
                        <Input
                          id="email"
                          name="email"
                          type="text"
                          placeholder=""
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={filter?.email}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="mobileNumber" className="form-label">
                          Mobile No.
                        </Label>
                        <Input
                          id="mobileNumber"
                          name="mobileNumber"
                          type="mobileNumber"
                          placeholder=""
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={filter?.mobileNumber}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>

            <CardFooter>
              <Row>
                <Col md={12}>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary mx-3 overlay-disable" onClick={filterSubmitHandler} disabled={isFilterDisabled}>
                      Search
                    </button>

                    <button type="submit" className="btn btn-primary overlay-disable" onClick={resetFilterHandler} disabled={isResetDisabled}>
                      Reset Filter
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CustomersDataFilter;
