import { useEffect, useState } from 'react';

import DataTable from 'react-data-table-component';

import { hasPermission } from '../../../../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../../../../Helpers/Constants/permissions';
import { Constant } from '../../../../../../../../Helpers/constant';

const ReconTable = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No.',
          width: '80px',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
        },
        {
          name: 'Benefit Type',
          width: '200px',
          selector: row => row?.benefitDomain,
        },
        {
          name: 'Client/Vendor Name',
          width: '200px',
          selector: row => row?.clientOrVendor,
        },
        {
          name: 'Month',
          width: '150px',
          selector: row => (
            <span
              className={`${
                (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.UPLOAD_FILE_WITH_AUTH_BOOKING_RECON_GM_REPORT) ||
                  hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_FILE_BOOKING_RECON_GM_REPORT)) &&
                'text-decoration-underline cursor-pointer'
              }`}
              onClick={() => {
                if (
                  hasPermission(PermissionConstants?.ALL_PERMISSIONS?.UPLOAD_FILE_WITH_AUTH_BOOKING_RECON_GM_REPORT) ||
                  hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_FILE_BOOKING_RECON_GM_REPORT)
                ) {
                  actionBtnHandler(`RECON_DOWNLOAD`, row?.originalData);
                }
              }}
            >
              {row?.originalData?.month} {row?.originalData?.year}
            </span>
          ),
        },
        {
          name: 'Status',
          width: '150px',
          selector: row => row?.status,
        },
        {
          name: 'Recon Status',
          width: '150px',
          selector: row => row?.reconStatus,
        },
        {
          name: 'Action',
          width: '220px',
          cell: row => (
            <div className="d-flex justify-content-center flex-column w-100">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  {(hasPermission(PermissionConstants?.ALL_PERMISSIONS?.UPLOAD_FILE_WITH_AUTH_BOOKING_RECON_GM_REPORT) ||
                    hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_FILE_BOOKING_RECON_GM_REPORT)) && (
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm mx-1"
                      onClick={() => {
                        actionBtnHandler(`RECON_DOWNLOAD`, row?.originalData);
                      }}
                    >
                      <i className="ri-download-cloud-line align-middle"></i>&nbsp; Download
                    </button>
                  )}

                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.UPLOAD_FILE_WITH_AUTH_BOOKING_RECON_GM_REPORT) && (
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm mx-1"
                      onClick={() => {
                        actionBtnHandler(`RECON_UPLOAD`, row?.originalData);
                      }}
                    >
                      <i className="ri-upload-2-line align-middle"></i>&nbsp; Upload
                    </button>
                  )}
                </div>
              </div>
            </div>
          ),
        },
      ];
      setColumns(fetchColumns);

      const data = props?.data?.map((reconData, index) => ({
        serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
        benefitDomain: reconData?.benefitDomain?.name,
        clientOrVendor: reconData?.clientOrVendor,
        month: `${reconData?.month} ${reconData?.year}`,
        status: reconData?.status,
        reconStatus: reconData?.reconStatus?.message,
        originalData: reconData,
      }));
      setData(data);
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `RECON_DOWNLOAD`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `RECON_UPLOAD`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    }
  };

  return (
    <DataTable
      columns={columns ?? []}
      data={data ?? []}
      highlightOnHover
      customStyles={{
        headRow: {
          style: {
            borderBottom: '1px solid #ddd',
            backgroundColor: '#f9fafb',
            fontWeight: '600',
          },
        },
        cells: {
          style: {
            border: '0.5px solid #ddd',
            borderBottom: '0px solid #ddd',
          },
        },
      }}
    />
  );
};

export default ReconTable;
