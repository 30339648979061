import { useEffect, useState } from 'react';

import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Formik, Form, ErrorMessage } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { MentionsInput, Mention } from 'react-mentions';
import { Card, CardBody, CardFooter, Col, Label, Row } from 'reactstrap';

import { CreatableInput } from '../../../../../../Common';
import { GET_VARIABLES } from '../../../../../../../store/actions';
import { BreadCrumb, FormikInput, PreviewCardHeader } from '../../../../../../Common';

const TemplatePage = ({ data, onSubmit, isUpdate, setShowTemplatePage, setIsUpdateFormShow, setIsListShow, setData }) => {
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [list, setList] = useState('');
  const [mailcc, setMailcc] = useState([]);
  const [mailbcc, setMailbcc] = useState([]);
  const [initialValues, setInitialValues] = useState({
    body: '',
    subject: '',
    from: '',
  });

  const { getVariables, getVariablesLoading } = useSelector(state => ({
    getVariables: state.GetVariables?.data?.results,
    getVariablesLoading: state.GetVariables.loading,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: GET_VARIABLES,
    });
  }, []);

  useEffect(() => {
    if (getVariables != null) {
      const getVariablesByChannel = getVariables.filter(variables => data?.channel === variables?.channel);
      if (getVariablesByChannel?.length > 0) {
        setList(
          getVariablesByChannel?.map(variablesList => {
            return {
              id: variablesList?.id,
              display: variablesList?.variable_key,
              data: variablesList,
            };
          }),
        );
      }
    }
  }, [getVariables]);

  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push('/dashboard');
    } else if (title === `Template List`) {
      setIsListShow(true);
      setIsUpdateFormShow(false);
      setShowTemplatePage(false);
      setData(null);
    } else if (title === 'Update Template Form') {
      setShowTemplatePage(false);
      setIsUpdateFormShow(true);
    } else if (title === 'Add Template Form') {
      setShowTemplatePage(false);
    }
  };

  useEffect(() => {
    if (isUpdate) {
      setInitialValues({
        body: data?.content?.body ?? '',
        from: data?.content?.from ?? '',
        subject: data?.content?.subject ?? '',
      });
      setMailcc(
        data?.content?.cc?.map(cc => {
          return { label: cc, value: cc };
        }) ?? [],
      );
      setMailbcc(
        data?.content?.bcc?.map(bcc => {
          return { label: bcc, value: bcc };
        }) ?? [],
      );
    }
  }, [isUpdate]);

  const handleInputChangeForBody = (event, newValue, newPlainTextValue, mentions) => {
    setInitialValues({ ...initialValues, body: newValue });
  };

  const handleInputChangeForSubject = (event, newValue, newPlainTextValue, mentions) => {
    setInitialValues({ ...initialValues, subject: newValue });
  };

  const handleSuggestionClickForSubject = (id, display) => {
    const textarea = document.getElementById('mentionsInputForSubject');
    const cursorPosition = textarea.selectionStart;
    let newValue = initialValues.subject;
    if (newValue[cursorPosition - 1] === '@') {
      newValue = newValue.slice(0, cursorPosition) + newValue.slice(cursorPosition);
    }
    newValue = newValue.slice(0, cursorPosition - 1) + `{{${display}}}` + newValue.slice(cursorPosition);
    setInitialValues({ ...initialValues, subject: newValue });
  };

  const handleSuggestionClickForBody = (id, display) => {
    const textarea = document.getElementById('mentionsInputForBody');
    const cursorPosition = textarea.selectionStart;
    let newValue = initialValues.body;
    if (newValue[cursorPosition - 1] === '@') {
      newValue = newValue.slice(0, cursorPosition) + newValue.slice(cursorPosition);
    }
    newValue = newValue.slice(0, cursorPosition - 1) + `{{${display}}}` + newValue.slice(cursorPosition);
    setInitialValues({ ...initialValues, body: newValue });
  };

  const validationSchema = Yup.object().shape({
    subject: data?.channel === 'email' ? Yup.string().trim().required('Subject is required') : Yup.string().trim().notRequired(),
    body: Yup.string().trim().required('Body is required'),
    from: Yup.string().trim().email('Invalid email format').notRequired(),
  });

  const handleFormSubmit = (values, { setSubmitting }) => {
    const bodyVariables = extractVariables(values.body);
    const subjectVariables = extractVariables(values.subject);
    onSubmit({ ...values, bodyVariables, subjectVariables, bcc: mailbcc, cc: mailcc });
    setSubmitting(false);
  };

  const extractVariables = value => {
    // Extract the variables using a regular expression
    const variableRegex = /\{\{(.*?)\}\}/g;
    const matches = value?.match(variableRegex);

    // Filter and store only the unique variable names
    if (matches?.length > 0) {
      const uniqueVariables = [...new Set(matches.map(match => match.replace(/\{\{|\}\}/g, '')))];

      // Remove curly braces and store the cleaned variables
      const cleanedVariables = uniqueVariables.map(variable => variable.replace(/\{\{|\}\}/g, ''));
      // Check if each cleaned variable exists in the list and store the matched items
      const matchedItems = cleanedVariables.filter(variable => list.some(item => item.display === variable));

      // Store the matched items or perform further actions as needed
      return matchedItems;
    } else {
      return [];
    }
  };

  return (
    <>
      <BreadCrumb
        title="Communication"
        navigationby={breadcrumNavigationHandler}
        navigation={
          isUpdate ? ['Dashboard', 'Template List', 'Update Template Form', 'Update Template'] : ['Dashboard', 'Add Template Form', 'Create Template']
        }
      />
      <Row>
        <Col xxl={12}>
          <Card>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleFormSubmit}
            >
              {({ values, errors, handleSubmit, setFieldValue, isSubmitting, handleChange, touched }) => (
                <Form onSubmit={handleSubmit}>
                  <>
                    {/* <button onClick={() => console.log(state)}>get state</button> */}
                    <CardBody>
                      <div className="live-preview">
                        <Row>
                          <Col xxl={12}>
                            <Card className="card-inner">
                              <PreviewCardHeader title={isUpdate ? 'Template Updation' : 'Template Creation'} />
                              <CardBody>
                                {data?.channel === 'email' && (
                                  <Row>
                                    <>
                                      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <div className="mb-3">
                                          <FormikInput
                                            label="From"
                                            type="email"
                                            name="from"
                                            placeholder=""
                                            value={values.from}
                                            requiredField={false}
                                            onKeyDown={e => {
                                              if (e.key === 'Enter') {
                                                e.preventDefault();
                                              }
                                            }}
                                            onChange={e => {
                                              setInitialValues({ ...initialValues, from: e?.target?.value });
                                            }}
                                          />
                                        </div>
                                      </Col>
                                      <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <div className="mb-3">
                                          <Label htmlFor="language" className="form-label">
                                            CC
                                          </Label>
                                          <CreatableInput value={mailcc} setValue={setMailcc} valid="email" />
                                        </div>
                                      </Col>
                                      <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <div className="mb-3">
                                          <Label htmlFor="language" className="form-label">
                                            BCC
                                          </Label>
                                          <CreatableInput value={mailbcc} setValue={setMailbcc} valid="email" />
                                        </div>
                                      </Col>

                                      <Col md={12}>
                                        <Label htmlFor="subject" className="form-label">
                                          Subject
                                          <span className="lbl_mandatory"> *</span>
                                        </Label>
                                        <div
                                          className="mentions-container"
                                          style={{
                                            border: '1px solid #ccc',
                                            boxSizing: 'border-box',
                                            border: 'none !important',
                                          }}
                                        >
                                          <MentionsInput
                                            id="mentionsInputForSubject"
                                            style={{
                                              fontSize: 13,
                                              suggestions: {
                                                list: {
                                                  backgroundColor: 'white',
                                                  border: '1px solid #333',
                                                  fontSize: 14,
                                                  maxHeight: '200px',
                                                  overflowY: 'auto',
                                                  zIndex: 99,
                                                },
                                                item: { padding: '10px 20px', borderBottom: '1px solid #333' },
                                              },
                                            }}
                                            placeholder=""
                                            value={initialValues.subject}
                                            onChange={handleInputChangeForSubject}
                                          >
                                            <Mention
                                              style={{
                                                backgroundColor: '#ccc',
                                                position: 'unset',
                                                zIndex: 999,
                                              }}
                                              className="hello"
                                              data={list}
                                              trigger="@"
                                              appendSpaceOnAdd={true}
                                              onAdd={(id, display) => handleSuggestionClickForSubject(id, display)}
                                            />
                                          </MentionsInput>
                                          <ErrorMessage name="subject" component="div" className="text-danger" />
                                        </div>
                                      </Col>
                                    </>
                                  </Row>
                                )}
                                <Row>
                                  <Col xxl={12} className="mt-3">
                                    <Label htmlFor="language" className="form-label">
                                      Body
                                      <span className="lbl_mandatory"> *</span>
                                    </Label>
                                    <div style={{ display: 'flex', flexDirection: 'row', height: '70vh' }}>
                                      <div
                                        className="mentions-container mentions-container-body"
                                        style={{
                                          flex: 1,
                                          border: '1px solid #ccc',
                                          padding: '10px',
                                          boxSizing: 'border-box',
                                          overflowY: 'auto',
                                        }}
                                      >
                                        <MentionsInput
                                          id="mentionsInputForBody"
                                          style={{
                                            fontSize: 14,
                                            suggestions: {
                                              list: {
                                                backgroundColor: 'white',
                                                border: '1px solid #333',
                                                fontSize: 14,
                                                maxHeight: '200px',
                                                overflowY: 'auto',
                                              },
                                              item: { padding: '10px 20px', borderBottom: '1px solid #333' },
                                            },
                                          }}
                                          placeholder=""
                                          value={initialValues.body}
                                          onChange={handleInputChangeForBody}
                                        >
                                          <Mention
                                            style={{
                                              backgroundColor: '#ccc',
                                            }}
                                            data={list}
                                            trigger="@"
                                            appendSpaceOnAdd={true}
                                            onAdd={(id, display) => handleSuggestionClickForBody(id, display)}
                                          />
                                        </MentionsInput>
                                      </div>
                                      <div
                                        style={{
                                          flex: 1,
                                          height: '100%',
                                          border: '1px solid #ccc',
                                          padding: '10px',
                                          boxSizing: 'border-box',
                                          overflow: 'auto',
                                          backgroundColor: '#f9f9f9',
                                        }}
                                      >
                                        <iframe
                                          title="HTML Preview"
                                          srcDoc={initialValues.body}
                                          style={{
                                            width: '100%',
                                            height: '100%',
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <ErrorMessage name="body" component="div" className="text-danger" />
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <Row>
                        <Col md={12} className="d-flex" style={{ justifyContent: 'center', gap: '10px' }}>
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-primary text-center"
                              onClick={() => {
                                setShowTemplatePage(false);
                                setIsUpdateFormShow(true);
                              }}
                            >
                              Back
                            </button>
                          </div>
                          <div className="text-center">
                            <button type="submit" className="btn btn-primary text-center">
                              {isUpdate ? 'Update' : 'Submit'}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </CardFooter>
                  </>
                </Form>
              )}
            </Formik>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default TemplatePage;
