import { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

const CancelModal = ({ data, setIsShowCancelModal, submitHandler }) => {
  const cancellationResons = ['Others'];

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    remarks: '',
  };
  const [state, setState] = useState(initialObj);

  const [modalData, setModalData] = useState(null);

  const [isOthers, setIsOthers] = useState(false);
  const [othersValue, setOthersValue] = useState('');
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  useEffect(() => {
    if (data !== null) {
      setModalData(data);
    }
  }, [data]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;

    let updatedState = null;

    switch (name) {
      case 'cancellation remarks':
        updatedState = {
          ...state,
          remarks: value,
        };
        setState(updatedState);
        validationCheck(updatedState);
        if (value === 'Others') {
          setIsOthers(true);
        } else {
          setIsOthers(false);
          setOthersValue('');
        }
        break;

      case 'others_value':
        validationCheck('others_value');
        setOthersValue(e.target.value);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const cancelSubmitHandler = () => {
    let remarks = '';
    if (state?.remarks === 'Others') {
      remarks = othersValue;
    } else {
      remarks = state?.remarks;
    }

    submitHandler(remarks);
  };

  /* ---------------------------- VALIDATION HANDLER ---------------------------- */
  const validationCheck = data => {
    if (data?.remarks === 'Others') {
      setIsSubmitBtnDisabled(true);
      if (othersValue !== '') {
        setIsSubmitBtnDisabled(false);
      } else {
        setIsSubmitBtnDisabled(true);
      }
    } else if (data?.remarks === '') {
      setIsSubmitBtnDisabled(true);
    } else {
      setIsSubmitBtnDisabled(false);
    }
  };

  return (
    <Modal
      isOpen={true}
      toggle={() => {
        setIsShowCancelModal(false);
      }}
    >
      <ModalHeader
        toggle={() => {
          setIsShowCancelModal(false);
        }}
      >
        Cancel Remarks
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="mb-3">
            <select className="form-select" name="cancellation remarks" value={state.remarks} onChange={inputChangeHandler}>
              <option value="">Please select a reason for cancellation</option>
              {cancellationResons?.map((cancellationReason, index) => (
                <option value={cancellationReason} key={index}>
                  {cancellationReason}
                </option>
              ))}
            </select>
          </div>

          {isOthers && (
            <div className="mb-3">
              <textarea name="others_value" className="form-control" row="3" value={othersValue} onChange={inputChangeHandler}></textarea>
            </div>
          )}
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="danger" onClick={cancelSubmitHandler} disabled={isSubmitBtnDisabled}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default CancelModal;
