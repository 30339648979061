import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BookingReconDetails from './Components/ProgramPricing/BookingReconDetails';
import RecordData from './Components/ProgramPricing/RecordData';

import ClientPricesUpload from './Components/PriceUploads/Tracker';
import RecordDataPriceUpload from './Components/PriceUploads/RecordData';
import { DELETE_TRACKER_RECORD_EMPTY, GET_RECORD_DATA_EMPTY, GET_TRACKER_DATA_EMPTY } from '../../../../../store/actions';

const initialFilters = {
  reconType: {
    value: ``,
    label: `All`,
  },
  benfitDomain: '',
  clientOrVendorName: '',
  status: {
    value: ``,
    label: `All`,
  },
};

const initialFiltersForPriceUploads = {
  status: '',
  startDate: '',
  endDate: '',
  uploadBy: '',
  facilityName: '',
};

const BookingRecon = () => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [isShowPriceUploads, setIsShowPriceUploads] = useState(false);
  const [currentClientPrice, setCurrentClientPrice] = useState(null);

  const [isClientPriceShow, setIsClientPriceShow] = useState(true);
  const [isRecordDataShow, setIsRecordDataShow] = useState(false);

  const [filters, setFilters] = useState(initialFilters);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  /* ---------------------------- REDUX STATES ---------------------------- */
  //  DELETE RECORD
  const { deleteRecord } = useSelector(state => ({
    deleteRecord: state?.DeleteTrackerRecord?.data,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_TRACKER_DATA_EMPTY,
    });
    setCurrentClientPrice(null);
    setIsClientPriceShow(true);
    setIsRecordDataShow(false);
  }, []);

  useEffect(() => {
    if (deleteRecord !== null) {
      setCurrentClientPrice(null);
      dispatch({
        type: DELETE_TRACKER_RECORD_EMPTY,
      });

      dispatch({
        type: GET_RECORD_DATA_EMPTY,
      });

      setIsClientPriceShow(true);
      setIsRecordDataShow(false);
    }
  }, [deleteRecord]);

  /* ---------------------------- VIEW RECORD DATA HANDLER ---------------------------- */
  const clientPriceSelectHandler = clientPriceHandler => {
    dispatch({
      type: GET_TRACKER_DATA_EMPTY,
    });
    setCurrentClientPrice(clientPriceHandler);
    setIsClientPriceShow(false);
    setIsRecordDataShow(true);
  };

  /* ---------------------------- VIEW CLIENT PRICE DATA HANDLER ---------------------------- */
  const toggleToClientPriceHandler = () => {
    dispatch({
      type: GET_TRACKER_DATA_EMPTY,
    });
    setCurrentClientPrice(null);
    setIsClientPriceShow(true);
    setIsRecordDataShow(false);
  };

  /* ---------------------------- HEADER TOGGLER ---------------------------- */
  const headerToggler = flag => {
    setCurrentPageNumber(0);
    if (flag === `PROGRAM_PRICING`) {
      dispatch({
        type: GET_TRACKER_DATA_EMPTY,
      });
      setIsShowPriceUploads(false);
      setFilters(initialFilters);
    } else if (flag === `PRICING_UPLOADS`) {
      setIsShowPriceUploads(true);
      setFilters(initialFiltersForPriceUploads);
    }
  };

  return (
    <div className="page-content">
      {!isShowPriceUploads && (
        <>
          {isClientPriceShow && (
            <BookingReconDetails
              onView={clientPriceSelectHandler}
              currentPageNumber={currentPageNumber}
              setCurrentPageNumber={setCurrentPageNumber}
              filters={filters}
              setFilters={setFilters}
              headerToggler={headerToggler}
            />
          )}
          {isRecordDataShow && (
            <RecordData selectedClientPrice={currentClientPrice} onViewTrackerDetails={toggleToClientPriceHandler} headerToggler={headerToggler} />
          )}
        </>
      )}

      {isShowPriceUploads && (
        <>
          {isClientPriceShow && (
            <ClientPricesUpload
              onView={clientPriceSelectHandler}
              currentPageNumber={currentPageNumber}
              setCurrentPageNumber={setCurrentPageNumber}
              filters={filters}
              setFilters={setFilters}
              headerToggler={headerToggler}
            />
          )}
          {isRecordDataShow && <RecordDataPriceUpload selectedTracker={currentClientPrice} onViewTrackerDetails={toggleToClientPriceHandler} />}
        </>
      )}
    </div>
  );
};

export default BookingRecon;
