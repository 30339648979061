import React from 'react';

import { Col, Row } from 'reactstrap';

const BreadCrumb = props => {
  return (
    <React.Fragment>
      <Row className="mb-5">
        <Col xs={12}>
          <div className="custom_breadcrumb_style" style={{ position: 'fixed', width: '100%', zIndex: 999 }}>
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 d-none">{props.title}</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  {props?.navigation?.map((text, index) => {
                    return (
                      <li key={index} className="breadcrumb-item">
                        <span
                          className={`${props?.navigation?.length !== index + 1 && 'text-primary cursor-pointer text-decoration-underline'}`}
                          onClick={() => props.navigationby(`${text}`)}
                        >
                          {text}
                        </span>
                      </li>
                    );
                  })}
                </ol>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BreadCrumb;
