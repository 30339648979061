import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, indianProjectsBaseURL, uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { VENDOR_OTP_VALIDATE, VENDOR_OTP_VALIDATE_SUCCESS, VENDOR_OTP_VALIDATE_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* vendorOtpValidate({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/user/v1/validate-otp`,
      method: 'POST',
      headers: {
        Authorization: payload?.headers?.token,
        // ...customHeaders,
        'Project-Code': localStorage.getItem('bankCode') ?? 'ADMIN_ACCESS',
      },
      data: payload?.data,
    });

    if (response?.status === 200) {
      cogoToast.success(`User validated successfully!`);
      yield put({
        type: VENDOR_OTP_VALIDATE_SUCCESS,
        payload: response,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: VENDOR_OTP_VALIDATE_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: VENDOR_OTP_VALIDATE_ERROR, payload: error });
  }
}

export function* watchVendorOtpValidate() {
  yield takeEvery(VENDOR_OTP_VALIDATE, vendorOtpValidate);
}

function* vendorOtpValidateSaga() {
  yield all([fork(watchVendorOtpValidate)]);
}

export default vendorOtpValidateSaga;
