import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from '../../../../Common';
import Table from './Components/Tables/Table';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import { GET_TEMPLATE_LIST, UPDATE_TEMPLATE, UPDATE_TEMPLATE_EMPTY } from '../../../../../store/application/actionType';
import { Constant } from '../../../../Helpers/constant';
import Details from './Components/Details/Details';
import Filter from './Components/Filters/Filter';
import CustomFilter from './Components/Filters/CustomFilter';
import LogsModal from './Components/Modals/LogsModal';
import AddTemplateEngineForm from '../AddTemplateEngine/Components/Forms/TemplateEngineForm';
import TemplateDetailsModal from './Components/Modals/TemplateDetailsModal';
import TemplatePage from '../AddTemplateEngine/Components/Forms/TemplatePage';

const ManageTemplateEngine = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [isShowFilters, setIsShowFilters] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);

  const [currentSelectedDetails, setCurrentSelectedDetails] = useState();
  const [currentConfirmationModalActionFor, setCurrentConfirmationModalActionFor] = useState(null);

  const [isListShow, setIsListShow] = useState(true);
  const [isDetailsPageShow, setIsDetailsPageShow] = useState(false);
  const [isUpdateFormShow, setIsUpdateFormShow] = useState(false);

  const [isShowLogsModal, setIsShowLogsModal] = useState(false);
  const [templateDetailsModal, setTemplateDetailsModal] = useState(false);

  const [data, setData] = useState(null);
  const [showTemplatePage, setShowTemplatePage] = useState(false);

  // Template List
  const { templates, templatesLoading } = useSelector(state => ({
    templates: state?.GetTemplates?.data,
    templatesLoading: state?.GetTemplates?.loading,
  }));
  // Update Template
  const { updateTemplate, updateTemplateLoading } = useSelector(state => ({
    updateTemplate: state?.UpdateTemplate?.data,
    updateTemplateLoading: state?.UpdateTemplate?.loading,
  }));

  const [modalData, setModalData] = useState({});
  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_TEMPLATE_LIST,
      payload: {
        urlParam: {
          offset: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          limit: Constant?.TABLE_PAGE_SIZE,
        },
      },
    });
  }, [dispatch]);

  const changeTemplateStatus = data => {
    delete data['edges'];
    dispatch({
      type: UPDATE_TEMPLATE,
      payload: {
        data: {
          ...data,
          is_active: !data?.is_active,
        },
        params: data?.id,
      },
    });
  };

  const templateSubmit = data => {
    setData(data);
    setShowTemplatePage(true);
    setIsUpdateFormShow(false);
  };

  const updateTemplatesData = values => {
    data.content = {
      ...data?.content,
      body: values?.body,
      subject: values?.subject,
      from: values?.from,
      cc: values?.cc?.map(cc => cc.value),
      bcc: values?.bcc?.map(bcc => bcc.value),
    };
    data.template_variables = {
      body_variables: values?.bodyVariables,
      subject_variables: values?.subjectVariables,
    };
    dispatch({
      type: UPDATE_TEMPLATE,
      payload: {
        data: {
          ...data,
        },
        params: currentSelectedDetails?.id,
      },
    });
  };
  useEffect(() => {
    if (updateTemplate !== null) {
      dispatch({
        type: UPDATE_TEMPLATE_EMPTY,
      });
      dispatch({
        type: GET_TEMPLATE_LIST,
        payload: {
          urlParam: {
            offset: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            limit: Constant?.TABLE_PAGE_SIZE,
            ...currentlyAppliedFilters,
          },
        },
      });
      setCurrentSelectedDetails(null);
      setData(null);
      setIsListShow(true);
      setIsDetailsPageShow(false);
      setIsUpdateFormShow(false);
      setShowTemplatePage(false);
    }
  }, [updateTemplate]);

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = filters => {
    setIsShowFilters(false);
    setCurrentlyAppliedFilters(filters);
    setCurrentPageNumber(0);
    const urlParams = {
      event_type: filters?.event_type === `` ? null : filters?.event_type,
      typeId: filters?.typeId === `` ? null : filters?.typeId?.data?.typeId,
      template_name: filters?.template_name === `` ? null : filters?.template_name,
      channel: Object.keys(filters?.channel).length < 0 ? null : filters?.channel?.value,
      priority_type: Object.keys(filters?.priority_type)?.length < 0 ? null : filters?.priority_type?.value,
    };
    dispatch({
      type: GET_TEMPLATE_LIST,
      payload: {
        urlParam: {
          offset: 0,
          limit: Constant?.TABLE_PAGE_SIZE,
          ...urlParams,
        },
      },
    });
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);

    let urlParams = {};

    if (currentlyAppliedFilters !== null) {
      urlParams = {
        event_type: currentlyAppliedFilters?.event_type === `` ? null : currentlyAppliedFilters?.event_type,
        typeId: currentlyAppliedFilters?.typeId === `` ? null : currentlyAppliedFilters?.typeId?.data?.typeId,
        template_name: currentlyAppliedFilters?.template_name === `` ? null : currentlyAppliedFilters?.template_name,
        channel: Object.keys(currentlyAppliedFilters?.channel).length < 0 ? null : currentlyAppliedFilters?.channel?.value,
        priority_type: Object.keys(currentlyAppliedFilters?.priority_type)?.length < 0 ? null : currentlyAppliedFilters?.priority_type?.value,
      };
    }

    dispatch({
      type: GET_TEMPLATE_LIST,
      payload: {
        urlParam: {
          offset: pageNumber * Constant.TABLE_PAGE_SIZE,
          limit: Constant?.TABLE_PAGE_SIZE,
          ...urlParams,
        },
        pagination: true,
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedDetails(params?.details);
    setCurrentConfirmationModalActionFor(params?.actionType);
    if (params?.actionType === `INFO`) {
      setIsListShow(false);
      setIsDetailsPageShow(true);
      setIsUpdateFormShow(false);
    } else if (params?.actionType === `UPDATE`) {
      setIsListShow(false);
      setIsDetailsPageShow(false);
      setIsUpdateFormShow(true);
    }
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push('/dashboard');
    } else if (title === `Template List`) {
      // do nothing
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (templatesLoading || updateTemplate) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [templatesLoading, updateTemplate]);

  const showModal = data => {
    setModalData(data);
    setTemplateDetailsModal(true);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ----------------------------  MODAL'S ---------------------------- */}
          {isShowLogsModal && <LogsModal data={currentSelectedDetails} onCloseHandler={() => setIsShowLogsModal(false)} />}

          {/*  ----------------------------  UI'S ---------------------------- */}
          {isListShow && (
            <>
              <BreadCrumb title="Category" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Template List']} />

              <CustomFilter
                filterHandler={filterSubmitHandler}
                appliedFilters={currentlyAppliedFilters}
                show={isShowFilters}
                onCloseClick={() => setIsShowFilters(false)}
              />

              <Table
                title={`Template's Data`}
                data={templates?.results}
                totalData={templates?.count}
                pageHandler={paginationHandler}
                pageNumber={currentPageNumber}
                actionHandler={tableActionHandler}
                isShowFilterBtn={true}
                filterBtnHandler={() => {
                  setIsShowFilters(true);
                }}
                changeTemplateStatus={changeTemplateStatus}
                showModal={showModal}
              />
            </>
          )}

          {isDetailsPageShow && (
            <>
              <BreadCrumb title="Category" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Category', 'Details']} />
              <Details details={currentSelectedDetails} />
            </>
          )}

          {isUpdateFormShow && (
            <>
              <AddTemplateEngineForm
                data={currentSelectedDetails}
                title="Update Template"
                onSubmit={templateSubmit}
                setIsListShow={setIsListShow}
                setIsUpdateFormShow={setIsUpdateFormShow}
                previousForm={data}
              />
            </>
          )}

          {showTemplatePage && (
            <>
              <TemplatePage
                data={data}
                onSubmit={updateTemplatesData}
                isUpdate={true}
                setShowTemplatePage={setShowTemplatePage}
                setIsUpdateFormShow={setIsUpdateFormShow}
                setIsListShow={setIsListShow}
                setData={setData}
              />
            </>
          )}

          {templateDetailsModal && (
            <TemplateDetailsModal templateDetailsModal={templateDetailsModal} setTemplateDetailsMOdal={setTemplateDetailsModal} data={modalData} />
          )}
        </Container>
      </div>
    </>
  );
};

export default ManageTemplateEngine;
