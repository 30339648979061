import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

const PriceSettings = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [state, setState] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [modal_varying1, setmodal_varying1] = useState(true);

  useEffect(() => {
    setState(props?.data?.data);
    setCurrentIndex(props?.data?.currentIndex);
  }, [props]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'defaultSellingPrice':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              prices: {
                ...state?.benefitGroupItem?.completeData?.prices,
                defaultSellingPrice: value,
              },
            },
          },
        };
        break;

      case 'customSellingPrice':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              prices: {
                ...state?.benefitGroupItem?.completeData?.prices,
                customSellingPrice: value,
              },
            },
          },
        };
        break;

      case 'pointsWorth':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              prices: {
                ...state?.benefitGroupItem?.completeData?.prices,
                pointsWorth: value,
              },
            },
          },
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validationCheck(updatedState);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    props.onSubmit({
      currentIndex: currentIndex,
      data: {
        // ...props.data,
        ...state,
      },
    });

    props.onSubmit({
      currentIndex: currentIndex,
      data: {
        ...props?.data?.data,
        benefitGroupItem: {
          ...state?.benefitGroupItem,
          completeData: {
            ...state?.benefitGroupItem?.completeData,
            prices: {
              ...state?.benefitGroupItem?.completeData?.prices,
              pointsWorth: parseInt(state?.benefitGroupItem?.completeData?.prices?.pointsWorth ?? 0),
              defaultSellingPrice: parseInt(state?.benefitGroupItem?.completeData?.prices?.defaultSellingPrice ?? 0),
              customSellingPrice: parseInt(state?.benefitGroupItem?.completeData?.prices?.customSellingPrice ?? 0),
            },
          },
        },
      },
    });

    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  /* ---------------------------- VALIDATION HANDLER ---------------------------- */
  const validationCheck = data => {};

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="price-settings"
      // centered
      size="md"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Price Settings
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log(state)}>get state</button> */}
        <Row>
          <Col md={12}>
            <div className="mb-3">
              <Label htmlFor="defaultSellingPrice" className="form-label">
                Default Selling Price
              </Label>
              <Input
                id="defaultSellingPrice"
                name="defaultSellingPrice"
                type="number"
                onWheel={e => e.target.blur()}
                className="form-control"
                value={state?.benefitGroupItem?.completeData?.prices?.defaultSellingPrice}
                onChange={inputChangeHandler}
              />
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <Label htmlFor="customSellingPrice" className="form-label">
                Custom Selling Price
              </Label>
              <Input
                id="customSellingPrice"
                name="customSellingPrice"
                type="number"
                onWheel={e => e.target.blur()}
                className="form-control"
                value={state?.benefitGroupItem?.completeData?.prices?.customSellingPrice}
                onChange={inputChangeHandler}
              />
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <Label htmlFor="pointsWorth" className="form-label">
                Point Worth
              </Label>
              <Input
                id="pointsWorth"
                name="pointsWorth"
                type="number"
                onWheel={e => e.target.blur()}
                className="form-control"
                value={state?.benefitGroupItem?.completeData?.prices?.pointsWorth}
                onChange={inputChangeHandler}
              />
            </div>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter className="d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PriceSettings;
