import React, { useState, useEffect } from 'react';

import classnames from 'classnames';
import Select from 'react-select';
import {
  Accordion,
  AccordionItem,
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { AsyncPaginate } from 'react-select-async-paginate';
import { baseUrl, customHeaders } from '../../../../../../../Config';
import { Constant } from '../../../../../../../Helpers/constant';
import { useDispatch, useSelector } from 'react-redux';

const BookingSettings = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [state, setState] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [modal_varying1, setmodal_varying1] = useState(true);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Countries
  const { countriesData } = useSelector(state => ({
    countriesData: state?.Countries?.data,
  }));

  useEffect(() => {
    let tempGuestSettingsType = 0;
    if ([null, 0].includes(props?.data?.data?.benefitGroupItem?.completeData?.guestSettings?.type)) {
      tempGuestSettingsType = null;
    } else if (props?.data?.data?.benefitGroupItem?.completeData?.guestSettings?.type === 1) {
      tempGuestSettingsType = { value: '1', label: 'Registered' };
    } else if (props?.data?.data?.benefitGroupItem?.completeData?.guestSettings?.type === 2) {
      tempGuestSettingsType = { value: '2', label: 'Not Registered' };
    }

    let customizedData = {
      ...props?.data?.data,
      benefitGroupItem: {
        ...props?.data?.data?.benefitGroupItem,
        completeData: {
          ...props?.data?.data?.benefitGroupItem?.completeData,
          guestSettings: {
            ...props?.data?.data?.benefitGroupItem?.completeData?.guestSettings,
            type: tempGuestSettingsType,
          },
          preAuth: {
            ...props?.data?.data?.benefitGroupItem?.completeData?.preAuth,
            amount: `${props?.data?.data?.benefitGroupItem?.completeData?.preAuth?.amount}`,
          },
          airports: props?.data?.data?.benefitGroupItem?.completeData?.airports?.map(airport => ({
            value: airport.id,
            label: `${airport.name}`,
            completeData: airport,
          })),
        },
      },
    };
    setState(customizedData);
    setCurrentIndex(props?.data?.currentIndex);
  }, [props]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'priority':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              priority: parseInt(value),
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'processingFees':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              processingFees: parseInt(value),
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'allowed':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              guestSettings: {
                ...state?.benefitGroupItem?.completeData?.guestSettings,
                allowed: e.target.checked,
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'allowedNumber':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              guestSettings: {
                ...state?.benefitGroupItem?.completeData?.guestSettings,
                allowedNumber: parseInt(value),
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'carType':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              carTypes: [
                {
                  ...state?.benefitGroupItem?.completeData?.carTypes[0],
                  carType: value,
                },
              ],
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      // ----------- Booking TAT -----------
      case 'bookingMinimumTatUnit':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              bookingTat: {
                ...state?.benefitGroupItem?.completeData?.bookingTat,
                minimumUnit: parseInt(value),
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'bookingMinimumTat':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              bookingTat: {
                ...state?.benefitGroupItem?.completeData?.bookingTat,
                minimum: parseInt(value),
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'bookingMaximumTatUnit':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              bookingTat: {
                ...state?.benefitGroupItem?.completeData?.bookingTat,
                maximumUnit: parseInt(value),
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'bookingMaximumTat':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              bookingTat: {
                ...state?.benefitGroupItem?.completeData?.bookingTat,
                maximum: parseInt(value),
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'confirmMinimumTatUnit':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              tat: {
                ...state?.benefitGroupItem?.completeData?.tat,
                confirmationMinTatUnit: parseInt(value),
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'confirmMinimumTat':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              tat: {
                ...state?.benefitGroupItem?.completeData?.tat,
                confirmationMinTat: parseInt(value),
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'confirmMaximumTatUnit':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              tat: {
                ...state?.benefitGroupItem?.completeData?.tat,
                confirmationMaxTatUnit: parseInt(value),
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'confirmMaximumTat':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              tat: {
                ...state?.benefitGroupItem?.completeData?.tat,
                confirmationMaxTat: parseInt(value),
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'delayedCancelledTatUnit':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              tat: {
                ...state?.benefitGroupItem?.completeData?.tat,
                delayedCancelTatUnit: parseInt(value),
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'delayedCancelledTat':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              tat: {
                ...state?.benefitGroupItem?.completeData?.tat,
                delayedCancelTat: parseInt(value),
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'delayedMinimumTatUnit':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              tat: {
                ...state?.benefitGroupItem?.completeData?.tat,
                delayedMinTatUnit: parseInt(value),
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'delayedMinimumTat':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              tat: {
                ...state?.benefitGroupItem?.completeData?.tat,
                delayedMinTat: parseInt(value),
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'delayedMaximumTatUnit':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              tat: {
                ...state?.benefitGroupItem?.completeData?.tat,
                delayedMaxTatUnit: parseInt(value),
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'delayedMaximumTat':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              tat: {
                ...state?.benefitGroupItem?.completeData?.tat,
                delayedMaxTat: parseInt(value),
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'projectLevelQuota':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              projectLevelQuota: {
                enabled: e.target.checked,
                quotaId: '',
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'quotaId':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              projectLevelQuota: {
                ...state?.benefitGroupItem?.completeData?.projectLevelQuota,
                quotaId: value,
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'preAuth-enabled':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              preAuth: {
                ...state?.benefitGroupItem?.completeData?.preAuth,
                enabled: e.target.checked,
                amount: e.target.checked ? state?.benefitGroupItem?.completeData?.preAuth?.amount : 0,
                currency: e.target.checked ? state?.benefitGroupItem?.completeData?.preAuth?.currency : '',
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'preAuth-amount':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              preAuth: {
                ...state?.benefitGroupItem?.completeData?.preAuth,
                amount: value,
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'preAuth-currency':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              preAuth: {
                ...state?.benefitGroupItem?.completeData?.preAuth,
                currency: value,
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'tatCheckOnDateOfPlay':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              tatCheckOnDateOfPlay: e?.target?.checked,
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'bookingTatOnDateOfPlay-maximumUnit':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              bookingTatOnDateOfPlay: {
                ...state?.benefitGroupItem?.completeData?.bookingTatOnDateOfPlay,
                maximumUnit: parseInt(value),
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'bookingTatOnDateOfPlay-maximum':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              bookingTatOnDateOfPlay: {
                ...state?.benefitGroupItem?.completeData?.bookingTatOnDateOfPlay,
                maximum: parseInt(value),
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'bookingTatOnDateOfPlay-minimumUnit':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              bookingTatOnDateOfPlay: {
                ...state?.benefitGroupItem?.completeData?.bookingTatOnDateOfPlay,
                minimumUnit: parseInt(value),
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'bookingTatOnDateOfPlay-minimum':
        updatedState = {
          ...state,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              bookingTatOnDateOfPlay: {
                ...state?.benefitGroupItem?.completeData?.bookingTatOnDateOfPlay,
                minimum: parseInt(value),
              },
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  const inputSelectHandler = (label, data) => {
    let updatedState = null;

    if (label === `type`) {
      updatedState = {
        ...state,
        benefitGroupItem: {
          ...state?.benefitGroupItem,
          completeData: {
            ...state?.benefitGroupItem?.completeData,
            guestSettings: {
              ...state?.benefitGroupItem?.completeData?.guestSettings,
              type: data,
            },
          },
        },
      };
      setState(updatedState);
      validationCheck(updatedState);
    }
  };

  /* ---------------------------- ADD TYPE HANDLER ---------------------------- */
  const carTypeInputHandler = (e, index) => {
    const { name, value } = e.target;
    let updatedState = null;

    let currentlyAllCarTypes = state?.benefitGroupItem?.completeData?.carTypes;
    let currentSelectedCarType = state?.benefitGroupItem?.completeData?.carTypes[index];

    switch (name) {
      case 'carType':
        currentSelectedCarType = {
          ...currentSelectedCarType,
          carType: value,
        };
        break;

      case 'passengers':
        currentSelectedCarType = {
          ...currentSelectedCarType,
          passengers: parseInt(value),
        };
        break;

      case 'babySeats':
        currentSelectedCarType = {
          ...currentSelectedCarType,
          babySeats: parseInt(value),
        };
        break;

      case 'luggages':
        currentSelectedCarType = {
          ...currentSelectedCarType,
          luggages: parseInt(value),
        };
        break;

      case 'image':
        currentSelectedCarType = {
          ...currentSelectedCarType,
          image: value,
        };
        break;

      case 'imageUrl':
        currentSelectedCarType = {
          ...currentSelectedCarType,
          imageUrl: value,
        };
        break;

      case 'minMax':
        currentSelectedCarType = {
          ...currentSelectedCarType,
          minMax: value,
        };
        break;

      default:
      // do nothing
    }

    currentlyAllCarTypes.splice(index, 1, currentSelectedCarType);

    updatedState = {
      ...state,
      benefitGroupItem: {
        ...state?.benefitGroupItem,
        completeData: {
          ...state?.benefitGroupItem?.completeData,
          carTypes: [...currentlyAllCarTypes],
        },
      },
    };

    setState(updatedState);
    validationCheck(updatedState);
  };

  /* ---------------------------- ADD CAR TYPES HANDLER ---------------------------- */
  const addCarTypesHandler = () => {
    let updatedState = {
      ...state,
      benefitGroupItem: {
        ...state?.benefitGroupItem,
        completeData: {
          ...state?.benefitGroupItem?.completeData,
          carTypes: [
            ...state.benefitGroupItem.completeData.carTypes,
            {
              carType: '',
              passengers: 0,
              babySeats: 0,
              luggages: 0,
              minMax: '',
              image: '',
              imageUrl: '',
              type: '',
            },
          ],
        },
      },
    };
    setState(updatedState);
  };

  /* ---------------------------- REMOVE CAR TYPES HANDLER ---------------------------- */
  const removeCarTypesHandler = index => {
    let tempCarTypes = state.benefitGroupItem.completeData.carTypes;

    tempCarTypes.splice(index, 1);

    let updatedState = {
      ...state,
      benefitGroupItem: {
        ...state?.benefitGroupItem,
        completeData: {
          ...state?.benefitGroupItem?.completeData,
          carTypes: [...tempCarTypes],
        },
      },
    };
    setState(updatedState);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    let tempGuestSettingsType = 0;
    if (state.benefitGroupItem.completeData?.guestSettings?.type === null) {
      tempGuestSettingsType = 0;
    } else if (state.benefitGroupItem.completeData?.guestSettings?.type?.value === 1) {
      tempGuestSettingsType = 1;
    } else if (state.benefitGroupItem.completeData?.guestSettings?.type?.value === 2) {
      tempGuestSettingsType = 2;
    }

    props.onSubmit({
      currentIndex: currentIndex,
      data: {
        ...props?.data?.data,
        benefitGroupItem: {
          ...state?.benefitGroupItem,
          completeData: {
            ...state.benefitGroupItem.completeData,
            guestSettings: {
              ...state.benefitGroupItem.completeData?.guestSettings,
              type: tempGuestSettingsType,
            },
            airports: state.benefitGroupItem.completeData?.airports?.map(airport => airport?.value),
            preAuth: {
              ...state.benefitGroupItem.completeData?.preAuth,
              amount: parseFloat(state.benefitGroupItem.completeData?.preAuth?.amount),
            },
          },
        },
      },
    });

    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  /* ---------------------------- TYPED AIRPORT HANDLER ---------------------------- */
  async function getAirportsHandler(search) {
    const response = await fetch(`${baseUrl}/admin/airports?name=${search}`, {
      method: `GET`,
      headers: new Headers({
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      }),
    });
    const airportsResponse = await response.json();

    return {
      options: airportsResponse?.results?.map((data, index) => {
        return {
          value: data.objectId,
          label: `${data.name}`,
          completeData: data,
        };
      }),
      hasMore: false,
    };
  }

  /* ---------------------------- ON SELECT USER ---------------------------- */
  const onSelectAirports = event => {
    setState({
      ...state,
      benefitGroupItem: {
        ...state?.benefitGroupItem,
        completeData: {
          ...state?.benefitGroupItem?.completeData,
          airports: event,
        },
      },
    });
  };

  /* ---------------------------- VALIDATION HANDLER ---------------------------- */
  const validationCheck = data => {};

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);
  const [col4, setcol4] = useState(false);
  const [col5, setcol5] = useState(false);

  const t_col1 = () => {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
    setcol4(false);
    setcol5(false);
  };

  const t_col2 = () => {
    setcol2(!col2);
    setcol1(false);
    setcol3(false);
    setcol4(false);
    setcol5(false);
  };

  const t_col3 = () => {
    setcol3(!col3);
    setcol1(false);
    setcol2(false);
    setcol4(false);
    setcol5(false);
  };

  const t_col4 = () => {
    setcol4(!col4);
    setcol1(false);
    setcol2(false);
    setcol3(false);
    setcol5(false);
  };

  const t_col5 = () => {
    setcol4(false);
    setcol1(false);
    setcol2(false);
    setcol3(false);
    setcol5(!col5);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
      // centered
      size="lg"
    >
      <ModalHeader
        toggle={() => {
          tog_varying1();
        }}
      >
        Booking Settings
      </ModalHeader>

      <ModalBody>
        {/* <button onClick={() => console.log(state)}>get state</button> */}

        <Row>
          <Col md={12}>
            <Card className="shadow-none">
              <CardBody>
                <div className="live-preview">
                  <Accordion id="default-accordion-example">
                    <AccordionItem>
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className={classnames('accordion-button', {
                            collapsed: !col1,
                          })}
                          type="button"
                          onClick={t_col1}
                          style={{ cursor: 'pointer' }}
                        >
                          Generals
                        </button>
                      </h2>
                      <Collapse isOpen={col1} className="accordion-collapse" id="collapseOne">
                        <div className="accordion-body">
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="airport" className="form-label">
                                  Airports
                                </Label>

                                <AsyncPaginate
                                  debounceTimeout={500}
                                  value={state?.benefitGroupItem?.completeData?.airports}
                                  loadOptions={getAirportsHandler}
                                  onChange={e => onSelectAirports(e)}
                                  isMulti={true}
                                  closeMenuOnSelect={true}
                                  noOptionsMessage={() => 'No results found'}
                                  cacheUniqs={[['code']]}
                                  placeholder="Enter Name..."
                                />
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="priority" className="form-label">
                                  Priority
                                </Label>
                                <Input
                                  id="priority"
                                  name="priority"
                                  type="number"
                                  onWheel={e => e.target.blur()}
                                  className="form-control"
                                  value={state?.benefitGroupItem?.completeData?.priority}
                                  min="0"
                                  onChange={inputChangeHandler}
                                />
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="processingFees" className="form-label">
                                  Processing fees
                                </Label>
                                <Input
                                  id="processingFees"
                                  name="processingFees"
                                  type="number"
                                  onWheel={e => e.target.blur()}
                                  className="form-control"
                                  value={state?.benefitGroupItem?.completeData?.processingFees}
                                  min="0"
                                  onChange={inputChangeHandler}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={12}>
                              <div>
                                <div className="form-switch d-inline-block ">
                                  <Input
                                    className="form-check-input code-switcher"
                                    type="checkbox"
                                    id="allowed"
                                    name="allowed"
                                    checked={state?.benefitGroupItem?.completeData?.guestSettings?.allowed}
                                    onChange={inputChangeHandler}
                                  />
                                </div>
                                <Label htmlFor="allowed" className="form-label">
                                  is Active
                                </Label>
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="allowedNumber" className="form-label">
                                  No. of guests
                                </Label>
                                <Input
                                  id="allowedNumber"
                                  name="allowedNumber"
                                  type="number"
                                  onWheel={e => e.target.blur()}
                                  className="form-control"
                                  value={state?.benefitGroupItem?.completeData?.guestSettings?.allowedNumber}
                                  min="0"
                                  onChange={inputChangeHandler}
                                  disabled={!state?.benefitGroupItem?.completeData?.guestSettings?.allowed}
                                />
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="type" className="form-label">
                                  Type of Guest
                                </Label>
                                <Select
                                  id="type"
                                  name="type"
                                  options={[
                                    { value: 1, label: 'Registered' },
                                    { value: 2, label: 'Not Registered' },
                                  ]}
                                  value={state?.benefitGroupItem?.completeData?.guestSettings?.type}
                                  onChange={data => inputSelectHandler(`type`, data)}
                                  isDisabled={!state?.benefitGroupItem?.completeData?.guestSettings?.allowed}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={12}>
                              <div>
                                <div className="form-switch d-inline-block ">
                                  <Input
                                    className="form-check-input code-switcher"
                                    type="checkbox"
                                    id="preAuth-enabled"
                                    name="preAuth-enabled"
                                    checked={state?.benefitGroupItem?.completeData?.preAuth?.enabled}
                                    onChange={inputChangeHandler}
                                  />
                                </div>
                                <Label htmlFor="preAuth-enabled" className="form-label">
                                  is Pre Auth
                                </Label>
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="preAuth-amount" className="form-label">
                                  Pre Auth Amount
                                </Label>
                                <Input
                                  id="preAuth-amount"
                                  name="preAuth-amount"
                                  type="number"
                                  onWheel={e => e.target.blur()}
                                  className="form-control"
                                  value={state?.benefitGroupItem?.completeData?.preAuth?.amount}
                                  min="0"
                                  onChange={inputChangeHandler}
                                  disabled={!state?.benefitGroupItem?.completeData?.preAuth?.enabled}
                                />
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="preAuth-currency" className="form-label">
                                  Pre Auth Currency
                                </Label>
                                <select
                                  id="preAuth-currency"
                                  name="preAuth-currency"
                                  className="form-control"
                                  value={state?.benefitGroupItem?.completeData?.preAuth?.currency}
                                  onChange={inputChangeHandler}
                                  disabled={!state?.benefitGroupItem?.completeData?.preAuth?.enabled}
                                >
                                  <option value="" selected disabled>
                                    Choose...
                                  </option>
                                  {countriesData?.map((country, index) => (
                                    <option key={index}>{country?.currency}</option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Collapse>
                    </AccordionItem>

                    <AccordionItem>
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className={classnames('accordion-button', {
                            collapsed: !col2,
                          })}
                          type="button"
                          onClick={t_col2}
                          style={{ cursor: 'pointer' }}
                        >
                          Car Types
                        </button>
                      </h2>
                      <Collapse isOpen={col2} className="accordion-collapse">
                        <div className="accordion-body">
                          {state?.benefitGroupItem?.completeData?.carTypes?.map((carType, index) => (
                            <Row key={index}>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="carType" className="form-label">
                                    Car Type
                                  </Label>

                                  <Input
                                    id="carType"
                                    name="carType"
                                    type="text"
                                    className="form-control"
                                    value={state?.benefitGroupItem?.completeData?.carTypes?.[index]?.[`carType`]}
                                    onChange={e => carTypeInputHandler(e, index)}
                                    min="0"
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="passengers" className="form-label">
                                    Passengers
                                  </Label>
                                  <Input
                                    id="passengers"
                                    name="passengers"
                                    type="number"
                                    onWheel={e => e.target.blur()}
                                    className="form-control"
                                    value={state?.benefitGroupItem?.completeData?.carTypes?.[index]?.[`passengers`]}
                                    onChange={e => carTypeInputHandler(e, index)}
                                    min="0"
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="babySeats" className="form-label">
                                    Baby Seats
                                  </Label>
                                  <Input
                                    id="babySeats"
                                    name="babySeats"
                                    type="number"
                                    onWheel={e => e.target.blur()}
                                    className="form-control"
                                    value={state?.benefitGroupItem?.completeData?.carTypes?.[index]?.[`babySeats`]}
                                    onChange={e => carTypeInputHandler(e, index)}
                                    min="0"
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="luggages" className="form-label">
                                    Luggages
                                  </Label>
                                  <Input
                                    id="luggages"
                                    name="luggages"
                                    type="number"
                                    onWheel={e => e.target.blur()}
                                    className="form-control"
                                    value={state?.benefitGroupItem?.completeData?.carTypes?.[index]?.[`luggages`]}
                                    onChange={e => carTypeInputHandler(e, index)}
                                    min="0"
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="image" className="form-label">
                                    Image
                                  </Label>
                                  <Input
                                    id="image"
                                    name="image"
                                    type="text"
                                    className="form-control"
                                    value={state?.benefitGroupItem?.completeData?.carTypes?.[index]?.[`image`]}
                                    onChange={e => carTypeInputHandler(e, index)}
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="imageUrl" className="form-label">
                                    Image URL
                                  </Label>
                                  <Input
                                    id="imageUrl"
                                    name="imageUrl"
                                    type="text"
                                    className="form-control"
                                    value={state?.benefitGroupItem?.completeData?.carTypes?.[index]?.[`imageUrl`]}
                                    onChange={e => carTypeInputHandler(e, index)}
                                  />
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="minMax" className="form-label">
                                    MinMax
                                  </Label>
                                  <Input
                                    id="minMax"
                                    name="minMax"
                                    type="text"
                                    className="form-control"
                                    value={state?.benefitGroupItem?.completeData?.carTypes?.[index]?.[`minMax`]}
                                    onChange={e => carTypeInputHandler(e, index)}
                                    placeholder="Min-Max"
                                  />
                                </div>
                              </Col>
                              <Col md={12} className="d-flex justify-content-center mb-2">
                                <Button color="danger" onClick={() => removeCarTypesHandler(index)}>
                                  Remove
                                </Button>
                              </Col>
                              <hr />
                            </Row>
                          ))}
                          <Col md={12} className="d-flex justify-content-center mt-2">
                            <Button color="primary" onClick={addCarTypesHandler}>
                              Add
                            </Button>
                          </Col>
                        </div>
                      </Collapse>
                    </AccordionItem>

                    <AccordionItem>
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className={classnames('accordion-button', {
                            collapsed: !col3,
                          })}
                          type="button"
                          onClick={t_col3}
                          style={{ cursor: 'pointer' }}
                        >
                          Booking TAT
                        </button>
                      </h2>
                      <Collapse isOpen={col3} className="accordion-collapse">
                        <div className="accordion-body">
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="bookingMinimumTatUnit" className="form-label">
                                  Booking Minimum TAT Unit
                                </Label>

                                <select
                                  id="bookingMinimumTatUnit"
                                  name="bookingMinimumTatUnit"
                                  className="form-control"
                                  onChange={inputChangeHandler}
                                  value={state?.benefitGroupItem?.completeData?.bookingTat?.minimumUnit}
                                >
                                  <option value="0">Choose</option>
                                  <option value="4">Hours</option>
                                  <option value="5">Minute</option>
                                  <option value="2">Months</option>
                                  <option value="3">Days</option>
                                </select>
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="bookingMinimumTat" className="form-label">
                                  Booking Minimum TAT
                                </Label>

                                <Input
                                  id="bookingMinimumTat"
                                  name="bookingMinimumTat"
                                  type="number"
                                  onWheel={e => e.target.blur()}
                                  className="form-control"
                                  onChange={inputChangeHandler}
                                  value={state?.benefitGroupItem?.completeData?.bookingTat?.minimum}
                                  autoComplete="off"
                                  min="0"
                                />
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="bookingMaximumTatUnit" className="form-label">
                                  Booking Maximum TAT Unit
                                </Label>

                                <select
                                  id="bookingMaximumTatUnit"
                                  name="bookingMaximumTatUnit"
                                  className="form-control"
                                  onChange={inputChangeHandler}
                                  value={state?.benefitGroupItem?.completeData?.bookingTat?.maximumUnit}
                                >
                                  <option value="0">Choose</option>
                                  <option value="4">Hours</option>
                                  <option value="5">Minute</option>
                                  <option value="2">Months</option>
                                  <option value="3">Days</option>
                                </select>
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="bookingMaximumTat" className="form-label">
                                  Booking Maximum TAT
                                </Label>

                                <Input
                                  id="bookingMaximumTat"
                                  name="bookingMaximumTat"
                                  type="number"
                                  onWheel={e => e.target.blur()}
                                  className="form-control"
                                  onChange={inputChangeHandler}
                                  value={state?.benefitGroupItem?.completeData?.bookingTat?.maximum}
                                  autoComplete="off"
                                  min="0"
                                />
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="confirmMinimumTatUnit" className="form-label">
                                  Confirmation Minimum TAT Unit
                                </Label>

                                <select
                                  id="confirmMinimumTatUnit"
                                  name="confirmMinimumTatUnit"
                                  className="form-control"
                                  onChange={inputChangeHandler}
                                  value={state?.benefitGroupItem?.completeData?.tat?.confirmationMinTatUnit}
                                >
                                  <option value="0">Choose</option>
                                  <option value="4">Hours</option>
                                  <option value="5">Minute</option>
                                  <option value="2">Months</option>
                                  <option value="3">Days</option>
                                </select>
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="confirmMinimumTat" className="form-label">
                                  Confirmation Minimum TAT
                                </Label>

                                <Input
                                  id="confirmMinimumTat"
                                  name="confirmMinimumTat"
                                  type="number"
                                  onWheel={e => e.target.blur()}
                                  className="form-control"
                                  onChange={inputChangeHandler}
                                  value={state?.benefitGroupItem?.completeData?.tat?.confirmationMinTat}
                                  autoComplete="off"
                                  min="0"
                                />
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="confirmMaximumTatUnit" className="form-label">
                                  Confirmation Maximum TAT Unit
                                </Label>

                                <select
                                  id="confirmMaximumTatUnit"
                                  name="confirmMaximumTatUnit"
                                  className="form-control"
                                  onChange={inputChangeHandler}
                                  value={state?.benefitGroupItem?.completeData?.tat?.confirmationMaxTatUnit}
                                >
                                  <option value="0">Choose</option>
                                  <option value="4">Hours</option>
                                  <option value="5">Minute</option>
                                  <option value="2">Months</option>
                                  <option value="3">Days</option>
                                </select>
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="confirmMaximumTat" className="form-label">
                                  Confirmation Maximum TAT
                                </Label>

                                <Input
                                  id="confirmMaximumTat"
                                  name="confirmMaximumTat"
                                  type="number"
                                  onWheel={e => e.target.blur()}
                                  className="form-control"
                                  onChange={inputChangeHandler}
                                  value={state?.benefitGroupItem?.completeData?.tat?.confirmationMaxTat}
                                  autoComplete="off"
                                  min="0"
                                />
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="delayedCancelledTatUnit" className="form-label">
                                  Delayed Cancelled TAT Unit
                                </Label>

                                <select
                                  id="delayedCancelledTatUnit"
                                  name="delayedCancelledTatUnit"
                                  className="form-control"
                                  onChange={inputChangeHandler}
                                  value={state?.benefitGroupItem?.completeData?.tat?.delayedCancelTatUnit}
                                >
                                  <option value="0">Choose</option>
                                  <option value="4">Hours</option>
                                  <option value="5">Minute</option>
                                  <option value="2">Months</option>
                                  <option value="3">Days</option>
                                </select>
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="delayedCancelledTat" className="form-label">
                                  Delayed Cancelled TAT
                                </Label>

                                <Input
                                  id="delayedCancelledTat"
                                  name="delayedCancelledTat"
                                  type="number"
                                  onWheel={e => e.target.blur()}
                                  className="form-control"
                                  onChange={inputChangeHandler}
                                  value={state?.benefitGroupItem?.completeData?.tat?.delayedCancelTat}
                                  autoComplete="off"
                                  min="0"
                                />
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="delayedMinimumTatUnit" className="form-label">
                                  Delayed Minimum TAT Unit
                                </Label>

                                <select
                                  id="delayedMinimumTatUnit"
                                  name="delayedMinimumTatUnit"
                                  className="form-control"
                                  onChange={inputChangeHandler}
                                  value={state?.benefitGroupItem?.completeData?.tat?.delayedMinTatUnit}
                                >
                                  <option value="0">Choose</option>
                                  <option value="4">Hours</option>
                                  <option value="5">Minute</option>
                                  <option value="2">Months</option>
                                  <option value="3">Days</option>
                                </select>
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="delayedMinimumTat" className="form-label">
                                  Delayed Minimum TAT
                                </Label>

                                <Input
                                  id="delayedMinimumTat"
                                  name="delayedMinimumTat"
                                  type="number"
                                  onWheel={e => e.target.blur()}
                                  className="form-control"
                                  onChange={inputChangeHandler}
                                  value={state?.benefitGroupItem?.completeData?.tat?.delayedMinTat}
                                  autoComplete="off"
                                  min="0"
                                />
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="delayedMaximumTatUnit" className="form-label">
                                  Delayed Maximum TAT Unit
                                </Label>

                                <select
                                  id="delayedMaximumTatUnit"
                                  name="delayedMaximumTatUnit"
                                  className="form-control"
                                  onChange={inputChangeHandler}
                                  value={state?.benefitGroupItem?.completeData?.tat?.delayedMaxTatUnit}
                                >
                                  <option value="0">Choose</option>
                                  <option value="4">Hours</option>
                                  <option value="5">Minute</option>
                                  <option value="2">Months</option>
                                  <option value="3">Days</option>
                                </select>
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="delayedMaximumTat" className="form-label">
                                  Delayed Maximum TAT
                                </Label>

                                <Input
                                  id="delayedMaximumTat"
                                  name="delayedMaximumTat"
                                  type="number"
                                  onWheel={e => e.target.blur()}
                                  className="form-control"
                                  onChange={inputChangeHandler}
                                  value={state?.benefitGroupItem?.completeData?.tat?.delayedMaxTat}
                                  autoComplete="off"
                                  min="0"
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Collapse>
                    </AccordionItem>

                    <AccordionItem>
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className={classnames('accordion-button', {
                            collapsed: !col4,
                          })}
                          type="button"
                          onClick={t_col4}
                          style={{ cursor: 'pointer' }}
                        >
                          Wildcard settings
                        </button>
                      </h2>
                      <Collapse isOpen={col4} className="accordion-collapse">
                        <div className="accordion-body">
                          <Row>
                            {/* <Col md={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="bookingMinimumTatUnit"
                                  className="form-label"
                                >
                                  Booking Minimum TAT Unit
                                </Label>

                                <select
                                  id="bookingMinimumTatUnit"
                                  name="bookingMinimumTatUnit"
                                  className="form-control"
                                  onChange={inputChangeHandler}
                                  value={
                                    state?.benefitGroupItem?.completeData
                                      ?.bookingTat?.minimumUnit
                                  }
                                >
                                  <option value="0">Choose</option>
                                  <option value="4">Hours</option>
                                  <option value="5">Minute</option>
                                  <option value="2">Months</option>
                                  <option value="3">Days</option>
                                </select>
                              </div>
                            </Col> */}

                            <Col md={6}>
                              <div className="form-switch d-inline-block ">
                                <Label htmlFor="benefitGroup" className="form-label">
                                  Project Level Booking Quota
                                </Label>
                                <Input
                                  className="form-check-input code-switcher form-switch-md cursor-pointer m-auto d-block"
                                  type="checkbox"
                                  id="projectLevelQuota"
                                  name="projectLevelQuota"
                                  checked={state?.benefitGroupItem?.completeData?.projectLevelQuota?.enabled ?? false}
                                  onChange={inputChangeHandler}
                                />
                              </div>
                            </Col>

                            {state?.benefitGroupItem?.completeData?.projectLevelQuota?.enabled && (
                              <Col md={6}>
                                <Label htmlFor="benefitGroup" className="form-label">
                                  Quota Name
                                </Label>
                                <select
                                  id="quotaId"
                                  name="quotaId"
                                  className="form-select"
                                  value={state?.benefitGroupItem?.completeData?.projectLevelQuota?.quotaId}
                                  onChange={inputChangeHandler}
                                  onClick={e => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <option selected disabled value=""></option>
                                  {props?.inventoryData?.results?.length > 0 &&
                                    props?.inventoryData?.results?.map((inventory, index) => (
                                      <option value={inventory?.objectId} key={index}>
                                        {inventory?.name}
                                      </option>
                                    ))}
                                </select>
                              </Col>
                            )}
                          </Row>
                        </div>
                      </Collapse>
                    </AccordionItem>

                    <AccordionItem>
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className={classnames('accordion-button', {
                            collapsed: !col5,
                          })}
                          type="button"
                          onClick={t_col5}
                          style={{ cursor: 'pointer' }}
                        >
                          Booking - Date of Play TAT
                        </button>
                      </h2>
                      <Collapse isOpen={col5} className="accordion-collapse">
                        <div className="accordion-body">
                          <Row>
                            <Col md={12}>
                              <div>
                                <div className="form-switch d-inline-block ">
                                  <Input
                                    className="form-check-input code-switcher"
                                    type="checkbox"
                                    id="tatCheckOnDateOfPlay"
                                    name="tatCheckOnDateOfPlay"
                                    checked={state?.benefitGroupItem?.completeData?.tatCheckOnDateOfPlay}
                                    onChange={inputChangeHandler}
                                  />
                                </div>
                                <Label htmlFor="tatCheckOnDateOfPlay" className="form-label">
                                  is Date of Play TAT
                                </Label>
                              </div>
                            </Col>

                            {state?.benefitGroupItem?.completeData?.tatCheckOnDateOfPlay && (
                              <Row>
                                <Col md={6}>
                                  <div className="mb-3">
                                    <Label htmlFor="bookingTatOnDateOfPlay-maximumUnit" className="form-label">
                                      Date of Play Minimum TAT Unit
                                    </Label>

                                    <select
                                      id="bookingTatOnDateOfPlay-maximumUnit"
                                      name="bookingTatOnDateOfPlay-maximumUnit"
                                      className="form-control"
                                      onChange={inputChangeHandler}
                                      value={state?.benefitGroupItem?.completeData?.bookingTatOnDateOfPlay?.maximumUnit}
                                    >
                                      <option value="0">Choose</option>
                                      <option value="4">Hours</option>
                                      <option value="5">Minute</option>
                                      <option value="2">Months</option>
                                      <option value="3">Days</option>
                                    </select>
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="mb-3">
                                    <Label htmlFor="bookingTatOnDateOfPlay-maximum" className="form-label">
                                      Date of Play Minimum TAT
                                    </Label>

                                    <Input
                                      id="bookingTatOnDateOfPlay-maximum"
                                      name="bookingTatOnDateOfPlay-maximum"
                                      type="number"
                                      onWheel={e => e.target.blur()}
                                      className="form-control"
                                      onChange={inputChangeHandler}
                                      value={state?.benefitGroupItem?.completeData?.bookingTatOnDateOfPlay?.maximum}
                                      autoComplete="off"
                                      min="0"
                                    />
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="mb-3">
                                    <Label htmlFor="bookingTatOnDateOfPlay-minimumUnit" className="form-label">
                                      Date of Play Maximum TAT Unit
                                    </Label>

                                    <select
                                      id="bookingTatOnDateOfPlay-minimumUnit"
                                      name="bookingTatOnDateOfPlay-minimumUnit"
                                      className="form-control"
                                      onChange={inputChangeHandler}
                                      value={state?.benefitGroupItem?.completeData?.bookingTatOnDateOfPlay?.minimumUnit}
                                    >
                                      <option value="0">Choose</option>
                                      <option value="4">Hours</option>
                                      <option value="5">Minute</option>
                                      <option value="2">Months</option>
                                      <option value="3">Days</option>
                                    </select>
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="mb-3">
                                    <Label htmlFor="bookingTatOnDateOfPlay-minimum" className="form-label">
                                      Date of Play Maximum TAT
                                    </Label>

                                    <Input
                                      id="bookingTatOnDateOfPlay-minimum"
                                      name="bookingTatOnDateOfPlay-minimum"
                                      type="number"
                                      onWheel={e => e.target.blur()}
                                      className="form-control"
                                      onChange={inputChangeHandler}
                                      value={state?.benefitGroupItem?.completeData?.bookingTatOnDateOfPlay?.minimum}
                                      autoComplete="off"
                                      min="0"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            )}
                          </Row>
                        </div>
                      </Collapse>
                    </AccordionItem>
                  </Accordion>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter className="d-flex justify-content-center">
        <Button
          className="overlay-disable"
          color="primary"
          onClick={submitHandler}
          //   disabled={isSubmitBtnDisabled}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default BookingSettings;
