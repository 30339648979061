import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../../Containers/Config/index.js';
import {
  CREATE_EVENT_RULE_WITH_BENEFIT_GROUP,
  CREATE_EVENT_RULE_WITH_BENEFIT_GROUP_SUCCESS,
  CREATE_EVENT_RULE_WITH_BENEFIT_GROUP_ERROR,
} from '../../../actionType';
import apiCall from '../../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../../Containers/Helpers/constant.js';

function* createEventRuleWithBenefitGroupId({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/add-coin-benefitPack`,
      method: 'PUT',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        'X-Api-Key': 'thriwe',
        ...customHeaders,
      },
      data: payload.data,
    });

    if (response.status === 200) {
      cogoToast.success(`${response?.data?.message}`);
      yield put({
        type: CREATE_EVENT_RULE_WITH_BENEFIT_GROUP_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: CREATE_EVENT_RULE_WITH_BENEFIT_GROUP_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({
      type: CREATE_EVENT_RULE_WITH_BENEFIT_GROUP_ERROR,
      payload: error,
    });
  }
}

export function* watchCreateEventRuleWithBenefitGroupId() {
  yield takeEvery(CREATE_EVENT_RULE_WITH_BENEFIT_GROUP, createEventRuleWithBenefitGroupId);
}

function* createEventRuleWithBenefitGroupIdSaga() {
  yield all([fork(watchCreateEventRuleWithBenefitGroupId)]);
}

export default createEventRuleWithBenefitGroupIdSaga;
