import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, commsHeader, indianProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { GET_BENEFIT_DOMAINS, GET_BENEFIT_DOMAINS_SUCCESS, GET_BENEFIT_DOMAINS_ERROR } from '../../actionType.js';
import apiCall from '../../apiCall.js';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* createVendorAgent({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/comms/configs`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload?.urlParam,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_BENEFIT_DOMAINS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_BENEFIT_DOMAINS_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_BENEFIT_DOMAINS_ERROR, payload: error });
  }
}

export function* watchCreateVendorAgent() {
  yield takeEvery(GET_BENEFIT_DOMAINS, createVendorAgent);
}

function* createVendorAgentSaga() {
  yield all([fork(watchCreateVendorAgent)]);
}

export default createVendorAgentSaga;
