import { useEffect, useState } from 'react';

import axios from 'axios';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { PreviewCardHeader } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import { ksaProjectsBaseURL } from '../../../../../../Config';
import { Card, CardBody, Col, Form, Label, Row, CardFooter } from 'reactstrap';

const themeStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    padding: '8px 15px',
    ':hover': {
      cursor: 'pointer',
    },
    borderBottom: '1px solid #80808026',
  }),

  singleValue: (provided, state) => ({
    ...provided,
    color: 'black',
    fontSize: '14px',
  }),

  placeholder: styles => ({
    ...styles,
    color: 'black',
  }),

  control: (styles, state) => ({
    ...styles,
    borderRadius: '30px',
    cursor: 'pointer',
    fontSize: '16px',
    padding: '2px 2px 2px 40px',
  }),

  menu: styles => ({
    ...styles,
    padding: '5px 0',
    margin: '5px 0',
    borderRadius: '20px',
    width: '100%',
    overflow: 'hidden',
  }),

  dropdownIndicator: styles => ({ ...styles, color: 'black' }),
  indicatorSeparator: styles => ({ ...styles, borderBottom: '1px solid black', display: 'none' }),
};

const initialValues = {
  client: null,
};

const disabledStyle = {
  backgroundColor: '#e0e0e0', // Example color, change as needed
  cursor: 'not-allowed',
  opacity: 0.65,
};

const Filter = props => {
  const { t } = useTranslation();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    createdAtRange: {
      from: ``,
      to: ``,
    },
  };

  const [filter, setFilter] = useState(initialState);
  const [state, setState] = useState(initialValues);

  const [isResetDisabled, setIsResetDisabled] = useState(true);
  const [clientList, setClientList] = useState([]);
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);

  useEffect(() => {
    const { clients } = JSON.parse(localStorage.getItem('authUser'));
    if (clients?.length > 0) {
      setClientList(
        clients?.map(client => {
          return { value: client, label: `${client} Bank`, completeData: { code: client, name: `${client} Bank` } };
        }),
      );
    }
  }, []);

  /* ---------------------------- DATE PICKR HANDLER ---------------------------- */
  const datePickHandler = (flag, date) => {
    let updatedState = { ...filter };
    switch (flag) {
      case 'createdAtRange':
        updatedState = {
          ...filter,
          createdAtRange: {
            from: moment(new Date(date[0])).format('Y-MM-DD'),
            to: moment(new Date(date[1])).format('Y-MM-DD'),
          },
        };
        break;
      default:
      // do nothing
    }

    setFilter(updatedState);
    validationCheck(state, updatedState);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = (state, filter) => {
    if (state.client && filter.createdAtRange.from !== '' && filter.createdAtRange.to !== '') {
      setIsSearchDisabled(false);
    } else {
      setIsSearchDisabled(true);
    }

    if (filter.createdAtRange?.from !== '' || filter.createdAtRange?.to !== '') {
      setIsResetDisabled(false);
    } else {
      setIsResetDisabled(true);
    }
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = e => {
    e.preventDefault();
    if (!isSearchDisabled) {
      let filterValue = { ...filter };
      props.filterHandler(filterValue, state?.client?.completeData?.code, state?.client?.completeData?.name, true);
    }
  };

  const inputSelectHandler = (label, data) => {
    let updatedState = null;

    if (label === `clients`) {
      updatedState = {
        ...state,
        client: data,
      };
      setState(updatedState);
      validationCheck(updatedState, filter);
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title={t('Filter')} />
            <CardBody>
              <div className="live-preview">
                <Form onSubmit={filterSubmitHandler}>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="client" className="form-label">
                          {t('Select Client')}
                        </Label>
                        <div className="area_expandable">
                          <Select
                            id="clients"
                            name="currency"
                            value={state?.client}
                            options={clientList}
                            onChange={data => inputSelectHandler(`clients`, data)}
                            styles={themeStyles}
                          />
                          <div className="select-icon-start">
                            <i className="ri-bank-line text-dark fs-20 align-middle me-1"></i>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="created-at-range" className="form-label">
                          {t('Created at Range')}
                          <span className="icon_calendar">
                            <i className="las la-calendar"></i>
                          </span>
                        </Label>
                        <div className="area_expandable">
                          <Flatpickr
                            className="form-control"
                            onChange={date => datePickHandler(`createdAtRange`, date)}
                            value={[filter?.createdAtRange?.from, filter?.createdAtRange?.to]}
                            options={{
                              mode: 'range',
                              dateFormat: 'Y-m-d',
                              maxDate: 'today',
                            }}
                            style={state.client === null ? disabledStyle : {}}
                            disabled={state.client === null ? true : false}
                            placeholder="Select Date"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className="text-end">
                        <button type="submit" className="btn btn-primary mx-3 overlay-disable" disabled={isSearchDisabled}>
                          {t('Search')}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Filter;
