import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

const metrices = [
  {
    id: 1,
    label: 'Total records',
    badge: 'ri-arrow-up-circle-line text-success',
    icon: 'ri-space-ship-line',
    counter: 0,
    decimals: 0,
    suffix: '',
    prefix: '',
  },
  {
    id: 2,
    label: 'Ok',
    badge: 'ri-arrow-up-circle-line text-success',
    icon: 'ri-exchange-dollar-line',
    counter: 0,
    decimals: 1,
    suffix: 'k',
    prefix: '$',
  },
  {
    id: 3,
    label: 'Not Ok',
    badge: 'ri-arrow-down-circle-line text-danger',
    icon: 'ri-pulse-line',
    counter: 0,
    decimals: 2,
    suffix: '%',
    prefix: '',
  },
];

const FileSummaryMetrices = data => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [metricesData, setMetricesData] = useState([]);

  useEffect(() => {
    let tempLabel = ``;
    let tempCounter = ``;

    const tempMetrices = metrices?.map((metrice, index) => {
      if (index === 0) {
        tempLabel = `Total records`;
        tempCounter = `${data?.data?.totalCount ?? 0}`;
      } else if (index === 1) {
        tempLabel = `Ok`;
        tempCounter = `${data?.data?.okCount ?? 0}`;
      } else if (index === 2) {
        tempLabel = `Not Ok`;
        tempCounter = `${data?.data?.notOkCount ?? 0}`;
      }
      return {
        ...metrice,
        label: tempLabel,
        counter: tempCounter,
      };
    });

    setMetricesData(tempMetrices);
  }, [data]);

  return (
    <Row>
      <Col md={12}>
        <Card className="">
          <CardBody className="border">
            <Row className="row-cols-md-3 row-cols-1">
              <>
                {[...(metricesData ?? [])]?.map((item, key) => (
                  <Col className={item.id === 3 ? 'col-lg' : 'col-lg border-end'} key={key}>
                    <div className="mt-3 mt-md-0 py-2 px-3 text-center">
                      <div className="flex-grow-1 ms-3">
                        <h2 className="mb-0">{item?.counter ?? 0}</h2>
                        <h5 className="text-muted">{item?.label}</h5>
                      </div>
                    </div>
                  </Col>
                ))}
              </>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default FileSummaryMetrices;
