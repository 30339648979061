import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'reactstrap';

import Table from './components/Tables/Table';
import Filter from './components/Filters/Filter';
import UpdatePost from './components/Modals/UpdatePost';
import { Constant } from '../../../../../Helpers/constant';
import { BreadCrumb, Loader } from '../../../../../Common';
import { CLICK_DOWNLOAD_DUMP_EMPTY, GET_POST, GET_POST_EMPTY } from '../../../../../../store/actions';

// Define the shape of the filter object
interface FilterState {
  createdAtRange: {
    from: string;
    to: string;
  };
  sortOn: string;
}

// Define the shape of your Redux state
interface RootStateForGetPost {
  GetPost: {
    data: any;
    loading: boolean;
    error: any;
  };
}

interface RootStateForUpdatePost {
  UpdatePost: {
    data: any;
    loading: boolean;
    error: any;
  };
}

const Posts = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState<boolean>(false);
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
  const [currentSelectedPostDetails, setCurrentSelectedPostDetails] = useState([]);
  const [isUpdateModalShow, setIsUpdateModalShow] = useState(false);
  const [updateType, setUpdateType] = useState('');

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Posts
  const { getPost, getPostLoading } = useSelector((state: RootStateForGetPost) => ({
    getPost: state?.GetPost?.data?.data,
    getPostLoading: state?.GetPost?.loading,
  }));

  // Update Post
  const { updatePost, updatePostLoading } = useSelector((state: RootStateForUpdatePost) => ({
    updatePost: state?.UpdatePost?.data,
    updatePostLoading: state?.UpdatePost?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    return () => {
      dispatch({
        type: GET_POST_EMPTY,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (updatePost !== null) {
      dispatch({
        type: GET_POST,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          },
          data: null,
        },
      });
    }
  }, [updatePost]);

  useEffect(() => {
    dispatch({
      type: GET_POST,
      payload: {
        urlParam: {
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
        },
        data: null,
      },
    });
  }, [dispatch]);

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedPostDetails(params?.postDetails);
    if (params?.actionType === `ACCEPT`) {
      setIsUpdateModalShow(true);
      setUpdateType('Accept');
    } else if (params?.actionType === `REJECT`) {
      setIsUpdateModalShow(true);
      setUpdateType('Reject');
    } else if (params?.actionType === `BLOCK`) {
      setIsUpdateModalShow(true);
      setUpdateType('Block');
    } else if (params?.actionType === `UNBLOCK`) {
      setIsUpdateModalShow(true);
      setUpdateType('Unblock');
    } else if (params?.actionType === `DELETE`) {
      setIsUpdateModalShow(true);
      setUpdateType('Delete');
    }
  };

  /* ---------------------------- DOWNLOAD DUMP HANDLER ---------------------------- */
  const downloadDumpHandler = () => {
    dispatch({
      type: CLICK_DOWNLOAD_DUMP_EMPTY,
      payload: {
        urlParam: {
          downloadType: 1,
        },
        data: currentlyAppliedFilters,
      },
    });
  };

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = (filters: FilterState) => {
    if (filters) {
      setCurrentPageNumber(0);
      setCurrentlyAppliedFilters(filters);
      dispatch({
        type: GET_POST,
        payload: {
          urlParam: {
            pageIndex: 0,
          },
          data: {
            ...filters,
          },
        },
      });
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = (pageNumber: number) => {
    setCurrentPageNumber(pageNumber);

    dispatch({
      type: GET_POST,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          sortOn: currentlyAppliedFilters?.sortOn ?? 'updatedAt',
        },
        data: currentlyAppliedFilters,
      },
    });
  };

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/client/dashboard`);
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (getPostLoading || updatePostLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [getPostLoading, updatePostLoading]);

  return (
    <>
      {loading && <Loader />}

      {isUpdateModalShow && (
        <UpdatePost data={currentSelectedPostDetails} onCloseHandler={() => setIsUpdateModalShow(false)} updateType={updateType} />
      )}

      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ----------------------------  UI'S ---------------------------- */}
          <>
            <BreadCrumb title="Post Approval" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Post Approval`]} />
            <Filter filterHandler={filterSubmitHandler} appliedFilters={currentlyAppliedFilters} />
            <Table
              data={getPost?.data}
              totalData={getPost?.count}
              pageNumber={currentPageNumber}
              pageHandler={paginationHandler}
              actionHandler={tableActionHandler}
              dumpDownload={downloadDumpHandler}
              appliedFilters={currentlyAppliedFilters}
            />
          </>
        </Container>
      </div>
    </>
  );
};

export default Posts;
