import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, indianProjectsBaseURL, uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { VENDOR_LOGIN, VENDOR_LOGIN_SUCCESS, VENDOR_LOGIN_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* vendorLogin({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/admin/v1/login`,
      method: 'POST',
      headers: {
        // Authorization: Constant?.CLIENT_TOKEN,
        // ...customHeaders,
        'Project-Code': 'ADMIN_ACCESS',
        'X-Requested-By': 'one-admin',
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({
        type: VENDOR_LOGIN_SUCCESS,
        payload: response,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: VENDOR_LOGIN_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: VENDOR_LOGIN_ERROR, payload: error });
  }
}

export function* watchVendorLogin() {
  yield takeEvery(VENDOR_LOGIN, vendorLogin);
}

function* vendorLoginSaga() {
  yield all([fork(watchVendorLogin)]);
}

export default vendorLoginSaga;
