import Select from 'react-select';
import { Button, ModalBody, Col, Input, Row, Modal, ModalFooter, Spinner, Card, CardHeader, CardBody } from 'reactstrap';

import ModalLoader from '../../../../../../Common/Loader/ModalLoader';
import { TablePagination } from '../../../../../../Common';
import DataTable from 'react-data-table-component';
import { useEffect, useState } from 'react';
import { Constant } from '../../../../../../Helpers/constant';
import { baseUrl, customHeaders, uaeProjectsBaseURL } from '../../../../../../Config';
import axios from 'axios';
import cogoToast from 'cogo-toast';

const DownloadExcelFormat = ({ selectedFileTypes, selectedFileTypeConfig, onClose, isLoading }) => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [filtersList, setFiltersList] = useState([]);

  const [filterName, setFilterName] = useState(null);
  const [filterValue, setFilterValue] = useState('');
  const [appliedFilters, setAppliedFilters] = useState([]);

  const [apiFilterResultData, setApiFilterResultData] = useState(null);
  const [recordData, setRecordData] = useState([]);
  const [columnsData, setColumnsData] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([]);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [isDownloadAllLoading, setIsDownloadAllLoading] = useState(false);

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (selectedFileTypeConfig?.filters?.length > 0) {
      const tempFilters = selectedFileTypeConfig?.filters?.map((filter, index) => ({
        ...filter,
        label: `${filter?.name}`,
        value: `${index}`,
      }));
      setFiltersList(tempFilters);
    } else {
      setFiltersList([]);
    }
    setAppliedFilters([]);
  }, []);

  /* ---------------------------- ENTER CLICK HANDLER ---------------------------- */
  const onKeyPressHandler = e => {
    if (e.key === 'Enter') {
      const tempAppliedFilter = {
        ...filterName,
        value: filterValue,
      };

      const tempFilterPayloadData = {
        fileConfigId: `${selectedFileTypeConfig?.objectId}`,
        configEnum: selectedFileTypeConfig?.configEnum,
        filters: [...(appliedFilters ?? []), tempAppliedFilter],
      };
      const tempParmas = {
        skip: currentPageNumber * Constant?.TABLE_PAGE_SIZE,
      };
      getFilterData(tempFilterPayloadData, tempParmas);

      setAppliedFilters([...(appliedFilters ?? []), tempAppliedFilter]);
      setFilterName(null);
      setFilterValue('');
    }
  };

  /* ---------------------------- GET FILTER DATA ---------------------------- */
  async function getFilterData(filterData, params) {
    setApiFilterResultData(null);

    axios({
      method: 'POST',
      url: `${uaeProjectsBaseURL}/admin/getFilterData`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...params,
        limit: Constant?.TABLE_PAGE_SIZE,
      },
      data: {
        ...filterData,
      },
    }).then(function (response) {
      setApiFilterResultData(response?.data);
      makeDataHandler(response?.data);
      findColumnsHandler(response?.data);
    });
  }

  /* ---------------------------- MAKE DATA HANDLER ---------------------------- */
  const makeDataHandler = data => {
    if (data?.results?.length > 0) {
      const rowData = data?.results?.map(rowData => {
        let excelRowData = {};

        Object.entries(rowData?.excelDataSchema)?.forEach(([key, value], index) => {
          // console.log('testtt1222', key, value, rowData, rowData?.[key]?.['value']);
          excelRowData = {
            ...excelRowData,
            [key]: rowData?.excelDataSchema?.[key]?.['value'],
          };
        });

        return { ...excelRowData, status: `${rowData?.status?.message}`, originalData: rowData };
      });

      setRecordData(rowData);
    } else {
      setRecordData([]);
    }
  };

  /* ---------------------------- MAKE COLUMN HANDLER ---------------------------- */
  const findColumnsHandler = data => {
    if (data?.results?.length > 0) {
      const colData = Object.entries(data?.results?.[0]?.excelDataSchema).map(([key, value], index) => {
        // console.log(`data1 = ${key}: `, index, value, data?.results?.[0]?.[key]?.['headerName']);

        return {
          name: `${data?.results?.[0]?.excelDataSchema?.[key]?.['headerName']}`,
          width: '200px',
          wrap: true,
          selector: row => row?.[key],
        };
      });

      setColumnsData(colData);
    } else {
      setColumnsData([]);
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);

    const tempFilterPayloadData = {
      fileConfigId: `${selectedFileTypeConfig?.objectId}`,
      configEnum: selectedFileTypeConfig?.configEnum,
      filters: [...(appliedFilters ?? [])],
    };

    const tempParmas = {
      skip: pageNumber * Constant?.TABLE_PAGE_SIZE,
    };
    getFilterData(tempFilterPayloadData, tempParmas);
  };

  /* ---------------------------- CHECKBOX HANDLER ---------------------------- */
  const handleRowSelected = state => {
    const selectRecords = state?.selectedRows;

    if (selectRecords.length > 0) {
      setSelectedRecords(selectRecords);
    } else {
      setSelectedRecords([]);
    }
  };

  /* ---------------------------- REMOVE FILTER HANDLER ---------------------------- */
  const removeFilterHandler = (event, filter, index) => {
    const tempAppliedFilter = [...(appliedFilters ?? [])];
    tempAppliedFilter?.splice(index, 1);
    setAppliedFilters(tempAppliedFilter);

    const tempFilterPayloadData = {
      fileConfigId: `${selectedFileTypeConfig?.objectId}`,
      configEnum: selectedFileTypeConfig?.configEnum,
      filters: [...(tempAppliedFilter ?? [])],
    };

    const tempParmas = {
      skip: 0,
    };
    getFilterData(tempFilterPayloadData, tempParmas);

    setCurrentPageNumber(0);
  };

  /* ---------------------------- ON DOWNLOAD HANDLER ---------------------------- */
  const onDownloadHandler = () => {
    const tempPayload = {
      fileConfigId: `${selectedFileTypeConfig?.objectId}`,
      configEnum: selectedFileTypeConfig?.configEnum,
      filters: [...(appliedFilters ?? [])],
      isDownloadCompleteData: false,
      objectIds: selectedRecords?.length > 0 ? selectedRecords?.map(record => record?.originalData?.objectId) : [],
    };

    getDownloadExcel(tempPayload);
  };

  const onDownloadAllHandler = () => {
    const tempPayload = {
      fileConfigId: `${selectedFileTypeConfig?.objectId}`,
      configEnum: selectedFileTypeConfig?.configEnum,
      filters: [...(appliedFilters ?? [])],
      isDownloadCompleteData: true,
      objectIds: [],
    };

    getDownloadExcel(tempPayload);
  };

  /* ---------------------------- DOWNLOAD EXCEL ---------------------------- */
  async function getDownloadExcel(payloadData) {
    setIsDownloadLoading(true);
    setIsDownloadAllLoading(true);

    fetch(`${baseUrl}/admin/download-excel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Constant?.CLIENT_TOKEN,
        'Project-Code': 'ADMIN_ACCESS',
      },
      body: JSON.stringify({
        ...payloadData,
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        const link = document.createElement('a');
        const fileName = 'file.xlsx';
        link.setAttribute('download', fileName);
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(error => {
        cogoToast.error('Something went wrong');
      })
      .finally(() => {
        setIsDownloadLoading(false);
        setIsDownloadAllLoading(false);
      });
  }

  return (
    <>
      <Modal isOpen={true} id="download-excel-format" size="lg" scrollable={true}>
        <div className="modal-header p-3 bg-soft-secondary">
          {/* <button onClick={() => console.log('filter11', filterName, filterValue)}>get state</button> */}

          <h5 className="modal-title d-flex align-items-center">
            Download Sample Format - {selectedFileTypes?.name ?? ''} {selectedFileTypeConfig?.name}
          </h5>
          <Button
            type="button"
            onClick={() => {
              onClose();
            }}
            id="addBoardBtn-close"
            className="btn-close btn-light"
            aria-label="Close"
          />
        </div>

        <ModalBody>
          <Row className="mb-2">
            <Col md={4}>
              <Select id="filter" name="filter" options={filtersList} value={filterName} onChange={data => setFilterName(data)} />
            </Col>

            <Col md={8}>
              <Input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="Enter text..."
                value={filterValue}
                onChange={e => setFilterValue(e.target.value)}
                onKeyPress={onKeyPressHandler}
                autoComplete="off"
                disabled={!filterName}
              />
            </Col>
          </Row>

          {appliedFilters?.length > 0 && (
            <Row>
              <Col md={12} className="my-3">
                <span>Searching for - </span>
                <div className="d-inline-block">
                  {appliedFilters?.map((filter, index) => (
                    <button
                      key={index}
                      type="button"
                      className="btn btn-light btn-sm mx-1"
                      onClick={e => {
                        removeFilterHandler(e, filter, index);
                      }}
                    >
                      {filter?.value}&nbsp;<i className="ri-close-line align-middle"></i>
                    </button>
                  ))}
                </div>
              </Col>
            </Row>
          )}

          <Row>
            {recordData?.length > 0 ? (
              <Col md={12}>
                <DataTable
                  columns={columnsData}
                  data={recordData}
                  selectableRows={true}
                  selectableRowsHighlight={true}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={apiFilterResultData === null ? false : true}
                  customStyles={{
                    table: {
                      style: {
                        border: '1px solid #afafaf',
                      },
                    },
                    headRow: {
                      style: {
                        borderBottom: '1px solid #ddd',
                        backgroundColor: '#f9fafb',
                        fontWeight: '600',
                      },
                    },
                    cells: {
                      style: {
                        border: '0.5px solid #ddd',
                        borderBottom: '0px solid #ddd',
                      },
                    },
                  }}
                />
              </Col>
            ) : (
              <Col md={12}>
                <Card className="card-height-100 bg-light mt-3" style={{ height: '40vh' }}>
                  <CardBody className="d-flex justify-content-center align-items-center">
                    <div>
                      <div className="text-center" style={{ fontSize: '40px' }}>
                        <i className="bx bxs-file-find"></i>
                      </div>
                      <span>No data found</span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>

          {apiFilterResultData?.results?.length > 0 && (
            <Row>
              <div className="text-center mt-3">
                {/* <span>
                  Showing <b> {apiFilterResultData?.results?.length}</b> of <b>{apiFilterResultData?.count}</b> results
                </span> */}

                <span>
                  Showing <b>{currentPageNumber * Constant?.TABLE_PAGE_SIZE + 1}</b> to&nbsp;
                  <b>{currentPageNumber * Constant?.TABLE_PAGE_SIZE + apiFilterResultData?.results?.length}</b> of <b>{apiFilterResultData?.count}</b>{' '}
                  records
                </span>
              </div>
            </Row>
          )}
        </ModalBody>

        <ModalFooter className="modal-footer bg-light pt-2">
          <Col md={12} className="mt-3 d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-end">
              {apiFilterResultData?.results?.length > 0 && (
                <TablePagination totalData={apiFilterResultData?.count} currentPage={currentPageNumber} onPageChangeHandler={paginationHandler} />
              )}
            </div>

            {apiFilterResultData?.results?.length > 0 && (
              <>
                {selectedRecords?.length > 0 && (
                  <>
                    {isDownloadLoading ? (
                      <Button color="primary" className="btn-load" outline>
                        <span className="d-flex align-items-center">
                          <Spinner size="sm" className="flex-shrink-0">
                            Loading...
                          </Spinner>
                          <span className="flex-grow-1 ms-2">Loading...</span>
                        </span>
                      </Button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary btn-md mx-1"
                        onClick={() => {
                          onDownloadHandler();
                        }}
                      >
                        <i className="ri-download-cloud-line align-middle"></i>&nbsp; Download
                      </button>
                    )}
                  </>
                )}

                {selectedRecords?.length === 0 && (
                  <>
                    {isDownloadLoading ? (
                      <Button color="primary" className="btn-load" outline>
                        <span className="d-flex align-items-center">
                          <Spinner size="sm" className="flex-shrink-0">
                            Loading...
                          </Spinner>
                          <span className="flex-grow-1 ms-2">Loading...</span>
                        </span>
                      </Button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary btn-md mx-1"
                        onClick={() => {
                          onDownloadAllHandler();
                        }}
                      >
                        <i className="ri-download-cloud-line align-middle"></i>&nbsp; Download All
                      </button>
                    )}
                  </>
                )}
              </>
            )}
          </Col>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DownloadExcelFormat;
