import { useState } from 'react';

import CreatableSelect from 'react-select/creatable';

import { isValidEmail } from '../../Helpers/Helpers';

const components = {
  DropdownIndicator: null,
};

const createOption = label => ({
  label,
  value: label,
});

const CreatebleInput = ({ value, setValue, valid }) => {
  const [inputValue, setInputValue] = useState('');
  const [isValid, setIsValid] = useState(true);

  const handleKeyDown = event => {
    console.log('inputValue', event.key);
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
        if (valid === 'email' && isValidEmail(inputValue)) {
          setValue(prev => [...prev, createOption(inputValue)]);
          setInputValue('');
          setIsValid(true);
          event.preventDefault();
        } else if (!valid) {
          setValue(prev => [...prev, createOption(inputValue)]);
          setInputValue('');
          setIsValid(true);
          event.preventDefault();
        } else {
          setIsValid(false);
          setInputValue('');
          event.preventDefault();
        }
        break;

      default:
      // do nothing
    }
  };

  const handleInputChange = newValue => {
    setInputValue(newValue);
    setIsValid(true);
  };

  return (
    <div>
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={newValue => setValue(newValue)}
        onInputChange={newValue => handleInputChange(newValue)}
        onKeyDown={handleKeyDown}
        placeholder=""
        value={value}
        styles={{ cursor: 'text' }}
      />
      {!isValid && <span style={{ color: 'red' }}>Invalid {valid} format</span>}
    </div>
  );
};

export default CreatebleInput;
