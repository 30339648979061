import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, realTimeBaseURL } from '../../../../Containers/Config/index.js';
import { DOWNLOAD_SLOTS_EXCEL, DOWNLOAD_SLOTS_EXCEL_SUCCESS, DOWNLOAD_SLOTS_EXCEL_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';
import cogoToast from 'cogo-toast';
import { extractFileName } from '../../../../Containers/Helpers/Helpers.js';

function* downloadExcelSlots({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${realTimeBaseURL}/admin/download-excel`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload.urlParams,
      },
    });

    if (response.status === 200) {
      yield put({
        type: DOWNLOAD_SLOTS_EXCEL_SUCCESS,
        payload: response?.data,
      });

      var blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
      var url = URL.createObjectURL(blob);
      var pom = document.createElement('a');
      pom.href = url;
      const fileName = extractFileName(response.headers['content-disposition']);
      pom.setAttribute('download', fileName);
      pom.click();
    } else {
      cogoToast.error(`Something went wrong`);
      yield put({
        type: DOWNLOAD_SLOTS_EXCEL_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: DOWNLOAD_SLOTS_EXCEL_ERROR, payload: error });
  }
}

export function* watchDownloadSlotsExcel() {
  yield takeEvery(DOWNLOAD_SLOTS_EXCEL, downloadExcelSlots);
}

function* downloadSlotsExcelSaga() {
  yield all([fork(watchDownloadSlotsExcel)]);
}

export default downloadSlotsExcelSaga;
