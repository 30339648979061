import CountUp from 'react-countup';
import { Card, CardBody, Col, Row } from 'reactstrap';

const SurgeData = ({ data }) => {
  return (
    <Row>
      {(
        [
          {
            id: 2,
            label: 'Total Users',
            percentageIcon: 'ri-arrow-right-down-line',
            counter: `${data?.totalUsers ?? 0}`,
            icon: 'bx bxs-group',
            iconClass: 'success',
          },
          {
            id: 3,
            label: 'No. of Brands',
            percentageIcon: 'ri-arrow-right-down-line',
            counter: `${data?.brandsCount ?? 0}`,
            icon: 'bx bx-basket',
            iconClass: 'info',
          },
          {
            id: 4,
            label: 'No. of Categories',
            percentageIcon: 'ri-arrow-right-down-line',
            counter: `${data?.categoriesCount ?? 0}`,
            icon: 'bx bx-category',
            iconClass: 'danger',
          },
          {
            id: 5,
            label: 'No. of Bookings',
            percentageIcon: 'ri-arrow-right-down-line',
            counter: `${data?.totalBookings ?? 0}`,
            icon: 'bx bx-spreadsheet',
            iconClass: 'secondary',
          },
          {
            id: 6,
            label: 'Completed Bookings',
            percentageIcon: 'ri-arrow-right-down-line',
            counter: `${data?.completedBookingsCount ?? 0}`,
            icon: 'bx bx-detail',
            iconClass: 'primary',
          },
          {
            id: 7,
            label: 'Pending Bookings',
            percentageIcon: 'ri-arrow-right-down-line',
            counter: `${data?.pendingBookingsCount ?? 0}`,
            icon: 'bx bx-error-circle',
            iconClass: 'warning',
          },
        ] || []
      ).map((item, key) => (
        <Col xl={3} md={6} key={key}>
          <Card className={'card-animate'}>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <p className={'text-uppercase fw-medium mb-0 text-muted'}>{item.label}</p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className={'fs-22 fw-semibold ff-secondary'}>
                    <span className="counter-value" data-target="559.25">
                      <CountUp start={0} end={item.counter} duration={4} />
                    </span>
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className={'avatar-title rounded fs-3 bg-soft-' + item.iconClass}>
                    <i className={item.icon + ' text-' + item.iconClass}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default SurgeData;
