import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert } from 'reactstrap';
import ParticlesAuth from '../../../../../pages/AuthenticationInner/ParticlesAuth';

import { withRouter, Link } from 'react-router-dom';

import ThriweWhite from '../../../../../assets/custom/thriwe-white-logo.png';
import { Error } from '../../../../Common';
import { isValidPassword } from '../../../../Helpers/Helpers';

const ResetPasswordForm = ({ onSubmit }) => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    password: '',
    confirmPassword: '',
  };
  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'password':
        updatedState = {
          ...state,
          password: value,
        };
        break;

      case 'confirmPassword':
        updatedState = {
          ...state,
          confirmPassword: value,
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (
      data?.password?.trim() !== `` &&
      data?.confirmPassword?.trim() !== `` &&
      isValidPassword(data?.password?.trim()) &&
      isValidPassword(data?.confirmPassword?.trim()) &&
      data?.password?.trim() === data?.confirmPassword?.trim()
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      const payload = {
        password: state?.password,
      };

      onSubmit({
        ...payload,
      });
    }
  };

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <p className="mt-3 fs-15 fw-medium">&nbsp;</p>
                    <Link to="/client/login" className="d-inline-block auth-logo">
                      <img src={ThriweWhite} alt="" height="50" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4" style={{ borderRadius: '40px' }}>
                  <CardBody className="p-md-5 p-sm-3">
                    {/* <button onClick={() => console.log(state)}>get state</button> */}
                    <div className="text-center mt-2">
                      <h1 className="text-dark">Reset Password</h1>
                    </div>
                    <div className="p-2 mt-4 mb-3">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="password-input">
                          Password
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            id="password"
                            name="password"
                            type={isShowPassword ? 'text' : 'password'}
                            placeholder="Enter password"
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={state?.password}
                          />
                          {isValidationShow && state?.password?.trim() === `` && <div className="text-danger">Password can't be empty</div>}
                          {isValidationShow && state?.password?.trim() !== `` && !isValidPassword(state?.password?.trim()) && (
                            <div className="text-danger">Please enter valid password</div>
                          )}
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                            type="button"
                            id="password-addon"
                            onClick={() => {
                              setIsShowPassword(!isShowPassword);
                            }}
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="password-input">
                          Confirm Password
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            id="confirmPassword"
                            name="confirmPassword"
                            type={isShowConfirmPassword ? 'text' : 'password'}
                            placeholder="Confirm password"
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={state?.confirmPassword}
                          />
                          {isValidationShow && state?.confirmPassword?.trim() === `` && (
                            <div className="text-danger">Confirm password can't be empty</div>
                          )}
                          {isValidationShow && state?.confirmPassword?.trim() !== `` && !isValidPassword(state?.confirmPassword?.trim()) && (
                            <div className="text-danger">Please enter valid password</div>
                          )}
                          {isValidationShow &&
                            state?.confirmPassword?.trim() !== `` &&
                            isValidPassword(state?.confirmPassword?.trim()) &&
                            state?.password?.trim() !== state?.confirmPassword?.trim() && <div className="text-danger">Password can't be same</div>}
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                            type="button"
                            id="password-addon"
                            onClick={() => {
                              setIsShowConfirmPassword(!isShowConfirmPassword);
                            }}
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </div>

                      <div className="text-muted">
                        Your password must be at least 8 characters long contains at least one special character, one number, one capital letter.
                      </div>

                      <div className="mt-4">
                        <Button color="success" className="btn btn-success w-100" type="submit" onClick={submitHandler}>
                          Reset Password
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(ResetPasswordForm);
