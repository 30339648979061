import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import cogoToast from 'cogo-toast';
import apiCall from '../../apiCall';
import { customHeaders, indianProjectsBaseURL, uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { VENDOR_RESEND_OTP, VENDOR_RESEND_OTP_SUCCESS, VENDOR_RESEND_OTP_ERROR } from './../../actionType.js';

function* vendorResendOtp({ payload, endpoint = `/user/v1/resend-otp` }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}${endpoint}`,
      method: 'POST',
      headers: {
        Authorization: payload?.headers?.token,
        ...customHeaders,
        ...payload.headers,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      if (localStorage.getItem('bankCode')) {
        localStorage.setItem('landmarkAuthToken', response?.headers?.authorization);
      }
      cogoToast.success(response?.data?.message);
      yield put({
        type: VENDOR_RESEND_OTP_SUCCESS,
        payload: response,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: VENDOR_RESEND_OTP_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: VENDOR_RESEND_OTP_ERROR, payload: error });
  }
}

export function* watchVendorResendOtp() {
  yield takeEvery(VENDOR_RESEND_OTP, vendorResendOtp);
}

function* vendorResendOtpSaga() {
  yield all([fork(watchVendorResendOtp)]);
}

export default vendorResendOtpSaga;
