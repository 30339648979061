import React from 'react';
import { Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

const CreateUserModal = ({ isOpen, modalHandler }) => {
  return (
    <Modal isOpen={isOpen} toggle={modalHandler} centered>
      <ModalHeader
        toggle={() => {
          modalHandler();
        }}
      >
        <h5 className="modal-title" id="myModalLabel">
          Add New Members
        </h5>
      </ModalHeader>

      <ModalBody>
        <Form>
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label htmlFor="teammembersName" className="form-label">
                  Name
                </Label>
                <Input type="text" className="form-control" id="teammembersName" placeholder="Enter name" />
              </div>
            </Col>
            <Col lg={12}>
              <div className="mb-3">
                <Label htmlFor="designation" className="form-label">
                  Designation
                </Label>
                <Input type="text" className="form-control" id="designation" placeholder="Enter designation" />
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="totalProjects" className="form-label">
                  Projects
                </Label>
                <Input type="number" onWheel={e => e.target.blur()} className="form-control" id="totalProjects" placeholder="Total projects" />
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="totalTasks" className="form-label">
                  Tasks
                </Label>
                <Input type="number" onWheel={e => e.target.blur()} className="form-control" id="totalTasks" placeholder="Total tasks" />
              </div>
            </Col>
            <Col lg={12}>
              <div className="mb-4">
                <Label htmlFor="formFile" className="form-label">
                  Profile Images
                </Label>
                <Input className="form-control" type="file" id="formFile" />
              </div>
            </Col>
            <Col lg={12}>
              <div className="hstack gap-2 justify-content-end">
                <button type="button" className="btn btn-light" data-bs-dismiss="modal">
                  Close
                </button>
                <button type="submit" className="btn btn-success">
                  Add Member
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default CreateUserModal;
