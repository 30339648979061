import { CardFooter, Col, Row } from 'reactstrap';

import { TablePagination } from '../../../../../../../../Common';
import { hasPermission } from '../../../../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../../../../Helpers/Constants/permissions';

const PaginationSection = ({
  recordData,
  currentPageNumber,
  publishHandler,
  paginationHandler,
  onRestart,
  approvalHandler,
  selectedTracker,
  userLevelData,
}) => {
  return (
    <>
      {recordData?.count > 0 && (
        <CardFooter>
          <Row className="custom_pagination">
            <Col>
              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DELETE_DRAFT_CLIENT_DATA_GM_REPORT) && (
                <div className="">
                  <button type="button" className="btn btn-md btn-outline-primary" onClick={onRestart} disabled={!userLevelData?.isActionable}>
                    <i className="ri-restart-line align-middle"></i>&nbsp; Restart
                  </button>
                </div>
              )}
            </Col>

            <Col>
              <div className="d-flex justify-content-center">
                <TablePagination totalData={recordData?.count} currentPage={currentPageNumber} onPageChangeHandler={paginationHandler} />
              </div>
            </Col>

            <Col>
              <div className="d-flex justify-content-end gap-2">
                {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.PUBLISH_CLIENT_DATA_GM_REPORT) && (
                  <button type="button" className="btn btn-md btn-primary" onClick={publishHandler} disabled={!userLevelData?.isActionable}>
                    <i className="ri-upload-cloud-line align-middle"></i>&nbsp; Publish
                  </button>
                )}

                {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.SEND_APPROVAL_CLIENT_DATA_GM_REPORT) && (
                  <button type="button" className="btn btn-md btn-primary" onClick={approvalHandler} disabled={!userLevelData?.isActionable}>
                    <i className="ri-file-lock-line align-middle"></i>&nbsp; Send for Approval
                  </button>
                )}
              </div>
            </Col>
          </Row>
        </CardFooter>
      )}
    </>
  );
};

export default PaginationSection;
