import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../Containers/Config/index.js';

import { GET_COUNTRY, GET_COUNTRY_SUCCESS, GET_COUNTRY_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getCountry({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/countries`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        pageIndex: 0,
        pageSize: 250,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_COUNTRY_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_COUNTRY_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_COUNTRY_ERROR, payload: error });
  }
}

export function* watchGetCountry() {
  yield takeEvery(GET_COUNTRY, getCountry);
}

function* getCountrySaga() {
  yield all([fork(watchGetCountry)]);
}

export default getCountrySaga;
