import cogoToast from 'cogo-toast';
import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { Constant } from '../../../../Containers/Helpers/constant.js';
import apiCall from '../../apiCall.js';

import { DELETE_FILE, DELETE_FILE_SUCCESS, DELETE_FILE_ERROR } from './../../actionType.js';

function* deleteFile({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/admin/files`,
      method: 'DELETE',

      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      data: payload.data,
    });

    if (response.status === 200) {
      cogoToast.success('File deleted successfully.');
      yield put({
        type: DELETE_FILE_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error('Something went wrong');
      yield put({ type: DELETE_FILE_ERROR, payload: response.data.message });
    }
  } catch (error) {
    yield put({ type: DELETE_FILE_ERROR, payload: error });
  }
}

export function* watchDeleteFile() {
  yield takeEvery(DELETE_FILE, deleteFile);
}

function* deleteFileSaga() {
  yield all([fork(watchDeleteFile)]);
}

export default deleteFileSaga;
