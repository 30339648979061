import { useState } from 'react';
import { useDispatch } from 'react-redux';

import * as moment from 'moment';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Card, CardBody, Col, Label, Row, CardFooter } from 'reactstrap';

import { PreviewCardHeader } from '../../../../../../../Common';
import { uaeProjectsBaseURL } from '../../../../../../../Config';
import { Constant } from '../../../../../../../Helpers/constant';
import { useTranslation } from 'react-i18next';
import { VENDOR_BOOKINGS_DATA } from '../../../../../../../../store/actions';

const themeStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    padding: '8px 15px',
    ':hover': {
      cursor: 'pointer',
    },
    borderBottom: '1px solid #80808026',
  }),

  singleValue: (provided, state) => ({
    ...provided,
    color: 'black',
    fontSize: '14px',
  }),

  placeholder: styles => ({
    ...styles,
    color: 'black',
  }),

  control: (styles, state) => ({
    ...styles,
    borderRadius: '30px',
    cursor: 'pointer',
    fontSize: '14px',
    padding: '0 0 0 40px',
  }),

  menu: styles => ({
    ...styles,
    padding: '5px 0',
    margin: '5px 0',
    borderRadius: '20px',
    width: '100%',
    overflow: 'hidden',
  }),

  dropdownIndicator: styles => ({ ...styles, color: 'black' }),
  indicatorSeparator: styles => ({ ...styles, borderBottom: '1px solid black', display: 'none' }),
};

const initialValues = {
  client: {
    label: `${JSON.parse(localStorage?.getItem('data'))?.program?.name}`,
    value: `${JSON.parse(localStorage?.getItem('data'))?.program?.code}`,
  },
};

const initialState = {
  createdAtRange: {
    from: ``,
    to: ``,
  },
  vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
  allocatedTo: {
    label: `New User`,
    value: `new_user`,
  },
};

const Filter = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [filter, setFilter] = useState(initialState);
  const [state, setState] = useState(initialValues);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...filter };

    switch (label) {
      case 'allocatedTo':
        updatedState = { ...filter, allocatedTo: data };
        break;

      default:
      // do nothing
    }

    setFilter(updatedState);
  };

  /* ---------------------------- DATE PICKR HANDLER ---------------------------- */
  const datePickHandler = (flag, date) => {
    let updatedState = { ...filter };

    switch (flag) {
      case 'createdAtRange':
        updatedState = {
          ...filter,
          createdAtRange: {
            from: moment(new Date(date[0])).format('Y-MM-DD'),
            to: moment(new Date(date[1])).format('Y-MM-DD'),
          },
        };
        break;
      default:
      // do nothing
    }

    setFilter(updatedState);
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    let filterValue = { ...filter };
    props.filterHandler(filterValue, state?.client?.completeData?.code, state?.client?.completeData?.name, true);
  };

  /* ---------------------------- LOAD PROJECTS HANDLER ---------------------------- */
  async function loadProjects(search) {
    let projectCodes = [];
    JSON.parse(localStorage?.getItem('data'))?.program?.client?.clients.map(item => projectCodes.push(item));
    const response = await fetch(`${uaeProjectsBaseURL}/admin/v1/projects`, {
      method: `POST`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ projectCodes: projectCodes }),
    });

    const usersResponse = await response.json();
    let array = JSON.parse(localStorage?.getItem('data'))?.program?.client?.clients;
    return {
      options: usersResponse?.results?.map((data, index) => {
        return {
          value: array[index],
          label: `${data?.name}`,
          completeData: data,
        };
      }),
    };
  }

  const onSelectClient = selectedOption => {
    setState({ ...state, client: selectedOption });
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter(initialState);
    props?.setCurrentPageNumber(0);
    props?.setCurrentlyAppliedFilters(null);

    dispatch({
      type: VENDOR_BOOKINGS_DATA,
      payload: {
        urlParam: {
          bookingTypeId: 2,
          pageIndex: 0,
          sortOn: props?.appliedFilters?.sortOn ?? 'updatedAt',
        },
        data: {
          vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
        },
        headers: {
          code: props?.selectedClientCode,
        },
      },
    });
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title={t(`Filter`)} />
            {/* <button onClick={() => console.log({ filter })}>get state</button> */}
            <CardBody>
              <div className="live-preview">
                <Row>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="client" className="form-label">
                        {t(`Select Client`)}
                      </Label>
                      <div className="area_expandable">
                        <AsyncPaginate
                          debounceTimeout={500}
                          value={state?.client}
                          loadOptions={loadProjects}
                          onChange={e => onSelectClient(e)}
                          isMulti={false}
                          closeMenuOnSelect={true}
                          noOptionsMessage={() => 'No results found'}
                          cacheUniqs={[['code']]}
                          placeholder={t(`Select Client`)}
                          styles={themeStyles}
                        />
                        <div className="select-icon-start">
                          <i className="ri-bank-line text-dark fs-20 align-middle me-1"></i>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="allocatedTo" className="form-label">
                        {t(`User Type`)}
                      </Label>
                      <Select
                        id="allocatedTo"
                        name="allocatedTo"
                        value={filter?.allocatedTo}
                        options={[
                          {
                            label: `New User`,
                            value: `new_user`,
                          },
                          {
                            label: `Existing User`,
                            value: `existing_user`,
                          },
                        ]}
                        onChange={data => inputSelectHandler('allocatedTo', data)}
                        disabled={state?.client === null ? true : false}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="created-at-range" className="form-label">
                        {t(`Created at Range`)}
                        <span className="icon_calendar">
                          <i className="las la-calendar"></i>
                        </span>
                      </Label>
                      <div className="area_expandable">
                        <Flatpickr
                          className="form-control"
                          style={{ backgroundColor: state.client === null ? '#e0dede' : '' }}
                          onChange={date => datePickHandler(`createdAtRange`, date)}
                          value={[filter?.createdAtRange?.from, filter?.createdAtRange?.to]}
                          options={{
                            mode: 'range',
                            dateFormat: 'Y-m-d',
                            maxDate: 'today',
                          }}
                          disabled={state?.client === null ? true : false}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>

            <CardFooter>
              <Row>
                <Col md={12}>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary mx-3" onClick={filterSubmitHandler}>
                      <i className="ri-search-line me-1 text-white fs-14 align-middle"></i>
                      {t(`Search`)}
                    </button>

                    <button type="submit" className="btn btn-light" onClick={resetFilterHandler}>
                      {t(`Reset Filter`)}
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Filter;
