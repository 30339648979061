import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';

import { Error } from '../../../../../../../../Common';
import MobileField from '../../../../../../../../Common/FormControls/MobileField';
import { isANumberWithDecimal, isValidAlphaNumbericWithoutSpace } from '../../../../../../../../Helpers/Helpers';

const initialState = {
  billNo: '',
  billAmount: '',
};

const OfferInvoiceModal = ({ selectedBenefitItem, data, loading, onClose, onSubmit }) => {
  const { t } = useTranslation();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;

    let updatedState = { ...state };
    let tempBillAmount = ``;
    switch (name) {
      case 'billNo':
        updatedState = {
          ...state,
          billNo: isValidAlphaNumbericWithoutSpace(value) ? value : state?.billNo,
        };
        break;

      case 'billAmount':
        if (value?.length === 0) {
          tempBillAmount = ``;
        } else {
          tempBillAmount = isANumberWithDecimal(value) ? value : state?.billAmount;
        }
        updatedState = {
          ...state,
          billAmount: tempBillAmount,
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATE ERROR HANDLER ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data.billNo?.trim() !== `` && data?.billAmount?.trim() !== ``) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const onSubmitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      onSubmit({
        ...state,
        billAmount: parseFloat(state?.billAmount),
      });
    }
  };

  return (
    <Modal isOpen={true} size="md" scrollable>
      <ModalHeader
        className="bg-soft-light p-3"
        toggle={() => {
          onClose();
        }}
      >
        {t(`Offers`)}
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col className="mb-3">
            <div className="border border-dashed border-success p-2 rounded-2 cursor-pointer bg-soft-success mb-1">
              <span>
                <Label className="form-check-label text-black">
                  <i className="bx bxs-offer label-icon text-warning fs-20 align-middle mx-2"></i>
                  {selectedBenefitItem?.name ?? ''}
                </Label>
              </span>
            </div>
            <p className="text-center text-success">{t(`Offer applied`)}!</p>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <Label htmlFor="billNo" className="form-label text-black">
                {t(`Invoice Number`)}
                <span className="lbl_mandatory"> *</span>
              </Label>
              <Input
                id="billNo"
                name="billNo"
                type="text"
                className="form-control"
                value={state?.billNo}
                onChange={inputChangeHandler}
                placeholder="# 0002"
                autoComplete="off"
              />
              {isValidationShow && state?.billNo?.trim() === `` && <Error text="Invoice no. can't be empty" />}
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <Label htmlFor="billAmount" className="form-label text-black">
                {t(`Invoice Value`)}
                <span className="lbl_mandatory"> *</span>
              </Label>
              <Input
                id="billAmount"
                name="billAmount"
                type="text"
                className="form-control"
                value={state?.billAmount}
                onChange={inputChangeHandler}
                placeholder="255"
                autoComplete="off"
              />
              {isValidationShow && state?.billAmount?.trim() === `` && <Error text="Invoice value can't be empty" />}
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <Label htmlFor="name" className="form-label text-black">
                {t(`Customer Name`)}
              </Label>
              <Input
                id="name"
                name="name"
                type="text"
                className="form-control"
                value={`${data?.firstName ?? ''}`}
                placeholder=""
                autoComplete="off"
                disabled={true}
              />
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-1">
              <Label htmlFor="name" className="form-label text-black">
                {t(`Phone Number`)}
              </Label>
              <div className="input-group" style={{ alignItems: 'center' }}>
                <MobileField
                  placeholder="Phone Number"
                  preferredCountries={['sa', 'uae', 'in']}
                  defaultCountry={'sa'}
                  value={`${data?.countryCode}${data?.phoneNumber}`}
                  disabled={true}
                />
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        <div className="mx-auto w-100 px-0 py-0 my-0 pb-2">
          {loading ? (
            <Button color="success" className="btn-load w-100" outline>
              <Spinner size="sm" className="flex-shrink-0">
                Loading...
              </Spinner>
              <span className="flex-grow-1 ms-2 fs-14">Loading...</span>
            </Button>
          ) : (
            <Button className="btn btn-success w-100 fs-14" onClick={onSubmitHandler}>
              {t(`Generate Voucher`)}
            </Button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default OfferInvoiceModal;
