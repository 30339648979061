import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Flatpickr from 'react-flatpickr';

import { Button, Modal, ModalBody, ModalHeader, Col, Row, Label, Input } from 'reactstrap';
import facilityDummyExcel from './../../../../../../../assets/static-files/Facility.xlsx';
import { POST_COUPONS } from '../../../../../../../store/actions';

const BulkUploadModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    file: '',
  };
  const [state, setState] = useState(initialObj);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);
  const [modal_varying1, setmodal_varying1] = useState(true);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'uploaded-locations':
        updatedState = { ...state, file: value, fileData: e.target.files[0] };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const formData = new FormData();
    formData.append('file', state.fileData);
    formData.append('type', 5);

    dispatch({
      type: POST_COUPONS,
      payload: {
        data: formData,
      },
    });

    setmodal_varying1(!modal_varying1);
    onCloseHandler();
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data?.file !== ``) {
      setIsSubmitBtnDisabled(false);
    } else {
      setIsSubmitBtnDisabled(true);
    }
  };

  /* ---------------------------- DOWNLOAD DUMMY EXCEL FORMAT HANDLER ---------------------------- */
  const downloadSampleHandler = () => {
    window.open(facilityDummyExcel, '_self');
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Bulk Upload
        </h5>
        <Button type="button" onClick={() => tog_varying1()} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close"></Button>
      </div>

      <ModalBody>
        <form>
          <Row>
            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="formatExcel" className="form-label" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>
                      Outlets <span className="lbl_mandatory">*</span>
                    </span>
                    <span className="download-sample-excel" onClick={downloadSampleHandler}>
                      Download Sample Format
                    </span>
                  </Label>
                  <Input
                    type="file"
                    name="uploaded-locations"
                    id="uploaded-locations"
                    value={state.file}
                    onChange={inputChangeHandler}
                    className="form-control"
                  />
                </div>
              </Col>
            </div>
          </Row>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default BulkUploadModal;
