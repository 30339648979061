import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, ModalBody, Col, Label, Input, Row, ModalFooter } from 'reactstrap';
import { Error } from '../../../../../../../../Common';
import { GET_FILE_TYPES_CONFIG, UPDATE_RECORD_DATA } from '../../../../../../../../../store/actions';
import cogoToast from 'cogo-toast';
import { isANumber, isANumberWithDecimal, isDecimalNumber } from '../../../../../../../../Helpers/Helpers';

const UpdateRecordModal = ({ data, onSubmit, onClose }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [formControls, setFormControls] = useState([]);
  const [currentDataState, setCurrentDataState] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  //  FILE TYPES CONFIG
  const { fileTypesConfig } = useSelector(state => ({
    fileTypesConfig: state?.GetFileTypesConfig?.data,
  }));

  useEffect(() => {
    dispatch({
      type: GET_FILE_TYPES_CONFIG,
      payload: {
        urlParam: {
          configId: `${data?.fileConfigId}`,
        },
      },
    });
  }, []);

  const controlMaker = controlData => {
    const formControlsData = Object.entries(controlData)?.map(([key, value], index) => {
      // console.log('testtt123', key, value, data?.excelDataSchema?.[key]?.['value'], typeof data?.excelDataSchema?.[key]?.['value']);
      setCurrentDataState(controlData);
      if (controlData?.[key]?.['isDisplay']) {
        return (
          <>
            {data?.excelDataSchema?.[key]?.['type'] === 'string' && (
              <Col xl={4} lg={4} md={6} sm={6} xs={12} key={index}>
                <div className="mb-3">
                  <Label className="form-label">
                    {controlData?.[key]?.['headerName']}
                    {controlData?.[key]?.['isRequired'] && <span className="lbl_mandatory"> *</span>}
                  </Label>
                  <Input
                    className="form-control"
                    name={`${key}`}
                    id={`${key}`}
                    type="text"
                    value={`${controlData?.[key]?.['value']}`}
                    onChange={onChangeHandler}
                    disabled={!controlData?.[key]?.['isEditable']}
                    autoComplete="off"
                  />
                  {/* {console.log('inppput', key, value, data?.excelDataSchema?.[key]?.['value'], data?.excelDataSchema?.[key]?.['errorMessage'])} */}
                  {/* (data?.excelDataSchema?.[key]?.['value']?.trim() === '' || data?.excelDataSchema?.[key]?.['errorMessage']?.trim() !== '') && */}

                  {data?.excelDataSchema?.[key]?.['isRequired'] && data?.excelDataSchema?.[key]?.['value']?.trim() === '' && (
                    <Error text="Kindly fill proper data" />
                  )}

                  {data?.excelDataSchema?.[key]?.['isRequired'] &&
                    data?.excelDataSchema?.[key]?.['value']?.trim() !== '' &&
                    data?.excelDataSchema?.[key]?.['errorMessage']?.trim() !== '' && (
                      <Error text={`${data?.excelDataSchema?.[key]?.['errorMessage']?.trim()}`} />
                    )}
                </div>
              </Col>
            )}

            {data?.excelDataSchema?.[key]?.['type'] === 'int' && (
              <Col xl={4} lg={4} md={6} sm={6} xs={12} key={index}>
                <div className="mb-3">
                  <Label className="form-label">
                    {controlData?.[key]?.['headerName']}
                    {controlData?.[key]?.['isRequired'] && <span className="lbl_mandatory"> *</span>}
                  </Label>
                  <Input
                    className="form-control"
                    name={`${key}`}
                    id={`${key}`}
                    type="number"
                    value={`${controlData?.[key]?.['value']}`}
                    onChange={onChangeHandler}
                    disabled={!controlData?.[key]?.['isEditable']}
                    autoComplete="off"
                  />

                  {data?.excelDataSchema?.[key]?.['isRequired'] && data?.excelDataSchema?.[key]?.['errorMessage']?.trim() !== '' && (
                    <Error text={`${data?.excelDataSchema?.[key]?.['errorMessage']?.trim()}`} />
                  )}
                </div>
              </Col>
            )}

            {data?.excelDataSchema?.[key]?.['type'] === 'float' && (
              <Col xl={4} lg={4} md={6} sm={6} xs={12} key={index}>
                <div className="mb-3">
                  <Label className="form-label">
                    {controlData?.[key]?.['headerName']}
                    {controlData?.[key]?.['isRequired'] && <span className="lbl_mandatory"> *</span>}
                  </Label>
                  <Input
                    className="form-control"
                    name={`${key}`}
                    id={`${key}`}
                    type="number"
                    value={`${controlData?.[key]?.['value']}`}
                    onChange={onChangeHandler}
                    disabled={!controlData?.[key]?.['isEditable']}
                    placeholder={`${controlData?.[key]?.['placeholder']}`}
                    autoComplete="off"
                  />

                  {data?.excelDataSchema?.[key]?.['isRequired'] && data?.excelDataSchema?.[key]?.['errorMessage']?.trim() !== '' && (
                    <Error text={`${data?.excelDataSchema?.[key]?.['errorMessage']?.trim()}`} />
                  )}
                </div>
              </Col>
            )}

            {data?.excelDataSchema?.[key]?.['type'] === 'boolean' && (
              <Col xl={4} lg={4} md={6} sm={6} xs={12} key={index}>
                <div className="mb-3">
                  <Label className="form-label">
                    {controlData?.[key]?.['headerName']}
                    {controlData?.[key]?.['isRequired'] && <span className="lbl_mandatory"> *</span>}
                  </Label>
                  <div className="d-flex align-items-center">
                    <span className="text-muted">False &nbsp;</span>
                    <div className="form-switch d-inline-block ">
                      <Input
                        className="form-check-input code-switcher form-switch-md cursor-pointer"
                        type="checkbox"
                        name={`${key}`}
                        id={`${key}`}
                        checked={controlData?.[key]?.['value']}
                        onChange={onChangeHandler}
                        // value={`${controlData?.[key]?.['value']}`}
                      />
                    </div>
                    <span> &nbsp;True</span>
                  </div>
                </div>
              </Col>
            )}
          </>
        );
      } else {
        return false;
      }
    });
    // console.log('helllooo', formControlsData.filter(Boolean), currentDataState);
    setFormControls(formControlsData?.filter(Boolean));
  };

  useEffect(() => {
    if (data) {
      controlMaker(data?.excelDataSchema);
    }
  }, [data]);

  /* ---------------------------- ON CHANGE HANDLER ---------------------------- */
  const onChangeHandler = e => {
    const { name, value } = e.target;
    let tempExcelSchema = data?.excelDataSchema;

    let type = tempExcelSchema?.[name]?.['type'];
    // console.log('hello excel', name, value, type, tempExcelSchema[name]['value'], tempExcelSchema);

    if (type === 'string') {
      tempExcelSchema[name]['value'] = value;
      tempExcelSchema[name]['errorMessage'] = '';
    } else if (type === 'int') {
      if (value === '') {
        tempExcelSchema[name]['value'] = 0;
        tempExcelSchema[name]['errorMessage'] = '';
      } else if (isANumber(value)) {
        tempExcelSchema[name]['value'] = parseInt(value);
        tempExcelSchema[name]['errorMessage'] = '';
      }
    } else if (type === 'float') {
      if (value === '') {
        tempExcelSchema[name]['value'] = 0;
        tempExcelSchema[name]['errorMessage'] = 'Value must be a floating point number';
      } else if (isANumberWithDecimal(value)) {
        tempExcelSchema[name]['value'] = parseFloat(value);
        if (isDecimalNumber(parseFloat(value))) {
          tempExcelSchema[name]['errorMessage'] = '';
        } else {
          tempExcelSchema[name]['errorMessage'] = 'Value must be a floating point number';
        }
      }
    } else if (type === 'boolean') {
      tempExcelSchema[name]['value'] = !tempExcelSchema?.[name]?.['value'];
      tempExcelSchema[name]['errorMessage'] = '';
    }

    controlMaker({ ...tempExcelSchema });
    setCurrentDataState({ ...tempExcelSchema });
  };

  /* ---------------------------- VALIDATION HANDLER ---------------------------- */
  const validationHandler = () => {
    let allInCorrectFormat = true;

    const tempCurrentDataState = { ...currentDataState };

    Object.entries(tempCurrentDataState)?.forEach(([key, value], index) => {
      // console.log('testtt1234', key, value, data?.excelDataSchema?.[key]?.['value']);

      if (data?.excelDataSchema?.[key]?.['isRequired']) {
        if (
          data?.excelDataSchema?.[key]?.['type'] === 'string' &&
          (data?.excelDataSchema?.[key]?.['value']?.trim() === `` || data?.excelDataSchema?.[key]?.['errorMessage']?.trim() !== ``)
        ) {
          allInCorrectFormat = false;
        } else if (data?.excelDataSchema?.[key]?.['type'] === 'int' && data?.excelDataSchema?.[key]?.['errorMessage']?.trim() !== ``) {
          allInCorrectFormat = false;
        } else if (data?.excelDataSchema?.[key]?.['type'] === 'float' && data?.excelDataSchema?.[key]?.['errorMessage']?.trim() !== ``) {
          allInCorrectFormat = false;
        }
      }
    });

    return allInCorrectFormat;
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validationHandler()) {
      if (fileTypesConfig?.results?.[0]?.['configEnum']) {
        const payload = {
          urlParam: {
            configEnum: fileTypesConfig?.results?.[0]?.['configEnum'],
          },
          data: {
            rowData: {
              ...data,
              excelDataSchema: {
                ...currentDataState,
              },
            },
          },
        };

        dispatch({
          type: UPDATE_RECORD_DATA,
          payload: {
            ...payload,
          },
        });
        onClose();
      } else {
        cogoToast.error('Config enum is missing!');
      }
    } else {
      console.log('something wrong happened');
    }
  };

  return (
    <Modal
      isOpen={true}
      // toggle={() => {
      //   onClose();
      // }}
      id="update-record-modal"
      scrollable={true}
      size="xl"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title">Edit</h5>
        <Button
          type="button"
          onClick={() => {
            onClose();
          }}
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log({ formControls, currentDataState })}>Get state</button> */}
        <form>
          <Row>{formControls?.map(controls => controls)}</Row>
        </form>
      </ModalBody>

      <div className="modal-footer d-flex justify-content-center d-grid gap-3">
        <button type="button" className="btn btn-md btn-outline-danger w-25" onClick={onClose}>
          Cancel
        </button>

        <button type="button" className="btn btn-md btn-primary w-25" onClick={submitHandler}>
          Submit
        </button>
      </div>
    </Modal>
  );
};

export default UpdateRecordModal;
