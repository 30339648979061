import React, { useState } from 'react';
import { PreviewCardHeader } from '../../../../../Common';
import { Button, Card, CardBody, Col, Label, Row, TabPane } from 'reactstrap';
import ConfirmationModal from '../../Components/Modal/ConfirmationModal';
import moment from 'moment';

const Summary = ({ activeTab, toggleTab, setIsConfirmationModalOpen, flightAndAddressDetails, isUserExist, bookingsDetails }) => {
  return (
    <TabPane tabId={3}>
      <div>
        <Row>
          <Col xxl={12}>
            <Card>
              <PreviewCardHeader title="Summary Information" />
              <CardBody>
                <Row className="mb-4">
                  <Col md={12}>
                    <h5 className="text-primary">{flightAndAddressDetails?.type === 'guest' ? 'Booking Request By' : 'Personal Details'}</h5>
                  </Col>

                  <Col md={4}>
                    <Label className="fw-normal text-muted mb-0">Name</Label>
                    <span className="d-block fw-normal mb-2">{`${isUserExist?.firstName} ${isUserExist?.lastName}`}</span>
                  </Col>

                  <Col md={4}>
                    <Label className="fw-normal text-muted mb-0">Email</Label>
                    <span className="d-block fw-normal mb-2">{isUserExist?.email}</span>
                  </Col>

                  <Col md={4}>
                    <Label className="fw-normal text-muted mb-0">Phone Number</Label>
                    <span className="d-block fw-normal mb-2">{isUserExist?.phoneNumber}</span>
                  </Col>

                  <Col md={4}>
                    <Label className="fw-normal text-muted mb-0">Bin Card Type</Label>
                    <span className="d-block fw-normal mb-2">{isUserExist?.binNumber?.label}</span>
                  </Col>

                  <Col md={4}>
                    <Label className="fw-normal text-muted mb-0">Bin Number</Label>
                    <span className="d-block fw-normal mb-2">{isUserExist?.binNumber?.data?.number}</span>
                  </Col>
                </Row>

                {flightAndAddressDetails?.type === 'guest' && (
                  <Row className="mb-4">
                    <Col md={12}>
                      <h5 className="text-primary">Traveller Details</h5>
                    </Col>
                    <>
                      <Col md={4}>
                        <Label className="fw-normal text-muted mb-0">Name</Label>
                        <span className="d-block fw-normal mb-2">{flightAndAddressDetails?.name}`</span>
                      </Col>

                      <Col md={4}>
                        <Label className="fw-normal text-muted mb-0">Email</Label>
                        <span className="d-block fw-normal mb-2">{flightAndAddressDetails?.email}</span>
                      </Col>

                      <Col md={4}>
                        <Label className="fw-normal text-muted mb-0">Phone Number</Label>
                        <span className="d-block fw-normal mb-2">{flightAndAddressDetails?.phoneNumber}</span>
                      </Col>
                    </>
                  </Row>
                )}

                <Row className="mb-4">
                  <Col md={12}>
                    <h5 className="text-primary">Service Details</h5>
                  </Col>

                  <Col md={4}>
                    <Label className="fw-normal text-muted mb-0">Journey Type</Label>
                    <span className="d-block fw-normal mb-2">{flightAndAddressDetails?.journeyType?.label}</span>
                  </Col>

                  <Col md={4}>
                    <Label className="fw-normal text-muted mb-0">Date Of Service</Label>
                    <span className="d-block fw-normal mb-2">{moment(flightAndAddressDetails?.dateOfService).format('Do MMMM YYYY')}</span>
                  </Col>

                  <Col md={4}>
                    <Label className="fw-normal text-muted mb-0">Time Of Service</Label>
                    <span className="d-block fw-normal mb-2">{flightAndAddressDetails?.timeOfService}</span>
                  </Col>
                </Row>

                <Row className="my-4">
                  <Col md={12}>
                    <h5 className="text-primary">Flight Details </h5>
                  </Col>

                  <Col md={4}>
                    <Label className="fw-normal text-muted mb-0">Airport</Label>
                    <span className="d-block fw-normal mb-2">{flightAndAddressDetails?.airportName?.label}</span>
                  </Col>

                  <Col md={4}>
                    <Label className="fw-normal text-muted mb-0">Flight Number</Label>
                    <span className="d-block fw-normal mb-2">{flightAndAddressDetails?.flightNumber}</span>
                  </Col>

                  <Col md={4}>
                    <Label className="fw-normal text-muted mb-0">Terminal Number</Label>
                    <span className="d-block fw-normal mb-2">{flightAndAddressDetails?.terminalNumber}</span>
                  </Col>

                  <Col md={4}>
                    <Label className="fw-normal text-muted mb-0">Class Of Travel</Label>
                    <span className="d-block fw-normal mb-2">{flightAndAddressDetails?.classOfTravel?.value}</span>
                  </Col>

                  <Col md={4}>
                    <Label className="fw-normal text-muted mb-0">Remarks</Label>
                    <span className="d-block fw-normal mb-2">{flightAndAddressDetails?.remarks}</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      <div className="d-flex align-items-start gap-3 mt-4">
        <button
          type="button"
          className="btn btn-outline-success btn-label previestab"
          onClick={() => {
            toggleTab(activeTab - 1, 50);
          }}
        >
          <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Previous
        </button>

        <button
          type="button"
          className="btn btn-success  right ms-auto "
          onClick={() => {
            setIsConfirmationModalOpen(true);
          }}
        >
          Confirm Booking
        </button>
      </div>
    </TabPane>
  );
};

export default Summary;
