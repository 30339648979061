import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Constant } from '../../../../../../Helpers/constant';

import { SEND_MAIL, UPDATE_BOOKING, UPDATE_GOLF_BOOKING } from '../../../../../../../store/application/actionType';

const CancelModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  const cancellationResons = ['Others'];

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    remarks: '',
  };
  const [state, setState] = useState(initialObj);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    if (data !== null) {
      setModalData(data);
    }
  }, [data]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;

    switch (name) {
      case 'cancellation remarks':
        setState({ ...state, remarks: value });
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    dispatch({
      type: UPDATE_BOOKING,
      payload: {
        data: {
          projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
          bookingTypeId: Constant?.BOOKING_TYPES?.qrBookings?.objectId,
          bookingsData: [
            {
              objectId: data?.bookingId,
              detailedBookingId: data?.detailedBookingId,
              status: Constant?.BOOKING_STATUS?.CANCEL_BY_THRIWE,
              process: `cancel-booking`,
            },
          ],
        },
      },
    });

    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Cancel Remarks
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        <form>
          <div className="mb-3">
            <select className="form-select" name="cancellation remarks" value={state.remarks} onChange={inputChangeHandler}>
              <option value="">Please select a reason for cancellation</option>
              {cancellationResons?.map((cancellationReason, index) => (
                <option value={cancellationReason} key={index}>
                  {cancellationReason}
                </option>
              ))}
            </select>
          </div>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={state.remarks === ''}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default CancelModal;
