import React, { useState } from 'react';
import Events from './Components/Events';
import Attributes from './Components/Attributes';
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

const EventsManagement = () => {
  const [topBorderjustifyTab, settopBorderjustifyTab] = useState('1');
  const topBorderJustifytoggle = tab => {
    if (topBorderjustifyTab !== tab) {
      settopBorderjustifyTab(tab);
    }
  };

  return (
    <>
      <div className="page-content">
        <Container fluid className="position-relative">
          <Row>
            <Col xxl={12}>
              <Card>
                <CardBody>
                  <Nav tabs className="nav nav-tabs nav-border-top nav-border-top-primary mb-3">
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: topBorderjustifyTab === '1',
                        })}
                        onClick={() => {
                          topBorderJustifytoggle('1');
                        }}
                      >
                        Event List
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: topBorderjustifyTab === '2',
                        })}
                        onClick={() => {
                          topBorderJustifytoggle('2');
                        }}
                      >
                        Event Attribute
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={topBorderjustifyTab} className="text-muted">
                    <TabPane tabId="1" id="nav-border-top-home">
                      <Events tabId="1" activeTab={topBorderjustifyTab} />
                    </TabPane>

                    <TabPane tabId="2" id="nav-border-top-profile">
                      <Attributes tabId="2" activeTab={topBorderjustifyTab} />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EventsManagement;
