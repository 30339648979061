import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import { Button, Modal, ModalBody, Col, Label, Input, Row } from 'reactstrap';

import { Error } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import { GET_SERVICE, GET_SERVICE_EMPTY, UPDATE_BOOKING } from '../../../../../../../store/application/actionType';

const UpdateBookingModal = ({ locations, data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    // bookingDateTime: '',
    facility: null,
    service: null,
    dateTimeOfService: {
      date: '',
      time: '',
    },
  };
  const [state, setState] = useState(initialObj);
  const [golfCourses, setGolfCourses] = useState([]);
  const [allServices, setAllServices] = useState([]);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [isValidationShow, setIsValidationShow] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Services
  const { services, servicesLoading } = useSelector(state => ({
    services: state?.GetService?.data,
    servicesLoading: state?.GetService?.loading,
  }));

  useEffect(() => {
    const updatedState = {
      ...state,
      facility: {
        label: data?.facility?.name,
        value: data?.facility?.id,
      },
      service: {
        label: data?.service?.name,
        value: data?.service?.id,
      },
      dateTimeOfService: {
        date: data?.dateTimeOfService?.date,
        time: data?.dateTimeOfService?.time,
      },
      // bookingDateTime: data?.timeOfPlay,
    };
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);

    dispatch({
      type: GET_SERVICE,
      payload: {
        urlParam: {
          facilityId: data?.facility?.id,
        },
      },
    });
  }, [data]);

  useEffect(() => {
    if (locations?.results?.length > 0) {
      setGolfCourses(
        locations?.results?.map(location => {
          return {
            label: location.name,
            value: location.objectId,
          };
        }),
      );
    } else {
      setGolfCourses([]);
    }
  }, [locations]);

  useEffect(() => {
    if (services?.results?.length > 0) {
      setAllServices(
        services?.results?.map(service => {
          return {
            label: service.name,
            value: service.objectId,
          };
        }),
      );
    } else {
      dispatch({ type: GET_SERVICE_EMPTY });
      setAllServices([]);
    }
  }, [services]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const selectInputChangeHandler = (label, data) => {
    let updatedState = null;

    switch (label) {
      case 'golfCourse':
        updatedState = {
          ...state,
          facility: data,
          service: null,
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);

        dispatch({
          type: GET_SERVICE,
          payload: {
            urlParam: {
              facilityId: data.value,
            },
          },
        });
        break;

      case 'service':
        updatedState = {
          ...state,
          service: data,
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'timeOfPlay':
        updatedState = {
          ...state,
          dateTimeOfService: {
            ...state?.dateTimeOfService,
            time: value,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data?.dateTimeOfService?.time !== `` && (data?.facility !== null) !== `` && data?.service !== null) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      let confirmedData = null;
      if (data?.bookingStatusCode === Constant?.BOOKING_STATUS?.CONFIRM) {
        confirmedData = {
          confirmedDate: state?.dateTimeOfService?.date,
          confirmedTime: state?.dateTimeOfService?.time,
        };
      } else {
        confirmedData = {
          dateTimeOfService: {
            date: state?.dateTimeOfService?.date,
            time: state?.dateTimeOfService?.time,
          },
        };
      }

      const payload = {
        objectId: data?.bookingId,
        detailedBookingId: data?.detailedBookingId,
        ...confirmedData,
        service: {
          id: state?.service?.value,
          name: state?.service?.label,
        },
        process: `update-booking`,
      };

      dispatch({
        type: UPDATE_BOOKING,
        payload: {
          data: {
            projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
            bookingTypeId: Constant?.BOOKING_TYPES?.golfBookings?.objectId,
            bookingsData: [{ ...payload }],
          },
        },
      });

      onCloseHandler(false);
      setmodal_varying1(!modal_varying1);
    }
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <>
      <Modal
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1();
        }}
        id="exampleModal"
        // scrollable={true}
      >
        <div className="modal-header p-3 bg-soft-secondary">
          <h5 className="modal-title" id="createboardModalLabel">
            Update Booking
          </h5>
          <Button
            type="button"
            onClick={() => {
              tog_varying1();
            }}
            id="addBoardBtn-close"
            className="btn-close btn-light"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          {/* <button onClick={() => console.log({ state })}>Get state</button> */}
          <form>
            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <div>
                    <Label htmlFor="name" className="form-label">
                      Booking ID
                    </Label>
                    <Input className="form-control" value={data?.bookingId} disabled />
                  </div>
                </div>
              </Col>

              <Col md={12}>
                <div className="mb-3">
                  <div>
                    <Label htmlFor="name" className="form-label">
                      User Name
                    </Label>
                    <Input className="form-control" value={data?.user?.name} disabled />
                  </div>
                </div>
              </Col>

              <Col md={12}>
                <div className="mb-3">
                  <div>
                    <Label htmlFor="location" className="form-label">
                      Golf Course <span className="lbl_mandatory">*</span>
                    </Label>
                    <Select
                      id="location"
                      name="location"
                      options={golfCourses}
                      value={state?.facility}
                      // onChange={data => selectInputChangeHandler(`golfCourse`, data)}
                      isDisabled={true}
                    />
                  </div>
                  {isValidationShow && state?.facility === null && <Error text="Please select golf course" />}
                </div>
              </Col>

              <Col md={12}>
                <div className="mb-3">
                  <div>
                    <Label htmlFor="service" className="form-label">
                      Service <span className="lbl_mandatory">*</span>
                    </Label>
                    <Select
                      id="service"
                      name="service"
                      options={allServices}
                      value={state.service}
                      // onChange={data => selectInputChangeHandler(`service`, data)}
                      isDisabled={true}
                    />
                  </div>
                  {isValidationShow && state?.service === null && <Error text="Please select service" />}
                </div>
              </Col>

              <Col md={12}>
                <div className="mb-3">
                  <div>
                    <Label htmlFor="timeOfPlay" className="form-label">
                      Time of Play <span className="lbl_mandatory">*</span>
                    </Label>
                    <div className="area_expandable">
                      <Input
                        value={state?.dateTimeOfService?.time}
                        className="form-control"
                        type="time"
                        name="timeOfPlay"
                        onChange={inputChangeHandler}
                      />
                    </div>
                  </div>
                  {isValidationShow && state?.dateTimeOfService?.time === '' && <Error text="Please select time" />}
                </div>
              </Col>
            </Row>
          </form>
        </ModalBody>
        <div className="modal-footer d-flex justify-content-center">
          <Button className="overlay-disable" color="primary" onClick={submitHandler}>
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default UpdateBookingModal;
