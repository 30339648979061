import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegCheckCircle } from 'react-icons/fa';
import { Modal, ModalBody } from 'reactstrap';

const SuccessModal = ({ isSuccessModal, setIsSuccessModal }) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (isSuccessModal) {
      const timer = setTimeout(() => {
        setIsSuccessModal(false);
      }, 1000); // 1000 milliseconds = 1 second

      return () => clearTimeout(timer);
    }
  }, [isSuccessModal, setIsSuccessModal]);

  const handleClose = () => {
    setIsSuccessModal(false);
  };

  return (
    <Modal isOpen={isSuccessModal} toggle={handleClose} centered>
      <ModalBody>
        <div>
          <div className="text-center">
            <FaRegCheckCircle size={50} color="#3F9B77" />
          </div>
          <h2 className="text-center mt-2 mb-2">{t('Congratulations')}</h2>
          <p className="text-center">Booking is accepted</p>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SuccessModal;
