import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ProjectPlaceholder from './../../../../../../../assets/custom/project-placeholder-img.png';

import classnames from 'classnames';
import DataTable from 'react-data-table-component';
import { Accordion, AccordionItem, Card, CardBody, CardHeader, Col, Collapse, Row } from 'reactstrap';

const AccordionSection = ({ accordionRow, setAccordionRow, reportsData, onViewDetailDescription }) => {
  const history = useHistory();

  const subHeaderComponent = (
    <div className="d-flex w-100 text-dark mt-2 fs-13 fw-semibold" style={{ justifyContent: 'space-evenly' }}>
      <div style={{ width: '100%', textAlign: 'center', margin: 0, borderRight: '1px solid #dddddd' }}>Revenue</div>
      <div style={{ width: '100%', textAlign: 'center' }}>Expense</div>
    </div>
  );

  /* ---------------------------- ACCORDION HANDLER ---------------------------- */
  const accordionHandler = index => {
    let tempAccordionRow = [...accordionRow];
    tempAccordionRow[index] = !tempAccordionRow[index];
    setAccordionRow(tempAccordionRow);
  };

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, reportsData, domainSpecific) => {
    if (flag === `DOWNLOAD`) {
      history.push(`/booking-recon`);
    } else if (flag === `UPLOAD`) {
      history.push(`/booking-recon`);
    } else if (flag === `VIEW`) {
      onViewDetailDescription(flag, reportsData, domainSpecific);
    }
  };

  return (
    <div>
      <div className="live-preview">
        <Accordion className="accordion-border-box">
          {reportsData?.map((reportData, index) => (
            <AccordionItem key={index}>
              <h2 className="accordion-header">
                <button
                  className={classnames('accordion-button bg-white', { collapsed: !accordionRow[index] })}
                  type="button"
                  onClick={() => accordionHandler(index)}
                  style={{ cursor: 'pointer' }}
                >
                  <Row className="w-100">
                    <Col sm={12} lg={5} className="my-1">
                      <img
                        src={`${reportData?.project?.logo === '' ? ProjectPlaceholder : reportData?.project?.logo}`}
                        alt="program-logo"
                        style={{
                          width: '40px',
                          height: '40px',
                          borderRadius: '50%',
                          display: 'inline-block',
                          marginRight: '10px',
                        }}
                        className="img-fluid"
                      />
                      <span>{reportData?.project?.name}</span>
                    </Col>

                    <Col sm={12} lg={3} className="my-1">
                      <div className="d-flex">
                        <div className="me-4">
                          <div className="bg-light fw-light px-2 py-1 rounded-2">{reportData?.overallData?.marginPercentage}% Margin</div>
                        </div>

                        <div className="flex-grow-1">
                          <span>{reportData?.overallData?.completionPercentage}% Completed</span>
                          <div className="progress mt-1" style={{ height: 4 }}>
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: `${reportData?.overallData?.completionPercentage}%` }}
                              aria-valuenow={100}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col sm={12} lg={4} className="my-1">
                      <div className="">
                        {reportData?.domains?.map((domain, index) => (
                          <button key={index} type="button" className="btn btn-sm btn-outline-primary mx-1">
                            {domain}
                          </button>
                        ))}
                        {/* <span className="text-primary fw-light">+ more</span> */}
                      </div>
                    </Col>
                  </Row>
                </button>
              </h2>

              <Collapse isOpen={accordionRow[index]} className="accordion-collapse">
                <div className="accordion-body">
                  <Card className="border border-1 mb-5">
                    <CardHeader className="d-flex justify-content-between">
                      <h4 className="card-title mb-0">{`Overview`}</h4>
                    </CardHeader>

                    <DataTable
                      subHeader={true}
                      subHeaderComponent={subHeaderComponent}
                      columns={[
                        {
                          name: 'Booking Count',
                          width: '150px',
                          selector: row => row?.data?.overallData?.revenue?.bookingCount,
                        },
                        {
                          name: 'Booking Amount Received (Tax Exl.)',
                          minWidth: '150px',
                          selector: row => row?.data?.overallData?.revenue?.bookingAmountReceived,
                        },
                        {
                          name: 'Other Incomes (Tax Exl.)',
                          width: '150px',
                          selector: row => row?.data?.overallData?.revenue?.otherIncomes,
                        },
                        {
                          name: 'Cost of Booking (Tax Exl.)',
                          width: '150px',
                          selector: row => row?.data?.overallData?.expense?.costOfBooking,
                        },
                        {
                          name: 'Other Charges (Tax Exl.)',
                          minWidth: '150px',
                          selector: row => row?.data?.overallData?.expense?.otherCharges,
                        },
                        {
                          name: 'Margin',
                          width: '150px',
                          selector: row => row?.data?.overallData?.margin,
                        },
                      ]}
                      data={[
                        {
                          data: reportData,
                        },
                      ]}
                      highlightOnHover
                      customStyles={{
                        header: {
                          style: {
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                          },
                        },
                        headRow: {
                          style: {
                            borderBottom: '1px solid #ddd',
                            backgroundColor: '#f9fafb',
                            fontWeight: '600',
                          },
                        },
                        cells: {
                          style: {
                            border: '0.5px solid #ddd',
                            borderBottom: '0px solid #ddd',
                          },
                        },
                      }}
                    />
                  </Card>

                  {reportData?.domainSpecificData?.map((domainSpecific, index) => (
                    <Card className="border border-1" key={index}>
                      <CardHeader className="d-flex justify-content-between">
                        <h4 className="card-title mb-0">{`${domainSpecific?.domain}`}</h4>

                        <div className="">
                          <span>{domainSpecific?.completionPercentage}% Completed</span>
                          <div className="progress mt-1" style={{ height: 4 }}>
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: `${domainSpecific?.completionPercentage}%` }}
                              aria-valuenow={100}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </div>

                        <div>
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-sm mx-1"
                            onClick={() => {
                              actionBtnHandler(`DOWNLOAD`, reportData, domainSpecific);
                            }}
                          >
                            <i className="ri-download-cloud-line align-middle"></i>&nbsp; Download
                          </button>

                          <button
                            type="button"
                            className="btn btn-outline-primary btn-sm mx-1"
                            onClick={() => {
                              actionBtnHandler(`UPLOAD`, reportData, domainSpecific);
                            }}
                          >
                            <i className="ri-upload-2-line align-middle"></i>&nbsp; Upload
                          </button>

                          <button
                            type="button"
                            className="btn btn-outline-primary btn-sm mx-1"
                            onClick={() => {
                              actionBtnHandler(`VIEW`, reportData, domainSpecific);
                            }}
                          >
                            <i className="ri-eye-line align-middle"></i>&nbsp; View
                          </button>
                        </div>
                      </CardHeader>

                      <CardBody>
                        <DataTable
                          columns={[
                            {
                              name: 'Verticals',
                              width: '150px',
                              selector: row => row?.data?.vertical,
                            },
                            {
                              name: 'Country',
                              minWidth: '150px',
                              selector: row => row?.data?.country,
                            },
                            {
                              name: 'Booking Count',
                              width: '150px',
                              selector: row => row?.data?.totalBookingCount,
                            },
                            {
                              name: 'No. Of Players',
                              width: '150px',
                              selector: row => row?.data?.playersCount,
                            },
                            {
                              name: 'Amount Read Exclusive Of Tax',
                              minWidth: '150px',
                              selector: row => row?.data?.revenue?.amountReceived,
                            },
                            {
                              name: 'Processing Fee If Received (Exclusive Of Tax)',
                              width: '150px',
                              selector: row => row?.data?.revenue?.processingFeeReceived,
                            },
                            {
                              name: 'No Show Charges/ Other charges paid by bank',
                              width: '150px',
                              selector: row => row?.data?.revenue?.otherCharges,
                            },
                            {
                              name: 'Concierge Fee Received',
                              width: '150px',
                              selector: row => row?.data?.revenue?.conciergeFeeReceived,
                            },
                            {
                              name: 'Cost of slot to game/session (exlusive of taxes)',
                              width: '150px',
                              selector: row => row?.data?.revenue?.conciergeFeeReceived,
                            },
                            {
                              name: 'No Show charges/ Commission paid to GC',
                              width: '150px',
                              selector: row => row?.data?.expense?.costToSlot,
                            },
                            {
                              name: 'Amount of Reimbursement to customer (if any)',
                              width: '150px',
                              selector: row => row?.data?.expense?.amountReimbursementToCustomer,
                            },
                            {
                              name: 'Concierge fee paid',
                              width: '150px',
                              selector: row => row?.data?.expense?.conciergeFeePaid,
                            },
                            {
                              name: 'Margin',
                              width: '150px',
                              selector: row => row?.data?.finalReport?.margin,
                            },
                            {
                              name: 'Margin %',
                              width: '150px',
                              selector: row => row?.data?.finalReport?.marginPercentage,
                            },
                            {
                              name: 'GM Contribution',
                              width: '150px',
                              selector: row => row?.data?.finalReport?.contributionPercentage,
                            },
                          ]}
                          data={[
                            {
                              data: domainSpecific,
                            },
                          ]}
                          highlightOnHover
                          customStyles={{
                            header: {
                              style: {
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                              },
                            },
                            headRow: {
                              style: {
                                borderBottom: '1px solid #ddd',
                                backgroundColor: '#f9fafb',
                                fontWeight: '600',
                              },
                            },
                            cells: {
                              style: {
                                border: '0.5px solid #ddd',
                                borderBottom: '0px solid #ddd',
                              },
                            },
                          }}
                        />
                      </CardBody>
                    </Card>
                  ))}
                </div>
              </Collapse>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default AccordionSection;
