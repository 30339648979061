import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';

import { Button, Modal, ModalBody, ModalHeader, Col, Label, Input, Row, Card, CardBody, CardFooter, Form, ModalFooter } from 'reactstrap';

import { CREATE_CLIENTS } from '../../../../../../../store/application/actionType';
import { Error, PreviewCardHeader } from '../../../../../../Common';
import axios from 'axios';
import { baseUrl, customHeaders } from '../../../../../../Config';
import { Constant } from '../../../../../../Helpers/constant';
import { AsyncPaginate } from 'react-select-async-paginate';
import ReactTooltip from 'react-tooltip';

const Client = ({ countries, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    name: '',
    description: '',
    contractingCountry: null,
    clientType: ``,
    industry: ``,
  };
  const [state, setState] = useState(initialObj);
  const [isValidationShow, setIsValidationShow] = useState(false);
  const [client, setClient] = useState({});
  const [isSelectedClient, setIsSelectedClient] = useState(false);

  const [allCountries, setAllCountries] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [modal_varying1, setmodal_varying1] = useState(true);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Client List
  const { clients } = useSelector(state => ({
    clients: state?.Clients?.data,
    clientsLoading: state?.Clients?.loading,
    clientsError: state?.Clients?.error,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (countries !== null) {
      setAllCountries(
        countries?.map(country => ({
          value: country?.countryId,
          label: country?.name,
        })),
      );
    }
  }, [countries]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'name':
        updatedState = {
          ...state,
          name: value,
        };
        break;

      case 'description':
        updatedState = {
          ...state,
          description: value,
        };
        break;

      case 'clientType':
        updatedState = {
          ...state,
          clientType: value,
        };
        break;

      case 'industry':
        updatedState = {
          ...state,
          industry: value,
        };
        break;

      default:
      // do nothing
    }
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = null;

    if (label === `contractingCountry`) {
      updatedState = {
        ...state,
        contractingCountry: data,
      };
      setState(updatedState);
      validateErrors(updatedState, `validationCheck`);
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (
      data?.name?.trim() !== `` &&
      data?.description?.time?.trim() !== `` &&
      data?.contractingCountry !== null &&
      data?.clientType?.trim() !== `` &&
      data?.industry?.trim() !== ``
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (isSelectedClient) {
      // submitParentHandler(client, "client");
      // toggleTab(2);
    } else {
      if (validateErrors(state, `submissionCheck`)) {
        const payload = {
          ...state,
          contractingCountry: {
            id: state?.contractingCountry?.value,
            name: state?.contractingCountry?.label,
          },
        };
        dispatch({
          type: CREATE_CLIENTS,
          payload: {
            data: {
              ...payload,
            },
          },
        });
        // toggleTab(2);
      }
    }
  };

  async function loadClient(search, loadedOptions) {
    return await axios({
      method: `GET`,
      url: `${baseUrl}/admin/clients`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        name: search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data.id,
              label: `${data.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  const onSelectClient = selectedOption => {
    setClient(selectedOption);
    setState({
      name: selectedOption?.completeData?.name,
      description: selectedOption?.completeData?.description,
      contractingCountry: {
        value: selectedOption?.completeData?.contractingCountry?.id,
        label: selectedOption?.completeData?.contractingCountry?.name,
      },
      clientType: selectedOption?.completeData?.clientType,
      industry: selectedOption?.completeData?.industry,
    });
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <>
      <Modal
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1();
        }}
        id="create-client"
        size="lg"
      >
        <div className="modal-header p-3 bg-soft-secondary">
          <h5 className="modal-title" id="createboardModalLabel">
            Client
          </h5>
          <Button type="button" onClick={tog_varying1} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close"></Button>
        </div>
        <ModalBody>
          {/* <button onClick={() => console.log({ state })}>Get state</button> */}
          <Row>
            <Col xxl={12}>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <div className="form-check form-switch">
                          <Input
                            role="switch"
                            type="checkbox"
                            id="has-parent-category"
                            name="has-parent-category"
                            className="form-check-input"
                            checked={isSelectedClient}
                            onChange={() => {
                              if (isSelectedClient) {
                                setClient({});
                                setState(initialObj);
                              }
                              setIsSelectedClient(!isSelectedClient);
                            }}
                          />
                          <Label className="form-check-label" htmlFor="has-parent-category">
                            Already Client
                          </Label>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={5} className="mb-4">
                      <div className="d-flex justify-content-between">
                        <Label htmlFor="choices-multiple-default" className="form-label">
                          Select Client
                          <span className="lbl_mandatory"></span>
                        </Label>
                      </div>
                      <AsyncPaginate
                        debounceTimeout={500}
                        value={client}
                        loadOptions={loadClient}
                        onChange={selectedOption => onSelectClient(selectedOption)}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        noOptionsMessage={() => 'No results found'}
                        cacheUniqs={[['code']]}
                        placeholder="Select..."
                        isDisabled={!isSelectedClient}
                      />
                    </Col>
                  </Row>
                  <hr style={{ backgroundColor: '#9ea0a1' }} />
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="name" className="form-label">
                          Name
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state?.name}
                          autoComplete="off"
                        />
                        {isValidationShow && state?.name?.trim() === `` && <Error text="Please enter name" />}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <div>
                          <Label htmlFor="name" className="form-label">
                            Description
                            <span className="lbl_mandatory"> *</span>
                          </Label>
                          <Input
                            id="description"
                            name="description"
                            type="text"
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={state?.description}
                            autoComplete="off"
                          />
                          {isValidationShow && state?.description?.trim() === `` && <Error text="Please enter description" />}
                        </div>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <div>
                          <Label htmlFor="clientType" className="form-label">
                            Client Type
                            <span className="lbl_mandatory"> *</span>
                          </Label>
                          <Input
                            id="clientType"
                            name="clientType"
                            type="text"
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={state?.clientType}
                            autoComplete="off"
                          />
                          {isValidationShow && state?.clientType?.trim() === `` && <Error text="Please enter client type" />}
                        </div>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <div>
                          <Label htmlFor="industry" className="form-label">
                            Industry
                            <span className="lbl_mandatory"> *</span>
                          </Label>
                          <Input
                            id="industry"
                            name="industry"
                            type="text"
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={state?.industry}
                            autoComplete="off"
                          />
                          {isValidationShow && state?.industry?.trim() === `` && <Error text="Please enter industry" />}
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <div>
                          <Label htmlFor="" className="form-label">
                            Contracting Country
                            <span className="lbl_mandatory"> *</span>
                          </Label>
                          <Select
                            id="contracting-country"
                            name="contracting-country"
                            value={state?.contractingCountry}
                            options={allCountries}
                            onChange={data => inputSelectHandler(`contractingCountry`, data)}
                          />
                          {isValidationShow && state?.contractingCountry === null && <Error text="Please select country" />}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>

              <div
                style={{
                  width: '100px',
                  position: 'relative',
                  zIndex: '999999999999',
                }}
              >
                <ReactTooltip />
              </div>
            </Col>
          </Row>
        </ModalBody>
        <CardFooter>
          <Row>
            <Col md={12}>
              <div className="text-center">
                <button type="submit" className="btn btn-primary mx-3 overlay-disable" onClick={submitHandler}>
                  {Object.keys(client)?.length > 0 ? 'Update' : 'Create'}
                </button>
              </div>
            </Col>
          </Row>
        </CardFooter>
      </Modal>
    </>
  );
};

export default Client;
