import React, { useEffect, useState } from 'react';
import CardBox from '../../../ProjectDashboard/Components/Card';
import { useDispatch, useSelector } from 'react-redux';
import { GET_BOOKING_SUMMARY } from '../../../../../../../store/application/actionType';
import { Card, CardBody, Col, Row } from 'reactstrap';
import moment from 'moment';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Mousewheel } from 'swiper/modules';

const StatusWiseData = [
  { name: `Total Bookings`, key: `total`, bgColor: 'slategray' },
  { name: `Pending`, key: `pending`, bgColor: 'darkorange' },
  { name: `Processed`, key: `processed`, bgColor: 'tomato' },
  { name: `Confirmed`, key: `confirmed`, bgColor: 'royalblue' },
  { name: `Completed`, key: `completed`, bgColor: 'seagreen' },
  { name: `Cancel by thriwe`, key: `cancelledByThriwe`, bgColor: 'indianred' },
  { name: `Cancel by user`, key: `cancelledByUser`, bgColor: 'indianred' },
  { name: `Payment Failed`, key: `paymentFailed`, bgColor: 'indianred' },
  { name: `Payment Pending`, key: `paymentPending`, bgColor: 'darkorange' },
  { name: `Payment Received`, key: `paymentRecieved`, bgColor: 'royalblue' },
];

const ProjectScore = () => {
  const dispatch = useDispatch();

  const [swiperData, setSwiperData] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { BookingSummary } = useSelector(state => ({
    BookingSummary: state?.GetBookingSummary?.data,
  }));

  useEffect(() => {
    dispatch({
      type: GET_BOOKING_SUMMARY,
      payload: {
        urlParam: {
          toDate: moment(new Date()).format('YYYY-MM-DD'),
          fromDate: moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD'),
        },
      },
    });
  }, []);

  useEffect(() => {
    if (BookingSummary !== null) {
      modifiedDataForSwiper(BookingSummary?.data);
    }
  }, [BookingSummary]);

  const modifiedDataForSwiper = data => {
    const dataForSwiper = StatusWiseData?.map(statusData => {
      return {
        ...statusData,
        [statusData?.key]: data?.bookings?.[statusData?.key] ?? 0,
        count: data?.bookings?.[statusData?.key] ?? 0,
      };
    });

    setSwiperData(dataForSwiper);
  };

  return (
    <>
      {swiperData && (
        <Swiper
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          className="mySwiper"
          slidesPerView={4}
          autoplay={{
            delay: 4000,
            disableOnInteraction: true,
          }}
          modules={[Pagination, Autoplay, Mousewheel]}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            481: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1200: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
          }}
        >
          {swiperData?.map((data, index) => {
            return (
              <SwiperSlide key={index}>
                <CardBox heading={data?.name} count={`${data?.count}`} backgroundColor={data?.bgColor} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </>
  );
};

export default ProjectScore;
