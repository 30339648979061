import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { Grid, _ } from 'gridjs-react';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import { PreviewCardHeader, TablePagination } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';
import { hasPermission } from '../../../../../../Helpers/Helpers';

const Table = props => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const [currentFilteredBookingTitle, setCurrentFilterBookingTitle] = useState('Bookings');

  useEffect(() => {
    getBookingStatusTitleHandler(parseInt(props?.appliedFilters?.status));
    if (props?.data?.length > 0) {
      let inProcessBy = '',
        processedBy = '',
        confirmBy = '',
        cancelBy = '';

      setData(
        props?.data?.map((data, index) => {
          const status = Constant?.BOOKING_STATUSES?.filter(statusObj => statusObj.objectId === data?.bookingStatusCode)[0]?.name;

          return [
            `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            data?.bookingId || `NA`,
            data?.membershipId || `NA`,
            data?.user?.name || `NA`,
            data?.user?.caNumber || `NA`,
            data?.couponCode || `NA`,
            data?.pin || `NA`,
            data?.user?.mobile || `NA`,
            data?.user?.email || `NA`,
            data?.category?.name || `NA`,
            data?.partner?.name || `NA`,
            data?.facility?.name || `NA`,
            data?.service?.name || `NA`,
            data?.voucherWorth || `NA`,

            data?.couponCodeExpiry ? moment(`${data?.couponCodeExpiry}`).format('DD-MM-YYYY, h:mm A') : `NA`,

            data?.updatedAt ? moment(`${data?.updatedAt}`).format('DD-MM-YYYY, h:mm A') : `NA`,
            status,
          ];
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- BOOKING TABLE TITLE HANDLER ---------------------------- */
  const getBookingStatusTitleHandler = bookingsStatusCode => {
    let bookingsTitle = 'Bookings';

    if (bookingsStatusCode !== 0) {
      Constant?.BOOKING_STATUSES?.forEach(bookingStatus => {
        if (bookingsStatusCode === bookingStatus?.objectId) {
          bookingsTitle = `${bookingStatus?.name} Bookings`;
        }
      });
    }
    setCurrentFilterBookingTitle(bookingsTitle);
  };

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, index) => {
    const currentSelectedIndex = Number(index.split('.')[0]) - 1 - props.pageNumber * Constant.TABLE_PAGE_SIZE;
    const selectedBookingDetails = props?.data[currentSelectedIndex];

    if (flag === `EDIT`) {
      props.actionHandler({
        bookingDetails: selectedBookingDetails,
        actionType: flag,
      });
    } else if (flag === `VIEW`) {
      props.actionHandler({
        bookingDetails: selectedBookingDetails,
        actionType: flag,
      });
    } else if (flag === `PROCESS`) {
      props.actionHandler({
        bookingDetails: selectedBookingDetails,
        actionType: flag,
      });
    } else if (flag === `CONFIRM`) {
      props.actionHandler({
        bookingDetails: selectedBookingDetails,
        actionType: flag,
      });
    } else if (flag === `CANCEL`) {
      props.actionHandler({
        bookingDetails: selectedBookingDetails,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{currentFilteredBookingTitle}</h4>

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_ACCESS) && props?.data?.length > 0 && (
                <span className="btn btn-success mb-1" onClick={props?.dumpDownload}>
                  <i className="ri-download-line me-1 text-white fs-14 align-middle"></i>Download dump
                </span>
              )}
            </CardHeader>

            {props?.data?.length > 0 && (
              <CardBody>
                <div id="table-gridjs">
                  <Grid
                    data={data}
                    columns={[
                      {
                        name: 'S.No.',
                        width: '70px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Booking ID',
                        width: '130px',
                        formatter: (cell, row) => _(<span>{cell}</span>),
                      },

                      {
                        name: 'Membership ID',
                        width: '155px',
                        formatter: (cell, row) => _(<span>{cell}</span>),
                      },

                      {
                        name: 'User Name',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'CA Number',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Coupon Code',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Pin',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'User Mobile',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'User Email',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Category',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Partner',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Facility',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Service',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Voucher Worth',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Coupon Expiry',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Updated At',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Status',
                        width: '140px',
                        formatter: cell =>
                          _(
                            <span
                              className={`booking_status badge bg-${Constant.BOOKING_STATUSES.filter(status => status?.name === cell)[0]?.bgColor}`}
                            >
                              {cell}
                            </span>,
                          ),
                      },

                      // {
                      //   name: 'Actions',
                      //   width: '120px',
                      //   formatter: (cell, row) =>
                      //     _(
                      //       <div className="d-flex justify-content-center flex-column">
                      //         {Constant?.CURRENTLY_ASSIGNED_PERMISSIONS.includes(
                      //           PermissionConstants?.ALL_PERMISSIONS
                      //             ?.UNIQUE_COUPON_BOOKING_UPDATE,
                      //         ) &&
                      //           row?.cells[row?.cells?.length - 2]?.data ===
                      //             'Pending' && (
                      //             <>
                      //               <span
                      //                 className="btn btn-sm btn-primary mb-1"
                      //                 onClick={() => {
                      //                   actionBtnHandler(
                      //                     `PROCESS`,
                      //                     row.cells[0]?.data,
                      //                   )
                      //                 }}
                      //               >
                      //                 Process
                      //               </span>

                      //               <span
                      //                 className="btn btn-sm btn-primary mb-1"
                      //                 onClick={() => {
                      //                   actionBtnHandler(
                      //                     `CANCEL`,
                      //                     row.cells[0]?.data,
                      //                   )
                      //                 }}
                      //               >
                      //                 Cancel
                      //               </span>
                      //             </>
                      //           )}

                      //         {Constant?.CURRENTLY_ASSIGNED_PERMISSIONS.includes(
                      //           PermissionConstants?.ALL_PERMISSIONS
                      //             ?.UNIQUE_COUPON_BOOKING_UPDATE,
                      //         ) &&
                      //           row.cells[row?.cells?.length - 2]?.data ===
                      //             'Process' && (
                      //             <>
                      //               <span
                      //                 className="btn btn-sm btn-primary mb-1"
                      //                 onClick={() => {
                      //                   actionBtnHandler(
                      //                     `CANCEL`,
                      //                     row.cells[0]?.data,
                      //                   )
                      //                 }}
                      //               >
                      //                 Cancel
                      //               </span>

                      //               <span
                      //                 className="btn btn-sm btn-primary mb-1"
                      //                 onClick={() => {
                      //                   actionBtnHandler(
                      //                     `CONFIRM`,
                      //                     row.cells[0]?.data,
                      //                   )
                      //                 }}
                      //               >
                      //                 Confirm
                      //               </span>
                      //             </>
                      //           )}
                      //         {Constant?.CURRENTLY_ASSIGNED_PERMISSIONS.includes(
                      //           PermissionConstants?.ALL_PERMISSIONS
                      //             ?.UNIQUE_COUPON_BOOKING_UPDATE,
                      //         ) &&
                      //           row.cells[row?.cells?.length - 2]?.data ===
                      //             'Confirmed' && (
                      //             <span
                      //               className="btn btn-sm btn-primary mb-1"
                      //               onClick={() => {
                      //                 actionBtnHandler(
                      //                   `CANCEL`,
                      //                   row.cells[0]?.data,
                      //                 )
                      //               }}
                      //             >
                      //               Cancel
                      //             </span>
                      //           )}
                      //       </div>,
                      //     ),
                      // },
                    ]}
                    search={false}
                    sort={false}
                    fixedHeader={true}
                    // height="400px"
                    pagination={{ enabled: false, limit: 5 }}
                  />
                </div>
                {props.children}
              </CardBody>
            )}

            {props?.data?.length === 0 && (
              <CardBody>
                <h5 className="text-center">Record not found!</h5>
              </CardBody>
            )}

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Table);
