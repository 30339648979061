import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, CardBody, Input, Label } from 'reactstrap';
import Select from 'react-select';

const PickupDrop = ({ data, index, terminalList, updateTerminal, removeTerminal }) => {
  const initialValues = {
    terminalPlaceholder: '',
    terminal: null,
    latlong: '',
  };
  const [state, setState] = useState(initialValues);
  const [options, setOptions] = useState([]);
  const [selectedButton, setSelectedButton] = useState(null);
  const [save, setSave] = useState(false);

  const handleSave = checkUpdation => {
    if (!checkUpdation) {
      updateTerminal(
        {
          terminal: state.terminal,
          type: selectedButton,
          latlong: state.latlong,
          terminalPlaceholder: state.terminalPlaceholder,
        },
        index,
      );
    }
    setSave(checkUpdation);
  };

  useEffect(() => {
    if (terminalList.length > 0) {
      const terminalNames = terminalList.map(({ name, lat, long }) => ({
        label: name,
        value: name,
        lat,
        long,
      }));
      setOptions(terminalNames);
      setState({
        ...state,
        terminal: data?.name && { label: data?.name, value: data?.name },
        latlong: `${data?.lat},${data?.long}`,
        terminalPlaceholder: data?.terminalPlaceholder ? data?.terminalPlaceholder : state.terminalPlaceholder,
      });
      data?.type ? setSelectedButton(data?.type) : setSelectedButton('pickup');
    }
  }, [terminalList, data]);

  const handleClick = button => {
    setSelectedButton(button);
  };

  return (
    <>
      <Card style={{ border: '1px solid black', margin: '10px' }}>
        <CardBody>
          <div>
            <div>
              <Input
                id="terminalName"
                name="terminalName"
                type="text"
                className="form-control"
                onChange={e => {
                  setState({ ...state, terminalPlaceholder: e.target.value });
                }}
                value={state?.terminalPlaceholder}
                autoComplete="off"
                disabled={!save}
              />
            </div>
            <div className="mt-3">
              <Select id="terminals" name="contracting-country" value={state?.terminal} options={options} onChange={data => {}} isDisabled={!save} />
            </div>
            <ButtonGroup className="d-flex mt-3">
              <Button
                type="button"
                style={{
                  width: '100%',
                  borderRadius: '2px',
                  backgroundColor: selectedButton === 'pickup' ? 'blue' : 'white',
                  color: selectedButton === 'pickup' ? 'white' : 'black',
                }}
                onClick={() => handleClick('pickup')}
                disabled={!save}
              >
                Pickup
              </Button>
              <Button
                type="button"
                style={{
                  width: '100%',
                  borderRadius: '2px',
                  border: '1px solid black',
                  backgroundColor: selectedButton === 'drop' ? 'blue' : 'white',
                  color: selectedButton === 'drop' ? 'white' : 'black',
                }}
                onClick={() => handleClick('drop')}
                disabled={!save}
              >
                Drop
              </Button>
            </ButtonGroup>
            <div className="mt-3">
              <Input
                id="clientType"
                name="clientType"
                type="text"
                className="form-control"
                onChange={() => {
                  setState({ ...state, latlong: '' });
                }}
                value={state?.latlong}
                autoComplete="off"
                disabled={true}
              />
            </div>
            <div className="d-flex mt-3 cursor-pointer justify-content-center fw-bold">
              <p
                style={{ width: '100%' }}
                className="text-decoration-underline"
                onClick={() => {
                  handleSave(!save);
                }}
              >
                {save ? 'Save' : 'Edit'}
              </p>
              <p style={{ width: '100%', textAlign: 'right' }} className="text-decoration-underline" onClick={() => removeTerminal(index)}>
                Remove
              </p>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default PickupDrop;
