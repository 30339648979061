import React, { useState } from 'react';
import { Accordion, AccordionItem, Col, Collapse, Input, Label, Row, Tooltip, UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';

const BenefitItem = () => {
  const [borderCol1, setborderCol1] = useState(true);
  const [borderCol2, setborderCol2] = useState(false);

  const t_borderCol1 = () => {
    setborderCol1(!borderCol1);
    setborderCol2(false);
  };

  const t_borderCol2 = () => {
    setborderCol2(!borderCol2);
    setborderCol1(false);
  };

  return (
    <Accordion className="custom-accordionwithicon accordion-border-box">
      <AccordionItem>
        <h2 className="accordion-header" id="accordionborderedExample1">
          <button
            className={classnames(
              'accordion-button',
              {
                collapsed: !borderCol1,
              },
              'fs-18 bg-white',
            )}
            type="button"
            onClick={t_borderCol1}
            style={{ cursor: 'pointer' }}
          >
            <img
              src="https://design.thriwe.com/thriwe-one-admin-surge/assets/images/brands/unnamed.png"
              style={{ width: '40px', borderRadius: '6px' }}
              alt=""
              className="img-fluid"
            />
            &nbsp;Zomato -
            <strong>
              {' '}
              3 Months Pro Subscription (Benefit) - <span className="badge bg-secondary"> 1000 Pts </span>
            </strong>
          </button>
        </h2>
        <Collapse isOpen={borderCol1} className="accordion-collapse" id="accor_borderedExamplecollapse1">
          <div className="accordion-body" style={{ background: '#f5f6f9' }}>
            <Row>
              <Col md={12}>
                <h5>Benefit</h5>
                <div>
                  <div className="d-grid col-6 p-0">
                    <button className="btn btn-success btn-lg d-flex align-items-center justify-content-center gap-1">
                      Zomato Pro <i className="ri-checkbox-circle-line fs-20"></i>
                    </button>
                  </div>
                </div>
              </Col>

              <hr style={{ display: 'inline-block', margin: '15px' }} />

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label htmlFor="name" className="form-label d-flex align-items-center gap-1 m-0">
                      Default Selling Price
                      <span to="#" className="fw-medium cursor-pointer" id="default_selling_price">
                        <i className=" ri-information-fill fs-14"></i>
                      </span>
                      <UncontrolledTooltip placement="top" target="default_selling_price">
                        Initial identifier for a membership id
                      </UncontrolledTooltip>
                    </Label>
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      className="form-control"
                      placeholder="Enter Value"
                      disabled={true}
                      // onChange={inputChangeHandler}
                      // value={state?.name}
                      // autoComplete="off"
                      // disabled={props?.title !== `Add Category`}
                    />
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label htmlFor="name" className="form-label d-flex align-items-center gap-1 m-0">
                      Custom Selling Price
                      <span to="#" className="fw-medium cursor-pointer" id="custom_selling_price">
                        <i className=" ri-information-fill fs-14"></i>
                      </span>
                      <UncontrolledTooltip placement="top" target="custom_selling_price">
                        Initial identifier for a membership id
                      </UncontrolledTooltip>
                    </Label>
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      className="form-control"
                      placeholder="Enter Value"
                      // onChange={inputChangeHandler}
                      // value={state?.name}
                      // autoComplete="off"
                      // disabled={props?.title !== `Add Category`}
                    />
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label htmlFor="name" className="form-label d-flex align-items-center gap-1 m-0">
                      Points Worth
                      <span to="#" className="fw-medium cursor-pointer" id="point-worth">
                        <i className=" ri-information-fill fs-14"></i>
                      </span>
                      <UncontrolledTooltip placement="top" target="point-worth">
                        Initial identifier for a membership id
                      </UncontrolledTooltip>
                    </Label>
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      className="form-control"
                      placeholder="Enter Value"
                      // onChange={inputChangeHandler}
                      // value={state?.name}
                      // autoComplete="off"
                      // disabled={props?.title !== `Add Category`}
                    />
                  </div>
                </Col>
              </Row>

              <Col md={12}>
                <h5>Benefit Workflow</h5>
                <Row>
                  <Col md={3}>
                    <select className="form-control form-select">
                      <option value="">1</option>
                      <option value="">2</option>
                      <option value="">3</option>
                    </select>
                  </Col>
                  <Col md={3}>
                    <button className="btn btn-outline-primary">View</button>
                  </Col>
                </Row>
              </Col>

              <hr style={{ display: 'inline-block', margin: '15px' }} />

              <Col md={12}>
                <Row>
                  <Col md={4}>
                    <h5>
                      Display Properties
                      <button type="button" className="btn btn-soft-dark btn-sm mx-1">
                        <i className="ri-settings-4-fill fs-10"></i>
                      </button>
                    </h5>
                  </Col>
                  <Col md={4}>
                    <h5>
                      Coupon Settings
                      <button type="button" className="btn btn-soft-dark btn-sm mx-1">
                        <i className="ri-settings-4-fill fs-10"></i>
                      </button>
                    </h5>
                  </Col>
                  <Col md={4}>
                    <h5>
                      Brand Settings
                      <button type="button" className="btn btn-soft-dark btn-sm mx-1">
                        <i className="ri-settings-4-fill fs-10"></i>
                      </button>
                    </h5>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Collapse>
      </AccordionItem>

      <AccordionItem>
        <h2 className="accordion-header" id="accordionborderedExample2">
          <button
            className={classnames(
              'accordion-button',
              {
                collapsed: !borderCol2,
              },
              'fs-18 bg-white',
            )}
            type="button"
            onClick={t_borderCol2}
            style={{ cursor: 'pointer' }}
          >
            <img
              src="https://design.thriwe.com/thriwe-one-admin-surge/assets/images/brands/unnamed.png"
              style={{ width: '40px', borderRadius: '6px' }}
              alt=""
              className="img-fluid"
            />
            &nbsp;Zomato -
            <strong>
              {' '}
              3 Months Pro Subscription (Benefit) - <span className="badge bg-secondary"> 1000 Pts </span>
            </strong>
          </button>
        </h2>
        <Collapse isOpen={borderCol2} className="accordion-collapse" id="accor_borderedExamplecollapse1">
          <div className="accordion-body" style={{ background: '#f5f6f9' }}>
            <Row>
              <Col md={12}>
                <h5>Benefit</h5>
                <Row>
                  <div className="d-grid col-6 p-0">
                    <button className="btn btn-success btn-lg d-flex align-items-center justify-content-center gap-1">
                      Zomato Pro <i className="ri-checkbox-circle-line fs-20"></i>
                    </button>
                  </div>
                </Row>
              </Col>

              <Col md={12}>
                <hr style={{ display: 'inline-block', margin: '10px' }} />
              </Col>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label htmlFor="name" className="form-label">
                      Default Selling Price
                    </Label>
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      className="form-control"
                      placeholder="Enter Value"
                      // onChange={inputChangeHandler}
                      // value={state?.name}
                      // autoComplete="off"
                      // disabled={props?.title !== `Add Category`}
                    />
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label htmlFor="name" className="form-label">
                      Custom Selling Price
                    </Label>
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      className="form-control"
                      placeholder="Enter Value"
                      // onChange={inputChangeHandler}
                      // value={state?.name}
                      // autoComplete="off"
                      // disabled={props?.title !== `Add Category`}
                    />
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label htmlFor="name" className="form-label">
                      Points Worth
                    </Label>
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      className="form-control"
                      placeholder="Enter Value"
                      // onChange={inputChangeHandler}
                      // value={state?.name}
                      // autoComplete="off"
                      // disabled={props?.title !== `Add Category`}
                    />
                  </div>
                </Col>
              </Row>

              <Col md={12}>
                <h5>Benefit Workflow</h5>
                <Row>
                  <Col md={3}>
                    <select className="form-control form-select">
                      <option value="">1</option>
                      <option value="">2</option>
                      <option value="">3</option>
                    </select>
                  </Col>
                  <Col md={3}>
                    <button className="btn btn-outline-primary">View</button>
                  </Col>
                </Row>
              </Col>

              <Col md={12}>
                <hr style={{ display: 'inline-block', margin: '10px' }} />
              </Col>

              <Col md={12}>
                <Row>
                  <Col md={4}>
                    <h5>
                      Display Properties
                      <button type="button" className="btn btn-soft-dark btn-sm mx-1">
                        <i className="ri-settings-4-fill"></i>
                      </button>
                    </h5>
                  </Col>
                  <Col md={4}>
                    <h5>
                      Coupon Settings
                      <button type="button" className="btn btn-soft-dark btn-sm mx-1">
                        <i className="ri-settings-4-fill"></i>
                      </button>
                    </h5>
                  </Col>
                  <Col md={4}>
                    <h5>
                      Brand Settings
                      <button type="button" className="btn btn-soft-dark btn-sm mx-1">
                        <i className="ri-settings-4-fill"></i>
                      </button>
                    </h5>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Collapse>
      </AccordionItem>
    </Accordion>
  );
};

export default BenefitItem;
