import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, indianProjectsBaseURL, uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { GET_BENEFIT_CATEGORY, GET_BENEFIT_CATEGORY_SUCCESS, GET_BENEFIT_CATEGORY_ERROR } from '../../actionType.js';
import apiCall from '../../apiCall.js';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getBenefitCategory({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/admin/benefit-categories`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload.urlParams,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_BENEFIT_CATEGORY_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_BENEFIT_CATEGORY_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_BENEFIT_CATEGORY_ERROR, payload: error });
  }
}

export function* watchGetBenefitCategory() {
  yield takeEvery(GET_BENEFIT_CATEGORY, getBenefitCategory);
}

function* getBenefitCategorySaga() {
  yield all([fork(watchGetBenefitCategory)]);
}

export default getBenefitCategorySaga;
