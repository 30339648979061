import React, { useEffect, useState } from 'react';

import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { Grid, _ } from 'gridjs-react';
import { Badge, Card, CardBody, CardFooter, Col, Row } from 'reactstrap';

import { Constant } from '../../../../../../Helpers/constant';
import { PreviewCardHeader, TablePagination } from '../../../../../../Common';

const Table = props => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      setData(
        props?.data?.map((data, index) => {
          return [`${props?.dataStartedFrom + (index + 1)}.`, data?.name || `NA`, data?.code || `NA`, data?.client?.name || `NA`, data];
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, details) => {
    if (flag === `MANAGE`) {
      props.actionHandler({
        details: details,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Programs List" />
            <CardBody>
              <div id="table-gridjs">
                <Grid
                  data={data}
                  columns={[
                    {
                      name: 'S.No.',
                      width: '50px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Project Name',
                      width: '140px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Project Code',
                      width: '140px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Client Name',
                      width: '140px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Actions',
                      width: '120px',
                      formatter: (cell, row) =>
                        _(
                          <div className="d-flex justify-content-center flex-column">
                            <>
                              <span
                                className="btn btn-sm btn-primary mb-1"
                                onClick={() => {
                                  actionBtnHandler(`MANAGE`, cell);
                                }}
                              >
                                Manage Data Integration
                              </span>
                            </>
                          </div>,
                        ),
                    },
                  ]}
                  search={false}
                  sort={false}
                  fixedHeader={true}
                  height="400px"
                  pagination={{ enabled: false, limit: 5 }}
                />
              </div>
              {props.children}
            </CardBody>

            <CardFooter>
              <Row className="custom_pagination">
                <Col>
                  <div className="d-flex justify-content-end">
                    <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Table, (prevProps, nextProps) => prevProps.data === nextProps.data);
