import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';

import { Card, CardBody, Col, Container, Form, Label, Row, Input, CardFooter, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import { PreviewCardHeader } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import ReactTooltip from 'react-tooltip';
import { CREATE_GOLF_COURSE_UNAVAILABILITY, GET_GOLF_COURSE } from '../../../../../../../store/application/actionType';

const timeIntervals = [
  {
    id: 1,
    name: 'All',
    time: '00:00 - 23:59',
  },
  {
    id: 2,
    name: 'Morning',
    time: '05:00 - 10:59',
  },
  {
    id: 3,
    name: 'AfterNoon',
    time: '11:00 - 16:59',
  },
  {
    id: 4,
    name: 'Evening',
    time: '17:00 - 23:59',
  },
];

const AddSlotsUnavailbility = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [golfCourses, setGolfCourses] = useState([]);

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    name: '',
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    remarks: '',
    gId: null,
  };
  const [state, setState] = useState(initialState);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Golf Courses
  const { allGolfCoursesData } = useSelector(state => ({
    allGolfCoursesData: state?.GetGolfCourses?.data,
  }));

  useEffect(() => {
    if (allGolfCoursesData?.results?.length > 0) {
      setGolfCourses(
        allGolfCoursesData?.results?.map(facility => {
          return { value: facility?.id, label: facility?.name };
        }),
      );
    }
  }, [allGolfCoursesData]);

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_GOLF_COURSE,
    });
  }, []);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'name':
        updatedState = {
          ...state,
          name: value,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'remarks':
        updatedState = {
          ...state,
          remarks: value,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'startDate':
        updatedState = {
          ...state,
          startDate: new Date(date).toISOString(),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'endDate':
        updatedState = {
          ...state,
          endDate: new Date(date).toISOString(),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  const onSelectInputChange = (data, label) => {
    let updatedState = null;

    switch (label) {
      case 'golfCourse':
        updatedState = {
          ...state,
          gId: data,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const payload = {
      name: state.name,
      startDate: state.startDate,
      endDate: state.endDate,
      remarks: state.remarks,
      gId: state.gId?.value,
      golfCourse: [
        {
          id: state.gId.value,
          name: state.gId.label,
          timeIntervals: timeIntervals,
        },
      ],
    };

    dispatch({
      type: CREATE_GOLF_COURSE_UNAVAILABILITY,
      payload: {
        data: {
          ...payload,
        },
      },
    });
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data.gId !== null && data?.name?.trim() !== ``) {
      setIsSubmitBtnDisabled(false);
    } else {
      setIsSubmitBtnDisabled(true);
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Slots Unavailability" />
            {/* <button onClick={() => console.log(state)}>get state</button> */}
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Name <span className="lbl_mandatory">*</span>
                        </Label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          placeholder="Enter Name"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state?.name}
                        />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="applicable-date" className="form-label">
                          Golf Course
                          <span className="lbl_mandatory">*</span>
                        </Label>
                        <Select options={golfCourses} value={state.gId} isMulti={false} onChange={e => onSelectInputChange(e, `golfCourse`)} />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="start-date1" className="form-label">
                          Applicable From
                        </Label>
                        <div className="area_expandable">
                          <Flatpickr
                            className="form-control"
                            name="start-date"
                            id="start-date"
                            value={state?.startDate}
                            onChange={date => datePickHandler(`startDate`, date)}
                            options={{
                              minDate: 'today',
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i',
                            }}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="endDate1" className="form-label">
                          Applicable To
                        </Label>
                        <div className="area_expandable">
                          <Flatpickr
                            className="form-control"
                            name="endDate"
                            id="endDate"
                            value={state?.endDate}
                            onChange={date => datePickHandler(`endDate`, date)}
                            options={{
                              minDate: 'today',
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i',
                            }}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className="mb-3">
                        <Label htmlFor="remarks1" className="form-label">
                          Remarks
                        </Label>
                        <Input
                          id="remarks"
                          name="remarks"
                          type="textarea"
                          placeholder="Enter Remarks"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state?.remarks}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md={6} className="p-0">
                  <div className="start"></div>
                </Col>

                <Col md={6}>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary mx-3 overlay-disable" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
                      Submit
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>

            <div
              style={{
                width: '100px',
                position: 'relative',
                zIndex: '999999999999',
              }}
            >
              <ReactTooltip />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AddSlotsUnavailbility;
