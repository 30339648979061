import React, { useState } from 'react';
import User from './User';
import CreateUserModal from './CreateUserModal';

const UsersList = ({ onSelectUser, setIsShowEditPermissionScreen }) => {
  const [isCreateUserModalShow, setIsCreateUserModalShow] = useState(false);

  const toggleModal = () => {
    setIsCreateUserModalShow(!isCreateUserModalShow);
  };

  return (
    <>
      {isCreateUserModalShow && <CreateUserModal isOpen={true} modalHandler={toggleModal} />}
      <User createUserModal={toggleModal} onSelectUser={onSelectUser} setIsShowEditPermissionScreen={setIsShowEditPermissionScreen} />
    </>
  );
};

export default UsersList;
