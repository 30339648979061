import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'reactstrap';

import Table from './Components/Tables/Table';
import Filter from './Components/Filters/Filter';
import { BreadCrumb, Loader } from '../../../../../Common';
import { Constant } from '../../../../../Helpers/constant';
import BookingDetails from './Components/Details/Details';
import AcceptBookingModal from './Components/Modals/AcceptBookingModal';
import RejectBookingModal from './Components/Modals/RejectBookingModal';
import CompleteBookingModal from './Components/Modals/CompleteBookingModal';
import {
  VENDOR_BOOKINGS_DATA,
  VENDOR_BOOKINGS_DATA_EMPTY,
  RESEND_MAIL,
  VENDOR_UPDATE_BOOKING,
  VENDOR_UPDATE_BOOKING_EMPTY,
  VENDOR_DOWNLOAD_DUMP,
  VENDOR_DOWNLOAD_DUMP_EMPTY,
} from '../../../../../../store/application/actionType';

const AirportTransferManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [bookingTypeId, setBookingTypeId] = useState(Constant?.BOOKING_TYPES?.pickupAndDropBookings?.objectId);

  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState({
    vendorStatus: Constant?.BOOKING_STATUS?.REQUESTED,
  });
  const [isDetailsPage, setIsDetailsPage] = useState(false);
  const [currentSelectedBookingDetails, setCurrentSelectedBookingDetails] = useState([]);

  const [loading, setLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [currentConfirmationModalActionFor, setCurrentConfirmationModalActionFor] = useState(null);

  const [isAcceptBookingModal, setIsAcceptBookingModal] = useState(false);
  const [isRejectBookingModal, setIsRejectBookingModal] = useState(false);
  const [isBookingCompletedModal, setIsBookingCompletedModal] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Bookings
  const { bookings, bookingsLoading } = useSelector(state => ({
    bookings: state?.GetVendorBookings?.data,
    bookingsLoading: state?.GetVendorBookings?.loading,
  }));

  // Update Booking
  const { updateBookings, updateBookingsLoading } = useSelector(state => ({
    updateBookings: state?.VendorUpdateBooking?.data,
    updateBookingsLoading: state?.VendorUpdateBooking?.loading,
  }));

  // Click Download Dump
  const { downloadDump, downloadDumpLoading } = useSelector(state => ({
    downloadDump: state?.VendorDownloadDump?.data,
    downloadDumpLoading: state?.VendorDownloadDump?.loading,
  }));

  // Resend Mail
  const { resendMail, resendMailLoading } = useSelector(state => ({
    resendMail: state?.ResendMail?.data,
    resendMailLoading: state?.ResendMail?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: VENDOR_BOOKINGS_DATA,
      payload: {
        urlParam: {
          bookingTypeId,
          pageIndex: 0,
          sortOn: 'updatedAt',
        },
        data: {
          vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
          vendorStatus: Constant?.BOOKING_STATUS?.REQUESTED,
        },
      },
    });

    return () => {
      dispatch({
        type: VENDOR_BOOKINGS_DATA_EMPTY,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (updateBookings !== null) {
      dispatch({
        type: VENDOR_UPDATE_BOOKING_EMPTY,
      });

      dispatch({
        type: VENDOR_BOOKINGS_DATA,
        payload: {
          urlParam: {
            bookingTypeId,
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            sortOn: currentlyAppliedFilters?.sortOn ?? 'updatedAt',
          },
          data: {
            ...currentlyAppliedFilters,
            vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
          },
        },
      });
    }
  }, [updateBookings]);

  useEffect(() => {
    if (downloadDump !== null) {
      dispatch({
        type: VENDOR_DOWNLOAD_DUMP_EMPTY,
      });
    }
  }, [downloadDump]);

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = filters => {
    setCurrentPageNumber(0);
    setCurrentPageIndex(0);
    setCurrentlyAppliedFilters(filters);
    dispatch({
      type: VENDOR_BOOKINGS_DATA,
      payload: {
        urlParam: {
          bookingTypeId,
          pageIndex: 0,
          sortOn: filters?.sortOn ?? 'updatedAt',
        },
        data: {
          ...filters,
          vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
        },
      },
    });
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);
    dispatch({
      type: VENDOR_BOOKINGS_DATA,
      payload: {
        urlParam: {
          bookingTypeId,
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          sortOn: currentlyAppliedFilters?.sortOn ?? 'updatedAt',
        },
        data: {
          ...currentlyAppliedFilters,
          vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
        },
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedBookingDetails(params?.bookingDetails);
    setCurrentConfirmationModalActionFor(params?.actionType);
    if (params?.actionType === `ACCEPT`) {
      setIsAcceptBookingModal(true);
    } else if (params?.actionType === `REJECT`) {
      setIsRejectBookingModal(true);
    } else if (params?.actionType === `COMPLETE`) {
      setIsBookingCompletedModal(true);
    }
  };

  /* ---------------------------- CLOSE MODAL HANDLER ---------------------------- */
  const closeModalHandler = (flag, data) => {
    if (flag === `ConfirmationModal`) {
      if (data === `YES`) {
        if (currentConfirmationModalActionFor === `PROCESS`) {
          dispatch({
            type: VENDOR_UPDATE_BOOKING,
            payload: {
              data: {
                bookingTypeId,
                bookingsData: [
                  {
                    // ...currentSelectedBookingDetails,
                    objectId: currentSelectedBookingDetails?.bookingId,
                    detailedBookingId: currentSelectedBookingDetails?.detailedBookingId,
                    status: Constant?.BOOKING_STATUS?.PROCESSED,
                    process: `process-booking`,
                  },
                ],
              },
            },
          });
        } else if (currentConfirmationModalActionFor === `CONFIRM`) {
          dispatch({
            type: VENDOR_UPDATE_BOOKING,
            payload: {
              data: {
                bookingTypeId,
                bookingsData: [
                  {
                    // ...currentSelectedBookingDetails,
                    objectId: currentSelectedBookingDetails?.bookingId,
                    detailedBookingId: currentSelectedBookingDetails?.detailedBookingId,
                    status: Constant?.BOOKING_STATUS?.CONFIRM,
                    process: `confirm-booking`,
                  },
                ],
              },
            },
          });
        } else if (currentConfirmationModalActionFor === `CANCEL`) {
          dispatch({
            type: VENDOR_UPDATE_BOOKING,
            payload: {
              data: {
                bookingTypeId,
                bookingsData: [
                  {
                    // ...currentSelectedBookingDetails,
                    objectId: currentSelectedBookingDetails?.bookingId,
                    detailedBookingId: currentSelectedBookingDetails?.detailedBookingId,
                    status: Constant?.BOOKING_STATUS?.CANCEL_BY_THRIWE,
                    process: `cancel-booking`,
                  },
                ],
              },
            },
          });
        } else if (currentConfirmationModalActionFor === `RESEND_EMAIL`) {
          dispatch({
            type: RESEND_MAIL,
            payload: {
              data: {
                bookingTypeId,
                bookingIds: [currentSelectedBookingDetails?.bookingId],
              },
            },
          });
        }
      }
    }
  };

  /* ---------------------------- DOWNLOAD DUMP HANDLER ---------------------------- */
  const downloadDumpHandler = () => {
    dispatch({
      type: VENDOR_DOWNLOAD_DUMP,
      payload: {
        urlParam: {
          bookingTypeId,
          downloadType: 1,
        },
        data: {
          createdAtRange: {
            from: currentlyAppliedFilters?.createdAtRange?.from,
            to: currentlyAppliedFilters?.createdAtRange?.to,
          },
          vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
        },
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (bookingsLoading || updateBookingsLoading || downloadDumpLoading || resendMailLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [bookingsLoading, updateBookingsLoading, downloadDumpLoading, resendMailLoading]);

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/vendor/dashboard`);
    } else if (title === `Airport Transfer Booking`) {
      setIsDetailsPage(false);
    } else if (title === `Airport Transfer Booking Details`) {
      // do nothing
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ----------------------------  MODAL ---------------------------- */}
          {isAcceptBookingModal && <AcceptBookingModal data={currentSelectedBookingDetails} onCloseHandler={() => setIsAcceptBookingModal(false)} />}
          {isRejectBookingModal && <RejectBookingModal data={currentSelectedBookingDetails} onCloseHandler={() => setIsRejectBookingModal(false)} />}
          {isBookingCompletedModal && (
            <CompleteBookingModal data={currentSelectedBookingDetails} onCloseHandler={() => setIsBookingCompletedModal(false)} />
          )}

          {!isDetailsPage && (
            <>
              <BreadCrumb
                title="Airport Transfer Booking"
                navigationby={breadcrumNavigationHandler}
                navigation={[`Dashboard`, `Airport Transfer Booking`]}
              />
              <Filter filterHandler={filterSubmitHandler} appliedFilters={currentlyAppliedFilters} />

              <Table
                data={bookings?.results}
                totalData={bookings?.count}
                pageNumber={currentPageNumber}
                pageHandler={paginationHandler}
                actionHandler={tableActionHandler}
                dumpDownload={downloadDumpHandler}
                appliedFilters={currentlyAppliedFilters}
              />
            </>
          )}

          {isDetailsPage && (
            <>
              <BreadCrumb
                title="Airport Transfer Booking"
                navigationby={breadcrumNavigationHandler}
                navigation={[`Dashboard`, `Airport Transfer Booking`, `Airport Transfer Details`]}
              />
              <BookingDetails details={currentSelectedBookingDetails} />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default AirportTransferManagement;
