import React, { useEffect, useState } from 'react';
import { PreviewCardHeader } from '../../../../../Common';
import { Button, Card, CardBody, CardHeader, Col, Input, Label, Row, TabPane } from 'reactstrap';
import MobileField from '../../../../../Common/FormControls/MobileField';
import axios from 'axios';
import { indianProjectsBaseURL } from '../../../../../Config';
import { Constant } from '../../../../../Helpers/constant';
import PhoneInput from 'react-phone-input-2';
import { startsWith } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { isValidAplha, isValidEmail } from '../../../../../Helpers/Helpers';
import Disclaimer from '../Modal/Disclaimer';
import { useTranslation } from 'react-i18next';

const initialState = {
  countryCode: '',
  phoneNumber: '',
  email: '',
  name: '',
  binNumber: null,
};

const User = ({ searchUser, submitUser, isUserExist, setIsUserExist, setisUserUpdate, isCreateUser }) => {
  const { t } = useTranslation();

  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);
  const [binOptions, setBinOptions] = useState([]);
  const [phoneValidation, setPhoneValidation] = useState(false);

  const [isDisclaimerShow, setIsDisclaimerShow] = useState(false);

  const { binsList, binsListLoading } = useSelector((state: any) => ({
    binsList: state.GetBinsList.data?.results,
    binsListLoading: state.GetBinsList.loading,
  }));

  useEffect(() => {
    if (isUserExist && isUserExist?.bins && isUserExist?.bins?.length > 0) {
      setState({
        ...state,
        binNumber: { label: isUserExist?.bins?.[0]?.name, value: isUserExist?.bins?.[0]?.name, data: isUserExist?.bins[0] },
        name: `${isUserExist?.firstName ?? ''} ${isUserExist?.lastName ?? ''}`,
        email: isUserExist?.email,
      });
    }
  }, [isUserExist]);

  /* ---------------------------- INPUT MOBILE HANDLER ---------------------------- */
  const mobileChangeHandler = async (value, data) => {
    let updatedState = {
      ...state,
      countryCode: data?.dialCode,
      phoneNumber: value.slice(data?.dialCode?.length),
    };
    if (phoneValidation && updatedState?.countryCode && updatedState?.phoneNumber) {
      setPhoneValidation(false);
    }
    if (state?.email || state.name || state?.binNumber) {
      setState({
        email: '',
        binNumber: null,
        name: '',
        countryCode: data?.dialCode,
        phoneNumber: value.slice(data?.dialCode?.length),
      });
      setIsUserExist(null);
      setisUserUpdate(false);
    } else {
      setState(updatedState);
    }
    validateErrors(updatedState, `validationCheck`);
  };

  useEffect(() => {
    if (binsList != null) {
      setBinOptions(
        binsList?.map(bin => {
          return {
            value: bin?.binId,
            label: bin?.name,
            data: bin,
          };
        }),
      );
    }
  }, [binsList]);

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (
      data?.name?.trim() !== `` &&
      data?.countryCode?.trim() !== `` &&
      isValidEmail(data?.email?.trim()) &&
      data?.name?.trim() !== `` &&
      data?.phoneNumber?.trim() !== `` &&
      data?.binNumber !== null
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'name':
        updatedState = {
          ...state,
          name: isValidAplha(value) ? value : state?.name,
        };
        setState(updatedState);
        break;

      case 'email':
        updatedState = {
          ...state,
          email: value,
        };
        setState(updatedState);
        break;

      case 'phoneNumber':
        updatedState = {
          ...state,
          phoneNumber: value,
        };
        setState(updatedState);
        break;
      default:
    }

    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label: string, data) => {
    let updatedState = null;
    if (label === `binNumber`) {
      updatedState = {
        ...state,
        binNumber: data,
      };
      setState(updatedState);
    }
    validateErrors(updatedState, `validationCheck`);
  };

  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      setIsDisclaimerShow(true);
      // submitUser(state);
    }
  };

  const searchHandler = () => {
    if (state.phoneNumber && state.countryCode) {
      searchUser(state.phoneNumber, state.countryCode);
    }
  };

  const onDisclaimerCloseHandler = () => {
    setIsDisclaimerShow(false);
    submitUser(state);
  };

  return (
    <>
      <TabPane tabId={1}>
        {isDisclaimerShow && <Disclaimer onClose={onDisclaimerCloseHandler} />}
        <div>
          <Row>
            <Col xxl={12}>
              {/* <PreviewCardHeader title="Personal Details" /> */}
              <CardHeader className="d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Personal Details </h4>
              </CardHeader>
              <CardBody>
                <Row className="mt-3">
                  <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                    <div className="mb-3">
                      <Label htmlFor="name" className="form-label">
                        Phone Number
                        <span className="lbl_mandatory"> *</span>
                      </Label>
                      <div className="input-group" style={{ alignItems: 'center' }}>
                        <Col xl={8} lg={8} xs={7}>
                          <MobileField
                            onChange={mobileChangeHandler}
                            value={`${state?.countryCode}${state?.phoneNumber}`}
                            preferredCountries={['sa', 'uae', 'in']}
                            defaultCountry={'sa'}
                            inputHandler={searchHandler}
                          />
                          {(isValidationShow && state?.phoneNumber === '') ||
                            (phoneValidation && <div className="text-danger">Please enter phone number</div>)}
                        </Col>
                        <Col xl={4} lg={2} xs={1}>
                          <span className="input-group-text cursor-pointer btn btn-primary" onClick={searchHandler}>
                            {t(`Show Details`)}
                          </span>
                        </Col>
                      </div>
                    </div>
                  </Col>
                  <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                    <div className="mb-3">
                      <Label htmlFor="name" className="form-label">
                        Full Name
                        <span className="lbl_mandatory"> *</span>
                      </Label>
                      <Input
                        type="text"
                        name="name"
                        placeholder=""
                        value={state?.name}
                        onChange={inputChangeHandler}
                        disabled={isCreateUser ? true : isUserExist ? true : false}
                      />
                      {isValidationShow && state?.name?.trim() === '' && <div className="text-danger">Please enter name</div>}
                    </div>
                  </Col>
                  <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                    <div className="mb-3">
                      <Label htmlFor="name" className="form-label">
                        Email
                        <span className="lbl_mandatory"> *</span>
                      </Label>
                      <Input
                        type="text"
                        name="email"
                        placeholder=""
                        value={state?.email}
                        onChange={inputChangeHandler}
                        disabled={isCreateUser ? true : isUserExist ? true : false}
                      />
                      {isValidationShow && state?.email?.trim() === '' && <div className="text-danger">Please enter email</div>}
                      {isValidationShow && state?.email?.trim() !== '' && !isValidEmail(state?.email?.trim()) && (
                        <div className="text-danger">Please enter correct email</div>
                      )}
                    </div>
                  </Col>
                  <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                    <div className="mb-3">
                      <Label htmlFor="name" className="form-label">
                        Bin Number
                        <span className="lbl_mandatory"> *</span>
                      </Label>
                      <Select
                        id="currency"
                        name="currency"
                        value={state?.binNumber}
                        options={binOptions}
                        onChange={data => inputSelectHandler(`binNumber`, data)}
                        isDisabled={isCreateUser ? true : isUserExist ? true : false}
                      />
                      {isValidationShow && state?.binNumber === null && <div className="text-danger">Please select bin number</div>}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Col>
          </Row>
        </div>
        <div className="mb-3"></div>
        <div className="d-flex align-items-start gap-3 mt-4">
          <Button type="button" className="btn btn-success btn-label right ms-auto nexttab nexttab" onClick={submitHandler} disabled={isCreateUser}>
            Continue <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
          </Button>
        </div>
      </TabPane>
    </>
  );
};

export default User;
