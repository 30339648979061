import React, { useEffect, useState } from 'react';
import { BreadCrumb, PreviewCardHeader } from '../../../../../../Common';
import { Card, CardBody, CardFooter, Col, Container, Label, Row } from 'reactstrap';
import { AsyncPaginate } from 'react-select-async-paginate';
import axios from 'axios';
import { indianProjectsBaseURL, customHeaders, uaeProjectsBaseURL } from '../../../../../../Config';
import { Constant } from '../../../../../../Helpers/constant';
import { TbCirclePlus } from 'react-icons/tb';

interface InitialValues {
  benefit: {
    label?: string;
    value?: string;
  };
  vendor: {
    label?: string;
    value?: string;
  };
  airport: {
    label?: string;
    value?: string;
  };
  carType: [];
}

interface Errors {
  benefit?: string;
  vendor?: string;
  carType?: string;
  airport?: string;
}

const AirportTransferSetting = props => {
  const initialValues = {
    benefit: {},
    vendor: {},
    airport: {},
    carType: null,
  };

  const [errors, setErrors] = useState<any>({
    benefit: '',
    vendor: '',
    airport: '',
    carType: '',
  });
  const [state, setState] = useState<InitialValues>(initialValues);

  const validateForm = () => {
    const errors: Errors = {};

    // Validate Benefit
    if (!state.benefit.value) {
      errors.benefit = 'Benefit is required';
    }

    // Validate Vendor
    if (!state.vendor.value) {
      errors.vendor = 'Vendor is required';
    }

    // Validate Car Type
    if (!state.carType || !state.carType.length) {
      errors.carType = 'Car Type is required';
    }

    // Validate Airport
    if (!state.airport.value) {
      errors.airport = 'Airport is required';
    }

    setErrors(errors);
    // Return true if no errors, false otherwise
    return Object.keys(errors).length === 0;
  };

  const submitHandler = () => {
    if (validateForm()) {
      props?.submitHandler();
    }
  };

  async function loadBenefits(search) {
    let urlParam = null;
    return await axios({
      method: `GET`,
      url: `${uaeProjectsBaseURL}/parse/classes/services`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        'x-parse-application-Id': 'STAG_APPLICATION_ID',
        'x-parse-master-key': 'STAG_MASTER_KEY',
        'x-auth-server': 1,
      },
      params: {
        ...urlParam,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data?.objectId,
              label: `${data?.displayName}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  async function loadAirports(search) {
    let urlParam = null;
    return await axios({
      method: `GET`,
      url: `${uaeProjectsBaseURL}/parse/classes/airports`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        'x-parse-application-Id': 'STAG_APPLICATION_ID',
        'x-parse-master-key': 'STAG_MASTER_KEY',
        'x-auth-server': 1,
      },
      params: {
        ...urlParam,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data?.objectId,
              label: `${data?.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  async function loadVendor(search) {
    let urlParam = null;
    return await axios({
      method: `GET`,
      url: `${uaeProjectsBaseURL}/parse/classes/services`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        'x-parse-application-Id': 'STAG_APPLICATION_ID',
        'x-parse-master-key': 'STAG_MASTER_KEY',
        'x-auth-server': 1,
      },
      params: {
        ...urlParam,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data?.objectId,
              label: `${data?.displayName}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  async function loadCarType(search) {
    let urlParam = null;
    return await axios({
      method: `GET`,
      url: `${uaeProjectsBaseURL}/parse/classes/carTypes`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        'x-parse-application-Id': 'STAG_APPLICATION_ID',
        'x-parse-master-key': 'STAG_MASTER_KEY',
        'x-auth-server': 1,
      },
      params: {
        ...urlParam,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data?.objectId,
              label: `${data?.carType}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  const onSelectVendor = selectedOption => {
    setState({ ...state, vendor: selectedOption });
    setErrors({ ...errors, vendor: '' });
  };

  const onSelectBenefit = selectedOption => {
    setState({ ...state, benefit: selectedOption });
    setErrors({ ...errors, benefit: '' });
  };

  const onSelectAirport = selectedOption => {
    setState({ ...state, airport: selectedOption });
    setErrors({ ...errors, airport: '' });
  };

  const onSelectCarType = selectedOption => {
    setState({ ...state, carType: selectedOption });
    setErrors({ ...errors, carType: '' });
  };
  return (
    <>
      <BreadCrumb title="Airport Transfer" navigationby={''} navigation={[`Dashboard`, `Airport Transfer`]} />
      <Container fluid className="position-relative">
        <Row>
          <Col xxl={12}>
            <Card>
              <PreviewCardHeader title={'Airport Transfer Setting'} />
              <CardBody>
                <div className="live-preview">
                  <Row>
                    <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div className="d-flex justify-content-between">
                        <Label htmlFor="choices-multiple-default" className="form-label">
                          Choose Benefit
                          <span className="lbl_mandatory">*</span>
                        </Label>
                      </div>
                      <AsyncPaginate
                        debounceTimeout={500}
                        value={state.benefit}
                        loadOptions={loadBenefits}
                        onChange={selectedOption => onSelectBenefit(selectedOption)}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        noOptionsMessage={() => 'No results found'}
                        cacheUniqs={[['code']]}
                        placeholder="Select..."
                      />
                      {errors.benefit && <div className="text-danger">{errors.benefit}</div>}
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div className="d-flex justify-content-between">
                        <Label htmlFor="choices-multiple-default" className="form-label">
                          Choose Vendor
                          <span className="lbl_mandatory">*</span>
                        </Label>
                      </div>
                      <AsyncPaginate
                        debounceTimeout={500}
                        value={state.vendor}
                        loadOptions={loadVendor}
                        onChange={selectedOption => onSelectVendor(selectedOption)}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        noOptionsMessage={() => 'No results found'}
                        cacheUniqs={[['code']]}
                        placeholder="Select..."
                      />
                      {errors.vendor && <div className="text-danger">{errors.vendor}</div>}
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div className="d-flex justify-content-between">
                        <Label htmlFor="choices-multiple-default" className="form-label">
                          Choose Car Type
                          <span className="lbl_mandatory">*</span>
                        </Label>
                      </div>
                      <AsyncPaginate
                        debounceTimeout={500}
                        value={state.carType}
                        loadOptions={loadCarType}
                        onChange={selectedOption => onSelectCarType(selectedOption)}
                        isMulti={true}
                        closeMenuOnSelect={true}
                        noOptionsMessage={() => 'No results found'}
                        cacheUniqs={[['code']]}
                        placeholder="Select..."
                      />
                      {errors.carType && <div className="text-danger">{errors.carType}</div>}
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div className="d-flex justify-content-between">
                        <Label htmlFor="choices-multiple-default" className="form-label">
                          Choose Airport
                          <span className="lbl_mandatory">*</span>
                        </Label>
                      </div>
                      <AsyncPaginate
                        debounceTimeout={500}
                        value={state.airport}
                        loadOptions={loadAirports}
                        onChange={selectedOption => onSelectAirport(selectedOption)}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        noOptionsMessage={() => 'No results found'}
                        cacheUniqs={[['code']]}
                        placeholder="Select..."
                      />
                      {errors.airport && <div className="text-danger">{errors.airport}</div>}
                    </Col>
                  </Row>
                </div>
              </CardBody>
              <CardFooter>
                <Col md={12}>
                  <div className="text-center">
                    <button type="submit" className="btn btn-primary text-center" onClick={submitHandler}>
                      Next
                    </button>
                  </div>
                </Col>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AirportTransferSetting;
