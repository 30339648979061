import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../../Containers/Config/index.js';
import { DELETE_EXPENDITURE_RULE, DELETE_EXPENDITURE_RULE_SUCCESS, DELETE_EXPENDITURE_RULE_ERROR } from '../../../actionType';
import apiCall from '../../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../../Containers/Helpers/constant.js';

function* deleteExpenditureRule({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/rules`,
      method: 'DELETE',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      data: payload.data,
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({
        type: DELETE_EXPENDITURE_RULE_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: DELETE_EXPENDITURE_RULE_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: DELETE_EXPENDITURE_RULE_ERROR, payload: error });
  }
}

export function* watchDeleteExpenditureRule() {
  yield takeEvery(DELETE_EXPENDITURE_RULE, deleteExpenditureRule);
}

function* deleteExpenditureRuleSaga() {
  yield all([fork(watchDeleteExpenditureRule)]);
}

export default deleteExpenditureRuleSaga;
