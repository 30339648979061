import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from '../../../../Common';
import { Container } from 'reactstrap';

import Table from './Components/Tables/Table';
import { PAYMENT_LOGS, PAYMENT_LOGS_EMPTY } from '../../../../../store/application/actionType';
import { Constant } from '../../../../Helpers/constant';
import Filter from './Components/Filters/Filters';
import CustomFilter from './Components/Filters/CustomFilter';

const PaymentLogs = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [isShowFilters, setIsShowFilters] = useState(false);

  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Payments Report
  const { paymentLogs, paymentLogsLoading } = useSelector(state => ({
    paymentLogs: state?.PaymentLogs?.data,
    paymentLogsLoading: state?.PaymentLogs?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  // useEffect(() => {
  //   if (paymentLogs === null) {
  //     dispatch({
  //       type: PAYMENT_LOGS,
  //       payload: {
  //         urlParam: {
  //           pageIndex: 0,
  //         },
  //       },
  //     })
  //   }
  // }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch({
        type: PAYMENT_LOGS_EMPTY,
      });
    };
  }, [dispatch]);

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = filters => {
    setIsShowFilters(false);
    setCurrentPageNumber(0);
    setCurrentPageIndex(0);
    setCurrentlyAppliedFilters(filters);

    dispatch({
      type: PAYMENT_LOGS,
      payload: {
        urlParam: {
          paymentId: filters?.paymentId === `` ? null : filters?.paymentId,

          projectCode: filters?.projectCode !== `` ? filters?.projectCode?.completeData?.code : ``,

          pageIndex: 0,
        },
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    // if (params?.actionType === `DOWNLOAD`) {
    //   setCurrentSelectedDetails(params?.downloadDetails)
    //   window.open(params?.downloadDetails?.file, `_blank`)
    // }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);

    dispatch({
      type: PAYMENT_LOGS,
      payload: {
        urlParam: {
          paymentId: currentlyAppliedFilters?.paymentId === `` ? null : currentlyAppliedFilters?.paymentId,

          projectCode: currentlyAppliedFilters?.projectCode !== `` ? currentlyAppliedFilters?.projectCode?.completeData?.code : ``,
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  /* ---------------------------- REFRESH HANDLER ---------------------------- */
  const refreshHandler = () => {
    dispatch({
      type: PAYMENT_LOGS,
      payload: {
        urlParam: {
          paymentId: currentlyAppliedFilters?.paymentId === `` ? null : currentlyAppliedFilters?.paymentId,
          projectCode: currentlyAppliedFilters?.projectCode !== `` ? currentlyAppliedFilters?.projectCode?.completeData?.code : ``,
          pageIndex: currentPageIndex,
        },
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (paymentLogsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [paymentLogsLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Payments Report`) {
      // do nothing
    }
  };

  return (
    <>
      {loading && <Loader />}
      {/* <button onClick={() => console.log(currentlyAppliedFilters)}>
        get State
      </button> */}

      <div className="page-content">
        <Container fluid className="position-relative">
          <BreadCrumb title="Payments Report" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Payments Report`]} />

          {/* <Filter filterHandler={filterSubmitHandler} appliedFilters={currentlyAppliedFilters} /> */}

          <CustomFilter
            filterHandler={filterSubmitHandler}
            appliedFilters={currentlyAppliedFilters}
            show={isShowFilters}
            onCloseClick={() => setIsShowFilters(false)}
          />

          <Table
            data={paymentLogs?.results}
            totalData={paymentLogs?.count}
            pageNumber={currentPageNumber}
            dataStartedFrom={currentPageIndex}
            pageHandler={paginationHandler}
            actionHandler={tableActionHandler}
            refreshHandler={refreshHandler}
            isShowFilterBtn={true}
            filterBtnHandler={() => {
              setIsShowFilters(true);
            }}
          />
        </Container>
      </div>
    </>
  );
};

export default PaymentLogs;
