import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';

const CardBox = ({ heading, count, backgroundColor }) => {
  return (
    // <Col md={3} className="my-1">
    <Card>
      <CardBody className="" style={{ backgroundColor, borderRadius: '6px' }}>
        <h5 className="text-light">{heading}</h5>
        <h2 className="text-light">{count}</h2>
      </CardBody>
    </Card>
    // </Col>
  );
};

export default CardBox;
