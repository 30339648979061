import { MAIN_AREA_LOADER_SHOW, MAIN_AREA_LOADER_HIDE } from './../../actionType.js';

const initialState = {
  loading: false,
};

const MainAreaLoader = (state = initialState, action) => {
  switch (action.type) {
    case MAIN_AREA_LOADER_SHOW:
      state = {
        loading: true,
      };
      break;

    case MAIN_AREA_LOADER_HIDE:
      state = {
        loading: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default MainAreaLoader;
