import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader, Col, Label, Input, Row } from 'reactstrap';
import { AiOutlinePlusCircle } from 'react-icons/ai';

const PickupModal = ({ isOpen, toggle, airportAndAddress, handlePickup, handleOpenAddress, label }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} id="pick-up-modal" scrollable={true} centered>
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          {label === 'dropAirport' ? 'Drop Airport' : ''}
          {label === 'dropLocation' ? 'Drop Location' : ''}
          {label === 'pickupLocation' ? 'Pick Up Location' : ''}
          {label === 'pickUpAirport' ? 'Pick Up Airport' : ''}
          {label === 'airportName' ? 'Airport' : ''}
        </h5>
        <Button type="button" onClick={toggle} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close"></Button>
      </div>

      <ModalBody>
        <form>
          <Row>
            <Col md={12} className="mb-3">
              {airportAndAddress?.list?.length > 0 &&
                airportAndAddress?.list?.map((item, index: number) => (
                  <div key={index} className="form-check form-radio-primary mb-3">
                    <Input
                      className="form-check-input"
                      type="radio"
                      name="pickUpLocation"
                      id={`radio${index}`}
                      onChange={() => handlePickup(label, item)}
                      checked={item?.value === airportAndAddress?.selectedValue?.value}
                    />
                    <Label className="form-check-label" htmlFor={`radio${index}`}>
                      {item?.label}
                    </Label>
                  </div>
                ))}
            </Col>
          </Row>
          {airportAndAddress?.isAddress && (
            <div className="d-flex gap-2" style={{ cursor: 'pointer', alignItems: 'center' }} onClick={handleOpenAddress}>
              <AiOutlinePlusCircle size={30} />
              <div>
                {' '}
                <span className="primary">Add New Address</span>{' '}
              </div>
            </div>
          )}
        </form>
      </ModalBody>
    </Modal>
  );
};

export default PickupModal;
