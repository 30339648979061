import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { Constant } from '../../../../../../Helpers/constant';

import { Button, Modal, ModalBody, ModalHeader, Col, Label, Input, Row } from 'reactstrap';

import { UPDATE_BOOKING } from '../../../../../../../store/application/actionType';

const ConfirmBookingEnbd = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    trackingCode: '',
    referenceNumber: '',
    remarks: '',
    confirmPickupLocation: {
      airportId: '',
      nickName: '',
      name: '',
      address: '',
      emirates: '',
      region: '',
      regionId: '',
      zipCode: '',
      latitude: '',
      longitude: '',
    },
    driver: {
      name: '',
      mobile: '',
      pickupDate: '',
      pickupTime: '',
    },
    dateTimeOfService: {
      date: '',
      time: '',
    },
    // bookingDateTime: '',
  };
  const [state, setState] = useState(initialObj);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    setState({
      ...data,
      confirmPickupLocation: data?.pickupLocation,
      dateTimeOfService: {
        date: data?.dateTimeOfService?.date,
        time: data?.dateTimeOfService?.time,
      },
      // bookingDateTime: data?.bookingDateTime?.replace('00Z', '00.000Z'),
    });
    validationCheck({
      ...data,
      confirmPickupLocation: data?.pickupLocation,
      dateTimeOfService: {
        date: data?.dateTimeOfService?.date,
        time: data?.dateTimeOfService?.time,
      },
      // bookingDateTime: data?.bookingDateTime?.replace('00Z', '00.000Z'),
    });
  }, [data]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'confirm-pickup-emirates':
        updatedState = {
          ...state,
          confirmPickupLocation: {
            ...state.confirmPickupLocation,
            emirates: value,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'confirm-pickup-address':
        updatedState = {
          ...state,
          confirmPickupLocation: {
            ...state.confirmPickupLocation,
            address: value,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'driverName':
        updatedState = {
          ...state,
          driver: {
            ...state.driver,
            name: value,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'pickupTime':
        updatedState = {
          ...state,
          driver: {
            ...state.driver,
            pickupTime: value,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'driverMobile':
        updatedState = {
          ...state,
          driver: {
            ...state.driver,
            mobile: value,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'confirm-pickup-region':
        updatedState = {
          ...state,
          confirmPickupLocation: {
            ...state.confirmPickupLocation,
            region: value,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'referenceNumber':
        updatedState = {
          ...state,
          referenceNumber: value,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'trackingCode':
        updatedState = {
          ...state,
          trackingCode: value,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'remarks':
        updatedState = {
          ...state,
          remarks: value,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'serviceTime':
        updatedState = {
          ...state,
          dateTimeOfService: {
            ...state?.dateTimeOfService,
            time: value,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'serviceDate':
        updatedState = {
          ...state,
          dateTimeOfService: {
            ...state?.dateTimeOfService,
            date: moment(new Date(date)).format('Y-MM-DD'),
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      // case 'pickupDate':
      //   updatedState = {
      //     ...state,
      //     driver: {
      //       ...state.driver,
      //       pickupDate: new Date(date).toISOString(),
      //     },
      //   }
      //   setState(updatedState)
      //   validationCheck(updatedState)
      //   break

      default:
      // do nothing
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    dispatch({
      type: UPDATE_BOOKING,
      payload: {
        data: {
          projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
          bookingTypeId: Constant?.BOOKING_TYPES?.localCourierBookings?.objectId,
          bookingsData: [
            {
              // ...state,
              trackingCode: state?.trackingCode,
              referenceNumber: state?.referenceNumber,
              remarks: state?.remarks,
              confirmPickupLocation: state?.confirmPickupLocation,
              driver: state?.driver,
              dateTimeOfService: {
                date: state?.dateTimeOfService?.date,
                time: state?.dateTimeOfService?.time,
              },
              objectId: data?.bookingId,
              detailedBookingId: data?.detailedBookingId,
              // bookingDateTime: state.bookingDateTime,
              status: Constant?.BOOKING_STATUS?.CONFIRM,
              process: `confirm-booking`,
            },
          ],
        },
      },
    });

    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (
      data?.confirmPickupLocation?.emirates !== `` &&
      data?.confirmPickupLocation?.address !== `` &&
      data?.confirmPickupLocation?.region !== `` &&
      // data?.bookingDateAndTime !== `` &&
      data?.trackingCode !== `` &&
      data?.driver?.name !== `` &&
      data?.driver?.mobile !== `` &&
      // data?.driver?.pickupDate !== `` &&
      // data?.driver?.pickupTime !== `` &&
      data?.referenceNumber !== `` &&
      data?.remarks !== `` &&
      data?.dateTimeOfService?.date !== `` &&
      data?.dateTimeOfService?.time !== ``
    ) {
      setIsSubmitBtnDisabled(false);
    } else {
      setIsSubmitBtnDisabled(true);
    }
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
      scrollable={true}
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Confirm Booking
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log({ state })}>Get state</button> */}
        <form>
          <Row>
            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="name" className="form-label">
                    Booking ID
                  </Label>
                  <Input className="form-control" value={data?.bookingId} disabled />
                </div>
              </Col>
            </div>
            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="name" className="form-label">
                    User Name
                  </Label>
                  <Input className="form-control" value={data?.user?.name} disabled />
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="driverName" className="form-label">
                    Picker Name
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="driverName"
                    name="driverName"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.driver?.name}
                  />
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="driverMobile" className="form-label">
                    Picker Mobile
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="driverMobile"
                    name="driverMobile"
                    type="number"
                    onWheel={e => e.target.blur()}
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.driver?.mobile}
                  />
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="coupon-remarks" className="form-label">
                    Pickup Date <span className="lbl_mandatory">*</span>
                  </Label>
                  <Flatpickr
                    className="form-control"
                    name="serviceDate"
                    id="serviceDate"
                    value={state?.dateTimeOfService?.date}
                    onChange={date => datePickHandler(`serviceDate`, date)}
                    options={{
                      minDate: 'today',
                      enableTime: false,
                      dateFormat: 'Y-m-d',
                    }}
                  />
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="coupon-remarks" className="form-label">
                    Pickup Time <span className="lbl_mandatory">*</span>
                  </Label>
                  <input
                    type="time"
                    className="form-control"
                    id="serviceTime"
                    name="serviceTime"
                    onChange={inputChangeHandler}
                    value={state?.dateTimeOfService?.time}
                  />
                </div>
              </Col>
            </div>

            {/* <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="booking-date-time" className="form-label">
                    Pickup Date
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Flatpickr
                    className="form-control"
                    name="booking-date-time"
                    id="booking-date-time"
                    value={state.driver?.pickupDate}
                    onChange={(date) => datePickHandler(`pickupDate`, date)}
                    options={{
                      minDate: 'today',
                      enableTime: false,
                      dateFormat: 'Y-m-d ',
                    }}
                  />
                </div>
              </Col>
            </div> */}

            {/* <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="pickupTime" className="form-label">
                    Pickup Time <span className="lbl_mandatory">*</span>
                  </Label>
                  <div className="area_expandable">
                    <Input
                      value={state?.driver?.pickupTime}
                      className="form-control"
                      name="pickupTime"
                      type="time"
                      onChange={inputChangeHandler}
                    />
                  </div>
                </div>
              </Col>
            </div> */}

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="confirm-pickup-address" className="form-label">
                    Conifrm Pickup Address
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="confirm-pickup-address"
                    name="confirm-pickup-address"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.confirmPickupLocation?.address}
                  />
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="confirm-pickup-emirates" className="form-label">
                    Confirm Pickup emirates
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="confirm-pickup-emirates"
                    name="confirm-pickup-emirates"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.confirmPickupLocation?.emirates}
                    disabled={true}
                  />
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="confirm-pickup-region" className="form-label">
                    Confirm Pickup Region
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="confirm-pickup-region"
                    name="confirm-pickup-region"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.confirmPickupLocation?.region}
                    disabled={true}
                  />
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="referenceNumber" className="form-label">
                    Reference No.
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="referenceNumber"
                    name="referenceNumber"
                    type="number"
                    onWheel={e => e.target.blur()}
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.referenceNumber}
                  />
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="trackingCode" className="form-label">
                    Tracking Code
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="trackingCode"
                    name="trackingCode"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.trackingCode}
                  />
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="remarks" className="form-label">
                    Remarks
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="remarks"
                    name="remarks"
                    type="textarea"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.remarks}
                  />
                </div>
              </Col>
            </div>
          </Row>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmBookingEnbd;
