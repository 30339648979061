import { useEffect, useState } from 'react';

import CustomersManagement from './CustomerManagement';
import UserManagement from './UserManagement';

const CustomerManagement = () => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [isUMSEnabled, setIsUMSEnabled] = useState(false);
  const [isUMSDisabled, setIsUMSDisabled] = useState(false);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('data'))?.program?.configuration?.ums?.isEnabled) {
      setIsUMSEnabled(true);
      setIsUMSDisabled(false);
    } else {
      setIsUMSEnabled(false);
      setIsUMSDisabled(true);
    }
  }, []);

  return (
    <>
      {isUMSDisabled && <CustomersManagement />}
      {isUMSEnabled && <UserManagement />}
    </>
  );
};

export default CustomerManagement;
