import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { Card, CardBody, Col, Row, Container, Label } from 'reactstrap';
import { Constant } from '../../../../../../Helpers/constant';

const BookingDetails = ({ details }) => {
  const [bookingDetails, setBookingDetails] = useState([]);

  useEffect(() => {
    setBookingDetails(details);
  }, [details]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Container fluid>
      {/* <button onClick={() => console.log({ details })}>Get state</button> */}
      <Row>
        <Col lg={12}>
          <Card className="mt-n4 mx-n4 mb-n5">
            <div className="bg-soft-warning">
              <CardBody className="pb-4 mb-5">
                <Row>
                  <div className="col-md">
                    <Row className="align-items-center">
                      <div className="col-md-auto mb-1 mt-4"></div>
                      <div className="col-md">
                        <h4 className="fw-semibold" id="ticket-title">
                          {details?.bookingId || ''}
                        </h4>
                      </div>
                    </Row>
                  </div>
                </Row>
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Personal Details</h6>
              <div className="border mt-2 mb-3 border"></div>
              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Full Name'}</Label>
                    <Label className="text-muted">{details?.user?.name || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">User Contact Details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Mobile No.'}</Label>
                    <Label className="text-muted">{details?.user?.mobile || `NA`}</Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Email'}</Label>
                    <Label className="text-muted">{details?.user?.email || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Dates</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Membership Id'}</Label>
                    <Label className="text-muted">
                      {details?.membershipId || `NA`}
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row> */}

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Payment details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Order Id'}</Label>
                    <Label className="text-muted">{details?.payment?.orderId || `NA`}</Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Payment Id'}</Label>
                    <Label className="text-muted">{details?.payment?.paymentId || `NA`}</Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Invoice No.'}</Label>
                    <Label className="text-muted">{details?.payment?.invoiceNumber || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Amount</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Rack Rate'}</Label>
                    <Label className="text-muted">{details?.payment?.rackRate || `NA`}</Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Total Amount Paid'}</Label>
                    <Label className="text-muted">{details?.payment?.totalAmountPaid || `NA`}</Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Processing Fee'}</Label>
                    <Label className="text-muted">{details?.payment?.processingFee || `NA`}</Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Taxes'}</Label>
                    <Label className="text-muted">{details?.payment?.taxes || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Location & Golf Course</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Membership Id'}</Label>
                    <Label className="text-muted">{details?.facility || `NA`}</Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Address'}</Label>
                    <Label className="text-muted">{details?.address || `NA`}</Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'City'}</Label>
                    <Label className="text-muted">{details?.subregion || `NA`}</Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'State'}</Label>
                    <Label className="text-muted">{details?.region || `NA`}</Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Country'}</Label>
                    <Label className="text-muted">{details?.country || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Booking</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Booking Date'}</Label>
                    <Label className="text-muted">{moment(`${new Date(`${details?.bookingDateTime}`)}`).format('DD-MM-YYYY, h:mm A') || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Guest Details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Membership Id'}</Label>
                    <Label className="text-muted">
                      {details?.membershipId || `NA`}
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row> */}

      {/* <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Card Details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Membership Id'}</Label>
                    <Label className="text-muted">
                      {details?.membershipId || `NA`}
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row> */}

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Remarks</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Remarks'}</Label>
                    <Label className="text-muted">{details?.remarks || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Updates</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Membership Id'}</Label>
                    <Label className="text-muted">{moment(`${details?.updatedAt}`).format('DD-MM-YYYY, h:mm A') || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BookingDetails;
