import { useEffect, useState } from 'react';

import { Card, CardBody, Col, Row, Container, Label } from 'reactstrap';

const Details = ({ details }) => {
  const [completeDetails, setCompleteDetails] = useState([]);

  useEffect(() => {
    setCompleteDetails(details);
  }, [details]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: `smooth` });
  }, []);

  return (
    <>
      {/* <button onClick={() => console.log({ details })}>Get state</button> */}
      <Row>
        <Col lg={12}>
          <Card className="mt-n4 mx-n4 mb-n5">
            <div className="bg-soft-warning">
              <CardBody className="pb-4 mb-5">
                <Row>
                  <div className="col-md">
                    <Row className="align-items-center">
                      <div className="col-md-auto mb-1 mt-4"></div>
                      <div className="col-md">
                        <h4 className="fw-semibold" id="ticket-title">
                          {details?.objectId || ''}
                        </h4>
                      </div>
                    </Row>
                  </div>
                </Row>
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Details</h6>
              <div className="border mt-2 mb-3 border"></div>
              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Name'}</Label>
                    <Label className="text-muted">{details?.name || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Phone'}</Label>
                    <Label className="text-muted">{details?.phone || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Partner Name'}</Label>
                    <Label className="text-muted">{details?.partner?.name || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Facility Required'}</Label>
                    <Label className="text-muted">{details?.facilityRequired ? `Yes` : `No` || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Redemption Process'}</Label>
                    <Label className="text-muted">{details?.redemptionProcess || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Redemption Type'}</Label>
                    <Label className="text-muted">{details?.redemptionType?.name || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Benefit Offered'}</Label>
                    <Label className="text-muted">{details?.benefitOffered || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Address</h6>
              <div className="border mt-2 mb-3 border"></div>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Address'}</Label>
                    <Label className="text-muted">{details?.address || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Area'}</Label>
                    <Label className="text-muted">{details?.area || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Country'}</Label>
                    <Label className="text-muted">{details?.country?.name || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Country Code'}</Label>
                    <Label className="text-muted">{details?.countryCode || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Pincode'}</Label>
                    <Label className="text-muted">{details?.pincode || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Region'}</Label>
                    <Label className="text-muted">{details?.region?.name || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'SubRegion'}</Label>
                    <Label className="text-muted">{details?.subRegion?.name || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Longitude'}</Label>
                    <Label className="text-muted">{details?.longitude || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Latitude'}</Label>
                    <Label className="text-muted">{details?.latitude || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Descriptions</h6>
              <div className="border mt-2 mb-3 border"></div>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Short Description'}</Label>
                    <Label className="text-muted">{details?.shortDescription || `NA`}</Label>
                  </div>
                </Col>

                <Col md={12}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Long Description'}</Label>
                    <Label className="text-muted">{details?.longDescription || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Terms &amp; Conditions</h6>
              <div className="border mt-2 mb-3 border"></div>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Label className="d-flex">{'T&C'}</Label>
                    <Label className="text-muted">{details?.termsAndCondition || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Details;
