import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../Containers/Config/index.js';
import { PAYMENT_LOGS, PAYMENT_LOGS_SUCCESS, PAYMENT_LOGS_ERROR } from '../../actionType.js';
import apiCall from '../../apiCall.js';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getPaymentLogs({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/payment-logs`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload?.urlParam,
        pageSize: Constant?.TABLE_PAGE_SIZE,
      },
    });

    if (response.status === 200) {
      yield put({
        type: PAYMENT_LOGS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: PAYMENT_LOGS_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: PAYMENT_LOGS_ERROR, payload: error });
  }
}

export function* watchPaymentLogs() {
  yield takeEvery(PAYMENT_LOGS, getPaymentLogs);
}

function* paymentLogsSaga() {
  yield all([fork(watchPaymentLogs)]);
}

export default paymentLogsSaga;
