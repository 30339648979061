import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { Grid, _ } from 'gridjs-react';
import { Card, CardBody, CardHeader, CardFooter, Col, Row } from 'reactstrap';
import { PreviewCardHeader, TablePagination } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';
import Countdown from 'react-countdown';
import { formattedWithComma, hasPermission, isDatePassed } from '../../../../../../Helpers/Helpers';

const Table = props => {
  const [data, setData] = useState([]);

  const [bookingData, setBookingData] = useState([]);
  const [selectedBookings, setSelectedBookings] = useState([]);
  const [serialColumnNumber, setSerialColumnNumber] = useState(1);
  const [isBulkActionShow, setIsBulkActionShow] = useState(false);
  const [currentFilterBookingStatus, setCurrentFilterBookingStatus] = useState(0);
  const [currentFilteredBookingTitle, setCurrentFilterBookingTitle] = useState('Bookings');

  useEffect(() => {
    getBookingStatusTitleHandler(parseInt(props?.appliedFilters?.status));
    if (props?.data?.length > 0) {
      setBookingData(
        props?.data?.map(booking => {
          return { ...booking, checkboxValue: false };
        }),
      );

      setSelectedBookings([]);
      setIsBulkActionShow(false);
      getBookingStatusTitleHandler(parseInt(props?.appliedFilters?.status));
      setCurrentFilterBookingStatus(parseInt(props?.appliedFilters?.status));
    } else {
      setBookingData([]);
      setSelectedBookings([]);
      setCurrentFilterBookingStatus(0);
      setIsBulkActionShow(false);
    }
  }, [props?.data]);

  useEffect(() => {
    if (bookingData?.length > 0) {
      setData(
        bookingData?.map((data, index) => {
          const status = Constant?.BOOKING_STATUSES?.filter(statusObj => statusObj.objectId === data?.bookingStatusCode)[0]?.name;

          return [
            // false,
            `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            data || `NA`,
            index,
            data?.membershipId || `NA`,
            data?.user?.name || `NA`,
            data?.user?.mobile || `NA`,
            data?.user?.email || `NA`,
            data?.airport?.name || `NA`,

            data?.flightDate ? moment(new Date(`${data?.flightDate}`)).format('DD-MM-YYYY') : 'NA',

            data?.flightTimings ? moment(`${data.flightTimings}`, 'h:mm').format('h:mm a') : `NA`,

            data?.partner?.name || `NA`,
            data?.service?.name || `NA`,

            data?.updatedAt ? moment(`${data?.updatedAt}`).format('DD-MM-YYYY, h:mm A') : `NA`,

            status,
            data,
          ];
        }),
      );

      setTimeout(() => {
        colorizeSlaBreached();
      }, [100]);
    } else {
      setData([]);
    }
  }, [bookingData]);

  setTimeout(() => {
    colorizeSlaBreached();
  }, [100]);

  /* ---------------------------- COLORIZE SLA BREACH ---------------------------- */
  function colorizeSlaBreached() {
    if (props?.data?.length > 0) {
      const slaBreachedTableIndexes = props?.data
        ?.map((bookingData, index) => {
          if (bookingData?.slaDetails?.slaBreached) {
            return index;
          }
        })
        .filter(data => data !== undefined);

      Array.from(document.querySelectorAll('.gridjs-tr')).map((row, index) => {
        if (slaBreachedTableIndexes?.includes(index - 1)) {
          row.style.backgroundColor = `#ec7b65`;
          row.style.color = `#fff`;
        }
      });
    } else {
      Array.from(document.querySelectorAll('.gridjs-tr')).map((row, index) => {
        row.style.backgroundColor = `#fff`;
        row.style.color = `black`;
      });
    }
  }

  /* ---------------------------- BOOKING TABLE TITLE HANDLER ---------------------------- */
  const getBookingStatusTitleHandler = bookingsStatusCode => {
    let bookingsTitle = 'Bookings';

    if (bookingsStatusCode !== 0) {
      Constant?.BOOKING_STATUSES?.forEach(bookingStatus => {
        if (bookingsStatusCode === bookingStatus?.objectId) {
          bookingsTitle = `${bookingStatus?.name} Bookings`;
        }
      });
    }
    setCurrentFilterBookingTitle(bookingsTitle);
  };

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    // const currentSelectedIndex = Number(index.split('.')[0]) - 1 - props.pageNumber * Constant.TABLE_PAGE_SIZE;
    // const selectedBookingDetails = bookingData[currentSelectedIndex];

    if (flag === `EDIT`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `VIEW`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `PROCESS`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CONFIRM`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CANCEL`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `UPDATE`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `RESEND_EMAIL`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    }
  };

  /* ---------------------------- CHECKBOX HANDLER ---------------------------- */
  const checkboxClickHandler = (serialNo, bookingId) => {
    let tempSelectedBookings = selectedBookings;
    let transformBookingData = null;

    const currentSelectedIndex = Number(serialNo.split('.')[0]) - 1 - props.pageNumber * Constant.TABLE_PAGE_SIZE;
    const selectedBookingDetails = props?.data[currentSelectedIndex];

    if (tempSelectedBookings?.filter(booking => booking?.bookingId === bookingId).length > 0) {
      const selectedBookingIndex = tempSelectedBookings.findIndex(booking => booking?.bookingId === bookingId);
      tempSelectedBookings.splice(selectedBookingIndex, 1);

      transformBookingData = bookingData.map(booking => {
        if (booking?.bookingId === bookingId) {
          return {
            ...booking,
            checkboxValue: false,
          };
        } else {
          return {
            ...booking,
          };
        }
      });
    } else {
      tempSelectedBookings.push(selectedBookingDetails);
      setIsBulkActionShow(true);

      transformBookingData = bookingData.map(booking => {
        if (booking?.bookingId === bookingId) {
          return {
            ...booking,
            checkboxValue: true,
          };
        } else {
          return {
            ...booking,
          };
        }
      });
    }

    setSelectedBookings(tempSelectedBookings);
    setBookingData(transformBookingData);

    tempSelectedBookings.length > 0 ? setIsBulkActionShow(true) : setIsBulkActionShow(false);
  };

  /* ---------------------------- BULK UPDATE STATUSES HANDLER ---------------------------- */
  const bulkUpdateStatusHandler = flag => {
    if (flag === 'BULK_PROCESS') {
      props.actionHandler({
        bookingDetails: selectedBookings,
        actionType: flag,
      });
    } else if (flag === 'BULK_CONFIRM') {
      props.actionHandler({
        bookingDetails: selectedBookings,
        actionType: flag,
      });
    } else if (flag === 'BULK_CANCEL') {
      props.actionHandler({
        bookingDetails: selectedBookings,
        actionType: flag,
      });
    } else if (flag === 'BULK_UPDATE') {
      props.actionHandler({
        bookingDetails: selectedBookings,
        actionType: flag,
      });
    } else if (flag === 'BULK_RESEND_MAIL') {
      props.actionHandler({
        bookingDetails: selectedBookings,
        actionType: flag,
      });
    }
  };

  /* ---------------------------- COUNTDOWN HANDLER ---------------------------- */
  const countdownFunc = index => {
    if (props?.data?.[index]?.slaDetails?.slaBreached === false && props?.data?.[index]?.slaDetails?.slaLimit === 0) {
      return;
    } else {
      const diffTime = new Date() - new Date(props?.data?.[index]?.slaDetails?.lastProcessedTime);

      const remainingMilisecond = props?.data?.[index]?.slaDetails?.slaLimit - diffTime;

      return <Countdown date={new Date().valueOf() + remainingMilisecond} renderer={data => renderer(data, index)}></Countdown>;
    }
  };

  const renderer = (data, index) => {
    if (data.completed) {
      const selectedRow = Array.from(document.querySelectorAll('.gridjs-tr'))[index + 1];
      selectedRow.style.backgroundColor = `#ec7b65`;
      selectedRow.style.color = `#fff`;
    } else {
      return (
        <span>
          {data.hours}:{data.minutes}:{data.seconds}
        </span>
      );
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{currentFilteredBookingTitle}</h4>

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_ACCESS) && props?.data?.length > 0 && (
                <span className="btn btn-success mb-1" onClick={props?.dumpDownload}>
                  <i className="ri-download-line me-1 text-white fs-14 align-middle"></i>Download dump
                </span>
              )}
            </CardHeader>

            {/* ------------------------ TABLE BULK ACTIONS ------------------------ */}

            {isBulkActionShow && hasPermission(PermissionConstants?.ALL_PERMISSIONS?.MEET_AND_GREET_BOOKING_UPDATE) && (
              <CardHeader>
                {[Constant?.BOOKING_STATUS?.PENDING].includes(currentFilterBookingStatus) && (
                  <button className="rounded-pill btn btn-primary btn-sm mx-1" onClick={() => bulkUpdateStatusHandler(`BULK_PROCESS`)}>
                    Process
                  </button>
                )}

                {[Constant?.BOOKING_STATUS?.PROCESSED].includes(currentFilterBookingStatus) && (
                  <button className="rounded-pill btn btn-primary btn-sm mx-1" onClick={() => bulkUpdateStatusHandler(`BULK_CONFIRM`)}>
                    Confirm
                  </button>
                )}

                {[Constant?.BOOKING_STATUS?.PENDING, Constant?.BOOKING_STATUS?.PROCESSED, Constant?.BOOKING_STATUS?.CONFIRM].includes(
                  currentFilterBookingStatus,
                ) && (
                  <button className="rounded-pill btn btn-primary btn-sm mx-1" onClick={() => bulkUpdateStatusHandler(`BULK_CANCEL`)}>
                    Cancel
                  </button>
                )}

                {[Constant?.BOOKING_STATUS?.PENDING, Constant?.BOOKING_STATUS?.PROCESSED, Constant?.BOOKING_STATUS?.CONFIRM].includes(
                  currentFilterBookingStatus,
                ) && (
                  <button className="rounded-pill btn btn-primary btn-sm mx-1" onClick={() => bulkUpdateStatusHandler(`BULK_UPDATE`)}>
                    Update
                  </button>
                )}

                {[
                  Constant?.BOOKING_STATUS?.PENDING,
                  Constant?.BOOKING_STATUS?.CONFIRM,
                  Constant?.BOOKING_STATUS?.CANCEL_BY_USER,
                  Constant?.BOOKING_STATUS?.CANCEL_BY_THRIWE,
                  Constant?.BOOKING_STATUS?.CANCELLED,
                ].includes(currentFilterBookingStatus) && (
                  <button className="rounded-pill btn btn-primary btn-sm mx-1" onClick={() => bulkUpdateStatusHandler(`BULK_RESEND_MAIL`)}>
                    Resend Email
                  </button>
                )}
              </CardHeader>
            )}

            {/* ------------------------ TABLE DETAILS ------------------------ */}
            {props?.data?.length > 0 && (
              <CardBody>
                <div id="table-gridjs">
                  <Grid
                    data={data}
                    columns={[
                      // {
                      //   name: 'Select',
                      //   width: '100px',
                      //   formatter: (cell, row) =>
                      //     _(
                      //       <div>
                      //         {
                      //           <input
                      //             className="form-check-input form-check-input"
                      //             type="checkbox"
                      //             disabled={currentFilterBookingStatus === 0 ? true : false}
                      //             checked={
                      //               bookingData[
                      //                 Number(row.cells[serialColumnNumber]?.data.split('.')[0]) - 1 - props.pageNumber * Constant?.TABLE_PAGE_SIZE
                      //               ]?.checkboxValue
                      //             }
                      //             onChange={() => checkboxClickHandler(row.cells[serialColumnNumber]?.data, row.cells[serialColumnNumber + 1]?.data)}
                      //           />
                      //         }
                      //       </div>,
                      //     ),
                      // },
                      {
                        name: 'S.No.',
                        width: '70px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Booking ID',
                        width: '130px',
                        formatter: (cell, row) =>
                          _(
                            <span
                              className="fw-semibold"
                              style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                color: '#3577f1',
                              }}
                              onClick={() => actionBtnHandler(`VIEW`, cell)}
                            >
                              {cell?.bookingId}
                            </span>,
                          ),
                      },

                      {
                        name: 'Countdown',
                        width: '150px',
                        formatter: (cell, row) => _(<div className="d-flex justify-content-center flex-column">{countdownFunc(cell)}</div>),
                      },

                      {
                        name: 'Membership Id',
                        width: '155px',
                        formatter: (cell, row) => _(<span>{cell}</span>),
                      },

                      {
                        name: 'Full Name',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Mobile No.',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Email',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Airport Name',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Flight Date',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Flight Time',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Partner',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Service',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Updated At',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Status',
                        width: '140px',
                        formatter: cell =>
                          _(
                            <span
                              className={`booking_status break-word badge bg-${
                                Constant.BOOKING_STATUSES.filter(status => status?.name === cell)[0]?.bgColor
                              }`}
                            >
                              {cell}
                            </span>,
                          ),
                      },

                      {
                        name: 'Actions',
                        width: '120px',
                        formatter: (cell, row) =>
                          _(
                            <div className="d-flex justify-content-center flex-column">
                              {cell?.workflow === Constant.WORKFLOWS[0] && (
                                <>
                                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.MEET_AND_GREET_BOOKING_UPDATE) &&
                                    row.cells[row?.cells?.length - 2]?.data === 'Pending' && (
                                      <>
                                        <button
                                          className="btn btn-sm btn-primary mb-1"
                                          onClick={() => {
                                            actionBtnHandler(`PROCESS`, cell);
                                          }}
                                          disabled={isDatePassed(cell?.bookingDateTime)}
                                        >
                                          Process
                                        </button>

                                        <button
                                          className="btn btn-sm btn-primary mb-1"
                                          onClick={() => {
                                            actionBtnHandler(`UPDATE`, cell);
                                          }}
                                          disabled={isDatePassed(cell?.bookingDateTime)}
                                        >
                                          Update
                                        </button>

                                        <button
                                          className="btn btn-sm btn-primary mb-1"
                                          onClick={() => {
                                            actionBtnHandler(`RESEND_EMAIL`, cell);
                                          }}
                                          disabled={isDatePassed(cell?.bookingDateTime)}
                                        >
                                          Resend Email
                                        </button>

                                        <button
                                          className="btn btn-sm btn-danger mb-1"
                                          onClick={() => {
                                            actionBtnHandler(`CANCEL`, cell);
                                          }}
                                          disabled={isDatePassed(cell?.bookingDateTime)}
                                        >
                                          Cancel
                                        </button>
                                      </>
                                    )}

                                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.MEET_AND_GREET_BOOKING_UPDATE) &&
                                    row.cells[row?.cells?.length - 2]?.data === 'Processed' && (
                                      <>
                                        <button
                                          className="btn btn-sm btn-primary mb-1"
                                          onClick={() => {
                                            actionBtnHandler(`CONFIRM`, cell);
                                          }}
                                          disabled={isDatePassed(cell?.bookingDateTime)}
                                        >
                                          Confirm
                                        </button>

                                        <button
                                          className="btn btn-sm btn-primary mb-1"
                                          onClick={() => {
                                            actionBtnHandler(`UPDATE`, cell);
                                          }}
                                          disabled={isDatePassed(cell?.bookingDateTime)}
                                        >
                                          Update
                                        </button>

                                        <button
                                          className="btn btn-sm btn-danger mb-1"
                                          onClick={() => {
                                            actionBtnHandler(`CANCEL`, cell);
                                          }}
                                          disabled={isDatePassed(cell?.bookingDateTime)}
                                        >
                                          Cancel
                                        </button>
                                      </>
                                    )}

                                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.MEET_AND_GREET_BOOKING_UPDATE) &&
                                    row.cells[row?.cells?.length - 2]?.data === 'Confirmed' && (
                                      <>
                                        <button
                                          className="btn btn-sm btn-primary mb-1"
                                          onClick={() => {
                                            actionBtnHandler(`UPDATE`, cell);
                                          }}
                                          disabled={isDatePassed(cell?.bookingDateTime)}
                                        >
                                          Update
                                        </button>

                                        <button
                                          className="btn btn-sm btn-primary mb-1"
                                          onClick={() => {
                                            actionBtnHandler(`RESEND_EMAIL`, cell);
                                          }}
                                          disabled={isDatePassed(cell?.bookingDateTime)}
                                        >
                                          Resend Email
                                        </button>

                                        <button
                                          className="btn btn-sm btn-danger mb-1"
                                          onClick={() => {
                                            actionBtnHandler(`CANCEL`, cell);
                                          }}
                                          disabled={isDatePassed(cell?.bookingDateTime)}
                                        >
                                          Cancel
                                        </button>
                                      </>
                                    )}

                                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.MEET_AND_GREET_BOOKING_UPDATE) &&
                                    row.cells[row?.cells?.length - 2].data === 'Cancel By Thriwe' && (
                                      <>
                                        <button
                                          className="btn btn-sm btn-primary mb-1"
                                          onClick={() => {
                                            actionBtnHandler(`RESEND_EMAIL`, cell);
                                          }}
                                          disabled={isDatePassed(cell?.bookingDateTime)}
                                        >
                                          Resend Email
                                        </button>
                                      </>
                                    )}
                                </>
                              )}
                            </div>,
                          ),
                      },
                    ]}
                    search={false}
                    sort={false}
                    fixedHeader={true}
                    // height="400px"
                    pagination={{ enabled: false, limit: 5 }}
                  />
                </div>
                {props.children}
              </CardBody>
            )}

            {props?.data?.length === 0 && (
              <CardBody>
                <h5 className="text-center">Record not found!</h5>
              </CardBody>
            )}

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Table);
