import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap';

import { Loader } from '../../../../../../../Common';
import { GET_QUES_ANS, GET_QUES_ANS_EMPTY } from '../../../../../../../../store/actions';

const QuestionAnswerModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [quesAnsForBusiness, setQuesAnsForBusiness] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Questions With Ans
  const { getQuesAns, getQuesAnsLoading } = useSelector((state: any) => ({
    getQuesAns: state?.GetQuestionAnswer?.data,
    getQuesAnsLoading: state?.GetQuestionAnswer?.loading,
  }));

  useEffect(() => {
    dispatch({
      type: GET_QUES_ANS,
      payload: {
        data: {
          businessId: data?.id,
          type: 1,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (getQuesAns) {
      setQuesAnsForBusiness(getQuesAns?.data?.filter(item => item.type === 1));
    }
  }, [getQuesAns]);

  useEffect(() => {
    return () => {
      dispatch({
        type: GET_QUES_ANS_EMPTY,
      });
    };
  }, []);

  useEffect(() => {
    if (getQuesAnsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [getQuesAnsLoading]);

  return (
    <Modal isOpen={true}>
      {loading && <Loader zIndex={true} />}

      <ModalHeader
        className="bg-soft-info p-3"
        toggle={() => {
          onCloseHandler(false);
        }}
      >
        Questions List
      </ModalHeader>

      <ModalBody>
        {quesAnsForBusiness?.length > 0 ? (
          <>
            <h5>Your Responses</h5>
            <p>Responses will be share with admin for registration approval</p>
            {quesAnsForBusiness?.length > 0 &&
              quesAnsForBusiness?.map(item => (
                <div key={item.id} className="mb-3">
                  <p style={{ border: '1px dashed #ddd', padding: '30px', borderRadius: '5px' }}>Q. {item.question}</p>
                  <span>Response</span>
                  <Input
                    type="text"
                    style={{ padding: '30px', margin: '10px 0' }}
                    value={item.answer}
                    disabled={true}
                    onChange={e => console.log(e.target.value)}
                  />
                </div>
              ))}
          </>
        ) : (
          <h5 className="text-center">No question found</h5>
        )}
      </ModalBody>
    </Modal>
  );
};

export default QuestionAnswerModal;
