import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { customHeaders, indianProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { GET_PRICE_DETAILS, GET_PRICE_DETAILS_SUCCESS, GET_PRICE_DETAILS_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getPriceDetails({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/admin/get-price-detail`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        'Project-Code': 'ADMIN_ACCESS',
      },
      params: {
        ...payload?.urlParam,
        pageSize: Constant?.TABLE_PAGE_SIZE,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_PRICE_DETAILS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_PRICE_DETAILS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_PRICE_DETAILS_ERROR, payload: error });
  }
}

export function* watchGetPriceDetails() {
  yield takeEvery(GET_PRICE_DETAILS, getPriceDetails);
}

function* getPriceDetailsSaga() {
  yield all([fork(watchGetPriceDetails)]);
}

export default getPriceDetailsSaga;
