import React, { useEffect, useState } from 'react';
import { BreadCrumb, PreviewCardHeader } from '../../../../../../Common';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import VendorPricingModal from '../Modal/VendorPricing';
import { Grid, _ } from 'gridjs-react';

const VendorPricing = () => {
  const [modal, setModal] = useState(false);
  const [vendorPricing, setVendorPricing] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [updateVendorPricing, setUpdateVendorPricing] = useState(null);
  const [maxDistance, setMaxDistance] = useState(0);
  const [minimumDistance, setMinimumDistance] = useState(0);

  useEffect(() => {
    if (vendorPricing.length > 0) {
      const generateTableData = () => {
        return vendorPricing.map((data, index) => {
          return [index + 1, `${data.minimumDistance}-${data.maximumDistance} ${data.unit.value}`, 'Both', { ...data, index }];
        });
      };
      setTableData(generateTableData());
    }
  }, [vendorPricing]);

  return (
    <>
      <BreadCrumb title="Airport Transfer" navigationby="" navigation={['Dashboard', 'Airport Transfer', 'Vendor Pricing']} />
      <Container fluid className="position-relative">
        <Row>
          <Col xxl={12}>
            <Card>
              <CardHeader className="d-flex justify-space-between">
                <h4>Airport Transfer Setting</h4>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => {
                    if (vendorPricing.length > 0) {
                      setMaxDistance(vendorPricing[vendorPricing.length - 1]?.maximumDistance);
                    }
                    setModal(true);
                  }}
                >
                  Add New Zones
                </button>
              </CardHeader>
              <CardBody>
                {vendorPricing.length > 0 && (
                  <div className="custom-striped-table">
                    <Grid
                      data={tableData}
                      columns={[
                        {
                          name: 'S.No.',
                          width: '50px',
                          formatter: cell => _(<span className="">{cell}</span>),
                        },

                        {
                          name: 'Zones',
                          width: '140px',
                          formatter: cell => _(<span className="">{cell}</span>),
                        },
                        {
                          name: 'Type',
                          width: '140px',
                          formatter: cell => _(<span className="">{cell}</span>),
                        },
                        {
                          name: 'Actions',
                          width: '140px',
                          formatter: (cell, row) =>
                            _(
                              <div className="flex-shrink-0">
                                <span
                                  id="TooltipTop"
                                  className="btn btn-light"
                                  onClick={() => {
                                    setModal(true);
                                    setUpdateVendorPricing(cell);
                                    setMaxDistance(vendorPricing[cell.index - 1]?.maximumDistance);
                                    if (vendorPricing.length - 1 > cell.index) {
                                      setMinimumDistance(vendorPricing[cell.index + 1]?.minimumDistance);
                                    }
                                  }}
                                >
                                  <i className="ri-pencil-fill align-bottom"></i>
                                </span>
                              </div>,
                            ),
                        },
                      ]}
                      sort={true}
                      fixedHeader={true}
                      height="400px"
                      pagination={{ enabled: false }}
                      style={{
                        th: { zIndex: '999', textAlign: 'center' },
                        td: { position: 'relative', textAlign: 'center' },
                      }}
                    />
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {modal && (
        <VendorPricingModal
          modal={modal}
          setModal={setModal}
          vendorPricing={vendorPricing}
          setVendorPricing={setVendorPricing}
          updateVendorPricing={updateVendorPricing}
          setUpdateVendorPricing={setUpdateVendorPricing}
          maxDistance={maxDistance}
          setMaxDistance={setMaxDistance}
          minimumDistance={minimumDistance}
        />
      )}
    </>
  );
};

export default VendorPricing;
