import { useEffect, useState } from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import { Badge, Card, CardBody, CardFooter, Col, Row } from 'reactstrap';

import { Constant } from '../../../../../../../Helpers/constant';
import { formattedWithComma } from '../../../../../../../Helpers/Helpers';
import { PreviewCardHeader, TablePagination } from '../../../../../../../Common';

const Table = props => {
  const { t } = useTranslation();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColums = [
        {
          name: t(`S.No.`),
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
          width: '70px',
        },
        {
          name: t(`File Name`),
          selector: row => <span className="fw-medium">{row?.filename}</span>,
          wrap: true,
          minWidth: '200px',
        },
        {
          name: t(`Generated At`),
          selector: row => row?.createdAt,
          wrap: true,
          width: '180px',
        },
        {
          name: t(`Generated At`),
          width: '160px',
          wrap: true,
          selector: row => checkDownloadStatusHandler(row?.status),
        },
        {
          name: t(`Actions`),
          width: '170px',
          cell: row => (
            <div className="d-flex justify-content-center flex-column w-100 p-2">
              {row?.status === 2 && (
                <span
                  className="btn btn-sm btn-primary w-100"
                  onClick={() => {
                    actionBtnHandler(`DOWNLOAD`, row?.data);
                  }}
                >
                  <div className="">
                    <i className="ri-download-cloud-2-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                    {t(`Download`)}
                  </div>
                </span>
              )}
            </div>
          ),
        },
      ];
      setColumns(fetchColums);
      setData(
        props?.data?.map((data, index) => {
          return {
            serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            filename: data?.filename || `NA`,
            createdAt: moment(new Date(`${data?.createdAt}`)).format('DD-MM-YYYY, h:mm A'),
            status: data?.status,
            data,
          };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data, t]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `DOWNLOAD`) {
      props.actionHandler({
        downloadDetails: data,
        actionType: flag,
      });
    }
  };

  /* ---------------------------- CHECK DOWNLOAD STATUS HANDLER ---------------------------- */
  const checkDownloadStatusHandler = status => {
    switch (status) {
      case 1:
        return <Badge color="warning"> {`In-process`} </Badge>;

      case 2:
        return <Badge color="success"> {`Ready`} </Badge>;

      case 3:
        return <Badge color="danger"> {`Failed`} </Badge>;

      default:
      // Need not to do anything
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title={t(`Downloads`)} refreshBtn={props?.data?.length > 0 ? true : false} refreshHandler={props?.refreshHandler} />
            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
