import React from 'react';

import Filter from './Components/Filter/Filter';
import { Container } from 'reactstrap';

const CustomDownload = () => {
  return (
    <>
      <div className="page-content">
        <Container fluid className="position-relative">
          <Filter />
        </Container>
      </div>
    </>
  );
};

export default CustomDownload;
