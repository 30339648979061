//REGISTER
export const POST_FAKE_REGISTER = '/auth/signup';

//LOGIN
export const POST_FAKE_LOGIN = '/auth/signin';
export const POST_FAKE_JWT_LOGIN = '/post-jwt-login';
export const POST_FAKE_PASSWORD_FORGET = '/auth/forgot-password';
export const POST_FAKE_JWT_PASSWORD_FORGET = '/jwt-forget-pwd';
export const SOCIAL_LOGIN = '/social-login';

//PROFILE
export const POST_EDIT_JWT_PROFILE = '/post-jwt-profile';
export const POST_EDIT_PROFILE = '/user';

// Calendar
export const GET_EVENTS = '/events';
export const GET_CATEGORIES = '/categories';
export const GET_UPCOMMINGEVENT = '/upcommingevents';
export const ADD_NEW_EVENT = '/add/event';
export const UPDATE_EVENT = '/update/event';
export const DELETE_EVENT = '/delete/event';

// Chat
export const GET_DIRECT_CONTACT = '/chat';
export const GET_MESSAGES = '/messages';
export const ADD_MESSAGE = 'add/message';
export const GET_CHANNELS = '/channels';

//Mailbox
export const GET_MAIL_DETAILS = '/mail';
export const DELETE_MAIL = '/delete/mail';

// Ecommerce
// Product
export const GET_PRODUCTS = '/apps/product';
export const DELETE_PRODUCT = '/apps/product';

// Orders
export const GET_ORDERS = '/apps/order';
export const ADD_NEW_ORDER = '/apps/order';
export const UPDATE_ORDER = '/apps/order';
export const DELETE_ORDER = '/apps/order';

// Customers
export const GET_CUSTOMERS = '/apps/customer';
export const ADD_NEW_CUSTOMER = '/apps/customer';
export const UPDATE_CUSTOMER = '/apps/customer';
export const DELETE_CUSTOMER = '/apps/customer';

// Sellers
export const GET_SELLERS = '/sellers';

// Project list
export const GET_PROJECT_LIST = '/project/list';

// Task
export const GET_TASK_LIST = '/apps/task';
export const ADD_NEW_TASK = '/apps/task';
export const UPDATE_TASK = '/apps/task';
export const DELETE_TASK = '/apps/task';

// CRM
// Conatct
export const GET_CONTACTS = '/apps/contact';
export const ADD_NEW_CONTACT = '/apps/contact';
export const UPDATE_CONTACT = '/apps/contact';
export const DELETE_CONTACT = '/apps/contact';

// Companies
export const GET_COMPANIES = '/apps/company';
export const ADD_NEW_COMPANIES = '/apps/company';
export const UPDATE_COMPANIES = '/apps/company';
export const DELETE_COMPANIES = '/apps/company';

// Lead
export const GET_LEADS = '/apps/lead';
export const ADD_NEW_LEAD = '/apps/lead';
export const UPDATE_LEAD = '/apps/lead';
export const DELETE_LEAD = '/apps/lead';

// Deals
export const GET_DEALS = '/deals';

// Crypto
export const GET_TRANSACTION_LIST = '/transaction-list';
export const GET_ORDRER_LIST = '/order-list';

// Invoice
export const GET_INVOICES = '/apps/invoice';
export const ADD_NEW_INVOICE = '/apps/invoice';
export const UPDATE_INVOICE = '/apps/invoice';
export const DELETE_INVOICE = '/apps/invoice';

// TicketsList
export const GET_TICKETS_LIST = '/apps/ticket';
export const ADD_NEW_TICKET = '/apps/ticket';
export const UPDATE_TICKET = '/apps/ticket';
export const DELETE_TICKET = '/apps/ticket';
