import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';

const TakafulData = ({ data }) => {
  return (
    <Row>
      <Col xl={6} md={12} lg={6} className="mb-2">
        <Card>
          <CardHeader>
            <h4 className="card-title mb-0 flex-grow-1">Month Wise Summary (Except Takaful)</h4>
          </CardHeader>

          <CardBody style={{ maxHeight: '300px', overflowY: 'scroll', position: 'relative', textAlign: 'center' }}>
            {data?.monthWiseData?.monthlyExceptTakafulData?.length > 0 ? (
              <Table bordered responsive>
                <thead className="table-light">
                  <tr>
                    <th>Month-Year</th>
                    <th>Bookings</th>
                  </tr>
                </thead>

                <tbody
                  style={{
                    maxHeight: '100px',
                    overflowY: 'auto',
                  }}
                >
                  {data?.monthWiseData?.monthlyExceptTakafulData?.map((data, index) => (
                    <tr key={index}>
                      <td>{`${data?.month ?? ''}`}</td>
                      <td>{`${data?.noOfBookings ?? 0}`}</td>
                    </tr>
                  ))}
                </tbody>

                <tfoot className="table-light">
                  <tr>
                    <th>Total</th>
                    <th>{`${data?.monthWiseData?.totalMonthlyExceptTakafulData ?? 0}`}</th>
                  </tr>
                </tfoot>
              </Table>
            ) : (
              <div className="fs-14">There are no records to display</div>
            )}
          </CardBody>
        </Card>
      </Col>

      <Col xl={6} md={12} lg={6} className="mb-2">
        <Card>
          <CardHeader>
            <h4 className="card-title mb-0 flex-grow-1">Partner Wise Bookings (Except Takaful)</h4>
          </CardHeader>

          <CardBody style={{ maxHeight: '300px', overflowY: 'scroll', position: 'relative', textAlign: 'center' }}>
            {data?.partnerWiseData?.partnerExceptTakafulData?.length > 0 ? (
              <Table bordered responsive>
                <thead className="table-light">
                  <tr>
                    <th>Partner</th>
                    <th>Bookings</th>
                  </tr>
                </thead>

                <tbody>
                  {data?.partnerWiseData?.partnerExceptTakafulData?.map((data, index) => (
                    <tr key={index}>
                      <td>{`${data?.partnerName ?? ''}`}</td>
                      <td>{`${data?.noOfBookings ?? 0}`}</td>
                    </tr>
                  ))}
                </tbody>

                <thead className="table-light">
                  <tr>
                    <th>Total</th>
                    <th>{`${data?.partnerWiseData?.totalPartnersExceptTakafulData ?? 0}`}</th>
                  </tr>
                </thead>
              </Table>
            ) : (
              <div className="fs-14">There are no records to display</div>
            )}
          </CardBody>
        </Card>
      </Col>

      <Col xl={6} md={12} lg={6} className="mb-2">
        <Card>
          <CardHeader>
            <h4 className="card-title mb-0 flex-grow-1">Month Wise Summary (Takaful)</h4>
          </CardHeader>

          <CardBody style={{ maxHeight: '300px', overflowY: 'scroll', position: 'relative', textAlign: 'center' }}>
            {data?.monthWiseData?.monthlyTakafulData?.length > 0 ? (
              <Table bordered responsive>
                <thead className="table-light">
                  <tr>
                    <th>Month-Year</th>
                    <th>Bookings</th>
                  </tr>
                </thead>

                <tbody>
                  {data?.monthWiseData?.monthlyTakafulData?.map((data, index) => (
                    <tr key={index}>
                      <td>{`${data?.month ?? ''}`}</td>
                      <td>{`${data?.noOfBookings ?? 0}`}</td>
                    </tr>
                  ))}
                </tbody>

                <thead className="table-light">
                  <tr>
                    <th>Total</th>
                    <th>{`${data?.monthWiseData?.totalMonthlyTakafulData ?? 0}`}</th>
                  </tr>
                </thead>
              </Table>
            ) : (
              <div className="fs-14">There are no records to display</div>
            )}
          </CardBody>
        </Card>
      </Col>

      <Col xl={6} md={12} lg={6} className="mb-2">
        <Card>
          <CardHeader>
            <h4 className="card-title mb-0 flex-grow-1">Partner Wise Bookings (Takaful)</h4>
          </CardHeader>

          <CardBody style={{ maxHeight: '300px', overflowY: 'scroll', position: 'relative', textAlign: 'center' }}>
            {data?.partnerWiseData?.partnerTakafulData?.length > 0 ? (
              <Table bordered responsive>
                <thead className="table-light">
                  <tr>
                    <th>Partner</th>
                    <th>Bookings</th>
                  </tr>
                </thead>

                <tbody>
                  {data?.partnerWiseData?.partnerTakafulData?.map((data, index) => (
                    <tr key={index}>
                      <td>{`${data?.partnerName ?? ''}`}</td>
                      <td>{`${data?.noOfBookings ?? 0}`}</td>
                    </tr>
                  ))}
                </tbody>

                <thead className="table-light">
                  <tr>
                    <th>Total</th>
                    <th>{`${data?.partnerWiseData?.totalPartnersTakafulData ?? 0}`}</th>
                  </tr>
                </thead>
              </Table>
            ) : (
              <div className="fs-14">There are no records to display</div>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default TakafulData;
