import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import ProjectScore from './Components/ProjectScore';

const CommonDashboard = () => {
  return (
    <Row>
      <Col md={12}>
        <ProjectScore />
      </Col>
    </Row>
  );
};

export default CommonDashboard;
