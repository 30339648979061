import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import { Card, CardBody, Col, Label, Row, CardFooter, Input, Button } from 'reactstrap';

import { PreviewCardHeader } from '../../../../../Common';
import { Constant } from '../../../../../Helpers/constant';
import { isANumber, isValidAlphaNumbericWithoutSpace } from '../../../../../Helpers/Helpers';
import { GET_CLIENT_HISTORY_LIST } from '../../../../../../store/actions';

const statuses = [
  {
    name: `Pending`,
    objectId: Constant?.BOOKING_STATUS?.PENDING,
  },
  {
    name: `Processed`,
    objectId: Constant?.BOOKING_STATUS?.PROCESSED,
  },
  {
    name: `Confirmed`,
    objectId: Constant?.BOOKING_STATUS?.CONFIRM,
  },
  {
    name: `Cancelled (ALL)`,
    objectId: Constant?.BOOKING_STATUS?.CANCELLED,
  },
  {
    name: `Cancelled by User`,
    objectId: Constant?.BOOKING_STATUS?.CANCEL_BY_USER,
  },
  {
    name: `Cancelled by Thriwe`,
    objectId: Constant?.BOOKING_STATUS?.CANCEL_BY_THRIWE,
  },
  {
    name: `Short Notice Cancelled by User`,
    objectId: Constant?.BOOKING_STATUS?.SHORT_NOTICE_CANCELLED_BY_USER,
  },
  {
    name: `Short Notice Cancelled by Thriwe`,
    objectId: Constant?.BOOKING_STATUS?.SHORT_NOTICE_CANCELLED_BY_THRIWE,
  },
];

const initialState = {
  customerDetails: {
    mobile: ``,
  },
  bookingId: ``,
  updatedAtRange: {
    from: ``,
    to: ``,
  },
  status: 0,
};

const Filter = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [filter, setFilter] = useState(initialState);

  useEffect(() => {
    if (props?.appliedFilters) {
      setFilter(props?.appliedFilters);
    }
  }, []);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;

    let updatedState = { ...filter };
    let tempMobileNumber = '';

    switch (name) {
      case 'mobileNumber':
        if (value?.length === 0) {
          tempMobileNumber = ``;
        } else {
          tempMobileNumber = isANumber(value) ? value : filter?.customerDetails?.mobile;
        }

        updatedState = {
          ...filter,
          customerDetails: {
            mobile: tempMobileNumber,
          },
        };
        break;

      case 'bookingId':
        updatedState = {
          ...filter,
          bookingId: isValidAlphaNumbericWithoutSpace(value) ? value : filter?.bookingId,
        };
        break;

      case 'status-type':
        updatedState = {
          ...filter,
          status: parseInt(value),
        };
        break;

      default:
      // do nothing
    }

    setFilter(updatedState);
  };

  /* ---------------------------- DATE PICKR HANDLER ---------------------------- */
  const datePickHandler = (flag, date) => {
    let updatedState = { ...filter };

    switch (flag) {
      case 'updatedAtRange':
        updatedState = {
          ...filter,
          updatedAtRange: {
            from: moment(new Date(date[0])).format('Y-MM-DD'),
            to: moment(new Date(date[1])).format('Y-MM-DD'),
          },
        };
        setFilter(updatedState);
        break;

      default:
      // do nothing
    }
    setFilter(updatedState);
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    props.filterHandler({ ...filter });
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter({ ...initialState });
    props?.setCurrentPageNumber(0);

    dispatch({
      type: GET_CLIENT_HISTORY_LIST,
      payload: {
        urlParam: {
          pageIndex: 0,
          bookingTypeId: props?.isAirportPickUp ? 5 : 6,
        },
        data: filter,
      },
    });
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Filter" />
            {/* <button onClick={() => console.log({ filter })}>get state</button> */}
            <CardBody>
              <div className="live-preview">
                <div className="text-center mb-3">
                  <div className="mb-3 " style={{ background: 'rgb(243, 246, 249)', display: 'inline-block', padding: '10px', borderRadius: '12px' }}>
                    <Button
                      color={!props.isAirportPickUp ? 'light' : 'primary'}
                      className="rounded-pill"
                      onClick={() => props.setIsAirportPickUp(true)}
                    >
                      Airport Transfer
                    </Button>
                    <Button
                      color={props.isAirportPickUp ? 'light' : 'primary'}
                      className="rounded-pill"
                      onClick={() => {
                        props.setIsAirportPickUp(false);
                      }}
                    >
                      Airport Meet & Greet
                    </Button>
                  </div>
                </div>

                <Row>
                  <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                    <div className="mb-3">
                      <Label htmlFor="mobileNumber" className="form-label">
                        Phone Number
                      </Label>
                      <Input
                        id="mobileNumber"
                        name="mobileNumber"
                        type="text"
                        className="form-control"
                        value={filter?.customerDetails?.mobile}
                        onChange={inputChangeHandler}
                      />
                    </div>
                  </Col>

                  <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                    <div className="mb-3">
                      <Label htmlFor="date-of-play-range" className="form-label">
                        Select date range
                        <span className="icon_calendar">
                          <i className="las la-calendar"></i>
                        </span>
                      </Label>
                      <div className="area_expandable">
                        <Flatpickr
                          className="form-control"
                          value={[filter?.updatedAtRange?.from, filter?.updatedAtRange?.to]}
                          onChange={date => datePickHandler(`updatedAtRange`, date)}
                          options={{
                            mode: 'range',
                            dateFormat: 'Y-m-d',
                            maxDate: 'today',
                          }}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                    <div className="mb-3">
                      <Label htmlFor="membershipId" className="form-label">
                        Booking Id
                      </Label>
                      <Input
                        id="bookingId"
                        name="bookingId"
                        type="text"
                        className="form-control"
                        value={filter?.bookingId}
                        onChange={inputChangeHandler}
                      />
                    </div>
                  </Col>

                  <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                    <div className="mb-3">
                      <Label htmlFor="status-type" className="form-label">
                        Booking Status
                      </Label>
                      <select id="status-type" name="status-type" className="form-select" value={filter?.status} onChange={inputChangeHandler}>
                        <option value="0">Choose...</option>
                        {statuses?.map((status, index) => (
                          <option key={index} value={status.objectId}>
                            {status.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>

            <CardFooter>
              <Row>
                <Col md={12}>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary mx-3" onClick={filterSubmitHandler}>
                      <i className="ri-search-line me-1 text-white fs-14 align-middle"></i>Search
                    </button>

                    <button type="submit" className="btn btn-light" onClick={resetFilterHandler}>
                      Reset Filter
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Filter;
