import { Constant } from '../Helpers/constant';

/* ---------------------------- COMMON ---------------------------- */
export const customHeaders = {
  'Content-Type': 'application/json',
  'Project-Code': Constant?.PROJECT_CODE ?? `ADMIN_ACCESS`,
};

export const clientCustomHeaders = () => {
  return {
    'Content-Type': 'application/json',
    'Project-Code': localStorage?.getItem('bankCode') ?? 'MASTERCARD_KSA',
  };
};

export const commsHeader = {
  'X-Thriwe-Comms-Key': process.env.REACT_APP_COMS_KEY ?? ``,
};

// API base URL
export const masterCardBaseURL = `https://stag-mastercard-api.thriwe.com`;

/* ---------------------------- API URL'S ---------------------------- */
export const indianProjectsBaseURL = [`SAB_LANDMARK`, `SAB`]?.includes(Constant?.PROJECT_CODE)
  ? process.env.REACT_APP_KSA_URL
  : process.env.REACT_APP_INDIA_PROJECTS_URL;

export const uaeProjectsBaseURL = [`SAB_LANDMARK`, `SAB`]?.includes(Constant?.PROJECT_CODE)
  ? process.env.REACT_APP_KSA_URL
  : process.env.REACT_APP_UAE_PROJECTS_URL;

export const ksaProjectsBaseURL = [`SAB_LANDMARK`, `SAB`]?.includes(Constant?.PROJECT_CODE)
  ? process.env.REACT_APP_KSA_URL
  : process.env.REACT_APP_KSA_PROJECTS_URL;

export const adibProjectsUrl = process.env.REACT_APP_ADIB_PROJECTS_URL;

export const thriweMarketPlaceUrl = process.env.REACT_APP_THRIWE_MARKETPLACE_PROJECTS_URL;

export const thriweDiscoveryUrl = process.env.REACT_APP_THRIWE_DISCOVERY_URL;

export const realTimeBaseURL = process.env.REACT_APP_REAL_TIME_BASE_URL;

let projectBaseURL = ``;
const contractingCountry = JSON.parse(localStorage?.getItem('data'))?.program?.contractingCountry?.name.toLowerCase();
if (contractingCountry === `india`) {
  projectBaseURL = indianProjectsBaseURL;
} else if (contractingCountry === `united arab emirates`) {
  projectBaseURL = uaeProjectsBaseURL;
} else {
  projectBaseURL = uaeProjectsBaseURL;
}

export const baseUrl = projectBaseURL;
