import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import fetch from './../../fetch';
import { masterCardBaseURL, customHeaders } from '../../../../Containers/Config/index.js';

import { GET_COUPON_UPLOADED_BY, GET_COUPON_UPLOADED_BY_SUCCESS, GET_COUPON_UPLOADED_BY_ERROR } from './../../actionType.js';
import { Constant } from '../../../../Containers/Helpers/constant';

function* getCouponUploadedBy({ payload }) {
  try {
    const response = yield call(fetch, {
      url: `${masterCardBaseURL}/parse/classes/golfbookings/?where=${payload.param}`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
    });

    if (response?.statusCode === undefined) {
      yield put({ type: GET_COUPON_UPLOADED_BY_SUCCESS, payload: response });
    } else {
      yield put({
        type: GET_COUPON_UPLOADED_BY_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_COUPON_UPLOADED_BY_ERROR, payload: error });
  }
}

export function* watchGetCouponUploadedBy() {
  yield takeEvery(GET_COUPON_UPLOADED_BY, getCouponUploadedBy);
}

function* getCouponUploadedBySaga() {
  yield all([fork(watchGetCouponUploadedBy)]);
}

export default getCouponUploadedBySaga;
