import { GET_PROJECT_UTILITY,GET_PROJECT_UTILITY_SUCCESS,GET_PROJECT_UTILITY_EMPTY,GET_PROJECT_UTILITY_ERROR } from './../../actionType.js';

const initialState = {
  data: null,
  message: null,
  loading: false,
  success: false,
  error: false,
};

const GetProjectUtility = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECT_UTILITY:
      state = {
        ...state,
        data: null,
        loading: true,
        success: false,
        error: false,
        message: null,
      };
      break;

    case GET_PROJECT_UTILITY_SUCCESS:
      state = {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
        error: false,
        message: null,
      };

      break;

    case GET_PROJECT_UTILITY_ERROR:
      state = {
        ...state,
        data: null,
        loading: false,
        success: false,
        error: true,
        message: action.payload,
      };
      break;

    case GET_PROJECT_UTILITY_EMPTY:
      state = {
        ...initialState,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default GetProjectUtility;
