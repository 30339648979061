import React, { useState } from 'react';
import AirportTransferSetting from './Components/Form/AirportTransferSetting';
import TerminalPickupAndDrop from './Components/Map/TerminalPickupAndDrop';
import ServicableArea from './Components/Map/ServicableNonServicableArea';
import VendorPricing from './Components/Form/VendorPricing';

const AirportTransfer = () => {
  const [airportTransferSettingForm, SetAirportTransferSettingForm] = useState<boolean>(true);
  const [showTerminalPointPage, setShowTerminalPointPage] = useState<boolean>(false);
  const [center, setcenter] = useState<Array<number>>([20.5937, 78.9629]);
  const [showServicablePage, setShowServicablePage] = useState<boolean>(false);
  const [showTablePage, setShowTablePage] = useState<boolean>(false);
  const [polygons, setPolygons] = useState<Array<any>>([]);
  const [point, setPoint] = useState<Array<any>>([]);
  const [userDrawItems, setUserDrawItems] = useState<Array<any>>([]);
  const [dropType, setDropType] = useState<Array<any>>([]);
  const [terminalList, setTerminalList] = useState([
    { name: 'Terminal 1', lat: '20.5937', long: '78.9629' },
    { name: 'Terminal 2', lat: '20.5935', long: '78.9622' },
  ]);
  const [terminal, setTerminal] = useState([{ name: 'Terminal 1', lat: '20.5937', long: '78.9629', type: 'pickup' }]);

  const extractUniqueTypes = locations => {
    const uniqueTypes = [];
    locations.forEach(location => {
      if (!uniqueTypes.includes(location.type) && uniqueTypes.length < 2) {
        uniqueTypes.push(location.type);
      }
    });
    return uniqueTypes;
  };

  const airportTransferSettingSubmitHandler = () => {
    SetAirportTransferSettingForm(false);
    setShowTerminalPointPage(true);
  };

  const terminalPointSubmitHandler = () => {
    setShowTerminalPointPage(false);
    setShowServicablePage(true);
    setDropType(extractUniqueTypes(terminal));
  };

  const submitServicablePageHandler = () => {
    setPolygons(userDrawItems);
    setShowServicablePage(false);
    setShowTablePage(true);
  };

  return (
    <div className="page-content">
      {airportTransferSettingForm && <AirportTransferSetting submitHandler={airportTransferSettingSubmitHandler} />}
      {showTerminalPointPage && (
        <TerminalPickupAndDrop
          terminal={terminal}
          terminalList={terminalList}
          center={center}
          setTerminal={setTerminal}
          submitHandler={terminalPointSubmitHandler}
        />
      )}
      {showServicablePage && (
        <ServicableArea
          polygons={polygons}
          setPolygons={setPolygons}
          setPoint={setPoint}
          setUserDrawItems={setUserDrawItems}
          submitHandler={submitServicablePageHandler}
          point={point}
          userDrawItems={userDrawItems}
        />
      )}
      {showTablePage && <VendorPricing />}
    </div>
  );
};

export default AirportTransfer;
