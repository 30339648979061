import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { Row, Col, Button, Modal, ModalBody, ModalHeader, Table, ModalFooter } from 'reactstrap';
import { GET_CARD_LIST_EMPTY } from '../../../../../../store/application/actionType';

const CardListModal = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [modal_varying1, setmodal_varying1] = useState(true);

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);

    dispatch({
      type: GET_CARD_LIST_EMPTY,
    });
  };

  const closeHandler = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);

    dispatch({
      type: GET_CARD_LIST_EMPTY,
    });
  };

  return (
    <Modal isOpen={true} scrollable={true} size="lg">
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title">User Card Details</h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        <form>
          <Row>
            <Col md={12} style={{ mixHeight: '500px' }}>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th> Bin Number</th>
                    <th> Last 4 digits</th>
                    <th> Membership Id</th>
                    <th> Status</th>
                    <th> Default</th>
                  </tr>
                </thead>
                <tbody>
                  {props?.cardDetails?.results?.map((cardDetail, index) => {
                    return (
                      <tr key={index}>
                        <th>{index + 1}.</th>
                        <td>{cardDetail?.name}</td>
                        <td>{cardDetail?.first}</td>
                        <td>{cardDetail?.last}</td>
                        <td>{cardDetail?.membershipId}</td>
                        <td>{cardDetail?.status ? 'Active' : 'In-active'}</td>
                        <td>{cardDetail?.default ? 'Active' : 'In-active'}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </form>
      </ModalBody>

      <div className="modal-footer d-flex justify-content-center">
        <Button className="btn btn-danger" color="primary" onClick={closeHandler}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default CardListModal;
