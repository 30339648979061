import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';

import { Card, CardBody, Col, Form, Label, Row, Input, CardFooter } from 'reactstrap';
import { Error, PreviewCardHeader } from '../../../../../../Common';
import ReactTooltip from 'react-tooltip';
import { CREATE_CLIENTS, CREATE_PROJECT, GET_CLIENTS, GET_COUNTRY, UPDATE_CLIENTS } from '../../../../../../../store/application/actionType';
import { AsyncPaginate } from 'react-select-async-paginate';
import axios from 'axios';
import { baseUrl, customHeaders } from '../../../../../../Config';
import { Constant } from '../../../../../../Helpers/constant';

const ClientCreationForm = ({ countries, toggleTab, submitParentHandler, updatedData }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    name: '',
    description: '',
    contractingCountry: null,
    clientType: ``,
    industry: ``,
  };
  const [state, setState] = useState(initialObj);
  const [isValidationShow, setIsValidationShow] = useState(false);
  const [client, setClient] = useState({});
  const [isSelectedClient, setIsSelectedClient] = useState(false);
  const [allCountries, setAllCountries] = useState([]);
  const [allClients, setAllClients] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Client List
  const { clients } = useSelector(state => ({
    clients: state?.Clients?.data,
    clientsLoading: state?.Clients?.loading,
    clientsError: state?.Clients?.error,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (countries !== null) {
      setAllCountries(
        countries?.map(country => ({
          value: country?.countryId,
          label: country?.name,
        })),
      );
    }
  }, [countries]);

  useEffect(() => {
    if (updatedData) {
      setState({
        name: updatedData?.name,
        description: updatedData?.description,
        contractingCountry: {
          value: updatedData?.contractingCountry?.id,
          label: updatedData?.contractingCountry?.name,
        },
        id: updatedData?.id,
        clientType: updatedData?.clientType,
        industry: updatedData?.industry,
      });
    }
  }, [updatedData]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'name':
        updatedState = {
          ...state,
          name: value,
        };
        break;

      case 'description':
        updatedState = {
          ...state,
          description: value,
        };
        break;

      case 'clientType':
        updatedState = {
          ...state,
          clientType: value,
        };
        break;

      case 'industry':
        updatedState = {
          ...state,
          industry: value,
        };
        break;

      default:
      // do nothing
    }
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = null;

    if (label === `contractingCountry`) {
      updatedState = {
        ...state,
        contractingCountry: data,
      };
      setState(updatedState);
      validateErrors(updatedState, `validationCheck`);
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (
      data?.name?.trim() !== `` &&
      data?.description?.time?.trim() !== `` &&
      data?.contractingCountry !== null &&
      data?.clientType?.trim() !== `` &&
      data?.industry?.trim() !== ``
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (isSelectedClient) {
      submitParentHandler(client, 'client');
      toggleTab(2);
    } else if (updatedData) {
      submitParentHandler(
        {
          value: state?.id,
          label: state?.name,
          completeData: {
            contractingCountry: {
              id: state?.contractingCountry?.value,
              name: state?.contractingCountry?.label,
            },
          },
        },
        'client',
      );
      const payload = {
        ...state,
        contractingCountry: {
          id: state?.contractingCountry?.value,
          name: state?.contractingCountry?.label,
        },
        id: updatedData?.id,
      };
      dispatch({
        type: UPDATE_CLIENTS,
        payload: {
          data: {
            ...payload,
          },
        },
      });
      toggleTab(2);
    } else {
      if (validateErrors(state, `submissionCheck`)) {
        const payload = {
          ...state,
          contractingCountry: {
            id: state?.contractingCountry?.value,
            name: state?.contractingCountry?.label,
          },
        };
        dispatch({
          type: CREATE_CLIENTS,
          payload: {
            data: {
              ...payload,
            },
          },
        });
        toggleTab(2);
      }
    }
  };

  async function loadClient(search, loadedOptions) {
    return await axios({
      method: `GET`,
      url: `${baseUrl}/admin/clients`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        name: search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data.id,
              label: `${data.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  const onSelectClient = selectedOption => {
    setClient(selectedOption);
    setState({
      name: selectedOption?.completeData?.name,
      description: selectedOption?.completeData?.description,
      contractingCountry: {
        value: selectedOption?.completeData?.contractingCountry?.id,
        label: selectedOption?.completeData?.contractingCountry?.name,
      },
      clientType: selectedOption?.completeData?.clientType,
      industry: selectedOption?.completeData?.industry,
    });
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Client Creation" />
            {/* <button onClick={() => console.log(state)}>get state</button> */}
            <CardBody>
              <div className="live-preview">
                <Form>
                  {!updatedData && (
                    <>
                      <Row>
                        <Col md={12}>
                          <div className="mb-3">
                            <div className="form-check form-switch">
                              <Input
                                role="switch"
                                type="checkbox"
                                id="has-parent-category"
                                name="has-parent-category"
                                className="form-check-input"
                                checked={isSelectedClient}
                                onChange={() => {
                                  setIsSelectedClient(!isSelectedClient);
                                }}
                              />
                              <Label className="form-check-label" htmlFor="has-parent-category">
                                Already Client
                              </Label>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={5} className="mb-4">
                          <div className="d-flex justify-content-between">
                            <Label htmlFor="choices-multiple-default" className="form-label">
                              Select Client
                              <span className="lbl_mandatory"></span>
                            </Label>
                          </div>
                          <AsyncPaginate
                            debounceTimeout={500}
                            value={client}
                            loadOptions={loadClient}
                            onChange={selectedOption => onSelectClient(selectedOption)}
                            isMulti={false}
                            closeMenuOnSelect={true}
                            noOptionsMessage={() => 'No results found'}
                            cacheUniqs={[['code']]}
                            placeholder="Select..."
                            isDisabled={!isSelectedClient}
                          />
                        </Col>
                      </Row>
                      <hr style={{ backgroundColor: '#9ea0a1' }} />
                    </>
                  )}
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="name" className="form-label">
                          Name
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state?.name}
                          autoComplete="off"
                          disabled={isSelectedClient}
                        />
                        {isValidationShow && state?.name?.trim() === `` && <Error text="Please enter name" />}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <div>
                          <Label htmlFor="name" className="form-label">
                            Description
                            <span className="lbl_mandatory"> *</span>
                          </Label>
                          <Input
                            id="description"
                            name="description"
                            type="text"
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={state?.description}
                            autoComplete="off"
                            disabled={isSelectedClient}
                          />
                          {isValidationShow && state?.description?.trim() === `` && <Error text="Please enter description" />}
                        </div>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <div>
                          <Label htmlFor="clientType" className="form-label">
                            Client Type
                            <span className="lbl_mandatory"> *</span>
                          </Label>
                          <Input
                            id="clientType"
                            name="clientType"
                            type="text"
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={state?.clientType}
                            autoComplete="off"
                            disabled={isSelectedClient}
                          />
                          {isValidationShow && state?.clientType?.trim() === `` && <Error text="Please enter client type" />}
                        </div>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <div>
                          <Label htmlFor="industry" className="form-label">
                            Industry
                            <span className="lbl_mandatory"> *</span>
                          </Label>
                          <Input
                            id="industry"
                            name="industry"
                            type="text"
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={state?.industry}
                            autoComplete="off"
                            disabled={isSelectedClient}
                          />
                          {isValidationShow && state?.industry?.trim() === `` && <Error text="Please enter industry" />}
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <div>
                          <Label htmlFor="" className="form-label">
                            Contracting Country
                            <span className="lbl_mandatory"> *</span>
                          </Label>
                          <Select
                            id="contracting-country"
                            name="contracting-country"
                            value={state?.contractingCountry}
                            options={allCountries}
                            onChange={data => inputSelectHandler(`contractingCountry`, data)}
                            isDisabled={isSelectedClient}
                          />
                          {isValidationShow && state?.contractingCountry === null && <Error text="Please select country" />}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>

            <CardFooter>
              <Row>
                <Col md={12}>
                  <div className="text-center">
                    <button type="submit" className="btn btn-primary mx-3 overlay-disable" onClick={submitHandler}>
                      {updatedData ? 'Update' : Object.keys(client)?.length > 0 ? 'Next' : 'Create'}
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>

            <div
              style={{
                width: '100px',
                position: 'relative',
                zIndex: '999999999999',
              }}
            >
              <ReactTooltip />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ClientCreationForm;
