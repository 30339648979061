import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, indianProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { GET_INTEGRATION_DATA, GET_INTEGRATION_DATA_SUCCESS, GET_INTEGRATION_DATA_ERROR } from '../../actionType.js';
import apiCall from '../../apiCall.js';
import { Constant } from '../../../../Containers/Helpers/constant.js';
import cogoToast from 'cogo-toast';

function* getIntegrationData({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/admin/data-integration`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload?.urlParam,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_INTEGRATION_DATA_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_INTEGRATION_DATA_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_INTEGRATION_DATA_ERROR, payload: error });
  }
}

export function* watchGetIntegrationData() {
  yield takeEvery(GET_INTEGRATION_DATA, getIntegrationData);
}

function* getIntegrationDataSaga() {
  yield all([fork(watchGetIntegrationData)]);
}

export default getIntegrationDataSaga;
