import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

const ActionConfirmationModal = ({ currentSelectedData, onCancel, onConfirm, totalData }) => {
  /* ----------------------------  TOTAL DATA COUNT ---------------------------- */
  const dataToBeActioned = () => {
    if (currentSelectedData?.label === `APPROVE_ENTIRE_BATCH`) {
      return totalData;
    } else {
      return currentSelectedData?.data?.length ?? 0;
    }
  };

  return (
    <Modal isOpen={true} id="master-upload-action-confirmation">
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title">Confirmation</h5>
        <Button
          type="button"
          onClick={() => {
            onCancel();
          }}
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        <p className="text-muted m-0 fs-14">
          Are you sure want to {currentSelectedData?.label?.includes('APPROVE') ? 'accept' : 'reject'}({dataToBeActioned()}) records?
        </p>
      </ModalBody>

      <ModalFooter className="pb-0">
        <div className="modal-footer mx-auto">
          <button className="btn rounded-3 mb-2 me-2 btn-outline-primary" onClick={onCancel}>
            Cancel
          </button>

          <button className="btn rounded-3 mb-2 me-2 btn-primary" onClick={() => onConfirm(currentSelectedData)}>
            {currentSelectedData?.label?.includes('APPROVE') ? 'Accept' : 'Reject'} ({dataToBeActioned()})
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ActionConfirmationModal;
