import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Button, Modal, ModalBody, Col, Label, Input, Row, ModalFooter, ModalHeader } from 'reactstrap';

import { Error } from '../../../../../../../Common';
import { Constant } from '../../../../../../../Helpers/constant';
import { isValidUrl } from '../../../../../../../Helpers/Helpers';
import { customHeaders, indianProjectsBaseURL } from '../../../../../../../Config';
import { POST_ANNOUNCEMENTS, POST_IMAGE, POST_IMAGE_EMPTY } from '../../../../../../../../store/application/actionType';

const initialState = {
  category: null,
  city: null,
  postType: null,
  image: ``,
  text: ``,
  link: ``,
  type: null,
};

const CreateAnnouncement = ({ data, onCloseHandler, setData }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // UPLOAD MEDIA
  const { postMedia, postMediaLoading } = useSelector(state => ({
    postMedia: state?.PostImage?.data,
    postMediaLoading: state?.PostImage?.loading,
  }));

  useEffect(() => {
    if (postMedia !== null) {
      const data = {
        ...state,
        image: postMedia?.data,
      };
      setState({ ...data });
      validateErrors(data, `validationCheck`);

      dispatch({
        type: POST_IMAGE_EMPTY,
      });
    }
  }, [postMedia]);

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    const updatedState = { ...state, [label]: data?.target ? data.target.value : data };
    setState(updatedState);
    validateErrors(updatedState, 'validationCheck');
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data?.type?.value === `1`) {
      if (
        data?.category !== null &&
        data?.city !== null &&
        data?.postType !== null &&
        data?.image !== `` &&
        data?.text?.trim() !== `` &&
        data?.link?.trim() !== `` &&
        isValidUrl(data?.link)
      ) {
        setIsValidationShow(false);
        return true;
      } else {
        setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
        return false;
      }
    } else if (data?.type?.value === `2`) {
      if (data?.text?.trim() !== `` && data?.link?.trim() !== `` && isValidUrl(data?.link) && data?.image !== ``) {
        setIsValidationShow(false);
        return true;
      } else {
        setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
        return false;
      }
    } else if (data?.type === null) {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      let payload = null;
      if (state?.type?.value === `1`) {
        payload = {
          ...state,
          type: parseInt(state?.type?.value),
          category: state?.category?.value,
          city: state?.city?.value,
          postType: state?.postType?.value,
        };
      } else if (state?.type?.value === `2`) {
        payload = {
          ...state,
          type: parseInt(state?.type?.value),
          category: state?.category === null ? null : state?.category?.value,
          city: state?.city === null ? null : state?.city?.value,
          postType: state?.postType === null ? null : state?.postType?.value,
        };
      }

      dispatch({
        type: POST_ANNOUNCEMENTS,
        payload: {
          data: {
            ...payload,
          },
        },
      });

      onCloseHandler();
    }
  };

  /* ---------------------------- UPLOAD IMAGE HANDLER ---------------------------- */
  const uploadImageHandler = e => {
    const formData = new FormData();
    formData.append('file', e?.target?.files[0]);
    dispatch({ type: POST_IMAGE, payload: { data: formData, urlParam: { fileType: 1 } } });
  };

  const loadOptions = async (url, search) => {
    try {
      const response = await axios.get(url, {
        headers: { Authorization: Constant?.CLIENT_TOKEN, ...customHeaders },
        params: { name: search },
      });
      return {
        options: response?.data?.data?.map(data => ({
          value: data?.id,
          label: data?.name,
          completeData: data,
        })),
        hasMore: false,
      };
    } catch (err) {
      return err;
    }
  };

  const loadPostTypes = search => loadOptions(`${indianProjectsBaseURL}/adib/generic/post-types`, search);
  const loadCategories = search => loadOptions(`${indianProjectsBaseURL}/adib/generic/categories`, search);
  const loadCities = search => loadOptions(`${indianProjectsBaseURL}/adib/generic/cities`, search);

  return (
    <Modal isOpen={true} scrollable={true} size="lg">
      <ModalHeader
        className="bg-soft-info p-3"
        toggle={() => {
          setData(initialState);
          onCloseHandler(false);
        }}
      >
        Create Announcement
      </ModalHeader>

      <ModalBody>
        {/* <button onClick={() => console.log(state)}>get state</button> */}
        <Row>
          <Col xxl={12}>
            <Row>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <div className="mb-3">
                  <Label htmlFor="type" className="form-label">
                    Type
                    <span className="lbl_mandatory"> *</span>
                  </Label>
                  <Select
                    id="type"
                    name="type"
                    value={state?.type}
                    options={[
                      {
                        label: `Feed`,
                        value: `1`,
                      },
                      {
                        label: `Advertisement`,
                        value: `2`,
                      },
                    ]}
                    onChange={data => inputSelectHandler(`type`, data)}
                  />
                  {isValidationShow && state?.type === null && <Error text="Please select type" />}
                </div>
              </Col>

              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <div className="mb-3">
                  <Label htmlFor="currency" className="form-label">
                    Post Type
                    {!(state?.type?.value === `2`) && <span className="lbl_mandatory"> *</span>}
                  </Label>
                  <AsyncPaginate
                    debounceTimeout={500}
                    value={state?.postType}
                    loadOptions={loadPostTypes}
                    onChange={data => inputSelectHandler(`postType`, data)}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    noOptionsMessage={() => 'No results found'}
                    cacheUniqs={[['code']]}
                    placeholder="Select..."
                    isDisabled={state?.type === null}
                  />
                  {isValidationShow && state?.type !== null && state?.type?.value === `1` && state?.postType === null && (
                    <Error text="Please select post type" />
                  )}
                </div>
              </Col>

              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <div className="mb-3">
                  <Label htmlFor="currency" className="form-label">
                    Category
                    {!(state?.type?.value === `2`) && <span className="lbl_mandatory"> *</span>}
                  </Label>
                  <AsyncPaginate
                    debounceTimeout={500}
                    value={state?.category}
                    loadOptions={loadCategories}
                    onChange={data => inputSelectHandler(`category`, data)}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    noOptionsMessage={() => 'No results found'}
                    cacheUniqs={[['code']]}
                    placeholder="Select..."
                    isDisabled={state?.type === null}
                  />
                  {isValidationShow && state?.type !== null && state?.type?.value === `1` && state?.category === null && (
                    <Error text="Please select category" />
                  )}
                </div>
              </Col>

              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <div className="mb-3">
                  <Label htmlFor="currency" className="form-label">
                    City
                    {!(state?.type?.value === `2`) && <span className="lbl_mandatory"> *</span>}
                  </Label>
                  <AsyncPaginate
                    debounceTimeout={500}
                    value={state?.city}
                    loadOptions={loadCities}
                    onChange={data => inputSelectHandler(`city`, data)}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    noOptionsMessage={() => 'No results found'}
                    cacheUniqs={[['code']]}
                    placeholder="Select..."
                    isDisabled={state?.type === null}
                  />
                  {isValidationShow && state?.type !== null && state?.type?.value === `1` && state?.city === null && (
                    <Error text="Please select city" />
                  )}
                </div>
              </Col>

              <Col xl={8} lg={8} md={8} sm={6} xs={12}>
                <div className="mb-3">
                  <Label className="form-label">
                    Image
                    <span className="lbl_mandatory"> *</span>
                  </Label>
                  <Input
                    type="file"
                    name="uploaded-coupon"
                    id="uploaded-coupon"
                    value={state?.file}
                    onChange={e => uploadImageHandler(e)}
                    className="form-control"
                    disabled={state?.type === null}
                  />
                  {isValidationShow && state?.type !== null && state?.image?.trim() === `` && <Error text="Please upload image" />}
                </div>
              </Col>

              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="mb-3">
                  <Label className="form-label">
                    Post Link
                    <span className="lbl_mandatory"> *</span>
                  </Label>
                  <Input
                    id="link"
                    name="link"
                    type="text"
                    className="form-control"
                    onChange={e => inputSelectHandler(`link`, e)}
                    value={state?.link}
                    disabled={state?.type === null}
                    autoComplete="off"
                  />
                  {isValidationShow && state?.type !== null && (state?.link?.trim() === `` || !isValidUrl(state?.link?.trim())) && (
                    <Error text="Please fill valid post link" />
                  )}
                </div>
              </Col>

              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="mb-3">
                  <Label htmlFor="text" className="form-label">
                    Post Text
                    <span className="lbl_mandatory"> *</span>
                  </Label>
                  <Input
                    id="text"
                    name="text"
                    type="textarea"
                    className="form-control"
                    onChange={e => inputSelectHandler(`text`, e)}
                    value={state?.text}
                    autoComplete="off"
                    rows={4}
                    disabled={state?.type === null}
                  />
                  {isValidationShow && state?.type !== null && state?.text?.trim() === `` && <Error text="Please enter post text" />}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter className="border border-top-1 d-flex justify-content-center">
        <div className="hstack gap-2">
          <Button
            color="light"
            onClick={() => {
              onCloseHandler(false);
            }}
          >
            Close
          </Button>
          <Button type="submit" color="success" onClick={submitHandler}>
            Submit
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default CreateAnnouncement;
