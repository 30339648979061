import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Modal, ModalBody, Button, Form, Label, Input } from 'reactstrap';

import { Error } from '../../../../../../Common';
import { GET_TEMPLATE_LIST_EMPTY, SEND_COMMUNICATION, SEND_COMMUNICATION_EMPTY } from '../../../../../../../store/actions';

const TestModal = ({ templateDetails, currentConfig, isShowTestModal, setIsShowTestModal }) => {
  const dispatch = useDispatch();
  const toggle = () => setIsShowTestModal();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [fields, setFields] = useState([]);
  const [isValidationShow, setIsValidationShow] = useState(false);
  const [state, setState] = useState({ senderNumber: '', senderEmail: '' });

  useEffect(() => {
    if (templateDetails !== null) {
      const { body_variables, subject_variables } = templateDetails?.template_variables ?? {};
      const variables = Array.from(new Set([...(body_variables || []), ...(subject_variables || [])]));
      let initialStateInArray = variables.map(v => ({ [v]: '' }));
      let initialState = initialStateInArray.reduce((acc, curr) => {
        return { ...acc, ...curr };
      }, {});
      setState({ ...state, ...initialState });
      setFields(variables);
    }
  }, [templateDetails]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value.trim(),
    });
  };

  const { sendComs } = useSelector(state => ({
    sendComs: state?.SendCommunication?.data,
  }));

  useEffect(() => {
    if (sendComs != null) {
      dispatch({
        type: SEND_COMMUNICATION_EMPTY,
      });
      toggle();
    }
  }, [sendComs]);

  function generateUniqueId() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const timestamp = Date.now().toString(36);
    let randomString = '';

    for (let i = 0; i < 16; i++) {
      randomString += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return randomString + timestamp;
  }

  const validateErrors = (data = state, flag) => {
    if (data?.senderNumber?.trim() !== `` && data?.senderEmail?.time?.trim() !== ``) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  const submitHandler = () => {
    if (validateErrors(state, 'submissionCheck')) {
      const sendComsPayload = {
        project_id: currentConfig?.project_id,
        request_id: generateUniqueId(),
        event_type: currentConfig?.event_type,
        channel: currentConfig?.channel,
        country_code: ['91'],
        phone_number: [state.senderNumber],
        to_email: [state.senderEmail],
        benefit_group_id: currentConfig?.benefit_group_id ? [currentConfig?.benefit_group_id] : null,
        payload: {
          ...state,
        },
      };
      dispatch({
        type: SEND_COMMUNICATION,
        payload: {
          data: sendComsPayload,
        },
      });
      dispatch({
        type: GET_TEMPLATE_LIST_EMPTY,
      });
    }
  };

  return (
    <Modal isOpen={isShowTestModal} toggle={toggle} size="xl">
      {/* <ModalHeader toggle={toggle}>Create Communication Config</ModalHeader> */}
      <div className="modal-header p-3 bg-soft-warning">
        <h5 className="modal-title" id="createboardModalLabel">
          Test Coms
        </h5>
        <Button type="button" onClick={toggle} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close"></Button>
      </div>
      <ModalBody>
        <Row>
          <Col xxl={12}>
            <div className="live-preview">
              <Form>
                <Row>
                  <Col md={4} className="mt-2">
                    <div className="mb-4">
                      <Label htmlFor="name" className="form-label">
                        Sender Number
                      </Label>
                      <Input
                        id="senderNumber"
                        name="senderNumber"
                        type="text"
                        className="form-control"
                        onChange={handleInputChange}
                        value={state.senderNumber || ''}
                        autoComplete="off"
                      />
                      {isValidationShow && state?.senderNumber?.trim() === `` && <Error text="Please enter sender number" />}
                    </div>
                  </Col>
                  <Col md={4} className="mt-2">
                    <div className="mb-4">
                      <Label htmlFor="name" className="form-label">
                        Sender Email
                      </Label>
                      <Input
                        id="senderEmail"
                        name="senderEmail"
                        type="text"
                        className="form-control"
                        onChange={handleInputChange}
                        value={state.senderEmail || ''}
                        autoComplete="off"
                      />
                      {isValidationShow && state?.senderEmail?.trim() === `` && <Error text="Please enter sender email" />}
                    </div>
                  </Col>
                  {fields?.map((variable, index) => (
                    <Col md={4} key={index} className="mt-2">
                      <div className="mb-4">
                        <Label htmlFor="name" className="form-label">
                          {variable}
                        </Label>
                        <Input
                          id={variable}
                          name={variable}
                          type="text"
                          className="form-control"
                          onChange={handleInputChange}
                          value={state[variable] || ''}
                          autoComplete="off"
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="text-center mt-4">
              <button
                type="submit"
                className="btn btn-primary"
                // disabled={isSubmitting}
                onClick={submitHandler}
              >
                Submit
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default TestModal;
