import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { Container } from 'reactstrap';

import Table from './Components/Tables/Table';
import Details from './Components/Details/Details';
import LogsModal from './Components/Modals/LogsModal';
import { Constant } from '../../../../Helpers/constant';
import LocationForm from '../AddLocation/Components/Form';
import { BreadCrumb, Loader } from './../../../../Common';
import CustomFilter from './Components/Filters/CustomFilter';
import { GET_CATEGORY, GET_LOCATION, UPDATE_LOCATION_EMPTY } from '../../../../../store/application/actionType';

const ManageLocation = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [isShowFilters, setIsShowFilters] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);

  const [currentSelectedDetails, setCurrentSelectedDetails] = useState([]);
  const [currentConfirmationModalActionFor, setCurrentConfirmationModalActionFor] = useState(null);
  const [isDetailsPage, setIsDetailsPage] = useState(false);
  const [isUpdateModalShow, setIsUpdateModalShow] = useState(false);

  const [isListShow, setIsListShow] = useState(true);
  const [isDetailsPageShow, setIsDetailsPageShow] = useState(false);
  const [isUpdateFormShow, setIsUpdateFormShow] = useState(false);

  const [isShowLogsModal, setIsShowLogsModal] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Facilities
  const { locations, locationsLoading } = useSelector(state => ({
    locations: state?.GetLocation?.data,
    locationsLoading: state?.GetLocation?.loading,
  }));

  // Update Facility
  const { updateLocation, updateLocationLoading } = useSelector(state => ({
    updateLocation: state?.UpdateLocation?.data,
    updateLocationLoading: state?.UpdateLocation?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_LOCATION,
      payload: {
        urlParam: {
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });

    dispatch({
      type: GET_CATEGORY,
      payload: {
        urlParam: {
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (updateLocation !== null) {
      dispatch({
        type: UPDATE_LOCATION_EMPTY,
      });

      setIsListShow(true);
      setIsDetailsPageShow(false);
      setIsUpdateFormShow(false);

      let urlParams = {};
      if (currentlyAppliedFilters) {
        urlParams = {
          name: currentlyAppliedFilters?.name === `` ? null : currentlyAppliedFilters?.name,
          merchantName: currentlyAppliedFilters?.merchantName === `` ? null : currentlyAppliedFilters?.merchantName,
          email: currentlyAppliedFilters?.email === `` ? null : currentlyAppliedFilters?.email,
          updateDate: currentlyAppliedFilters?.updateDate === `` ? null : moment(new Date(currentlyAppliedFilters?.updateDate)).format('YYYY-MM-DD'),
          categoryId: currentlyAppliedFilters?.categoryId === null ? null : currentlyAppliedFilters?.categoryId?.value,
          partnerId: currentlyAppliedFilters?.partnerId === null ? null : currentlyAppliedFilters?.partnerId?.value,
          countryId: currentlyAppliedFilters?.countryId === null ? null : currentlyAppliedFilters?.countryId?.value,
          subRegionId: currentlyAppliedFilters?.subRegionId === null ? null : currentlyAppliedFilters?.subRegionId?.value,
          regionId: currentlyAppliedFilters?.regionId === null ? null : currentlyAppliedFilters?.regionId?.value,
        };
      }

      dispatch({
        type: GET_LOCATION,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            ...urlParams,
          },
        },
      });
    }
  }, [updateLocation]);

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = filters => {
    setCurrentlyAppliedFilters(filters);
    setCurrentPageNumber(0);
    setIsShowFilters(false);

    const urlParams = {
      name: filters?.name === `` ? null : filters?.name,
      merchantName: filters?.merchantName === `` ? null : filters?.merchantName,
      email: filters?.email === `` ? null : filters?.email,
      updateDate: filters?.updateDate === `` ? null : moment(new Date(filters?.updateDate)).format('YYYY-MM-DD'),
      categoryId: filters?.categoryId === null ? null : filters?.categoryId?.value,
      partnerId: filters?.partnerId === null ? null : filters?.partnerId?.value,
      countryId: filters?.countryId === null ? null : filters?.countryId?.value,
      subRegionId: filters?.subRegionId === null ? null : filters?.subRegionId?.value,
      regionId: filters?.regionId === null ? null : filters?.regionId?.value,
    };

    dispatch({
      type: GET_LOCATION,
      payload: {
        urlParam: {
          pageIndex: 0,
          ...urlParams,
        },
      },
    });
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);

    let urlParams = {};
    if (currentlyAppliedFilters) {
      urlParams = {
        name: currentlyAppliedFilters?.name === `` ? null : currentlyAppliedFilters?.name,
        merchantName: currentlyAppliedFilters?.merchantName === `` ? null : currentlyAppliedFilters?.merchantName,
        email: currentlyAppliedFilters?.email === `` ? null : currentlyAppliedFilters?.email,
        updateDate: currentlyAppliedFilters?.updateDate === `` ? null : moment(new Date(currentlyAppliedFilters?.updateDate)).format('YYYY-MM-DD'),
        categoryId: currentlyAppliedFilters?.categoryId === null ? null : currentlyAppliedFilters?.categoryId?.value,
        partnerId: currentlyAppliedFilters?.partnerId === null ? null : currentlyAppliedFilters?.partnerId?.value,
        countryId: currentlyAppliedFilters?.countryId === null ? null : currentlyAppliedFilters?.countryId?.value,
        subRegionId: currentlyAppliedFilters?.subRegionId === null ? null : currentlyAppliedFilters?.subRegionId?.value,
        regionId: currentlyAppliedFilters?.regionId === null ? null : currentlyAppliedFilters?.regionId?.value,
      };
    }

    dispatch({
      type: GET_LOCATION,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          ...urlParams,
        },
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedDetails(params?.details);
    setCurrentConfirmationModalActionFor(params?.actionType);

    if (params?.actionType === `VIEW`) {
      setIsListShow(false);
      setIsDetailsPageShow(true);
      setIsUpdateFormShow(false);
    } else if (params?.actionType === `UPDATE`) {
      setIsListShow(false);
      setIsDetailsPageShow(false);
      setIsUpdateFormShow(true);
    } else if (params?.actionType === `LOGS`) {
      setIsShowLogsModal(true);
    }
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push('/dashboard');
    } else if (title === `Outlet`) {
      setIsListShow(true);
      setIsDetailsPageShow(false);
      setIsUpdateFormShow(false);

      let urlParams = {};
      if (currentlyAppliedFilters) {
        urlParams = {
          name: currentlyAppliedFilters?.name === `` ? null : currentlyAppliedFilters?.name,
          merchantName: currentlyAppliedFilters?.merchantName === `` ? null : currentlyAppliedFilters?.merchantName,
          email: currentlyAppliedFilters?.email === `` ? null : currentlyAppliedFilters?.email,
          updateDate: currentlyAppliedFilters?.updateDate === `` ? null : moment(new Date(currentlyAppliedFilters?.updateDate)).format('YYYY-MM-DD'),
          categoryId: currentlyAppliedFilters?.categoryId === null ? null : currentlyAppliedFilters?.categoryId?.value,
          partnerId: currentlyAppliedFilters?.partnerId === null ? null : currentlyAppliedFilters?.partnerId?.value,
          countryId: currentlyAppliedFilters?.countryId === null ? null : currentlyAppliedFilters?.countryId?.value,
          subRegionId: currentlyAppliedFilters?.subRegionId === null ? null : currentlyAppliedFilters?.subRegionId?.value,
          regionId: currentlyAppliedFilters?.regionId === null ? null : currentlyAppliedFilters?.regionId?.value,
        };
      }

      dispatch({
        type: GET_LOCATION,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            ...urlParams,
          },
        },
      });
    } else if (title === `Details`) {
      // do nothing
    } else if (title === `Update Details`) {
      // do nothing
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (locationsLoading || updateLocationLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [locationsLoading, updateLocationLoading]);

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        {/*  ----------------------------  MODAL'S ---------------------------- */}
        {isShowLogsModal && <LogsModal data={currentSelectedDetails} onCloseHandler={() => setIsShowLogsModal(false)} />}

        {/*  ----------------------------  UI'S ---------------------------- */}
        <Container fluid className="position-relative">
          {isListShow && (
            <>
              <BreadCrumb title="Outlet" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Outlet']} />

              {/* <Filter
                filterHandler={filterSubmitHandler}
                appliedFilters={currentlyAppliedFilters}
              /> */}

              <CustomFilter
                filterHandler={filterSubmitHandler}
                appliedFilters={currentlyAppliedFilters}
                show={isShowFilters}
                onCloseClick={() => setIsShowFilters(false)}
              />

              <Table
                title={`Outlet's Data`}
                data={locations?.results}
                totalData={locations?.count}
                pageHandler={paginationHandler}
                pageNumber={currentPageNumber}
                actionHandler={tableActionHandler}
                isShowFilterBtn={true}
                filterBtnHandler={() => setIsShowFilters(true)}
              />
            </>
          )}

          {isDetailsPageShow && (
            <>
              <BreadCrumb title="Outlet" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Outlet', 'Outlet Details']} />
              <Details details={currentSelectedDetails} />
            </>
          )}

          {isUpdateFormShow && (
            <>
              <BreadCrumb title="Outlet" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Outlet', 'Update Details']} />
              <LocationForm data={currentSelectedDetails} title="Update Outlet" />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default ManageLocation;
