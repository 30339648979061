import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { Constant } from '../../../../../../Helpers/constant';

import { Button, Modal, ModalBody, ModalHeader, Col, Label, Input, Row } from 'reactstrap';

import { UPDATE_BOOKING } from '../../../../../../../store/application/actionType';
import { Error } from '../../../../../../Common';

const ConfirmModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [state, setState] = useState([]);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [isValidationShow, setIsValidationShow] = useState(false);

  useEffect(() => {
    setState({
      ...data,
    });
  }, [data]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'remarks':
        updatedState = {
          ...state,
          remarks: value,
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'confirmationCode':
        updatedState = {
          ...state,
          confirmationCode: value,
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'confirmedTime':
        updatedState = {
          ...state,
          dateTimeOfService: {
            ...state?.dateTimeOfService,
            time: value,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      dispatch({
        type: UPDATE_BOOKING,
        payload: {
          data: {
            projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
            bookingTypeId: Constant?.BOOKING_TYPES?.golfBookings?.objectId,
            bookingsData: [
              {
                objectId: data?.bookingId,
                detailedBookingId: data?.detailedBookingId,
                // dateTimeOfService: {
                //   date: state?.dateTimeOfService?.date,
                //   time: state?.dateTimeOfService?.time,
                // },
                confirmedDate: state?.dateTimeOfService?.date,
                confirmedTime: state?.dateTimeOfService?.time,
                remarks: state.remarks,
                confirmationCode: state?.confirmationCode,
                status: Constant?.BOOKING_STATUS?.CONFIRM,
                process: `confirm-booking`,
              },
            ],
          },
        },
      });

      onCloseHandler(false);
      setmodal_varying1(!modal_varying1);
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data?.dateTimeOfService?.time !== `` && data?.confirmationCode?.trim() !== `` && data?.remarks?.trim() !== ``) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Confirm Booking
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>
      <ModalBody>
        {/* <button onClick={() => console.log({ data, state })}>Get state</button> */}
        <form>
          <Row>
            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="name" className="form-label">
                    Booking ID
                  </Label>
                  <Input className="form-control" value={data?.bookingId} disabled />
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="name" className="form-label">
                    User Name
                  </Label>
                  <Input className="form-control" value={data?.user?.name} disabled />
                </div>
              </Col>
            </div>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="name" className="form-label">
                    Confirmation No. <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input type="text" className="form-control" name="confirmationCode" value={state?.confirmationCode} onChange={inputChangeHandler} />
                </div>
                {isValidationShow && state?.confirmationCode?.trim() === `` && <Error text="Please enter confirmation no." />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="confirmedTime" className="form-label">
                    Confirmed Time <span className="lbl_mandatory">*</span>
                  </Label>
                  <div className="area_expandable">
                    <Input
                      name="confirmedTime"
                      value={state?.dateTimeOfService?.time}
                      className="form-control"
                      type="time"
                      onChange={inputChangeHandler}
                    />
                  </div>
                  {isValidationShow && state?.dateTimeOfService?.time === `` && <Error text="Please select time" />}
                </div>
              </div>
            </Col>

            {/* <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="date-of-play" className="form-label">
                    Confirmed Time <span className="lbl_mandatory">*</span>
                  </Label>
                  <div className="area_expandable">
                    <Input
                      value={state?.bookingDateTime}
                      className="form-control"
                      type="time"
                      onChange={(e) => {
                        setState({
                          ...state,
                          bookingDateTime: e.target.value,
                        })
                      }}
                    />
                  </div>
                </div>
              </Col>
            </div> */}

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="name" className="form-label">
                    Remarks <span className="lbl_mandatory">*</span>
                  </Label>
                  <textarea className="form-control" name="remarks" value={state?.remarks} onChange={inputChangeHandler} />
                </div>
                {isValidationShow && state?.remarks?.trim() === `` && <Error text="Please enter remarks" />}
              </div>
            </Col>
          </Row>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
