import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from './../../../../Containers/Config/index';
import { GET_WORKFLOWS_LIST, GET_WORKFLOWS_LIST_SUCCESS, GET_WORKFLOWS_LIST_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getWorkflowsList({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/booking-workflows`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload?.urlParam,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_WORKFLOWS_LIST_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_WORKFLOWS_LIST_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_WORKFLOWS_LIST_ERROR, payload: error });
  }
}

export function* watchGetWorkflowsList() {
  yield takeEvery(GET_WORKFLOWS_LIST, getWorkflowsList);
}

function* getWorkflowsListSaga() {
  yield all([fork(watchGetWorkflowsList)]);
}

export default getWorkflowsListSaga;
