import { Card, CardBody, Col, Row } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { AsyncPaginate } from 'react-select-async-paginate';
import { customHeaders, uaeProjectsBaseURL, baseUrl, indianProjectsBaseURL } from '../../../../../Config';
import { Constant } from '../../../../../Helpers/constant';
import { useEffect, useState } from 'react';
import { Loader } from '../../../../../Common';
const PricingViewerComponent = ({
  setFilteredInvoiceData,
  filteredInvoiceData,
  CURRENCY_NAME,
  searchFormData,
  setCostPriceUploadedDate,
  setSellPriceUploadedDate,
}) => {
  const [vendorDate, setVendorDate] = useState();
  const [clientDate, setClientDate] = useState();
  const [updateOn, setUpdateOn] = useState(false);

  const [loading, setLoading] = useState(false);

  const priceType = 1;
  let dateTo = undefined;
  let dateFrom = undefined;
  if (searchFormData?.searchType == 2) {
    dateTo = searchFormData?.dateTo?.[0] ?? '';
    dateFrom = searchFormData?.dateFrom?.[0] ?? '';
  } else {
    dateTo = searchFormData?.date?.[0] ?? '';
    dateFrom = searchFormData?.date?.[0] ?? '';
  }

  let to = dateTo ? moment(dateTo).format('DD MMM YYYY') : '';
  let from = dateFrom ? moment(dateFrom).format('DD MMM YYYY') : '';

  async function loadCostPriceUploadedOptions(search, loadedOptions) {
    return {
      options: filteredInvoiceData?.golfPriceViewer?.vendor?.uploadedDates?.map(data => {
        return {
          value: data,
          label: `${moment(data).format('YYYY-MM-DD')}`,
          completeData: data,
        };
      }),
      hasMore: false,
    };
  }

  async function loadSellPriceUploadedOptions(search, loadedOptions) {
    return {
      options: filteredInvoiceData?.golfPriceViewer?.client?.uploadedDates?.map(data => {
        return {
          value: data,
          label: `${moment(data).format('YYYY-MM-DD')}`,
          completeData: data,
        };
      }),
      hasMore: false,
    };
  }

  const vendorDataUpdate = e => {
    setUpdateOn(true);
    setVendorDate({ label: e?.label, value: e?.value });
    setCostPriceUploadedDate({ label: e?.label, value: e?.value });
  };
  const clientDataUpdate = e => {
    setUpdateOn(true);
    setClientDate({ label: e?.label, value: e?.value });
    setSellPriceUploadedDate({ label: e?.label, value: e?.value });
  };

  useEffect(() => {
    if (updateOn) {
      getPricingDetails(searchFormData);
    }
  }, [vendorDate, clientDate, updateOn]);

  const getPricingDetails = async values => {
    let data = {
      facilityId: values?.golfCourse?.value ?? '',
      serviceId: values?.service?.value ?? '',
      contractType: values?.contractType?.value ?? '',
      customerType: values?.customerType?.value ?? '',
      priceType: priceType.toString(),
      projectId: values?.program?.value ?? '',
      benefitGroupId: values?.benefitGroup?.value ?? '',
      amenitiesPresent: values?.amenitiesPresent ?? [],
      amenitiesNotPresent: [],
      // amenitiesPresent: values?.amenitiesPresent ?? [],
      // amenitiesNotPresent: values?.filterAmenitiesNotPresent ?? [],
      applicableDate: {
        isDateRangeSelected: values?.searchType == 2 ? true : false,
        isPeakDay: values?.searchType == 2 && values?.peakType == 1 ? true : false,
      },
      vendorUploadedOn: vendorDate?.value ?? '',
      clientUploadedOn: clientDate?.value ?? '',
    };

    let dateTo = undefined;
    let dateFrom = undefined;
    if (values?.searchType == 2) {
      dateTo = values?.dateTo?.[0] ?? '';
      dateFrom = values?.dateFrom?.[0] ?? '';
    } else {
      dateTo = values?.date?.[0] ?? '';
      dateFrom = values?.date?.[0] ?? '';
    }

    data.applicableDate['to'] = dateTo ? moment(dateTo).format('YYYY-MM-DD') : '';
    data.applicableDate['from'] = dateFrom ? moment(dateFrom).format('YYYY-MM-DD') : '';

    setLoading(true);
    const response = await fetch(`${indianProjectsBaseURL}/admin/get-price-detail`, {
      method: `POST`,
      url: `${indianProjectsBaseURL}/admin/services`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        'Project-Code': 'ADMIN_ACCESS',
      },
      body: JSON.stringify(data),
    });

    if (response?.ok) {
      setLoading(false);
      let response2 = await response.json();
      const invoiceData = response2?.results?.[0];
      setFilteredInvoiceData(invoiceData);
      // setPricingView(true);
    } else {
      setLoading(false);
      let sdd = await response.text().then(text => {
        return JSON.parse(text)?.message;
      });
      throw new Error(sdd);
    }
  };

  return (
    <>
      {loading && <Loader />}

      <CardBody>
        <div className="ddd d-flex">
          <div className="max-w181">
            <p className="fsc-1 mb-0">Golf Course</p>
            <h4 className="hc-1">{searchFormData?.golfCourse?.label}</h4>
          </div>
          <div className="max-w295">
            <p className="fsc-1 mb-0">Service</p>
            <h4 className="hc-1">{searchFormData?.service?.label}</h4>
          </div>
          <div className="max-w181">
            <p className="fsc-1 mb-0">Contract Type</p>
            <h4 className="hc-1">{searchFormData?.contractType?.label}</h4>
          </div>
          <div className="max-w181">
            <p className="fsc-1 mb-0">Customer Type</p>
            <h4 className="hc-1">{searchFormData?.customerType?.label}</h4>
          </div>
          <div className="max-w243">
            <p className="fsc-1 mb-0">Elements</p>
            <h4 className="hc-1">{searchFormData?.amenitiesPresentLabel?.join(', ')}</h4>
          </div>
          <div className="max-w181">
            <p className="fsc-1 mb-0">Date Range</p>
            <h4 className="hc-1">{from && to && `${from} to ${to}`}</h4>
          </div>
        </div>
        <Card className="card rounded border border-light">
          <CardBody>
            <h4 className="fs-4">Cost Price</h4>
            <div className="d-flex justify-content-between mt-3">
              <div>
                <p className="m-0 mb-2">Applicable Price Name</p>
                <h5 className="fs-6 fw-bold">{filteredInvoiceData?.golfPriceViewer?.vendor?.priceName ?? ''}</h5>
              </div>
              <div className="text-end">
                <p className="m-0 mb-2">Program Name</p>
                <h5 className="fs-6 fw-bold">{filteredInvoiceData?.golfPriceViewer?.vendor?.programName ?? ''}</h5>
              </div>
            </div>

            <div className="d-flex justify-content-between mt-3">
              <div>
                <p className="m-0 mb-2">Uploaded on</p>
                <div>
                  <AsyncPaginate
                    loadOptions={loadCostPriceUploadedOptions}
                    onChange={vendorDataUpdate}
                    placeholder="Select any one"
                    className="custom-selector"
                  />
                </div>
              </div>
              <div className="text-end">
                <p className="m-0 mb-2">Applicable from</p>
                <h5 className="fs-6 fw-bold">
                  {filteredInvoiceData?.golfPriceViewer?.vendor?.validity?.from &&
                    filteredInvoiceData?.golfPriceViewer?.vendor?.validity?.to &&
                    `${moment(filteredInvoiceData?.golfPriceViewer?.vendor?.validity?.from).format('DD MMM YYYY')} to ${moment(
                      filteredInvoiceData?.golfPriceViewer?.vendor?.validity?.to,
                    ).format('DD MMM YYYY')}`}
                </h5>
              </div>
            </div>

            <div className="mt-3">
              <table className="table table-bordered">
                <thead className="table-secondary">
                  <tr>
                    <th scope="col">Base Cost Price</th>
                    <th scope="col">Processing Fees</th>
                    <th scope="col">Telcom Charges</th>
                    <th scope="col">Other Charge</th>
                    <th scope="col">Tax</th>
                    <th scope="col">GDF</th>
                    <th scope="col">Final Cost Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{filteredInvoiceData?.golfPriceViewer?.vendor?.costPrice ?? ''}</td>
                    <td>{filteredInvoiceData?.golfPriceViewer?.vendor?.processingFee ?? ''}</td>
                    <td>{filteredInvoiceData?.golfPriceViewer?.vendor?.telecomCharges ?? ''}</td>
                    <td>{filteredInvoiceData?.golfPriceViewer?.vendor?.otherCharges ?? ''}</td>
                    <td>{filteredInvoiceData?.golfPriceViewer?.vendor?.tax ?? ''}</td>
                    <td>{filteredInvoiceData?.golfPriceViewer?.vendor?.gdf ?? ''}</td>
                    <td>{filteredInvoiceData?.golfPriceViewer?.vendor?.finalCost ?? ''}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="mt-5">
              <h4>More Charges</h4>
              <table className="table table-bordered mt-3">
                <thead className="table-secondary">
                  <tr>
                    <th scope="col">MRP</th>
                    <th scope="col">No Show Charges</th>
                    <th scope="col">Short Cancel Charges</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{filteredInvoiceData?.golfPriceViewer?.vendor?.mrp ?? ''}</td>
                    <td>{filteredInvoiceData?.golfPriceViewer?.vendor?.noShowCharges ?? ''}</td>
                    <td>{filteredInvoiceData?.golfPriceViewer?.vendor?.shortNoticeCancel ?? ''}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>

        <Card className="card rounded border border-light">
          <CardBody>
            <div className="d-flex justify-content-between mt-3">
              <div>
                <h4 className="fs-4">Sell Price</h4>
              </div>
              <div className="text-end">
                <p className="m-0 mb-2">Applicable Price Name</p>
                <h5 className="fs-6 fw-bold">{filteredInvoiceData?.golfPriceViewer?.client?.priceName ?? ''} </h5>
              </div>
            </div>

            <div className="d-flex justify-content-between mt-3">
              <div>
                <p className="m-0 mb-2">Uploaded on</p>
                <div className="position-relative">
                  <AsyncPaginate
                    // value={sellPriceUploadedDate}
                    loadOptions={loadSellPriceUploadedOptions}
                    onChange={clientDataUpdate}
                    placeholder="Select any one"
                    className="custom-selector"
                  />
                </div>
              </div>
              <div className="text-end">
                <p className="m-0 mb-2">Applicable from</p>
                <h5 className="fs-6 fw-bold">
                  {filteredInvoiceData?.golfPriceViewer?.client?.validity?.from &&
                    filteredInvoiceData?.golfPriceViewer?.client?.validity?.to &&
                    `${moment(filteredInvoiceData?.golfPriceViewer?.client?.validity?.from).format('DD MMM YYYY')} to ${moment(
                      filteredInvoiceData?.golfPriceViewer?.client?.validity?.to,
                    ).format('DD MMM YYYY')}`}
                </h5>
              </div>
            </div>

            <div className="mt-3">
              <table className="table table-bordered">
                <thead className="table-secondary">
                  <tr>
                    <th scope="col">Base Sell Price</th>
                    <th scope="col">Processing Fees</th>
                    <th scope="col">Telcom Charges</th>
                    <th scope="col">Other Charge</th>
                    <th scope="col">Tax</th>
                    <th scope="col">GDF</th>
                    <th scope="col">Final Cost Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{filteredInvoiceData?.golfPriceViewer?.client?.sellPrice ?? ''}</td>
                    <td>{filteredInvoiceData?.golfPriceViewer?.client?.processingFee ?? ''}</td>
                    <td>{filteredInvoiceData?.golfPriceViewer?.client?.telecomCharges ?? ''}</td>
                    <td>{filteredInvoiceData?.golfPriceViewer?.client?.otherCharges ?? ''}</td>
                    <td>{filteredInvoiceData?.golfPriceViewer?.client?.tax ?? ''}</td>
                    <td>{filteredInvoiceData?.golfPriceViewer?.client?.gdf ?? ''}</td>
                    <td>{filteredInvoiceData?.golfPriceViewer?.client?.finalCost ?? ''}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="mt-5">
              <h4>More Charges</h4>
              <table className="table table-bordered mt-3">
                <thead className="table-secondary">
                  <tr>
                    <th scope="col">No Show Charges</th>
                    <th scope="col">Short Cancel Charges</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{filteredInvoiceData?.golfPriceViewer?.client?.noShowCharges ?? ''}</td>
                    <td>{filteredInvoiceData?.golfPriceViewer?.client?.shortNoticeCancel ?? ''}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </CardBody>
    </>
  );
};

export default PricingViewerComponent;
