import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, indianProjectsBaseURL, uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { UPDATE_POST, UPDATE_POST_SUCCESS, UPDATE_POST_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* updatePost({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/adib/admin/post`,
      method: 'PUT',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      data: payload?.data,
      params: payload?.urlParams,
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({
        type: UPDATE_POST_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: UPDATE_POST_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: UPDATE_POST_ERROR, payload: error });
  }
}

export function* watchupdatePost() {
  yield takeEvery(UPDATE_POST, updatePost);
}

function* updatePostSaga() {
  yield all([fork(watchupdatePost)]);
}

export default updatePostSaga;
