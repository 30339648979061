import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import apiCall from '../../apiCall';
import { baseUrl, customHeaders, uaeProjectsBaseURL } from './../../../../Containers/Config/index';

import { VALIDATE_USER, VALIDATE_USER_SUCCESS, VALIDATE_USER_ERROR } from './../../actionType';
import { Constant } from '../../../../Containers/Helpers/constant';
import cogoToast from 'cogo-toast';

function* validateUser({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/admin/v1/validate-customer`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        'X-Requested-By': 'one-admin',
        'Project-Code': localStorage.getItem('bankCode') ?? 'MASTERCARD_KSA',
      },
      data: payload?.data,
    });

    if (response?.status === 200) {
      cogoToast.success(response?.data?.message);
      if (localStorage.getItem('bankCode')) {
        localStorage.setItem('landmarkAuthToken', response?.headers?.authorization);
      }
      yield put({ type: VALIDATE_USER_SUCCESS, payload: response });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({ type: VALIDATE_USER_ERROR, payload: response.error });
    }
  } catch (error) {
    yield put({ type: VALIDATE_USER_ERROR, payload: error });
  }
}

export function* watchValidateUser() {
  yield takeEvery(VALIDATE_USER, validateUser);
}

function* validateUserSaga() {
  yield all([fork(watchValidateUser)]);
}

export default validateUserSaga;
