import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import DataTable from 'react-data-table-component';

import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';
import { findGMUserLevel, hasPermission } from '../../../../../../Helpers/Helpers';
import { APPROVE_REJECT_RECORDS_EMPTY } from '../../../../../../../store/actions';
import { Constant } from '../../../../../../Helpers/constant';
import ActionConfirmationModal from './Modals/ActionConfirmationModal';
import RemarksModal from './Modals/RemarksModal';

const RecordedDataTable = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [recordData, setRecordData] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([]);

  const [currentSelectedData, setCurrentSelectedData] = useState(null);
  const [isShowActionConfirmationModal, setIsShowActionConfirmationModal] = useState(false);
  const [isRemarksModalShow, setIsRemarksModalShow] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // APPROVE REJECT
  const { approveReject } = useSelector(state => ({
    approveReject: state?.ApproveRejectRecords?.data,
  }));

  useEffect(() => {
    if (approveReject !== null) {
      setSelectedRecords([]);
      dispatch({
        type: APPROVE_REJECT_RECORDS_EMPTY,
      });
    }
  }, [approveReject]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const rowData = props?.data?.map(rowData => {
        let excelRowData = {};
        Object.entries(rowData?.excelDataSchema)?.forEach(([key, value], index) => {
          // console.log('testtt1222', key, value, rowData, rowData?.excelDataSchema?.[key]['value']);
          excelRowData = {
            ...excelRowData,
            [key]: rowData?.excelDataSchema?.[key]['value'],
          };
        });

        return { ...excelRowData, status: `${rowData?.status?.message}`, originalData: rowData };
      });

      setRecordData(rowData);
    } else {
      setRecordData([]);
    }
  }, [props?.data]);

  const findColumnsHandler = () => {
    const colData = Object.entries(props?.data[0]?.excelDataSchema).map(([key, value], index) => {
      // console.log(`data1 = ${key}: `, index, value, props?.data[0]?.excelDataSchema?.[key]?.['headerName']);

      return {
        name: `${props?.data[0]?.excelDataSchema?.[key]?.['headerName']}`,
        width: '200px',
        wrap: true,
        selector: row => row?.[key],
      };
    });

    if (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.EDIT_RECORD_SUPPLY_DATA_GM_REPORT)) {
      colData.unshift({
        name: 'Action',
        width: '150px',
        cell: row => (
          <div className="d-flex justify-content-center flex-column w-100">
            <button
              type="button"
              className="btn fw-normal"
              onClick={() => {
                actionBtnHandler(`EDIT`, row?.originalData);
              }}
              disabled={!props?.userLevelData?.isActionable}
            >
              <i className=" ri-edit-fill align-middle"></i>
              Edit
            </button>
          </div>
        ),
      });
    }

    colData.push({
      name: `Status`,
      width: '200px',
      wrap: true,
      selector: row => row?.['status'],
    });

    colData.push({
      name: 'Action',
      width: '200px',
      wrap: true,
      cell: row => (
        <div className="d-flex justify-content-center flex-column w-100">
          <div>
            {(hasPermission(PermissionConstants?.ALL_PERMISSIONS?.APPROVE_RECORD_SUPPLY_DATA_GM_REPORT) ||
              hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DIRECT_APPROVE_RECORD_SUPPLY_DATA_GM_REPORT)) && (
              <button
                type="button"
                className={`rounded-pill btn ${
                  showApprovedBtnAccordingToLevel(row?.originalData) ? 'btn-primary' : 'btn-outline-primary'
                } btn-sm mx-1`}
                onClick={() => {
                  approveRejectHandler(`APPROVE`, row?.originalData);
                }}
                disabled={checkCTADisablility(row?.originalData)}
              >
                {showApprovedBtnAccordingToLevel(row?.originalData) ? (
                  <>
                    <i className="ri-check-line align-middle"></i>
                    Approved
                  </>
                ) : (
                  <>Approve</>
                )}
              </button>
            )}

            {(hasPermission(PermissionConstants?.ALL_PERMISSIONS?.REJECT_RECORD_SUPPLY_DATA_GM_REPORT) ||
              hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DIRECT_APPROVE_RECORD_SUPPLY_DATA_GM_REPORT)) && (
              <button
                type="button"
                className={`rounded-pill btn ${showRejectedBtnAccordingToLevel(row?.originalData) ? 'btn-danger' : 'btn-outline-danger'} btn-sm mx-1`}
                onClick={() => {
                  approveRejectHandler(`REJECT`, row?.originalData);
                }}
                disabled={checkCTADisablility(row?.originalData)}
              >
                {showRejectedBtnAccordingToLevel(row?.originalData) ? (
                  <>
                    <i className="ri-check-line align-middle"></i>
                    Rejected
                  </>
                ) : (
                  <>Reject</>
                )}
              </button>
            )}
          </div>
        </div>
      ),
    });

    return colData;
  };

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `EDIT`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    }
  };

  /* ---------------------------- CHECKBOX HANDLER ---------------------------- */
  const handleRowSelected = state => {
    const selectRecords = state?.selectedRows;

    if (selectRecords.length > 0) {
      setSelectedRecords(selectRecords);
    } else {
      setSelectedRecords([]);
    }
  };

  /* ---------------------------- BULK UPDATE HANDLER ---------------------------- */
  const updateBulkHandler = label => {
    const records = selectedRecords?.map(record => record?.originalData);
    actionMiddleware(records, label);
  };

  /* ---------------------------- APPROVE REJECT HANDLER ---------------------------- */
  const approveRejectHandler = (label, data) => {
    actionMiddleware([data], label);
  };

  const actionMiddleware = (data, label) => {
    setCurrentSelectedData({
      data,
      label,
    });
    setIsShowActionConfirmationModal(true);
  };

  /* ---------------------------- APPROVE BTN ACCORDING TO LEVEL HANDLER ---------------------------- */
  const showApprovedBtnAccordingToLevel = rowData => {
    const userLevel = findGMUserLevel(`SUPPLY-DATA`);

    if (userLevel === `L2`) {
      if (rowData?.firstApproverAction?.action === 0) {
        return false;
      } else if (rowData?.firstApproverAction?.action === 1) {
        return true;
      }
    } else if (userLevel === `L3`) {
      if (rowData?.secondApproverAction?.action === 0) {
        return false;
      } else if (rowData?.secondApproverAction?.action === 1) {
        return true;
      }
    }
  };

  const checkCTADisablility = rowData => {
    const userLevel = findGMUserLevel(`SUPPLY-DATA`);

    if (!props?.userLevelData?.isActionable) {
      return true;
    } else {
      if (userLevel === 'L1') {
        return false;
      } else if (userLevel === `L2`) {
        if (rowData?.status?.code === 2) {
          return true;
        } else {
          return false;
        }
      } else if (userLevel === `L3`) {
        if (rowData?.status?.code === 1 && rowData?.firstApproverAction?.action === 0) {
          return false;
        } else if (rowData?.status?.code === 1 && rowData?.firstApproverAction?.action === 2) {
          return true;
        } else if (rowData?.status?.code === 1 && rowData?.firstApproverAction?.action === 1) {
          return false;
        } else if (rowData?.status?.code === 2) {
          return true;
        }
      }
    }
  };

  /* ---------------------------- REJECTED BTN ACCORDING TO LEVEL HANDLER ---------------------------- */
  const showRejectedBtnAccordingToLevel = rowData => {
    const userLevel = findGMUserLevel(`SUPPLY-DATA`);

    if (userLevel === `L2`) {
      if (rowData?.firstApproverAction?.action === 0) {
        return false;
      } else if (rowData?.firstApproverAction?.action === 2) {
        return true;
      }
    } else if (userLevel === `L3`) {
      if (rowData?.firstApproverAction?.action === 2) {
        return true;
      } else if (rowData?.secondApproverAction?.action === 0) {
        return false;
      } else if (rowData?.secondApproverAction?.action === 2) {
        return true;
      }
    }
  };

  /* ----------------------------  CLOSE ACTION MODAL CONFIRMATION HANDLER ---------------------------- */
  const onCloseActionConfirmationModalHandler = () => {
    setIsShowActionConfirmationModal(false);
    setCurrentSelectedData(null);
  };

  const onConfirmActionConfirmationHandler = data => {
    setIsRemarksModalShow(true);
    setIsShowActionConfirmationModal(false);
  };

  /* ----------------------------  CLOSE REMARKS MODAL HANDLER ---------------------------- */
  const onCloseRemarksModal = () => {
    setIsRemarksModalShow(false);
    setCurrentSelectedData(null);
  };

  const onSubmitRemarksModal = (currentSelectedData, remarks) => {
    let updatedData = [];

    if (currentSelectedData?.label === `APPROVE_ENTIRE_BATCH`) {
      props?.updateHandler(currentSelectedData?.label, null, remarks);
    } else {
      updatedData = currentSelectedData?.data?.map(data => ({ ...data, remarks: remarks }));
      props?.updateHandler(currentSelectedData?.label, updatedData, '');
    }
    setIsRemarksModalShow(false);
  };

  return (
    <>
      {isShowActionConfirmationModal && (
        <ActionConfirmationModal
          currentSelectedData={currentSelectedData}
          onCancel={onCloseActionConfirmationModalHandler}
          onConfirm={onConfirmActionConfirmationHandler}
          totalData={props?.totalData}
        />
      )}

      {isRemarksModalShow && (
        <RemarksModal currentSelectedData={currentSelectedData} onCancel={onCloseRemarksModal} onConfirm={onSubmitRemarksModal} />
      )}

      <div className="d-flex justify-content-between my-1">
        <div>
          {selectedRecords?.length > 0 && (
            <>
              {(hasPermission(PermissionConstants?.ALL_PERMISSIONS?.APPROVE_RECORD_SUPPLY_DATA_GM_REPORT) ||
                hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DIRECT_APPROVE_RECORD_SUPPLY_DATA_GM_REPORT)) && (
                <button
                  className={`btn rounded-3 mb-2 me-2 btn-outline-primary`}
                  onClick={() => updateBulkHandler(`APPROVE`)}
                  disabled={!props?.userLevelData?.isActionable}
                >
                  <span className="d-flex align-items-center">
                    <span className="flex-grow-1">Bulk Approve</span>
                  </span>
                </button>
              )}

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.REJECT_RECORD_SUPPLY_DATA_GM_REPORT) && (
                <button
                  className={`btn rounded-3 mb-2 me-2 btn-outline-primary`}
                  onClick={() => updateBulkHandler(`REJECT`)}
                  disabled={!props?.userLevelData?.isActionable}
                >
                  <span className="d-flex align-items-center">
                    <span className="flex-grow-1">Bulk Reject</span>
                  </span>
                </button>
              )}
            </>
          )}

          {(hasPermission(PermissionConstants?.ALL_PERMISSIONS?.APPROVE_RECORD_SUPPLY_DATA_GM_REPORT) ||
            hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DIRECT_APPROVE_RECORD_SUPPLY_DATA_GM_REPORT)) &&
            props?.userLevelData?.isActionable && (
              <button
                className={`btn rounded-3 mb-2 me-2 btn-outline-primary`}
                onClick={() => updateBulkHandler(`APPROVE_ENTIRE_BATCH`)}
                disabled={!props?.userLevelData?.isActionable}
              >
                <span className="d-flex align-items-center">
                  <span className="flex-grow-1">Approve Entire Batch</span>
                </span>
              </button>
            )}
        </div>

        {!hasPermission(PermissionConstants?.ALL_PERMISSIONS?.EDIT_RECORD_SUPPLY_DATA_GM_REPORT) && (
          <div style={{ width: '15vw' }}>
            <Select
              id="filters"
              name="filters"
              options={[
                {
                  value: 2,
                  label: `All records`,
                },
                {
                  value: 3,
                  label: `Approved`,
                },
                {
                  value: 4,
                  label: `Rejected`,
                },
              ]}
              value={props?.appliedFilter}
              onChange={data => props.onApplyFilter(data)}
            />
          </div>
        )}
      </div>

      {props?.totalData > 0 ? (
        <div>
          <DataTable
            columns={findColumnsHandler()}
            data={recordData}
            selectableRows={
              (hasPermission(PermissionConstants?.ALL_PERMISSIONS?.APPROVE_RECORD_SUPPLY_DATA_GM_REPORT) ||
                hasPermission(PermissionConstants?.ALL_PERMISSIONS?.REJECT_RECORD_SUPPLY_DATA_GM_REPORT) ||
                hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DIRECT_APPROVE_RECORD_SUPPLY_DATA_GM_REPORT)) &&
              props?.userLevelData?.isActionable
                ? true
                : false
            }
            selectableRowsHighlight={true}
            selectableRowDisabled={data => checkCTADisablility(data?.originalData)}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={approveReject === null ? false : true}
            customStyles={{
              headRow: {
                style: {
                  borderBottom: '1px solid #ddd',
                  backgroundColor: '#f9fafb',
                  fontWeight: '600',
                },
              },
              cells: {
                style: {
                  border: '0.5px solid #ddd',
                  borderBottom: '0px solid #ddd',
                },
              },
            }}
          />

          <div className="text-center mt-2 fs-6">
            Showing <b>{props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1}</b> to&nbsp;
            <b>{props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length}</b> of <b>{props?.totalData}</b> records
          </div>
        </div>
      ) : (
        <h5 className="text-center">Record not found!</h5>
      )}
    </>
  );
};

export default React.memo(RecordedDataTable, (prevProps, nextProps) => prevProps.data === nextProps.data);
