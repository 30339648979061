import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Row, Col, Button, Modal, ModalBody, ModalHeader, Table, Input } from 'reactstrap';
import { UPDATE_BOOKING } from '../../../../../../../../store/application/actionType';
import { Constant } from '../../../../../../../Helpers/constant';
import Flatpickr from 'react-flatpickr';

const UpdateModal = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */

  const [bookings, setBookings] = useState([]);

  const [modal_varying1, setmodal_varying1] = useState(true);

  const [isOthers, setIsOthers] = useState(false);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  useEffect(() => {
    if (props?.data !== null) {
      setBookings(props?.data);
      validationCheck(props?.data);
    }
  }, [props]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = (e, index) => {
    const { name, value } = e.target;

    let tempBooking = [];
    let selectedChangingBooking = null;

    switch (name) {
      case 'pickup-address':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],
          pickupLocation: {
            ...tempBooking[index]['pickupLocation'],
            address: value,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'pickup-emirates':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],
          pickupLocation: {
            ...tempBooking[index]['pickupLocation'],
            emirates: value,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'pickup-nickname':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],
          pickupLocation: {
            ...tempBooking[index]['pickupLocation'],
            nickName: value,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'pickup-region':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],
          pickupLocation: {
            ...tempBooking[index]['pickupLocation'],
            region: value,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'pickup-zipcode':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],
          pickupLocation: {
            ...tempBooking[index]['pickupLocation'],
            zipCode: value,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'drop-address':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],
          dropLocation: {
            ...tempBooking[index]['dropLocation'],
            address: value,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'drop-emirates':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],
          dropLocation: {
            ...tempBooking[index]['dropLocation'],
            emirates: value,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'drop-nickname':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],
          dropLocation: {
            ...tempBooking[index]['dropLocation'],
            nickName: value,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'drop-region':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],
          dropLocation: {
            ...tempBooking[index]['dropLocation'],
            region: value,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'drop-zipcode':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],
          dropLocation: {
            ...tempBooking[index]['dropLocation'],
            zipCode: value,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- VALIDATION HANDLER ---------------------------- */
  const validationCheck = data => {
    let flag = false;
    for (let i = 0; i < data?.length; i++) {
      if (
        data[i]?.pickupLocation?.address === `` ||
        data[i]?.pickupLocation?.emirates === `` ||
        data[i]?.pickupLocation?.nickName === `` ||
        data[i]?.pickupLocation?.region === `` ||
        data[i]?.pickupLocation?.zipCode === ``
      ) {
        flag = true;
        break;
      } else if (
        data[i]?.dropLocation?.address === `` ||
        data[i]?.dropLocation?.emirates === `` ||
        data[i]?.dropLocation?.nickName === `` ||
        data[i]?.dropLocation?.region === `` ||
        data[i]?.dropLocation?.zipCode === ``
      ) {
        flag = true;
        break;
      }
    }

    flag ? setIsSubmitBtnDisabled(true) : setIsSubmitBtnDisabled(false);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const requestBookings = bookings?.map(booking => {
      return {
        objectId: booking?.bookingId,
        detailedBookingId: booking?.detailedBookingId,
        pickupLocation: { ...booking?.pickupLocation },
        dropLocation: { ...booking?.dropLocation },
        process: `update-booking`,
      };
    });

    dispatch({
      type: UPDATE_BOOKING,
      payload: {
        data: {
          projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
          bookingTypeId: Constant?.BOOKING_TYPES?.pickupAndDropBookings?.objectId,
          bookingsData: requestBookings,
        },
      },
    });
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      scrollable={true}
      id="exampleModal"
      size="lg"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Update
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log({ bookings })}>get state</button> */}
        <form>
          <Row>
            <Col md={12} style={{ mixHeight: '500px' }}>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Booking ID</th>
                    <th>Name</th>
                    <th> Pickup Location Address</th>
                    <th> Pickup Location Emirates</th>
                    <th> Pickup Location Nick Name</th>
                    <th> Pickup Location Region</th>
                    <th> Pickup Location ZipCode</th>
                    <th> Drop Location Address</th>
                    <th> Drop Location Emirates</th>
                    <th> Drop Location Nick Name</th>
                    <th> Drop Location Region</th>
                    <th> Drop Location ZipCode</th>
                  </tr>
                </thead>
                <tbody>
                  {bookings?.map((booking, index) => (
                    <tr key={index}>
                      <th>{index + 1}.</th>
                      <td>
                        <span style={{ display: 'inline-block', width: '140px' }}>{booking?.bookingId}</span>
                      </td>
                      <td>
                        <span style={{ display: 'inline-block', width: '140px' }}>{booking?.user?.name}</span>
                      </td>

                      <td>
                        {
                          <Input
                            id="pickup-address"
                            name="pickup-address"
                            type="text"
                            placeholder=""
                            className="form-control"
                            style={{ width: '140px' }}
                            onChange={e => inputChangeHandler(e, index)}
                            value={booking?.pickupLocation?.address}
                          />
                        }
                      </td>

                      <td>
                        <Input
                          id="pickup-emirates"
                          name="pickup-emirates"
                          type="text"
                          placeholder=""
                          className="form-control"
                          style={{ width: '140px' }}
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.pickupLocation?.emirates}
                        />
                      </td>
                      <td>
                        {' '}
                        <Input
                          id="pickup-nickname"
                          name="pickup-nickname"
                          type="text"
                          placeholder=""
                          className="form-control"
                          style={{ width: '140px' }}
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.pickupLocation?.nickName}
                        />
                      </td>
                      <td>
                        {' '}
                        <Input
                          id="pickup-region"
                          name="pickup-region"
                          type="text"
                          placeholder=""
                          className="form-control"
                          style={{ width: '140px' }}
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.pickupLocation?.region}
                        />
                      </td>
                      <td>
                        {' '}
                        <Input
                          id="pickup-zipcode"
                          name="pickup-zipcode"
                          type="number"
                          onWheel={e => e.target.blur()}
                          placeholder=""
                          className="form-control"
                          style={{ width: '140px' }}
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.pickupLocation?.zipCode}
                        />
                      </td>
                      <td>
                        {' '}
                        <Input
                          id="drop-address"
                          name="drop-address"
                          type="text"
                          placeholder=""
                          className="form-control"
                          style={{ width: '140px' }}
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.dropLocation?.address}
                        />
                      </td>
                      <td>
                        <Input
                          id="drop-emirates"
                          name="drop-emirates"
                          type="text"
                          placeholder=""
                          className="form-control"
                          style={{ width: '140px' }}
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.dropLocation?.emirates}
                        />
                      </td>
                      <td>
                        <Input
                          id="drop-nickname"
                          name="drop-nickname"
                          type="text"
                          placeholder=""
                          className="form-control"
                          style={{ width: '140px' }}
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.dropLocation?.nickName}
                        />
                      </td>
                      <td>
                        <Input
                          id="drop-region"
                          name="drop-region"
                          type="text"
                          placeholder=""
                          className="form-control"
                          style={{ width: '140px' }}
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.dropLocation?.region}
                        />
                      </td>
                      <td>
                        <Input
                          id="drop-zipcode"
                          name="drop-zipcode"
                          type="number"
                          onWheel={e => e.target.blur()}
                          placeholder=""
                          className="form-control"
                          style={{ width: '140px' }}
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.dropLocation?.zipCode}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateModal;
