import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';

import { Card, CardBody, Col, Container, Form, Label, Row, Input, CardFooter, UncontrolledTooltip } from 'reactstrap';
import { PreviewCardHeader } from '../../../../../Common';
import { Constant } from '../../../../../Helpers/constant';
import ReactTooltip from 'react-tooltip';
import { GET_COUPON_PARTNER, GET_COUPON_FACILITY, GET_COUPON_SERVICE } from '../../../../../../store/application/actionType';

const ServiceWorthForm = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- REDUX STATES ---------------------------- */

  // Partners
  const { couponPartners } = useSelector(state => ({
    couponPartners: state?.GetCouponPartner?.data,
  }));

  // Facilities
  const { couponFacility } = useSelector(state => ({
    couponFacility: state?.GetCouponFacility?.data,
  }));

  // Services
  const { couponServices } = useSelector(state => ({
    couponServices: state?.GetCouponServices?.data,
  }));

  const initialState = {
    earnValue: 0,
    burnValue: ``,
    partner: null,
    facility: null,
    services: null,
  };

  const [state, setState] = useState(initialState);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);
  const [isRepeatDetailsDisabled, setIsRepeatDetailsDisabled] = useState(false);

  const [currentDisplayPartners, setCurrentDisplayPartners] = useState([]);
  const [currentDisplayFacilities, setCurrentDisplayFacilities] = useState([]);
  const [currentDisplayServices, setCurrentDisplayServices] = useState([]);

  const [isEarnAndBurnDisabled, setIsEarnAndBurnDisabled] = useState(true);
  const [isFacilityDisabled, setIsFacilityDisabled] = useState(true);
  const [isServiceDisabled, setIsServiceDisabled] = useState(true);

  useEffect(() => {
    if (couponPartners === null) {
      dispatch({
        type: GET_COUPON_PARTNER,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (couponPartners?.length > 0) {
      setCurrentDisplayPartners(
        couponPartners?.map(partner => {
          return {
            value: partner?.objectId,
            label: partner?.name,
          };
        }),
      );
    } else {
      setCurrentDisplayPartners([]);
    }

    if (couponFacility?.length > 0) {
      setCurrentDisplayFacilities(
        couponFacility?.map(facility => {
          return { value: facility?.objectId, label: facility?.name };
        }),
      );
      setIsFacilityDisabled(false);
    } else {
      setIsFacilityDisabled(true);
      setCurrentDisplayFacilities([]);
    }

    if (couponServices?.length > 0) {
      setCurrentDisplayServices(
        couponServices?.map(service => {
          return { value: service?.objectId, label: service?.name };
        }),
      );
      setIsServiceDisabled(false);
    } else {
      setIsServiceDisabled(true);
      setCurrentDisplayServices([]);
    }
  }, [couponPartners, couponFacility, couponServices]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'earnValue':
        updatedState = {
          ...state,
          earnValue: parseInt(value),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'burnValue':
        updatedState = {
          ...state,
          burnValue: parseInt(value),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  const inputPartnerHandler = data => {
    const updatedState = {
      ...state,
      partner: data,
      earnValue: 0,
      burnValue: ``,
      facility: null,
      services: null,
    };
    setState(updatedState);
    validationCheck(updatedState);

    dispatch({
      type: GET_COUPON_FACILITY,
      payload: {
        urlParam: {
          partnerId: data?.value,
        },
      },
    });

    dispatch({
      type: GET_COUPON_SERVICE,
      payload: {
        urlParam: {
          partnerId: data?.value,
        },
      },
    });
  };

  const inputFacilityHandler = data => {
    const updatedState = {
      ...state,
      facility: data,
      services: null,
    };
    setState(updatedState);
    validationCheck(updatedState);

    dispatch({
      type: GET_COUPON_SERVICE,
      payload: {
        urlParam: {
          partnerId: state?.partner?.value,
          facilityId: data?.value,
        },
      },
    });
  };

  const inputServiceHandler = data => {
    const updatedState = {
      ...state,
      earnValue: 0,
      burnValue: ``,
      services: data,
    };
    setState(updatedState);
    validationCheck(updatedState);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */

  /* ---------------------------- LOCAL STATES ---------------------------- */
  //   {
  //     "projectId": "projectX",
  //     "services": [
  //         {
  //             "serviceId": "abcd",
  //             "serviceName": "Airport",
  //             "earnValue": 200,
  //             "burnValue": 100
  //         },
  //         {
  //             "serviceId": "xyz",
  //             "serviceName": "Courier",
  //             "earnValue": 400,
  //             "burnValue": 200
  //         },
  //     ]
  // }

  const submitHandler = () => {
    const services = state?.services?.map(service => {
      return {
        serviceId: service?.value,
        serviceName: service?.label,
        earnValue: state?.earnValue,
        burnValue: state?.burnValue,
      };
    });

    const payload = {
      services: services,
    };

    props.onSubmit(payload);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data?.services?.length > 0) {
      setIsEarnAndBurnDisabled(false);
    } else {
      setIsEarnAndBurnDisabled(true);
    }

    if (data?.services?.length > 0 && data?.burnValue !== ``) {
      setIsSubmitBtnDisabled(false);
    } else {
      setIsSubmitBtnDisabled(true);
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Service Worth Creation" />
            {/* <button onClick={() => console.log(state)}>get state</button> */}
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="earnValue">
                          Partner
                        </Label>
                        <Select
                          value={state?.partner}
                          id="partner"
                          name="partner"
                          isMulti={false}
                          onChange={inputPartnerHandler}
                          options={currentDisplayPartners}
                          style={{
                            border: '4px solid #ced4da',
                            bordeRadius: '0.25rem',
                          }}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="earnValue">
                          Facility
                        </Label>
                        <Select
                          value={state?.facility}
                          id="facility"
                          name="facility"
                          isDisabled={isFacilityDisabled}
                          isMulti={false}
                          onChange={inputFacilityHandler}
                          options={currentDisplayFacilities}
                          style={{
                            border: '4px solid #ced4da',
                            bordeRadius: '0.25rem',
                          }}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="earnValue">
                          Services
                          <span className="lbl_mandatory">*</span>
                        </Label>
                        <Select
                          value={state?.services}
                          id="services"
                          name="services"
                          isDisabled={isServiceDisabled}
                          isMulti={true}
                          onChange={inputServiceHandler}
                          options={currentDisplayServices}
                          style={{
                            border: '4px solid #ced4da',
                            bordeRadius: '0.25rem',
                          }}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="earnValue">
                          Earn Value
                        </Label>
                        <Input
                          id="earnValue"
                          name="earnValue"
                          type="number"
                          onWheel={e => e.target.blur()}
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state?.earnValue}
                          disabled={isEarnAndBurnDisabled}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="burnValue">
                          Burn Value
                          <span className="lbl_mandatory">*</span>
                        </Label>
                        <Input
                          id="burnValue"
                          name="burnValue"
                          type="number"
                          onWheel={e => e.target.blur()}
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state?.burnValue}
                          disabled={isEarnAndBurnDisabled}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md={6} className="p-0">
                  <div className="start"></div>
                </Col>

                <Col md={6}>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary mx-3 overlay-disable" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
                      Create
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>

            <div
              style={{
                width: '100px',
                position: 'relative',
                zIndex: '999999999999',
              }}
            >
              <ReactTooltip />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ServiceWorthForm;
