import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { Constant } from '../../../../../../Helpers/constant';

import { Button, Modal, ModalBody, ModalHeader, Col, Label, Input, Row } from 'reactstrap';

import { GET_VENDORS_LIST_EMPTY, UPDATE_BOOKING } from '../../../../../../../store/application/actionType';

const VendorAssignModal = ({ modalFor, data, onCloseHandler, vendorsList }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    vendor: null,
  };
  const [state, setState] = useState(initialObj);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [modalData, setModalData] = useState(null);

  const [vendorsLists, setVendorsLists] = useState([]);

  useEffect(() => {
    if (vendorsList !== null) {
      setVendorsLists(
        vendorsList?.results?.map((vendor, vendorIndex) => {
          return {
            value: `${vendor.objectId}`,
            label: `${vendor.vendorName}`,
          };
        }),
      );
    }

    if (data?.vendor?.id !== '') {
      setState({
        vendor: {
          value: data?.vendor?.id,
          label: data?.vendor?.name,
        },
      });

      validationCheck({
        vendor: {
          value: data?.vendor?.id,
          label: data?.vendor?.name,
        },
      });
    } else {
      setState({
        vendor: null,
      });

      validationCheck({
        vendor: null,
      });
    }
  }, [data]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = null;

    if (label === `vendors`) {
      updatedState = {
        ...state,
        vendor: data,
      };
      setState(updatedState);
      validationCheck(updatedState);
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    dispatch({
      type: UPDATE_BOOKING,
      payload: {
        data: {
          projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
          bookingTypeId: Constant?.BOOKING_TYPES?.pickupAndDropBookings?.objectId,
          bookingsData: [
            {
              ...data,
              objectId: data?.bookingId,
              detailedBookingId: data?.detailedBookingId,
              vendor: {
                ...data?.vendor,
                id: state?.vendor?.value,
                name: state?.vendor?.label,
              },
              status: Constant?.BOOKING_STATUS?.PROCESSED,
              process: `update-booking`,
            },
          ],
        },
      },
    });

    dispatch({
      type: GET_VENDORS_LIST_EMPTY,
    });

    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data?.vendor !== null) {
      setIsSubmitBtnDisabled(false);
    } else {
      setIsSubmitBtnDisabled(true);
    }
  };

  const tog_varying1 = () => {
    dispatch({
      type: GET_VENDORS_LIST_EMPTY,
    });

    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
      //   scrollable={true}
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Assign Vendor
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log({ state })}>Get state</button> */}
        <form>
          <Row>
            <div className="mb-3">
              <div className="mb-3">
                <Col md={12}>
                  <div>
                    <Label htmlFor="name" className="form-label">
                      Booking ID
                    </Label>
                    <Input className="form-control" value={data?.bookingId} disabled />
                  </div>
                </Col>
              </div>

              <div className="mb-3">
                <Col md={12}>
                  <div>
                    <Label htmlFor="name" className="form-label">
                      User Name
                    </Label>
                    <Input className="form-control" value={data?.user?.name} disabled />
                  </div>
                </Col>
              </div>

              <Col md={12}>
                <div>
                  <Label htmlFor="pickup-address" className="form-label">
                    Vendors
                    <span className="lbl_mandatory"> *</span>
                  </Label>
                  <Select
                    id="vendor"
                    name="vendor"
                    value={state?.vendor}
                    options={vendorsLists}
                    onChange={data => inputSelectHandler(`vendors`, data)}
                  />
                </div>
              </Col>
            </div>
          </Row>
        </form>
      </ModalBody>

      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
          Assign
        </Button>
      </div>
    </Modal>
  );
};

export default VendorAssignModal;
