import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { Grid, _ } from 'gridjs-react';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import { PreviewCardHeader } from './../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import { PermissionConstants } from './../../../../../../Helpers/Constants/permissions';

const status = [
  { name: `Inactive`, id: 0 },
  { name: `Active`, id: 1 },
];

const GolfCourseSlots = props => {
  const [data, setData] = useState([]);

  const [serialColumnNumber, setSerialColumnNumber] = useState(0);

  useEffect(() => {
    if (props?.data?.length > 0) {
      setData(
        props?.data?.map((data, index) => {
          return [
            `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            data?.golfCourseName || `NA`,
            data?.entryName || `NA`,

            data?.startDate ? moment(`${data?.startDate}`).format('DD-MM-YYYY, h:mm A') : `NA`,

            data?.endDate ? moment(`${data?.endDate}`).format('DD-MM-YYYY, h:mm A') : `NA`,

            data?.remarks || `NA`,
            data?.createdAt ? moment(`${data?.createdAt}`).format('DD-MM-YYYY, h:mm A') : `NA`,
            status[data?.status]?.name || `NA`,
          ];
        }),
      );
    } else {
      setData([]);
    }
  }, [props]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, index) => {
    const currentSelectedIndex = Number(index.split('.')[0]) - 1 - props.pageNumber * Constant.TABLE_PAGE_SIZE;
    const selectedDetails = props?.data[currentSelectedIndex];

    if (flag === `DISABLE`) {
      props.actionHandler({
        details: selectedDetails,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader
              title={'Slots Unavailability'}
              primaryButton={Constant?.CURRENTLY_ASSIGNED_PERMISSIONS?.includes(
                PermissionConstants?.ALL_PERMISSIONS?.CREATE_UNAVAILABLE_GOLF_COURSE_SLOTS,
              )}
              primaryButtonText="Add New Unavailable Slots"
              primaryClickHandler={props?.primaryBtnHandler}
            />

            {/* ------------------------ TABLE DETAILS ------------------------ */}
            <CardBody>
              <div id="table-gridjs">
                <Grid
                  data={data}
                  columns={[
                    {
                      name: 'S.No.',
                      width: '60px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Golf Course Name',
                      width: '140',
                      formatter: (cell, row) => _(<span>{cell}</span>),
                    },

                    {
                      name: 'Entry Name',
                      width: '140',
                      formatter: (cell, row) => _(<span>{cell}</span>),
                    },

                    {
                      name: 'Start Date & Time',
                      width: '140',
                      formatter: (cell, row) => _(<span>{cell}</span>),
                    },

                    {
                      name: 'End Date & Time',
                      width: '140',
                      formatter: (cell, row) => _(<span>{cell}</span>),
                    },

                    {
                      name: 'Remarks',
                      width: '140',
                      formatter: (cell, row) => _(<span>{cell}</span>),
                    },

                    {
                      name: 'Created At',
                      width: '140',
                      formatter: (cell, row) => _(<span>{cell}</span>),
                    },

                    {
                      name: 'Status',
                      width: '140',
                      formatter: (cell, row) =>
                        _(<span className={row.cells[row?.cells?.length - 2]?.data === `Active` ? 'text-success' : 'text-danger'}>{cell}</span>),
                    },

                    {
                      name: 'Actions',
                      width: '120px',
                      formatter: (cell, row) =>
                        _(
                          <div className="d-flex justify-content-center flex-column">
                            {Constant?.CURRENTLY_ASSIGNED_PERMISSIONS?.includes(PermissionConstants?.ALL_PERMISSIONS?.DISABLE_GOLF_COURSE_SLOTS) &&
                              row.cells[row?.cells?.length - 2].data === 'Active' && (
                                <>
                                  <span
                                    className="btn btn-sm btn-primary mb-1"
                                    onClick={() => {
                                      actionBtnHandler(`DISABLE`, row.cells[serialColumnNumber]?.data);
                                    }}
                                  >
                                    Disable
                                  </span>
                                </>
                              )}
                          </div>,
                        ),
                    },
                  ]}
                  search={false}
                  sort={false}
                  fixedHeader={true}
                  height="400px"
                  pagination={{ enabled: false, limit: 5 }}
                />
              </div>
              {props.children}
            </CardBody>

            <CardFooter>
              <Row className="custom_pagination">
                <Col className="text-end">
                  <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    forcePage={props?.pageNumber}
                    pageCount={Math.ceil(props?.totalData / Constant.TABLE_PAGE_SIZE)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={event => {
                      props.pageHandler(event.selected);
                    }}
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default GolfCourseSlots;
