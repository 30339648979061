import React from 'react';
import classnames from 'classnames';
import { Nav, NavItem, NavLink, Progress } from 'reactstrap';

function Navigation(props) {
  return (
    <div className="w-100 d-flex justify-content-center">
      <div className="progress-nav mb-5 mt-3 w-75">
        <Progress value={props.progressbarvalue} style={{ height: '1px' }} />
        <Nav className="nav-pills progress-bar-tab custom-nav " role="tablist">
          <NavItem className="position-relative">
            <NavLink
              to="#"
              id="pills-gen-info-tab"
              className={classnames(
                {
                  active: props.activeTab === 1,
                  done: props.activeTab <= 4 && props.activeTab >= 0,
                },
                'rounded-pill',
              )}
              // onClick={() => {
              //   props.toggleTab(1, 0);
              // }}
              tag="button"
            >
              1
            </NavLink>
            <div
              className="position-absolute"
              style={{
                left: '50%',
                whiteSpace: 'pre',
                transform: 'translate(-50%, 0px)',
                marginTop: '20px',
                maxWidth: '100px', // Adjust this width as needed
              }}
            >
              <h5 className="mb-0 d-none d-md-block">Passenger Details</h5>
              <h6 className="mb-0 d-block d-md-none text-truncate">Passenger Details</h6>
            </div>
          </NavItem>

          {/* Second Navigation Item */}
          <NavItem className="position-relative">
            <NavLink
              to="#"
              id="pills-gen-info-tab"
              className={classnames(
                {
                  active: props.activeTab === 2,
                  done: props.activeTab <= 4 && props.activeTab > 1,
                },
                'rounded-pill',
              )}
              // onClick={() => {
              //   props.toggleTab(2, 50);
              // }}
              tag="button"
            >
              2
            </NavLink>
            <div
              className="position-absolute navgation-for-address"
              style={{
                left: '50%',
                whiteSpace: 'pre',
                transform: 'translate(-50%, 0px)',
                marginTop: '20px',
              }}
            >
              <h5 className="mb-0 d-none d-md-block">{props?.bookingType === 'Airport Transfer' ? 'Address & Flight Details' : 'Flight Details'}</h5>
              <h6 className="mb-0 d-block d-md-none text-truncate">
                {props?.bookingType === 'Airport Transfer' ? 'Address & Flight Details' : 'Flight Details'}
              </h6>
            </div>
          </NavItem>

          {/* Third Navigation Item */}
          <NavItem className="position-relative">
            <NavLink
              to="#"
              id="pills-gen-info-tab"
              className={classnames(
                {
                  active: props.activeTab === 3,
                  done: props.activeTab <= 4 && props.activeTab > 2,
                },
                'rounded-pill',
              )}
              // onClick={() => {
              //   props.toggleTab(3, 100);
              // }}
              tag="button"
            >
              3
            </NavLink>
            <div
              className="position-absolute"
              style={{
                left: '50%',
                whiteSpace: 'pre',
                transform: 'translate(-50%, 0px)',
                marginTop: '20px',
                maxWidth: '100px', // Adjust this width as needed
              }}
            >
              <h5 className="mb-0 d-none d-md-block">Summary</h5>
              <h6 className="mb-0 d-block d-md-none text-truncate">Summary</h6>
            </div>
          </NavItem>
        </Nav>
      </div>
    </div>
  );
}

export default Navigation;
