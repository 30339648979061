import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';

import Select from 'react-select';

import { Card, CardBody, Col, Container, Form, Label, Row, Input, CardFooter, UncontrolledTooltip } from 'reactstrap';
import { PreviewCardHeader } from '../../../../../Common';
import { Constant } from '../../../../../Helpers/constant';
import ReactTooltip from 'react-tooltip';
import { GET_BENEFIT_GROUPS, PROGRAM_USERS_LIST } from '../../../../../../store/application/actionType';
import { isValidAplha, isValidNumber } from '../../../../../Helpers/Helpers';

const eventRuleTypes = [
  { value: 1, label: `Custom` },
  { value: 2, label: `Birthday` },
  { value: 3, label: `Users` },
  { value: 4, label: `Benefit Groups` },
];

const EventForm = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Program Users
  const { programUsers } = useSelector(state => ({
    programUsers: state?.ProgramUsers?.data,
  }));

  // Benefit Groups
  const { benefitGroups } = useSelector(state => ({
    benefitGroups: state?.BenefitGroups?.data,
  }));

  // R & D
  const [asyncOptions, setAsyncOptions] = useState([]);

  /* ---------------------------- LOCAL STATES ---------------------------- */

  const initialState = {
    globalEventDetails: {
      id: 0,
      ruleName: '',
    },
    name: ``,
    isRepeat: false,
    repeatDetail: {
      value: 1,
      unit: ``,
    },
    coinAmount: ``,
    coinExpiry: ``,
    isActive: true,
    ruleExpiryDate: ``,
    eventDate: null,
    userId: [],
    benefitGroupId: ``,
  };

  const [state, setState] = useState(initialState);

  const [users, setUsers] = useState([]);
  const [benefitGroupsData, setBenefitGroupsData] = useState([]);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);
  const [isRepeatDetailsDisabled, setIsRepeatDetailsDisabled] = useState(false);

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (programUsers === null) {
      dispatch({
        type: PROGRAM_USERS_LIST,
        payload: {
          urlParam: {
            pageIndex: 0,
          },
        },
      });
    }

    if (benefitGroups === null) {
      dispatch({
        type: GET_BENEFIT_GROUPS,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (programUsers !== null) {
      setUsers(
        programUsers?.results?.map(user => {
          return {
            value: user.objectId,
            label: `${user.firstName} ${user.lastName}`,
          };
        }),
      );
    }
  }, [programUsers]);

  useEffect(() => {
    if (benefitGroups !== null) {
      setBenefitGroupsData(
        benefitGroups?.results?.map(benefitGroup => {
          return {
            value: benefitGroup.objectId,
            label: benefitGroup.name,
          };
        }),
      );
    }
  }, [benefitGroups]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'isRepeat':
        updatedState = {
          ...state,
          repeatDetail: {
            ...state.repeatDetail,
            // value: !state.isRepeat === false ? `` : state?.repeatDetail?.value,
            value: !state.isRepeat === false ? `` : 1,
          },
          isRepeat: !state.isRepeat,
        };
        setIsRepeatDetailsDisabled(!isRepeatDetailsDisabled);
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'repeatDetailUnit':
        updatedState = {
          ...state,
          repeatDetail: {
            value: parseInt(state?.repeatDetail?.value),
            unit: value,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'repeatDetailValue':
        updatedState = {
          ...state,
          repeatDetail: {
            value: parseInt(value),
            unit: state?.repeatDetail?.unit,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'coinAmount':
        updatedState = {
          ...state,
          coinAmount: isValidNumber(value) ? (value === '' ? '' : parseInt(value)) : state?.coinAmount,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'isActive':
        updatedState = {
          ...state,
          isActive: !state.isActive,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'name':
        updatedState = {
          ...state,
          name: isValidAplha(value) ? value : state?.name,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'coinExpiry':
        updatedState = {
          ...state,
          coinExpiry: isValidNumber(value) ? (value === '' ? '' : parseInt(value)) : state?.coinExpiry,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'ruleExpiry':
        updatedState = {
          ...state,
          ruleExpiryDate: new Date(date).toISOString(),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'eventDate':
        updatedState = {
          ...state,
          eventDate: new Date(date).toISOString(),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  const eventTypeInputHandler = data => {
    let updatedState = null;

    updatedState = {
      ...initialState,
      globalEventDetails: {
        id: data?.value,
        ruleName: data?.label,
      },
    };
    setState(updatedState);
    validationCheck(updatedState);
    setIsRepeatDetailsDisabled(false);
  };

  const userInputHandler = data => {
    let updatedState = null;
    updatedState = {
      ...state,
      userId: data,
    };
    setState(updatedState);
    validationCheck(updatedState);
  };

  const benefitGroupInputHandler = data => {
    let updatedState = null;
    updatedState = {
      ...state,
      benefitGroupId: data,
    };
    setState(updatedState);
    validationCheck(updatedState);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const transformedData = {
      ...state,
      userId: state.userId?.length > 0 ? state.userId.map(user => user.value) : [],
      benefitGroupId: state.benefitGroupId === `` ? `` : state.benefitGroupId.value,
    };

    props.onSubmit({
      ...transformedData,
    });
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data.globalEventDetails?.id === 1) {
      if (data.coinAmount !== `` && data.coinExpiry !== `` && data.ruleExpiryDate !== `` && data.eventDate !== null && data.name !== ``) {
        setIsSubmitBtnDisabled(false);
        // if (data.isRepeat === true && data.repeatDetail.unit !== ``) {
        //   setIsSubmitBtnDisabled(false)
        // } else if (data.isRepeat === false && data.repeatDetail.unit === ``) {
        //   setIsSubmitBtnDisabled(false)
        // } else {
        //   setIsSubmitBtnDisabled(true)
        // }
      } else {
        setIsSubmitBtnDisabled(true);
      }
    } else if (data.globalEventDetails?.id === 2) {
      if (data.coinAmount !== `` && data.coinExpiry !== `` && data.ruleExpiryDate !== `` && data.name !== ``) {
        setIsSubmitBtnDisabled(false);
        // if (data.isRepeat === true && data.repeatDetail.unit !== ``) {
        //   setIsSubmitBtnDisabled(false)
        // } else if (data.isRepeat === false && data.repeatDetail.unit === ``) {
        //   setIsSubmitBtnDisabled(false)
        // } else {
        //   setIsSubmitBtnDisabled(true)
        // }
      } else {
        setIsSubmitBtnDisabled(true);
      }
    } else if (data.globalEventDetails?.id === 3) {
      if (data.coinAmount !== `` && data.coinExpiry !== `` && data.name !== `` && data?.userId?.length > 0) {
        setIsSubmitBtnDisabled(false);
      } else {
        setIsSubmitBtnDisabled(true);
      }
    } else if (data.globalEventDetails?.id === 4) {
      if (data.coinAmount !== `` && data.coinExpiry !== `` && data.name !== `` && data?.benefitGroupId !== ``) {
        setIsSubmitBtnDisabled(false);
      } else {
        setIsSubmitBtnDisabled(true);
      }
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Event Rule Creation" />
            {/* <button onClick={() => console.log(state)}>get state</button> */}
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Row>
                      {/* <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="client" className="form-label">
                            Client
                          </Label>
                          <select
                            id="client"
                            name="client"
                            className="form-select"
                            value={state?.clientId}
                            disabled={true}
                          >
                            <option value={state?.clientId?.id}>
                              {state?.clientId?.name}
                            </option>
                          </select>
                        </div>
                      </Col> */}

                      {/* <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="program" className="form-label">
                            Program
                          </Label>
                          <select
                            id="program"
                            name="program"
                            className="form-select"
                            disabled={true}
                          >
                            <option value={state?.projectId?.id}>
                              {state?.projectId?.name}
                            </option>

                            {props?.data?.projects?.map((project, index) => (
                              <option key={index} value={project?.objectId}>
                                {project?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col> */}

                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="program" className="form-label">
                            Event Type <span className="lbl_mandatory">*</span>
                          </Label>
                          <Select id="eventType" name="eventType" options={eventRuleTypes} onChange={eventTypeInputHandler} />
                        </div>
                      </Col>

                      {[1, 2, 3, 4].includes(state.globalEventDetails.id) && (
                        <Col md={4}>
                          <div className="mb-3">
                            <Label className="form-label" htmlFor="name">
                              Name <span className="lbl_mandatory">*</span>
                            </Label>
                            <Input id="name" name="name" type="text" className="form-control" onChange={inputChangeHandler} value={state?.name} />
                          </div>
                        </Col>
                      )}
                    </Row>

                    {false && (
                      <>
                        <hr style={{ backgroundColor: '#9ea0a1' }} />
                        <Row>
                          <Col md={12}>
                            <div
                              style={{
                                // position: 'relative',
                                // top: '20px',
                                marginBottom: '10px',
                              }}
                            >
                              <Label className="form-label">Is Repeat &nbsp;</Label>
                              <div className="form-switch d-inline-block ">
                                <Input
                                  className="form-check-input code-switcher"
                                  type="checkbox"
                                  id="isRepeat"
                                  name="isRepeat"
                                  checked={state?.isRepeat}
                                  onChange={inputChangeHandler}
                                />
                              </div>
                            </div>
                          </Col>

                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="repeatDetailUnit" className="form-label">
                                Repeat Details in
                                <span className="lbl_mandatory">*</span>
                              </Label>
                              <select
                                id="repeatDetailUnit"
                                name="repeatDetailUnit"
                                className="form-select"
                                onChange={inputChangeHandler}
                                value={state?.repeatDetail?.unit}
                                disabled={!isRepeatDetailsDisabled}
                              >
                                <option value="">Choose...</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Yearly">Yearly</option>
                              </select>
                            </div>
                          </Col>

                          <Col md={4}>
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="repeatDetailValue">
                                Repeat Details Value
                                <span className="lbl_mandatory">*</span>
                              </Label>
                              <Input
                                id="repeatDetailValue"
                                name="repeatDetailValue"
                                type="number"
                                onWheel={e => e.target.blur()}
                                className="form-control"
                                onChange={inputChangeHandler}
                                value={state?.repeatDetail?.value}
                                disabled={true}
                                // disabled={!isRepeatDetailsDisabled}
                                // min={6}
                                // max={20}
                              />
                            </div>
                          </Col>
                        </Row>
                        <hr style={{ backgroundColor: '#9ea0a1' }} />
                      </>
                    )}

                    {[3].includes(state.globalEventDetails.id) && (
                      <>
                        <hr style={{ backgroundColor: '#9ea0a1' }} />
                        <Col md={4}>
                          <div className="mb-3">
                            <Label htmlFor="user" className="form-label">
                              Users <span className="lbl_mandatory">*</span>
                            </Label>
                            <Select
                              id="user"
                              name="user"
                              isMulti={true}
                              value={state?.userId}
                              options={users}
                              onChange={userInputHandler}
                              style={{
                                border: '4px solid #ced4da',
                                bordeRadius: '0.25rem',
                              }}
                            />
                          </div>
                        </Col>
                      </>
                    )}

                    {[4].includes(state.globalEventDetails.id) && (
                      <>
                        <hr style={{ backgroundColor: '#9ea0a1' }} />
                        <Col md={4}>
                          <div className="mb-3">
                            <Label htmlFor="benefitGroups" className="form-label">
                              Benefit Groups
                              <span className="lbl_mandatory">*</span>
                            </Label>
                            <Select
                              id="benefitGroups"
                              name="benefitGroups"
                              isMulti={false}
                              // value={state?.benfitGroups}
                              options={benefitGroupsData}
                              onChange={benefitGroupInputHandler}
                              style={{
                                border: '4px solid #ced4da',
                                bordeRadius: '0.25rem',
                              }}
                            />
                          </div>
                        </Col>
                      </>
                    )}

                    {[1, 2, 3, 4].includes(state.globalEventDetails.id) && (
                      <Col md={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="coinAmount">
                            Coin Amount <span className="lbl_mandatory">*</span>
                          </Label>
                          <Input
                            id="coinAmount"
                            name="coinAmount"
                            type="text"
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={state?.coinAmount}
                            min={6}
                            max={20}
                          />
                        </div>
                      </Col>
                    )}

                    {[1, 2, 3, 4].includes(state.globalEventDetails.id) && (
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="coinExpiry" className="form-label">
                            Coin Expiry <span className="lbl_mandatory">*</span>
                            <span to="#" className="fw-medium" id="length_tooltip">
                              <i className="ri-information-line"></i>
                            </span>
                            <UncontrolledTooltip placement="top" target="length_tooltip">
                              Coin expiry must be in months
                            </UncontrolledTooltip>
                          </Label>
                          <Input
                            id="coinExpiry"
                            name="coinExpiry"
                            type="text"
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={state?.coinExpiry}
                          />
                          {/* <div className="area_expandable">
                          <Flatpickr
                            className="form-control"
                            name="coinExpiry"
                            id="coinExpiry"
                            value={state?.coinExpiry}
                            onChange={(date) =>
                              datePickHandler(`coinExpiry`, date)
                            }
                            options={{
                              minDate: 'today',
                              defaultMinute: '59',
                              defaultHour: '23',
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i',
                            }}
                          />
                        </div> */}
                        </div>
                      </Col>
                    )}

                    {[1, 2].includes(state.globalEventDetails.id) && (
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="ruleExpiry" className="form-label">
                            Rule Expiry <span className="lbl_mandatory">*</span>
                          </Label>
                          <div className="area_expandable">
                            <Flatpickr
                              className="form-control"
                              name="ruleExpiry"
                              id="ruleExpiry"
                              value={state?.ruleExpiryDate}
                              onChange={date => datePickHandler(`ruleExpiry`, date)}
                              options={{
                                minDate: 'today',
                                defaultMinute: '59',
                                defaultHour: '23',
                                enableTime: true,
                                dateFormat: 'Y-m-d H:i',
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    )}

                    {[1].includes(state.globalEventDetails.id) && (
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="eventDate" className="form-label">
                            Event Date <span className="lbl_mandatory">*</span>
                          </Label>
                          <div className="area_expandable">
                            <Flatpickr
                              className={`form-control ${state?.globalEventDetails?.id !== 1 ? `overlay-disable` : `overlay-enabled`}`}
                              // className="form-control"
                              name="eventDate"
                              id="eventDate"
                              value={state?.eventDate}
                              onChange={date => datePickHandler(`eventDate`, date)}
                              disabled={state?.globalEventDetails?.id !== 1}
                              options={{
                                minDate: 'today',
                                defaultMinute: '00',
                                defaultHour: '00',
                                enableTime: true,
                                dateFormat: 'Y-m-d H:i',
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    )}

                    {[1, 2, 3, 4].includes(state.globalEventDetails.id) && (
                      <Col md={12}>
                        <div className="mb-3">
                          <Label className="form-label">Is Active &nbsp;</Label>
                          <div className="form-switch d-inline-block ">
                            <Input
                              className="form-check-input code-switcher"
                              type="checkbox"
                              id="isActive"
                              name="isActive"
                              checked={state?.isActive}
                              onChange={inputChangeHandler}
                            />
                          </div>
                        </div>
                      </Col>
                    )}
                  </Row>
                </Form>
              </div>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md={6} className="p-0">
                  <div className="start"></div>
                </Col>

                <Col md={6}>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary mx-3 overlay-disable" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
                      Create
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>

            <div
              style={{
                width: '100px',
                position: 'relative',
                zIndex: '999999999999',
              }}
            >
              <ReactTooltip />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EventForm;
