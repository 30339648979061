import cogoToast from 'cogo-toast';
import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { customHeaders, indianProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { Constant } from '../../../../Containers/Helpers/constant.js';
import apiCall from '../../apiCall.js';

import { POST_IMAGE, POST_IMAGE_SUCCESS, POST_IMAGE_ERROR } from './../../actionType.js';

function* postImage({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `https://prod-fab-api-gateway.thriwe.com/adib/generic/upload-media`,
      method: 'POST',
      data: payload.data,
      isFormData: true,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload?.urlParam,
      },
    });

    if (response.status === 200) {
      cogoToast.success('File uploaded successfully');
      yield put({
        type: POST_IMAGE_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(`${response?.response?.data?.message}`);
      yield put({ type: POST_IMAGE_ERROR, payload: response?.data?.message });
    }
  } catch (error) {
    yield put({ type: POST_IMAGE_ERROR, payload: error });
  }
}

export function* watchPostImage() {
  yield takeEvery(POST_IMAGE, postImage);
}

function* postImageSaga() {
  yield all([fork(watchPostImage)]);
}

export default postImageSaga;
