import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../../Containers/Config/index.js';
import { UPDATE_EXPENDITURE_RULE, UPDATE_EXPENDITURE_RULE_SUCCESS, UPDATE_EXPENDITURE_RULE_ERROR } from '../../../actionType';
import apiCall from '../../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../../Containers/Helpers/constant.js';

function* updateExpenditureRule({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/rules`,
      method: 'PUT',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        objectId: payload.data.objectId,
      },
      data: payload.data,
    });

    if (response.status === 200) {
      cogoToast.success(`Updated Successfully`);
      yield put({
        type: UPDATE_EXPENDITURE_RULE_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: UPDATE_EXPENDITURE_RULE_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: UPDATE_EXPENDITURE_RULE_ERROR, payload: error });
  }
}

export function* watchUpdateExpenditureRule() {
  yield takeEvery(UPDATE_EXPENDITURE_RULE, updateExpenditureRule);
}

function* updateExpenditureRuleSaga() {
  yield all([fork(watchUpdateExpenditureRule)]);
}

export default updateExpenditureRuleSaga;
