import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { customBaseURL, customHeaders, indianProjectsBaseURL, uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { GET_CLIENT_CUSTOMER, GET_CLIENT_CUSTOMER_SUCCESS, GET_CLIENT_CUSTOMER_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getClientCustomers({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/vendor/program-users`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        'Content-Type': 'application/json',
        'project-code': payload?.headers?.code,
      },
      params: {
        ...payload?.urlParam,
        pageSize: Constant?.TABLE_PAGE_SIZE,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_CLIENT_CUSTOMER_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_CLIENT_CUSTOMER_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_CLIENT_CUSTOMER_ERROR, payload: error });
  }
}

export function* watchGetClientCustomers() {
  yield takeEvery(GET_CLIENT_CUSTOMER, getClientCustomers);
}

function* getClientCustomersSaga() {
  yield all([fork(watchGetClientCustomers)]);
}

export default getClientCustomersSaga;
