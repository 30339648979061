import { GET_VENDORS, GET_VENDORS_SUCCESS, GET_VENDORS_ERROR, GET_VENDORS_EMPTY } from './../../actionType.js';

const initialState = {
  data: null,
  message: null,
  loading: false,
  success: false,
  error: false,
};

const GetVendors = (state = initialState, action) => {
  switch (action.type) {
    case GET_VENDORS:
      state = {
        ...state,
        // data: null,
        loading: true,
        success: false,
        error: false,
        message: null,
      };
      break;

    case GET_VENDORS_SUCCESS:
      state = {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
        error: false,
        message: null,
      };
      break;

    case GET_VENDORS_ERROR:
      state = {
        ...state,
        data: null,
        loading: false,
        success: false,
        error: true,
        message: action.payload,
      };
      break;

    case GET_VENDORS_EMPTY:
      state = { ...initialState };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default GetVendors;
