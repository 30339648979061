import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { Card, CardBody, Col, Row, Container, Label, CardFooter, Button } from 'reactstrap';
import { Constant } from '../../../../../../Helpers/constant';

const BookingDetails = ({ details }) => {
  const [bookingDetails, setBookingDetails] = useState([]);

  let lastUpdatedBy = `NA`;
  let confirmedBy = `NA`;
  let processedBy = `NA`;
  let cancelledBy = `NA`;
  let cancelledReason = `NA`;

  let confirmedAt = `NA`;
  let processedAt = `NA`;
  let cancelledAt = `NA`;
  let latestBookingStatus = details?.bookingStatusCode;

  details?.bookingModifyPersons?.forEach(element => {
    if (element?.bookingStatus === latestBookingStatus) {
      lastUpdatedBy = element?.name;
    }
    if (
      element?.bookingStatus === Constant?.BOOKING_STATUS?.CANCEL_BY_THRIWE ||
      element?.bookingStatus === Constant?.BOOKING_STATUS?.CANCEL_BY_USER ||
      element?.bookingStatus === Constant.BOOKING_STATUS?.SHORT_NOTICE_CANCELLED_BY_THRIWE
    ) {
      cancelledBy = element?.name;
      cancelledReason = element?.remarks;
      cancelledAt = moment(`${element?.modifiedAt}`).format('DD-MM-YYYY, h:mm A');
    }
    if (element?.bookingStatus === Constant?.BOOKING_STATUS?.CONFIRM) {
      confirmedBy = element?.name;
      confirmedAt = moment(`${element?.modifiedAt}`).format('DD-MM-YYYY, h:mm A');
    }
    if (element?.bookingStatus === Constant?.BOOKING_STATUS?.PROCESSED) {
      processedBy = element?.name;
      processedAt = moment(`${element?.modifiedAt}`).format('DD-MM-YYYY, h:mm A');
    }
  });

  useEffect(() => {
    setBookingDetails(details);
  }, [details]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      {/* <button onClick={() => console.log({ details })}>Get state</button> */}
      <Row>
        <Col lg={12}>
          <Card className="mt-n4 mx-n4 mb-n5">
            <div className="bg-soft-warning">
              <CardBody className="pb-4 mb-5">
                <Row>
                  <div className="col-md">
                    <Row className="align-items-center">
                      <div className="col-md-auto mb-1 mt-4"></div>
                      <div className="col-md">
                        <h4 className="fw-semibold" id="ticket-title">
                          {details?.bookingId || ''}
                        </h4>
                      </div>
                    </Row>
                  </div>
                </Row>
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Personal Details</h6>
              <div className="border mt-2 mb-3 border"></div>
              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Full Name'}</Label>
                    <Label className="text-muted">{details?.user?.name || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">User Contact Details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Mobile No.'}</Label>
                    <Label className="text-muted">{details?.user?.mobile || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Email'}</Label>
                    <Label className="text-muted">{details?.user?.email || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Insurance Details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Date of Enrolment'}</Label>
                    <Label className="text-muted">
                      {details?.dateOfEnrolment ? moment(`${details?.dateOfEnrolment}`).format('DD-MM-YYYY') : `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Insurance Starting Date'}</Label>
                    <Label className="text-muted">{`${details?.insuranceStartDate}`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'No. Of Adults'}</Label>
                    <Label className="text-muted">{details?.numberOfAdults || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'No. Of Childs'}</Label>
                    <Label className="text-muted">{details?.numberOfChild || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Insured Person Details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Name'}</Label>
                    <Label className="text-muted">{details?.insuredPersonDetails?.name || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Gender'}</Label>
                    <Label className="text-muted">{Constant?.GENDER_TYPES[details?.insuredPersonDetails?.gender?.type - 1] || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'DOB'}</Label>
                    <Label className="text-muted">
                      {details?.insuredPersonDetails?.dateOfBirth
                        ? moment(`${details?.insuredPersonDetails?.dateOfBirth}`).format('DD-MM-YYYY')
                        : `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Relation'}</Label>
                    <Label className="text-muted">{details?.insuredPersonDetails?.relation || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Nationality'}</Label>
                    <Label className="text-muted">{details?.insuredPersonDetails?.nationality || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Country Of Residency'}</Label>
                    <Label className="text-muted">{details?.insuredPersonDetails?.countryOfResidence || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Spouse Details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Name'}</Label>
                    <Label className="text-muted">{details?.spouseDetails?.name || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Gender'}</Label>
                    <Label className="text-muted">{Constant?.GENDER_TYPES[details?.spouseDetails?.gender?.type - 1] || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'DOB'}</Label>
                    <Label className="text-muted">
                      {details?.spouseDetails?.dateOfBirth ? moment(`${details?.spouseDetails?.dateOfBirth}`).format('DD-MM-YYYY') : `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Relation'}</Label>
                    <Label className="text-muted">{details?.spouseDetails?.relation || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Nationality'}</Label>
                    <Label className="text-muted">{details?.spouseDetails?.nationality || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Country Of Residency'}</Label>
                    <Label className="text-muted">{details?.spouseDetails?.countryOfResidence || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Child Details</h6>
              <div className="border mt-2 mb-3 border"></div>

              {details?.childrenDetails?.map((child, index) => (
                <Row key={index}>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="d-flex">{'Name'}</Label>
                      <Label className="text-muted">{child?.name || `NA`}</Label>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="d-flex">{'Gender'}</Label>
                      <Label className="text-muted">{Constant?.GENDER_TYPES[child?.gender?.type - 1] || `NA`}</Label>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="d-flex">{'DOB'}</Label>
                      <Label className="text-muted">{child?.dateOfBirth ? moment(`${child?.dateOfBirth}`).format('DD-MM-YYYY') : `NA`}</Label>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="d-flex">{'Relation'}</Label>
                      <Label className="text-muted">{child?.relation || `NA`}</Label>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="d-flex">{'Nationality'}</Label>
                      <Label className="text-muted">{child?.nationality || `NA`}</Label>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="d-flex">{'Country Of Residency'}</Label>
                      <Label className="text-muted">{child?.countryOfResidence || `NA`}</Label>
                    </div>
                  </Col>

                  {details?.childrenDetails?.length - 1 !== index && <hr />}
                </Row>
              ))}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Payment details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Order Id'}</Label>
                    <Label className="text-muted">{details?.payment?.orderId || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Payment Id'}</Label>
                    <Label className="text-muted">{details?.payment?.paymentId || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Invoice No.'}</Label>
                    <Label className="text-muted">{details?.payment?.invoiceNumber || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Booking Details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Country'}</Label>
                    <Label className="text-muted">{details?.country || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'State'}</Label>
                    <Label className="text-muted">{details?.region || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'City'}</Label>
                    <Label className="text-muted">{details?.subregion || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Category'}</Label>
                    <Label className="text-muted">{details?.category?.name || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Partner'}</Label>
                    <Label className="text-muted">{details?.partner?.name || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Facility'}</Label>
                    <Label className="text-muted">{details?.facility?.name || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Service'}</Label>
                    <Label className="text-muted">{details?.service?.name || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Creation and service</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Date and time of service'}</Label>
                    <Label className="text-muted">{`${details?.insuranceStartDate}`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Date & Time of booking creation'}</Label>
                    <Label className="text-muted">{details?.createdAt ? moment(`${details?.createdAt}`).format('DD-MM-YYYY, h:mm A') : 'NA'}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Action Date and time</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Booking Processed At'}</Label>
                    <Label className="text-muted">{processedAt}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Booking Confirmed At'}</Label>
                    <Label className="text-muted">{confirmedAt}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Booking Cancelled At'}</Label>
                    <Label className="text-muted">{cancelledAt}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Action By</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Last Updated By'}</Label>
                    <Label className="text-muted">{lastUpdatedBy}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Processed By'}</Label>
                    <Label className="text-muted">{processedBy}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Confirmed By'}</Label>
                    <Label className="text-muted">{confirmedBy}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Cancelled By'}</Label>
                    <Label className="text-muted">{cancelledBy}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Updated At'}</Label>
                    <Label className="text-muted">{moment(`${details?.updatedAt}`).format('DD-MM-YYYY, h:mm A') || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Remarks and Reason</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Remarks'}</Label>
                    <Label className="text-muted">{details?.remarks || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Reason for cancellation'}</Label>
                    <Label className="text-muted">{cancelledReason}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default BookingDetails;
