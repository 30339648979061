import { useEffect, useState } from 'react';

import moment from 'moment';
import cogoToast from 'cogo-toast';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';

import { Constant } from '../../../../../../Helpers/constant';
import { PreviewCardHeader, TablePagination } from '../../../../../../Common';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';
import { formattedWithComma, hasPermission } from '../../../../../../Helpers/Helpers';

const logsInfoStyle = {
  cursor: 'pointer',
  position: 'relative',
  top: ' 3px',
  fontSize: '17px',
  left: '3px',
  zIndex: '0',
};

const Table = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColums = [
        {
          name: 'S.No',
          width: '90px',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
        },
        {
          name: 'System ID',
          selector: row => <span className="fw-medium">{row?.systemId}</span>,
          width: '150px',
        },
        {
          name: 'Name',
          wrap: true,
          width: '250px',
          selector: row => row?.displayName,
        },
        {
          name: 'Category',
          wrap: true,
          width: '200px',
          selector: row => row?.category,
        },
        {
          name: 'Brand',
          wrap: true,
          width: '180px',
          selector: row => row?.partner,
        },
        {
          name: 'Country',
          wrap: true,
          width: '150px',
          selector: row => row?.country,
        },
        {
          name: 'Region',
          wrap: true,
          width: '180px',
          selector: row => row?.region,
        },
        {
          name: 'Sub Region',
          wrap: true,
          width: '150px',
          selector: row => row?.subRegion,
        },
        {
          name: 'Booking Type',
          wrap: true,
          width: '200px',
          selector: row => row?.bookingType,
        },
        {
          name: 'Last Updated',
          width: '220px',
          cell: row => (
            <span>
              {row?.data?.updatedAt ? moment(new Date(`${row?.data?.updatedAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA'}

              <span
                style={logsInfoStyle}
                onClick={() => {
                  actionBtnHandler(`LOGS`, row?.data);
                }}
              >
                <i className="ri-information-fill"></i>
              </span>
            </span>
          ),
        },
        {
          name: 'Actions',
          width: '200px',
          cell: row => (
            <div className="d-flex justify-content-center flex-column w-100">
              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CATEGORY_UPDATE) && (
                <span
                  className="btn btn-sm btn-primary mb-1 d-inline-block w-100"
                  onClick={() => {
                    actionBtnHandler(`UPDATE`, row?.data);
                  }}
                  style={{ width: '100%' }}
                >
                  Update
                </span>
              )}
            </div>
          ),
        },
      ];
      setColumns(fetchColums);
      setData(
        props?.data?.map((data, index) => {
          return {
            serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            systemId: data?.objectId || `NA`,
            displayName: data?.displayName || `NA`,
            category: data?.category?.name || `NA`,
            partner: data?.partner?.name || `NA`,
            country: data?.country?.name || `NA`,
            region: data?.region?.name || `NA`,
            subRegion: data?.subRegion?.name || `NA`,
            bookingType: data?.bookingType?.name || `NA`,
            data: data || 'NA',
            data: data,
          };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `VIEW`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `UPDATE`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `INFO`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `LOGS`) {
      if (data?.logs?.length > 0) {
        props.actionHandler({
          details: data,
          actionType: flag,
        });
      } else {
        cogoToast.error('No records found');
      }
    }
  };

  return (
    <>
      <Row>
        <Col lg={12}>
          <PreviewCardHeader title={props.title} isShowFilterBtn={props?.isShowFilterBtn} filterBtnHandler={props?.filterBtnHandler} />
          <Card>
            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
