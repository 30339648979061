import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import fetch from '../../fetch.js';
import { baseUrl, customHeaders, commsHeader, indianProjectsBaseURL, uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';

import { VENDORS_LIST, VENDORS_LIST_SUCCESS, VENDORS_LIST_ERROR } from '../../actionType.js';
import apiCall from '../../apiCall.js';
import { Constant } from '../../../../Containers/Helpers/constant.js';
import cogoToast from 'cogo-toast';

function* getVendorsList({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/admin/v1/vendors`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        'Content-Type': 'application/json',
        'Project-Code': `ADMIN_ACCESS`,
        ...commsHeader,
      },
      params: {
        ...payload?.urlParam,
      },
    });

    if (response.status === 200) {
      yield put({ type: VENDORS_LIST_SUCCESS, payload: response?.data });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({ type: VENDORS_LIST_ERROR, payload: response?.data?.message });
    }
  } catch (error) {
    yield put({ type: VENDORS_LIST_ERROR, payload: error });
  }
}

export function* watchGetVendorsList() {
  yield takeEvery(VENDORS_LIST, getVendorsList);
}

function* vendorsListSaga() {
  yield all([fork(watchGetVendorsList)]);
}

export default vendorsListSaga;
