import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { ASK_QUESTION, ASK_QUESTION_EMPTY } from '../../../../../../../../store/actions';

const QuestionModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [value, setValue] = useState('');

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Post Questions
  const { askQuestion } = useSelector((state: any) => ({
    askQuestion: state?.AskQuestion?.data,
  }));

  const submitHandler = () => {
    const userId = JSON.parse(localStorage.getItem('authUser'))?.data?.userId;

    dispatch({
      type: ASK_QUESTION,
      payload: {
        data: {
          externalId: data?.id,
          question: value,
          type: 2,
          adminId: userId,
        },
        urlParams: {
          email_id: data?.email,
        },
      },
    });
  };

  useEffect(() => {
    if (askQuestion) {
      onCloseHandler();
      return () => {
        dispatch({
          type: ASK_QUESTION_EMPTY,
        });
      };
    }
  }, [askQuestion]);

  return (
    <Modal isOpen={true}>
      <ModalHeader
        className="bg-soft-info p-3"
        toggle={() => {
          onCloseHandler(false);
        }}
      >
        Question Ask
      </ModalHeader>

      <ModalBody>
        <Label className="form-label">Ask More Questions</Label>
        <Input type="textarea" onChange={e => setValue(e.target?.value)} rows={5} />
      </ModalBody>

      <ModalFooter className="justify-content-center">
        <Button className="btn btn-success mb-1" onClick={submitHandler} disabled={value?.trim() === '' ? true : false}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default QuestionModal;
