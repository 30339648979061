import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from '../../../../Common';
import { Container } from 'reactstrap';

import Table from './Components/Tables/Table';
import {
  CREATE_INTEGRATION_DATA_EMPTY,
  GET_INTEGRATION_DATA,
  GET_INTEGRATION_DATA_EMPTY,
  GET_PROJECTS_LIST,
  UPDATE_INTEGRATION_DATA_EMPTY,
} from '../../../../../store/application/actionType';
import { Constant } from '../../../../Helpers/constant';
import DataIntegrationForm from './Components/Forms/DataIntegrationForm';

const DataIntegration = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [isMainPage, setIsMainPage] = useState(true);
  const [isIntegrationFormShow, setIsIntegrationFormShow] = useState(false);

  const [formStatus, setFormStatus] = useState(null);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentSelectedData, setCurrentSelectedData] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Data Integration
  const { getDataIntegration, getDataIntegrationLoading } = useSelector(state => ({
    getDataIntegration: state?.IntegrationData,
    getDataIntegrationLoading: state?.IntegrationData?.loading,
  }));

  // Projects List
  const { projectsList, projectsListLoading } = useSelector(state => ({
    projectsList: state?.ProjectsList?.data,
    projectsListLoading: state?.ProjectsList?.loading,
  }));

  // Create Data Integration
  const { createIntegration, createIntegrationLoading } = useSelector(state => ({
    createIntegration: state?.CreateIntegrationData?.data,
    createIntegrationLoading: state?.CreateIntegrationData?.loading,
  }));

  // Update Data Integration
  const { updateIntegration, updateIntegrationLoading } = useSelector(state => ({
    updateIntegration: state?.UpdateIntegrationData?.data,
    updateIntegrationLoading: state?.UpdateIntegrationData?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_PROJECTS_LIST,
      payload: {
        urlParam: {
          pageIndex: currentPageIndex,
          pageSize: Constant?.TABLE_PAGE_SIZE,
        },
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (getDataIntegration?.error) {
      setIsMainPage(false);
      setIsIntegrationFormShow(true);
      setFormStatus('CREATE');

      dispatch({
        type: GET_INTEGRATION_DATA_EMPTY,
      });
    } else if (getDataIntegration?.data !== null) {
      setIsMainPage(false);
      setIsIntegrationFormShow(true);

      if (getDataIntegration?.data?.results?.length > 0) {
        setFormStatus('UPDATE');
      } else {
        setFormStatus('CREATE');
      }
    }
  }, [getDataIntegration]);

  useEffect(() => {
    if (createIntegration !== null) {
      setIsMainPage(true);
      setIsIntegrationFormShow(false);

      dispatch({
        type: CREATE_INTEGRATION_DATA_EMPTY,
      });

      dispatch({
        type: GET_PROJECTS_LIST,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            pageSize: Constant?.TABLE_PAGE_SIZE,
          },
        },
      });
    }
  }, [createIntegration]);

  useEffect(() => {
    if (updateIntegration !== null) {
      setIsMainPage(true);
      setIsIntegrationFormShow(false);

      dispatch({
        type: UPDATE_INTEGRATION_DATA_EMPTY,
      });

      dispatch({
        type: GET_INTEGRATION_DATA_EMPTY,
      });

      dispatch({
        type: GET_PROJECTS_LIST,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            pageSize: Constant?.TABLE_PAGE_SIZE,
          },
        },
      });
    }
  }, [updateIntegration]);

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedData(params?.details);

    if (params?.actionType === `MANAGE`) {
      dispatch({
        type: GET_INTEGRATION_DATA,
        payload: {
          urlParam: {
            filterQuery: {
              projectCode: params?.details?.code,
            },
          },
        },
      });
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);

    dispatch({
      type: GET_PROJECTS_LIST,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          pageSize: Constant?.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (projectsListLoading || createIntegrationLoading || updateIntegrationLoading || getDataIntegrationLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [projectsListLoading, createIntegrationLoading, updateIntegrationLoading, getDataIntegrationLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Programs List`) {
      setIsMainPage(true);
      setIsIntegrationFormShow(false);
      dispatch({
        type: GET_INTEGRATION_DATA_EMPTY,
      });
    }
  };

  return (
    <>
      {loading && <Loader />}
      {/* <button onClick={() => console.log(currentlyAppliedFilters)}>
        get State
      </button> */}

      <div className="page-content">
        <Container fluid className="position-relative">
          {isMainPage && (
            <>
              <BreadCrumb title="Programs List" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Programs List`]} />
              <Table
                data={projectsList?.results}
                totalData={projectsList?.count}
                pageNumber={currentPageNumber}
                dataStartedFrom={currentPageIndex}
                pageHandler={paginationHandler}
                actionHandler={tableActionHandler}
              />
            </>
          )}

          {isIntegrationFormShow && (
            <>
              <BreadCrumb
                title="Programs List"
                navigationby={breadcrumNavigationHandler}
                navigation={[`Dashboard`, `Programs List`, `Update Data Integration`]}
              />
              <DataIntegrationForm data={getDataIntegration?.data?.results} formStatus={formStatus} currentProject={currentSelectedData} />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default DataIntegration;
