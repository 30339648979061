import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardBody, Col, Container, Input, Label, Row, Table } from 'reactstrap';
import { PreviewCardHeader } from '../../../../../../Common';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Constant } from '../../../../../../Helpers/constant';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';

const RealTimeSlots = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [headers, setHeaders] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (props?.data?.slotDates?.length > 0) {
      // Headers
      const tempHeaders = ['S.No.', 'Time'];

      props?.data?.slotDates?.forEach(dateSlotsData => {
        tempHeaders.push(moment(`${dateSlotsData.date}`).format('Do MMMM YYYY, dddd'));
      });
      setHeaders(tempHeaders);

      // Data
      let timings = [];
      props?.data?.slotDates?.forEach(slots => {
        slots?.slots?.forEach(slot => {
          timings.push(slot.slotTime);
        });
      });
      timings = [...new Set(timings)].sort();

      const completeData = [];
      timings.forEach((time, indexTime) => {
        const row = [{ serialNo: ++indexTime }, { slotTime: time }];
        props?.data?.slotDates?.forEach(slots => {
          const tempSlot = slots?.slots?.filter(slot => slot.slotTime === time);

          if (tempSlot.length > 0) {
            row.push({ ...tempSlot[0] });
          } else {
            row.push(null);
          }
        });
        completeData.push(row);
      });

      setData(completeData);
    }
  }, [props]);

  return (
    <Col md={12}>
      <Card>
        {/* <button onClick={() => console.log(allTimeSlots)}>get state</button> */}
        <PreviewCardHeader
          title="Real Time Slots"
          primaryButton={Constant?.CURRENTLY_ASSIGNED_PERMISSIONS?.includes(PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_GOLF_COURSE_SLOT_REPORT)}
          primaryButtonText="Download Report"
          primaryClickHandler={props?.downloadReport}
        />
        <CardBody>
          <div className="live-preview">
            <div className="table-responsive">
              <Table className="table-bordered table-striped table-nowrap align-middle table-nowrap mb-0 text-center">
                <thead>
                  <tr>
                    {headers?.map((headerName, index) => {
                      return (
                        <th key={index} scope="col">
                          {headerName}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {data?.map((rowData, rowIndex) => {
                    const d = rowData.map((colData, colIndex) => {
                      if (colIndex === 0) {
                        return <td key={colIndex}>{colData?.serialNo}.</td>;
                      } else if (colIndex === 1) {
                        return <td key={colIndex}>{colData?.slotTime}</td>;
                      } else {
                        return <td key={colIndex}>{colData !== null ? colData?.availableCount : 'NA'}</td>;
                      }
                    });
                    return <tr key={rowIndex}>{d}</tr>;
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default RealTimeSlots;
