import { useSelector, useDispatch } from 'react-redux';

import { CLIENT_LOGIN, VENDOR_LOGIN } from '../../../../../store/actions';
import LoginForm from './Components/LoginForm';
import FullLoader from '../../../../Common/Loader/CompleteLoader';
import jwt_decode from 'jwt-decode';

import { Redirect, withRouter, Link, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Constant } from '../../../../Helpers/constant';

const VendorLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Login
  const { vendorLogin, vendorLoginLoading } = useSelector(state => ({
    vendorLogin: state?.VendorLogin?.data,
    vendorLoginLoading: state?.VendorLogin?.loading,
  }));

  useEffect(() => {
    if (vendorLogin !== null) {
      localStorage.setItem('userType', JSON.stringify(Constant?.userTypes?.VENDOR ?? -1));

      localStorage.setItem(
        'authUser',
        JSON.stringify({
          status: 'success',
          token: vendorLogin?.headers?.authorization,
          data: {
            ...vendorLogin?.data?.user,
          },
          permissions: ['VENDOR_DASHBOARD', 'VENDOR_DOWNLOAD_DUMP', 'VENDOR_BOOKING_MANAGEMENT', 'VENDOR_AIRPORT_TRANSFER'],
        }),
      );

      window.localStorage.setItem(
        'data',
        JSON.stringify({
          program: {
            id: 'IaQlNnHgJr',
            name: 'ENBD',
            internalName: '',
            code: 'ENBD_V3',
            logo: '',
            url: '',
            configId: 'JjOfOscNo0',
            client: {
              id: 'qeOwLFd3O9',
              name: 'Enbd',
              contractingCountry: { id: 231, name: 'United Arab Emirates' },
              isActive: true,
            },
            bookingTypes: [],
            contractingCountry: { id: '', name: '' },
          },
        }),
      );

      history.push(`/vendor/dashboard`);
      window.location.reload();
    }
  }, [vendorLogin]);

  const onLogin = data => {
    dispatch({
      type: VENDOR_LOGIN,
      payload: {
        data: {
          ...data,
        },
      },
    });
  };

  useEffect(() => {
    if (![null, undefined, -1].includes(window.localStorage.getItem('authUser'))) {
      let redirectionRoute = `/`;
      const userType = parseInt(JSON.parse(window.localStorage.getItem('userType') ?? -1));
      if (userType === Constant?.userTypes?.ADMIN_USER) {
        redirectionRoute = `/dashboard`;
      } else if (userType === Constant?.userTypes?.CLIENT) {
        redirectionRoute = `/client/dashboard`;
      } else if (userType === Constant?.userTypes?.VENDOR) {
        redirectionRoute = `/vendor/dashboard`;
      } else {
        redirectionRoute = `/dashboard`;
      }
      history.push(`${redirectionRoute}`);
    }
  }, []);

  return (
    <>
      {vendorLoginLoading && <FullLoader />}
      <LoginForm onSubmit={onLogin} />
    </>
  );
};

export default VendorLogin;
