import React, { useEffect, useState } from 'react';

import moment from 'moment';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';

import { TablePagination } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import { formattedWithComma, hasPermission } from '../../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';

const Table = props => {
  const [currentFilteredBookingTitle, setCurrentFilterBookingTitle] = useState('Bookings');

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      getBookingStatusTitleHandler(parseInt(props?.appliedFilters?.status));

      const fetchColums = [
        {
          name: 'S.No',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
          width: '90px',
        },
        {
          name: 'Booking ID',
          selector: row => row?.bookingId,
          wrap: true,
          width: '160px',
        },
        {
          name: 'Membership ID',
          selector: row => row?.membershipId,
          wrap: true,
          width: '220px',
        },
        {
          name: 'User Name',
          selector: row => row?.name,
          wrap: true,
          width: '170px',
        },
        {
          name: 'CA Number',
          selector: row => row?.caNumber,
          wrap: true,
          width: '150px',
        },
        {
          name: 'Coupon Code',
          selector: row => row?.couponCode,
          width: '200px',
          wrap: true,
        },
        {
          name: 'Pin',
          selector: row => row?.pin,
          width: '140px',
          wrap: true,
        },
        {
          name: 'User Mobile',
          selector: row => row?.userMobile,
          width: '140px',
          wrap: true,
        },
        {
          name: 'User Email',
          selector: row => row?.userEmail,
          width: '200px',
          wrap: true,
        },
        {
          name: 'Category',
          selector: row => row?.categoryName,
          width: '180px',
          wrap: true,
        },
        {
          name: 'Partner',
          selector: row => row?.partnerName,
          width: '200px',
          wrap: true,
        },
        {
          name: 'Facility',
          selector: row => row?.facilityName,
          width: '200px',
          wrap: true,
        },
        {
          name: 'Service',
          selector: row => row?.serviceName,
          width: '300px',
          wrap: true,
        },
        {
          name: 'Voucher Worth',
          selector: row => row?.voucherWorth,
          width: '160px',
          wrap: true,
        },
        {
          name: 'Coupon Expiry',
          selector: row => row?.couponCodeExpiry,
          width: '190px',
          wrap: true,
        },
        {
          name: 'Created At',
          selector: row => row?.updatedAt,
          width: '190px',
          wrap: true,
        },
        {
          name: 'Voucher Status',
          width: '160px',
          cell: row => row.voucherStatus,
        },
        {
          name: 'Status',
          width: '160px',
          cell: row => (
            <span
              className={`booking_status badge break-word bg-${
                Constant.BOOKING_STATUSES.filter(status => status?.name === row?.bookingStatus)[0]?.bgColor
              }`}
            >
              {row.bookingStatus}
            </span>
          ),
        },
      ];
      setColumns(fetchColums);

      setData(
        props?.data?.map((data, index) => {
          const status = Constant?.BOOKING_STATUSES?.filter(statusObj => statusObj.objectId === data?.bookingStatusCode)[0]?.name;
          return {
            serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            bookingId: data?.bookingId || `NA`,
            membershipId: data?.membershipId || `NA`,
            name: data?.user?.name || `NA`,
            caNumber: data?.user?.caNumber || `NA`,
            couponCode: data?.couponCode || `NA`,
            pin: data?.pin || `NA`,
            userMobile: data?.user?.mobile || `NA`,
            userEmail: data?.user?.email || `NA`,
            categoryName: data?.category?.name || `NA`,
            partnerName: data?.partner?.name || `NA`,
            facilityName: data?.facility?.name || `NA`,
            serviceName: data?.service?.name || `NA`,
            voucherWorth: data?.voucherWorth || `NA`,

            couponCodeExpiry:
              data?.couponCodeExpiry === '0001-01-01T00:00:00Z' ? 'NA' : moment(new Date(`${data?.couponCodeExpiry}`)).format('DD-MM-YYYY, h:mm A'),
            updatedAt: data?.updatedAt ? moment(new Date(`${data?.updatedAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            bookingStatus: status,
            data,
            voucherStatus: data?.thirdPartyStatus?.status || `NA`,
          };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- BOOKING TABLE TITLE HANDLER ---------------------------- */
  const getBookingStatusTitleHandler = bookingsStatusCode => {
    let bookingsTitle = 'Bookings';

    if (bookingsStatusCode !== 0) {
      Constant?.BOOKING_STATUSES?.forEach(bookingStatus => {
        if (bookingsStatusCode === bookingStatus?.objectId) {
          bookingsTitle = `${bookingStatus?.name} Bookings`;
        }
      });
    }
    setCurrentFilterBookingTitle(bookingsTitle);
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{currentFilteredBookingTitle}</h4>

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_ACCESS) && props?.data?.length > 0 && (
                <span className="btn btn-success mb-1" onClick={props?.dumpDownload}>
                  <i className="ri-download-line me-1 text-white fs-14 align-middle"></i>Download dump
                </span>
              )}
            </CardHeader>

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Table);
