import { useEffect, useState } from 'react';

import moment from 'moment';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, Col, Row, Label, CardHeader } from 'reactstrap';

import { Constant } from '../../../../../../Helpers/constant';
import { TablePagination } from '../../../../../../Common';
import { formattedWithComma } from '../../../../../../Helpers/Helpers';

const BenefitPacksTable = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColums = [
        {
          name: 'S.No.',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
          width: '90px',
        },
        {
          name: 'Membership ID',
          selector: row => row?.membershipId,
          wrap: true,
          width: '250px',
        },
        {
          name: 'Customer Name',
          selector: row => row?.name,
          wrap: true,
          minWidth: '250px',
        },
        {
          name: 'Uploaded On',
          selector: row => row?.createdAt,
          wrap: true,
          width: '250px',
        },
      ];
      setColumns(fetchColums);

      setData(
        props?.data?.map((data, index) => {
          return {
            serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            membershipId: data?.membershipId || `NA`,
            name: data?.customer?.name || `NA`,
            createdAt: data?.createdAt ? moment(new Date(`${data?.createdAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            data,
          };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Information</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={3}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Benefit Group Name'}</Label>
                    <Label className="text-muted">{props?.data?.length > 0 ? props?.data?.[0]?.benefitGroup?.name : ``}</Label>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="mb-3">
                    <Label className="d-flex">{'User Name'}</Label>
                    <Label className="text-muted">{props?.data?.length > 0 ? props?.data?.[0]?.user?.name : ``}</Label>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Applicable Date'}</Label>
                    <Label className="text-muted">
                      {props?.data?.length > 0
                        ? props?.data?.[0]?.applicableDate
                          ? moment(`${props?.data?.[0]?.applicableDate}`).format('DD-MM-YYYY, h:mm A')
                          : ``
                        : ``}
                    </Label>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Expiry Date'}</Label>
                    <Label className="text-muted">
                      {props?.data?.length > 0
                        ? props?.data?.[0]?.expiryDate
                          ? moment(`${props?.data?.[0]?.expiryDate}`).format('DD-MM-YYYY, h:mm A')
                          : ``
                        : ``}
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{`Benefit Pack Information`}</h4>
            </CardHeader>

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default BenefitPacksTable;
