import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, indianProjectsBaseURL, uaeProjectsBaseURL,clientCustomHeaders } from '../../../../Containers/Config/index.js';
import { CREATE_BOOKING, CREATE_BOOKING_SUCCESS, CREATE_BOOKING_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* createBooking({ payload,endpoint=`/vendor/bookings` }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}${endpoint}`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...clientCustomHeaders(),
        ...payload?.headers,
        'x-user-service': 'UMS',
      },
      data: payload?.data,
      params: payload?.urlParams,
    });

    if (response.status === 200) {
      yield put({
        type: CREATE_BOOKING_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: CREATE_BOOKING_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: CREATE_BOOKING_ERROR, payload: error });
  }
}

export function* watchCreateBooking() {
  yield takeEvery(CREATE_BOOKING, createBooking);
}

function* createBookingSaga() {
  yield all([fork(watchCreateBooking)]);
}

export default createBookingSaga;
