import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { Constant } from '../../../../../../Helpers/constant';

import { Button, Modal, ModalBody, ModalHeader, Col, Label, Input, Row } from 'reactstrap';

import { UPDATE_BOOKING } from '../../../../../../../store/application/actionType';
import { isValidAlphaNumberic, isValidAlphaNumbericWithoutSpace, isValidAplha, isValidNumber } from '../../../../../../Helpers/Helpers';
import { Error } from '../../../../../../Common';

const UpdateBookingModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    referenceNumber: '',
    remarks: '',
    pickupLocation: {
      nickName: '',
      address: '',
      emirates: '',
      region: '',
      zipCode: '',
    },
    driver: {
      name: '',
      mobile: '',
      pickupDate: '',
      pickupTime: '',
    },
    bookingDateTime: '',
    dropLocation: {
      name: '',
      airportId: '',
      nickName: '',
      address: '',
      emirates: '',
      region: '',
      regionId: '',
      zipCode: '',
      latitude: '',
      longitude: '',
      landmark: '',
      mobileNumber: '',
      landlineNumber: '',
    },
  };
  const [state, setState] = useState(initialObj);

  const [isValidationShow, setIsValidationShow] = useState(false);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    setState({
      ...data,
      driver: data?.driver,
      pickupLocation: data?.pickupLocation,
      dropLocation: data?.dropLocation,
      bookingDateTime: data?.bookingDateTime?.replace('00Z', '00.000Z'),
    });
    validationCheck({
      ...data,
      driver: data?.driver,
      pickupLocation: data?.pickupLocation,
      dropLocation: data?.dropLocation,
      bookingDateTime: data?.bookingDateTime?.replace('00Z', '00.000Z'),
    });
  }, [data]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'driverName':
        updatedState = {
          ...state,
          driver: {
            ...state.driver,
            name: isValidAplha(value) ? value : state?.driver?.name,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'engineerName':
        updatedState = {
          ...state,
          dropLocation: {
            ...state.dropLocation,
            name: isValidAplha(value) ? value : state?.dropLocation?.name,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'pickupTime':
        updatedState = {
          ...state,
          driver: {
            ...state.driver,
            pickupTime: value,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'driverMobile':
        updatedState = {
          ...state,
          driver: {
            ...state.driver,
            mobile: isValidNumber(value) ? value : state?.driver?.mobile,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'referenceNumber':
        updatedState = {
          ...state,
          referenceNumber: isValidNumber(value) ? value : state.referenceNumber,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'remarks':
        updatedState = {
          ...state,
          remarks: isValidAlphaNumberic(value) ? value : state.remarks,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'pickupPoint':
        updatedState = {
          ...state,
          pickupLocation: {
            ...state.pickupLocation,
            address: value,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'pickupDate':
        updatedState = {
          ...state,
          driver: {
            ...state.driver,
            pickupDate: new Date(date).toISOString(),
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validationCheck(state, `submissionCheck`)) {
      let confirmedData = null;
      if (data?.bookingStatusCode === Constant?.BOOKING_STATUS?.CONFIRM) {
        confirmedData = {
          confirmedDate: state?.dateTimeOfService?.date,
          confirmedTime: state?.dateTimeOfService?.time === '' ? '00:00' : state?.dateTimeOfService?.time,
        };
      } else {
        confirmedData = {
          dateTimeOfService: {
            date: state?.dateTimeOfService?.date,
            time: state?.dateTimeOfService?.time === '' ? '00:00' : state?.dateTimeOfService?.time,
          },
        };
      }

      dispatch({
        type: UPDATE_BOOKING,
        payload: {
          data: {
            projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
            bookingTypeId: Constant?.BOOKING_TYPES?.carServicingBookings?.objectId,
            bookingsData: [
              {
                ...state,
                driver: {
                  ...state.driver,
                  pickupDate: `${moment(state?.driver?.pickupDate).format('YYYY-MM-DD')}`,
                },
                ...confirmedData,
                objectId: data?.bookingId,
                detailedBookingId: data?.detailedBookingId,
                bookingDateTime: state.bookingDateTime,
                process: `update-booking`,
              },
            ],
          },
        },
      });
      onCloseHandler(false);
      setmodal_varying1(!modal_varying1);
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = (data = state, flag) => {
    if (
      data?.driver?.name?.trim() !== `` &&
      data?.driver?.mobile?.trim() !== `` &&
      data?.driver?.pickupDate?.trim() !== `` &&
      data?.driver?.pickupTime?.trim() !== `` &&
      data?.referenceNumber?.trim() !== `` &&
      data?.remarks?.trim() !== `` &&
      data?.dropLocation?.name?.trim() !== ``
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };
  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
      scrollable={true}
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Update Booking
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>
      <ModalBody>
        {/* <button onClick={() => console.log({ state })}>Get state</button> */}
        <form>
          <Row>
            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="name" className="form-label">
                    Booking ID
                  </Label>
                  <Input className="form-control" value={data?.bookingId} disabled />
                </div>
              </Col>
            </div>
            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="name" className="form-label">
                    User Name
                  </Label>
                  <Input className="form-control" value={data?.user?.name} disabled />
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="driverName" className="form-label">
                    Picker Name
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="driverName"
                    name="driverName"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.driver?.name}
                  />
                  {isValidationShow && state?.driver?.name?.trim() === `` && <Error text="Please enter picker name" />}
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="driverMobile" className="form-label">
                    Picker Mobile
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="driverMobile"
                    name="driverMobile"
                    type="number"
                    onWheel={e => e.target.blur()}
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.driver?.mobile}
                  />
                  {isValidationShow && state?.driver?.mobile?.trim() === `` && <Error text="Please enter picker mobile number" />}
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="booking-date-time" className="form-label">
                    Driver Pickup Date
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Flatpickr
                    className="form-control"
                    name="booking-date-time"
                    id="booking-date-time"
                    value={state.driver?.pickupDate}
                    onChange={date => datePickHandler(`pickupDate`, date)}
                    options={{
                      minDate: 'today',
                      enableTime: false,
                      dateFormat: 'Y-m-d ',
                    }}
                  />
                  {isValidationShow && state?.driver?.pickupDate === `` && <Error text="Please select pickup date" />}
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="pickupTime" className="form-label">
                    Driver Pickup Time <span className="lbl_mandatory">*</span>
                  </Label>
                  <div className="area_expandable">
                    <Input value={state?.driver?.pickupTime} className="form-control" name="pickupTime" type="time" onChange={inputChangeHandler} />
                    {isValidationShow && state?.driver?.pickupTime === `` && <Error text="Please select pickup time" />}
                  </div>
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="engineerName" className="form-label">
                    Name of Assigned Service Engineer
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="engineerName"
                    name="engineerName"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.dropLocation?.name}
                  />
                  {isValidationShow && state?.dropLocation?.name?.trim() === `` && <Error text="Please enter engineer name" />}
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="referenceNumber" className="form-label">
                    Reference No.
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="referenceNumber"
                    name="referenceNumber"
                    type="number"
                    onWheel={e => e.target.blur()}
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.referenceNumber}
                  />
                  {isValidationShow && state?.referenceNumber?.trim() === `` && <Error text="Please enter reference number" />}
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="remarks" className="form-label">
                    Remarks
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="remarks"
                    name="remarks"
                    type="textarea"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.remarks}
                  />
                  {isValidationShow && state?.remarks?.trim() === `` && <Error text="Please enter remarks" />}
                </div>
              </Col>
            </div>
          </Row>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateBookingModal;
