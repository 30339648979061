import React, { useEffect, useState } from 'react';
import ThriweNameLogo from './../../../../../assets/custom/Thriwe-name-logo.svg';
import ValetParking from './../../../../../assets/custom/valet-parking/QRCode.svg';
import { Container } from 'reactstrap';
import QRCode from 'qrcode.react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { VENDOR_GENERATE_QR, VENDOR_GENERATE_QR_EMPTY } from '../../../../../store/actions';
import { BreadCrumb, Loader } from '../../../../Common';
import { IoMdArrowBack } from 'react-icons/io';
import jwtDecode from 'jwt-decode';
import { useTranslation } from 'react-i18next';

const QRPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [url, setUrl] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [address, setAddress] = useState('');

  // Function to check if the device is a mobile device based on the screen width
  const checkDeviceType = () => {
    setIsMobile(window.innerWidth <= 1024);
  };

  useEffect(() => {
    // Add event listener to window resize event
    window.addEventListener('resize', checkDeviceType);

    // Initial check of device type on component mount
    checkDeviceType();

    // Cleanup function to remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkDeviceType);
    };
  }, []);

  // Generate Qr
  const { vendorGenerateQr, vendorGenerateQrLoading } = useSelector((state: any) => ({
    vendorGenerateQr: state?.VendorGenerateQr?.data,
    vendorGenerateQrLoading: state?.VendorGenerateQr?.loading,
  }));

  useEffect(() => {
    if (vendorGenerateQr !== null) {
      setUrl(`${vendorGenerateQr?.url}?token=${vendorGenerateQr?.token}&lang=${localStorage.getItem('I18N_LANGUAGE')}`);
      const data: any = jwtDecode(vendorGenerateQr?.token);
      setAddress(data?.facility_info?.name || '');
      dispatch({
        type: VENDOR_GENERATE_QR_EMPTY,
      });
    }
  }, [vendorGenerateQr]);

  const authUserJson = localStorage.getItem('authUser');
  const authUser = authUserJson ? JSON.parse(authUserJson) : null;
  const { firstName, lastName, userId } = authUser.data;

  useEffect(() => {
    if (location.state && location.state.code) {
      if (authUser && authUser.data) {
        const { firstName, lastName, userId, facilityId } = authUser.data;
        dispatch({
          type: VENDOR_GENERATE_QR,
          payload: {
            headers: {
              code: location.state.code,
            },
            data: {
              vendorId: userId,
              vendorFirstName: firstName,
              vendorLastName: lastName,
              facilityId: facilityId,
            },
          },
        });
      }
    }
  }, [location.state]);

  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      dispatch({
        type: VENDOR_GENERATE_QR_EMPTY,
      });
      history.push(`/vendor/dashboard`);
    }
  };

  return (
    <div className="page-content">
      {vendorGenerateQrLoading && <Loader />}
      {!isMobile && <BreadCrumb title="Airport Transfer Booking" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Qr `]} />}
      <div className="qr-container">
        <Container>
          <div className="text-center">
            <div className="logo-md">
              <img src={ThriweNameLogo} className="img-fluid" alt="" />
            </div>
            <h3 className="mt-2">{t('thriwe Valet Parking')}</h3>
          </div>

          <div className="qr-img-container text-center" style={{ margin: '40px' }}>
            {url && <QRCode value={url} />}
          </div>

          <div className="d-flex justify-content-around">
            <div className="fs-5">
              <span>
                <i className="ri-building-line text-dark fs-20 align-middle me-1"></i>
              </span>
              <span>{address || ''}</span>
            </div>
            <div className="fs-5">
              <span>
                <i className=" ri-user-3-line text-dark fs-20 align-middle me-1"></i>
              </span>
              <span>
                {firstName || ''} {lastName || ''}
              </span>
            </div>
          </div>
          {isMobile && (
            <div
              className="mt-3 d-flex justify-content-center  gap-2 cursor-pointer align-items-center"
              onClick={() => {
                dispatch({
                  type: VENDOR_GENERATE_QR_EMPTY,
                });
                history.push(`/vendor/dashboard`);
              }}
              style={{ background: 'white', padding: '5px', borderRadius: '5px' }}
            >
              <IoMdArrowBack size={20} />
              <span className="text-center">{t('Back To Home')}</span>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

export default QRPage;
