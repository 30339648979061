import cogoToast from 'cogo-toast';
import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { customHeaders, indianProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { Constant } from '../../../../Containers/Helpers/constant.js';
import apiCall from '../../apiCall.js';

import { DOWNLOAD_CONFIG_EXCEL, DOWNLOAD_CONFIG_EXCEL_SUCCESS, DOWNLOAD_CONFIG_EXCEL_ERROR } from './../../actionType.js';

function* downloadExcel({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/admin/download-excel`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        'Project-Code': 'ADMIN_ACCESS',
        // 'Content-Type': 'blob',
      },
      params: {
        ...payload?.urlParam,
      },
      data: payload?.data,
    });

    if (response?.status === 200) {
      yield put({
        type: DOWNLOAD_CONFIG_EXCEL_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(`${response?.response?.data?.message}`);
      yield put({ type: DOWNLOAD_CONFIG_EXCEL_ERROR, payload: response?.data?.message });
    }
  } catch (error) {
    yield put({ type: DOWNLOAD_CONFIG_EXCEL_ERROR, payload: error });
  }
}

export function* watchDownloadExcel() {
  yield takeEvery(DOWNLOAD_CONFIG_EXCEL, downloadExcel);
}

function* downloadExcelSaga() {
  yield all([fork(watchDownloadExcel)]);
}

export default downloadExcelSaga;
