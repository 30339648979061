import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Row, Col, Button, Modal, ModalBody, Table, ModalFooter, Input } from 'reactstrap';

import { Constant } from '../../../../../../../Helpers/constant';
import { isANumberWithDecimal } from '../../../../../../../Helpers/Helpers';
import { UPDATE_BOOKING } from '../../../../../../../../store/application/actionType';

const ConfirmEnbd = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [bookings, setBookings] = useState([]);
  const [modal_varying1, setmodal_varying1] = useState(true);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  useEffect(() => {
    if (props?.data !== null) {
      setBookings(
        props?.data?.map(booking => {
          return {
            ...booking,
          };
        }),
      );
    }
  }, [props]);

  useEffect(() => {
    validationCheck(bookings);
  }, [bookings]);

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const requestBookings = bookings?.map(booking => {
      return {
        objectId: booking?.bookingId,
        vendor: {
          ...booking?.vendor,
          bookingId: booking?.bookingId,
          price: Number(booking?.vendor?.price ?? 0),
        },

        process: `accept-booking`,
      };
    });

    dispatch({
      type: UPDATE_BOOKING,
      payload: {
        data: {
          projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
          bookingTypeId: Constant?.BOOKING_TYPES?.meetAndGreetBookings?.objectId,
          bookingsData: requestBookings,
        },
      },
    });
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const cancelHandler = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = (e, index) => {
    const { name, value } = e.target;
    let tempBooking = [...bookings];
    let selectedChangingBooking = null;
    let vendorPrice = '';

    switch (name) {
      case 'price':
        if (value?.length === 0) {
          vendorPrice = ``;
        } else {
          vendorPrice = isANumberWithDecimal(value) ? value : tempBooking[index]?.vendor?.price;
        }
        selectedChangingBooking = {
          ...tempBooking[index],
          vendor: {
            ...tempBooking[index]?.vendor,
            price: vendorPrice,
          },
        };
        break;

      case 'car-type':
        selectedChangingBooking = {
          ...tempBooking[index],
          vendor: {
            ...tempBooking[index]?.vendor,
            carType: value,
          },
        };
        break;

      case 'vendor-remarks':
        selectedChangingBooking = {
          ...tempBooking[index],
          vendor: {
            ...tempBooking[index]?.vendor,
            remarks: value,
          },
        };
        break;

      default:
      // do nothing
    }
    tempBooking?.splice(index, 1, selectedChangingBooking);
    validationCheck(tempBooking);
    setBookings(tempBooking);
  };

  /* ---------------------------- VALIDATION HANDLER ---------------------------- */
  const validationCheck = data => {
    let flag = false;
    for (let i = 0; i < data?.length; i++) {
      if (data[i]?.vendor?.price === 0 || data[i]?.vendor?.price === '') {
        flag = true;
        break;
      } else if (data[i]?.vendor?.carType === '') {
        flag = true;
        break;
      } else if (data[i]?.vendor?.remarks === '') {
        flag = true;
        break;
      }
    }
    flag ? setIsSubmitBtnDisabled(true) : setIsSubmitBtnDisabled(false);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      scrollable={true}
      id="exampleModal"
      size="lg"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Are you sure you want to go ahead?
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log({ bookings })}>get state</button> */}
        <form>
          <Row>
            <Col md={12} style={{ mixHeight: '500px' }}>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Booking ID</th>
                    <th> Name</th>
                    <th> Vendor Price</th>
                    <th> Vendor Car Type</th>
                    <th> Vendor Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {bookings &&
                    bookings?.map((booking, index) => (
                      <tr key={index}>
                        <th>{index + 1}.</th>
                        <td>{booking?.bookingId}</td>
                        <td>{booking?.user?.name}</td>
                        <td>
                          <Input
                            id="price"
                            name="price"
                            type="text"
                            className="form-control"
                            value={booking?.vendor?.price}
                            onChange={e => inputChangeHandler(e, index)}
                          />
                        </td>
                        <td>
                          <select
                            id="car-type"
                            name="car-type"
                            className="form-select"
                            value={booking?.vendor?.carType}
                            onChange={e => inputChangeHandler(e, index)}
                          >
                            <option value="" disabled>
                              Choose...
                            </option>
                            <option value="Sedan">Sedan</option>
                            <option value="MVP">MVP</option>
                            <option value="Luxury">Luxury</option>
                          </select>
                        </td>
                        <td>
                          <Input
                            id="vendor-remarks"
                            name="vendor-remarks"
                            type="text"
                            className="form-control"
                            value={booking?.vendor?.remarks}
                            onChange={e => inputChangeHandler(e, index)}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <p className="text-muted m-0">Once you will click on proceed, you will not be able to change previous state.</p>
          </Row>
        </form>
      </ModalBody>

      <ModalFooter className="pb-0">
        <div className="modal-footer mx-auto">
          <Button className="btn btn-light overlay-disable" color="primary" onClick={cancelHandler}>
            Cancel
          </Button>
          <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
            Sure
          </Button>
        </div>
      </ModalFooter>
      {/* <div className="modal-footer d-flex justify-content-center">
      <Button
        className="overlay-disable"
        color="primary"
        onClick={submitHandler}
        disabled={isSubmitBtnDisabled}
      >
        Submit
      </Button>
    </div> */}
    </Modal>
  );
};

export default ConfirmEnbd;
