import React, { useEffect, useState } from 'react';
import { Error, PreviewCardHeader } from '../../../../../Common';
import { Button, ButtonGroup, Card, Col, Input, Label, Row, TabPane } from 'reactstrap';
import { isValidEmail, isValidNumber } from '../../../../../Helpers/Helpers';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import Address from '../Modal/Address';
import { useDispatch, useSelector } from 'react-redux';
import GetMemberships from '../../../../../../store/application/CustomerManagement/GetMemberships/reducer';
import PickupModal from '../../Components/Modal/Pickup';
import { UPDATE_PROFILE } from '../../../../../../store/actions';
import DateUtils from '../../../../../Helpers/DateUtils';

const initialState = {
  email: '',
  name: '',
  phoneNumber: '',
  noOfPassenger: null,
  noOfBabySeat: null,
  noOfLuggage: null,
  pickUpDate: null,
  pickUpTime: '',
  pickupLocation: null,
  dropLocation: null,
  flightNo: '',
  terminalNo: '',
  pickUpAirport: null,
  dropAirport: null,
  type: 'Airport Pickup',
  bookingType: 'self',
  remarks: '',
};

const AddressAndFlight = ({ activeTab, toggleTab, bookingDetails, submitFlightAndAddress, isUserExist }) => {
  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);
  const [noOfLuggage, setNoOfLuggageOptions] = useState([]);
  const [noOfPassengers, setNoOfPassengers] = useState([]);
  const [noOfBabySeats, setNoOfBabySeats] = useState([]);
  const [addressOptions, setAddressOptions] = useState([]);
  const [airportOptions, setAirportOptions] = useState([]);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [isPickupModalOpen, setIsPickupModalOpen] = useState(false);
  const [labelPickup, setLabelPickup] = useState('');
  const [addressOrAirportList, setAddressOrAirportList] = useState(null);

  const dispatch = useDispatch();

  const handleOptionSelect = option => {
    setState({ ...state, type: option });
  };

  const { updateUser, updateUserLoading } = useSelector((state: any) => ({
    updateUser: state?.UpdateProfile,
    updateUserLoading: state?.UpdateProfile?.loading,
  }));

  useEffect(() => {
    if (updateUser !== null) {
      setIsPickupModalOpen(false);
      setIsAddressModalOpen(false);
    }
  }, [updateUser]);

  useEffect(() => {
    setNoOfLuggageOptions(
      Array.from({ length: bookingDetails?.carTypes?.[0]?.luggages + 1 }, (_, index) => ({
        value: index.toString(),
        label: index.toString(),
      })),
    );
    setNoOfPassengers(
      Array.from({ length: bookingDetails?.carTypes?.[0]?.passengers }, (_, index) => ({
        value: (index + 1).toString(),
        label: (index + 1).toString(),
      })),
    );

    setNoOfBabySeats(
      Array.from({ length: bookingDetails?.carTypes?.[0]?.babySeats + 1 }, (_, index) => ({
        value: index.toString(),
        label: index.toString(),
      })),
    );

    if (isUserExist?.addresses?.length > 0) {
      setAddressOptions(
        isUserExist?.addresses?.map(addres => ({
          label: addres?.streetAddress,
          value: addres?.streetAddress,
          data: addres,
        })),
      );
    }

    setAirportOptions(
      bookingDetails?.airports?.map(airport => {
        return {
          label: airport?.nickName,
          value: airport?.nickName,
          data: airport,
        };
      }),
    );
  }, [bookingDetails]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };
    switch (name) {
      case 'name':
        updatedState = {
          ...state,
          name: value,
        };
        break;

      case 'email':
        updatedState = {
          ...state,
          email: value,
        };
        break;

      case 'phoneNumber':
        updatedState = {
          ...state,
          phoneNumber: value,
        };
        break;
      case 'flightNo':
        updatedState = {
          ...state,
          flightNo: value,
        };
        break;

      case 'terminalNo':
        updatedState = {
          ...state,
          terminalNo: value,
        };
        break;

      case 'pickUpTime':
        updatedState = {
          ...state,
          pickUpTime: value,
        };
        break;

      case 'remarks':
        updatedState = {
          ...state,
          remarks: value,
        };
        break;

      default:
      // do nothing
    }
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...state };

    if (label === `noOfPassenger`) {
      if (state?.noOfBabySeat) {
        if (Number(state?.noOfBabySeat?.value) + Number(data?.value) <= noOfPassengers?.length) {
          updatedState = {
            ...state,
            noOfPassenger: data,
          };
        } else {
          updatedState = {
            ...state,
            noOfPassenger: data,
            noOfBabySeat: { label: '0', value: '0' },
          };
        }
      } else {
        updatedState = {
          ...state,
          noOfPassenger: data,
          noOfBabySeat: { label: '0', value: '0' },
        };
      }

      // if (state.noOfBabySeat && state.noOfBabySeat?.value + data?.value > noOfPassengers.length) {
      //   updatedState = {
      //     ...state,
      //     noOfBabySeat: { label: '0', value: '0' },
      //     noOfPassenger: data,
      //   };
      // } else if (data?.value === String(noOfPassengers.length)) {
      //   updatedState = {
      //     ...state,
      //     noOfBabySeat: { label: '0', value: '0' },
      //     noOfPassenger: data,
      //   };
      // } else {
      //   updatedState = {
      //     ...state,
      //     noOfBabySeat: null,
      //     noOfPassenger: data,
      //   };
      // }
      setState(updatedState);
    } else if (label === `noOfBabySeat`) {
      if (state?.noOfPassenger) {
        if (Number(state.noOfPassenger?.value) + Number(data?.value) <= noOfPassengers.length) {
          updatedState = {
            ...state,
            noOfBabySeat: data,
          };
        } else {
          updatedState = {
            ...state,
            noOfPassenger: { label: '0', value: '0' },
            noOfBabySeat: data,
          };
        }
      } else {
        updatedState = {
          ...state,
          noOfPassenger: null,
          noOfBabySeat: data,
        };
      }
      setState(updatedState);
    } else if (label === `noOfLuggage`) {
      updatedState = {
        ...state,
        noOfLuggage: data,
      };
      setState(updatedState);
    } else if (label === `pickupLocation`) {
      updatedState = {
        ...state,
        pickupLocation: data,
      };
      setState(updatedState);
    } else if (label === `dropAirport`) {
      updatedState = {
        ...state,
        dropAirport: data,
      };
      setState(updatedState);
    } else if (label === `pickUpAirport`) {
      updatedState = {
        ...state,
        pickUpAirport: data,
      };
      setState(updatedState);
    } else if (label === `dropLocation`) {
      updatedState = {
        ...state,
        dropLocation: data,
      };
      setState(updatedState);
    }
    validateErrors(updatedState, `validationCheck`);
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;
    switch (flag) {
      case 'pickUpDate':
        updatedState = {
          ...state,
          pickUpDate: moment(new Date(date[0])).format('Y-MM-DD'),
        };
        setState(updatedState);
        break;
      case 'travelPurchaseDate':
        updatedState = {
          ...state,
          travelPurchaseDate: moment(new Date(date[0])).format('Y-MM-DD'),
        };
        setState(updatedState);
        break;
      default:
      // do nothing
    }
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (
      (state.bookingType !== 'guest' || // Check if bookingType is not guest or
        (state.bookingType === 'guest' && // If bookingType is guest, then check required fields
          data?.name?.trim() !== '' &&
          isValidEmail(data?.email?.trim()) &&
          isValidNumber(data?.phoneNumber?.trim()))) &&
      ((data?.type === 'Airport Pickup' && data?.pickUpAirport !== null && data?.dropLocation !== null) ||
        (data?.type === 'Airport Drop' && data?.dropAirport !== null && data?.pickupLocation !== null)) &&
      data?.noOfPassenger !== null &&
      data?.pickUpDate !== null &&
      data?.flightNo?.trim() !== '' &&
      data?.terminalNo?.trim() !== '' &&
      data?.pickUpTime?.trim() !== ''
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      submitFlightAndAddress(state);
    }
  };

  const handleAddress = data => {
    setState(
      state.type === 'Airport Pickup'
        ? {
            ...state,
            dropLocation: {
              label: data?.nickname,
              value: data?.nickname,
              data: data,
            },
          }
        : {
            ...state,
            pickupLocation: {
              label: data?.nickname,
              value: data?.nickname,
              data: data,
            },
          },
    );
    dispatch({
      type: UPDATE_PROFILE,
      payload: {
        data: {
          addresses: [
            {
              nickname: data?.nickname,
              streetAddress: data?.streetAddress,
              region: data?.region?.value,
              subregion: data?.subregion?.value,
              postalCode: data?.postalCode,
            },
          ],
        },
        urlParams: {
          userId: isUserExist?.userId,
        },
      },
    });
    setAddressOptions([
      ...addressOptions,
      {
        label: data?.nickname,
        value: data?.nickname,
        data: data,
      },
    ]);
  };

  const toggleAddressModal = () => {
    setIsAddressModalOpen(!isAddressModalOpen);
  };

  const togglePickupModal = () => {
    setIsPickupModalOpen(!isPickupModalOpen);
    setAddressOrAirportList(null);
  };

  const handlePickup = (flag: string, data) => {
    setState({ ...state, [flag]: data });
    setIsPickupModalOpen(false);
  };

  const handleOpenAddress = () => {
    setIsAddressModalOpen(true);
    setIsPickupModalOpen(false);
  };

  return (
    <TabPane tabId={2}>
      {isAddressModalOpen && <Address isOpen={isAddressModalOpen} toggle={toggleAddressModal} onConfirm={handleAddress} />}
      {isPickupModalOpen && (
        <PickupModal
          isOpen={isPickupModalOpen}
          toggle={togglePickupModal}
          airportAndAddress={addressOrAirportList}
          handlePickup={handlePickup}
          handleOpenAddress={handleOpenAddress}
          label={labelPickup}
        />
      )}

      <div className="mb-3 d-flex flex-column flex-md-row align-items-center justify-content-between">
        <div className="d-flex gap-2 align-items-center mb-2 mb-md-0">
          <PreviewCardHeader title="Passenger & Luggage Details" />
        </div>
        <div className="d-flex align-items-center gap-3">
          <div className="mr-2 mr-md-3">Booking for</div>
          <div className="form-check mr-2 mr-md-3">
            <Input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              checked={state.bookingType === 'self'}
              onChange={() => setState({ ...state, bookingType: 'self' })}
            />
            <Label className="form-check-label" for="flexRadioDefault1">
              Self
            </Label>
          </div>
          {/* <div className="form-check">
            <Input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              checked={state.bookingType === 'guest'}
              onChange={() => setState({ ...state, bookingType: 'guest' })}
            />
            <Label className="form-check-label" for="flexRadioDefault2">
              Guest
            </Label>
          </div> */}
        </div>
      </div>

      <Row>
        <Col xxl={12}>
          <Row>
            {state.bookingType === 'guest' && (
              <Row>
                <h5 style={{ textDecoration: 'underline', marginBottom: '10px' }}>Booking for Guest</h5>
                <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                  <div className="mb-3">
                    <Label htmlFor="name" className="form-label">
                      Guest Name
                      <span className="lbl_mandatory"> *</span>
                    </Label>
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      className="form-control"
                      onChange={inputChangeHandler}
                      value={state.name}
                      autoComplete="off"
                    />
                    {isValidationShow && state?.name.trim() === '' && <div className="text-danger">Please enter name</div>}
                  </div>
                </Col>
                <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                  <div className="mb-3">
                    <Label htmlFor="name" className="form-label">
                      Guest Email
                      <span className="lbl_mandatory"> *</span>
                    </Label>
                    <div className="input-group">
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        className="form-control"
                        onChange={inputChangeHandler}
                        value={state.email}
                        autoComplete="off"
                        disabled={false}
                      />
                    </div>
                    {isValidationShow && state?.email.trim() === '' && <div className="text-danger">Please enter email</div>}
                    {isValidationShow && state?.email?.trim() !== '' && !isValidEmail(state?.email?.trim()) && (
                      <div className="text-danger">Please enter correct email</div>
                    )}
                  </div>
                </Col>

                <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                  <div className="mb-3">
                    <Label htmlFor="name" className="form-label">
                      Guest Mobile
                      <span className="lbl_mandatory"> *</span>
                    </Label>
                    <Input
                      id="phoneNumber"
                      name="phoneNumber"
                      type="number"
                      onWheel={(e: any) => e.target.blur()}
                      className="form-control"
                      onChange={inputChangeHandler}
                      value={state.phoneNumber}
                      autoComplete="off"
                      disabled={false}
                    />
                    {isValidationShow && state?.phoneNumber?.trim() === '' && <div className="text-danger">Please enter mobile no</div>}
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className="mb-3">
                  <Label htmlFor="noOfPassenger" className="form-label">
                    No Of Passengers
                    <span className="lbl_mandatory"> *</span>
                  </Label>
                  <Select
                    id="noOfPassenger"
                    name="noOfPassenger"
                    value={state.noOfPassenger}
                    options={noOfPassengers}
                    onChange={data => {
                      inputSelectHandler('noOfPassenger', data);
                    }}
                    isDisabled={false}
                  />
                  {isValidationShow && state?.noOfPassenger === null && <div className="text-danger">Please select passenger</div>}
                </div>
              </Col>
              <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className="mb-3">
                  <Label htmlFor="noOfBabySeat" className="form-label">
                    No Of BabySeat
                  </Label>
                  <Select
                    id="noOfBabySeat"
                    name="noOfBabySeat"
                    value={state.noOfBabySeat}
                    options={noOfBabySeats}
                    onChange={data => inputSelectHandler('noOfBabySeat', data)}
                    isDisabled={noOfPassengers.length == state?.noOfPassenger?.value}
                  />
                </div>
              </Col>
              <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className="mb-3">
                  <Label htmlFor="noOfLuggage" className="form-label">
                    No Of Luggage
                  </Label>
                  <Select
                    id="noOfLuggage"
                    name="noOfLuggage"
                    value={state?.noOfLuggage}
                    options={noOfLuggage}
                    onChange={data => inputSelectHandler('noOfLuggage', data)}
                    isDisabled={false}
                  />
                </div>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
      <div className="mb-3">
        <PreviewCardHeader title="Choose Service" />
      </div>
      <div className="mb-3 " style={{ background: 'rgb(243, 246, 249)', display: 'inline-block', padding: '10px', borderRadius: '12px' }}>
        <Button
          color={state?.type === 'Airport Pickup' ? 'primary' : 'light'}
          className="rounded-pill"
          onClick={() => handleOptionSelect('Airport Pickup')}
        >
          Airport Pickup
        </Button>
        <Button
          color={state?.type === 'Airport Drop' ? 'primary' : 'light'}
          className="rounded-pill"
          onClick={() => handleOptionSelect('Airport Drop')}
        >
          Airport Drop
        </Button>
      </div>
      <Row>
        <Col xxl={12}>
          <Row>
            {state.type === 'Airport Pickup' ? (
              <>
                <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                  <div className="mb-3">
                    <Label htmlFor="noOfBabySeat" className="form-label">
                      Pick Up Airport
                      <span className="lbl_mandatory"> *</span>
                    </Label>
                    <Select
                      id="pickUpAirport"
                      name="pickUpAirport"
                      value={state.pickUpAirport}
                      options={airportOptions}
                      onChange={data => inputSelectHandler('pickUpAirport', data)}
                      isDisabled={false}
                      onMenuOpen={() => {
                        if (!isPickupModalOpen) {
                          setIsPickupModalOpen(true);
                          setLabelPickup('pickUpAirport');
                          setAddressOrAirportList({ isAddress: false, list: airportOptions, selectedValue: state.pickUpAirport });
                        }
                      }}
                      styles={{
                        container: provided => ({
                          ...provided,
                          flex: 1,
                          marginRight: '8px',
                        }),
                      }}
                    />
                    {isValidationShow && state?.pickUpAirport === null && <div className="text-danger">Please select airport</div>}
                  </div>
                </Col>
                <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                  <div className="mb-3">
                    <Label htmlFor="pickupLocation" className="form-label">
                      Drop off Location
                      <span className="lbl_mandatory"> *</span>
                    </Label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Select
                        id="dropLocation"
                        name="dropLocation"
                        value={state.dropLocation}
                        options={addressOptions}
                        onMenuOpen={() => {
                          if (!isPickupModalOpen) {
                            setIsPickupModalOpen(true);
                            setLabelPickup('dropLocation');
                            setAddressOrAirportList({ isAddress: true, list: addressOptions, selectedValue: state.dropLocation });
                          }
                        }}
                        isDisabled={false}
                        styles={{
                          container: provided => ({
                            ...provided,
                            flex: 1,
                            marginRight: '8px',
                          }),
                        }}
                      />
                    </div>
                    {isValidationShow && state?.dropLocation === null && <div className="text-danger">Please select location</div>}
                  </div>
                </Col>
              </>
            ) : (
              <>
                <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                  <div className="mb-3">
                    <Label htmlFor="pickupLocation" className="form-label">
                      Pick Up Location
                      <span className="lbl_mandatory"> *</span>
                    </Label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Select
                        id="pickupLocation"
                        name="pickupLocation"
                        value={state.pickupLocation}
                        options={addressOptions}
                        onMenuOpen={() => {
                          if (!isPickupModalOpen) {
                            setIsPickupModalOpen(true);
                            setLabelPickup('pickupLocation');
                            setAddressOrAirportList({ isAddress: true, list: addressOptions, selectedValue: state.pickupLocation });
                          }
                        }}
                        isDisabled={false}
                        styles={{
                          container: provided => ({
                            ...provided,
                            flex: 1,
                            marginRight: '8px',
                          }),
                        }}
                      />
                    </div>
                    {isValidationShow && state?.pickupLocation === null && <div className="text-danger">Please select location</div>}
                  </div>
                </Col>
                <Col xl={3} lg={3} md={4} sm={6} xs={12}>
                  <div className="mb-3">
                    <Label htmlFor="noOfBabySeat" className="form-label">
                      Drop Airport
                      <span className="lbl_mandatory"> *</span>
                    </Label>
                    <Select
                      id="dropAirport"
                      name="dropAirport"
                      value={state.dropAirport}
                      options={airportOptions}
                      onMenuOpen={() => {
                        if (!isPickupModalOpen) {
                          setIsPickupModalOpen(true);
                          setLabelPickup('dropAirport');
                          setAddressOrAirportList({ iaAddress: false, list: airportOptions, selectedValue: state.dropAirport });
                        }
                      }}
                      onChange={data => inputSelectHandler('dropAirport', data)}
                      isDisabled={false}
                    />
                    {isValidationShow && state?.dropAirport === null && <div className="text-danger">Please select airport</div>}
                  </div>
                </Col>
              </>
            )}
            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
              <div className="mb-3">
                <Label htmlFor="name" className="form-label">
                  Pickup Date
                  <span className="lbl_mandatory"> *</span>
                </Label>
                <Flatpickr
                  className="form-control"
                  onChange={date => datePickHandler(`pickUpDate`, date)}
                  value={state?.pickUpDate}
                  options={{
                    dateFormat: 'Y-m-d',
                    minDate: new Date().fp_incr(
                      DateUtils.getMinDateInDays(bookingDetails?.tat?.delayedMinTat, bookingDetails?.tat?.delayedMinTatUnit),
                    ),
                    maxDate: new Date().fp_incr(
                      DateUtils.getMaxDateInDays(bookingDetails?.tat?.delayedMaxTat, bookingDetails?.tat?.delayedMaxTatUnit),
                    ),
                  }}
                />
                {isValidationShow && state?.pickUpDate === null && <div className="text-danger">Please select pickup date</div>}
              </div>
            </Col>
            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
              <div className="mb-3">
                <Label htmlFor="name" className="form-label">
                  Pickup Time
                  <span className="lbl_mandatory"> *</span>
                </Label>
                <Input
                  type="time"
                  name="pickUpTime"
                  className="form-control"
                  style={{ width: '100% important' }}
                  value={state.pickUpTime}
                  onChange={inputChangeHandler}
                />
                {isValidationShow && state?.pickUpTime === '' && <div className="text-danger">Please select pickup time</div>}
              </div>
            </Col>
            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
              <div className="mb-3">
                <Label htmlFor="name" className="form-label">
                  Flight No
                  <span className="lbl_mandatory"> *</span>
                </Label>
                <Input
                  id="flightNo"
                  name="flightNo"
                  type="text"
                  className="form-control"
                  onChange={inputChangeHandler}
                  value={state.flightNo}
                  autoComplete="off"
                  disabled={false}
                />
                {isValidationShow && state?.flightNo.trim() === '' && <div className="text-danger">Please select flight no</div>}
              </div>
            </Col>
            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
              <div className="mb-3">
                <Label htmlFor="terminalNo" className="form-label">
                  Terminal No
                  <span className="lbl_mandatory"> *</span>
                </Label>
                <Input
                  id="terminalNo"
                  name="terminalNo"
                  type="text"
                  className="form-control"
                  onChange={inputChangeHandler}
                  value={state.terminalNo}
                  autoComplete="off"
                  disabled={false}
                />
                {isValidationShow && state?.terminalNo.trim() === '' && <div className="text-danger">Please select terminal no</div>}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="remarks" className="form-label">
                  Remarks
                </Label>
                <Input type="textarea" name="remarks" id="remarks" rows="3" value={state?.remarks} onChange={inputChangeHandler} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="d-flex align-items-start gap-3 mt-4">
        <button
          type="button"
          className="btn btn-outline-success btn-label previestab"
          onClick={() => {
            toggleTab(activeTab - 1, 0);
          }}
        >
          <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Previous
        </button>
        <button type="button" className="btn btn-success btn-label right ms-auto nexttab nexttab" onClick={submitHandler}>
          <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
          Continue
        </button>
      </div>
    </TabPane>
  );
};

export default AddressAndFlight;
