import { useDispatch, useSelector } from 'react-redux';

import { CLIENT_FORGOT_PASSWORD, CLIENT_FORGOT_PASSWORD_EMPTY } from '../../../../store/actions';
import ForgotPassword from './Components/ForgotPassword';
import RecoverySent from './Components/RecoverySent';
import FullLoader from '../../../Common/Loader/CompleteLoader';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ClientForgotPassword = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isClientType, setIsClientType] = useState(false);

  useEffect(() => {
    const isClientType = location?.pathname?.includes('/client/') ?? false;
    setIsClientType(isClientType);
  }, []);

  const [email, setEmail] = useState(null);
  const [isRecoverySent, setIsRecoverySent] = useState(false);

  // Forget password
  const { forgetPassword, forgetPasswordLoading } = useSelector((state: any) => ({
    forgetPassword: state?.ClientForgotPassword?.data,
    forgetPasswordLoading: state?.ClientForgotPassword?.loading,
  }));

  useEffect(() => {
    if (forgetPassword !== null) {
      setIsRecoverySent(true);

      dispatch({
        type: CLIENT_FORGOT_PASSWORD_EMPTY,
      });
    }
  }, [forgetPassword]);

  const onSubmitForgotPassword = data => {
    dispatch({
      type: CLIENT_FORGOT_PASSWORD,
      payload: {
        data: {
          ...data,
        },
      },
    });

    setEmail(data?.email);
  };

  useEffect(() => {
    return () => {
      setEmail(null);
      setIsRecoverySent(false);
    };
  }, []);

  return (
    <>
      {forgetPasswordLoading && <FullLoader />}

      {!isRecoverySent && <ForgotPassword onSubmit={onSubmitForgotPassword} />}
      {isRecoverySent && <RecoverySent email={email} isClientType={isClientType} />}
    </>
  );
};

export default ClientForgotPassword;
