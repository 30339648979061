import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, uaeProjectsBaseURL } from './../../../../../Containers/Config/index';
import { GET_SERVICE, GET_SERVICE_SUCCESS, GET_SERVICE_ERROR } from './../../../actionType';
import apiCall from '../../../apiCall';
import { Constant } from '../../../../../Containers/Helpers/constant';

function* getService({ payload }) {
  // let URL = `${uaeProjectsBaseURL}/admin/services`

  // let urlParams = ''
  // if (payload?.pagination) {
  //   urlParams = `${`pageIndex=${payload?.urlParam?.pageIndex}`}&${`pageSize=${Constant?.TABLE_PAGE_SIZE}`}`
  // } else {
  //   if (payload?.urlParam?.partnerId) {
  //     urlParams =
  //       urlParams === ``
  //         ? `partnerId=${payload?.urlParam?.partnerId}`
  //         : `&partnerId=${payload?.urlParam?.partnerId}`
  //   }
  //   if (payload?.urlParam?.facilityId) {
  //     urlParams =
  //       urlParams === ``
  //         ? `facilityId=${payload?.urlParam?.facilityId}`
  //         : `&facilityId=${payload?.urlParam?.facilityId}`
  //   }
  // }

  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/admin/services`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        pageSize: Constant?.TABLE_PAGE_SIZE,
        ...payload?.urlParam,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_SERVICE_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_SERVICE_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_SERVICE_ERROR, payload: error });
  }
}

export function* watchGetService() {
  yield takeEvery(GET_SERVICE, getService);
}

function* getServiceSaga() {
  yield all([fork(watchGetService)]);
}

export default getServiceSaga;
