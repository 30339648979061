import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Col, Label, Row, Input, Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap';

import { customHeaders, uaeProjectsBaseURL } from '../../../../../../Config';
import { Constant } from '../../../../../../Helpers/constant';
import { isValidAplha } from '../../../../../../Helpers/Helpers';
import {
  GET_BOOKING_TYPES_LIST,
  GET_CATEGORY_EMPTY,
  GET_LOCATION_EMPTY,
  GET_PARTNER_EMPTY,
  GET_SERVICE_EMPTY,
} from '../../../../../../../store/application/actionType';

const CustomFilter = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    name: ``,
    updateDate: ``,
    typeId: ``,
    categoryId: null,
  };
  const [filter, setFilter] = useState(initialState);
  const [isResetDisabled, setIsResetDisabled] = useState(true);

  const [bookingTypesData, setBookingTypesData] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Booking Types
  const { bookingTypes } = useSelector(state => ({
    bookingTypes: state?.BookingTypes?.data,
  }));

  useEffect(() => {
    if (props?.appliedFilters !== null) {
      setFilter(props?.appliedFilters);
    }
  }, [props?.appliedFilters]);

  useEffect(() => {
    if (bookingTypes === null) {
      dispatch({
        type: GET_BOOKING_TYPES_LIST,
      });
    }
  }, []);

  useEffect(() => {
    if (bookingTypes !== null) {
      setBookingTypesData(
        bookingTypes?.results?.map(bookingType => {
          return {
            value: bookingType?.objectId,
            label: bookingType?.name,
            data: bookingType,
          };
        }),
      );
    }
  }, [bookingTypes]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...filter };

    switch (name) {
      case 'name':
        updatedState = {
          ...filter,
          name: value,
          // name: isValidAplha(value) ? value : filter?.name,
        };
        break;

      default:
      // do nothing
    }

    setFilter(updatedState);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...filter };

    if (label === `typeId`) {
      updatedState = {
        ...filter,
        typeId: data,
      };
    }

    setFilter(updatedState);
  };

  /* ---------------------------- DATE SELECT HANDLER ---------------------------- */
  const datePickHandler = (flag, date) => {
    let updatedState = { ...filter };

    switch (flag) {
      case 'updateDate':
        updatedState = {
          ...filter,
          updateDate: new Date(date).toISOString(),
        };
        break;

      default:
      // do nothing
    }

    setFilter(updatedState);
  };

  /* ---------------------------- TYPED USER HANDLER ---------------------------- */
  async function loadOptions(search, loadedOptions) {
    const response = await fetch(`${uaeProjectsBaseURL}/admin/categories?name=${search}`, {
      method: `GET`,
      headers: new Headers({
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      }),
    });
    const categoriesResponse = await response.json();

    return {
      options: categoriesResponse?.results?.map((data, index) => {
        return {
          value: data.objectId,
          label: `${data.name}`,
          completeData: data,
        };
      }),
      hasMore: false,
    };
  }

  /* ---------------------------- ON SELECT USER ---------------------------- */
  const onSelectCategory = event => {
    let updatedState = { ...filter, categoryId: event };

    setFilter({ ...updatedState });
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    props.filterHandler({ ...filter });
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter({ ...initialState });
    setIsResetDisabled(true);

    dispatch({
      type: GET_CATEGORY_EMPTY,
    });

    dispatch({
      type: GET_PARTNER_EMPTY,
    });

    dispatch({
      type: GET_LOCATION_EMPTY,
    });

    dispatch({
      type: GET_SERVICE_EMPTY,
    });
  };

  return (
    <>
      <Offcanvas direction="end" isOpen={props.show} id="offcanvasExample" toggle={() => props.onCloseClick()}>
        <OffcanvasHeader className="bg-light" toggle={() => props.onCloseClick()}>
          Brand Filters
        </OffcanvasHeader>

        <OffcanvasBody>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="name" className="form-label">
                  Display Name
                </Label>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  className="form-control"
                  value={filter?.name}
                  onChange={inputChangeHandler}
                  autoComplete="off"
                />
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="name" className="form-label">
                  Sub-Category
                </Label>
                <AsyncPaginate
                  debounceTimeout={500}
                  value={filter?.categoryId}
                  loadOptions={loadOptions}
                  onChange={e => onSelectCategory(e)}
                  isMulti={false}
                  closeMenuOnSelect={true}
                  noOptionsMessage={() => 'No results found'}
                  cacheUniqs={[['code']]}
                  placeholder="Enter sub-category..."
                />
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="bookingType" className="form-label">
                  Booking Type
                </Label>
                <Select
                  id="bookingType"
                  name="bookingType"
                  options={bookingTypesData ?? []}
                  value={filter?.typeId}
                  onChange={data => inputSelectHandler(`typeId`, data)}
                />
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="to" className="form-label">
                  Last Updated At
                  <span className="icon_calendar">
                    <i className="las la-calendar"></i>
                  </span>
                </Label>
                <div className="area_expandable">
                  <Flatpickr
                    className="form-control"
                    name="to"
                    id="to"
                    value={filter?.updateDate}
                    onChange={date => datePickHandler(`updateDate`, date)}
                    options={{
                      enableTime: false,
                      dateFormat: 'd M, Y',
                      maxDate: 'today',
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </OffcanvasBody>

        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
          <button
            className="btn btn-light w-100"
            onClick={resetFilterHandler}
            // disabled={isResetDisabled}
          >
            Reset Filter
          </button>

          <button className="btn btn-primary w-100" onClick={filterSubmitHandler}>
            Search
          </button>
        </div>
      </Offcanvas>
    </>
  );
};

export default CustomFilter;
