import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, indianProjectsBaseURL, uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { GET_MEMBERSHIP_QUOTA, GET_MEMBERSHIP_QUOTA_SUCCESS, GET_MEMBERSHIP_QUOTA_ERROR } from '../../actionType.js';
import apiCall from '../../apiCall.js';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getMembershipQuota({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/admin/memberships-quota`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload.urlParams,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_MEMBERSHIP_QUOTA_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_MEMBERSHIP_QUOTA_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_MEMBERSHIP_QUOTA_ERROR, payload: error });
  }
}

export function* watchGetMembershipQuota() {
  yield takeEvery(GET_MEMBERSHIP_QUOTA, getMembershipQuota);
}

function* getMembershipQuotaSaga() {
  yield all([fork(watchGetMembershipQuota)]);
}

export default getMembershipQuotaSaga;
