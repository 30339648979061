import React from 'react';
import { Card, CardHeader, Col, Input, Row } from 'reactstrap';

const InfoPreview = () => {
  return (
    <>
      <CardHeader className="border-0">
        <Row className="align-items-center g-5 text-center">
          <Col xxl={2}>
            <h5 className="card-title">All Transactions</h5>
          </Col>

          <Col xxl={4} md={6}>
            <Row>
              <Col md={7} className="my-auto">
                <h6 className="text-muted ">Total Incoming Balance :</h6>
              </Col>
              <Col md={5} className="text-start">
                <h3 className="mb-2 text-center">
                  <i className="fas fa-coins text-warning"></i>{' '}
                  <span className="text-warning fs-24">
                    <i className="lab las la-coins"></i>
                  </span>
                  74,000
                  <small className="text-muted fs-13"></small>
                </h3>
              </Col>
            </Row>
          </Col>

          <Col xxl={4} md={6}>
            <Row>
              <Col md={7} className="my-auto">
                <h6 className="text-muted">Total Outgoing Balance :</h6>
              </Col>
              <Col md={5}>
                <h3 className="mb-2 text-center">
                  <i className="fas fa-coins text-warning"></i>{' '}
                  <span className="text-center">
                    <span className="text-warning fs-24">
                      <i className="lab las la-coins"></i>
                    </span>
                    4,000
                  </span>
                  <small className="text-muted fs-13"></small>
                </h3>
              </Col>
            </Row>
          </Col>

          <Col xxl={2} md={6} className="align-self-end">
            <button type="button" className="btn btn-success add-btn">
              <i className="ri-download-cloud-2-fill me-1"></i> Export
            </button>
          </Col>
        </Row>
      </CardHeader>
    </>
  );
};

export default InfoPreview;
