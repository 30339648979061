import { useState } from 'react';
import OtpInput from 'react-otp-input';

import { Button, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';

const OtpInputModal = ({ data, loading, onClose, onConfirm }) => {
  const [otp, setOtp] = useState('');

  const handleChange = value => {
    // Regex to allow only numbers (up to 6 characters)
    if (/^\d{0,6}$/.test(value)) {
      setOtp(value);
    }
  };

  const onSubmit = () => {
    onConfirm(otp);
  };

  return (
    <Modal isOpen={true} id="restart-confirmation">
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title">OTP Verification</h5>
        <Button
          type="button"
          onClick={() => {
            onClose();
          }}
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        <p className="m-0">We have sent an OTP to your email id {data?.email || ''}</p>
        <h6 className="fw-semibold my-2">Enter OTP to verify yourself</h6>
        <div className="d-flex justify-content-center">
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            renderInput={(props, index) => (
              <input
                {...props}
                onKeyPress={e => {
                  if (!/^\d$/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            )}
            inputStyle={{
              padding: '5px',
              margin: '10px 10px',
              alignItems: 'center',
              textAlign: 'center',
              height: '40px',
              width: '40px',
              color: 'black',
              borderRadius: '5px',
              backgroundColor: 'transparent',
              border: '1px solid #E1E1E1',
              outline: 'none',
              background: '#FFFFFF',
              gap: '10px',
            }}
          />
        </div>
      </ModalBody>

      <ModalFooter className="pb-0">
        <div className="modal-footer mx-auto">
          {loading ? (
            <Button color="primary" className="btn-load" outline>
              <span className="d-flex align-items-center">
                <Spinner size="sm" className="flex-shrink-0">
                  Loading...
                </Spinner>
                <span className="flex-grow-1 ms-2">Loading...</span>
              </span>
            </Button>
          ) : (
            <Button disabled={otp?.length === 6 ? false : true} className="btn btn-primary" color="primary" onClick={onSubmit}>
              Submit
            </Button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default OtpInputModal;
