import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { indianProjectsBaseURL, customHeaders } from '../../../../Containers/Config/index.js';
import { ASK_QUESTION, ASK_QUESTION_SUCCESS, ASK_QUESTION_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* askQuestion({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/adib/admin/question`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        ...payload?.headers,
      },
      data: payload?.data,
      params: payload?.urlParams,
    });

    if (response.status === 200) {
      yield put({
        type: ASK_QUESTION_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: ASK_QUESTION_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: ASK_QUESTION_ERROR, payload: error });
  }
}

export function* watchaskQuestion() {
  yield takeEvery(ASK_QUESTION, askQuestion);
}

function* askQuestionSaga() {
  yield all([fork(watchaskQuestion)]);
}

export default askQuestionSaga;
