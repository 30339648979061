import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardBody, CardFooter, Col, Container, Input, Label, Row, Table } from 'reactstrap';
import { PreviewCardHeader } from '../../../../../../Common';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { UPDATE_GOLF_COURSE_SLOTS } from '../../../../../../../store/application/actionType';
import cogoToast from 'cogo-toast';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';
import { Constant } from '../../../../../../Helpers/constant';

const FormatList = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [headers, setHeaders] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [data, setData] = useState(null);
  const [updatedData, setUpdatedData] = useState(null);

  useEffect(() => {
    if (props?.data?.slotDates?.length > 0) {
      setUpdatedData(null);
      // Headers
      const tempHeaders = ['Time'];

      props?.data?.slotDates?.forEach(dateSlotsData => {
        tempHeaders.push(moment(`${dateSlotsData.date}`).format('Do MMMM YYYY, dddd'));
      });
      setHeaders(tempHeaders);

      // Data
      let timings = [];
      props?.data?.slotDates?.forEach(slots => {
        slots?.slots?.forEach(slot => {
          timings.push(slot.slotTime);
        });
      });
      timings = [...new Set(timings)].sort();

      const completeData = [];
      timings.forEach(time => {
        const row = [{ slotTime: time }];
        props?.data?.slotDates?.forEach(slots => {
          const tempSlot = slots?.slots?.filter(slot => slot.slotTime === time);

          if (tempSlot.length > 0) {
            row.push({ ...tempSlot[0] });
          } else {
            row.push(null);
          }
        });
        completeData.push(row);
      });

      setData(completeData);
      setOriginalData(completeData);
    }
  }, [props]);

  const handleInputChange = (e, oldData, rowIndex, colIndex) => {
    const tranformedData = JSON.parse(JSON.stringify(data));

    const newValue = parseInt(e.target.value) > 99 ? oldData?.availableCount : parseInt(e.target.value);

    const newData = {
      ...oldData,
      availableCount: newValue,
    };

    tranformedData[rowIndex][colIndex] = {
      ...newData,
      isChanged: true,
    };
    setData(tranformedData);

    if (updatedData === null) {
      setUpdatedData([{ slotPoolId: oldData.slotPoolId, count: newValue }]);
    } else {
      const findOutIndex = updatedData.findIndex(data => data?.slotPoolId === oldData?.slotPoolId);

      if (findOutIndex === -1) {
        setUpdatedData([...updatedData, { slotPoolId: oldData.slotPoolId, count: newValue }]);
      } else {
        const tempUpdate = [...updatedData];
        tempUpdate.splice(findOutIndex, 1, {
          slotPoolId: oldData.slotPoolId,
          count: newValue,
        });
        setUpdatedData(tempUpdate);
      }
    }
  };

  const resetHandler = () => {
    setData(originalData);
  };

  const submitHandler = () => {
    if (updatedData === null || updatedData.length === 0) {
      cogoToast.error(`Please change data for update.`);
      return;
    } else {
      const payload = {
        golfCourseId: parseInt(props?.data?.golfCourseId),
        poolData: [...updatedData],
      };

      dispatch({
        type: UPDATE_GOLF_COURSE_SLOTS,
        payload: {
          data: payload,
        },
      });
    }
  };

  return (
    <Col md={12}>
      <Card>
        <PreviewCardHeader title="Format List" />
        {/* <button onClick={() => console.log(data, updatedData)}>
          Get state
        </button> */}
        <CardBody>
          <div className="live-preview">
            <div className="table-responsive">
              <Table className="table-bordered table-striped table-nowrap align-middle table-nowrap mb-0 text-center">
                <thead>
                  <tr>
                    {headers?.map((headerName, index) => {
                      return (
                        <th key={index} scope="col">
                          {headerName}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {data?.map((rowData, rowIndex) => {
                    const d = rowData.map((colData, colIndex) => {
                      if (colIndex === 0) {
                        return <td key={colIndex}>{colData?.slotTime}</td>;
                      } else {
                        return (
                          <td key={colIndex}>
                            {colData !== null ? (
                              <input
                                type="number"
                                onWheel={e => e.target.blur()}
                                className="form-control"
                                value={colData?.availableCount}
                                onChange={e => handleInputChange(e, colData, rowIndex, colIndex)}
                                style={{
                                  border: colData?.isChanged ? '1px solid #405189' : '',
                                }}
                              />
                            ) : (
                              <input type="text" className="form-control" value={`NA`} disabled={true} />
                            )}
                          </td>
                        );
                      }
                    });
                    return <tr key={rowIndex}>{d}</tr>;
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </CardBody>

        {Constant?.CURRENTLY_ASSIGNED_PERMISSIONS?.includes(PermissionConstants?.ALL_PERMISSIONS?.UPDATE_GOLF_COURSE_FORMATS) && (
          <CardFooter>
            <Row>
              <Col md={6} className="p-0">
                <div className="start"></div>
              </Col>

              <Col md={6}>
                <div className="text-end">
                  <button type="submit" className="btn btn-danger mx-2 overlay-disable" onClick={resetHandler}>
                    Reset
                  </button>

                  <button type="submit" className="btn btn-success overlay-disable" onClick={submitHandler}>
                    Save Changes
                  </button>
                </div>
              </Col>
            </Row>
          </CardFooter>
        )}
      </Card>
    </Col>
  );
};

export default React.memo(FormatList, (prevProps, nextProps) => prevProps.data === nextProps.data);

// export default FormatList
