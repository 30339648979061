import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';

import FilesSummaryPreview from '../../Tables/FileSummaryPreview';
import { Constant } from '../../../../../Helpers/constant';
import { GET_FILES_SUMMARY, GET_FILE_SUMMARY } from '../../../../../../store/application/actionType';

const Conclusion = ({ selectedFileSummary }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  /* ---------------------------- REDUX STATES ---------------------------- */
  //    Files Summary
  const { fileSummary } = useSelector(state => ({
    fileSummary: state?.FileSummary?.data,
  }));

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);

    dispatch({
      type: GET_FILE_SUMMARY,
      payload: {
        params: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          pageSize: Constant?.TABLE_PAGE_SIZE,
          filterQuery: {
            projectCode: selectedFileSummary?.projectCode,
            fileId: selectedFileSummary?.fileId,
          },
        },
      },
    });
  };

  return (
    <div>
      <Row className="">
        <Col md={3}>
          <div className="card card-body text-center">
            <div className="avatar-sm mx-auto mb-3">
              <div className="avatar-title bg-soft-success text-success fs-17 rounded">
                <i className="mdi mdi-check"></i>
              </div>
            </div>
            <p className="card-title">{selectedFileSummary?.totalRecords ?? 0}</p>
            <h4 className="card-title">Total Records</h4>
          </div>
        </Col>
        <Col md={3}>
          <div className="card card-body text-center">
            <div className="avatar-sm mx-auto mb-3">
              <div className="avatar-title bg-soft-success text-success fs-17 rounded">
                <i className="mdi mdi-check"></i>
              </div>
            </div>
            <p className="card-title">{selectedFileSummary?.successRecords ?? 0}</p>
            <h4 className="card-title">Success Records</h4>
          </div>
        </Col>
        <Col md={3}>
          <div className="card card-body text-center">
            <div className="avatar-sm mx-auto mb-3">
              <div className="avatar-title bg-soft-danger text-danger fs-17 rounded">
                <i className="mdi mdi-cancel"></i>
              </div>
            </div>
            <p className="card-title">{selectedFileSummary?.failedRecords ?? 0}</p>
            <h4 className="card-title">Failed Records</h4>
          </div>
        </Col>
      </Row>

      <div>
        <FilesSummaryPreview
          data={fileSummary?.results}
          totalData={fileSummary?.count}
          pageNumber={currentPageNumber}
          dataStartedFrom={currentPageIndex}
          pageHandler={paginationHandler}
          selectedFileSummary={selectedFileSummary}
        />
      </div>
    </div>
  );
};

export default Conclusion;
