import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';

import { GET_BENEFIT_ITEMS_LIST, GET_BENEFIT_ITEMS_LIST_EMPTY } from './../../../../../../../../store/application/actionType';
import ReactPaginate from 'react-paginate';
import { Constant } from '../../../../../../../Helpers/constant';

const BenefitForSubCategoryModal = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */

  const [state, setState] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [modal_varying1, setmodal_varying1] = useState(true);

  const [totalData, setTotalData] = useState(0);

  const [currentAllServices, setCurrentAllServices] = useState([]);
  const [currentSelectedBenefitItems, setCurrentSelectedBenefitItems] = useState([]);

  const [newlyAddedBenefitItems, setNewlyAddedBenefitItems] = useState([]);
  const [removedBenefitItems, setRemovedBenefitItems] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Services List With Benefit Group Items
  const { benefitGroupItems, benefitGroupItemsLoading } = useSelector(state => ({
    benefitGroupItems: state?.BenefitItemsList?.data,
    benefitGroupItemsLoading: state?.BenefitItemsList?.loading,
  }));

  useEffect(() => {
    setState(props);
    setCurrentIndex(props?.data?.currentIndex);
    getBenefitListItems(props, 0);
  }, []);

  useEffect(() => {
    if (benefitGroupItems !== null) {
      setCurrentAllServices(benefitGroupItems?.results?.map(item => ({ ...item })));

      const currentBenefitItems = benefitGroupItems?.results?.filter(item => item.isBenefitAssociated === true)?.map(service => ({ ...service }));

      setCurrentSelectedBenefitItems([...newlyAddedBenefitItems, ...currentBenefitItems]);
      setTotalData(benefitGroupItems?.count);
    }
  }, [benefitGroupItems]);

  const getBenefitListItems = (data, pageIndex = 0) => {
    dispatch({
      type: GET_BENEFIT_ITEMS_LIST_EMPTY,
    });

    let urlParam = null;

    if (data?.data?.data?.benefitGroupItemType === `Benefit`) {
      urlParam = {
        serviceId: data?.data?.data?.benefitGroupItem?.completeData?.allocationTypeId,
      };
    } else if (data?.data?.data?.benefitGroupItemType === `Brand`) {
      urlParam = {
        partnerId: data?.data?.data?.benefitGroupItem?.completeData?.allocationTypeId,
      };
    } else if (data?.data?.data?.benefitGroupItemType === `Sub-category`) {
      urlParam = {
        categoryId: data?.data?.data?.benefitGroupItem?.completeData?.allocationTypeId,
      };
    }

    dispatch({
      type: GET_BENEFIT_ITEMS_LIST,
      payload: {
        urlParam: {
          ...urlParam,
          configId: data?.data?.data?.benefitGroupItem?.completeData?.objectId,
          pageIndex,
          pageSize: Constant?.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = service => {
    const tempCurrentSelectedBenefitItems = [...currentSelectedBenefitItems];

    const tempNewAddedBenefitItems = [...newlyAddedBenefitItems];
    const tempRemovedBenefitItems = [...removedBenefitItems];

    const isAlreadyAsItem = tempCurrentSelectedBenefitItems?.filter(item => item?.service?.id === service?.service?.id);

    if (isAlreadyAsItem?.length > 0) {
      const indexPosition = tempCurrentSelectedBenefitItems.findIndex(item => item?.service?.id === service?.service?.id);
      tempCurrentSelectedBenefitItems.splice(indexPosition, 1);

      tempRemovedBenefitItems.push(service);
      setRemovedBenefitItems(tempRemovedBenefitItems);

      // ------
      const NeedToRemoveIndex = tempNewAddedBenefitItems?.findIndex(item => item?.service?.id === tempRemovedBenefitItems?.[0]?.service?.id);
      if (NeedToRemoveIndex !== -1) {
        tempNewAddedBenefitItems.splice(NeedToRemoveIndex, 1);
        setNewlyAddedBenefitItems(tempNewAddedBenefitItems);
      }
    } else {
      tempCurrentSelectedBenefitItems.push(service);

      tempNewAddedBenefitItems.push(service);
      setNewlyAddedBenefitItems(tempNewAddedBenefitItems);

      // ------
      const NeedToRemoveIndex = tempRemovedBenefitItems?.findIndex(item => item?.service?.id === tempNewAddedBenefitItems?.[0]?.service?.id);
      if (NeedToRemoveIndex !== -1) {
        tempRemovedBenefitItems.splice(NeedToRemoveIndex, 1);
        setRemovedBenefitItems(tempRemovedBenefitItems);
      }
    }

    setCurrentSelectedBenefitItems([...tempCurrentSelectedBenefitItems]);
    setCurrentAllServices([...currentAllServices]);
  };

  const onPageChangeHandler = event => {
    getBenefitListItems(state, event?.selected * Constant?.TABLE_PAGE_SIZE);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    let data = null;

    const allAlreadySelectedItems = currentAllServices
      ?.map(bItem => {
        if (bItem?.service?.id && bItem?.isBenefitAssociated) {
          return bItem?.service?.id;
        } else {
          return null;
        }
      })
      .filter(Boolean);

    const newlyData = newlyAddedBenefitItems?.filter(newItem => !allAlreadySelectedItems?.includes(newItem?.service?.id));

    if (props?.data?.data?.status === `Update`) {
      if (props?.data?.data?.benefitGroupItemType === `Sub-category`) {
        data = {
          currentIndex: currentIndex,
          data: {
            ...state?.data?.data,
            benefitGroupItem: {
              ...state?.data?.data?.benefitGroupItem,
              completeData: {
                ...state?.data?.data?.benefitGroupItem?.completeData,
                category: {
                  id: state?.data?.data?.benefitGroupItem?.completeData?.objectId,
                  name: state?.benefitGroupItem?.completeData?.name,
                },
                removeItems: removedBenefitItems?.map(item => item?.benefitItem?.id),
                services: newlyData?.map(service => ({
                  ...service?.service,
                })),
              },
            },
          },
        };
      } else {
        data = {
          currentIndex: currentIndex,
          data: {
            ...state?.data?.data,
            benefitGroupItem: {
              ...state?.data?.data?.benefitGroupItem,
              completeData: {
                ...state?.data?.data?.benefitGroupItem?.completeData,
                removeItems: removedBenefitItems?.map(item => item?.benefitItem?.id),
                services: newlyData?.map(service => ({
                  ...service?.service,
                })),
              },
            },
          },
        };
      }
    }

    // console.log("submit", data);
    props.onSubmit(data);

    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);

    dispatch({
      type: GET_BENEFIT_ITEMS_LIST_EMPTY,
    });
  };

  /* ---------------------------- CLOSE HANDLER ---------------------------- */
  const onCloseHandler = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);

    dispatch({
      type: GET_BENEFIT_ITEMS_LIST_EMPTY,
    });
  };

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);

    dispatch({
      type: GET_BENEFIT_ITEMS_LIST_EMPTY,
    });
  };

  const haveChecked = actionableService => {
    const tempCurrentSelectedBenefitItems = [...currentSelectedBenefitItems];
    const isAvailable = tempCurrentSelectedBenefitItems?.filter(service => service?.service?.id === actionableService?.service?.id);

    if (isAvailable?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
      centered
      size="lg"
      style={{ position: 'relative' }}
    >
      <ModalHeader
        toggle={() => {
          tog_varying1();
        }}
      >
        Add Benefit for Sub-Category
      </ModalHeader>

      {/* <button
        onClick={() =>
          console.log(
            state,
            currentAllServices,
            currentSelectedBenefitItems,
            newlyAddedBenefitItems,
            removedBenefitItems,
          )
        }
      >
        Get State
      </button> */}

      <ModalBody style={{ position: 'relative' }}>
        {benefitGroupItemsLoading && (
          <div className="loader" style={{ inset: 0, position: 'absolute' }}>
            <div className="lds-facebook">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}

        <Row>
          <Col md={12}>
            <div className="table-responsive table-card mt-4" style={{ maxHeight: '300px' }}>
              <Table className="align-middle table-nowrap">
                <thead className="table-light">
                  <tr>
                    <th scope="col" style={{ width: '46px' }}></th>
                    <th scope="col">ID</th>
                    <th scope="col">Brand</th>
                    <th scope="col">Benefit</th>
                  </tr>
                </thead>
                <tbody>
                  {currentAllServices?.map((service, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="form-check">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id="cardtableCheck01"
                              value={service}
                              checked={haveChecked(service)}
                              onChange={() => inputChangeHandler(service, index)}
                            />
                            <Label className="form-check-label" htmlFor="cardtableCheck01"></Label>
                          </div>
                        </td>
                        <td>{service?.service?.id}</td>
                        <td>{service?.partner?.name}</td>
                        <td>{service?.service?.name}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '35px' }}>
          <Col className="text-end">
            <ReactPaginate
              previousLabel={'previous'}
              nextLabel={'next'}
              breakLabel={'...'}
              pageCount={Math.ceil(totalData / Constant.TABLE_PAGE_SIZE)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={onPageChangeHandler}
              containerClassName={'pagination justify-content-center'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter className="d-flex justify-content-end">
        <Button className="overlay-disable btn btn-light" color="primary" onClick={onCloseHandler}>
          Close
        </Button>

        <Button className="overlay-disable" color="primary" onClick={submitHandler}>
          Add to list
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default BenefitForSubCategoryModal;
