import { useEffect, useState } from 'react';

import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import { Card, CardBody, Col, Form, Label, Row, CardFooter } from 'reactstrap';

import { PreviewCardHeader } from '../../../../../../../Common';

const initialState = {
  date: {
    from: '',
    to: '',
  },
};

const Filter = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [filter, setFilter] = useState(initialState);

  useEffect(() => {
    if (props?.appliedFilters) {
      setFilter(props?.appliedFilters);
    }
  }, [props?.appliedFilters]);

  const datePickHandler = (flag, date) => {
    if (flag === 'date') {
      const updatedState = {
        ...filter,
        date: {
          from: moment(new Date(date[0])).format('Y-MM-DD'),
          to: moment(new Date(date[1])).format('Y-MM-DD'),
        },
      };
      setFilter(updatedState);
    }
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    props.filterHandler({ ...filter, filter: true });
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter(initialState);
    props.filterHandler({ ...initialState, filter: false });
  };

  return (
    <Row>
      <Col xxl={12}>
        <Card>
          <PreviewCardHeader title="Filter" />
          <CardBody>
            <div className="live-preview">
              <Form>
                <Row>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="created-at-range" className="form-label">
                        Created At - Range
                        <span className="icon_calendar">
                          <i className="las la-calendar"></i>
                        </span>
                      </Label>
                      <div className="area_expandable">
                        <Flatpickr
                          className="form-control"
                          onChange={date => datePickHandler('date', date)}
                          value={[filter?.date?.from, filter?.date?.to]}
                          options={{
                            mode: 'range',
                            dateFormat: 'Y-m-d',
                            maxDate: 'today',
                          }}
                          placeholder=""
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </CardBody>

          <CardFooter>
            <Row>
              <Col md={12}>
                <div className="text-end">
                  <button type="submit" className="btn btn-primary mx-3" onClick={filterSubmitHandler}>
                    <i className="ri-search-line me-1 text-white fs-14 align-middle"></i>Search
                  </button>
                  <button type="submit" className="btn btn-light" onClick={resetFilterHandler}>
                    Reset Filter
                  </button>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
};

export default Filter;
