import moment from 'moment';
import { Col, Container, Row } from 'reactstrap';

const ThriweDiningDashboard = () => {
  /* ---------------------------- GREETINGS ---------------------------- */
  const getGreeting = () => {
    const currentTime = moment();
    const hour = currentTime.hours();

    if (hour >= 5 && hour < 12) {
      return 'morning';
    } else if (hour >= 12 && hour < 17) {
      return 'afternoon';
    } else if (hour >= 17 && hour < 21) {
      return 'evening';
    } else {
      return 'night';
    }
  };

  return (
    <div className="page-content">
      <Container fluid className="position-relative">
        <Row>
          <Col md={12}>
            <div className="d-flex align-items-lg-center flex-lg-row flex-column mb-3 pb-1">
              <div className="flex-grow-1">
                <h4 className="fs-16 mb-1">
                  Good {getGreeting()}, {JSON.parse(localStorage.getItem('authUser'))?.data?.firstName}
                </h4>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ThriweDiningDashboard;
