import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, thriweMarketPlaceUrl } from '../../../../Containers/Config/index.js';
import { GET_BOOKING_LIST_V2, GET_BOOKING_LIST_V2_SUCCESS, GET_BOOKING_LIST_V2_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getBookingListV2({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${thriweMarketPlaceUrl}/v1/admin/bookings`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        'Content-Type': 'application/json',
        'Project-Code': 'thriwe_app'?.toLocaleUpperCase(),
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      // yield put({
      //   type: MAIN_AREA_LOADER_HIDE,
      // })

      yield put({
        type: GET_BOOKING_LIST_V2_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_BOOKING_LIST_V2_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_BOOKING_LIST_V2_ERROR, payload: error });
  }
}

export function* watchBookingListV2() {
  yield takeEvery(GET_BOOKING_LIST_V2, getBookingListV2);
}

function* bookingListV2Saga() {
  yield all([fork(watchBookingListV2)]);
}

export default bookingListV2Saga;
