import React, { useEffect, useState } from 'react';

import { Card, Col, Row, CardBody, Input, Label, CardHeader } from 'reactstrap';
import Select from 'react-select';
import { Constant } from '../../../../../../Helpers/constant';
import UserHistoryModal from '../UserHistoryModal';
import { Error, Loader } from './../../../../../../Common';
import { useDispatch, useSelector } from 'react-redux';
import {
  GET_USER_BOOKINGS,
  GET_USER_BOOKINGS_EMPTY,
  GET_USER_POINTS,
  GET_USER_POINTS_EMPTY,
  GET_WALLETS,
  GET_WALLETS_EMPTY,
} from '../../../../../../../store/application/actionType';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index';
import moment from 'moment';

const ProjectStats = ({ data, filterDateHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    caNumber: '',
  };
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [isValidationShow, setIsValidationShow] = useState(false);
  const [isUserHistoryModalShow, setIsUserHistoryModalShow] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [dateRange, setDateRange] = useState({ from: null, to: null });

  /* ---------------------------- REDUX STATES ---------------------------- */
  // User Points
  const { userPoints, userPointsLoading } = useSelector(state => ({
    userPoints: state?.GetUserPoints?.data,
    userPointsLoading: state?.GetUserPoints?.loading,
  }));

  // User Bookings
  const { userBookings, userBookingsLoading } = useSelector(state => ({
    userBookings: state?.GetUserBookings?.data,
    userBookingsLoading: state?.GetUserBookings?.loading,
  }));

  useEffect(() => {
    if (userBookings !== null) {
      dispatch({
        type: GET_USER_POINTS,
        payload: {
          urlParam: {
            caNumber: parseInt(state?.caNumber),
          },
        },
      });
      setIsUserHistoryModalShow(true);
    }
  }, [userBookings]);

  useEffect(() => {
    return () => {
      setIsUserHistoryModalShow(false);
      setIsValidationShow(false);

      dispatch({
        type: GET_USER_POINTS_EMPTY,
      });
      dispatch({
        type: GET_USER_BOOKINGS_EMPTY,
      });
      dispatch({
        type: GET_WALLETS_EMPTY,
      });
    };
  }, []);

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'caNumber':
        updatedState = {
          ...state,
          caNumber: value?.length > 9 ? state?.caNumber : value,
        };
        break;
      default:
      // do nothing
    }
    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- VALIDATE ERROR ---------------------------- */
  const validateErrors = (flag, data = state) => {
    if (data?.caNumber?.trim()?.length === 9) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(`submissionCheck`, state)) {
      dispatch({
        type: GET_USER_BOOKINGS,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            caNumber: parseInt(state?.caNumber),
          },
        },
      });
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);
    dispatch({
      type: GET_USER_POINTS,
      payload: {
        urlParam: {
          caNumber: parseInt(state?.caNumber),
        },
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (userPointsLoading || userBookingsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [userPointsLoading, userBookingsLoading]);

  const filterDateHandlerForUserBooking = date => {
    if (date.length === 2) {
      dispatch({
        type: GET_USER_BOOKINGS,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            caNumber: parseInt(state?.caNumber),
            dateRange: {
              from: moment(new Date(date[0])).format('Y-MM-DD'),
              to: moment(new Date(date[1])).format('Y-MM-DD'),
            },
          },
        },
      });
    }
  };

  return (
    <>
      {loading && <Loader zIndex={true} />}
      {isUserHistoryModalShow && (
        <UserHistoryModal
          userPoints={userPoints}
          userBookings={userBookings}
          onCloseHandler={() => setIsUserHistoryModalShow(false)}
          pageNumber={currentPageNumber}
          pageHandler={paginationHandler}
          filterDateHandler={filterDateHandlerForUserBooking}
        />
      )}

      {/* <Card className="card-inner">
        <CardBody>
          <Row>
            <Col md={4}>
              <Label htmlFor="programId" className="form-label">
                Program Name
              </Label>
              <Select
                id="programId"
                name="programId"
                isDisabled={true}
                value={{
                  value: Constant?.CURRENT_SELECTED_PROGRAM?.id,
                  label: Constant?.CURRENT_SELECTED_PROGRAM?.name,
                }}
                // options={projects?.results?.map((project) => ({
                //     value: project?.id,
                //     label: project?.name,
                // }))}
                // onChange={(data) => inputSelectHandler(`programId`, data)}
              />
            </Col>

            <Col md={4}>
              <Label htmlFor="Wallet" className="form-label">
                Wallet
              </Label>
              <Select
                id="Wallet"
                name="Wallet"
                // isDisabled={true}
                value={{
                  value: Constant?.CURRENT_SELECTED_PROGRAM?.id,
                  label: Constant?.CURRENT_SELECTED_PROGRAM?.name,
                }}
                // options={projects?.results?.map((project) => ({
                //     value: project?.id,
                //     label: project?.name,
                // }))}
                // onChange={(data) => inputSelectHandler(`programId`, data)}
              />
            </Col>

            <Col md={4} className="align-self-end">
              <button type="button" className="btn btn-primary" disabled>
                Apply
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card> */}

      <Card className="card-inner">
        <CardHeader>
          <h5>User History</h5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={4}>
              <div>
                <Label htmlFor="validityTatValue" className="form-label">
                  CA Number
                </Label>
                <Input
                  id="caNumber"
                  name="caNumber"
                  type="number"
                  className="form-control"
                  onChange={inputChangeHandler}
                  value={state?.caNumber}
                  autoComplete="off"
                />
              </div>
              {isValidationShow && state?.caNumber?.trim()?.length !== 9 ? <Error text="Please enter CA number" /> : <span>&nbsp;</span>}
            </Col>

            <Col md={4} className="align-self-center mt-2">
              <button type="button" className="btn btn-primary" onClick={submitHandler}>
                Submit
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="card-inner">
        <CardHeader>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h5>Program Summary</h5>
            <Flatpickr
              className="form-control"
              style={{ width: '120px' }}
              onChange={filterDateHandler}
              value={`${moment().startOf('month').format('YYYY-MM-DD')}`}
              options={{
                maxDate: moment().endOf('month').format('YYYY-MM-DD'),
                plugins: [
                  new monthSelectPlugin({
                    shorthand: true,
                    dateFormat: 'Y-m',
                    altFormat: 'F Y',
                    theme: 'dark',
                  }),
                ],
              }}
              placeholder="Select Month"
            />
          </div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={3}>
              <div className="p-2 my-1 border border-dashed rounded bg-white">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm me-2">
                    <div className="avatar-title rounded bg-transparent text-primary fs-24">
                      <i className="ri-user-line"></i>
                    </div>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <p className="text-muted mb-1">Total Users:</p>
                    <h5 className="mb-0">{data?.totalUsers ?? '0'}</h5>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={3}>
              <div className="p-2 my-1 border border-dashed rounded bg-white">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm me-2">
                    <div className="avatar-title rounded bg-transparent text-primary fs-24">
                      <i className="ri-wallet-3-line"></i>
                    </div>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <p className="text-muted mb-1">Total Wallet Balance:</p>
                    <h5 className="mb-0">
                      <span className="text-warning fs-20">
                        <i className="lab las la-coins"></i>
                      </span>
                      {data?.totalWalletBalance ?? '0'}
                    </h5>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={3}>
              <div className="p-2 my-1 border border-dashed rounded bg-white">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm me-2">
                    <div className="avatar-title rounded bg-transparent text-primary fs-24">
                      <i className="ri-ticket-2-line"></i>
                    </div>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <p className="text-muted mb-1">No. of rewards claimed:</p>
                    <h5 className="mb-0">{data?.noOfRewardsClaimed ?? '0'}</h5>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={3}>
              <div className="p-2 my-1 border border-dashed rounded bg-white">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm me-2">
                    <div className="avatar-title rounded bg-transparent text-primary fs-24">
                      <i className="ri-map-pin-user-line"></i>{' '}
                    </div>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <p className="text-muted mb-1">Unique User Rewards Claimed:</p>
                    <h5 className="mb-0">
                      <span className="text-warning fs-20">
                        <i className="lab las la-coins"></i>
                      </span>
                      {data?.uniqueUserRewards ?? '0'}
                    </h5>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={3}>
              <div className="p-2 my-1 border border-dashed rounded bg-white">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm me-2">
                    <div className="avatar-title rounded bg-transparent text-primary fs-24">
                      <i className="ri-map-pin-user-line"></i>{' '}
                    </div>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <p className="text-muted mb-1">Average Points Per Unique User:</p>
                    <h5 className="mb-0">
                      <span className="text-warning fs-20">
                        <i className="lab las la-coins"></i>
                      </span>
                      {data?.pointsPerUser ?? '0'}
                    </h5>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={3}>
              <div className="p-2 my-1 border border-dashed rounded bg-white">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm me-2">
                    <div className="avatar-title rounded bg-transparent text-primary fs-24">
                      <i className="ri-ticket-2-line"></i>
                    </div>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <p className="text-muted mb-1">Total coins received:</p>
                    <h5 className="mb-0">{data?.totalIncoming ?? '0'}</h5>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={3}>
              <div className="p-2 my-1 border border-dashed rounded bg-white">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm me-2">
                    <div className="avatar-title rounded bg-transparent text-primary fs-24">
                      <i className="ri-ticket-2-line"></i>
                    </div>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <p className="text-muted mb-1">Total coins spent:</p>
                    <h5 className="mb-0">{data?.totalOutgoingCoins ?? '0'}</h5>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={3}>
              <div className="p-2 my-1 border border-dashed rounded bg-white">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm me-2">
                    <div className="avatar-title rounded bg-transparent text-primary fs-24">
                      <i className=" ri-delete-back-2-line"></i>{' '}
                    </div>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <p className="text-muted mb-1">Total Coins Expired:</p>
                    <h5 className="mb-0">
                      <span className="text-warning fs-20">
                        <i className="lab las la-coins"></i>
                      </span>
                      {data?.totalCoinsExpired ?? '0'}
                    </h5>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default ProjectStats;
