import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardBody, Col, Container, Form, Label, Row, CardFooter } from 'reactstrap';
import { PreviewCardHeader } from '../../../../Common';
import { Constant } from '../../../../Helpers/constant';
import { AsyncPaginate } from 'react-select-async-paginate';
import { GET_PERMISSIONS, UPDATE_PERMISSIONS } from '../../../../../store/application/actionType';
import { baseUrl, customHeaders } from '../../../../Config';

const AssignRightsForm = ({ adminUser }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    userId: ``,
    permissionsToGive: [],
  };
  const [state, setState] = useState(initialState);
  const [allPermissions, setAllPermissions] = useState(null);
  const [isPermissionChanged, setIsPermissionChanged] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get All Permissions
  const { permissions } = useSelector(state => ({
    permissions: state?.Permissions?.data,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    const tempPermissions = adminUser?.groupsAndPermissions?.permissions?.map(permission => permission?.code);

    setState({
      ...state,
      userId: {
        label: adminUser?.name,
        value: adminUser?.objectId,
      },
      permissionsToGive: [...new Set(tempPermissions)],
    });
  }, [adminUser]);

  useEffect(() => {
    if (permissions === null) {
      dispatch({
        type: GET_PERMISSIONS,
      });
    } else {
      setAllPermissions(permissions);
    }
  }, [permissions]);

  /* ---------------------------- TYPED USER HANDLER ---------------------------- */
  async function loadOptions(search, loadedOptions) {
    const response = await fetch(`${baseUrl}/admin/users?name=${search}`, {
      method: `GET`,
      headers: new Headers({
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      }),
    });
    const usersResponse = await response.json();

    return {
      options: usersResponse?.results?.map((data, index) => {
        return {
          value: data.objectId,
          label: `${data.name}`,
          completeData: data,
        };
      }),
      hasMore: false,
    };
  }

  /* ---------------------------- ON SELECT USER ---------------------------- */
  const onSelectUser = event => {
    const tempPermissions = event?.completeData?.groupsAndPermissions?.permissions?.map(permission => permission?.code);

    setState({
      ...state,
      userId: event,
      permissionsToGive: [...new Set(tempPermissions)],
    });
  };

  /* ---------------------------- FEATURE CHECKBOX HANDLER ---------------------------- */
  const checkboxHandler = data => {
    let updatedState = [...state.permissionsToGive];
    setIsPermissionChanged(true);

    if (state?.permissionsToGive?.includes(data?.code)) {
      // Remove
      const permissionIndex = updatedState.findIndex(per => per === data.code);
      updatedState.splice(permissionIndex, 1);
    } else {
      // Add
      updatedState.push(data.code);
    }

    setState({
      ...state,
      permissionsToGive: [...new Set(updatedState)],
    });
  };

  /* ---------------------------- GROUP CHECKBOX HANDLER ---------------------------- */
  const groupCheckboxHandler = data => {
    setIsPermissionChanged(true);
    let updatedState = [...state.permissionsToGive];

    if (state?.permissionsToGive?.includes(data?.objectId)) {
      // Remove
      let permissionToRemove = [data?.objectId];
      data?.permissions?.forEach(permission => {
        permissionToRemove.push(permission.code);
      });

      updatedState = updatedState.filter(value => {
        return !permissionToRemove.includes(value);
      });
    } else {
      // Add
      const tempPermission = data?.permissions?.map(per => per?.code);
      updatedState = [...updatedState, ...tempPermission, data?.objectId];
      setState({
        ...state,
        permissionsToGive: [...new Set(updatedState)],
      });
    }

    setState({
      ...state,
      permissionsToGive: [...new Set(updatedState)],
    });
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const handleUpdatePermissions = () => {
    const payload = {
      userId: state?.userId?.value,
      permissionsToGive: state?.permissionsToGive,
    };

    dispatch({
      type: UPDATE_PERMISSIONS,
      payload: {
        data: payload,
      },
    });
  };

  return (
    <>
      {/* <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Assign User Rights" />
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="program" className="form-label">
                          User <span className="lbl_mandatory">*</span>
                        </Label>

                        <AsyncPaginate
                          debounceTimeout={500}
                          value={state?.userId}
                          loadOptions={loadOptions}
                          onChange={(e) => onSelectUser(e)}
                          isMulti={false}
                          closeMenuOnSelect={true}
                          noOptionsMessage={() => 'No results found'}
                          cacheUniqs={[['code']]}
                          placeholder="Enter Name..."
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row> */}

      {/* Group */}
      {state?.userId !== `` &&
        allPermissions?.results?.groups?.group?.map((group, groupIndex) => {
          return (
            <Row key={groupIndex}>
              <Col xxl={12}>
                <Card>
                  <CardBody className="p-4">
                    <Label className="">
                      <input
                        type="checkbox"
                        className="form-check-input cursor-pointer"
                        id={`${group?.objectId}`}
                        style={{ cursor: 'pointer' }}
                        checked={state?.permissionsToGive.includes(group?.objectId)}
                        onChange={() => groupCheckboxHandler(group)}
                      />
                    </Label>
                    <Label className="fw-semibold mx-1 cursor-pointer" htmlFor={`${group?.objectId}`}>
                      {group?.name}
                    </Label>

                    <div className="border mt-2 mb-3 border"></div>
                    <Row>
                      {group?.permissions?.map((groupPermission, groupPermissionIndex) => {
                        return (
                          <Col md={3} key={groupPermissionIndex}>
                            <div className="mb-3">
                              <Label className="">
                                <input
                                  type="checkbox"
                                  id={`${groupPermission?.name}`}
                                  className="form-check-input"
                                  style={{ cursor: 'pointer' }}
                                  checked={state?.permissionsToGive.includes(groupPermission?.code)}
                                  onChange={() => checkboxHandler(groupPermission)}
                                />
                              </Label>
                              <Label className="mx-1 cursor-pointer font-weight-normal" htmlFor={`${groupPermission?.name}`}>
                                {groupPermission?.name}
                              </Label>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          );
        })}

      {/* Permissions */}
      {state?.userId !== `` && (
        <Row>
          <Col xxl={12}>
            <Card>
              <CardBody className="p-4">
                <h6 className="fw-semibold text-uppercase mb-3">Consolidate Permissions</h6>
                <div className="border mt-2 mb-3 border"></div>
                <Row>
                  {allPermissions?.results?.permissions?.permissions?.map((permission, permissionIndex) => {
                    return (
                      <Col md={3} key={permissionIndex}>
                        <div className="mb-3">
                          <Label className="">
                            <input
                              type="checkbox"
                              id={`${permission?.name}`}
                              className="form-check-input cursor-pointer"
                              checked={state?.permissionsToGive.includes(permission?.code)}
                              onChange={() => checkboxHandler(permission)}
                            />
                          </Label>
                          <Label className="mx-1 cursor-pointer font-weight-normal" htmlFor={`${permission?.name}`}>
                            {permission?.name}
                          </Label>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

      {state?.userId !== `` && (
        <Row>
          <Card>
            <CardFooter>
              <Row>
                <Col md={12}>
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-primary mx-3 overlay-disable"
                      style={{ cursor: 'pointer' }}
                      onClick={handleUpdatePermissions}
                      disabled={!isPermissionChanged}
                    >
                      Update
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Row>
      )}
    </>
  );
};

export default AssignRightsForm;
