import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, commsHeader, indianProjectsBaseURL } from '../../../../Containers/Config/index.js';
import {
  CREATE_COMS_CHANNEL_CONFIG_PROJECT,
  CREATE_COMS_CHANNEL_CONFIG_PROJECT_SUCCESS,
  CREATE_COMS_CHANNEL_CONFIG_PROJECT_EMPTY,
  CREATE_COMS_CHANNEL_CONFIG_PROJECT_ERROR,
} from './../../actionType.js';
import apiCall from '../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* createComsChannelConfig({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/comms/projects/channel`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        ...commsHeader,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({
        type: CREATE_COMS_CHANNEL_CONFIG_PROJECT_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: CREATE_COMS_CHANNEL_CONFIG_PROJECT_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({
      type: CREATE_COMS_CHANNEL_CONFIG_PROJECT_ERROR,
      payload: error,
    });
  }
}

export function* watchCreateComsChannelConfig() {
  yield takeEvery(CREATE_COMS_CHANNEL_CONFIG_PROJECT, createComsChannelConfig);
}

function* createComsChannelConfigSaga() {
  yield all([fork(watchCreateComsChannelConfig)]);
}

export default createComsChannelConfigSaga;
