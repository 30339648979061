import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Flatpickr from 'react-flatpickr';
import * as moment from 'moment';

import { Card, CardBody, Col, Form, Label, Row, CardFooter, Input, ButtonGroup, Button } from 'reactstrap';
import Select from 'react-select';
import { Constant } from '../../../../../../../Helpers/constant';
import { PreviewCardHeader } from '../../../../../../../Common';
import { isANumber, isValidAlphaNumbericWithoutSpace } from '../../../../../../../Helpers/Helpers';
import { GET_CLIENT_CUSTOMER, GET_CLIENT_HISTORY_LIST } from '../../../../../../../../store/actions';

const initialState = {
  email: ``,
  mobileNumber: ``,
};

const Filter = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [filter, setFilter] = useState(initialState);

  useEffect(() => {
    if (props?.appliedFilters) {
      setFilter(props?.appliedFilters);
    }
  }, []);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...filter };
    let tempMobileNumber = '';

    switch (name) {
      case 'email':
        updatedState = {
          ...filter,
          email: value,
        };
        break;

      case 'mobileNumber':
        if (value?.length === 0) {
          tempMobileNumber = ``;
        } else {
          tempMobileNumber = isANumber(value) ? value : filter?.mobileNumber;
        }

        updatedState = {
          ...filter,
          mobileNumber: tempMobileNumber,
        };
        break;

      default:
      // do nothing
    }

    setFilter(updatedState);
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    props.filterHandler({ ...filter });
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter({ ...initialState });
    props?.setCurrentPageNumber(0);

    dispatch({
      type: GET_CLIENT_CUSTOMER,
      payload: {
        urlParam: {
          pageIndex: 0,
        },
        headers: {
          code: 'ADIB',
        },
      },
    });
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Filter" />
            {/* <button onClick={() => console.log({ filter })}>get state</button> */}
            <CardBody>
              <div className="live-preview">
                <Row>
                  <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                    <div className="mb-3">
                      <Label htmlFor="email" className="form-label">
                        Email
                      </Label>
                      <Input id="email" name="email" type="text" className="form-control" value={filter?.email} onChange={inputChangeHandler} />
                    </div>
                  </Col>

                  <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                    <div className="mb-3">
                      <Label htmlFor="mobileNumber" className="form-label">
                        Mobile No.
                      </Label>
                      <Input
                        id="mobileNumber"
                        name="mobileNumber"
                        type="text"
                        className="form-control"
                        value={filter?.mobileNumber}
                        onChange={inputChangeHandler}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>

            <CardFooter>
              <Row>
                <Col md={12}>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary mx-3" onClick={filterSubmitHandler}>
                      Search
                    </button>

                    <button type="submit" className="btn btn-light" onClick={resetFilterHandler}>
                      Reset Filter
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Filter;
