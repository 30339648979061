import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { UPDATE_PAYMENT_SETTING } from '../../../../../../../store/application/actionType';

const ConfirmationModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [modal_varying1, setmodal_varying1] = useState(true);

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = label => {
    if (label === `YES`) {
      dispatch({
        type: UPDATE_PAYMENT_SETTING,
        payload: {
          data: data,
        },
      });
    }

    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
      //   scrollable={true}
      centered
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 style={{ textAlign: 'center' }}>Are you sure you want to update ?</h5>
        <Button type="button" onClick={() => tog_varying1()} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close"></Button>
      </div>

      <ModalBody>
        <h4 style={{ textAlign: 'center' }}>Are you sure you want to update ?</h4>

        <div className=" d-flex justify-content-center mt-3">
          <Button className="btn btn-primary mx-1" color="primary" onClick={() => submitHandler(`YES`)}>
            Yes
          </Button>

          <Button className="btn btn-danger mx-1" color="danger" onClick={() => submitHandler(`NO`)}>
            No
          </Button>
        </div>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center"></div>
    </Modal>
  );
};

export default ConfirmationModal;
