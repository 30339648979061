import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../../Containers/Config/index';
import { GET_WALLETS, GET_WALLETS_SUCCESS, GET_WALLETS_ERROR } from '../../../actionType';
import apiCall from '../../../apiCall';
import { Constant } from '../../../../../Containers/Helpers/constant';

function* getWallets({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/wallet`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload?.urlParam,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_WALLETS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_WALLETS_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_WALLETS_ERROR, payload: error });
  }
}

export function* watchGetWallets() {
  yield takeEvery(GET_WALLETS, getWallets);
}

function* getWalletsSaga() {
  yield all([fork(watchGetWallets)]);
}

export default getWalletsSaga;
