import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from './../../../../../Containers/Config/index';
import { GET_REDEMPTION_TYPE, GET_REDEMPTION_TYPE_SUCCESS, GET_REDEMPTION_TYPE_ERROR } from './../../../actionType';
import apiCall from '../../../apiCall';
import { Constant } from '../../../../../Containers/Helpers/constant';

function* getRedemptionType({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/redemption-types`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_REDEMPTION_TYPE_SUCCESS,
        payload: response?.data?.results,
      });
    } else {
      yield put({
        type: GET_REDEMPTION_TYPE_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_REDEMPTION_TYPE_ERROR, payload: error });
  }
}

export function* watchGetRedemptionTypeSaga() {
  yield takeEvery(GET_REDEMPTION_TYPE, getRedemptionType);
}

function* getRedemptionTypeSaga() {
  yield all([fork(watchGetRedemptionTypeSaga)]);
}

export default getRedemptionTypeSaga;
