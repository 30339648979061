import React from 'react';
import { Container } from 'reactstrap';
import TabNavigation from './Components/CatalogueForm/TabNavigation';
import CatalogueForm from './Components/CatalogueForm';

const CatalogueManagement = () => {
  return (
    <div className="page-content">
      <Container fluid className="position-relative">
        <CatalogueForm />
      </Container>
    </div>
  );
};

export default CatalogueManagement;
