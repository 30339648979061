import React from 'react';
import { Button } from 'reactstrap';
import { PreviewCardHeader } from '../../../../../Common';
import { useHistory } from 'react-router-dom';

const Success = ({ booking, bookingType }) => {
  const history = useHistory();
  return (
    <>
      <PreviewCardHeader title="Booking Success" />
      <div className="container">
        <h4 className="text-center mt-5">We have received your booking request</h4>
        <h4 className="text-center mt-5">
          Booking ID: <span>{booking?.BookingId}</span>
        </h4>
        <h6
          className="text-center mt-3 p-3 d-flex rounded align-items-center justify-content-center gap-2"
          style={{ background: '#C8DBF5', borderRadius: '10px !important' }}
        >
          <i className=" ri-information-fill"></i>
          {bookingType === 'MeetAndGreet' && `The confirmation will be sent within 12 hours.`}

          {bookingType === 'AirpotTransfer' &&
            `The confirmation will be sent within 12 hours and the chauffeur details will be shared within 4 hours from the time of pickup.`}
        </h6>
      </div>
      <div className="d-flex justify-content-center mt-5">
        <Button color="success" className="w-lg" onClick={() => history.push('/client/dashboard')}>
          Go to Dashboard
        </Button>
      </div>
    </>
  );
};

export default Success;
