import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../Containers/Config/index.js';
import { CREATE_CLIENTS, CREATE_CLIENTS_SUCCESS, CREATE_CLIENTS_ERROR } from '../../actionType.js';
import apiCall from '../../apiCall.js';
import { Constant } from '../../../../Containers/Helpers/constant.js';
import cogoToast from 'cogo-toast';

function* createClient({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/clients`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      cogoToast.success(`Client created successfully`);
      yield put({
        type: CREATE_CLIENTS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: CREATE_CLIENTS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: CREATE_CLIENTS_ERROR, payload: error });
  }
}

export function* watchCreateClient() {
  yield takeEvery(CREATE_CLIENTS, createClient);
}

function* createClientSaga() {
  yield all([fork(watchCreateClient)]);
}

export default createClientSaga;
