import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, thriweMarketPlaceUrl } from '../../../../Containers/Config/index.js';
import { CONFIRM_BOOKING_V2, CONFIRM_BOOKING_V2_SUCCESS, CONFIRM_BOOKING_V2_ERROR } from '../../actionType.js';
import apiCall from '../../apiCall.js';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* confirmBookingV2({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${thriweMarketPlaceUrl}/v1/admin/confirm-booking`,
      method: 'PUT',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        'Content-Type': 'application/json',
        'Project-Code': 'thriwe_app'?.toLocaleUpperCase(),
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({
        type: CONFIRM_BOOKING_V2_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: CONFIRM_BOOKING_V2_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: CONFIRM_BOOKING_V2_ERROR, payload: error });
  }
}

export function* watchConfirmBookingV2() {
  yield takeEvery(CONFIRM_BOOKING_V2, confirmBookingV2);
}

function* confirmBookingV2Saga() {
  yield all([fork(watchConfirmBookingV2)]);
}

export default confirmBookingV2Saga;
