import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'reactstrap';
import {
  CREATE_ATTRIBUTE,
  CREATE_ATTRIBUTE_EMPTY,
  GET_ATTRIBUTES,
  GET_SURGE_EVENTS,
  GET_PROJECTS_LIST,
  UPDATE_ATTRIBUTE,
  UPDATE_ATTRIBUTE_EMPTY,
  DELETE_ATTRIBUTE,
  DELETE_ATTRIBUTE_EMPTY,
} from './../../../../../../store/application/actionType';
import AttributesTable from './Components/Tables/Table';
import CreateAttributeForm from './Components/Forms/Form';
import { Loader } from '../../../../../Common';
import { Constant } from '../../../../../Helpers/constant';

const Attributes = ({ tabId, activeTab }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentSelectedDetails, setCurrentSelectedDetails] = useState(null);

  const [isShowAttributeTable, setIsShowAttributeTable] = useState(true);
  const [isShowCreateAttributeForm, setIsShowCreateAttributeForm] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Projects List
  const { projectsList, projectsListLoading } = useSelector(state => ({
    projectsList: state?.ProjectsList?.data,
    projectsListLoading: state?.ProjectsList?.loading,
  }));

  // Events List
  const { eventsList, eventsLoading } = useSelector(state => ({
    eventsList: state?.GetEvents?.data,
    eventsLoading: state?.GetEvents?.loading,
  }));

  // Attributes List
  const { attributes, attributesLoading } = useSelector(state => ({
    attributes: state?.GetAttributes?.data,
    attributesLoading: state?.GetAttributes?.loading,
  }));

  // Create Attribute
  const { createAttribute, createAttributeLoading } = useSelector(state => ({
    createAttribute: state?.CreateAttribute?.data,
    createAttributeLoading: state?.CreateAttribute?.loading,
  }));

  // Update Attribute
  const { updateAttribute, updateAttributeLoading } = useSelector(state => ({
    updateAttribute: state?.UpdateAttribute?.data,
    updateAttributeLoading: state?.UpdateAttribute?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (activeTab !== tabId) {
      setCurrentPageNumber(0);
      setCurrentSelectedDetails(null);
      setIsShowAttributeTable(true);
      setIsShowCreateAttributeForm(false);

      dispatch({
        type: GET_ATTRIBUTES,
        payload: {
          urlParam: {
            pageIndex: 0,
          },
        },
      });
    }
  }, [activeTab]);

  useEffect(() => {
    dispatch({
      type: GET_ATTRIBUTES,
      payload: {
        urlParam: {
          pageIndex: 0,
        },
      },
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: GET_SURGE_EVENTS,
      payload: {
        urlParam: {
          pageIndex: 0,
          getAll: 'true',
        },
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (projectsList === null) {
      dispatch({
        type: GET_PROJECTS_LIST,
        payload: {
          urlParam: {
            pageIndex: 0,
            pageSize: 100,
          },
        },
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (createAttribute !== null) {
      dispatch({
        type: CREATE_ATTRIBUTE_EMPTY,
      });
      setIsShowAttributeTable(true);
      setIsShowCreateAttributeForm(false);
      setCurrentSelectedDetails(null);

      dispatch({
        type: GET_ATTRIBUTES,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          },
        },
      });
    }
  }, [createAttribute]);

  useEffect(() => {
    if (updateAttribute !== null) {
      dispatch({
        type: UPDATE_ATTRIBUTE_EMPTY,
      });
      setIsShowAttributeTable(true);
      setIsShowCreateAttributeForm(false);
      setCurrentSelectedDetails(null);

      dispatch({
        type: GET_ATTRIBUTES,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          },
        },
      });
    }
  }, [updateAttribute]);

  /* ---------------------------- CREATE CAMPAIGN FORM HANDLER ---------------------------- */
  const createNewAttributeHandler = () => {
    setIsShowAttributeTable(false);
    setIsShowCreateAttributeForm(true);
  };

  /* ---------------------------- ON SUBMIT CAMPAIGN HANDLER ---------------------------- */
  const onSubmitAttributeForm = (data, flag) => {
    if (flag === `New`) {
      dispatch({
        type: CREATE_ATTRIBUTE,
        payload: {
          data: {
            ...data,
          },
        },
      });
    } else if (flag === `Update`) {
      dispatch({
        type: UPDATE_ATTRIBUTE,
        payload: {
          data: {
            ...data,
          },
        },
      });
    } else if (flag === `DISABLE`) {
      dispatch({
        type: UPDATE_ATTRIBUTE,
        payload: {
          data: {
            ...data,
            status: false,
          },
        },
      });
    } else if (flag === `ENABLE`) {
      dispatch({
        type: UPDATE_ATTRIBUTE,
        payload: {
          data: {
            ...data,
            status: true,
          },
        },
      });
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentSelectedDetails(null);

    dispatch({
      type: GET_ATTRIBUTES,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedDetails(params?.details);

    if (params?.actionType === `UPDATE`) {
      setIsShowAttributeTable(false);
      setIsShowCreateAttributeForm(true);
    } else if (params?.actionType === `DISABLE`) {
      onSubmitAttributeForm(params?.details, `DISABLE`);
    } else if (params?.actionType === `ENABLE`) {
      onSubmitAttributeForm(params?.details, `ENABLE`);
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (projectsListLoading || createAttributeLoading || attributesLoading || updateAttributeLoading || eventsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [projectsListLoading, createAttributeLoading, attributesLoading, updateAttributeLoading, eventsLoading]);

  /* ---------------------------- BACK BUTTON HANDLER ---------------------------- */
  const backBtnHandler = () => {
    setIsShowAttributeTable(true);
    setIsShowCreateAttributeForm(false);
    setCurrentSelectedDetails(null);
  };

  return (
    <>
      {loading && <Loader />}
      {isShowCreateAttributeForm && (
        <>
          <CreateAttributeForm
            projects={projectsList}
            events={eventsList?.results}
            onSubmit={onSubmitAttributeForm}
            attributeData={currentSelectedDetails}
            onBack={backBtnHandler}
          />
        </>
      )}

      {isShowAttributeTable && (
        <>
          <AttributesTable
            attributes={attributes?.results}
            createNewAttribute={createNewAttributeHandler}
            totalData={attributes?.count}
            pageHandler={paginationHandler}
            pageNumber={currentPageNumber}
            actionHandler={tableActionHandler}
          />
        </>
      )}
    </>
  );
};

export default Attributes;
