import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from '../../../../Common';
import { Container } from 'reactstrap';

import ServiceForm from './Components/Form';
import { CREATE_SERVICE_EMPTY, GET_EMPTY_POST_COUPON } from '../../../../../store/application/actionType';
import BulkUploadModal from './Components/Modals/BulkUpload';

const AddService = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [isBulkUploadShow, setIsBulkUploadShow] = useState(false);

  const [isBenefitScreenShow, setIsBenefitScreenShow] = useState(true);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Create Service
  const { createService, createServiceLoading } = useSelector(state => ({
    createService: state?.CreateService?.data,
    createServiceLoading: state?.CreateService?.loading,
  }));

  // Categories
  const { categoriesLoading } = useSelector(state => ({
    categoriesLoading: state?.GetCouponCategory?.loading,
  }));

  // Partners
  const { partnersLoading } = useSelector(state => ({
    partnersLoading: state?.GetCouponPartner?.loading,
  }));

  // Facilities
  const { facilitiesLoading } = useSelector(state => ({
    facilitiesLoading: state?.GetCouponFacility?.loading,
  }));

  // Redemption Types
  const { redemptionTypesLoading } = useSelector(state => ({
    redemptionTypesLoading: state?.GetRedemptionType?.loading,
  }));

  // Booking Types
  const { bookingTypesLoading } = useSelector(state => ({
    bookingTypesLoading: state?.BookingTypes?.loading,
  }));

  // Bulk Upload
  const { bulkUpload, bulkUploadLoading } = useSelector(state => ({
    bulkUpload: state?.PostCoupons?.data,
    bulkUploadLoading: state?.PostCoupons?.loading,
  }));

  // Delete File
  const { deleteFile, deleteFileLoading } = useSelector(state => ({
    deleteFile: state?.DeleteFile?.data,
    deleteFileLoading: state?.DeleteFile?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (createService !== null) {
      history.push('/service/manage-service');
      dispatch({
        type: CREATE_SERVICE_EMPTY,
      });
    }
  }, [createService]);

  useEffect(() => {
    if (bulkUpload !== null) {
      dispatch({
        type: GET_EMPTY_POST_COUPON,
      });
    }
  }, [bulkUpload]);

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (
      createServiceLoading ||
      categoriesLoading ||
      partnersLoading ||
      facilitiesLoading ||
      redemptionTypesLoading ||
      bookingTypesLoading ||
      bulkUploadLoading ||
      deleteFileLoading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    createServiceLoading,
    categoriesLoading,
    partnersLoading,
    facilitiesLoading,
    redemptionTypesLoading,
    bookingTypesLoading,
    bulkUploadLoading,
    deleteFileLoading,
  ]);

  /* ---------------------------- BULK UPLOAD HANDLER ---------------------------- */
  const bulkUploadActionHandler = () => {
    setIsBulkUploadShow(true);
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push('/dashboard');
    } else if (title === `Benefit`) {
      history.push(`/service/manage-service`);
    } else if (title === `Add Benefit`) {
      setIsBenefitScreenShow(true);
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ---------------------------- MODAL'S ----------------------------  */}
          {isBulkUploadShow && <BulkUploadModal onCloseHandler={() => setIsBulkUploadShow(false)} />}

          {/*  ---------------------------- PAGES ----------------------------  */}
          <>
            <BreadCrumb title="Benefit" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Benefit', 'Add Benefit']} />
            <ServiceForm
              bulkUploadAction={bulkUploadActionHandler}
              title="Add Benefit"
              isBenefitScreenShow={isBenefitScreenShow}
              setIsBenefitScreenShow={setIsBenefitScreenShow}
            />
          </>
        </Container>
      </div>
    </>
  );
};

export default AddService;
