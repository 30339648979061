import { useEffect, useState } from 'react';

import axios from 'axios';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Button, Modal, ModalBody, Col, Input, Row, ModalFooter, ModalHeader } from 'reactstrap';

import { Constant } from '../../../../../../Helpers/constant';
import { customHeaders, indianProjectsBaseURL } from '../../../../../../Config';

const CuisinesModal = ({ onCloseHandler, state, setState }) => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [selectedCuisines, setSelectedCuisines] = useState([]);
  const [tempCuisine, setTempCuisine] = useState(null);

  useEffect(() => {
    setSelectedCuisines(state?.cuisines);
  }, [state]);

  /* ---------------------------- LOAD CLIENT ---------------------------- */
  async function loadCuisines(search) {
    return await axios({
      method: `GET`,
      url: `${indianProjectsBaseURL}/public/client/cuisines`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        name: search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              label: `${data?.name}`,
              value: data?.objectId,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  const handleAddCuisine = () => {
    if (tempCuisine !== null) {
      setSelectedCuisines([...(selectedCuisines ?? []), tempCuisine]);
      setTempCuisine(null);
    }
  };

  const handleRemoveCuisine = index => {
    const tempSelectedCuisines = [...selectedCuisines];
    tempSelectedCuisines?.splice(index, 1);
    setSelectedCuisines([...tempSelectedCuisines]);
  };

  const onSubmitCuisines = () => {
    setState({ ...state, cuisines: selectedCuisines });
    onCloseHandler();
  };

  return (
    <Modal isOpen={true} id="cuisines" size="md">
      <ModalHeader
        className="bg-soft-success p-3"
        toggle={() => {
          onCloseHandler();
        }}
      >
        Add Cuisines
      </ModalHeader>
      <ModalBody>
        {/* <button onClick={() => console.log(selectedCuisines)}>get state</button> */}
        <Row>
          <Col xxl={12} className="mb-3">
            <Row>
              <Col md={10}>
                <div className="">
                  <AsyncPaginate
                    debounceTimeout={500}
                    value={tempCuisine}
                    loadOptions={loadCuisines}
                    onChange={selectedOption => setTempCuisine(selectedOption)}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    noOptionsMessage={() => 'No results found'}
                    cacheUniqs={[['code']]}
                    placeholder="Select..."
                    isClearable={true}
                  />
                </div>
              </Col>
              <Col md={2}>
                <Button color="success" onClick={handleAddCuisine}>
                  <FaPlus />
                </Button>
              </Col>
            </Row>
          </Col>

          {selectedCuisines?.map((sCuisine, index) => (
            <Col md={12} key={index} className="mb-3">
              <Row>
                <Col md={10}>
                  <Input type="text" value={sCuisine?.label} className="me-2" disabled />
                </Col>
                <Col md={2}>
                  <Button color="danger" onClick={() => handleRemoveCuisine(index)}>
                    <FaMinus />
                  </Button>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </ModalBody>

      <ModalFooter className="d-flex justify-content-center">
        <button className="btn btn-success" onClick={onSubmitCuisines}>
          Submit
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default CuisinesModal;
