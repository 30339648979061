import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../Containers/Config/index.js';
import { CREATE_BENEFIT_GROUP_ITEM, CREATE_BENEFIT_GROUP_ITEM_SUCCESS, CREATE_BENEFIT_GROUP_ITEM_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* createBenefitGroupItem({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/benefit-group-items`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      data: payload.data,
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({
        type: CREATE_BENEFIT_GROUP_ITEM_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: CREATE_BENEFIT_GROUP_ITEM_ERROR,
        payload: response?.data,
      });
    }
  } catch (error) {
    yield put({ type: CREATE_BENEFIT_GROUP_ITEM_ERROR, payload: error });
  }
}

export function* watchBenefitGroupItem() {
  yield takeEvery(CREATE_BENEFIT_GROUP_ITEM, createBenefitGroupItem);
}

function* creatBenefitGroupItemSaga() {
  yield all([fork(watchBenefitGroupItem)]);
}

export default creatBenefitGroupItemSaga;
