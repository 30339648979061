import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from '../../Common';
import { Container } from 'reactstrap';

import AssignRightsForm from './Components/Forms/AssignRightsForm';
import UsersList from './Components/Users';
import CreateVendor from './Components/Forms/CreateVendor';
import CreateInternalUser from './Components/Forms/CreateInternalUser';
import CreateClient from './Components/Forms/CreateClient';
import CreateClientAgent from './Components/Forms/CreateClientAgent';

const UserManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [isShowEditPermissionScreen, setIsShowEditPermissionScreen] = useState(false);
  const [selectedAdminUser, setSelectedAdminUser] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Permissions
  const { permissionsLoading } = useSelector(state => ({
    permissionsLoading: state?.Permissions?.loading,
  }));

  // Update  Permissions
  const { updatePermissions, updatePermissionsLoading } = useSelector(state => ({
    updatePermissions: state?.UpdatePermissions?.data,
    updatePermissionsLoading: state?.UpdatePermissions?.loading,
  }));

  useEffect(() => {
    if (updatePermissions !== null) {
      setIsShowEditPermissionScreen(false);
    }
  }, [updatePermissions]);

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (permissionsLoading || updatePermissionsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [permissionsLoading, updatePermissionsLoading]);

  /* ---------------------------- SELECTED ADMIN USER FOR CHANGE PERMISSION HANDLER ---------------------------- */
  const onSelectEditPermission = selAdminUser => {
    setSelectedAdminUser(selAdminUser);
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Users`) {
      setIsShowEditPermissionScreen(false);
    } else if (title === `Permissions`) {
      // do nothing
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {/* {
            <>
              <BreadCrumb title="Create Client" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Users`]} />
              <CreateClient />
            </>
          } */}

          {/* {
            <>
              <BreadCrumb title="Create Client Agent" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Users`]} />
              <CreateClientAgent />
            </>
          } */}

          {/* {
            <>
              <BreadCrumb title="Internal User" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Users`]} />
              <CreateInternalUser />
            </>
          } */}

          {/* {
            <>
              <BreadCrumb title="Create Internal User" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Users`]} />
              <CreateInternalUser />
            </>
          } */}

          {/* {
            <>
              <BreadCrumb title="Create Vendor" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Users`]} />
              <CreateVendor />
            </>
          } */}

          {/* --------------------------------------------------- */}
          {!isShowEditPermissionScreen && (
            <>
              <BreadCrumb title="Dump Download" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Users`]} />
              <UsersList onSelectUser={onSelectEditPermission} setIsShowEditPermissionScreen={setIsShowEditPermissionScreen} />
            </>
          )}

          {isShowEditPermissionScreen && (
            <>
              <BreadCrumb title="Dump Download" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Users`, `Permissions`]} />
              <AssignRightsForm adminUser={selectedAdminUser} />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default UserManagement;
