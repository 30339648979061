import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { CREATE_INVENTORY_QUOTA } from './../../../../../../../../store/application/actionType';

const newCondition = {
  name: '',
  bookingsAllowed: 0,
  unit: 1,
  claimOneBenefit: true,
  value: 0,
};

const CreateOrConditionModal = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [conditions, setConditions] = useState([{ ...newCondition }]);
  const [currentConditionIndex, setCurrentConditionIndex] = useState(2);

  useEffect(() => {
    if (props?.inventoryData?.results?.length > 0) {
      setConditions([...props.inventoryData.results]);
    }
  }, [props?.inventoryData]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = (label, index, data, value) => {
    let tempConditions = [...conditions];

    if (label === `UNIT`) {
      const tempData = {
        ...data,
        unit: value,
      };
      tempConditions.splice(index, 1, tempData);
    } else if (label === `CONDITION`) {
      const tempData = {
        ...data,
        name: value,
      };
      tempConditions.splice(index, 1, tempData);
    } else if (label === `claimOneBenefit`) {
      const tempData = {
        ...data,
        claimOneBenefit: value,
      };
      tempConditions.splice(index, 1, tempData);
    } else if (label === `ADD`) {
      const tempData = {
        ...data,
        bookingsAllowed: +data?.bookingsAllowed + 1,
      };
      tempConditions.splice(index, 1, tempData);
    } else if (label === `MINUS` && data?.bookingsAllowed > 0) {
      const tempData = {
        ...data,
        bookingsAllowed: +data?.bookingsAllowed - 1,
      };
      tempConditions.splice(index, 1, tempData);
    } else if (label === `DURATION-ADD`) {
      const tempData = {
        ...data,
        value: +data?.value + 1,
      };
      tempConditions.splice(index, 1, tempData);
    } else if (label === `DURATION-MINUS` && data?.value > 0) {
      const tempData = {
        ...data,
        value: +data?.value - 1,
      };
      tempConditions.splice(index, 1, tempData);
    }
    setConditions(tempConditions);
  };

  /* ---------------------------- CREATE NEW CONDITION HANDLER ---------------------------- */
  const onClickAddCondition = () => {
    setCurrentConditionIndex(currentConditionIndex + 1);
    setConditions([...conditions, { ...newCondition }]);
  };

  /* ---------------------------- CREATE NEW CONDITION HANDLER ---------------------------- */
  const onDeleteCondition = index => {
    let tempConditions = [...conditions];
    tempConditions?.splice(index, 1);
    setConditions([...tempConditions]);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const body = conditions?.map(condition => ({
      ...condition,
      bookingsAllowed: condition?.bookingsAllowed,
      unit: parseInt(condition?.unit),
    }));

    dispatch({
      type: CREATE_INVENTORY_QUOTA,
      payload: {
        data: {
          requests: body,
        },
      },
    });
    props.onCloseHandler();
  };

  return (
    <Modal
      isOpen={true}
      toggle={() => {
        props.onCloseHandler();
      }}
      size="lg"
    >
      <div className="modal-header p-3 bg-soft-warning">
        <h5 className="modal-title" id="createboardModalLabel">
          Create Conditions
        </h5>
        <Button
          type="button"
          onClick={() => props.onCloseHandler()}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      {/* <button onClick={() => console.log(conditions)}>Get State</button> */}

      <ModalBody>
        <Row>
          <Col md={12}>
            <div className="card-body">
              <div className="d-flex justify-content-center">
                <button className="btn btn-primary text-center" onClick={onClickAddCondition}>
                  + &nbsp;Add Condition
                </button>
              </div>
            </div>
          </Col>

          <Col>
            {conditions?.map((condition, index) => (
              <Row key={index} className="mb-3">
                <Col md={2}>
                  <Label htmlFor="benefitGroup" className="form-label">
                    Condition
                  </Label>
                  <Input
                    id="condition"
                    name="condition"
                    type="text"
                    placeholder="Condition"
                    className="form-control"
                    value={condition?.name}
                    onChange={e => {
                      inputChangeHandler(`CONDITION`, index, condition, e.target.value);
                    }}
                  />
                </Col>

                <Col md={2}>
                  <div className="d-flex flex-column align-items-center ">
                    <Label htmlFor="benefitGroup" className="form-label">
                      Total Quota
                    </Label>
                    <div className="input-step step-primary">
                      <button
                        type="button"
                        className="minus"
                        onClick={() => {
                          inputChangeHandler(`MINUS`, index, condition);
                        }}
                      >
                        –
                      </button>
                      <input
                        type="number"
                        onWheel={e => e.target.blur()}
                        className="product-quantity"
                        value={condition?.bookingsAllowed}
                        min="0"
                        max="100"
                      />
                      <button
                        type="button"
                        className="plus"
                        onClick={() => {
                          inputChangeHandler(`ADD`, index, condition);
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </Col>

                <Col md={2}>
                  <div className="d-flex flex-column align-items-center ">
                    <Label htmlFor="benefitGroup" className="form-label">
                      Duration
                    </Label>
                    <div className="input-step step-primary">
                      <button
                        type="button"
                        className="minus"
                        onClick={() => {
                          inputChangeHandler(`DURATION-MINUS`, index, condition);
                        }}
                      >
                        –
                      </button>
                      <input type="number" onWheel={e => e.target.blur()} className="product-quantity" value={condition?.value} min="0" max="100" />
                      <button
                        type="button"
                        className="plus"
                        onClick={() => {
                          inputChangeHandler(`DURATION-ADD`, index, condition);
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </Col>

                <Col md={2}>
                  <Label htmlFor="benefitGroup" className="form-label">
                    Unit
                  </Label>
                  <select
                    className="form-control"
                    name=""
                    id=""
                    value={condition?.unit}
                    onChange={e => inputChangeHandler(`UNIT`, index, condition, e?.target?.value)}
                  >
                    <option value={3}>Day</option>
                    <option value={2}>Month</option>
                    <option value={1}>Year</option>
                  </select>
                </Col>

                <Col md={3}>
                  <div className="form-switch d-inline-block ">
                    <Label htmlFor="benefitGroup" className="form-label">
                      Claim Same Benefit
                    </Label>
                    <Input
                      className="form-check-input code-switcher form-switch-md cursor-pointer m-auto d-block"
                      type="checkbox"
                      id="claimOneBenefit"
                      name="claimOneBenefit"
                      checked={condition?.claimOneBenefit}
                      onChange={e => inputChangeHandler(`claimOneBenefit`, index, condition, e?.target?.checked)}
                    />
                  </div>
                </Col>

                <Col md={1} className="d-flex align-items-end gap-2 justify-content-end">
                  <button type="submit" className="btn btn-danger" onClick={() => onDeleteCondition(index)} disabled={conditions?.length === 1}>
                    <i className="ri-delete-bin-5-line"></i>
                  </button>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter className="modal-footer bg-light pt-3 justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler}>
          Create
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateOrConditionModal;
