import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import apiCall from '../../apiCall';
import { baseUrl, customHeaders, uaeProjectsBaseURL } from './../../../../Containers/Config/index';

import { UPDATE_CUSTOMER_DATA, UPDATE_CUSTOMER_DATA_SUCCESS, UPDATE_CUSTOMER_DATA_ERROR } from './../../actionType';
import { Constant } from '../../../../Containers/Helpers/constant';
import cogoToast from 'cogo-toast';

function* updateCustomer({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/user/v1/update-user`,
      method: 'PATCH',
      headers: { Authorization: Constant?.CLIENT_TOKEN, ...customHeaders, 'X-Requested-By': 'one-admin' },
      data: payload?.data,
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({ type: UPDATE_CUSTOMER_DATA_SUCCESS, payload: response });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({ type: UPDATE_CUSTOMER_DATA_ERROR, payload: response.error });
    }
  } catch (error) {
    yield put({ type: UPDATE_CUSTOMER_DATA_ERROR, payload: error });
  }
}

export function* watchUpdateCustomer() {
  yield takeEvery(UPDATE_CUSTOMER_DATA, updateCustomer);
}

function* updateCustomerSaga() {
  yield all([fork(watchUpdateCustomer)]);
}

export default updateCustomerSaga;
