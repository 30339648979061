import React from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row } from 'reactstrap';
import { Error, PreviewCardHeader } from '../../../../../../Common';

const CarType = ({ carType, dropType, state, setState, errors, setErrors }) => {
  const handleArrivalChange = e => {
    setState({
      ...state,
      [carType]: {
        ...state[carType],
        arrival: e.target.value,
      },
    });
    setErrors({
      ...errors,
      [carType]: {
        ...errors[carType],
        arrival: '',
      },
    });
  };

  const handleDepartureChange = e => {
    setState({
      ...state,
      [carType]: {
        ...state[carType],
        departure: e.target.value,
      },
    });
    setErrors({
      ...errors,
      [carType]: {
        ...errors[carType],
        departure: '',
      },
    });
  };

  const handleParkingChange = e => {
    setState({
      ...state,
      [carType]: {
        ...state[carType],
        parking: e.target.value,
      },
    });
    setErrors({
      ...errors,
      [carType]: {
        ...errors[carType],
        parking: '',
      },
    });
  };

  return (
    <>
      <Container fluid className="position-relative">
        <Row>
          <Col xxl={12}>
            <Card>
              <CardBody>
                <h4>{carType}</h4>
                <div className="live-preview">
                  <Row>
                    {dropType.includes('pickup') && (
                      <>
                        <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                          <div className="mb-3">
                            <Label htmlFor="arrival" className="form-label">
                              Arrival
                              <span className="lbl_mandatory"> *</span>
                            </Label>
                            <Input
                              id="arrival"
                              name="arrival"
                              type="number"
                              onWheel={(e: any) => e.target.blur()}
                              className="form-control"
                              onChange={handleArrivalChange}
                              value={state[carType].arrival}
                              autoComplete="off"
                            />
                            {errors?.[carType]?.arrival && <span style={{ color: 'red' }}>{errors[carType].arrival}</span>}
                          </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                          <div className="mb-3">
                            <Label htmlFor="parking" className="form-label">
                              Parking
                              <span className="lbl_mandatory"> *</span>
                            </Label>
                            <Input
                              id="parking"
                              name="parking"
                              type="number"
                              onWheel={(e: any) => e.target.blur()}
                              className="form-control"
                              onChange={handleParkingChange}
                              value={state[carType].parking}
                              autoComplete="off"
                            />
                            {errors?.[carType]?.parking && <span style={{ color: 'red' }}>{errors[carType].parking}</span>}
                          </div>
                        </Col>
                      </>
                    )}
                    {dropType.includes('drop') && (
                      <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                        <div className="mb-3">
                          <Label htmlFor="departure" className="form-label">
                            Departure
                            <span className="lbl_mandatory"> *</span>
                          </Label>
                          <Input
                            id="departure"
                            name="departure"
                            type="number"
                            onWheel={(e: any) => e.target.blur()}
                            className="form-control"
                            onChange={handleDepartureChange}
                            value={state[carType].departure}
                            autoComplete="off"
                          />
                          {errors?.[carType]?.departure && <span style={{ color: 'red' }}>{errors[carType].departure}</span>}
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CarType;
