import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, ModalBody } from 'reactstrap';

import { UPDATE_BUSINESS, UPDATE_BUSINESS_EMPTY } from '../../../../../../../../store/actions';

const UpdateBusiness = ({ data, onCloseHandler, updateType }) => {
  const dispatch = useDispatch();

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { updateBusiness } = useSelector((state: any) => ({
    updateBusiness: state?.UpdateBusiness?.data,
  }));

  useEffect(() => {
    if (updateBusiness !== null) {
      onCloseHandler();

      return () => {
        dispatch({
          type: UPDATE_BUSINESS_EMPTY,
        });
      };
    }
  }, [updateBusiness, dispatch, onCloseHandler]);

  const statusMapping = {
    Accept: 2,
    Reject: 3,
    Block: 4,
    Unblock: 2,
  };

  const handleYesClick = () => {
    const status = statusMapping[updateType];

    dispatch({
      type: UPDATE_BUSINESS,
      payload: {
        data: {
          businessId: data?.id,
          status: status,
        },
      },
    });
  };

  return (
    <Modal isOpen={true} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <div className="mt-4 pt-2 fs-15 mx-2 mx-sm-2">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-1 mb-0">Are you sure you want to {updateType} this company?</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button type="button" className="btn w-sm btn-light" data-bs-dismiss="modal" onClick={onCloseHandler}>
            Close
          </button>
          <button type="button" className="btn w-sm btn-primary" onClick={handleYesClick}>
            Sure
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UpdateBusiness;
