import React from 'react';

import { Card, CardBody } from 'reactstrap';

const DefaultScreen = ({ data, onClick }) => {
  return (
    <div>
      <Card>
        <CardBody className="p-4 text-center">
          <div className="mx-auto avatar-md mb-3">
            <div>
              <div className="avatar-sm flex-shrink-0">
                <span className="avatar-title bg-soft-dark rounded-3 fs-2">
                  <i className={`${data?.icon} text-primary`}></i>
                </span>
              </div>
            </div>
          </div>
          <h5 className="card-title mb-1">{data?.name}</h5>
          <p className="text-muted mb-0">{data?.description}</p>

          <button type="submit" className="btn btn-success mt-3 px-4" onClick={onClick}>
            {data?.btnTitle} <i className="ri-arrow-right-line me-1 text-white fs-16 align-middle"></i>
          </button>
        </CardBody>
      </Card>
    </div>
  );
};

export default DefaultScreen;
