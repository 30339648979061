import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Constant } from '../../../../../../../Helpers/constant';
import { AsyncPaginate } from 'react-select-async-paginate';
import { baseUrl, customHeaders, uaeProjectsBaseURL } from '../../../../../../../Config';

const payload = {
  name: '',
  benefitGroup: {
    id: '',
    name: '',
  },
  allocationType: 0,
  allocationTypeId: '',
  shortDescription: '',
  longDescription: '',
  benefitOffered: '',
  redemptionProcess: '',
  termsOfUse: '',
  images: {
    logo: '',
    coverImageMobile: '',
    coverImageWeb: '',
    displayImageMobile: '',
    displayImageWeb: '',
    bannersMobile: null,
    bannersWeb: null,
  },
  totalCount: 0,
  bookingType: {
    name: '',
    typeId: 0,
    redemptionType: {
      id: '',
      name: '',
    },
  },
  bookingTat: {
    maximum: 0,
    maximumUnit: 0,
    minimum: 0,
    minimumUnit: 0,
  },
  tat: {
    delayedMinTat: 0,
    delayedMinTatUnit: 0,
    delayedMaxTat: 0,
    delayedMaxTatUnit: 0,
    delayedCancelTat: 0,
    delayedCancelTatUnit: 0,
    confirmationMinTat: 0,
    confirmationMinTatUnit: 0,
    confirmationMaxTat: 0,
    confirmationMaxTatUnit: 0,
  },
  category: {
    id: '',
    name: '',
    images: {
      logo: '',
      coverImageMobile: '',
      coverImageWeb: '',
      displayImageMobile: '',
      displayImageWeb: '',
    },
  },

  quota: {
    discountPecentage: 0,
    delayedMinValue: 0, // duration
    delayedMinTat: 0, // quota
    delayedMinTatUnit: 3, // unit
    delayedMaxValue: 0,
    delayedMaxTat: 0,
    delayedMaxTatUnit: 0,
    delayedQuotaType: 0,
  },
  discountquota: {
    discountPecentage: 0,
    delayedMinTat: 0,
    delayedMinTatUnit: 0,
    delayedMaxTat: 0,
    delayedMaxTatUnit: 0,
    amount: 0,
    type: '',
    tax: false,
  },
  coin: {
    minValue: 0,
    maxValue: 0,
  },
  carTypes: [
    {
      carType: '',
      passengers: 0,
      babySeats: 0,
      luggages: 0,
      minMax: '',
      image: '',
      imageUrl: '',
      type: '',
    },
  ],
  discountPercentage: 0,
  services: [],
  voucherDetails: {
    amount: 0,
    currency: '',
    expiry: '',
  },
  workflowId: 'DEFAULT',
  thirdPartyWebsite: '',
};

const allocationTypesIds = ['SUB_CATEGORY', 'PARTNER', 'FACILITY', 'SERVICE'];

const CreateBenefitGroupItemsModal = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    benefitGroupItemType: '',
    complementaryBenefit: false,
    discountedBenefit: false,
    benefitGroupItem: null,
  };
  const [state, setState] = useState(initialObj);

  const [modal_varying1, setmodal_varying1] = useState(true);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'complementaryBenefit':
        updatedState = {
          ...state,
          discountedBenefit: false,
          complementaryBenefit: !state?.complementaryBenefit,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              type: 0,
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'discountedBenefit':
        updatedState = {
          ...state,
          complementaryBenefit: false,
          discountedBenefit: !state?.discountedBenefit,
          benefitGroupItem: {
            ...state?.benefitGroupItem,
            completeData: {
              ...state?.benefitGroupItem?.completeData,
              type: 1,
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  const checkBoxChangeHandler = label => {
    let updatedState = null;
    if (label === 'Benefit') {
      updatedState = {
        ...state,
        benefitGroupItem: null,
        benefitGroupItemType: label,
      };
    } else if (label === 'Brand') {
      updatedState = {
        ...state,
        benefitGroupItem: null,
        benefitGroupItemType: label,
      };
    } else if (label === 'Sub-category') {
      updatedState = {
        ...state,
        benefitGroupItem: null,
        benefitGroupItemType: label,
      };
    }
    setState(updatedState);
    validationCheck(updatedState);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    let data = null;
    let tempBenefitOffered = null;
    let tempRedemptionProcess = null;
    let tempTermsOfUse = null;

    let tempAllocationType = 0;
    if (state?.benefitGroupItemType === `Benefit`) {
      tempAllocationType = 4;
    } else if (state?.benefitGroupItemType === `Brand`) {
      tempAllocationType = 2;
      tempBenefitOffered = state?.benefitGroupItem?.completeData?.defaultBenefitOffered;
      tempRedemptionProcess = state?.benefitGroupItem?.completeData?.defaultRedemptionProcess;
      tempTermsOfUse = state?.benefitGroupItem?.completeData?.defaultTermsAndCondition;
    } else if (state?.benefitGroupItemType === `Sub-category`) {
      tempAllocationType = 1;
      tempBenefitOffered = state?.benefitGroupItem?.completeData?.benefitOffered;
      tempRedemptionProcess = state?.benefitGroupItem?.completeData?.redemptionProcess;
      tempTermsOfUse = state?.benefitGroupItem?.completeData?.termsAndCondition;
    }

    if (state?.benefitGroupItemType === `Benefit`) {
      data = {
        ...state,
        benefitGroupItem: {
          ...state?.benefitGroupItem,
          completeData: {
            ...payload,
            ...state?.benefitGroupItem?.completeData,
            services: [state?.benefitGroupItem?.completeData],
            allocationType: tempAllocationType,
            allocationTypeId: state?.benefitGroupItem?.completeData?.objectId,

            benefitOffered: tempBenefitOffered || '',
            redemptionProcess: tempRedemptionProcess || '',
            termsOfUse: tempTermsOfUse || '',
          },
        },
      };
    } else {
      data = {
        ...state,
        benefitGroupItem: {
          ...state?.benefitGroupItem,
          completeData: {
            ...payload,
            ...state?.benefitGroupItem?.completeData,
            allocationType: tempAllocationType,
            allocationTypeId: state?.benefitGroupItem?.completeData?.objectId,

            benefitOffered: tempBenefitOffered,
            redemptionProcess: tempRedemptionProcess,
            termsOfUse: tempTermsOfUse,
          },
        },
      };
    }

    props.onSubmit(data);

    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data?.benefitGroupItemType !== '' && data?.benefitGroupItem !== null) {
      if (data?.complementaryBenefit === false && data?.discountedBenefit === true) {
        setIsSubmitBtnDisabled(false);
      } else if (data?.complementaryBenefit === true && data?.discountedBenefit === false) {
        setIsSubmitBtnDisabled(false);
      } else {
        setIsSubmitBtnDisabled(true);
      }
    } else {
      setIsSubmitBtnDisabled(true);
    }
  };

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  /* ---------------------------- TYPED USER HANDLER ---------------------------- */
  async function loadOptions(search, loadedOptions) {
    let URL = ``;
    if (state?.benefitGroupItemType === `Benefit`) {
      URL += `${uaeProjectsBaseURL}/admin/services?name=${search}`;
    } else if (state?.benefitGroupItemType === `Brand`) {
      URL += `${uaeProjectsBaseURL}/admin/partners?name=${search}`;
    } else if (state?.benefitGroupItemType === `Sub-category`) {
      URL += `${uaeProjectsBaseURL}/admin/categories?name=${search}`;
    } else {
      return;
    }

    const response = await fetch(`${URL}`, {
      method: `GET`,
      headers: new Headers({
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      }),
    });
    const responseData = await response.json();

    const requiredData = responseData?.results
      // ?.filter(response => {
      //   let currentStatus = true;
      //   props?.allConfigs?.forEach(config => {
      //     if (config?.allocationTypeId === response?.objectId && config?.isActive === true) {
      //       currentStatus = false;
      //     }
      //   });

      //   if (currentStatus) {
      //     return {
      //       value: response.objectId,
      //       label: `${response?.name}`,
      //       completeData: response,
      //     };
      //   } else {
      //     return false;
      //   }
      // })
      ?.map(service => ({
        value: service.objectId,
        label: `${service?.name}`,
        completeData: service,
      }));

    return {
      options: requiredData,
      hasMore: false,
    };
  }

  /* ---------------------------- ON SELECT USER ---------------------------- */
  const onSelectBenefitGroup = event => {
    let updatedState = null;

    updatedState = {
      ...state,
      benefitGroupItem: event,
    };
    validationCheck(updatedState);
    setState(updatedState);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
      centered
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Create Benefit Group Items
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      {/* <button onClick={() => console.log('state', state)}>get state</button> */}

      <ModalBody>
        <Row>
          <Col md={12}>
            <div className="mb-3">
              <Label htmlFor="" className="form-label">
                Benefit Group Item Type
              </Label>
              <div className="d-flex justify-content-between">
                <div className="">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id="Benefit"
                    name="Benefit"
                    checked={state?.benefitGroupItemType === `Benefit`}
                    onChange={() => checkBoxChangeHandler('Benefit')}
                  />
                  &nbsp;
                  <Label className="form-check-label" for="Benefit">
                    Benefit
                  </Label>
                </div>

                <div className="">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id="Brand"
                    name="Brand"
                    checked={state?.benefitGroupItemType === `Brand`}
                    onChange={() => checkBoxChangeHandler('Brand')}
                  />
                  &nbsp;
                  <Label className="form-check-label" for="Brand">
                    Brand
                  </Label>
                </div>

                <div className="">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id="Sub-category"
                    name="Sub-category"
                    checked={state?.benefitGroupItemType === `Sub-category`}
                    onChange={() => checkBoxChangeHandler('Sub-category')}
                  />
                  &nbsp;
                  <Label className="form-check-label" for="Sub-category">
                    Sub Category
                  </Label>
                </div>
              </div>
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <Label htmlFor="" className="form-label">
                Benefit Group Items
              </Label>

              <AsyncPaginate
                debounceTimeout={500}
                value={state?.userId}
                loadOptions={loadOptions}
                onChange={e => onSelectBenefitGroup(e)}
                isMulti={false}
                closeMenuOnSelect={true}
                noOptionsMessage={() => 'No results found'}
                // cacheUniqs={[['code']]}
                placeholder=""
                isDisabled={state?.benefitGroupItemType === ``}
              />

              {/* <select
                className="form-select"
                name="cancellation remarks"
                value={state.remarks}
                onChange={inputChangeHandler}
              >
                <option value="">Zomato</option>
              </select> */}
            </div>
          </Col>

          <Col md={6}>
            <div className="mb-3">
              <Label htmlFor="billinginfo-firstName" className="form-label">
                Complimentary Benefits
              </Label>
              <div className="d-flex align-items-center">
                <span className="text-muted">No &nbsp;</span>
                <div className="form-switch d-inline-block ">
                  <Input
                    className="form-check-input code-switcher form-switch-md cursor-pointer"
                    type="checkbox"
                    id="complementaryBenefit"
                    name="complementaryBenefit"
                    checked={state?.complementaryBenefit}
                    onChange={inputChangeHandler}
                  />
                </div>
                <span> &nbsp;Yes</span>
              </div>
            </div>
          </Col>

          <Col md={6}>
            <div className="mb-3">
              <Label htmlFor="billinginfo-firstName" className="form-label">
                Discounted Benefits
              </Label>
              <div className="d-flex align-items-center">
                <span className="text-muted">No &nbsp;</span>
                <div className="form-switch d-inline-block ">
                  <Input
                    className="form-check-input code-switcher form-switch-md cursor-pointer"
                    type="checkbox"
                    id="discountedBenefit"
                    name="discountedBenefit"
                    checked={state?.discountedBenefit}
                    onChange={inputChangeHandler}
                  />
                </div>
                <span> &nbsp;Yes</span>
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter className="d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateBenefitGroupItemsModal;
