import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap';

import BulkUpload from '../BulkUpload';
import TrackerTable from './Components/TrackerTable';
import TrackerFilter from './Components/TrackerFilter';
import { Constant } from '../../../../../Helpers/constant';
import { hasPermission } from '../../../../../Helpers/Helpers';
import { BreadCrumb, Loader, TablePagination } from '../../../../../Common';
import { PermissionConstants } from '../../../../../Helpers/Constants/permissions';
import { GET_FILE_TYPES, GET_TRACKER_DATA, UPLOAD_CONFIG_EXCEL_EMPTY } from '../../../../../../store/actions';

const Tracker = ({ onView, currentPageNumber, setCurrentPageNumber, filters, setFilters }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [isBulkModalShow, setIsBulkModalShow] = useState(false);
  const [trackerData, setTrackerData] = useState([]);

  const [isContinousJourney, setIsContinousJourney] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  //  TRACKER DATA
  const { trackerList, trackerListLoading } = useSelector(state => ({
    trackerList: state?.GetTrackerData?.data,
    trackerListLoading: state?.GetTrackerData?.loading,
  }));

  //  UPLOAD CONFIG FILE
  const { uploadConfigFileData } = useSelector(state => ({
    uploadConfigFileData: state?.UploadConfigExcel?.data,
  }));

  //  FILE TYPES
  const { fileTypes } = useSelector(state => ({
    fileTypes: state?.GetFileTypes?.data,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_TRACKER_DATA,
      payload: {
        urlParam: {
          skip: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          limit: Constant?.TABLE_PAGE_SIZE,
          orderBy: `_created_at`,
          fileType: `SUPPLY-DATA`,
        },
      },
    });

    if (fileTypes === null) {
      dispatch({
        type: GET_FILE_TYPES,
      });
    }
  }, []);

  useEffect(() => {
    if (trackerList !== null) {
      if (isContinousJourney) {
        setIsBulkModalShow(false);
        setIsContinousJourney(false);
        onView(trackerList?.results?.[0]);
      } else {
        setTrackerData(trackerList);
      }
    }
  }, [trackerList]);

  useEffect(() => {
    if (uploadConfigFileData !== null) {
      let urlParams = {
        skip: currentPageNumber * Constant.TABLE_PAGE_SIZE,
        limit: Constant?.TABLE_PAGE_SIZE,
        fileTrackerId: uploadConfigFileData?.fileTrackerId,
        orderBy: `_created_at`,
        fileType: `SUPPLY-DATA`,
      };

      dispatch({
        type: GET_TRACKER_DATA,
        payload: {
          urlParam: {
            ...urlParams,
          },
        },
      });

      setIsContinousJourney(true);
      dispatch({
        type: UPLOAD_CONFIG_EXCEL_EMPTY,
      });
    }
  }, [uploadConfigFileData]);

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);

    let urlParams = {};
    dispatch({
      type: GET_TRACKER_DATA,
      payload: {
        urlParam: {
          ...urlParams,
          skip: pageNumber * Constant.TABLE_PAGE_SIZE,
          limit: Constant?.TABLE_PAGE_SIZE,
          orderBy: `_created_at`,
          fileType: `SUPPLY-DATA`,
        },
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    if (params?.actionType === `VIEW`) {
      onView(params?.details?.originalData);
    }
  };

  /* ---------------------------- LOADER HANDLER ---------------------------- */
  useEffect(() => {
    if (trackerListLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [trackerListLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push('/dashboard');
    }
  };

  return (
    <>
      {loading && <Loader />}
      {isBulkModalShow && <BulkUpload onClose={() => setIsBulkModalShow(false)} />}

      <Container fluid className="position-relative">
        <BreadCrumb title="Master upload" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Master upload']} />
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Master Uploads</h4>

                {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.UPLOAD_FILE_SUPPLY_DATA_GM_REPORT) && (
                  <button className="btn btn-success mx-2" onClick={() => setIsBulkModalShow(true)}>
                    <i className="ri-add-line align-middle fs-18 text-white me-2"></i>
                    New Bulk Upload
                  </button>
                )}
              </CardHeader>

              <CardBody>
                <TrackerFilter
                  filters={filters}
                  setFilters={setFilters}
                  currentPageNumber={currentPageNumber}
                  setCurrentPageNumber={setCurrentPageNumber}
                />
                <TrackerTable
                  data={trackerData?.results}
                  totalData={trackerData?.count}
                  pageHandler={paginationHandler}
                  pageNumber={currentPageNumber}
                  actionHandler={tableActionHandler}
                />
              </CardBody>

              {trackerData?.count > 0 && (
                <CardFooter>
                  <Row className="custom_pagination">
                    <Col md={12} className="d-flex justify-content-between align-items-center">
                      <div>
                        Showing <b>{currentPageNumber * Constant?.TABLE_PAGE_SIZE + 1}</b> to&nbsp;
                        <b>{currentPageNumber * Constant?.TABLE_PAGE_SIZE + trackerData?.results?.length}</b> of <b>{trackerData?.count}</b> records
                      </div>
                      <div className="d-flex justify-content-end">
                        <TablePagination totalData={trackerData?.count} currentPage={currentPageNumber} onPageChangeHandler={paginationHandler} />
                      </div>
                    </Col>
                  </Row>
                </CardFooter>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Tracker;
