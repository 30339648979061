import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import apiCall from '../../../apiCall';
import { baseUrl, customHeaders } from './../../../../../Containers/Config/index';

import { DELETE_ATTRIBUTE, DELETE_ATTRIBUTE_SUCCESS, DELETE_ATTRIBUTE_ERROR } from './../../../actionType';
import { Constant } from '../../../../../Containers/Helpers/constant';
import cogoToast from 'cogo-toast';

function* deleteAttribute({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/attributes`,
      method: 'DELETE',
      headers: { Authorization: Constant?.CLIENT_TOKEN, ...customHeaders },
      data: payload.data,
    });

    if (response.status === 200) {
      cogoToast.success(`Attribute disabled successfully!`);
      // cogoToast.success(response?.data?.message)
      yield put({ type: DELETE_ATTRIBUTE_SUCCESS, payload: response });
    } else {
      cogoToast.error(response?.response?.data?.message);
      // yield put({ type: DELETE_ATTRIBUTE_ERROR, payload: response.error })
    }
  } catch (error) {
    yield put({ type: DELETE_ATTRIBUTE_ERROR, payload: error });
  }
}

export function* watchDeleteAttribute() {
  yield takeEvery(DELETE_ATTRIBUTE, deleteAttribute);
}

function* deleteAttributeSaga() {
  yield all([fork(watchDeleteAttribute)]);
}

export default deleteAttributeSaga;
