import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, CardBody, Row, CardFooter, Label, Col, ButtonGroup, Button, Input } from 'reactstrap';
import Select from 'react-select';

const PickupDropModal = ({ modal, setModal, addTerminal, terminalList }) => {
  const initialValues = {
    terminalPlaceholder: '',
    terminal: null,
  };
  const [state, setState] = useState(initialValues);
  const [options, setOptions] = useState([]);
  const [selectedButton, setSelectedButton] = useState(null);
  useEffect(() => {
    if (terminalList.length > 0) {
      const terminalNames = terminalList.map(({ name, lat, long }) => ({
        label: name,
        value: name,
        lat,
        long,
      }));
      setOptions(terminalNames);
    }
  }, [terminalList]);

  const toggle = () => setModal(!modal);

  const handleClick = button => {
    setSelectedButton(button);
  };

  return (
    <Modal isOpen={modal} toggle={toggle} size="md">
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Add Locations
        </h5>
        <Button type="button" onClick={toggle} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close"></Button>
      </div>
      <ModalBody>
        <Row>
          <Col md={12}>
            <div className="mb-3">
              <Input
                id="terminalPlaceHolder"
                name="terminalPlaceHolder"
                type="text"
                className="form-control"
                onChange={e => {
                  setState({ ...state, terminalPlaceholder: e.target.value });
                }}
                value={state?.terminalPlaceholder}
                autoComplete="off"
              />
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <Select
                id="terminal"
                name="terminal"
                value={state?.terminal}
                options={options}
                onChange={data => {
                  setState({ ...state, terminal: data });
                }}
              />
            </div>
          </Col>
          <Col md={12}>
            <ButtonGroup className="d-flex mt-3">
              <Button
                type="button"
                style={{
                  width: '100%',
                  borderRadius: '2px',
                  backgroundColor: selectedButton === 'pickup' ? 'blue' : 'white',
                  color: selectedButton === 'pickup' ? 'white' : 'black',
                }}
                onClick={() => handleClick('pickup')}
              >
                Pickup
              </Button>
              <Button
                type="button"
                style={{
                  width: '100%',
                  borderRadius: '2px',
                  border: '1px solid black',
                  backgroundColor: selectedButton === 'drop' ? 'blue' : 'white',
                  color: selectedButton === 'drop' ? 'white' : 'black',
                }}
                onClick={() => handleClick('drop')}
              >
                Drop
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-right">
        <Button
          className="overlay-disable"
          color="primary"
          onClick={() =>
            addTerminal({
              name: state.terminal,
              terminalPlaceholder: state.terminalPlaceholder,
              type: selectedButton,
            })
          }
          disabled={false}
        >
          Add
        </Button>
      </div>
    </Modal>
  );
};

export default PickupDropModal;
