import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';

import { AsyncPaginate } from 'react-select-async-paginate';
import axios from 'axios';
import { customHeaders, uaeProjectsBaseURL } from '../../../../../../../../Config';
import { Constant } from '../../../../../../../../Helpers/constant';
import { GET_CLIENT_PRICE } from '../../../../../../../../../store/actions';

const clientPricingStatusFilter = [
  {
    value: ``,
    label: `All`,
  },
  {
    value: `1`,
    label: `Pending`,
  },
  {
    value: `2`,
    label: `Need Attention`,
  },
  {
    value: `3`,
    label: `Ok`,
  },
];

const Filters = ({ filters, setFilters, currentPageNumber, setCurrentPageNumber }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */

  const onChangeHandler = (name, event) => {
    let updatedFilters = { ...filters };

    switch (name) {
      case 'clientId':
        updatedFilters = {
          ...filters,
          clientId: event,
        };
        break;

      case 'benefitGroupId':
        updatedFilters = {
          ...filters,
          benefitGroupId: event,
        };
        break;

      case 'programId':
        updatedFilters = {
          ...filters,
          programId: event,
        };
        break;

      case 'pricing-status':
        updatedFilters = {
          ...filters,
          status: event,
        };
        break;

      default:
      // do nothing
    }

    setFilters(updatedFilters);
    setCurrentPageNumber(0);

    let urlParams = {
      benefitGroupId: updatedFilters?.benefitGroupId === `` ? null : updatedFilters?.benefitGroupId?.value,
      programId: updatedFilters?.programId === `` ? null : updatedFilters?.programId?.value,
      clientId: updatedFilters?.clientId === `` ? null : updatedFilters?.clientId?.value,
      status: updatedFilters?.status === `` ? null : updatedFilters?.status?.value,
      // limit: Constant?.TABLE_PAGE_SIZE,
      // skip: 0,
    };

    dispatch({
      type: GET_CLIENT_PRICE,
      payload: {
        urlParam: {
          ...urlParams,
        },
      },
    });
  };

  /* ---------------------------- GET CLIENT HANDLER ---------------------------- */
  async function loadClient(search) {
    let urlParam = null;

    return await axios({
      method: `GET`,
      url: `${uaeProjectsBaseURL}/admin/clients`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...urlParam,
        name: search === '' ? null : search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data?.id,
              label: data?.name,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  /* ---------------------------- GET PROGRAM HANDLER ---------------------------- */
  async function loadProgram(search) {
    let urlParam = null;

    return await axios({
      method: `GET`,
      url: `${uaeProjectsBaseURL}/admin/projects`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...urlParam,
        name: search === '' ? null : search,
        clientName: filters?.clientId === '' ? null : filters?.clientId?.label,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data?.id,
              label: data?.name,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  /* ---------------------------- GET BENEFIT GROUP HANDLER ---------------------------- */
  async function loadBenefitGroups(search) {
    let urlParam = null;

    return await axios({
      method: `GET`,
      url: `${uaeProjectsBaseURL}/admin/benefitGroups`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...urlParam,
        name: search === '' ? null : search,
        clientName: filters?.clientId === '' ? null : filters?.clientId?.label,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data?.objectId,
              label: data?.name,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  return (
    <>
      <Row className="my-4">
        {/* <button
          onClick={e => {
            e.preventDefault();
            console.log(filters);
          }}
        >
          get state
        </button> */}

        <Col md={12}>
          <h5>Filters -</h5>
        </Col>

        <Col xl={3} lg={3} md={4} sm={6} xs={12} className="mb-3">
          <Label htmlFor="status" className="form-label">
            Client Name
          </Label>
          <AsyncPaginate
            debounceTimeout={500}
            value={filters?.clientId}
            loadOptions={loadClient}
            onChange={e => onChangeHandler(`clientId`, e)}
            isMulti={false}
            closeMenuOnSelect={true}
            noOptionsMessage={() => 'No results found'}
            cacheUniqs={[['code']]}
            placeholder="Select..."
            isClearable={true}
          />
        </Col>

        <Col xl={3} lg={3} md={4} sm={6} xs={12} className="mb-3">
          <Label htmlFor="status" className="form-label">
            Program Name
          </Label>
          <AsyncPaginate
            debounceTimeout={500}
            value={filters?.programId}
            loadOptions={loadProgram}
            onChange={e => onChangeHandler(`programId`, e)}
            isMulti={false}
            closeMenuOnSelect={true}
            noOptionsMessage={() => 'No results found'}
            cacheUniqs={[['code']]}
            placeholder="Select..."
            isClearable={true}
          />
        </Col>

        <Col xl={3} lg={3} md={4} sm={6} xs={12} className="mb-3">
          <Label htmlFor="status" className="form-label">
            Benefit Group Name
          </Label>
          <AsyncPaginate
            debounceTimeout={500}
            value={filters?.benefitGroupId}
            loadOptions={loadBenefitGroups}
            onChange={e => onChangeHandler(`benefitGroupId`, e)}
            isMulti={false}
            closeMenuOnSelect={true}
            noOptionsMessage={() => 'No results found'}
            cacheUniqs={[['code']]}
            placeholder="Select..."
            isClearable={true}
          />
        </Col>

        <Col xl={3} lg={3} md={4} sm={6} xs={12} className="mb-3">
          <Label htmlFor="status" className="form-label">
            Status
          </Label>
          <Select
            id="pricing-status"
            name="pricing-status"
            options={clientPricingStatusFilter}
            value={filters?.status}
            onChange={data => onChangeHandler(`pricing-status`, data)}
            isClearable={true}
          />
        </Col>
      </Row>
    </>
  );
};

export default Filters;
