import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../Containers/Config/index.js';
import { VENDOR_UPDATE_BOOKING, VENDOR_UPDATE_BOOKING_SUCCESS, VENDOR_UPDATE_BOOKING_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* vendorUpdateBooking({ payload }) {
  const headers = payload?.headers?.code
    ? { Authorization: Constant?.CLIENT_TOKEN, 'Content-Type': 'application/json', 'Project-Code': payload?.headers?.code }
    : { Authorization: Constant?.CLIENT_TOKEN, ...customHeaders };
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/vendor/v1/vendor-booking`,
      method: 'PUT',
      headers: headers,
      data: payload?.data,
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({
        type: VENDOR_UPDATE_BOOKING_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: VENDOR_UPDATE_BOOKING_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: VENDOR_UPDATE_BOOKING_ERROR, payload: error });
  }
}

export function* watchVendorUpdateBooking() {
  yield takeEvery(VENDOR_UPDATE_BOOKING, vendorUpdateBooking);
}

function* vendorUpdateBookingSaga() {
  yield all([fork(watchVendorUpdateBooking)]);
}

export default vendorUpdateBookingSaga;
