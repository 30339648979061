import React, { useEffect, useState } from 'react';
import { Card, Row, Col, CardBody, CardHeader, Label, CardFooter } from 'reactstrap';
import { Grid, _ } from 'gridjs-react';
import moment from 'moment';
import { PermissionConstants } from '../../../../Helpers/Constants/permissions';
import { hasPermission } from '../../../../Helpers/Helpers';
import { Constant } from '../../../../Helpers/constant';
import ReactPaginate from 'react-paginate';
import { PreviewCardHeader, TablePagination } from '../../../../Common';

const FilesSummaryTable = props => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      setData(
        props?.data?.map((data, index) => {
          return [
            `${props?.dataStartedFrom + (index + 1)}.`,
            data?.fileId || `NA`,
            (data?.isMailSent ? 'Sent' : 'Pending') || `NA`,
            data?.createdDate ? moment(`${data?.createdDate}`).format('DD-MM-YYYY, h:mm A') : `NA`,

            data?.updatedAt ? moment(`${data?.updatedAt}`).format('DD-MM-YYYY, h:mm A') : `NA`,
            data?.status || `NA`,
            data,
          ];
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `VIEW`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    }
  };

  return (
    <Card className="card-inner">
      <PreviewCardHeader
        title="Files Summary"
        primaryButton={hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DATA_POOL)}
        primaryButtonText="Upload File"
        primaryClickHandler={props?.onFileUpload}
      />

      <CardBody>
        <Row>
          <Col md={12}>
            <div className="custom-striped-table">
              <Grid
                data={data}
                columns={[
                  {
                    name: 'S.No.',
                    width: '80px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },

                  {
                    name: 'File Id',
                    width: '120px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },

                  {
                    name: 'Mail Status',
                    width: '120px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },

                  {
                    name: 'Created At',
                    width: '120px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },

                  {
                    name: 'Updated At',
                    width: '120px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },

                  {
                    name: 'Status',
                    width: '120px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Actions',
                    width: '120px',
                    formatter: (cell, row) =>
                      _(
                        <div className="d-flex justify-content-center flex-column">
                          <>
                            {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DATA_POOL) && (
                              <span
                                className="btn btn-sm btn-primary mb-1"
                                onClick={() => {
                                  actionBtnHandler(`VIEW`, cell);
                                }}
                              >
                                View Summary
                              </span>
                            )}
                          </>
                        </div>,
                      ),
                  },
                ]}
                sort={true}
                // search={true}
                fixedHeader={true}
                height="400px"
                pagination={{ enabled: false, limit: 5 }}
                style={{
                  th: {
                    zIndex: '999',
                    textAlign: 'center',
                  },
                  td: {
                    position: 'relative',
                    textAlign: 'center',
                  },
                  li: {
                    color: 'blue',
                  },
                }}
              />
            </div>
          </Col>
        </Row>
      </CardBody>

      <CardFooter>
        <Row className="custom_pagination">
          <Col>
            <div className="d-flex justify-content-end">
              <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
            </div>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

export default FilesSummaryTable;
