import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const ConfirmationModal = ({ isOpen, toggle, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Confirm Booking
        </h5>
        <Button type="button" onClick={toggle} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close"></Button>
      </div>
      <ModalBody>
        {' '}
        <h4 className="text-center">Are you sure you want to confirm this booking? </h4>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex mt-4" style={{ justifyContent: 'center', margin: 'auto', gap: '10px' }}>
          <Button type="button" className="btn btn-success" onClick={onConfirm}>
            Yes
          </Button>
          <Button type="button" className="btn btn-success" onClick={toggle}>
            No
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
