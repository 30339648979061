import React, { useState } from 'react';

import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Table } from 'reactstrap';

const ActionConfirmation = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [modal_varying1, setmodal_varying1] = useState(true);

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    setmodal_varying1(!modal_varying1);
    props?.onClose('YES', props?.data);
  };

  const tog_varying1 = () => {
    setmodal_varying1(!modal_varying1);
    props?.onClose('NO', props?.data);
  };

  const cancelHandler = () => {
    setmodal_varying1(!modal_varying1);
    props?.onClose('NO', props?.data);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Are you sure you want to go ahead?
        </h5>
        <Button type="button" onClick={() => tog_varying1()} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close"></Button>
      </div>

      <ModalBody>
        <p className="text-muted m-0">Once you will click on proceed, you will not be able to change previous state.</p>
      </ModalBody>

      <ModalFooter className="pb-0">
        <div className="modal-footer mx-auto">
          <Button className="btn btn-light overlay-disable" color="primary" onClick={cancelHandler}>
            Cancel
          </Button>
          <Button className="overlay-disable" color="primary" onClick={submitHandler}>
            Sure
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ActionConfirmation;
