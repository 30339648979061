import cogoToast from 'cogo-toast';
import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { customHeaders, indianProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { Constant } from '../../../../Containers/Helpers/constant.js';
import apiCall from '../../apiCall.js';

import { UPLOAD_ATTACHMENT, UPLOAD_ATTACHMENT_SUCCESS, UPLOAD_ATTACHMENT_ERROR } from './../../actionType.js';

function* uploadAttachment({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/admin/upload-attachments`,
      method: 'POST',
      data: payload?.data,
      isFormData: true,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        'Project-Code': 'ADMIN_ACCESS',
      },
    });

    if (response?.status === 200) {
      cogoToast.success('File uploaded successfully');
      yield put({
        type: UPLOAD_ATTACHMENT_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(`${response?.response?.data?.message}`);
      yield put({ type: UPLOAD_ATTACHMENT_ERROR, payload: response?.data?.message });
    }
  } catch (error) {
    yield put({ type: UPLOAD_ATTACHMENT_ERROR, payload: error });
  }
}

export function* watchUploadAttachment() {
  yield takeEvery(UPLOAD_ATTACHMENT, uploadAttachment);
}

function* uploadAttachmentSaga() {
  yield all([fork(watchUploadAttachment)]);
}

export default uploadAttachmentSaga;
