import React, { useEffect, useState } from 'react';

import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { Grid, _ } from 'gridjs-react';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { Constant } from './../../../../../Helpers/constant';
import { PreviewCardHeader } from './../../../../../Common';
import { PermissionConstants } from './../../../../../Helpers/Constants/permissions';
import { hasPermission } from './../../../../../Helpers/Helpers';

const LedgerInfo = props => {
  const [data, setData] = useState([]);
  const [serialColumnNumber, setSerialColumnNumber] = useState(0);

  useEffect(() => {
    if (props?.data?.length > 0) {
      setData(
        props?.data?.map((data, index) => {
          return [
            `${props?.dataStartedFrom + (index + 1)}.`,
            data?.benefitGroup?.name || `NA`,
            data?.count || `NA`,
            data?.createdBy?.name || `NA`,

            data?.applicableDate ? moment(`${data?.applicableDate}`).format('DD-MM-YYYY, h:mm A') : `NA`,

            data?.updatedAt ? moment(`${data?.updatedAt}`).format('DD-MM-YYYY, h:mm A') : `NA`,

            data?.expiryDate ? moment(`${data?.expiryDate}`).format('DD-MM-YYYY, h:mm A') : `NA`,
          ];
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, index) => {
    const currentSelectedIndex = Number(index.split('.')[0]) - 1 - props.pageNumber * Constant.TABLE_PAGE_SIZE;
    const selectedBookingDetails = props?.data[currentSelectedIndex];

    if (flag === `EDIT`) {
      props.actionHandler({
        bookingDetails: selectedBookingDetails,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <div id="table-gridjs hide-border-radius">
        <Grid
          data={data}
          columns={[
            {
              name: 'S.No.',
              width: '50px',
              formatter: cell => _(<span className="">{cell}</span>),
            },

            {
              name: 'Customer Info',
              width: '100px',
              formatter: cell => _(<span className="">{cell}</span>),
            },

            {
              name: 'Transaction ID',
              width: '100px',
              formatter: cell => _(<span className="">{cell}</span>),
            },

            {
              name: 'Program ID',
              width: '100px',
              formatter: cell => _(<span className="">{cell}</span>),
            },

            {
              name: 'Particulars',
              width: '100px',
              formatter: cell => _(<span className="">{cell}</span>),
            },

            {
              name: 'Transaction Value',
              width: '100px',
              formatter: cell => _(<span className="">{cell}</span>),
            },

            {
              name: 'Transaction Date & Time',
              width: '100px',
              formatter: cell => _(<span className="">{cell}</span>),
            },

            {
              name: 'Updated Balance',
              width: '120px',
              formatter: cell => _(<span className="">{cell}</span>),
            },
          ]}
          search={false}
          sort={true}
          fixedHeader={true}
          height="400px"
          pagination={{ enabled: false, limit: 5 }}
          style={{
            th: {
              color: '#878a99',
            },
            td: {
              border: 'none',
            },
          }}
        />
      </div>
      {props.children}

      <CardFooter>
        <Row className="custom_pagination">
          <Col className="text-end">
            <ReactPaginate
              previousLabel={'previous'}
              nextLabel={'next'}
              breakLabel={'...'}
              forcePage={props?.pageNumber}
              pageCount={Math.ceil(props?.totalData / Constant.TABLE_PAGE_SIZE)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={event => {
                props.pageHandler(event.selected);
              }}
              containerClassName={'pagination justify-content-center'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </Col>
        </Row>
      </CardFooter>
    </>
  );
};

export default LedgerInfo;
