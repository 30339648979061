import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ResetPasswordForm from './Components/ResetPasswordForm';
import PasswordCreatedSuccessful from './Components/PasswordCreatedSuccessful';
import FullLoader from '../../../Common/Loader/CompleteLoader';
import { CLIENT_RESET_PASSWORD, CLIENT_RESET_PASSWORD_EMPTY, VALIDATE_TOKEN, VALIDATE_TOKEN_EMPTY } from '../../../../store/actions';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LinkBroken from './Components/LinkBroken';

const ClientResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramValue = searchParams.get('dataId');

  const [isValidatedToken, setIsValidatedToken] = useState(false);
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [isPasswordResetSuccessfully, setIsPasswordResetSuccessfully] = useState(false);

  // Validate Token
  const { validateToken, validateTokenLoading, validateTokenError } = useSelector((state: any) => ({
    validateToken: state?.ValidateToken?.data,
    validateTokenLoading: state?.ValidateToken?.loading,
    validateTokenError: state?.ValidateToken?.error,
  }));

  // Reset Password
  const { resetPwd, resetPassword, resetPasswordLoading } = useSelector((state: any) => ({
    resetPwd: state,
    resetPassword: state?.ClientResetPassword?.data,
    resetPasswordLoading: state?.ClientResetPassword?.loading,
  }));

  useEffect(() => {
    if (['', null]?.includes(paramValue)) {
      history.push(`/client/login`);
    } else {
      dispatch({
        type: VALIDATE_TOKEN,
        payload: {
          data: {
            dataId: paramValue,
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (validateToken) {
      setIsValidatedToken(true);
      setIsLinkExpired(false);
      setIsPasswordResetSuccessfully(false);
    }
  }, [validateToken]);

  useEffect(() => {
    if (validateTokenError) {
      setIsValidatedToken(false);
      setIsLinkExpired(true);
      setIsPasswordResetSuccessfully(false);
    }
  }, [validateTokenError]);

  useEffect(() => {
    if (resetPassword !== null) {
      setIsValidatedToken(false);
      setIsLinkExpired(false);
      setIsPasswordResetSuccessfully(true);
    }
  }, [resetPassword]);

  const onSubmitPassword = data => {
    dispatch({
      type: CLIENT_RESET_PASSWORD,
      payload: {
        data: {
          ...data,
          dataId: paramValue,
        },
      },
    });
  };

  useEffect(() => {
    return () => {
      setIsValidatedToken(false);
      setIsLinkExpired(false);
      setIsPasswordResetSuccessfully(false);

      dispatch({
        type: VALIDATE_TOKEN_EMPTY,
      });
      dispatch({
        type: CLIENT_RESET_PASSWORD_EMPTY,
      });
    };
  }, []);

  return (
    <>
      {(resetPasswordLoading || validateTokenLoading) && <FullLoader />}

      {isLinkExpired && <LinkBroken />}
      {isValidatedToken && !isLinkExpired && !isPasswordResetSuccessfully && <ResetPasswordForm onSubmit={onSubmitPassword} />}
      {isPasswordResetSuccessfully && <PasswordCreatedSuccessful />}
    </>
  );
};

export default ClientResetPassword;
