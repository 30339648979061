import React from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import ParticlesAuth from '../../../../../pages/AuthenticationInner/ParticlesAuth';
import ThriweWhite from '../../../../../assets/custom/thriwe-white-logo.png';

const RecoverySent = props => {
  const history = useHistory();

  const redirectToMail = () => {
    window.open(`mailto:${props?.email ?? ''}`, '_blank');
  };

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <p className="mt-3 fs-15 fw-medium">&nbsp;</p>
                    <Link to="/client/login" className="d-inline-block auth-logo">
                      <img src={ThriweWhite} alt="" height="50" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4" style={{ borderRadius: '40px' }}>
                  <CardBody className="p-md-5 p-sm-3">
                    <div className="text-center mt-2">
                      <h2 className="text-dark">Password recovery sent</h2>
                    </div>
                    <div className="p-2 mt-4 mb-3">
                      <div className="text-center fs-6">
                        Password recovery link has been sent to &nbsp;
                        <span className="fw-bold d-inline-block cursor-pointer" onClick={() => redirectToMail()}>
                          {props?.email ?? ''}
                        </span>
                        &nbsp;kindly check your inbox
                      </div>
                    </div>
                    <div className="mt-4">
                      <Button
                        color="success"
                        className="btn btn-success w-100"
                        // type="submit"
                        onClick={() => history.push(`/client/login`)}
                      >
                        <i className="ri-arrow-left-line label-icon align-middle fs-16 ms-2"></i>
                        &nbsp; Back to Login
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(RecoverySent);
