import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';

const ServicableNonServicableCard = ({ selectedButton, handleClick }) => {
  return (
    <>
      <ButtonGroup className="d-flex mt-3">
        <Button
          type="button"
          style={{
            width: '100%',
            borderRadius: '2px',
            backgroundColor: selectedButton === 'Servicable' ? 'blue' : 'white',
            color: selectedButton === 'Servicable' ? 'white' : 'black',
          }}
          onClick={() => handleClick('Servicable')}
        >
          Servicable
        </Button>
        <Button
          type="button"
          style={{
            width: '100%',
            borderRadius: '2px',
            border: '1px solid black',
            backgroundColor: selectedButton === 'Non Servicable' ? 'blue' : 'white',
            color: selectedButton === 'Non Servicable' ? 'white' : 'black',
          }}
          onClick={() => handleClick('Non Servicable')}
        >
          Non Servicable
        </Button>
      </ButtonGroup>
      <p
        style={{
          fontSize: '15px',
          marginBottom: '0!important',
          fontWeight: 500,
          textAlign: 'center',
        }}
      >
        List of all the servicable areas
      </p>
    </>
  );
};

export default ServicableNonServicableCard;
