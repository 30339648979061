import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Dropdown, DropdownMenu, Row } from 'reactstrap';
import CreateUserModal from './CreateUserModal';
import { GET_USERS } from '../../../../../store/application/actionType';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Constant } from '../../../../Helpers/constant';
import { baseUrl, customHeaders } from '../../../../Config';

const team = [
  {
    id: 1,
    userImage: null,
    backgroundImg: 'smallImage9',
    userShortName: 'NM',
    name: 'Nancy Martino',
    jobPosition: 'Team Leader & HR',
    projectCount: 225,
  },
];

const User = ({ createUserModal, onSelectUser, setIsShowEditPermissionScreen }) => {
  const dispatch = useDispatch();
  const skip = useRef(0);

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [typedAdminName, setTypedAdminName] = useState(null);
  const [adminUsers, setAdminUsers] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Admin Users
  const { users, usersLoading } = useSelector(state => ({
    users: state?.Users?.data,
    usersLoading: state?.Users?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_USERS,
      payload: {
        urlParam: {
          pageIndex: skip.current,
        },
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (users?.results?.length > 0) {
      const newAdminUsers = users?.results?.map(user => {
        return {
          ...user,
          userShortName: makeUserShortName(user?.name),
        };
      });
      setAdminUsers([...adminUsers, ...newAdminUsers]);
    }
  }, [users]);

  useEffect(() => {
    const list = document.querySelectorAll('.team-list');
    const buttonGroups = document.querySelectorAll('.filter-button');
    for (let i = 0; i < buttonGroups.length; i++) {
      buttonGroups[i].addEventListener('click', onButtonGroupClick);
    }

    function onButtonGroupClick(event) {
      if (event.target.id === 'list-view-button' || event.target.parentElement.id === 'list-view-button') {
        document.getElementById('list-view-button').classList.add('active');
        document.getElementById('grid-view-button').classList.remove('active');
        list.forEach(function (el) {
          el.classList.add('list-view-filter');
          el.classList.remove('grid-view-filter');
        });
      } else {
        document.getElementById('grid-view-button').classList.add('active');
        document.getElementById('list-view-button').classList.remove('active');
        list.forEach(function (el) {
          el.classList.remove('list-view-filter');
          el.classList.add('grid-view-filter');
        });
      }
    }
  }, []);

  const toggledropDown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  /* ---------------------------- TYPED USER HANDLER ---------------------------- */
  async function loadAdminUsers(search) {
    const response = await fetch(`${baseUrl}/admin/users?name=${search}`, {
      method: `GET`,
      headers: new Headers({
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      }),
    });
    const usersResponse = await response.json();

    return {
      options: usersResponse?.results?.map((data, index) => {
        return {
          value: data.objectId,
          label: `${data.name}`,
          completeData: data,
        };
      }),
      hasMore: false,
    };
  }

  /* ---------------------------- ON SELECT USER ---------------------------- */
  const onSelectAdminUser = event => {
    setAdminUsers([
      {
        ...event?.completeData,
        userShortName: makeUserShortName(event?.completeData?.name),
      },
    ]);
    setTypedAdminName(event);
  };

  /* ---------------------------- GET ADMIN USERS ---------------------------- */
  const fetchAdminUsers = () => {
    skip.current += 10;
    dispatch({
      type: GET_USERS,
      payload: {
        urlParam: {
          pageIndex: skip.current,
        },
      },
    });
  };

  /* ---------------------------- SHORT USERNAME HANDLER ---------------------------- */
  const makeUserShortName = fullName => {
    const tempFullName = fullName?.trim()?.split(' ');
    const fName = tempFullName[0];
    const lName = tempFullName[tempFullName.length - 1];
    return `${fName.split('')[0]?.toLocaleUpperCase()}${lName.split('')[0]?.toLocaleUpperCase()}`;
  };

  const removeSearchedUser = () => {
    setAdminUsers([]);
    setTypedAdminName(null);
    skip.current = 0;

    dispatch({
      type: GET_USERS,
      payload: {
        urlParam: {
          pageIndex: skip.current,
        },
      },
    });
  };

  return (
    <>
      <CreateUserModal />
      {/* <button onClick={() => console.log('typedadmin name', typedAdminName, adminUsers)}>get state</button> */}

      <Card>
        <CardBody>
          <Row className="g-2">
            <Col sm={4}>
              <div className="">
                <AsyncPaginate
                  debounceTimeout={500}
                  value={typedAdminName}
                  loadOptions={loadAdminUsers}
                  onChange={e => onSelectAdminUser(e)}
                  isMulti={false}
                  closeMenuOnSelect={true}
                  noOptionsMessage={() => 'No results found'}
                  cacheUniqs={[['code']]}
                  placeholder="Enter Name..."
                />
              </div>
            </Col>
            {typedAdminName && (
              <Col sm={4}>
                <Button
                  color="danger"
                  id="grid-view-button"
                  className="btn btn-soft-danger nav-link btn-icon fs-14 active"
                  onClick={removeSearchedUser}
                >
                  <i className="ri-close-fill"></i>
                </Button>
              </Col>
            )}

            <Col className="col-sm-auto ms-auto">
              <div className="list-grid-nav hstack gap-1">
                <Button color="info" id="grid-view-button" className="btn btn-soft-info nav-link btn-icon fs-14 active filter-button">
                  <i className="ri-grid-fill"></i>
                </Button>
                <Button color="info" id="list-view-button" className="btn btn-soft-info nav-link  btn-icon fs-14 filter-button">
                  <i className="ri-list-unordered"></i>
                </Button>
                <Dropdown isOpen={dropdownOpen} toggle={toggledropDown}>
                  {/* <DropdownToggle
                                        type="button"
                                        className="btn btn-soft-info btn-icon fs-14"
                                    >
                                        <i className="ri-more-2-fill"></i>
                                    </DropdownToggle> */}
                  <DropdownMenu>
                    <li>
                      <Link className="dropdown-item" to="#">
                        All
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="#">
                        Last Week
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="#">
                        Last Month
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="#">
                        Last Year
                      </Link>
                    </li>
                  </DropdownMenu>
                </Dropdown>
                {/* <Button color="success" onClick={createUserModal}>
                                    <i className="ri-add-fill me-1 align-bottom"></i> Add Members
                                </Button> */}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Row>
        <Col lg={12}>
          <div>
            <InfiniteScroll dataLength={adminUsers?.length} next={fetchAdminUsers} hasMore={typedAdminName === null ? true : false}>
              <Row className="team-list grid-view-filter">
                {adminUsers?.map((adminUser, key) => (
                  <Col key={key}>
                    <Card className="team-box">
                      <div className="team-cover">
                        <img src={adminUser.backgroundImg} alt="" className="img-fluid" />
                      </div>
                      <CardBody className="p-4">
                        <Row className="align-items-center team-row">
                          <Col lg={4} className="col">
                            <div className="team-profile-img">
                              <div className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">
                                {adminUser.userImage != null ? (
                                  <img src={adminUser.userImage} alt="" className="img-fluid d-block rounded-circle" />
                                ) : (
                                  <div className={'avatar-title rounded-circle bg-soft-' + adminUser.bgColor + ' text-' + adminUser.textColor}>
                                    {adminUser.userShortName}
                                  </div>
                                )}
                              </div>
                              <div className="team-content">
                                <h5 className="fs-16 mb-1">{adminUser.name}</h5>
                                {/* <p className="text-muted mb-0">
                                                                    {adminUser.jobPosition}
                                                                </p> */}
                                <p className="text-muted mb-0">{adminUser.email}</p>
                              </div>
                            </div>
                          </Col>
                          <Col lg={4} className="col">
                            <Row className="text-muted text-center">
                              <Col xs={12} className="border-end border-end-dashed border-start border-start-dashed">
                                <h5 className="mb-1">{adminUser.projectCount}</h5>
                                <p className="text-muted mb-0">Projects</p>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={2} className="col">
                            <div
                              className="text-center btn btn-light view-btn"
                              onClick={() => {
                                onSelectUser(adminUser);
                                setIsShowEditPermissionScreen(true);
                              }}
                            >
                              Edit Permissions
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))}

                {usersLoading && (
                  <Col lg={12}>
                    <div className="text-center mb-3">
                      <Link to="#" className="text-success">
                        <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                        Loading
                      </Link>
                    </div>
                  </Col>
                )}
              </Row>
            </InfiniteScroll>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default User;
