import cogoToast from 'cogo-toast';
import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';
import { uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { CREATE_CLIENT_USER, CREATE_CLIENT_USER_SUCCESS, CREATE_CLIENT_USER_ERROR } from './../../actionType.js';

function* createClientUser({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/admin/v1/register-customer`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        'X-Requested-By': 'one-admin',
        'Project-Code': localStorage.getItem('bankCode') ?? 'MASTERCARD_KSA',
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      // cogoToast.success(response?.data?.message);
      if (localStorage.getItem('bankCode')) {
        localStorage.setItem('landmarkAuthToken', response?.headers?.authorization);
      }
      yield put({
        type: CREATE_CLIENT_USER_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: CREATE_CLIENT_USER_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: CREATE_CLIENT_USER_ERROR, payload: error });
  }
}

export function* watchCreateUser() {
  yield takeEvery(CREATE_CLIENT_USER, createClientUser);
}

function* createClientUserSaga() {
  yield all([fork(watchCreateUser)]);
}

export default createClientUserSaga;
