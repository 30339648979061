import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { baseUrl, customHeaders, indianProjectsBaseURL } from '../../../../Containers/Config';
import { GET_MIS_REPORT, GET_MIS_REPORT_SUCCESS, GET_MIS_REPORT_ERROR } from './../../actionType';
import cogoToast from 'cogo-toast';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant';

function* getMisReport({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/admin/mis-report`,
      method: 'POST',
      headers: { Authorization: Constant?.CLIENT_TOKEN, ...customHeaders },
      data: payload?.data,
      params: {
        ...payload?.urlParam,
      },
    });

    if (response.status === 200) {
      // cogoToast.success(response?.data?.message)
      yield put({ type: GET_MIS_REPORT_SUCCESS, payload: response?.data });
    } else {
      // cogoToast.error(response?.response?.data?.message)
      yield put({ type: GET_MIS_REPORT_ERROR, payload: response.error });
    }
  } catch (error) {
    yield put({ type: GET_MIS_REPORT_ERROR, payload: error });
  }
}

export function* watchGetMisReport() {
  yield takeEvery(GET_MIS_REPORT, getMisReport);
}

function* getMisReportSaga() {
  yield all([fork(watchGetMisReport)]);
}

export default getMisReportSaga;
