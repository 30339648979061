import { Modal, ModalBody } from 'reactstrap';

const RemoveConfirmation = ({ onClose, onSubmit }) => {
  return (
    <Modal isOpen={true} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-1 mb-0">Once you will proceed, you will not be able to change previous state</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button type="button" className="btn w-sm btn-light" data-bs-dismiss="modal" onClick={onClose}>
            Close
          </button>
          <button type="button" className="btn w-sm btn-danger " id="delete-record" onClick={onSubmit}>
            Yes, Delete It!
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default RemoveConfirmation;
