import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';

import { Card, CardBody, Col, Container, Form, Label, Row, Input, CardFooter, UncontrolledTooltip } from 'reactstrap';
import { PreviewCardHeader } from '../../../../../Common';
import { Constant } from '../../../../../Helpers/constant';
import ReactTooltip from 'react-tooltip';
import {
  GET_COUPON_PARTNER,
  GET_COUPON_FACILITY,
  GET_COUPON_SERVICE,
  GET_COUPON_FACILITY_EMPTY,
  GET_COUPON_SERVICE_EMPTY,
} from '../../../../../../store/application/actionType';

const Filters = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- REDUX STATES ---------------------------- */

  // Partners
  const { couponPartners } = useSelector(state => ({
    couponPartners: state?.GetCouponPartner?.data,
  }));

  // Facilities
  const { couponFacility } = useSelector(state => ({
    couponFacility: state?.GetCouponFacility?.data,
  }));

  // Services
  const { couponServices } = useSelector(state => ({
    couponServices: state?.GetCouponServices?.data,
  }));

  const initialState = {
    partner: null,
    facility: null,
    services: null,
  };

  const [state, setState] = useState(initialState);

  const [isFilterDisabled, setIsFilterDisabled] = useState(true);
  const [isResetDisabled, setIsResetDisabled] = useState(true);

  const [currentDisplayPartners, setCurrentDisplayPartners] = useState([]);
  const [currentDisplayFacilities, setCurrentDisplayFacilities] = useState([]);
  const [currentDisplayServices, setCurrentDisplayServices] = useState([]);

  const [isFacilityDisabled, setIsFacilityDisabled] = useState(true);
  const [isServiceDisabled, setIsServiceDisabled] = useState(true);

  useEffect(() => {
    if (props?.appliedFilters) {
      setState(props?.appliedFilters);
      // props.onSubmitFilter(props?.appliedFilters)
    }
  }, [props]);

  useEffect(() => {
    if (couponPartners === null) {
      dispatch({
        type: GET_COUPON_PARTNER,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (couponPartners?.length > 0) {
      setCurrentDisplayPartners(
        couponPartners?.map(partner => {
          return {
            value: partner?.objectId,
            label: partner?.name,
          };
        }),
      );
    } else {
      setCurrentDisplayPartners([]);
    }

    if (couponFacility?.length > 0) {
      setCurrentDisplayFacilities(
        couponFacility?.map(facility => {
          return { value: facility?.objectId, label: facility?.name };
        }),
      );
      setIsFacilityDisabled(false);
    } else {
      setIsFacilityDisabled(true);
      setCurrentDisplayFacilities([]);
    }

    if (couponServices?.length > 0) {
      setCurrentDisplayServices(
        couponServices?.map(service => {
          return { value: service?.objectId, label: service?.name };
        }),
      );
      setIsServiceDisabled(false);
    } else {
      setIsServiceDisabled(true);
      setCurrentDisplayServices([]);
    }
  }, [couponPartners, couponFacility, couponServices]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputPartnerHandler = data => {
    const updatedState = {
      ...state,
      partner: data,

      facility: null,
      services: null,
    };
    setState(updatedState);
    validationCheck(updatedState);

    dispatch({
      type: GET_COUPON_FACILITY,
      payload: {
        urlParam: {
          partnerId: data?.value,
        },
      },
    });

    dispatch({
      type: GET_COUPON_SERVICE,
      payload: {
        urlParam: {
          partnerId: data?.value,
        },
      },
    });

    setIsFilterDisabled(true);
    setIsResetDisabled(false);
  };

  const inputFacilityHandler = data => {
    const updatedState = {
      ...state,
      facility: data,
      services: null,
    };
    setState(updatedState);
    validationCheck(updatedState);

    dispatch({
      type: GET_COUPON_SERVICE,
      payload: {
        urlParam: {
          partnerId: state?.partner?.value,
          facilityId: data?.value,
        },
      },
    });

    setIsFilterDisabled(true);
  };

  const inputServiceHandler = data => {
    const updatedState = {
      ...state,

      services: data,
    };
    setState(updatedState);
    validationCheck(updatedState);
    setIsFilterDisabled(false);
  };

  /* ---------------------------- FILTER RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setState(initialState);
    setIsResetDisabled(true);
    setIsFilterDisabled(true);

    setCurrentDisplayFacilities([]);
    setCurrentDisplayServices([]);

    dispatch({
      type: GET_COUPON_FACILITY_EMPTY,
    });

    dispatch({
      type: GET_COUPON_SERVICE_EMPTY,
    });
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */

  const submitHandler = () => {
    props.onSubmitFilter(state);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {};

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Filter" />
            {/* <button onClick={() => console.log(state)}>get state</button> */}
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="earnValue">
                          Partner
                        </Label>
                        <Select
                          value={state?.partner}
                          id="partner"
                          name="partner"
                          isMulti={false}
                          onChange={inputPartnerHandler}
                          options={currentDisplayPartners}
                          style={{
                            border: '4px solid #ced4da',
                            bordeRadius: '0.25rem',
                          }}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="earnValue">
                          Facility
                        </Label>
                        <Select
                          value={state?.facility}
                          id="facility"
                          name="facility"
                          isDisabled={isFacilityDisabled}
                          isMulti={false}
                          onChange={inputFacilityHandler}
                          options={currentDisplayFacilities}
                          style={{
                            border: '4px solid #ced4da',
                            bordeRadius: '0.25rem',
                          }}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="earnValue">
                          Services
                          <span className="lbl_mandatory">*</span>
                        </Label>
                        <Select
                          value={state?.services}
                          id="services"
                          name="services"
                          isDisabled={isServiceDisabled}
                          isMulti={false}
                          onChange={inputServiceHandler}
                          options={currentDisplayServices}
                          style={{
                            border: '4px solid #ced4da',
                            bordeRadius: '0.25rem',
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>

            <CardFooter>
              <Row>
                <Col md={12}>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary mx-3 overlay-disable" onClick={submitHandler} disabled={isFilterDisabled}>
                      Search
                    </button>

                    <button type="submit" className="btn btn-primary overlay-disable" onClick={resetFilterHandler} disabled={isResetDisabled}>
                      Reset Filter
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>

            <div
              style={{
                width: '100px',
                position: 'relative',
                zIndex: '999999999999',
              }}
            >
              <ReactTooltip />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

// export default React.memo(
//   Filters,
//   (prevProps, nextProps) => prevProps === nextProps,
// )
export default Filters;
