import moment from 'moment';

import { Row, Col, Button, Modal, ModalBody, Card, CardBody } from 'reactstrap';

const MembershipsThriweApp = props => {
  return (
    <Modal centered isOpen={true} scrollable={true} id="user-cards-list" size="lg">
      <div className="modal-header p-3 bg-soft-warning">
        <h5 className="modal-title">Memberships</h5>
        <Button
          type="button"
          onClick={() => props.onCloseHandler()}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        <Row>
          {props?.data?.length > 0 ? (
            props?.data?.map((membership, index) => (
              <Col md={4} key={index}>
                <Card className="card-animate bg-light">
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <div>
                        {/* <p className="fw-medium mb-0 mt-2">Membership</p> */}
                        <h5 className="mt-1 ff-secondary fw-semibold">
                          <span className="counter-value">Membership:</span>
                        </h5>
                        <h6 className="mt-1 ff-secondary fw-semibold text-muted">
                          <span className="counter-value">{`${membership?.membership_id}`}</span>
                        </h6>
                        <h6 className="mt-4 ff-secondary fw-semibold">
                          <span className="counter-value">ID-{`${membership?.name}`}</span>
                        </h6>
                        <p className="mb-0">
                          Uploaded At: <span className="text-muted">{`${moment(membership?.created_at)?.format('DD-MM-YY')}`}</span>
                        </p>
                        <p className="mb-0">
                          Expiry: <span className="text-muted">{`${moment(membership?.expiry_date)?.format('DD-MM-YY')}`}</span>
                        </p>
                      </div>

                      <div></div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))
          ) : (
            <h5 className="text-center">Membership is not available.</h5>
          )}
        </Row>
        <div className="modal-footer d-flex justify-content-center pt-3">
          <Button className="btn btn-md btn-danger" color="danger" onClick={() => props.onCloseHandler()}>
            Close
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MembershipsThriweApp;
