import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap';

import Filters from './Components/Filters';
import PricingTable from './Components/PricingTable';
import { Constant } from '../../../../../../../Helpers/constant';
import UploadFileModal from './Components/Modals/UploadFileModal';
import { BreadCrumb, Loader, TablePagination } from '../../../../../../../Common';
import ProgramPricingDetails from './Components/Modals/ProgramPricingDetailsModal';
import {
  GET_CLIENT_PRICE,
  GET_CLIENT_PRICE_EMPTY,
  GET_RECORD_DATA_EMPTY,
  GET_TRACKER_DATA,
  GET_TRACKER_DATA_EMPTY,
  PRICE_PREVIEW,
  PRICE_PREVIEW_EMPTY,
  PUBLISH_DATA_WITH_AUTH_EMPTY,
  PUBLISH_RECORD_DATA_EMPTY,
  SEND_APPROVAL_MAIL_EMPTY,
  UPDATE_RECORD_DATA_EMPTY,
  UPLOAD_CONFIG_EXCEL,
  UPLOAD_CONFIG_EXCEL_EMPTY,
} from '../../../../../../../../store/actions';

const ClientPrices = ({ onView, currentPageNumber, setCurrentPageNumber, filters, setFilters, headerToggler }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);

  const [isShowUploadFile, setIsShowUploadFile] = useState(false);

  const [currentSelectedDetails, setCurrentSelectedDetails] = useState(null);
  const [isShowProgramPricingDetails, setIsShowProgramPricingDetails] = useState(false);

  const [currentPageNo, setCurrentPageNo] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState({ id: `1`, name: `All records`, value: `` });

  const [isBenefitGroupSelected, setIsBenefitGroupSelected] = useState(false);
  const [downloadType, setDownloadType] = useState('');

  /* ---------------------------- REDUX STATES ---------------------------- */
  //  CLIENT PRICES
  const { clientPrices, clientPricesLoading } = useSelector(state => ({
    clientPrices: state?.GetClientPrice?.data,
    clientPricesLoading: state?.GetClientPrice?.loading,
  }));

  //  PRICE PREVIEW
  const { pricePreviewData, pricePreviewLoading } = useSelector(state => ({
    pricePreviewData: state?.PricePreview?.data,
    pricePreviewLoading: state?.PricePreview?.loading,
  }));

  //  UPLOAD FILE
  const { uploadFile, uploadFileLoading } = useSelector(state => ({
    uploadFile: state?.UploadConfigExcel?.data,
    uploadFileLoading: state?.UploadConfigExcel?.loading,
  }));

  //  TRACKER DATA
  const { trackerList, trackerListLoading } = useSelector(state => ({
    trackerList: state?.GetTrackerData?.data,
    trackerListLoading: state?.GetTrackerData?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_CLIENT_PRICE,
      payload: {
        urlParam: {
          skip: 0,
          limit: Constant.TABLE_PAGE_SIZE,
        },
      },
    });

    return () => {
      dispatch({
        type: SEND_APPROVAL_MAIL_EMPTY,
      });

      dispatch({
        type: GET_CLIENT_PRICE_EMPTY,
      });

      dispatch({
        type: PUBLISH_RECORD_DATA_EMPTY,
      });

      dispatch({
        type: PUBLISH_DATA_WITH_AUTH_EMPTY,
      });

      dispatch({
        type: GET_RECORD_DATA_EMPTY,
      });

      dispatch({
        type: UPDATE_RECORD_DATA_EMPTY,
      });

      dispatch({
        type: GET_TRACKER_DATA_EMPTY,
      });

      dispatch({
        type: UPLOAD_CONFIG_EXCEL_EMPTY,
      });
    };
  }, []);

  useEffect(() => {
    if (pricePreviewData !== null) {
      setIsShowProgramPricingDetails(true);
    }
  }, [pricePreviewData]);

  useEffect(() => {
    if (uploadFile !== null) {
      // setIsShowUploadFile(false);
      // setIsShowProgramPricingDetails(false);

      // onView({
      //   ...currentSelectedDetails,
      //   ...uploadFile,
      // });

      let urlParams = {
        skip: currentPageNumber * Constant.TABLE_PAGE_SIZE,
        limit: Constant?.TABLE_PAGE_SIZE,
        fileTrackerId: uploadFile?.fileTrackerId,
        orderBy: `_created_at`,
        fileType: `CLIENT-DATA`,
      };

      dispatch({
        type: GET_TRACKER_DATA,
        payload: {
          urlParam: {
            ...urlParams,
          },
        },
      });

      dispatch({
        type: UPLOAD_CONFIG_EXCEL_EMPTY,
      });

      dispatch({
        type: PRICE_PREVIEW_EMPTY,
      });
    }
  }, [uploadFile]);

  useEffect(() => {
    if (trackerList !== null) {
      onView(trackerList?.results?.[0]);
      dispatch({
        type: GET_TRACKER_DATA_EMPTY,
      });
    }
  }, [trackerList]);

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);

    let urlParams = {};
    dispatch({
      type: GET_CLIENT_PRICE,
      payload: {
        urlParam: {
          ...urlParams,
          skip: pageNumber * Constant.TABLE_PAGE_SIZE,
          limit: Constant?.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedDetails(params?.details);

    if (params?.actionType === `CATEGORY_DOWNLOAD`) {
      setCurrentPageNo(0);
      setDownloadType('Category');
      setIsBenefitGroupSelected(false);
      dispatch({
        type: PRICE_PREVIEW,
        payload: {
          urlParam: {
            skip: 0,
            limit: Constant.TABLE_PAGE_SIZE,
            status: appliedFilters?.value,
          },
          data: {
            projectId: `${params?.details?.projectId || ''}`,
            categoryId: `${params?.details?.category?.id || ''}`,
            priceType: `${params?.details?.priceType || '1'}`,
            configId: `${params?.details?.configId || ''}`,
          },
        },
      });
    } else if (params?.actionType === `CATEGORY_UPLOAD`) {
      setDownloadType('Benefit Group');
      setIsShowUploadFile(true);
      setIsBenefitGroupSelected(false);
    } else if (params?.actionType === `BENEFIT_DOWNLOAD`) {
      setCurrentPageNo(0);
      setIsBenefitGroupSelected(true);

      dispatch({
        type: PRICE_PREVIEW,
        payload: {
          urlParam: {
            skip: 0,
            limit: Constant.TABLE_PAGE_SIZE,
            status: appliedFilters?.value,
          },
          data: {
            projectId: `${params?.details?.projectId || ''}`,
            benefitGroupId: `${params?.details?.benefitGroups?.id || ''}`,
            categoryId: `${params?.details?.category?.id || ''}`,
            priceType: `${params?.details?.priceType || '1'}`,
            configId: `${params?.details?.configId || ''}`,
          },
        },
      });
    } else if (params?.actionType === `BENEFIT_UPLOAD`) {
      setIsShowUploadFile(true);
      setIsBenefitGroupSelected(true);
    }
  };

  /* ---------------------------- PAGE CHANGE HANDLER ---------------------------- */
  const pageChangeHandler = pageNumber => {
    setCurrentPageNo(pageNumber);
    dispatch({
      type: PRICE_PREVIEW,
      payload: {
        urlParam: {
          skip: pageNumber * Constant.TABLE_PAGE_SIZE,
          limit: Constant.TABLE_PAGE_SIZE,
          status: appliedFilters?.value,
        },
        data: {
          projectId: `${currentSelectedDetails?.projectId || ''}`,
          benefitGroupId: `${isBenefitGroupSelected ? currentSelectedDetails?.benefitGroups?.id : ''}`,
          categoryId: `${currentSelectedDetails?.category?.id || ''}`,
          priceType: `${currentSelectedDetails?.priceType || '1'}`,
          configId: `${currentSelectedDetails?.configId || ''}`,
        },
      },
    });
  };

  /* ---------------------------- UPLOAD FILE HANDLER ---------------------------- */
  const onUploadFile = data => {
    dispatch({
      type: UPLOAD_CONFIG_EXCEL,
      payload: {
        data: data,
      },
    });
  };

  /* ---------------------------- ON FILTER HANDLER ---------------------------- */
  const filterOnProgramPricing = filter => {
    setAppliedFilters(filter);
    setCurrentPageNo(0);

    dispatch({
      type: PRICE_PREVIEW,
      payload: {
        urlParam: {
          skip: 0,
          limit: Constant.TABLE_PAGE_SIZE,
          status: filter?.value,
        },
        data: {
          projectId: `${currentSelectedDetails?.projectId || ''}`,
          benefitGroupId: `${isBenefitGroupSelected ? currentSelectedDetails?.benefitGroups?.id : ''}`,
          categoryId: `${currentSelectedDetails?.category?.id || ''}`,
          priceType: `${currentSelectedDetails?.priceType || '1'}`,
          configId: `${currentSelectedDetails?.configId || ''}`,
        },
      },
    });
  };

  /* ---------------------------- LOADER HANDLER ---------------------------- */
  useEffect(() => {
    if (clientPricesLoading || pricePreviewLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [clientPricesLoading, pricePreviewLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push('/dashboard');
    } else if (title === `Program Pricing`) {
      dispatch({
        type: PRICE_PREVIEW_EMPTY,
      });
    } else if (title === `Preview`) {
      //
    }
  };

  /* ---------------------------- ON CLOSE PROGRAM PREVIEW HANDLER ---------------------------- */
  const onCloseProgramPreview = () => {
    setIsShowProgramPricingDetails(false);
    setCurrentPageNo(0);
    setAppliedFilters({ id: `1`, name: `All records`, value: `` });
    setDownloadType('');
    dispatch({
      type: PRICE_PREVIEW_EMPTY,
    });
  };

  return (
    <Container fluid className="position-relative">
      {loading && <Loader />}

      {isShowProgramPricingDetails && (
        <ProgramPricingDetails
          pricingData={currentSelectedDetails}
          pricePreviewData={pricePreviewData}
          downloadType={downloadType}
          onClose={onCloseProgramPreview}
          onUpload={() => setIsShowUploadFile(true)}
          onFilter={filterOnProgramPricing}
          currentSelectedDetails={currentSelectedDetails}
          appliedFilters={appliedFilters}
          onChangePage={pageChangeHandler}
          currentPageNumber={currentPageNo}
        />
      )}
      {isShowUploadFile && (
        <UploadFileModal
          data={currentSelectedDetails}
          isLoading={uploadFileLoading}
          onUpload={onUploadFile}
          onClose={() => setIsShowUploadFile(false)}
        />
      )}

      <BreadCrumb title="Client price" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Program Pricing']} />
      <Row>
        <Col lg={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">Program Pricing</h4>

              <div className="btn-group custom-btn-group" role="group" aria-label="Basic radio toggle button group">
                <input
                  type="radio"
                  className="btn-check"
                  name="peakType"
                  id="btnradio1"
                  autoComplete="off"
                  value={1}
                  defaultChecked
                  onChange={() => headerToggler(`PROGRAM_PRICING`)}
                />
                <label className="btn btn-outline-primary" htmlFor="btnradio1">
                  Program Pricing
                </label>

                <input
                  type="radio"
                  className="btn-check"
                  name="peakType"
                  id="btnradio2"
                  autoComplete="off"
                  value={2}
                  onChange={() => headerToggler(`PRICING_UPLOADS`)}
                />
                <label className="btn btn-outline-primary" htmlFor="btnradio2">
                  Pricing Uploads
                </label>
              </div>
            </CardHeader>

            <CardBody>
              <>
                <Filters
                  filters={filters}
                  setFilters={setFilters}
                  currentPageNumber={currentPageNumber}
                  setCurrentPageNumber={setCurrentPageNumber}
                />
                {clientPrices?.results?.length > 0 && (
                  <Col md={12}>
                    <h6 className="mb-3">{filters?.status?.label}</h6>
                  </Col>
                )}
                <PricingTable
                  data={clientPrices?.results}
                  totalData={clientPrices?.count}
                  pageHandler={paginationHandler}
                  pageNumber={currentPageNumber}
                  actionHandler={tableActionHandler}
                />
              </>
            </CardBody>

            {clientPrices?.count > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{currentPageNumber * Constant?.TABLE_PAGE_SIZE + 1}</b> to&nbsp;
                      <b>{currentPageNumber * Constant?.TABLE_PAGE_SIZE + clientPrices?.results?.length}</b> of <b>{clientPrices?.count}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={clientPrices?.count} currentPage={currentPageNumber} onPageChangeHandler={paginationHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ClientPrices;
