import DataTable from 'react-data-table-component';
import { hasPermission } from '../../../../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../../../../Helpers/Constants/permissions';
import { useEffect, useState } from 'react';
import { Constant } from '../../../../../../../../Helpers/constant';

const PricingTable = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No.',
          width: '70px',
          selector: row => row.serialNumber,
        },
        {
          name: 'Client Name',
          width: '200px',
          selector: row => row.clientName,
        },
        {
          name: 'Program Name',
          width: '200px',
          selector: row => row.programName,
        },
        {
          name: 'Status',
          width: '150px',
          selector: row => row.status,
        },
        {
          name: 'Category',
          width: '500px',
          cell: row => (
            <div className="d-flex justify-content-center flex-column w-100">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <span
                    className="text-decoration-underline cursor-pointer"
                    onClick={() => {
                      actionBtnHandler(`CATEGORY_DOWNLOAD`, row?.originalData);
                    }}
                  >
                    {row?.categoryName}
                  </span>
                </div>

                <div>
                  {(hasPermission(PermissionConstants?.ALL_PERMISSIONS?.UPLOAD_FILE_WITH_AUTH_CLIENT_DATA_GM_REPORT) ||
                    hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_FILE_CLIENT_DATA_GM_REPORT)) && (
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm mx-1"
                      onClick={() => {
                        actionBtnHandler(`CATEGORY_DOWNLOAD`, row?.originalData);
                      }}
                    >
                      <i className="ri-download-cloud-line align-middle"></i>&nbsp; Download
                    </button>
                  )}

                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.UPLOAD_FILE_WITH_AUTH_CLIENT_DATA_GM_REPORT) && (
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm mx-1"
                      onClick={() => {
                        actionBtnHandler(`CATEGORY_UPLOAD`, row?.originalData);
                      }}
                    >
                      <i className="ri-upload-2-line align-middle"></i>&nbsp; Upload
                    </button>
                  )}
                </div>
              </div>
            </div>
          ),
        },
        {
          name: 'Benefit Group Name',
          width: '500px',
          cell: row => (
            <div className="d-flex justify-content-center flex-column w-100">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <span
                    className="text-decoration-underline cursor-pointer"
                    onClick={() => {
                      actionBtnHandler(`BENEFIT_DOWNLOAD`, row?.originalData);
                    }}
                  >
                    {row?.benefitGroupsName}
                  </span>
                </div>

                <div>
                  {(hasPermission(PermissionConstants?.ALL_PERMISSIONS?.UPLOAD_FILE_WITH_AUTH_CLIENT_DATA_GM_REPORT) ||
                    hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_FILE_CLIENT_DATA_GM_REPORT)) && (
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm mx-1"
                      onClick={() => {
                        actionBtnHandler(`BENEFIT_DOWNLOAD`, row?.originalData);
                      }}
                    >
                      <i className="ri-download-cloud-line align-middle"></i>&nbsp; Download
                    </button>
                  )}

                  {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.UPLOAD_FILE_WITH_AUTH_CLIENT_DATA_GM_REPORT) && (
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm mx-1"
                      onClick={() => {
                        actionBtnHandler(`BENEFIT_UPLOAD`, row?.originalData);
                      }}
                    >
                      <i className="ri-upload-2-line align-middle"></i>&nbsp; Upload
                    </button>
                  )}
                </div>
              </div>
            </div>
          ),
        },
      ];
      setColumns(fetchColumns);

      const data = props?.data?.map((priceData, index) => ({
        serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
        clientName: priceData?.clientName,
        programName: priceData?.programName,
        status: priceData?.status?.message,
        categoryName: priceData?.benefitDomain?.name,
        benefitGroupsName: priceData?.benefitGroups?.name,
        originalData: priceData,
      }));
      setData(data);
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `CATEGORY_DOWNLOAD`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `CATEGORY_UPLOAD`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `BENEFIT_DOWNLOAD`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `BENEFIT_UPLOAD`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    }
  };

  return (
    <DataTable
      columns={columns ?? []}
      data={data ?? []}
      highlightOnHover
      customStyles={{
        headRow: {
          style: {
            borderBottom: '1px solid #ddd',
            backgroundColor: '#f9fafb',
            fontWeight: '600',
          },
        },
        cells: {
          style: {
            border: '0.5px solid #ddd',
            borderBottom: '0px solid #ddd',
          },
        },
      }}
    />
  );
};

export default PricingTable;
