import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from '../../../Common';
import { Container } from 'reactstrap';
import {
  CLICK_DOWNLOAD_DUMP,
  GET_USER_CARDS,
  GET_BULK_USERS,
  GET_MEMBERSHIPS_EMPTY,
  GET_MEMBERSHIPS,
  UPDATE_USER_CARD_EMPTY,
  GET_USER_CARDS_EMPTY,
  UPDATE_CUSTOMER_DATA_EMPTY,
  GET_BULK_USERS_EMPTY,
} from '../../../../store/application/actionType';
import Table from './Components/Tables/Table';
import { Constant } from '../../../Helpers/constant';
import UserCards from './Components/Details/UserCards';
import Memberships from './Components/Modals/Memberships';
import UpdateUser from './Components/Modals/UpdateUser';
import CustomFilter from './Components/Filters/CustomFilter';
import cogoToast from 'cogo-toast';
import MembershipsThriweApp from './Components/Modals/MembershipsThriwe';

const UserManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [projectId, setProjectId] = useState(Constant?.CURRENT_SELECTED_PROGRAM?.id);

  const [loading, setLoading] = useState(false);
  const [isMainPage, setIsMainPage] = useState(true);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  const [currentSelectedCustomerDetails, setCurrentSelectedCustomerDetails] = useState([]);

  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);

  const [isUserCardListPageShow, setIsUserCardListPageShow] = useState(false);

  const [isMembershipsModal, setIsMembershipsModal] = useState(false);

  const [isMembershipsModalThriwe, setisMembershipsModalThriwe] = useState(false);

  const [isUpdateUserModal, setIsUpdateUserModal] = useState(false);

  const [isShowFilters, setIsShowFilters] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Bulk User
  const { users, usersListLoading } = useSelector(state => ({
    users: state?.BulkUsers?.data,
    usersListLoading: state?.BulkUsers?.loading,
  }));

  // Get User Cards
  const { userCards, userCardsLoading } = useSelector(state => ({
    userCards: state?.UserCards?.data,
    userCardsLoading: state?.UserCards?.loading,
  }));

  // Update User Card
  const { updateUserCard, updateUserCardLoading } = useSelector(state => ({
    updateUserCard: state?.UpdateUserCard?.data,
    updateUserCardLoading: state?.UpdateUserCard?.loading,
  }));

  // Get Memberships
  const { memberships, membershipsLoading } = useSelector(state => ({
    memberships: state?.Memberships?.data,
    membershipsLoading: state?.Memberships?.loading,
  }));

  // Update Memberships
  const { UpdateMemberShip, UpdateMemberShipLoading } = useSelector(state => ({
    UpdateMemberShip: state?.UpdateMembership?.data,
    UpdateMemberShipLoading: state?.UpdateMembership?.loading,
  }));

  // Update User
  const { updateUser, updateUserLoading } = useSelector(state => ({
    updateUser: state.UpdateCustomer.data,
    updateUserLoading: state.UpdateCustomer.loading,
  }));

  // Download Dump
  const { downloadDumpLoading } = useSelector(state => ({
    downloadDumpLoading: state?.DownloadDump?.loading,
  }));

  useEffect(() => {
    dispatch({
      type: GET_BULK_USERS,
      payload: {
        urlParam: {
          pageIndex: 0,
          pageSize: Constant.TABLE_PAGE_SIZE,
        },
      },
    });

    return () => {
      setIsMainPage(true);
      setIsUserCardListPageShow(false);

      dispatch({
        type: GET_BULK_USERS_EMPTY,
      });

      dispatch({
        type: GET_USER_CARDS_EMPTY,
      });
    };
  }, []);

  useEffect(() => {
    if (userCards !== null) {
      if (userCards?.results?.length === 0 || userCards?.results === null) {
        cogoToast.error('No card found!');
        dispatch({
          type: GET_USER_CARDS_EMPTY,
        });
      } else {
        setIsMainPage(false);
        setIsUserCardListPageShow(true);
      }
    }
  }, [userCards]);

  useEffect(() => {
    if (memberships !== null && Constant.PROJECT_CODE !== 'THRIWE_APP') {
      setIsMembershipsModal(true);
    } else if (memberships !== null) {
      setisMembershipsModalThriwe(true);
    }
  }, [memberships]);

  useEffect(() => {
    if (updateUserCard !== null) {
      dispatch({
        type: UPDATE_USER_CARD_EMPTY,
      });

      dispatch({
        type: GET_USER_CARDS,
        payload: {
          urlParam: {
            userId: `${currentSelectedCustomerDetails?.userId}`,
          },
        },
      });
    }
  }, [updateUserCard]);

  useEffect(() => {
    if (UpdateMemberShip != null) {
      dispatch({
        type: GET_MEMBERSHIPS,
        payload: {
          urlParam: {
            userId: `${currentSelectedCustomerDetails?.userId}`,
          },
        },
      });

      dispatch({
        type: GET_USER_CARDS_EMPTY,
      });
    }
  }, [UpdateMemberShip]);

  useEffect(() => {
    if (updateUser !== null) {
      setIsUpdateUserModal(false);
      dispatch({
        type: GET_BULK_USERS,
        payload: {
          data: {
            ...currentlyAppliedFilters,
          },
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            pageSize: Constant.TABLE_PAGE_SIZE,
          },
        },
      });
      dispatch({
        type: UPDATE_CUSTOMER_DATA_EMPTY,
      });
    }
  }, [updateUser]);

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedCustomerDetails(params?.details);

    if (params?.actionType === `CARD_LIST`) {
      dispatch({
        type: GET_USER_CARDS,
        payload: {
          urlParam: {
            userId: `${params?.details?.userId}`,
          },
        },
      });
    } else if (params?.actionType === `MEMBERSHIPS`) {
      dispatch({
        type: GET_MEMBERSHIPS,
        payload: {
          urlParam: {
            userId: `${params?.details?.userId}`,
          },
        },
      });

      dispatch({
        type: GET_USER_CARDS_EMPTY,
      });
    } else if (params?.actionType === `UPDATE`) {
      setIsUpdateUserModal(true);
    } else if (params?.actionType === `MEMBERSHIPS_THRIWE_APP`) {
      dispatch({
        type: GET_MEMBERSHIPS,
        payload: {
          urlParam: {
            user_id: `${params?.details?.userId}`,
          },
        },
      });
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    dispatch({
      type: GET_BULK_USERS,
      payload: {
        data: {
          ...currentlyAppliedFilters,
        },
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          pageSize: Constant.TABLE_PAGE_SIZE,
        },
      },
    });

    dispatch({
      type: GET_USER_CARDS_EMPTY,
    });

    dispatch({
      type: GET_MEMBERSHIPS_EMPTY,
    });
  };

  /* ---------------------------- DOWNLOAD DUMP HANDLER ---------------------------- */
  const downloadDumpHandler = () => {
    dispatch({
      type: CLICK_DOWNLOAD_DUMP,
      payload: {
        urlParam: {
          projectId,
          downloadType: 3,
        },
        data: currentlyAppliedFilters,
      },
    });
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Customer Management`) {
      setIsMainPage(true);
      setIsUserCardListPageShow(false);
    } else if (title === `user Cards`) {
      setIsMainPage(true);
      setIsUserCardListPageShow(false);
    }
  };

  const onCloseMembershipModal = () => {
    setIsMembershipsModal(false);
    setisMembershipsModalThriwe(false);

    dispatch({
      type: GET_USER_CARDS_EMPTY,
    });

    dispatch({
      type: GET_MEMBERSHIPS_EMPTY,
    });
  };

  const onCloseUserModal = () => {
    setIsUpdateUserModal(false);
  };

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = filters => {
    setIsShowFilters(false);

    const urlParams = {
      email: filters?.email === `` ? null : filters?.email,
      phoneNumber: filters?.phoneNumber === `` ? null : filters?.phoneNumber,
      membershipId: filters?.membershipId === `` ? null : filters?.membershipId,
      firstName: filters?.firstName === `` ? null : filters?.firstName,
    };
    setCurrentlyAppliedFilters(urlParams);
    setCurrentPageNumber(0);

    dispatch({
      type: GET_BULK_USERS,
      payload: {
        data: {
          ...urlParams,
        },
        urlParam: {
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          pageSize: Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (
      userCardsLoading ||
      usersListLoading ||
      updateUserCardLoading ||
      membershipsLoading ||
      downloadDumpLoading ||
      updateUserLoading ||
      UpdateMemberShipLoading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    userCardsLoading,
    usersListLoading,
    updateUserCardLoading,
    membershipsLoading,
    downloadDumpLoading,
    updateUserLoading,
    UpdateMemberShipLoading,
  ]);

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ----------------------------  MODAL ---------------------------- */}
          {isMembershipsModal && <Memberships data={memberships?.results} onCloseHandler={onCloseMembershipModal} />}
          {isMembershipsModalThriwe && <MembershipsThriweApp data={memberships?.results} onCloseHandler={onCloseMembershipModal} />}
          {isUpdateUserModal && (
            <UpdateUser data={currentSelectedCustomerDetails} setModal={setIsUpdateUserModal} onCloseHandler={onCloseUserModal} />
          )}

          {/*  ----------------------------  UI'S ---------------------------- */}
          {isUserCardListPageShow && (
            <>
              <BreadCrumb
                title="Customer Management"
                navigationby={breadcrumNavigationHandler}
                navigation={['Dashboard', 'Customer Management', 'user Cards']}
              />

              <UserCards data={userCards?.results} currentUser={currentSelectedCustomerDetails} />
            </>
          )}

          {isMainPage && (
            <>
              <BreadCrumb title="Customer Management" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Customer Management']} />
              {/* <Filter filterHandler={filterSubmitHandler} appliedFilters={currentlyAppliedFilters} setCurrentPageNumber={setCurrentPageNumber} /> */}
              <CustomFilter
                filterHandler={filterSubmitHandler}
                appliedFilters={currentlyAppliedFilters}
                setCurrentPageNumber={setCurrentPageNumber}
                show={isShowFilters}
                onCloseClick={() => setIsShowFilters(false)}
                onReset={() => setIsShowFilters(false)}
                setCurrentlyAppliedFilters={setCurrentlyAppliedFilters}
              />
              <Table
                data={users?.users ?? []}
                totalData={users?.totalCount}
                pageNumber={currentPageNumber}
                pageHandler={paginationHandler}
                actionHandler={tableActionHandler}
                dumpDownload={downloadDumpHandler}
                filterBtnHandler={() => setIsShowFilters(true)}
              />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default UserManagement;
