import { Col, Input, Label } from 'reactstrap';

const Offer = ({ benefit, selectedBenefitItem, onSelectBenefitItem }) => {
  return (
    <Col lg={12} md={12}>
      <div className="border border-dashed border-success p-2 rounded-2 cursor-pointer bg-soft-success mb-2">
        <span>
          <Input
            className="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id={benefit?.objectId}
            value={benefit?.objectId}
            onChange={onSelectBenefitItem}
            checked={selectedBenefitItem === benefit?.objectId}
          />
          <Label className="form-check-label text-black" htmlFor={benefit?.objectId}>
            <i className="bx bxs-offer label-icon text-warning fs-20 align-middle mx-2"></i>
            {benefit?.name}
          </Label>
        </span>
      </div>
    </Col>
  );
};

export default Offer;
