import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import apiCall from '../../../apiCall';
import { baseUrl, customHeaders, uaeProjectsBaseURL } from './../../../../../Containers/Config/index';

import { UPDATE_SERVICE, UPDATE_SERVICE_SUCCESS, UPDATE_SERVICE_ERROR } from './../../../actionType';
import { Constant } from '../../../../../Containers/Helpers/constant';
import cogoToast from 'cogo-toast';

function* updateService({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/admin/services`,
      method: 'PUT',
      headers: { Authorization: Constant?.CLIENT_TOKEN, ...customHeaders },
      data: payload.data,
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({ type: UPDATE_SERVICE_SUCCESS, payload: response });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({ type: UPDATE_SERVICE_ERROR, payload: response.error });
    }
  } catch (error) {
    yield put({ type: UPDATE_SERVICE_ERROR, payload: error });
  }
}

export function* watchUpdateService() {
  yield takeEvery(UPDATE_SERVICE, updateService);
}

function* updateServiceSaga() {
  yield all([fork(watchUpdateService)]);
}

export default updateServiceSaga;
