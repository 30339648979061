import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

const RestartConfirmation = ({ onClose, onConfirm }) => {
  return (
    <Modal isOpen={true} id="restart-confirmation" centered>
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title">Discard</h5>
        <Button
          type="button"
          onClick={() => {
            onClose();
          }}
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        <p className="fs-5 m-0">Are you sure want to discard the changes and restart the process?</p>
      </ModalBody>

      <ModalFooter className="pb-0">
        <div className="modal-footer mx-auto">
          <Button className="btn btn-soft-primary" onClick={onClose}>
            No
          </Button>
          <Button className="btn btn-primary" color="primary" onClick={onConfirm}>
            Yes
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default RestartConfirmation;
