import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Flatpickr from 'react-flatpickr';
import { Card, CardBody, Col, Form, Label, Row, CardFooter } from 'reactstrap';

import { PreviewCardHeader } from '../../../../../../Common';
import { PROGRAM_USER_EMPTY } from '../../../../../../../store/actions';

const Filter = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    utilized: ``,
    utilizedAtFrom: ``,
    utilizedAtTo: ``,
  };
  const [filter, setFilter] = useState(initialState);

  useEffect(() => {
    if (props?.appliedFilters) {
      setFilter(props?.appliedFilters);
    }
  }, [props?.appliedFilters]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'utilized':
        updatedState = {
          ...filter,
          utilized: value,
        };
        setFilter(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- DATE PICKR HANDLER ---------------------------- */
  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'utilizedDateRange':
        updatedState = {
          ...filter,
          utilizedAtFrom: date[0],
          utilizedAtTo: date[1],
        };
        setFilter(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    props.filterHandler({ ...filter });
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter({ ...initialState });
    props.filterHandler({ ...initialState });
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Filter" />
            {/* <button onClick={() => console.log({ filter })}>get state</button> */}
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="utilized" className="form-label">
                          Utilized
                        </Label>
                        <select id="utilized" name="utilized" className="form-select" value={filter?.utilized} onChange={inputChangeHandler}>
                          <option value="all">ALL</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="utilizedDateRange" className="form-label">
                          Utilized At - Range
                          <span className="icon_calendar">
                            <i className="las la-calendar"></i>
                          </span>
                        </Label>
                        <div className="area_expandable">
                          <Flatpickr
                            className="form-control"
                            name="utilizedDateRange"
                            id="utilizedDateRange"
                            value={[filter?.utilizedAtFrom, filter?.utilizedAtTo]}
                            onChange={date => datePickHandler(`utilizedDateRange`, date)}
                            options={{
                              mode: 'range',
                              dateFormat: 'Y-m-d',
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>

            <CardFooter>
              <Row>
                <Col md={12}>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary mx-3" onClick={filterSubmitHandler}>
                      Search
                    </button>

                    <button type="submit" className="btn btn-light" onClick={resetFilterHandler}>
                      Reset Filter
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Filter;
