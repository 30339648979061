import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, indianProjectsBaseURL, uaeProjectsBaseURL,clientCustomHeaders } from '../../../../Containers/Config/index.js';
import { GET_MEMBERSHIPS_DATA, GET_MEMBERSHIPS_DATA_SUCCESS, GET_MEMBERSHIPS_DATA_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getMembershipsData({ payload,endpoint ='/vendor/memberships'}) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}${endpoint}`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...clientCustomHeaders(),
        ...payload.headers,
      },
      params: {
        ...payload.urlParams,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_MEMBERSHIPS_DATA_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_MEMBERSHIPS_DATA_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_MEMBERSHIPS_DATA_ERROR, payload: error });
  }
}

export function* watchGetMembershipsData() {
  yield takeEvery(GET_MEMBERSHIPS_DATA, getMembershipsData);
}

function* getMembershipsDataSaga() {
  yield all([fork(watchGetMembershipsData)]);
}

export default getMembershipsDataSaga;
