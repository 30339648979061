import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from './../../../../Common';
import { Container } from 'reactstrap';
import EditCourseFromatsForm from './Components/Forms/EditCourseFormatsForm';
import {
  GET_COUPON_FACILITY,
  GET_GOLF_COURSE,
  GET_GOLF_COURSE_SLOTS,
  GET_GOLF_COURSE_SLOTS_EMPTY,
  UPDATE_GOLF_COURSE_SLOTS_EMPTY,
} from '../../../../../store/application/actionType';
import { Constant } from '../../../../Helpers/constant';
import FormatList from './Components/Table/FormatList';

const EditCourseFormats = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [currentPageNumberSecondary, setCurrentPageNumberSecondary] = useState(0);
  const [currentPageIndexSecondary, setCurrentPageIndexSecondary] = useState(0);

  const [getSlotsFiltersApplied, setGetSlotsFiltersApplied] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Golf Courses
  const { golfCoursesLoading } = useSelector(state => ({
    golfCoursesLoading: state?.GetGolfCourses?.loading,
  }));

  // Get Golf Courses slots
  const { golfCourseSlots, getGolfCourseSlotsLoading } = useSelector(state => ({
    golfCourseSlots: state?.GolfCourseSlots?.data,
    getGolfCourseSlotsLoading: state?.GolfCourseSlots?.loading,
  }));

  // Update Golf Course Slots
  const { updateGolfCourseSlots, updateGolfCourseSlotsLoading } = useSelector(state => ({
    updateGolfCourseSlots: state?.UpdateGolfCourseSlots?.data,
    updateGolfCourseSlotsLoading: state?.UpdateGolfCourseSlots?.loading,
  }));

  useEffect(() => {
    return () => {
      dispatch({
        type: GET_GOLF_COURSE_SLOTS_EMPTY,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (updateGolfCourseSlots !== null) {
      dispatch({
        type: UPDATE_GOLF_COURSE_SLOTS_EMPTY,
      });

      dispatch({
        type: GET_GOLF_COURSE_SLOTS,
        payload: {
          urlParam: {
            ...getSlotsFiltersApplied,
          },
        },
      });
    }
  }, [updateGolfCourseSlots]);

  /* ---------------------------- DISPATCHING ---------------------------- */

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    // setCurrentSelectedBookingDetails(params?.bookingDetails)
    // setCurrentConfirmationModalActionFor(params?.actionType)

    if (params?.actionType === `VIEW`) {
      // setBenefitPackInfoTableShow(false)
      // setBenefitPackTableShow(true)
      // setIsCreateBenefitPackFormShow(false)
      // setCurrentPageIndexSecondary(0)
      // setCurrentPageNumberSecondary(0)
      // SetCurrentSelectedBenefit(params?.bookingDetails?.objectId)
      // dispatch({
      //   type: BENEFIT_PACK_LIST,
      //   payload: {
      //     urlParam: {
      //       pageIndex: 0,
      //       benefitPacksInfoId: params?.bookingDetails?.objectId,
      //     },
      //   },
      // })
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    // setCurrentPageNumber(pageNumber)
    // setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE)
    // dispatch({
    //   type: BENEFIT_PACK_INFO,
    //   payload: {
    //     urlParam: {
    //       pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
    //     },
    //   },
    // })
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (golfCoursesLoading || getGolfCourseSlotsLoading || updateGolfCourseSlotsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [golfCoursesLoading, getGolfCourseSlotsLoading, updateGolfCourseSlotsLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Golf Admin`) {
      // do nothing
    } else if (title === `Edit Course Formats`) {
      // do nothing
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        {/* <button onClick={() => console.log(getSlotsFiltersApplied)}>
          get state
        </button> */}
        <Container fluid className="position-relative">
          {/*  ----------------------------  UI'S ---------------------------- */}
          <>
            <BreadCrumb title="Benefits" navigationby={breadcrumNavigationHandler} navigation={[`Golf Admin`, `Edit Course Formats`]} />
            <EditCourseFromatsForm onSubmit={data => setGetSlotsFiltersApplied(data)} />

            {golfCourseSlots?.slotDates?.length > 0 && (
              <FormatList
                data={golfCourseSlots}
                totalData={2}
                pageNumber={currentPageNumber}
                pageHandler={paginationHandler}
                actionHandler={tableActionHandler}
                // primaryBtnHandler={assignGolfCourseHandler}
              />
            )}
          </>
        </Container>
      </div>
    </>
  );
};

export default EditCourseFormats;
