import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'reactstrap';

import FileTypesModal from './Components/FileTypesModal';
import FileTypesConfigModal from './Components/FileTypesConfig';
import UploadExcelModal from './Components/UploadExcelModal';
import { DOWNLOAD_CONFIG_EXCEL, GET_FILE_TYPES_CONFIG, UPLOAD_CONFIG_EXCEL } from '../../../../../../store/actions';
import ModalLoader from '../../../../../Common/Loader/ModalLoader';
import DownloadExcelFormat from './Components/DownloadExcelFormat';

const BulkUpload = ({ onClose }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [isModalShow, setIsModalShow] = useState(true);

  const [selectedFileTypes, setSelectedFileTypes] = useState(null);
  const [selectedFileTypeConfig, setSelectedFileTypeConfig] = useState(null);

  const [isFileTypes, setIsFileTypes] = useState(true);
  const [isFileTypesConfig, setIsFileTypesConfig] = useState(false);
  const [isUploadExcel, setIsUploadExcel] = useState(false);

  const [isSampleFilterModalShow, setIsSampleFilterModalShow] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  //  FILE TYPES
  const { fileTypes, fileTypesLoading } = useSelector(state => ({
    fileTypes: state?.GetFileTypes?.data,
    fileTypesLoading: state?.GetFileTypes?.loading,
  }));

  //  FILE TYPES CONFIG
  const { fileTypesConfig, fileTypesConfigLoading } = useSelector(state => ({
    fileTypesConfig: state?.GetFileTypesConfig?.data,
    fileTypesConfigLoading: state?.GetFileTypesConfig?.loading,
  }));

  //  UPLOAD CONFIG FILE
  const { uploadConfigFileLoading } = useSelector(state => ({
    uploadConfigFileLoading: state?.UploadConfigExcel?.loading,
  }));

  // DOWNLOAD EXCEL
  const { downloadExcel, downloadExcelLoading } = useSelector(state => ({
    downloadExcel: state?.DownloadExcel?.data,
    downloadExcelLoading: state?.DownloadExcel?.loading,
  }));

  const [isShowDownloadBtn, setIsShowDownloadBtn] = useState(false);
  useEffect(() => {
    if (downloadExcel !== null) {
      //
    }
  }, [downloadExcel]);

  useEffect(() => {
    setIsModalShow(true);
    setIsFileTypes(true);
    setIsFileTypesConfig(false);
    setIsUploadExcel(false);
  }, []);

  /* ---------------------------- CLOSE MODAL HANDLER ---------------------------- */
  const onCloseHandler = () => {
    onClose();
    setIsModalShow(false);
  };

  /* ---------------------------- ON SUBMIT FILE TYPE HANDLER ---------------------------- */
  const onSubmitFileType = data => {
    setSelectedFileTypes(data);

    setIsFileTypes(false);
    setIsFileTypesConfig(true);
    setIsUploadExcel(false);

    dispatch({
      type: GET_FILE_TYPES_CONFIG,
      payload: {
        urlParam: {
          fileTypeId: `${data?.objectId}`,
        },
      },
    });
  };

  /* ---------------------------- ON SUBMIT FILE TYPE CONFIG HANDLER ---------------------------- */
  const onSubmitFileTypeConfig = data => {
    setSelectedFileTypeConfig(data);

    setIsFileTypes(false);
    setIsFileTypesConfig(false);
    setIsUploadExcel(true);
  };

  /* ---------------------------- ON SUBMIT FILE TYPE CONFIG HANDLER ---------------------------- */
  const onSubmitFile = data => {
    dispatch({
      type: UPLOAD_CONFIG_EXCEL,
      payload: {
        data: data,
      },
    });
  };

  /* ---------------------------- EXCEL DOWNLOAD HANDLER ---------------------------- */
  const excelDownloadHandler = () => {
    dispatch({
      type: DOWNLOAD_CONFIG_EXCEL,
      payload: {
        data: {
          fileConfigId: `${selectedFileTypeConfig?.objectId || ''}`,
          configEnum: selectedFileTypeConfig?.configEnum,
          objectIds: [],
        },
      },
    });
  };

  const onBackToFileTypes = () => {
    setIsFileTypes(true);
    setIsFileTypesConfig(false);
    setIsUploadExcel(false);
  };

  const onBackToFileTypesConfig = () => {
    setIsFileTypes(false);
    setIsFileTypesConfig(true);
    setIsUploadExcel(false);
  };

  const onClickSampleWithFilter = () => {
    setIsSampleFilterModalShow(true);
  };

  return (
    <>
      <Modal
        isOpen={isModalShow}
        // toggle={() => {
        //   onClose();
        // }}
        id="bulk-upload-gm"
      >
        {isFileTypes && (
          <FileTypesModal
            dataList={fileTypes?.results ?? []}
            selectedData={selectedFileTypes}
            onSubmit={onSubmitFileType}
            onClose={onCloseHandler}
            isLoading={fileTypesLoading}
          />
        )}

        {isFileTypesConfig && (
          <FileTypesConfigModal
            dataList={fileTypesConfig?.results ?? []}
            selectedData={selectedFileTypeConfig}
            onSubmit={onSubmitFileTypeConfig}
            onClose={onCloseHandler}
            onBack={onBackToFileTypes}
            isLoading={fileTypesConfigLoading}
          />
        )}

        {isUploadExcel && (
          <UploadExcelModal
            selectedFileTypes={selectedFileTypes}
            selectedFileTypeConfig={selectedFileTypeConfig}
            onClose={onCloseHandler}
            onDownloadExcel={excelDownloadHandler}
            onSubmit={onSubmitFile}
            onBack={onBackToFileTypesConfig}
            isLoading={downloadExcelLoading || uploadConfigFileLoading}
            onClickDownloadSampleWithFilter={onClickSampleWithFilter}
          />
        )}
      </Modal>

      {isSampleFilterModalShow && (
        <DownloadExcelFormat
          selectedFileTypes={selectedFileTypes}
          selectedFileTypeConfig={selectedFileTypeConfig}
          onClose={() => setIsSampleFilterModalShow(false)}
        />
      )}
    </>
  );
};

export default BulkUpload;
