import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'reactstrap';

import { Constant } from '../../../../Helpers/constant';
import { BreadCrumb, Loader } from '../../../../Common';
import ProjectsTable from './Components/Tables/ProjectsTable';
import PaymentSetting from './Components/Forms/PaymentSetting';
import ConfirmationModal from './Components/Modals/Confirmation';
import { GET_PROJECTS_LIST, UPDATE_PAYMENT_SETTING_EMPTY } from '../../../../../store/application/actionType';

const ProjectPaymentsSettings = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [projectsTableShow, setProjectsTableShow] = useState(true);
  const [isCreateProjectShow, setIsCreateProjectShow] = useState(false);

  const [currentSelectedDetails, setCurrentSelectedDetails] = useState([]);
  const [currentConfirmationModalActionFor, setCurrentConfirmationModalActionFor] = useState(null);

  const [updateConfirmationData, setUpdateConfirmationData] = useState(null);
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Projects List
  const { projectsList, projectsListLoading } = useSelector(state => ({
    projectsList: state?.ProjectsList?.data,
    projectsListLoading: state?.ProjectsList?.loading,
  }));

  // Update payment
  const { updatePaymentLoading } = useSelector(state => ({
    updatePaymentLoading: state?.UpdatePayment?.loading,
  }));

  // Get Payment Gateways
  const { paymentGatewaysLoading } = useSelector(state => ({
    paymentGatewaysLoading: state?.GetPaymentGateways?.loading,
  }));

  // Create Bin
  const { createBinLoading } = useSelector(state => ({
    createBinLoading: state?.CreateBin?.loading,
  }));

  // Bins List
  const { binsListLoading } = useSelector(state => ({
    binsListLoading: state?.BinsList?.loading,
  }));

  // Update Bin
  const { udpateBinLoading } = useSelector(state => ({
    udpateBinLoading: state?.UpdateBins?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */

  useEffect(() => {
    if (projectsList === null) {
      dispatch({
        type: GET_PROJECTS_LIST,
        payload: {
          urlParam: {
            pageIndex: currentPageIndex,
            pageSize: Constant?.TABLE_PAGE_SIZE,
          },
        },
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (updatePaymentLoading !== null) {
      dispatch({
        type: UPDATE_PAYMENT_SETTING_EMPTY,
      });

      dispatch({
        type: GET_PROJECTS_LIST,
        payload: {
          urlParam: {
            pageIndex: currentPageIndex,
            pageSize: Constant?.TABLE_PAGE_SIZE,
          },
        },
      });

      setProjectsTableShow(true);
      setIsCreateProjectShow(false);
    }
  }, [updatePaymentLoading]);

  /* ---------------------------- BUTTON HANDLER ---------------------------- */
  const createProjectBtnHandler = () => {
    setProjectsTableShow(false);
    setIsCreateProjectShow(true);
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedDetails(params?.details);
    setCurrentConfirmationModalActionFor(params?.actionType);

    if (params?.actionType === `UPDATE_PAYMENT`) {
      setProjectsTableShow(false);
      setIsCreateProjectShow(true);
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);

    dispatch({
      type: GET_PROJECTS_LIST,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          pageSize: Constant?.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (projectsListLoading || binsListLoading || paymentGatewaysLoading || createBinLoading || udpateBinLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [projectsListLoading, binsListLoading, paymentGatewaysLoading, createBinLoading, udpateBinLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Payments Management`) {
      setProjectsTableShow(true);
      setIsCreateProjectShow(false);

      dispatch({
        type: GET_PROJECTS_LIST,
        payload: {
          urlParam: {
            pageIndex: currentPageIndex,
            pageSize: Constant?.TABLE_PAGE_SIZE,
          },
        },
      });
    } else if (title === `Information`) {
      // do nothing
    }
  };

  const updatePaymentHandler = updatedData => {
    setUpdateConfirmationData(updatedData);
    setIsShowConfirmationModal(true);
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {isShowConfirmationModal && <ConfirmationModal data={updateConfirmationData} onCloseHandler={() => setIsShowConfirmationModal(false)} />}

          {projectsTableShow && (
            <>
              <BreadCrumb title="Payments" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Payments Management`]} />
              <ProjectsTable
                data={projectsList?.results}
                totalData={projectsList?.count}
                pageNumber={currentPageNumber}
                dataStartedFrom={currentPageIndex}
                pageHandler={paginationHandler}
                actionHandler={tableActionHandler}
                onClickPrimaryBtn={createProjectBtnHandler}
              />
            </>
          )}

          {isCreateProjectShow && (
            <>
              <PaymentSetting onSubmit={updatePaymentHandler} data={{ ...currentSelectedDetails }} breadcrumHandler={breadcrumNavigationHandler} />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default ProjectPaymentsSettings;
