import flagus from "../../assets/images/flags/us.svg";
import flagspain from "../../assets/images/flags/spain.svg";
import flaggermany from "../../assets/images/flags/germany.svg";
import flagitaly from "../../assets/images/flags/italy.svg";
import flagrussia from "../../assets/images/flags/russia.svg";
import flagchina from "../../assets/images/flags/china.svg";
import flagfrench from "../../assets/images/flags/french.svg";
import flaguae from "../../assets/images/flags/uae.svg";


const languages = {
  
 
  en: {
    label: "English",
    flag: flagus,
  },

  ar:{label:"Arabic",flag:flaguae}
}

export default languages
