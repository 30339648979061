import React from 'react';
import { Field, ErrorMessage, FieldHookConfig } from 'formik';
import { Label } from 'reactstrap';

const FormikInput = ({ type, name, label, value, requiredField, ...props }) => {
  const inputComponent =
    type === 'textarea' ? (
      <Field as="textarea" className="form-control" name={name} value={value} {...props} autoComplete="off" />
    ) : (
      <Field className="form-control" type={type} name={name} value={value} {...props} autoComplete="off" />
    );

  return (
    <div>
      <>
        <Label className="form-label">
          {label}
          {requiredField && <span className="lbl_mandatory"> *</span>}
        </Label>
        {inputComponent}
      </>
      <div
        style={{
          color: 'red',
          fontSize: '13px',
        }}
      >
        <small>
          <ErrorMessage name={name} />
        </small>
      </div>
    </div>
  );
};

export default FormikInput;
