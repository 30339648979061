import React from 'react';
import ParticlesAuth from '../../../../../../pages/AuthenticationInner/ParticlesAuth';
import { Card, CardBody, Col, Container, Label, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageDropdown from '../../../../../../Components/Common/LanguageDropdown';

const LoginType = ({ setLoginType }) => {
  const { t } = useTranslation();
  const divStyle = {
    border: '1px solid #E1E1E1',
    padding: '10px',
    borderRadius: '8px',
    fontSize: '13px',
    fontWeight: 400,
  };

  return (
    <>
      <div className="text-center mt-2">
        <h1 className="text-primary">{t('Login')}</h1>
      </div>
      <Row className="mb-5">
        <Col className="mt-4" md={12} style={{ cursor: 'pointer' }} onClick={() => setLoginType(1)}>
          <div className="d-flex justify-content-between align-items-center bg-white" style={divStyle}>
            <span>{t('UserName and Password')}</span>
            <span>
              <i className="ri-arrow-right-s-line text-dark fw-semibold fs-20 align-middle me-1"></i>
            </span>
          </div>
        </Col>

        <Col className="mt-4" md={12} style={{ cursor: 'pointer' }} onClick={() => setLoginType(2)}>
          <div className="d-flex justify-content-between align-items-center bg-white" style={divStyle}>
            <span>{t('Mobile Number and Password')}</span>
            <span>
              <i className="ri-arrow-right-s-line text-dark fw-semibold fs-20 align-middle me-1"></i>
            </span>
          </div>
        </Col>

        <Col className="mt-4" md={12} style={{ cursor: 'pointer' }} onClick={() => setLoginType(3)}>
          <div className="d-flex justify-content-between align-items-center bg-white" style={divStyle}>
            <span>{t('Mobile Number and OTP')}</span>
            <span>
              <i className="ri-arrow-right-s-line text-dark fw-semibold fs-20 align-middle me-1"></i>
            </span>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default LoginType;
