import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { Grid, _ } from 'gridjs-react';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { PreviewCardHeader, TablePagination } from '../../../../Common';
import { Constant } from '../../../../Helpers/constant';
import { PermissionConstants } from '../../../../Helpers/Constants/permissions';

const Table = props => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      setData(
        props?.data?.map((data, index) => {
          return [
            `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            data?.firstSix || `NA`,
            data?.lastFour || `NA`,

            data?.firstUser?.userId || `NA`,
            `NA`,
            data?.firstUser?.firstName || `NA`,
            data?.firstUser?.LastName || `NA`,
            data?.firstUser?.email || `NA`,
            data?.firstUser?.mobile || `NA`,

            data?.lastUser?.userId || `NA`,
            `NA`,
            data?.lastUser?.firstName || `NA`,
            data?.lastUser?.LastName || `NA`,
            data?.lastUser?.email || `NA`,
            data?.lastUser?.mobile || `NA`,

            data?.source || `NA`,
            // data?.filename || `NA`,
            // data?.createdAt
            //   ? moment(`${data?.createdAt}`).format('DD-MM-YYYY, h:mm A')
            //   : `NA`,
            // status[data?.status - 1],
          ];
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, index) => {
    const currentSelectedIndex = Number(index.split('.')[0]) - 1 - props.pageNumber * Constant.TABLE_PAGE_SIZE;
    const selectedDownloadDetails = props?.data[currentSelectedIndex];

    // if (flag === `DOWNLOAD`) {
    //   props.actionHandler({
    //     downloadDetails: selectedDownloadDetails,
    //     actionType: flag,
    //   })
    // }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Duplicate Cards" refreshBtn={props?.data?.length > 0 ? true : false} refreshHandler={props?.refreshHandler} />
            <CardBody>
              <div id="table-gridjs">
                <Grid
                  data={data}
                  columns={[
                    {
                      name: 'S.No.',
                      width: '70px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'First 6 Digits',
                      width: '140',
                      formatter: (cell, row) => _(<span>{cell}</span>),
                    },

                    {
                      name: 'Last 4 Digits',
                      width: '140',
                      formatter: (cell, row) => _(<span>{cell}</span>),
                    },

                    {
                      name: 'Old User Id',
                      width: '140',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Old Registered At',
                      width: '140',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Old First Name',
                      width: '140',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Old Last Name',
                      width: '140',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Old Email',
                      width: '140',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Old Mobile',
                      width: '140',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'New User Id',
                      width: '140',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'New Registered At',
                      width: '140',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'New First Name',
                      width: '140',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'New Last Name',
                      width: '140',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'New Email',
                      width: '140',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'New Mobile',
                      width: '140',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Source',
                      width: '140',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    // {
                    //   name: 'Actions',
                    //   width: '120px',
                    //   formatter: (cell, row) =>
                    //     _(
                    //       <div className="d-flex justify-content-center flex-column">
                    //         <>
                    //           {row.cells[row?.cells?.length - 2].data ===
                    //             status[1] && (
                    //             <span
                    //               className="btn btn-sm btn-primary mb-1"
                    //               onClick={() => {
                    //                 actionBtnHandler(
                    //                   `DOWNLOAD`,
                    //                   row.cells[0]?.data,
                    //                 )
                    //               }}
                    //             >
                    //               Download
                    //             </span>
                    //           )}
                    //         </>
                    //       </div>,
                    //     ),
                    // },
                  ]}
                  search={false}
                  sort={false}
                  fixedHeader={true}
                  height="400px"
                  pagination={{ enabled: false, limit: 5 }}
                />
              </div>
              {props.children}
            </CardBody>

            <CardFooter>
              <Row className="custom_pagination">
                <Col>
                  <div className="d-flex justify-content-end">
                    <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
