import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';

import { Card, CardBody, Col, Form, Label, Row, Input, CardFooter } from 'reactstrap';
import { Error, PreviewCardHeader } from '../../../../../../Common';
import ReactTooltip from 'react-tooltip';
import { CREATE_PARENT_PROJECT, GET_CLIENTS, GET_COUNTRY } from '../../../../../../../store/application/actionType';
import { doNotAllowSpecialsCharacters, isValidUrl, removeSpaceToUnderscoreAndCaps } from '../../../../../../Helpers/Helpers';

const PlatformConfiguration = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    name: '',
    internalName: '',
    description: '',
    code: '',
    client: null,
    url: '',
    brandingType: null,
    contractingCountry: null,
    isActive: true,
  };
  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);

  const [allCountries, setAllCountries] = useState([]);
  const [allClients, setAllClients] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Country List
  const { countries } = useSelector(state => ({
    countries: state?.Countries?.data,
  }));

  // Get Client List
  const { clients } = useSelector(state => ({
    clients: state?.Clients?.data,
    clientsLoading: state?.Clients?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    // Countries
    if (countries == null) {
      dispatch({
        type: GET_COUNTRY,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    // Clients
    if (clients === null) {
      dispatch({
        type: GET_CLIENTS,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (countries !== null) {
      setAllCountries(
        countries?.map(country => {
          return {
            value: `${country?.countryId}`,
            label: `${country?.name}`,
          };
        }),
      );
    }

    if (clients !== null) {
      setAllClients(
        clients?.results?.map(client => {
          return {
            value: `${client?.id}`,
            label: `${client?.name}`,
            data: client,
          };
        }),
      );
    }
  }, [countries, clients]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'name':
        updatedState = {
          ...state,
          name: doNotAllowSpecialsCharacters(value) ? value : state?.name,
        };
        break;

      case 'internalName':
        updatedState = {
          ...state,
          internalName: doNotAllowSpecialsCharacters(value) ? value : state?.internalName,
          code: doNotAllowSpecialsCharacters(value) ? removeSpaceToUnderscoreAndCaps(value) : state?.internalName,
        };
        break;

      case 'url':
        updatedState = {
          ...state,
          url: value,
        };
        break;

      case 'code':
        updatedState = {
          ...state,
          code: value,
        };
        break;

      case 'isActive':
        updatedState = {
          ...state,
          isActive: e.target.checked,
        };
        break;

      case 'description':
        updatedState = {
          ...state,
          description: value,
        };
        break;

      default:
      // do nothing
    }
    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...state };

    if (label === `contractingCountry`) {
      updatedState = {
        ...state,
        contractingCountry: data,
      };
    } else if (label === `client`) {
      updatedState = {
        ...state,
        client: data,
        contractingCountry: {
          label: data?.data?.contractingCountry?.name,
          value: data?.data?.contractingCountry?.id,
        },
      };
    } else if (label === `brandingType`) {
      updatedState = {
        ...state,
        brandingType: data,
      };
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (flag, data) => {
    if (
      data?.name?.trim() !== `` &&
      data?.internalName?.trim() !== `` &&
      data?.client !== null &&
      data?.contractingCountry !== null &&
      data?.url?.trim() !== `` &&
      isValidUrl(data?.url) &&
      data?.description?.trim() !== ``
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(`submissionCheck`, state)) {
      const body = {
        ...state,
        client: {
          objectId: state?.client?.value,
        },
        contractingCountry: {
          id: `${state?.contractingCountry?.value}`,
          name: state?.contractingCountry?.label,
        },
      };

      dispatch({
        type: CREATE_PARENT_PROJECT,
        payload: {
          data: body,
        },
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Platform Creation" />
            {/* <button onClick={() => console.log(state)}>get state</button> */}
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    {/* -------------------- start -------------------- */}
                    <Col md={4}>
                      <div className="mb-3">
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Label htmlFor="client" className="form-label">
                            Client
                            <span className="lbl_mandatory"> *</span>
                          </Label>

                          <span className="download-sample-excel" onClick={props?.createClientAction}>
                            Add Client
                          </span>
                        </div>
                        <Select
                          id="client"
                          name="client"
                          value={state?.client}
                          options={allClients}
                          onChange={data => inputSelectHandler(`client`, data)}
                        />
                        {isValidationShow && state?.client === null && <Error text="Kindly select client" />}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Platform Display Name
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input id="name" name="name" type="text" className="form-control" onChange={inputChangeHandler} value={state?.name} />
                        {isValidationShow && state?.name?.trim() === `` && <Error text="Kindly enter display name" />}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Platform Internal Name
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input
                          id="internalName"
                          name="internalName"
                          type="text"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state?.internalName}
                        />
                        {isValidationShow && state?.internalName?.trim() === `` && <Error text="Kindly enter internal name" />}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Platform Code
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input
                          id="code"
                          name="code"
                          type="text"
                          disabled={true}
                          className="form-control text-uppercase"
                          // onChange={inputChangeHandler}
                          value={state?.code}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="" className="form-label">
                          Contracting Country
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Select
                          id="contractingCountry"
                          name="contractingCountry"
                          value={state?.contractingCountry}
                          options={allCountries}
                          onChange={data => inputSelectHandler(`contractingCountry`, data)}
                        />
                        {isValidationShow && state?.contractingCountry === null && <Error text="Kindly select country" />}
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Platform URL
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input id="url" name="url" type="text" className="form-control" onChange={inputChangeHandler} value={state?.url} />
                        {isValidationShow && (state?.url?.trim() === `` || !isValidUrl(state?.url?.trim())) && (
                          <Error text="Kindly enter valid url" />
                        )}
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Description
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <textarea
                          rows="3"
                          id="description"
                          name="description"
                          type="text"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state?.description}
                        />
                        {isValidationShow && state?.description?.trim() === `` && <Error text="Kindly enter description" />}
                      </div>
                    </Col>

                    <Col sm={6}>
                      <div className="mb-3">
                        <Label htmlFor="isActive" className="form-label">
                          Is Active?
                        </Label>
                        <div className="d-flex align-items-center">
                          <span className="text-muted">False &nbsp;</span>
                          <div className="form-switch d-inline-block ">
                            <Input
                              className="form-check-input code-switcher form-switch-md cursor-pointer"
                              type="checkbox"
                              id="isActive"
                              name="isActive"
                              checked={state?.isActive}
                              onChange={inputChangeHandler}
                            />
                          </div>
                          <span> &nbsp;True</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>

            <CardFooter>
              <Row>
                <Col md={12}>
                  <div className="text-center">
                    <button type="submit" className="btn btn-primary mx-3 overlay-disable" onClick={submitHandler}>
                      Create
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>

            <div
              style={{
                width: '100px',
                position: 'relative',
                zIndex: '999999999999',
              }}
            >
              <ReactTooltip />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PlatformConfiguration;
