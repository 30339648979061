import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { Error } from '../../../../../Common';
import { IoMdArrowBack } from 'react-icons/io';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import MobileField from '../../../../../Common/FormControls/MobileField';
import { VENDOR_LOGIN_EMPTY, VENDOR_OTP_VALIDATE_EMPTY } from '../../../../../../store/actions';
import { useTranslation } from 'react-i18next';
import LanguageDropdown from '../../../../../../Components/Common/LanguageDropdown';

const LoginForm = ({ onSubmit, loginType, setLoginType, onValidate, onResendOtp }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    username: '',
    phoneNumber: '',
    password: '',
    countryCode: '',
  };
  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [otp, setOtp] = useState('');
  const [remainingSeconds, setRemainingSeconds] = useState(0);
  const [resendDisabled, setResendDisabled] = useState(false);

  // Login
  const { vendorLogin } = useSelector((state: any) => ({
    vendorLogin: state?.VendorLogin?.data,
  }));

  // Resend Otp
  const { vendorResendOtp, VendorResendOtpLoading } = useSelector((state: any) => ({
    vendorResendOtp: state?.VendorResendOtp?.data,
    VendorResendOtpLoading: state?.VendorResendOtp?.loading,
  }));

  useEffect(() => {
    let timer: any;
    if (remainingSeconds > 0) {
      timer = setTimeout(() => {
        setRemainingSeconds(prevSeconds => prevSeconds - 1);
      }, 1000);
    } else {
      setResendDisabled(false);
    }
    return () => clearTimeout(timer);
  }, [remainingSeconds]);

  useEffect(() => {
    if (vendorResendOtp !== null) {
      setRemainingSeconds(30);
      setResendDisabled(true);
    }
  }, [vendorResendOtp]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'username':
        updatedState = {
          ...state,
          username: value,
        };
        break;

      case 'password':
        updatedState = {
          ...state,
          password: value,
        };
        break;

      case 'phoneNumber':
        updatedState = {
          ...state,
          phoneNumber: value,
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  const handleChange = (value: string) => {
    // Regex to allow only numbers (up to 6 characters)
    if (/^\d{0,6}$/.test(value)) {
      setOtp(value);
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (loginType === 1 && data?.username?.trim() !== '' && data?.password?.trim() !== '') {
      setIsValidationShow(false);
      return true;
    } else if (loginType === 2 && data?.phoneNumber?.trim() !== '' && data?.password?.trim() !== '') {
      setIsValidationShow(false);
      return true;
    } else if (loginType === 3 && vendorLogin && otp.trim() !== '') {
      setIsValidationShow(false);
      return true;
    } else if (loginType === 3 && data?.phoneNumber?.trim() !== '' && !vendorLogin) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === 'submissionCheck' ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      const payload = {
        ...state,
      };
      onSubmit({
        ...payload,
      });
    }
  };

  const validateHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      onValidate({ otp });
    }
  };

  const handleResendOTP = () => {
    onResendOtp();
  };

  /* ---------------------------- INPUT MOBILE HANDLER ---------------------------- */
  const mobileChangeHandler = async (value, data) => {
    let updatedState = {
      ...state,
      countryCode: data?.dialCode,
      phoneNumber: value.slice(data?.dialCode?.length),
    };
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  return (
    <>
      {/* <button onClick={() => console.log(state)}>get state</button> */}
      <div className="text-center mt-2">
        <h1 className="text-primary">{t('Login')}</h1>
      </div>
      <div className="p-2 mt-4 mb-3">
        {loginType === 1 && (
          <div className="mb-3">
            <Label htmlFor="username" className="form-label">
              {t('Username')}
            </Label>
            <Input
              id="username"
              name="username"
              type="text"
              placeholder={t('Enter Username')}
              className="form-control"
              onChange={inputChangeHandler}
              value={state?.username}
            />
            {isValidationShow && state?.username?.trim() === `` && <Error text={t("Login id can't be empty")} />}
          </div>
        )}
        {(loginType === 2 || loginType === 3) && (
          <div className="mb-3">
            <Label htmlFor="phoneNumber" className="form-label">
              {t('Mobile Number')}
            </Label>
            <MobileField
              onChange={mobileChangeHandler}
              value={`${state?.countryCode}${state?.phoneNumber}`}
              preferredCountries={['sa', 'uae', 'in']}
              defaultCountry={'in'}
              disabled={loginType === 3 && vendorLogin ? true : false}
            />
            {isValidationShow && state?.phoneNumber?.trim() === `` && <Error text={t("Mobile no can't be empty")} />}
          </div>
        )}
        {(loginType === 1 || loginType === 2) && (
          <div className="mb-3">
            <Label className="form-label" htmlFor="password-input">
              {t('Password')}
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                id="password"
                name="password"
                type={isShowPassword ? 'text' : 'password'}
                placeholder={t('Enter Password')}
                className="form-control"
                onChange={inputChangeHandler}
                value={state?.password}
              />
              {isValidationShow && state?.password?.trim() === `` && <Error text={t("Password can't be empty")} />}
              <button
                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                type="button"
                id="password-addon"
                onClick={() => {
                  setIsShowPassword(!isShowPassword);
                }}
              >
                <i className="ri-eye-fill align-middle"></i>
              </button>
            </div>
          </div>
        )}
        {loginType === 3 && vendorLogin && (
          <>
            <div className="mb-3">
              <Label className="form-label" htmlFor="password-input">
                {t('OTP')}
              </Label>
              <div className="position-relative auth-pass-inputgroup mb-3 otp-container">
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  renderInput={(props, index) => (
                    <input
                      {...props}
                      onKeyPress={e => {
                        if (!/^\d$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  )}
                  inputStyle={{
                    padding: '5px',
                    margin: '10px 0',
                    alignItems: 'center',
                    textAlign: 'center',
                    height: '40px',
                    width: '40px',
                    color: 'black', // Change the text color to a lighter color (white)
                    borderRadius: '5px',
                    backgroundColor: 'transparent',
                    border: '1px solid #E1E1E1',
                    outline: 'none',
                    background: '#FFFFFF',
                    gap: '10px',
                  }}
                />
                {isValidationShow && vendorLogin && otp?.trim() === `` && <Error text={t("OTP can't be empty")} />}
              </div>
            </div>
            <div className="mt-3 d-flex justify-content-between">
              {remainingSeconds <= 0 && <p></p>}
              {remainingSeconds > 0 && (
                <button className="btn text-muted" type="button" style={{ fontSize: '11px' }}>
                  {t('Time Remaining:')} {remainingSeconds} {t('seconds')}
                </button>
              )}
              <button
                className="btn btn-link text-decoration-underline text-muted"
                style={{ cursor: 'pointer', textAlign: 'right' }}
                onClick={handleResendOTP}
                disabled={resendDisabled}
                type="button"
              >
                {t('Resend OTP')}
              </button>
            </div>
          </>
        )}

        <div className="mt-4">
          {!vendorLogin && (
            <Button color="success" className="btn btn-success w-100" type="submit" onClick={submitHandler}>
              {loginType === 3 ? `${t('Send Otp')}` : `${t('Login')}`}
            </Button>
          )}
          {vendorLogin && (
            <Button color="success" className="btn btn-success w-100" type="submit" onClick={validateHandler}>
              {t('Login')}
            </Button>
          )}
          <div
            className="mt-3 d-flex justify-content-center  gap-2 cursor-pointer"
            onClick={() => {
              setLoginType(0);
              dispatch({
                type: VENDOR_LOGIN_EMPTY,
              });
            }}
          >
            <IoMdArrowBack size={20} />
            <p className="text-center fw-medium">{t('Go Back')}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(LoginForm);
