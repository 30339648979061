import React from 'react';

import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

const TabNavigation = ({ curActiveTab, toggleHandler }) => {
  return (
    <div className="step-arrow-nav mb-4">
      <Nav className="nav-pills nav-justified custom-nav" role="tablist">
        <NavItem>
          <NavLink
            href="#"
            className={classnames(
              {
                active: curActiveTab === 1,
                done: curActiveTab <= 3 && curActiveTab > 0,
              },
              'p-3 ',
            )}
            style={{ fontSize: '15px' }}
            onClick={() => {
              toggleHandler(1);
            }}
          >
            <i className="ri-user-2-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
            Catalog Properties
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            href="#"
            className={classnames(
              {
                active: curActiveTab === 2,
                done: curActiveTab <= 3 && curActiveTab > 1,
              },
              'p-3 ',
            )}
            style={{ fontSize: '15px' }}
            onClick={() => {
              toggleHandler(2);
            }}
          >
            <i className="ri-user-2-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
            Add Catalog Item(s)
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            href="#"
            className={classnames(
              {
                active: curActiveTab === 3,
                done: curActiveTab <= 3 && curActiveTab > 2,
              },
              'p-3 ',
            )}
            style={{ fontSize: '15px' }}
            onClick={() => {
              toggleHandler(3);
            }}
          >
            <i className="ri-user-2-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
            Catalog Preview
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  );
};

export default TabNavigation;
