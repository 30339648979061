import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';

import { Card, CardBody, Col, Form, Label, Row, Input, CardFooter } from 'reactstrap';
import { Error, PreviewCardHeader } from '../../../../../../Common';
import ReactTooltip from 'react-tooltip';
import { CREATE_PROJECT, GET_CLIENTS, GET_COUNTRY, UPDATE_PROJECT } from '../../../../../../../store/application/actionType';
import {
  doNotAllowSpecialsCharacters,
  isANumber,
  isValidAlphaNumberic,
  isValidUrl,
  removeSpaceToUnderscoreAndCaps,
} from '../../../../../../Helpers/Helpers';

const invoiceStatuses = [
  { label: 'Raised', value: 'Raised' },
  { label: 'Received', value: 'Received' },
  { label: 'Pending', value: 'Pending' },
];

const ProgramConfiguration = ({ data, createClientAction }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    name: '',
    code: '',
    description: '',
    clientId: null,
    url: '',
    internalName: '',
    logo: '',
    contractingCountry: null,
    bookingTypes: [],

    startDate: '',
    endDate: '',
    cost: '',
    capping: '',

    invoices: [
      {
        number: '',
        date: '',
        status: '',
        amount: '',
      },
    ],
  };

  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);

  const [allCountries, setAllCountries] = useState([]);
  const [allClients, setAllClients] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Country List
  const { countries } = useSelector(state => ({
    countries: state?.Countries?.data,
  }));

  // Get Client List
  const { clients } = useSelector(state => ({
    clients: state?.Clients?.data,
    clientsLoading: state?.Clients?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    // Countries
    if (countries == null) {
      dispatch({
        type: GET_COUNTRY,
      });
    }

    // Clients
    if (clients === null) {
      dispatch({
        type: GET_CLIENTS,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (countries !== null) {
      setAllCountries(
        countries?.map(country => {
          return {
            value: `${country?.countryId}`,
            label: `${country?.name}`,
          };
        }),
      );
    }

    if (clients !== null) {
      setAllClients(
        clients?.results?.map(client => {
          return {
            value: `${client?.id}`,
            label: `${client?.name}`,
            data: client,
          };
        }),
      );
    }
  }, [countries, clients]);

  useEffect(() => {
    if (data !== null) {
      setState({
        ...data,
        objectId: data?.id,
        contractingCountry: {
          value: `${data?.contractingCountry?.id}`,
          label: data?.contractingCountry?.name,
        },
        cost: `${data?.cost}`,
        clientId: {
          value: data?.client?.id,
          label: data?.client?.name,
        },
        invoices: data?.invoices?.map(invoice => ({
          ...invoice,
          amount: invoice?.amount.toString(),
          status: invoiceStatuses?.filter(invoiceData => invoiceData?.value === invoice?.status)?.[0] ?? null,
        })),
      });
    }
  }, [data]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    let programCost = '';

    switch (name) {
      case 'internal-name':
        updatedState = {
          ...state,
          internalName: doNotAllowSpecialsCharacters(value) ? value : state?.internalName,

          code: doNotAllowSpecialsCharacters(value) ? removeSpaceToUnderscoreAndCaps(value) : state?.internalName,
        };
        break;

      case 'name':
        updatedState = {
          ...state,
          name: doNotAllowSpecialsCharacters(value) ? value : state?.name,
        };
        break;

      case 'url':
        updatedState = {
          ...state,
          url: value,
        };
        break;

      case 'code':
        updatedState = {
          ...state,
          code: value,
        };
        break;

      case 'logo':
        updatedState = {
          ...state,
          logo: value,
        };
        break;

      case 'capping':
        updatedState = {
          ...state,
          capping: value,
        };
        break;

      case 'cost':
        if (value?.length === 0) {
          programCost = ``;
        } else {
          programCost = isANumber(value) ? value : state?.cost;
        }
        updatedState = {
          ...state,
          cost: programCost,
        };
        break;

      default:
      // do nothing
    }
    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...state };

    if (label === `contractingCountry`) {
      updatedState = {
        ...state,
        contractingCountry: data,
      };
    } else if (label === `client`) {
      updatedState = {
        ...state,
        clientId: data,
        contractingCountry: {
          label: data?.data?.contractingCountry?.name,
          value: `${data?.data?.contractingCountry?.id}`,
        },
      };
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- DATE SELECT HANDLER ---------------------------- */
  const datePickHandler = (flag, date) => {
    let updatedState = { ...state };

    switch (flag) {
      case 'startDate':
        updatedState = {
          ...state,
          startDate: new Date(date).toISOString(),
        };
        break;

      case 'endDate':
        updatedState = {
          ...state,
          endDate: new Date(date).toISOString(),
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- INVOICE CHANGE HANDLER ---------------------------- */
  const invoiceChangeHandler = (index, flag, data) => {
    let updatedState = { ...state };

    const tempInvoices = updatedState?.invoices;
    const selInvoice = tempInvoices?.[index] ?? [];
    let updatedInvoice = { ...selInvoice };

    switch (flag) {
      case 'invoice-number':
        updatedInvoice = {
          ...updatedInvoice,
          number: data,
        };
        break;

      case 'invoice-amount':
        updatedInvoice = {
          ...updatedInvoice,
          amount: data,
        };
        break;

      case 'invoice-status':
        updatedInvoice = {
          ...updatedInvoice,
          status: data,
        };
        break;

      case 'invoice-date':
        updatedInvoice = {
          ...updatedInvoice,
          date: new Date(data).toISOString(),
        };
        break;

      default:
      // do nothing
    }

    tempInvoices?.splice(index, 1, updatedInvoice);
    setState({
      ...updatedState,
      invoices: tempInvoices,
    });
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (flag, data) => {
    if (
      data?.code !== `` &&
      data?.name !== `` &&
      data?.url !== `` &&
      data?.clientId !== null &&
      data?.internalName !== `` &&
      data?.logo !== `` &&
      data?.contractingCountry !== null &&
      isValidUrl(data?.logo) &&
      isValidUrl(data?.url) &&
      data?.startDate !== `` &&
      data?.endDate !== `` &&
      data?.cost !== `` &&
      data?.capping !== ``
    ) {
      let isCorrectlyFilledInvoiceData = true;
      data?.invoices?.forEach(invoice => {
        if (invoice?.number === `` || invoice?.date === `` || invoice?.status === `` || invoice?.amount === ``) {
          isCorrectlyFilledInvoiceData = false;
        }
      });
      if (!isCorrectlyFilledInvoiceData) {
        setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
        return true;
      } else {
        setIsValidationShow(false);
        return false;
      }
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return true;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (!validateErrors(`submissionCheck`, state)) {
      const payload = {
        ...state,
        clientId: state?.clientId?.value,
        contractingCountry: {
          id: `${state?.contractingCountry?.value}`,
          name: state?.contractingCountry?.label,
        },
        cost: parseFloat(state?.cost),
        invoices: state?.invoices?.map(invoice => ({
          ...invoice,
          amount: parseFloat(invoice?.amount),
          status: invoice?.status?.value,
        })),
      };

      if (data) {
        dispatch({
          type: UPDATE_PROJECT,
          payload: {
            data: payload,
          },
        });
      } else {
        dispatch({
          type: CREATE_PROJECT,
          payload: {
            data: payload,
          },
        });
      }
    }
  };

  /* ---------------------------- ADD MORE INVOICE HANDLER ---------------------------- */
  const addMoreInvoice = (e, index) => {
    e.preventDefault();
    let updatedState = { ...state };

    const tempInvoices = updatedState?.invoices;
    tempInvoices?.splice(index + 1, 0, {
      number: '',
      date: '',
      status: '',
      amount: '',
    });

    setState({
      ...state,
      invoices: tempInvoices,
    });
  };

  /* ---------------------------- ADD MORE INVOICE HANDLER ---------------------------- */
  const removeInvoice = (e, index) => {
    e.preventDefault();
    let updatedState = { ...state };

    const tempInvoices = updatedState?.invoices;
    tempInvoices?.splice(index, 1);

    setState({
      ...state,
      invoices: tempInvoices,
    });
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title={`Program ${data ? 'Updation' : 'Creation'}`} />
            {/* <button onClick={() => console.log(state)}>get state</button> */}
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Label htmlFor="client" className="form-label">
                            Client
                            <span className="lbl_mandatory"> *</span>
                          </Label>

                          <span className="download-sample-excel" onClick={createClientAction}>
                            Add Client
                          </span>
                        </div>
                        <Select
                          id="client"
                          name="client"
                          value={state?.clientId}
                          options={allClients}
                          onChange={data => inputSelectHandler(`client`, data)}
                        />
                        {isValidationShow && state?.clientId === null && <Error text="Kindly select client" />}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Program Display Name
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input id="name" name="name" type="text" className="form-control" onChange={inputChangeHandler} value={state?.name} />
                        {isValidationShow && state?.name?.trim() === `` && <Error text="Kindly enter display name" />}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Program Internal Name
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input
                          id="internal-name"
                          name="internal-name"
                          type="text"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state?.internalName}
                        />
                        {isValidationShow && state?.internalName?.trim() === `` && <Error text="Kindly enter internal name" />}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Program Code
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input
                          id="code"
                          name="code"
                          type="text"
                          disabled={true}
                          className="form-control text-uppercase"
                          // onChange={inputChangeHandler}
                          value={state?.code}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Program Logo URL
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input id="logo" name="logo" type="text" className="form-control" onChange={inputChangeHandler} value={state?.logo} />
                        {isValidationShow && (state?.logo?.trim() === `` || !isValidUrl(state?.logo)) && <Error text="Kindly enter valid logo url" />}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="" className="form-label">
                          Contracting Country
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Select
                          id="contracting-country"
                          name="contracting-country"
                          value={state?.contractingCountry}
                          options={allCountries}
                          onChange={data => inputSelectHandler(`contractingCountry`, data)}
                        />
                        {isValidationShow && state?.contractingCountry === null && <Error text="Kindly select country" />}
                      </div>
                    </Col>

                    {/* ------------------------------------------------------ */}

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Program Cost
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input id="cost" name="cost" type="text" className="form-control" onChange={inputChangeHandler} value={state?.cost} />
                        {isValidationShow && state?.cost?.trim() === `` && <Error text="Kindly enter program cost" />}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="form-label">
                          MOQ/Capping
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input
                          id="capping"
                          name="capping"
                          type="number"
                          onWheel={e => e.target.blur()}
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state?.capping}
                        />
                        {isValidationShow && state?.capping?.trim() === `` && <Error text="Kindly enter MOQ/Capping" />}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="to" className="form-label">
                          Start Date
                          <span className="lbl_mandatory"> *</span>
                          <span className="icon_calendar">
                            <i className="las la-calendar"></i>
                          </span>
                        </Label>
                        <div className="area_expandable">
                          <Flatpickr
                            className="form-control"
                            name="to"
                            id="to"
                            value={state?.startDate}
                            onChange={date => datePickHandler(`startDate`, date)}
                            options={{
                              enableTime: false,
                              dateFormat: 'd M, Y',
                              // maxDate: "today",
                            }}
                          />
                        </div>
                        {isValidationShow && state?.startDate === '' && <Error text="Kindly enter start date" />}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="to" className="form-label">
                          End Date
                          <span className="lbl_mandatory"> *</span>
                          <span className="icon_calendar">
                            <i className="las la-calendar"></i>
                          </span>
                        </Label>
                        <div className="area_expandable">
                          <Flatpickr
                            className="form-control"
                            name="to"
                            id="to"
                            value={state?.endDate}
                            onChange={date => datePickHandler(`endDate`, date)}
                            options={{
                              enableTime: false,
                              dateFormat: 'd M, Y',
                              // maxDate: "today",
                            }}
                          />
                        </div>
                        {isValidationShow && state?.endDate === '' && <Error text="Kindly enter end date" />}
                      </div>
                    </Col>

                    <Col md={8}>
                      <div className="mb-3">
                        <Label className="form-label">Program URL</Label>
                        <Input id="url" name="url" type="text" className="form-control" onChange={inputChangeHandler} value={state?.url} />
                        {isValidationShow && (state?.url?.trim() === `` || !isValidUrl(state?.url?.trim())) && (
                          <Error text="Kindly enter valid url" />
                        )}
                      </div>
                    </Col>

                    {/* ------------------------------------------------------ */}
                    <Col md={12}>
                      <Card className="card-inner">
                        <PreviewCardHeader title="Invoice(s)" />
                        <CardBody>
                          {state?.invoices?.map((invoice, index) => (
                            <Row key={index}>
                              <Col md={3}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Invoice Number
                                    <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Input
                                    id="number"
                                    name="number"
                                    type="number
                                                                                      onWheel={e => e.target.blur()}
"
                                    className="form-control"
                                    onChange={e => invoiceChangeHandler(index, `invoice-number`, e.target.value)}
                                    value={invoice?.number}
                                  />
                                  {isValidationShow && invoice?.number?.trim() === `` && <Error text="Kindly enter invoice number" />}
                                </div>
                              </Col>

                              <Col md={3}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Invoice Amount
                                    <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Input
                                    id="amount"
                                    name="amount"
                                    type="number
                                                                                      onWheel={e => e.target.blur()}
"
                                    className="form-control"
                                    onChange={e => invoiceChangeHandler(index, `invoice-amount`, e.target.value)}
                                    value={invoice?.amount}
                                  />
                                  {isValidationShow && invoice?.amount?.trim() === `` && <Error text="Kindly enter invoice amount" />}
                                </div>
                              </Col>

                              <Col md={2}>
                                <div className="mb-3">
                                  <Label htmlFor="" className="form-label">
                                    Invoice status
                                    <span className="lbl_mandatory"> *</span>
                                  </Label>
                                  <Select
                                    id="invoice-status"
                                    name="invoice-status"
                                    value={invoice?.status}
                                    options={invoiceStatuses ?? []}
                                    onChange={data => invoiceChangeHandler(index, `invoice-status`, data)}
                                  />
                                  {isValidationShow && invoice?.status === `` && <Error text="Kindly select status" />}
                                </div>
                              </Col>

                              <Col md={2}>
                                <div className="mb-3">
                                  <Label htmlFor="to" className="form-label">
                                    Invoice Date
                                    <span className="lbl_mandatory"> *</span>
                                    <span className="icon_calendar">
                                      <i className="las la-calendar"></i>
                                    </span>
                                  </Label>
                                  <div className="area_expandable">
                                    <Flatpickr
                                      className="form-control"
                                      name="to"
                                      id="to"
                                      value={invoice?.date}
                                      onChange={date => invoiceChangeHandler(index, `invoice-date`, date)}
                                      options={{
                                        enableTime: false,
                                        dateFormat: 'd M, Y',
                                        // maxDate: "today",
                                      }}
                                    />
                                    {isValidationShow && invoice?.date?.trim() === `` && <Error text="Kindly select date" />}
                                  </div>
                                </div>
                              </Col>

                              <Col md={2}>
                                <div className="mb-3">
                                  <Label htmlFor="validityTatValue" className="form-label">
                                    &nbsp;
                                  </Label>

                                  <div className="d-flex justify-content-around">
                                    <button type="submit" className="btn btn-primary" onClick={e => addMoreInvoice(e, index)}>
                                      <i className=" ri-add-line"></i>
                                    </button>

                                    <button
                                      type="submit"
                                      className="btn btn-danger"
                                      onClick={e => removeInvoice(e, index)}
                                      disabled={state?.invoices?.length === 1}
                                    >
                                      <i className="ri-delete-bin-5-line"></i>
                                    </button>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          ))}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>

            <CardFooter>
              <Row>
                <Col md={12}>
                  <div className="text-center">
                    <button type="submit" className="btn btn-primary mx-3 overlay-disable" onClick={submitHandler}>
                      {`${data ? 'Update' : 'Create'}`}
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>

            <div
              style={{
                width: '100px',
                position: 'relative',
                zIndex: '999999999999',
              }}
            >
              <ReactTooltip />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ProgramConfiguration;
