import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { Constant } from '../../../../../Containers/Helpers/constant';
import apiCall from '../../../apiCall';

import { baseUrl, customHeaders, uaeProjectsBaseURL } from './../../../../../Containers/Config/index';
import { GET_PARENT_CATEGORY, GET_PARENT_CATEGORY_SUCCESS, GET_PARENT_CATEGORY_ERROR } from './../../../actionType';

function* getParentCategory({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/admin/categories?parent=true`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_PARENT_CATEGORY_SUCCESS,
        payload: response?.data?.results,
      });
    } else {
      yield put({
        type: GET_PARENT_CATEGORY_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_PARENT_CATEGORY_ERROR, payload: error });
  }
}

export function* watchGetParentCategorySaga() {
  yield takeEvery(GET_PARENT_CATEGORY, getParentCategory);
}

function* getParentCategorySaga() {
  yield all([fork(watchGetParentCategorySaga)]);
}

export default getParentCategorySaga;
