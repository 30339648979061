import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';

import { Card, CardBody, Col, Form, Label, Row, Input, CardFooter, Button, CardHeader } from 'reactstrap';
import { BreadCrumb, Loader, PreviewCardHeader } from '../../../../../../Common';

import { ReactSortable } from 'react-sortablejs';
import { GET_PAYMENT_GATEWAYS, LIST_BINS, LIST_BINS_EMPTY, UPDATE_BINS_EMPTY } from '../../../../../../../store/application/actionType';
import CreateBinModal from '../Modals/CreateBin';
import BinsTable from '../Tables/BinsTable';
import UpdateBinModal from '../Modals/UpdateBin';
import { Constant } from '../../../../../../Helpers/constant';

const brandingTypes = [
  { value: 'standard', label: 'Standard' },
  { value: 'project', label: 'Project only' },
];

const PaymentSetting = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    configId: '',
    projectCode: '',
    bookingTypes: [],
    payment: {
      gateways: [],
      secretKey: '',
      binDirectory: {
        isActive: false,
        allowInRequest: '',
      },
      cardDirectory: {
        isActive: false,
        allowInRequest: '',
        cardsLimit: '',
        duplicacyRestriction: false,
        duplicacyLevel: '',
        tokenization: false,
        tokenizationVendor: '',
        registerViaBinLocking: false,
        cardInfoLevel: '',
      },
      brandingType: '',
      paymentViaCard: false,
      paymentViaNonCard: false,
      paymentLinkFunctionality: false,
      cardDuplicationCheck: false,
      cardDuplicationLevel: '',
      gatewaySelection: '',
    },
  };
  const [state, setState] = useState(initialState);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);
  const [isBinsListShow, setIsBinsListShow] = useState(false);

  const [isShowUpdateBinModal, setIsShowUpdateBinModal] = useState(false);
  const [currentSelectedDetails, setCurrentSelectedDetails] = useState([]);
  const [currentConfirmationModalActionFor, setCurrentConfirmationModalActionFor] = useState(null);

  const [allPaymentGatways, setAllPaymentGateways] = useState(null);
  const [allProjects, setAllProjects] = useState(null);

  const [paymentPriorityList, setPaymentPriorityList] = useState([]);

  const [isShowCreateBinModal, setIsShowCreateBinModal] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Payment Gateways
  const { paymentGateways } = useSelector(state => ({
    paymentGateways: state?.GetPaymentGateways?.data,
  }));

  // Bins List
  const { binsList } = useSelector(state => ({
    binsList: state?.BinsList?.data,
  }));

  // Update Bin
  const { updateBins } = useSelector(state => ({
    updateBins: state?.UpdateBins?.data,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */

  useEffect(() => {
    if (updateBins !== null) {
      dispatch({
        type: UPDATE_BINS_EMPTY,
      });

      dispatch({
        type: LIST_BINS,
        payload: {
          urlParam: {
            projectCode: `${props?.data?.code}`,
            pageIndex: currentPageIndex,
          },
        },
      });
    }
  }, [updateBins]);

  useEffect(() => {
    if (props?.data !== null) {
      setState({
        ...state,
        configId: props?.data?.configId,
        projectCode: props?.data?.code,
        payment: {
          ...props?.data?.configuration.payment,

          brandingType: brandingTypes?.filter(type => type.value === props?.data?.configuration?.payment?.brandingType)?.[0],

          gateways: props?.data?.configuration?.payment?.gateways?.map(gateway => {
            return {
              value: `${gateway?.pgData?.objectId}`,
              label: `${gateway?.pgData?.name}`,
              code: `${gateway?.pgData?.code}`,
              status: `${gateway?.status}`,
              logo: `${gateway?.pgData?.logo}`,
            };
          }),

          selectedPaymentGateways:
            props?.data?.configuration?.payment?.gateways?.map(gateway => {
              return {
                value: `${gateway?.pgData?.objectId}`,
                label: `${gateway?.pgData?.name}`,
                code: `${gateway?.pgData?.code}`,
                status: `${gateway?.status}`,
                logo: `${gateway?.pgData?.logo}`,
              };
            }) ?? [],
        },
      });

      setPaymentPriorityList(
        props?.data?.configuration?.payment?.gateways?.map(gateway => {
          return {
            value: `${gateway?.pgData?.objectId}`,
            label: `${gateway?.pgData?.name}`,
            code: `${gateway?.pgData?.code}`,
            status: `${gateway?.status}`,
            logo: `${gateway?.pgData?.logo}`,
          };
        }) ?? [],
      );
    }
  }, []);

  useEffect(() => {
    if (paymentGateways === null) {
      dispatch({
        type: GET_PAYMENT_GATEWAYS,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (paymentGateways !== null) {
      setAllPaymentGateways(
        paymentGateways?.results?.map(gateway => {
          return {
            value: `${gateway?.objectId}`,
            label: `${gateway?.name}`,
            code: `${gateway?.code}`,
            status: `active`,
            logo: `${gateway?.logo}`,
          };
        }),
      );
    }
  }, [paymentGateways]);

  useEffect(() => {
    if (binsList !== null) {
      setIsBinsListShow(true);
    }
  }, [binsList]);

  /* ---------------------------- REDUX STATES ---------------------------- */

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'binDirectoryStatus':
        updatedState = {
          ...state,
          payment: {
            ...state.payment,
            binDirectory: {
              ...state?.payment?.binDirectory,
              isActive: !state?.payment?.binDirectory?.isActive,
              // allowInRequest:
              //   !state?.payment?.binDirectory?.isActive === false
              //     ? ``
              //     : state?.payment?.binDirectory?.allowInRequest,
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'binDirectoryAllowInRequest':
        updatedState = {
          ...state,
          payment: {
            ...state.payment,
            binDirectory: {
              ...state.payment.binDirectory,
              allowInRequest: value,
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'cardDirectoryStatus':
        updatedState = {
          ...state,
          payment: {
            ...state.payment,

            cardDuplicationLevel: !state?.payment?.cardDirectory?.isActive === false ? '' : state?.payment?.cardDuplicationLevel,

            cardDirectory: {
              ...state.payment.cardDirectory,
              isActive: !state.payment.cardDirectory.isActive,

              cardsLimit: !state?.payment?.cardDirectory?.isActive === false ? `` : state?.payment?.cardDirectory?.cardsLimit,
              duplicacyRestriction: !state?.payment?.cardDirectory?.isActive === false ? false : state?.payment?.cardDirectory?.duplicacyRestriction,
              tokenization: !state?.payment?.cardDirectory?.isActive === false ? false : state?.payment?.cardDirectory?.tokenization,
              tokenizationVendor: !state?.payment?.cardDirectory?.isActive === false ? `` : state?.payment?.cardDirectory?.tokenizationVendor,
              duplicacyLevel: !state?.payment?.cardDirectory?.isActive === false ? `` : state?.payment?.cardDirectory?.duplicacyLevel,
              cardInfoLevel: !state?.payment?.cardDirectory?.isActive === false ? `` : state?.payment?.cardDirectory?.cardInfoLevel,
              registerViaBinLocking:
                !state?.payment?.cardDirectory?.isActive === false ? false : state?.payment?.cardDirectory?.registerViaBinLocking,
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'cardDirectoryAllowInRequest':
        updatedState = {
          ...state,
          payment: {
            ...state.payment,
            cardDirectory: {
              ...state.payment.cardDirectory,
              allowInRequest: value,
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'cardDirectoryCardsLimit':
        updatedState = {
          ...state,
          payment: {
            ...state.payment,
            cardDirectory: {
              ...state.payment.cardDirectory,
              cardsLimit: value,
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'duplicacyLevel':
        updatedState = {
          ...state,
          payment: {
            ...state.payment,
            cardDirectory: {
              ...state.payment.cardDirectory,
              duplicacyLevel: value,
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'cardDirectoryTokenization':
        updatedState = {
          ...state,
          payment: {
            ...state.payment,
            cardDirectory: {
              ...state.payment.cardDirectory,
              tokenization: !state?.payment.cardDirectory?.tokenization,
              tokenizationVendor: !state?.payment.cardDirectory?.tokenization === false ? `` : state?.payment.cardDirectory?.tokenizationVendor,
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'cardDirectoryTokenizationVendor':
        updatedState = {
          ...state,
          payment: {
            ...state.payment,
            cardDirectory: {
              ...state.payment.cardDirectory,
              tokenizationVendor: value,
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'cardDirectoryduplicacyRestriction':
        updatedState = {
          ...state,
          payment: {
            ...state.payment,
            cardDirectory: {
              ...state.payment.cardDirectory,
              duplicacyRestriction: !state?.payment.cardDirectory?.duplicacyRestriction,
              duplicacyLevel: !state?.payment.cardDirectory?.duplicacyRestriction === false ? `` : state?.payment?.cardDirectory?.duplicacyLevel,
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'paymentViaCard':
        updatedState = {
          ...state,
          payment: {
            ...state.payment,
            paymentViaCard: !state?.payment.paymentViaCard,
            binDirectory: {
              isActive: !state?.payment.paymentViaCard ? state?.payment?.binDirectory?.isActive : false,
              allowInRequest: !state?.payment.paymentViaCard ? state?.payment?.allowInRequest?.isActive : '',
            },

            cardDuplicationCheck: !state?.payment.paymentViaCard === false ? '' : state?.payment?.cardDuplicationCheck,
            cardDuplicationLevel: !state?.payment.paymentViaCard === false ? '' : state?.payment?.cardDuplicationLevel,

            cardDirectory: {
              ...state.payment.cardDirectory,

              isActive: !state?.payment.paymentViaCard === false ? false : state?.payment?.cardDirectory?.isActive,

              allowInRequest: !state?.payment.paymentViaCard === false ? `` : state?.payment?.cardDirectory?.allowInRequest,
              cardsLimit: !state?.payment.paymentViaCard === false ? `` : state?.payment?.cardDirectory?.cardsLimit,
              duplicacyRestriction: !state?.payment.paymentViaCard === false ? false : state?.payment?.cardDirectory?.duplicacyRestriction,
              tokenization: !state?.payment.paymentViaCard === false ? false : state?.payment?.cardDirectory?.tokenization,
              tokenizationVendor: !state?.payment.paymentViaCard === false ? `` : state?.payment?.cardDirectory?.tokenizationVendor,
              duplicacyLevel: !state?.payment.paymentViaCard === false ? `` : state?.payment?.cardDirectory?.duplicacyLevel,
              cardInfoLevel: !state?.payment.paymentViaCard === false ? `` : state?.payment?.cardDirectory?.cardInfoLevel,
              registerViaBinLocking: !state?.payment.paymentViaCard === false ? false : state?.payment?.cardDirectory?.registerViaBinLocking,
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'paymentViaNonCard':
        updatedState = {
          ...state,
          payment: {
            ...state.payment,
            paymentViaNonCard: !state?.payment.paymentViaNonCard,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'paymentLinkFunctionality':
        updatedState = {
          ...state,
          payment: {
            ...state.payment,
            paymentLinkFunctionality: !state?.payment.paymentLinkFunctionality,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'registerViaBinLocking':
        updatedState = {
          ...state,
          payment: {
            ...state.payment,
            cardDirectory: {
              ...state.payment.cardDirectory,
              registerViaBinLocking: !state?.payment.cardDirectory.registerViaBinLocking,
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'cardInfoLevel':
        updatedState = {
          ...state,
          payment: {
            ...state.payment,
            cardDirectory: {
              ...state.payment.cardDirectory,
              cardInfoLevel: value,
            },
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'cardDuplicationCheck':
        updatedState = {
          ...state,
          payment: {
            ...state.payment,
            cardDuplicationCheck: !state?.payment?.cardDuplicationCheck,
            cardDuplicationLevel: !state?.payment?.cardDuplicationCheck ? state?.payment?.cardDuplicationLevel : ``,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'cardDuplicationLevel':
        updatedState = {
          ...state,
          payment: {
            ...state.payment,
            cardDuplicationLevel: value,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'gatewaySelection':
        updatedState = {
          ...state,
          payment: {
            ...state.payment,
            gatewaySelection: value,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  const inputSelectHandler = (label, data) => {
    let updatedState = null;

    if (label === `configId`) {
      updatedState = {
        ...state,
        configId: data,
      };
      setState(updatedState);
      validationCheck(updatedState);
    } else if (label === `selectedPaymentGateways`) {
      updatedState = {
        ...state,
        payment: {
          ...state.payment,
          selectedPaymentGateways: data,
        },
      };
      setState(updatedState);
      validationCheck(updatedState);
      setPaymentPriorityList(data);
    } else if (label === `brandingType`) {
      updatedState = {
        ...state,
        payment: {
          ...state.payment,
          brandingType: data,
        },
      };
      setState(updatedState);
      validationCheck(updatedState);
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const payload = {
      ...state,
      payment: {
        ...state.payment,
        brandingType: state.payment.brandingType === '' ? '' : state.payment.brandingType.value,
        gateways: paymentPriorityList?.map((paymentPriority, index) => {
          return {
            priority: index,
            status: paymentPriority?.status,
            id: paymentPriority?.value,
          };
        }),
      },
    };

    props.onSubmit(payload);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data.benefitGroupId !== ``) {
      setIsSubmitBtnDisabled(false);
    } else {
      setIsSubmitBtnDisabled(true);
    }
  };

  /* ---------------------------- CHANGED PAYMENT STATUS HANDLER ---------------------------- */
  const changePaymentPriorityStatusHandler = (event, paymentStatusIndex, payment) => {
    const tempPaymentPriorityList = [...paymentPriorityList];

    let changedPaymentStatusData = tempPaymentPriorityList[paymentStatusIndex];
    changedPaymentStatusData = {
      ...changedPaymentStatusData,
      status: event.target.value,
    };

    tempPaymentPriorityList.splice(paymentStatusIndex, 1, changedPaymentStatusData);
    setPaymentPriorityList(tempPaymentPriorityList);
  };

  /* ---------------------------- CREATE BINS HANDLER ---------------------------- */
  const createBinsHandler = () => {
    setIsShowCreateBinModal(true);
  };

  /* ---------------------------- LIST BINS HANDLER ---------------------------- */
  const listBinsHandler = () => {
    dispatch({
      type: LIST_BINS,
      payload: {
        urlParam: {
          pageIndex: currentPageIndex,
          projectCode: `${props?.data?.code}`,
        },
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedDetails(params?.details);
    setCurrentConfirmationModalActionFor(params?.actionType);

    if (params?.actionType === `UPDATE_BIN`) {
      setIsShowUpdateBinModal(true);
      // logic
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber + 1);
    setCurrentPageIndex(pageNumber + 1);

    dispatch({
      type: LIST_BINS,
      payload: {
        urlParam: {
          projectCode: `${props?.data?.code}`,
          pageIndex: pageNumber + 1,
        },
      },
    });
  };

  /* ---------------------------- BREADCRUM HANDLER  ---------------------------- */
  const breadcrumNavHandler = title => {
    if (title === `Payments Management`) {
      props.breadcrumHandler(title);
    } else if (title === `Payment Update`) {
      setIsBinsListShow(false);
      dispatch({
        type: LIST_BINS_EMPTY,
      });
    } else if (title === `Bins List`) {
      setIsBinsListShow(true);
    }
  };

  return (
    <>
      {/* <button onClick={() => console.log(state)}>Get state</button> */}

      {/*  ---------------------------- MODAL'S  ---------------------------- */}
      {isShowCreateBinModal && (
        <CreateBinModal
          data={{
            allpaymentGateways: [...allPaymentGatways],
            selectedPaymentGateways: state?.payment?.selectedPaymentGateways,
            selectedProject: { ...props?.data },
          }}
          onCloseHandler={() => setIsShowCreateBinModal(false)}
        />
      )}

      {isShowUpdateBinModal && (
        <UpdateBinModal data={currentSelectedDetails} selectedProject={props?.data} onCloseHandler={() => setIsShowUpdateBinModal(false)} />
      )}

      {/*  ---------------------------- UI'S  ---------------------------- */}

      {isBinsListShow && (
        <>
          <BreadCrumb
            title="Payments"
            navigationby={breadcrumNavHandler}
            navigation={[`Dashboard`, `Payments Management`, `Payment Update`, `Bins List`]}
          />

          <BinsTable
            data={binsList?.results}
            totalData={binsList?.count}
            pageNumber={currentPageNumber - 1}
            dataStartedFrom={(currentPageIndex - 1) * 10}
            pageHandler={paginationHandler}
            actionHandler={tableActionHandler}
            selectedProject={props?.data}
          />
        </>
      )}

      {!isBinsListShow && (
        <>
          <BreadCrumb title="Payments" navigationby={breadcrumNavHandler} navigation={[`Dashboard`, `Payments Management`, `Payment Update`]} />

          <Row>
            <Col lg={12}>
              <Card className="overflow-hidden shadow-none">
                <CardBody className="bg-soft-success text-success fw-semibold d-flex">
                  <div className="marquee">
                    <div>
                      <span>Any changes made to these settings - need to be saved in order to reflect them.</span>
                      <span>Any changes made to these settings - need to be saved in order to reflect them.</span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xxl={12}>
              <Card>
                <CardHeader className="d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    <span className="fw-light">Selected Project - </span>
                    {`${props?.data?.name}`}
                  </h4>
                </CardHeader>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xxl={12}>
              <Card>
                <PreviewCardHeader title="Functional Settings" />
                <CardBody>
                  <div className="live-preview">
                    <Form>
                      <Row>
                        <Col md={4}>
                          <div
                            style={{
                              marginBottom: '10px',
                            }}
                          >
                            <div className="form-switch d-inline-block ">
                              <Input
                                className="form-check-input code-switcher"
                                type="checkbox"
                                id="paymentViaCard"
                                name="paymentViaCard"
                                checked={state?.payment?.paymentViaCard}
                                onChange={inputChangeHandler}
                              />
                            </div>
                            <Label className="form-label">Allow payment via Card functionality &nbsp;</Label>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div
                            style={{
                              marginBottom: '10px',
                            }}
                          >
                            <div className="form-switch d-inline-block ">
                              <Input
                                className="form-check-input code-switcher"
                                type="checkbox"
                                id="paymentViaNonCard"
                                name="paymentViaNonCard"
                                checked={state?.payment?.paymentViaNonCard}
                                onChange={inputChangeHandler}
                              />
                            </div>
                            <Label className="form-label">Allow payment via Non-Card functionality &nbsp;</Label>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div
                            style={{
                              marginBottom: '10px',
                            }}
                          >
                            <div className="form-switch d-inline-block ">
                              <Input
                                className="form-check-input code-switcher"
                                type="checkbox"
                                id="paymentLinkFunctionality"
                                name="paymentLinkFunctionality"
                                checked={state?.payment?.paymentLinkFunctionality}
                                onChange={inputChangeHandler}
                              />
                            </div>
                            <Label className="form-label">Allow Payment Links functionality &nbsp;</Label>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={4}>
                          <div className="mb-3">
                            <Label htmlFor="" className="form-label">
                              Branding type
                              <span className="lbl_mandatory"> *</span>
                            </Label>
                            <Select
                              id="brandingType"
                              name="brandingType"
                              value={state?.payment?.brandingType}
                              options={brandingTypes}
                              onChange={data => inputSelectHandler(`brandingType`, data)}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xxl={12}>
              <Card>
                <PreviewCardHeader
                  title="Bin Directory Settings"
                  tooltip={{
                    isShow: true,
                    id: 'bin_directory_settings',
                    content: `It will enable when allow payment via Card functionality`,
                  }}
                  primaryButton={state?.payment?.binDirectory?.isActive}
                  primaryButtonText={`View Bins`}
                  primaryClickHandler={listBinsHandler}
                  secondaryButton={state?.payment?.binDirectory?.isActive}
                  secondaryButtonText={`Create Bins`}
                  secondaryButtonHandler={createBinsHandler}
                />
                <CardBody>
                  <div className="live-preview">
                    <Form>
                      <Row>
                        <Col md={12}>
                          <div
                            style={{
                              marginBottom: '10px',
                            }}
                          >
                            <div className="form-switch d-inline-block ">
                              <Input
                                className="form-check-input code-switcher"
                                type="checkbox"
                                id="binDirectoryStatus"
                                name="binDirectoryStatus"
                                checked={state?.payment?.binDirectory?.isActive}
                                onChange={inputChangeHandler}
                                disabled={!state?.payment?.paymentViaCard}
                              />
                            </div>
                            <Label className="form-label">Allow BIN Directory & Locking functionality &nbsp;</Label>
                          </div>
                        </Col>
                        <hr style={{ backgroundColor: '#9ea0a1' }} />
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xxl={12}>
              <Card>
                <PreviewCardHeader
                  title="Card Directory Settings"
                  tooltip={{
                    isShow: true,
                    id: 'card_directory_settings',
                    content: `It will enable when allow payment via Card functionality`,
                  }}
                />
                <CardBody>
                  <div className="live-preview">
                    <Row>
                      <Col md={12}>
                        <div
                          style={{
                            marginBottom: '10px',
                          }}
                        >
                          <div className="form-switch d-inline-block ">
                            <Input
                              className="form-check-input code-switcher"
                              type="checkbox"
                              id="cardDirectoryStatus"
                              name="cardDirectoryStatus"
                              checked={state?.payment?.cardDirectory?.isActive}
                              onChange={inputChangeHandler}
                              disabled={!state?.payment?.paymentViaCard}
                            />
                          </div>
                          <Label className="form-label">Allow Card Directory functionality &nbsp;</Label>
                        </div>
                      </Col>
                      <hr style={{ backgroundColor: '#9ea0a1' }} />
                    </Row>

                    <Row>
                      <Col md={4}>
                        <Row>
                          <Col md={12}>
                            <div
                              style={{
                                marginBottom: '10px',
                              }}
                            >
                              <div className="form-switch d-inline-block ">
                                <Input
                                  className="form-check-input code-switcher"
                                  type="checkbox"
                                  id="cardDirectoryduplicacyRestriction"
                                  name="cardDirectoryduplicacyRestriction"
                                  checked={state?.payment?.cardDirectory?.duplicacyRestriction}
                                  onChange={inputChangeHandler}
                                  disabled={!state?.payment?.cardDirectory?.isActive}
                                />
                              </div>
                              <Label className="form-label">Card Duplicacy Restriction &nbsp;</Label>
                            </div>
                          </Col>

                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Duplicacy Level
                                <span className="lbl_mandatory"> *</span>
                              </Label>
                              <select
                                id="duplicacyLevel"
                                name="duplicacyLevel"
                                className="form-select"
                                onChange={inputChangeHandler}
                                value={state?.payment?.cardDirectory?.duplicacyLevel}
                                disabled={!state?.payment?.cardDirectory?.isActive || !state?.payment?.cardDirectory?.duplicacyRestriction}
                              >
                                <option value="">Choose...</option>
                                <option value="user">Unique per User</option>
                                <option value="project">Unique among all users</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col md={4}>
                        <Row>
                          <Col md={12}>
                            <div
                              style={{
                                marginBottom: '10px',
                              }}
                            >
                              <div className="form-switch d-inline-block ">
                                <Input
                                  className="form-check-input code-switcher"
                                  type="checkbox"
                                  id="cardDirectoryTokenization"
                                  name="cardDirectoryTokenization"
                                  checked={state?.payment?.cardDirectory?.tokenization}
                                  onChange={inputChangeHandler}
                                  disabled={!state?.payment?.cardDirectory?.isActive}
                                />
                              </div>
                              <Label className="form-label">Tokenization &nbsp;</Label>
                            </div>
                          </Col>

                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Tokenization Vendor
                                <span className="lbl_mandatory"> *</span>
                              </Label>
                              <select
                                id="cardDirectoryTokenizationVendor"
                                name="cardDirectoryTokenizationVendor"
                                className="form-select"
                                onChange={inputChangeHandler}
                                value={state?.payment?.cardDirectory?.tokenizationVendor}
                                disabled={!state?.payment?.cardDirectory?.isActive || !state?.payment?.cardDirectory?.tokenization}
                              >
                                <option value="">Choose...</option>
                                {allPaymentGatways?.map((payment, index) => {
                                  return (
                                    <option key={index} value={payment?.code}>
                                      {payment?.label}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={4}>
                        <div
                          style={{
                            marginBottom: '10px',
                          }}
                        >
                          <div className="form-switch d-inline-block ">
                            <Input
                              className="form-check-input code-switcher"
                              type="checkbox"
                              id="registerViaBinLocking"
                              name="registerViaBinLocking"
                              onChange={inputChangeHandler}
                              checked={state?.payment?.cardDirectory?.registerViaBinLocking}
                              disabled={!state?.payment?.cardDirectory?.isActive}
                            />
                          </div>
                          <Label className="form-label">Cards registration Bin Locking &nbsp;</Label>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Cards Limit per User
                            <span className="lbl_mandatory"> *</span>
                          </Label>
                          <select
                            id="cardDirectoryCardsLimit"
                            name="cardDirectoryCardsLimit"
                            className="form-select"
                            onChange={inputChangeHandler}
                            value={state?.payment?.cardDirectory?.cardsLimit}
                            disabled={!state?.payment?.cardDirectory?.isActive}
                          >
                            <option value="">Choose...</option>
                            <option value="not_applicable">Not Applicable</option>
                            <option value="one">One</option>
                            <option value="unlimited">Unlimited</option>
                          </select>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Card Information Level
                            <span className="lbl_mandatory"> *</span>
                          </Label>
                          <select
                            id="cardInfoLevel"
                            name="cardInfoLevel"
                            className="form-select"
                            onChange={inputChangeHandler}
                            value={state?.payment?.cardDirectory?.cardInfoLevel}
                            disabled={!state?.payment?.cardDirectory?.isActive}
                          >
                            <option value="">Choose...</option>
                            <option value="not_applicable">Not Applicable</option>
                            <option value="full">Full</option>
                            <option value="six_four">6+4</option>
                            <option value="eight_four">8+4</option>
                            <option value="ten_four">10+4</option>
                            <option value="six_eight_four">(6/8) +4</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xxl={12}>
              <Card>
                <PreviewCardHeader title="Card Payment Settings" />
                <CardBody>
                  <div className="live-preview">
                    <Row></Row>

                    <Row>
                      <Col md={12}>
                        <div
                          style={{
                            marginBottom: '10px',
                          }}
                        >
                          <div className="form-switch d-inline-block ">
                            <Input
                              className="form-check-input code-switcher"
                              type="checkbox"
                              id="cardDuplicationCheck"
                              name="cardDuplicationCheck"
                              checked={state?.payment?.cardDuplicationCheck}
                              onChange={inputChangeHandler}
                              disabled={!state?.payment?.paymentViaCard}
                            />
                          </div>
                          <Label className="form-label">Card duplication additional check validation &nbsp;</Label>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Card duplication additional check level
                            <span className="lbl_mandatory"> *</span>
                          </Label>
                          <select
                            id="cardDuplicationLevel"
                            name="cardDuplicationLevel"
                            className="form-select"
                            onChange={inputChangeHandler}
                            value={state?.payment?.cardDuplicationLevel}
                            disabled={!state?.payment?.cardDuplicationCheck}
                          >
                            <option value="">Choose...</option>
                            <option value="not_applicable">Not Applicable</option>
                            <option value="card_directory">As per Card Directory Settings</option>
                            <option value="full">Full</option>
                            <option value="six_four">6+4</option>
                            <option value="eight_four">8+4</option>
                            <option value="ten_four">10+4</option>
                          </select>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Allow BINs in Request
                            <span className="lbl_mandatory"> *</span>
                          </Label>
                          <select
                            id="binDirectoryAllowInRequest"
                            name="binDirectoryAllowInRequest"
                            className="form-select"
                            onChange={inputChangeHandler}
                            value={state?.payment?.binDirectory?.allowInRequest}
                            // disabled={!state?.payment?.binDirectory?.isActive}
                            disabled={!state?.payment?.paymentViaCard}
                          >
                            <option value="">Choose...</option>
                            <option value="required">Required</option>
                            <option value="non_mandatory">Non-mandatory</option>
                            <option value="not_required">Not-required</option>
                          </select>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Allow Cards in Request
                            <span className="lbl_mandatory"> *</span>
                          </Label>

                          <select
                            id="cardDirectoryAllowInRequest"
                            name="cardDirectoryAllowInRequest"
                            className="form-select"
                            onChange={inputChangeHandler}
                            value={state?.payment?.cardDirectory?.allowInRequest}
                            disabled={!state?.payment?.paymentViaCard}
                          >
                            <option value="">Choose...</option>
                            <option value="non_mandatory">Not mandatory</option>
                            <option value="not_applicable">Not applicable</option>
                            <option value="required">Required</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xxl={12}>
              <Card>
                <PreviewCardHeader title="Payment Settings" />
                <CardBody>
                  <div className="live-preview">
                    <Form>
                      <Row>
                        <Col md={4}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Gateway selection criteria
                              <span className="lbl_mandatory"> *</span>
                            </Label>
                            <select
                              id="gatewaySelection"
                              name="gatewaySelection"
                              className="form-select"
                              onChange={inputChangeHandler}
                              value={state?.payment?.gatewaySelection}
                            >
                              <option value="">Choose...</option>
                              <option value="client_side">Client Side</option>
                              <option value="server_side">Server Side - BIN + Priority wise</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <div className="mb-3">
                            <Label htmlFor="" className="form-label">
                              Payment Gateways
                              <span className="lbl_mandatory"> *</span>
                            </Label>
                            <Select
                              id="payment-gateways"
                              name="payment-gateways"
                              value={state?.payment?.selectedPaymentGateways}
                              options={allPaymentGatways}
                              isMulti={true}
                              onChange={data => inputSelectHandler(`selectedPaymentGateways`, data)}
                            />
                          </div>
                        </Col>

                        <Col md={12}>
                          <ReactSortable
                            filter=".addImageButtonContainer"
                            dragClass="sortableDrag"
                            list={paymentPriorityList}
                            setList={setPaymentPriorityList}
                            animation="200"
                            easing="ease-out"
                            className="row"
                            style={{ gap: '10px' }}
                          >
                            {paymentPriorityList?.map((payment, paymentStatusIndex) => (
                              <Col md={2} sm={6} key={paymentStatusIndex} className="draggableItem">
                                <Card className="border">
                                  <CardBody className="text-center">
                                    <div className="avatar-md mx-auto mb-4" id="register-tour">
                                      <div className="avatar-title bg-light rounded-circle text-primary fs-24">
                                        <img
                                          className="d-inline-block w-100 h-100"
                                          style={{
                                            borderRadius: '50%',
                                            objectFit: 'contain',
                                          }}
                                          alt={paymentStatusIndex}
                                          src={payment?.logo}
                                        />
                                      </div>
                                    </div>
                                    <h5>{payment?.label}</h5>
                                    <p className="text-muted mb-0">{payment?.code}</p>

                                    <div className="d-inline-block ">
                                      <select
                                        className="form-select"
                                        onChange={event => changePaymentPriorityStatusHandler(event, paymentStatusIndex, payment)}
                                        value={payment.status}
                                      >
                                        <option value="inactive">In-Active</option>
                                        <option value="active">Active</option>
                                        <option value="onhold">On-Hold</option>
                                      </select>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                            ))}
                          </ReactSortable>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Card>
              <CardFooter>
                <Row>
                  <Col md={12}>
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn btn-primary mx-3 overlay-disable"
                        style={{ cursor: 'pointer' }}
                        onClick={submitHandler}
                        // disabled={!isPermissionChanged}
                      >
                        Update
                      </button>
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export default PaymentSetting;
