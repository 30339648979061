import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, indianProjectsBaseURL } from './../../../../../Containers/Config/index';
import { CREATE_CAMPAIGN, CREATE_CAMPAIGN_SUCCESS, CREATE_CAMPAIGN_ERROR } from './../../../actionType';
import apiCall from '../../../apiCall';
import { Constant } from '../../../../../Containers/Helpers/constant';
import cogoToast from 'cogo-toast';

function* createCampaign({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/admin/campaign`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      // cogoToast.success(`${response?.data?.message}`)
      cogoToast.success(`Campaign created successfully!`);
      yield put({
        type: CREATE_CAMPAIGN_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      // yield put({
      //     type: CREATE_CAMPAIGN_ERROR,
      //     payload: response?.data?.message,
      // })
    }
  } catch (error) {
    yield put({ type: CREATE_CAMPAIGN_ERROR, payload: error });
  }
}

export function* watchCreateCampaignSaga() {
  yield takeEvery(CREATE_CAMPAIGN, createCampaign);
}

function* createCampaignSaga() {
  yield all([fork(watchCreateCampaignSaga)]);
}

export default createCampaignSaga;
