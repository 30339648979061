import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import fetch from './../../fetch';
import { masterCardBaseURL, customHeaders } from '../../../../Containers/Config/index.js';
import { GET_GOLF_COURSES, GET_GOLF_COURSES_SUCCESS, GET_GOLF_COURSES_ERROR } from './../../actionType.js';

function* getGolfCoursesList({ payload }) {
  try {
    const response = yield call(fetch, {
      url: `${masterCardBaseURL}/parse/classes/golfcourses/`,
      method: 'GET',
      headers: { ...customHeaders },
    });

    if (response?.error === undefined) {
      yield put({ type: GET_GOLF_COURSES_SUCCESS, payload: response });
    } else {
      yield put({ type: GET_GOLF_COURSES_ERROR, payload: response.error });
    }
  } catch (error) {
    yield put({ type: GET_GOLF_COURSES_ERROR, payload: error });
  }
}

export function* watchGetGolfBookingList() {
  yield takeEvery(GET_GOLF_COURSES, getGolfCoursesList);
}

function* allGolfCoursesSaga() {
  yield all([fork(watchGetGolfBookingList)]);
}

export default allGolfCoursesSaga;
