import { useEffect, useState } from 'react';

import moment from 'moment';
import DataTable from 'react-data-table-component';
import { Badge, Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';

import { TablePagination } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import { formattedWithComma, hasPermission } from '../../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';

const Table = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColums = [
        {
          name: 'S.No',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
          width: '70px',
          wrap: true,
        },
        {
          name: 'Code',
          selector: row => row?.code,
          width: '200px',
          wrap: true,
        },
        {
          name: 'Max Usage',
          selector: row => row?.maxUsage,
          wrap: true,
          width: '120px',
        },
        {
          name: 'Current Usage',
          selector: row => row?.currentUsage,
          width: '140px',
        },
        {
          name: 'Remaining Usage',
          selector: row => row?.remainingUsage,
          width: '150px',
        },
        {
          name: 'Created At',
          selector: row => row?.createdAt,
          width: '200px',
        },
        {
          name: 'Expiry At',
          selector: row => row?.expiryDate,
          width: '200px',
        },
        {
          name: 'Status',
          cell: row => addBadgeOnStatus(row?.isActive),
          width: '160px',
        },
        {
          name: 'Actions',
          width: '200px',
          cell: row => (
            <div>
              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.STATIC_COUPON) && (
                <span
                  className="btn btn-sm btn-primary mb-1"
                  onClick={() => {
                    actionBtnHandler(`UPDATE`, row?.data);
                  }}
                  style={{ width: '150px' }}
                >
                  Update
                </span>
              )}
            </div>
          ),
        },
      ];
      setColumns(fetchColums);
      setData(
        props?.data?.map((data, index) => {
          return {
            serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            code: data?.code,
            maxUsage: data?.maxUsage,
            currentUsage: data?.currentUsage,
            remainingUsage: data?.remainingUsage,
            createdAt: data?.createdAt ? moment(`${data?.createdAt}`).format('DD-MM-YYYY, h:mm A') : `NA`,
            expiryDate: data?.expiryDate ? moment(`${data?.expiryDate}`).format('DD-MM-YYYY, h:mm A') : `NA`,
            isActive: data?.isActive || `NA`,
            data: data || 'NA',
          };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `UPDATE`) {
      props.actionHandler({
        couponDetails: data,
        actionType: flag,
      });
    }
  };

  /* ---------------------------- STATUS FLAG ---------------------------- */
  const addBadgeOnStatus = status => {
    switch (status) {
      case true:
        return <Badge color="success"> Active </Badge>;

      case false:
        return <Badge color="danger"> In-Active </Badge>;

      default:
        return <Badge color="dark"> N/A </Badge>;
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">Coupon Data</h4>

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.COUPON_UPLOAD) && (
                <span className="btn btn-primary mb-1 mx-1" onClick={props?.uploadCoupon}>
                  <i className="ri-upload-cloud-2-line me-1 text-white fs-14 align-middle"></i>Upload Coupon
                </span>
              )}

              {props?.data?.length > 0 && (
                <span className="btn btn-success mb-1 mx-1" onClick={props?.refreshHandler}>
                  <i className="ri-refresh-line me-1 text-white fs-14 align-middle"></i>Refresh
                </span>
              )}
            </CardHeader>

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
              {props.children}
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
