import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from './../../../../Common';
import { Container } from 'reactstrap';
import Table from './Components/Tables/Table';
import { GET_CATEGORY, GET_LOCATION, GET_PARTNER, UPDATE_PARTNER_EMPTY } from '../../../../../store/application/actionType';
import { Constant } from '../../../../Helpers/constant';
import Details from './Components/Details/Details';
import DetailsInfo from './Components/Details/DetailsInfo';
import UpdateCategoryModal from './Components/Modals/UpdateModal';
import AddPartnerForm from '../AddPartner/Components/Forms/Form';
import Filter from './Components/Filters/Filter';
import CustomFilter from './Components/Filters/CustomFilter';
import LogsModal from './Components/Modals/LogsModal';
import moment from 'moment';

const ManagePartner = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [isShowFilters, setIsShowFilters] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);

  const [currentSelectedDetails, setCurrentSelectedDetails] = useState([]);
  const [currentConfirmationModalActionFor, setCurrentConfirmationModalActionFor] = useState(null);
  const [isDetailsPage, setIsDetailsPage] = useState(false);

  const [isUpdateModalShow, setIsUpdateModalShow] = useState(false);

  const [isListShow, setIsListShow] = useState(true);
  const [isDetailsPageShow, setIsDetailsPageShow] = useState(false);
  const [isUpdateFormShow, setIsUpdateFormShow] = useState(false);

  const [isShowLogsModal, setIsShowLogsModal] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Partners
  const { partners, partnersLoading } = useSelector(state => ({
    partners: state?.GetPartners?.data,
    partnersLoading: state?.GetPartners?.loading,
  }));

  // Update Partner
  const { updatePartner, updatePartnerLoading } = useSelector(state => ({
    updatePartner: state?.UpdatePartner?.data,
    updatePartnerLoading: state?.UpdatePartner?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_PARTNER,
      payload: {
        urlParam: {
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });

    dispatch({
      type: GET_CATEGORY,
      payload: {
        urlParam: {
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });

    dispatch({
      type: GET_LOCATION,
      payload: {
        urlParam: {
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (updatePartner !== null) {
      dispatch({
        type: UPDATE_PARTNER_EMPTY,
      });

      setIsListShow(true);
      setIsDetailsPageShow(false);
      setIsUpdateFormShow(false);

      let urlParams = {};
      if (currentlyAppliedFilters) {
        urlParams = {
          name: currentlyAppliedFilters?.name === `` ? null : currentlyAppliedFilters?.name,
          typeId: currentlyAppliedFilters?.typeId === `` ? null : currentlyAppliedFilters?.typeId?.data?.typeId,
          updateDate: currentlyAppliedFilters?.updateDate === `` ? null : moment(new Date(currentlyAppliedFilters?.updateDate)).format('YYYY-MM-DD'),
          categoryId: currentlyAppliedFilters?.categoryId === null ? null : currentlyAppliedFilters?.categoryId?.value,
        };
      }

      dispatch({
        type: GET_PARTNER,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            ...urlParams,
          },
        },
      });
    }
  }, [updatePartner]);

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = filters => {
    setIsShowFilters(false);
    setCurrentlyAppliedFilters(filters);
    setCurrentPageNumber(0);

    const urlParams = {
      name: filters?.name === `` ? null : filters?.name,
      typeId: filters?.typeId === `` ? null : filters?.typeId?.data?.typeId,
      updateDate: filters?.updateDate === `` ? null : moment(new Date(filters?.updateDate)).format('YYYY-MM-DD'),
      categoryId: filters?.categoryId === null ? null : filters?.categoryId?.value,
    };

    dispatch({
      type: GET_PARTNER,
      payload: {
        urlParam: {
          pageIndex: 0,
          ...urlParams,
        },
      },
    });
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);

    let urlParams = {};
    if (currentlyAppliedFilters) {
      urlParams = {
        name: currentlyAppliedFilters?.name === `` ? null : currentlyAppliedFilters?.name,
        typeId: currentlyAppliedFilters?.typeId === `` ? null : currentlyAppliedFilters?.typeId?.data?.typeId,
        updateDate: currentlyAppliedFilters?.updateDate === `` ? null : moment(new Date(currentlyAppliedFilters?.updateDate)).format('YYYY-MM-DD'),
        categoryId: currentlyAppliedFilters?.categoryId === null ? null : currentlyAppliedFilters?.categoryId?.value,
      };
    }

    dispatch({
      type: GET_PARTNER,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          ...urlParams,
        },
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedDetails(params?.details);
    setCurrentConfirmationModalActionFor(params?.actionType);

    if (params?.actionType === `VIEW`) {
      setIsListShow(false);
      setIsDetailsPageShow(true);
      setIsUpdateFormShow(false);
    } else if (params?.actionType === `UPDATE`) {
      setIsListShow(false);
      setIsDetailsPageShow(false);
      setIsUpdateFormShow(true);
    } else if (params?.actionType === `LOGS`) {
      setIsShowLogsModal(true);
    } else if (params?.actionType === `INFO`) {
      setIsListShow(false);
      setIsDetailsPageShow(true);
      setIsUpdateFormShow(false);
    }
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push('/dashboard');
    } else if (title === `Brand`) {
      setIsListShow(true);
      setIsDetailsPageShow(false);
      setIsUpdateFormShow(false);

      let urlParams = {};
      if (currentlyAppliedFilters) {
        urlParams = {
          name: currentlyAppliedFilters?.name === `` ? null : currentlyAppliedFilters?.name,
          typeId: currentlyAppliedFilters?.typeId === `` ? null : currentlyAppliedFilters?.typeId?.data?.typeId,
          updateDate: currentlyAppliedFilters?.updateDate === `` ? null : moment(new Date(currentlyAppliedFilters?.updateDate)).format('YYYY-MM-DD'),
          categoryId: currentlyAppliedFilters?.categoryId === null ? null : currentlyAppliedFilters?.categoryId?.value,
        };
      }

      dispatch({
        type: GET_PARTNER,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            ...urlParams,
          },
        },
      });
    } else if (title === `Details`) {
      // do nothing
    } else if (title === `Update Details`) {
      // do nothing
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (partnersLoading || updatePartnerLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [partnersLoading, updatePartnerLoading]);

  return (
    <>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ----------------------------  MODAL'S ---------------------------- */}
          {isShowLogsModal && <LogsModal data={currentSelectedDetails} onCloseHandler={() => setIsShowLogsModal(false)} />}

          {/*  ----------------------------  UI'S ---------------------------- */}
          {isListShow && (
            <>
              <BreadCrumb title="Brand" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Brand']} />

              {/* <Filter
                filterHandler={filterSubmitHandler}
                appliedFilters={currentlyAppliedFilters}
              /> */}

              <CustomFilter
                filterHandler={filterSubmitHandler}
                appliedFilters={currentlyAppliedFilters}
                show={isShowFilters}
                onCloseClick={() => setIsShowFilters(false)}
              />

              <Table
                title={`Brand's Data`}
                data={partners?.results}
                totalData={partners?.count}
                pageHandler={paginationHandler}
                pageNumber={currentPageNumber}
                actionHandler={tableActionHandler}
                isShowFilterBtn={true}
                filterBtnHandler={() => setIsShowFilters(true)}
              />
            </>
          )}

          {isDetailsPageShow && (
            <>
              <BreadCrumb title="Category" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Brand', 'Details']} />
              <DetailsInfo details={currentSelectedDetails} editActionHandler={tableActionHandler} />
              {/* <Details details={currentSelectedDetails} /> */}
            </>
          )}

          {isUpdateFormShow && (
            <>
              <BreadCrumb title="Brand" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Brand', 'Update Details']} />

              <AddPartnerForm data={currentSelectedDetails} title="Update Brand" />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default ManagePartner;
