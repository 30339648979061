import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Modal, ModalBody, ModalHeader, Col, Label, Input, Row } from 'reactstrap';
import Select from 'react-select';
import { UPDATE_BINS } from '../../../../../../../store/application/actionType';
import { AsyncPaginate } from 'react-select-async-paginate';
import { baseUrl, customHeaders } from '../../../../../../Config';
import { Constant } from '../../../../../../Helpers/constant';
import axios from 'axios';

const UpdateBinModal = ({ data, selectedProject, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    number: '',
    name: '',
    description: '',
    gateways: [],
  };
  const [state, setState] = useState(initialObj);

  const [isValidationShow, setIsValidationShow] = useState(false);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  useEffect(() => {
    if (data !== null) {
      setState({ ...data });
    }
  }, []);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'name':
        updatedState = {
          ...state,
          name: value,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'number':
        updatedState = {
          ...state,
          number: value,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'description':
        updatedState = {
          ...state,
          description: value,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  const inputSelectHandler = (label, data) => {
    let updatedState = { ...state };

    if (label === `gateways`) {
      updatedState = {
        ...state,
        gateways: data,
      };
    } else if (label === `benefitGroupId`) {
      updatedState = {
        ...state,
        benefitGroupId: data,
      };
    }

    setState(updatedState);
    validationCheck(updatedState);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data?.name !== `` && data?.description !== `` && data?.benefitGroupId !== '') {
      setIsSubmitBtnDisabled(false);
    } else {
      setIsSubmitBtnDisabled(true);
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const payload = {
      ...state,
      projectCode: selectedProject?.code,
    };

    dispatch({
      type: UPDATE_BINS,
      payload: {
        data: payload,
      },
    });

    onCloseHandler(false);
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
  };

  const loadOptionForBenefitGroup = async search => {
    return await axios({
      method: `GET`,
      url: `${baseUrl}/admin/benefitGroups`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        name: search === '' ? null : search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data?.objectId,
              label: `${data?.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  };

  return (
    <Modal isOpen={true}>
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title">Update Bin</h5>
        <Button type="button" onClick={() => tog_varying1()} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close"></Button>
      </div>
      <ModalBody>
        {/* <button onClick={() => console.log(data)}>Get state</button> */}
        <form>
          <Row>
            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="name" className="form-label">
                    Name <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input id="name" name="name" value={state?.name} className="form-control" onChange={inputChangeHandler} />
                  {/* {isValidationShow && state?.membershipData?.prefix === `` && <Error text="Prefix can't be empty" />} */}
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="name" className="form-label">
                    Number
                  </Label>
                  <Input
                    id="number"
                    name="number"
                    value={state?.number}
                    className="form-control"
                    disabled={true}
                    // onChange={inputChangeHandler}
                  />
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="name" className="form-label">
                    Description <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input id="description" name="description" value={state?.description} className="form-control" onChange={inputChangeHandler} />
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="benefit-group" className="form-label">
                    Benefit Group <span className="lbl_mandatory">*</span>
                  </Label>
                  <AsyncPaginate
                    debounceTimeout={500}
                    value={state?.benefitGroupId}
                    loadOptions={loadOptionForBenefitGroup}
                    onChange={data => inputSelectHandler(`benefitGroupId`, data)}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    noOptionsMessage={() => 'No results found'}
                    cacheUniqs={[['code']]}
                    placeholder="Select Benefit Group"
                    // isDisabled={props?.currentSelectedDetails}
                  />
                </div>
              </Col>
            </div>

            {/* <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="name" className="form-label">
                    Payment Gateway <span className="lbl_mandatory">*</span>
                  </Label>
                  <Select
                    id="gateways"
                    name="gateways"
                    isMulti={true}
                    value={state?.gateways}
                    options={[...data.allpaymentGateways]}
                    onChange={(data) => inputSelectHandler(`gateways`, data)}
                  />
                </div>
              </Col>
            </div> */}
          </Row>
        </form>
      </ModalBody>

      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateBinModal;
