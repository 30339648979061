import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'reactstrap';

import Table from './Components/Tables/Table';
import { Constant } from '../../../../../Helpers/constant';
import { BreadCrumb, Loader } from './../../../../../Common';
import { GET_CLIENT_HISTORY_EMPTY, GET_CLIENT_HISTORY_LIST } from '../../../../../../store/application/actionType';

const ClientUniqueCouponBooking = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [bookingTypeId, setBookingTypeId] = useState(Constant?.bookingTypes?.UNIQUE_COUPON_CODE);

  const [loading, setLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // GET BOOKINGS
  const { GetHistoryList, GetHistoryLoading } = useSelector(state => ({
    GetHistoryList: state?.GetClientHistoryList?.data,
    GetHistoryLoading: state?.GetClientHistoryList?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_CLIENT_HISTORY_LIST,
      payload: {
        urlParam: {
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          bookingTypeId,
        },
        headers: {
          code: 'ADIB',
        },
      },
    });

    return () => {
      dispatch({
        type: GET_CLIENT_HISTORY_EMPTY,
      });
    };
  }, []);

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);

    dispatch({
      type: GET_CLIENT_HISTORY_LIST,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          bookingTypeId,
        },
        headers: {
          code: 'ADIB',
        },
      },
    });
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/client/dashboard`);
    } else if (title === `Coupon Bookings`) {
      // nothing to do here
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (GetHistoryLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [GetHistoryLoading]);

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        {/*  ----------------------------  UI'S ---------------------------- */}
        <Container fluid className="position-relative">
          <>
            <BreadCrumb title="Coupon Bookings" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Coupon Bookings']} />
            <Table
              title={`Coupon Bookings`}
              data={GetHistoryList?.results ?? []}
              totalData={GetHistoryList?.count}
              pageHandler={paginationHandler}
              pageNumber={currentPageNumber}
            />
          </>
        </Container>
      </div>
    </>
  );
};

export default ClientUniqueCouponBooking;
