import React from 'react';

import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Table } from 'reactstrap';

const ActionConfirmation = props => {
  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    props?.onClose('YES');
  };

  const cancelHandler = () => {
    props?.onClose('NO');
  };

  return (
    <Modal
      isOpen={true}
      toggle={() => {
        props?.onClose('NO');
      }}
      id="status-confirmation"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Confirmation
        </h5>
        <Button
          type="button"
          onClick={() => {
            props?.onClose('NO');
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        <h5 className="text-center m-0">Are you sure?</h5>
      </ModalBody>

      <ModalFooter className="pb-0 d-flex justify-content-center">
        <div className="modal-footer d-flex justify-content-center">
          <Button className="btn btn-light " color="primary" onClick={cancelHandler}>
            Cancel
          </Button>
          <Button color="primary" onClick={submitHandler}>
            Sure
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ActionConfirmation;
