import React from 'react';
import './Loader.scss';

const FullLoader = props => {
  return (
    <React.Fragment>
      <div className="custom_loader">
        <div className="custom_lds-facebook">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FullLoader;
