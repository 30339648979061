import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'reactstrap';

import ReportSection from './ReportSection';
import { BreadCrumb } from '../../../../Common';
import DetailDescription from './ReportSection/Components/DetailDescription';
import { GET_GM_REPORT, GET_GM_REPORT_EMPTY } from '../../../../../store/actions';

const initialFilters = {
  reportType: null,
  projectCode: null,
};

const Report = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [isShowReport, setIsShowReport] = useState(true);

  const [selectedReportData, setSelectedReportData] = useState(null);
  const [domainSpecifData, setDomainSpecificData] = useState(null);

  const [filters, setFilters] = useState(initialFilters);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  const [type, setType] = useState('actual');

  const [accordionRow, setAccordionRow] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  //  GM REPORT DATA
  const { gmReport } = useSelector(state => ({
    gmReport: state?.GetGmReport?.data,
  }));

  useEffect(() => {
    return () => {
      setIsShowReport(true);
      setSelectedReportData(null);
      setDomainSpecificData(null);
      setFilters(initialFilters);

      dispatch({
        type: GET_GM_REPORT_EMPTY,
      });
    };
  }, []);

  /* ---------------------------- TOGGLE SCREEN HANDLER ---------------------------- */
  const onViewDetailDescription = (flag, reportsData, domainSpecific) => {
    setSelectedReportData(reportsData);
    setDomainSpecificData(domainSpecific);
    setIsShowReport(false);
  };

  /* ---------------------------- FILTER HANDLER ---------------------------- */
  const filterHandler = filterData => {
    setCurrentPageNumber(0);
    setFilters(filterData);

    dispatch({
      type: GET_GM_REPORT,
      payload: {
        urlParam: {
          pageIndex: 1,
          pageSize: 5,
          requestedFrom: filterData?.reportType === null ? null : filterData?.reportType?.value?.split(' ')?.[0],
          requestedTo: filterData?.reportType === null ? null : filterData?.reportType?.value?.split(' ')?.[1],
          projectCode: filterData?.projectCode === null ? null : filterData?.projectCode?.value,
          type: type,
        },
      },
    });
  };

  /* ---------------------------- HEADER TOGGLER ---------------------------- */
  const headerToggler = typeFlag => {
    setType(typeFlag);
    setCurrentPageNumber(0);
    setFilters(initialFilters);

    dispatch({
      type: GET_GM_REPORT,
      payload: {
        urlParam: {
          pageIndex: 1,
          pageSize: 5,
          type: typeFlag,
        },
      },
    });
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push('/dashboard');
    } else if (title === `GM Report`) {
      setIsShowReport(true);
      setSelectedReportData(null);
      setDomainSpecificData(null);
    }
  };

  useEffect(() => {
    if (gmReport?.results) {
      const tempAccordion = gmReport?.results?.map(data => false);
      setAccordionRow(tempAccordion ?? []);
    }
  }, [gmReport?.results]);

  return (
    <div className="page-content">
      <Container fluid className="position-relative">
        {isShowReport ? (
          <>
            <BreadCrumb title="GM Report" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'GM Report']} />
            <ReportSection
              type={type}
              currentPageNumber={currentPageNumber}
              setCurrentPageNumber={setCurrentPageNumber}
              filters={filters}
              setFilters={filterHandler}
              onViewDetailDescription={onViewDetailDescription}
              headerToggler={headerToggler}
              accordionRow={accordionRow}
              setAccordionRow={setAccordionRow}
            />
          </>
        ) : (
          <>
            <BreadCrumb title="GM Report" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'GM Report', 'Preview']} />
            <DetailDescription selectedReportData={selectedReportData} domainSpecifData={domainSpecifData} />
          </>
        )}
      </Container>
    </div>
  );
};

export default Report;
