import React from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { Button, Modal, ModalBody } from 'reactstrap';
import { Loader } from '../../../../../Common';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CancelModal = ({ isCancelModal, setIsCancelModal, currentBookingDetails, handleBookingStatus }) => {
  const buttonStyle = {
    background: 'linear-gradient(to right, #009BBD 0%, #3F9B76 100%)',
    border: 'none',
    color: 'white',
    padding: '10px 20px',
    cursor: 'pointer',
    borderRadius: '5px',
    fontSize: '16px',
    width: '38%',
  };
  const { t } = useTranslation();
  const handleClose = () => {
    setIsCancelModal(!isCancelModal);
  };

  const bookingCancelHandler = () => {
    handleBookingStatus('Cancel', currentBookingDetails);
  };

  // Update Booking
  const { updateBookings, updateBookingsLoading } = useSelector((state: any) => ({
    updateBookings: state?.VendorUpdateBooking?.data,
    updateBookingsLoading: state?.VendorUpdateBooking?.loading,
  }));

  return (
    <Modal isOpen={true} toggle={handleClose} id="exampleModal" centered>
      <div className="modal-header p-3 bg-soft-danger">
        <h5 className="modal-title" id="createboardModalLabel">
          Cancel
        </h5>
        <Button type="button" onClick={handleClose} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close"></Button>
      </div>
      {updateBookingsLoading && <Loader />}
      <ModalBody>
        <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
          <IoCloseCircleOutline size={100} color="#CE1126" />
        </div>
        <div>
          <h3 className="text-center">Oops!</h3>
          <p className="text-center">Are you sure you want to cancel this booking</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button style={buttonStyle} className="rounded-pill" onClick={bookingCancelHandler}>
            Yes
          </Button>
          <Button className="rounded-pill" style={buttonStyle} onClick={handleClose}>
            No
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CancelModal;
