import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'reactstrap';

import Table from './Components/Tables/Table';
import { Loader, BreadCrumb } from '../../../Common';
import { Constant } from '../../../Helpers/constant';
import { GET_UPLOADS_INFO, GET_UPLOADS_INFO_EMPTY } from '../../../../store/actions';

const UploadStatus = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [currentSelectedDetails, setCurrentSelectedDetails] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Upload Info
  const { uploadsInfo, uploadsInfoLoading } = useSelector(state => ({
    uploadsInfo: state?.GetUploadsInfo?.data,
    uploadsInfoLoading: state?.GetUploadsInfo?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (uploadsInfo === null) {
      dispatch({
        type: GET_UPLOADS_INFO,
        payload: {
          urlParam: {
            pageIndex: currentPageIndex,
          },
        },
      });
    }
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch({
        type: GET_UPLOADS_INFO_EMPTY,
      });
    };
  }, [dispatch]);

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    if (params?.actionType === `DOWNLOAD`) {
      setCurrentSelectedDetails(params?.downloadDetails);
      window.open(params?.downloadDetails?.file, `_blank`);
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);

    dispatch({
      type: GET_UPLOADS_INFO,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  /* ---------------------------- REFRESH HANDLER ---------------------------- */
  const refreshHandler = () => {
    dispatch({
      type: GET_UPLOADS_INFO,
      payload: {
        urlParam: {
          pageIndex: currentPageIndex,
        },
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (uploadsInfoLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [uploadsInfoLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Upload Status`) {
      // do nothing
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          <BreadCrumb title="Upload Status" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Upload Status`]} />
          <Table
            data={uploadsInfo?.results}
            totalData={uploadsInfo?.count}
            pageNumber={currentPageNumber}
            dataStartedFrom={currentPageIndex}
            pageHandler={paginationHandler}
            actionHandler={tableActionHandler}
            refreshHandler={refreshHandler}
          />
        </Container>
      </div>
    </>
  );
};

export default UploadStatus;
