import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AsyncPaginate } from 'react-select-async-paginate';
import Flatpickr from 'react-flatpickr';

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,
  Row,
  Input,
  CardFooter,
  UncontrolledTooltip,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { Constant } from '../../../../../../Helpers/constant';

import {
  DELETE_GOLF_COURSE_FORMATS_BY_ID,
  DISABLE_GOLF_COURSE_UNAVAILABILITY,
  GET_GOLF_COURSE_FORMATS_BY_ID,
  GET_GOLF_COURSE_UNAVAILABLE,
  SEND_MAIL,
  UPDATE_BOOKING,
  UPDATE_GOLF_BOOKING,
} from '../../../../../../../store/application/actionType';

const DisabledModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    remarks: '',
  };
  const [state, setState] = useState(initialObj);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [modalData, setModalData] = useState(null);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  useEffect(() => {
    if (data !== null) {
      setModalData(data);
    }
  }, [data]);

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = label => {
    if (label === `YES`) {
      dispatch({
        type: DISABLE_GOLF_COURSE_UNAVAILABILITY,
        payload: {
          id: data?.id,
        },
      });
    }

    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
      //   scrollable={true}
      centered
    >
      <ModalHeader
        toggle={() => {
          tog_varying1();
        }}
      ></ModalHeader>

      <ModalBody>
        <h4 style={{ textAlign: 'center' }}>Are you sure you want to disable ?</h4>

        <div className=" d-flex justify-content-center mt-3">
          <Button className="btn btn-primary mx-1" color="primary" onClick={() => submitHandler(`YES`)}>
            Yes
          </Button>

          <Button className="btn btn-primary mx-1" color="primary" onClick={() => submitHandler(`NO`)}>
            No
          </Button>
        </div>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center"></div>
    </Modal>
  );
};

export default DisabledModal;
