import { Button, ModalBody, Col, Row, Card, CardHeader, CardBody, ModalFooter, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { UPLOAD_CONFIG_EXCEL } from '../../../../../../../store/actions';
import ModalLoader from '../../../../../../Common/Loader/ModalLoader';
import React, { useCallback } from 'react';
import cogoToast from 'cogo-toast';
import axios from 'axios';
import { Constant } from '../../../../../../Helpers/constant';
import { baseUrl, uaeProjectsBaseURL } from '../../../../../../Config';
import { useDropzone } from 'react-dropzone';

const UploadExcelModal = ({
  selectedFileTypes,
  selectedFileTypeConfig,
  onSubmit,
  onClose,
  onDownloadExcel,
  onBack,
  isLoading,
  onClickDownloadSampleWithFilter,
}) => {
  const dispatch = useDispatch();

  const [selectedFiles, setselectedFiles] = useState([]);

  const handleAcceptedFiles = files => {
    const file = files[0];
    const allowedExtensions = ['xls', 'xlsx', 'csv'];
    const fileExtension = file?.name?.split('.')?.pop()?.toLowerCase();

    if (allowedExtensions?.includes(fileExtension)) {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        }),
      );
      setselectedFiles(files);
    } else {
      cogoToast.error('Kindly upload proper file!');
    }
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const onSubmitHandler = () => {
    if (selectedFiles?.length > 0) {
      const formData = new FormData();
      formData.append('file', selectedFiles[0]);
      formData.append('fileConfigId', selectedFileTypeConfig?.objectId);
      formData.append('configEnum', selectedFileTypeConfig?.configEnum);

      onSubmit(formData);
    } else {
      cogoToast.error('Please select a file!');
    }
  };

  const getFile = () => {
    fetch(`${uaeProjectsBaseURL}/admin/download-excel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Constant?.CLIENT_TOKEN,
        'Project-Code': 'ADMIN_ACCESS',
      },
      body: JSON.stringify({
        fileConfigId: `${selectedFileTypeConfig?.objectId}`,
        configEnum: selectedFileTypeConfig?.configEnum,
        isDownloadCompleteData: false,
        objectIds: [],
        filters: [],
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        const link = document.createElement('a');
        const fileName = 'file.xlsx';
        link.setAttribute('download', fileName);
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(error => {
        // console.log
        cogoToast.error('Something went wrong');
      });
  };

  return (
    <>
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title d-flex align-items-center">
          <span className="fs-4 cursor-pointer d-inline-block me-2" onClick={onBack}>
            <i className=" ri-arrow-left-s-line"></i>
          </span>
          Bulk Upload
        </h5>
        <Button
          type="button"
          onClick={() => {
            onClose();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        />
      </div>

      {/* {isLoading && <ModalBody>{isLoading && <ModalLoader />}</ModalBody>} */}

      <ModalBody>
        <Row>
          <Col md={12}>
            <div className="d-flex align-items-center border border-1 px-3 py-3 mb-3 rounded rounded-3">
              <div className="">
                <span className="">
                  <i className="ri-download-cloud-2-line fs-5 align-middle"></i>&nbsp;
                </span>
              </div>
              <div className="px-2">
                <span>Download sample format for specific Golf Course</span>
              </div>
              <div className="text-nowrap">
                <button type="button" className="btn btn-outline-primary btn-sm mx-1 px-3 py-2" onClick={onClickDownloadSampleWithFilter}>
                  Download File
                </button>
              </div>
            </div>
          </Col>

          <Col md={12}>
            <div className="fs-5 d-flex justify-content-center">
              <span className="">
                <i className=" ri-download-cloud-2-line align-middle"></i>&nbsp;
              </span>
              <p className="fs-5 d-block text-center text-decoration-underline cursor-pointer" onClick={getFile}>
                Download blank sample format
              </p>
            </div>
          </Col>

          <CardBody>
            <Dropzone
              onDrop={acceptedFiles => {
                handleAcceptedFiles(acceptedFiles);
              }}
              className="cursor-pointer"
              accept="image/*,audio/*,video/*"
              canRemove={true}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone dz-clickable">
                  <input {...getInputProps()} />
                  <div className="dz-message needsclick" {...getRootProps()}>
                    <div className="mb-3">
                      <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                    </div>
                    <h6>Select a file of drag and drop here</h6>
                  </div>
                </div>
              )}
            </Dropzone>

            <div className="list-unstyled mb-0" id="file-previews">
              {selectedFiles.map((f, i) => {
                return (
                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={i + '-file'}>
                    <div className="p-2">
                      <Row className="align-items-center">
                        {/* <Col className="col-auto">
                              <img data-dz-thumbnail="" height="80" className="avatar-sm rounded bg-light" alt={f.name} src={f.preview} />
                            </Col> */}
                        <Col>
                          <Link to="#" className="text-muted font-weight-bold">
                            {f.name}
                          </Link>
                          <p className="mb-0">
                            <strong>{f.formattedSize}</strong>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                );
              })}
            </div>
          </CardBody>
        </Row>
      </ModalBody>

      <ModalFooter className="modal-footer bg-light pt-3 justify-content-center">
        {isLoading ? (
          <Button color="primary" className="btn-load" outline>
            <span className="d-flex align-items-center">
              <Spinner size="sm" className="flex-shrink-0">
                Loading...
              </Spinner>
              <span className="flex-grow-1 ms-2">Loading...</span>
            </span>
          </Button>
        ) : (
          <Button className="btn btn-md" color="primary" onClick={onSubmitHandler}>
            <i className="ri-upload-2-line align-middle"></i>&nbsp; Upload
          </Button>
        )}
      </ModalFooter>
    </>
  );
};

export default UploadExcelModal;
