import { useEffect, useState } from 'react';

import { Grid, _ } from 'gridjs-react';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import { PreviewCardHeader, TablePagination } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import DataTable from 'react-data-table-component';
import { formattedWithComma } from '../../../../../../Helpers/Helpers';

const ProjectsTable = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColums = [
        {
          name: 'S.No',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
          width: '90px',
        },
        {
          name: 'Program Name',
          selector: row => row?.name,
          minWidth: '90px',
          wrap: true,
        },
        {
          name: 'Program Code',
          selector: row => row?.code,
          width: '230px',
          wrap: true,
        },
        {
          name: 'Client Name',
          selector: row => row?.clientName,
          width: '230px',
          wrap: true,
        },
        {
          name: 'Actions',
          width: '170px',
          cell: row => (
            <div className="d-flex justify-content-center flex-column w-100">
              <span
                className="btn btn-sm btn-primary mb-1"
                onClick={() => {
                  actionBtnHandler(`Show_Table`, row?.data);
                }}
              >
                Manage Settings
              </span>
            </div>
          ),
        },
      ];
      setColumns(fetchColums);
      setData(
        props?.data?.map((data, index) => {
          return {
            serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            name: data?.name || `NA`,
            code: data?.code || `NA`,
            clientName: data?.client?.name || `NA`,
            data,
          };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `Show_Table`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{`Programs`}</h4>

              {false && (
                <span className="btn btn-success mb-1" onClick={props?.onClickPrimaryBtn}>
                  <div className="me-2">
                    <i className="ri-add-line me-1 text-white text-opacity-75 fs-14 align-middle"></i>Create Payment
                  </div>
                </span>
              )}
            </CardHeader>

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ProjectsTable;
