import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { LOGOUT, LOGIN_EMPTY } from '../../store/application/actionType';
import { useLocation } from 'react-router-dom';
import dummyProfilePic from './../../assets/custom/avatar-1.jpg';
import { Constant } from '../../Containers/Helpers/constant';
import { useTranslation } from 'react-i18next';

//import images

const ProfileDropdown = ({ selectedUserType, props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [isClientType, setIsClientType] = useState(false);

  const { userLogout, userLogoutLoading, userLogoutError } = useSelector(state => ({
    userLogout: state.UserLogout.data,
    userLogoutLoading: state.UserLogout.loading,
    userLogoutError: state.UserLogout.error,
  }));

  // const { user } = useSelector((state) => ({
  //   user: state.Profile.user,
  // }))

  const [userName, setUserName] = useState('Thriwe');

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  const logoutHandler = () => {
    setTimeout(() => {
      if (selectedUserType === Constant?.userTypes?.ADMIN_USER) {
        history.push(`/logout`);
      } else if (JSON.parse(localStorage.getItem('projectUtility'))) {
        history.push(`/client/landmark/login`);
      } else if (selectedUserType === Constant?.userTypes?.CLIENT) {
        history.push(`/client/login`);
      } else if (selectedUserType === Constant?.userTypes?.VENDOR) {
        history.push(`/vendor/login`);
      }

      localStorage.clear();
      window.location.reload();
    }, 200);
  };

  const profilePictureCreator = name => {
    const canvas = document.createElement('canvas');
    canvas.width = 100;
    canvas.height = 100;
    const context = canvas.getContext('2d');

    // Draw a circle
    context.beginPath();
    context.arc(50, 50, 40, 0, 2 * Math.PI);
    context.fillStyle = '#18ae73';
    context.fill();

    // Draw the first character of the name
    context.font = '40px Arial';
    context.fillStyle = '#fff';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(name?.charAt(0)?.toUpperCase(), 50, 50);

    return canvas.toDataURL();
  };

  const getUserName = () => {
    let userName = ``;
    if (selectedUserType === Constant?.userTypes?.ADMIN_USER) {
      userName = JSON.parse(localStorage.getItem('authUser'))?.data?.name?.split(' ')?.[0];
    } else if (selectedUserType === Constant?.userTypes?.CLIENT) {
      userName = JSON.parse(localStorage.getItem('authUser'))?.data?.firstName;
    } else if (selectedUserType === Constant?.userTypes?.VENDOR) {
      userName = JSON.parse(localStorage.getItem('authUser'))?.data?.firstName;
    }
    return userName;
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle header-profile-user"
              // src={isClientType ? dummyProfilePic : window.localStorage.getItem('profilePic')}
              src={profilePictureCreator(getUserName())}
              alt="Header Avatar"
            />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{getUserName()}</span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem onClick={logoutHandler}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle" data-key="t-logout">
              {t(`Log out`)}
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
