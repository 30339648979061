import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { Grid, _ } from 'gridjs-react';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { hasPermission } from '../../../../Helpers/Helpers';
import { PreviewCardHeader, TablePagination } from '../../../../Common';
import { Constant } from '../../../../Helpers/constant';
import { PermissionConstants } from '../../../../Helpers/Constants/permissions';

const CustomersDataTable = props => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      setData(
        props?.data?.map((data, index) => {
          return [
            `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            `${data?.firstName} ${data?.lastName}` || `NA`,
            data?.mobileNumber || `NA`,
            data?.email || `NA`,
            data?.createdAt ? moment(new Date(`${data?.createdAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA',

            data?.expiryDate ? moment(new Date(`${data?.expiryDate}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            data,
          ];
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `CHANGE_EXPIRY`) {
      props.actionHandler({
        data: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Customer Data" />
            <CardBody>
              <div id="table-gridjs">
                <Grid
                  data={data}
                  columns={[
                    {
                      name: 'S.No.',
                      width: '70px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Name',
                      width: '140',
                      formatter: (cell, row) => _(<span>{cell}</span>),
                    },

                    {
                      name: 'Mobile No.',
                      width: '140',
                      formatter: (cell, row) => _(<span>{cell}</span>),
                    },

                    {
                      name: 'Email',
                      width: '140',
                      formatter: (cell, row) => _(<span>{cell}</span>),
                    },

                    {
                      name: 'Created At',
                      width: '140',
                      formatter: (cell, row) => _(<span>{cell}</span>),
                    },

                    {
                      name: 'Expiry At',
                      width: '140',
                      formatter: (cell, row) => _(<span>{cell}</span>),
                    },

                    {
                      name: 'Actions',
                      width: '120px',
                      formatter: (cell, row) =>
                        _(
                          <div className="d-flex justify-content-center flex-column">
                            <>
                              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CUSTOMER_DATA_MANAGEMENT) && cell[`signedUpAt`] === `` && (
                                <span
                                  className="btn btn-sm btn-primary mb-1"
                                  onClick={() => {
                                    actionBtnHandler(`CHANGE_EXPIRY`, cell);
                                  }}
                                >
                                  Change Expiry
                                </span>
                              )}
                            </>
                          </div>,
                        ),
                    },
                  ]}
                  search={false}
                  sort={false}
                  fixedHeader={true}
                  height="400px"
                  pagination={{ enabled: false, limit: 5 }}
                />
              </div>
              {props.children}
            </CardBody>

            <CardFooter>
              <Row className="custom_pagination">
                <Col>
                  <div className="d-flex justify-content-end">
                    <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CustomersDataTable;
