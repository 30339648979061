import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import apiCall from '../../apiCall';
import { baseUrl, customHeaders, uaeProjectsBaseURL } from './../../../../Containers/Config/index';

import { UPDATE_USER_CARD, UPDATE_USER_CARD_SUCCESS, UPDATE_USER_CARD_ERROR } from './../../actionType';
import { Constant } from '../../../../Containers/Helpers/constant';
import cogoToast from 'cogo-toast';

function* updateUserCard({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/payment/v1/cards`,
      method: 'PUT',
      headers: { Authorization: Constant?.CLIENT_TOKEN, ...customHeaders },
      data: payload?.data,
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({ type: UPDATE_USER_CARD_SUCCESS, payload: response });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({ type: UPDATE_USER_CARD_ERROR, payload: response.error });
    }
  } catch (error) {
    yield put({ type: UPDATE_USER_CARD_ERROR, payload: error });
  }
}

export function* watchUpdateUserCard() {
  yield takeEvery(UPDATE_USER_CARD, updateUserCard);
}

function* updateUserCardSaga() {
  yield all([fork(watchUpdateUserCard)]);
}

export default updateUserCardSaga;
