import React from 'react';

import Select from 'react-select';
import { Row, Col, TabPane, Label, Input } from 'reactstrap';
import FinishImg from './../../../../../../../../assets/custom/finish.gif';

const Finish = props => {
  return (
    <>
      <TabPane tabId={4} id="pills-bill-info">
        <div className="text-center py-5">
          <div className="mb-4">
            <img src={FinishImg} alt="finish" style={{ display: 'inline-block', width: '17%' }} />
          </div>
          <h5 className="mb-3">Your Benefit is Created !!!</h5>
          {/* <a
            href="benefit-group-onboarding.html"
            className="btn btn-success mx-1"
          >
            Create another benefit group
          </a> */}
          <button
            onClick={e => {
              e.preventDefault();
              props.onClickViewBenefitGrpBtn();
            }}
            className="btn btn-primary mx-1"
          >
            View benefit group(s)
          </button>
        </div>
      </TabPane>
    </>
  );
};

export default Finish;
