import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import fetch from './../../../fetch';
import { customHeaders, baseUrl, uaeProjectsBaseURL } from './../../../../../Containers/Config/index';
import { Constant } from '../../../../../Containers/Helpers/constant';

import { CREATE_LOCATION, CREATE_LOCATION_SUCCESS, CREATE_LOCATION_ERROR } from './../../../actionType';

function* createLocation({ payload }) {
  try {
    const response = yield call(fetch, {
      url: `${uaeProjectsBaseURL}/admin/facilities`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      data: payload.data,
    });

    if (response?.error === undefined) {
      yield put({ type: CREATE_LOCATION_SUCCESS, payload: response });
    } else {
      yield put({ type: CREATE_LOCATION_ERROR, payload: response.error });
    }
  } catch (error) {
    yield put({ type: CREATE_LOCATION_ERROR, payload: error });
  }
}

export function* watchCreateLocation() {
  yield takeEvery(CREATE_LOCATION, createLocation);
}

function* createLocationSaga() {
  yield all([fork(watchCreateLocation)]);
}

export default createLocationSaga;
