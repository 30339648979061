import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, Row, Table } from 'reactstrap';

import { Constant } from '../../../../../../../Helpers/constant';
import { GET_BENEFIT_ITEMS_LIST, GET_BENEFIT_ITEMS_LIST_EMPTY } from './../../../../../../../../store/application/actionType';

const WildcardSettingModal = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [state, setState] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [modal_varying1, setmodal_varying1] = useState(true);

  const [totalData, setTotalData] = useState(0);

  const [currentAllServices, setCurrentAllServices] = useState([]);
  const [currentSelectedBenefitItems, setCurrentSelectedBenefitItems] = useState([]);

  const [newlyAddedBenefitItems, setNewlyAddedBenefitItems] = useState([]);
  const [removedBenefitItems, setRemovedBenefitItems] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Services List With Benefit Group Items
  const { benefitGroupItems, benefitGroupItemsLoading } = useSelector(state => ({
    benefitGroupItems: state?.BenefitItemsList?.data,
    benefitGroupItemsLoading: state?.BenefitItemsList?.loading,
  }));

  useEffect(() => {
    setState(props);
    setCurrentIndex(props?.data?.currentIndex);
    getBenefitListItems(props, 0);
  }, []);

  useEffect(() => {
    if (benefitGroupItems !== null) {
      setCurrentAllServices(benefitGroupItems?.results?.map(item => ({ ...item })));
    }
  }, [benefitGroupItems]);

  const getBenefitListItems = (data, pageIndex = 0) => {
    dispatch({
      type: GET_BENEFIT_ITEMS_LIST_EMPTY,
    });

    let urlParam = null;

    if (data?.data?.data?.benefitGroupItemType === `Benefit`) {
      urlParam = {
        serviceId: data?.data?.data?.benefitGroupItem?.completeData?.allocationTypeId,
      };
    } else if (data?.data?.data?.benefitGroupItemType === `Brand`) {
      urlParam = {
        partnerId: data?.data?.data?.benefitGroupItem?.completeData?.allocationTypeId,
      };
    } else if (data?.data?.data?.benefitGroupItemType === `Sub-category`) {
      urlParam = {
        categoryId: data?.data?.data?.benefitGroupItem?.completeData?.allocationTypeId,
      };
    }

    dispatch({
      type: GET_BENEFIT_ITEMS_LIST,
      payload: {
        urlParam: {
          ...urlParam,
          configId: data?.data?.data?.benefitGroupItem?.completeData?.objectId,
          pageIndex,
          pageSize: Constant?.TABLE_PAGE_SIZE * 10,
          isBenefitAssociated: true,
        },
      },
    });
  };

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = (e, index = -1) => {
    const { name } = e.target;
    let tempItems = [...currentAllServices];

    if (name === `wildCard-quota`) {
      tempItems = currentAllServices?.map(service => ({
        ...service,
        projectLevelSettings: {
          enabled: e?.target?.checked,
          quotaId: '',
        },
      }));
    } else if (name === `quotaId`) {
      tempItems = currentAllServices?.map((service, i) => {
        if (i === index) {
          return {
            ...service,
            projectLevelSettings: {
              ...service?.projectLevelSettings,
              quotaId: e?.target?.value,
            },
          };
        } else {
          return {
            ...service,
          };
        }
      });
    }
    setCurrentAllServices(tempItems);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    let data = null;

    data = {
      currentIndex: currentIndex,
      data: {
        ...state?.data?.data,
        benefitGroupItem: {
          ...state?.data?.data?.benefitGroupItem,
          completeData: {
            ...state?.data?.data?.benefitGroupItem?.completeData,
            updateItems: currentAllServices?.map(service => ({
              ...service,
              id: service?.service?.id,
              name: service?.service?.name,
            })),
          },
        },
      },
    };

    props.onSubmit(data);

    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);

    dispatch({
      type: GET_BENEFIT_ITEMS_LIST_EMPTY,
    });
  };

  /* ---------------------------- CLOSE HANDLER ---------------------------- */
  const onCloseHandler = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);

    dispatch({
      type: GET_BENEFIT_ITEMS_LIST_EMPTY,
    });
  };

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);

    dispatch({
      type: GET_BENEFIT_ITEMS_LIST_EMPTY,
    });
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
      centered
      size="lg"
      style={{ position: 'relative' }}
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Wildcard quota
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      {/* <button
                onClick={() =>
                    console.log(
                        state,
                        currentAllServices,
                    )
                }
            >
                Get State
            </button> */}

      <ModalBody style={{ position: 'relative' }}>
        {benefitGroupItemsLoading && (
          <div className="loader" style={{ inset: 0, position: 'absolute' }}>
            <div className="lds-facebook">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}

        <Row>
          <Col sm={12}>
            <div className="mb-3">
              <Label htmlFor="wildCard-quota" className="form-label">
                Is Quota Enabled
              </Label>
              <div className="d-flex align-items-center">
                <span className="text-muted">False &nbsp;</span>
                <div className="form-switch d-inline-block ">
                  <Input
                    className="form-check-input code-switcher form-switch-md"
                    type="checkbox"
                    id="wildCard-quota"
                    name="wildCard-quota"
                    checked={currentAllServices?.[0]?.projectLevelSettings?.enabled ?? false}
                    onChange={inputChangeHandler}
                  />
                </div>
                <span> &nbsp;True</span>
              </div>
            </div>
          </Col>

          {(currentAllServices?.[0]?.projectLevelSettings?.enabled ?? false) && (
            <Col md={12}>
              <div className="table-responsive table-card mt-4" style={{ maxHeight: '300px' }}>
                <Table className="align-middle table-nowrap">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">ID</th>
                      <th scope="col">Sub-Category</th>
                      <th scope="col">Brand</th>
                      <th scope="col">Benefit</th>
                      <th scope="col">Quota</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentAllServices?.map((service, index) => {
                      return (
                        <tr key={index}>
                          <td>{`${index + 1}.`}</td>
                          <td>{service?.service?.id}</td>
                          <td>{service?.category?.name}</td>
                          <td>{service?.partner?.name}</td>
                          <td>{service?.service?.name}</td>
                          <td>
                            {(currentAllServices?.[0]?.projectLevelSettings?.enabled ?? false) && (
                              <select
                                id="quotaId"
                                name="quotaId"
                                className="form-select w-100"
                                value={service?.projectLevelSettings?.quotaId}
                                onChange={e => inputChangeHandler(e, index)}
                                onClick={e => {
                                  e.stopPropagation();
                                }}
                              >
                                <option selected disabled value=""></option>
                                {props?.inventoryData?.results?.length > 0 &&
                                  props?.inventoryData?.results?.map((inventory, index) => (
                                    <option value={inventory?.objectId} key={index}>
                                      {inventory?.name}
                                    </option>
                                  ))}
                              </select>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Col>
          )}
        </Row>
      </ModalBody>

      <ModalFooter className="d-flex justify-content-end">
        <Button className="overlay-disable btn btn-light" color="primary" onClick={onCloseHandler}>
          Close
        </Button>

        <Button className="overlay-disable" color="primary" onClick={submitHandler}>
          Add to list
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WildcardSettingModal;
