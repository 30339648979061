import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { customBaseURL, customHeaders, indianProjectsBaseURL, uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { GET_ADMIN_BENEFIT_ITEM, GET_ADMIN_BENEFIT_ITEM_SUCCESS, GET_ADMIN_BENEFIT_ITEM_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getAdminBenefitItems({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/v2/admin/benefit-items`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload?.urlParam,
        pageSize: Constant?.TABLE_PAGE_SIZE,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_ADMIN_BENEFIT_ITEM_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_ADMIN_BENEFIT_ITEM_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_ADMIN_BENEFIT_ITEM_ERROR, payload: error });
  }
}

export function* watchGetAdminBenefitItems() {
  yield takeEvery(GET_ADMIN_BENEFIT_ITEM, getAdminBenefitItems);
}

function* getAdminBenefitItemsSaga() {
  yield all([fork(watchGetAdminBenefitItems)]);
}

export default getAdminBenefitItemsSaga;
