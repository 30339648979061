import React from 'react';

import { Card, CardBody, Col, Form, Label, Row, Input } from 'reactstrap';

import Flatpickr from 'react-flatpickr';

const Filter = () => {
  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="customer-name" className="form-label">
                          Select Program <span className="lbl_mandatory">*</span>
                        </Label>
                        <select className="form-control">
                          <option>All</option>
                          <option>Axis Magnus</option>
                          <option>Axis Bharat</option>
                          <option>Axis</option>
                        </select>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="customer-name" className="form-label">
                          Select Wallet <span className="lbl_mandatory">*</span>
                        </Label>
                        <select className="form-control">
                          <option>All</option>
                          <option>Silver</option>
                          <option>Gold</option>
                          <option>Platinum</option>
                        </select>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="customer-name" className="form-label">
                          Search Customer
                        </Label>
                        <Input
                          id="customer-name"
                          name="customer-name"
                          type="text"
                          placeholder="Search by Name / Email / Phone No."
                          className="form-control"
                          //   onChange={inputChangeHandler}
                          //   value={filter?.customerDetails?.name}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="customer-name" className="form-label">
                          Search Transaction ID
                        </Label>
                        <Input
                          id="customer-name"
                          name="customer-name"
                          type="text"
                          placeholder="Search by Transaction ID"
                          className="form-control"
                          //   onChange={inputChangeHandler}
                          //   value={filter?.customerDetails?.name}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="customer-name" className="form-label">
                          Date Range
                        </Label>
                        <Flatpickr
                          className="form-control"
                          // value={[
                          //   filter?.bookingDateRange?.from,
                          //   filter?.bookingDateRange?.to,
                          // ]}
                          // onChange={(date) =>
                          //   datePickHandler(`dateOfPlayRange`, date)
                          // }
                          options={{
                            mode: 'range',
                            dateFormat: 'Y-m-d',
                            // maxDate: 'today',
                          }}
                          placeholder="Select Dates"
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="customer-name" className="form-label">
                          Search Transaction ID
                        </Label>
                        <Input
                          id="customer-name"
                          name="customer-name"
                          type="text"
                          placeholder="Search by Transaction ID"
                          className="form-control"
                          //   onChange={inputChangeHandler}
                          //   value={filter?.customerDetails?.name}
                        />
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          // onClick={submitHandler}
                          // disabled={isSubmitBtnDisabled}
                        >
                          Submit
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Filter;
