import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from './../../../../Common';
import { Container } from 'reactstrap';
import EditCourseFromatsForm from './Components/Forms/RealTimeSlotsReport';
import { DOWNLOAD_SLOTS_EXCEL, GET_GOLF_COURSE, GET_GOLF_COURSE_SLOTS_EMPTY } from '../../../../../store/application/actionType';
import RealTimeSlots from './Components/Table/RealTimeSlots';

const RealTimeSlotsReportForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [currentPageNumberSecondary, setCurrentPageNumberSecondary] = useState(0);
  const [currentPageIndexSecondary, setCurrentPageIndexSecondary] = useState(0);
  const [currentlyAppliedFilter, setCurrentlyAppliedFilter] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Benefit Packs
  // const { benefitPacks, benefitPacksLoading, benefitPacksError } = useSelector(
  //   (state) => ({
  //     benefitPacks: state?.BenefitPack?.data,
  //     benefitPacksLoading: state?.BenefitPack?.loading,
  //     benefitPacksError: state?.BenefitPack?.error,
  //   }),
  // )

  // Get Golf Courses
  const { golfCoursesLoading } = useSelector(state => ({
    golfCoursesLoading: state?.GetGolfCourses?.loading,
  }));

  // Get Golf Courses
  const { golfCourseSlots, getGolfCourseSlotsLoading } = useSelector(state => ({
    golfCourseSlots: state?.GolfCourseSlots?.data,
    getGolfCourseSlotsLoading: state?.GolfCourseSlots?.loading,
  }));

  useEffect(() => {
    return () => {
      dispatch({
        type: GET_GOLF_COURSE_SLOTS_EMPTY,
      });
    };
  }, [dispatch]);

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    // setCurrentSelectedBookingDetails(params?.bookingDetails)
    // setCurrentConfirmationModalActionFor(params?.actionType)

    if (params?.actionType === `VIEW`) {
      // setBenefitPackInfoTableShow(false)
      // setBenefitPackTableShow(true)
      // setIsCreateBenefitPackFormShow(false)
      // setCurrentPageIndexSecondary(0)
      // setCurrentPageNumberSecondary(0)
      // SetCurrentSelectedBenefit(params?.bookingDetails?.objectId)
      // dispatch({
      //   type: BENEFIT_PACK_LIST,
      //   payload: {
      //     urlParam: {
      //       pageIndex: 0,
      //       benefitPacksInfoId: params?.bookingDetails?.objectId,
      //     },
      //   },
      // })
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    // setCurrentPageNumber(pageNumber)
    // setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE)
    // dispatch({
    //   type: BENEFIT_PACK_INFO,
    //   payload: {
    //     urlParam: {
    //       pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
    //     },
    //   },
    // })
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (getGolfCourseSlotsLoading || golfCoursesLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [getGolfCourseSlotsLoading, golfCoursesLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Golf Admin`) {
      // do nothing
    } else if (title === `Real Time Slots Report`) {
      // do nothing
    }
  };

  const downloadReportHandler = () => {
    dispatch({
      type: DOWNLOAD_SLOTS_EXCEL,
      payload: {
        urlParams: {
          ...currentlyAppliedFilter,
        },
      },
    });
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ----------------------------  UI'S ---------------------------- */}
          <>
            <BreadCrumb title="Benefits" navigationby={breadcrumNavigationHandler} navigation={[`Golf Admin`, `Real Time Slots Report`]} />
            <EditCourseFromatsForm onGetSlots={filters => setCurrentlyAppliedFilter(filters)} />

            {golfCourseSlots?.slotDates?.length > 0 && <RealTimeSlots data={golfCourseSlots} downloadReport={downloadReportHandler} />}
          </>
        </Container>
      </div>
    </>
  );
};

export default RealTimeSlotsReportForm;
