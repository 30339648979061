import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import fetch from './../../fetch';
import { masterCardBaseURL, customHeaders } from '../../../../Containers/Config/index.js';
import { LOGOUT, LOGOUT_SUCCESS, LOGOUT_ERROR } from './../../actionType.js';
import { Constant } from '../../../../Containers/Helpers/constant';

function* userLogout() {
  try {
    const response = yield call(fetch, {
      url: `${masterCardBaseURL}/parse/logout`,
      method: 'POST',
      headers: {
        ...customHeaders,
        'x-parse-session-token': `${Constant?.CLIENT_TOKEN}`,
      },
    });

    if (response?.error === undefined) {
      yield put({ type: LOGOUT_SUCCESS, payload: response });
    } else {
      yield put({ type: LOGOUT_ERROR, payload: response.error });
    }
  } catch (error) {
    yield put({ type: LOGOUT_ERROR, payload: error });
  }
}
export function* watchUserLogout() {
  yield takeEvery(LOGOUT, userLogout);
}

function* userLogoutSaga() {
  yield all([fork(watchUserLogout)]);
}

export default userLogoutSaga;
