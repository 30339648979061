import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../Containers/Config/index.js';
import { GET_MEMBERSHIP_DETAILS, GET_MEMBERSHIP_DETAILS_SUCCESS, GET_MEMBERSHIP_DETAILS_ERROR } from '../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getMembershipDetails({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/benefitPacks`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload.urlParam,
        pageIndex: payload?.urlParam?.pageIndex,
        pageSize: Constant?.TABLE_PAGE_SIZE,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      yield put({
        type: GET_MEMBERSHIP_DETAILS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_MEMBERSHIP_DETAILS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_MEMBERSHIP_DETAILS_ERROR, payload: error });
  }
}

export function* watchMembershipDetails() {
  yield takeEvery(GET_MEMBERSHIP_DETAILS, getMembershipDetails);
}

function* membershipDetailsSaga() {
  yield all([fork(watchMembershipDetails)]);
}

export default membershipDetailsSaga;
