import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, commsHeader, indianProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { GET_VARIABLES, GET_VARIABLES_SUCCESS, GET_VARIABLES_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getVariables({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/comms/variables`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        ...commsHeader,
      },
      params: {
        ...payload?.urlParam,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_VARIABLES_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_VARIABLES_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_VARIABLES_ERROR, payload: error });
  }
}

export function* watchGetVariables() {
  yield takeEvery(GET_VARIABLES, getVariables);
}

function* getVariablesSaga() {
  yield all([fork(watchGetVariables)]);
}

export default getVariablesSaga;
