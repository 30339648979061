import { Col, Row } from 'reactstrap';

const RecordsFilter = ({ appliedFilter, onApply }) => {
  return (
    <>
      <Row>
        <Col md={12} className="mt-2">
          <h6>Filters -</h6>
        </Col>

        <Col md={12}>
          {[
            { id: 1, name: 'All records', status: ``, recordType: ``, errorType: `` },
            { id: 2, name: 'Okay(All)', status: `1`, recordType: ``, errorType: `` },
            { id: 3, name: 'Okay(New record)', status: `1`, recordType: `1`, errorType: `` },
            { id: 3.1, name: 'Okay(Updated record)', status: `1`, recordType: `2`, errorType: `` },
            { id: 4, name: 'Not okay(All)', status: `2`, recordType: ``, errorType: `` },
            { id: 5, name: 'Not okay(New record)', status: `2`, recordType: `1`, errorType: `` },
            { id: 6, name: 'Not okay(Updated record)', status: `2`, recordType: `2`, errorType: `` },
            { id: 7, name: 'Not okay(Missing data)', status: `2`, recordType: ``, errorType: `1` },
            { id: 8, name: 'Not okay(Validation failed)', status: `2`, recordType: ``, errorType: `2` },
            { id: 9, name: 'Not okay(Duplicate data)', status: `2`, recordType: ``, errorType: `3` },
          ]?.map((filter, index) => (
            <button
              key={index}
              className={`btn rounded-pill mb-2 me-2  ${appliedFilter?.id === filter?.id ? 'btn-primary' : 'btn-outline-primary'} btn-sm`}
              onClick={() => onApply(filter)}
            >
              <span className="d-flex align-items-center">
                <span className="flex-grow-1">{filter?.name}</span>
              </span>
            </button>
          ))}
        </Col>
      </Row>
    </>
  );
};

export default RecordsFilter;
