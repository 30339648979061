import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Row, Col, Button, Modal, ModalBody, ModalHeader, Table, Input } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { UPDATE_BOOKING } from '../../../../../../../../store/application/actionType';
import { Constant } from '../../../../../../../Helpers/constant';

const DriverModal = props => {
  const dispatch = useDispatch();

  const cancellationResons = [
    'Requested Slot Not Available',
    'Tournament at the golf course',
    'Golf Course Fully Booked',
    'No response on recommendation',
    'Others',
  ];

  /* ---------------------------- LOCAL STATES ---------------------------- */

  const [bookings, setBookings] = useState([]);

  const [modal_varying1, setmodal_varying1] = useState(true);

  const [isOthers, setIsOthers] = useState(false);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  useEffect(() => {
    if (props?.data !== null) {
      setBookings(
        props?.data?.map(booking => {
          return {
            ...booking,
            remarksValue: ``,
            othersValue: ``,
            isRemarksOther: false,
          };
        }),
      );
    }
  }, [props]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = (e, index) => {
    const { name, value } = e.target;

    let tempBooking = [];
    let selectedChangingBooking = null;

    switch (name) {
      case 'name':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],
          driver: {
            ...tempBooking[index]['driver'],
            name: value,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'mobile':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],
          driver: {
            ...tempBooking[index]['driver'],
            mobile: value,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      case 'pickUpTime':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],
          driver: {
            ...tempBooking[index]['driver'],
            pickupTime: value,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      default:
      // do nothing
    }
  };

  const datePickHandler = (flag, date, index) => {
    let tempBooking = [];
    let selectedChangingBooking = null;

    switch (flag) {
      case 'pickUpDate':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],
          driver: {
            ...tempBooking[index]['driver'],
            pickupDate: new Date(date[0]).toISOString(),
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- VALIDATION HANDLER ---------------------------- */
  const validationCheck = data => {
    let flag = false;
    for (let i = 0; i < data?.length; i++) {
      if (
        data[i]?.driver?.name === `` ||
        data[i]?.driver?.mobile === `` ||
        data[i]?.driver?.pickupDate === `` ||
        data[i]?.driver?.pickupTime === ``
      ) {
        flag = true;
        break;
      }
    }

    flag ? setIsSubmitBtnDisabled(true) : setIsSubmitBtnDisabled(false);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const requestBookings = bookings?.map(booking => {
      return {
        objectId: booking?.bookingId,
        detailedBookingId: booking?.detailedBookingId,
        // status: 4,
        driver: {
          ...booking.driver,
          pickupDate: `${moment(booking?.pickupDate).format('YYYY-MM-DD')}`,
        },
        process: `update-booking`,
      };
    });

    dispatch({
      type: UPDATE_BOOKING,
      payload: {
        data: {
          projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
          bookingTypeId: Constant?.BOOKING_TYPES?.localCourierBookings?.objectId,
          bookingsData: requestBookings,
        },
      },
    });
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      scrollable={true}
      id="exampleModal"
      size="lg"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Driver Assign
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log({ bookings })}>get state</button> */}
        <form>
          <Row>
            <Col md={12} style={{ mixHeight: '500px' }}>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Booking ID</th>
                    <th> Name</th>
                    <th> Driver Name</th>
                    <th> Mobile No.</th>
                    <th> Pick up Date</th>
                    <th> Pick up Time</th>
                  </tr>
                </thead>
                <tbody>
                  {bookings &&
                    bookings?.map((booking, index) => (
                      <tr key={index}>
                        <th>{index + 1}.</th>
                        <td>
                          <span style={{ display: 'inline-block', width: '140px' }}>{booking?.bookingId}</span>
                        </td>
                        <td>
                          <span style={{ display: 'inline-block', width: '140px' }}>{booking?.user?.name}</span>
                        </td>

                        <td>
                          <Input
                            id="name"
                            name="name"
                            type="text"
                            placeholder="Name"
                            className="form-control"
                            style={{ width: '140px' }}
                            value={booking?.name}
                            onChange={e => inputChangeHandler(e, index)}
                          />
                        </td>
                        <td>
                          <Input
                            id="mobile"
                            name="mobile"
                            type="number"
                            onWheel={e => e.target.blur()}
                            placeholder="Mobile"
                            className="form-control"
                            style={{ width: '140px' }}
                            value={booking?.mobile}
                            onChange={e => inputChangeHandler(e, index)}
                          />
                        </td>
                        <td>
                          <Flatpickr
                            className="form-control"
                            style={{ width: '140px' }}
                            name="pickUpDate"
                            id="pickUpDate"
                            value={booking?.pickupDate}
                            onChange={date => datePickHandler(`pickUpDate`, date, index)}
                            options={{
                              minDate: 'today',
                              enableTime: false,
                              dateFormat: 'Y-m-d',
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            id="pickUpTime"
                            name="pickUpTime"
                            type="time"
                            placeholder="pickUpTime"
                            className="form-control"
                            style={{ width: '140px' }}
                            value={booking?.pickupDate}
                            onChange={e => inputChangeHandler(e, index)}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default DriverModal;
