import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Constant } from '../../../../../../Helpers/constant';

import { UPDATE_BOOKING } from '../../../../../../../store/application/actionType';

const CancelModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  const cancellationResons = ['Others'];

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    remarks: '',
  };
  const [state, setState] = useState(initialObj);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [modalData, setModalData] = useState(null);

  const [isOthers, setIsOthers] = useState(false);
  const [othersValue, setOthersValue] = useState('');
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  useEffect(() => {
    if (data !== null) {
      setModalData(data);
    }
  }, [data]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;

    let updatedState = null;

    switch (name) {
      case 'cancellation remarks':
        updatedState = {
          ...state,
          remarks: value,
        };
        setState(updatedState);
        validationCheck(updatedState);
        if (value === 'Others') {
          setIsOthers(true);
        } else {
          setIsOthers(false);
          setOthersValue('');
        }
        break;

      case 'others_value':
        validationCheck('others_value');
        setOthersValue(e.target.value);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    let remarks = '';
    if (state?.remarks === 'Others') {
      remarks = othersValue;
    } else {
      remarks = state?.remarks;
    }

    dispatch({
      type: UPDATE_BOOKING,
      payload: {
        data: {
          projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
          bookingTypeId: Constant?.BOOKING_TYPES?.carRegistrationBookings?.objectId,
          bookingsData: [
            {
              objectId: data?.bookingId,
              status: Constant?.BOOKING_STATUS?.CANCEL_BY_THRIWE,
              bookingRemarks: remarks,
              detailedBookingId: data?.detailedBookingId,
              process: `cancel-booking`,
            },
          ],
        },
      },
    });

    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  /* ---------------------------- VALIDATION HANDLER ---------------------------- */
  const validationCheck = data => {
    if (data?.remarks === 'Others') {
      setIsSubmitBtnDisabled(true);
      if (othersValue !== '') {
        setIsSubmitBtnDisabled(false);
      } else {
        setIsSubmitBtnDisabled(true);
      }
    } else if (data?.remarks === '') {
      setIsSubmitBtnDisabled(true);
    } else {
      setIsSubmitBtnDisabled(false);
    }
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Cancel Remarks
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>
      <ModalBody>
        <form>
          <div className="mb-3">
            <select className="form-select" name="cancellation remarks" value={state.remarks} onChange={inputChangeHandler}>
              <option value="">Please select a reason for cancellation</option>
              {cancellationResons?.map((cancellationReason, index) => (
                <option value={cancellationReason} key={index}>
                  {cancellationReason}
                </option>
              ))}
            </select>
          </div>

          {isOthers && (
            <div className="mb-3">
              <textarea name="others_value" className="form-control" row="3" value={othersValue} onChange={inputChangeHandler}></textarea>
            </div>
          )}
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="danger" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default CancelModal;
