import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { AsyncPaginate } from 'react-select-async-paginate';

const CreateBenefitGroupItemsModal = ({ status, onClose }) => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [modalStatus, setModalStatus] = useState(false);

  const closeModalHandler = () => {
    onClose(false);
    setModalStatus(!modalStatus);
  };

  useEffect(() => {
    setModalStatus(status);
  }, [status]);

  return (
    <Modal
      isOpen={modalStatus}
      toggle={() => {
        closeModalHandler();
      }}
      id="create-benefit-group-item"
      centered
    >
      <ModalHeader
        toggle={() => {
          closeModalHandler();
        }}
      >
        <h4 className="modal-title">Create Benefit Group Items</h4>
      </ModalHeader>
      <hr />

      <ModalBody>
        <Row>
          <Col md={12}>
            <div className="mb-3">
              <Label htmlFor="" className="form-label">
                Benefit Group Item Type
              </Label>
              <div className="d-flex justify-content-between">
                <div className="">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id="Benefit"
                    name="Benefit"
                    // checked={state?.benefitGroupItemType === `Benefit`}
                    // onChange={() => checkBoxChangeHandler('Benefit')}
                  />
                  &nbsp;
                  <Label className="form-check-label" for="Benefit">
                    Benefit
                  </Label>
                </div>

                <div className="">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id="Brand"
                    name="Brand"
                    // checked={state?.benefitGroupItemType === `Brand`}
                    // onChange={() => checkBoxChangeHandler('Brand')}
                  />
                  &nbsp;
                  <Label className="form-check-label" for="Brand">
                    Brand
                  </Label>
                </div>

                <div className="">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id="Sub-category"
                    name="Sub-category"
                    // checked={state?.benefitGroupItemType === `Sub-category`}
                    // onChange={() => checkBoxChangeHandler('Sub-category')}
                  />
                  &nbsp;
                  <Label className="form-check-label" for="Sub-category">
                    Sub Category
                  </Label>
                </div>
              </div>
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <Label htmlFor="" className="form-label">
                Benefit Group Items
              </Label>

              <AsyncPaginate
                debounceTimeout={500}
                // value={state?.userId}
                // loadOptions={loadOptions}
                // onChange={(e) => onSelectBenefitGroup(e)}
                isMulti={false}
                closeMenuOnSelect={true}
                noOptionsMessage={() => 'No results found'}
                cacheUniqs={[['code']]}
                placeholder=""
                // isDisabled={state?.benefitGroupItemType === ``}
              />
            </div>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter className="d-flex justify-content-center">
        <Button
          className="overlay-disable"
          color="primary"
          //   onClick={submitHandler}
          //   disabled={isSubmitBtnDisabled}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateBenefitGroupItemsModal;
