import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Modal, ModalBody, Input, Label, Col } from 'reactstrap';
import { Constant } from '../../../../../../Helpers/constant';

import { UPDATE_BOOKING } from '../../../../../../../store/application/actionType';
import { Error } from '../../../../../../Common';
import { isANumber, isValidAplha } from '../../../../../../Helpers/Helpers';

const ChaufferAssignModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    name: '',
    mobile: '',
    carType: '',
  };
  const [state, setState] = useState(initialObj);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [isValidationShow, setIsValidationShow] = useState(false);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    let chaufferName = '';
    let chaufferMobile = '';

    switch (name) {
      case 'name':
        if (value?.length === 0) {
          chaufferName = ``;
        } else {
          chaufferName = isValidAplha(value) ? value : state?.name;
        }
        updatedState = {
          ...state,
          name: chaufferName,
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'mobile':
        if (value?.length === 0) {
          chaufferMobile = ``;
        } else {
          chaufferMobile = isANumber(value) ? value : state?.mobile;
        }
        updatedState = {
          ...state,
          mobile: chaufferMobile,
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'car-number':
        updatedState = { ...state, carNumber: value };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'car-type':
        updatedState = { ...state, carType: value };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data?.name !== `` && data?.mobile !== `` && data?.carType !== ``) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      dispatch({
        type: UPDATE_BOOKING,
        payload: {
          data: {
            projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
            bookingTypeId: Constant?.BOOKING_TYPES?.pickupAndDropBookings?.objectId,
            bookingsData: [
              {
                objectId: data?.bookingId,
                process: `chauffer-assign`,
                detailedBookingId: data?.detailedBookingId,
                chauffer: {
                  ...state,
                },
              },
            ],
          },
        },
      });
      onCloseHandler(false);
      setmodal_varying1(!modal_varying1);
    }
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Chauffeur Assign
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        <form>
          <Col md={12}>
            <div className="mb-3">
              <div>
                <Label htmlFor="name" className="form-label">
                  Booking ID
                </Label>
                <Input className="form-control" value={data?.bookingId} disabled />
              </div>
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <div>
                <Label htmlFor="name" className="form-label">
                  User Name
                </Label>
                <Input className="form-control" value={data?.user?.name} disabled />
              </div>
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <div>
                <Label htmlFor="name" className="form-label">
                  Chauffeur Name <span className="lbl_mandatory">*</span>
                </Label>
                <Input id="name" name="name" type="text" className="form-control" value={state?.name} onChange={inputChangeHandler} />
              </div>
              {isValidationShow && state?.name?.trim() === `` && <Error text="Please enter chauffeur name" />}
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <div>
                <Label htmlFor="mobile" className="form-label">
                  Chauffeur Mobile No. <span className="lbl_mandatory">*</span>
                </Label>
                <Input id="mobile" name="mobile" type="text" className="form-control" value={state?.mobile} onChange={inputChangeHandler} />
              </div>
              {isValidationShow && state?.mobile?.trim() === `` && <Error text="Please enter chauffeur mobile no." />}
            </div>
          </Col>

          {/* <div className="mb-3">
            <Col md={12}>
              <div>
                <Label htmlFor="car-number" className="form-label">
                  Car Number <span className="lbl_mandatory">*</span>
                </Label>
                <Input
                  id="car-number"
                  name="car-number"
                  type="number"
                  placeholder="Car Number"
                  className="form-control"
                  value={state?.carNumber}
                  onChange={inputChangeHandler}
                />
              </div>
            </Col>
          </div> */}

          <Col md={12}>
            <div className="mb-3">
              <div>
                <Label htmlFor="car-type" className="form-label">
                  Car Type <span className="lbl_mandatory">*</span>
                </Label>
                <select id="car-type" name="car-type" className="form-select" value={state?.carType} onChange={inputChangeHandler}>
                  <option value="">Choose...</option>
                  <option value="NA">NA</option>
                  <option value="Sedan">Sedan</option>
                  <option value="MVP">MVP</option>
                  <option value="Luxury">Luxury</option>
                </select>
              </div>
              {isValidationShow && state?.carType === `` && <Error text="Please select car type" />}
            </div>
          </Col>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default ChaufferAssignModal;
