import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProjectStats from './Components/ProjectStats';
import ProjectGraph from './Components/ProjectGraph';
import { GET_SURGE_REPORT, GET_USER_LIST, GET_WALLETS } from '../../../../../store/application/actionType';
import { Loader } from '../../../../Common';
import ProjectUsers from './Components/ProjectUsers';
import { Constant } from '../../../../Helpers/constant';
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import MisReports from './Components/MisReports';
import SurgeReport from './Components/SurgeReport';

const BSESDashboard = () => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // SURGE REPORT
  const { reportData, reportLoading } = useSelector(state => ({
    reportData: state?.GetSurgeReport?.data,
    reportLoading: state?.GetSurgeReport?.loading,
  }));

  // GET WALLETS
  const { wallets, walletsLoading } = useSelector(state => ({
    wallets: state?.GetWallets?.data,
    walletsLoading: state?.GetWallets?.loading,
  }));

  // USER INFO
  const { userInfoLoading } = useSelector(state => ({
    userInfoLoading: state?.GetUsersList?.loading,
  }));

  // MIS REPORT
  const { misReportDataLoading } = useSelector(state => ({
    misReportDataLoading: state?.GetMisReport?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_WALLETS,
    });
  }, [dispatch]);

  useEffect(() => {
    if (wallets !== null && wallets?.results?.length > 0) {
      dispatch({
        type: GET_SURGE_REPORT,
        payload: {
          data: {
            dateRange: {
              from: `${moment().startOf('month').format('YYYY-MM-DD')}`,
              to: `${moment().endOf('month').format('YYYY-MM-DD')}`,
            },
            walletId: `${wallets?.results[0]?.['objectId']}`,
          },
        },
      });

      dispatch({
        type: GET_USER_LIST,
        payload: {
          data: {
            dateRange: {
              from: `${moment().subtract(1, 'months').format('YYYY-MM-DD')}`,
              to: `${moment().subtract(0, 'months').format('YYYY-MM-DD')}`,
            },
            walletId: `${wallets?.results[0]?.['objectId']}`,
          },
          urlParam: {
            pageSize: Constant?.TABLE_PAGE_SIZE,
            pageIndex: 0,
          },
        },
      });
    }
  }, [wallets]);

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (reportLoading || walletsLoading || userInfoLoading || misReportDataLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [reportLoading, walletsLoading, userInfoLoading, misReportDataLoading]);

  const filterDateHandler = selectedDates => {
    if (selectedDates.length > 0 && wallets !== null && wallets?.results?.length > 0) {
      const selectedDate = selectedDates[0];
      console.log('selectedDate', selectedDate);

      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth();
      console.log('Year:', year, 'Month:', month);

      // Calculate the first day of the selected month
      const firstDayOfMonth = moment([year, month]).startOf('month').toDate();
      console.log('firstDayOfMonth:', firstDayOfMonth);

      // Calculate the last day of the selected month
      const lastDayOfMonth = moment([year, month]).endOf('month').toDate();
      console.log('lastDayOfMonth:', lastDayOfMonth);

      // Format dates as "YYYY-MM-DD" using Moment.js
      const from = moment(firstDayOfMonth).format('YYYY-MM-DD');
      console.log('Formatted firstDayOfMonth:', from);

      const to = moment(lastDayOfMonth).format('YYYY-MM-DD');
      console.log('Formatted lastDayOfMonth:', to);

      dispatch({
        type: GET_SURGE_REPORT,
        payload: {
          data: {
            dateRange: {
              from: from,
              to: to,
            },
            walletId: `${wallets?.results[0]?.['objectId']}`,
          },
        },
      });
    }
  };

  return (
    <>
      {loading && <Loader />}

      <Row>
        <Col md={12}>
          <ProjectStats data={reportData} filterDateHandler={filterDateHandler} />
          <SurgeReport data={reportData} />
          <MisReports data={reportData} />
          {/* <ProjectGraph data={reportData} /> */}
          {/* <ProjectUsers wallets={wallets} /> */}
        </Col>
      </Row>
    </>
  );
};

export default BSESDashboard;
