import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TestModal from '../Modal/TestModal';
import SmsTable from './Components/Table/Table';
import ComsConfigModal from '../Modal/ComsConfig';
import { Loader } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import {
  CREATE_COMS_CHANNEL_CONFIG_PROJECT,
  CREATE_COMS_CONFIG_EMPTY,
  GET_COMS_CHANNEL_CONFIG_PROJECT,
  GET_COMS_CHANNEL_CONFIG_PROJECT_EMPTY,
  GET_COMS_CONFIG_LIST,
  GET_COMS_CONFIG_LIST_EMPTY,
  GET_TEMPLATE_LIST,
  GET_TEMPLATE_LIST_EMPTY,
  UPDATE_COMS_CHANNEL_CONFIG_PROJECT,
  UPDATE_COMS_CHANNEL_CONFIG_PROJECT_EMPTY,
  UPDATE_COMS_CONFIG,
  UPDATE_COMS_CONFIG_EMPTY,
} from '../../../../../../../store/actions';

const Sms = ({ tabId, activeTab, projectId, setcheckMarks }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  const [isShowEventsTable, setIsShowEventsTable] = useState(true);
  const [isShowCreateEventForm, setIsShowCreateEventForm] = useState(false);
  const [events, setEvents] = useState([]);
  const [updateFormData, setupdateFormData] = useState({});
  const [comsChannelConfig, setComsChannelConfig] = useState({});
  const [isShowTestModal, setIsShowTestModal] = useState(false);
  const [currentConfig, setCurrentConfig] = useState(null);

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (activeTab !== tabId) {
      setCurrentPageNumber(0);
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === tabId) {
      dispatch({
        type: GET_COMS_CONFIG_LIST_EMPTY,
      });
      dispatch({
        type: GET_COMS_CHANNEL_CONFIG_PROJECT,
        payload: {
          urlParam: {
            project_id: projectId,
            channel: 'sms',
          },
        },
      });
      dispatch({
        type: GET_COMS_CONFIG_LIST,
        payload: {
          urlParam: {
            offset: currentPageNumber,
            limit: Constant.TABLE_PAGE_SIZE,
            channel: 'sms',
            project_id: projectId,
          },
        },
      });
    }
  }, []);

  const { getComsChannel, getComsChannelLoading } = useSelector(state => ({
    getComsChannel: state.GetComsChannelConfig?.data,
    getComsChannelLoading: state.GetComsChannelConfig.loading,
  }));

  useEffect(() => {
    if (getComsChannel?.length > 0) {
      setComsChannelConfig(getComsChannel?.[0]);
    }
  }, [getComsChannel]);

  const { createConfigData, createConfigLoadingData } = useSelector(state => ({
    createConfigData: state.CreateComsConfig?.data,
    createConfigLoadingData: state.CreateComsConfig.loading,
  }));

  const { updateComsConfigData, updateComsConfigDataLoading } = useSelector(state => ({
    updateComsConfigData: state.UpdateComsConfig?.data,
    updateComsConfigDataLoading: state.UpdateComsConfig?.loading,
  }));

  const { createComsChannelConfigData, createComsChannelConfigDataLoading } = useSelector(state => ({
    createComsChannelConfigData: state.CreateComsChannelConfig?.data,
    createComsChannelConfigDataLoading: state.CreateComsChannelConfig?.loading,
  }));

  const { updateComsChannelConfigData, updateComsChannelConfigDataLoading } = useSelector(state => ({
    updateComsChannelConfigData: state.UpdateComsChannelConfig?.data,
    updateComsChannelConfigDataLoading: state.UpdateComsChannelConfig?.loading,
  }));

  const { template, templateLoading } = useSelector(state => ({
    template: state?.GetTemplates?.data,
    templateLoading: state?.GetTemplates?.loading,
  }));

  const { sendComsLoading } = useSelector(state => ({
    sendComsLoading: state?.SendCommunication?.loading,
  }));

  useEffect(() => {
    if (createConfigData != null) {
      dispatch({
        type: CREATE_COMS_CONFIG_EMPTY,
      });
      dispatch({
        type: GET_COMS_CHANNEL_CONFIG_PROJECT,
        payload: {
          urlParam: {
            project_id: projectId,
            channel: 'sms',
          },
        },
      });
      dispatch({
        type: GET_COMS_CONFIG_LIST,
        payload: {
          urlParam: {
            offset: currentPageNumber,
            limit: Constant.TABLE_PAGE_SIZE,
            channel: 'sms',
            project_id: projectId,
          },
        },
      });
    }
  }, [createConfigData]);

  // Update Config
  useEffect(() => {
    if (updateComsConfigData != null) {
      dispatch({
        type: UPDATE_COMS_CONFIG_EMPTY,
      });
      dispatch({
        type: GET_COMS_CONFIG_LIST,
        payload: {
          urlParam: {
            offset: currentPageNumber,
            limit: Constant.TABLE_PAGE_SIZE,
            channel: 'sms',
            project_id: projectId,
          },
        },
      });
    }
  }, [updateComsConfigData]);

  useEffect(() => {
    if (template !== null) {
      setIsShowTestModal(true);
    }
  }, [template]);

  const { getConfigList, getConfigListLoading } = useSelector(state => ({
    getConfigList: state?.GetComsConfig?.data,
    getConfigListLoading: state?.GetComsConfig?.loading,
  }));

  useEffect(() => {
    if (
      getConfigListLoading ||
      createConfigLoadingData ||
      updateComsConfigDataLoading ||
      getComsChannelLoading ||
      createComsChannelConfigDataLoading ||
      updateComsChannelConfigDataLoading ||
      templateLoading ||
      sendComsLoading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    getConfigListLoading,
    createConfigLoadingData,
    updateComsConfigDataLoading,
    updateComsChannelConfigDataLoading,
    createComsChannelConfigDataLoading,
    getComsChannelLoading,
    templateLoading,
    sendComsLoading,
  ]);

  /* ---------------------------- ON SUBMIT CAMPAIGN HANDLER ---------------------------- */
  const onSubmitEventForm = (data, flag) => {
    setEvents([...events, ...data]);
    setIsShowCreateEventForm(false);
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = (data, flag) => {
    if (flag === 'Update Data') {
      setIsShowCreateEventForm(true);
      setupdateFormData(data);
    } else if (flag === 'Test Mail') {
      setCurrentConfig(data);
      dispatch({
        type: GET_TEMPLATE_LIST,
        payload: {
          id: data?.template_id,
        },
      });
    } else {
      dispatch({
        type: UPDATE_COMS_CONFIG,
        payload: {
          data: {
            ...data,
            is_active: data?.is_active ? false : true,
          },
          params: data?.id,
        },
      });
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    dispatch({
      type: GET_COMS_CONFIG_LIST,
      payload: {
        urlParam: {
          offset: pageNumber * Constant.TABLE_PAGE_SIZE,
          limit: Constant?.TABLE_PAGE_SIZE,
          channel: 'sms',
          project_id: projectId,
        },
      },
    });
  };

  useEffect(() => {
    if (updateComsChannelConfigData != null || createComsChannelConfigData != null) {
      dispatch({
        type: GET_COMS_CHANNEL_CONFIG_PROJECT,
        payload: {
          urlParam: {
            project_id: projectId,
            channel: 'sms',
          },
        },
      });
      dispatch({
        type: UPDATE_COMS_CHANNEL_CONFIG_PROJECT_EMPTY,
      });
    }
  }, [updateComsChannelConfigData, createComsChannelConfigData]);

  /* ---------------------------- BACK BUTTON HANDLER ---------------------------- */
  const backBtnHandler = () => {
    setIsShowEventsTable(true);
    setIsShowCreateEventForm(false);
  };

  /* ---------------------------- CREATE EVENT FORM HANDLER ---------------------------- */
  const createNewEventHandler = () => {
    setIsShowEventsTable(false);
    setupdateFormData({});
    setIsShowCreateEventForm(true);
  };

  const changeStatus = () => {
    dispatch({
      type: GET_COMS_CHANNEL_CONFIG_PROJECT_EMPTY,
    });
    dispatch({
      type: UPDATE_COMS_CHANNEL_CONFIG_PROJECT_EMPTY,
    });
    const { data } = JSON.parse(localStorage.getItem('authUser'));
    if (!getComsChannel?.length) {
      dispatch({
        type: CREATE_COMS_CHANNEL_CONFIG_PROJECT,
        payload: {
          data: {
            is_active: true,
            channel: 'sms',
            project_id: projectId,
            created_by: data?.name,
          },
        },
      });
    } else {
      dispatch({
        type: UPDATE_COMS_CHANNEL_CONFIG_PROJECT,
        payload: {
          params: {
            project_id: projectId,
            channel: 'sms',
          },
          urlParam: {
            ...comsChannelConfig,
            is_active: comsChannelConfig?.is_active ? false : true,
          },
        },
      });
    }
  };

  const closeModalHandler = () => {
    setIsShowTestModal(!isShowTestModal);
    dispatch({
      type: GET_TEMPLATE_LIST_EMPTY,
    });
  };

  return (
    <>
      {loading && <Loader />}

      {isShowTestModal && (
        <TestModal
          templateDetails={template}
          isShowTestModal={isShowTestModal}
          setIsShowTestModal={closeModalHandler}
          currentConfig={currentConfig}
        />
      )}

      {isShowCreateEventForm && (
        <ComsConfigModal
          projectID={projectId}
          submitForm={onSubmitEventForm}
          channel="sms"
          onBack={backBtnHandler}
          isShowCreateEventForm={isShowCreateEventForm}
          setIsShowCreateEventForm={setIsShowCreateEventForm}
          formType={Object.keys(updateFormData)?.length > 0 ? 'UpdateForm' : 'CreateForm'}
          updateData={updateFormData}
        />
      )}

      <SmsTable
        events={getConfigList?.results}
        createNewEvent={createNewEventHandler}
        totalData={getConfigList?.count}
        pageHandler={paginationHandler}
        pageNumber={currentPageNumber}
        actionHandler={tableActionHandler}
        changeStatus={changeStatus}
        projectID={projectId}
        getComsChannel={getComsChannel}
        disableButton={createComsChannelConfigDataLoading || updateComsChannelConfigData ? true : false}
      />
    </>
  );
};

export default Sms;
