import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'reactstrap';
import Filter from './Components/Filters/Filter';
import Table from './Components/Tables/Table';
import { ActionConfirmation, BreadCrumb, Loader } from '../../../../Common';
import { Constant } from '../../../../Helpers/constant';

import {
  CLICK_DOWNLOAD_DUMP,
  CLICK_DOWNLOAD_DUMP_EMPTY,
  EMPTY_UPDATE_BOOKING,
  GET_BOOKING_LIST,
  GET_BOOKING_LIST_EMPTY,
  GET_CATEGORY,
  GET_COUNTRY,
  GET_LOCATION,
  GET_PARTNER,
  UPDATE_BOOKING,
} from '../../../../../store/application/actionType';
import BookingDetails from './Components/Details/Details';
import CancelModal from './Components/Modals/CancelModal';
import UpdateBookingModal from './Components/Modals/Update';

const DynamicCouponBookingManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [projectId, setProjectId] = useState(Constant?.CURRENT_SELECTED_PROGRAM?.id);
  const [bookingTypeId, setBookingTypeId] = useState(Constant?.BOOKING_TYPES?.dynamicCouponBookings?.objectId);

  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);
  const [isDetailsPage, setIsDetailsPage] = useState(false);
  const [currentSelectedBookingDetails, setCurrentSelectedBookingDetails] = useState([]);

  const [loading, setLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [currentConfirmationModalActionFor, setCurrentConfirmationModalActionFor] = useState(null);

  const [isCancelModalShow, setIsCancelModalShow] = useState(false);
  const [isUpdateBookingModal, setIsUpdateBookingModal] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Bookings
  const { bookings, bookingsLoading, bookingsError } = useSelector(state => ({
    bookings: state?.Bookings?.data,
    bookingsLoading: state?.Bookings?.loading,
    bookingsError: state?.Bookings?.error,
  }));

  // Update Booking
  const { updateBookings, updateBookingsLoading, updateBookingsError } = useSelector(state => ({
    updateBookings: state?.UpdateBooking?.data,
    updateBookingsLoading: state?.UpdateBooking?.loading,
    updateBookingsError: state?.UpdateBooking?.error,
  }));

  // Countries
  const { countries, countriesLoading, countriesError } = useSelector(state => ({
    countries: state?.Countries?.data,
    countriesLoading: state?.Countries?.loading,
    countriesError: state?.Countries?.error,
  }));

  // Regions
  const { regions, regionsLoading, regionsError } = useSelector(state => ({
    regions: state?.Regions?.data,
    regionsLoading: state?.Regions?.loading,
    regionsError: state?.Regions?.error,
  }));

  // Sub-Regions
  const { subRegions, subRegionsLoading, subRegionsError } = useSelector(state => ({
    subRegions: state?.SubRegions?.data,
    subRegionsLoading: state?.SubRegions?.loading,
    subRegionsError: state?.SubRegions?.error,
  }));

  // Category
  const { categories, categoryLoading, categoryError } = useSelector(state => ({
    categories: state?.GetCategory?.data,
    categoryLoading: state?.GetCategory?.loading,
    categoryError: state?.GetCategory?.error,
  }));

  // Partners
  const { partners, partnersLoading, partnersError } = useSelector(state => ({
    partners: state?.GetPartners?.data,
    partnersLoading: state?.GetPartners?.loading,
    partnersError: state?.GetPartners?.error,
  }));

  // Locations
  const { locations, locationsLoading, locationsError } = useSelector(state => ({
    locations: state?.GetLocation?.data,
    locationsLoading: state?.GetLocation?.loading,
    locationsError: state?.GetLocation?.error,
  }));

  // Service
  const { services, serviceLoading, serviceError } = useSelector(state => ({
    services: state?.GetService?.data,
    serviceLoading: state?.GetService?.loading,
    serviceError: state?.GetService?.error,
  }));

  // Click Download Dump
  const { downloadDump, downloadDumpLoading, downloadDumpError } = useSelector(state => ({
    downloadDump: state?.DownloadDump?.data,
    downloadDumpLoading: state?.DownloadDump?.loading,
    downloadDumpError: state?.DownloadDump?.error,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_BOOKING_LIST,
      payload: {
        urlParam: {
          projectId,
          bookingTypeId,
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          sortOn: 'updatedAt',
        },
        data: null,
      },
    });

    return () => {
      dispatch({
        type: GET_BOOKING_LIST_EMPTY,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    // dispatch({
    //   type: GET_BOOKING_LIST,
    //   payload: {
    //     urlParam: {
    //       projectId,
    //       bookingTypeId,
    //       pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
    //     },
    //     data: null,
    //   },
    // })

    if (countries === null) {
      dispatch({
        type: GET_COUNTRY,
      });
    }

    if (categories === null) {
      dispatch({
        type: GET_CATEGORY,
      });
    }

    if (partners === null) {
      dispatch({
        type: GET_PARTNER,
      });
    }

    if (locations === null) {
      dispatch({
        type: GET_LOCATION,
        payload: {
          urlParam: {
            categoryId: Constant?.GOLF_COURSE_CATEGORY_ID,
          },
        },
      });
    }
    if (partners === null) {
      dispatch({
        type: GET_PARTNER,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (updateBookings !== null) {
      dispatch({
        type: EMPTY_UPDATE_BOOKING,
      });

      dispatch({
        type: GET_BOOKING_LIST,
        payload: {
          urlParam: {
            projectId,
            bookingTypeId,
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            sortOn: currentlyAppliedFilters?.sortOn ?? 'updatedAt',
          },
          data: currentlyAppliedFilters,
        },
      });
    }
  }, [updateBookings]);

  useEffect(() => {
    if (downloadDump !== null) {
      dispatch({
        type: CLICK_DOWNLOAD_DUMP_EMPTY,
      });
    }
  }, [downloadDump]);

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = filters => {
    if (filters?.filter) {
      setCurrentPageNumber(0);
      setCurrentPageIndex(0);
      setCurrentlyAppliedFilters(filters);

      dispatch({
        type: GET_BOOKING_LIST,
        payload: {
          urlParam: {
            projectId,
            bookingTypeId,
            pageIndex: 0,
            sortOn: filters?.sortOn ?? 'updatedAt',
          },
          data: filters,
        },
      });
    } else {
      setCurrentlyAppliedFilters(filters);
    }
  };

  /* ---------------------------- FILTER RESET HANDLER ---------------------------- */
  const onResetHandler = () => {
    setCurrentPageNumber(0);
    setCurrentPageIndex(0);

    dispatch({
      type: GET_BOOKING_LIST,
      payload: {
        urlParam: {
          projectId,
          bookingTypeId,
          pageIndex: 0,
          sortOn: 'updatedAt',
        },
        data: null,
      },
    });
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);

    dispatch({
      type: GET_BOOKING_LIST,
      payload: {
        urlParam: {
          projectId,
          bookingTypeId,
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          sortOn: currentlyAppliedFilters?.sortOn ?? 'updatedAt',
        },
        data: currentlyAppliedFilters,
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedBookingDetails(params?.bookingDetails);
    setCurrentConfirmationModalActionFor(params?.actionType);

    if (params?.actionType === `VIEW`) {
      setIsDetailsPage(!isDetailsPage);
    } else if (params?.actionType === `PROCESS`) {
      setIsShowConfirmationModal(true);
    } else if (params?.actionType === `CONFIRM`) {
      setIsShowConfirmationModal(true);
    } else if (params?.actionType === `CANCEL`) {
      // setIsShowConfirmationModal(true)
      setIsCancelModalShow(true);
    } else if (params?.actionType === `UPDATE`) {
      setIsUpdateBookingModal(true);
    }
  };

  /* ---------------------------- CLOSE MODAL HANDLER ---------------------------- */
  const closeModalHandler = (flag, data) => {
    if (flag === `ConfirmationModal`) {
      setIsShowConfirmationModal(false);

      if (data === `YES`) {
        if (currentConfirmationModalActionFor === `PROCESS`) {
          dispatch({
            type: UPDATE_BOOKING,
            payload: {
              data: {
                projectId,
                bookingTypeId,
                bookingsData: [
                  {
                    objectId: currentSelectedBookingDetails?.bookingId,
                    detailedBookingId: currentSelectedBookingDetails?.detailedBookingId,
                    status: Constant?.BOOKING_STATUS?.PROCESSED,
                    process: `process-booking`,
                  },
                ],
              },
            },
          });
        } else if (currentConfirmationModalActionFor === `CONFIRM`) {
          dispatch({
            type: UPDATE_BOOKING,
            payload: {
              data: {
                projectId,
                bookingTypeId,
                bookingsData: [
                  {
                    objectId: currentSelectedBookingDetails?.bookingId,
                    detailedBookingId: currentSelectedBookingDetails?.detailedBookingId,
                    status: Constant?.BOOKING_STATUS?.CONFIRM,
                    process: `confirm-booking`,
                  },
                ],
              },
            },
          });
        } else if (currentConfirmationModalActionFor === `CANCEL`) {
          dispatch({
            type: UPDATE_BOOKING,
            payload: {
              data: {
                projectId,
                bookingTypeId,
                bookingsData: [
                  {
                    objectId: currentSelectedBookingDetails?.bookingId,
                    detailedBookingId: currentSelectedBookingDetails?.detailedBookingId,
                    status: Constant?.BOOKING_STATUS?.CANCEL_BY_THRIWE,
                    process: `cancel-booking`,
                  },
                ],
              },
            },
          });
        }
      }
    }
  };

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Dynamic Coupon Booking`) {
      setIsDetailsPage(false);
    } else if (title === `Dynamic Coupon Booking Details`) {
      // do nothing
    }
  };

  /* ---------------------------- DOWNLOAD DUMP HANDLER ---------------------------- */
  const downloadDumpHandler = () => {
    dispatch({
      type: CLICK_DOWNLOAD_DUMP,
      payload: {
        urlParam: {
          projectId,
          bookingTypeId,
          downloadType: 1,
        },
        data: currentlyAppliedFilters,
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (
      bookingsLoading ||
      countriesLoading ||
      regionsLoading ||
      subRegionsLoading ||
      partnersLoading ||
      locationsLoading ||
      updateBookingsLoading ||
      downloadDumpLoading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    bookingsLoading,
    countriesLoading,
    regionsLoading,
    subRegionsLoading,
    partnersLoading,
    locationsLoading,
    updateBookingsLoading,
    downloadDumpLoading,
  ]);

  return (
    <>
      {loading && <Loader />}

      {isShowConfirmationModal && <ActionConfirmation onClose={closeModalHandler} />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {/* <button onClick={() => console.log({ currentlyAppliedFilters })}>
            Get state
          </button> */}

          {isCancelModalShow && <CancelModal data={currentSelectedBookingDetails} onCloseHandler={() => setIsCancelModalShow(false)} />}

          {isUpdateBookingModal && (
            <UpdateBookingModal locations={locations} data={currentSelectedBookingDetails} onCloseHandler={() => setIsUpdateBookingModal(false)} />
          )}

          {!isDetailsPage && (
            <>
              <BreadCrumb
                title="Dynamic Coupon Booking"
                navigationby={breadcrumNavigationHandler}
                navigation={[`Dashboard`, `Dynamic Coupon Booking`]}
              />
              <Filter
                data={{
                  countries,
                  regions,
                  subRegions,
                  categories: categories?.results,
                  partners: partners?.results,
                  locations: locations?.results,
                  services: services?.results,
                }}
                filterHandler={filterSubmitHandler}
                appliedFilters={currentlyAppliedFilters}
                onReset={onResetHandler}
              />

              <Table
                data={bookings?.results}
                totalData={bookings?.count}
                pageNumber={currentPageNumber}
                pageHandler={paginationHandler}
                actionHandler={tableActionHandler}
                dumpDownload={downloadDumpHandler}
                appliedFilters={currentlyAppliedFilters}
              />
            </>
          )}

          {isDetailsPage && (
            <>
              <BreadCrumb
                title="Dynamic Coupon Booking"
                navigationby={breadcrumNavigationHandler}
                navigation={[`Dashboard`, `Dynamic Coupon Booking`, `Dynamic Coupon Booking Details`]}
              />
              <BookingDetails details={currentSelectedBookingDetails} />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default DynamicCouponBookingManagement;
