import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import axios from 'axios';

import { Button, Modal, ModalBody, ModalHeader, Col, Label, Input, Row } from 'reactstrap';
import { ASSIGN_COUPON, GET_COUPON_PARTNER, GET_COUPON_FACILITY, GET_COUPON_SERVICE } from '../../../../../../store/actions';
import { Constant } from '../../../../../Helpers/constant';
import { AsyncPaginate } from 'react-select-async-paginate';
import { baseUrl, customHeaders, uaeProjectsBaseURL } from '../../../../../Config';

const AssignedModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Partners
  const { couponPartners, couponPartnersLoading, couponPartnersError } = useSelector(state => ({
    couponPartners: state?.GetCouponPartner?.data,
    couponPartnersLoading: state?.GetCouponPartner?.loading,
    couponPartnersError: state?.GetCouponPartner?.error,
  }));

  // Facilities
  const { couponFacility } = useSelector(state => ({
    couponFacility: state?.GetCouponFacility?.data,
  }));

  // Services
  const { couponServices } = useSelector(state => ({
    couponServices: state?.GetCouponServices?.data,
  }));

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    couponsInfoId: '',
    expiryDate: '',
    isGeneral: false,
    projects: [],
    partner: null,
    facility: null,
    services: [],
    allServices: false,
    exceptServices: [],
  };
  const [state, setState] = useState(initialObj);
  const [projects, setProjects] = useState([]);
  const [partners, setPartners] = useState([]);
  const [locations, setLocations] = useState([]);
  const [services, setServices] = useState([]);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);
  const [isFacilityDisabled, setIsFacilityDisabled] = useState(true);
  const [isServiceDisabled, setIsServiceDisabled] = useState(true);

  const [modal_varying1, setmodal_varying1] = useState(true);

  useEffect(() => {
    setState({
      couponsInfoId: data?.couponLogId?.id,
      expiryDate: data?.couponLogId?.expiryDate,
      isGeneral: false,
      projects: [],
      partner: null,
      facility: null,
      services: [],
      allServices: false,
      exceptServices: [],
    });

    setProjects(
      data?.projects?.map(project => {
        return { value: project.id, label: project.name, data: project };
      }),
    );
  }, []);

  useEffect(() => {
    // if (couponPartners?.length > 0) {
    //   setPartners(
    //     couponPartners?.map((partner) => {
    //       return {
    //         value: partner?.objectId,
    //         label: partner?.name,
    //         data: partner,
    //       }
    //     }),
    //   )
    // } else {
    //   setPartners([])
    // }

    if (couponFacility?.length > 0) {
      // setLocations(
      //   couponFacility?.map((facility) => {
      //     return { value: facility?.objectId, label: facility?.name }
      //   }),
      // )
      setIsFacilityDisabled(false);
    } else {
      setIsFacilityDisabled(true);
      // setLocations([])
    }

    if (couponServices?.length > 0) {
      // setServices(
      //   couponServices?.map((service) => {
      //     return { value: service?.objectId, label: service?.name }
      //   }),
      // )
      setIsServiceDisabled(false);
    } else {
      setIsServiceDisabled(true);
      // setServices([])
    }
  }, [couponPartners, couponFacility, couponServices]);

  // useEffect(() => {
  //   if (couponPartners === null) {
  //     dispatch({
  //       type: GET_COUPON_PARTNER,
  //     })
  //   }
  // }, [dispatch])

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'general':
        updatedState = {
          ...state,
          isGeneral: e.target.checked,
          projects: e.target.checked ? [] : state.projects,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'allServices':
        updatedState = {
          ...state,
          allServices: e.target.checked,
          services: [],
          exceptServices: [],
        };
        setIsServiceDisabled(e.target.checked);
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- INPUT PROJECT HANDLER ---------------------------- */
  const inputProjectHandler = data => {
    const updatedState = {
      ...state,
      projects: data,
      isGeneral: false,
    };
    setState(updatedState);
    validationCheck(updatedState);
  };

  const inputPartnersHandler = data => {
    const updatedState = {
      ...state,
      partner: data,
      facility: null,
      services: [],
      allServices: false,
      exceptServices: [],
    };
    setState(updatedState);
    validationCheck(updatedState);

    dispatch({
      type: GET_COUPON_FACILITY,
      payload: {
        urlParam: {
          partnerId: data?.value,
        },
      },
    });

    dispatch({
      type: GET_COUPON_SERVICE,
      payload: {
        urlParam: {
          partnerId: data?.value,
        },
      },
    });
  };

  const inputFacilityHandler = data => {
    const updatedState = {
      ...state,
      facility: data,
      services: [],
      exceptServices: [],
      allServices: false,
    };
    setState(updatedState);
    validationCheck(updatedState);

    dispatch({
      type: GET_COUPON_SERVICE,
      payload: {
        urlParam: {
          partnerId: state?.partner?.value,
          facilityId: data?.value,
        },
      },
    });
  };

  const inputServiceHandler = data => {
    const updatedState = {
      ...state,
      services: data,
    };
    setState(updatedState);
    validationCheck(updatedState);
  };

  const inputExceptServices = data => {
    const updatedState = {
      ...state,
      exceptServices: data,
    };
    setState(updatedState);
    validationCheck(updatedState);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const payload = {
      couponsInfoId: state.couponsInfoId,
      expiryDate: new Date(state.expiryDate).toJSON(),
      isGeneral: state.isGeneral,
      projects: state.projects.map(project => {
        return {
          id: project?.value,
          name: project?.label,
          code: project?.data.code,
        };
      }),
      services: state.services.map(service => {
        return {
          id: service.value,
          name: service.label,
        };
      }),
      partnerId: state?.partner?.value,
      facilityId: state?.facility !== null ? state.facility.value : null,
      allServices: state?.allServices,
      exceptServices:
        state?.exceptServices?.length > 0
          ? state?.exceptServices?.map(service => {
              return {
                id: service.value,
                name: service.label,
              };
            })
          : [],
    };

    dispatch({
      type: ASSIGN_COUPON,
      payload: {
        data: payload,
      },
    });

    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'expiryDate':
        updatedState = {
          ...state,
          expiryDate: new Date(date).toISOString(),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data?.couponsInfoId !== '' && data?.expiryDate !== '') {
      if ((data?.projects?.length === 0 && data?.isGeneral === true) || (data?.projects?.length > 0 && data?.isGeneral === false)) {
        if ((data?.services.length === 0 && data.allServices === true) || (data?.services.length > 0 && data?.allServices === false)) {
          setIsSubmitBtnDisabled(false);
        } else {
          setIsSubmitBtnDisabled(true);
        }
      } else {
        setIsSubmitBtnDisabled(true);
      }
    } else {
      setIsSubmitBtnDisabled(true);
    }
  };

  /* ---------------------------- TYPED PARTNER HANDLER ---------------------------- */
  async function loadPartners(search, loadedOptions) {
    return await axios({
      method: `GET`,
      url: `${uaeProjectsBaseURL}/admin/partners`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        name: search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data.objectId,
              label: `${data.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  /* ---------------------------- ON SELECT PARTNER ---------------------------- */
  const onSelectPartner = event => {
    const updatedState = {
      ...state,
      partner: event,
    };
    setState(updatedState);
    validationCheck(updatedState);

    dispatch({
      type: GET_COUPON_FACILITY,
      payload: {
        urlParam: {
          partnerId: event?.value,
        },
      },
    });

    dispatch({
      type: GET_COUPON_SERVICE,
      payload: {
        urlParam: {
          partnerId: event?.value,
        },
      },
    });
  };

  /* ---------------------------- TYPED FACILITY HANDLER ---------------------------- */
  async function loadFacilities(search, loadedOptions) {
    return await axios({
      method: `GET`,
      url: `${uaeProjectsBaseURL}/admin/facilities`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        partnerId: state?.partner !== null ? state?.partner?.value : null,
        name: search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data.objectId,
              label: `${data.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  /* ---------------------------- ON SELECT FACILITY ---------------------------- */
  const onSelectFacilities = event => {
    const updatedState = {
      ...state,
      facility: event,
    };
    setState(updatedState);
    validationCheck(updatedState);

    dispatch({
      type: GET_COUPON_SERVICE,
      payload: {
        urlParam: {
          partnerId: state?.partner?.value,
          facilityId: event?.value,
        },
      },
    });
  };

  /* ---------------------------- TYPED SERVICE HANDLER ---------------------------- */
  async function loadServices(search) {
    return await axios({
      method: `GET`,
      url: `${uaeProjectsBaseURL}/admin/services`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        partnerId: state?.partner !== null ? state?.partner?.value : null,
        facilityId: state?.facility !== null ? state?.facility?.value : null,
        name: search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data.objectId,
              label: `${data.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  /* ---------------------------- ON SELECT SERVICE ---------------------------- */
  const onSelectService = event => {
    const updatedState = {
      ...state,
      services: event,
    };
    setState(updatedState);
    validationCheck(updatedState);
  };

  /* ---------------------------- TYPED EXCEPT SERVICE HANDLER ---------------------------- */
  async function loadExceptServices(search, loadedOptions) {
    return await axios({
      method: `GET`,
      url: `${uaeProjectsBaseURL}/admin/services`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        partnerId: state?.partner !== null ? state?.partner?.value : null,
        facilityId: state?.facility !== null ? state?.facility?.value : null,
        name: search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data.objectId,
              label: `${data.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  /* ---------------------------- ON EXCEPT SELECT SERVICE ---------------------------- */
  const onSelectExceptService = event => {
    const updatedState = {
      ...state,
      exceptServices: event,
    };
    setState(updatedState);
    validationCheck(updatedState);
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Assign Coupon
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log(state)}>Get state</button> */}
        <form>
          <Row>
            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="partner" className="form-label">
                    Partner
                  </Label>
                  {/* <Select
                    id="partners"
                    name="partners"
                    isMulti={false}
                    options={partners}
                    value={state?.partner}
                    onChange={inputPartnersHandler}
                    style={{
                      border: '4px solid #ced4da',
                      bordeRadius: '0.25rem',
                    }}
                  /> */}

                  <AsyncPaginate
                    debounceTimeout={500}
                    value={state?.partner}
                    loadOptions={loadPartners}
                    onChange={e => onSelectPartner(e)}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    noOptionsMessage={() => 'No results found'}
                    cacheUniqs={[['code']]}
                    placeholder="Select..."
                  />
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="location" className="form-label">
                    Facility
                  </Label>
                  {/* <Select
                    value={state?.facility}
                    id="facility"
                    name="facility"
                    isMulti={false}
                    isDisabled={isFacilityDisabled}
                    options={locations}
                    onChange={inputFacilityHandler}
                    style={{
                      border: '4px solid #ced4da',
                      bordeRadius: '0.25rem',
                    }}
                  /> */}

                  <AsyncPaginate
                    debounceTimeout={500}
                    value={state?.facility}
                    loadOptions={loadFacilities}
                    onChange={e => onSelectFacilities(e)}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    noOptionsMessage={() => 'No results found'}
                    cacheUniqs={[['code']]}
                    placeholder="Select..."
                    isDisabled={isFacilityDisabled}
                  />
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div className="d-flex justify-content-between">
                  <Label htmlFor="choices-multiple-default" className="form-label">
                    Services
                    <span className="lbl_mandatory">*</span>
                  </Label>

                  <div>
                    <Label htmlFor="allServices" className="form-label mx-2">
                      All
                    </Label>
                    <div className="form-switch d-inline-block ">
                      <Input
                        className="form-check-input code-switcher"
                        type="checkbox"
                        id="allServices"
                        name="allServices"
                        disabled={state.partner === null}
                        checked={state.allServices}
                        onChange={inputChangeHandler}
                      />
                    </div>
                  </div>
                </div>

                <AsyncPaginate
                  debounceTimeout={500}
                  value={state?.services}
                  loadOptions={loadServices}
                  onChange={e => onSelectService(e)}
                  isMulti={true}
                  closeMenuOnSelect={true}
                  noOptionsMessage={() => 'No results found'}
                  cacheUniqs={[['code']]}
                  placeholder="Select..."
                  isDisabled={isServiceDisabled}
                />
              </Col>
            </div>

            {state?.partner !== null && state?.allServices && (
              <div className="mb-3">
                <Col md={12}>
                  <div className="d-flex justify-content-between">
                    <Label htmlFor="choices-multiple-default" className="form-label">
                      Except Services
                    </Label>
                  </div>

                  <AsyncPaginate
                    debounceTimeout={500}
                    value={state?.exceptServices}
                    loadOptions={loadExceptServices}
                    onChange={e => onSelectExceptService(e)}
                    isMulti={true}
                    closeMenuOnSelect={true}
                    noOptionsMessage={() => 'No results found'}
                    cacheUniqs={[['code']]}
                    placeholder="Select..."
                  />
                </Col>
              </div>
            )}

            <div className="mb-3">
              <Col md={12}>
                <div className="d-flex justify-content-between">
                  <Label htmlFor="choices-multiple-default" className="form-label">
                    Select Program <span className="lbl_mandatory">*</span>
                  </Label>
                  <div>
                    <Label htmlFor="general" className="form-label mx-2">
                      Global Pool
                    </Label>
                    <div className="form-switch d-inline-block ">
                      <Input
                        className="form-check-input code-switcher"
                        type="checkbox"
                        id="general"
                        name="general"
                        checked={state.isGeneral}
                        onChange={inputChangeHandler}
                      />
                    </div>
                  </div>
                </div>
                <Select
                  value={state?.projects}
                  id="programs"
                  name="programs"
                  isMulti={true}
                  onChange={inputProjectHandler}
                  options={projects}
                  style={{
                    border: '4px solid #ced4da',
                    bordeRadius: '0.25rem',
                  }}
                />
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="expiry-date" className="form-label">
                    Expiry Date <span className="lbl_mandatory">* </span>
                  </Label>
                  <div className="area_expandable">
                    <Flatpickr
                      className="form-control"
                      name="expiry-date"
                      id="expiry-date"
                      value={state?.expiryDate}
                      onChange={date => datePickHandler(`expiryDate`, date)}
                      options={{
                        // minDate: 'today',
                        defaultMinute: '59',
                        defaultHour: '23',
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',
                      }}
                    />
                  </div>
                </div>
              </Col>
            </div>
          </Row>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default React.memo(AssignedModal);
