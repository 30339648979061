import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Tracker from './Tracker';
import RecordData from './RecordData';
import { DELETE_TRACKER_RECORD_EMPTY, GET_TRACKER_DATA_EMPTY } from '../../../../../store/actions';

const initialFilters = {
  status: '',
  startDate: '',
  endDate: '',
  uploadBy: '',
  facilityName: '',
};

const MasterUpload = () => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [currentTracker, setCurrentTracker] = useState(null);

  const [isTrackerShow, setIsTrackerShow] = useState(true);
  const [isRecordDataShow, setIsRecordDataShow] = useState(false);

  const [filters, setFilters] = useState(initialFilters);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  /* ---------------------------- REDUX STATES ---------------------------- */
  //  DELETE RECORD
  const { deleteRecord } = useSelector(state => ({
    deleteRecord: state?.DeleteTrackerRecord?.data,
  }));

  /* ----------------------------  DISPATCHING ---------------------------- */
  useEffect(() => {
    if (deleteRecord !== null) {
      setIsTrackerShow(true);
      setIsRecordDataShow(false);

      dispatch({
        type: DELETE_TRACKER_RECORD_EMPTY,
      });
    }
  }, [deleteRecord]);

  useEffect(() => {
    return () => {
      dispatch({
        type: GET_TRACKER_DATA_EMPTY,
      });
    };
  }, []);

  /* ---------------------------- VIEW RECORD DATA HANDLER ---------------------------- */
  const trackerSelectHandler = trackerData => {
    setCurrentTracker(trackerData);

    setIsTrackerShow(false);
    setIsRecordDataShow(true);
  };

  /* ---------------------------- VIEW TRACKER DATA HANDLER ---------------------------- */
  const toggleToTrackerHandler = () => {
    setCurrentTracker(null);

    setIsTrackerShow(true);
    setIsRecordDataShow(false);
  };

  return (
    <div className="page-content">
      {isTrackerShow && (
        <Tracker
          onView={trackerSelectHandler}
          currentPageNumber={currentPageNumber}
          setCurrentPageNumber={setCurrentPageNumber}
          filters={filters}
          setFilters={setFilters}
        />
      )}
      {isRecordDataShow && <RecordData selectedTracker={currentTracker} onViewTrackerDetails={toggleToTrackerHandler} />}
    </div>
  );
};
export default MasterUpload;
