import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';

import { Button, Modal, ModalBody, ModalHeader, Col, Label, Input, Row } from 'reactstrap';
import { AsyncPaginate } from 'react-select-async-paginate';
import { customHeaders, uaeProjectsBaseURL, baseUrl } from './../../../../Config';
import { Constant } from '../../../../Helpers/constant';
import { POST_COUPONS } from './../../../../../store/actions';
import customerDataDummyExcel from './../../../../../assets/static-files/Customer-data-format.xlsx';

const UploadCustomerData = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- REDUX STATES ---------------------------- */

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    file: '',
    benefitGroup: null,
  };

  const [state, setState] = useState(initialObj);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  const [modal_varying1, setmodal_varying1] = useState(true);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'uploaded-coupon':
        updatedState = { ...state, file: value, fileData: e.target.files[0] };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const formData = new FormData();
    formData.append('file', state?.fileData);
    formData.append('type', 7);
    formData.append('benefitGroup', state?.benefitGroup?.value);

    dispatch({
      type: POST_COUPONS,
      payload: {
        data: formData,
      },
    });

    setmodal_varying1(!modal_varying1);
    onCloseHandler();
  };

  /* ---------------------------- TYPED SERVICE HANDLER ---------------------------- */
  async function loadBenefitGroup(search, loadedOptions) {
    return await axios({
      method: `GET`,
      url: `${baseUrl}/admin/benefitGroups`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        name: search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data.objectId,
              label: `${data.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  /* ---------------------------- ON SELECT SERVICE ---------------------------- */
  const onSelectBenefitGroup = event => {
    const updatedState = {
      ...state,
      benefitGroup: event,
    };
    setState(updatedState);
    validationCheck(updatedState);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data?.file !== `` && data?.benefitGroup !== null) {
      setIsSubmitBtnDisabled(false);
    } else {
      setIsSubmitBtnDisabled(true);
    }
  };

  /* ---------------------------- DOWNLOAD DUMMY EXCEL FORMAT HANDLER ---------------------------- */
  const downloadSampleHandler = () => {
    window.open(customerDataDummyExcel, '_self');
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Upload Customers
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log(state)}>Get state</button> */}
        <form>
          <Row>
            <div className="mb-3">
              <Col md={12}>
                <div>
                  {/* <Label
                    htmlFor="formatExcel"
                    className="form-label"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span>
                      Upload Customers <span className="lbl_mandatory">*</span>
                    </span>
                  </Label> */}

                  <Label htmlFor="formatExcel" className="form-label" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>
                      Upload Customers<span className="lbl_mandatory">*</span>
                    </span>
                    <span className="download-sample-excel" onClick={downloadSampleHandler}>
                      Download Sample Format
                    </span>
                  </Label>
                  <Input
                    type="file"
                    name="uploaded-coupon"
                    id="uploaded-coupon"
                    value={state.file}
                    onChange={inputChangeHandler}
                    className="form-control"
                  />
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div className="d-flex justify-content-between">
                  <Label htmlFor="benefit-group" className="form-label">
                    Benefit Group
                    <span className="lbl_mandatory">*</span>
                  </Label>
                </div>

                <AsyncPaginate
                  debounceTimeout={500}
                  value={state?.services}
                  loadOptions={loadBenefitGroup}
                  onChange={e => onSelectBenefitGroup(e)}
                  isMulti={false}
                  closeMenuOnSelect={true}
                  noOptionsMessage={() => 'No results found'}
                  cacheUniqs={[['code']]}
                  placeholder="Select..."
                  //   isDisabled={isServiceDisabled}
                />
              </Col>
            </div>
          </Row>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default React.memo(UploadCustomerData);
