import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'reactstrap';

import Table from './Components/Tables/Table';
import Filter from './Components/Filters/Filter';
import CancelModal from './Components/Modals/CancelModal';
import { Constant } from '../../../../../Helpers/constant';
import { BreadCrumb, Loader } from './../../../../../Common';
import ConfirmationModal from './Components/Modals/ConfirmationModal';
import {
  GET_CLIENT_HISTORY_EMPTY,
  GET_CLIENT_HISTORY_LIST,
  GET_VENDOR_DOWNLOAD_BOOKINGS,
  GET_VENDOR_DOWNLOAD_BOOKINGS_EMPTY,
} from '../../../../../../store/application/actionType';

const ClientGolfBooking = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [bookingTypeId, setBookingTypeId] = useState(Constant?.bookingTypes?.GOLF_BOOKINGS);

  const [loading, setLoading] = useState(false);
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  const [selectedDetails, setSelectedDetails] = useState(null);
  const [modalAction, setModalAction] = useState(null);
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(null);
  const [isShowCancelModal, setIsShowCancelModal] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // GET BOOKINGS
  const { GetHistoryList, GetHistoryLoading } = useSelector(state => ({
    GetHistoryList: state?.GetClientHistoryList?.data,
    GetHistoryLoading: state?.GetClientHistoryList?.loading,
  }));

  // DOWNLOAD DUMP
  const { downloadDump, downloadDumpLoading } = useSelector(state => ({
    downloadDump: state?.DownloadBookingsDump?.data,
    downloadDumpLoading: state?.DownloadBookingsDump?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_CLIENT_HISTORY_LIST,
      payload: {
        urlParam: {
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          bookingTypeId,
        },
        headers: {
          code: 'ADIB',
        },
      },
    });

    return () => {
      dispatch({
        type: GET_CLIENT_HISTORY_EMPTY,
      });
    };
  }, []);

  useEffect(() => {
    if (downloadDump !== null) {
      dispatch({
        type: GET_VENDOR_DOWNLOAD_BOOKINGS_EMPTY,
      });
    }
  }, [downloadDump]);

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);

    dispatch({
      type: GET_CLIENT_HISTORY_LIST,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          bookingTypeId,
        },
        data: { ...currentlyAppliedFilters },
        headers: {
          code: 'ADIB',
        },
      },
    });
  };

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = filters => {
    setCurrentlyAppliedFilters(filters);

    setCurrentPageNumber(0);

    dispatch({
      type: GET_CLIENT_HISTORY_LIST,
      payload: {
        urlParam: {
          pageIndex: 0,
          bookingTypeId,
        },
        data: filters,
        headers: {
          code: 'ADIB',
        },
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setSelectedDetails(params?.bookingDetails);
    setModalAction(params?.actionType);

    if (params?.actionType === `PROCESS`) {
      setIsShowConfirmationModal(true);
    } else if (params?.actionType === `CONFIRM`) {
      setIsShowConfirmationModal(true);
    } else if (params?.actionType === `CANCEL`) {
      setIsShowCancelModal(true);
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = (remarks = '') => {
    setIsShowConfirmationModal(false);
    setIsShowCancelModal(false);

    if (modalAction === `PROCESS`) {
      //
    } else if (modalAction === `CONFIRM`) {
      //
    } else if (modalAction === `CANCEL`) {
      //
    }
  };

  /* ---------------------------- DOWNLOAD DUMP HANDLER ---------------------------- */
  const downloadDumpHandler = () => {
    dispatch({
      type: GET_VENDOR_DOWNLOAD_BOOKINGS,
      payload: {
        urlParam: {
          bookingTypeId,
          downloadType: 1,
          projectId: JSON.parse(window.localStorage.getItem('data'))?.program?.id,
        },
        data: {
          // vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
          ...currentlyAppliedFilters,
        },
        headers: {
          'Project-Code': 'ADIB_V2',
        },
      },
    });
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/client/dashboard`);
    } else if (title === `Bookings Report`) {
      // nothing to do here
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (GetHistoryLoading || downloadDumpLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [GetHistoryLoading, downloadDumpLoading]);

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        {isShowConfirmationModal && (
          <ConfirmationModal bookingData={selectedDetails} setIsShowConfirmationModal={setIsShowConfirmationModal} submitHandler={submitHandler} />
        )}

        {isShowCancelModal && <CancelModal data={selectedDetails} setIsShowCancelModal={setIsShowCancelModal} submitHandler={submitHandler} />}

        {/*  ----------------------------  UI'S ---------------------------- */}
        <Container fluid className="position-relative">
          <>
            <BreadCrumb title="Bookings Report" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Bookings Report']} />
            <Filter
              filterHandler={filterSubmitHandler}
              appliedFilters={currentlyAppliedFilters}
              setCurrentPageNumber={setCurrentPageNumber}
              bookingTypeId={bookingTypeId}
            />
            <Table
              title={`Bookings Report`}
              data={GetHistoryList?.results ?? []}
              totalData={GetHistoryList?.count}
              pageHandler={paginationHandler}
              pageNumber={currentPageNumber}
              actionHandler={tableActionHandler}
              dumpDownload={downloadDumpHandler}
            />
          </>
        </Container>
      </div>
    </>
  );
};

export default ClientGolfBooking;
