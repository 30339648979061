import cogoToast from 'cogo-toast';
import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../Containers/Config/index.js';
import { Constant } from '../../../../Containers/Helpers/constant.js';
import apiCall from '../../apiCall.js';

import { UPDATE_STATIC_COUPON, UPDATE_STATIC_COUPON_SUCCESS, UPDATE_STATIC_COUPON_ERROR } from './../../actionType.js';

function* updateStaticCoupon({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/static-coupon`,
      method: 'PUT',
      data: payload.data,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
    });

    if (response.status === 200) {
      cogoToast.success(response.data.message);
      yield put({
        type: UPDATE_STATIC_COUPON_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: UPDATE_STATIC_COUPON_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: UPDATE_STATIC_COUPON_ERROR, payload: error });
  }
}

export function* watchStaticUpdateCoupon() {
  yield takeEvery(UPDATE_STATIC_COUPON, updateStaticCoupon);
}

function* updateStaticCouponSaga() {
  yield all([fork(watchStaticUpdateCoupon)]);
}

export default updateStaticCouponSaga;
