import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from '../../../../Common';
import { Container } from 'reactstrap';
import { GET_CLIENTS, CREATE_PROJECT_EMPTY, CREATE_CLIENTS_EMPTY, GET_COUNTRY } from '../../../../../store/application/actionType';
import { Constant } from '../../../../Helpers/constant';
import ClientCreationForm from './Components/Forms/ClientCreation';
import ClientsList from './Components/Tables/ClientsList';

const Clients = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [isClientsShow, setIsClientsShow] = useState(true);
  const [isCreateClientFormShow, setIsCreateClientFormShow] = useState(false);

  const [currentSelectedDetails, setCurrentSelectedDetails] = useState([]);
  const [currentConfirmationModalActionFor, setCurrentConfirmationModalActionFor] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Client List
  const { clients, clientsLoading } = useSelector(state => ({
    clients: state?.Clients?.data,
    clientsLoading: state?.Clients?.loading,
  }));

  // Create Client
  const { createClient, createClientLoading } = useSelector(state => ({
    createClient: state?.CreateClient?.data,
    createClientLoading: state?.CreateClient?.loading,
  }));

  // Countries List
  const { countries, countriesLoading } = useSelector(state => ({
    countries: state?.Countries?.data,
    countriesLoading: state?.Countries?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (countries === null) {
      dispatch({
        type: GET_COUNTRY,
      });
    }

    dispatch({
      type: GET_CLIENTS,
      payload: {
        urlParam: {
          pageIndex: currentPageIndex,
          pageSize: 100,
        },
      },
    });

    return () => {
      dispatch({
        type: CREATE_CLIENTS_EMPTY,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (createClient !== null) {
      dispatch({
        type: CREATE_CLIENTS_EMPTY,
      });

      dispatch({
        type: GET_CLIENTS,
        payload: {
          urlParam: {
            pageIndex: currentPageIndex,
            pageSize: 100,
          },
        },
      });
      setIsClientsShow(true);
      setIsCreateClientFormShow(false);
    }
  }, [createClient]);

  /* ---------------------------- BUTTON HANDLER ---------------------------- */
  const createClientBtnHandler = () => {
    setIsClientsShow(false);
    setIsCreateClientFormShow(true);
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (countriesLoading || clientsLoading || createClientLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [countriesLoading, clientsLoading, createClientLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Client Management`) {
      setIsClientsShow(true);
      setIsCreateClientFormShow(false);

      dispatch({
        type: GET_CLIENTS,
        payload: {
          urlParam: {
            pageIndex: currentPageIndex,
            pageSize: 100,
          },
        },
      });
    } else if (title === `Information`) {
      // do nothing
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {isClientsShow && (
            <>
              <BreadCrumb title="Project" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Client Management`]} />
              <ClientsList clients={clients?.results} createNew={createClientBtnHandler} />
            </>
          )}

          {isCreateClientFormShow && (
            <>
              <BreadCrumb title="Project" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Client Management`, `Create`]} />
              <ClientCreationForm countries={countries} />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default Clients;
