import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../Containers/Config/index.js';
import { GET_PERMISSIONS_BY_PROJECT_ID, GET_PERMISSIONS_BY_PROJECT_ID_SUCCESS, GET_PERMISSIONS_BY_PROJECT_ID_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getPermissionsByProjectId({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/permissions`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload.urlParam,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_PERMISSIONS_BY_PROJECT_ID_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: GET_PERMISSIONS_BY_PROJECT_ID_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_PERMISSIONS_BY_PROJECT_ID_ERROR, payload: error });
  }
}

export function* watchPermissionsByProjectId() {
  yield takeEvery(GET_PERMISSIONS_BY_PROJECT_ID, getPermissionsByProjectId);
}

function* permissionsByProjectIdSaga() {
  yield all([fork(watchPermissionsByProjectId)]);
}

export default permissionsByProjectIdSaga;
