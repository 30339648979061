import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Flatpickr from 'react-flatpickr';
import * as moment from 'moment';
import { Card, CardBody, Col, Form, Label, Row, CardFooter, Input, UncontrolledDropdown } from 'reactstrap';

import {
  GET_BOOKING_LIST_EMPTY,
  GET_REGION,
  GET_REGION_EMPTY,
  GET_SUB_REGION,
  GET_SUB_REGION_EMPTY,
} from '../../../../../../../store/application/actionType';
import { PreviewCardHeader } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';

const statuses = [
  {
    name: `Pending`,
    objectId: Constant?.BOOKING_STATUS?.PENDING,
  },
  {
    name: `Processed`,
    objectId: Constant?.BOOKING_STATUS?.PROCESSED,
  },
  {
    name: `Confirmed`,
    objectId: Constant?.BOOKING_STATUS?.CONFIRM,
  },
  {
    name: `Cancelled (ALL)`,
    objectId: Constant?.BOOKING_STATUS?.CANCELLED,
  },
  {
    name: `Cancelled by User`,
    objectId: Constant?.BOOKING_STATUS?.CANCEL_BY_USER,
  },
  {
    name: `Cancelled by Thriwe`,
    objectId: Constant?.BOOKING_STATUS?.CANCEL_BY_THRIWE,
  },
  {
    name: `Short Notice Cancelled by User`,
    objectId: Constant?.BOOKING_STATUS?.SHORT_NOTICE_CANCELLED_BY_USER,
  },
  {
    name: `Short Notice Cancelled by Thriwe`,
    objectId: Constant?.BOOKING_STATUS?.SHORT_NOTICE_CANCELLED_BY_THRIWE,
  },
];

const initialState = {
  status: 0,
  createdAt: ``,
  createdAtRange: {
    from: ``,
    to: ``,
  },
  bookingDate: ``,
  bookingDateRange: {
    from: ``,
    to: ``,
  },
  updatedAtRange: {
    from: ``,
    to: ``,
  },
  category: ``,
  partner: ``,
  facility: ``,
  service: ``,
  countryId: 0,
  regionId: 0,
  subRegionId: 0,

  bookingId: ``,
  membershipId: ``,
  expiryDate: ``,
  paymentStatus: 0,
  redeemedDate: ``,
  couponCode: ``,
  familyMemberName: ``,
  customerDetails: {
    name: ``,
    email: ``,
    mobile: ``,
  },
  slaBreached: false,
  sortOn: 'updatedAt',
};

const Filter = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [filter, setFilter] = useState(initialState);

  const [facilities, setFacilities] = useState(null);

  const [searchCode, setSearchCode] = useState(`0`);
  const [searchValue, setSearchValue] = useState(``);

  useEffect(() => {
    if (props?.appliedFilters) {
      setFilter(props?.appliedFilters);
    }
  }, [props?.appliedFilters]);

  useEffect(() => {
    setFacilities(props?.data?.locations);
  }, [props]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...filter };

    switch (name) {
      case 'search':
        setSearchCode(value);
        setSearchValue(``);
        break;

      case 'search-value':
        if (searchCode !== '0') {
          setSearchValue(value);
        }
        break;

      case 'category':
        updatedState = {
          ...filter,
          category: value,
        };
        break;

      case 'partner':
        updatedState = {
          ...filter,
          partner: value,
        };
        break;

      case 'facility':
        updatedState = {
          ...filter,
          facility: value,
        };
        break;

      case 'service':
        updatedState = {
          ...filter,
          service: value,
        };
        break;

      case 'booking-id':
        updatedState = {
          ...filter,
          bookingId: value,
        };
        break;

      case 'membership-id':
        updatedState = {
          ...filter,
          membershipId: value,
        };
        break;

      case 'customer-name':
        updatedState = {
          ...filter,
          customerDetails: {
            ...filter.customerDetails,
            name: value,
          },
        };
        break;

      case 'customer-mobile':
        updatedState = {
          ...filter,
          customerDetails: {
            ...filter.customerDetails,
            mobile: value,
          },
        };
        break;

      case 'customer-email':
        updatedState = {
          ...filter,
          customerDetails: {
            ...filter.customerDetails,
            email: value,
          },
        };
        break;

      case 'coupon-code':
        updatedState = {
          ...filter,
          couponCode: value,
        };
        break;

      case 'payment-status':
        updatedState = {
          ...filter,
          paymentStatus: parseInt(value),
        };
        break;

      case 'status-type':
        updatedState = {
          ...filter,
          status: parseInt(value),
        };
        break;

      case 'country':
        updatedState = {
          ...filter,
          countryId: parseInt(value),
          regionId: 0,
          subRegionId: 0,
        };

        dispatch({
          type: GET_REGION,
          payload: {
            urlParam: {
              countryId: parseInt(value),
            },
          },
        });

        dispatch({
          type: GET_SUB_REGION_EMPTY,
        });
        break;

      case 'region':
        updatedState = { ...filter, regionId: parseInt(value), subRegionId: 0 };

        dispatch({
          type: GET_SUB_REGION,
          payload: {
            urlParam: {
              regionId: parseInt(value),
            },
          },
        });
        break;

      case 'sub-region':
        updatedState = { ...filter, subRegionId: parseInt(value) };
        break;

      case 'family-member-name':
        updatedState = {
          ...filter,
          familyMemberName: value,
        };
        break;

      case 'sla':
        updatedState = { ...filter, slaBreached: value === 'true' };
        break;

      case 'sortOn':
        updatedState = { ...filter, sortOn: value };
        break;

      default:
      // do nothing
    }

    setFilter(updatedState);
  };

  /* ---------------------------- DATE PICKR HANDLER ---------------------------- */
  const datePickHandler = (flag, date) => {
    let updatedState = { ...filter };

    switch (flag) {
      case 'createdAtRange':
        updatedState = {
          ...filter,
          createdAtRange: {
            from: moment(new Date(date[0])).format('Y-MM-DD'),
            to: moment(new Date(date[1])).format('Y-MM-DD'),
          },
        };
        break;

      case 'updatedAtRange':
        updatedState = {
          ...filter,
          updatedAtRange: {
            from: moment(new Date(date[0])).format('Y-MM-DD'),
            to: moment(new Date(date[1])).format('Y-MM-DD'),
          },
        };
        break;

      case 'dateOfPlayRange':
        updatedState = {
          ...filter,
          bookingDateRange: {
            from: moment(new Date(date[0])).format('Y-MM-DD'),
            to: moment(new Date(date[1])).format('Y-MM-DD'),
          },
        };
        break;

      case 'expiryDate':
        updatedState = {
          ...filter,
          expiryDate: moment(new Date(date)).format('Y-MM-DD'),
        };
        break;

      case 'redemeedDate':
        updatedState = {
          ...filter,
          redeemedDate: moment(new Date(date)).format('Y-MM-DD'),
        };
        break;

      default:
      // do nothing
    }

    setFilter(updatedState);
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    let filterValue = { ...filter, customerDetails: { ...initialState.customerDetails }, bookingId: '', membershipId: '' };

    if (searchCode !== '0' && searchValue !== ``) {
      if (searchCode === '1') {
        filterValue = {
          ...filterValue,
          customerDetails: {
            ...filterValue.customerDetails,
            name: searchValue,
          },
        };
        setFilter(filterValue);
      } else if (searchCode === '2') {
        filterValue = {
          ...filterValue,
          customerDetails: {
            ...filterValue.customerDetails,
            mobile: searchValue,
          },
        };
        setFilter(filterValue);
      } else if (searchCode === '3') {
        filterValue = {
          ...filterValue,
          customerDetails: {
            ...filterValue.customerDetails,
            email: searchValue,
          },
        };
        setFilter(filterValue);
      } else if (searchCode === '4') {
        filterValue = {
          ...filterValue,
          bookingId: searchValue,
        };
        setFilter(filterValue);
      } else if (searchCode === '5') {
        filterValue = {
          ...filterValue,
          membershipId: searchValue,
        };
        setFilter(filterValue);
      }
    }
    props.filterHandler({ ...filterValue, filter: true });
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter(initialState);
    setSearchCode('0');
    setSearchValue(``);
    props.filterHandler({ ...initialState, filter: false });

    dispatch({
      type: GET_BOOKING_LIST_EMPTY,
    });

    dispatch({
      type: GET_REGION_EMPTY,
    });
    dispatch({
      type: GET_SUB_REGION_EMPTY,
    });
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Filter" />
            {/* <button onClick={() => console.log({ facilities })}>
              get state
            </button> */}
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="search" className="form-label">
                          Search
                        </Label>
                        <UncontrolledDropdown className="input-group">
                          <select
                            id="search"
                            name="search"
                            className="form-select truncate cursor-pointer"
                            style={{ maxWidth: '35%' }}
                            value={searchCode}
                            onChange={inputChangeHandler}
                          >
                            <option value="0">Select</option>
                            <option value="1">First name</option>
                            <option value="2">Mobile</option>
                            <option value="3">Email</option>
                            <option value="4">Booking Id</option>
                            {/* <option value="5">Membership Id</option> */}
                          </select>
                          <Input
                            id="search-value"
                            name="search-value"
                            type="text"
                            className="form-control"
                            aria-label="Text input with dropdown button"
                            value={searchValue}
                            onChange={inputChangeHandler}
                          />
                        </UncontrolledDropdown>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="status-type" className="form-label">
                          Booking Status
                        </Label>
                        <select id="status-type" name="status-type" className="form-select" value={filter?.status} onChange={inputChangeHandler}>
                          <option value="0">Choose...</option>
                          {statuses?.map((status, index) => (
                            <option key={index} value={status.objectId}>
                              {status.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="facility" className="form-label">
                          Facility Name
                        </Label>
                        <Input
                          id="facility"
                          name="facility"
                          type="text"
                          className="form-control"
                          aria-label="Text input with dropdown button"
                          value={filter.facility}
                          onChange={inputChangeHandler}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="created-at-range" className="form-label">
                          Created At - Range
                          <span className="icon_calendar">
                            <i className="las la-calendar"></i>
                          </span>
                        </Label>
                        <div className="area_expandable">
                          <Flatpickr
                            className="form-control"
                            onChange={date => datePickHandler(`createdAtRange`, date)}
                            value={[filter?.createdAtRange?.from, filter?.createdAtRange?.to]}
                            options={{
                              mode: 'range',
                              dateFormat: 'Y-m-d',
                              maxDate: 'today',
                            }}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="date-of-play-range" className="form-label">
                          Updated At - Range
                          <span className="icon_calendar">
                            <i className="las la-calendar"></i>
                          </span>
                        </Label>
                        <div className="area_expandable">
                          <Flatpickr
                            className="form-control"
                            value={[filter?.updatedAtRange?.from, filter?.updatedAtRange?.to]}
                            onChange={date => datePickHandler(`updatedAtRange`, date)}
                            options={{
                              mode: 'range',
                              dateFormat: 'Y-m-d',
                              maxDate: 'today',
                            }}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="sortOn" className="form-label">
                          Sort By
                        </Label>
                        <select id="sortOn" name="sortOn" className="form-select" value={filter?.sortOn} onChange={inputChangeHandler}>
                          <option value="updatedAt">Updated At</option>
                          <option value="createdAt">Created At</option>
                          {/* <option value="displayName">Display Name</option> */}
                          {/* <option value="membershipId">Membership Id</option> */}
                          {/* <option value="bookingStatusCode">Booking Status</option> */}
                        </select>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>

            <CardFooter>
              <Row>
                <Col md={12}>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary mx-3" onClick={filterSubmitHandler}>
                      <i className="ri-search-line me-1 text-white fs-14 align-middle"></i>Search
                    </button>

                    <button type="submit" className="btn btn-light" onClick={resetFilterHandler}>
                      Reset Filter
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Filter;
