import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../Containers/Config/index.js';
import { UPDATE_PAYMENT_SETTING, UPDATE_PAYMENT_SETTING_SUCCESS, UPDATE_PAYMENT_SETTING_ERROR } from '../../actionType.js';
import apiCall from '../../apiCall.js';
import { Constant } from '../../../../Containers/Helpers/constant.js';
import cogoToast from 'cogo-toast';

function* updatePayment({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/project-configs`,
      method: 'PUT',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      cogoToast.success(`Payment settings updated successfully`);
      yield put({
        type: UPDATE_PAYMENT_SETTING_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: UPDATE_PAYMENT_SETTING_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: UPDATE_PAYMENT_SETTING_ERROR, payload: error });
  }
}

export function* watchUpdatePayment() {
  yield takeEvery(UPDATE_PAYMENT_SETTING, updatePayment);
}

function* updatePaymentSaga() {
  yield all([fork(watchUpdatePayment)]);
}

export default updatePaymentSaga;
