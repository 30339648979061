export class PermissionConstants {
  static ALL_PERMISSIONS = {
    /* --------------------------- BOOKING MANAGEMENT --------------------------- */
    BOOKING_MANAGEMENT: `BOOKING_MANAGEMENT`,

    // GOLF BOOKING
    GOLF_BOOKING_READ: `GOLF_BOOKING_READ`,
    GOLF_BOOKING_UPDATE: `GOLF_BOOKING_UPDATE`,

    // AIRPORT TRANSFER BOOKING
    PICKUP_AND_DROP_BOOKING_READ: `PICKUP_AND_DROP_BOOKING_READ`,
    PICKUP_AND_DROP_BOOKING_UPDATE: `PICKUP_AND_DROP_BOOKING_UPDATE`,

    // MEET & GREET BOOKING
    MEET_AND_GREET_BOOKING_READ: `MEET_AND_GREET_BOOKING_READ`,
    MEET_AND_GREET_BOOKING_UPDATE: `MEET_AND_GREET_BOOKING_UPDATE`,

    // LOCAL COURIER BOOKING
    LOCAL_COURIER_BOOKING_READ: `LOCAL_COURIER_BOOKING_READ`,
    LOCAL_COURIER_BOOKING_UPDATE: `LOCAL_COURIER_BOOKING_UPDATE`,

    // QR BOOKING
    QR_BOOKING_READ: `QR_BOOKING_READ`,
    QR_BOOKING_UPDATE: `QR_BOOKING_UPDATE`,

    // THRIWE PRIME BOOKING
    THRIWE_PRIME_BOOKING_READ: `THRIWE_PRIME_BOOKING_READ`,

    // CLUB BOOKING
    CLUB_BOOKING_READ: `CLUB_BOOKING_READ`,
    CLUB_BOOKING_UPDATE: `CLUB_BOOKING_UPDATE`,

    // COUPON BOOKING - UNIQUE
    UNIQUE_COUPON_BOOKING_READ: `UNIQUE_COUPON_BOOKING_READ`,
    UNIQUE_COUPON_BOOKING_UPDATE: `UNIQUE_COUPON_BOOKING_UPDATE`,

    // COUPON BOOKING - STATIC
    STATIC_COUPON_BOOKING_READ: `STATIC_COUPON_BOOKING_READ`,
    STATIC_COUPON_BOOKING_UPDATE: `STATIC_COUPON_BOOKING_UPDATE`,

    // COUPON BOOKING - DELAYED
    DELAYED_COUPON_BOOKING_READ: `DELAYED_COUPON_BOOKING_READ`,
    DELAYED_COUPON_BOOKING_UPDATE: `DELAYED_COUPON_BOOKING_UPDATE`,

    // INSURANCE BOOKING
    INSURANCE_BOOKING_UPDATE: `INSURANCE_BOOKING_UPDATE`,
    INSURANCE_BOOKING_READ: `INSURANCE_BOOKING_READ`,

    // CAR SERVICING BOOKING
    CAR_SERVICING_BOOKING_READ: `CAR_SERVICING_BOOKING_READ`,
    CAR_SERVICING_BOOKING_UPDATE: `CAR_SERVICING_BOOKING_UPDATE`,

    // CAR REGISTRATION BOOKING
    CAR_REGISTRATION_BOOKING_READ: `CAR_REGISTRATION_BOOKING_READ`,
    CAR_REGISTRATION_BOOKING_UPDATE: `CAR_REGISTRATION_BOOKING_UPDATE`,

    // HIRE A DRIVER BOOKING
    HIRE_A_DRIVER_BOOKING_READ: `HIRE_A_DRIVER_BOOKING_READ`,
    HIRE_A_DRIVER_BOOKING_UPDATE: `HIRE_A_DRIVER_BOOKING_UPDATE`,

    // VALET PARKING BOOKING
    VALET_PARKING_BOOKING_READ: `VALET_PARKING_BOOKING_READ`,
    VALET_PARKING_BOOKING_UPDATE: `VALET_PARKING_BOOKING_UPDATE`,

    // DINING BOOKING
    OUTLET_BOOKING_READ: `OUTLET_BOOKING_READ`,
    OUTLET_BOOKING_UPDATE: `OUTLET_BOOKING_UPDATE`,

    // DYNAMIC QR BOOKING
    DYNAMIC_QR_BOOKING_READ: `DYNAMIC_QR_BOOKING_READ`,
    DYNAMIC_QR_BOOKING_UPDATE: `DYNAMIC_QR_BOOKING_UPDATE`,

    // CARTERX BOOKING
    CARTERX_BOOKING_READ: `CARTERX_BOOKING_READ`,

    // DYNAMIC COUPON BOOKING
    DYNAMIC_COUPON_BOOKING_READ: `DYNAMIC_COUPON_BOOKING_READ`,

    // DYNAMIC BOOKING
    DYNAMIC_BOOKING_READ: `DYNAMIC_BOOKING_READ`,
    DYNAMIC_BOOKING_UPDATE: `DYNAMIC_BOOKING_UPDATE`,

    /* --------------------------- RULES MANAGEMENT --------------------------- */
    RULES_MANAGEMENT: `RULES_MANAGEMENT`,

    // EXPENDITURE
    EXPENDITURE_CREATE: `EXPENDITURE_CREATE`,
    EXPENDITURE_READ: `EXPENDITURE_READ`,
    EXPENDITURE_UPDATE: `EXPENDITURE_UPDATE`,
    EXPENDITURE_DELETE: `EXPENDITURE_DELETE`,

    // EVENT
    EVENT_CREATE: `EVENT_CREATE`,
    EVENT_READ: `EVENT_READ`,
    EVENT_UPDATE: `EVENT_UPDATE`,
    EVENT_DELETE: `EVENT_DELETE`,

    // ASSIGN COINS
    ASSIGN_COINS: `ASSIGN_COINS`,
    ASSIGN_COINS_CREATE: `ASSIGN_COINS_CREATE`,
    ASSIGN_COINS_UPDATE: `ASSIGN_COINS_UPDATE`,
    ASSIGN_COINS_DELETE: `ASSIGN_COINS_DELETE`,

    /* --------------------------- COMMUNICATION MANAGEMENT --------------------------- */
    COMMUNICATION: `COMMUNICATION`,
    TEMPLATE_ENGINE_MANAGEMENT: `TEMPLATE_ENGINE_MANAGEMENT`,
    TEMPLATE_ENGINE_CREATE: `TEMPLATE_ENGINE_CREATE`,
    TEMPLATE_ENGINE_READ: `TEMPLATE_ENGINE_READ`,

    /* --------------------------- BLOCK DATE MANAGEMENT --------------------------- */
    SUPPLY_STORE_MANAGEMENT: `SUPPLY_STORE_MANAGEMENT`,

    /* --------------------------- SUPPLY STORE MANAGEMENT --------------------------- */
    BLOCK_DATE_MANAGEMENT: `BLOCK_DATE_MANAGEMENT`,

    // BULK UPLOAD
    UPLOAD_FILE: `UPLOAD_FILE`,
    MASTER_UPLOAD: `MASTER_UPLOAD`,

    // CATEGORY
    CATEGORY_MANAGEMENT: `CATEGORY_MANAGEMENT`,
    CATEGORY_READ: `CATEGORY_READ`,
    CATEGORY_CREATE: `CATEGORY_CREATE`,
    CATEGORY_UPDATE: `CATEGORY_UPDATE`,
    CATEGORY_DELETE: `CATEGORY_DELETE`,
    WORKFLOW_MANAGEMENT: `WORKFLOW_MANAGEMENT`,

    // PARTNER
    PARTNER_MANAGEMENT: `PARTNER_MANAGEMENT`,
    PARTNER_READ: `PARTNER_READ`,
    PARTNER_CREATE: `PARTNER_CREATE`,
    PARTNER_UPDATE: `PARTNER_UPDATE`,
    PARTNER_DELETE: `PARTNER_DELETE`,

    // FACILITY
    FACILITY_MANAGEMENT: `FACILITY_MANAGEMENT`,
    FACILITY_READ: `FACILITY_READ`,
    FACILITY_CREATE: `FACILITY_CREATE`,
    FACILITY_UPDATE: `FACILITY_UPDATE`,
    FACILITY_DELETE: `FACILITY_DELETE`,

    // SERVICE
    SERVICE_MANAGEMENT: `SERVICE_MANAGEMENT`,
    SERVICE_CREATE: `SERVICE_CREATE`,
    SERVICE_DELETE: `SERVICE_DELETE`,
    SERVICE_READ: `SERVICE_READ`,
    SERVICE_UPDATE: `SERVICE_UPDATE`,

    // BLOCKED DATES
    FACILITY_BLOCKED_DATES: `FACILITY_BLOCKED_DATES`,
    SERVICE_BLOCKED_DATES: `SERVICE_BLOCKED_DATES`,

    /* --------------------------- SURGE SPHERE MANAGEMENT --------------------------- */
    SURGE_SPHERE_MANAGEMENT: `SURGE_SPHERE_MANAGEMENT`,
    WALLET: `WALLET`,
    LEDGER: `LEDGER`,
    RULES: `RULES`,
    CAMPAIGNS: `CAMPAIGNS`,
    EVENTS: `EVENTS`,
    COMMUNICATION_MANAGEMENT: `COMMUNICATION_MANAGEMENT`,

    /* --------------------------- DATA POOL --------------------------- */
    DATA_POOL: `DATA_POOL`,

    /* --------------------------- COUPON MANAGEMENT --------------------------- */
    COUPON_MANAGEMENT: `COUPON_MANAGEMENT`,
    STATIC_COUPON: `STATIC_COUPON`,
    STATIC_COUPON_UPDATE: `STATIC_COUPON_UPDATE`,
    UNQIUE_COUPON: `UNQIUE_COUPON`,
    UNQIUE_COUPON_UPDATE: `UNQIUE_COUPON_UPDATE`,

    COUPON_ASSIGN: `COUPON_ASSIGN`,
    COUPON_READ: `COUPON_READ`,
    COUPON_UPLOAD: `COUPON_UPLOAD`,
    COUPON_INFO_READ: `COUPON_INFO_READ`,
    CHANGE_LOT_EXPIRY: `CHANGE_LOT_EXPIRY`,
    CHANGE_COUPON_EXPIRY: `CHANGE_COUPON_EXPIRY`,

    /* --------------------------- CUSTOMER MANAGEMENT --------------------------- */
    CUSTOMER_MANAGEMENT: `CUSTOMER_MANAGEMENT`,
    UPDATE_CUSTOMER: `UPDATE_CUSTOMER`,
    UPDATE_USER: `UPDATE_USER`,

    USER_DELETE: `USER_DELETE`,
    USER_UPDATE: `USER_UPDATE`,
    USER_READ: `USER_READ`,
    USER_AGENT_CARD_INACTIVE: `USER_AGENT_CARD_INACTIVE`,

    /* --------------------------- CUSTOMER DATA MANAGEMENT --------------------------- */
    CUSTOMER_DATA_MANAGEMENT: `CUSTOMER_DATA_MANAGEMENT`,

    /* --------------------------- CUSTOM REPORT DATA MANAGEMENT --------------------------- */
    CUSTOM_REPORT_MANAGEMENT: `CUSTOM_REPORT_MANAGEMENT`,

    /* --------------------------- DUPLICATE CARDS MANAGEMENT --------------------------- */
    DUPLICATE_CARDS_MANAGEMENT: `DUPLICATE_CARDS_MANAGEMENT`,

    /* --------------------------- BENEFIT MANAGEMENT --------------------------- */
    BENEFIT_MANAGEMENT: `BENEFIT_MANAGEMENT`,
    BENEFIT_PACK: `BENEFIT_PACK`,
    BENEFIT_GROUP: `BENEFIT_GROUP`,

    BENEFIT_PACK_READ: `BENEFIT_PACK_READ`,
    BENEFIT_PACK_UPDATE: `BENEFIT_PACK_UPDATE`,
    BENEFIT_PACK_CREATE: `BENEFIT_PACK_CREATE`,
    BENEFIT_PACK_DOWNLOAD: `BENEFIT_PACK_DOWNLOAD`,
    BENEFIT_PACK_CHANGE_EXPIRY: `BENEFIT_PACK_CHANGE_EXPIRY`,

    /* --------------------------- BENEFIT GROUP MANAGEMENT --------------------------- */
    REWARD_SETTINGS: `REWARD_SETTINGS`,
    CONFIG_STORE_MANAGEMENT: `CONFIG_STORE_MANAGEMENT`,
    BENEFIT_CONFIG: `BENEFIT_CONFIG`,

    BENEFIT_GROUP_MANAGEMENT: `BENEFIT_GROUP_MANAGEMENT`,

    /* --------------------------- DOWNLOADS MANAGEMENT --------------------------- */
    DOWNLOAD_ACCESS: `DOWNLOAD_ACCESS`,

    /* --------------------------- GOLF ADMIN MANAGEMENT --------------------------- */
    GOLF_ADMIN_MANAGEMENT: `GOLF_ADMIN_MANAGEMENT`,

    ASSIGN_GOLF_COURSE: `ASSIGN_GOLF_COURSE`,
    VIEW_GOLF_SLOTS_FORMAT: `VIEW_GOLF_SLOTS_FORMAT`,
    UNASSIGN_GOLF_COURSE_SLOTS: `UNASSIGN_GOLF_COURSE_SLOTS`,
    UPDATE_GOLF_COURSE_FORMATS: `UPDATE_GOLF_COURSE_FORMATS`,
    READ_GOLF_COURSE_SLOTS: `READ_GOLF_COURSE_SLOTS`,
    DOWNLOAD_GOLF_COURSE_SLOT_REPORT: `DOWNLOAD_GOLF_COURSE_SLOT_REPORT`,
    CREATE_UNAVAILABLE_GOLF_COURSE_SLOTS: `CREATE_UNAVAILABLE_GOLF_COURSE_SLOTS`,
    DISABLE_GOLF_COURSE_SLOTS: `DISABLE_GOLF_COURSE_SLOTS`,

    /* --------------------------- USER MANAGEMENT --------------------------- */
    ADD_USER_PERMISSION: `ADD_USER_PERMISSION`,
    VIEW_CARD_LIST: `VIEW_CARD_LIST`,

    /* --------------------------- PROJECT MANAGEMENT --------------------------- */
    PROJECT_MANAGEMENT: `PROJECT_MANAGEMENT`,
    ALL_PLATFORMS: `ALL_PLATFORMS`,
    ALL_PROGRAMS: `ALL_PROGRAMS`,
    PROJECT_PAYMENTS: `PROJECT_PAYMENTS`,
    CLIENTS: `CLIENTS`,
    DATA_INTEGRATION: `DATA_INTEGRATION`,
    COMMS_SETTINGS: `COMMS_SETTINGS`,
    PROGRAM_ONBOARDING: `PROGRAM_ONBOARDING`,

    /* --------------------------- CONSOLIDATED PERMISSIONS --------------------------- */
    ADMIN_ACCESS: `ADMIN_ACCESS`,
    HSBC_ONE: `HSBC_ONE`,

    /* --------------------------- REPORTS MANAGEMENT --------------------------- */
    REPORTS_MANAGEMENT: `REPORTS_MANAGEMENT`,
    COMMS_REPORTS: `COMMS_REPORTS`,
    PAYMENT_LOGS: `PAYMENT_LOGS`,

    /* --------------------------- INVENTORY MANAGEMENT --------------------------- */
    INVENTORY_SETTINGS: `INVENTORY_SETTINGS`,
    AIRPORT_TRANSFER: `AIRPORT_TRANSFER`,

    /* --------------------------- CLIENT PANEL --------------------------- */
    CLIENT_AIRPORT_TRANSFER: `CLIENT_AIRPORT_TRANSFER`,
    CLIENT_AIRPORT_MEET_AND_GREET: `CLIENT_AIRPORT_MEET_AND_GREET`,
    CLIENT_HISTORY: `CLIENT_HISTORY`,
    CLIENT_CONTACT: `CLIENT_CONTACT`,
    CLIENT_DOWNLOAD_DUMP: `CLIENT_DOWNLOAD_DUMP`,

    GENERATE_VOUCHER: `GENERATE_VOUCHER`,
    VOUCHERS_REPORT: `VOUCHERS_REPORT`,

    /* --------------------------- VENDOR PANEL --------------------------- */
    VENDOR_DASHBOARD: `VENDOR_DASHBOARD`,
    VENDOR_AIRPORT_TRANSFER: `VENDOR_AIRPORT_TRANSFER`,

    /* --------------------------- GM REPORT --------------------------- */
    // MASTER UPLOAD - GM REPORT
    SUPPLY_DATA_GM_REPORT: `SUPPLY_DATA_GM_REPORT`,
    UPLOAD_FILE_SUPPLY_DATA_GM_REPORT: `UPLOAD_FILE_SUPPLY_DATA_GM_REPORT`,
    EDIT_RECORD_SUPPLY_DATA_GM_REPORT: `EDIT_RECORD_SUPPLY_DATA_GM_REPORT`,
    VIEW_RECORD_SUPPLY_DATA_GM_REPORT: `VIEW_RECORD_SUPPLY_DATA_GM_REPORT`,
    SEND_APPROVAL_SUPPLY_DATA_GM_REPORT: `SEND_APPROVAL_SUPPLY_DATA_GM_REPORT`,
    PUBLISH_WITH_AUTH_SUPPLY_DATA_GM_REPORT: `PUBLISH_WITH_AUTH_SUPPLY_DATA_GM_REPORT`,
    APPROVE_RECORD_SUPPLY_DATA_GM_REPORT: `APPROVE_RECORD_SUPPLY_DATA_GM_REPORT`,
    REJECT_RECORD_SUPPLY_DATA_GM_REPORT: `REJECT_RECORD_SUPPLY_DATA_GM_REPORT`,
    DIRECT_APPROVE_RECORD_SUPPLY_DATA_GM_REPORT: `DIRECT_APPROVE_RECORD_SUPPLY_DATA_GM_REPORT`,
    PUBLISH_WITHOUT_AUTH_SUPPLY_DATA_GM_REPORT: `PUBLISH_WITHOUT_AUTH_SUPPLY_DATA_GM_REPORT`,
    DELETE_DRAFT_SUPPLY_DATA_GM_REPORT: `DELETE_DRAFT_SUPPLY_DATA_GM_REPORT`,
    ATTACHMENT_SUPPLY_DATA_GM_REPORT: `ATTACHMENT_SUPPLY_DATA_GM_REPORT`,

    // PRICE VIEWER - GM REPORT
    PRICE_VIEWER_GM_REPORT: `PRICE_VIEWER_GM_REPORT`,
    DOWNLOAD_PRICING_GOLF_PLAY_GM_REPORT: `DOWNLOAD_PRICING_GOLF_PLAY_GM_REPORT`,

    // CLIENT PRICE UPLOAD - GM REPORT
    CLIENT_DATA_GM_REPORT: `CLIENT_DATA_GM_REPORT`,
    UPLOAD_FILE_WITH_AUTH_CLIENT_DATA_GM_REPORT: `UPLOAD_FILE_WITH_AUTH_CLIENT_DATA_GM_REPORT`,
    DOWNLOAD_FILE_CLIENT_DATA_GM_REPORT: `DOWNLOAD_FILE_CLIENT_DATA_GM_REPORT`,
    VIEW_RECORD_CLIENT_DATA_GM_REPORT: `VIEW_RECORD_CLIENT_DATA_GM_REPORT`,
    APPROVE_RECORD_CLIENT_DATA_GM_REPORT: `APPROVE_RECORD_CLIENT_DATA_GM_REPORT`,
    REJECT_RECORD_CLIENT_DATA_GM_REPORT: `REJECT_RECORD_CLIENT_DATA_GM_REPORT`,
    SEND_APPROVAL_CLIENT_DATA_GM_REPORT: `SEND_APPROVAL_CLIENT_DATA_GM_REPORT`,
    PUBLISH_CLIENT_DATA_GM_REPORT: `PUBLISH_CLIENT_DATA_GM_REPORT`,
    DELETE_DRAFT_CLIENT_DATA_GM_REPORT: `DELETE_DRAFT_CLIENT_DATA_GM_REPORT`,

    // BOOKING RECON - GM REPORT
    BOOKING_RECON_GM_REPORT: `BOOKING_RECON_GM_REPORT`,
    UPLOAD_FILE_WITH_AUTH_BOOKING_RECON_GM_REPORT: `UPLOAD_FILE_WITH_AUTH_BOOKING_RECON_GM_REPORT`,
    DOWNLOAD_FILE_BOOKING_RECON_GM_REPORT: `DOWNLOAD_FILE_BOOKING_RECON_GM_REPORT`,
    VIEW_RECORD_BOOKING_RECON_GM_REPORT: `VIEW_RECORD_BOOKING_RECON_GM_REPORT`,
    APPROVE_RECORD_BOOKING_RECON_GM_REPORT: `APPROVE_RECORD_BOOKING_RECON_GM_REPORT`,
    REJECT_RECORD_BOOKING_RECON_GM_REPORT: `REJECT_RECORD_BOOKING_RECON_GM_REPORT`,
    SEND_APPROVAL_BOOKING_RECON_GM_REPORT: `SEND_APPROVAL_BOOKING_RECON_GM_REPORT`,
    PUBLISH_BOOKING_RECON_GM_REPORT: `PUBLISH_BOOKING_RECON_GM_REPORT`,
    DELETE_DRAFT_BOOKING_RECON_GM_REPORT: `DELETE_DRAFT_BOOKING_RECON_GM_REPORT`,

    // REPORT - GM REPORT
    MASTER_GM_REPORT: `MASTER_GM_REPORT`,

    VENDOR_BOOKING_MANAGEMENT: `VENDOR_BOOKING_MANAGEMENT`,

    DINING_BOOKING_MANAGEMENT: `DINING_BOOKING_MANAGEMENT`,

    VENDOR_DOWNLOAD_DUMP: `VENDOR_DOWNLOAD_DUMP`,

    VENDOR_QR: `VENDOR_QR`,
    VENDOR_VALET_PARKING: `VENDOR_VALET_PARKING`,
    VENDOR_REPORT: `VENDOR_REPORT`,

    // Update User
    UPDATE_USER: `UPDATE_USER`,

    // CLIENT - ADIB
    BUSINESS_REGISTRATION: `BUSINESS_REGISTRATION`,
    BUSINESS_REGISTRATION_UPDATE: `BUSINESS_REGISTRATION_UPDATE`,
    COMPANIES: `COMPANIES`,
    COMPANIES_UPDATE: `COMPANIES_UPDATE`,
    POST: `POST`,
    POST_UPDATE: `POST_UPDATE`,
    OFFER: `OFFER`,
    OFFER_UPDATE: `OFFER_UPDATE`,
    ANNOUNCEMENT: `ANNOUNCEMENT`,
    CLIENT_DASHBOARD: `CLIENT_DASHBOARD`,
    CLIENT_CUSTOMERS: `CLIENT_CUSTOMERS`,
    CLIENT_DELAYED_BOOKINGS: `CLIENT_DELAYED_BOOKINGS`,
    CLIENT_UNIQUE_COUPON_BOOKINGS: `CLIENT_UNIQUE_COUPON_BOOKINGS`,
    CLIENT_BOOKINGS_DOWNLOAD_DUMP: `CLIENT_BOOKINGS_DOWNLOAD_DUMP`,
    
    /* --------------------------- BOOKING MANAGEMENT V2 --------------------------- */

    // DELAYED BOOKING V2
    DELAYED_BOOKING_V2_READ: 'DELAYED_BOOKING_V2_READ',
    DELAYED_BOOKING_V2_UPDATE: 'DELAYED_BOOKING_V2_READ',

    // UNIQUE BOOKING V2
    UNIQUE_BOOKING_V2_READ: 'UNIQUE_BOOKING_V2_READ',
    UNIQUE_BOOKING_V2_UPDATE: 'UNIQUE_BOOKING_V2_UPDATE',

    // STATIC BOOKING V2
    STATIC_BOOKING_V2_READ: 'STATIC_BOOKING_V2_READ',
    STATIC_BOOKING_V2_UPDATE: 'STATIC_BOOKING_V2_UPDATE',
  };
}
