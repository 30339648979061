import {
  DISABLE_GOLF_COURSE_UNAVAILABILITY,
  DISABLE_GOLF_COURSE_UNAVAILABILITY_SUCCESS,
  DISABLE_GOLF_COURSE_UNAVAILABILITY_ERROR,
  DISABLE_GOLF_COURSE_UNAVAILABILITY_EMPTY,
} from './../../actionType.js';

const initialState = {
  data: null,
  message: null,
  loading: false,
  success: false,
  error: false,
};

const DisableGolfCourseUnavailability = (state = initialState, action) => {
  switch (action.type) {
    case DISABLE_GOLF_COURSE_UNAVAILABILITY:
      state = {
        ...state,
        // data: null,
        loading: true,
        success: false,
        error: false,
        message: null,
      };
      break;

    case DISABLE_GOLF_COURSE_UNAVAILABILITY_SUCCESS:
      state = {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
        error: false,
        message: null,
      };
      break;

    case DISABLE_GOLF_COURSE_UNAVAILABILITY_ERROR:
      state = {
        ...state,
        data: null,
        loading: false,
        success: false,
        error: true,
        message: action.payload,
      };
      break;

    case DISABLE_GOLF_COURSE_UNAVAILABILITY_EMPTY:
      state = {
        ...initialState,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default DisableGolfCourseUnavailability;
