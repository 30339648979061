import React, { useEffect, useState } from 'react';
import { Grid, _ } from 'gridjs-react';
import { Card, CardBody, CardFooter, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, UncontrolledDropdown } from 'reactstrap';
import { PreviewCardHeader } from '../../../../../../Common';
import ReactPaginate from 'react-paginate';
import { Constant } from '../../../../../../Helpers/constant';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';
import { hasPermission } from '../../../../../../Helpers/Helpers';



const Table = props => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      setData(
        props?.data?.map((data, index) => {
          return [`${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`, data || `NA`, data?.event_type || `NA`, data, data];
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `VIEW`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `UPDATE`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `INFO`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `LOGS`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === 'UPDATE STATUS') {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <PreviewCardHeader title={props.title} isShowFilterBtn={props?.isShowFilterBtn} filterBtnHandler={props?.filterBtnHandler} />

            <CardBody>
              <div id="table-gridjs">
                <Grid
                  data={data}
                  columns={[
                    {
                      name: 'S.No.',
                      width: '60px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Template name',
                      width: '200px',
                      formatter: (cell, row) =>
                        _(
                          <span className="fw-semibold cursor-pointer" onClick={() => props.showModal(cell)}>
                            {cell?.template_name}
                          </span>,
                        ),
                    },
                    {
                      name: 'Event Name',
                      width: '170px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Status',
                      width: '190px',
                      formatter: cell => {
                        return _(
                          <div
                            onClick={() => {
                              props?.changeTemplateStatus(cell);
                            }}
                          >
                            <div className="form-switch d-inline-block">
                              <Input
                                className="form-check-input code-switcher"
                                type="checkbox"
                                id="sms"
                                name="sms"
                                checked={cell?.is_active}
                                onChange={() => {}}
                                style={{ cursor: 'pointer' }}
                              />
                            </div>
                            {cell?.is_active ? <span style={{ color: '#28a745' }}>Active</span> : <span style={{ color: '#dc3545' }}>InActive</span>}{' '}
                          </div>,
                        );
                      },
                    },

                    {
                      name: 'Actions',
                      width: '120px',
                      formatter: (cell, row) =>
                        _(
                          <div className="d-flex justify-content-center flex-column">
                            {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.TEMPLATE_ENGINE_READ) && (
                              <div className="flex-shrink-0">
                                <span
                                  href="apps-ecommerce-add-product"
                                  id="TooltipTop"
                                  className="btn btn-light"
                                  onClick={() => actionBtnHandler(`UPDATE`, cell)}
                                >
                                  <i className="ri-pencil-fill align-bottom"></i>
                                </span>
                              </div>
                            )}
                          </div>,
                        ),
                    },
                  ]}
                  search={false}
                  sort={false}
                  fixedHeader={true}
                  height="400px"
                  pagination={{ enabled: false, limit: 5 }}
                  style={{
                    th: {
                      zIndex: '999',
                    },
                    td: {
                      position: 'relative',
                    },
                  }}
                />
              </div>
              {props.children}
            </CardBody>

            <CardFooter>
              <Row className="custom_pagination">
                <Col className="text-end">
                  <ReactPaginate
                    previousLabel={'previous'}
                    forcePage={props?.pageNumber}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    pageCount={Math.ceil(props?.totalData / Constant.TABLE_PAGE_SIZE)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={event => {
                      props.pageHandler(event.selected);
                    }}
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Table, (prevProps, nextProps) => prevProps.data === nextProps.data);
