import React, { useState } from 'react';

import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Table } from 'reactstrap';

const Disclaimer = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [modal_varying1, setmodal_varying1] = useState(true);

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    // setmodal_varying1(!modal_varying1);
    // props?.onClose('ConfirmationModal', 'YES');
    props.onClose();
  };

  return (
    <Modal
      isOpen={true}
      // toggle={() => {
      //   props.onClose();
      // }}
      id="disclaimer-modal"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Disclaimer
        </h5>
        {/* <Button
          type="button"
          onClick={() => {
            props.onClose();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button> */}
      </div>

      <ModalBody>
        <p className="text-muted m-0">
          Please Note: Meet & Greet and Airport Transfer Car Booking is to be made only for the Cardholders. No Guests should be booked.
        </p>
      </ModalBody>

      <ModalFooter className="pb-0">
        <div className="modal-footer mx-auto">
          <Button className="overlay-disable" color="primary" onClick={submitHandler}>
            Confirm
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default Disclaimer;
