import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../Containers/Config/index.js';
import { CUSTOMER_UPLOADS_INFO, CUSTOMER_UPLOADS_INFO_SUCCESS, CUSTOMER_UPLOADS_INFO_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getCustomerUploadsInfo({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/customeruploadsInfo`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload?.urlParam,
        pageSize: Constant?.TABLE_PAGE_SIZE,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      yield put({
        type: CUSTOMER_UPLOADS_INFO_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: CUSTOMER_UPLOADS_INFO_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: CUSTOMER_UPLOADS_INFO_ERROR, payload: error });
  }
}

export function* watchCustomerUploadsInfo() {
  yield takeEvery(CUSTOMER_UPLOADS_INFO, getCustomerUploadsInfo);
}

function* customerUploadsInfoSaga() {
  yield all([fork(watchCustomerUploadsInfo)]);
}

export default customerUploadsInfoSaga;
