import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { BLOCK_DATE_DEACTIVATE, BLOCK_DATE_DEACTIVATE_SUCCESS, BLOCK_DATE_DEACTIVATE_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* deactivateBlockDate({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/admin/block-dates`,
      method: 'PUT',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      data: payload?.data,
      params: payload?.urlParams,
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({
        type: BLOCK_DATE_DEACTIVATE_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: BLOCK_DATE_DEACTIVATE_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: BLOCK_DATE_DEACTIVATE_ERROR, payload: error });
  }
}

export function* watchDeactivateBlockDate() {
  yield takeEvery(BLOCK_DATE_DEACTIVATE, deactivateBlockDate);
}

function* deactivateBlockDateSaga() {
  yield all([fork(watchDeactivateBlockDate)]);
}

export default deactivateBlockDateSaga;
