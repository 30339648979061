import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../Containers/Config/index.js';
import { CREATE_PAYMENT, CREATE_PAYMENT_SUCCESS, CREATE_PAYMENT_ERROR } from '../../actionType.js';
import apiCall from '../../apiCall.js';
import { Constant } from '../../../../Containers/Helpers/constant.js';
import cogoToast from 'cogo-toast';

function* createPayment({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/projects`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      cogoToast.success(`Project created successfully`);
      yield put({
        type: CREATE_PAYMENT_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: CREATE_PAYMENT_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: CREATE_PAYMENT_ERROR, payload: error });
  }
}

export function* watchCreatePayment() {
  yield takeEvery(CREATE_PAYMENT, createPayment);
}

function* createPaymentSaga() {
  yield all([fork(watchCreatePayment)]);
}

export default createPaymentSaga;
