import React, { useEffect, useState } from 'react';
import { CardHeader, Col, Row } from 'reactstrap';

const ClientsList = ({ clients, createNew }) => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [searchedName, setSearchedName] = useState('');
  const [allClientsList, setAllClientsList] = useState([]);

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    setAllClientsList(clients);
  }, [clients]);

  /* ---------------------------- SEARCH PROJECT BY NAME HANDLER ---------------------------- */
  const searchClientHandler = e => {
    setSearchedName(e.target.value);

    let filteredClientsName = clients;
    if (e.target.value.trim() !== '') {
      filteredClientsName = allClientsList?.filter(client => client?.name?.toLocaleLowerCase()?.includes(e.target.value?.toLocaleLowerCase()));
    }
    setAllClientsList([...filteredClientsName]);
  };

  return (
    <>
      <Row className="g-4 mb-3">
        <Col className="col-sm-auto">
          <div className="btn btn-success" onClick={createNew}>
            <i className="ri-add-line align-bottom me-1"></i> Add New Client
          </div>
        </Col>

        {/* {allClientsList?.length > 0 && ( */}
        <Col className="col-sm">
          <div className="d-flex justify-content-sm-end gap-2">
            <div className="search-box ms-2">
              <input type="text" className="form-control" placeholder="Search..." onChange={searchClientHandler} value={searchedName} />
              <i className="ri-search-line search-icon"></i>
            </div>
          </div>
        </Col>
        {/* )} */}
      </Row>

      <Row>
        <CardHeader className="p-0 border-0 bg-soft-light">
          <Row className="g-0 text-center">
            {allClientsList?.map((client, key) => (
              <>
                <Col xs={6} sm={4} key={key}>
                  <div className="p-3 border bg-white border-dashed border-start-0">
                    {/* <h5 className="mb-1"><span className="counter-value" data-target="854">
                                            23
                                        </span>
                                            <span className="text-success ms-1 fs-12">49%<i className="ri-arrow-right-up-line ms-1 align-middle"></i></span>
                                        </h5> */}
                    <p className="text-muted mb-0">{client?.name ?? ''}</p>
                  </div>
                </Col>
              </>
            ))}
          </Row>
        </CardHeader>
      </Row>
    </>
  );
};

export default ClientsList;
