// Import the necessary dependencies
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_TEMPLATE, CREATE_TEMPLATE_EMPTY, UPDATE_TEMPLATE_EMPTY } from '../../../../../store/application/actionType';
import TemplateEngineForm from './Components/Forms/TemplateEngineForm';
import { Loader, BreadCrumb } from '../../../../Common';
import { Container } from 'reactstrap';
import ManageTemplateEngine from '../ManageTemplateEngine';
import TemplatePage from './Components/Forms/TemplatePage';
import { useHistory } from 'react-router-dom';

const TemplateEngineManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [showListPage, setShowListPage] = useState(false);
  const [showTemplatePage, setShowTemplatePage] = useState(false);
  const [data, setData] = useState(null);

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const templateSubmit = data => {
    setData(data);
    setShowTemplatePage(true);
  };

  const { createTemplateData, createTemplateLoading } = useSelector(state => ({
    createTemplateData: state.CreateTemplate?.data,
    createTemplateLoading: state.CreateTemplate.loading,
  }));

  const { updateTemplateData, updateTemplateLoading } = useSelector(state => ({
    updateTemplateData: state.UpdateTemplate?.data,
    updateTemplateLoading: state.UpdateTemplate.loading,
  }));

  const createTemplate = values => {
    data.content = {
      ...data?.content,
      body: values?.body,
      subject: values?.subject,
      from: values?.from,
      cc: values?.cc?.map(cc => cc.value),
      bcc: values?.bcc?.map(bcc => bcc.value),
    };
    data.template_variables = {
      body_variables: values?.bodyVariables,
      subject_variables: values?.subjectVariables,
    };
    dispatch({
      type: CREATE_TEMPLATE,
      payload: {
        data: {
          ...data,
        },
      },
    });
  };

  useEffect(() => {
    if (createTemplateData != null) {
      setShowListPage(true);
      setShowTemplatePage(false);
      dispatch({
        type: CREATE_TEMPLATE_EMPTY,
      });
    }
    if (updateTemplateData != null) {
      history.push('/template-engine/manage-template-engine');
      dispatch({
        type: UPDATE_TEMPLATE_EMPTY,
      });
    }
  }, [createTemplateData, updateTemplateData]);

  useEffect(() => {
    if (createTemplateLoading || updateTemplateLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [createTemplateLoading, updateTemplateLoading]);

  return (
    <>
      {loading && <Loader />}
      {!showListPage && !showTemplatePage && (
        <div className="page-content">
          <Container fluid className="position-relative">
            <TemplateEngineForm onSubmit={templateSubmit} previousForm={data} />
          </Container>
        </div>
      )}
      {showTemplatePage && (
        <div className="page-content">
          <Container fluid className="position-relative">
            <TemplatePage onSubmit={createTemplate} data={data} setShowTemplatePage={setShowTemplatePage} isUpdate={false} />
          </Container>
        </div>
      )}

      {showListPage && <ManageTemplateEngine />}
    </>
  );
};

export default TemplateEngineManagement;
