import cogoToast from 'cogo-toast';
import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, indianProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { Constant } from '../../../../Containers/Helpers/constant.js';
import apiCall from '../../apiCall.js';

import { UPLOAD_FILE, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_ERROR } from './../../actionType.js';

function* uploadFile({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/upload-files`,
      method: 'POST',
      data: payload?.data,
      isFormData: true,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
    });

    if (response.status === 200) {
      cogoToast.success('File uploaded successfully');
      yield put({
        type: UPLOAD_FILE_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(`${response?.response?.data?.message}`);
      yield put({ type: UPLOAD_FILE_ERROR, payload: response?.data?.message });
    }
  } catch (error) {
    yield put({ type: UPLOAD_FILE_ERROR, payload: error });
  }
}

export function* watchUploadFile() {
  yield takeEvery(UPLOAD_FILE, uploadFile);
}

function* uploadFileSaga() {
  yield all([fork(watchUploadFile)]);
}

export default uploadFileSaga;
