import React, { useEffect, useState } from 'react';
import {
  Card,
  Row,
  Col,
  CardBody,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  CardFooter,
  Input,
} from 'reactstrap';

import { Grid, _ } from 'gridjs-react';
import { Constant } from '../../../../../../../../Helpers/constant';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { GET_COMS_CHANNEL_CONFIG_PROJECT, GET_COMS_CHANNEL_CONFIG_PROJECT_EMPTY } from '../../../../../../../../../store/actions';
import { TablePagination } from '../../../../../../../../Common';
import { GrTrigger } from 'react-icons/gr';

const WhatsappTable = ({
  events,
  createNewEvent,
  totalData,
  pageHandler,
  pageNumber,
  actionHandler,
  changeStatus,
  getComsChannel,
  disableButton,
}) => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [eventsData, setEventsData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (events?.length > 0) {
      const updatedEventsData = events.map((event, index) => [
        `${pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
        event?.event_type ? event?.event_type : `NA`,
        event?.template_name ? event?.template_name : `NA`,
        event?.region || 'NA',
        event,
      ]);

      setEventsData(updatedEventsData);
    } else {
      setEventsData([]);
    }
  }, [events]);

  // useEffect(() => {
  //   dispatch({
  //     type: GET_COMS_CHANNEL_CONFIG_PROJECT_EMPTY,
  //   });
  //   dispatch({
  //     type: GET_COMS_CHANNEL_CONFIG_PROJECT,
  //     payload: {
  //       urlParam: {
  //         projectID,
  //         channel: "sms",
  //       },
  //     },
  //   });
  // }, []);

  // const { getChannelSetting, getChannelSettingLoading } = useSelector(
  //   (state) => ({
  //     getChannelSetting: state?.GetComsChannelConfig?.data,
  //     getChannelSettingLoading: state?.GetComsChannelConfig?.loading,
  //   })
  // );

  return (
    <Card className="card-inner">
      <CardHeader className="d-flex">
        <h4 className="card-title mb-0 flex-grow-1">Whatsapp</h4>
        <div>Channel</div>
        <div className="form-switch d-inline-block mx-4">
          <Input
            className="form-check-input code-switcher form-switch-md cursor-pointer"
            type="checkbox"
            id="complementaryBenefit"
            name="complementaryBenefit"
            checked={!getComsChannel?.length ? false : getComsChannel[0]?.is_active ? true : false}
            disabled={disableButton}
            onChange={() => changeStatus()}
          />
        </div>
        <button
          className="btn btn-primary"
          onClick={createNewEvent}
          disabled={!getComsChannel?.length ? true : getComsChannel[0]?.is_active ? false : true}
        >
          + Create New Config
        </button>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={12}>
            <div className="custom-striped-table">
              <Grid
                data={eventsData}
                columns={[
                  {
                    name: 'S.No.',
                    width: '50px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Event Name',
                    width: '100px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Template Name',
                    width: '100px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Region',
                    width: '100px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Status',
                    width: '100px',
                    formatter: cell =>
                      _(
                        <div
                          onClick={() => {
                            actionHandler(cell, 'Update Status');
                          }}
                        >
                          <div className="form-switch d-inline-block">
                            <Input
                              className="form-check-input code-switcher"
                              type="checkbox"
                              id="whatsapp"
                              name="whatsapp"
                              checked={cell?.is_active ? true : false}
                              style={{ cursor: 'pointer' }}
                            />
                          </div>
                          {cell?.is_active ? <span style={{ color: '#28a745' }}>Active</span> : <span style={{ color: '#dc3545' }}>InActive</span>}
                        </div>,
                      ),
                  },
                ]}
                sort={true}
                // search={true}
                fixedHeader={true}
                height="400px"
                pagination={{ enabled: false, limit: 5 }}
                style={{
                  th: {
                    zIndex: '999',
                    textAlign: 'center',
                  },
                  td: {
                    position: 'relative',
                    textAlign: 'center',
                  },
                  li: {
                    color: 'blue',
                  },
                }}
              />
            </div>
          </Col>
        </Row>
      </CardBody>

      <CardFooter>
        <Row className="custom_pagination">
          <Col>
            <div className="d-flex justify-content-end">
              <TablePagination totalData={totalData} currentPage={pageNumber} onPageChangeHandler={pageHandler} />
            </div>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

export default WhatsappTable;
