import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders } from '../../../../Containers/Config/index.js';
import { GET_VENDORS_LIST, GET_VENDORS_LIST_SUCCESS, GET_VENDORS_LIST_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getVendorsList({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseUrl}/admin/vendors`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload.urlParams,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_VENDORS_LIST_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_VENDORS_LIST_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_VENDORS_LIST_ERROR, payload: error });
  }
}

export function* watchGetVendorsList() {
  yield takeEvery(GET_VENDORS_LIST, getVendorsList);
}

function* getVendorsListSaga() {
  yield all([fork(watchGetVendorsList)]);
}

export default getVendorsListSaga;
