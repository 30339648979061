import { useState } from 'react';

import Flatpickr from 'react-flatpickr';

import { Button, Modal, ModalBody, Col, Label, Input, Row, Card } from 'reactstrap';

import moment from 'moment';
import { Error } from '../../../../../../Common';

const BlockDates = ({ data, onCloseHandler }) => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    dateRange: '',
    startDate: '',
    endDate: '',
    fromTime: '',
    toTime: '',
    remarks: '',
    isActive: true,
    isTimeDisplay: true,
  };
  const [state, setState] = useState(initialState);
  const [modal_varying1, setmodal_varying1] = useState(true);
  const [isValidationShow, setIsValidationShow] = useState(false);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = (flag, event) => {
    let updatedState = { ...state };

    switch (flag) {
      case 'dateRange':
        updatedState = {
          ...state,
          dateRange: event,
          startDate: moment(new Date(event[0])).format('Y-MM-DD'),
          endDate: moment(new Date(event[1])).format('Y-MM-DD'),
        };
        break;

      case 'fromTime':
        updatedState = {
          ...state,
          fromTime: event?.target?.value,
        };
        break;

      case 'toTime':
        updatedState = {
          ...state,
          toTime: event?.target?.value,
        };
        break;

      case 'remarks':
        updatedState = {
          ...state,
          remarks: event?.target?.value,
        };
        break;

      case 'isActive':
        updatedState = {
          ...state,
          isActive: event?.target?.checked,
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data?.dateRange !== `` && data?.toTime?.trim() !== `` && data?.fromTime?.trim() !== `` && data?.remarks?.trim() !== ``) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      // const payload = {
      //     objectId: state?.objectId,
      //     blockedDate: {
      //         startDate: state?.blockedDate?.startDate,
      //         endDate: state?.blockedDate?.endDate,
      //         fromTime: state?.blockedDate?.fromTime,
      //         toTime: state?.blockedDate?.toTime,
      //         remarks: state?.blockedDate?.remarks,
      //         isTimeDisplay: state?.blockedDate?.isTimeDisplay,
      //         isActive: true,
      //     },
      // };

      const allBlockDates = [...(data?.blockedDates ?? [])];
      const currentBlockedDateData = {
        startDate: state?.startDate,
        endDate: state?.endDate,
        fromTime: state?.fromTime,
        toTime: state?.toTime,
        remarks: state?.remarks,
        isTimeDisplay: state?.isTimeDisplay,
        isActive: true,
      };

      if (state?.index === undefined) {
        allBlockDates?.push(currentBlockedDateData);
      } else {
        allBlockDates?.splice(state?.index, 1, currentBlockedDateData);
      }

      // dispatch({
      //     type: UPDATE_SERVICE,
      //     payload: {
      //         data: {
      //             requests: [{ objectId: data?.objectId, blockedDate: allBlockDates }],
      //         },
      //     },
      // });

      // onCloseHandler(false);
      // setmodal_varying1(!modal_varying1);
    }
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const updateBlockDateHandler = data => {
    setState({
      ...data,
      // dateRange: [new Date(data?.startDate, )]
      remarks: data?.remarks,
    });
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="block-dates"
      size="lg"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Block Date(s)
        </h5>
        <Button type="button" onClick={() => tog_varying1()} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close"></Button>
      </div>
      <ModalBody>
        {/* <button onClick={() => console.log({ state })}>Get state</button> */}
        <Row>
          <Col md={4}>
            <div className="mb-3">
              <Label htmlFor="redemeed-date" className="form-label">
                Block Date(s) <span className="lbl_mandatory">* </span>
              </Label>
              <Flatpickr
                className="form-control"
                name="redemeed-date"
                id="redemeed-date"
                value={state?.dateRange}
                // value={[new Date(), new Date()]}
                onChange={date => inputChangeHandler(`dateRange`, date)}
                options={{
                  mode: 'range',
                  minDate: 'today',
                  enableTime: false,
                  dateFormat: 'd-m-Y',
                }}
              />
              {isValidationShow && state?.dateRange === `` && <Error text="Please select date(s)" />}
            </div>
          </Col>

          <Col md={2}>
            <div className="mb-3">
              <div>
                <Label htmlFor="date-of-play" className="form-label">
                  From Time <span className="lbl_mandatory">*</span>
                </Label>
                <div className="area_expandable">
                  <Input type="time" className="form-control" value={state?.fromTime} onChange={e => inputChangeHandler(`fromTime`, e)} />
                  {isValidationShow && state?.fromTime?.trim() === `` && <Error text="Please select" />}
                </div>
              </div>
            </div>
          </Col>

          <Col md={2}>
            <div className="mb-3">
              <div>
                <Label htmlFor="date-of-play" className="form-label">
                  To Time <span className="lbl_mandatory">*</span>
                </Label>
                <div className="area_expandable">
                  <Input type="time" className="form-control" value={state?.toTime} onChange={e => inputChangeHandler(`toTime`, e)} />
                  {isValidationShow && state?.toTime?.trim() === `` && <Error text="Please select" />}
                </div>
              </div>
            </div>
          </Col>

          <Col md={4}>
            <div className="mb-3">
              <div>
                <Label htmlFor="remarks" className="form-label">
                  Remarks <span className="lbl_mandatory">* </span>
                </Label>
                <textarea
                  id="remarks"
                  name="remarks"
                  className="form-control"
                  value={state?.remarks}
                  onChange={e => inputChangeHandler(`remarks`, e)}
                  rows={1}
                ></textarea>
                {isValidationShow && state?.remarks?.trim() === `` && <Error text="Please enter remarks" />}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="mb-3">
              <div className="d-flex align-items-center justify-content-center">
                <span className="text-muted">IsActive &nbsp;</span>
                <div className="form-switch d-inline-block ">
                  <Input
                    className="form-check-input code-switcher form-switch-md"
                    type="checkbox"
                    id="isActive"
                    name="isActive"
                    checked={state?.isActive}
                    onChange={e => inputChangeHandler(`isActive`, e)}
                  />
                </div>
                <span> &nbsp;Active</span>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <div className="modal-footer d-flex justify-content-center">
            <Button color="primary" onClick={submitHandler}>
              Submit
            </Button>
          </div>
        </Row>

        {data?.blockedDates?.length > 0 && (
          <>
            <hr />
            <Row>
              <Card style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                <div className="card-body">
                  <div className="table-responsive table-card">
                    <table className="table table-hover table-borderless table-centered align-middle table-nowrap mb-0">
                      <thead className="text-muted bg-soft-light">
                        <tr>
                          <th>S.No</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.blockedDates?.map((blockDate, index) => (
                          <tr key={index}>
                            <td>{index + 1}.</td>
                            <td>{moment(`${blockDate?.startDate}`).format('DD-MM-YYYY')}</td>
                            <td>{moment(`${blockDate?.endDate}`).format('DD-MM-YYYY')}</td>
                            <td>{blockDate?.fromTime}</td>
                            <td>{blockDate?.toTime}</td>
                            <td>{blockDate?.isActive}</td>
                            <td>
                              <div
                                to="/apps-crypto-buy-sell"
                                className="btn btn-sm btn-soft-secondary"
                                onClick={() =>
                                  updateBlockDateHandler({
                                    index: index,
                                    ...blockDate,
                                  })
                                }
                              >
                                Edit
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Card>
            </Row>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default BlockDates;
