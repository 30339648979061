import React, { useState, useEffect } from 'react';
import { Grid, _ } from 'gridjs-react';

import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import moment from 'moment';

const LogsModal = ({ data, onCloseHandler }) => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [modal_varying1, setmodal_varying1] = useState(true);
  const [logsData, setLogsData] = useState([]);

  useEffect(() => {
    if (data?.logs?.length > 0) {
      setLogsData(
        data?.logs?.map((log, index) => {
          return [`${index + 1}.`, log?.name || `NA`, log?.loggedAt ? moment(`${log?.loggedAt}`).format('DD-MM-YYYY, h:mm A') : `NA`];
        }),
      );
    } else {
      setLogsData([]);
    }
  }, [data?.logs]);

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <>
      <Modal
        isOpen={modal_varying1}
        toggle={() => {
          tog_varying1();
        }}
        id="logsModal"
        size="lg"
        centered
      >
        <div className="modal-header p-3 bg-soft-secondary">
          <h5 className="modal-title" id="createboardModalLabel">
            Logs
          </h5>
          <Button
            type="button"
            onClick={() => {
              tog_varying1();
            }}
            id="addBoardBtn-close"
            className="btn-close btn-light"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <Grid
            data={logsData}
            columns={[
              {
                name: 'S.No.',
                width: '30px',
                formatter: cell => _(<span className="">{cell}</span>),
              },
              {
                name: 'Name',
                width: '60px',
                formatter: cell => _(<span className="">{cell}</span>),
              },
              {
                name: 'Logged At',
                width: '70px',
                formatter: cell => _(<span className="">{cell}</span>),
              },
            ]}
            search={false}
            sort={false}
            fixedHeader={true}
            // height="350px"
            style={{
              table: {
                maxHeight: '350px',
              },
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default LogsModal;
