import { Row, Col, Button, Modal, ModalBody, ModalHeader, Table, ModalFooter } from 'reactstrap';

const ConfirmationModal = ({ bookingData, setIsShowConfirmationModal, submitHandler }) => {
  return (
    <Modal
      isOpen={true}
      toggle={() => {
        setIsShowConfirmationModal(false);
      }}
      scrollable={true}
      size="md"
    >
      <ModalHeader
        toggle={() => {
          setIsShowConfirmationModal(false);
        }}
      >
        Are you sure you want to go ahead?
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12} style={{ mixHeight: '500px' }}>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Booking ID</th>
                  <th> Name</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>1.</th>
                  <td>{bookingData?.bookingId}</td>
                  <td>{bookingData?.user?.name}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <p className="text-muted m-0">Once you will click on proceed, you will not be able to change previous state.</p>
        </Row>

        <div className="modal-footer justify-content-center pb-0">
          <Button className="btn btn-light" color="primary" onClick={() => setIsShowConfirmationModal(false)}>
            Cancel
          </Button>

          <Button color="primary" onClick={submitHandler}>
            Sure
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmationModal;
