import { Alert, CardFooter, Col, Row } from 'reactstrap';

import Offer from './Offer';
import { useTranslation } from 'react-i18next';
import { Error } from '../../../../../../../../Common';

const Offers = ({ data, onSelectBenefitItem, selectedBenefitItem, onGenerateVoucher }) => {
  const { t } = useTranslation();

  return (
    <CardFooter>
      <Row>
        <Col md={12} className="mb-3">
          <h5>{t(`Offers`)}</h5>
        </Col>

        {!data && (
          <>
            <Col md={6}>
              <Alert color="warning" className="alert-label-icon">
                <i className="ri-alert-line label-icon" style={{ left: '8px' }}></i>
                <strong>{t('No offers')}, </strong>
                {t('enter the card details above & click submit')}
              </Alert>
            </Col>
          </>
        )}

        <Col md={6}>
          {data?.length > 0 &&
            data?.map((benefitData, index) => (
              <Offer key={index} benefit={benefitData} onSelectBenefitItem={onSelectBenefitItem} selectedBenefitItem={selectedBenefitItem} />
            ))}

          {/* {['', null, undefined]?.includes(selectedBenefitItem) && <Error text="Please select offer!" />} */}
        </Col>

        {data?.length > 0 && (
          <Col md={12} className="mt-3">
            <button
              type="submit"
              className="btn btn-success px-4"
              onClick={onGenerateVoucher}
              disabled={selectedBenefitItem === `` || selectedBenefitItem === null}
            >
              {t(`Generate Voucher`)} <i className="ri-arrow-right-line me-1 text-white fs-16 align-middle"></i>
            </button>
          </Col>
        )}
      </Row>
    </CardFooter>
  );
};

export default Offers;
