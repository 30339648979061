import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import cogoToast from 'cogo-toast';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';

import ThriweNameLogo from './../../../../../../../../assets/custom/success.svg';
import LandmarkLogo from './../../../../../../../../assets/custom/LandmarkLogo.png';
import {
  CREATE_BOOKING_EMPTY,
  CREATE_CLIENT_USER_EMPTY,
  GET_BENEFIT_ITEMS_EMPTY,
  GET_BINS_LIST_EMPTY,
  GET_CLIENT_AIRPORT_EMPTY,
  GET_CLIENT_BENEFIT_CONFIG_EMPTY,
  GET_MEMBERSHIPS_DATA_EMPTY,
  GET_USER_DETAILS_EMPTY,
  VALIDATE_USER_EMPTY,
  VENDOR_OTP_VALIDATE_EMPTY,
  VENDOR_RESEND_OTP_EMPTY,
} from '../../../../../../../../store/actions';

const GeneratedVoucher = ({ data, flag }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch({
        type: GET_BINS_LIST_EMPTY,
      });
      dispatch({
        type: CREATE_CLIENT_USER_EMPTY,
      });
      dispatch({
        type: GET_MEMBERSHIPS_DATA_EMPTY,
      });
      dispatch({
        type: GET_BENEFIT_ITEMS_EMPTY,
      });
      dispatch({
        type: GET_CLIENT_BENEFIT_CONFIG_EMPTY,
      });
      dispatch({
        type: GET_CLIENT_AIRPORT_EMPTY,
      });
      dispatch({
        type: VENDOR_RESEND_OTP_EMPTY,
      });
      dispatch({
        type: VENDOR_OTP_VALIDATE_EMPTY,
      });
      dispatch({
        type: CREATE_BOOKING_EMPTY,
      });
      dispatch({
        type: VALIDATE_USER_EMPTY,
      });
      dispatch({
        type: GET_USER_DETAILS_EMPTY,
      });
    };
  }, []);

  return (
    <Col md={flag === `new_user` ? 6 : 12} className="pt-5">
      <div className="text-center">
        <div className="logo-md">
          <img src={ThriweNameLogo} className="img-fluid" alt="" />
        </div>
        <Row style={{ margin: '20px 0px 20px 0px' }}>
          <h4 className="mt-2 fw-semibold">{t(`Voucher is generated`)}</h4>
        </Row>
      </div>

      <Col md={12} sm={12}>
        <Card>
          <CardBody style={{ margin: '15px', border: '1px solid #00000014', borderRadius: '10px' }}>
            <div className="text-center">
              <div className="logo-md" style={{ marginBottom: '20px' }}>
                <img src={LandmarkLogo} className="img-fluid" alt="" />
              </div>
              <h5 className="mt-2 fw-bold" style={{ margin: '20px 0px' }}>
                {data?.MasterBookings?.results[0]?.displayName ?? 'DISPLAY_NAME'}
              </h5>
              <p className="text-center" style={{ margin: '20px 0px' }}>
                {t(`Voucher Code`)}
              </p>
            </div>

            <Col
              md={flag === `new_user` ? 6 : 3}
              className="text-center mx-auto"
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                border: '1px dashed #0AB39C',
                borderRadius: '5px',
                backgroundColor: '#0AB39C1A',
                padding: '5px',
              }}
            >
              <h5 className="text-center fw-medium" style={{ color: '#0AB39C', paddingTop: '9px' }}>
                {data?.MasterBookings?.results[0]?.bookingDetails?.coupon?.code ?? 'CODE'}
              </h5>
              <button
                style={{ backgroundColor: '#0AB39C', height: '70%', borderWidth: 0, color: 'white', alignSelf: 'center', borderRadius: '4px' }}
                onClick={e => {
                  navigator.clipboard.writeText(data?.MasterBookings?.results[0]?.bookingDetails?.coupon?.code ?? '');
                  cogoToast.info('Text copied!');
                }}
              >
                <small>{t(`Copy`)}</small>
              </button>
            </Col>
            <div className="text-center" style={{ marginTop: '5px' }}>
              <small style={{ opacity: 0.8 }}>
                Valid till&nbsp;
                {moment(data?.MasterBookings.results[0].bookingDetails?.coupon?.validTill).format('DD MMM YYYY')}
              </small>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col md={12} className="text-center">
        <Button
          className=""
          color="success"
          onClick={() => {
            history.push(`/client/voucher-bookings`);
          }}
        >
          {t(`View Reports`)}
          <i className="bx bxs-file-export text-light fs-20 align-middle me-1"></i>
        </Button>
      </Col>
    </Col>
  );
};

export default GeneratedVoucher;
