import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import apiCall from '../../apiCall';
import { customHeaders, indianProjectsBaseURL } from './../../../../Containers/Config/index';

import { APPROVE_REJECT_RECORDS, APPROVE_REJECT_RECORDS_SUCCESS, APPROVE_REJECT_RECORDS_ERROR } from './../../actionType';
import { Constant } from '../../../../Containers/Helpers/constant';
import cogoToast from 'cogo-toast';

function* approveRejectUpdate({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/admin/approve-reject-records`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        'Project-Code': 'ADMIN_ACCESS',
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({ type: APPROVE_REJECT_RECORDS_SUCCESS, payload: response });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({ type: APPROVE_REJECT_RECORDS_ERROR, payload: response.error });
    }
  } catch (error) {
    yield put({ type: APPROVE_REJECT_RECORDS_ERROR, payload: error });
  }
}

export function* watchApproveRejectUpdate() {
  yield takeEvery(APPROVE_REJECT_RECORDS, approveRejectUpdate);
}

function* approveRejectUpdateSaga() {
  yield all([fork(watchApproveRejectUpdate)]);
}

export default approveRejectUpdateSaga;
