import React, { useEffect, useState } from 'react';
import { BreadCrumb, Loader, PreviewCardHeader } from '../../../../Common';
import { Button, Card, CardBody, Container, Form, Progress, TabContent } from 'reactstrap';
import classNames from 'classnames';
import User from '../Components/Forms/User';
import FlightDetails from './Forms/FlightDetails';
import Summary from './Forms/Summary';
import Success from '../Components/Forms/Success';
import ConfirmationModal from '../Components/Modal/ConfirmationModal';
import Navigation from '../Components/Navigation/Navigation';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  CREATE_BOOKING,
  CREATE_BOOKING_EMPTY,
  CREATE_CLIENT_USER,
  CREATE_CLIENT_USER_EMPTY,
  GET_BENEFIT_ITEMS,
  GET_BENEFIT_ITEMS_EMPTY,
  GET_BINS_LIST,
  GET_BINS_LIST_EMPTY,
  GET_CLIENT_AIRPORT,
  GET_CLIENT_AIRPORT_EMPTY,
  GET_CLIENT_BENEFIT_CONFIG,
  GET_CLIENT_BENEFIT_CONFIG_EMPTY,
  GET_MEMBERSHIPS_DATA,
  GET_MEMBERSHIPS_DATA_EMPTY,
} from '../../../../../store/actions';
import { Constant } from '../../../../Helpers/constant';
import axios from 'axios';
import { indianProjectsBaseURL, customHeaders, uaeProjectsBaseURL } from '../../../../Config';
import cogoToast from 'cogo-toast';

const MeetAndGreetManualBooking = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [progressbarValue, setProgressbarValue] = useState(0);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isUserExist, setIsUserExist] = useState(null);
  const [bookingsDetails, setBookingsDetails] = useState(null);
  const [flightAndAddressDetails, setFlightAndAddressDetails] = useState(null);
  const [searchUserLoading, setSearchUserLoading] = useState(false);
  const [showBookingSuccess, setShowBookingSuccess] = useState(false);
  const [isUserUpdate, setisUserUpdate] = useState(false);
  const [isCreateUser, setIsCreateUser] = useState(true);
  const [binNumberForCreateUser, setBinNumberForCreateUser] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const { createUser, createUserLoading } = useSelector((state: any) => ({
    createUser: state.CreateClientUser.data,
    createUserLoading: state.CreateClientUser.loading,
  }));

  const { membershipData, membershipDataLoading } = useSelector((state: any) => ({
    membershipData: state.GetMembershipsData?.data?.results,
    membershipDataLoading: state.GetMembershipsData.loading,
  }));

  const { clientBenefitConfig, clientBenefitConfigLoading } = useSelector((state: any) => ({
    clientBenefitConfig: state.GetClientBenefitConfig.data?.results,
    clientBenefitConfigLoading: state.GetClientBenefitConfig.loading,
  }));

  const { getBenefitItems, getBenefitItemsLoading } = useSelector((state: any) => ({
    getBenefitItems: state.GetBenefitItems.data?.results,
    getBenefitItemsLoading: state.GetBenefitItems.loading,
  }));

  const { getClientAirport, getClientAirportLoading } = useSelector((state: any) => ({
    getClientAirport: state.GetClientAirport.data?.results,
    getClientAirportLoading: state.GetClientAirport.loading,
  }));

  const { createBooking, createBookingLoading } = useSelector((state: any) => ({
    createBooking: state.CreateBooking.data,
    createBookingLoading: state.CreateBooking.loading,
  }));

  const toggleConfirmationModal = () => {
    setIsConfirmationModalOpen(!isConfirmationModalOpen);
  };

  function toggleTab(tab, value) {
    if (activeTab !== tab) {
      let modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setActiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
    setProgressbarValue(value);
  }

  useEffect(() => {
    if (createUser !== null) {
      dispatch({
        type: GET_MEMBERSHIPS_DATA,
        payload: {
          urlParams: {
            userId: createUser?.user?.userId,
          },
        },
      });
      createUser.user.binNumber = binNumberForCreateUser;
      setIsUserExist(createUser?.user);
    }
  }, [createUser]);

  useEffect(() => {
    dispatch({
      type: GET_BINS_LIST,
    });
  }, []);

  useEffect(() => {
    if (membershipData?.length > 0) {
      const { benefitGroupId, membershipId, objectId } = membershipData[0];
      dispatch({
        type: GET_CLIENT_BENEFIT_CONFIG,
        payload: {
          // urlParam: {
          //   benefitGroupId,
          //   membershipId : objectId,
          // },
        },
      });
    }
  }, [membershipData]);

  useEffect(() => {
    if (clientBenefitConfig?.length > 0) {
      const benefitConfigDetails = clientBenefitConfig?.find(
        config => config?.bookingType?.typeId === Constant?.bookingTypes?.MEET_AND_GREET_BOOKINGS,
      );
      dispatch({
        type: GET_BENEFIT_ITEMS,
        payload: {
          urlParam: {
            benefitConfigId: benefitConfigDetails?.objectId,
            userId: isUserExist?.userId,
          },
        },
      });

      dispatch({
        type: GET_CLIENT_AIRPORT,
        payload: {
          urlParam: {
            objectIds: benefitConfigDetails?.airports,
          },
        },
      });
      setBookingsDetails({
        membershipId: membershipData[0]?.objectId,
        benefitConfigId: benefitConfigDetails?.objectId,
        address: isUserExist?.addresses,
        tat: benefitConfigDetails?.tat,
        remainingBookings: benefitConfigDetails?.remainingBookings,
        carTypes: benefitConfigDetails?.carTypes,
      });
    }
  }, [clientBenefitConfig]);

  useEffect(() => {
    if (getBenefitItems?.length > 0) {
      setBookingsDetails({ ...bookingsDetails, serviceId: getBenefitItems?.[0]?.service?.id });
    }
  }, [getBenefitItems]);

  useEffect(() => {
    if (getClientAirport?.length) {
      setBookingsDetails({ ...bookingsDetails, airports: getClientAirport });
      toggleTab(activeTab + 1, 50);
      setisUserUpdate(true);
    }
  }, [getClientAirport]);

  useEffect(() => {
    if (createBooking !== null) {
      setShowBookingSuccess(true);
      dispatch({
        type: GET_BINS_LIST_EMPTY,
      });
      dispatch({
        type: CREATE_CLIENT_USER_EMPTY,
      });
      dispatch({
        type: GET_MEMBERSHIPS_DATA_EMPTY,
      });
      dispatch({
        type: GET_BENEFIT_ITEMS_EMPTY,
      });
      dispatch({
        type: GET_CLIENT_BENEFIT_CONFIG_EMPTY,
      });
      dispatch({
        type: GET_CLIENT_AIRPORT_EMPTY,
      });
      setIsUserExist(null);
      setActiveTab(1);
      setPassedSteps([1]);
    }
  }, [createBooking]);

  useEffect(() => {
    if (
      createUserLoading ||
      membershipDataLoading ||
      clientBenefitConfigLoading ||
      getBenefitItemsLoading ||
      searchUserLoading ||
      createBookingLoading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    createUserLoading || membershipDataLoading || clientBenefitConfigLoading || getBenefitItemsLoading || searchUserLoading || createBookingLoading,
  ]);

  const searchUser = async (phoneNumber: string, countryCode: string) => {
    setSearchUserLoading(true);
    const userDetails = await axios({
      method: `GET`,
      url: `${uaeProjectsBaseURL}/admin/v1/fetch-customer-details`,
      headers: {
        Authorization: Constant.CLIENT_TOKEN,
        ...customHeaders,
        'X-Requested-By': 'one-admin',
      },
      params: {
        phoneNumber: phoneNumber,
        countryCode: countryCode,
      },
    });
    if (userDetails?.data) {
      setIsUserExist(userDetails?.data);
    } else {
      cogoToast.warn('user not found');
    }
    setIsCreateUser(false);
    setSearchUserLoading(false);
  };

  const submitUser = async data => {
    if (isUserExist) {
      if (!isUserUpdate) {
        dispatch({
          type: GET_MEMBERSHIPS_DATA,
          payload: {
            urlParams: {
              userId: isUserExist?.userId,
            },
          },
        });
        setIsUserExist({ ...isUserExist, binNumber: data?.binNumber });
      } else {
        toggleTab(2, 50);
      }
      return;
    }
    dispatch({
      type: CREATE_CLIENT_USER,
      payload: {
        data: {
          firstName: data?.name?.split(' ')[0],
          lastName: data?.name?.split(' ')[1],
          countryCode: data?.countryCode,
          phoneNumber: data?.phoneNumber,
          email: data?.email,
          card: {
            binId: data?.binNumber?.data?.binId,
            first: data?.binNumber?.data?.number,
          },
        },
      },
    });
    setBinNumberForCreateUser(data?.binNumber);
  };

  const submitFlightAndAddress = data => {
    setFlightAndAddressDetails(data);
    toggleTab(activeTab + 1, 100);
  };

  const handleConfirm = () => {
    const user = JSON.parse(localStorage.getItem('authUser'))?.data;
    dispatch({
      type: CREATE_BOOKING,
      payload: {
        data: {
          process: 'initiate-booking',
          numberOfLuggages: flightAndAddressDetails?.noOfBags?.value ?? '',
          porter: flightAndAddressDetails?.porter?.value === 'Yes' ? true : false,
          bookingType: '1', // 1 - Complimentary, 2 - Discounted
          serviceType: {
            type: flightAndAddressDetails?.journeyType?.value, // arrival, departure
          },
          classOfTravel: {
            type: flightAndAddressDetails?.classOfTravel?.value,
          },
          airport: {
            id: flightAndAddressDetails?.airportName?.data?.objectId ?? '',
            name: flightAndAddressDetails?.airportName?.data?.name ?? '',
            location: '-',
          },
          flightNumber: flightAndAddressDetails?.flightNumber,
          terminal: flightAndAddressDetails?.terminalNumber,
          guest:
            flightAndAddressDetails?.type === 'guest'
              ? [
                  {
                    name: flightAndAddressDetails?.name ?? '',
                    phone: flightAndAddressDetails?.phoneNumber ?? '',
                    email: flightAndAddressDetails?.email ?? '',
                  },
                ]
              : [],
          bookingDate: flightAndAddressDetails?.dateOfService
            ? moment(`${flightAndAddressDetails?.dateOfService} ${flightAndAddressDetails?.timeOfService}`, 'YYYY-MM-DD HH:mm').toISOString()
            : '',
          date: flightAndAddressDetails?.dateOfService ?? '',
          time: flightAndAddressDetails?.timeOfService ?? '',
          preferredComms: {
            email: user?.email ?? '',
            mobileNumber: user?.phoneNumber ?? '',
            countryCode: user?.countryCode ?? '',
          },
          remarks: flightAndAddressDetails?.remarks ?? '',
        },
        urlParams: {
          benefitConfigId: bookingsDetails?.benefitConfigId,
          membershipId: bookingsDetails?.membershipId,
          serviceId: bookingsDetails?.serviceId,
          userId: isUserExist?.userId,
        },
      },
    });
    setIsConfirmationModalOpen(false);
  };

  useEffect(() => {
    return () => {
      window.location.reload();
    };
  }, []);

  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/client/dashboard`);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="page-content">
        <ConfirmationModal isOpen={isConfirmationModalOpen} toggle={toggleConfirmationModal} onConfirm={handleConfirm} />
        <Container fluid className="position-relative">
          <BreadCrumb title="Program" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Meet And Greet`]} />
          <Card>
            <CardBody className="form-steps">
              {!showBookingSuccess && (
                <>
                  <Navigation
                    progressbarvalue={progressbarValue}
                    activeTab={activeTab}
                    toggleTab={toggleTab}
                    bookingTypeId={6}
                    bookingType={'Meet And Greet'}
                  />
                  <TabContent activeTab={activeTab} style={{ marginTop: '50px' }}>
                    <User
                      searchUser={searchUser}
                      submitUser={submitUser}
                      isUserExist={isUserExist}
                      setIsUserExist={setIsUserExist}
                      setisUserUpdate={setisUserUpdate}
                      isCreateUser={isCreateUser}
                    />
                    <FlightDetails
                      activeTab={activeTab}
                      toggleTab={toggleTab}
                      bookingDetails={bookingsDetails}
                      submitFlightAndAddress={submitFlightAndAddress}
                    />
                    <Summary
                      activeTab={activeTab}
                      toggleTab={toggleTab}
                      setIsConfirmationModalOpen={setIsConfirmationModalOpen}
                      flightAndAddressDetails={flightAndAddressDetails}
                      isUserExist={isUserExist}
                      bookingsDetails={bookingsDetails}
                    />
                  </TabContent>
                </>
              )}
              {showBookingSuccess && <Success booking={createBooking} bookingType="MeetAndGreet" />}
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default MeetAndGreetManualBooking;
