import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Card, CardBody, Col, Row } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import { ErrorAlert } from '../ErrorAlert';
import { CloseIcon, CalenderIcon } from '../PricingViewer/icons';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { Constant } from '../../../../../Helpers/constant';
import axios from 'axios';
import { customHeaders, uaeProjectsBaseURL, baseUrl } from '../../../../../Config';

import { GET_AMENITIES_DETAILS } from '../../../../../../store/actions';
import moment from 'moment';
import { Loader } from '../../../../../Common';

const validationSchema = Yup.object().shape({
  golfCourse: Yup.object().required('Golf Course is required'),
  service: Yup.object().required('Service is required'),
  contractType: Yup.object().required('Contract Type is required'),
  customerType: Yup.object().required('Customer Type is required'),
  program: Yup.object().required('Program  is required'),
  benefitGroup: Yup.object().required('Benefit Group is required'),
  searchType: Yup.string().required('Search Type is required'),
  elements: Yup.array().min(1, 'Elements is required'),
  date: Yup.date().test('conditionalRequired', 'Field is required', function (value) {
    const { searchType } = this.parent;
    if (searchType === '1') {
      return !!value;
    }
    return true;
  }),
  dateFrom: Yup.date().test('conditionalRequired', 'Field is required', function (value) {
    const { searchType } = this.parent;
    if (searchType === '2') {
      return !!value;
    }
    return true;
  }),
  dateTo: Yup.date().test('conditionalRequired', 'Field is required', function (value) {
    const { searchType } = this.parent;
    if (searchType === '2') {
      return !!value;
    }
    return true;
  }),
});

const SearchFormComponent = ({ setFilteredInvoiceData, setSearchFormData, navigation, setNavigation, setPage }) => {
  const dispatch = useDispatch();
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const priceType = 1;

  const [amenitiesPresent, setAmenitiesPresent] = useState([]);

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      searchType: '1',
      elements: [],
      golfCourse: null,
      service: null,
      contractType: null,
      customerType: null,
      program: null,
      benefitGroup: null,
      date: '',
      peakType: '1',
      dateFrom: '',
      dateTo: '',
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      getPricingDetails(values);
    },
  });

  const getPricingDetails = async values => {
    try {
      let filterAmenitiesPresentLabel = [];
      let filterAmenitiesNotPresent = [];
      if (amenities?.results) {
        filterAmenitiesPresentLabel = amenities?.results
          .map(amenity => {
            if (amenitiesPresent.includes(amenity?.json) && amenity?.flag) {
              return amenity.label;
            }
          })
          .filter(Boolean);
        setSearchFormData({ ...values, amenitiesPresent: amenitiesPresent, amenitiesPresentLabel: filterAmenitiesPresentLabel });

        filterAmenitiesNotPresent = amenities?.results
          .map(amenity => {
            if (!amenitiesPresent.includes(amenity?.json) && amenity?.flag) {
              return amenity.json;
            }
          })
          .filter(Boolean);
      }

      let data = {
        facilityId: values?.golfCourse?.value ?? '',
        serviceId: values?.service?.value ?? '',
        contractType: values?.contractType?.value ?? '',
        customerType: values?.customerType?.value ?? '',
        priceType: priceType.toString(),
        projectId: values?.program?.value ?? '',
        benefitGroupId: values?.benefitGroup?.value ?? '',
        amenitiesPresent: amenitiesPresent ?? [],
        amenitiesNotPresent: filterAmenitiesNotPresent ?? [],
        // "amenitiesPresent": [
        //   "cart",
        //   "trolley",
        //   "clubsRent",
        //   "insurance",
        //   "lockerRoom",
        //   "drivingRangeAccess",
        //   "proShopDiscountAccess",
        //   "ballsBucket",
        //   "caddy",
        //   "fAndB"
        // ],
        // "amenitiesNotPresent": [],
        applicableDate: {
          isDateRangeSelected: values?.searchType == 2 ? true : false,
          isPeakDay: values?.searchType == 2 && values?.peakType == 1 ? true : false,
        },
      };

      let dateTo = undefined;
      let dateFrom = undefined;
      if (values?.searchType == 2) {
        dateTo = values?.dateTo?.[0] ?? '';
        dateFrom = values?.dateFrom?.[0] ?? '';
      } else {
        dateTo = values?.date?.[0] ?? '';
        dateFrom = values?.date?.[0] ?? '';
      }

      data.applicableDate['to'] = dateTo ? moment(dateTo).format('YYYY-MM-DD') : '';
      data.applicableDate['from'] = dateFrom ? moment(dateFrom).format('YYYY-MM-DD') : '';

      setLoading(true);
      const response = await fetch(`${baseUrl}/admin/get-price-detail`, {
        method: `POST`,
        url: `${uaeProjectsBaseURL}/admin/services`,
        headers: {
          Authorization: Constant?.CLIENT_TOKEN,
          ...customHeaders,
          'Project-Code': 'ADMIN_ACCESS',
        },
        body: JSON.stringify(data),
      });

      if (response?.ok) {
        setLoading(false);
        let response2 = await response.json();
        const invoiceData = response2?.results?.[0];
        setFilteredInvoiceData(invoiceData);
        setNavigation([...navigation, 'Read']);
        setPage(2);
      } else {
        let sdd = await response.text().then(text => {
          return JSON.parse(text)?.message;
        });
        throw new Error(sdd);
      }
    } catch (e) {
      setLoading(false);
      setFilteredInvoiceData({});
      setErrorMessage(e.message);
      setErrorModal(true);
    }
  };

  async function loadGolfCourseOptions(search, loadedOptions) {
    return await axios({
      method: `GET`,
      url: `${uaeProjectsBaseURL}/admin/facilities`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        categoryId: process.env.REACT_APP_GOLF_CATEGORY_ID,
        name: search === '' ? null : search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data.objectId,
              label: `${data.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  async function loadServiceOptions(search, loadedOptions) {
    return await axios({
      method: `GET`,
      url: `${uaeProjectsBaseURL}/admin/services`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        // ...urlParam,
        facilityId: formik?.values?.golfCourse?.value,
        name: search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data.objectId,
              label: `${data.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        formik.setFieldValue('service', null);
        return err;
      });
  }

  async function loadContractTypeOptions(search, loadedOptions) {
    const results = [
      {
        objectId: 'Tour Operator',
        name: 'Tour Operator',
      },
      {
        objectId: 'Wildcard',
        name: 'Wildcard',
      },
    ];

    return {
      options: results?.map(data => {
        return {
          value: data.objectId,
          label: `${data.name}`,
          completeData: data,
        };
      }),
      hasMore: false,
    };
  }

  async function loadCustomerTypeOptions(search, loadedOptions) {
    const results = [
      {
        objectId: 'Indian',
        name: 'Indian',
      },
      {
        objectId: 'Expat',
        name: 'Expat',
      },
      {
        objectId: 'Member',
        name: 'Member',
      },
      {
        objectId: 'Non-Member',
        name: 'Non-Member',
      },
      {
        objectId: 'Guest',
        name: 'Guest',
      },
      {
        objectId: 'Guest with Member',
        name: 'Guest with Member',
      },
    ];

    return {
      options: results?.map(data => {
        return {
          value: data.objectId,
          label: `${data.name}`,
          completeData: data,
        };
      }),
      hasMore: false,
    };
  }

  async function loadProgramOptions(search, loadedOptions) {
    return await axios({
      method: `GET`,
      url: `${uaeProjectsBaseURL}/admin/projects`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        // ...urlParam,
        name: search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data.id,
              label: `${data.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  async function loadBenefitGroupOptions(search, loadedOptions) {
    return await axios({
      method: `GET`,
      url: `${uaeProjectsBaseURL}/admin/benefitGroups`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        // ...urlParam,
        projectId: formik.values.program?.value,
        name: search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data.objectId,
              label: `${data.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });

    // const results = [
    //   {
    //     objectId: 'rFEX7QbgCi',
    //     name: 'invalid',
    //   }
    // ];

    // return {
    //   options: results?.map(data => {
    //     return {
    //       value: data.objectId,
    //       label: `${data.name}`,
    //       completeData: data,
    //     };
    //   }),
    //   hasMore: false,
    // };
  }

  const { amenities, amenitiesLoading } = useSelector(state => ({
    amenities: state?.GetAmenitiesDetails?.data,
    amenitiesLoading: state?.GetAmenitiesDetails?.loading,
  }));

  // const amenities = {
  //   results: [
  //     {
  //       flag: true,
  //       json: "1",
  //       label: "1"
  //     },
  //     {
  //       flag: true,
  //       json: "2",
  //       label: "2"
  //     },
  //     {
  //       flag: false,
  //       json: "3",
  //       label: "3"
  //     }, {
  //       flag: true,
  //       json: "4",
  //       label: "4"
  //     },

  //   ]
  // }

  useEffect(() => {
    if (formik.values.service && formik.values.golfCourse) {
      dispatch({
        type: GET_AMENITIES_DETAILS,
        payload: {
          data: {
            facilityId: formik.values.golfCourse.value,
            serviceId: formik.values.service.value,
            priceType: priceType.toString(),
            isClientPrice: false,
          },
          urlParam: {
            isClientPrice: false,
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          },
        },
      });
    }
  }, [dispatch, formik.values.service, formik.values.golfCourse]);

  const handleElementsChange = e => {
    const { checked, name, value } = e.target;
    if (checked) {
      formik.setFieldValue('elements', [...formik.values.elements, value]);
    } else {
      formik.setFieldValue(
        'elements',
        formik.values.elements.filter(v => v !== value),
      );
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (amenitiesLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [amenitiesLoading]);

  return (
    <>
      {loading && <Loader />}

      <CardBody>
        <form onSubmit={formik.handleSubmit}>
          <Card className="card rounded border border-light">
            <CardBody>
              <div>
                <h4 className="fs-5 fw-medium text-truncate">Input</h4>
              </div>

              <Card className="card rounded border border-light">
                <CardBody>
                  <div>
                    <h4 className="fs-5 fw-medium text-truncate">Supply</h4>
                  </div>

                  <Row className="mt-3">
                    <Col xl={3}>
                      <div>
                        <label className="small">
                          Golf Course <span className="text-danger">*</span>
                        </label>
                        <AsyncPaginate
                          name="golfCourse"
                          value={formik.values.golfCourse}
                          loadOptions={loadGolfCourseOptions}
                          onChange={e => {
                            formik.setFieldValue('golfCourse', { label: e?.label, value: e?.value });
                            formik.setFieldValue('service', null);
                            loadServiceOptions('');
                          }}
                          // isClearable={true}
                          onBlur={formik.handleBlur}
                          placeholder="Select any one"
                          className="custom-selector"
                        />
                        {formik.touched.golfCourse && formik.errors.golfCourse && <p className="text-danger">{formik.errors.golfCourse}</p>}
                      </div>
                    </Col>
                    <Col xl={3} className="mt-2 mt-sm-0">
                      <div>
                        <label className="small">
                          Service <span className="text-danger">*</span>
                        </label>
                        <AsyncPaginate
                          name="service"
                          value={formik.values.service}
                          loadOptions={loadServiceOptions}
                          onChange={e => {
                            formik.setFieldValue('service', { label: e?.label, value: e?.value });
                          }}
                          onBlur={formik.handleBlur}
                          placeholder="Select any one"
                          className="custom-selector"
                          isDisabled={!formik.values.golfCourse ? true : false}
                          cacheUniqs={[formik.values.golfCourse]}
                        />
                        {formik.touched.service && formik.errors.service && <p className="text-danger">{formik.errors.service}</p>}
                      </div>
                    </Col>
                    <Col xl={3} className="mt-2 mt-sm-0">
                      <div>
                        <label className="small">
                          Contract Type <span className="text-danger">*</span>
                        </label>
                        <AsyncPaginate
                          name="contractType"
                          value={formik.values.contractType}
                          loadOptions={loadContractTypeOptions}
                          onChange={e => {
                            formik.setFieldValue('contractType', { label: e?.label, value: e?.value });
                          }}
                          onBlur={formik.handleBlur}
                          placeholder="Select any one"
                          className="custom-selector"
                        />
                        {formik.touched.contractType && formik.errors.contractType && <p className="text-danger">{formik.errors.contractType}</p>}
                      </div>
                    </Col>
                    <Col xl={3} className="mt-2 mt-sm-0">
                      <div>
                        <label className="small">
                          Customer Type <span className="text-danger">*</span>
                        </label>
                        <AsyncPaginate
                          name="customerType"
                          value={formik.values.customerType}
                          loadOptions={loadCustomerTypeOptions}
                          onChange={e => {
                            formik.setFieldValue('customerType', { label: e?.label, value: e?.value });
                          }}
                          onBlur={formik.handleBlur}
                          placeholder="Select any one"
                          className="custom-selector"
                        />
                        {formik.touched.customerType && formik.errors.customerType && <p className="text-danger">{formik.errors.customerType}</p>}
                      </div>
                    </Col>
                  </Row>

                  {formik.values.service && formik.values.golfCourse && (
                    <div>
                      <div className="mt-3 d-flex align-items-center gap-2">
                        <div>
                          <h4 className="fs-5 fw-medium text-truncate m-0">Elements - </h4>
                        </div>
                        <div>
                          {amenities?.results?.length > 0 &&
                            amenities?.results?.map((value, key) => (
                              <div key={key} className="form-check form-check-inline user-select-none">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name={`elements[${key}]`}
                                  id={`inlineCheckbox${key}`}
                                  defaultValue={value?.json}
                                  disabled={value?.flag ? false : true}
                                  onChange={e => {
                                    handleElementsChange(e);
                                    setAmenitiesPresent(prev => {
                                      if (prev && !prev?.includes(e.target.value)) {
                                        return [...prev, e.target.value];
                                      } else if (prev && prev?.includes(e.target.value)) {
                                        return prev.filter(item => {
                                          return item !== e.target.value;
                                        });
                                      } else {
                                        return [];
                                      }
                                    });
                                  }}
                                />
                                <label className="form-check-label" htmlFor={`inlineCheckbox${key}`}>
                                  {value?.label}
                                </label>
                              </div>
                            ))}
                        </div>
                      </div>
                      {formik.touched.elements && formik.errors.elements && <p className="text-danger">{formik.errors.elements}</p>}
                    </div>
                  )}
                </CardBody>
              </Card>

              <Card className="card rounded border border-light">
                <CardBody>
                  <div>
                    <h4 className="fs-5 fw-medium text-truncate">Demand </h4>
                  </div>

                  <Row className="mt-3">
                    <Col xl={3}>
                      <div>
                        <label className="small">
                          Program <span className="text-danger">*</span>
                        </label>
                        <AsyncPaginate
                          name="program"
                          value={formik.values.program}
                          loadOptions={loadProgramOptions}
                          onChange={e => {
                            formik.setFieldValue('program', { label: e?.label, value: e?.value });
                            formik.setFieldValue('benefitGroup', null);
                            loadBenefitGroupOptions('');
                          }}
                          onBlur={formik.handleBlur}
                          placeholder="Select any one"
                          className="custom-selector"
                        />
                        {formik.touched.program && formik.errors.program && <p className="text-danger">{formik.errors.program}</p>}
                      </div>
                    </Col>
                    <Col xl={3} className="mt-2 mt-sm-0">
                      <div>
                        <label className="small">
                          Benefit Group <span className="text-danger">*</span>
                        </label>
                        <AsyncPaginate
                          name="benefitGroup"
                          value={formik?.values?.benefitGroup}
                          loadOptions={loadBenefitGroupOptions}
                          onChange={e => {
                            formik.setFieldValue('benefitGroup', { label: e?.label, value: e?.value });
                          }}
                          onBlur={formik.handleBlur}
                          placeholder="Select any one"
                          className="custom-selector"
                          isDisabled={!formik?.values?.program ? true : false}
                          cacheUniqs={[formik?.values?.program]}
                        />
                        {formik.touched.benefitGroup && formik.errors.benefitGroup && <p className="text-danger">{formik.errors.benefitGroup}</p>}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              {/* {JSON.stringify(formik.errors)} */}

              <Card className="card rounded border border-light">
                <CardBody>
                  <div>
                    <h4 className="fs-5 fw-medium text-truncate">Search</h4>
                  </div>

                  <div>
                    <div className="form-check form-check-inline user-select-none">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="searchType"
                        id="inlineRadio1"
                        defaultChecked
                        value={1}
                        onInput={e => {
                          formik.setFieldValue('searchType', e.target.value);
                          formik.setFieldValue('date', '');
                          formik.setFieldValue('dateFrom', '');
                          formik.setFieldValue('dateTo', '');
                        }}
                      />
                      <label className="form-check-label" htmlFor="inlineRadio1">
                        Search by Day
                      </label>
                    </div>
                    <div className="form-check form-check-inline user-select-none">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="searchType"
                        id="inlineRadio2"
                        value={2}
                        onInput={e => {
                          formik.setFieldValue('searchType', e.target.value);

                          formik.setFieldValue('date', '');
                          formik.setFieldValue('dateFrom', '');
                          formik.setFieldValue('dateTo', '');
                        }}
                      />
                      <label className="form-check-label" htmlFor="inlineRadio2">
                        Search by Date Range
                      </label>
                    </div>
                  </div>

                  {formik.values.searchType == 1 && <SelectByDateComponent formik={formik} />}
                  {formik.values.searchType == 2 && <SelectByDateRangeComponent formik={formik} />}
                </CardBody>
              </Card>

              <div>
                <button type="submit" className="btn btn-primary d-flex ms-auto gap-2 align-items-center">
                  <svg width={18} height={18} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11 2C15.968 2 20 6.032 20 11C20 15.968 15.968 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2ZM11 18C14.8675 18 18 14.8675 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18ZM19.4853 18.0711L22.3137 20.8995L20.8995 22.3137L18.0711 19.4853L19.4853 18.0711Z"
                      fill="white"
                    />
                  </svg>

                  <span>Search</span>
                </button>
              </div>
            </CardBody>
          </Card>
        </form>
      </CardBody>
      <ErrorAlert modal={errorModal} setModal={setErrorModal} errorMessage={errorMessage} />
    </>
  );
};

export default SearchFormComponent;

function SelectByDateComponent({ formik }) {
  return (
    <Row className="mt-3">
      <Col xl={3}>
        <div>
          <label className="small">Select Date</label>
          <div className="position-relative">
            <Flatpickr
              name="date"
              className="form-control"
              options={{
                dateFormat: 'Y-m-d',
                // minDate: 'today',
              }}
              placeholder="Choose a date"
              id="date-select"
              onChange={date => formik.setFieldValue(`date`, date)}
              value={formik.values.date}
            />

            <div className="date-icons">
              {formik.values.date && (
                <span className="cursor-pointer" onClick={() => formik.setFieldValue('date', '')}>
                  <CloseIcon />
                </span>
              )}
              <label className="date-picker-icon" htmlFor="date-select">
                <CalenderIcon />
              </label>
            </div>
          </div>

          {formik.touched.date && formik.errors.date && <p className="text-danger">{formik.errors.date}</p>}
        </div>
      </Col>
    </Row>
  );
}

function SelectByDateRangeComponent({ formik }) {
  return (
    <div className="d-flex justify-content-between flex-column flex-lg-row">
      <div className="mt-4 d-flex align-items-center gap-2" style={{ zIndex: 0 }}>
        <span className="d-none d-md-block">Date Type - </span>
        <div className="btn-group custom-btn-group" role="group" aria-label="Basic radio toggle button group">
          <input
            type="radio"
            className="btn-check"
            name="peakType"
            id="btnradio1"
            autoComplete="off"
            value={1}
            defaultChecked
            onInput={e => formik.setFieldValue('peakType', e.target.value)}
          />
          <label className="btn btn-outline-primary" htmlFor="btnradio1">
            Peak Day
          </label>

          <input
            type="radio"
            className="btn-check"
            name="peakType"
            id="btnradio2"
            autoComplete="off"
            value={2}
            onInput={e => formik.setFieldValue('peakType', e.target.value)}
          />
          <label className="btn btn-outline-primary" htmlFor="btnradio2">
            Non-Peak Day
          </label>
        </div>
      </div>
      <div className="d-flex gap-2 align-items-center mt-3 mt-lg-0">
        <span className="d-none d-md-block">Between - </span>
        <div className="d-flex gap-2">
          <div>
            <label className="small">From</label>
            <div className="position-relative">
              <Flatpickr
                className="form-control"
                options={{
                  dateFormat: 'Y-m-d',
                  // minDate: 'today',
                }}
                placeholder="Choose a date"
                id="from-date"
                name="dateFrom"
                onChange={date => formik.setFieldValue(`dateFrom`, date)}
                value={formik.values.dateFrom}
              />
              <div className="date-icons">
                {formik.values.dateFrom && (
                  <span className="cursor-pointer" onClick={() => formik.setFieldValue('dateFrom', '')}>
                    <CloseIcon />
                  </span>
                )}
                <label className="date-picker-icon" htmlFor="from-date">
                  <CalenderIcon />
                </label>
              </div>
            </div>
            {formik.touched.dateFrom && formik.errors.dateFrom && <p className="text-danger">{formik.errors.dateFrom}</p>}
          </div>
          <div>
            <label className="small">To</label>
            <div className="position-relative">
              <Flatpickr
                className="form-control"
                options={{
                  dateFormat: 'Y-m-d',
                  // minDate: 'today',
                }}
                placeholder="Choose a date"
                id="to-date"
                name="dateTo"
                onChange={date => formik.setFieldValue(`dateTo`, date)}
                value={formik.values.dateTo}
              />

              <div className="date-icons">
                {formik.values.dateTo && (
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      formik.setFieldValue('dateTo', '');
                    }}
                  >
                    <CloseIcon />
                  </span>
                )}
                <label className="date-picker-icon" htmlFor="to-date">
                  <CalenderIcon />
                </label>
              </div>
            </div>
            {formik.touched.dateTo && formik.errors.dateTo && <p className="text-danger">{formik.errors.dateTo}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}
