import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
import * as Yup from 'yup';
import Select from 'react-select';
import { Formik, Form } from 'formik';
import { GoPlus } from 'react-icons/go';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Card, CardBody, Col, Row, CardFooter, Label } from 'reactstrap';

import { PreviewCardHeader, FormikInput, BreadCrumb } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import { indianProjectsBaseURL, customHeaders, commsHeader } from '../../../../../../Config';

const language = [
  { label: 'Hindi', value: 'hi' },
  { label: 'English', value: 'en' },
];

const priority_type = [
  { label: 'Transactional', value: 'transactional' },
  { label: 'Promotional', value: 'promotional' },
  { label: 'Delayed', value: 'delayed' },
];

const channelType = [
  { label: 'Sms', value: 'sms' },
  { label: 'Whatsapp', value: 'whatsapp' },
  { label: 'Email', value: 'email' },
];
const category = [
  { label: 'Implicit', value: 'implicit' },
  { label: 'Explicit', value: 'explicit' },
];

const validationSchema = Yup.object().shape({
  template_name: Yup.string().trim().required('Kindly enter a template name'),
  priority_type: Yup.string().required('Please select a priority type'),
  channel: Yup.string().required('Please select a category'),
  category: Yup.string().when('channel', {
    is: 'sms',
    then: () => Yup.string().required('Please select a category'),
    otherwise: () => Yup.string().notRequired(),
  }),
  id: Yup.string().when('channel', {
    is: 'sms',
    then: () => {
      return Yup.string().trim().required('DLT ID is required');
    },
    otherwise: () => Yup.string().notRequired(),
  }),
  language: Yup.string().required('Kindly enter a language'),
  subject: Yup.string().when('channel', {
    is: 'Email',
    then: () => {
      return Yup.string().required('Subject is required for Email templates');
    },
    otherwise: () => Yup.string().notRequired(),
  }),
  from: Yup.string().email('Invalid email format'),
});

const AddTemplateEngineForm = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [vendors, setVendors] = useState([{ id: null, reference_id: '' }]);
  const [submitTemplate, setSubmitTemplate] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [eventRequired, setEventRequired] = useState(false);
  const [event, setEvent] = useState({});
  const [initialValues, setInitialValues] = useState({
    template_name: '',
    priority_type: null,
    templateID: '',
    channel: null,
    category: null,
    id: '',
    language: 'en',
  });

  useEffect(() => {
    if (props.title === 'Update Template' && props?.previousForm === null) {
      setInitialValues({
        ...initialValues,
        templateID: props?.data?.id,
        priority_type: props?.data?.priority_type,
        template_name: props?.data?.template_name,
        channel: props?.data?.channel,
        id: props?.data?.dlt?.id,
        category: props?.data?.dlt?.category,
        language: props?.data?.content?.language,
      });
      setEvent({
        value: props?.data?.event_id,
        label: props?.data?.event_type,
        completeData: 'data',
      });
      if (props?.data?.vendor?.length > 0) {
        const updatedVendor = props?.data?.vendor?.map(obj => {
          const newObj = {};
          for (const key in obj) {
            if (Object.hasOwnProperty.call(obj, key)) {
              if (key === 'id') {
                newObj[key] = {
                  value: obj?.[key], // Optional chaining here
                  label: obj?.[key], // Optional chaining here
                };
              } else {
                newObj[key] = obj?.[key]; // Optional chaining here
              }
            }
          }
          return newObj;
        });
        setVendors(updatedVendor);
      }
    }
  }, []);

  useEffect(() => {
    if (props?.previousForm != null) {
      setInitialValues({
        templateID: props?.previousForm?.templateID,
        priority_type: props?.previousForm?.priority_type,
        template_name: props?.previousForm?.template_name,
        channel: props?.previousForm?.channel,
        id: props?.previousForm?.dlt?.id,
        category: props?.previousForm?.dlt?.category,
        language: props?.previousForm?.content?.language,
      });
      setEvent({
        value: props?.previousForm?.event_id,
        label: props?.previousForm?.event_type,
        completeData: '',
      });
      if (props?.previousForm?.vendor?.length > 0) {
        const updatedVendor = props?.previousForm?.vendor?.map(obj => {
          const newObj = {};
          for (const key in obj) {
            if (Object.hasOwnProperty.call(obj, key)) {
              if (key === 'id') {
                newObj[key] = {
                  value: obj?.[key], // Optional chaining here
                  label: obj?.[key], // Optional chaining here
                };
              } else {
                newObj[key] = obj?.[key]; // Optional chaining here
              }
            }
          }
          return newObj;
        });
        setVendors(updatedVendor);
      }
    }
  }, [props?.previousForm]);

  async function loadVendors(search) {
    let urlParam = null;
    return await axios({
      method: `GET`,
      url: `${indianProjectsBaseURL}/comms/vendor`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        ...commsHeader,
      },
      params: {
        ...urlParam,
        vendor_name: search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.map(data => {
            return {
              value: data?.vendor_name,
              label: data?.vendor_name,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  const onSelectVendor = (selectedOption, index) => {
    const updatedVendors = [...vendors];

    // Ensure that updatedVendors[index] is not null or undefined before attempting to update
    if (updatedVendors[index] != null) {
      updatedVendors[index].id = selectedOption;
    }

    setVendors(updatedVendors);
    const updatedValidationErrors = [...validationErrors];
    updatedValidationErrors[index] = {
      id: false,
      reference_id: updatedValidationErrors[index]?.reference_id,
    };
    setValidationErrors(updatedValidationErrors);
  };

  const onReferenceIdChange = (e, index) => {
    const updatedVendors = [...vendors];
    if (updatedVendors[index] != null) {
      updatedVendors[index].reference_id = e.target.value;
    }
    setVendors(updatedVendors);
    const updatedValidationErrors = [...validationErrors];
    updatedValidationErrors[index] = {
      if: updatedValidationErrors[index]?.id,
      reference_id: false,
    };
    setValidationErrors(updatedValidationErrors);
  };

  const addVendorField = () => {
    setVendors([...vendors, { id: null, reference_id: '' }]);
    setValidationErrors([...validationErrors, { id: false, reference_id: false }]);
  };

  async function loadEvents(search) {
    let urlParam = null;
    return await axios({
      method: `GET`,
      url: `${indianProjectsBaseURL}/comms/events`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        ...commsHeader,
      },
      params: {
        ...urlParam,
        event_type: search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.map(data => {
            return {
              value: data?.id,
              label: `${data?.event_type}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  }

  const onSelectEvent = selectedOption => {
    setEvent(selectedOption);
    setEventRequired(false);
  };

  const removeVendorField = index => {
    if (vendors.length > 1) {
      const updatedVendors = vendors.filter((_, i) => i !== index);
      setVendors(updatedVendors);
      const updatedValidationErrors = [...validationErrors];
      updatedValidationErrors.splice(index, 1);
      setValidationErrors(updatedValidationErrors);
    }
  };

  const createTemplate = values => {
    let createTemplatePayload;

    if (values?.channel === 'email') {
      createTemplatePayload = {
        template_name: values?.template_name,
        channel: values?.channel,
        event_id: event?.value,
        event_type: event?.label,
        content: {
          language: values?.language,
        },
        priority_type: values?.priority_type,
        created_by: values?.created_by,
        updated_by: values?.updated_by,
      };
    } else if (values?.channel === 'sms') {
      createTemplatePayload = {
        template_name: values?.template_name,
        channel: values?.channel,
        event_id: event?.value,
        event_type: event?.label,
        content: {
          language: values?.language,
        },
        priority_type: values?.priority_type,

        vendor: vendors.map(item => ({
          id: item?.id?.label,
          reference_id: item?.reference_id,
        })),
        created_by: values?.created_by,
        updated_by: values?.updated_by,
        dlt: {
          id: values?.id,
          category: values?.category,
        },
      };
    } else {
      createTemplatePayload = {
        template_name: values?.template_name,
        channel: values?.channel,
        event_id: event?.value,
        event_type: event?.label,
        content: {
          language: values?.language,
        },
        priority_type: values?.priority_type,
        vendor: vendors.map(item => ({
          id: item?.id?.label,
          reference_id: item?.reference_id,
        })),
        created_by: values?.created_by,
        updated_by: values?.updated_by,
      };
    }

    props?.onSubmit(createTemplatePayload);
  };

  const updateTemplate = values => {
    let uploadTemplatePayload;
    if (values?.channel === 'email') {
      uploadTemplatePayload = {
        templateID: props?.data?.id,
        template_name: values?.template_name,
        channel: values?.channel,
        event_id: event?.value,
        event_type: event?.label,
        priority_type: values?.priority_type,
        content: {
          language: values?.language,
          body: props?.data?.content?.body,
          subject: props?.data?.content?.subject,
          cc: props?.data?.content?.cc,
          bcc: props?.data?.content?.bcc,
          from: props?.data?.content?.from,
        },
        template_variables: {
          body_variables: props?.data?.template_variables?.body_variables,
          subject_variables: props?.data?.template_variables?.subject_variables,
        },
        updated_by: values?.updated_by,
        from: props?.data?.content?.from,
        is_active: props?.data?.is_active ? true : false,
      };
    } else if (values?.channel === 'sms') {
      uploadTemplatePayload = {
        templateID: props?.data?.id,
        template_name: values?.template_name,
        channel: values?.channel,
        event_id: event?.value,
        event_type: event?.label,
        priority_type: values?.priority_type,
        content: {
          language: values?.language,
          body: props?.data?.content?.body,
        },
        vendor: vendors.map(item => ({
          id: item?.id?.value,
          reference_id: item?.reference_id,
        })),
        template_variables: {
          body_variables: props?.data?.template_variables?.body_variables,
          subject_variables: props?.data?.template_variables?.subject_variables,
        },
        updated_by: values?.updated_by,
        dlt: {
          id: values?.id,
          category: values?.category,
        },
        is_active: props?.data?.is_active ? true : false,
      };
    } else {
      uploadTemplatePayload = {
        templateID: props?.data?.id,
        template_name: values?.template_name,
        channel: values?.channel,
        event_id: event?.value,
        event_type: event?.label,
        priority_type: values?.priority_type,
        content: {
          language: values?.language,
          body: props?.data?.content?.body,
        },
        vendor: vendors.map(item => ({
          id: item?.id?.value,
          reference_id: item?.reference_id,
        })),
        template_variables: {
          body_variables: props?.data?.template_variables?.body_variables,
          subject_variables: props?.data?.template_variables?.subject_variables,
        },
        updated_by: values?.updated_by,
        is_active: props?.data?.is_active ? true : false,
      };
    }
    props?.onSubmit(uploadTemplatePayload);
  };

  useEffect(() => {
    setSubmitTemplate(false);
  }, []);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push('/dashboard');
    } else if (title === `Template List`) {
      props?.setIsListShow(true);
      props?.setIsUpdateFormShow(false);
    }
  };

  return (
    <>
      <BreadCrumb
        title="Commnuication"
        navigationby={breadcrumNavigationHandler}
        navigation={props.title === 'Update Template' ? ['Dashboard', 'Template List', 'Update Template Form'] : ['Dashboard', 'Add Template Form']}
      />
      <Row>
        <Col xxl={12}>
          <Card>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting, resetForm, forceUpdate }) => {
                // Perform your form submission logic here.
                if (Object.keys(event).length === 0) {
                  setEventRequired(true);
                  return;
                }
                if (values?.channel === 'sms') {
                  const updatedValidationErrors = vendors.map(vendor => ({
                    id: !vendor.id,
                    reference_id: !vendor.reference_id?.trim(),
                  }));

                  setValidationErrors(updatedValidationErrors);
                  // Check if any field is invalid
                  if (updatedValidationErrors.some(errors => errors.id || errors.reference_id)) {
                    return;
                  }
                }
                const { data } = JSON.parse(localStorage.getItem('authUser'));
                values.created_by = data?.name;
                if (props?.title === 'Update Template') {
                  values.updated_by = data?.name;
                  updateTemplate(values);
                } else {
                  values.created_by = data?.name;
                  values.updated_by = data?.name;
                  createTemplate(values);
                }
                // setSubmitting(false);
              }}
            >
              {({ values, errors, handleSubmit, setFieldValue, isSubmitting, handleChange, touched }) => (
                <Form onSubmit={handleSubmit}>
                  <>
                    <PreviewCardHeader title={props.title === 'Update Template' ? 'Template Engine Updation' : 'Template Engine Creation'} />
                    {/* <button onClick={() => console.log(state)}>get state</button> */}
                    <CardBody>
                      <div className="live-preview">
                        <Row>
                          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                            <div className="mb-3">
                              <FormikInput
                                label="Template Name"
                                type="text"
                                name="template_name"
                                placeholder=""
                                value={values.template_name}
                                requiredField={true}
                              />
                            </div>
                          </Col>
                          {props?.title === 'Update Template' && (
                            <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                              <div className="mb-3">
                                <FormikInput
                                  label="Template ID"
                                  type="text"
                                  name="templateID"
                                  placeholder=""
                                  disabled={true}
                                  value={values.templateID}
                                  requiredField={true}
                                />
                              </div>
                            </Col>
                          )}
                          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                            <div className="mb-3">
                              <Label htmlFor="priority_type" className="form-label">
                                Priority Type
                                <span className="lbl_mandatory"> *</span>
                              </Label>
                              <Select
                                id="priority_type"
                                name="priority_type"
                                options={priority_type}
                                onChange={option => {
                                  handleChange('priority_type')(option.value);
                                }}
                                value={
                                  priority_type && values?.priority_type ? priority_type.find(option => option.value === values.priority_type) : ''
                                }
                              />
                              {touched.priority_type && !values.priority_type && <div style={{ color: 'red' }}>Please select a priority type</div>}
                            </div>
                          </Col>
                          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                            <div className="mb-3">
                              <Label htmlFor="channel" className="form-label">
                                Channel
                                <span className="lbl_mandatory"> *</span>
                              </Label>
                              <Select
                                id="channel"
                                name="channel"
                                options={channelType}
                                onChange={option => {
                                  if (values.channel !== option.value) {
                                    setVendors([{ id: null, reference_id: '' }]);
                                    setInitialValues({ ...initialValues, id: '', category: null });
                                  }
                                  handleChange('channel')(option.value);
                                }}
                                value={
                                  channelType && values?.channel
                                    ? channelType.find(option => {
                                        return option.value === values.channel;
                                      })
                                    : ''
                                }
                              />
                              {touched.channel && !values.channel && <div style={{ color: 'red' }}>Please select a channel type</div>}
                            </div>
                          </Col>
                          <Col xl={4} lg={4} md={6} sm={6} xs={12} className="mb-4">
                            <div className="d-flex justify-content-between">
                              <Label htmlFor="choices-multiple-default" className="form-label">
                                Choose Events
                                <span className="lbl_mandatory">*</span>
                              </Label>
                            </div>
                            <AsyncPaginate
                              debounceTimeout={500}
                              value={event}
                              loadOptions={loadEvents}
                              onChange={selectedOption => onSelectEvent(selectedOption)}
                              isMulti={false}
                              closeMenuOnSelect={true}
                              noOptionsMessage={() => 'No results found'}
                              cacheUniqs={[['code']]}
                              placeholder="Select..."
                            />
                            {eventRequired && (
                              <div
                                style={{
                                  color: 'red',
                                }}
                              >
                                event is required
                              </div>
                            )}
                          </Col>
                          <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                            <div className="mb-3">
                              <Label htmlFor="language" className="form-label">
                                Language
                                <span className="lbl_mandatory"> *</span>
                              </Label>
                              <Select
                                id="language"
                                name="language"
                                options={language}
                                onChange={option => {
                                  handleChange('language')(option.value);
                                }}
                                isDisabled={!values.channel ? true : false}
                                value={language && values?.language ? language.find(option => option.value === values.language) : ''}
                              />
                              {touched.language && !values.language && <div style={{ color: 'red' }}>Please select a language</div>}
                            </div>
                          </Col>

                          {values?.channel === 'sms' && (
                            <Col md={12}>
                              <Card className="card-inner">
                                <CardBody>
                                  <Row>
                                    <Col md={6}>
                                      <div className="mb-3">
                                        <FormikInput
                                          label="DLT ID"
                                          type="text"
                                          name="id"
                                          placeholder=""
                                          value={values.id}
                                          requiredField={values?.channel === 'Sms' ? true : false}
                                        />
                                      </div>
                                    </Col>
                                    <Col md={6}>
                                      <div className="mb-3">
                                        <Label htmlFor="category" className="form-label">
                                          Category
                                          <span className="lbl_mandatory"> *</span>
                                        </Label>
                                        <Select
                                          id="category"
                                          name="category"
                                          options={category}
                                          onChange={option => {
                                            handleChange('category')(option.value);
                                          }}
                                          value={category && values?.category ? category.find(option => option.value === values.category) : ''}
                                        />
                                        {touched.category && !values.category && <div style={{ color: 'red' }}>Please select a category</div>}
                                      </div>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          )}

                          {(values.channel === 'sms' || values.channel === 'whatsapp') && (
                            <Col md={12}>
                              <Card className="card-inner">
                                <PreviewCardHeader title="Vendors" />
                                <CardBody>
                                  {vendors?.map((vendor, index) => (
                                    <Row key={index}>
                                      <Col md={5}>
                                        <div className="d-flex justify-content-between">
                                          <Label htmlFor="choices-multiple-default" className="form-label">
                                            Choose Vendors
                                            <span className="lbl_mandatory">*</span>
                                          </Label>
                                        </div>
                                        <AsyncPaginate
                                          debounceTimeout={500}
                                          value={vendor?.id}
                                          loadOptions={loadVendors}
                                          onChange={selectedOption => onSelectVendor(selectedOption, index)}
                                          isMulti={false}
                                          closeMenuOnSelect={true}
                                          noOptionsMessage={() => 'No results found'}
                                          cacheUniqs={[['code']]}
                                          placeholder="Select..."
                                        />
                                        {validationErrors[index]?.id && (
                                          <div
                                            style={{
                                              color: 'red',
                                            }}
                                          >
                                            vendor is required
                                          </div>
                                        )}
                                      </Col>
                                      <Col md={5}>
                                        <div className="mb-3">
                                          <FormikInput
                                            label="Reference Id"
                                            type="text"
                                            name={`reference_id[${index}]`}
                                            placeholder=""
                                            value={vendor.reference_id}
                                            onChange={e => onReferenceIdChange(e, index)}
                                          />
                                          {validationErrors[index]?.reference_id && (
                                            <div
                                              style={{
                                                color: 'red',
                                              }}
                                            >
                                              reference id is required
                                            </div>
                                          )}
                                        </div>
                                      </Col>
                                      <Col md={2}>
                                        <div className="mb-3">
                                          <Label htmlFor="validityTatValue" className="form-label">
                                            &nbsp;
                                          </Label>
                                          <div className="d-flex gap-2">
                                            <button type="button" className={`btn btn-success`} onClick={addVendorField}>
                                              <GoPlus />
                                            </button>

                                            <button
                                              type="button"
                                              className="btn btn-danger"
                                              onClick={() => removeVendorField(index)}
                                              disabled={vendors.length === 1}
                                            >
                                              <i className="ri-delete-bin-5-line"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  ))}
                                </CardBody>
                              </Card>
                            </Col>
                          )}
                        </Row>
                      </div>
                    </CardBody>
                    <CardFooter>
                      {/* <Col md={12}>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary text-center"
                            // disabled={isSubmitting}
                            onClick={() => {
                              const updatedValidationErrors = vendors.map(vendor => ({
                                id: !vendor.id,
                                reference_id: !vendor.reference_id,
                              }));
                              setValidationErrors(updatedValidationErrors);
                              setSubmitTemplate(true);
                              if (Object.keys(event).length === 0) setEventRequired(true);
                              if (updatedValidationErrors.some(errors => errors.id || errors.reference_id)) {
                                return;
                              }
                            }}
                          >
                            {props.title === 'Update Template' ? 'Update Template' : 'Create Template'}
                          </button>
                        </div>
                      </Col> */}
                      <Col md={12}>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary text-center"
                            // disabled={isSubmitting}
                            onClick={() => {
                              const updatedValidationErrors = vendors.map(vendor => ({
                                id: !vendor.id,
                                reference_id: !vendor?.reference_id?.trim(),
                              }));
                              setValidationErrors(updatedValidationErrors);
                              setSubmitTemplate(true);
                              if (Object.keys(event).length === 0) setEventRequired(true);
                              if (updatedValidationErrors.some(errors => errors.id || errors.reference_id)) {
                                return;
                              }
                            }}
                          >
                            Next
                          </button>
                        </div>
                      </Col>
                    </CardFooter>
                  </>
                </Form>
              )}
            </Formik>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AddTemplateEngineForm;
