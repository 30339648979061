import { useEffect, useState } from 'react';
import { Card, Row, Col, CardBody, CardHeader, CardFooter } from 'reactstrap';
import { Grid, _ } from 'gridjs-react';
import moment from 'moment';
import { TablePagination } from '../../../../Common';

const FilesSummaryPreview = props => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      setData(
        props?.data?.map((data, index) => {
          return [
            `${props?.dataStartedFrom + (index + 1)}.`,
            data?.fileId || `NA`,
            data?.mobileNumber || `NA`,
            data?.email || `NA`,
            data?.expiryDate ? moment(`${data?.createdAt}`).format('DD-MM-YYYY, h:mm A') : `NA`,
            data?.status || `NA`,
            data?.errorMessage || `NA`,
          ];
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  return (
    <Card className="card-inner">
      <CardHeader className="d-flex">
        <h4 className="card-title mb-0 flex-grow-1">Jobs Summary</h4>
      </CardHeader>

      <CardBody>
        <Row>
          <Col md={12}>
            <div className="custom-striped-table">
              <Grid
                data={data}
                columns={[
                  {
                    name: 'S.No.',
                    width: '50px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'File ID',
                    width: '120px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Mobile No.',
                    width: '80px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Email ID',
                    width: '150px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Created At',
                    width: '80px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Status',
                    width: '80px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Failed Reason',
                    width: '150px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                ]}
                sort={true}
                // search={true}
                fixedHeader={true}
                height="400px"
                pagination={{ enabled: false, limit: 5 }}
                style={{
                  th: {
                    zIndex: '999',
                    textAlign: 'center',
                  },
                  td: {
                    position: 'relative',
                    textAlign: 'center',
                  },
                  li: {
                    color: 'blue',
                  },
                }}
              />
            </div>
          </Col>
        </Row>
      </CardBody>

      <CardFooter>
        <Row className="custom_pagination">
          <Col>
            <div className="d-flex justify-content-end">
              <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
            </div>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

export default FilesSummaryPreview;
