import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from '../../../Common';
import { Container } from 'reactstrap';
import {
  CREATE_SERVICE_WORTH,
  CREATE_SERVICE_WORTH_EMPTY,
  DELETE_SERVICE_WORTH,
  DELETE_SERVICE_WORTH_EMPTY,
  LIST_SERVICE_WORTH,
  LIST_SERVICE_WORTH_EMPTY,
  UPDATE_SERVICE_WORTH_EMPTY,
} from '../../../../store/application/actionType';
import ServiceWorthForm from './Components/Forms/ServiceWorthForm';
import { Constant } from '../../../Helpers/constant';
import ServiceWorthTable from './Components/Tables/ServiceWorthTable';
import ActionConfirmation from './Components/Modals/ConfirmationModal';
import UpdateServiceWorthModal from './Components/Modals/UpdateServiceWorthModal';
import Filters from './Components/Filters/Filters';

const ServiceWorthManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [isCreateForm, setIsCreateForm] = useState(false);

  const [currentSelectedDetails, setCurrentSelectedDetails] = useState([]);
  const [currentConfirmationModalActionFor, setCurrentConfirmationModalActionFor] = useState(null);

  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [isShowUpdateModal, setIsShowUpdateModal] = useState(false);

  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Service Worth List
  const { serviceWorths, serviceWorthsLoading, serviceWorthsError } = useSelector(state => ({
    serviceWorths: state?.ServiceWorthList?.data,
    serviceWorthsLoading: state?.ServiceWorthList?.loading,
    serviceWorthsError: state?.ServiceWorthList?.error,
  }));

  // Service Worth Rule
  const { createServiceWorth, createServiceWorthLoading, createServiceWorthError } = useSelector(state => ({
    createServiceWorth: state?.CreateServiceWorth?.data,
    createServiceWorthLoading: state?.CreateServiceWorth?.loading,
    createServiceWorthError: state?.CreateServiceWorth?.error,
  }));

  // Delete Service Worth
  const { deleteServiceWorth, deleteServiceWorthLoading, deleteServiceWorthError } = useSelector(state => ({
    deleteServiceWorth: state?.DeleteServiceWorth?.data,
    deleteServiceWorthLoading: state?.DeleteServiceWorth?.loading,
    deleteServiceWorthError: state?.DeleteServiceWorth?.error,
  }));

  // Update Service Worth
  const { updateServiceWorth, updateServiceWorthLoading, updateServiceWorthError } = useSelector(state => ({
    updateServiceWorth: state?.UpdateServiceWorth?.data,
    updateServiceWorthLoading: state?.UpdateServiceWorth?.loading,
    updateServiceWorthError: state?.UpdateServiceWorth?.error,
  }));

  // Services
  const { couponServices, couponServicesLoading } = useSelector(state => ({
    couponServices: state?.GetCouponServices?.data,
    couponServicesLoading: state?.GetCouponServices?.loading,
  }));

  // Partners
  const { couponPartners, couponPartnersLoading } = useSelector(state => ({
    couponPartners: state?.GetCouponPartner?.data,
    couponPartnersLoading: state?.GetCouponPartner?.loading,
  }));

  // Facilities
  const { couponFacilities, couponFacilityLoading } = useSelector(state => ({
    couponFacilities: state?.GetCouponFacility?.data,
    couponFacilityLoading: state?.GetCouponFacility?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */

  useEffect(() => {
    return () => {
      dispatch({
        type: LIST_SERVICE_WORTH_EMPTY,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (createServiceWorth !== null) {
      dispatch({
        type: CREATE_SERVICE_WORTH_EMPTY,
      });

      setIsCreateForm(false);
      dispatch({
        type: LIST_SERVICE_WORTH,
        // payload: {
        //   urlParam: {
        //     pageIndex: currentPageIndex,
        //   },
        // },
      });
    }
    if (deleteServiceWorth !== null) {
      dispatch({
        type: DELETE_SERVICE_WORTH_EMPTY,
      });

      dispatch({
        type: LIST_SERVICE_WORTH,
        payload: {
          urlParam: {
            serviceId: currentlyAppliedFilters?.services?.value,
            // pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          },
        },
      });
    }

    if (updateServiceWorth !== null) {
      dispatch({
        type: UPDATE_SERVICE_WORTH_EMPTY,
      });

      dispatch({
        type: LIST_SERVICE_WORTH,
        payload: {
          urlParam: {
            serviceId: currentlyAppliedFilters?.services?.value,
            // pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          },
        },
      });
    }
  }, [createServiceWorth, deleteServiceWorth, updateServiceWorth]);

  /* ---------------------------- BUTTON HANDLER ---------------------------- */
  const createCreateExpenditureRuleBtnHandler = () => {
    setIsCreateForm(true);
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedDetails(params?.details);
    setCurrentConfirmationModalActionFor(params?.actionType);

    if (params?.actionType === `DELETE`) {
      setIsShowConfirmationModal(true);
    } else if (params?.actionType === `UPDATE`) {
      setIsShowUpdateModal(true);
    }
  };

  /* ---------------------------- CLOSE MODAL HANDLER ---------------------------- */
  const closeModalHandler = (flag, data) => {
    setIsShowConfirmationModal(false);

    const payload = {
      objectId: currentSelectedDetails?.objectId,
      projectId: currentSelectedDetails?.projectId,
      delete: true,
      services: [
        {
          serviceId: currentSelectedDetails?.serviceId,
          serviceName: currentSelectedDetails?.serviceName,
          earnValue: currentSelectedDetails?.earnValue,
          burnValue: currentSelectedDetails?.burnValue,
        },
      ],
    };

    if (flag === `YES`) {
      if (currentConfirmationModalActionFor === `DELETE`) {
        dispatch({
          type: DELETE_SERVICE_WORTH,
          payload: payload,
        });
      }
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);

    dispatch({
      type: LIST_SERVICE_WORTH,
      // payload: {
      //   urlParam: {
      //     pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
      //   },
      // },
    });
  };

  /* ---------------------------- SUBMIT FILTER HANDLER ---------------------------- */
  const onSubmitFilterHandler = data => {
    setCurrentlyAppliedFilters(data);
    dispatch({
      type: LIST_SERVICE_WORTH,
      payload: {
        urlParam: {
          serviceId: data?.services?.value,
          // pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const createServiceWorthHandler = data => {
    dispatch({
      type: CREATE_SERVICE_WORTH,
      payload: {
        data: data,
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (
      serviceWorthsLoading ||
      createServiceWorthLoading ||
      couponServicesLoading ||
      couponPartnersLoading ||
      couponFacilityLoading ||
      deleteServiceWorthLoading ||
      updateServiceWorthLoading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    serviceWorthsLoading,
    createServiceWorthLoading,
    couponServicesLoading,
    couponPartnersLoading,
    couponFacilityLoading,
    deleteServiceWorthLoading,
    updateServiceWorthLoading,
  ]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Service worth`) {
      setIsCreateForm(false);

      dispatch({
        type: LIST_SERVICE_WORTH,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          },
        },
      });
    } else if (title === `Create`) {
      // do nothing
    }
  };

  return (
    <>
      {loading && <Loader />}

      {/*  ----------------------------  MODAL'S ---------------------------- */}
      {isShowConfirmationModal && (
        <ActionConfirmation onClose={closeModalHandler} data={currentSelectedDetails} action={currentConfirmationModalActionFor} />
      )}

      {isShowUpdateModal && <UpdateServiceWorthModal data={currentSelectedDetails} onCloseHandler={() => setIsShowUpdateModal(false)} />}

      {/*  ----------------------------  UI'S ---------------------------- */}

      <div className="page-content">
        <Container fluid className="position-relative">
          {!isCreateForm && (
            <>
              <BreadCrumb title="Rules" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Service worth`]} />

              <Filters onSubmitFilter={onSubmitFilterHandler} appliedFilters={currentlyAppliedFilters} />

              <ServiceWorthTable
                data={serviceWorths}
                totalData={serviceWorths?.length}
                pageNumber={currentPageNumber}
                dataStartedFrom={currentPageIndex}
                pageHandler={paginationHandler}
                actionHandler={tableActionHandler}
                onClickPrimaryBtn={createCreateExpenditureRuleBtnHandler}
              />
            </>
          )}

          {isCreateForm && (
            <>
              <BreadCrumb title="Rules" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Service worth`, `Create`]} />

              <ServiceWorthForm onSubmit={createServiceWorthHandler} />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

// export default React.memo(
//   ServiceWorthManagement,
//   (prevProps, nextProps) =>
//     prevProps.appliedFilters === nextProps.appliedFilters,
// )
export default ServiceWorthManagement;
