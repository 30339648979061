import { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

export function ErrorAlert({ modal, setModal, errorMessage, ...args }) {
    useEffect(() => {
        let timer1 = setTimeout(() => {
            setModal(false);
        }, 5000);

        return () => {
            clearTimeout(timer1);
        };
    }, [modal])
    return (
        <div>
            <Modal isOpen={modal} {...args} className=' h-100 d-flex align-items-center'>
                <ModalBody>
                    <div className='text-center'>
                        <div>
                            <svg
                                width={56}
                                height={56}
                                viewBox="0 0 56 56"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M24.0101 9.00666L4.24672 42C3.83924 42.7056 3.62364 43.5057 3.62136 44.3205C3.61907 45.1354 3.83019 45.9366 4.23371 46.6445C4.63723 47.3525 5.21908 47.9424 5.92137 48.3556C6.62366 48.7689 7.42191 48.991 8.23672 49H47.7634C48.5782 48.991 49.3764 48.7689 50.0787 48.3556C50.781 47.9424 51.3629 47.3525 51.7664 46.6445C52.1699 45.9366 52.381 45.1354 52.3787 44.3205C52.3765 43.5057 52.1609 42.7056 51.7534 42L31.9901 9.00666C31.5741 8.3209 30.9884 7.75394 30.2895 7.36045C29.5906 6.96697 28.8021 6.76025 28.0001 6.76025C27.198 6.76025 26.4095 6.96697 25.7106 7.36045C25.0117 7.75394 24.426 8.3209 24.0101 9.00666Z"
                                    stroke="black"
                                    strokeWidth={4}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M28 21V30.3333"
                                    stroke="black"
                                    strokeWidth={4}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M28 39.6666H28.0233"
                                    stroke="black"
                                    strokeWidth={4}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>

                        </div>
                        <h4>Error</h4>
                        <p>{errorMessage}</p>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}