import React from 'react';

import { Col, Row, TabPane } from 'reactstrap';
import BenefitItem from './BenefitItem';

const CatalogueItems = ({ curActiveTab, toggleHandler }) => {
  return (
    <TabPane tabId={2} id="pills-bill-info">
      <Row>
        <Col md={12}>
          <div className="card-body">
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary text-center"
                onClick={e => {
                  e.preventDefault();
                  // setIsShowCreateBenefitGrpItemModal(true)
                }}
              >
                + &nbsp; Add Catalog Items
              </button>
            </div>
          </div>
        </Col>

        <Col md={12}>
          <BenefitItem />
        </Col>

        <Col md={12}>
          <div className="d-flex align-items-start gap-3 mt-4">
            <button
              type="button"
              className="btn btn-light btn-label previestab"
              // onClick={() => {
              //   props.toggleTab(props.activeTab - 1)
              // }}
            >
              <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
              Back to Catalog Properties
            </button>
            <button
              type="button"
              className="btn btn-primary btn-label right ms-auto nexttab"
              onClick={() => {
                //   props.onPressNext(groupItemList)
                // props.toggleTab(props.activeTab + 1)
              }}
            >
              <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
              Continue to next step
            </button>
          </div>
        </Col>
      </Row>
    </TabPane>
  );
};

export default CatalogueItems;
